import React, { FC } from 'react';
import { useFormTypeCtx, FormTypeCtx } from '../../common/ctx/useFormTypesCtx';
import { useHintsCtx, HintsCtx } from './useHintsCtx';
import { useLanguagesCtx, LangsuagesCtx } from '../../common/ctx/useLanguagesCtx';
import { useModifiedHintsCtx, ModifiedHintsCtx } from './useModifiedHintsCtx';
import { usePaymentSystemsCtx, PaymentSystemsCtx } from '../../common/ctx/usePaymentSystemsCtx';
import { useSaveModalCtx, SaveModalCtx } from './useSaveModalCtx';
import { FormWasSubmittedCtx, useFormWasSubmittedCtx } from './useFormWasSubmitted';
import { ConfirmModalCtx, useConfirmModalCtx } from './useConfirmModal';
import { UseOpenedFormTypeCtx, useUseOpenedFormTypeCtx } from './useOpenedFormType';

export {
  useFormTypeCtx,
  useHintsCtx,
  useLanguagesCtx,
  useModifiedHintsCtx,
  usePaymentSystemsCtx,
  useSaveModalCtx,
  useFormWasSubmittedCtx,
  useConfirmModalCtx,
  useUseOpenedFormTypeCtx,
};

export const TranslatesCtx: FC = ({ children }) => {
  return (
    <SaveModalCtx>
      <ConfirmModalCtx>
        <FormWasSubmittedCtx>
          <ModifiedHintsCtx>
            <FormTypeCtx>
              <LangsuagesCtx>
                <PaymentSystemsCtx>
                  <HintsCtx>
                    <UseOpenedFormTypeCtx>{children}</UseOpenedFormTypeCtx>
                  </HintsCtx>
                </PaymentSystemsCtx>
              </LangsuagesCtx>
            </FormTypeCtx>
          </ModifiedHintsCtx>
        </FormWasSubmittedCtx>
      </ConfirmModalCtx>
    </SaveModalCtx>
  );
};
