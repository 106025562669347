import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getFormSyncErrors, getFormValues } from 'redux-form';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { widgetSubmittingSelector } from '@kassma-team/kassma-toolkit/lib';

import { CREATE_WALLET_FORM_NAME } from 'utils/constants';
import { WidgetType } from 'utils/enums';
import { walletActionCreators } from 'actions/widgets/wallet';
import useWizardForm from 'hooks/widgets/useWizardForm';

import WalletCreationTypeSelection from 'components/widgets/wallets/create/WalletCreationTypeSelection';
import WalletCreationData from 'components/widgets/wallets/create/WalletCreationData';
import WizardForm from 'components/form/WizardForm';
import useWalletSubmitHandler from 'hooks/widgets/wallets/useWalletSubmitHandler';
import { useProxyNightParserSelector, useProxySelector } from 'selectors/widgets/constructor/paymentsConstructor';

const WalletCreationForm = () => {
  const { page, setPage } = useWizardForm({ form: CREATE_WALLET_FORM_NAME });
  const errors = useSelector(getFormSyncErrors(CREATE_WALLET_FORM_NAME));

  const submitting: boolean = useSelector(widgetSubmittingSelector(WidgetType.WALLETS));

  const [t] = useTranslation();

  const values: Record<string, string> = useSelector(getFormValues(CREATE_WALLET_FORM_NAME)) || {};

  const {
    onForcedSubmit,
    onSubmit,
    onConfirmationInfoCancel,
    confirmationInfoData,
    confirmationInfoType,
  } = useWalletSubmitHandler({
    form: CREATE_WALLET_FORM_NAME,
    type: `creating`,
    widget: WidgetType.WALLETS,
    actionCreators: walletActionCreators,
  });

  const [tabs] = useState([
    {
      title: t(`common.type`),
      page: 1,
    },
    {
      title: t(`common.data`),
      page: 2,
    },
  ]);

  const { wallet_type, status } = values;

  //TODO: КОСТЫЛЬ, используется для валидации кошельков
  const useProxy = useSelector(useProxySelector);
  const useProxyNightEmailParser = useSelector(useProxyNightParserSelector);

  return (
    <WizardForm
      title="wallets.walletCreating"
      actionCreators={walletActionCreators}
      activePage={page}
      setPage={setPage}
      tabs={tabs}
      formName={CREATE_WALLET_FORM_NAME}
      submitText="common.create"
      disabledNextStep={get(errors, `comment`) || !wallet_type || !status}
      disabledSubmitBtn={submitting}
      showFooter={!confirmationInfoType}
    >
      {page === 1 && <WalletCreationTypeSelection />}
      {page === 2 && (
        <WalletCreationData
          walletTypeValue={values.wallet_type as string}
          onSubmit={onSubmit}
          onConfirmationInfoCancel={onConfirmationInfoCancel}
          onForcedSubmit={onForcedSubmit}
          confirmationInfoData={confirmationInfoData}
          confirmationInfoType={confirmationInfoType}
          submitting={submitting}
          useProxy={useProxy}
          useProxyNightEmailParser={useProxyNightEmailParser}
        />
      )}
    </WizardForm>
  );
};

export default WalletCreationForm;
