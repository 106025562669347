export const getDiff = (initVal: Record<any, any>, currVal: Record<any, any>): Record<any, any> => {
  const diff: Record<any, any> = {};

  for (const key in currVal) {
    const curr = currVal[key];
    const init = initVal[key];

    if (curr !== init) {
      diff[key] = curr;
    }
  }

  return {
    ...diff,
    ...(typeof currVal?.is_check_amount === `boolean` ? { is_check_amount: currVal.is_check_amount } : {}),
  };
};
