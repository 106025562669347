import React, { useEffect } from 'react';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import { widgetSubmittingSelector, InputType, TableContentContainer } from '@kassma-team/kassma-toolkit/lib';
import { Dispatch } from 'redux';

import { ModalType, Permission, PluginColorScheme, WidgetType } from 'utils/enums';
import { loadPluginSettings, settingsActionCreators, updatePluginColorScheme } from 'actions/widgets/settings';
import { hasAccessSelector } from 'selectors/auth';
import { APPEARANCE_SETTINGS } from 'utils/constants';
import { currencyListWithCustomSortingSelector } from 'selectors/widgets/settings';
import { IAppearanceSettingsForm } from 'interfaces/widgets/settings/pluginSettings';

import FormField from 'components/form/FormField';
import MediaUploadingForm from 'components/widgets/settings/pluginSettings/HelpGifsForm';
import Modal from 'components/modals/Modal';
import CustomThemeUploading from 'components/widgets/settings/pluginSettings/CustomThemeUploading';
import NewFormSubmit from 'components/form/formSubmit/NewFormSubmit';
import CreateSortingModal from 'components/widgets/settings/pluginSettings/paymentSorting/CreateSortingModal';
import UpdateSortingModal from 'components/widgets/settings/pluginSettings/paymentSorting/UpdateSortingModal';
import ResetSortingModal from 'components/widgets/settings/pluginSettings/paymentSorting/ResetSortingModal';
import PaymentCurrencyRow from 'components/widgets/settings/pluginSettings/PaymentCurrencyRow';
import ConfirmDefaultSortingModal from 'components/widgets/settings/pluginSettings/paymentSorting/ConfirmDefaultSortingModal';

import './pluginSettingsForm.scss';

const { showModal } = settingsActionCreators;

const onSubmit = (values: IAppearanceSettingsForm, dispatch: Dispatch) => {
  const { payment_plugin_color_scheme } = values;
  dispatch(updatePluginColorScheme({ payment_plugin_color_scheme }));
};

const modals = [
  {
    type: ModalType.CREATE_PAYMENTS_SORTING,
    widget: WidgetType.SETTINGS,
    component: CreateSortingModal,
  },
  {
    type: ModalType.UPDATE_PAYMENTS_SORTING,
    widget: WidgetType.SETTINGS,
    component: UpdateSortingModal,
  },
  {
    type: ModalType.RESET_PAYMENTS_SORTING,
    widget: WidgetType.SETTINGS,
    component: ResetSortingModal,
  },
  {
    type: ModalType.SETTINGS_MEDIA_UPLOADING,
    widget: WidgetType.SETTINGS,
    component: MediaUploadingForm,
  },
  {
    type: ModalType.CONFIRM_DEFAULT_SORTING,
    widget: WidgetType.SETTINGS,
    component: ConfirmDefaultSortingModal,
  },
];

const AppearanceSettingsForm = ({ handleSubmit, form, change }: InjectedFormProps) => {
  const dispatch = useDispatch();

  const [t] = useTranslation();

  const mediaAccess = useSelector(hasAccessSelector(Permission.MEDIA_SETTINGS));
  const submitting = useSelector(widgetSubmittingSelector(WidgetType.SETTINGS));
  const { payment_plugin_color_scheme }: Partial<IAppearanceSettingsForm> = useSelector(getFormValues(form)) || {};
  const paymentCurrencyTableData = useSelector(currencyListWithCustomSortingSelector);

  useEffect(() => {
    dispatch(loadPluginSettings());
  }, []);

  const paymentCurrencyTableTitles = [
    {
      title: `common.currency`,
      translateKeys: true,
    },
    {
      title: `settings.displayOrder`,
      translateKeys: true,
    },
    {
      title: (
        <button
          className="btn btn-secondary js-tooltip-enabled"
          type="button"
          onClick={() => dispatch(showModal({ type: ModalType.CREATE_PAYMENTS_SORTING }))}
        >
          <i className="fa fa-plus-circle" />
        </button>
      ),
      translateKeys: false,
      className: `text-right`,
    },
  ];

  return (
    <>
      <div className="plugin-settings-form col-xl-9 col-md-12 col-lg-8">
        {mediaAccess && (
          <button
            onClick={() => dispatch(showModal({ type: ModalType.SETTINGS_MEDIA_UPLOADING }))}
            className="btn btn-md btn-secondary mb-15 w-100"
          >
            {t(`settings.mediaSettings`)}
          </button>
        )}
        <div className="row">
          <form className="row col-xl-12 col-md-12 col-lg-12" onSubmit={handleSubmit}>
            <div className="d-flex-pol col-xl-6 col-md-12 col-lg-12">
              <FormField
                name="payment_plugin_color_scheme"
                type={InputType.MUI_SELECT}
                label={t(`settings.theme`)}
                data={[
                  {
                    value: PluginColorScheme.LIGHT,
                    text: t(`settings.light`),
                  },
                  {
                    value: PluginColorScheme.DARK,
                    text: t(`settings.dark`),
                  },
                  {
                    value: PluginColorScheme.WHITE_GREEN,
                    text: t(`settings.whiteGreen`),
                  },
                  {
                    value: PluginColorScheme.CUSTOM,
                    text: t(`settings.customTheme`),
                  },
                ]}
                required
              />
            </div>

            <div className="col-xl-6 col-md-12 col-lg-12">
              {payment_plugin_color_scheme === PluginColorScheme.CUSTOM && (
                <CustomThemeUploading changeHandler={change} />
              )}
            </div>
            <NewFormSubmit submitting={submitting as boolean}>{t(`common.save`)}</NewFormSubmit>
          </form>
        </div>
      </div>
      <TableContentContainer title="settings.paymentSystemsDisplayOrder">
        <table className="table table-vcenter">
          <thead>
            <tr>
              {map(paymentCurrencyTableTitles, ({ title, translateKeys, className = `` }, key) => (
                <th key={key} className={className}>
                  {translateKeys ? t(title as string) : title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {map(paymentCurrencyTableData, (item, key) => (
              <PaymentCurrencyRow {...item} key={key} />
            ))}
          </tbody>
        </table>
      </TableContentContainer>
      {map(modals, (modal, key) => (
        <Modal key={key} {...modal} />
      ))}
    </>
  );
};

export default reduxForm({
  form: APPEARANCE_SETTINGS,
  destroyOnUnmount: false,
  onSubmit,
})(AppearanceSettingsForm as any);
