import {
  createWidgetAction,
  widgetActionCreators,
  IWidgetActionCreators,
  IWidgetsAction,
} from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import {
  DEPOSITS_REQUESTED,
  PAYMENT_CURRENCIES_REQUESTED,
  RESET_DEPOSIT,
  UPDATE_DEPOSITS_REQUESTED,
  UPDATE_PAYMENT_CURRENCIES_REQUESTED,
  PAYMENT_VIEW_DATA_REQUESTED,
  UPDATE_PAYMENT_VIEW_DATA_REQUESTED,
  RESET_PAYMENT_VIEW_DATA,
  TOGGLE_SHOW_ON_PLUGIN,
  TOGGLE_STANDARD_PS,
  UPDATE_PAYMENTS_PLUGIN_PARAMS_REQUESTED,
  CHANGE_LIMIT_OF_GETTING_STANDARD_STATUS,
  PAYMENT_WORKING_TIME_DATA_REQUESTED,
  RESET_PAYMENT_WORKING_TIME_DATA,
  TOGGLE_ENABLING_POSSIBLE_TRANSACTIONS,
  PAYMENT_TRANSLATIONS_DATA_REQUESTED,
  PAYMENT_TRANSLATIONS_DATA_SUCCEEDED,
  LOAD_PAYMENT_PROXY_REQUESTED,
  GET_REQUISITES,
  CREATE_PAYMENT_SYSTEM,
  UPDATE_WALLET_TYPE_SETTINGS,
  CHANGE_SETTINGS,
} from 'actionTypes';
import { ILimitsFormData, IUpdateRequisitesData } from 'interfaces/widgets/constructor/paymentsConstructor';

export const paymentsConstructorActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.PAYMENTS_CONSTRUCTOR,
  labels: { listLabel: `payment_systems_with_applied_currencies`, itemLabel: `supportedCurrencies` },
});

export const getPaymentCurrencies: IWidgetsAction = createWidgetAction({
  widget: WidgetType.PAYMENTS_CONSTRUCTOR,
})(PAYMENT_CURRENCIES_REQUESTED);

export const updatePaymentCurrencies = (walletType: string, data: any): IWidgetsAction =>
  createWidgetAction({
    widget: WidgetType.PAYMENTS_CONSTRUCTOR,
    walletType,
  })(UPDATE_PAYMENT_CURRENCIES_REQUESTED)(data);

export const getPaymentDeposits: IWidgetsAction = createWidgetAction({
  widget: WidgetType.PAYMENTS_CONSTRUCTOR,
})(DEPOSITS_REQUESTED);

export const updateDepositsRequest = (walletType: string, data: ILimitsFormData[]): IWidgetsAction =>
  createWidgetAction({
    widget: WidgetType.PAYMENTS_CONSTRUCTOR,
    walletType,
  })(UPDATE_DEPOSITS_REQUESTED)(data);

export const resetDeposit: IWidgetsAction = createWidgetAction({
  widget: WidgetType.PAYMENTS_CONSTRUCTOR,
})(RESET_DEPOSIT);

export const getPaymentViewData: IWidgetsAction = createWidgetAction({
  widget: WidgetType.PAYMENTS_CONSTRUCTOR,
})(PAYMENT_VIEW_DATA_REQUESTED);

export const updatePaymentViewData = (walletType: string): IWidgetsAction =>
  createWidgetAction({
    widget: WidgetType.PAYMENTS_CONSTRUCTOR,
    walletType,
  })(UPDATE_PAYMENT_VIEW_DATA_REQUESTED);

export const resetPaymentViewData: IWidgetsAction = createWidgetAction({
  widget: WidgetType.PAYMENTS_CONSTRUCTOR,
})(RESET_PAYMENT_VIEW_DATA);

interface IToggleShowOnPluginPayload {
  walletType: string;
  showOnPlugin: boolean;
}

export const toggleShowOnPlugin: IWidgetsAction<IToggleShowOnPluginPayload> = createWidgetAction({
  widget: WidgetType.PAYMENTS_CONSTRUCTOR,
})(TOGGLE_SHOW_ON_PLUGIN);

interface IToggleStandardPSPayload {
  walletType: string;
  isStandardPS: boolean;
}

export const toggleStandardPS: IWidgetsAction<IToggleStandardPSPayload> = createWidgetAction({
  widget: WidgetType.PAYMENTS_CONSTRUCTOR,
})(TOGGLE_STANDARD_PS);

interface IToggleEnablingPTPayload {
  walletType: string;
  enablePossibleTransactions: boolean;
}

export const toggleEnablingPossibleTransactions: IWidgetsAction<IToggleEnablingPTPayload> = createWidgetAction({
  widget: WidgetType.PAYMENTS_CONSTRUCTOR,
})(TOGGLE_ENABLING_POSSIBLE_TRANSACTIONS);

interface IChangeLimitStandardStatus {
  walletType: string;
  limitOfGettingStandardStatus: string;
}

export const changeLimitOfGettingStandardStatus: IWidgetsAction<IChangeLimitStandardStatus> = createWidgetAction({
  widget: WidgetType.PAYMENTS_CONSTRUCTOR,
})(CHANGE_LIMIT_OF_GETTING_STANDARD_STATUS);

export const updatePaymentsPlugin = (walletType: string): IWidgetsAction =>
  createWidgetAction({
    widget: WidgetType.PAYMENTS_CONSTRUCTOR,
    walletType,
  })(UPDATE_PAYMENTS_PLUGIN_PARAMS_REQUESTED);

export const loadProxyData = (walletType: string): IWidgetsAction =>
  createWidgetAction({
    widget: WidgetType.PAYMENTS_CONSTRUCTOR,
    walletType,
  })(LOAD_PAYMENT_PROXY_REQUESTED)();

export const getPaymentWorkingTimeData: IWidgetsAction = createWidgetAction({
  widget: WidgetType.PAYMENTS_CONSTRUCTOR,
})(PAYMENT_WORKING_TIME_DATA_REQUESTED);

export const resetPaymentWorkingTimeData: IWidgetsAction = createWidgetAction({
  widget: WidgetType.PAYMENTS_CONSTRUCTOR,
})(RESET_PAYMENT_WORKING_TIME_DATA);

export const getPaymentTranslationsData: IWidgetsAction = createWidgetAction({
  widget: WidgetType.PAYMENTS_CONSTRUCTOR,
})(PAYMENT_TRANSLATIONS_DATA_REQUESTED);

export const getPaymentTranslationsDataSucceeded: IWidgetsAction = createWidgetAction({
  widget: WidgetType.PAYMENTS_CONSTRUCTOR,
})(PAYMENT_TRANSLATIONS_DATA_SUCCEEDED);

export const changeSettings: IWidgetsAction = createWidgetAction({
  widget: WidgetType.PAYMENTS_CONSTRUCTOR,
})(CHANGE_SETTINGS);

export const getRequisites: IWidgetsAction = (walletType: string) =>
  createWidgetAction({
    widget: WidgetType.PAYMENTS_CONSTRUCTOR,
    walletType,
  })(GET_REQUISITES)();

export const createPaymentSystem: IWidgetsAction = (data: any) =>
  createWidgetAction({
    widget: WidgetType.PAYMENTS_CONSTRUCTOR,
  })(CREATE_PAYMENT_SYSTEM)(data);

export const updateWalletTypeSettings: IWidgetsAction = (walletType: string, data: Record<any, any>) =>
  createWidgetAction({
    widget: WidgetType.PAYMENTS_CONSTRUCTOR,
    walletType,
  })(UPDATE_WALLET_TYPE_SETTINGS)(data);
