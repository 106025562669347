import { useState } from 'react';

import { WithdrawalsLogsColumn } from 'utils/enums';
import { ITableTitleField } from 'interfaces/widgets/widgets';

const useWithdrawalsLogsTableTitles = (): ITableTitleField[] => {
  const [titles] = useState([
    {
      title: `wallets.walletType`,
      id: WithdrawalsLogsColumn.WALLET_TYPE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `logs.message`,
      id: WithdrawalsLogsColumn.MESSAGE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.date`,
      id: WithdrawalsLogsColumn.DATE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
  ]);

  return titles;
};

export default useWithdrawalsLogsTableTitles;
