import { put, takeEvery } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { ToastrEmitter } from 'react-redux-toastr';
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import get from 'lodash/get';
import pick from 'lodash/pick';
import map from 'lodash/map';
import difference from 'lodash/difference';
import { ForkEffect } from '@redux-saga/core/effects';
import i18n from 'i18n';
import { getErrorMessage, normalizeErrorMessage } from 'utils';
import refreshSaga from 'sagas/effects/refreshSaga';

import {
  IAction,
  IRefreshSagaParams,
  WidgetOperations,
  WIDGET_ITEM_SUCCEEDED,
  WIDGET_LIST_SUCCEEDED,
  WIDGET_CREATE_SUCCEEDED,
  sleep,
  MODAL_FADING_TIMEOUT,
  WIDGET_LIST_ITEM_UPDATE_SUCCEEDED,
  WIDGET_ITEM_UPDATE_SUCCEEDED,
  WIDGET_REMOVE_SUCCEEDED,
  WIDGET_LIST_REQUESTED,
  WIDGET_ITEM_REQUESTED,
  WIDGET_CREATE_REQUESTED,
  WIDGET_LIST_ITEM_UPDATE_REQUESTED,
  WIDGET_ITEM_UPDATE_REQUESTED,
  WIDGET_REMOVE_REQUESTED,
  WIDGET_SET_MODAL,
  WIDGET_SET_ANY_DATA,
} from '@kassma-team/kassma-toolkit';
import {
  formSubmissionError,
  validateForm,
  WIDGET_RESET_MODAL,
  WIDGET_SET_SUBMITTING_STATUS,
} from '@kassma-team/kassma-toolkit/lib';
import {
  WIDGET_REMOVE_MULTIPLE_ITEMS_REQUESTED,
  WIDGET_REMOVE_MULTIPLE_ITEMS_SUCCEEDED,
  WIDGET_RESET_SELECT_LIST,
} from '@kassma-team/kassma-toolkit/lib/actionTypes';
import { WalletType, WidgetType } from 'utils/enums';
import { CREATE_WITHDRAWAL_FORM_NAME } from 'utils/constants';

type ApiFetchingCallback = (...params: any) => AxiosPromise | undefined;

interface IApiFetching {
  [widget: string]: ApiFetchingCallback;
}

interface IListSagaParams {
  listFetching: IApiFetching;
  toastr: ToastrEmitter;
}

const getListLoadingSaga = ({ listFetching, toastr }: IListSagaParams) =>
  function* ({ payload, meta }: IAction) {
    if (!meta?.widget) {
      throw new Error(`meta.widget is required param`);
    }

    // Костыль-заглушка. Нет нужды фетча данных для ручных ПС
    // @ts-ignore
    const request: ApiFetchingCallback =
      meta?.widget === `manual-payment-systems` ? () => null : listFetching[meta?.widget];

    if (!request) {
      throw new Error(`Request callback does not found in listFetching by widget ${meta?.widget}`);
    }

    if (meta?.statusBar) {
      yield put(showLoading());
    }

    yield refreshSaga({
      request: () => request(payload),
      onSuccess: function* (resp: AxiosResponse) {
        if (meta?.onSuccess) meta.onSuccess(resp);

        if (meta.widget === `payment-hint-langs`) {
          const modifiedResp = {
            ...resp.data,
            [meta.labels.listLabel]: resp.data.data,
          };

          yield put({ type: WIDGET_LIST_SUCCEEDED, meta, payload: modifiedResp });

          return;
        } else if (resp.data.code) {
          let data = resp.data.data || resp.data.items;
          if (meta?.onSuccess) meta.onSuccess(data);
          if (meta?.widget !== WidgetType.ACCOUNT_BALANCE_HISTORY) {
            data = { [meta?.labels.listLabel]: data };
          }
          if (meta.withPagination) {
            delete meta.withPagination;
            const paginationInfo = resp.data.paginate;
            const { offset, limit, total } = paginationInfo;
            const currentPage = Math.ceil(offset / limit) + 1;
            const lastPage = currentPage + Math.floor(total / limit);
            // console.log(currentPage, lastPage, paginationInfo);
            yield put({
              type: WIDGET_SET_ANY_DATA,
              meta,
              payload: {
                page: currentPage,
                nextPage: currentPage < lastPage,
                paginationLoading: false,
                lastPage: lastPage,
              },
            });
          }
          yield put({ type: WIDGET_LIST_SUCCEEDED, meta, payload: data });
        } else {
          yield put({ type: WIDGET_LIST_SUCCEEDED, meta, payload: resp.data || resp.data.items });
        }
      },
      onError: function (err?: AxiosError) {
        if (meta.onError) meta.onError(err as AxiosError);
        const metaOptions = get(meta, `toastrErrorOptions.${WidgetOperations.LIST_LOADING}`) || {};

        // Костыль-заглушка. Нет нужды показа ошибки для ручных ПС
        if (meta?.widget === `manual-payment-systems`) return;

        toastr.error(
          i18n.t(`common.error`),
          getErrorMessage(err, {
            normalize: true,
            defaultValue: i18n.t(`widgets.listDataFetchingHasBeenFailed`),
            ...metaOptions,
          }) as string
        );
      },
      onFinally: function* () {
        if (meta?.onFinally) meta.onFinally();
        if (meta?.statusBar) {
          yield put(hideLoading());
        }
      },
    });
  };

interface IItemSagaParams {
  itemFetching: IApiFetching;
  toastr: ToastrEmitter;
}

const getItemLoadingSaga = ({ itemFetching, toastr }: IItemSagaParams) =>
  function* ({ payload, meta }: IAction) {
    if (!meta?.widget) {
      throw new Error(`meta.widget is required param`);
    }

    const request = itemFetching[meta?.widget];
    if (!request) {
      throw new Error(`Request callback does not found in itemFetching by widget ${meta?.widget}`);
    }

    yield refreshSaga({
      request: () => request(payload),
      onSuccess: function* (resp: any) {
        if (meta?.onSuccess) meta.onSuccess(resp);
        yield put({ type: WIDGET_ITEM_SUCCEEDED, meta, payload: resp.data });
      },
      onError: function (err: any) {
        if (meta.onError) meta.onError(err as AxiosError);
        toastr.error(
          i18n.t(`common.error`),
          getErrorMessage(err as AxiosError, {
            normalize: true,
            defaultValue: i18n.t(`widgets.itemFetchingHasBeenFailed`),
          }) as string
        );
      },
      onFinally: meta?.onFinally,
    });
  };

interface ICreateItemSagaParams {
  createFetching: IApiFetching;
  toastr: ToastrEmitter;
}

const getItemCreatingSaga = ({ createFetching, toastr }: ICreateItemSagaParams) =>
  function* ({ meta, payload }: IAction) {
    const isUPI_S_AP = payload?.wallet_type === WalletType.UPI_S || payload?.wallet_type === WalletType.UPI_AP;
    const isWithdrawalCreating =
      meta?.widget === WidgetType.WITHDRAWALS && meta?.creatingForm === CREATE_WITHDRAWAL_FORM_NAME;
    if (!meta?.creatingForm) {
      throw new Error(`meta.creatingForm is required param`);
    }

    if (!meta?.widget) {
      throw new Error(`meta.widget is required param`);
    }

    const request = createFetching[meta.widget];
    if (!request) {
      throw new Error(`Request callback does not found in creatingRequest by widget ${meta.widget}`);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const valid = yield validateForm({ form: meta?.creatingForm, meta });
    if (!valid) {
      return;
    }

    yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: true, meta });
    yield refreshSaga({
      request: () => request(payload),
      callErrorWhenNoPermissions: true,
      onSuccess: function* (resp: any) {
        const data = resp?.data;
        yield put({ type: WIDGET_CREATE_SUCCEEDED, meta, payload: data });
        yield put({ type: WIDGET_RESET_MODAL, meta });
        toastr.success(
          i18n.t(`common.success`),
          i18n.t(meta?.succeededCreatingMessage || `widgets.itemHasBeenSuccessfullyCreated`)
        );

        if (meta?.onSuccess) meta?.onSuccess(resp); // Todo need refactoring

        yield sleep(MODAL_FADING_TIMEOUT);
        yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      },
      onError: function* (err: any) {
        if (meta.onError) meta.onError(err as AxiosError);

        let payload: any = getErrorMessage(err as AxiosError, {
          defaultValue: i18n.t(`widgets.itemCreatingHasBeenFailed`),
        });

        if (isUPI_S_AP) {
          if (typeof payload !== `string`) {
            payload._error = normalizeErrorMessage(pick(payload, `upi_addresses`), true);
          }
        }

        if (isWithdrawalCreating && payload instanceof String) {
          const errMessage = payload;
          payload = {};
          payload._error = errMessage;
        }

        if (meta.fieldAsErrorBlock) {
          payload._error = normalizeErrorMessage(pick(payload, meta.fieldAsErrorBlock), true);
        }
        yield formSubmissionError({ payload, meta, form: meta.creatingForm as string });
        yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      },
      onFinally: meta?.onFinally,
    });
  };

interface IUpdateListItemSagaParams {
  updateListItemFetching: IApiFetching;
  toastr: ToastrEmitter;
}

const getListItemUpdatingSaga = ({ updateListItemFetching, toastr }: IUpdateListItemSagaParams) =>
  function* ({ meta, payload }: IAction) {
    if (!meta?.listItemUpdatingForm) {
      throw new Error(`meta.listItemUpdatingForm is required param`);
    }

    if (!meta?.widget) {
      throw new Error(`meta.widget is required param`);
    }

    if (isEmpty(payload)) {
      throw new Error(`payload must be a not-empty object in updateListItemSaga`);
    }

    const request = updateListItemFetching[meta.widget];
    if (!request) {
      throw new Error(`Request callback does not found in updatingListItemRequest by widget ${meta.widget}`);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const valid = yield validateForm({ form: meta?.listItemUpdatingForm, meta });
    if (!valid) {
      return;
    }

    yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: true, meta });

    const { id, ...data } = payload;

    if (`cookie` in data && !data.cookie) {
      data.cookie = [];
    }

    yield refreshSaga({
      request: () => request(id, data),
      callErrorWhenNoPermissions: true,
      onSuccess: function* (resp: any) {
        if (meta?.onSuccess) meta?.onSuccess(resp); // Todo need refactoring

        const data = resp?.data;
        yield put({ type: WIDGET_LIST_ITEM_UPDATE_SUCCEEDED, meta, payload: data });
        yield put({ type: WIDGET_RESET_MODAL, meta });

        toastr.success(
          i18n.t(`common.success`),
          i18n.t(meta?.succeededUpdatingMessage || `widgets.itemHasBeenSuccessfullyUpdated`)
        );

        yield sleep(MODAL_FADING_TIMEOUT);
        yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      },
      onError: function* (err: any) {
        if (meta.onError) meta.onError(err as AxiosError);

        const payload: any = getErrorMessage(err as AxiosError, {
          defaultValue: i18n.t(`widgets.itemUpdatingHasBeenFailed`),
        });

        if (meta.fieldAsErrorBlock) {
          payload._error = normalizeErrorMessage(pick(payload, meta.fieldAsErrorBlock), true);
        }

        yield formSubmissionError({ payload, meta, form: meta.listItemUpdatingForm as string });
        yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      },
      onFinally: meta?.onFinally,
    });
  };

interface IUpdateItemSagaParams {
  updateItemFetching: IApiFetching;
  toastr: ToastrEmitter;
}

const getItemUpdatingSaga = ({ updateItemFetching, toastr }: IUpdateItemSagaParams) =>
  function* updateItemSaga({ meta, payload }: IAction) {
    if (!meta?.itemUpdatingForm) {
      throw new Error(`meta.itemUpdatingForm is required param`);
    }

    if (!meta?.widget) {
      throw new Error(`meta.widget is required param`);
    }

    const request = updateItemFetching[meta.widget];
    if (!request) {
      throw new Error(`Request callback does not found in itemUpdatingRequest by widget ${meta.widget}`);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const valid = yield validateForm({ form: meta?.itemUpdatingForm, meta });
    if (!valid) {
      return;
    }

    yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: true, meta });

    yield refreshSaga({
      request: () => request(payload),
      onSuccess: function* (resp: any) {
        if (meta?.onSuccess) meta?.onSuccess(resp); // Todo need refactoring

        const data = resp?.data;
        yield put({ type: WIDGET_ITEM_UPDATE_SUCCEEDED, meta, payload: data });
        yield put({ type: WIDGET_RESET_MODAL, meta });

        toastr.success(
          i18n.t(`common.success`),
          i18n.t(meta?.succeededUpdatingMessage || `widgets.itemHasBeenSuccessfullyUpdated`)
        );

        yield sleep(MODAL_FADING_TIMEOUT);
        yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      },
      onError: function* (err: any) {
        if (meta.onError) meta.onError(err as AxiosError);

        const payload: any = getErrorMessage(err as AxiosError, {
          defaultValue: i18n.t(`widgets.itemUpdatingHasBeenFailed`),
        });
        yield formSubmissionError({ payload, meta, form: meta.itemUpdatingForm as string });
        yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      },
      onFinally: meta?.onFinally,
    });
  };

interface IDeleteItemSagaParams {
  deleteItemFetching: IApiFetching;
  toastr: ToastrEmitter;
}

const getItemDeletingSaga = ({ deleteItemFetching, toastr }: IDeleteItemSagaParams) =>
  function* ({ meta, payload }: IAction) {
    if (!meta?.widget) {
      throw new Error(`meta.widget is required param`);
    }

    const request = deleteItemFetching[meta.widget];
    if (!request) {
      throw new Error(`Request callback does not found in deletingRequest by widget ${meta.widget}`);
    }

    yield refreshSaga({
      request: () => request(payload),
      onSuccess: function* (resp: any) {
        if (meta?.onSuccess) meta.onSuccess(resp);

        yield put({ type: WIDGET_REMOVE_SUCCEEDED, meta, payload });
        toastr.success(
          i18n.t(`common.success`),
          i18n.t(meta.succeededDeletingMessage || `widgets.itemHasBeenSuccessfullyDeleted`)
        );
      },
      onFinally: function* () {
        if (meta?.onFinally) meta.onFinally();

        yield put({ type: WIDGET_RESET_MODAL, meta });
      },
    });
  };

interface IDeleteListItemSagaParams {
  multiDeleteFetching: IApiFetching;
  toastr: ToastrEmitter;
}

const getMultiDeletingSaga = ({ multiDeleteFetching, toastr }: IDeleteListItemSagaParams) =>
  function* ({ meta, payload }: IAction) {
    if (!meta?.widget) {
      throw new Error(`meta.widget is required param`);
    }

    const request = multiDeleteFetching[meta.widget];
    if (!request) {
      throw new Error(`Request callback does not found in deletingRequest by widget ${meta.widget}`);
    }

    const oneItemSent = size(payload) === 1;

    yield refreshSaga({
      request: () => request(payload),
      onSuccess: function* (resp: any) {
        if (meta?.onSuccess) meta.onSuccess(resp);

        let listToRemove = payload;

        const status = resp?.status;
        const errors = resp?.data?.errors;
        if (status === 220 && !isEmpty(errors)) {
          const notDeletedItems = map(errors, (error) => error?.target);
          listToRemove = difference(listToRemove, notDeletedItems);

          if (oneItemSent) {
            const errorMessage = get(errors, `[0].message`);
            toastr.error(i18n.t(`common.error`), i18n.t(meta.warningDeletingMessage || errorMessage));
          } else {
            toastr.warning(
              i18n.t(`common.warning`),
              i18n.t(meta.warningDeletingMessage || `widgets.someOfTheItemsHaveNotBeenDeleted`)
            );
          }
        } else {
          toastr.success(
            i18n.t(`common.success`),
            i18n.t(meta.succeededDeletingMessage || `widgets.itemsHaveBeenSuccessfullyDeleted`)
          );
        }
        if (!isEmpty(listToRemove)) {
          yield put({ type: WIDGET_REMOVE_MULTIPLE_ITEMS_SUCCEEDED, meta, payload: new Set(listToRemove) });
        }
        yield put({ type: WIDGET_RESET_SELECT_LIST, meta });
      },
      onFinally: function* () {
        if (meta?.onFinally) meta.onFinally();

        yield put({ type: WIDGET_RESET_MODAL, meta });
      },
    });
  };

const resetModalSideEffect = (payload: IAction) => {
  if (!payload?.meta?.notChangeBodyOverflowForModal) {
    document.body.style.overflow = `auto`;
  }
};

const setModalSideEffect = (payload: IAction) => {
  if (!payload?.meta?.notChangeBodyOverflowForModal) {
    document.body.style.overflow = `hidden`;
  }
};

export interface IWidgetSagasParams {
  listFetching?: IApiFetching;
  itemFetching?: IApiFetching;
  createFetching?: IApiFetching;
  updateListItemFetching?: IApiFetching;
  updateItemFetching?: IApiFetching;
  deleteItemFetching?: IApiFetching;
  multiDeleteFetching?: IApiFetching;
  toastr: ToastrEmitter;
}

const widgetSagas = ({
  listFetching = {},
  itemFetching = {},
  createFetching = {},
  updateListItemFetching = {},
  updateItemFetching = {},
  deleteItemFetching = {},
  multiDeleteFetching = {},
  toastr,
}: IWidgetSagasParams): ForkEffect[] => {
  const loadListSaga = getListLoadingSaga({ listFetching, toastr });
  const loadItemSaga = getItemLoadingSaga({ itemFetching, toastr });
  const createItemSaga = getItemCreatingSaga({ createFetching, toastr });
  const updateListItemSaga = getListItemUpdatingSaga({ updateListItemFetching, toastr });
  const updateItemSaga = getItemUpdatingSaga({ updateItemFetching, toastr });
  const deleteItemSaga = getItemDeletingSaga({ deleteItemFetching, toastr });
  const multiDeletingSaga = getMultiDeletingSaga({ multiDeleteFetching, toastr });

  return [
    takeEvery<IAction>(WIDGET_LIST_REQUESTED, loadListSaga),
    takeEvery<IAction>(WIDGET_ITEM_REQUESTED, loadItemSaga),
    takeEvery<IAction>(WIDGET_CREATE_REQUESTED, createItemSaga),
    takeEvery<IAction>(WIDGET_LIST_ITEM_UPDATE_REQUESTED, updateListItemSaga),
    takeEvery<IAction>(WIDGET_ITEM_UPDATE_REQUESTED, updateItemSaga),
    takeEvery<IAction>(WIDGET_REMOVE_REQUESTED, deleteItemSaga),
    takeEvery<IAction>(WIDGET_REMOVE_MULTIPLE_ITEMS_REQUESTED, multiDeletingSaga),
    takeEvery<IAction>(WIDGET_RESET_MODAL, resetModalSideEffect),
    takeEvery<IAction>(WIDGET_SET_MODAL, setModalSideEffect),
  ];
};

export default widgetSagas;
