import React, { useEffect, useState, ReactNode } from 'react';
import map from 'lodash/map';
import size from 'lodash/size';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import { useTranslation } from 'react-i18next';

import ModalElement from 'components/modals/ModalElement';

interface ITabs {
  title: string;
  page: number;
}

interface IWizardFormProps {
  title: string;
  actionCreators: Record<string, unknown>;
  tabs: ITabs[];
  activePage: number;
  children: ReactNode;
  setPage: (value: number) => void;
  formName: string;
  submitText: string;
  disabledNextStep?: boolean;
  disabledSubmitBtn?: boolean;
  showFooter?: boolean;
  forms?: string[];
  errors?: any;
  submitSucceeded?: boolean;
}

const WizardForm = ({
  tabs,
  activePage,
  children,
  setPage,
  formName,
  submitText,
  disabledNextStep,
  disabledSubmitBtn,
  title,
  actionCreators,
  forms,
  errors,
  submitSucceeded,
  showFooter = true,
}: IWizardFormProps) => {
  const [progressBarStyle, setProgressBarStyle] = useState({});

  const dispatch = useDispatch();

  const [t] = useTranslation();

  useEffect(() => {
    setProgressBarStyle({ width: `${(activePage / size(tabs)) * 100}%` });
  }, [activePage]);

  const firstPage = activePage === 1;

  const lastPage = activePage === size(tabs);

  const disabledNextBtn = (lastPage && disabledSubmitBtn) || disabledNextStep;

  const isAbleToMoveToNextFrom = forms
    ? submitSucceeded && errors && Object.keys(errors).length === 0 && !disabledSubmitBtn
    : !disabledNextBtn;

  const content = (
    <div className="js-wizard-simple block">
      <ul className="nav nav-tabs nav-tabs-block nav-fill">
        {map(tabs, ({ title, page }) => (
          <li className="nav-item" key={page}>
            <button
              type="button"
              className={classNames(`nav-link`, `full-width`, {
                active: page === activePage,
                disabled: !isAbleToMoveToNextFrom,
              })}
              onClick={() => setPage(page)}
              disabled={!isAbleToMoveToNextFrom}
              id={`${formName}_page_${page}_btn`}
            >
              {page}. {title}
            </button>
          </li>
        ))}
      </ul>
      <div className="block-content block-content-sm">
        <div className="progress">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated bg-primary"
            style={progressBarStyle}
          />
        </div>
      </div>
      <div className="block-content block-content-full tab-content">{children}</div>
    </div>
  );

  const handleNextClick = () => {
    if (forms) {
      dispatch(submit(forms[activePage - 1]));
      if (!lastPage && submitSucceeded && errors && Object.keys(errors).length === 0) {
        setPage(activePage + 1);
      }
    } else {
      if (lastPage) {
        dispatch(submit(formName));
      } else {
        setPage(activePage + 1);
      }
    }
  };

  const footer = showFooter && (
    <div className="block-content block-content-sm block-content-full bg-body-light">
      <div className="row">
        <div className="col-6">
          <button
            className={classNames(`btn`, `btn-alt-secondary`, { disabled: firstPage })}
            type="button"
            disabled={firstPage}
            onClick={() => setPage(activePage - 1)}
            id={`${formName}_back_btn`}
          >
            <i className="fa fa-angle-left mr-5" />
            {t(`common.back`)}
          </button>
        </div>
        <div className="col-6 text-right">
          <button
            className={classNames(`btn`, {
              'btn-alt-secondary': !lastPage,
              'btn-alt-primary': lastPage,
            })}
            type="button"
            onClick={handleNextClick}
            disabled={disabledNextBtn}
            id={`${formName}_next_btn`}
          >
            {!lastPage && <i className="fa fa-angle-right mr-5" />}
            {lastPage ? t(submitText) : t(`common.next`)}
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <ModalElement
      title={title}
      actionCreators={actionCreators}
      footer={footer}
      content={content}
      footerClassName="pl-20 pr-20 pb-20"
    />
  );
};

export default WizardForm;
