import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { change, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { getSubmitHandler, InputType } from '@kassma-team/kassma-toolkit/lib';
import moment from 'moment';

import useUserHasAccess from 'hooks/useUserHasAccess';
import { ModalType, TransactionDirection, WidgetType } from 'utils/enums';
import { postbackActionCreators } from 'actions/widgets/postback';
import postbackRouteTabs from 'pages/postbacks/postbackRouteTabs';
import { selectionOfAllManualPSSelector } from 'selectors/widgets/manualPS';
import { paymentSystemsWithoutSettlementsSelector } from 'selectors/widgets/paymentSystems';
import { manualPSActionCreators } from 'actions/widgets/paymentSystem';
import usePostbacksTableTitles from 'hooks/widgets/tableTitles/usePostbacksTableTitles';
import { IPostbackRowProps } from 'interfaces/widgets/postback';
import { IFilterProps } from 'interfaces/widgets/widgets';

import Filters from 'components/widgets/filters/Filters';
import PostbackRow from 'components/widgets/postback/PostbackRow';
import PostbackRequestModal from 'components/widgets/postback/PostbackRequestModal';
import Widget from 'components/widgets/Widget';
import useSearchParams from '../../hooks/useSearchParams';
import PostBackResentModal from '../../components/widgets/postback/PostBackResentModal';

const FORM_NAME = `postbacks-form`;

const FilterForm = reduxForm<any, IFilterProps>({
  form: FORM_NAME,
  onSubmit: getSubmitHandler({
    dateFields: [{ name: `date_range`, fromName: `date_from`, toName: `date_to` }],
  }),
})(Filters);

const filterDateFields = [
  {
    from: `date_from`,
    to: `date_to`,
    name: `date_range`,
  },
];

const Postbacks = () => {
  const titles = usePostbacksTableTitles();
  useUserHasAccess();
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const paymentSystemList = useSelector(paymentSystemsWithoutSettlementsSelector);

  const safePaymentSystemList = paymentSystemList?.map((el) => ({
    ...el,
    text: el.name,
    value: el.code,
  }));

  const testPaymentSystems = useSelector(selectionOfAllManualPSSelector);

  const safeTestPaymentSystems = testPaymentSystems?.map((el) => ({
    ...el,
    text: el.name,
    value: el.code,
  }));

  const searchParams = useSearchParams();

  const fromValue = useMemo(() => {
    if (searchParams.date_from) {
      return moment(searchParams.date_from).toDate();
    } else {
      return moment().subtract(7, `day`).toDate();
    }
  }, []);

  const toValue = useMemo(() => {
    if (searchParams.date_to) {
      return moment(searchParams.date_to).toDate();
    } else {
      return moment().toDate();
    }
  }, []);

  useEffect(() => {
    dispatch(
      change(FORM_NAME, `date_range`, {
        values: {
          startDate: fromValue,
          endDate: toValue,
        },
      })
    );
  }, []);

  const leftFilterFields = [
    {
      name: `wallet_type`,
      id: `${FORM_NAME}_wallet_type`,
      data: [{ value: null, text: t(`common.all`) }, ...safePaymentSystemList, ...safeTestPaymentSystems],
      type: InputType.SELECT,
      placeholder: t(`wallets.walletType`),
    },
    {
      name: `label`,
      placeholder: t(`common.label`),
    },
    {
      name: `merchant_order_id`,
      placeholder: t(`common.orderId`),
    },
    {
      name: `direction`,
      id: `${FORM_NAME}_direction`,
      type: InputType.SELECT,
      data: [
        {
          value: null,
          text: `common.all`,
        },
        {
          value: TransactionDirection.INGOING,
          text: `transactions.ingoing`,
        },
        {
          value: TransactionDirection.OUTGOING,
          text: `transactions.outgoing`,
        },
      ],
      translateKeys: true,
      placeholder: t(`reports.direction`),
    },
  ];

  useEffect(() => {
    dispatch(manualPSActionCreators.getList());
  }, []);

  const rightFilterFields = [
    {
      name: `date_range`,
      type: InputType.DATE_RANGE,
      required: true,
    },
  ];

  return (
    <Widget
      widget={WidgetType.POSTBACKS}
      actionCreators={postbackActionCreators}
      pageTitle="postbacks.postbacks"
      headTitle="postbacks.postbacks"
      routeTabs={postbackRouteTabs}
      tableTitle="postbacks.sentPostbackList"
      tableHeadTitles={titles}
      listMapping={(item: IPostbackRowProps) => (
        <PostbackRow {...item} actionCreators={postbackActionCreators} key={item.id} widget={WidgetType.POSTBACKS} />
      )}
      filterForm={
        <FilterForm
          formName={FORM_NAME}
          leftFields={leftFilterFields}
          rightFields={rightFilterFields}
          dateFields={filterDateFields}
        />
      }
      modals={[
        {
          type: ModalType.SHOW,
          widget: WidgetType.POSTBACKS,
          component: PostbackRequestModal,
        },
        {
          type: ModalType.UPDATE,
          widget: WidgetType.POSTBACKS,
          component: PostBackResentModal,
        },
      ]}
      withPagination
      statusBar
      filterFormName={FORM_NAME}
    />
  );
};

export default Postbacks;
