import moment from 'moment';
import { TransactionDateType } from 'utils/enums';

export const asideFiltersInitData = {
  date_range: {
    values: {
      startDate: moment()
        .set({
          hour: 0,
          minute: 0,
          second: 0,
        })
        .subtract(6, `d`)
        .toDate(),
      endDate: moment()
        .set({
          hour: 23,
          minute: 59,
          second: 59,
        })
        .toDate(),
    },
  },
  date_type: TransactionDateType.ACTIVATION,
  currency_codes: [],
  country_id: null,
  display_in_currency: `USD`,
};

export const dashboardFiltersInitData = {
  show_all_active_transaction_data: 1,
};
