import { createSelector } from 'reselect';
import map from 'lodash/map';
import { widgetListSelector, IRootState, ISelectData } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { IChangeLogActionItems } from 'interfaces/widgets/changeLogAction';

export const changeLogActionsSelectionSelector = createSelector<IRootState, IChangeLogActionItems[], ISelectData[]>(
  widgetListSelector(WidgetType.CHANGE_LOG_ACTIONS),
  (items) => map(items, ({ id, name }) => ({ value: id, text: name }))
);
