import { widgetActionCreators, IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { IWidgetLabels } from 'interfaces/widgets/widgets';

export const currencyPayloadLabels: IWidgetLabels = { listLabel: `currencies` };

export const currencyActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.CURRENCIES,
  labels: currencyPayloadLabels,
});
