import {
  createWidgetAction,
  widgetActionCreators,
  IWidgetActionCreators,
  IWidgetsAction,
} from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { GENERATE_REPORT_REQUESTED } from 'actionTypes';

export const reportsActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.REPORTS,
  labels: { listLabel: `reports` },
});

export const generateReport: IWidgetsAction = createWidgetAction({
  widget: WidgetType.REPORTS,
})(GENERATE_REPORT_REQUESTED);

export const autoGenerateReport: IWidgetsAction = createWidgetAction({
  widget: WidgetType.AUTOREPORTS,
})(GENERATE_REPORT_REQUESTED);
