import { useTranslation } from 'react-i18next';

type UseNotificationUi = {
  title: string;
  unreadedTitle: string;
  readedTitle: string;
  showAllBtn: string;
  emptyMessages: string;
};

export function useNotificationUi(): UseNotificationUi {
  const [t] = useTranslation();

  return {
    title: t(`notification.center`),
    unreadedTitle: t(`notification.unreaded`),
    readedTitle: t(`notification.readed`),
    showAllBtn: t(`notification.showAll`),
    emptyMessages: t(`notification.hasNotMessages`),
  };
}
