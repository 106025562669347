import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ZeroBalancesPlaceholder.module.scss';

const ZeroBalancesPlaceholder = () => {
  const [t] = useTranslation();

  return (
    <div className={styles.placeholder}>
      <div className={styles.title}>{t(`balances.zeroBalancesPlaceholderTitle`)}</div>
      <div className={styles.description}>{t(`balances.zeroBalancesPlaceholderDescription`)}</div>
    </div>
  );
};

export default ZeroBalancesPlaceholder;
