import includes from 'lodash/includes';
import find from 'lodash/find';
import get from 'lodash/get';
import { logoutRequest } from '@kassma-team/kassma-toolkit/lib';

import { Permission } from 'utils/enums';
import { hasIntersection } from 'utils';
import { IListDataProps } from 'interfaces/widgets/header';

interface IPaths {
  path: string;
  access: boolean;
}

const getActualPath = (paths: IPaths[]): string | undefined =>
  get(
    find(paths, ({ access }) => access),
    `path`
  );

const listData = ({ permissions, userName, dispatch }: IListDataProps): Record<string, unknown>[] => [
  {
    title: `wallets.paymentSystems`,
    id: `nav-payment-systems`,
    path: getActualPath([
      {
        path: `/wallets`,
        access: includes(permissions, Permission.WALLET_LIST),
      },
      {
        path: `/disabled-wallets`,
        access: includes(permissions, Permission.WALLETS_DISABLED),
      },
      {
        path: `/removed-wallets`,
        access: includes(permissions, Permission.WALLETS_REMOVED),
      },
      {
        path: `/payment-systems`,
        access: includes(permissions, Permission.MANUAL_PS_LIST),
      },
    ]),
    icon: `si si-wallet`,
    hasAccess: hasIntersection(permissions, [
      Permission.WALLET_LIST,
      Permission.WALLETS_DISABLED,
      Permission.MANAGE_PAYMENTS_CONSTRUCTOR,
      Permission.MANUAL_PS_LIST,
      Permission.WALLETS_REMOVED,
    ]),
    children: [
      {
        title: `wallets.wallets`,
        id: `nav-wallets`,
        path: getActualPath([
          {
            path: `/wallets`,
            access: includes(permissions, Permission.WALLET_LIST),
          },
          {
            path: `/disabled-wallets`,
            access: includes(permissions, Permission.WALLETS_DISABLED),
          },
          {
            path: `/removed-wallets`,
            access: includes(permissions, Permission.WALLETS_REMOVED),
          },
          {
            path: `/wallets-statistic`,
            access: includes(permissions, Permission.WALLET_LIST),
          },
        ]),
        icon: `si si-wallet`,
        hasAccess: hasIntersection(permissions, [
          Permission.WALLET_LIST,
          Permission.WALLETS_DISABLED,
          Permission.WALLETS_REMOVED,
        ]),
        children: [
          {
            title: `wallets.walletList`,
            id: `nav-active-wallets`,
            path: `/wallets`,
            hasAccess: includes(permissions, Permission.WALLET_LIST),
          },
          {
            title: `wallets.disabledWallets`,
            id: `nav-disabled-wallets`,
            path: `/disabled-wallets`,
            hasAccess: includes(permissions, Permission.WALLETS_DISABLED),
          },
          {
            title: `wallets.archivedWallets`,
            id: `nav-removed-wallets`,
            path: `/removed-wallets`,
            hasAccess: includes(permissions, Permission.WALLETS_REMOVED),
          },
          {
            title: `wallets.walletStatistic`,
            id: `nav-statisctic-wallets`,
            path: `/statistic-wallets`,
            hasAccess: includes(permissions, Permission.WALLET_LIST),
          },
        ],
      },
      {
        title: `paymentConstructor.constructor`,
        id: `nav-constructor`,
        path: `/payments-constructor`,
        icon: `fa fa-list-alt`,
        hasAccess: includes(permissions, Permission.MANAGE_PAYMENTS_CONSTRUCTOR),
        children: [
          {
            id: `nav-payments-constructor`,
            path: `/payments-constructor`,
            title: `paymentConstructor.paymentSystems`,
            hasAccess: includes(permissions, Permission.MANAGE_PAYMENTS_CONSTRUCTOR),
          },
          {
            id: `nav-tariffs-constructor`,
            path: `/tariffs-constructor`,
            title: `paymentConstructor.tariffs`,
            hasAccess: includes(permissions, Permission.MANAGE_PAYMENTS_CONSTRUCTOR),
          },
          {
            id: `nav-translations-constructor`,
            path: `/payment-translations`,
            title: `paymentConstructor.paymentTranslations`,
            hasAccess: includes(permissions, Permission.MANAGE_PAYMENTS_CONSTRUCTOR),
          },
          {
            id: `nav-plugin-constructor`,
            path: `/view-plugin`,
            title: `paymentConstructor.plugin`,
            hasAccess: includes(permissions, Permission.MANAGE_PAYMENTS_CONSTRUCTOR),
          },
        ],
      },
    ],
  },
  {
    title: `logs.walletsDebugging`,
    id: `nav-debugging`,
    path: getActualPath([
      {
        path: `/logs`,
        access: includes(permissions, Permission.LOGS),
      },
      {
        path: `/withdrawals-logs`,
        access: true,
      },
      {
        path: `/proxies`,
        access: includes(permissions, Permission.PROXY_LIST),
      },
    ]),
    icon: `si si-direction`,
    hasAccess: hasIntersection(permissions, [
      Permission.LOGS,
      Permission.PAYTM_COOKIES_UPLOAD,
      Permission.PAYTM_COOKIES_VIEW,
      Permission.PAYTM_COOKIES_DELETE,
      Permission.PROXY_LIST,
    ]),
    children: [
      {
        title: `logs.walletsDebugging`,
        id: `nav-wallets-debugging`,
        path: getActualPath([
          {
            path: `/logs`,
            access: includes(permissions, Permission.LOGS),
          },
        ]),
        icon: `si si-direction`,
        hasAccess: hasIntersection(permissions, [
          Permission.LOGS,
          Permission.PAYTM_COOKIES_UPLOAD,
          Permission.PAYTM_COOKIES_VIEW,
          Permission.PAYTM_COOKIES_DELETE,
        ]),
        children: [
          {
            title: `logs.logList`,
            id: `nav-logs`,
            hasAccess: includes(permissions, Permission.LOGS),
            path: `/logs`,
          },
          {
            title: `logs.withdrawalsLogs`,
            id: `nav-withdrawals-logs`,
            hasAccess: true,
            path: `/withdrawals-logs`,
          },
        ],
      },
      {
        title: `proxies.proxies`,
        id: `nav-proxies`,
        path: `/proxies`,
        icon: `si si-globe`,
        hasAccess: includes(permissions, Permission.PROXY_LIST),
      },
    ],
  },
  {
    title: `transactions.payments`,
    id: `nav-payments`,
    path: getActualPath([
      {
        path: `/transactions`,
        access: hasIntersection(permissions, [Permission.TRANSACTION_LIST]),
      },
      {
        path: `/transactions-only-by-id`,
        access: includes(permissions, Permission.TRANSACTION_ONLY_BY_ID),
      },
      {
        path: `/possible-transactions`,
        access: includes(permissions, Permission.TRANSACTION_MANAGEMENT),
      },
      {
        path: `/archive-transactions`,
        access: includes(permissions, Permission.ARCHIVE_TRANSACTION_MANAGEMENT),
      },
      {
        path: `/withdrawals`,
        access: includes(permissions, Permission.WITHDRAWALS_VIEW),
      },
      {
        path: `/stockpiling`,
        access: includes(permissions, Permission.STOCKPILING_LIST),
      },
      {
        path: `/postbacks`,
        access: includes(permissions, Permission.POSTBACKS),
      },
      {
        path: `/settlement`,
        access: includes(permissions, Permission.VIEW_SETTLEMENT),
      },
    ]),
    icon: `si si-pie-chart`,
    hasAccess: hasIntersection(permissions, [
      Permission.TRANSACTION_LIST,
      Permission.TRANSACTION_ONLY_BY_ID,
      Permission.TRANSACTION_MANAGEMENT,
      Permission.PRELIMINARY_TRANSACTION,
      Permission.PRELIMINARY_TRANSACTION_ONLY_BY_ID,
      Permission.WITHDRAWALS_VIEW,
      Permission.STOCKPILING_LIST,
      Permission.POSTBACKS,
      Permission.WALLET_BALANCE,
      Permission.ACCOUNTS_BALANCE,
      Permission.VIEW_SETTLEMENT,
    ]),
    children: [
      {
        title: `transactions.transactions`,
        id: `nav-transactions`,
        path: getActualPath([
          {
            path: `/transactions`,
            access: hasIntersection(permissions, [Permission.TRANSACTION_LIST]),
          },
          {
            path: `/transactions-only-by-id`,
            access: includes(permissions, Permission.TRANSACTION_ONLY_BY_ID),
          },
          {
            path: `/possible-transactions`,
            access: includes(permissions, Permission.TRANSACTION_MANAGEMENT),
          },
          {
            path: `/archive-transactions`,
            access: includes(permissions, Permission.ARCHIVE_TRANSACTION_VIEW),
          },
        ]),
        icon: `si si-pie-chart`,
        hasAccess: hasIntersection(permissions, [
          Permission.TRANSACTION_LIST,
          Permission.TRANSACTION_ONLY_BY_ID,
          Permission.TRANSACTION_MANAGEMENT,
          Permission.PRELIMINARY_TRANSACTION,
          Permission.PRELIMINARY_TRANSACTION_ONLY_BY_ID,
        ]),
        children: [
          {
            title: `transactions.transactions`,
            id: `nav-transaction-list`,
            path: getActualPath([
              {
                path: `/transactions`,
                access: hasIntersection(permissions, [Permission.TRANSACTION_LIST]),
              },
              {
                path: `/transactions-only-by-id`,
                access: includes(permissions, Permission.TRANSACTION_ONLY_BY_ID),
              },
            ]),
            hasAccess: hasIntersection(permissions, [Permission.TRANSACTION_LIST, Permission.TRANSACTION_ONLY_BY_ID]),
          },
          {
            title: `transactions.possibleTransactions`,
            id: `nav-possible-transactions`,
            path: `/possible-transactions`,
            hasAccess: includes(permissions, Permission.TRANSACTION_MANAGEMENT),
          },
          {
            title: `transactions.archiveTransactions`,
            id: `nav-archive-transactions`,
            path: `/archive-transactions`,
            hasAccess: includes(permissions, Permission.ARCHIVE_TRANSACTION_VIEW),
          },
        ],
      },
      {
        title: `withdrawals.withdrawals`,
        id: `nav-withdrawals`,
        path: `/withdrawals`,
        icon: `si si-credit-card`,
        hasAccess: includes(permissions, Permission.WITHDRAWALS_VIEW),
      },
      {
        title: `stockpilings.stockpilings`,
        id: `nav-stockpilings`,
        path: `/stockpiling`,
        icon: `si si-layers`,
        hasAccess: includes(permissions, Permission.STOCKPILING_LIST),
      },
      {
        title: `postbacks.postbacks`,
        id: `nav-postbacks`,
        path: `/postbacks`,
        icon: `fa fa-send-o`,
        hasAccess: includes(permissions, Permission.POSTBACKS),
        children: [
          {
            title: `postbacks.postbackList`,
            id: `nav-postback-list`,
            path: `/postbacks`,
            hasAccess: includes(permissions, Permission.POSTBACKS),
          },
          {
            title: `postbacks.notAcceptedPostbackList`,
            id: `nav-not-accepted-postbacks`,
            path: `/not-accepted-postbacks`,
            hasAccess: includes(permissions, Permission.POSTBACKS),
          },
        ],
      },
      {
        title: `wallets.accountsBalance`,
        id: `nav-balances`,
        path: getActualPath([
          {
            path: `/accounts-balance`,
            access: includes(permissions, Permission.ACCOUNTS_BALANCE),
          },
        ]),
        icon: `si si-wallet`,
        hasAccess: hasIntersection(permissions, [Permission.WALLET_BALANCE, Permission.ACCOUNTS_BALANCE]),
      },
    ],
  },
  {
    title: `reports.reports`,
    id: `nav-reports`,
    path: getActualPath([
      {
        path: `/reports`,
        access: hasIntersection(permissions, [Permission.TRANSACTION_REPORTS, Permission.LABEL_HISTORY_REPORT]),
      },
      {
        path: `/wallets-load`,
        access: includes(permissions, Permission.WALLETS_LOAD),
      },
      {
        path: `/change-logs`,
        access: includes(permissions, Permission.VIEW_CHANGE_LOG),
      },
    ]),
    icon: `fa fa-file-text`,
    hasAccess: hasIntersection(permissions, [
      Permission.WALLETS_LOAD,
      Permission.TRANSACTION_REPORTS,
      Permission.VIEW_CHANGE_LOG,
    ]),
    children: [
      {
        title: `wallets.load`,
        id: `nav-load`,
        path: `/wallets-load`,
        icon: `fa fa-bar-chart`,
        hasAccess: includes(permissions, Permission.WALLETS_LOAD),
      },
      {
        title: `reports.reports`,
        id: `nav-report-list`,
        path: `/reports`,
        icon: `fa fa-file-text`,
        hasAccess: hasIntersection(permissions, [Permission.TRANSACTION_REPORTS, Permission.LABEL_HISTORY_REPORT]),
      },
      {
        title: `changeLog.changeLogs`,
        id: `nav-change-list`,
        path: `/change-logs`,
        icon: `fa fa-file-text`,
        hasAccess: includes(permissions, Permission.VIEW_CHANGE_LOG),
      },
    ],
  },
  {
    title: userName,
    path: `/settings`,
    id: `nav-profile`,
    icon: `si si-user`,
    hasAccess: true,
    children: [
      {
        title: `settings.settings`,
        path: `/settings`,
        id: `nav-settings`,
        icon: `si si-settings`,
        hasAccess: true,
      },
      {
        title: `auth.logout`,
        id: `nav-logout`,
        onClick: () => dispatch(logoutRequest()),
        icon: `si si-logout`,
        hasAccess: true,
      },
    ],
  },
];

export default listData;
