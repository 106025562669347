import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';

export class ProxySettingsApi extends BaseApi {
  loadProxyData = (walletType: string): AxiosPromise =>
    this.makeFetch(`{walletType}`, { method: `GET` }, { walletType });
}

const proxySettingsApi = new ProxySettingsApi(`wallet-type/proxy-setting`);

export default proxySettingsApi;
