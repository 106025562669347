import React, { FC, useState, useCallback, useEffect, useRef, ChangeEvent } from 'react';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { FormType, Hint } from 'api/translations/TranslationsApi';

import { Field } from 'pages/constructor/DefaultTranslates/common/components/Field';
import {
  useHintsCtx,
  useFormTypeCtx,
  usePaymentSystemsCtx,
  useLanguagesCtx,
  useModifiedHintsCtx,
  useFormWasSubmittedCtx,
} from 'pages/constructor/DefaultTranslates/PaymentTranslation/ctx';
import { AddButton } from 'pages/constructor/DefaultTranslates/common/components';
import { isTranslateWasModified } from 'pages/constructor/DefaultTranslates/common/helpers';
import { TranslatesScreen } from 'pages/constructor/DefaultTranslates/common/screens';

import './TranslatesList.scss';

type Args = {
  hints: Array<Hint>;

  paymentSystemId?: string;
  typeOfForm?: FormType[`type`];
  entityId: string;
};

const findTranslatesInHint = ({ paymentSystemId, hints, typeOfForm, entityId }: Args) => {
  return hints.find(
    ({ wallet_type, form_type, uuid }) =>
      wallet_type === paymentSystemId && form_type === typeOfForm && uuid === entityId
  );
};

export const TranslatesList: FC = () => {
  const [t] = useTranslation();
  const { currentPaymentSystem } = usePaymentSystemsCtx();
  const { currentFormType } = useFormTypeCtx();
  const { modifiedHints, setModifiedHints } = useModifiedHintsCtx();
  const { hints, handleUpdateHint, handleCreateHint, isHintUpdating, fetchHints } = useHintsCtx();
  const { languages } = useLanguagesCtx();
  const initialHint = useRef<Hint | undefined>(undefined);
  const [localHint, setLocalHint] = useState<Hint | undefined>(undefined);
  const { isFormWasSubmitted, setFormSubmitted } = useFormWasSubmittedCtx();
  const isNeedToCreateHint = useRef(false);
  const [isWithoutEngErr, setIsWithoutEngErr] = useState(false);

  const isWasM = isTranslateWasModified({
    current: (initialHint.current as unknown) as Hint,
    initial: (localHint as unknown) as Hint,
  });

  useEffect(() => {
    if (isWasM) {
      localHint && setModifiedHints([...modifiedHints, localHint]);
    } else {
      setModifiedHints(modifiedHints.filter((el) => el.uuid !== localHint?.uuid));
    }
  }, [isWasM]);

  useEffect(() => {
    if (!currentFormType || !currentPaymentSystem) {
      setLocalHint(undefined);

      return;
    }

    if (currentFormType?.type && currentPaymentSystem?.code && currentFormType?.entityId) {
      const finded = findTranslatesInHint({
        hints: hints,
        typeOfForm: currentFormType?.type,
        paymentSystemId: currentPaymentSystem?.code,
        entityId: currentFormType?.entityId,
      });

      initialHint.current = finded;
      setLocalHint(finded);
    }
  }, [hints, currentFormType?.type, currentFormType?.entityId, currentPaymentSystem?.uuid]);

  const handleAddTranslate = () => {
    if (localHint) {
      setLocalHint({
        ...localHint,
        translations: [
          ...localHint.translations,
          {
            clientId: nanoid(),
            language: ``,
            text: ``,
          },
        ],
      });

      return;
    }

    isNeedToCreateHint.current = true;

    const newHint: Hint = {
      uuid: `client-id-${nanoid()}`,
      wallet_type_uuid: currentPaymentSystem?.uuid || ``,
      wallet_type: currentPaymentSystem?.wallet_type || ``,
      created_at: ``,
      updated_at: ``,
      is_manual: false,
      form_type: currentFormType?.type || ``,
      is_default: true,
      name: `${currentPaymentSystem?.name} (default)`,
      translations: [
        {
          clientId: nanoid(),
          language: ``,
          text: ``,
        },
      ],
    };

    setLocalHint(newHint);
    setModifiedHints([...modifiedHints, newHint]);
  };

  const handleChangeLang = useCallback(
    (language: string, clientId?: string) => (option: { value: string; text: string } | string) => {
      if (localHint) {
        if (typeof option === `object`) {
          if (option.value === `en` && isWithoutEngErr) setIsWithoutEngErr(false);
        }

        if (typeof option === `string`) {
          if (option === `en` && isWithoutEngErr) setIsWithoutEngErr(false);
        }

        if (clientId) {
          setLocalHint({
            ...localHint,
            translations: localHint.translations.map((trans) => {
              if (trans.clientId === clientId) {
                return { ...trans, language: typeof option === `object` ? option.value : option };
              }

              return trans;
            }),
          });

          return;
        }

        setLocalHint({
          ...localHint,
          translations: localHint.translations.map((trans) => {
            if (trans.language === language) {
              return { ...trans, language: typeof option === `object` ? option.value : option };
            }

            return trans;
          }),
        });
      }
    },
    [localHint]
  );

  const handleChangeText = useCallback(
    (language: string, clientId?: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const content = e.currentTarget.value;

      if (localHint) {
        if (clientId) {
          setLocalHint({
            ...localHint,
            translations: localHint.translations.map((trans) => {
              if (trans.clientId === clientId) {
                return {
                  ...trans,
                  text: content,
                };
              }

              return trans;
            }),
          });

          return;
        }

        setLocalHint({
          ...localHint,
          translations: localHint.translations.map((trans) => {
            if (trans.language === language) {
              return {
                ...trans,
                text: content,
              };
            }

            return trans;
          }),
        });
      }
    },
    [localHint]
  );

  const handleRemove = useCallback(
    (language: string, clientId?: string) => () => {
      if (localHint) {
        if (clientId) {
          const translations = localHint.translations.filter((trans) => trans.clientId !== clientId);

          if (!translations.length && initialHint.current === undefined) {
            setLocalHint(undefined);
            setModifiedHints([]);

            return;
          }

          setLocalHint({
            ...localHint,
            translations: translations,
          });

          return;
        }

        setLocalHint({
          ...localHint,
          translations: localHint.translations.filter((trans) => trans.language !== language),
        });
      }
    },
    [localHint]
  );

  return (
    <TranslatesScreen
      title={t(`transactions.transfer`)}
      headerBtn={
        !currentFormType || localHint?.is_default ? null : (
          <AddButton disabled={localHint?.is_default} onClick={handleAddTranslate} />
        )
      }
    >
      <ul className="TranslatesList__list">
        {localHint &&
          localHint.translations.map(({ clientId, language, text }, i) => {
            return (
              <li key={`${clientId}-${language}-${i}`} className="TranslatesList__item">
                <Field
                  lang={language}
                  text={text}
                  selectedLangs={localHint.translations.map(({ language }) => language)}
                  onChangeLang={handleChangeLang(language, clientId)}
                  onChangeText={handleChangeText(language, clientId)}
                  onRemove={handleRemove(language, clientId)}
                  langs={languages}
                  isRemoveBtnShowed={language !== `en`}
                  isFormSubmitted={isFormWasSubmitted}
                  isDisabled={localHint.is_default}
                />
              </li>
            );
          })}
      </ul>
      {modifiedHints.length > 0 && localHint && (
        <>
          {isWithoutEngErr && <p className="TranslatesList__error">{t(`common.engIsRequired`)}</p>}
          <button
            className="TranslatesList__save-btn"
            onClick={() => {
              setFormSubmitted(true);

              if (
                localHint?.translations.some(
                  ({ language, text }) => !language || !text || text === `<br>` || text.length > 500
                )
              ) {
                return;
              }

              if (!localHint?.translations.find(({ language }) => language === `en`)) {
                setIsWithoutEngErr(true);

                return;
              } else {
                setIsWithoutEngErr(false);
              }

              isNeedToCreateHint.current
                ? handleCreateHint({
                    wallet_type: localHint?.wallet_type || ``,
                    name: localHint?.name || ``,
                    is_manual: localHint?.is_manual,
                    form_type: localHint?.form_type || ``,
                    translations:
                      localHint?.translations?.map((el) => ({
                        language: el.language,
                        text: el.text,
                      })) || [],
                  })().then(() => (isNeedToCreateHint.current = false))
                : handleUpdateHint(localHint?.uuid || ``, {
                    is_manual: localHint?.is_manual,
                    name: localHint?.name || ``,
                    translations: localHint?.translations || [],
                  })().then(() => fetchHints());
            }}
            type="button"
            disabled={isHintUpdating}
          >
            <span className="TranslatesList__save-btn_content">
              {isHintUpdating ? <i className="fa fa-spinner fa-spin" /> : t(`common.save`)}
            </span>
          </button>
        </>
      )}
    </TranslatesScreen>
  );
};
