import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFilterColumns, useIsTouchDevice } from '@kassma-team/kassma-toolkit/lib';

import { ReportColumn, ReportStatus } from 'utils/enums';
import { IReportRowProps } from 'interfaces/widgets/reports';
import { formatDate } from 'utils/formatDate';
import { reportParamsByStatus } from 'static/reports';

const ReportRow = ({
  file_name,
  created_at,
  processed_at,
  status,
  file_path,
  desktopSize,
  hiddenColumns,
}: IReportRowProps) => {
  const [t] = useTranslation();

  const isTouchDevice = useIsTouchDevice();

  const downloadBtn =
    status === ReportStatus.SUCCESS ? (
      <a
        className={classNames(`btn`, `btn-secondary`, `js-tooltip-enabled`, {
          'btn-sm': !isTouchDevice,
        })}
        href={file_path}
        download
      >
        <i className="fa fa-download" />
      </a>
    ) : null;

  const formattedCreatedAt = formatDate(created_at);
  const formattedProcessedAt = formatDate(processed_at);

  const statusParams = reportParamsByStatus[status];

  const statusBadge = (
    <div className="multi-badge-cell">
      <span className={`badge ${statusParams.color}`}>{t(statusParams.message)}</span>
    </div>
  );

  const columns = [
    {
      id: ReportColumn.FILE,
      desktop: <td>{file_name}</td>,
      mobile: (
        <tr className="first-mobile-row">
          <td>{t(`reports.file`)}</td>
          <td>{file_name}</td>
        </tr>
      ),
    },
    {
      id: ReportColumn.STATUS,
      desktop: <td>{statusBadge}</td>,
      mobile: (
        <tr>
          <td>{t(`common.status`)}</td>
          <td>{statusBadge}</td>
        </tr>
      ),
    },
    {
      id: ReportColumn.DATE,
      desktop: <td>{formattedCreatedAt}</td>,
      mobile: (
        <tr>
          <td>{t(`common.createdAt`)}</td>
          <td>{formattedCreatedAt}</td>
        </tr>
      ),
    },
    {
      id: ReportColumn.PROCESSING_DATE,
      desktop: <td>{formattedProcessedAt}</td>,
      mobile: (
        <tr>
          <td>{t(`reports.dateCompleted`)}</td>
          <td>{formattedProcessedAt}</td>
        </tr>
      ),
    },
    {
      id: ReportColumn.ACTIONS,
      desktop: <td>{downloadBtn}</td>,
      mobile: (
        <tr>
          <td>{t(`common.actions`)}</td>
          <td>{downloadBtn}</td>
        </tr>
      ),
    },
  ];

  return useFilterColumns({ columns, desktopSize, hiddenColumns });
};

export default ReportRow;
