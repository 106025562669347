import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useIsCrypt, InputType, widgetListSelector, widgetModalSelector } from '@kassma-team/kassma-toolkit/lib';

import { WalletType, WidgetType } from 'utils/enums';
import { CREATE_SETTLEMENT_FORM_NAME } from 'utils/constants';
import {
  IReplenishmentComponentProps,
  ITransferringMoneyToSettlementBalanceForm,
} from 'interfaces/widgets/balances/balances';

import ModalForm from 'components/modals/ModalForm';
import { supportedPaymentSystemsByCurrencySelector } from '../../../selectors/widgets/paymentSystems';
import useSearchParams from '../../../hooks/useSearchParams';
import {
  FieldsState,
  formatCrediting,
  getCalcAmount,
  getCalcCrediting,
  getCases,
  isIncorrectResult,
  trimDote,
} from './replenishmentUtils';
import { LastTouchedField } from './replenishmentUtils/types';
import useDebounce from '../../../utils/debounce';
import { commissionsSelector } from '../../../selectors/widgets/tariffs';
import { loadSettlementCommission } from '../../../actions/widgets/tariffs';

type IProps = IReplenishmentComponentProps & InjectedFormProps;

const Replenishment = ({ change, paymentSystems, currencySelectorByPSType, ...props }: IProps) => {
  const dispatch = useDispatch();
  const modal: any = useSelector(widgetModalSelector(WidgetType.ACCOUNT_BALANCE_HISTORY)) || {};
  const commissionPercent = useSelector(commissionsSelector);

  const [fieldsState, setFieldsState] = useState<FieldsState>({
    amount: { touched: false, inFocus: false, inputedValue: `` },
    crediting: { touched: false, inFocus: false, inputedValue: `` },
    commission: { touched: false, inFocus: false, inputedValue: `` },
    exRate: { touched: false, inFocus: false, inputedValue: `` },
  });

  const [lastTouchedField, setLastTouchedField] = useState<LastTouchedField | null>(null);

  const { isSettlement, currency_code: currency, payment_system } = modal;
  const form = get(props, `form`);
  const values: Partial<any> = useSelector(getFormValues(form as string));
  const { recipient_payment_system, currency_code, writeoff_amount, commission_percent, ex_rate, crediting } =
    values || {};
  const debouncedValue = useDebounce(values?.id, values?.writeoff_amount, 500);
  const isPSPaykassma = recipient_payment_system === `paykassma`;

  const supportedPaymentSystems = useSelector(supportedPaymentSystemsByCurrencySelector(currency_code || ``));

  const currencyList = useSelector(widgetListSelector(WidgetType.CURRENCIES));
  const [t] = useTranslation();

  const currencies = useSelector(currencySelectorByPSType(recipient_payment_system));

  const isCrypt = useIsCrypt({ currencyCode: currency_code, currencies: currencyList });

  const queryParams = useSearchParams();
  useEffect(() => {
    change(`id`, queryParams?.id);
  }, [queryParams]);

  useEffect(() => {
    if (isSettlement) {
      change(`currency_code`, undefined);
    }
  }, [recipient_payment_system, isSettlement]);

  useEffect(() => {
    change(`writeoff_amount`, undefined);
    change(`ex_rate`, undefined);
    change(`commission_percent`, undefined);
  }, [currency_code]);

  const getAmount = useCallback(
    (creditting, commission_rate, ex_rate) => {
      const getAmount = getCalcAmount(isSettlement);

      const clearCrediting = formatCrediting(creditting);
      const clearExRate = formatCrediting(ex_rate);
      const clearCommissionRate = formatCrediting(commission_rate);

      return formatCrediting(
        getAmount({
          commission_rate: clearCommissionRate,
          ex_rate: clearExRate,
          creditting: clearCrediting,
        })
      );
    },
    [isSettlement]
  );

  const getCrediting = useCallback(
    (amount, commission_rate, ex_rate) => {
      const getCrediting = getCalcCrediting(isSettlement);

      const clearAmount = formatCrediting(amount);
      const clearExRate = formatCrediting(ex_rate);
      const clearCommissionRate = formatCrediting(commission_rate);

      return formatCrediting(
        getCrediting({
          commission_rate: clearCommissionRate,
          ex_rate: clearExRate,
          amount: clearAmount,
        })
      );
    },
    [isSettlement]
  );

  // Весь этот огород создан для того, чтобы при изменении одного поля, другое автоматически пересчитывалось
  // И это работало в обе стороны
  // Чтобы сие было более читаемо, я разбил варианты на несколько кейсов
  useEffect(() => {
    const isCase = getCases(fieldsState, { writeoff_amount, crediting, commission_percent, ex_rate });

    if (isCase(`cleared credit field`)) {
      change(`writeoff_amount`, undefined);

      return;
    }

    if (isCase(`cleared amount field`)) {
      change(`crediting`, undefined);

      return;
    }

    if (crediting && commission_percent && ex_rate) {
      if (isCase(`amount field didnt touched`)) {
        const amount = getAmount(crediting, commission_percent, ex_rate);

        if (isIncorrectResult(amount)) {
          change(`writeoff_amount`, undefined);

          return;
        }

        change(`writeoff_amount`, amount);

        return;
      }

      if (isCase(`amount field touched, but empty`)) {
        const amount = getAmount(crediting, commission_percent, ex_rate);

        if (isIncorrectResult(amount)) {
          change(`writeoff_amount`, undefined);

          return;
        }

        change(`writeoff_amount`, amount);

        return;
      }

      if (isCase(`amount field in focus`)) {
        const credit = getCrediting(fieldsState.amount.inputedValue, commission_percent, ex_rate);

        if (isIncorrectResult(credit)) {
          change(`crediting`, undefined);

          return;
        }

        change(`crediting`, credit);

        return;
      }

      if (isCase(`comission field in focus`)) {
        if (lastTouchedField && lastTouchedField === `crediting`) {
          const amount = getAmount(crediting, commission_percent, ex_rate);
          if (isIncorrectResult(amount)) {
            change(`writeoff_amount`, undefined);

            return;
          }

          change(`writeoff_amount`, amount);

          return;
        }
      }

      if (isCase(`exRate field in focus`)) {
        if (lastTouchedField && lastTouchedField === `crediting`) {
          const amount = getAmount(crediting, commission_percent, ex_rate);

          if (isIncorrectResult(amount)) {
            change(`writeoff_amount`, undefined);

            return;
          }

          change(`writeoff_amount`, amount);

          return;
        }
      }
    }

    if (writeoff_amount && commission_percent && ex_rate) {
      if (isCase(`crediting field didnt touched`)) {
        const crediting = getCrediting(writeoff_amount, commission_percent, ex_rate);

        if (isIncorrectResult(crediting)) {
          change(`crediting`, undefined);

          return;
        }

        change(`crediting`, crediting);

        return;
      }

      if (isCase(`crediting field touched, but empty`)) {
        const crediting = getCrediting(writeoff_amount, commission_percent, ex_rate);

        if (isIncorrectResult(crediting)) {
          change(`crediting`, undefined);

          return;
        }

        change(`crediting`, crediting);

        return;
      }

      if (isCase(`crediting field in focus`)) {
        const amount = getAmount(fieldsState.crediting.inputedValue, commission_percent, ex_rate);

        if (isIncorrectResult(amount)) {
          change(`writeoff_amount`, undefined);

          return;
        }

        change(`writeoff_amount`, amount);

        return;
      }

      if (isCase(`comission field in focus`)) {
        if (lastTouchedField && lastTouchedField === `amount`) {
          const crediting = getCrediting(writeoff_amount, commission_percent, ex_rate);

          if (isIncorrectResult(crediting)) {
            change(`crediting`, undefined);

            return;
          }

          change(`crediting`, crediting);

          return;
        }
      }

      if (isCase(`exRate field in focus`)) {
        const crediting = getCrediting(writeoff_amount, commission_percent, ex_rate);

        if (lastTouchedField && lastTouchedField === `amount`) {
          if (isIncorrectResult(crediting)) {
            change(`crediting`, undefined);
          }

          change(`crediting`, crediting);

          return;
        }
      }
    }
  }, [
    fieldsState.amount.inFocus,
    fieldsState.amount.touched,
    fieldsState.crediting.inFocus,
    fieldsState.crediting.touched,
    fieldsState.commission.inFocus,
    fieldsState.commission.touched,
    fieldsState.exRate.inFocus,
    fieldsState.exRate.touched,
    writeoff_amount,
    crediting,
    commission_percent,
    ex_rate,
    isSettlement,
    lastTouchedField,
  ]);

  useEffect(() => {
    if (!isSettlement) {
      change(`currency_code`, currency);
    }
  }, [isSettlement]);

  useEffect(() => {
    if (!isSettlement && recipient_payment_system !== `paykassma` && ex_rate !== 1) {
      change(`ex_rate`, 1);
    }
  }, [isSettlement, ex_rate]);

  useEffect(() => {
    if (!isPSPaykassma) {
      change(`commission_percent`, 0);
    }
  }, [ex_rate]);

  useEffect(() => {
    if (commissionPercent !== undefined && values?.recipient_payment_system === WalletType.PAYKASSMA && isPSPaykassma)
      change(`commission_percent`, commissionPercent);
  }, [commissionPercent, values?.recipient_payment_system]);

  useEffect(() => {
    if (recipient_payment_system === `paykassma`) {
      change(`currency_code`, `USDT`);
    }
  }, [recipient_payment_system]);

  const getSettlementCommission = useCallback(() => {
    if (debouncedValue?.id && debouncedValue?.writeoff_amount && isPSPaykassma) {
      dispatch(loadSettlementCommission({ id: debouncedValue.id, amount: debouncedValue.writeoff_amount }));
    }
  }, [debouncedValue?.id, debouncedValue?.writeoff_amount]);

  useEffect(getSettlementCommission, [getSettlementCommission]);

  const psData = useMemo(() => {
    const source = isSettlement
      ? paymentSystems
      : //TODO: КОСТЫЛЬ
        [...supportedPaymentSystems, { value: `paykassma`, text: `Paykassma` }].filter((el) => el.value && el.text);

    return source.filter((item) => item.value !== payment_system);
  }, [isSettlement, paymentSystems, supportedPaymentSystems]);

  console.log(`recipient_payment_system: `, recipient_payment_system);

  const fields = [
    {
      name: `recipient_payment_system`,
      id: `${form}_payment_system`,
      label: t(`balances.accountType`),
      type: InputType.SELECT,
      data: psData,
      required: true,
    },
    {
      name: `currency_code`,
      id: `${form}_currency_code`,
      label: t(`common.currency`),
      type: InputType.SELECT,
      data: currencies,
      required: true,
      disabled: !get(values, `recipient_payment_system`) || !isSettlement,
    },
    {
      name: `writeoff_amount`,
      label: t(`common.amount`),
      standardAmount: true,
      currency: currency_code,
      required: true,
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        setFieldsState({
          ...fieldsState,
          //@ts-ignore
          amount: { ...fieldsState.amount, touched: true, inFocus: true, inputedValue: e?.target?.value },
        });
      },
      onBlur: () => {
        setFieldsState({ ...fieldsState, amount: { ...fieldsState.amount, inFocus: false } });
        setLastTouchedField(`amount`);
      },
      disabled: !currency_code,
      description: `wallets.settlementReplenishmentAmountHint`,
    },
    {
      name: `ex_rate`,
      label: t(`wallets.exchangeRate`),
      standardAmount: true,
      required: true,
      precision: isCrypt ? 8 : 4,
      disabled: !currency_code || (!isSettlement && recipient_payment_system !== `paykassma`),
      description: `wallets.settlementReplenishmentRateHint`,
      unused: !isSettlement,
      hide: !isSettlement,
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        setFieldsState({
          ...fieldsState,
          //@ts-ignore
          exRate: { ...fieldsState.exRate, touched: true, inputedValue: e?.target?.value },
        });
      },
      onFocus: () => {
        setFieldsState({ ...fieldsState, exRate: { ...fieldsState.exRate, inFocus: true } });
      },
      onBlur: () => {
        setFieldsState({ ...fieldsState, exRate: { ...fieldsState.amount, inFocus: false } });
        setLastTouchedField(`amount`);
      },
    },
    {
      name: `commission_percent`,
      label: t(`wallets.commissionWithSuffix`),
      percent: true,
      required: true,
      disabled: !currency_code || !isPSPaykassma,
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        setFieldsState({
          ...fieldsState,
          //@ts-ignore
          commission: {
            ...fieldsState.commission,
            touched: true,
            inFocus: true,
            inputedValue: isPSPaykassma ? e?.target?.value : `0`,
          },
        });
      },
      onBlur: () => {
        setFieldsState({ ...fieldsState, commission: { ...fieldsState.commission, inFocus: false } });
      },
    },
    {
      name: `crediting`,
      label: t(`balances.toCrediting`),
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        setFieldsState({
          ...fieldsState,
          //@ts-ignore
          crediting: { ...fieldsState.crediting, touched: true, inFocus: true, inputedValue: e?.target?.value },
        });
      },
      onBlur: () => {
        setFieldsState({ ...fieldsState, crediting: { ...fieldsState.crediting, inFocus: false } });
        setLastTouchedField(`crediting`);
      },
      standardAmount: true,
    },
  ];

  return <ModalForm {...props} fields={fields} />;
};

export default reduxForm<ITransferringMoneyToSettlementBalanceForm, any>({ form: CREATE_SETTLEMENT_FORM_NAME })(
  Replenishment
);
