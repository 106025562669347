import { useState } from 'react';

import { PossibleTransactionsColumn } from 'utils/enums';
import { ITableTitleField } from 'interfaces/widgets/widgets';

const usePossibleTransactionsTableTitles = (): ITableTitleField[] => {
  const [titles] = useState([
    {
      title: `common.ID`,
      id: PossibleTransactionsColumn.ID,
      desktop: true,
      mobile: true,
      disabled: false,
    },
    {
      title: `common.type`,
      id: PossibleTransactionsColumn.TYPE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `transactions.transactionNumber`,
      id: PossibleTransactionsColumn.TRANSACTION_NUMBER,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.amount`,
      id: PossibleTransactionsColumn.AMOUNT,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.labelOrderId`,
      id: PossibleTransactionsColumn.LABEL,
      desktop: true,
      mobile: true,
    },
    {
      title: `common.createdAt`,
      id: PossibleTransactionsColumn.CREATED_AT,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.actions`,
      id: PossibleTransactionsColumn.ACTIONS,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.status`,
      id: PossibleTransactionsColumn.STATUS,
      desktop: true,
      mobile: true,
      disabled: true,
    },
  ]);

  return titles;
};

export default usePossibleTransactionsTableTitles;
