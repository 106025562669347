import React, { useCallback, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { TransactionConfirmationType } from 'utils/enums';
import { IDuplicatedTransactionModalProps } from 'interfaces/widgets/transaction/transaction';

import DuplicatedTransactionModal from 'components/widgets/transaction/confirmationModals/DuplicatedTransactionModal';

interface IProps {
  onSave: () => void;
  onCancel: () => void;
  infoType?: number;
  infoData?: Record<string, unknown> | null;
  submitFailed?: boolean;
  error?: string;
  className?: string;
  submitting?: boolean;
}

const ConfirmationModal = ({ infoType, infoData, onSave, ...props }: IProps) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const onConfirm = useCallback(() => {
    onSave();
    setHasSubmitted(true);
  }, []);

  if (infoType === TransactionConfirmationType.DUPLICATED_TRANSACTION && !isEmpty(infoData)) {
    return <DuplicatedTransactionModal {...infoData} {...props} onSave={onConfirm} hasSubmitted={hasSubmitted} />;
  }

  return null;
};

export default ConfirmationModal;
