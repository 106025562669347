import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';

import { WalletType } from 'api/translations/TranslationsApi';
import { Modal } from 'pages/constructor/DefaultTranslates/common/components/Modal';
import {
  useSaveModalCtx,
  useFormTypeCtx,
  useModifiedHintsCtx,
  usePaymentSystemsCtx,
} from 'pages/constructor/DefaultTranslates/PaymentTranslation/ctx';
import { PaymentSystemsScreen } from 'pages/constructor/DefaultTranslates/common/screens/PaymentSystemsScreen';

import './PaymentSystemList.scss';

export const PaymentSystemList: FC = () => {
  const {
    paymentSystems,
    isLoading: isLoadingPaymentSystems,
    handleSetPaymentSystem,
    currentPaymentSystem,
  } = usePaymentSystemsCtx();

  const { currentFormType, handleCurrentFormType } = useFormTypeCtx();

  const { modifiedHints } = useModifiedHintsCtx();
  const { handleToggleModal, isSaveModalOpen } = useSaveModalCtx();

  const [t] = useTranslation();

  const handleCheckSystem = (ps: WalletType) => () => {
    if (modifiedHints.length) {
      handleToggleModal(true)();

      return;
    }

    if (currentFormType) {
      handleCurrentFormType(undefined);
    }

    handleSetPaymentSystem(ps);
  };

  /**
   * isChecked
   *
   * Определяает состояние checked для ПС в списке.
   */
  const isChecked = (firstId: string, secondId?: string) => firstId === secondId;

  return (
    <PaymentSystemsScreen title={t(`common.paymentSystem`)}>
      <ul className="PaymentSystemsList__list">
        {isLoadingPaymentSystems ? (
          <Skeleton count={40} />
        ) : (
          paymentSystems.map((ps) => {
            return (
              <li
                key={ps.wallet_type}
                className={cn(`PaymentSystemsList__item`, {
                  'PaymentSystemsList__item--checked': isChecked(ps.code, currentPaymentSystem?.code),
                })}
                onClick={handleCheckSystem(ps)}
              >
                {ps.name}
              </li>
            );
          })
        )}
      </ul>
      {isSaveModalOpen && <Modal variant="save" onClose={handleToggleModal(false)} />}
    </PaymentSystemsScreen>
  );
};
