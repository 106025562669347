import { createWidgetAction, widgetActionCreators, IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import {
  UPLOAD_PAYTM_COOKIES_REQUESTED,
  RESET_PAYTM_COOKIES_UPLOADING,
  DELETE_PAYTM_COOKIES_REQUESTED,
} from 'actionTypes';

export const paytmCookiesActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.PAYTM_COOKIES,
  labels: { itemLabel: `cookie_file_list`, listLabel: `cookie_file_list` },
  itemIsUniqKey: true,
  successCreatingText: `logs.filesHaveBeenSuccessfullyUploaded`,
  uniqList: true,
  creatingForm: `cookie-files-uploading`,
});

export const uploadPaytmCookies: IWidgetActionCreators = createWidgetAction({
  widget: WidgetType.PAYTM_COOKIES,
})(UPLOAD_PAYTM_COOKIES_REQUESTED);

export const resetPaytmCookiesUnloadFiles: IWidgetActionCreators = createWidgetAction({
  widget: WidgetType.PAYTM_COOKIES,
})(RESET_PAYTM_COOKIES_UPLOADING);

export const removePaytmWalletData: IWidgetActionCreators = createWidgetAction({
  widget: WidgetType.PAYTM_COOKIES,
})(DELETE_PAYTM_COOKIES_REQUESTED);
