import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import moment, { Moment } from 'moment';
import get from 'lodash/get';
import { Dispatch } from 'redux';
import { widgetSelector, InputType } from '@kassma-team/kassma-toolkit';

import { accountBalanceHistoryList, accountsBalancesActionCreators } from 'actions/widgets/balances/accountsBalances';
import { WidgetType } from 'utils/enums';

import FormField from 'components/form/FormField';

const FORM_NAME = `current-balance`;

interface IProps extends InjectedFormProps<any> {
  onSubmit: (values: any, dispatch: Dispatch) => void;
}

interface IValues {
  date: string | null;
}

/**
 * getPastDate
 *
 * Используется для отмены ограничения выбора даты.
 * Передается в DatePicker.
 * Костыль. Прости, Господи.
 * */
const getPastDate = () => moment().subtract(100, `years`);

const CurrentBalance = ({ initialize, onSubmit, change }: IProps) => {
  const [t] = useTranslation();
  const [dateState, setDateState] = useState<string | null>(null);
  const [isToday, setToday] = useState<boolean>(true);
  const [minDate, setMinDate] = useState(getPastDate());

  const dispatch = useDispatch();

  const values: IValues = useSelector(getFormValues(FORM_NAME)) as IValues;
  const widget = useSelector(widgetSelector(WidgetType.ACCOUNTS_BALANCES));

  useEffect(() => {
    setMinDate(get(widget, `minDate`) || getPastDate());
  }, [widget]);

  useEffect(() => {
    dispatch(
      //  @ts-expect-error
      initialize({
        date: null,
      })
    );
  }, []);

  const onReset = useCallback(() => {
    initialize({ date: null });
    setDateState(null);
    dispatch(accountsBalancesActionCreators.getList());
  }, [onSubmit, values, dispatch, initialize]);

  const dateValue = useMemo(() => values?.date, [values]);

  const submitHandler = useCallback(
    (e) => {
      e.preventDefault();
      setDateState(dateValue);
      onSubmit(values, dispatch);
    },
    [onSubmit, values, dispatch]
  );

  const formClickHandler = (date: Moment | string | null) => () => {
    if (!date) return;

    change(`date`, null);
    dispatch(accountsBalancesActionCreators.getList());
  };

  return (
    <div className="d-flex flex-wrap flex-align-center align-items-baseline space-between">
      {dateState ? (
        <p className="font-weight-bold font-size-h5 mr-30">
          {t(`balances.balanceOn`)}
          {` `}
          {moment(dateState).format(`DD.MM.YYYY HH:mm`)}
        </p>
      ) : (
        <p className="font-weight-bold font-size-h5 mr-30">{t(`balances.currentBalance`)}</p>
      )}
      <div className="d-flex flex-row align-items-baseline">
        <p className="font-weight-bold font-size-h6 mr-10">{t(`balances.showBalanceOn`)}</p>
        <form onSubmit={submitHandler}>
          <span onClick={formClickHandler(values?.date)}>
            <FormField
              key="date"
              value={dateState}
              name="date"
              minDate={minDate}
              type={InputType.MUI_DATE}
              showLabel={true}
              timePicker={isToday}
              clearValueWithNoDate={false}
              onChange={(val: Moment) => setToday(moment().isSame(val, `day`))}
            />
          </span>
        </form>
        <button className="btn btn-secondary m-10" disabled={!dateValue} onClick={submitHandler}>
          {t(`balances.show`)}
        </button>
        <button className="btn btn-secondary" disabled={!dateValue} onClick={onReset}>
          {t(`common.reset`)}
        </button>
      </div>
    </div>
  );
};

export default reduxForm({
  form: FORM_NAME,
  onSubmit: (values: Record<string, any>, dispatch: Dispatch) => {
    const date = moment(get(values, `date`)).format(`YYYY-MM-DD HH:mm:ss`);
    dispatch(accountBalanceHistoryList({ date }));
  },
})(CurrentBalance as any);
