import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WalletImg, useFilterColumns } from '@kassma-team/kassma-toolkit/lib';

import { walletActionCreators } from 'actions/widgets/wallet';
import { logActionCreators } from 'actions/widgets/walletsDebugging/logs';
import { ModalType, LogsColumn, Permission } from 'utils/enums';
import { formatDate } from 'utils/formatDate';
import { ILogRowProps } from 'interfaces/widgets/logs';
import { paymentSystemLogoSelector } from '../../../../selectors/widgets/paymentSystems';
import { hasAccessSelector } from '../../../../selectors/auth';

const LogRow = ({
  desktopSize,
  error_type,
  message,
  created_at,
  wallet_id,
  wallet_type,
  wallet_identifier,
  hiddenColumns,
  category,
}: ILogRowProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const formattedCreatedAt = formatDate(created_at);

  const logo = useSelector(paymentSystemLogoSelector(wallet_type));

  const showWalletFilter = useSelector(hasAccessSelector(Permission.WALLET_LIST));
  const walletIdentifier = showWalletFilter ? (
    <button
      className="button-link"
      onClick={() =>
        dispatch(
          walletActionCreators.showModal({
            id: wallet_id,
            type: ModalType.SHOW,
          })
        )
      }
    >
      {wallet_identifier}
    </button>
  ) : (
    <div>{wallet_identifier}</div>
  );

  const columns = [
    {
      id: LogsColumn.WALLET_TYPE,
      desktop: (
        <td>
          <WalletImg title={wallet_type} src={logo} />
        </td>
      ),
      mobile: (
        <tr className="first-mobile-row">
          <td>{t(`wallets.walletType`)}</td>
          <td>
            <WalletImg title={wallet_type} src={logo} />
          </td>
        </tr>
      ),
    },
    {
      id: LogsColumn.IDENTIFIER,
      desktop: <td>{walletIdentifier}</td>,
      mobile: (
        <tr>
          <td>{t(`wallets.walletIdentifier`)}</td>
          <td>{walletIdentifier}</td>
        </tr>
      ),
    },
    {
      id: LogsColumn.ERROR_TYPE,
      desktop: (
        <td>
          <span className="text-danger">{error_type}</span>
        </td>
      ),
      mobile: (
        <tr>
          <td>{t(`logs.errorType`)}</td>
          <td>
            <span className="text-danger">{error_type}</span>
          </td>
        </tr>
      ),
    },
    {
      id: LogsColumn.CATEGORY,
      desktop: (
        <td>
          <span>{category}</span>
        </td>
      ),
      mobile: (
        <tr>
          <td>{t(`logs.category`)}</td>
          <td>
            <span>{category}</span>
          </td>
        </tr>
      ),
    },
    {
      id: LogsColumn.MESSAGE,
      desktop: (
        <td>
          <button
            className="button-link"
            onClick={() =>
              dispatch(
                logActionCreators.showModal({
                  type: ModalType.LOG_MESSAGE,
                  message,
                })
              )
            }
          >
            {message.substr(0, 255)}
          </button>
        </td>
      ),
      mobile: (
        <tr>
          <td>{t(`logs.message`)}</td>
          <td>
            <button
              className="button-link"
              onClick={() =>
                dispatch(
                  logActionCreators.showModal({
                    type: ModalType.LOG_MESSAGE,
                    message,
                  })
                )
              }
            >
              {message.substr(0, 255)}
            </button>
          </td>
        </tr>
      ),
    },
    {
      id: LogsColumn.DATE,
      desktop: <td className="logs__cell--date">{formattedCreatedAt}</td>,
      mobile: (
        <tr>
          <td>{t(`common.date`)}</td>
          <td className="logs__cell--date">{formattedCreatedAt}</td>
        </tr>
      ),
    },
  ];

  return useFilterColumns({ columns, desktopSize, hiddenColumns });
};

export default LogRow;
