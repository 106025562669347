import { useState } from 'react';

import { ReportColumn } from 'utils/enums';
import { ITableTitleField } from 'interfaces/widgets/widgets';

const useReportsTableTitles = (): ITableTitleField[] => {
  const [titles] = useState([
    {
      title: `reports.file`,
      id: ReportColumn.FILE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.status`,
      id: ReportColumn.STATUS,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.createdAt`,
      id: ReportColumn.DATE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `reports.dateCompleted`,
      id: ReportColumn.PROCESSING_DATE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.actions`,
      id: ReportColumn.ACTIONS,
      desktop: true,
      mobile: true,
      disabled: true,
    },
  ]);

  return titles;
};

export default useReportsTableTitles;
