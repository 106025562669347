import { createSelector } from 'reselect';
import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import { IRootState, ISelectData, widgetItemSelector, widgetListSelector } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import {
  IPaygigaAvailableAmountsItems,
  IPaygigaBankCodesItems,
  IWithdrawalBranchCodesItems,
  IWithdrawalCardsItems,
  IWithdrawalProvidersItems,
  IWithdrawalsApprovalDataItem,
  IWithdrawalsApprovalDataItemWallets,
  IWithdrawalsBankCodesItems,
} from 'interfaces/widgets/withdrawals';

export const withdrawalBankCodesSelector = createSelector<IRootState, IWithdrawalsBankCodesItems[], ISelectData[]>(
  widgetListSelector(WidgetType.WITHDRAWALS_BANK_CODES),
  (list) => map(list, ({ name, code }) => ({ value: code, text: name }))
);

export const paygigaBankCodesSelector = createSelector<IRootState, IPaygigaBankCodesItems[], ISelectData[]>(
  widgetListSelector(WidgetType.PAYGIGA_BANK_CODES),
  (list) => map(list, ({ bankName, bankCode }) => ({ value: bankCode, text: bankName }))
);

export const paygigaAvailableAmountSelector = createSelector<IRootState, IPaygigaAvailableAmountsItems, ISelectData[]>(
  widgetListSelector(WidgetType.PAYGIGA_AVAILABLE_AMOUNTS),
  (list) => map(list?.amounts, ({ id, amount }) => ({ value: id, text: amount.toString() }))
);

export const paygigaCurrencyCodeByIdSelector = (amountId: number) =>
  createSelector<IRootState, IPaygigaAvailableAmountsItems, string | undefined>(
    widgetListSelector(WidgetType.PAYGIGA_AVAILABLE_AMOUNTS),
    (list) => find(list?.amounts, ({ id }) => id === amountId)?.currencyCode
  );

export const paygigaAmountByIdSelector = (amountId: number) =>
  createSelector<IRootState, IPaygigaAvailableAmountsItems, number | undefined>(
    widgetListSelector(WidgetType.PAYGIGA_AVAILABLE_AMOUNTS),
    (list) => find(list?.amounts, ({ id }) => id === amountId)?.amount
  );

export const withdrawalCurrencyCodesSelector = createSelector<IRootState, string[], ISelectData[]>(
  widgetListSelector(WidgetType.WITHDRAWALS_CURRENCY_CODES),
  (list) => map(list, (currency) => ({ value: currency, text: currency }))
);

export const withdrawalBranchCodesSelector = createSelector<
  IRootState,
  IWithdrawalBranchCodesItems[],
  ISelectData[] | ISelectData
>(widgetListSelector(WidgetType.WITHDRAWALS_BRANCH_CODES), (list) => {
  if (isArray(list)) {
    return map(list, ({ name, code }) => ({ value: code, text: name }));
  }

  return list;
});

export const withdrawalPayoutMethodsSelector = createSelector<IRootState, string[], ISelectData[]>(
  widgetListSelector(WidgetType.WITHDRAWALS_PAYOUT_METHODS),
  (list) => map(list, (payoutMethod) => ({ value: payoutMethod, text: payoutMethod }))
);

export const withdrawalProvidersSelector = createSelector<
  IRootState,
  IWithdrawalProvidersItems[],
  ISelectData[] | ISelectData
>(widgetListSelector(WidgetType.WITHDRAWALS_PROVIDERS), (list) => {
  if (isArray(list)) {
    return map(list, ({ name, id }) => ({ value: id, text: name }));
  }

  return list;
});

export const withdrawalCardsSelector = createSelector<IRootState, IWithdrawalCardsItems[], ISelectData[]>(
  widgetListSelector(WidgetType.WITHDRAWALS_CARDS),
  (list) => map(list, ({ card }) => ({ value: card, text: card }))
);

export const withdrawalsWalletsOfApprovalSelector = createSelector<
  IRootState,
  IWithdrawalsApprovalDataItem[],
  IWithdrawalsApprovalDataItemWallets[]
>(widgetItemSelector(WidgetType.WITHDRAWALS_APPROVAL_DATA), (data) => get(data, `wallets`, []));

export const withdrawalsWalletsSelectionOfApprovalSelector = createSelector<
  IWithdrawalsApprovalDataItemWallets[],
  IWithdrawalsApprovalDataItemWallets[],
  ISelectData[]
>(withdrawalsWalletsOfApprovalSelector, (wallets) =>
  map(wallets, ({ id, identifier }) => ({ value: identifier, text: `${id} | ${identifier}` }))
);

export const withdrawalsCardsOfWalletOfApprovalSelector = (walletId: string) =>
  createSelector<IWithdrawalsApprovalDataItemWallets[], IWithdrawalsApprovalDataItemWallets[], ISelectData[]>(
    withdrawalsWalletsOfApprovalSelector,
    (wallets) => {
      const wallet = find(wallets, ({ id }) => id === walletId);
      const cards = get(wallet, `cards`, null);

      if (!cards) {
        return cards;
      }

      return map(cards, ({ card }) => ({ value: card.replace(/ /g, ``), text: card }));
    }
  );
