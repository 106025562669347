import { AxiosPromise } from 'axios';

import { IMoneyTransferFormProps } from 'interfaces/widgets/balances/balances';
import { BaseApi } from 'api/common/BaseApi';

export class AccountBalanceApi extends BaseApi {
  transferMoney = (data: IMoneyTransferFormProps): AxiosPromise => this.makeFetch(`transfer`, { data, method: `POST` });

  loadTransferHistory = (queryParams: Record<string, any>): AxiosPromise =>
    this.makeFetch(`transfer-history`, { queryParams });

  cancelHistoryTransaction = (id: any): AxiosPromise =>
    this.makeFetch(`transfer/cancel`, { data: { id }, method: `POST` });
}

const accountBalanceApi = new AccountBalanceApi(`account-balance`);

export default accountBalanceApi;
