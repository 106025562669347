import { useState } from 'react';

import { PaymentHintsColumn } from 'utils/enums';
import { ITableTitleField } from 'interfaces/widgets/widgets';

const usePaymentHintsTableTitles = (): ITableTitleField[] => {
  const [titles] = useState([
    {
      title: `paymentConstructor.paymentSystemTitle`,
      id: PaymentHintsColumn.NAME,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.type`,
      id: PaymentHintsColumn.TYPE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.translatesHints`,
      id: PaymentHintsColumn.FORM,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `paymentConstructor.formElement`,
      id: PaymentHintsColumn.FORM_ELEMENT,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.actions`,
      id: PaymentHintsColumn.ACTIONS,
      desktop: true,
      mobile: true,
      disabled: true,
    },
  ]);

  return titles;
};

export default usePaymentHintsTableTitles;
