import moment from 'moment';

export const formatDate = (date?: string): string => {
  if (!date) {
    return ``;
  }

  return moment(date).format(`DD.MM.YYYY HH:mm:ss`);
};

export const formatDateWithoutTime = (date?: string): string => {
  if (!date) {
    return ``;
  }

  return moment(date).format(`DD.MM.YYYY`);
};
