import { takeEvery, put, select } from 'redux-saga/effects';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { toastr } from 'react-redux-toastr';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { replace } from 'connected-react-router';
import moment from 'moment';
import { AxiosError, AxiosResponse } from 'axios';
import { IAction } from '@kassma-team/kassma-toolkit';

import refreshSaga from 'sagas/effects/refreshSaga';
import i18n from 'i18n';
import {
  DASHBOARD_DATA_REQUESTED,
  DASHBOARD_DATA_SUCCEEDED,
  DASHBOARD_CHART_DATA_REQUESTED,
  DASHBOARD_CHART_DATA_SUCCEEDED,
  SET_DASHBOARD_ASIDE_FILTERS_REQUESTED,
  SET_DASHBOARD_ASIDE_FILTERS_SUCCEEDED,
  SET_DASHBOARD_CHART_FILTERS_REQUESTED,
  SET_DASHBOARD_CHART_FILTERS_SUCCEEDED,
  CHANGE_DASHBOARD_ENTITY_TYPE,
} from 'actionTypes';
import dashboardApi from 'api/dashboard/DashboardApi';
import { fetchDashboardData, fetchDashboardChartEntityData } from 'actions/dashboard';
import { dashboardAsideFilters, dashboardChartFilters, dashboardEntityTypeSelector } from 'selectors/dashboard';
import { DashboardChartEntityType, TransactionDateType } from 'utils/enums';

import { IDashboardStateAsideFilters, IDashboardStateChartFilters } from 'interfaces/dashboard';
import { IDateRange } from 'interfaces/form';

type IFilters = IDashboardStateChartFilters & IDashboardStateAsideFilters;

function* getFilters() {
  const chartFilters: IDashboardStateChartFilters = yield select(dashboardChartFilters);
  const asideFilters: IDashboardStateAsideFilters = yield select(dashboardAsideFilters);

  interface IAdditionFilters {
    date_from?: string;
    date_to?: string;
    show_all_active_transaction_data?: number;
    stockpiling_status?: number;
  }

  const additionFilters: IAdditionFilters = {};

  if (!asideFilters?.date_from) {
    additionFilters.date_from = moment()
      .set({
        hour: 0,
        minute: 0,
        second: 0,
      })
      .subtract(6, `d`)
      .format(`YYYY-MM-DD HH:mm:ss`);
  }
  if (!asideFilters?.date_to) {
    asideFilters.date_to = moment()
      .set({
        hour: 23,
        minute: 59,
        second: 59,
      })
      .format(`YYYY-MM-DD HH:mm:ss`);
  }

  if (chartFilters) {
    additionFilters.show_all_active_transaction_data = chartFilters?.show_all_active_transaction_data ?? 1;
    additionFilters.stockpiling_status = (chartFilters?.stockpiling_status ?? 0) + 1;
  }

  const filters: IFilters = {
    ...chartFilters,
    ...asideFilters,
    ...additionFilters,
  };
  if (!filters.display_in_currency) {
    filters.display_in_currency = `USD`;
  }
  if (!filters.date_type) {
    filters.date_type = TransactionDateType.ACTIVATION;
  }

  return filters;
}

function* dashboardSaga() {
  yield put(showLoading());

  const filters: IFilters = yield getFilters();

  yield refreshSaga({
    request: () => dashboardApi.loadDashboardData(filters),
    onSuccess: function* (resp: AxiosResponse) {
      yield put({ type: DASHBOARD_DATA_SUCCEEDED, payload: omit(get(resp, `data.data`), `status`) });
    },
    onError: function* (err: AxiosError) {
      const status = get(err, `response.status`);
      if (status === 403) {
        yield put(replace(`/wallets`));
      } else {
        yield toastr.error(i18n.t(`common.error`), i18n.t(`dashboard.dashboardDataFetchingHasBeenFailed`));
      }
    },
    callErrorWhenNoPermissions: true,
    showToastrWhenNoPermissions: false,
  });

  yield put(hideLoading());
}

function* setAsideFiltersSaga({ payload }: Record<`payload`, Record<`date_range`, IDateRange>>) {
  const date_from = get(payload, `date_range.values.startDate`);
  const date_to = get(payload, `date_range.values.endDate`);

  interface IData {
    date_from?: string;
    date_to?: string;
  }
  const data: IData = omit(payload, `date_range`);
  if (date_from && date_to) {
    data.date_from = moment(date_from).format(`YYYY-MM-DD HH:mm:ss`);
    data.date_to = moment(date_to).format(`YYYY-MM-DD HH:mm:ss`);
  }
  yield put({ type: SET_DASHBOARD_ASIDE_FILTERS_SUCCEEDED, payload: data });
  yield put(fetchDashboardData());
  yield put(fetchDashboardChartEntityData());
}

function* setChartFiltersSaga({ payload }: Record<`payload`, IDashboardStateChartFilters>) {
  yield put({ type: SET_DASHBOARD_CHART_FILTERS_SUCCEEDED, payload });
  yield put(fetchDashboardData());
  yield put(fetchDashboardChartEntityData());
}

function* dashboardChartDataSaga() {
  yield put(showLoading());

  const filters: IFilters = yield getFilters();

  const entityType: number = yield select(dashboardEntityTypeSelector);

  yield refreshSaga({
    request: () => {
      if (entityType === DashboardChartEntityType.WITHDRAWAL) {
        return dashboardApi.loadDashboardWithdrawalsData(filters);
      }

      return dashboardApi.loadDashboardTransactionsData(filters);
    },
    onSuccess: function* (resp: AxiosResponse) {
      yield put({
        meta: { entityType },
        type: DASHBOARD_CHART_DATA_SUCCEEDED,
        payload: omit(get(resp, `data`), `status`),
      });
    },
    onError: function* (err: AxiosError) {
      const status = get(err, `response.status`);
      if (status === 403) {
        yield put(replace(`/wallets`));
      } else {
        yield toastr.error(i18n.t(`common.error`), i18n.t(`dashboard.dashboardDataFetchingHasBeenFailed`));
      }
    },
    callErrorWhenNoPermissions: true,
    showToastrWhenNoPermissions: false,
  });

  yield put(hideLoading());
}

function* changingDashboardEntityTypeSaga() {
  yield put({ type: SET_DASHBOARD_CHART_FILTERS_SUCCEEDED, payload: {} });
}

const dashboardSagas = [
  takeEvery<IAction>(DASHBOARD_DATA_REQUESTED, dashboardSaga),
  takeEvery<IAction>(SET_DASHBOARD_ASIDE_FILTERS_REQUESTED, setAsideFiltersSaga),
  takeEvery<IAction>(SET_DASHBOARD_CHART_FILTERS_REQUESTED, setChartFiltersSaga),
  takeEvery<IAction>(DASHBOARD_CHART_DATA_REQUESTED, dashboardChartDataSaga),
  takeEvery<IAction>(CHANGE_DASHBOARD_ENTITY_TYPE, changingDashboardEntityTypeSaga),
];

export default dashboardSagas;
