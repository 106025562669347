import { Permission } from 'utils/enums';
import includes from 'lodash/includes';

const constructorRouteTabs = (permissions: string[]) => [
  {
    path: `/payments-constructor`,
    title: `paymentConstructor.paymentSystems`,
    hasAccess: includes(permissions, Permission.MANAGE_PAYMENTS_CONSTRUCTOR),
  },
  {
    path: `/tariffs-constructor`,
    title: `paymentConstructor.tariffs`,
    hasAccess: includes(permissions, Permission.MANAGE_PAYMENTS_CONSTRUCTOR),
  },
  {
    path: `/payment-translations`,
    title: `common.translatesHints`,
    hasAccess: includes(permissions, Permission.MANAGE_PAYMENTS_CONSTRUCTOR),
  },
  {
    path: `/packages-translations`,
    title: `common.translatesPackages`,
    hasAccess: includes(permissions, Permission.MANAGE_PAYMENTS_CONSTRUCTOR),
  },
  {
    path: `/view-plugin`,
    title: `paymentConstructor.plugin`,
    hasAccess: includes(permissions, Permission.MANAGE_PAYMENTS_CONSTRUCTOR),
  },
];

export default constructorRouteTabs;
