import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { widgetListSelector, Checkbox } from '@kassma-team/kassma-toolkit';
import size from 'lodash/size';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';

import { WidgetType } from 'utils/enums';
import { IAccountBalancesItems } from 'interfaces/widgets/balances/balances';
import useTable from 'hooks/widgets/useTable';
import { accountsBalancesActionCreators } from 'actions/widgets/balances/accountsBalances';
import BalanceRow from 'components/widgets/balances/BalanceRow';
import CurrentBalance from 'pages/balances/CurrentBalance';
import { filterBalanceGroups } from './utils';
import ZeroBalancesPlaceholder from './ZeroBalancesPlaceholder';

const AccountsTab = () => {
  const balancesGroupedByPS: IAccountBalancesItems[] = useSelector(widgetListSelector(WidgetType.ACCOUNTS_BALANCES));
  const [hideZeroBalances, setZeroBalancesVisibility] = useState(true);
  const [t] = useTranslation();

  useTable({
    widget: WidgetType.ACCOUNTS_BALANCES,
    actionCreators: accountsBalancesActionCreators,
    statusBar: true,
  });

  let content;

  const filteredBalancesGroupedByPS = filterBalanceGroups(balancesGroupedByPS, hideZeroBalances);

  if (size(filteredBalancesGroupedByPS) > 0) {
    content = map(filteredBalancesGroupedByPS, (balance) => (
      <BalanceRow key={balance.payment_system} {...balance} wallets={balance.balances} />
    ));
  } else if (balancesGroupedByPS !== null) {
    content = <ZeroBalancesPlaceholder />;
  }

  return (
    <div className="block-content table-content__content">
      <div className="table-content__top-controls d-flex flex-align-center space-between">
        <CurrentBalance />
        <Checkbox
          name="hideZeroBalancesCheckbox"
          checked={hideZeroBalances}
          label={t(`balances.hideZeroBalances`)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setZeroBalancesVisibility(e.target.checked)}
        />
      </div>
      {content}
    </div>
  );
};

export default AccountsTab;
