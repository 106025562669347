import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import { IGenerateReportsForm } from 'interfaces/widgets/reports';

export class ReportApi extends BaseApi {
  generateClientBalanceChangeReport = (data: IGenerateReportsForm) =>
    this.makeFetch(`balance-history`, { method: `POST`, data });

  generateLabelHistoryReport = (data: IGenerateReportsForm): AxiosPromise =>
    this.makeFetch(`label-statistics`, { method: `POST`, data });

  generateListReport = (data: IGenerateReportsForm): AxiosPromise =>
    this.makeFetch(`transactions/list`, { method: `POST`, data });

  generateCompletedListReport = (data: IGenerateReportsForm): AxiosPromise =>
    this.makeFetch(`transactions/completed-list`, { method: `POST`, data });

  generateReport = (data: IGenerateReportsForm): AxiosPromise =>
    this.makeFetch(`transactions`, { method: `POST`, data });

  generateSettlementOperationsReport = (data: IGenerateReportsForm): AxiosPromise =>
    this.makeFetch(`settlement-operations`, { method: `POST`, data });

  generateSumReport = (data: IGenerateReportsForm): AxiosPromise =>
    this.makeFetch(`transactions/sum`, { method: `POST`, data });

  generateSumWithDetailReport = (data: IGenerateReportsForm): AxiosPromise =>
    this.makeFetch(`transactions/sum-detail`, { method: `POST`, data });

  generateCompletedSumReport = (data: IGenerateReportsForm): AxiosPromise =>
    this.makeFetch(`transactions/completed-sum`, { method: `POST`, data });

  generateCompletedSumWithDetailReport = (data: IGenerateReportsForm): AxiosPromise =>
    this.makeFetch(`transactions/completed-sum-detail`, { method: `POST`, data });

  generateWithdrawalRequestReport = (data: IGenerateReportsForm): AxiosPromise =>
    this.makeFetch(`withdrawals`, { method: `POST`, data });

  generateOperationsWithBalances = (data: IGenerateReportsForm): AxiosPromise =>
    this.makeFetch(`balance-operations`, { method: `POST`, data });

  loadReportList = (): AxiosPromise => this.makeFetch(``);

  reportsCommission = (data: IGenerateReportsForm): AxiosPromise =>
    this.makeFetch(`commission`, { method: `POST`, data });
}

const reportApi = new ReportApi(`reports`);

export default reportApi;
