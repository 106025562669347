import React, { ReactNode } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';

import FormField from 'components/form/FormField';

import './paymentView.scss';

interface IProps {
  logo: ReactNode;
  fields: Record<string, unknown>[];
  onDelete?: () => void;
}

const PaymentViewBlock = ({ logo, fields, onDelete }: IProps) => {
  return (
    <div className="position-relative mb-20">
      <div className="payment-view-block p-10 flex flex-wrap">
        <div className="col-sm-12 col-md-4 payment-view-block__logo-wrap">{logo}</div>
        <div className="col-sm-12 col-md-8">
          {map(fields, (field, key) => {
            const fieldId = field?.name || key;
            if (field?.type === `separator`) {
              return (
                <div key={fieldId as string} className="row my-10">
                  <div className="col-sm-12">
                    <span className="display-block border-bottom">{field?.label as string}</span>
                  </div>
                </div>
              );
            }
            if (get(field, `type`) === `static`) {
              return (
                <div key={fieldId as string} className="row">
                  <div className="col-sm-12 col-md-4 font-weight-bold">{field?.label as string}</div>
                  <div className="col-sm-12 col-md-8">{field?.value as string}</div>
                </div>
              );
            }

            return <FormField {...field} key={fieldId} />;
          })}
        </div>
      </div>
      {onDelete && (
        <button
          type="button"
          onClick={onDelete}
          className="payment-item__control-btn payment-item__control-btn--delete"
        >
          <i className="fa fa-close" />
        </button>
      )}
    </div>
  );
};

export default PaymentViewBlock;
