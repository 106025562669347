import axios from 'axios';
import { useState, useEffect } from 'react';
import api, { Lang } from 'api/translations/TranslationsApi';
import refreshHelper from '../../../../../utils/refreshHelper';

export type UseLanguages = {
  isLoading: boolean;
  languages: Array<Lang>;
};

/**
 * useLanguages
 *
 * Хранит данные о доступных языках
 */
export function useLanguages(): UseLanguages {
  const [isLoading, setIsLoading] = useState(false);
  const [languages, setLanguages] = useState<UseLanguages[`languages`]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await refreshHelper({
        request: api.fetchLangs,
        onSuccess: ({ data }: any) => {
          setLanguages(data.data);
        },
        onFinally: () => {
          setIsLoading(false);
        },
      });
    };

    fetchData();
  }, []);

  return {
    isLoading,
    languages,
  };
}
