import React from 'react';
import useMount from 'react-use/lib/useMount';
import { useDispatch } from 'react-redux';
import { initApp } from '@kassma-team/kassma-toolkit/lib';

const AuthChecking = () => {
  const dispatch = useDispatch();

  const config = {
    apiUrl: process.env.REACT_APP_API_URL,
    pluginUrl: process.env.REACT_APP_PAYMENT_URL,
    apiSubdomain: process.env.REACT_APP_API_SUBDOMAIN,
    apiProtocol: process.env.REACT_APP_API_PROTOCOL,
    useTheSameDomainNameForApi: process.env.REACT_APP_USE_THE_SAME_DOMAIN_NAME_FOR_API === `true`,
  };

  useMount(() => {
    dispatch(initApp(config));
  });

  return <div id="page-loader" className="show" />;
};

export default AuthChecking;
