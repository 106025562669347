import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';

export class RbacPermissionApi extends BaseApi {
  loadPermissionList = (): AxiosPromise => this.makeFetch(`list`);
}

const rbacPermissionApi = new RbacPermissionApi(`rbac/permission`);

export default rbacPermissionApi;
