import { Permission } from 'utils/enums';
import includes from 'lodash/includes';

const walletRouteTabs = (permissions: string[]) => [
  {
    path: `/wallets`,
    title: `wallets.myWallets`,
    hasAccess: includes(permissions, Permission.WALLET_LIST),
  },
  {
    path: `/disabled-wallets`,
    title: `wallets.disabledWallets`,
    hasAccess: includes(permissions, Permission.WALLETS_DISABLED),
  },
  {
    path: `/removed-wallets`,
    title: `wallets.archivedWallets`,
    hasAccess: includes(permissions, Permission.WALLETS_REMOVED),
  },
  {
    path: `/statistic-wallets`,
    title: `wallets.walletStatistic`,
    hasAccess: includes(permissions, Permission.WALLET_LIST),
  },
];

export default walletRouteTabs;
