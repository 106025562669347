import React, { MutableRefObject } from 'react';

// Это такой костыль, что мое почтение
// По какой-то причине, клик на кнопке с классом "Messages__btn"
// Который находится внутри тега <section>
// Попадает в ветку if (ref.current && !ref.current.contains(target))

export const useClickOutside = <T extends HTMLElement | null>(ref: MutableRefObject<T>, callback: () => void): void => {
  const handleClick = (e: globalThis.MouseEvent) => {
    const target = e.target as HTMLElement;

    if (ref.current && !ref.current.contains(target)) {
      target.className !== `Messages__btn` && callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener(`click`, handleClick);

    return () => {
      document.removeEventListener(`click`, handleClick);
    };
  });
};
