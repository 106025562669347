import React from 'react';
import { Dispatch } from 'redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { useTranslation } from 'react-i18next';
import {
  loginRequest,
  AuthContainer,
  LOGIN_FORM_NAME,
  InputType,
  ILoginFormData,
} from '@kassma-team/kassma-toolkit/lib';

import FormField from '../components/form/FormField';

const onSubmit = (values: ILoginFormData, dispatch: Dispatch) => {
  dispatch(loginRequest(values));
};

const Form = ({ handleSubmit, submitting, error }: InjectedFormProps<ILoginFormData>) => {
  const [t] = useTranslation();

  return (
    <AuthContainer>
      <form className="sign-account-form js-validation-signin" onSubmit={handleSubmit}>
        <div className="block block-themed block-rounded block-shadow">
          <div className="block-content">
            <FormField type={InputType.TEXT} label={t(`auth.login`)} name="name" required />
            <FormField
              name="password"
              label={t(`auth.password`)}
              autocompletePassword
              onlyLatinDigitsAndSymbols
              password
              required
            />
            {error && <span className="text-error">{error}</span>}
            <div className="form-group row mb-0">
              <div className="col-sm-6 d-sm-flex align-items-center push" />
              <div className="col-sm-6 text-sm-right push">
                <button type="submit" className="btn btn-alt-primary" disabled={submitting}>
                  <i className="si si-login mr-10" /> {t(`auth.signIn`)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </AuthContainer>
  );
};

export default reduxForm({ form: LOGIN_FORM_NAME, onSubmit })(Form);
