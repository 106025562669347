import { BaseApi } from 'api/common/BaseApi';
import { AxiosPromise } from 'axios';
import { ITransactionsFilterForm } from 'interfaces/widgets/transaction/transaction';

interface FetchParams extends ITransactionsFilterForm {
  archiving_status: `withoutArchived` | `onlyArchived`;
}

type FetchResponse = Readonly<{
  status: string;
  transactions: ReadonlyArray<{
    amount: number;
    created_at: string;
    creation_type: string;
    currency_code: string;
    merchant_order_id: string;
    direction: string;
    exchanger_identifier: string;
    exchanger_name: string;
    id: string;
    label: string;
    postback_message: string;
    postback_send: number;
    postback_status: number;
    primary_id: number;
    status: number;
    status_code: number;
    stockpiling_id: number;
    total_commission: number;
    type: number;
    updated_at: string;
    activated_at: string;
    wallet_balance: number;
    wallet_id: number;
    wallet_identifier: string;
    wallet_test_type: string;
    wallet_type: string;
    is_fake: boolean;
  }>;
}>;

class TransactionsApi extends BaseApi {
  fetch = (archiving_status: `onlyArchived` | `withoutArchived`) => (
    queryParams: FetchParams
  ): AxiosPromise<FetchResponse> => {
    if (queryParams.merchant_order_id) {
      //@ts-ignore
      queryParams.merchant_order_id = queryParams.merchant_order_id
        .split(`,`)
        .filter((order) => !!order && !/^\s+$/.test(order))
        .map((order) => order.trim());
    }

    return this.makeFetch(``, {
      queryParams,
      meta: { archiving_status },
    });
  };
}

export default new TransactionsApi(`transactions`);
