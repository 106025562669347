import { createSelector } from 'reselect';
import map from 'lodash/map';
import { IRootState, widgetListSelector, ISelectData } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { ITimezoneItems } from 'interfaces/widgets/timezone';

export const timezoneSelectionSelector = createSelector<IRootState, ITimezoneItems[], ISelectData>(
  widgetListSelector(WidgetType.TIMEZONES),
  (items) => map(items, ({ name, value }) => ({ text: name, value }))
);
