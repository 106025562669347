import React, { FC } from 'react';
import { Counter } from './Counter';
import './NotificationButton.scss';

type Props = {
  onClick?: () => void;

  children?: never;
};

export const NotificationButton: FC<Props> = ({ onClick }) => {
  return (
    <button className="NotificationButton" type="button" onClick={onClick}>
      <i className="fa fa-bell-o fa-lg" />
      <Counter className="NotificationButton__counter" />
    </button>
  );
};
