import { useState } from 'react';

import { NotAcceptedPostbacksColumn, PostbacksColumn } from 'utils/enums';
import { ITableTitleField } from 'interfaces/widgets/widgets';

const useNotAcceptedPostbackTableTitles = (): ITableTitleField[] => {
  const [titles] = useState([
    {
      title: ``,
      id: NotAcceptedPostbacksColumn.CHECKBOX,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `ID`,
      id: NotAcceptedPostbacksColumn.ID,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `postbacks.url`,
      id: NotAcceptedPostbacksColumn.URL,
      desktop: true,
      mobile: true,
    },
    {
      title: `postbacks.statusCode`,
      id: NotAcceptedPostbacksColumn.STATUS_CODE,
      desktop: true,
      mobile: true,
    },
    {
      title: `postbacks.countFailure`,
      id: NotAcceptedPostbacksColumn.COUNT_FAILURE,
      desktop: true,
      mobile: true,
    },
    {
      title: `postbacks.response`,
      id: NotAcceptedPostbacksColumn.RESPONSE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.labelOrderId`,
      id: PostbacksColumn.MERCHANT_ORDER_ID,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `wallets.walletType`,
      id: NotAcceptedPostbacksColumn.WALLET_TYPE,
      desktop: true,
      mobile: true,
    },
    {
      title: `common.status`,
      id: NotAcceptedPostbacksColumn.STATUS,
      desktop: true,
      mobile: true,
    },
    {
      title: `common.type`,
      id: NotAcceptedPostbacksColumn.TYPE,
      desktop: true,
      mobile: true,
    },
    {
      title: `common.createdAt`,
      id: NotAcceptedPostbacksColumn.CREATED_AT,
      desktop: true,
      mobile: true,
    },
    {
      title: ``,
      id: NotAcceptedPostbacksColumn.ACTIONS,
      desktop: true,
      mobile: true,
      disabled: true,
    },
  ]);

  return titles;
};

export default useNotAcceptedPostbackTableTitles;
