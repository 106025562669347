import { takeEvery, put, select } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import queryString from 'query-string';
import { getSearch, replace } from 'connected-react-router';
import get from 'lodash/get';
import { AxiosError, AxiosResponse } from 'axios';
import {
  setLang,
  AUTH_ERROR,
  AUTH_CHECK_REQUESTED,
  SET_TOKEN,
  IAction,
  AUTH_CHECK_SUCCEEDED,
  LOGIN_SUCCEEDED,
} from '@kassma-team/kassma-toolkit/lib';

import refreshSaga from 'sagas/effects/refreshSaga';
import { CHANGE_PASSWORD_REQUESTED } from 'actionTypes';
import { currencyActionCreators } from 'actions/widgets/currency';
import i18n from 'i18n';
import getSystemLang from 'utils/getSystemLang';
import { countriesActionCreators } from 'actions/widgets/countries';
import {
  paymentSystemsActionCreators,
  manualPSActionCreators,
  transactionPSActionCreators,
} from 'actions/widgets/paymentSystem';
import { IChangePasswordData } from 'interfaces/auth';
import authApi from 'api/auth/AuthApi';
import userApi from 'api/auth/UserApi';
import getAuthSagas from './effects/getAuthSagas';
import hiddenColumnsApi from '../api/hidden_columns/HiddenColumnsApi';
import { GET_HIDDEN_COLUMNS, getHiddenColumnsAction } from '../actions/widgets/hiddenColumns';

const REQUIRED_CONFIG_KEYS = [`apiUrl`, `pluginUrl`];

function* fetchDataOnLogin() {
  yield put(currencyActionCreators.getList());
  yield put(countriesActionCreators.getList());
  yield put(paymentSystemsActionCreators.getList());
  yield put(transactionPSActionCreators.getList());
  yield put(manualPSActionCreators.getList());
}

function* onLoggedIn() {
  yield fetchDataOnLogin();
}

function* loginBySuperAdmin() {
  const search: string = yield select(getSearch);
  const parsedSearch = queryString.parse(search);
  const sa_token = get(parsedSearch, `sa_token`);

  if (!sa_token) {
    return null;
  }

  let token;

  localStorage.setItem(`from_sa`, `true`);

  try {
    const resp: AxiosResponse = yield authApi.exchange({ token: sa_token });
    const { token, refresh_token } = resp.data.data;
    yield put(replace({ search: `` }));
    localStorage.setItem(`token`, token);
    localStorage.setItem(`refresh_token`, refresh_token);

    return { token, refresh_token };
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return token;
}

function* authCheckSaga(login?: boolean) {
  const lang = getSystemLang(localStorage.getItem(`lang`));
  i18n.changeLanguage(lang);
  yield put(setLang(lang));

  const saTokenData: Record<string, string> | undefined = yield loginBySuperAdmin();

  const token = saTokenData?.token || localStorage.getItem(`token`);
  if (token) {
    yield put({ type: SET_TOKEN, payload: token });
    yield refreshSaga({
      request: () => userApi.checkAuth(),
      onSuccess: function* (resp: AxiosResponse) {
        const { data } = resp;
        if (!data || !data.user) {
          throw new Error(`Invalid response`);
        }
        yield put(getHiddenColumnsAction(data.user));
        yield put({ type: AUTH_CHECK_SUCCEEDED, payload: { user: data.user } });
        // yield put({
        //   type: AUTH_CHECK_SUCCEEDED,
        //   payload: {
        //     user: {
        //       name: data.user.name,
        //       roles: [
        //         `default-roles-paykassma-release`,
        //         `admin.wallet.private-information-view`,
        //         `sa.payment-system.list`,
        //         `admin.account-balances.settlements.view`,
        //         `admin.wallet.archive`,
        //         `sa.settings.set-timezone`,
        //         `admin.settings.system-settings.update`,
        //         `default-roles-local`,
        //         `admin.proxy.check`,
        //         `sa.counterparty.manage`,
        //         `admin.transaction.approve-possible-transactions-manual-ps`,
        //         `sa.statistics.view`,
        //         `admin.transaction.suspects-transactions.delete`,
        //         `admin.reports.client-balance-history`,
        //         `admin.account-balances.settlement-withdrawal.create`,
        //         `admin.wallet.update`,
        //         `admin.withdrawals.manage`,
        //         `admin.transaction.list-only-with-transaction-id`,
        //         `admin.payment-systems.view-all-payment-systems`,
        //         `uma_authorization`,
        //         `sa.counterparty.create-mailing`,
        //         `admin.transaction.commission-view`,
        //         `admin.wallet.list`,
        //         `sa.counterparty.list`,
        //         `admin.report.withdrawal-list`,
        //         `admin.proxy.delete`,
        //         `admin.reports.autoreports.manage`,
        //         `admin.account-balances.manage`,
        //         `private.settings.view`,
        //         `sa.counterparty.balance`,
        //         `admin.transaction.forced-transaction.create`,
        //         `admin.transaction.archive-transactions-view`,
        //         `admin.transaction.possible_transaction.delete`,
        //         `sa.settings.get-timezone`,
        //         `admin.settings.payment-plugin`,
        //         `admin.postback.sending`,
        //         `admin.wallet.delete`,
        //         `sa.counterparty.view-mailing`,
        //         `admin.manual-payment-systems.manage`,
        //         `admin.transaction.inner`,
        //         `admin.withdrawals.view`,
        //         `admin.settings.system-settings.view`,
        //         `access_all_clients`,
        //         `admin.withdrawals.create`,
        //         `admin.account-balances.view`,
        //         `admin.transaction.private-information.view`,
        //         `admin.payment-systems.manage`,
        //         `admin.report.label-history`,
        //         // `admin.transaction.archive-transaction-manager`,
        //         `admin.wallet.hard-delete`,
        //         `sa.payment-system.settings`,
        //         `admin.transaction.set-fake`,
        //         `admin.wallets-load`,
        //         `admin.transaction.view-types`,
        //         `admin.wallet.set-active`,
        //         `sa.currencies.manage`,
        //         `admin.transactions.load`,
        //         `admin.report.other-users-reports-view`,
        //         `admin.proxy.update`,
        //         `admin.media.settings`,
        //         `admin.report.transactions`,
        //         `offline_access`,
        //         `admin.disabled-wallets.list`,
        //         `private.settings.update`,
        //         `admin.transaction.suspects-transactions-approve`,
        //         `admin.stockpiling.list`,
        //         `admin.logs.list`,
        //         // `admin.transaction.list`,
        //         `admin.wallet.create`,
        //         `admin.postbacks.view`,
        //         `admin.settings.timezone.view`,
        //         `admin.change-log.view`,
        //         `admin.report.settlement-list`,
        //         `admin.transaction.debug-transaction.create`,
        //         `admin.proxy.list`,
        //         `admin.settings.timezone.update`,
        //         `admin.reports.wallet-provider-code-view`,
        //         `admin.wallet.balance-view`,
        //         `admin.settings.payment-plugin.client`,
        //         `sa.hidden.settings.update`,
        //         `admin.transaction.possible-transaction-manager`,
        //         `admin.transaction.suspects_transactions_view`,
        //         `admin.report.commission`,
        //         `admin.proxy.create`,
        //         `admin.dashboard.all`,
        //       ],
        //     },
        //   },
        // });

        if (login) {
          yield put({ type: LOGIN_SUCCEEDED, payload: {} });
        }
        yield onLoggedIn();
      },
      onError: function* (e: AxiosError) {
        yield put({ type: AUTH_ERROR, payload: e.message });
      },
    });
  } else {
    yield put({ type: AUTH_ERROR, payload: `No token` });
  }
}

function* getHiddenColumns({ payload }: Record<`payload`, any>) {
  yield refreshSaga({
    request: () => hiddenColumnsApi.getHiddenColumns(),
    onSuccess: function* (resp: AxiosResponse) {
      const { data } = resp;
      if (!data) {
        throw new Error(`Invalid response`);
      }
      const items = data.data.items;
      const columns: Record<string, string[]> = {};
      items.forEach(({ table, column }: any) => {
        if (!(table in columns)) {
          columns[table] = [];
        }
        columns[table].push(column);
      });
      yield put({ type: AUTH_CHECK_SUCCEEDED, payload: { user: { ...payload, hidden_columns: columns } } });
      // yield put({
      //   type: AUTH_CHECK_SUCCEEDED,
      //   payload: {
      //     user: {
      //       ...payload,
      //       roles: [
      //         `default-roles-paykassma-release`,
      //         `admin.wallet.private-information-view`,
      //         `sa.payment-system.list`,
      //         `admin.account-balances.settlements.view`,
      //         `admin.wallet.archive`,
      //         `sa.settings.set-timezone`,
      //         `admin.settings.system-settings.update`,
      //         `default-roles-local`,
      //         `admin.proxy.check`,
      //         `sa.counterparty.manage`,
      //         `admin.transaction.approve-possible-transactions-manual-ps`,
      //         `sa.statistics.view`,
      //         `admin.transaction.suspects-transactions.delete`,
      //         `admin.reports.client-balance-history`,
      //         `admin.account-balances.settlement-withdrawal.create`,
      //         `admin.wallet.update`,
      //         `admin.withdrawals.manage`,
      //         `admin.transaction.list-only-with-transaction-id`,
      //         `admin.payment-systems.view-all-payment-systems`,
      //         `uma_authorization`,
      //         `sa.counterparty.create-mailing`,
      //         `admin.transaction.commission-view`,
      //         `admin.wallet.list`,
      //         `sa.counterparty.list`,
      //         `admin.report.withdrawal-list`,
      //         `admin.proxy.delete`,
      //         `admin.reports.autoreports.manage`,
      //         `admin.account-balances.manage`,
      //         `private.settings.view`,
      //         `sa.counterparty.balance`,
      //         `admin.transaction.forced-transaction.create`,
      //         `admin.transaction.archive-transactions-view`,
      //         `admin.transaction.possible_transaction.delete`,
      //         `sa.settings.get-timezone`,
      //         `admin.settings.payment-plugin`,
      //         `admin.postback.sending`,
      //         `admin.wallet.delete`,
      //         `sa.counterparty.view-mailing`,
      //         `admin.manual-payment-systems.manage`,
      //         `admin.transaction.inner`,
      //         `admin.withdrawals.view`,
      //         `admin.settings.system-settings.view`,
      //         `access_all_clients`,
      //         `admin.withdrawals.create`,
      //         `admin.account-balances.view`,
      //         `admin.transaction.private-information.view`,
      //         `admin.payment-systems.manage`,
      //         `admin.report.label-history`,
      //         // `admin.transaction.archive-transaction-manager`,
      //         `admin.wallet.hard-delete`,
      //         `sa.payment-system.settings`,
      //         `admin.transaction.set-fake`,
      //         `admin.wallets-load`,
      //         `admin.transaction.view-types`,
      //         `admin.wallet.set-active`,
      //         `sa.currencies.manage`,
      //         `admin.transactions.load`,
      //         `admin.report.other-users-reports-view`,
      //         `admin.proxy.update`,
      //         `admin.media.settings`,
      //         `admin.report.transactions`,
      //         `offline_access`,
      //         `admin.disabled-wallets.list`,
      //         `private.settings.update`,
      //         `admin.transaction.suspects-transactions-approve`,
      //         `admin.stockpiling.list`,
      //         `admin.logs.list`,
      //         // `admin.transaction.list`,
      //         `admin.wallet.create`,
      //         `admin.postbacks.view`,
      //         `admin.settings.timezone.view`,
      //         `admin.change-log.view`,
      //         `admin.report.settlement-list`,
      //         `admin.transaction.debug-transaction.create`,
      //         `admin.proxy.list`,
      //         `admin.settings.timezone.update`,
      //         `admin.reports.wallet-provider-code-view`,
      //         `admin.wallet.balance-view`,
      //         `admin.settings.payment-plugin.client`,
      //         `sa.hidden.settings.update`,
      //         `admin.transaction.possible-transaction-manager`,
      //         `admin.transaction.suspects_transactions_view`,
      //         `admin.report.commission`,
      //         `admin.proxy.create`,
      //         `admin.dashboard.all`,
      //       ],

      //       hidden_columns: columns,
      //     },
      //   },
      // });
    },
  });
}

function* changePasswordSaga({ payload }: Record<`payload`, IChangePasswordData>) {
  yield refreshSaga({
    request: () => userApi.changePassword(payload),
    onSuccess: function () {
      toastr.success(i18n.t(`common.success`), i18n.t(`settings.passwordHasBeenSuccessfullyUpdated`));
    },
    onError: function () {
      toastr.error(i18n.t(`common.error`), i18n.t(`settings.passwordUpdatingHasBeenFailed`));
    },
  });
}

const authSagas: any = [
  ...getAuthSagas({
    fetchDataOnLogin,
    ...authApi,
    hideColumn: hiddenColumnsApi.saveHiddenColumns,
    toastr,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    requiredKeys: REQUIRED_CONFIG_KEYS,
  }),
  takeEvery<IAction>(GET_HIDDEN_COLUMNS, getHiddenColumns),
  takeEvery<IAction>(AUTH_CHECK_REQUESTED, authCheckSaga),
  takeEvery<IAction>(CHANGE_PASSWORD_REQUESTED, changePasswordSaga),
];

export default authSagas;
