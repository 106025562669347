import React, { useMemo } from 'react';
import {
  WidgetTable as KassmaWidgetTable,
  hiddenColumnsSelector,
  PAGINATOR_FORM_NAME,
  IWidgetActionCreators,
} from '@kassma-team/kassma-toolkit/lib';
import { useDispatch, useSelector } from 'react-redux';
import { getLocation, RouterLocation, RouterRootState } from 'connected-react-router';
import { DecoratedComponentClass, getFormValues } from 'redux-form';
import { Dispatch } from 'redux';

import useSearchParams from 'hooks/useSearchParams';

import FormField from 'components/form/FormField';
import ReduxFormPaginator from 'components/widgets/ReduxFormPaginator';

interface IProps {
  widget: string;
  data: any[];
  titles: ({ title: string; className?: string } | boolean)[];
  listMapping: (item: any, key: number) => JSX.Element;
  paginationLoading?: boolean;
  lastPage?: number;
  page?: number;
  perPage?: number;
  actionCreators?: IWidgetActionCreators;
  loading?: boolean;
  withPagination?: boolean;
  showCellFilters?: boolean;
  hiddenColumns?: string[];
  dispatch?: Dispatch;
  location?: RouterLocation<unknown>;
  paginatorValues?: Record<string, number>;
  search?: Record<string, string | string[] | null>;
  FormField?: React.ComponentType<any>;
  ReduxFormPaginator?: DecoratedComponentClass<any, any>;
  error?: string;
}

const WidgetTable: React.FC<IProps> = ({ widget, ...props }) => {
  const columnsSelector = useMemo(() => hiddenColumnsSelector(widget), [widget]);
  const hiddenColumns: string[] = useSelector(columnsSelector);

  const dispatch = useDispatch();

  const location = useSelector(getLocation as (state: RouterRootState) => RouterLocation<unknown>);
  const paginatorValues = useSelector(getFormValues(PAGINATOR_FORM_NAME)) || {};

  const search = useSearchParams();

  return (
    <KassmaWidgetTable
      {...props}
      widget={widget}
      hiddenColumns={hiddenColumns}
      dispatch={dispatch}
      location={location}
      paginatorValues={paginatorValues}
      search={search}
      FormField={FormField}
      ReduxFormPaginator={ReduxFormPaginator}
    />
  );
};

export default WidgetTable;
