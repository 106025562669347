import { Dispatch, SetStateAction, useState } from 'react';

export type UseFormWasSubmitted = {
  isFormWasSubmitted: boolean;
  setFormSubmitted: Dispatch<SetStateAction<boolean>>;
};

export function useFormWasSubmitted(): UseFormWasSubmitted {
  const [isFormWasSubmitted, setFormSubmitted] = useState(false);

  return {
    isFormWasSubmitted,
    setFormSubmitted,
  };
}
