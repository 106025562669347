import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { widgetModalSelector } from '@kassma-team/kassma-toolkit/lib';

import { confirmRejectionOfWithdrawalRequest, withdrawalsActionCreators } from 'actions/widgets/withdrawals';
import { WidgetType } from 'utils/enums';

import ConfirmationModal from 'components/modals/ConfirmationModal';

const ConfirmRejectionModal = () => {
  const modal = useSelector(widgetModalSelector(WidgetType.WITHDRAWALS));

  const dispatch = useDispatch();

  const [id] = useState(get(modal, `id`));
  const [withdrawal_id] = useState(get(modal, `withdrawal_id`));

  const onConfirm = useCallback(() => {
    dispatch(confirmRejectionOfWithdrawalRequest({ id, withdrawal_id }));
  }, []);

  return (
    <ConfirmationModal
      title="withdrawals.withdrawalRejection"
      actionCreators={withdrawalsActionCreators}
      content="withdrawals.doYouConfirmThatTheWithdrawalHasNotBeenMade"
      confirmText="common.confirm"
      confirmClassName="btn-alt-success"
      onConfirm={onConfirm}
    />
  );
};

export default ConfirmRejectionModal;
