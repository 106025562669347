import { put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { AxiosError, AxiosResponse } from 'axios';
import {
  validateForm,
  WIDGET_RESET_MODAL,
  WIDGET_SET_SUBMITTING_STATUS,
  IWidgetsMeta,
  IAction,
} from '@kassma-team/kassma-toolkit/lib';

import { getErrorMessage } from 'utils';
import refreshSaga from 'sagas/effects/refreshSaga';
import {
  LOAD_SETTLEMENT_COMMISSION,
  TARIFFS_REQUESTED,
  TARIFFS_SUCCEEDED,
  UPDATE_TARIFFS_REQUESTED,
} from 'actionTypes';
import i18n from 'i18n';
import { TARIFFS_FORM_NAME } from 'utils/constants';
import { ITariffsData } from 'interfaces/widgets/constructor/paymentsConstructor';
import tariffApi from 'api/constructors/TariffApi';
import { tariffsMeta } from '../../actions/widgets/tariffs';
import commissionApi, { ISettlementCommission } from '../../api/commission/CommissionApi';

interface ILoadTariffsProps {
  payload: string;
  meta: IWidgetsMeta;
}

interface ILoadSettlementCommissionProps {
  payload: ISettlementCommission;
  meta: IWidgetsMeta;
}

function* loadTariffsSaga({ payload: walletType, meta }: ILoadTariffsProps) {
  yield refreshSaga({
    request: () => tariffApi.loadTariffs(walletType),
    onSuccess: function* (resp: AxiosResponse) {
      yield put({ type: TARIFFS_SUCCEEDED, meta, payload: resp.data });
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

interface IUpdateTariffsProps {
  payload: ITariffsData[];
  meta: IWidgetsMeta;
}

function* updateTariffsSaga({ payload, meta }: IUpdateTariffsProps) {
  yield put({ meta, type: WIDGET_SET_SUBMITTING_STATUS, payload: true });
  const valid: boolean = yield validateForm({ form: TARIFFS_FORM_NAME, meta });
  if (!valid) {
    return;
  }

  yield refreshSaga({
    request: () => tariffApi.updateTariffs(meta.walletType, payload),
    onSuccess: function* () {
      toastr.success(i18n.t(`common.success`), i18n.t(`paymentConstructor.depositsHaveBeenSuccessfullyUpdated`));
      yield put({ type: WIDGET_RESET_MODAL, meta });
    },
    onError: (e: AxiosError) => {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
    onFinally: function* () {
      yield put({ meta, type: WIDGET_SET_SUBMITTING_STATUS, payload: false });
    },
  });
}

function* loadSettlementCommission({ payload, meta }: ILoadSettlementCommissionProps) {
  yield refreshSaga({
    request: () => commissionApi.loadSettlementCommission(payload),
    onSuccess: function* (resp: AxiosResponse) {
      yield put(tariffsMeta.setAnyData({ ...resp.data.data }));
    },
    onError: (e: AxiosError) => {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
    onFinally: function* () {
      yield put({ meta, type: WIDGET_SET_SUBMITTING_STATUS, payload: false });
    },
  });
}

const tariffsSagas = [
  takeEvery<IAction>(TARIFFS_REQUESTED, loadTariffsSaga),
  takeEvery<IAction>(UPDATE_TARIFFS_REQUESTED, updateTariffsSaga),
  takeEvery<IAction>(LOAD_SETTLEMENT_COMMISSION, loadSettlementCommission),
];

export default tariffsSagas;
