import React, { FC } from 'react';
import { Portal } from 'react-portal';
import './Modal.scss';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';

type ConfirmModal = {
  variant: `confirm`;

  onClose: () => void;
  onApply: () => void;

  children?: never;
};

type SaveModal = {
  variant: `save`;
  onClose: () => void;

  onApply?: never;

  children?: never;
};

type Props = ConfirmModal | SaveModal;

const getContent = (variant: Props[`variant`]) => {
  const content: Record<Props[`variant`], string> = {
    save: i18n.t(`common.unsaveChanges`),
    confirm: i18n.t(`common.confirmDeleteTranslation`),
  };

  return content[variant];
};

const getHeader = (variant: Props[`variant`]) => {
  const content: Record<Props[`variant`], string> = {
    save: ``,
    confirm: i18n.t(`common.translationDelete`),
  };

  return content[variant];
};

export const Modal: FC<Props> = ({ onClose, onApply, variant }) => {
  const [t] = useTranslation();

  return (
    <Portal node={document && document.getElementById(`page-container`)}>
      <div className="Modal">
        <div className="Modal__overlay">
          <div className="Modal__body">
            <div className="Modal__header">
              <p className="Modal__header_title">{getHeader(variant)}</p>
              <button onClick={onClose} type="button">
                <i className="si si-close" />
              </button>
            </div>
            <p className="Modal__main">{getContent(variant)}</p>
            <div className="Modal__footer">
              {variant === `confirm` ? (
                <>
                  <button onClick={onClose} className="cancel-btn" type="button">
                    {t(`common.cancel`)}
                  </button>
                  <button onClick={onApply} className="cancel-btn" type="button">
                    {t(`common.delete`)}
                  </button>
                </>
              ) : (
                <button onClick={onClose} className="cancel-btn" type="button">
                  {t(`common.closeWindow`)}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};
