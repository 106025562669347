import React, { useMemo, useState } from 'react';
import isNumber from 'lodash/isNumber';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WalletImg, useFilterColumns } from '@kassma-team/kassma-toolkit/lib';

import { WalletsLoadColumn, WalletsLoadObject, WalletsLoadType, WalletType } from 'utils/enums';
import useFullAmount from 'hooks/useFullAmount';
import useSearchParams from 'hooks/useSearchParams';
import { ICurrencyTransactionSum, IWalletsLoadRowProps } from 'interfaces/widgets/wallets/wallet';
import { paymentSystemLogoSelector } from '../../../selectors/widgets/paymentSystems';

interface ICurrencyTransactionSumProps {
  amount: string;
  currencyCode?: string;
}

const CurrencyTransactionSum = ({ amount, currencyCode }: ICurrencyTransactionSumProps) => {
  const fullAmount = useFullAmount({
    amount,
    currencyCode,
    showSymbol: false,
  });

  return (
    <p>
      {currencyCode}: {fullAmount}
    </p>
  );
};

const getCurrencyTransactionSum = (list: ICurrencyTransactionSum[]) => {
  return list?.map(({ currency_code, amount }) => {
    return <CurrencyTransactionSum key={currency_code} amount={amount as string} currencyCode={currency_code} />;
  });
};

const WalletsLoadRow = ({
  id,
  type,
  identifier,
  transactions_count,
  transactions_usd_sum,
  currency_transaction_sum,
  desktopSize,
  onWalletClick,
  dataTitle,
  test_wallet_type,
  hiddenColumns,
}: IWalletsLoadRowProps) => {
  const dispatch = useDispatch();

  const logo = useSelector(paymentSystemLogoSelector(type));
  const walletLogoSource = useMemo(
    () => (type === WalletType.TEST && test_wallet_type?.logo ? test_wallet_type.logo : logo),
    [type, test_wallet_type, logo]
  );

  const [t] = useTranslation();

  const search = useSearchParams();
  const { load_object, load_type } = search || {};

  const fullTransactionUsdSum = useFullAmount({
    amount: transactions_usd_sum,
    showSymbol: false,
  });

  const loadTypeToValue: Record<string, string> = {
    transactions_sum: fullTransactionUsdSum,
    transactions_count: isNumber(transactions_count) ? transactions_count.toLocaleString(`ru`) : ``,
  };

  const walletIdentifier = onWalletClick ? (
    <button className="button-link" onClick={() => dispatch(onWalletClick())}>
      {identifier}
    </button>
  ) : (
    identifier
  );

  const walletTypeImage = (
    <WalletImg title={type === WalletType.TEST ? test_wallet_type?.name || type : type} src={walletLogoSource} />
  );

  const columns = [
    {
      id: WalletsLoadColumn.ID,
      desktop: <td>{id}</td>,
      mobile: (
        <tr className="first-mobile-row">
          <td>ID</td>
          <td>{id}</td>
        </tr>
      ),
      hide: load_object === WalletsLoadObject.PAYMENT_SYSTEMS,
    },
    {
      id: WalletsLoadColumn.WALLET_TYPE,
      desktop: <td>{walletTypeImage}</td>,
      mobile: (
        <tr>
          <td>{t(`common.type`)}</td>
          <td>{walletTypeImage}</td>
        </tr>
      ),
    },
    {
      id: WalletsLoadColumn.WALLET_IDENTIFIER,
      desktop: <td>{walletIdentifier}</td>,
      mobile: (
        <tr>
          <td>{t(`wallets.wallet`)}</td>
          <td>{identifier}</td>
        </tr>
      ),
      hide: load_object === WalletsLoadObject.PAYMENT_SYSTEMS,
    },
    {
      id: WalletsLoadColumn.AMOUNT,
      desktop: <td>{getCurrencyTransactionSum(currency_transaction_sum)}</td>,
      mobile: (
        <tr>
          <td>{t(`wallets.amountOfIngoingTransactions`)}</td>
          <td>{getCurrencyTransactionSum(currency_transaction_sum)}</td>
        </tr>
      ),
      hide: load_type !== WalletsLoadType.TRANSACTIONS_SUM,
    },
    {
      id: WalletsLoadColumn.LOAD_TYPE,
      desktop: <td>{loadTypeToValue[load_type as string]}</td>,
      mobile: (
        <tr>
          <td>{t(dataTitle as string)}</td>
          <td>{loadTypeToValue[load_type as string]}</td>
        </tr>
      ),
    },
  ];

  return useFilterColumns({ columns, desktopSize, hiddenColumns });
};

export default WalletsLoadRow;
