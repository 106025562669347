import { createSelector } from 'reselect';
import map from 'lodash/map';
import { widgetListSelector, IRootState, ISelectData } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { IChangeLogObjectItems } from 'interfaces/widgets/changeLogObject';

export const changeLogObjectsSelectionSelector = createSelector<IRootState, IChangeLogObjectItems[], ISelectData[]>(
  widgetListSelector(WidgetType.CHANGE_LOG_OBJECTS),
  (items) => map(items, ({ id, name }) => ({ value: id, text: name }))
);
