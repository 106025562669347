import React, { FC, ReactNode } from 'react';

import './FormTypesScreen.scss';

type Props = {
  title: string;
  headerBtn: ReactNode;
};

/**
 * Контейнер для списка форм.
 */
export const FormTypesScreen: FC<Props> = ({ title, headerBtn, children }) => {
  return (
    <section className="FormTypesScreen">
      <div className="FormTypes__header">
        <h2 className="FormTypes__title">{title}</h2>
        {headerBtn}
      </div>

      {children}
    </section>
  );
};
