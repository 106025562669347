import React from 'react';

import { WidgetType } from 'utils/enums';
import { disabledWalletActionCreators } from 'actions/widgets/wallet';

import RemovingModal from 'components/modals/RemovingModal';

const DisabledWalletRemoving = () => {
  return (
    <RemovingModal
      widget={WidgetType.DISABLED_WALLETS}
      actionCreators={disabledWalletActionCreators}
      title="wallets.walletArchiving"
      content="wallets.doYouReallyWantToArchiveThisWallet"
      confirmText="common.archive"
    />
  );
};

export default DisabledWalletRemoving;
