import React, { useCallback, useEffect, useState } from 'react';
import useMount from 'react-use/lib/useMount';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import join from 'lodash/join';
import uniq from 'lodash/uniq';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import useUnmount from 'react-use/lib/useUnmount';
import {
  ErrorBlock,
  ModalFormSubmit,
  widgetModalSelector,
  widgetSubmittingSelector,
  FieldType,
  InputType,
  Size,
} from '@kassma-team/kassma-toolkit/lib';

import {
  getPaymentTranslationsData,
  getPaymentViewData,
  paymentsConstructorActionCreators,
  resetPaymentViewData,
  updatePaymentViewData,
} from 'actions/widgets/constructors/paymentsConstructor';
import { WidgetType, PaymentSystemType } from 'utils/enums';
import { PAYMENT_VIEW_FORM_NAME } from 'utils/constants';
import {
  paymentViewDataSelector,
  requisiteTypeHintsSelector,
  paymentTypeHintsSelector,
  paymentHintNameByIdSelector,
  requisiteTypeFieldsSelector,
  paymentFieldNameByIdSelector,
  paymentTypeFieldsSelector,
} from 'selectors/widgets/constructor/paymentsConstructor';
import { paymentItemSelector } from 'selectors/widgets/paymentSystems';
import useFormSubmissionHandler from 'hooks/widgets/useFormSubmissionHandler';

import PaymentViewBlock from 'components/widgets/constructors/payments/paymentView/PaymentViewBlock';
import FormField from 'components/form/FormField';
import ModalElement from 'components/modals/ModalElement';

const fileAcceptedTypes = [`.png`, `.jpeg`, `.jpg`, `.svg`];
const maxSize = 1024 * 1024 * 100;

const PaymentViewModal = ({ form, initialize, submitFailed, error, change }: InjectedFormProps) => {
  const [t] = useTranslation();

  const [deleted, setDeleted] = useState<number[]>([]);

  const modal = useSelector(widgetModalSelector(WidgetType.PAYMENTS_CONSTRUCTOR));
  const [walletType] = useState(get(modal, `walletType`));
  const paymentSystem = useSelector(paymentItemSelector(walletType));
  const [payment_type] = useState(get(paymentSystem, `payment_type`));
  const showRequisites = [PaymentSystemType.P2P, PaymentSystemType.MANUAL].includes(payment_type || ``);

  const onSubmit = useCallback(
    (values, dispatch) => {
      dispatch(updatePaymentViewData(walletType)({ ...values, deleted }));
    },
    [walletType, deleted]
  );

  const handleSubmit = useFormSubmissionHandler({ form, onSubmit });

  const dispatch = useDispatch();

  useMount(() => {
    dispatch(getPaymentTranslationsData(walletType));
    dispatch(getPaymentViewData(walletType));
  });

  useUnmount(() => {
    dispatch(resetPaymentViewData());
  });

  const data = useSelector(paymentViewDataSelector);
  const currencies = get(data, `default.currencies`);

  const requisiteHints = useSelector(requisiteTypeHintsSelector);
  const paymentHints = useSelector(paymentTypeHintsSelector);
  const requisiteFields = useSelector(requisiteTypeFieldsSelector);
  const paymentFields = useSelector(paymentTypeFieldsSelector);
  const defaultRequisiteHint = useSelector(paymentHintNameByIdSelector(get(data, `default.hint_requisit`)));
  const defaultPaymentHint = useSelector(paymentHintNameByIdSelector(get(data, `default.hint_payment`)));
  const defaultRequisiteField = useSelector(paymentFieldNameByIdSelector(get(data, `default.field_requisit`)));
  const defaultPaymentField = useSelector(paymentFieldNameByIdSelector(get(data, `default.field_payment`)));
  const submitting = useSelector(widgetSubmittingSelector(WidgetType.PAYMENTS_CONSTRUCTOR));

  useEffect(() => {
    if (data) {
      const targets = get(data, `targets`);
      const is_default_enabled = get(data, `default.is_enabled`);
      const show_limits = get(data, `default.show_limits`);
      initialize({ targets, is_default_enabled, show_limits });
    }
  }, [data, initialize]);

  const renderTargets = useCallback(
    ({ fields }) => (
      <div className="mb-15">
        <div className="flex space-between align-items-center mb-20">
          <span className="font-weight-bold">{t(`paymentConstructor.targeting`)}</span>
          <button className="btn btn-alt-success" type="button" onClick={() => fields.push({ show_limits: true })}>
            {t(`common.add`)}
          </button>
        </div>
        {fields.map((field: string, index: number) => {
          const logo = (
            <FormField
              arrayField
              formGroup={false}
              name={`${field}.logo`}
              type={InputType.DROPZONE}
              required
              maxSize={maxSize}
              fileAcceptedTypes={fileAcceptedTypes}
              deleteFileHandler={() => {
                change(`${field}.logo`, null);
              }}
            />
          );

          const formFields = [
            {
              type: `separator`,
              label: t(`paymentConstructor.general`),
            },
            {
              arrayField: true,
              required: true,
              name: `${field}.title`,
              label: t(`paymentConstructor.name`),
            },
            {
              arrayField: true,
              required: true,
              name: `${field}.currencies`,
              type: InputType.MULTI_SELECT,
              data: currencies.map((el: { currency: string }) => el.currency || ``),
              label: t(`paymentConstructor.currencies`),
              withModalScroll: true,
            },
            {
              type: `separator`,
              label: t(`paymentConstructor.paymentMakingForm`),
            },
            {
              arrayField: true,
              name: `${field}.hint_requisites`,
              type: InputType.SELECT,
              data: requisiteHints,
              label: t(`paymentConstructor.hint`),
              withModalScroll: true,
            },
            {
              type: InputType.CHECKBOX,
              label: t(`paymentConstructor.limitHint`),
              name: `${field}.show_limits`,
              isModalField: true,
            },
            {
              arrayField: true,
              required: true,
              name: `${field}.field_requisites`,
              type: InputType.SELECT,
              data: requisiteFields,
              label: t(`paymentConstructor.field`),
              withModalScroll: true,
            },
            ...(showRequisites
              ? [
                  {
                    type: `separator`,
                    label: t(`paymentConstructor.paymentConfirmationForm`),
                  },
                  {
                    arrayField: true,
                    name: `${field}.hint_payment`,
                    type: InputType.SELECT,
                    data: paymentHints,
                    label: t(`paymentConstructor.hint`),
                    withModalScroll: true,
                  },
                  {
                    arrayField: true,
                    required: true,
                    name: `${field}.field_payment`,
                    type: InputType.SELECT,
                    data: paymentFields,
                    label: t(`paymentConstructor.field`),
                    withModalScroll: true,
                  },
                ]
              : []),
          ];

          const onDelete = () => {
            fields.remove(index);
            const item = fields.get(index);
            const id = get(item, `id`);
            if (id) {
              setDeleted(uniq([...(deleted || []), id]));
            }
          };

          return <PaymentViewBlock key={index} logo={logo} fields={formFields} onDelete={onDelete} />;
        })}
      </div>
    ),
    [t, data, deleted, paymentHints, requisiteHints, showRequisites]
  );

  let content;
  if (data) {
    const defaultLogo = <img className="w-100" src={get(data, `default.logo`)} alt="" />;

    const defaultFields = [
      {
        type: `separator`,
        label: t(`paymentConstructor.general`),
      },
      {
        type: `static`,
        label: t(`paymentConstructor.name`),
        value: get(data, `default.title`),
      },
      {
        type: `static`,
        label: t(`paymentConstructor.currencies`),
        value: join(
          currencies.map((el: { currency: string }) => el.currency || ``),
          `, `
        ),
      },
      {
        type: InputType.CHECKBOX,
        label: t(`paymentConstructor.display`),
        name: `is_default_enabled`,
        isModalField: true,
      },
      {
        type: `separator`,
        label: t(`paymentConstructor.paymentMakingForm`),
      },
      {
        type: `static`,
        label: t(`paymentConstructor.hint`),
        value: defaultRequisiteHint || t(`common.absent`),
      },
      {
        type: InputType.CHECKBOX,
        label: t(`paymentConstructor.limitHint`),
        name: `show_limits`,
        isModalField: true,
      },
      {
        type: `static`,
        label: t(`paymentConstructor.field`),
        value: defaultRequisiteField || t(`common.absent`),
      },
      ...(showRequisites
        ? [
            {
              type: `separator`,
              label: t(`paymentConstructor.paymentConfirmationForm`),
            },
            {
              type: `static`,
              label: t(`paymentConstructor.hint`),
              value: defaultPaymentHint || t(`common.absent`),
            },
            {
              type: `static`,
              label: t(`paymentConstructor.field`),
              value: defaultPaymentField || t(`common.absent`),
            },
          ]
        : []),
    ];

    content = (
      <form className="p-10">
        <PaymentViewBlock logo={defaultLogo} fields={defaultFields} />
        <FormField name="targets" fieldType={FieldType.FIELD_ARRAY} component={renderTargets} />
      </form>
    );
  }

  const footer = (
    <>
      {submitFailed && error && <ErrorBlock error={error} />}
      <ModalFormSubmit submitting={submitting} onClick={handleSubmit}>
        {t(`common.update`)}
      </ModalFormSubmit>
    </>
  );

  return (
    <ModalElement
      title="paymentConstructor.viewType"
      actionCreators={paymentsConstructorActionCreators}
      footer={footer}
      content={content}
      size={Size.MIDDLE}
      loading={!data}
    />
  );
};

export default reduxForm({
  form: PAYMENT_VIEW_FORM_NAME,
})(PaymentViewModal);
