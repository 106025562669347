import { useState } from 'react';

import { PostbacksColumn } from 'utils/enums';
import { ITableTitleField } from 'interfaces/widgets/widgets';

const usePostbacksTableTitles = (): ITableTitleField[] => {
  const [titles] = useState([
    {
      title: `ID`,
      id: PostbacksColumn.ID,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `postbacks.url`,
      id: PostbacksColumn.URL,
      desktop: true,
      mobile: true,
    },
    {
      title: `postbacks.statusCode`,
      id: PostbacksColumn.STATUS_CODE,
      desktop: true,
      mobile: true,
    },
    {
      title: `postbacks.response`,
      id: PostbacksColumn.RESPONSE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.labelOrderId`,
      id: PostbacksColumn.MERCHANT_ORDER_ID,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `wallets.walletType`,
      id: PostbacksColumn.WALLET_TYPE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.status`,
      id: PostbacksColumn.STATUS,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.type`,
      id: PostbacksColumn.TYPE,
      desktop: true,
      mobile: true,
    },
    {
      title: `common.createdAt`,
      id: PostbacksColumn.CREATED_AT,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: ``,
      id: PostbacksColumn.ACTIONS,
      desktop: true,
      mobile: true,
      disabled: true,
    },
  ]);

  return titles;
};

export default usePostbacksTableTitles;
