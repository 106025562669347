import React, { FC } from 'react';
import { useNotifications } from '../../useNotificationContext';
import './Counter.scss';

type Props = {
  className?: string;
  children?: never;
};

export const Counter: FC<Props> = ({ className }) => {
  const {
    notificationsState: { unreadedMessages },
  } = useNotifications();

  if (!unreadedMessages.length) return null;

  return <span className={`Counter ${className}`}>{unreadedMessages.length}</span>;
};
