import { AccountType, ParserType, WalletType } from 'utils/enums';
import { TFunction } from 'react-i18next';

export default (walletType: string, t: TFunction<`translation`>, account_type?: string) => {
  switch (walletType) {
    case WalletType.UPI_L:
      return [
        {
          value: ParserType.PARSER_FREE_CHARGE,
          text: t(`wallets.freeChargeParser`),
        },
        {
          value: ParserType.PARSER_INDUSIN,
          text: t(`wallets.indusIndParser`),
        },
        {
          value: ParserType.FEDNET,
          text: t(`wallets.federalBankParser`),
        },
      ];
    case WalletType.NAGAD:
    case WalletType.BKASH:
      return [AccountType.AGENT, AccountType.PERSONAL].includes(account_type || ``)
        ? [
            {
              value: ParserType.PARSER_EMAIL,
              text: t(`wallets.emailParser`),
            },
            {
              value: ParserType.PARSER_APP,
              text: t(`wallets.appParser`),
            },
          ]
        : account_type === AccountType.MERCHANT
        ? [
            {
              value: ParserType.PARSER_SITE,
              text: t(`wallets.siteParser`),
            },
          ]
        : [];
    case WalletType.PHONE_PE:
      return [
        {
          value: ParserType.PARSER_PHONE_PE_MOBILE_API,
          text: t(`wallets.mobileApiParser`),
        },
        {
          value: ParserType.PARSER_EMAIL,
          text: t(`wallets.emailParser`),
        },
        {
          value: ParserType.PARSER_HDFC,
          text: t(`wallets.hdfcParser`),
        },
        {
          value: ParserType.PARSER_INDUSIN,
          text: t(`wallets.indusinParser`),
        },
      ];
    case WalletType.IMPS_AB:
    case WalletType.UPI_AB:
      return [
        {
          value: ParserType.PARSER_EMAIL,
          text: t(`wallets.emailParser`),
        },
        {
          value: ParserType.PARSER_AXIS,
          text: t(`wallets.axisParser`),
        },
      ];
    case WalletType.PAY_TM:
      return [
        {
          value: ParserType.MOBILE_API,
          text: t(`wallets.parserApi`),
        },
        {
          value: ParserType.PARSER_SITE,
          text: t(`wallets.siteParser`),
        },
      ];
  }
};
