import { WithdrawalLogType } from 'utils/enums';

const withdrawalLogTypeField = {
  [WithdrawalLogType.INCORRECT_STATUS]: {
    text: `withdrawals.incorrectStatus`,
  },
  [WithdrawalLogType.INCORRECT_WALLET_SENDER]: {
    text: `withdrawals.incorrectWalletSender`,
  },
};

export default withdrawalLogTypeField;
