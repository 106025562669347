import { createWidgetAction, widgetActionCreators, IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { CREATE_PROXY_FORM_NAME, UPDATE_PROXY_FORM_NAME } from 'utils/constants';
import {
  PROXY_INIT_CHECK_ALL_REQUESTED,
  PROXY_CHECK_REQUESTED,
  INIT_CHECKING_MULTI_ADDING_PROXY_LIST,
  SET_INVALID_MULTI_ADDING_PROXY_ROWS,
  INIT_CHECKING_MULTI_ADDING_PROXY_ITEM,
  DELETE_MULTI_ADDING_PROXY,
  ADD_MULTI_ADDING_PROXIES_REQUESTED,
  RESET_MULTI_ADDING_PROXIES_DATA,
  UPLOAD_PROXIES_REQUESTED,
  RESET_UPLOADING_PROXIES_DATA,
  DOWNLOAD_PROXIES,
} from 'actionTypes';

export const proxyPayloadLabels = { listLabel: `proxies`, itemLabel: `proxy` };

const proxiesMeta = {
  widget: WidgetType.PROXIES,
  labels: proxyPayloadLabels,
  listItemUpdatingForm: UPDATE_PROXY_FORM_NAME,
  creatingForm: CREATE_PROXY_FORM_NAME,
  uniqKey: `uuid`,
};

export const proxyActionCreators: IWidgetActionCreators = widgetActionCreators(proxiesMeta);

export const checkProxyStatusRequest: IWidgetActionCreators = createWidgetAction(proxiesMeta)(PROXY_CHECK_REQUESTED);

export const checkAllProxiesRequest: IWidgetActionCreators = createWidgetAction(proxiesMeta)(
  PROXY_INIT_CHECK_ALL_REQUESTED
);

export const checkMultiAddingProxyList: IWidgetActionCreators = createWidgetAction(proxiesMeta)(
  INIT_CHECKING_MULTI_ADDING_PROXY_LIST
);

export const setInvalidMultiAddingProxyRows: IWidgetActionCreators = createWidgetAction(proxiesMeta)(
  SET_INVALID_MULTI_ADDING_PROXY_ROWS
);

export const resetInvalidMultiAddingProxyRows = (): IWidgetActionCreators => {
  return createWidgetAction(proxiesMeta)(SET_INVALID_MULTI_ADDING_PROXY_ROWS)(null);
};

export const checkMultiAddingProxyItem: IWidgetActionCreators = createWidgetAction(proxiesMeta)(
  INIT_CHECKING_MULTI_ADDING_PROXY_ITEM
);

export const deleteMultiAddingProxy: IWidgetActionCreators = createWidgetAction(proxiesMeta)(DELETE_MULTI_ADDING_PROXY);

export const addMultiAddingProxies: IWidgetActionCreators = createWidgetAction(proxiesMeta)(
  ADD_MULTI_ADDING_PROXIES_REQUESTED
);

export const resetMultiAddingProxiesData: IWidgetActionCreators = createWidgetAction(proxiesMeta)(
  RESET_MULTI_ADDING_PROXIES_DATA
);

export const uploadProxies: IWidgetActionCreators = createWidgetAction(proxiesMeta)(UPLOAD_PROXIES_REQUESTED);

export const resetProxiesData: IWidgetActionCreators = createWidgetAction(proxiesMeta)(RESET_UPLOADING_PROXIES_DATA);

export const downloadProxies: IWidgetActionCreators = createWidgetAction(proxiesMeta)(DOWNLOAD_PROXIES);
