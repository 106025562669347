import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import { ICreatePaymentHintData } from 'interfaces/widgets/constructor/paymentsConstructor';
import apiFetch from 'api/apiFetch';

export class PaymentHintApi extends BaseApi {
  createPaymentHint = (data: ICreatePaymentHintData): AxiosPromise =>
    this.makeFetch(`create`, { method: `POST`, data });

  deletePaymentHint = (id: string): AxiosPromise => this.makeFetch(`delete/{id}`, { method: `DELETE` }, { id });

  loadLangs = (): AxiosPromise => apiFetch(`/translation/language`);

  loadPaymentDefaultHints = (paymentSystem: string, queryParams: Record<string, any>): AxiosPromise =>
    this.makeFetch(`default/{paymentSystem}`, { queryParams }, { paymentSystem });

  // loadPaymentHintData = (paymentSystem: string): AxiosPromise =>
  //   this.makeFetch(`get/{paymentSystem}`, {}, { paymentSystem });

  loadPaymentHintItem = (id: number): AxiosPromise => this.makeFetch(`show/{id}`, {}, { id });

  loadPaymentHintList = (queryParams: Record<string, any>): AxiosPromise => this.makeFetch(`list`, { queryParams });

  updatePaymentHint = (id: number, data: ICreatePaymentHintData): AxiosPromise =>
    this.makeFetch(`update/{id}`, { data, method: `POST` }, { id });
}

const paymentHintApi = new PaymentHintApi(`hint`);

export default paymentHintApi;
