import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { put, select, takeEvery } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import { ToastrEmitter } from 'react-redux-toastr';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import size from 'lodash/size';
import filter from 'lodash/filter';
import every from 'lodash/every';
import { ForkEffect } from '@redux-saga/core/effects';
import i18n from 'i18n';
import {
  ILoginFormData,
  IHideColumnsRequestBody,
  IAction,
  authCheckRequest,
  LOGIN_FORM_NAME,
  AUTH_ERROR,
  LOGOUT_SUCCEEDED,
  IHideColumnsPayload,
  ILoginRespData,
  updateHiddenColumnSucceeded,
  LOGIN_REQUESTED,
  LOGOUT_REQUESTED,
  hiddenColumnsSelector,
} from '@kassma-team/kassma-toolkit/lib';

export const INIT_APP_REQUESTED = `INIT_APP_REQUESTED`;
export const INIT_APP_SUCCEEDED = `INIT_APP_SUCCEEDED`;

export const UPDATE_HIDDEN_COLUMN_REQUESTED = `UPDATE_HIDDEN_COLUMN_REQUESTED`;

export enum ResponseStatus {
  SUCCESS = `success`,
  ERROR = `error`,
}

import { getRefreshSaga } from './refreshSaga';
import { formatErrors } from '../../utils';
import { SET_TOKEN } from '@kassma-team/kassma-toolkit';

export interface IConfigState {
  apiUrl?: string;
  pluginUrl?: string;
  apiSubdomain?: string;
  apiProtocol?: string;
  useTheSameDomainNameForApi?: boolean;
  clientAppHostnames?: string;
  projectName?: string;
  sentryEnabled?: boolean;
  sentryNodeJsDsn?: string;
}

export interface IError {
  target: string;
  code: number;
  message: string;
}

export interface IPaginate {
  current: number;
  total: number;
  limit: number;
}

export interface IResponse<T> {
  status: ResponseStatus;
  message: string;
  code: number;
  data: Array<any> | T;
  paginate: IPaginate;
  errors: IError[];
  error_message: string;
}

export interface IHideColumnsPayload {
  name: string;
  columns: string[];
}

export interface IHideColumnsData {
  name: string;
  column: string;
  show: boolean;
}

type IRequiredKey = `apiUrl` | `pluginUrl` | `apiSubdomain` | `apiProtocol` | `useTheSameDomainNameForApi`;

export interface IAuthSagasParams {
  fetchDataOnLogin: () => Generator;
  login: (data: ILoginFormData) => AxiosPromise;
  refreshToken: () => AxiosPromise;
  logout: (refresh_token: string) => AxiosPromise;
  hideColumn: (data: IHideColumnsRequestBody) => AxiosPromise;
  toastr: ToastrEmitter;
  requiredKeys: IRequiredKey[];
}

const getAuthSagas = ({
  fetchDataOnLogin,
  login,
  refreshToken,
  logout,
  hideColumn,
  toastr,
  requiredKeys,
}: IAuthSagasParams): ForkEffect[] => {
  const refreshSaga = getRefreshSaga({ refreshToken, toastr });

  function* onLoggedIn() {
    yield fetchDataOnLogin();
  }

  function checkRequiredFields(config?: IConfigState) {
    if (!requiredKeys || !config) {
      return false;
    }

    return every(requiredKeys, (key) => config[key] !== undefined);
  }

  async function getConfig() {
    try {
      const response = await axios(`/config.json`);

      return response.data;
    } catch (e) {
      throw new Error(`Invalid response`);
    }
  }

  function* initApp(prop: IAction<IConfigState>) {
    let config = prop.payload;
    let isRequiredFieldsExists = checkRequiredFields(config);

    if (!isRequiredFieldsExists) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      config = yield getConfig();
      isRequiredFieldsExists = checkRequiredFields(config);
    }

    if (isRequiredFieldsExists) {
      yield put({ type: INIT_APP_SUCCEEDED, payload: config });
      yield put(authCheckRequest());
    } else {
      yield toastr.error(i18n.t(`auth.configError`), i18n.t(`auth.noRequiredParameters`), {
        timeOut: 99999,
        showCloseButton: true,
        progressBar: false,
      });
    }
  }

  function* loginSaga({ payload }: IAction<ILoginFormData>) {
    if (!payload) {
      throw new Error(`Invalid payload`);
    }
    const resp: AxiosResponse<IResponse<ILoginRespData>> = yield login(payload);
    const {
      data: { data, status, error_message, errors },
    } = resp;
    if (status === ResponseStatus.SUCCESS) {
      if (!data || !data.token) {
        throw new Error(`Invalid response`);
      }
      const { token, refresh_token } = data;
      yield localStorage.setItem(`refresh_token`, refresh_token);
      yield localStorage.setItem(`token`, token);
      yield put({ type: SET_TOKEN, payload: token });
      yield put(authCheckRequest(true));
      yield onLoggedIn();
    } else {
      const formattedErrors = formatErrors(errors);
      const errorMessage = error_message || `Login failed`;
      yield put(stopSubmit(LOGIN_FORM_NAME, { _error: errorMessage, ...formattedErrors }));
      yield put({ type: AUTH_ERROR, payload: errorMessage });
    }
  }

  function* logoutSaga() {
    const refresh_token = localStorage.getItem(`refresh_token`) || ``;
    yield refreshSaga({ request: () => logout(refresh_token) });
    yield localStorage.removeItem(`refresh_token`);
    yield localStorage.removeItem(`token`);
    yield localStorage.removeItem(`from_sa`);
    yield put({ type: LOGOUT_SUCCEEDED });
  }

  function* updateHiddenColumnSaga({ payload }: IAction<IHideColumnsPayload>) {
    if (!hideColumn) {
      return;
    }

    if (!payload || !payload?.name) {
      throw new Error(`payload.name is a required field`);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const currentHiddenColumns = yield select(hiddenColumnsSelector(payload?.name));
    const newHiddenColumns = payload?.columns;

    let data: IHideColumnsData[] = [];
    if (size(newHiddenColumns) > size(currentHiddenColumns)) {
      const added = filter(newHiddenColumns, (item) => !currentHiddenColumns.includes(item));
      if (!isEmpty(added)) {
        data = map(added, (item) => ({
          name: payload.name,
          column: item,
          show: false,
        }));
      }
    } else {
      const removed = filter(currentHiddenColumns, (item) => !newHiddenColumns.includes(item));
      if (!isEmpty(removed)) {
        data = map(removed, (item) => ({
          name: payload.name,
          column: item,
          show: true,
        }));
      }
    }

    yield put(updateHiddenColumnSucceeded(payload));
    if (!isEmpty(data)) {
      yield refreshSaga({
        request: () => hideColumn({ data }),
        onSuccess: function () {
          toastr.success(i18n.t(`common.success`), i18n.t(`settings.columnUpdated`));
        },
        onError: function () {
          toastr.error(i18n.t(`common.error`), i18n.t(`settings.columnUpdatingFailed`));
        },
      });
    }
  }

  return [
    takeEvery(LOGIN_REQUESTED, loginSaga),
    takeEvery(LOGOUT_REQUESTED, logoutSaga),
    takeEvery(UPDATE_HIDDEN_COLUMN_REQUESTED, updateHiddenColumnSaga),
    takeEvery(INIT_APP_REQUESTED, initApp),
  ];
};

export default getAuthSagas;
