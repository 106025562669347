import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalType, Permission, WidgetType } from 'utils/enums';
import { IReportRowProps } from 'interfaces/widgets/reports';
import AutoReportsRow from 'components/widgets/reports/autoreports/AutoReportsRow';
import Widget from 'components/widgets/Widget';
import reportRouteTabs from './reportRoutTabs';
import { autoReportsActionCreators } from 'actions/widgets/autoreports';
import AutoReportRemoving from 'components/widgets/reports/autoreports/remove/AutoReportRemoving';
import AutoReportProcessing from 'components/widgets/reports/autoreports/AutoReportProcessing';
import { autoReportsTableTitles } from 'static/autoreports';
import withPermissionCheck from 'hocs/withPermissionCheck';

const { showModal } = autoReportsActionCreators;

const listMapping = (item: IReportRowProps, key: number) => <AutoReportsRow {...item} key={key} />;

const modals = [
  {
    type: ModalType.CREATE,
    widget: WidgetType.AUTOREPORTS,
    component: AutoReportProcessing,
  },
  {
    type: ModalType.UPDATE,
    widget: WidgetType.AUTOREPORTS,
    component: AutoReportProcessing,
  },
  {
    type: ModalType.REMOVE_CONFIRMATION,
    widget: WidgetType.AUTOREPORTS,
    component: AutoReportRemoving,
  },
];

const AutoReports = () => {
  const tableHeadTitles = autoReportsTableTitles;

  const [t] = useTranslation();

  const [extraButtons] = useState([
    {
      title: t(`reports.createTask`),
      onClick: () => showModal({ type: ModalType.CREATE }),
    },
  ]);

  return (
    <Widget
      widget={WidgetType.AUTOREPORTS}
      routeTabs={reportRouteTabs}
      actionCreators={autoReportsActionCreators}
      pageTitle="reports.reports"
      headTitle="reports.reports"
      tableTitle="reports.listOfTasksForAutoGeneration"
      tableHeadTitles={tableHeadTitles}
      listMapping={listMapping}
      extraButtons={extraButtons}
      statusBar
      modals={modals}
    />
  );
};

export default withPermissionCheck(AutoReports, [
  {
    permissions: [
      {
        permission: Permission.MANAGE_AUTO_GENERATING_REPORTS,
        opposite: true,
      },
    ],
    redirectUrl: `/no-permissions`,
  },
]);
