import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';

export class WalletTypeApi extends BaseApi {
  createNewPaymentSystem = (data: any) => this.makeFetch(`wallet-types`, { method: `POST`, data, formData: true });
  loadWalletTypes = (): AxiosPromise => this.makeFetch(`wallet_type`);
  getRequisites = (ps: string): AxiosPromise =>
    this.makeFetch(`wallet-type/settings-constructor/{ps}`, { method: `GET` }, { ps });
  deletePaymentSystem = (code_name: string): AxiosPromise =>
    this.makeFetch(`wallet-types`, { method: `DELETE`, data: { code_name } });
  updateWalletTypesSettings = (data: Record<any, any>): AxiosPromise =>
    this.makeFetch(`wallet-types/settings`, { data, method: `PUT` });
}

const walletTypeApi = new WalletTypeApi(``);

export default walletTypeApi;
