import map from 'lodash/map';
import find from 'lodash/find';
import { createSelector } from 'reselect';
import { widgetListSelector, IRootState, ISelectData } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { ICountriesItems } from 'interfaces/widgets/countries';

export const countriesSelectionSelector = createSelector<IRootState, ICountriesItems[], ISelectData[]>(
  widgetListSelector(WidgetType.COUNTRIES),
  (list) => map(list, ({ title, id }) => ({ value: id, text: title }))
);

export const countryTitleByIdSelector = (id: number) =>
  createSelector<IRootState, ICountriesItems[], string | undefined>(
    widgetListSelector(WidgetType.COUNTRIES),
    (list) => find(list, (item) => item.id === id)?.title
  );

export const countryCodesSelector = createSelector<IRootState, ICountriesItems[], string[]>(
  widgetListSelector(WidgetType.COUNTRIES),
  (list) => map(list, ({ code }) => code)
);

export const countryCodesSelectionSelector = createSelector<IRootState, string[], ISelectData[]>(
  countryCodesSelector,
  (list) => map(list, (code) => ({ value: code, text: code }))
);
