import React, { useCallback, useEffect } from 'react';
import { getFormInitialValues, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';
import i18n from 'i18n';
import { widgetModalSelector, InputType, IWidgetModal } from '@kassma-team/kassma-toolkit/lib';

import useTable from 'hooks/widgets/useTable';
import { PAYMENT_SETTINGS_FORM_NAME } from 'utils/constants';
import { WalletType, WidgetType } from 'utils/enums';
import {
  getPaymentWorkingTimeData,
  getRequisites,
  loadProxyData,
  paymentsConstructorActionCreators,
  resetPaymentWorkingTimeData,
  updateWalletTypeSettings,
} from 'actions/widgets/constructors/paymentsConstructor';
import { timezonesActionCreators } from 'actions/widgets/timezones';
import {
  allowedRequisitesUpdateSelector,
  getConstructorData,
  isCheckAmountSelector,
  paymentWorkingTimeDataSelector,
  useProxySelector,
} from 'selectors/widgets/constructor/paymentsConstructor';
import { timezoneSelectionSelector } from 'selectors/widgets/timezone';
import {
  IPaymentsConstructorPSSettingsForm,
  IUpdateProxyData,
} from 'interfaces/widgets/constructor/paymentsConstructor';

import ModalForm from 'components/modals/ModalForm';
import { isP2PPaymentSystem } from '../../../../selectors/widgets/paymentSystems';
import { getDiff } from '../../../../utils/getDiff';

const SettingsModal = (props: InjectedFormProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const modal: IWidgetModal | null = useSelector(widgetModalSelector(WidgetType.PAYMENTS_CONSTRUCTOR));
  const walletType = modal?.walletType;
  const values: Partial<IPaymentsConstructorPSSettingsForm> = useSelector(getFormValues(props.form));
  const timezoneList = useSelector(timezoneSelectionSelector);
  const data = useSelector(paymentWorkingTimeDataSelector);

  const updateRequisitesData = useSelector(allowedRequisitesUpdateSelector);
  const isCheckAmount = useSelector(isCheckAmountSelector);
  const useProxy = useSelector(useProxySelector);
  const constructorData = useSelector(getConstructorData);
  const isP2PPS = useSelector(isP2PPaymentSystem(walletType));

  useTable({
    widget: WidgetType.TIMEZONES,
    actionCreators: timezonesActionCreators,
    resetOnUnmount: false,
    loadOnlyIfNecessary: true,
  });

  useEffect(() => {
    dispatch(getPaymentWorkingTimeData(walletType));
    dispatch(loadProxyData(walletType));
    dispatch(getRequisites(walletType));
    props.initialize({
      all_time: true,
      time_range: { fromTime: `00:00`, toTime: `23:59` },
      ...(isP2PPS
        ? {
            is_check_amount: isCheckAmount,
          }
        : {}),
    });
  }, []);

  useUnmount(() => {
    dispatch(resetPaymentWorkingTimeData());
  });

  useEffect(() => {
    if (data) {
      const fromTime = data?.begin.substr(0, 5) || `00:00`;
      const toTime = data?.end.substr(0, 5) || `23:59`;
      const timezone = data?.timezone || null;
      const all_time = data?.all_time || false;
      const use_proxy = useProxy || false;
      props.initialize({
        all_time,
        timezone,
        time_range: {
          fromTime,
          toTime,
        },
        use_proxy,
        allowed_update_requisites: updateRequisitesData,
        transactions_verification: constructorData?.transactions_verification,
        no_transactions_period: constructorData?.no_transactions_period,
        disable_wallet_visibility: constructorData?.disable_wallet_visibility,
        impression_counter: constructorData?.impression_counter,
        is_check_amount: isCheckAmount,
      });
    }
  }, [data, useProxy, updateRequisitesData]);

  // useEffect(() => {
  //   if (isP2PPS) {
  //     props.initialize({
  //       is_check_amount: isCheckAmount,
  //     });
  //   }
  // }, [isCheckAmount, isP2PPS]);

  const initial = useSelector(getFormInitialValues(props.form));

  const onSubmit = useCallback(
    (values) => {
      const {
        allowed_update_requisites,
        transactions_verification,
        no_transactions_period,
        disable_wallet_visibility,
        impression_counter,
      } = values;

      const isCheckAmountExists = (is_check_amount?: boolean) => {
        if (typeof is_check_amount !== `boolean`) {
          return false;
        }

        return true;
      };

      const workingTimePayload = values?.all_time
        ? {
            all_time: true,
            begin: `00:00:00`,
            end: `23:59:59`,
          }
        : {
            all_time: false,
            begin: `${values?.time_range?.fromTime}:00`,
            end: `${values?.time_range?.toTime}:00`,
            timezone: values?.timezone,
            // value: timezoneList.find((el: any) => el.value === data?.timezone),
          };

      const proxyPayload: IUpdateProxyData = {
        use_proxy: values.use_proxy || false,
      };

      if (walletType !== WalletType.UPI_IB) {
        proxyPayload.use_proxy_night_parser = values.use_proxy_night_parser || false;
      }

      const updateRequisitesPayload = {
        allowed_update_requisites,
        transactions_verification,
        no_transactions_period: transactions_verification ? +no_transactions_period : null,
        disable_wallet_visibility: transactions_verification ? disable_wallet_visibility : false,
        impression_counter: transactions_verification ? +impression_counter : null,
        ...(isCheckAmountExists(values.is_check_amount)
          ? {
              is_check_amount: values.is_check_amount,
            }
          : {}),
      };

      const requestData = {
        ...workingTimePayload,
        ...proxyPayload,
        ...updateRequisitesPayload,
      };

      const diff = getDiff({ ...initial, ...data }, requestData);

      if (Object.keys(diff).length > 0) {
        dispatch(updateWalletTypeSettings(walletType, { ...diff }));
      } else {
        dispatch(paymentsConstructorActionCreators.hideModal());
      }
    },

    [walletType, initial, data]
  );

  const timeFields = [
    {
      name: `all_time`,
      label: t(`paymentConstructor.aroundTheClock`),
      type: InputType.CHECKBOX,
      required: true,
    },
    ...(!values?.all_time
      ? [
          {
            name: `time_range`,
            label: t(`paymentConstructor.timeRange`),
            type: InputType.TIME_RANGE,
          },
          {
            name: `timezone`,
            label: t(`paymentConstructor.timezone`),
            type: InputType.SELECT,
            data: timezoneList,
            required: true,
          },
        ]
      : []),
  ];

  const proxiesFields = [
    {
      name: `use_proxy`,
      label: t(`wallets.useProxy`),
      type: InputType.CHECKBOX,
    },
  ];

  const pluginFields = [
    {
      name: `allowed_update_requisites`,
      label: t(`paymentConstructor.allowUpdateRequisites`),
      type: InputType.CHECKBOX,
    },
  ];

  const fields = [
    {
      title: `paymentConstructor.workingTime`,
      type: `group`,
      fields: timeFields,
    },
    {
      title: `paymentConstructor.proxies`,
      type: `group`,
      fields: proxiesFields,
    },
    ...(isP2PPS
      ? [
          {
            title: `paymentConstructor.plugin`,
            type: `group`,
            fields: pluginFields,
          },
          {
            title: `transactions.transactions`,
            type: `group`,
            fields: [
              {
                name: `is_check_amount`,
                label: t(`settings.isCheckAmount`),
                type: InputType.CHECKBOX,
              },
              {
                name: `transactions_verification`,
                label: t(`settings.checkingForTransactions`),
                type: InputType.CHECKBOX,
              },
            ],
          },
        ]
      : []),
  ];

  if (values?.transactions_verification) {
    fields.push({
      name: `no_transactions_period`,
      label: t(`settings.noTransactionsPeriod`),
      type: InputType.TEXT,
      disabled: !isP2PPS,
      // @ts-expect-error Wrong typing. It is valid.
      amount: true,
      required: true,
      description: t(`settings.noTransactionsPeriodDescription`),
    });
    fields.push({
      name: `disable_wallet_visibility`,
      label: t(`settings.disableWalletVisibility`),
      type: InputType.CHECKBOX,
      disabled: false,
    });
    fields.push({
      name: `impression_counter`,
      label: t(`settings.numberOfWalletImpressions`),
      type: InputType.TEXT,
      // @ts-expect-error Wrong typing. It is valid.
      amount: true,
      minAmount: 5,
      required: true,
    });
  }

  return (
    <ModalForm
      {...props}
      fields={fields}
      onSubmit={onSubmit}
      submitText="common.save"
      actionCreators={paymentsConstructorActionCreators}
      widget={WidgetType.PAYMENTS_CONSTRUCTOR}
      title="paymentConstructor.settings"
      loading={!data}
    />
  );
};

export default reduxForm<IPaymentsConstructorPSSettingsForm, InjectedFormProps>({
  form: PAYMENT_SETTINGS_FORM_NAME,
  validate: (values) => {
    const errors: Record<string, string> = {};

    const fromTime = values?.time_range?.fromTime;
    const toTime = values?.time_range?.toTime;

    if (fromTime && toTime && fromTime === toTime) {
      errors.time_range = i18n.t(`paymentConstructor.timeEqual24HoursError`);
    }

    if (values.transactions_verification) {
      if (values.no_transactions_period) {
        if (values.no_transactions_period < 5 || values.no_transactions_period > 600) {
          errors.no_transactions_period = i18n.t(`settings.wrongTransactionAmount`);
        }
      }

      if (values.impression_counter) {
        if (values.impression_counter < 5) {
          errors.number_of_wallet_impressions = i18n.t(`settings.wrongImpressionsAmount`);
        }
      }
    }

    return errors;
  },
})(SettingsModal);
