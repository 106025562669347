import { Reducer } from 'redux';
import { IWidgetsState } from '@kassma-team/kassma-toolkit/lib/types/widgets';

import { RESET_TARIFFS, TARIFFS_SUCCEEDED } from 'actionTypes';

const actionTypes = new Set([TARIFFS_SUCCEEDED, RESET_TARIFFS]);

const reducer: Reducer = (state: IWidgetsState, { type, payload }) => {
  switch (type) {
    case TARIFFS_SUCCEEDED:
      return {
        ...state,
        tariffs: payload,
      };
    case RESET_TARIFFS:
      return {
        ...state,
        tariffs: null,
      };
    default:
      return state;
  }
};

const tariffs = { reducer, actionTypes };

export default tariffs;
