import { AxiosPromise } from 'axios';
import {
  sameDomainNameForApiSelector,
  apiSubdomainSelector,
  apiProtocolSelector,
  apiUrlSelector,
  ICreateApiFetchOptions,
} from '@kassma-team/kassma-toolkit/lib';

import store from '../store';
import createApiFetch, { AxiosMiddleware } from './createApiFetch';
import { newResponseOptionsMiddleware } from './middlewares/newResponseOptionsMiddleware';
import { isPathSupportNewResponse } from './newResponseSupport';

const apiFetch = <T>(...args: [string, ICreateApiFetchOptions?, string?]): AxiosPromise<T> => {
  const state = store.getState();
  const useTheSameDomainNameForApi = sameDomainNameForApiSelector(state);
  const apiSubdomain = apiSubdomainSelector(state);
  const apiProtocol = apiProtocolSelector(state);
  const apiUrl = apiUrlSelector(state);

  const [URL, options, purePath] = args;

  const middlewares: AxiosMiddleware[] = [];
  if (isPathSupportNewResponse(URL) || isPathSupportNewResponse(purePath || ``)) {
    middlewares.push(newResponseOptionsMiddleware);
  }

  return createApiFetch({
    apiUrlParams: {
      apiUrl: apiUrl,
      useTheSameDomainNameForApi: useTheSameDomainNameForApi,
      subDomain: apiSubdomain,
      protocol: apiProtocol,
    },
    store,
    middlewares,
  })(URL, options);
};

export default apiFetch;
