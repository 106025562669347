import React, { useCallback } from 'react';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IModalFormProps, InputType } from '@kassma-team/kassma-toolkit/lib';

import { MANUAL_WITHDRAWALS_DOWNLOADING_FORM_NAME } from 'utils/constants';
import { WidgetType, WithdrawalStatus } from 'utils/enums';
import { downloadManualWithdrawalsRequest, withdrawalsActionCreators } from 'actions/widgets/withdrawals';
import { withdrawalsPSSelector } from 'selectors/widgets/paymentSystems';

import withdrawalStatusField from 'utils/widgets/withdrawals/withdrawalStatusField';

import ModalForm from 'components/modals/ModalForm';

const ManualWithdrawalsDownloadingModal = (props: InjectedFormProps & IModalFormProps) => {
  const values = useSelector(getFormValues(props.form));
  const paymentSystems = useSelector(withdrawalsPSSelector);

  const [t] = useTranslation();

  const fields = [
    {
      name: `payment_system`,
      data: [
        {
          value: null,
          text: t(`common.all`),
        },
        ...paymentSystems,
      ],
      type: InputType.SELECT,
      label: t(`wallets.walletType`),
      placeholder: t(`common.all`),
    },
    {
      name: `status`,
      data: [
        {
          text: `common.all`,
          value: undefined,
        },
        {
          text: get(withdrawalStatusField, `${WithdrawalStatus.NEW}.filterText`),
          value: WithdrawalStatus.NEW,
        },
        {
          text: get(withdrawalStatusField, `${WithdrawalStatus.ERROR}.filterText`),
          value: WithdrawalStatus.ERROR,
        },
      ],
      type: InputType.SELECT,
      placeholder: t(`common.all`),
      label: t(`common.status`),
      translateKeys: true,
    },
    {
      name: `date_range`,
      fromValue: get(values, `date_range.values.startDate`),
      toValue: get(values, `date_range.values.endDate`),
      type: InputType.DATE_RANGE,
      label: t(`common.period`),
      required: true,
    },
  ];

  const onSubmit = useCallback((values, dispatch) => {
    dispatch(downloadManualWithdrawalsRequest(values));
  }, []);

  return (
    <ModalForm
      {...props}
      widget={WidgetType.WITHDRAWALS}
      actionCreators={withdrawalsActionCreators}
      fields={fields}
      onSubmit={onSubmit}
      submitText="withdrawals.manualDownload"
      title="withdrawals.manualWithdrawalsDownloading"
    />
  );
};

export default reduxForm({
  form: MANUAL_WITHDRAWALS_DOWNLOADING_FORM_NAME,
})(ManualWithdrawalsDownloadingModal);
