import toNumber from 'lodash/toNumber';

import i18n from '../../../i18n';

const validateProxyPort = (port: string | number): string | null => {
  if (toNumber(port) < 0 || toNumber(port) > 65535) {
    return i18n.t(`errors.invalidPortFormat`);
  }

  return null;
};

export default validateProxyPort;
