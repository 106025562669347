import React from 'react';
import { RouteTabs as KassmaRouteTabs } from '@kassma-team/kassma-toolkit/lib';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useRouteName from 'hooks/useRouteName';
import { userPermissionsSelector } from 'selectors/auth';

interface IProps {
  routeTabs?: (permissions: string[]) => Record<string, string | boolean>[];
  routeName?: string;
  permissions?: string[];
  ReactNavLink?: unknown;
}

const RouteTabs = (props: IProps) => {
  const routeName = useRouteName();
  const permissions = useSelector(userPermissionsSelector);

  return <KassmaRouteTabs {...props} routeName={routeName} permissions={permissions} ReactNavLink={Link} />;
};

export default RouteTabs;
