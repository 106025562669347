import React, { useCallback, useState, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import useMount from 'react-use/lib/useMount';
import useUnmount from 'react-use/lib/useUnmount';
import {
  FormSubmissionButton,
  RadioButtons,
  Preloader,
  useDesktopSize,
  useModalOverflow,
  widgetModalSelector,
  widgetSubmittingSelector,
  widgetModalErrorSelector,
  Size,
} from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import {
  addMultiAddingProxies,
  checkMultiAddingProxyList,
  proxyActionCreators,
  resetMultiAddingProxiesData,
} from 'actions/widgets/proxy';
import {
  addingProxiesSelector,
  checkingAddingProxySelector,
  invalidAddingProxiesSelector,
  verifiedAddingProxiesSelector,
  verifyingAddingProxiesSelector,
} from 'selectors/widgets/proxies';
import { countryCodesSelectionSelector } from 'selectors/widgets/countries';

import MultiAddingProxyRow from 'components/widgets/proxy/multiAdding/MultiAddingProxyRow';
import ModalElement from 'components/modals/ModalElement';

interface IMultiAddingTableProps {
  onNext: () => void;
}

const Step = {
  MULTI_ADDING_TABLE: 1,
  UPDATE_EXISTED: 2,
};

const MODAL_TITLE = `proxies.proxiesAdding`;

const MultiAddingTable = ({ onNext }: IMultiAddingTableProps) => {
  const [t] = useTranslation();

  const desktopSize = useDesktopSize();

  const invalidProxies = useSelector(invalidAddingProxiesSelector);
  const checkingProxy = useSelector(checkingAddingProxySelector);
  const addingProxies = useSelector(addingProxiesSelector);
  const countryCodes = useSelector(countryCodesSelectionSelector);

  const content = (
    <>
      <table className="table table-vcenter">
        {desktopSize && (
          <thead>
            <tr>
              <th>{t(`proxies.ipHost`)}</th>
              <th>{t(`proxies.port`)}</th>
              <th>{t(`proxies.username`)}</th>
              <th>{t(`proxies.password`)}</th>
              <th>{t(`common.type`)}</th>
              <th>{t(`common.country`)}</th>
              <th>{t(`common.status`)}</th>
              <th>{t(`common.actions`)}</th>
            </tr>
          </thead>
        )}
        <tbody>
          {map(addingProxies, (props) => (
            <MultiAddingProxyRow
              {...props}
              key={get(props, `id`)}
              desktopSize={desktopSize}
              countryCodes={countryCodes}
            />
          ))}
        </tbody>
      </table>
      {invalidProxies && (
        <div>
          <div className="mb-10">{t(`proxies.invalidRows`)}</div>
          <pre className="border-top pt-5" id="invalid_proxies">
            {invalidProxies}
          </pre>
        </div>
      )}
    </>
  );

  const footer = (
    <div className="form-group pt-10 row justify-content-end">
      <div className="col-lg-8">
        <button className="btn btn-alt-primary" id="next_btn" onClick={onNext} disabled={checkingProxy}>
          {t(`common.next`)}
        </button>
      </div>
    </div>
  );

  return (
    <ModalElement
      title={MODAL_TITLE}
      content={content}
      actionCreators={proxyActionCreators}
      size={Size.BIG}
      footer={footer}
    />
  );
};

const AddingMethod = {
  ADD_ONLY_NEW: `add-only-new`,
  ADD_NEW_AND_UPDATE_EXISTED: `add-new-and-update-existed`,
};

const addingMethods = [
  {
    text: `proxies.addOnlyNew`,
    value: AddingMethod.ADD_ONLY_NEW,
  },
  {
    text: `proxies.addNewAndUpdateExisted`,
    value: AddingMethod.ADD_NEW_AND_UPDATE_EXISTED,
  },
];

interface IAddingMethodSelectionProps {
  onBack: () => void;
}

const AddingMethodSelection = ({ onBack }: IAddingMethodSelectionProps) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const [method, setMethod] = useState(AddingMethod.ADD_ONLY_NEW);

  const submitting: boolean | undefined = useSelector(widgetSubmittingSelector(WidgetType.PROXIES));

  const onSubmit = useCallback(() => {
    dispatch(addMultiAddingProxies({ update_existed: method === AddingMethod.ADD_NEW_AND_UPDATE_EXISTED }));
  }, [method]);

  const content = <RadioButtons data={addingMethods} onChange={setMethod} selected={method} name="update_existed" />;

  const footer = (
    <div className="form-group pt-10 row justify-content-end">
      <div className="col-lg-8 flex">
        <FormSubmissionButton id="add_proxies_btn" onClick={onSubmit} submitting={submitting}>
          {t(`common.add`)}
        </FormSubmissionButton>
        <button id="back_btn" onClick={onBack} disabled={submitting} className="btn btn-alt-primary ml-5">
          {t(`common.back`)}
        </button>
      </div>
    </div>
  );

  return (
    <ModalElement
      title={MODAL_TITLE}
      content={content}
      actionCreators={proxyActionCreators}
      size={Size.BIG}
      footer={footer}
    />
  );
};

const ProxiesMultiAdding = (): ReactNode => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const [step, setStep] = useState<number>();

  useModalOverflow();

  const modal = useSelector(widgetModalSelector(WidgetType.PROXIES));
  const [addingProxiesText] = useState(get(modal, `proxies`));

  useMount(() => {
    dispatch(checkMultiAddingProxyList(addingProxiesText));
  });

  useUnmount(() => {
    dispatch(resetMultiAddingProxiesData());
  });

  const error: string | null = useSelector(widgetModalErrorSelector(WidgetType.PROXIES));

  const verifyingProxies = useSelector(verifyingAddingProxiesSelector);
  const verifiedProxies = useSelector(verifiedAddingProxiesSelector);
  const addingProxies = useSelector(addingProxiesSelector);

  let content;

  if (error) {
    content = <div className="text-error text-center p-10">{error}</div>;
  } else if (verifyingProxies) {
    content = (
      <>
        <Preloader />
        <div className="text-center p-10">{t(`proxies.verifiedProxies`, { verified: verifiedProxies })}</div>
      </>
    );
  } else if (isEmpty(addingProxies)) {
    content = <div className="text-error text-center p-10">{t(`proxies.noValidProxies`)}</div>;
  }

  if (content) {
    return <ModalElement title={MODAL_TITLE} content={content} actionCreators={proxyActionCreators} size={Size.BIG} />;
  }

  if (step === Step.UPDATE_EXISTED) {
    return <AddingMethodSelection onBack={() => setStep(Step.MULTI_ADDING_TABLE)} />;
  }

  return <MultiAddingTable onNext={() => setStep(Step.UPDATE_EXISTED)} />;
};

export default ProxiesMultiAdding;
