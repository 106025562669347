import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';
import { TextInput } from '@kassma-team/kassma-toolkit';
import { TextInputProps } from '@kassma-team/kassma-toolkit/lib/components/form/inputs/TextInput';

import { standardAmountField, percentField } from 'utils/widgets/tariffs/tariffsConstructor';

import './tariffInput.scss';

interface ITariffTextInputProps {
  percent?: boolean;
  value?: string;
  disabled?: boolean;
}

const TariffInput = ({
  label,
  name,
  error,
  value,
  percent = false,
  onChange,
  ...rest
}: ITariffTextInputProps & Partial<TextInputProps>): JSX.Element => {
  const [valueState, setValueState] = useState(value);

  useEffect(() => {
    let parsedValue = ``;

    if (value) {
      const symbolsAfterDotLength = value.split(`.`)?.[1]?.length;
      parsedValue = percent && symbolsAfterDotLength > 2 ? value.slice(0, -(symbolsAfterDotLength - 2)) : value;
    }

    setValueState(parsedValue);
  }, [value]);

  const handleChange = useCallback(
    ({ value }) => {
      if (/[a-zA-Zа-яА-Я]/gi.test(value)) return;

      value = percent ? percentField(value) : standardAmountField(value);

      if (percent && value > 100) return;

      setValueState(value);
      onChange && onChange(value);
    },
    [value, percent, onChange]
  );

  return (
    <div className="tariff_text_input">
      <div className="tariff_text_input__input">
        {label && (
          <label className="tariff_text_input__label" htmlFor={name}>
            {label}
          </label>
        )}
        <TextInput {...rest} value={valueState} onChange={(e: ChangeEvent) => handleChange(e.target)} />
        <span className="text-error display-block tariff_text_input__error">{error}</span>
      </div>
    </div>
  );
};

export default TariffInput;
