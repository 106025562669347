import { AxiosPromise } from 'axios';

import { BaseApi } from '../common/BaseApi';
import { Response } from './types';
import apiFetch from 'api/apiFetch';

type Translation = {
  language: string;
  text: string;

  clientId?: string;
};

export type WalletType = {
  code: string;
  uuid: string;
  payment_type: string;
  name: string;
  id: string;
  wallet_type: string;
  is_settlement: boolean;
};

export type Lang = string;

export type FormType = {
  type: string;
  title: string;
};

export type Hint = {
  created_at: string;
  form_type: string;
  is_default?: boolean;
  is_manual: boolean;
  name: string;
  updated_at: string;
  uuid: string;
  wallet_type_uuid: string;
  wallet_type: string;
  translations: Array<Translation>;
};

type ListResponse = AxiosPromise<Response<Array<Hint>>>;

type HintResponse = AxiosPromise<Response<Hint>>;

export type CreateParams = {
  wallet_type: string;
  form_type: string;
  is_manual: boolean;
  name: string;
  translations: Array<Translation>;
};

type CreateResponse = AxiosPromise<Response<{ uuid: string }>>;

export type UpdateParams = {
  name: string;
  is_manual: boolean;
  translations: Array<Translation>;
};

type UpdateResponse = AxiosPromise<Response<unknown>>;

export class TranslationsApi extends BaseApi {
  fetchFormTypes = () => this.makeFetch<Response<Array<FormType>>>(`form-type`);

  fetchPaymentSystems = (): AxiosPromise<Response<Array<WalletType>>> =>
    apiFetch(`/wallet-types`, {
      headers: {
        'Request-Version': `1.000.0`,
      },
    });

  fetchLangs = () => this.makeFetch<Response<Array<Lang>>>(`language`);

  fetchHints = (queryParams?: Record<string, string | boolean>): ListResponse =>
    this.makeFetch(`hint`, { queryParams });

  fetchHint = (id: string): HintResponse => this.makeFetch(`hint/${id}`);

  deleteHint = (id: string): AxiosPromise => this.makeFetch(`hint/${id}`, { method: `DELETE` });

  createHint = (body: CreateParams): CreateResponse =>
    this.makeFetch(`hint`, {
      method: `POST`,
      data: body,
      headers: {
        'Request-Version': `1.000.0`,
      },
    });

  updateHint = (id: string, body: UpdateParams): UpdateResponse =>
    this.makeFetch(`hint/${id}`, { method: `PUT`, data: body });
}

const translationsApi = new TranslationsApi(`translation`);

export default translationsApi;
