import { widgetActionCreators, IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { CREATE_PRELIMINARY_TRANSACTION_FORM_NAME } from 'utils/constants';
import { ACTIVATE_PRELIMINARY_TRANSACTION_REQUESTED } from 'actionTypes';

const labels = {
  listLabel: `pretransactions`,
  itemLabel: `pretransaction`,
};

export const preliminaryTransactionsActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.PRELIMINARY_TRANSACTIONS,
  labels,
  creatingForm: CREATE_PRELIMINARY_TRANSACTION_FORM_NAME,
  uniqKey: `primary_id`,
  withPagination: true,
});

export const preliminaryTransactionsOnlyByIdActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.PRELIMINARY_TRANSACTIONS_ONLY_BY_ID,
  labels,
  uniqKey: `primary_id`,
  withPagination: true,
});

interface IActivatePreliminaryTransactionPayload {
  primary_id: number;
  possible_transaction_primary_id: number;
}

interface IActivatePreliminaryTransactionReturns {
  payload: IActivatePreliminaryTransactionPayload;
  type: string;
}

export const activatePreliminaryTransaction = (
  payload: IActivatePreliminaryTransactionPayload
): IActivatePreliminaryTransactionReturns => ({
  type: ACTIVATE_PRELIMINARY_TRANSACTION_REQUESTED,
  payload,
});
