import React, { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react';
import { TextInput } from '@kassma-team/kassma-toolkit';
import Skeleton from 'react-loading-skeleton';

import { packagesApi } from 'api/packages';
import { Package } from 'api/packages/PackagesApi';
import { IconButton } from 'pages/constructor/DefaultTranslates/common/components';

import {
  useCreationModeCtx,
  useFormTypeCtx,
  useTranslationsCtx,
  usePaymentSystemsCtx,
} from 'pages/constructor/DefaultTranslates/PackagesTranslation/ctx';

import './NewPackageForm.scss';
import refreshHelper from 'utils/refreshHelper';

export const NewPackageForm: FC = () => {
  const { handleCreateField, fetchPackages } = useTranslationsCtx();
  const { currentPaymentSystem } = usePaymentSystemsCtx();
  const { fetchFormTypes } = useFormTypeCtx();

  const [isDefaultLoading, setIsDefaultLoading] = useState(false);
  const [defaultPackages, setDefaultPackages] = useState<Record<string, Package>>();

  const { setPackageName, packageName, setIsCreationMode, creationPackage, reset } = useCreationModeCtx();

  useEffect(() => {
    if (currentPaymentSystem?.code) {
      const fetcher = async () => {
        setIsDefaultLoading(true);

        await refreshHelper({
          request: () =>
            packagesApi.fetchTranslations({
              wallet_type: currentPaymentSystem?.code,
              is_default: true,
            }),
          onSuccess: ({ data }: any) => {
            if (data.data) {
              const nData = data.data.reduce((acc: Record<string, Package>, curr: Package) => {
                acc[curr.form_type] = curr;

                return acc;
              }, {} as Record<string, Package>);

              setDefaultPackages(nData);
            }
          },
          onFinally: () => {
            setIsDefaultLoading(false);
          },
        });
      };

      fetcher();
    }
  }, []);

  useEffect(() => {
    return () => reset();
  }, []);

  const handlePackageName = (e: ChangeEvent<HTMLInputElement>) => setPackageName(e.target.value);

  const handleClose = () => setIsCreationMode(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (creationPackage && defaultPackages && currentPaymentSystem?.code) {
      const promises = Object.keys(defaultPackages).map((key) => {
        handleCreateField(
          {
            form_type: key,
            is_manual: defaultPackages[key].is_manual,
            name: packageName,
            wallet_type: currentPaymentSystem?.code,
            packages_translations: defaultPackages[key].packages_translations.map(({ field, translations }) => ({
              field_uuid: field,
              translations,
            })),
          },
          false
        )();
      });

      Promise.allSettled(promises).then((results) => {
        if (results.every(({ status }) => status === `fulfilled`)) {
          setIsCreationMode(false);
          fetchFormTypes();
          fetchPackages();
        }
      });
    }
  };

  return (
    <form className="NewPackageForm" onSubmit={handleSubmit}>
      <div className="NewPackageForm__triangle" />

      {isDefaultLoading ? (
        <Skeleton count={2} />
      ) : (
        <div>
          <TextInput className="NewPackageForm__package-name" value={packageName} onChange={handlePackageName} />

          <div className="NewPackageForm__controls">
            <div>
              {packageName && (
                <button type="submit">
                  <IconButton onClick={() => null} variant="apply" />
                </button>
              )}
            </div>
            <IconButton onClick={handleClose} variant="remove" />
          </div>
        </div>
      )}
    </form>
  );
};
