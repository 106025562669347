import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { WalletImg, useFilterColumns } from '@kassma-team/kassma-toolkit/lib';
import { useTranslation } from 'react-i18next';

import useFullAmount from 'hooks/useFullAmount';
import { PostbackSendStatus, Permission, ModalType, WalletType, StockpilingListColumn } from 'utils/enums';
import { hasAccessSelector, userPermissionsSelector } from 'selectors/auth';
import { splitNumBySpaces } from 'utils';
import { stockpilingListActionCreators } from 'actions/widgets/stockpiling';
import { IStockpilingRowProps } from 'interfaces/widgets/stockpiling';
import { paymentSystemLogoSelector } from '../../../selectors/widgets/paymentSystems';

const StockpilingRow = ({
  id,
  desktopSize,
  label,
  amount,
  currency_symbol,
  payment_system,
  postback_send,
  test_wallet_type,
  hiddenColumns,
  postback_id,
  direction,
  is_real,
  min_amount,
}: IStockpilingRowProps) => {
  const permissionsList = useSelector(userPermissionsSelector);
  const canSendPostback = () => permissionsList.includes(Permission.POSTBACK_SEND);
  const canViewPostbacks = () => permissionsList.includes(Permission.POSTBACKS);
  const canViewStockpilings = () => permissionsList.includes(Permission.STOCKPILING_LIST);

  const fullAmount = useFullAmount({
    amount: splitNumBySpaces(amount, { splitFloatNumbers: true }) as string,
    currencySymbol: currency_symbol,
  });
  const fullMinAmount = useFullAmount({
    amount: splitNumBySpaces(min_amount, { splitFloatNumbers: true }) as string,
    currencySymbol: currency_symbol,
  });
  const dispatch = useDispatch();

  const [t] = useTranslation();

  const postbacksAccess = useSelector(hasAccessSelector(Permission.POSTBACKS));

  const onTransactionsClick = useCallback(() => {
    dispatch(
      stockpilingListActionCreators.showModal({
        type: ModalType.STOCKPILINGS_TRANSACTION_LIST,
        id,
      })
    );
  }, [id]);

  const onPostbackClick = useCallback(() => {
    dispatch(
      stockpilingListActionCreators.showModal({
        type: ModalType.STOCKPILINGS_POSTBACK,
        id: postback_id,
        direction,
      })
    );
  }, [postback_id, direction]);

  const onSendPostbackClick = useCallback(() => {
    dispatch(
      stockpilingListActionCreators.showModal({
        type: ModalType.STOCKPILINGS_SEND_POSTBACK,
        id,
      })
    );
  }, [id]);

  const isPostbackSend = postback_send === PostbackSendStatus.SEND ? <i className="fa fa-check" /> : null;

  const logo = useSelector(paymentSystemLogoSelector(payment_system));
  const logoPath = payment_system === WalletType.TEST ? get(test_wallet_type, `logo`) : logo;
  const walletTypeImage = (
    <WalletImg
      title={payment_system === WalletType.TEST ? get(test_wallet_type, `name`) : payment_system}
      src={logoPath}
    />
  );

  const getBadge = (stockpilling_type: IStockpilingRowProps[`is_real`]) => {
    return stockpilling_type == 0 ? (
      <div className="badge badge-warning">{t(`stockpilings.debug`)}</div>
    ) : (
      <div className="badge badge-success">{t(`stockpilings.real`)}</div>
    );
  };

  const actions = (
    <div className="btn-group">
      {canViewStockpilings() && (
        <button className="btn btn-sm btn-secondary" onClick={onTransactionsClick}>
          {t(`transactions.transactions`)}
        </button>
      )}
      {canViewPostbacks() && postback_send === PostbackSendStatus.SEND && (
        <button className="btn btn-sm btn-secondary" onClick={onPostbackClick}>
          {t(`postbacks.postback`)}
        </button>
      )}
      {canSendPostback() && postback_send === PostbackSendStatus.NO_SEND && (
        <button className="btn btn-sm btn-secondary" onClick={onSendPostbackClick}>
          {t(`stockpilings.sendPostback`)}
        </button>
      )}
    </div>
  );
  const columns = [
    {
      id: StockpilingListColumn.ID,
      desktop: <th>{id}</th>,
      mobile: (
        <tr className="first-mobile-row">
          <td>ID</td>
          <td>{id}</td>
        </tr>
      ),
    },
    {
      id: StockpilingListColumn.LABEL,
      desktop: <td>{label}</td>,
      mobile: (
        <tr>
          <td>{t(`common.label`)}</td>
          <td>{label}</td>
        </tr>
      ),
    },
    {
      id: StockpilingListColumn.PAYMENT_SYSTEM,
      desktop: <td>{walletTypeImage}</td>,
      mobile: (
        <tr>
          <td>{t(`common.paymentSystem`)}</td>
          <td>{walletTypeImage}</td>
        </tr>
      ),
    },
    {
      id: StockpilingListColumn.MIN_DEPOSIT,
      desktop: (
        <td>
          <div className="text-elegance">
            <strong>{fullMinAmount}</strong>
          </div>
        </td>
      ),
      mobile: (
        <tr>
          <td>{t(`manualPS.minDeposit`)}</td>
          <td>
            <div className="text-elegance">
              <strong>{fullMinAmount}</strong>
            </div>
          </td>
        </tr>
      ),
    },
    {
      id: StockpilingListColumn.STOCKPILINGS_AMOUNT,
      desktop: (
        <td>
          <div className="text-elegance">
            <strong>{fullAmount}</strong>
          </div>
        </td>
      ),
      mobile: (
        <tr>
          <td>{t(`stockpilings.stockpilingsAmount`)}</td>
          <td>
            <div className="text-elegance">
              <strong>{fullAmount}</strong>
            </div>
          </td>
        </tr>
      ),
    },
    {
      id: StockpilingListColumn.STOCKPILING_TYPE,
      desktop: <td>{getBadge(is_real)}</td>,
      mobile: (
        <tr>
          <td>{t(`stockpilings.type`)}</td>
          <td>{getBadge(is_real)}</td>
        </tr>
      ),
    },
    {
      id: StockpilingListColumn.STATUS,
      desktop: <td>{isPostbackSend}</td>,
      mobile: (
        <tr>
          <td>{t(`common.status`)}</td>
          <td>{isPostbackSend}</td>
        </tr>
      ),
    },
    {
      id: StockpilingListColumn.ACTIONS,
      desktop: <td>{actions}</td>,
      mobile: (
        <tr>
          <td>{t(`common.actions`)}</td>
          <td>{actions}</td>
        </tr>
      ),
    },
  ];

  return useFilterColumns({ columns, desktopSize, hiddenColumns });
};

export default StockpilingRow;
