import { widgetActionCreators, IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { IWidgetLabels } from 'interfaces/widgets/widgets';

export const hintLangsPayloadLabels: IWidgetLabels = { listLabel: `langs` };

export const hintLangsActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.PAYMENT_HINT_LANGS,
  labels: hintLangsPayloadLabels,
});
