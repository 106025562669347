import { destroy, getFormSyncErrors, getFormValues, InjectedFormProps } from 'redux-form';
import { CREATE_MANUAL_PS_FORM, CREATE_MANUAL_PS_HINT_FORM } from 'utils/constants';
import { WidgetType } from 'utils/enums';
import React, { useCallback, useEffect, useState } from 'react';
import WizardForm from 'components/form/WizardForm';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { widgetSubmittingSelector } from '@kassma-team/kassma-toolkit/lib';
import CreationPSData from './CreationPSData';
import CreationPSHintsData from './CreationPSHintsData';
import {
  createPaymentSystem,
  paymentsConstructorActionCreators,
} from 'actions/widgets/constructors/paymentsConstructor';

const CreatingManualPSModal = (props: InjectedFormProps) => {
  const [t] = useTranslation();

  const [page, setPage] = useState(1);
  const errors = useSelector(getFormSyncErrors(CREATE_MANUAL_PS_FORM));

  const formProps = useSelector((state: any) => state.form[CREATE_MANUAL_PS_FORM]) || {};
  const values = useSelector(getFormValues(CREATE_MANUAL_PS_FORM));
  const submitSucceeded = formProps.submitSucceeded;

  const submitting: boolean = useSelector(widgetSubmittingSelector(WidgetType.PAYMENTS_CONSTRUCTOR));

  const [tabs] = useState([
    {
      title: t(`common.data`),
      page: 1,
    },
    {
      title: t(`paymentConstructor.paymentHints`),
      page: 2,
    },
  ]);

  const forms = [CREATE_MANUAL_PS_FORM, CREATE_MANUAL_PS_HINT_FORM];

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      forms.forEach((form) => {
        dispatch(destroy(form));
      });
    };
  }, []);

  const onFirstSubmit = () => {
    setPage((page) => page + 1);
  };

  const onSecondSubmit = useCallback(
    (formValues: any) => {
      const finalValues = {
        ...formValues,
        ...values,
      };

      dispatch(createPaymentSystem(finalValues));
    },
    [values]
  );

  return (
    <WizardForm
      title="paymentConstructor.creatingAManualPS"
      actionCreators={paymentsConstructorActionCreators}
      activePage={page}
      setPage={setPage}
      tabs={tabs}
      formName={CREATE_MANUAL_PS_FORM}
      forms={forms}
      submitText="common.create"
      disabledSubmitBtn={submitting}
      errors={errors}
      submitSucceeded={submitSucceeded}
      disabledNextStep={!values}
    >
      {page === 1 && <CreationPSData onSubmit={onFirstSubmit} />}
      {page === 2 && <CreationPSHintsData onSubmit={onSecondSubmit} />}
    </WizardForm>
  );
};

export default CreatingManualPSModal;
