import { useState } from 'react';
import { useSelector } from 'react-redux';

import { hasAccessSelector } from 'selectors/auth';
import { Permission, TransactionsColumn, WidgetType } from 'utils/enums';
import { ITableTitleField } from 'interfaces/widgets/widgets';

const useTransactionsTableTitles = (widget: string): ITableTitleField[] => {
  const typeDisplayingAccess = useSelector(hasAccessSelector(Permission.DISPLAY_TRANSACTION_TYPE));
  const postbacksAccess = useSelector(hasAccessSelector(Permission.POSTBACKS));
  const walletNumberAccess = useSelector(hasAccessSelector(Permission.WALLET_NUMBER));
  const commissionViewAccess = useSelector(hasAccessSelector(Permission.COMMISSION_VIEW));
  const transactionListAccess = useSelector(hasAccessSelector(Permission.TRANSACTION_LIST));
  const hasAccessToBalances = useSelector(hasAccessSelector(Permission.WALLET_BALANCE));
  const displayTransactionTypePermission = useSelector(hasAccessSelector(Permission.DISPLAY_TRANSACTION_TYPE));

  const [titles] = useState([
    {
      title: `common.ID`,
      id: TransactionsColumn.ID,
      desktop: true,
      mobile: true,
      disabled: false,
      hide: widget === WidgetType.TRANSACTIONS_ONLY_BY_ID,
    },
    {
      title: `wallets.walletType`,
      id: TransactionsColumn.WALLET_TYPE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `wallets.walletIdentifier`,
      id: TransactionsColumn.WALLET_IDENTIFIER,
      desktop: true,
      mobile: true,
      disabled: true,
      hide:
        widget === WidgetType.TRANSACTIONS_ONLY_BY_ID
          ? !walletNumberAccess
          : walletNumberAccess && !transactionListAccess,
    },
    {
      title: `transactions.transactionAmount`,
      id: TransactionsColumn.TRANSACTION_AMOUNT,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `transactions.commission`,
      id: TransactionsColumn.COMISSION_AMOUNT,
      desktop: true,
      mobile: true,
      hide: !commissionViewAccess,
    },
    {
      title: `common.balance`,
      id: TransactionsColumn.BALANCE,
      desktop: true,
      mobile: true,
      disabled: false,
      hide: !hasAccessToBalances,
    },
    {
      title: `transactions.transactionNumber`,
      id: TransactionsColumn.TRANSACTION_NUMBER,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `transactions.exchanger`,
      id: TransactionsColumn.EXCHANGER,
      desktop: true,
      mobile: true,
    },
    {
      title: `common.date`,
      id: TransactionsColumn.DATE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.labelOrderId`,
      id: TransactionsColumn.LABEL_ORDER_ID,
      desktop: true,
      mobile: true,
    },
    {
      title: `common.activatedAt`,
      id: TransactionsColumn.ACTIVATION_DATE,
      desktop: true,
      mobile: true,
    },
    ...(widget === WidgetType.ARCHIVED_TRANSACTIONS
      ? [
          {
            title: `common.archivedAt`,
            id: TransactionsColumn.Archived_DATE,
            desktop: true,
            mobile: true,
          },
        ]
      : []),
    {
      title: `common.status`,
      id: TransactionsColumn.STATUS,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `transactions.postbackStatus`,
      id: TransactionsColumn.POSTBACK_STATUS,
      desktop: true,
      mobile: true,
      hide: !postbacksAccess,
    },
    {
      title: `transactions.transactionType`,
      id: TransactionsColumn.TRANSACTION_KIND,
      desktop: true,
      mobile: true,
      hide: !typeDisplayingAccess,
    },
    {
      title: `transactions.kindOfTransaction`,
      id: TransactionsColumn.TRANSACTION_TYPE,
      desktop: true,
      mobile: true,
      hide: !displayTransactionTypePermission,
    },
    ...(widget !== WidgetType.ARCHIVED_TRANSACTIONS
      ? [
          {
            title: `common.actions`,
            id: TransactionsColumn.ACTIONS,
            desktop: true,
            mobile: true,
            disabled: true,
          },
        ]
      : []),
  ]);

  return titles;
};

export default useTransactionsTableTitles;
