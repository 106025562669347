import { useCallback } from 'react';
import get from 'lodash/get';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import toNumber from 'lodash/toNumber';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import { ProxyStatus, WidgetType } from 'utils/enums';
import { widgetSelector, ISelectData } from '@kassma-team/kassma-toolkit/lib';
import { useTranslation } from 'react-i18next';

import { IProxiesItems } from 'interfaces/widgets/proxies';

interface IProps {
  formName: string;
  proxyFieldName: string;
  listName: string;
}

const useAvailableProxiesCallback = ({
  formName,
  proxyFieldName,
  listName,
}: IProps): ((uuid: string) => ISelectData[]) => {
  const [t] = useTranslation();
  const formValues = useSelector(getFormValues(formName)) || {};

  const { items: proxyList }: Record<`items`, IProxiesItems[]> = useSelector(widgetSelector(WidgetType.PROXIES));

  return useCallback(
    (currentProxyId) => {
      const list = get(formValues, listName);
      const selectedProxies = map(list, (item) => get(item, proxyFieldName));

      const availableProxyList = !isEmpty(proxyList)
        ? filter(
            proxyList,
            ({ uuid, status }) =>
              uuid === currentProxyId || (!includes(selectedProxies, uuid) && status === ProxyStatus.SUCCESS)
          )
        : proxyList;

      return map(availableProxyList, ({ id, uuid, ip, port, status, username, password, type, country_code }) => {
        let text = ip && ip.match(/[A-Za-z]+/g) && type !== `http` ? `${username} : ${password}` : `${ip}:${port}`;
        if (type) {
          text = `${type} | ` + text;
        }
        if (country_code) {
          text = `${country_code} | ` + text;
        }
        text = `${id} | ` + text;

        return { value: uuid, text, status };
      });
    },
    [formValues, proxyList]
  );
};

export default useAvailableProxiesCallback;
