import { widgetActionCreators, IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { CREATE_PAYMENT_HINT_FORM_NAME, UPDATE_PAYMENT_HINT_FORM_NAME } from 'utils/constants';

export const paymentHintsActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.PAYMENT_HINTS_CONSTRUCTOR,
  labels: { listLabel: `suggestions`, itemLabel: `field` },
  creatingForm: CREATE_PAYMENT_HINT_FORM_NAME,
  listItemUpdatingForm: UPDATE_PAYMENT_HINT_FORM_NAME,
  withPagination: false,
  fieldAsErrorBlock: [`translations`],
});
