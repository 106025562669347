import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import { ICreateSettlementForm } from 'interfaces/widgets/settlement';
import { ITransferringMoneyToSettlementBalanceForm } from 'interfaces/widgets/balances/balances';

export class SettlementApi extends BaseApi {
  createExternalReplenishment = (data: ICreateSettlementForm): AxiosPromise =>
    this.makeFetch(`external-replenishment`, { data, method: `POST` });

  createReplenishment = (data: Omit<ITransferringMoneyToSettlementBalanceForm, `crediting`>): AxiosPromise =>
    this.makeFetch(`replenishment`, { data, method: `PUT` });

  createReplenishmentFromBalance = (data: Omit<ITransferringMoneyToSettlementBalanceForm, `crediting`>): AxiosPromise =>
    this.makeFetch(`replenish-from-balance`, { data, method: `POST` });

  loadReplenishment = (): AxiosPromise => this.makeFetch(`replenishment`);

  loadSettlementBalance = (): AxiosPromise => this.makeFetch(`balance`);
}

const settlementApi = new SettlementApi(`settlement`);

export default settlementApi;
