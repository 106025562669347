import React from 'react';
import ReactDOM from 'react-dom';

import App from 'App';
import * as serviceWorker from 'serviceWorker';

import 'react-loading-skeleton/dist/skeleton.css';
import 'styles/index.css';
import '@kassma-team/kassma-toolkit/lib/styles/index.scss';

// if (process.env.NODE_ENV === `development`) {
//   import(`./mocks/browser`).then(({ worker }) => {
//     worker.start();
//   });
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById(`root`)
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
