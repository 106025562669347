import { Package, PackageTranslation } from 'api/packages/PackagesApi';
import React, { FC, useEffect, useRef, useState, ChangeEvent } from 'react';
import cn from 'classnames';
// @ts-expect-error Именованный импорт из библиотеки не отрабатывает должныи образом
import { motion } from 'framer-motion/dist/framer-motion';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import { Field, AddButton } from 'pages/constructor/DefaultTranslates/common/components';
import { isTranslateWasModified } from 'pages/constructor/DefaultTranslates/common/helpers';
import { useLanguagesCtx } from 'pages/constructor/DefaultTranslates/common/ctx';
import {
  useModifiedPackageTranslationsCtx,
  useTranslationsCtx,
} from 'pages/constructor/DefaultTranslates/PackagesTranslation/ctx';

import './TranslateDropdown.scss';

type Props = {
  packageTranslation: PackageTranslation;
  otherTranslationsFormField: Array<PackageTranslation>;
  packageName: Package[`name`];
  is_manual: Package[`is_manual`];

  isDisabled?: boolean;

  fieldId: string;

  children?: never;
};

export const TranslateDropdown: FC<Props> = ({
  packageTranslation,
  packageName,
  fieldId,
  otherTranslationsFormField,
  isDisabled,
  is_manual,
}) => {
  const { languages } = useLanguagesCtx();
  const { modifiedPackageTranslations, setModifiedPackageTranslations } = useModifiedPackageTranslationsCtx();
  const { handleUpdateTranslation, fetchPackages } = useTranslationsCtx();
  const [isWithoutEngErr, setIsWithoutEngErr] = useState(false);

  const [isOpened, setIsOpened] = useState(false);

  const [localPackage, setLocalPackage] = useState<PackageTranslation>(packageTranslation);
  const initialPackage = useRef<PackageTranslation>(packageTranslation);

  const isWasM = isTranslateWasModified({
    initial: (initialPackage.current as unknown) as Package,
    current: (localPackage as unknown) as Package,
  });

  const [t] = useTranslation();

  useEffect(() => {
    if (isWasM) {
      localPackage && setModifiedPackageTranslations([...modifiedPackageTranslations, localPackage]);
    } else {
      setModifiedPackageTranslations(modifiedPackageTranslations.filter((el) => el.field !== localPackage?.field));
    }
  }, [isWasM]);

  const handleToggleHint = () => {
    setIsOpened((prev) => !prev);
  };

  const handleAddTranslate = () => {
    setLocalPackage({
      ...localPackage,
      translations: [
        ...localPackage.translations,
        {
          language: ``,
          text: ``,
          clientId: `clientId-${v4()}`,
        },
      ],
    });
  };

  const handleChangeLang = (language: string, clientId?: string) => (
    option: { value: string; text: string } | string
  ) => {
    if (typeof option === `object`) {
      if (option.value === `en` && isWithoutEngErr) setIsWithoutEngErr(false);
    }

    if (typeof option === `string`) {
      if (option === `en` && isWithoutEngErr) setIsWithoutEngErr(false);
    }

    if (clientId) {
      setLocalPackage({
        ...localPackage,
        translations: localPackage.translations.map((trans) => {
          if (trans.clientId === clientId) {
            return { ...trans, language: typeof option === `object` ? option.value : option };
          }

          return trans;
        }),
      });

      return;
    }

    setLocalPackage({
      ...localPackage,
      translations: localPackage.translations.map((trans) => {
        if (trans.language === language) {
          return { ...trans, language: typeof option === `object` ? option.value : option };
        }

        return trans;
      }),
    });
  };

  const handleChangeText = (language: string, clientId?: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const content = e.currentTarget.value;

    if (language === `en`) {
      const isEmptyError = !content.length;

      if (isEmptyError) {
        setIsWithoutEngErr(true);
      } else {
        setIsWithoutEngErr(false);
      }
    }

    if (clientId) {
      setLocalPackage({
        ...localPackage,
        translations: localPackage.translations.map((trans) => {
          if (trans.clientId === clientId) {
            return {
              ...trans,
              text: content,
            };
          }

          return trans;
        }),
      });

      return;
    }

    setLocalPackage({
      ...localPackage,
      translations: localPackage.translations.map((trans) => {
        if (trans.language === language) {
          return {
            ...trans,
            text: content,
          };
        }

        return trans;
      }),
    });
  };

  const handleRemove = (language: string) => () => {
    setLocalPackage({
      ...localPackage,
      translations: localPackage.translations.filter((trans) => trans.language !== language),
    });
  };

  return (
    <div className="TranslateDropdown">
      <div
        onClick={handleToggleHint}
        className={cn(`TranslateDropdown__header`, {
          [`TranslateDropdown__header--open`]: isOpened,
        })}
      >
        <h4 className="TranslateDropdown__header_name">{packageTranslation.name}</h4>
        <button
          className={cn(`TranslateDropdown__header_toggler`, {
            'TranslateDropdown__header_toggler--open': isOpened,
          })}
          type="button"
        >
          <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.13086 6.96729L7.06539 1.03275L12.9999 6.96729" stroke="#3D3D3B" />
          </svg>
        </button>
      </div>

      {isOpened && !isDisabled && <AddButton onClick={handleAddTranslate} />}

      <motion.ul
        animate={isOpened ? `visible` : `hidden`}
        initial="hidden"
        transition={{ duration: 0.3 }}
        variants={{
          hidden: { height: 0, opacity: 0, overflow: `hidden` },
          visible: { height: `auto`, overflow: `auto`, opacity: 1 },
        }}
        className="TranslateDropdown__translations"
      >
        {localPackage.translations.map(({ language, text, clientId }, i) => {
          return (
            <li key={`${i}-${language}`} className="TranslateDropdown__translation_translation">
              <Field
                lang={language}
                text={text}
                langs={languages}
                isDisabled={isDisabled}
                selectedLangs={localPackage.translations.map(({ language }) => language)}
                onChangeLang={handleChangeLang(language, clientId)}
                onRemove={handleRemove(language)}
                useTranslateWithEffect={true}
                onChangeText={handleChangeText(language, clientId)}
                isRemoveBtnShowed={language !== `en`}
              />
            </li>
          );
        })}
      </motion.ul>

      {isWithoutEngErr && <p className="TranslatesList__error">{t(`common.engIsRequired`)}</p>}

      {isWasM && isOpened && !isDisabled && (
        <>
          <button
            className="TranslatesList__save-btn"
            onClick={() => {
              if (!localPackage?.translations.some(({ language }) => language === `en`)) {
                setIsWithoutEngErr(true);

                return;
              }

              handleUpdateTranslation(fieldId, {
                name: packageName,
                is_manual: is_manual,
                packages_translations: [
                  ...otherTranslationsFormField
                    .filter((el) => el.field !== packageTranslation.field)
                    .map((el) => {
                      return {
                        field_uuid: el.field,
                        translations: el.translations.map((el) => ({ language: el.language, text: el.text })),
                      };
                    }),

                  {
                    field_uuid: localPackage.field,
                    translations: localPackage.translations.map((el) => ({ language: el.language, text: el.text })),
                  },
                ],
              })().then(() => fetchPackages());
            }}
          >
            {t(`common.save`)}
          </button>
        </>
      )}
    </div>
  );
};
