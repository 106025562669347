import React, { useCallback, useRef, useState } from 'react';
import { useMount } from 'react-use';
import map from 'lodash/map';
import get from 'lodash/get';
import { WalletImg } from '@kassma-team/kassma-toolkit/lib';
import useFullAmount from 'hooks/useFullAmount';
import { IAccountBalancesItems, IAccountBalancesItemsBalances } from 'interfaces/widgets/balances/balances';

import './balanceRow.scss';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { paymentSystemLogoSelector } from '../../../selectors/widgets/paymentSystems';

const SCROLL_DISTANCE = 300;

interface IWalletProps {
  amount: string;
  currencyCode: string;
  paymentSystem: string;
  id?: string;
}

interface IBalanceRowProps extends Omit<IAccountBalancesItems, `balances`> {
  wallets: IAccountBalancesItemsBalances[];
}

const Wallet = ({ amount, currencyCode, id }: IWalletProps) => {
  const fullAmount = useFullAmount({ amount, currencyCode });

  const dispatch = useDispatch();

  const handleClick = () => {
    if (id) {
      dispatch(push(`/operation-history?tab=1&id=${id}`));
    }
  };

  return (
    <button onClick={handleClick} className="balance-row__cell balance-row__cell--wallet">
      <span>{fullAmount}</span>
    </button>
  );
};

const BalanceRow = ({ payment_system, additional_info, wallets }: IBalanceRowProps): JSX.Element => {
  const [overflow, setOverflow] = useState<boolean>(false);

  const walletsRef = useRef<any>();

  useMount(() => {
    setOverflow(walletsRef?.current?.scrollWidth > walletsRef.current.offsetWidth);
  });

  let logo = useSelector(paymentSystemLogoSelector(payment_system));

  let title;
  if (additional_info) {
    logo = get(additional_info, `logo`);
    title = get(additional_info, `name`);
  } else {
    title = payment_system;
  }

  const scroll = useCallback(
    (direction) => {
      const currentScroll = walletsRef.current.scrollLeft;
      let newScroll = currentScroll;
      switch (direction) {
        case `right`:
          newScroll += SCROLL_DISTANCE;
          break;
        case `left`:
          newScroll -= SCROLL_DISTANCE;
          break;
      }
      walletsRef.current.scrollTo({
        left: newScroll,
        behavior: `smooth`,
      });
    },
    [walletsRef]
  );

  return (
    <div className="balance-row">
      <div className="balance-row__cell balance-row__cell--payment-system">
        <WalletImg title={title} className="balance-row__logo" src={logo} />
      </div>
      {overflow && (
        <button className="balance-row__cell balance-row__cell--arrow" onClick={() => scroll(`left`)}>
          &#8249;
        </button>
      )}
      <div className="balance-row__wallets" ref={walletsRef}>
        {map(wallets, ({ amount, currency_code, id }, index) => (
          <Wallet paymentSystem={payment_system} amount={amount} currencyCode={currency_code} key={index} id={id} />
        ))}
      </div>
      {overflow && (
        <button className="balance-row__cell balance-row__cell--arrow" onClick={() => scroll(`right`)}>
          &#8250;
        </button>
      )}
    </div>
  );
};

export default BalanceRow;
