import React, { FC, ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';
import { IconButton } from 'pages/constructor/DefaultTranslates/common/components';

import './EditFormType.scss';

type Props = {
  title: string;

  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onApply: () => void;
  onRemove: () => void;

  children?: never;
};

export const EditFormType: FC<Props> = (props) => {
  const { title, onChange, onApply, onRemove } = props;

  return (
    <div className="EditFormType-edit">
      <div className="EditFormType-edit__triangle" />
      <TextField className="EditFormType-edit__field" value={title} onChange={onChange} variant="standard" />
      <div>
        <IconButton variant="apply" className="FormType__apply-btn" onClick={onApply} />
        <IconButton variant="remove" onClick={onRemove} />
      </div>
    </div>
  );
};
