import { useState } from 'react';

import { LogsColumn } from 'utils/enums';
import { ITableTitleField } from 'interfaces/widgets/widgets';

const useLogsTableTitles = (): ITableTitleField[] => {
  const [titles] = useState([
    {
      title: `wallets.walletType`,
      id: LogsColumn.WALLET_TYPE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `wallets.walletIdentifier`,
      id: LogsColumn.IDENTIFIER,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `logs.errorType`,
      id: LogsColumn.ERROR_TYPE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `logs.category`,
      id: LogsColumn.CATEGORY,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `logs.message`,
      id: LogsColumn.MESSAGE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.date`,
      id: LogsColumn.DATE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
  ]);

  return titles;
};

export default useLogsTableTitles;
