import { Package } from 'api/packages/PackagesApi';
import { Hint } from 'api/translations/TranslationsApi';

import { CustomTranslate } from './CustomTranslate';

const isPackageTranslation = (customTranslate: CustomTranslate): customTranslate is Package => {
  return (customTranslate as Package).packages_translations !== undefined;
};

const isPaymentTranslation = (customTranslate: CustomTranslate): customTranslate is Hint => {
  return (customTranslate as Hint).translations !== undefined;
};

export { isPackageTranslation, isPaymentTranslation };
