import React from 'react';

import { WidgetType } from 'utils/enums';
import { reportsActionCreators } from 'actions/widgets/reports';

import InfoModal from 'components/modals/InfoModal';

const ReportWaitingInfo = () => {
  return <InfoModal widget={WidgetType.REPORTS} actionCreators={reportsActionCreators} title="common.attention" />;
};

export default ReportWaitingInfo;
