import React from 'react';

import { disabledWalletActionCreators } from 'actions/widgets/wallet';
import { WidgetType } from 'utils/enums';

import WalletUpdatingForm from 'components/widgets/wallets/WalletUpdatingForm';
import { useSelector } from 'react-redux';
import {
  useProxyNightParserSelector,
  useProxySelector,
} from '../../../../selectors/widgets/constructor/paymentsConstructor';

const DisabledWalletUpdating = () => {
  //TODO: КОСТЫЛЬ, используется для валидации кошельков
  const useProxy = useSelector(useProxySelector);
  const useProxyNightEmailParser = useSelector(useProxyNightParserSelector);

  return (
    <WalletUpdatingForm
      useProxy={useProxy}
      useProxyNightEmailParser={useProxyNightEmailParser}
      actionCreators={disabledWalletActionCreators}
      widget={WidgetType.DISABLED_WALLETS}
    />
  );
};

export default DisabledWalletUpdating;
