import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import keys from 'lodash/keys';
import without from 'lodash/without';
import find from 'lodash/find';
import includes from 'lodash/includes';
import { useSelector } from 'react-redux';
import { ResponsiveContainer, LineChart, XAxis, YAxis, Tooltip, CartesianGrid, Line, Legend } from 'recharts';
import { useDesktopSize } from '@kassma-team/kassma-toolkit/lib';

import { selectionOfAllManualPSSelector } from 'selectors/widgets/manualPS';
import yAxisTickFormatter from 'utils/dashboard/yAxisTickFormatter';
import { walletColor } from './constants';

import CustomTooltip from './CustomTooltip';
import { IChartProps } from 'interfaces/dashboard';

const LineChartWrapper = ({ chartData, isSum }: IChartProps) => {
  const [lines, setLines] = useState<string[]>([]);
  const [hiddenLines, setHiddenLines] = useState<string[]>([]);

  useEffect(() => {
    setLines(without(keys(get(chartData, `[0]`, {})), `date`));
  }, [chartData]);

  const desktopSize = useDesktopSize();

  const testPayments = useSelector(selectionOfAllManualPSSelector);

  const selectBar = (event: Record<string, any>) => {
    if (includes(hiddenLines, event.value)) {
      return setHiddenLines(without(hiddenLines, event.value));
    }
    setHiddenLines([...hiddenLines, event.value]);
  };

  if (!chartData) return null;

  return (
    <ResponsiveContainer minWidth={296} minHeight={168} width="100%" height="100%" aspect={1.895}>
      <LineChart data={chartData} margin={{ top: 5, right: 30, left: 0, bottom: 40 }}>
        <XAxis dataKey="date" type="category" angle={-45} tickMargin={30} minTickGap={1} />
        <YAxis padding={{ top: 10 }} tickFormatter={yAxisTickFormatter} />
        <Tooltip
          isAnimationActive={false}
          content={<CustomTooltip testPayments={testPayments} isSum={isSum as boolean} />}
          wrapperStyle={{
            zIndex: 2,
            pointerEvents: `all`,
            backgroundColor: `rgb(255, 255, 255)`,
            border: `1px solid rgb(204, 204, 204)`,
            margin: 0,
            padding: 10,
            top: desktopSize ? 0 : 48,
          }}
          coordinate={{ x: 0, y: 20 }}
        />
        <Legend
          onClick={selectBar}
          verticalAlign="top"
          height={48}
          wrapperStyle={{ overflowY: `scroll` }}
          iconType="rect"
          formatter={(value) => {
            const isHidden = includes(hiddenLines, value);
            const testPaymentName = get(find(testPayments, { value: value }), `text`);

            return (
              <span style={{ textDecoration: isHidden ? `line-through` : `none` }}>{testPaymentName || value}</span>
            );
          }}
        />
        <CartesianGrid stroke="#f5f5f5" />
        {lines.map((line, index) => {
          return (
            <Line
              type="monotone"
              dataKey={(record) => (includes(hiddenLines, line) ? null : get(record, `${line}.value`))}
              key={line}
              name={line}
              stroke={walletColor[index]}
              strokeWidth={2}
              isAnimationActive={false}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};

LineChartWrapper.defaultProps = {
  isSum: false,
};

export default LineChartWrapper;
