import { IAccountBalancesItems } from 'interfaces/widgets/balances/balances';
import filter from 'lodash/filter';
import map from 'lodash/map';

type filterBalanceGroupsType = (
  balancesGroupedByPS: IAccountBalancesItems[],
  hideZeroBalances: boolean
) => IAccountBalancesItems[];

export const filterBalanceGroups: filterBalanceGroupsType = (balancesGroupedByPS, hideZeroBalances) =>
  map(balancesGroupedByPS, (group) => {
    if (!hideZeroBalances) {
      return group;
    }

    const noZeroBalances = filter(group.balances, (balance) => {
      const balanceAmount = parseInt(balance.amount, 10);

      return balanceAmount !== 0;
    });

    if (noZeroBalances.length !== 0) {
      return { ...group, balances: noZeroBalances };
    }

    return null;
  }).filter((group) => group !== null) as IAccountBalancesItems[];
