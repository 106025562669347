import { takeEvery, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { IAction } from '@kassma-team/kassma-toolkit';

import refreshSaga from 'sagas/effects/refreshSaga';
import {
  MARK_AS_SENT,
  RESEND_POSTBACK_REQUESTED,
  RESEND_WITHDRAWALS_POSTBACK_REQUESTED,
  RESET_ATTEMPT_COUNTER,
} from 'actionTypes';
import { notAcceptedPostbackActionCreators } from 'actions/widgets/postback';
import i18n from 'i18n';
import stockpilingApi from 'api/stockpiling/StockpilingApi';
import postbackApi from 'api/postback/PostbackApi';

interface IResendPostbackProps {
  id: number;
  stockpiling_id: number;
}

function* resendPostbackSaga({ payload: { id, stockpiling_id } }: Record<`payload`, IResendPostbackProps>) {
  yield refreshSaga({
    request: () => stockpilingApi.resendPostback(stockpiling_id),
    onSuccess: function* () {
      yield put(notAcceptedPostbackActionCreators.removeSync(id));
      toastr.success(i18n.t(`common.success`), i18n.t(`postbacks.postbackHasBeenResent`));
    },
    onError: function () {
      toastr.error(i18n.t(`common.error`), i18n.t(`postbacks.postbackResendingHasBeenFailed`));
    },
  });
}

interface IResendWithdrawalsPostbackProps {
  id: number;
  withdrawal_id: number;
}

function* resendWithdrawalsPostbackSaga({ payload: { id } }: Record<`payload`, IResendWithdrawalsPostbackProps>) {
  yield refreshSaga({
    request: () => postbackApi.resendWithdrawalsPostback(id),
    onSuccess: function* () {
      yield put(notAcceptedPostbackActionCreators.removeSync(id));
      toastr.success(i18n.t(`common.success`), i18n.t(`postbacks.postbackHasBeenResent`));
    },
    onError: function () {
      toastr.error(i18n.t(`common.error`), i18n.t(`postbacks.postbackResendingHasBeenFailed`));
    },
  });
}

function* resetAttemptCounterSaga({ payload: { ids } }: any) {
  yield refreshSaga({
    request: () => postbackApi.resetCountFailure(ids),
    onSuccess: function* () {
      yield put(notAcceptedPostbackActionCreators.getList());
      toastr.success(i18n.t(`common.success`), ``);
    },
    onError: function () {
      toastr.error(i18n.t(`common.error`), ``);
    },
    onFinally: function* () {
      yield put(notAcceptedPostbackActionCreators.hideModal());
    },
  });
}

function* markAsSentSaga({ payload: { ids } }: any) {
  yield refreshSaga({
    request: () => postbackApi.markAsSent(ids),
    onSuccess: function* () {
      yield put(notAcceptedPostbackActionCreators.getList());
      toastr.success(i18n.t(`common.success`), ``);
    },
    onError: function () {
      toastr.error(i18n.t(`common.error`), ``);
    },
    onFinally: function* () {
      yield put(notAcceptedPostbackActionCreators.hideModal());
    },
  });
}

const postbackSagas = [
  takeEvery<IAction>(RESEND_POSTBACK_REQUESTED, resendPostbackSaga),
  takeEvery<IAction>(RESEND_WITHDRAWALS_POSTBACK_REQUESTED, resendWithdrawalsPostbackSaga),
  takeEvery<IAction>(RESET_ATTEMPT_COUNTER, resetAttemptCounterSaga),
  takeEvery<IAction>(MARK_AS_SENT, markAsSentSaga),
];

export default postbackSagas;
