import { useWidgetItem as useKassmaItem, widgetSelector } from '@kassma-team/kassma-toolkit/lib';
import { useDispatch, useSelector } from 'react-redux';
import { IWidgetActionCreators, IWidgetsState } from '@kassma-team/kassma-toolkit/lib/types/widgets';
import { IUseItemResult } from '@kassma-team/kassma-toolkit/lib/hooks/widgets/useWidgetItem';

interface IProps<Item = any[]> {
  actionCreators: IWidgetActionCreators;
  loadOnlyIfNecessary?: boolean;
  widget?: string;
  queryParams?: Record<string, unknown>;
}

const useItem = <Item = any>({
  actionCreators,
  queryParams,
  loadOnlyIfNecessary,
  widget,
}: IProps): IUseItemResult<Item> => {
  const { item, itemLoading }: IWidgetsState = useSelector(widgetSelector(widget));

  const dispatch = useDispatch();

  return useKassmaItem({
    actionCreators,
    payload: queryParams,
    loadOnlyIfNecessary,
    item,
    itemLoading,
    dispatch,
  });
};

export default useItem;
