import React, { useEffect, useMemo, useState } from 'react';
import useMount from 'react-use/lib/useMount';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';
import { useTranslation } from 'react-i18next';
import { submitFilters, InputType, IModalFormProps, IFormField } from '@kassma-team/kassma-toolkit/lib';
import { Dispatch } from 'redux';

import { walletsLoadActionCreators } from 'actions/widgets/walletsLoad';
import { getFilledDateRange } from 'utils';
import {
  LoadingWalletStatus,
  WalletsLoadDirection,
  WalletsLoadType,
  WalletsLoadObject,
  WidgetType,
  TransactionStatus,
} from 'utils/enums';
import useSearchParams from 'hooks/useSearchParams';
import useFilterFormFields from 'hooks/widgets/useFilterFormFields';

import ModalForm from 'components/modals/ModalForm';
import { transactionPSSelector } from 'selectors/widgets/paymentSystems';
import { selectionOfAllManualPSSelector } from 'selectors/widgets/manualPS';
import { ILoadWalletForm } from 'interfaces/widgets/wallets/wallet';
import { init } from 'i18next';

const FORM_NAME = `wallets-load-settings`;

const onSubmit = (values: ILoadWalletForm, dispatch: Dispatch) => {
  delete values.status;
  dispatch(
    submitFilters(values, {
      onSuccess: () => dispatch(walletsLoadActionCreators.hideModal()), // Todo: check
      dateFields: [
        { name: `date_range`, fromName: `create_date_from`, toName: `create_date_to` },
        { name: `date_range_activated`, fromName: `date_from`, toName: `date_to` },
      ],
      dateFormat: `YYYY-MM-DD HH:mm:ss`,
      form: FORM_NAME,
      widget: WidgetType.WALLETS_LOAD,
    })
  );
};

const WalletsLoadSettings = ({ initialize, change, ...props }: InjectedFormProps & IModalFormProps) => {
  const [t] = useTranslation();

  const [fields, setFields] = useState<IFormField[]>([]);

  const transactionPS = useSelector(transactionPSSelector);

  const safeTransactionPS = transactionPS.map((el) => ({
    ...el,
    value: el.code,
    text: el.name,
  }));

  const testPS = useSelector(selectionOfAllManualPSSelector);

  const safeTestPS = testPS.map((el) => ({
    ...el,
    value: el.code,
    text: el.name,
  }));

  const values: Partial<ILoadWalletForm> = useSelector(getFormValues(FORM_NAME)) || {};

  const shouldWalletTypeBeDisplayed = useMemo(() => {
    const loadObject = get(values, `load_object`);

    return loadObject === WalletsLoadObject.WALLETS;
  }, [values]);

  const walletTypes = useMemo(() => {
    return [
      {
        value: null,
        text: t(`common.all`),
      },
      ...safeTransactionPS,
      ...safeTestPS,
    ];
  }, [transactionPS]);

  useEffect(() => {
    if (!shouldWalletTypeBeDisplayed) {
      change(`wallet_type`, null);
    }
  }, [shouldWalletTypeBeDisplayed]);

  const search = useSearchParams();
  const { direction, transaction_status, date_range, date_range_activated } = values;

  const directionIsNotIngoing = direction !== WalletsLoadDirection.INGOING;
  const transactionStatusIsNotActivated = transaction_status !== TransactionStatus.ACTIVATED;

  const status = values.status;

  useEffect(() => {
    if (status === LoadingWalletStatus.ACTIVE) {
      change(`is_active`, `true`);
      change(`removed`, `false`);
    } else if (status === LoadingWalletStatus.STOPPED) {
      change(`is_active`, `false`);
      change(`removed`, `false`);
    } else if (status === LoadingWalletStatus.DELETED) {
      change(`removed`, `true`);
      change(`is_active`, undefined);
    } else {
      change(`removed`, undefined);
      change(`is_active`, undefined);
    }
  }, [status]);

  useEffect(() => {
    const newFields = [
      {
        name: `direction`,
        id: `${FORM_NAME}_direction`,
        type: InputType.SELECT,
        data: [
          {
            value: WalletsLoadDirection.OUTGOING,
            text: t(`wallets.outgoing`),
          },
          {
            value: WalletsLoadDirection.INGOING,
            text: t(`wallets.ingoing`),
          },
        ],
        label: t(`wallets.direction`),
        required: true,
      },
      {
        name: `transaction_status`,
        id: `${FORM_NAME}_transaction_status`,
        type: InputType.SELECT,
        hide: directionIsNotIngoing,
        unused: directionIsNotIngoing,
        data: [
          {
            value: null,
            text: t(`common.all`),
          },
          {
            value: TransactionStatus.ACTIVATED,
            text: t(`reports.activated`),
          },
          {
            value: TransactionStatus.NOT_ACTIVATED,
            text: t(`reports.notActivated`),
          },
        ],
        label: t(`wallets.transactionStatus`),
        placeholder: t(`common.all`),
      },
      {
        name: `load_object`,
        id: `${FORM_NAME}_load_object`,
        type: InputType.SELECT,
        data: [
          {
            value: WalletsLoadObject.WALLETS,
            text: t(`wallets.wallets`),
          },
          {
            value: WalletsLoadObject.PAYMENT_SYSTEMS,
            text: t(`dashboard.paymentSystems`),
          },
        ],
        label: t(`wallets.loadObject`),
        required: true,
      },
      {
        name: `wallet_type`,
        id: `${FORM_NAME}_wallet_type`,
        type: InputType.SELECT,
        data: walletTypes,
        hide: !shouldWalletTypeBeDisplayed,
        unused: !shouldWalletTypeBeDisplayed,
        placeholder: t(`common.all`),
        label: t(`wallets.wallet_type`),
      },
      {
        name: `load_type`,
        id: `${FORM_NAME}_load_type`,
        type: InputType.SELECT,
        data: [
          {
            value: WalletsLoadType.TRANSACTIONS_COUNT,
            text: t(`common.quantity`),
          },
          {
            value: WalletsLoadType.TRANSACTIONS_SUM,
            text: t(`common.sum`),
          },
        ],
        label: t(`wallets.loadType`),
        required: true,
      },
      {
        name: `status`,
        id: `${FORM_NAME}_status`,
        type: InputType.SELECT,
        data: [
          {
            value: null,
            text: t(`common.all`),
          },
          {
            value: LoadingWalletStatus.STOPPED,
            text: t(`wallets.stoppedWallets`),
          },
          {
            value: LoadingWalletStatus.ACTIVE,
            text: t(`wallets.activeWallets`),
          },
          {
            value: LoadingWalletStatus.DELETED,
            text: t(`wallets.deletedWallets`),
          },
        ],
        label: t(`wallets.walletStatus`),
        placeholder: t(`common.all`),
      },
      {
        name: `date_range`,
        fromValue: get(date_range, `values.startDate`),
        toValue: get(date_range, `values.endDate`),
        type: InputType.DATE_RANGE,
        label: t(`wallets.dateTransactionCreation`),
        timePicker: true,
        showSeconds: true,
      },
      {
        name: `date_range_activated`,
        fromValue: get(date_range_activated, `values.startDate`),
        toValue: get(date_range_activated, `values.endDate`),
        type: InputType.DATE_RANGE,
        label: t(`wallets.dateTransactionActivation`),
        timePicker: true,
        showSeconds: true,
        hide: directionIsNotIngoing || transactionStatusIsNotActivated,
        unused: directionIsNotIngoing || transactionStatusIsNotActivated,
      },
    ];
    setFields(newFields);
  }, [direction, transaction_status, date_range, date_range_activated, shouldWalletTypeBeDisplayed]);

  useMount(() => {
    const {
      date_from,
      date_to,
      direction,
      load_type,
      transaction_status,
      load_object,
      create_date_from,
      create_date_to,
      wallet_type,
      is_active,
      removed,
    } = search;
    if (direction && load_type) {
      let status = null;
      const active = is_active === `true`;
      const rem = removed === `true`;
      if (active && !rem) {
        status = LoadingWalletStatus.ACTIVE;
      } else if (is_active === `false` && removed === `false`) {
        status = LoadingWalletStatus.STOPPED;
      } else if (rem) {
        status = LoadingWalletStatus.DELETED;
      }
      const initData = {
        date_range: getFilledDateRange(create_date_from as string, create_date_to as string),
        transaction_status: transaction_status ? toNumber(transaction_status) : undefined,
        date_range_activated: getFilledDateRange(date_from as string, date_to as string),
        direction,
        load_type,
        load_object,
        status,
        wallet_type,
      };
      initialize(initData);
    }
  });

  const filteredFields = useFilterFormFields({ fields, formName: FORM_NAME });

  return (
    <ModalForm
      {...props}
      onSubmit={onSubmit}
      fields={filteredFields}
      formName={FORM_NAME}
      submitText="common.select"
      widget={WidgetType.WALLETS_LOAD}
      actionCreators={walletsLoadActionCreators}
      title="wallets.loadPreviewSetting"
    />
  );
};

export default reduxForm({ form: FORM_NAME })(WalletsLoadSettings);
