import { takeEvery, put, select } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import get from 'lodash/get';
import trim from 'lodash/trim';
import size from 'lodash/size';
import replace from 'lodash/replace';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import FileSaver from 'file-saver';
import { AxiosError, AxiosResponse } from 'axios';
import {
  formSubmissionError,
  validateForm,
  WIDGET_SET_SUBMITTING_STATUS,
  IWidgetsMeta,
  IAction,
  WIDGET_SET_MODAL_ERROR,
} from '@kassma-team/kassma-toolkit/lib';

import refreshSaga from 'sagas/effects/refreshSaga';
import {
  APPROVE_WITHDRAWAL_REQUESTED,
  APPROVE_WITHDRAWAL_SUCCEEDED,
  CONFIRM_REJECTION_OF_WITHDRAWAL_REQUESTED,
  CONFIRM_REJECTION_OF_WITHDRAWAL_SUCCEEDED,
  CONFIRM_APPROVAL_OF_WITHDRAWAL_REQUESTED,
  CONFIRM_APPROVAL_OF_WITHDRAWAL_SUCCEEDED,
  REJECT_WITHDRAWAL_REQUESTED,
  REJECT_WITHDRAWAL_SUCCEEDED,
  UPDATE_WITHDRAWAL_COMMENT,
  UPLOAD_MANUAL_WITHDRAWALS_REQUESTED,
  DOWNLOAD_MANUAL_WITHDRAWALS_REQUESTED,
  CREATE_WITHDRAWAL_REQUESTED,
} from 'actionTypes';
import { withdrawalsActionCreators } from 'actions/widgets/withdrawals';
import i18n from 'i18n';
import { dateFormat, normalizeTextEditorValue, getErrorMessage } from 'utils';
import { ModalType, WalletType, WithdrawalType } from 'utils/enums';
import { paygigaAmountByIdSelector } from 'selectors/widgets/withdrawals';
import {
  MANUAL_WITHDRAWALS_DOWNLOADING_FORM_NAME,
  MANUAL_WITHDRAWALS_UPLOADING_FORM_NAME,
  WITHDRAWAL_COMMENT_FORM_NAME,
} from 'utils/constants';
import { IApproveWithdrawalFrom, ICreateWithdrawalForm, IDownloadWithdrawalForm } from 'interfaces/widgets/withdrawals';
import { IComment } from 'interfaces/widgets/paymentSystems';
import withdrawalApi from 'api/withdrawals/WithdrawalApi';
import withdrawalV1Api from 'api/withdrawals/WithdrawalV1Api';

interface IRejectWithdrawalProps {
  payload: Record<`comment`, IComment>;
  meta: IWidgetsMeta;
}

function* rejectWithdrawalSaga({ payload, meta }: IRejectWithdrawalProps) {
  const valid: boolean = yield validateForm({ form: `reject-withdrawal-form`, meta });
  if (!valid) {
    return;
  }

  const data = { ...payload, comment: normalizeTextEditorValue(payload?.comment) };

  yield refreshSaga({
    request: () => withdrawalApi.rejectWithdrawal(meta.id, data),
    onSuccess: function* () {
      yield put({ meta, type: REJECT_WITHDRAWAL_SUCCEEDED, payload: meta.id });
      yield put(withdrawalsActionCreators.hideModal());
      toastr.success(i18n.t(`common.success`), i18n.t(`withdrawals.withdrawalHasBeenSuccessfullyRejected`));
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

interface IApproveWithdrawalProps {
  payload: IApproveWithdrawalFrom;
  meta: IWidgetsMeta;
}

function* approveWithdrawalSaga({ payload, meta = {} }: IApproveWithdrawalProps) {
  const form = `approve-withdrawal-form`;
  const valid: boolean = yield validateForm({ form, meta });
  if (!valid) {
    return;
  }
  yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: true, meta });

  const data = omit(payload, `isManualFill`);
  if (data.comment) {
    const commentHtml = get(data, `comment.html`);
    const commentValue = get(data, `comment.value`);
    data.comment = size(trim(commentValue)) > 0 && commentHtml ? commentHtml : undefined;
  }

  yield refreshSaga({
    request: () => withdrawalApi.approveWithdrawal(meta.id, data),
    onSuccess: function* () {
      yield put({ meta, type: APPROVE_WITHDRAWAL_SUCCEEDED, payload: meta.id });
      yield put(withdrawalsActionCreators.hideModal());
      toastr.success(i18n.t(`common.success`), i18n.t(`withdrawals.withdrawalHasBeenSuccessfullyApproved`));
      yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
    },
    onError: function* (e: AxiosError) {
      yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      const message = getErrorMessage(e);
      if (meta.walletType === WalletType.DUSUPAY) {
        yield formSubmissionError({
          payload: message,
          meta,
          form,
        });
      } else {
        toastr.error(i18n.t(`common.error`), message);
      }
    },
    callErrorWhenNoPermissions: true,
  });
}

function* confirmApprovalOfWithdrawalSaga({ meta }: Record<`meta`, IWidgetsMeta>) {
  yield refreshSaga({
    request: () => withdrawalV1Api.confirmApprovalOfWithdrawal(meta.withdrawal_id),
    onSuccess: function* () {
      yield put({ meta, type: CONFIRM_APPROVAL_OF_WITHDRAWAL_SUCCEEDED, payload: meta.id });
      yield put(withdrawalsActionCreators.hideModal());
      toastr.success(i18n.t(`common.success`), i18n.t(`withdrawals.withdrawalHasBeenSuccessfullyApproved`));
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

function* confirmRejectionOfWithdrawalSaga({ meta }: Record<`meta`, IWidgetsMeta>) {
  yield refreshSaga({
    request: () => withdrawalV1Api.confirmRejectionOfWithdrawal(meta.withdrawal_id),
    onSuccess: function* () {
      yield put({ meta, type: CONFIRM_REJECTION_OF_WITHDRAWAL_SUCCEEDED, payload: meta.id });
      yield put(withdrawalsActionCreators.hideModal());
      toastr.success(i18n.t(`common.success`), i18n.t(`withdrawals.withdrawalHasBeenSuccessfullyRejected`));
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

interface IUpdateCommentProps {
  meta: IWidgetsMeta;
  payload: Record<`comment`, string>;
}

function* updateCommentSaga({ meta, payload }: IUpdateCommentProps) {
  const valid: boolean = yield validateForm({ form: WITHDRAWAL_COMMENT_FORM_NAME, meta });
  if (!valid) {
    return;
  }

  if (payload.comment) {
    const commentHtml = get(payload, `comment.html`);
    const commentValue = get(payload, `comment.value`);
    payload.comment = size(trim(commentValue)) > 0 && commentHtml ? commentHtml : ``;
  }

  yield refreshSaga({
    request: () => withdrawalApi.updateWithdrawalComment(meta.id, payload),
    onSuccess: function* () {
      yield toastr.success(i18n.t(`common.success`), i18n.t(`withdrawals.commentHasBeenSuccessfullyUpdated`));
      yield put(withdrawalsActionCreators.hideModal());
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

function* createWithdrawalSaga({ payload, meta }: Record<`payload`, ICreateWithdrawalForm>) {
  const amount = +payload?.amount;
  const { comment, account_number, payment_system, ...rest } = payload;
  const normalizedComment = normalizeTextEditorValue(comment as IComment) || null;

  const formData = {
    payment_system,
    account_number,
    ...(!isUndefined(normalizedComment) && { comment: normalizedComment }),
    ...omit(rest, [`bankCodeType`, `transfer_type`]),
    amount,
    type: WithdrawalType.MANUAL,
  };

  if (payment_system === WalletType.PAYME_UZ) {
    formData.account_number = replace(account_number, / /g, ``);
  }
  if (payment_system === WalletType.PAY_TM && account_number) {
    formData.account_number = account_number.slice(2);
  }
  if (payment_system === WalletType.PAYGIGA) {
    formData.amount = yield select(paygigaAmountByIdSelector(amount));
  }

  yield put(
    withdrawalsActionCreators.create(formData, {
      onSuccess: function* () {
        yield put(withdrawalsActionCreators.hideModal());
      },
    })
  );
}

interface IUploadManualWithdrawalsProps {
  meta: IWidgetsMeta;
  payload: Record<`report`, string>;
}

function* uploadManualWithdrawalsSaga({ meta, payload }: IUploadManualWithdrawalsProps) {
  const valid: boolean = yield validateForm({ form: MANUAL_WITHDRAWALS_UPLOADING_FORM_NAME, meta });
  if (!valid) {
    return;
  }

  yield refreshSaga({
    request: () => withdrawalApi.uploadManualWithdrawals(payload),
    onSuccess: function* (resp: AxiosResponse) {
      const logs = get(resp, `data.logs`);
      yield put(withdrawalsActionCreators.hideModal());
      if (!isEmpty(logs)) {
        yield toastr.warning(i18n.t(`common.warning`), i18n.t(`withdrawals.unprocessedWithdrawalRequests`));
        yield put(withdrawalsActionCreators.showModal({ type: ModalType.ERROR, logs }));
      } else {
        yield toastr.success(i18n.t(`common.success`), i18n.t(`withdrawals.withdrawalsHaveBeenSuccessfullyUploaded`));
      }
    },
    onError: function* (e: AxiosError) {
      const errPayload: string = yield getErrorMessage(e);
      yield formSubmissionError({ payload: errPayload, meta, form: MANUAL_WITHDRAWALS_UPLOADING_FORM_NAME });
    },
  });
}

interface IDownloadManualWithdrawalsProps {
  meta: IWidgetsMeta;
  payload: IDownloadWithdrawalForm;
}

function* downloadManualWithdrawalsSaga({ meta, payload }: IDownloadManualWithdrawalsProps) {
  const valid: boolean = yield validateForm({ form: MANUAL_WITHDRAWALS_DOWNLOADING_FORM_NAME, meta });
  if (!valid) {
    return;
  }

  const dateFrom = dateFormat(get(payload, `date_range.values.startDate`));
  const dateTo = dateFormat(get(payload, `date_range.values.endDate`));

  const data: IDownloadWithdrawalForm = {
    ...omit(payload, `date_range`),
    date_from: dateFrom,
    date_to: dateTo,
  };

  yield refreshSaga({
    request: () => withdrawalApi.downloadManualWithdrawals(data),
    onSuccess: function* (resp: AxiosResponse) {
      yield put(withdrawalsActionCreators.hideModal());
      const file: Blob = yield new Blob([get(resp, `data`)], {});
      const paymentSystem = get(payload, `payment_system`);
      const paymentSystemStr = paymentSystem ? `_${paymentSystem}` : ``;
      yield FileSaver.saveAs(file, `manual_withdrawal_${dateFrom}_${dateTo}${paymentSystemStr}.xlsx`);
    },
    onError: function* (e: AxiosError) {
      const errPayload: string = yield getErrorMessage(e);
      yield formSubmissionError({ payload: errPayload, meta, form: MANUAL_WITHDRAWALS_DOWNLOADING_FORM_NAME });
    },
  });
}

const withdrawalsSagas = [
  takeEvery<IAction>(REJECT_WITHDRAWAL_REQUESTED, rejectWithdrawalSaga),
  takeEvery<IAction>(APPROVE_WITHDRAWAL_REQUESTED, approveWithdrawalSaga),
  takeEvery<IAction>(CONFIRM_APPROVAL_OF_WITHDRAWAL_REQUESTED, confirmApprovalOfWithdrawalSaga),
  takeEvery<IAction>(CONFIRM_REJECTION_OF_WITHDRAWAL_REQUESTED, confirmRejectionOfWithdrawalSaga),
  takeEvery<IAction>(UPDATE_WITHDRAWAL_COMMENT, updateCommentSaga),
  takeEvery<IAction>(CREATE_WITHDRAWAL_REQUESTED, createWithdrawalSaga),
  takeEvery<IAction>(UPLOAD_MANUAL_WITHDRAWALS_REQUESTED, uploadManualWithdrawalsSaga),
  takeEvery<IAction>(DOWNLOAD_MANUAL_WITHDRAWALS_REQUESTED, downloadManualWithdrawalsSaga),
];

export default withdrawalsSagas;
