import get from 'lodash/get';
import { widgetActionCreators, IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { IWidgetLabels } from 'interfaces/widgets/widgets';

export const walletsLoadPayloadLabels: IWidgetLabels = { listLabel: `result` };

export const walletsLoadActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.WALLETS_LOAD,
  labels: walletsLoadPayloadLabels,
  getExtraListPayload: (payload: Record<string, unknown>) => ({ currency_symbol: get(payload, `currency_symbol`) }),
});
