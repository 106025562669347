import React, { FC, useState, MouseEvent } from 'react';
import { FormType } from 'api/translations/TranslationsApi';
import { IconButton } from 'pages/constructor/DefaultTranslates/common/components';
import cn from 'classnames';
// @ts-expect-error Именованный импорт из библиотеки не отрабатывает должныи образом
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion';
import './FormTypeDropdown.scss';
import { CheckedFormType } from '../../hooks/useFormTypes';

type Props = {
  /* Типы форм. Форма совершения оплаты, форма подтверждения оплаты и т.д. */
  formTypes: Array<{ form: FormType; entityId: string; onCheck: (formType?: CheckedFormType) => void }>;

  /* Заголовок. Название формы */
  title: string;

  /* Выбранная форма */
  checkedForm?: CheckedFormType;

  /* Доступность удаления */
  onDelete?: () => void;

  /* Доступность редактирования */
  onEdit?: () => void;

  children?: never;
};

export const FormTypeDropdown: FC<Props> = (props) => {
  const { formTypes, checkedForm, title, onDelete, onEdit } = props;
  const [isOpened, setIsOpened] = useState(false);

  const handleToggleOpened = () => setIsOpened((prev) => !prev);

  const getCheckedForm = (current: FormType, checked?: CheckedFormType) => {
    if (!checked) return false;

    return current.title === checked.title && current.type === checked.type;
  };

  const handleEdit = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    onEdit && onEdit();
  };

  const handleDelete = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    onDelete && onDelete();
  };

  return (
    <div>
      <div className="FormTypeDropdown__header" onClick={handleToggleOpened}>
        <p className="FormTypeDropdown__header_name">{title}</p>

        <div className="FormTypeDropdown__controls">
          <div className="FormTypeDropdown__controls_edit" onClick={handleEdit}>
            {onEdit && <IconButton onClick={() => null} variant="edit" />}
          </div>

          <div className="FormTypeDropdown__controls_remove" onClick={handleDelete}>
            {onDelete && <IconButton onClick={() => null} variant="remove" />}
          </div>

          <div
            className={cn(`FormTypeDropdown__toggler`, {
              'FormTypeDropdown__toggler--open': isOpened,
            })}
          >
            <IconButton onClick={() => null} variant="arrow" />
          </div>
        </div>
      </div>

      <AnimatePresence key={`form-types`}>
        <motion.ul
          className="FormTypeDropdown__list"
          animate={isOpened ? `visible` : `hidden`}
          initial="hidden"
          transition={{ duration: 0.3 }}
          variants={{
            hidden: { height: 0, opacity: 0, visibility: `hidden`, display: `none` },
            visible: { height: `auto`, opacity: 1, visibility: `visible`, display: `block` },
          }}
        >
          {formTypes.map(({ form, entityId, onCheck }, i) => (
            <li
              key={`${form.title}-${i}`}
              className={cn(`FormTypeDropdown__list_item`, {
                'FormTypeDropdown__list_item--active':
                  getCheckedForm({ title: form.title, type: form.type }, checkedForm) &&
                  entityId === checkedForm?.entityId,
              })}
            >
              <button onClick={() => onCheck({ title: form.title, type: form.type, entityId })} type="button">
                {form.title}
              </button>
            </li>
          ))}
        </motion.ul>
      </AnimatePresence>
    </div>
  );
};
