import { IRootState, widgetSelector } from '@kassma-team/kassma-toolkit';
import { WidgetType } from 'utils/enums';
import { createSelector } from 'reselect';
import { widgetListSelector } from '@kassma-team/kassma-toolkit/lib';

export const manualFieldsSelector = createSelector(
  widgetSelector(WidgetType.WALLETS),
  (state: any) => state.manualFields || []
);

export const walletCurrencySelector = (wallet_id: string) =>
  createSelector(widgetListSelector(WidgetType.NOT_REMOVED_WALLETS), (list) => {
    //@ts-ignore
    const arr: any[] = list || [];
    const foundItem = arr.find((item: any) => item.id === +wallet_id);

    //@ts-ignore
    return (foundItem || {}).currency;
  });
