import {
  useTable as useKassmaTable,
  widgetSelector,
  tablesPerPageSelector,
  IUseListParams,
  IFilterSearchParams,
} from '@kassma-team/kassma-toolkit/lib';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { IWidgetActionCreators, IWidgetsState } from '@kassma-team/kassma-toolkit/lib/types/widgets';

import useSearchParams from 'hooks/useSearchParams';

interface IProps extends IUseListParams {
  widget?: string;
  filterFormName?: string;
  actionCreators: IWidgetActionCreators;
  withPagination?: boolean;
  resetOnUnmount?: boolean;
  loadOnlyIfNecessary?: boolean;
  statusBar?: boolean;
  loadOnMount?: boolean;
  loadOnlyFilters?: string[];
  disableLoading?: boolean;
  widgetState?: IWidgetsState;
  perPage?: number;
  dispatch?: Dispatch;
  search?: Record<string, string | string[] | null>;
  queryParams?: Record<string, unknown>;
}

export interface IUseTableParams extends IUseListParams {
  widgetState: IWidgetsState;
  perPage: number;
  search: IFilterSearchParams;

  filterFormName?: string;
  withPagination?: boolean;
  statusBar?: boolean;
  loadOnMount?: boolean;
  loadOnlyFilters?: string[];
}

export interface IUseTableResult<ListItem = any> {
  state: IWidgetsState<ListItem>;
  dispatch: Dispatch;
}

const useTable = <ListItem = any>({
  widget,
  filterFormName,
  actionCreators,
  withPagination,
  resetOnUnmount,
  loadOnlyIfNecessary,
  statusBar,
  loadOnMount,
  loadOnlyFilters,
  disableLoading,
  queryParams,
}: IProps): ((params: IUseTableParams) => IUseTableResult<ListItem>) => {
  const widgetState: IWidgetsState = useSelector(widgetSelector(widget));
  const perPage: number = useSelector(tablesPerPageSelector);

  const dispatch = useDispatch();

  const search: Record<string, string | string[] | null> = useSearchParams();

  return useKassmaTable({
    filterFormName,
    actionCreators,
    withPagination,
    resetOnUnmount,
    loadOnlyIfNecessary,
    statusBar,
    loadOnMount,
    loadOnlyFilters,
    disableLoading,
    widgetState,
    perPage,
    dispatch,
    search,
    queryParams,
  });
};

export default useTable;
