import { Reducer } from 'redux';
import map from 'lodash/map';
import { IWidgetsState } from '@kassma-team/kassma-toolkit/lib/types/widgets';

import {
  DEPOSITS_SUCCEEDED,
  PAYMENT_CURRENCIES_SUCCEEDED,
  RESET_DEPOSIT,
  UPDATE_PAYMENT_CURRENCIES_SUCCEEDED,
  PAYMENT_VIEW_DATA_SUCCEEDED,
  RESET_PAYMENT_VIEW_DATA,
  TOGGLE_SHOW_ON_PLUGIN,
  TOGGLE_STANDARD_PS,
  CHANGE_LIMIT_OF_GETTING_STANDARD_STATUS,
  PAYMENT_WORKING_TIME_DATA_SUCCEEDED,
  RESET_PAYMENT_WORKING_TIME_DATA,
  TOGGLE_ENABLING_POSSIBLE_TRANSACTIONS,
  PAYMENT_TRANSLATIONS_DATA_SUCCEEDED,
  CHANGE_SETTINGS,
} from 'actionTypes';

const actionTypes = new Set([
  PAYMENT_CURRENCIES_SUCCEEDED,
  UPDATE_PAYMENT_CURRENCIES_SUCCEEDED,
  DEPOSITS_SUCCEEDED,
  RESET_DEPOSIT,
  PAYMENT_VIEW_DATA_SUCCEEDED,
  RESET_PAYMENT_VIEW_DATA,
  TOGGLE_SHOW_ON_PLUGIN,
  TOGGLE_STANDARD_PS,
  CHANGE_LIMIT_OF_GETTING_STANDARD_STATUS,
  PAYMENT_WORKING_TIME_DATA_SUCCEEDED,
  RESET_PAYMENT_WORKING_TIME_DATA,
  TOGGLE_ENABLING_POSSIBLE_TRANSACTIONS,
  PAYMENT_TRANSLATIONS_DATA_SUCCEEDED,
  CHANGE_SETTINGS,
]);

const reducer: Reducer = (state: IWidgetsState, { type, payload, meta }) => {
  switch (type) {
    case PAYMENT_CURRENCIES_SUCCEEDED:
      return { ...state, ...payload };
    case DEPOSITS_SUCCEEDED:
      return {
        ...state,
        minDepositData: payload,
      };
    case UPDATE_PAYMENT_CURRENCIES_SUCCEEDED:
      return {
        ...state,
        items: map(state.items, (payment) => {
          return payment.code === meta.walletType ? { ...payment, applied_currencies: payload } : payment;
        }),
      };
    case RESET_DEPOSIT:
      return { ...state, minDepositData: null };
    case RESET_PAYMENT_VIEW_DATA:
      return { ...state, viewData: null };
    case PAYMENT_VIEW_DATA_SUCCEEDED:
      return { ...state, viewData: payload };
    case RESET_PAYMENT_WORKING_TIME_DATA:
      return { ...state, workingTimeData: null };
    case PAYMENT_WORKING_TIME_DATA_SUCCEEDED:
      return { ...state, workingTimeData: payload };
    case PAYMENT_TRANSLATIONS_DATA_SUCCEEDED:
      return { ...state, translationsData: payload };
    case CHANGE_SETTINGS:
      return {
        ...state,
        items: map(state.items, (payment) => {
          return payment.type === payload.walletType || payment.code === payload.walletType
            ? {
                ...payment,
                type_settings: {
                  ...payment.type_settings,
                  ...payload.settings,
                },
              }
            : payment;
        }),
      };
    case TOGGLE_SHOW_ON_PLUGIN:
      return {
        ...state,
        items: map(state.items, (payment) => {
          return payment.code === payload.walletType
            ? {
                ...payment,
                type_settings: {
                  ...payment.type_settings,
                  plugin_visibility: payload.showOnPlugin,
                },
              }
            : payment;
        }),
      };
    case TOGGLE_STANDARD_PS:
      return {
        ...state,
        items: map(state.items, (payment) => {
          return payment.type === payload.walletType || payment.code === payload.walletType
            ? {
                ...payment,
                type_settings: {
                  ...payment.type_settings,
                  is_standard: payload.isStandardPS,
                },
              }
            : payment;
        }),
      };
    case TOGGLE_ENABLING_POSSIBLE_TRANSACTIONS:
      return {
        ...state,
        items: map(state.items, (payment) => {
          return payment.code === payload.walletType
            ? {
                ...payment,
                type_settings: {
                  ...payment.type_settings,
                  use_possible_transactions: payload.enablePossibleTransactions,
                },
              }
            : payment;
        }),
      };
    case CHANGE_LIMIT_OF_GETTING_STANDARD_STATUS:
      return {
        ...state,
        items: map(state.items, (payment) => {
          return payment.type === payload.walletType || payment.code === payload.walletType
            ? {
                ...payment,
                type_settings: {
                  ...payment.type_settings,
                  standard_threshold: payload.limitOfGettingStandardStatus,
                },
              }
            : payment;
        }),
      };
    default:
      return state;
  }
};

const paymentsConstructor = { actionTypes, reducer };

export default paymentsConstructor;
