import { WithdrawalType } from 'utils/enums';

const withdrawalTypeField = {
  [WithdrawalType.AUTO]: {
    text: `withdrawals.auto`,
    badge: `badge-primary`,
    filterText: `withdrawals.filterApproved`,
  },
  [WithdrawalType.MANUAL]: {
    text: `withdrawals.manual`,
    badge: `badge-danger`,
    filterText: `withdrawals.filterNew`,
  },
};

export default withdrawalTypeField;
