import { widgetActionCreators, IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { AUTO_REPORT_GENERATING_FORM_NAME } from 'utils/constants';

export const autoReportsActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.AUTOREPORTS,
  listItemUpdatingForm: AUTO_REPORT_GENERATING_FORM_NAME,
  labels: { listLabel: `data`, itemLabel: `data` },
});
