import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSearch, RouterRootState } from 'connected-react-router';
import queryString from 'query-string';
import isObject from 'lodash/isObject';

const useSearchParams = (): Record<string, string | string[] | null> => {
  const search: string = useSelector<RouterRootState, string>(getSearch);

  return useMemo(() => {
    let obj = queryString.parse(search);
    obj = isObject(obj) ? { ...obj } : {};

    return obj;
  }, [search]);
};

export default useSearchParams;
