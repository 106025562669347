import React from 'react';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Dispatch } from 'redux';
import { IModalFormProps } from '@kassma-team/kassma-toolkit';

import { CREATE_SETTLEMENT_FORM_NAME } from 'utils/constants';
import { WidgetType } from 'utils/enums';
import { createSettlementReplenishment, settlementActionCreators } from 'actions/widgets/balances/settlement';

import ModalForm from 'components/modals/ModalForm';
import { accountsBalanceHistoryActionCreators } from '../../../actions/widgets/balances/accountsBalanceHistory';

const onSubmit = (values: Record<`amount`, string>, dispatch: Dispatch) => {
  dispatch(createSettlementReplenishment(values));
};

const ExternalAccountReplenishment = (props: IModalFormProps) => {
  const [t] = useTranslation();

  return (
    <ModalForm
      {...props}
      onSubmit={onSubmit}
      widget={WidgetType.ACCOUNT_BALANCE_HISTORY}
      actionCreators={accountsBalanceHistoryActionCreators}
      title="balances.replenishmentFromExternalAccount"
      submitText="wallets.replenishment"
      fields={[
        {
          name: `amount`,
          label: t(`common.amount`),
          standardAmount: true,
          required: true,
        },
      ]}
    />
  );
};

export default reduxForm({ form: CREATE_SETTLEMENT_FORM_NAME })(ExternalAccountReplenishment);
