import React from 'react';

import { notAcceptedPostbackActionCreators } from 'actions/widgets/postback';
import { WidgetType } from 'utils/enums';

import PostbackRequest from 'components/widgets/postback/PostbackRequest';

const NotAcceptedPostbackModal = () => {
  return (
    <PostbackRequest actionCreators={notAcceptedPostbackActionCreators} widget={WidgetType.NOT_ACCEPTED_POSTBACKS} />
  );
};

export default NotAcceptedPostbackModal;
