import { takeEvery, put } from 'redux-saga/effects';
import get from 'lodash/get';
import { toastr } from 'react-redux-toastr';
import { AxiosError, AxiosResponse } from 'axios';
import i18n from 'i18n';
import { IAction } from '@kassma-team/kassma-toolkit/lib';
import { IWidgetsMeta } from '@kassma-team/kassma-toolkit/lib/types/widgets';

import { getErrorMessage } from 'utils';
import refreshSaga from 'sagas/effects/refreshSaga';
import { PAYMENT_HINT_DEFAULT_TRANSLATIONS_REQUESTED, PAYMENT_HINT_DEFAULT_TRANSLATIONS_SUCCEEDED } from 'actionTypes';
import paymentHintApi from 'api/constructors/PaymentHintApi';

interface IGetPaymentHintDefaultTranslationsProps {
  meta: IWidgetsMeta;
  payload: Record<string, string>;
}

function* getPaymentHintDefaultTranslationsSaga({ meta, payload }: IGetPaymentHintDefaultTranslationsProps) {
  const payment = get(payload, `payment`);
  const data = {
    form_type: get(payload, `form_type`),
    form_element: get(payload, `form_element`),
  };
  yield put({ meta, type: PAYMENT_HINT_DEFAULT_TRANSLATIONS_SUCCEEDED, payload: null });

  yield refreshSaga({
    request: () => paymentHintApi.loadPaymentDefaultHints(payment, data),
    onSuccess: function* (resp: AxiosResponse) {
      const payload = get(resp, `data.field.translations`, null);
      yield put({ meta, type: PAYMENT_HINT_DEFAULT_TRANSLATIONS_SUCCEEDED, payload });
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

const paymentHintSagas = [
  takeEvery<IAction>(PAYMENT_HINT_DEFAULT_TRANSLATIONS_REQUESTED, getPaymentHintDefaultTranslationsSaga),
];

export default paymentHintSagas;
