import React, { useMemo, useState } from 'react';
import './SpoilerWithCount.scss';
import { useTranslation } from 'react-i18next';

const SpoilerWithCount = ({ elements, count = 3 }: { elements: string[]; count?: number }) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  const slicedItems = elements.slice(0, count);
  const len = elements.length;
  const isExceeded = len > count;
  const reminder = len - count;
  const sign = !open ? `+` : `-`;

  const assignedItems = useMemo(() => slicedItems.map((elem: string, id: number) => <div key={id}>{elem}</div>), [
    slicedItems,
  ]);
  const allItems = useMemo(() => elements.map((elem: string, id: number) => <div key={id}>{elem}</div>), [elements]);

  const handleClick = () => setOpen((prevState: boolean) => !prevState);

  return (
    <>
      {!open && isExceeded ? assignedItems : allItems}
      {isExceeded && (
        <div className="expand-spoiler" onClick={handleClick}>
          {!open ? t(`notification.showAll`) : t(`notification.hide`)}
          <span className="expand-spoiler__reminder">
            {sign} {reminder}
          </span>
        </div>
      )}
    </>
  );
};

export default SpoilerWithCount;
