import React from 'react';

import useUserHasAccess from 'hooks/useUserHasAccess';
import { ModalType, WidgetType } from 'utils/enums';
import { paymentsConstructorActionCreators } from 'actions/widgets/constructors/paymentsConstructor';
import constructorRouteTabs from 'pages/constructor/constructorRouteTabs';
import usePaymentsConstructorTableTitles from 'hooks/widgets/tableTitles/constructor/usePaymentsConstructorTableTitles';
import { IPaymentsConstructorRowProps } from 'interfaces/widgets/constructor/paymentsConstructor';

import PaymentsConstructorRow from 'components/widgets/constructors/payments/PaymentsConstructorRow';
import PaymentsConstructorModal from 'components/widgets/constructors/payments/PaymentsConstructorModal';
import DepositsModal from 'components/widgets/constructors/payments/DepositsModal';
import PaymentViewModal from 'components/widgets/constructors/payments/paymentView/PaymentViewModal';
import SettingsModal from 'components/widgets/constructors/payments/SettingsModal';
import CreatingManualPS from 'components/widgets/constructors/payments/CreatingManualPS';
import Widget from 'components/widgets/Widget';
import { useTranslation } from 'react-i18next';
import DeleteManualPS from 'components/widgets/constructors/payments/DeleteManualPS';

const listMapping = (item: IPaymentsConstructorRowProps) => {
  return <PaymentsConstructorRow {...item} key={item.code} />;
};

const modals = [
  {
    type: ModalType.PAYMENT_CURRENCIES,
    widget: WidgetType.PAYMENTS_CONSTRUCTOR,
    component: PaymentsConstructorModal,
  },
  {
    type: ModalType.DEPOSITS,
    widget: WidgetType.PAYMENTS_CONSTRUCTOR,
    component: DepositsModal,
  },
  {
    type: ModalType.VIEW_TYPE,
    widget: WidgetType.PAYMENTS_CONSTRUCTOR,
    component: PaymentViewModal,
  },
  {
    type: ModalType.SETTINGS,
    widget: WidgetType.PAYMENTS_CONSTRUCTOR,
    component: SettingsModal,
  },
  {
    type: ModalType.CREATE,
    widget: WidgetType.PAYMENTS_CONSTRUCTOR,
    component: CreatingManualPS,
  },
  {
    type: ModalType.REMOVE_CONFIRMATION,
    widget: WidgetType.PAYMENTS_CONSTRUCTOR,
    component: DeleteManualPS,
  },
];

const PaymentsConstructor = () => {
  const tableHeadTitles = usePaymentsConstructorTableTitles();

  useUserHasAccess();

  const [t] = useTranslation();

  return (
    <Widget
      widget={WidgetType.PAYMENTS_CONSTRUCTOR}
      actionCreators={paymentsConstructorActionCreators}
      pageTitle="paymentConstructor.constructor"
      tableTitle="paymentConstructor.paymentSystems"
      headTitle="paymentConstructor.paymentConstructor"
      routeTabs={constructorRouteTabs}
      tableHeadTitles={tableHeadTitles}
      extraButtons={[
        {
          title: t(`paymentConstructor.addManualPS`),
          onClick: () => paymentsConstructorActionCreators.showModal({ type: ModalType.CREATE }),
        },
      ]}
      listMapping={listMapping}
      modals={modals}
      statusBar
    />
  );
};

export default PaymentsConstructor;
