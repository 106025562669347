import size from 'lodash/size';
import some from 'lodash/some';
import split from 'lodash/split';
import trim from 'lodash/trim';
import toNumber from 'lodash/toNumber';

import i18n from '../../../i18n';

const validateProxyIp = (ip: string): string | null => {
  const errorMessage = i18n.t(`errors.invalidIpFormat`);

  if (ip && ip.match(/[A-Za-z]+/g)) {
    const hostRegex =
      // eslint-disable-next-line
      /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/g;

    if (!ip.match(hostRegex)) {
      return errorMessage;
    }
  } else {
    const ipSeparatedValues = split(ip, `.`);
    if (size(ipSeparatedValues) !== 4) {
      return errorMessage;
    } else {
      const wrongType = some(ipSeparatedValues, (val: string) => {
        if (size(trim(val)) === 0) {
          return true;
        }

        const number = toNumber(val);

        return number < 0 || number > 255;
      });
      if (wrongType) {
        return errorMessage;
      }
    }
  }

  return null;
};

export default validateProxyIp;
