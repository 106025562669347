import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import {
  InfoModal as KassmaInfoModal,
  IWidgetActionCreators,
  widgetModalSelector,
  IWidgetModal,
} from '@kassma-team/kassma-toolkit/lib';

interface IProps {
  actionCreators?: IWidgetActionCreators;
  widget?: string;
  title?: string;
  modal?: IWidgetModal;
  dispatch?: Dispatch;
}

const InfoModal = ({ widget, ...props }: IProps) => {
  const modal = useSelector(widgetModalSelector(widget));

  const dispatch = useDispatch();

  return <KassmaInfoModal {...props} modal={modal} dispatch={dispatch} />;
};

export default InfoModal;
