import { widgetActionCreators, IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';

export const logActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.LOGS,
  labels: { listLabel: `logs` },
  withPagination: true,
});

export const changeLogsCategoriesActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.LOGS_CATEGORIES,
  labels: { listLabel: `categories` },
});
