import { useState } from 'react';
import { useSelector } from 'react-redux';

import { hasAccessSelector, userPermissionsSelector } from 'selectors/auth';
import { StockpilingListColumn, Permission } from 'utils/enums';
import { ITableTitleField } from 'interfaces/widgets/widgets';

const useStorckpilingTableTitles = (): ITableTitleField[] => {
  const permissionsList = useSelector(userPermissionsSelector);
  const postbacksAccess = useSelector(hasAccessSelector(Permission.POSTBACKS));
  const canSendPostback = () => permissionsList.includes(Permission.POSTBACK_SEND);
  const canViewPostbacks = () => permissionsList.includes(Permission.POSTBACKS);
  const canViewStockpilings = () => permissionsList.includes(Permission.STOCKPILING_LIST);

  const isActionsHide = () => {
    if (canSendPostback() || canViewPostbacks() || canViewStockpilings()) {
      return false;
    }

    return true;
  };

  const [titles] = useState([
    {
      title: `ID`,
      id: StockpilingListColumn.ID,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.label`,
      id: StockpilingListColumn.LABEL,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.paymentSystem`,
      id: StockpilingListColumn.PAYMENT_SYSTEM,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `paymentConstructor.minimumAmount`,
      id: StockpilingListColumn.MIN_DEPOSIT,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `stockpilings.stockpilingsAmount`,
      id: StockpilingListColumn.STOCKPILINGS_AMOUNT,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `stockpilings.type`,
      id: StockpilingListColumn.STOCKPILING_TYPE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.status`,
      id: StockpilingListColumn.STATUS,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.actions`,
      id: StockpilingListColumn.ACTIONS,
      desktop: true,
      mobile: true,
      disabled: true,
      // hide: !canSendPostback() || !canViewPostbacks() || !canViewStockpilings(),
      hide: isActionsHide(),
    },
  ]);

  return titles;
};

export default useStorckpilingTableTitles;
