import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import {
  IUpdateDepositsPreparedData,
  IUpdatePaymentViewFormData,
} from 'interfaces/widgets/constructor/paymentsConstructor';

export class WalletTypesApi extends BaseApi {
  getRequisites = (walletType: string): AxiosPromise => this.makeFetch(`requisites/{walletType}`, {}, { walletType });

  loadPaymentLogos = (walletType: string): AxiosPromise =>
    this.makeFetch(`get-all-logo/{walletType}`, {}, { walletType });

  updatePaymentLogos = (walletType: string, data: Record<string, unknown>): AxiosPromise =>
    this.makeFetch(`logo/{walletType}`, { data, method: `POST`, formData: true }, { walletType });

  loadPaymentViewData = (paymentSystem: string): AxiosPromise =>
    this.makeFetch(`get-targets/{paymentSystem}`, {}, { paymentSystem });

  updatePaymentViewData = (paymentSystem: string, data: IUpdatePaymentViewFormData): AxiosPromise =>
    this.makeFetch(`targets/{paymentSystem}`, { method: `POST`, data, formData: true }, { paymentSystem });

  // loadPaymentList = (): AxiosPromise => this.makeFetch(`list-with-currencies`);

  // loadPaymentSystems = (): AxiosPromise => this.makeFetch(`list`);

  // loadPaymentSystemsForPossibleTransactions = (): AxiosPromise => this.makeFetch(`list/for-possible-transactions`);

  loadCurrencyCodeList = (walletType: number): AxiosPromise =>
    this.makeFetch(`get-support-currency/{walletType}`, {}, { walletType });

  loadDeposits = (paymentSystem: string): AxiosPromise => this.makeFetch(`{paymentSystem}`, {}, { paymentSystem });

  updateDeposits = (paymentSystem: string, data: IUpdateDepositsPreparedData[]): AxiosPromise =>
    this.makeFetch(`{paymentSystem}`, { method: `POST`, data }, { paymentSystem });
}

const paymentSystemsApi = new WalletTypesApi(`wallet-type`);

export default paymentSystemsApi;
