import React, { useEffect, useState } from 'react';
import { getFormValues, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import trim from 'lodash/trim';
import { useSelector } from 'react-redux';
import { GridType, getSubmitHandler, InputType } from '@kassma-team/kassma-toolkit/lib';

import { transactionsOnlyByIdActionCreators } from 'actions/widgets/transactions/transactions';
import { ModalType, Permission, WidgetType } from 'utils/enums';
import transactionListMapping from 'utils/widgets/transactions/transactionListMapping';
import useTransactionsTableTitles from 'hooks/widgets/tableTitles/transactions/useTransactionsTableTitles';
import useParticularFilters from 'hooks/widgets/useParticularFilters';
import { ITransactionsOnlyByIdFiltersForm } from 'interfaces/widgets/transaction/transaction';
import withPermissionCheck from 'hocs/withPermissionCheck';
import { IFilterProps } from 'interfaces/widgets/widgets';

import Filters from 'components/widgets/filters/Filters';
import transactionRouteTabs from 'pages/transactions/transactionRouteTabs';
import TransactionActivationOnlyById from 'components/widgets/transaction/transactionActivation/TransactionActivationOnlyById';
import Widget from 'components/widgets/Widget';

const FORM_NAME = `transaction-only-by-id-filters`;

const entityParams = {
  actionCreators: transactionsOnlyByIdActionCreators,
  widget: WidgetType.TRANSACTIONS_ONLY_BY_ID,
};

const filterDateFields = [
  {
    from: `date_from`,
    to: `date_to`,
    name: `date_range`,
  },
];

const FilterForm = reduxForm<any, IFilterProps>({
  form: FORM_NAME,
  onSubmit: getSubmitHandler({
    dateFields: [{ name: `date_range`, fromName: `date_from`, toName: `date_to` }],
    dateFormat: `YYYY-MM-DD HH:mm`,
  }),
})(Filters);

const rightFilterFields = [
  {
    name: `date_range`,
    type: InputType.DATE_RANGE,
  },
];

const modals = [
  {
    type: ModalType.TRANSACTION_ACTIVATION,
    widget: WidgetType.TRANSACTIONS_ONLY_BY_ID,
    component: TransactionActivationOnlyById,
  },
];

const TransactionsOnlyById = () => {
  const [t] = useTranslation();

  const [filterFormDisabled, setFilterFormDisabled] = useState(false);

  const values: ITransactionsOnlyByIdFiltersForm = useSelector(getFormValues(FORM_NAME));

  useEffect(() => {
    setFilterFormDisabled(!(values && (trim(values.transaction_id) || values.label)));
  }, [values]);

  const titles = useTransactionsTableTitles(WidgetType.TRANSACTIONS_ONLY_BY_ID);

  const [leftFilterFields] = useState([
    {
      name: `label`,
      placeholder: t(`common.label`),
    },
    {
      name: `transaction_id`,
      placeholder: t(`transactions.transactionId`),
    },
    {
      name: `exchanger_identifier`,
      placeholder: t(`transactions.exchanger`),
    },
  ]);

  const { loadOnlyFilters, refreshingIsDisabled } = useParticularFilters([`transaction_id`, `label`]);

  return (
    <Widget
      actionCreators={transactionsOnlyByIdActionCreators}
      widget={WidgetType.TRANSACTIONS_ONLY_BY_ID}
      filterFormName={FORM_NAME}
      pageTitle="transactions.transactions"
      headTitle="transactions.transactions"
      routeTabs={transactionRouteTabs}
      tableTitle="transactions.transactionList"
      tableHeadTitles={titles}
      listMapping={transactionListMapping(entityParams)}
      filterForm={
        <FilterForm
          leftFields={leftFilterFields}
          rightFields={rightFilterFields}
          formName={FORM_NAME}
          gridType={GridType.FLEX}
          dateFields={filterDateFields}
          disabled={filterFormDisabled}
        />
      }
      modals={modals}
      withPagination
      statusBar
      loadListOnMount={false}
      loadOnlyFilters={loadOnlyFilters}
      refreshingIsDisabled={refreshingIsDisabled}
    />
  );
};

export default withPermissionCheck(TransactionsOnlyById, [
  {
    permissions: [
      {
        permission: Permission.TRANSACTION_ONLY_BY_ID,
        opposite: true,
      },
    ],
    redirectUrl: `/no-permissions`,
  },
]);
