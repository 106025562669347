import includes from 'lodash/includes';

import { Permission } from 'utils/enums';
import { hasIntersection } from 'utils';

const walletsDebuggingRouteTabs = (permissions: string[]) => [
  {
    path: `/logs`,
    title: `logs.logList`,
    hasAccess: includes(permissions, Permission.LOGS),
  },
  {
    path: `/withdrawals-logs`,
    title: `logs.withdrawalsLogs`,
    hasAccess: true,
  },
];

export default walletsDebuggingRouteTabs;
