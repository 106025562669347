import get from 'lodash/get';
import i18n from 'i18n';
import find from 'lodash/find';
import forEach from 'lodash/forEach';

import { ReportType, WidgetType } from 'utils/enums';

const reportValidation = (values: any, form: any) => {
  //todo do on the 5th step
  const errors: Record<string, unknown> = {};

  const reportType = get(values, `report_type`);

  if (reportType === ReportType.LIST_REPORT || reportType === ReportType.SUM_REPORT) {
    const fieldNames = [`date_range`, `date_range_of_activation`, `archiving_period`];

    const filledField = find(fieldNames, (fieldName) => {
      const fieldValues = get(values, `${fieldName}.values`);

      return fieldValues && fieldValues.startDate && fieldValues.endDate;
    });

    if (!filledField) {
      forEach(fieldNames, (fieldName) => {
        errors[fieldName] = i18n.t(`errors.fillAtLeastOnePeriod`);
      });
    } else form.clearSubmitErrors && form.clearSubmitErrors();
  }

  if (!values?.pre_emails?.length && values.widget === WidgetType.AUTOREPORTS) {
    errors._error = i18n.t(`reports.oneRecipientEmail`);
  }

  return errors;
};

export default reportValidation;
