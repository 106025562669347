import React from 'react';

import { WidgetType } from 'utils/enums';
import { removedWalletActionCreators } from 'actions/widgets/wallet';
import RemovingModal from 'components/modals/RemovingModal';

const RemovedWalletDeleting = () => {
  return (
    <RemovingModal
      widget={WidgetType.REMOVED_WALLETS}
      actionCreators={removedWalletActionCreators}
      title="wallets.walletDeleting"
      content="wallets.doYouReallyWantToDeleteThisWallet"
      confirmText="common.delete"
    />
  );
};
export default RemovedWalletDeleting;
