import { FormType } from 'api/translations/TranslationsApi';
import { EditorState } from 'draft-js';
import { BaseApi } from '../common/BaseApi';
import { Response } from './types';

export type PackageTranslation = {
  field: string;
  name: string;
  code: string;
  translations: Array<{
    language: string;
    text: string;

    // Может быть на клиенте
    clientId?: string;
    prevState?: EditorState;
  }>;
};

export type Package = {
  uuid: string;
  wallet_type: string;
  form_type: FormType[`type`];
  name: string;
  is_default?: boolean;
  created_at: string;
  updated_at: string;
  is_manual?: boolean;
  packages_translations: Array<PackageTranslation>;
};

export type Field = {
  uuid: string;
  name: string;
  code: string;
};

export type UpdateBody = {
  name: Package[`name`];
  is_manual: Package[`is_manual`];
  packages_translations: Array<{
    field_uuid: PackageTranslation[`field`];
    translations: PackageTranslation[`translations`];
  }>;
};

export type CreateBody = {
  wallet_type: Package[`wallet_type`];
  form_type: FormType[`type`];
  name: Package[`name`];
  is_manual: Package[`is_manual`];

  packages_translations: Array<{
    field_uuid: PackageTranslation[`field`];
    translations: PackageTranslation[`translations`];
  }>;
};

export class PackagesApi extends BaseApi {
  fetchTranslations = (queryParams?: Record<string, string | boolean>) =>
    this.makeFetch<Response<Array<Package>>>(`package`, { queryParams });

  addTranslation = () => this.makeFetch<Response>(`package`, { method: `POST` });

  updateTranslation = (id: string, body: UpdateBody) =>
    this.makeFetch<Response>(`package/${id}`, {
      method: `PUT`,
      data: body,
      headers: { 'request-version': `1.000.0` },
    });

  deleteTranslation = (id: string) => this.makeFetch<Response>(`package/${id}`, { method: `DELETE` });

  createField = (body: CreateBody) => this.makeFetch<Response>(`package`, { method: `POST`, data: body });

  fetchFields = () => this.makeFetch<Response<Array<Field>>>(`field`);
}
