import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import map from 'lodash/map';
import { WidgetContainer, PageContainer } from '@kassma-team/kassma-toolkit/lib';

import { ModalType, Permission, WidgetType } from 'utils/enums';
import withPermissionCheck from 'hocs/withPermissionCheck';

import Header from 'components/header/Header';
import Modal from 'components/modals/Modal';
import MoneyTransferModal from 'components/widgets/balances/MoneyTransferModal';
import AccountsBalanceReplenishment from 'components/widgets/balances/AccountsBalanceReplenishment';
import Accounts from './Accounts';

const modals = [
  {
    widget: WidgetType.ACCOUNTS_BALANCES,
    type: ModalType.REPLENISHMENT,
    component: AccountsBalanceReplenishment,
  },
  {
    widget: WidgetType.ACCOUNTS_BALANCES,
    type: ModalType.MONEY_TRANSFER,
    component: MoneyTransferModal,
  },
];

const AccountsBalance = () => {
  const [t] = useTranslation();

  return (
    <PageContainer>
      <Helmet>
        <title>{t(`wallets.accountsBalance`)}</title>
      </Helmet>
      <Header />
      <WidgetContainer title="wallets.accountsBalance">
        <Accounts />
      </WidgetContainer>
      {map(modals, (modal, key) => (
        <Modal key={key} {...modal} />
      ))}
    </PageContainer>
  );
};

export default withPermissionCheck(AccountsBalance, [
  {
    permissions: [
      {
        permission: Permission.ACCOUNTS_BALANCE,
        opposite: true,
      },
    ],
    redirectUrl: `/no-permissions`,
  },
]);
