import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { IProxyWalletSelect } from 'interfaces/widgets/proxies';
import { INotRemovedWallet, IProxyCreatingFormWalletItem } from 'interfaces/widgets/wallets/wallet';

const parserTypes = {
  ICICI: `icici`,
  EMAIL: `email`,
};

interface IProps {
  formName: string;
  wallets: INotRemovedWallet[] | null;
  listName: string;
}

interface IWallet {
  wallet: IProxyCreatingFormWalletItem;
}

const useAvailableWallets = ({ wallets, formName, listName }: IProps) => {
  const [t] = useTranslation();
  const formValues: Record<string, IWallet[]> = useSelector(getFormValues(formName)) || {};
  const list: IWallet[] = formValues?.[listName];

  return useMemo(() => {
    let selectedDublicatedWallets: Record<string, string | number>[] = [];

    const selectedWallets = list?.map((item) => {
      const currentValue = item.wallet?.value || item.wallet?.id;

      if (typeof currentValue === `string`) {
        if (currentValue.includes(`-1`)) {
          const convertedId = currentValue.split(``).slice(0, -2).join(``);
          selectedDublicatedWallets = [...selectedDublicatedWallets, { id: +convertedId, type: parserTypes.EMAIL }];

          return convertedId;
        } else {
          selectedDublicatedWallets = [...selectedDublicatedWallets, { id: +currentValue, type: parserTypes.ICICI }];

          return currentValue;
        }
      }

      return currentValue;
    });

    const availableWalletList = wallets ? wallets.filter(({ id }) => !selectedWallets?.includes(id)) : wallets;

    return availableWalletList?.reduce(
      (result: IProxyWalletSelect[], { id, identifier, status, site_parser, email_parser, parser_type }) => {
        let text = id.toString();
        const selectedDublicatedItem = selectedDublicatedWallets.filter((item) => item?.id === id) || [];

        if (identifier) {
          text = `${text} | ${identifier}`;
        }

        let selectElement: IProxyWalletSelect | IProxyWalletSelect[] = {
          value: +id,
          text,
          status,
        };

        if (site_parser && email_parser) {
          if (selectedDublicatedItem?.length === 2) {
            selectElement = [];
          } else if (selectedDublicatedItem?.length === 1) {
            selectElement =
              selectedDublicatedItem[0]?.type === parserTypes.ICICI
                ? {
                    value: id,
                    text: `${text} | ${t(`wallets.emailParser`)}`,
                    status,
                  }
                : {
                    value: id,
                    text: `${text} | ${t(`wallets.siteParser`)}`,
                    status,
                  };
          } else {
            selectElement = [
              {
                value: id.toString(),
                text: `${text} | ${t(`wallets.siteParser`)}`,
                status,
              },
              {
                value: (id + `-1`).toString(),
                text: `${text} | ${t(`wallets.emailParser`)}`,
                status,
              },
            ];
          }
        }

        return Array.isArray(selectElement) ? [...result, ...selectElement] : [...result, selectElement];
      },
      []
    );
  }, [wallets, list]);
};

export default useAvailableWallets;
