import React, { useMemo } from 'react';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import get from 'lodash/get';
import useMount from 'react-use/lib/useMount';
import { sleep, InputType, Button, IAction } from '@kassma-team/kassma-toolkit/lib';

import { DASHBOARD_ASIDE_FILTERS_FORM_NAME } from 'utils/constants';
import { currencySelectionSelector } from 'selectors/widgets/currency';
import { countriesSelectionSelector } from 'selectors/widgets/countries';
import { setDashboardAsideFilters } from 'actions/dashboard';
import i18n from '../../i18n';
import { asideFiltersInitData } from 'utils/dashboard/filtersInitData';
import { transactionStatusList } from 'static/transactions';
import { IDashboardStateAsideFilters } from 'interfaces/dashboard';

import FormField from 'components/form/FormField';

interface IProps {
  submit?: () => void;
}

const AsideFilterForm: React.FC<IProps & InjectedFormProps<IDashboardStateAsideFilters, IProps>> = ({
  handleSubmit,
  form,
  submit,
  initialize,
}) => {
  const currencies = useSelector(currencySelectionSelector);
  const countries = useSelector(countriesSelectionSelector);
  const values = useSelector(getFormValues(form));

  useMount(() => {
    initialize({ ...asideFiltersInitData });
  });

  const [t] = useTranslation();

  const filterFields = useMemo(
    () => [
      {
        name: `currency_codes`,
        type: InputType.MUI_MULTI_SELECT,
        data: currencies,
        label: t(`common.currency`),
        placeholder: t(`common.currency`),
      },
      {
        name: `display_in_currency`,
        required: true,
        type: InputType.MUI_SELECT,
        data: currencies,
        label: t(`common.displayIn`),
      },
      {
        name: `date_type`,
        data: transactionStatusList,
        translateKeys: true,
        type: InputType.MUI_SELECT,
        label: t(`common.generateBy`),
      },
      {
        name: `date_range`,
        fromValue: get(values, `date_range.values.startDate`),
        toValue: get(values, `date_range.values.endDate`),
        type: InputType.MUI_DATE_RANGE,
        showLabel: true,
        timePicker: true,
        showSeconds: true,
      },
    ],
    [currencies, values, countries]
  );

  return (
    <form className="p-10 table-filter-form--list" onSubmit={handleSubmit}>
      {map(filterFields, (field, key) => {
        const fieldId = get(field, `name`) || key;

        return <FormField key={fieldId} {...field} />;
      })}
      <Button>{t(`common.filterSubmit`)}</Button>
      <Button
        className="ml-2"
        onClick={async () => {
          initialize({ ...asideFiltersInitData });
          await sleep(100);
          submit && submit();
        }}
        type="button"
      >
        {t(`common.reset`)}
      </Button>
    </form>
  );
};

export default reduxForm<IDashboardStateAsideFilters, IProps>({
  form: DASHBOARD_ASIDE_FILTERS_FORM_NAME,
  onSubmit: (values: IDashboardStateAsideFilters, dispatch: Dispatch): IAction<IDashboardStateAsideFilters> =>
    dispatch(setDashboardAsideFilters(values)),
  validate: (values) => {
    const errors: Record<string, string> = {};

    const dateFrom = get(values, `date_range.values.startDate`);
    const dateTo = get(values, `date_range.values.endDate`);
    if (dateTo && dateFrom) {
      if (new Date(dateFrom).getTime() > new Date(dateTo).getTime()) {
        errors.date_range = i18n.t(`errors.invalidDateRange`);
      }
    }

    return errors;
  },
})(AsideFilterForm);
