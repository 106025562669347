import { widgetActionCreators, IWidgetActionCreators, WidgetOperations } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';

export const withdrawalsLogsActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.WITHDRAWALS_LOGS,
  labels: { listLabel: `data` },
  withPagination: true,
  toastrErrorOptions: {
    [WidgetOperations.LIST_LOADING]: {
      onlyValue: true,
    },
  },
});
