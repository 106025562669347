import map from 'lodash/map';
import filter from 'lodash/filter';
import { Reducer } from 'redux';
import { IWidgetsState } from '@kassma-team/kassma-toolkit/lib/types/widgets';

import {
  ACTIVATE_PRELIMINARY_TRANSACTION_SUCCEEDED,
  ACTIVATE_TRANSACTION_SUCCEEDED,
  ARCHIVATE_TRANSACTION_SUCCEEDED,
  DOWNLOAD_TRANSACTION_CSV_LIST_FAILED,
  DOWNLOAD_TRANSACTION_CSV_LIST_REQUESTED,
  DOWNLOAD_TRANSACTION_CSV_LIST_SUCCEEDED,
} from 'actionTypes';
import { PreliminaryTransactionStatus, TransactionStatus } from 'utils/enums';

const actionTypes = new Set([
  DOWNLOAD_TRANSACTION_CSV_LIST_REQUESTED,
  DOWNLOAD_TRANSACTION_CSV_LIST_SUCCEEDED,
  DOWNLOAD_TRANSACTION_CSV_LIST_FAILED,
  ACTIVATE_PRELIMINARY_TRANSACTION_SUCCEEDED,
  ACTIVATE_TRANSACTION_SUCCEEDED,
  ARCHIVATE_TRANSACTION_SUCCEEDED,
]);

const reducer: Reducer = (state: IWidgetsState, { type, payload }) => {
  switch (type) {
    case DOWNLOAD_TRANSACTION_CSV_LIST_REQUESTED:
      return { ...state, csvLoading: true };
    case DOWNLOAD_TRANSACTION_CSV_LIST_SUCCEEDED:
    case DOWNLOAD_TRANSACTION_CSV_LIST_FAILED:
      return { ...state, csvLoading: false };
    case ACTIVATE_PRELIMINARY_TRANSACTION_SUCCEEDED:
      return {
        ...state,
        items: map(state.items, (item) => {
          return item.primary_id === payload ? { ...item, status: PreliminaryTransactionStatus.PROCESSED } : item;
        }),
      };
    case ACTIVATE_TRANSACTION_SUCCEEDED:
      return {
        ...state,
        items: map(state.items, (item) => {
          return item.primary_id === payload.primary_id
            ? { ...item, ...payload, status: TransactionStatus.ACTIVATED }
            : item;
        }),
      };
    case ARCHIVATE_TRANSACTION_SUCCEEDED:
      return {
        ...state,
        items: filter(state.items, (item) => item.primary_id !== payload.primary_id),
      };
    default:
      return state;
  }
};

const transaction = { actionTypes, reducer };

export default transaction;
