import { Reducer } from 'redux';
import filter from 'lodash/filter';
import map from 'lodash/map';
import get from 'lodash/get';
import { IWidgetsState } from '@kassma-team/kassma-toolkit/lib/types/widgets';

import {
  SET_SORTING_CREATE_MODAL_DATA,
  SET_SORTING_UPDATE_MODAL_DATA,
  RESET_SORTING_CREATE_MODAL_DATA,
  RESET_SORTING_UPDATE_MODAL_DATA,
  SET_PAYMENTS_SORTING_SUCCEEDED,
  UPDATE_HELP_IMAGES_SUCCEEDED,
  CREATE_PAYMENTS_SORTING_SUCCEEDED,
  SET_APP_VERSION_SUCCEEDED,
} from 'actionTypes';
import { ICurrencyListWithCustomSorting } from 'interfaces/widgets/settings';

const actionTypes = new Set([
  UPDATE_HELP_IMAGES_SUCCEEDED,
  SET_SORTING_CREATE_MODAL_DATA,
  SET_SORTING_UPDATE_MODAL_DATA,
  RESET_SORTING_CREATE_MODAL_DATA,
  RESET_SORTING_UPDATE_MODAL_DATA,
  SET_PAYMENTS_SORTING_SUCCEEDED,
  CREATE_PAYMENTS_SORTING_SUCCEEDED,
  SET_APP_VERSION_SUCCEEDED,
]);

interface IState extends IWidgetsState {
  currency_list_with_custom_sorting: ICurrencyListWithCustomSorting[];
}

const reducer: Reducer = (state: IState, { type, payload }) => {
  const currency = get(payload, `currency`);
  const payments = get(payload, `payments`);
  const is_default = get(payload, `is_default`);

  switch (type) {
    case UPDATE_HELP_IMAGES_SUCCEEDED:
      return {
        ...state,
        gif_guide: payload,
      };
    case SET_SORTING_CREATE_MODAL_DATA:
      return {
        ...state,
        currency_list_with_default_sorting: get(payload, `currency_list_with_default_sorting`),
      };
    case RESET_SORTING_CREATE_MODAL_DATA:
      return {
        ...state,
        currency_list_with_default_sorting: undefined,
      };
    case SET_SORTING_UPDATE_MODAL_DATA:
      return {
        ...state,
        applied_payment_systems: get(payload, `applied_payment_systems`),
        default_payment_systems: get(payload, `default_payment_systems`),
      };
    case RESET_SORTING_UPDATE_MODAL_DATA:
      return {
        ...state,
        applied_payment_systems: undefined,
        default_payment_systems: undefined,
      };
    case SET_PAYMENTS_SORTING_SUCCEEDED:
      return {
        ...state,
        currency_list_with_custom_sorting: is_default
          ? filter(state.currency_list_with_custom_sorting, (sorting) => sorting.code !== currency)
          : map(state.currency_list_with_custom_sorting, (sorting) =>
              sorting.code === currency ? { code: currency, payments, is_default } : sorting
            ),
      };
    case CREATE_PAYMENTS_SORTING_SUCCEEDED:
      return {
        ...state,
        currency_list_with_custom_sorting: is_default
          ? state.currency_list_with_custom_sorting
          : [{ code: currency, payments, is_default }, ...state.currency_list_with_custom_sorting],
      };
    case SET_APP_VERSION_SUCCEEDED:
      return { ...state, appVersion: payload };
    default:
      return state;
  }
};

const settings = { actionTypes, reducer };

export default settings;
