import React, { FC } from 'react';

import './PaymentSystemsScreen.scss';

type Props = {
  title: string;
};

/**
 * Контейнер для списка платежных систем.
 */
export const PaymentSystemsScreen: FC<Props> = ({ title, children }) => {
  return (
    <section className="PaymentSystemsScreen">
      <h2 className="PaymentSystemsScreen__title">{title}</h2>

      {children}
    </section>
  );
};
