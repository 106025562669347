import { WalletImg, useFilterColumns, widgetListSelector } from '@kassma-team/kassma-toolkit';
import { WalletStatistic } from 'api/wallets/WalletsApi';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ModalType, WalletStatisticsColumns, WidgetType } from 'utils/enums';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../../utils';

type Props = {
  item: WalletStatistic;
};

function getMaxValue(data: WalletStatistic[`all_daily_turnover`]): number | null {
  if (data.length === 0) {
    return null;
  }

  const maxValue = data.reduce((max, obj) => (obj.value > max ? obj.value : max), data[0].value);

  return maxValue;
}

const WalletStatisticRow = ({ item }: Props) => {
  const {
    visible_counter,
    all_counter,
    all_daily_turnover,
    currency,
    not_removed_balance,
    not_removed_daily_turnover,
    not_removed_wallet_off_limit,
    not_removed_wallet_off_load,
    visible_balance,
    visible_daily_turnover,
    visible_wallet_off_limit,
    visible_wallet_off_load,
    wallet_type_name,
    worked_counter,
  } = item;

  const [t] = useTranslation();

  const paymentSystems = useSelector(widgetListSelector(WidgetType.PAYMENT_SYSTEMS));

  const logo = paymentSystems?.find((el) => el.code === item.wallet_type)?.logo;

  const dispatch = useDispatch();

  const walletTypeRow = (
    <>
      <WalletImg title={wallet_type_name} src={logo} />
      <div>{wallet_type_name}</div>
    </>
  );

  const visibleRow = (
    <div>
      {visible_counter} / {worked_counter} / {all_counter}
    </div>
  );

  const balanceOfVisibleRow = (
    <div>
      <span className="font-w600">{formatNumber(visible_balance)}</span> / {formatNumber(visible_wallet_off_limit)}
    </div>
  );

  const turnoverMaxRow = (
    <div>
      <span className="font-w600">{formatNumber(visible_daily_turnover)}</span> /{` `}
      {formatNumber(visible_wallet_off_load)}
    </div>
  );

  const balanceOfAllRow = (
    <div>
      <span className="font-w600">{formatNumber(not_removed_balance)}</span> /{` `}
      {formatNumber(not_removed_wallet_off_limit)}
    </div>
  );

  const turnoverOfAllMaxRow = (
    <div>
      <span className="font-w600">{formatNumber(not_removed_daily_turnover)}</span> /{` `}
      {formatNumber(not_removed_wallet_off_load)}
    </div>
  );

  const dailyTurnoverRow = (
    <div className="d-flex">
      <div className="d-flex">
        {formatNumber(getMaxValue(all_daily_turnover) || 0)} {currency}
      </div>
      <button
        className="d-flex"
        onClick={() => {
          dispatch({
            type: `WIDGET_SET_MODAL`,
            payload: {
              type: ModalType.WALLETS_STATISTIC,
              walletTypeName: wallet_type_name,
            },
            meta: {
              widget: WidgetType.WALLETS_STATISTIC,
            },
          });
        }}
        title={t(`wallets.walletStatisticTurnoverTip`)}
      >
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Button">
            <path
              id="Union"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 5.35962C6.96214 5.35962 4.5 7.82176 4.5 10.8596C4.5 13.8975 6.96214 16.3596 10 16.3596C13.0379 16.3596 15.5 13.8975 15.5 10.8596C15.5 7.82176 13.0379 5.35962 10 5.35962ZM10 4.35962C6.40986 4.35962 3.5 7.26948 3.5 10.8596C3.5 14.4498 6.40986 17.3596 10 17.3596C13.5901 17.3596 16.5 14.4498 16.5 10.8596C16.5 7.26948 13.5901 4.35962 10 4.35962ZM10 10.3596C10.2761 10.3596 10.5 10.5835 10.5 10.8596V14.193C10.5 14.4691 10.2761 14.693 10 14.693C9.72386 14.693 9.5 14.4691 9.5 14.193V10.8596C9.5 10.5835 9.72386 10.3596 10 10.3596ZM10 9.04541C10.4142 9.04541 10.75 8.70962 10.75 8.29541C10.75 7.8812 10.4142 7.54541 10 7.54541C9.58579 7.54541 9.25 7.8812 9.25 8.29541C9.25 8.70962 9.58579 9.04541 10 9.04541Z"
              fill="#323232"
            />
          </g>
        </svg>
      </button>
    </div>
  );

  const columns = [
    {
      id: WalletStatisticsColumns.WALLET_TYPE,
      mobile: (
        <tr>
          <td>{t(`wallets.walletType`)}</td>
          <td>{walletTypeRow}</td>
        </tr>
      ),
      desktop: <td>{walletTypeRow}</td>,
    },
    {
      id: WalletStatisticsColumns.VISIBLE_WORKING_ALL,
      mobile: (
        <tr>
          <td>{t(`wallets.visibleWorkingAll`)}</td>
          <td>{visibleRow}</td>
        </tr>
      ),
      desktop: <td>{visibleRow}</td>,
    },
    {
      id: WalletStatisticsColumns.BALANCE_OF_VISIBLE_MAX,
      mobile: (
        <tr>
          <td>{t(`wallets.balanceOfVisibleMax`)}</td>
          <td>{balanceOfVisibleRow}</td>
        </tr>
      ),
      desktop: <td>{balanceOfVisibleRow}</td>,
    },
    {
      id: WalletStatisticsColumns.TURNOVER_OF_VISIBLE_MAX,
      mobile: (
        <tr>
          <td>{t(`wallets.turnoverOfVisibleMax`)}</td>
          <td>{turnoverMaxRow}</td>
        </tr>
      ),
      desktop: <td>{turnoverMaxRow}</td>,
    },
    {
      id: WalletStatisticsColumns.BALANCE_OF_ALL_MAX,
      mobile: (
        <tr>
          <td>{t(`wallets.balanceOfAllMax`)}</td>
          <td>{balanceOfAllRow}</td>
        </tr>
      ),
      desktop: <td>{balanceOfAllRow}</td>,
    },
    {
      id: WalletStatisticsColumns.TURNOVER_OF_ALL_MAX,
      mobile: (
        <tr>
          <td>{t(`wallets.turnoverOfAllMax`)}</td>
          <td>{turnoverOfAllMaxRow}</td>
        </tr>
      ),
      desktop: <td>{turnoverOfAllMaxRow}</td>,
    },
    {
      id: WalletStatisticsColumns.TURNOVER_DAILY_TURNOVER,
      mobile: (
        <tr>
          <td>{t(`wallets.turnoverDailyTurnover`)}</td>
          <td>{dailyTurnoverRow}</td>
        </tr>
      ),
      desktop: <td>{dailyTurnoverRow}</td>,
    },
  ];

  return useFilterColumns({ columns, hiddenColumns: item.hiddenColumns, desktopSize: item.desktopSize });
};

export default WalletStatisticRow;
