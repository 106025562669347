import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { useTranslation } from 'react-i18next';
import { DropdownButtons, widgetSelectedItemsSelector } from '@kassma-team/kassma-toolkit/lib';

import { ModalType, Permission, WidgetType } from 'utils/enums';
import { checkAllProxiesRequest, downloadProxies, proxyActionCreators } from 'actions/widgets/proxy';
import { hasAccessSelector } from 'selectors/auth';
import useUserHasAccess from 'hooks/useUserHasAccess';
import { isCheckingAllProxiesSelector } from 'selectors/widgets/proxies';
import useHeaderButtonWithPreloader from 'hooks/widgets/useHeaderButtonWithPreloader';
import useProxiesTableTitles from 'hooks/widgets/tableTitles/useProxiesTableTitles';
import { IProxyRowProps } from 'interfaces/widgets/proxies';

import ProxyRow from 'components/widgets/proxy/ProxyRow';
import ProxyUpdating from 'components/widgets/proxy/ProxyUpdating';
import ProxyRemoving from 'components/widgets/proxy/ProxyRemoving';
import ProxiesRelations from 'components/widgets/proxy/ProxiesRelations';
import Widget from 'components/widgets/Widget';

import 'pages/proxies/proxies.scss';

interface IExtraButtons {
  title: string;
  onClick: () => void;
  buttonClassName: string;
  id: string;
}

const { showModal, resetSelectList } = proxyActionCreators;

const listMapping = (item: IProxyRowProps) => <ProxyRow key={item.uuid} {...item} />;

const Proxies = () => {
  const tableHeadTitles = useProxiesTableTitles();

  useUserHasAccess();
  const [extraButtons, setExtraButtons] = useState<IExtraButtons[]>([]);

  const [t] = useTranslation();

  const dispatch = useDispatch();

  const selectedItems: any = useSelector(widgetSelectedItemsSelector(WidgetType.PROXIES));

  const [modals] = useState([
    {
      type: ModalType.REMOVE_CONFIRMATION,
      widget: WidgetType.PROXIES,
      component: ProxyRemoving,
    },
    {
      type: ModalType.UPDATE,
      widget: WidgetType.PROXIES,
      component: ProxyUpdating,
    },
    {
      type: ModalType.PROXY_RELATIONS,
      widget: WidgetType.PROXIES,
      component: ProxiesRelations,
    },
    // Proxy creation modal is general, so it placed in the component "GeneralModals"
  ]);

  const addProxyAccess = useSelector(hasAccessSelector(Permission.PROXY_CREATE));
  const isCheckingAll = useSelector(isCheckingAllProxiesSelector);

  // Закоментировано в рамках https://jira.fixmost.com/browse/PAY-7208
  // const checkAllProxyBtn = useHeaderButtonWithPreloader({
  //   buttonProps: {
  //     children: t(`proxies.startCheckingAllProxies`),
  //     onClick: () => dispatch(checkAllProxiesRequest()),
  //     disabled: isCheckingAll,
  //     id: `check_all_proxies_btn`,
  //   },
  //   loadingSelector: isCheckingAllProxiesSelector,
  // });

  const onProxyUploadingClick = useCallback(() => {
    dispatch(showModal({ type: ModalType.PROXY_RELATIONS }));
  }, []);

  const onProxyDownloadingClick = useCallback(() => {
    dispatch(downloadProxies());
  }, []);

  useMount(() => {
    dispatch(resetSelectList());
  });

  useEffect(() => {
    const extraButtons: IExtraButtons[] = [];

    if (addProxyAccess) {
      extraButtons.push({
        title: t(`proxies.addProxy`),
        onClick: () => showModal({ type: ModalType.CREATE }),
        buttonClassName: `btn-alt-primary`,
        id: `add_proxy_btn`,
      });
    }

    setExtraButtons(extraButtons);
  }, [addProxyAccess]);

  const extraTableHeaderContent = (
    <>
      <DropdownButtons
        buttons={[
          {
            onClick: onProxyUploadingClick,
            children: t(`proxies.upload`),
            id: `proxy_paytm_relations_upload_btn`,
          },
          {
            children: t(`proxies.download`),
            onClick: onProxyDownloadingClick,
            id: `proxy_paytm_relations_download_btn`,
          },
        ]}
        className="btn-secondary"
        id="proxy_paytm_relations_btn"
      >
        {t(`proxies.proxyConnection`)}
      </DropdownButtons>
      <button
        className="btn btn-secondary"
        disabled={selectedItems.size === 0}
        onClick={() => dispatch(showModal({ type: ModalType.REMOVE_CONFIRMATION, list: [...selectedItems] }))}
      >
        <i className="fa fa-trash" />
      </button>
    </>
  );

  return (
    <Widget
      // Закоментировано в рамках https://jira.fixmost.com/browse/PAY-7208
      // extraHeaderButtons={[checkAllProxyBtn]}
      widget={WidgetType.PROXIES}
      actionCreators={proxyActionCreators}
      pageTitle="proxies.proxies"
      tableTitle="proxies.proxyList"
      headTitle="proxies.proxies"
      tableHeadTitles={tableHeadTitles}
      listMapping={listMapping}
      extraButtons={extraButtons}
      modals={modals}
      statusBar
      extraTableHeaderContent={extraTableHeaderContent}
    />
  );
};

export default Proxies;
