import { widgetActionCreators, IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { createWidgetAction } from '@kassma-team/kassma-toolkit';
import { POSSIBLE_TRANSACTION_APPROVE } from 'actionTypes';

export const possibleTransactionActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.POSSIBLE_TRANSACTIONS,
  labels: {
    itemLabel: `transaction`,
    listLabel: `transactions`,
  },
  uniqKey: `primary_id`,
  withPagination: true,
});

export const approvePossibleTransaction: IWidgetActionCreators = createWidgetAction({
  widget: WidgetType.POSSIBLE_TRANSACTIONS,
})(POSSIBLE_TRANSACTION_APPROVE);
