import React from 'react';
import { useMount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { WidgetType } from 'utils/enums';
import { accountsBalancesActionCreators, transferToAnotherAccount } from 'actions/widgets/balances/accountsBalances';
import { replenishmentActionCreators } from 'actions/widgets/balances/replenishment';
import { replenishmentPSCurrencySelectSelector, replenishmentPSSelectSelector } from 'selectors/widgets/balances';
import {
  IReplenishmentComponentProps,
  ITransferringMoneyToSettlementBalanceForm,
} from 'interfaces/widgets/balances/balances';

import Replenishment from 'components/widgets/balances/Replenishment';

const onSubmit = (values: ITransferringMoneyToSettlementBalanceForm, dispatch: Dispatch) => {
  dispatch(transferToAnotherAccount(values));
};

const AccountsBalanceReplenishment: React.FC<IReplenishmentComponentProps> = () => {
  const dispatch = useDispatch();

  const paymentSystems = useSelector(replenishmentPSSelectSelector);

  useMount(() => {
    dispatch(replenishmentActionCreators.getList());
  });

  return (
    <Replenishment
      paymentSystems={paymentSystems}
      currencySelectorByPSType={replenishmentPSCurrencySelectSelector}
      onSubmit={onSubmit}
      widget={WidgetType.ACCOUNTS_BALANCES}
      actionCreators={accountsBalancesActionCreators}
      title="balances.transferMoneyToTheSettlementBalance"
      submitText="wallets.replenishment"
    />
  );
};

export default AccountsBalanceReplenishment;
