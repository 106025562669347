import React, { useEffect, useMemo, useState } from 'react';
import useMount from 'react-use/lib/useMount';
import useUnmount from 'react-use/lib/useUnmount';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { widgetModalSelector } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import {
  setPaymentsSorting,
  settingsActionCreators,
  getPaymentsByCurrency,
  resetUpdateSortingModalData,
} from 'actions/widgets/settings';
import { appliedPaymentsSelector, defaultPaymentsSelector } from 'selectors/widgets/settings';
import usePaymentsSortingList from 'hooks/widgets/usePaymentsSortingList';

import DragNDropOrder from 'components/widgets/settings/pluginSettings/paymentSorting/DragNDropOrder';
import ModalElement from 'components/modals/ModalElement';
import { ISettingsStateModal } from 'interfaces/widgets/settings';
import { IPaymentSortedList } from 'interfaces/widgets/widgets';

const UpdateSortingModal = () => {
  const [sorting, setSorting] = useState<IPaymentSortedList[]>([]);

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { currency }: ISettingsStateModal | { currency: string } = useSelector(
    widgetModalSelector(WidgetType.SETTINGS)
  ) || { currency: `` };
  const appliedPayments = useSelector(appliedPaymentsSelector);
  const defaultPayments = useSelector(defaultPaymentsSelector);

  useMount(() => {
    if (currency) {
      dispatch(getPaymentsByCurrency(currency));
    }
  });

  useUnmount(() => dispatch(resetUpdateSortingModalData()));

  const { getSortingList, sortingListsAreEqual } = usePaymentsSortingList();

  useEffect(() => setSorting(getSortingList(appliedPayments)), [appliedPayments]);

  const defaultSorting = useMemo(() => getSortingList(defaultPayments), [defaultPayments]);

  const content = (
    <>
      <div className="payment-constructor__header-section mb-10">
        <span className="font-weight-bold mr-5">{t(`common.currency`)}:</span>
        <span>{currency}</span>
      </div>
      <DragNDropOrder
        sorting={sorting}
        defaultSorting={defaultSorting}
        currency={currency as string}
        setSorting={setSorting}
        submitAction={setPaymentsSorting}
        sortingListsAreEqual={sortingListsAreEqual}
      />
    </>
  );

  return (
    <ModalElement actionCreators={settingsActionCreators} content={content} title="settings.editingDisplayOrder" />
  );
};

export default UpdateSortingModal;
