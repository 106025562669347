import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { widgetItemSelector } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { paytmActionCreators, resetPaytmWallet } from 'actions/widgets/payments/paytm';

import ConfirmationModal from 'components/modals/ConfirmationModal';

const PaytmDataResetting = () => {
  const dispatch = useDispatch();

  const [t] = useTranslation();

  const identifier = useSelector(widgetItemSelector(WidgetType.DISABLED_WALLETS));
  const id = get(identifier, `id`);

  const handleConfirm = () => {
    dispatch(resetPaytmWallet(id));
  };

  return (
    <ConfirmationModal
      actionCreators={paytmActionCreators}
      confirmText={t(`common.reset`)}
      content={t(`wallets.doYouReallyWantToResetThisWalletSettings`)}
      onConfirm={handleConfirm}
      title="wallets.dataResetting"
    />
  );
};

export default PaytmDataResetting;
