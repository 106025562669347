import { createSelector } from 'reselect';
import map from 'lodash/map';
import filter from 'lodash/filter';
import find from 'lodash/find';
import { widgetSelector, IRootState } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType, PaymentTranslationFormType } from 'utils/enums';
import {
  IPaymentTranslationDataItem,
  IPaymentTranslationDataList,
  IPaymentsConstructorState,
  IPaymentsConstructorViewData,
  ISupportedCurrenciesList,
  ITariffsData,
  IUpdateDepositsInitialData,
  IWorkingTimeDataTime,
  IUseProxy,
} from 'interfaces/widgets/constructor/paymentsConstructor';

export const paymentCurrencySelector = createSelector<IRootState, IPaymentsConstructorState, any[]>(
  widgetSelector(WidgetType.PAYMENTS_CONSTRUCTOR),
  (state) => state.currencies || []
);

export const availablePaymentCurrencySelectionSelector = createSelector<
  IRootState,
  IPaymentsConstructorState,
  ISupportedCurrenciesList[]
>(widgetSelector(WidgetType.PAYMENTS_CONSTRUCTOR), (state) => {
  const list = state?.currencies || [];

  return list.map((item) => ({ text: item.currency, value: item.id }));
});

export const appliedPaymentCurrencyItemSelectionSelector = createSelector<IRootState, any, string[]>(
  paymentCurrencySelector,
  (list) => list.filter((item: any) => item.is_applied)
);

export const appliedPaymentCurrencySelectionSelector = createSelector<IRootState, any, string[]>(
  paymentCurrencySelector,
  (list) => {
    return list.filter((item: any) => item.is_applied).map((item: any) => item.id);
  }
);

export const appliedPaymentLogosSelector = createSelector<
  IRootState,
  IPaymentsConstructorState,
  Record<string, unknown>
>(widgetSelector(WidgetType.PAYMENTS_CONSTRUCTOR), (state) => {
  const logos = state.logos;
  const mainLogo = state.main_logo;

  return { logos, mainLogo };
});

export const appliedDepositsSelector = createSelector<
  IRootState,
  IPaymentsConstructorState,
  IUpdateDepositsInitialData[]
>(widgetSelector(WidgetType.PAYMENTS_CONSTRUCTOR), (state) => state.minDepositData);

export const appliedPaymentTitlesSelector = createSelector<
  IRootState,
  IPaymentsConstructorState,
  Record<string, unknown>
>(widgetSelector(WidgetType.PAYMENTS_CONSTRUCTOR), (state) => {
  const extra_names = state.extra_names;
  const default_name = state.default_name;
  const data: Record<string, unknown> = {};
  if (extra_names) {
    data.extra_names = extra_names;
  }
  if (default_name) {
    data.default_name = default_name;
  }

  return data;
});

export const paymentViewDataSelector = createSelector<
  IRootState,
  IPaymentsConstructorState,
  IPaymentsConstructorViewData
>(widgetSelector(WidgetType.PAYMENTS_CONSTRUCTOR), (state) => state.viewData);

export const paymentTranslationsHintSelector = createSelector<
  IRootState,
  IPaymentsConstructorState,
  IPaymentTranslationDataItem[]
>(widgetSelector(WidgetType.PAYMENTS_CONSTRUCTOR), (state) => state?.translationsData?.hints);

export const paymentTranslationsFieldSelector = createSelector<
  IRootState,
  IPaymentsConstructorState,
  IPaymentTranslationDataItem[]
>(widgetSelector(WidgetType.PAYMENTS_CONSTRUCTOR), (state) => state?.translationsData?.fields);

export const paymentHintDataSelector = createSelector<
  IRootState,
  IPaymentTranslationDataItem[],
  IPaymentTranslationDataList[]
>(paymentTranslationsHintSelector, (list) =>
  map(list, (item) => ({ value: item.id, text: item.name, form_type: item.form_type }))
);

export const paymentFieldDataSelector = createSelector<
  IRootState,
  IPaymentTranslationDataItem[],
  IPaymentTranslationDataList[]
>(paymentTranslationsFieldSelector, (list) =>
  map(list, (item) => ({ value: item.id, text: item.name, form_type: item.form_type }))
);

export const paymentHintNameByIdSelector = (id: number) =>
  createSelector<IRootState, IPaymentTranslationDataItem[], string | undefined>(
    paymentTranslationsHintSelector,
    (list) => find(list, (item) => item.id === id)?.name
  );

export const paymentFieldNameByIdSelector = (id: number) =>
  createSelector<IRootState, IPaymentTranslationDataItem[], string | undefined>(
    paymentTranslationsFieldSelector,
    (list) => find(list, (item) => item.id === id)?.name
  );

export const requisiteTypeHintsSelector = createSelector<
  IRootState,
  IPaymentTranslationDataList[],
  IPaymentTranslationDataList[]
>(paymentHintDataSelector, (list) =>
  filter(list, (item) => {
    return item.form_type === PaymentTranslationFormType.REQUISITE;
  })
);

export const paymentTypeHintsSelector = createSelector<
  IRootState,
  IPaymentTranslationDataList[],
  IPaymentTranslationDataList[]
>(paymentHintDataSelector, (list) => filter(list, (item) => item.form_type === PaymentTranslationFormType.PAYMENT));

export const requisiteTypeFieldsSelector = createSelector<
  IRootState,
  IPaymentTranslationDataList[],
  IPaymentTranslationDataList[]
>(paymentFieldDataSelector, (list) => filter(list, (item) => item.form_type === PaymentTranslationFormType.REQUISITE));

export const paymentTypeFieldsSelector = createSelector<
  IRootState,
  IPaymentTranslationDataList[],
  IPaymentTranslationDataList[]
>(paymentFieldDataSelector, (list) => filter(list, (item) => item.form_type === PaymentTranslationFormType.PAYMENT));

export const tariffsSelector = (widget: string) =>
  createSelector<IRootState, IPaymentsConstructorState, ITariffsData[]>(
    widgetSelector(widget),
    (state) => state.tariffs?.data
  );

export const paymentWorkingTimeDataSelector = createSelector<
  IRootState,
  IPaymentsConstructorState,
  IWorkingTimeDataTime
>(widgetSelector(WidgetType.PAYMENTS_CONSTRUCTOR), (state) => state.workingTimeData?.time);

export const useProxySelector = createSelector<IRootState, IPaymentsConstructorState, boolean>(
  widgetSelector(WidgetType.PAYMENTS_CONSTRUCTOR),
  (state) => state.use_proxy
);

export const useProxyNightParserSelector = createSelector<IRootState, IPaymentsConstructorState, boolean>(
  widgetSelector(WidgetType.PAYMENTS_CONSTRUCTOR),
  (state) => state.use_proxy_night_parser
);

export const allowedRequisitesUpdateSelector = createSelector<IRootState, IPaymentsConstructorState, boolean>(
  widgetSelector(WidgetType.PAYMENTS_CONSTRUCTOR),
  (state) => state.allowed_update_requisites
);

// is_check_amount
export const isCheckAmountSelector = createSelector<IRootState, IPaymentsConstructorState, boolean>(
  widgetSelector(WidgetType.PAYMENTS_CONSTRUCTOR),
  (state) => state.is_check_amount
);

export const getConstructorData = createSelector<
  IRootState,
  {
    transactions_verification: boolean;
    no_transactions_period?: number;
    disable_wallet_visibility?: boolean;
    impression_counter?: number;
  },
  {
    transactions_verification: boolean;
    no_transactions_period?: number;
    disable_wallet_visibility?: boolean;
    impression_counter?: number;
  }
>(widgetSelector(WidgetType.PAYMENTS_CONSTRUCTOR), (state) => state);
