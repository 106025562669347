import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DropdownButtons, getSubmitHandler, GridType } from '@kassma-team/kassma-toolkit/lib';
import { reduxForm } from 'redux-form';

import useUserHasAccess from 'hooks/useUserHasAccess';
import { FilterWalletStatus, ModalType, Permission, WidgetType } from 'utils/enums';
import { toggleVisibilityOfAllWallets, walletActionCreators } from 'actions/widgets/wallet';
import walletRouteTabs from 'pages/wallets/walletRouteTabs';
import { hasAccessSelector } from 'selectors/auth';
import useWalletsExtraButtons from 'hooks/widgets/wallets/useWalletsExtraButtons';
import useWalletTableTitles from 'hooks/widgets/tableTitles/wallets/useWalletTableTitles';
import { IWalletRowProps } from 'interfaces/widgets/wallets/wallet';
import { IFilterProps } from 'interfaces/widgets/widgets';
import useWalletFilterFields from 'hooks/widgets/tableTitles/wallets/useWalletFilterFields';

import WalletRow from 'components/widgets/wallets/WalletRow';
import WalletUpdating from 'components/widgets/wallets/active/WalletUpdating';
import ActiveWalletRemoving from 'components/widgets/wallets/active/ActiveWalletRemoving';
import Widget from 'components/widgets/Widget';
import Filters from 'components/widgets/filters/Filters';

import './wallets.scss';

const FORM_NAME = `wallets-filters`;

const FilterForm = reduxForm<Record<string, unknown>, IFilterProps>({
  form: FORM_NAME,
  onSubmit: getSubmitHandler({}),
})(Filters);

const listMapping = (item: IWalletRowProps) => {
  return <WalletRow key={item.id} {...item} widget={WidgetType.WALLETS} />;
};

const modals = [
  {
    type: ModalType.UPDATE,
    component: WalletUpdating,
    widget: WidgetType.WALLETS,
  },
  {
    type: ModalType.REMOVE_CONFIRMATION,
    widget: WidgetType.WALLETS,
    component: ActiveWalletRemoving,
  },
];

const Wallets = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  useUserHasAccess();

  const extraButtons = useWalletsExtraButtons();

  const updateWalletPermission = useSelector(hasAccessSelector(Permission.WALLET_UPDATE));

  const titles = useWalletTableTitles();

  const extraHeaderContent = (
    <>
      {updateWalletPermission && (
        <DropdownButtons
          className="btn-secondary"
          buttons={[
            {
              onClick: () => dispatch(toggleVisibilityOfAllWallets(true)),
              children: t(`wallets.enableVisibilityOfAllWallets`),
            },
            {
              onClick: () => dispatch(toggleVisibilityOfAllWallets(false)),
              children: t(`wallets.disableVisibilityOfAllWallets`),
            },
          ]}
          children={t(`wallets.visibilityOfAllWallets`)}
        />
      )}
    </>
  );

  const leftFilterFields = useWalletFilterFields(FORM_NAME, FilterWalletStatus.WORKED);

  return (
    <Widget
      widget={WidgetType.WALLETS}
      routeTabs={walletRouteTabs}
      extraHeaderContent={extraHeaderContent}
      actionCreators={walletActionCreators}
      pageTitle="wallets.wallets"
      tableTitle="wallets.walletList"
      tableHeadTitles={titles}
      listMapping={listMapping}
      modals={modals}
      filterFormName={FORM_NAME}
      filterForm={
        <FilterForm leftFields={leftFilterFields} formName={FORM_NAME} gridType={GridType.GRID} numFields={[`id`]} />
      }
      statusBar
      headTitle="wallets.wallets"
      extraButtons={extraButtons}
      withPagination
    />
  );
};

export default Wallets;
