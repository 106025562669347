import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';

import { selectionOfManualPSSelector } from 'selectors/widgets/manualPS';
import { manualPSActionCreators } from 'actions/widgets/paymentSystem';
import { ISelectData } from '@kassma-team/kassma-toolkit/lib/types/form';
import { WalletType } from 'api/WalletTypes';

interface IProps {
  walletType?: string;
  form?: string;
  fieldNameOfSelectionOfTestPayments?: string;
}

const useManualPSList = ({ walletType, form, fieldNameOfSelectionOfTestPayments }: IProps): Array<WalletType> => {
  const [isLoadedTestPayments, setIsLoadedTestPayments] = useState(false);

  const testPaymentSystems = useSelector(selectionOfManualPSSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (walletType === `test` && !isLoadedTestPayments) {
      dispatch(manualPSActionCreators.getList());
      setIsLoadedTestPayments(true);
    }
    if (walletType !== `test` && form && fieldNameOfSelectionOfTestPayments) {
      dispatch(change(form, fieldNameOfSelectionOfTestPayments, null));
    }
  }, [walletType]);

  return testPaymentSystems;
};

export default useManualPSList;
