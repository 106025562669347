import React, { useCallback, useState } from 'react';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { IModalFormProps, InputType } from '@kassma-team/kassma-toolkit/lib';

import { TRANSACTIONS_UPLOADING_FORM_NAME } from 'utils/constants';
import { WidgetType } from 'utils/enums';
import { transactionActionCreators, uploadTransactionsRequest } from 'actions/widgets/transactions/transactions';

import ModalForm from 'components/modals/ModalForm';

const TransactionsUploadingModal = (props: IModalFormProps) => {
  const [t] = useTranslation();

  const [fields] = useState([
    {
      type: InputType.DROPZONE,
      label: t(`transactions.psvFile`),
      name: `file`,
      fileAcceptedTypes: [`psv`],
      maxSize: 1024 * 1024 * 200,
      required: true,
    },
  ]);

  const onSubmit = useCallback((values, dispatch) => {
    dispatch(uploadTransactionsRequest(values));
  }, []);

  return (
    <ModalForm
      {...props}
      widget={WidgetType.TRANSACTIONS}
      actionCreators={transactionActionCreators}
      fields={fields}
      onSubmit={onSubmit}
      submitText="common.upload"
      title="transactions.loadIciciTransactionFile"
    />
  );
};

export default reduxForm({
  form: TRANSACTIONS_UPLOADING_FORM_NAME,
})(TransactionsUploadingModal);
