import React from 'react';

import { disabledWalletActionCreators } from 'actions/widgets/wallet';
import { WidgetType } from 'utils/enums';

import WalletModal from 'components/widgets/wallets/WalletModal';

const DisabledWalletModal = () => {
  return <WalletModal actionCreators={disabledWalletActionCreators} widget={WidgetType.DISABLED_WALLETS} />;
};

export default DisabledWalletModal;
