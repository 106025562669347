import { ModalElement } from '@kassma-team/kassma-toolkit';
import { walletsSummary } from 'actions/widgets/wallets';
import api from 'api';
import React, { FC, useEffect } from 'react';
import { WalletSummary } from 'api/wallets/WalletsApi';
import { Accordion } from './components/Accordion';
import { useDispatch } from 'react-redux';

// Create type for normalized data structure
type WalletsSummaryData = Record<WalletSummary[`wallet_type`], Record<WalletSummary[`currency`], WalletSummary>>;

const normalize = (walletsSummary: ReadonlyArray<WalletSummary>): WalletsSummaryData => {
  return walletsSummary.reduce((acc, walletSummary) => {
    const { wallet_type_name, currency } = walletSummary;

    if (!acc[wallet_type_name]) {
      acc[wallet_type_name] = {};
    }

    acc[wallet_type_name][currency] = walletSummary;

    return acc;
  }, {} as WalletsSummaryData);
};

export const WalletSummaryModal: FC = () => {
  const [data, setData] = React.useState<WalletsSummaryData>({});
  const dispatch = useDispatch();

  useEffect(() => {
    const fetcher = async () => {
      const { data } = await api.walletsApi.getSummary();

      setData(normalize(data.data));
    };

    fetcher();
  }, []);

  return (
    <ModalElement
      actionCreators={walletsSummary}
      title={`wallets.activeWallets`}
      content={<Accordion data={data} />}
      dispatch={dispatch}
    />
  );
};
