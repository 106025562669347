import { BaseApi } from 'api/common/BaseApi';
import { AxiosPromise } from 'axios';

export class CurrencyApi extends BaseApi {
  loadCurrencyList = (): AxiosPromise => this.makeFetch(`list`, {});

  setDefaultSorting = (data: any): AxiosPromise => {
    const { currency, ...rest } = data;

    return this.makeFetch(
      `{currency}`,
      { method: `POST`, data: rest },
      {
        currency,
      }
    );
  };
}

const currencyList = new CurrencyApi(`currency`);

export default currencyList;
