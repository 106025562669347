import { useState } from 'react';

export function useOpenedFormType() {
  const [openedFormTypes, setOpenedFormTypes] = useState<Array<string>>([]);

  return {
    openedFormTypes,
    setOpenedFormTypes,
  };
}
