export const useSettlementWithdrawalTitles = () => {
  return [
    {
      title: `common.date`,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `balances.writeOffAddress`,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.amount`,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.status`,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.actions`,
      desktop: true,
      mobile: true,
      disabled: true,
    },
  ];
};
