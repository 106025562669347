import React, { FC, useContext, createContext, useState, Dispatch, SetStateAction } from 'react';
import { PackageTranslation } from 'api/packages/PackagesApi';

type ModifiedPackageTranslationsCtx = {
  setModifiedPackageTranslations: Dispatch<SetStateAction<Array<PackageTranslation>>>;
  modifiedPackageTranslations: Array<PackageTranslation>;
};

const modifiedPackageTranslationsCtx = createContext<ModifiedPackageTranslationsCtx | undefined>(undefined);

export const ModifiedPackageTranslationsCtx: FC = ({ children }) => {
  const [modifiedPackageTranslations, setModifiedPackageTranslations] = useState<Array<PackageTranslation>>([]);

  const val: ModifiedPackageTranslationsCtx = {
    modifiedPackageTranslations,
    setModifiedPackageTranslations,
  };

  return <modifiedPackageTranslationsCtx.Provider value={val}>{children}</modifiedPackageTranslationsCtx.Provider>;
};

export function useModifiedPackageTranslationsCtx(): ModifiedPackageTranslationsCtx {
  const context = useContext(modifiedPackageTranslationsCtx);

  if (context === undefined) {
    throw new Error(
      `Is need to use useModifiedPackageTranslationsCtx inside a ModifiedPackageTranslationsContext component`
    );
  }

  return context;
}
