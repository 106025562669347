import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { v4 } from 'uuid';

import {
  usePaymentSystemsCtx,
  useCreationModeCtx,
  useTranslationsCtx,
  useFormTypeCtx,
} from 'pages/constructor/DefaultTranslates/PackagesTranslation/ctx';

import { TranslateDropdown } from './TranslateDropdown';
import CreationTranslateDropdown from './CreationTranslateDropdown';

import './TranslatesScreen.scss';
import { Package } from 'api/packages/PackagesApi';

export const TranslatesScreen: FC = () => {
  const [t] = useTranslation();
  const { currentFormType } = useFormTypeCtx();
  const { translations } = useTranslationsCtx();
  const { currentPaymentSystem } = usePaymentSystemsCtx();

  const currentField = translations.filter((el) => {
    return (
      el.wallet_type === currentPaymentSystem?.code &&
      el.form_type === currentFormType?.type &&
      el.uuid === currentFormType?.entityId
    );
  });

  const { isCreationMode, creationPackage, setCreationPackage } = useCreationModeCtx();

  const handleAddTranslate = (fieldUid: string) => {
    if (creationPackage) {
      setCreationPackage({
        ...creationPackage,
        packages_translations: creationPackage.packages_translations.map((el) => {
          if (el.field === fieldUid) {
            return { ...el, translations: [...el.translations, { clientId: v4(), language: ``, text: `` }] };
          }

          return el;
        }),
      });
    }
  };

  const handleChangeLang = (packageTranslationUuid: string, language: string, clientId?: string) => (
    option: { value: string; text: string } | string
  ) => {
    if (creationPackage) {
      setCreationPackage({
        ...creationPackage,
        packages_translations: creationPackage.packages_translations.map((el) => {
          if (el.field === packageTranslationUuid) {
            return {
              ...el,
              translations: el.translations.map((el) => {
                if (el.clientId === clientId) {
                  return {
                    ...el,
                    language: typeof option === `object` ? option.value : option,
                  };
                }

                return el;
              }),
            };
          }

          return el;
        }),
      });
    }
  };

  const handleChangeText = (packageTranslationUuid: string, language: string, clientId?: string) => (
    text: EditorState
  ) => {
    if (clientId && creationPackage) {
      setCreationPackage({
        ...creationPackage,
        packages_translations: creationPackage.packages_translations.map((el) => {
          if (el.field === packageTranslationUuid) {
            return {
              ...el,
              translations: el.translations.map((el) => {
                if (el.clientId === clientId) {
                  return {
                    ...el,
                    text: stateToHTML(text.getCurrentContent(), {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error Null - отключает тег. undefined, как описано в типе - нет.
                      defaultBlockTag: null,
                      inlineStyles: {
                        BOLD: { element: `b` },
                      },
                    }),
                    prevState: text,
                  };
                }

                return el;
              }),
            };
          }

          return el;
        }),
      });
    }
  };

  const handleRemove = (packageTranslationUuid: string, cliendId?: string) => () => {
    setCreationPackage({
      ...creationPackage,
      // @ts-expect-error
      packages_translations: creationPackage.packages_translations.map((el) => {
        if (el.field === packageTranslationUuid) {
          return {
            ...el,
            translations: el.translations
              .map((el) => {
                if (el.clientId !== cliendId) return;

                return el;
              })
              .filter((el) => el),
          };
        }

        return el;
      }),
    });
  };

  const isDisabled = ({ is_default, is_manual }: Package) => {
    if (is_default && is_manual) return false;
    if (!is_default && is_manual) return false;
    if (!is_default && !is_manual) return false;

    return true;
  };

  return (
    <div className="TranslatesScreenPackages">
      <p className="TranslatesScreenPackages__title">{t(`transactions.transfer`)}</p>
      {isCreationMode &&
        creationPackage &&
        creationPackage.packages_translations.map((el) => {
          return (
            <CreationTranslateDropdown
              key={el.field}
              packageTranslation={el}
              fieldId={el.field}
              handleAddTranslate={handleAddTranslate}
              handleChangeLang={handleChangeLang}
              handleChangeText={handleChangeText}
              handleRemove={handleRemove}
            />
          );
        })}

      {!isCreationMode &&
        currentField.map((field) => {
          return field.packages_translations.map((translation, i) => {
            return (
              <TranslateDropdown
                packageName={field.name}
                key={`${field.uuid}-${translation.field}-${i}`}
                fieldId={field.uuid}
                is_manual={field.is_manual}
                packageTranslation={translation}
                isDisabled={isDisabled(field)}
                otherTranslationsFormField={field.packages_translations}
              />
            );
          });
        })}
    </div>
  );
};
