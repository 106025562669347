const en = {
  "translation": {
    "auth": {
      "login": "Login",
      "password": "Password",
      "signIn": "Sign in",
      "logout": "Logout",
      "configError": "Config error",
      "noRequiredParameters": "No requiered parameters"
    },
    "notification": {
      "center": "Notification center",
      "unreaded": "Unread",
      "readed": "Read",
      "showAll": "Show all",
      "hide": "Hide",
      "hasNotMessages": "You have no unread messages"
    },
    "quantities": {
      "million": "M",
      "billion": "B",
      "trillion": "T",
      "quadrillion": "q",
      "quintillion": "Q"
    },
    "withdrawals": {
      "withdrawals": "Withdrawals",
      "withdrawalList": "Withdrawal list",
      "new": "New",
      "inProgress": "Processing",
      "canceled": "Canceled",
      "processed": "Approved",
      "rejected": "Rejected",
      "addBeneficiare": "Add beneficiare",
      "withdrawalHasBeenSuccessfullyRejected": "Withdrawal has been successfully rejected",
      "withdrawalHasBeenSuccessfullyApproved": "Withdrawal has been successfully approved",
      "withdrawalRejection": "Withdrawal rejection",
      "withdrawalApproval": "Withdrawal approval",
      "sendersWalletNumber": "Sender's wallet number",
      "receiversWalletNumber": "Receiver's wallet number",
      "withdrawal": "Withdrawal",
      "filterNew": "New",
      "filterInProgress": "Processing",
      "filterCanceled": "Canceled",
      "filterProcessed": "Approved",
      "filterRejected": "Rejected",
      "withdrawalId": "Withdrawal ID",
      "receipts": "Receipts",
      "areYouSureYouWantToApproveThisWithdrawal": "Are you sure you want to approve this withdrawal?",
      "recipient": "Recipient",
      "sender": "Sender",
      "receipt": "Receipt",
      "method": "Method",
      "bankCode": "Bank Code",
      "branchCode": "Branch code",
      "manualWithdrawalRequest": "Manual withdrawal request",
      "user": "User",
      "auto": "Auto",
      "manual": "Manual",
      "type": "Request",
      "withdrawalRecipientBank": "Wallet recipient name",
      "bankAccountRecipient": "Bank account recipient",
      "walletRecipientNumber": "Wallet recipient name",
      "card": "Card",
      "wallet_sender": "Wallet sender",
      "walletSender": "Wallet sender",
      "walletSenderIdentifier": "Wallet sender identifier",
      "cardNumber": "Wallet recipient name",
      "label": "Label",
      "walletRecipient": "Wallet recipient",
      "accountName": "Account name",
      "comment": "Comment",
      "processing": "Processing",
      "approved": "Approved",
      "payoutMethod": "Payout method",
      "providerId": "Provider",
      "walletRecipientAccount": "Recipient account",
      "branchCode2": "Branch",
      "email": "Recipient email",
      "walletRecipientName": "Recipient name",
      "walletRecipientCard": "Recipient wallet",
      "filterProcessing": "Processing",
      "filterApproved": "Approved",
      "areYouSureYouWantToRejectThisWithdrawal": "Are you sure you want to reject this withdrawal?",
      "checkApprovedBtn": "Approved",
      "checkRejectedBtn": "Rejected",
      "doYouConfirmThatTheWithdrawalHasBeenMade": "Do you confirm that the withdrawal has been made?",
      "doYouConfirmThatTheWithdrawalHasNotBeenMade": "Do you confirm that the withdrawal has NOT been made?",
      "check": "Check",
      "walletNumberForWithdrawal": "Wallet number for withdrawal",
      "cardForWithdrawal": "Card for withdrawal",
      "requestDate": "Request date",
      "requestType": "Request type",
      "withdrawalInfo": "Withdrawal info",
      "updatedAt": "Updated at",
      "changeComment": "Change the comment",
      "commentHasBeenSuccessfullyUpdated": "Comment has been successfully updated.",
      "impsReceiverName": "Receiver name",
      "impsTransferType": "Transfer type",
      "impsIfsc": "Receiver IFSC",
      "impsAccountNumber": "Account number",
      "impsIciciBankClient": "ICICI bank client",
      "impsOtherBankClient": "Other bank client",
      "partitial": "Partitial success",
      "partitialAmount": "Partitial success amount",
      "withdrawalMethod": "Withdrawal method\t",
      "manualMethod": "Manually from PA",
      "accountNumber": "Account number",
      "accountEmail": "Recipient email",
      "manualWithdrawals": "Manual withdrawals",
      "manualWithdrawalsUploading": "Upload manual withdrawal requests",
      "manualWithdrawalsDownloading": "Download manual withdrawal requests",
      "manualDownload": "Download",
      "xlsxFile": "XLSX-file",
      "withdrawalsHaveBeenSuccessfullyUploaded": "Withdrawals have been successfully uploaded",
      "pagsmileBeneficiaryName": "The beneficiary name",
      "pagsmileBankSelectionType": "Type of bank selection",
      "pagsmileBanksCodeList": "List of banks",
      "pagsmileBanksCodeText": "Write bank code",
      "pagsmileBankSelection": "Bank selection",
      "pagsmileWriteBankCode": "Write bank code XXX",
      "pagsmileBranch": "The code of bank branch",
      "pagsmileActionType": "The type of beneficiary bank account",
      "pagsmileAccountNumber": "The benenficiary's bank account number",
      "pagsmileDocumentType": "The beneficiary's document type",
      "pagsmileDocumentID": "The beneficiary's document id",
      "pagsmileAccountDigit": "Account digit",
      "walletRecipientID": "Recipient ID",
      "paygigaIBAN": "IBAN",
      "paygigaBankSelection": "Bank selection",
      "paymentsMethod": "Payments method",
      "paymentsProvider": "Payments provider",
      "bankCodeInPaymentsSystem": "Bank code in payments system",
      "error": "Withdrawal error",
      "unprocessedWithdrawalRequests": "Unprocessed withdrawal requests",
      "incorrectWalletSender": "Empty Wallet sender for an accepted request",
      "incorrectStatus": "Incorrect status",
      "logField": "Date {{sheet}} line {{data}}",
      "turnOnManualFill": "*If the needed wallet is not in the list, turn on the \"Manual fill\" option and fill in the wallet number manually.",
      "manualFill": "Manual fill",
      "walletNumberForWithdrawalWithAnnotation": "Wallet number for withdrawal*",
      "addWithdrawalRequest": "Add withdrawal request",
      "downloadExampleFile": "Download example file",
      "tooltip": "To accept the request, enter the wallet number for withdrawal in the Wallet sender column and the value “Yes” in the Status column.\r\nTo reject the request, enter the value “No” in the Status column.",
      "statusMessage": "Status message",
      "kind": "Request kind",
      "confirmedKind": "Confirmed",
      "debugKind": "Debug"
    },
    "balances": {
      "transferMoney": "Transfering",
      "transferMoneyBetweenAccounts": "Transferring money between accounts",
      "writeOffAccount": "Write-off account",
      "replenishmentAccount": "Replenishment account",
      "accountType": "Account type",
      "toAnotherAccountBalance": "To another account balance",
      "toSettlementBalance": "To settlement balance",
      "transferMoneyToAnotherAccountBalance": "Transferring money to another account balance",
      "transferMoneyToTheSettlementBalance": "Transferring money to the settlement balance",
      "toCrediting": "To crediting",
      "replenishmentFromExternalAccount": "Replenishment from the client's external account",
      "withdrawalToExternalAccount": "Withdrawal to the client's external account",
      "transferMoneyToClientAccountBalance": "Transfering to the balance of the client account",
      "replenishmentSuccessful": "Replenishment was successful.",
      "replenishmentError": "Replenishment error",
      "transferingSuccessful": "Transfering was successful",
      "transferingError": "Transfering error",
      "transferOnBalance": "Transfering to the balance of the client's account",
      "unknownOperationType": "Unknown operation type",
      "writeOffAddress": "Write off address",
      "withdrawals": "Withdrawals",
      "statusNew": "New",
      "statusSuccess": "Success",
      "statusRejected": "Rejected",
      "currentBalance": "Current balance",
      "show": "Show",
      "showBalanceOn": "Show balance on:",
      "balanceOn": "Balance on",
      "noDataAvailableForDate": "No data available for the selected date",
      "accounts": "Accounts",
      "transferHistory": "Transfer history",
      "replenishmentAccountType": "Replenishment account type",
      "writeOffAccountType": "Write-off account type",
      "writeOffAmountInTransactionCurrency": "The write-off amount in the transaction currency",
      "replenishmentAmountInTransactionCurrency": "The replenishment amount in the transaction currency",
      "operationHistory": "Operation history",
      "accountBalance": "Account balance",
      "externalAccount": "External account",
      "operationType": "Operation type",
      "availableBalance": "Available balance",
      "withdrawalRequests": "Withdrawal requests",
      "hideZeroBalances": "Hide zero balances",
      "zeroBalancesPlaceholderTitle": "The balances of all accounts are zero",
      "zeroBalancesPlaceholderDescription": "Change the date or enable the display of zero balances.",
      "cancelBtn": "Cancel",
      "canceledLabel": "Canceled",
      "compensatingLabel": "Compensating",
      "cancelOperationTitle": "Cancel operation",
      "cancelModalContent": "Are you sure you want to cancel the operation?",
      "extraInfo": "Information"
    },
    "reports": {
      "reports": "Reports",
      "archivingStatus": "Transaction archiving status",
      "notArchivedOnly": "Not archived only",
      "archivedOnly": "Archived only",
      "oneRecipientEmail": "At least one recipient email must be entered",
      "transactions_list": "Transaction list",
      "transactions_sum-detail": "Sum of transactions with details by day",
      "transactions_sum": "Sum of transactions",
      "listOfTasksForAutoGeneration": "List of tasks for autogeneration reports",
      "creatingAutogenerationTask": "Creating task for autogenerating",
      "updatingAutogenerationTask": "Updating autogeneration task",
      "deletingAutogenerationTask": "Deleting autogeneration task",
      "doYouDeleteAutogenerationTask": "Are you sure to delete this autogeneration task?",
      "createTask": "Create task",
      "reportingPeriod": "Reporting period",
      "start": "Start",
      "autogeneratingReport": "Autogenerating of report",
      "reportTasks": "Tasks",
      "report": "Report",
      "recipient": "Recipient",
      "author": "Author",
      "actions": "Actions",
      "utc": "Timezone UTC+00:00",
      "reportGenerating": "Report generating",
      "generateReport": "Generate report",
      "calcAmounts": "Calculate amounts",
      "fields": "Fields",
      "reportList": "Report list",
      "file": "File",
      "reportInProgress": "Report generation can take up to 5 minutes. You can leave the page for now. The generated report will be available here until 11:59 pm tomorrow",
      "kindOfReport": "Kind of report",
      "sumOfTransactions": "Sum of transactions",
      "counterparty": "Counterparty",
      "reportType": "Report type",
      "activated": "Activated",
      "notActivated": "Not activated",
      "canceled": "Canceled",
      "approved": "Approved",
      "activationPeriod": "Activation period",
      "activationData": "Activation date",
      "archivingPeriod": "Archiving period",
      "archivedTransactionsOnly": "Only archived transactions",
      "archivingDate": "Archiving date",
      "orderId": "Order ID",
      "commissionAmount": "Commission amount",
      "detailByDay": "Detail by day",
      "listOfWithdrawalRequests": "List of withdrawal requests",
      "postbackStatus": "Postback status",
      "requestStatus": "Request status",
      "operationWithSettlementBalance": "Operation with settlement balance",
      "commission": "Commission",
      "operationType": "Operation type",
      "transactionActivation": "Transaction activation",
      "transactionArchiving": "Transaction archiving",
      "transferBetweenAccounts": "Transfer between accounts",
      "transferBetweenAccountAndSettlement": "Transfer between account and settlement",
      "direction": "Direction",
      "replenishment": "Replenishment",
      "writeOff": "Write-off",
      "reportFormat": "Report format",
      "postbackDate": "Postback date",
      "stockpilingId": "Stockpiling ID",
      "selectBy": "Select by",
      "transactionListWithCompletedStockpiling": "Transaction list with completed stockpiling",
      "stockpilingEndDate": "Stockpiling end date",
      "stockpilingEndPeriod": "Stockpiling end period",
      "postbackSent": "Sent",
      "postbackError": "Error sending postback",
      "transactionSumWithCompletedStockpiling": "Transaction sum with completed stockpiling",
      "operationWithBalances": "Operations with balances",
      "counterpartyWalletType": "Counterparty wallet type",
      "externalAccount": "Ex. account",
      "accountBalance": "Account balance",
      "dateCompleted": "Completion date",
      "statusNew": "New",
      "statusInProgress": "In progress",
      "statusCompleted": "Completed",
      "statusError": "Error",
      "reportSentToQueue": "The report has been sent to the queue for processing"
    },
    "testPS": {
      "psEditing": "Editing the payment system",
      "psCreating": "Creating a payment system ",
      "addPS": "Create payment system",
      "paymentSystems": "Payment systems",
      "psList": "Payment system list",
      "name": "Name",
      "description": "Description",
      "logo": "Logo",
      "screen": "Screenshot",
      "minDeposit": "Minimum deposit",
      "requisites": "Billing details",
      "machineName": "Machine name",
      "transactionType": "Transaction type",
      "isActive": "Enable on plugin",
      "requisite": "Payment details",
      "active": "Enabled",
      "notActive": "Disabled",
      "addAtLeastOneRequisite": "Add at least one of the required payment details",
      "testPS": "Test payment systems",
      "PSHasBeenSuccessfullyDisabled": "Payment system has been successfully disabled",
      "inOrderToActivatePSCreateWalletForIt": "In order to activate a payment system, you need to create a wallet for it.",
      "psDeleting": "Deleting a payment system ",
      "doYouReallyWantToDisableThisTestPS": "Are you sure you  want to delete this payment system?",
      "keyFieldDescription": "If payment can be identified via a unique number, use type \"with key\". If payment can be confirmed only via a screenshot, use type \"without key\".",
      "requisiteName": "Payment details title",
      "walletData": "Wallet data",
      "paymentSystemHint": "Payment system hint",
      "manualPS": "Manual payment systems",
      "codeName": "Code name",
      "manualPSList": "Manual payment system list"
    },
    "manualPS": {
      "psEditing": "Editing the payment system",
      "psCreating": "Creating a payment system ",
      "addPS": "Create payment system",
      "paymentSystems": "Payment systems",
      "psList": "Payment system list",
      "name": "Name",
      "description": "Description",
      "logo": "Logo",
      "screen": "Screenshot",
      "minDeposit": "Minimum deposit",
      "requisites": "Billing details",
      "machineName": "Machine name",
      "transactionType": "Transaction type",
      "isActive": "Enable on plugin",
      "requisite": "Payment details",
      "active": "Enabled",
      "notActive": "Disabled",
      "addAtLeastOneRequisite": "Add at least one of the required payment details",
      "testPS": "Test payment systems",
      "PSHasBeenSuccessfullyDisabled": "Payment system has been successfully disabled",
      "inOrderToActivatePSCreateWalletForIt": "In order to activate a payment system, you need to create a wallet for it.",
      "psDeleting": "Deleting a payment system ",
      "doYouReallyWantToDisableThisTestPS": "Are you sure you  want to delete this payment system?",
      "keyFieldDescription": "If payment can be identified via a unique number, use type \"with key\". If payment can be confirmed only via a screenshot, use type \"without key\".",
      "requisiteName": "Payment details title",
      "walletData": "Wallet data",
      "paymentSystemHint": "Payment system hint",
      "manualPS": "Manual payment systems",
      "codeName": "Code name",
      "manualPSList": "Manual payment system list",
      "codeNameFormat": "Name in English with the postfix _ml, small letters, without spaces. Example: upi_ib_ml"
    },
    "changeLog": {
      "changeLogs": "Change Log",
      "user": "User",
      "objectType": "Object type",
      "objectId": "Object ID",
      "actionType": "Action type",
      "oldValue": "Old value",
      "newValue": "New value"
    },
    "errors": {
      "requiredField": "Required field",
      "invalidEmail": "Invalid email format",
      "onlyLatinDigitsAndSymbols": "Only latin letters, numbers and symbols are allowed",
      "minFieldSize": "Minimum allowed characters: {{len}}",
      "maxFieldSize": "Maximum allowed characters: {{len}}",
      "maxFileSize": "File should be less than {{size}} {{postfix}}",
      "fileFormatNotAllowed": "Invalid file format. The file must be in the following formats: {{formats}}",
      "invalidDateRange": "Invalid date range. \"Date to\" should be greater than \"Date from\" ",
      "dateRangeIsRequired": "Date range should be filled",
      "invalidIpFormat": "Field should either be IP address (from 0.0.0.0 to 255.255.255.255) or host's address",
      "invalidPortFormat": "Port shoud be a number and its value should not exceed 65535",
      "invalidAmount": "Invalid amount format",
      "cannotActivatePaymentBecauseOfMinDeposit": "To activate following payment systems, set non-zero minimum deposit: {{payments}}",
      "minAmount": "Minimum allowed value: {{amount}}",
      "maxAmount": "Maximum allowed value: {{amount}}",
      "minAmountShouldBeGreater": "Amount should be greater than {{amount}}",
      "setValueGreaterThan0ForThisPayment": "This payment system is active. You can't set minimum deposit to 0",
      "setValueRange": "Amount should be in range from 0 to 9999999999.9999999999",
      "invalidCardFormat": "Invalid card number format. Card number length must be 16 or 18",
      "invalidPhoneFormat": "Phone number must be {{len}} digits and start with \"{{prefix}}\"",
      "invalidDate": "Invalid date",
      "passwordMustNotContainSpaces": "Password must not contain spaces",
      "invalidUrl": "Invalid URL format",
      "maxDepositMustBeGreaterThanMin": "Max deposit must be greater than min deposit",
      "mustBeDifferentValues": "Disabling and enabling time must be different",
      "fileNameDontMatchPattern": "The name of the file(s) do not match the required pattern {{pattern}}",
      "maxWithdrawalMustBeGreaterThanMin": "Max withdrawal must be greater than min deposit",
      "mustBeMultiple": "The value must be a multiple of 10",
      "phoneNumberLength": "The value without country code must be 10 digits long",
      "startWithSA": "The value cannot start with \"sa_\"",
      "fillAtLeastOnePeriod": "Fill at least one period",
      "invalidIfscCode": "Receiver IFSC does not match the specified format",
      "filesAlreadyInTable": "This files are already in table:",
      "bothDateRequired": "Both dates are required",
      "commissionPercentageIsNotSet": "Commission percentage of the transaction amount is not set",
      "onlyOnePeriodMustBeDefined": "Only one of the periods can be specified at a time",
      "invalidCookieString": "Input format: {\"date\":\"YYYY-MM-DD HH:MM:SS\", \"type\":\"work/middle\", \"payload\": {json string}}",
      "isRequiredAtSymbol": "Symbol @ is required",
      "invalidEnglishName": "Invalid name of payment system",
      "invalidEnglishCode": "Invalid code name of payment system",
      "providerError": "You need to put @ in front of the nickname",
      "invalidFormat": "Invalid format",
      "upiPaymentDisplayError": "Link-only payment is not available. Select other payment method.",
      "upiPaymentSamePaymentError": "Values are duplicated. Select different payment methods.",
      "manualPSFormatError": "Allowed characters: a-z, 0-9, _. The \"_ml\" postfix is required. Example: upi_ib_ml",
      "tryAgainLater": "An error occurred, please try again later"
    },
    "settings": {
      "passwordChanging": "Password changing",
      "passwordChangingDescription": "Changing a password for your account is an easy way to secure it.",
      "oldPassword": "Old password",
      "newPassword": "New password",
      "pluginInstallation": "Plugin installation",
      "pluginInstallationDescription": "This is a code for your widget. Paste it into the code of those pages on which you want to display it. You should fill parameters",
      "pluginSettings": "Plugin settings",
      "light": "Light",
      "dark": "Dark",
      "whiteGreen": "White and green",
      "minDepositSettings": "Minimum desposit settings",
      "mediaSettings": "Upload supporting media",
      "dashboardCurrency": "Dashboard currency",
      "theme": "Theme",
      "project": "Project",
      "postbackUrl": "Postback URL of the deposit",
      "postbackAccessKey": "Postback access key",
      "privatePostbackAccessKey": "Private postback access key",
      "companyName": "Company name",
      "paymentUrlOnSuccess": "Redirect URL on successful payment",
      "paymentUrlOnError": "Redirect URL on failure payment",
      "mediaUploading": "Upload supporting media",
      "minDeposit": "Minimum deposit",
      "settings": "Settings",
      "passwordHasBeenSuccessfullyUpdated": "Password has been successfully updated",
      "passwordUpdatingHasBeenFailed": "Password updating has been failed",
      "mediaFilesHaveBeenSuccessfullyUploaded": "Media files have been successfully uploaded",
      "useConstructorInPlugin": "Use the constructor",
      "profileSettings": "Profile settings",
      "language": "Language",
      "ru": "Russian",
      "en": "English",
      "setMinDeposit": "Set minimum deposit",
      "displayFirstWindow": "Display first window",
      "timezone": "Timezone",
      "custom": "Custom",
      "primaryButtonColor": "Primary button color",
      "secondaryButtonColor": "Secondary button color",
      "textColor": "Text color",
      "backgroundColor": "Background color",
      "inputBackgroundColor": "Input background color",
      "inputTextColor": "Input text color",
      "inputBorderColor": "Input border color",
      "displayOnPlugin": "Display on plugin",
      "customTheme": "Custom",
      "file": "File",
      "download": "Download",
      "stylesHaveBeenUpdated": "Styles have been successfully updated",
      "stylesUpdatingHasBeenFailed": "Styles updating has been failed",
      "paykassmaSettings": "Paykassma settings",
      "required": "Required",
      "optional": "Optional",
      "optionalWithDefault": "Optional (default: {{param}})",
      "withdrawalPostbackSuccess": "Withdrawal Postback Success",
      "withdrawalPostbackFailure": "Withdrawal Postback Failure",
      "manualWithdrawalPostback": "Postback URL for manual output",
      "deleteWithdrawalsAfterDays": "Number of downtime days to delete withdrawals",
      "standardStatusSettings": "User status settings",
      "generalAmountForGettingStandardStatus": "General amount for getting \"Standard\" status (USD)",
      "paymentSystems": "Payment systems",
      "vip": "VIP",
      "availableForInvoice": "Available for invoice",
      "standard": "Standard",
      "standardPaymentSystem": "Standard payment system",
      "limitForGettingStatusStandard": "Limit for getting \"standard\" status (USD)",
      "parserSettings": "Parser settings",
      "severalValue": "Can be used with several values separated by commas",
      "succeededUrl": "Redirect URL on successful payment",
      "failedUrl": "Redirect URL on failure payment",
      "id": "ID",
      "url": "Url",
      "timezoneHint": "The time zone defines the time stamp of transactions in the system. It is recommended to select the time zone of the country in which transactions are made",
      "manualWithdrawalPostbackHint": "URL to which the postback will be sent about the withdrawal of funds from the user's account",
      "deleteWithdrawalsAfterDaysHint": "The number of days after which the unprocessed withdrawal will be deleted from the System",
      "postbackUrlHint": "URL to which a postback will be sent about crediting funds to the user's account",
      "postbackAccessKeyHint": "Public access key used to encrypt postback data",
      "privatePostbackAccessKeyHint": "Secret access key used to decrypt postback data",
      "reactivationTimer": "Waiting time between attempts to activate a transaction (in seconds)",
      "columnUpdated": "Table column view has been successfully updated",
      "columnUpdatingFailed": "Table column view updating has been failed",
      "nativeRU": "Русский",
      "nativeEN": "English",
      "postback": "Postback",
      "postbackWithdrawal": "Postback withdrawal",
      "deposit": "Deposit",
      "profile": "Profile",
      "systemSettings": "System settings",
      "pluginAppearance": "Plugin appearance",
      "timeProfile": "Time profile",
      "deletePossibleTransactionAfterDays": "Number of downtime days to delete a possible transaction",
      "deletePossibleTransactionAfterDaysDescription": "Number of days after which an unprocessed possible transaction will be deleted from the System.",
      "paymentSystemsDisplayOrder": "Payment systems display order",
      "displayOrder": "Display order",
      "resetOrder": "Reset order",
      "addingDisplayOrder": "Adding display order",
      "selectCurrencyToDisplayPS": "Select a currency to display a list of payment systems",
      "resetDisplayOrder": "Reset display order",
      "sureResetDisplayOrder": "Are you sure you want to reset the display order of payment systems?",
      "editingDisplayOrder": "Editing display order",
      "orderHasBeenSuccessfullyUpdated": "Display order updated successfully",
      "fileMissing": "File is missing",
      "customAndDefaultOrderIsTheSame": "The custom display order is the same as the default order. Are you sure you want to use the default order?",
      "savingDisplayOrder": "Saving display order",
      "updatePassword": "Update password",
      "versionFetchingHasBeenFailed": "Version fetching has been failed.",
      "reactivationTimerHint": "The time that must elapse before trying to activate the transaction again.",
      "recheckTimer": "Period of re-checking the existence of a transaction (in seconds)",
      "recheckTimerHint": "Period of re-checking the existence of a transaction during the waiting time between transaction attempts of a transaction",
      "files": "Files",
      "notification": "Notification",
      "numberOfDaysToKeepNotifications": "Number of days to keep notifications in the \"Notifications\" window",
      "numberOfDaysToKeepNotificationsHint": "Number of days after which the notification will not be displayed in the \"Notifications\" window",
      "useRedirectURLFromSettingsParameterAvailability": "The parameter is available if the \"Use redirect URL from settings\" switcher is disabled in the settings",
      "useRedirectURLFromSettings": "Use redirect URL from settings",
      "smsInputMethodForPayTM": "SMS input method for PayTM",
      "manualInput": "Manual input",
      "automaticInput": "Automatic input",
      "depositPostback": "Postback deposit",
      "useUpiQrCode": "Display QR code on plugin for UPI",
      "checkingForTransactions": "Checking for transactions",
      "noTransactionsPeriod": "No transactions period ",
      "disableWalletVisibility": "Disable wallet visibility",
      "numberOfWalletImpressions": "Number of wallet impressions",
      "wrongTransactionAmount": "The period must be more than 5 and less than 600",
      "wrongImpressionsAmount": "Number of wallet impressions must be more than 5 ",
      "noTransactionsPeriodDescription": "No transactions period  at which verification is considered failed  (minutes)",
      "link": "Link",
      "qr": "QR",
      "upi_id": "UPI ID",
      "upiP2P": "UPI P2P",
      "upiAPI": "UPI API",
      "paymentMethod": "Payment method",
      "paymentMethodsDisplay": "Displaying payment methods on the plugin in UPI wallet types",
      "confidentialSettings": "Confidential settings",
      "plugin": "Plugin",
      "reportAProblem": "Display of the \"Report a problem\" feature",
      "withdrawalRequestsTitle": "Withdrawal requests",
      "withdrawalRequestsDescription": "Restrict withdrawal requests of insufficient balance",
      "pendingUrlTitles": "Redirect URL on pending payment",
      "pendingUrlDescription": "The parameter is available if the \"Use redirect URL from settings\" switcher is disabled in the settings",
      "isCheckAmount": "Checking the amount upon activation"
    },
    "stockpilings": {
      "stockpilings": "Stockpilings",
      "stockpilingList": "Stockpiling list",
      "stockpilingsAmount": "Stockpiling amount",
      "createdAt": "Formation date",
      "sendPostback": "Send postback",
      "actionConfirmation": "Confirmation of action",
      "sureWantSendPostback": "Are you sure you want to send a postback?",
      "real": "Real",
      "debug": "Debug",
      "type": "Stockpiling type"
    },
    "postbacks": {
      "resend": "Resend",
      "sureToResendPostback": "Are you sure you want to resend the postback?",
      "postbackHasBeenSent": "Postback sent",
      "postbackList": "Postback list",
      "sentPostbacks": "Sent postbacks",
      "url": "Url",
      "statusCode": "Status code",
      "response": "Response",
      "requestBody": "Request body",
      "postbackListIsEmpty": "Postback list is empty",
      "postback": "Postback",
      "postbacks": "Postbacks",
      "sentPostbackList": "Sent postback list",
      "notAcceptedPostbacks": "Not accepted postbacks",
      "notAcceptedPostbackList": "Not accepted postback list",
      "resendPostback": "Resend postback",
      "postbackHasBeenResent": "Postback has been successfully resent",
      "postbackResendingHasBeenFailed": "Postback resending has been failed",
      "postbackStatus": "Postback status",
      "sent": "Sent",
      "stockpilingInProgress": "Stockpiling in progress",
      "stockpilingNotFound": "Stockpiling not found",
      "notSend": "Is not sent",
      "received": "Received",
      "error": "Error",
      "errorSendingPostback": "Error sending postback",
      "countFailure": "Number of attemps to load",
      "clearCountFailure": "Reset attempt counter",
      "markAsSent": "Mark as sent",
      "areYouSureYouWantToResetAttemptCounter": "Are you sure you want to reset the postback attempt counter?",
      "markPostbackAsSent": "Mark postback as sent",
      "areYouSureYouWantToMarkPostbackAsSent": "Are you sure you want to mark the postback as successfully sent?"
    },
    "transactions": {
      "outgoing": "Outgoing",
      "ingoing": "Ingoing",
      "direction": "Direction",
      "transactionId": "Transaction number",
      "exchanger": "Сounterparty",
      "fake": "Debug",
      "real": "Confirmed",
      "forced": "Forced",
      "selectTheWallet": "Select the wallet",
      "transactionGeneration": "Generating a transaction ",
      "downloadData": "Download data",
      "transactionList": "Transaction list",
      "sumOfTransactionsDetailedByDay": "Sum of transactions with details by day",
      "walletTransactions": "Wallet transactions",
      "generateTransaction": "Generate the transaction",
      "transactions": "Transactions",
      "possibleTransactions": "Possible transactions",
      "preliminaryTransactions": "Preliminary transactions",
      "kindOfTransaction": "Transaction type",
      "playerId": "Label",
      "youHaveNotPermissionsToCreateForcedTransaction": "You haven't permissions to create forced transaction",
      "youHaveNotPermissionsToCreateFakeTransaction": "You haven't permissions to create debug transaction",
      "generate": "Generate",
      "transactionAmount": "Transaction amount",
      "transactionNumber": "Transaction number",
      "activated": "Activated",
      "notActivated": "Not activated",
      "rejected": "Rejected",
      "pending": "Pending",
      "inProgress": "Processing",
      "crashed": "Crashed",
      "canceled": "Canceled",
      "whereFrom": "From",
      "whereTo": "To",
      "transactionsById": "Transactions by ID",
      "transaction": "Transaction",
      "new": "New",
      "processed": "Processed",
      "screenDownloadingHasBeenFailed": "A screenshot was not provided for this transaction",
      "screen": "Screen",
      "preliminaryTransactionCreation": "Creating preliminary transaction ",
      "preliminaryTransactionDeleting": "Deleting preliminary transaction ",
      "createTransaction": "Create transaction",
      "preliminaryTransactionHasBeenSuccessfullyActivated": "Preliminary transaction has been successfully activated",
      "fileDownloadingHasBeenFailed": "File downloading has been failed",
      "preliminaryTransactionsById": "Preliminary transactions by ID",
      "withKey": "With key",
      "withoutKey": "Without key",
      "possibleTransactionList": "Possible transaction list",
      "error": "Error",
      "filterActivated": "Activated",
      "filterNotActivated": "Not activated",
      "filterRejected": "Rejected",
      "filterPending": "Pending",
      "filterInProgress": "Processing",
      "filterCrashed": "Crashed",
      "filterCanceled": "Canceled",
      "filterError": "Error",
      "undefined": "Undefined",
      "preliminaryTransactionsList": "Preliminary transactions list",
      "preliminaryTransactionNumber": "Preliminary transaction number",
      "preliminaryTransactionId": "Preliminary transaction ID",
      "createPreliminaryTransaction": "Create preliminary transaction",
      "payments": "Payments",
      "postbackStatus": "Postback status",
      "transactionListIsEmpty": "Transaction list is empty",
      "inner": "Internal",
      "activate": "Activate",
      "transactionActivation": "Transaction activation",
      "empty": "Empty",
      "withLabel": "With label",
      "labelAvailability": "Label availability",
      "label": "Label",
      "orderNumber": "Order number",
      "transactionHasBeenSuccessfullyUpdated": "Transaction has been successfully updated",
      "labelHistoryReport": "History of displaying account data to a label",
      "fakeGeneration": "Generating debug transaction ",
      "realGeneration": "Generating confirmed transaction ",
      "forcedGeneration": "Generating forced transaction ",
      "walletNumber": "Wallet number",
      "transactionType": "Transaction kind",
      "auto": "Auto",
      "manual": "Manual",
      "commission": "Commission",
      "realHint": "Select this type of transaction if the money has been credited to the account, but the transaction is not in the system for some reason.",
      "forcedHint": "Select this type of transaction if the money has not been credited to the account, but you have to create a transaction in the system.",
      "fakeHint": "Select this type of transaction if you need to do testing or demo.",
      "duplicatedTransactionText1": "Transaction has been found in possible transaction list:",
      "transactionNumberWithValue": "Transaction number: {{id}}",
      "amountWithValue": "Amount: {{amount}}",
      "labelWithValue": "Label: {{label}}",
      "duplicatedTransactionText2": "If you save, the eventual transaction will be deleted. Are you sure you want to create a transaction?",
      "archiveTransactions": "Archived transactions",
      "archivateTransaction": "Transaction archivation",
      "addToArchive": "Are you sure you want to archive the transaction?",
      "addTransactionToArchive": "Add transaction to archive",
      "transactionHasBeenAddedToArchive": "The transaction has been added to the archive!",
      "archiveTransactionsList": "Archived transactions list",
      "notSpecified": "Not specified",
      "orderId": "Order ID",
      "bulkTransactionLoading": "Bulk transaction loading",
      "iciciTransactionFile": "ICICI transaction file",
      "iciciTransactionFileHint": "Select this menu item to upload the file downloaded from the personal account of the ICICI bank",
      "loadIciciTransactionFile": "Load ICICI transaction file",
      "fileInProcessed": "The file is uploaded and is being processed",
      "psvFile": "PSV-file",
      "archivedTransaction": "Archived transaction",
      "internal": "Outgoing",
      "status": "Transaction status",
      "clientAccountBalanceChange": "Client account balance change",
      "transfer": "Transfer",
      "replenishment": "Replenishment",
      "withdrawal": "Withdrawal",
      "statusSuspect": "Suspect",
      "approveButton": "Approve",
      "approveMessageSuccess": "Approve success",
      "typeOrAccount": "Type/Ex. account",
      "possibleTransactionApproval": "Possible transaction approval",
      "archivingStatus": "Transaction archiving status",
      "archivedOnly": "Archived only",
      "notArchivedOnly": "Not archived only",
      "outsideLimit": "The amount exceeds the maximum deposit for the PS",
      "noTransactions": "No transactions",
      "originality": "Originality",
      "original": "Normal",
      "scam": "Scam",
      "fakeBtn": "Fake",
      "findedPossible": "Found possible transaction number",
      "choosePossible": "Select an item from the list and click \"Next\"",
      "dontUseLastData": "Do not use data from the possible transaction",
      "approveTransactionTitle": "Approve transaction",
      "approveTransactionText": "Are you sure you want to approve transaction with number {{id}}?"
    },
    "dashboard": {
      "sumOfActivatedTransactions": "Sum of activated transactions",
      "quantityOfActivatedTransactions": "Number of activated transactions",
      "workingWallets": "working wallets",
      "totalBalance": "Total balance",
      "workingProxies": "working proxies",
      "withdrawalsAmountForToday": "Sum of withdrawals for today",
      "dashboardDataFetchingHasBeenFailed": "Dashboard data fetching has been failed",
      "filters": "Filters",
      "totalSum": "Total sum",
      "accept": "Submit",
      "paymentSystems": "Payment systems",
      "testPS": "Test payment systems",
      "details": "Show details",
      "sumOfActivatedTransactionsForToday": "Sum of activated transaction for today",
      "quantityOfActivatedTransactionsForToday": "Number of activated transactions for today",
      "dashboard": "Dashboard",
      "periodFromTo": "Period: from {{from}} to {{to}}",
      "sum": "Sum",
      "count": "Count",
      "manualPS": "Manual payment systems",
      "ingoingTransactions": "Ingoing transactions",
      "outgoingTransactions": "Outgoing transactions",
      "sumOfCommission": "Sum of commission",
      "forToday": "Today",
      "withCompletedStockpiling": "With completed stockpiling",
      "withCompletedStockpilingTip": "If enabled:\n\n1. Only activated transactions with completed stockpiling will be displayed on the chart\n2. Information blocks will display information on transactions with completed stockpiling\nIf disabled the chart and information blocks will display information on the activated transactions regardless of the stockpiling status."
    },
    "logs": {
      "errorType": "Error type",
      "message": "Message",
      "logList": "Log list",
      "walletLogs": "Wallet logs",
      "walletsLogs": "Wallets' logs",
      "logs": "Logs",
      "messageIsEmpty": "Message is empty",
      "file": "File",
      "paytmCookies": "Paytm cookies",
      "walletsDebugging": "Wallets' debugging",
      "filesUploading": "Files' uploading",
      "uploadFiles": "Upload files",
      "uploadFile": "Upload file",
      "files": "Files",
      "filesHaveBeenSuccessfullyUploaded": "Files have been successfully uploaded",
      "downloadAll": "Download all",
      "withdrawalsLogs": "Withdrawals' logs",
      "category": "Category",
      "walletType": "Wallet type",
      "сategory": "Сategory"
    },
    "wallets": {
      "wallets": "Wallets",
      "walletRecoveryDate": "Wallet recovery date",
      "sureToRecoverWallet": "Are you sure you want to recovery your wallet? If the wallet is P2P, indicate the start date of transaction parsing.",
      "walletCreationDate": "Wallet creation date",
      "parsingStartTime": "Parsing start time",
      "ParsingStartDate": "Parsing start date",
      "onlyOneUPIAddress": "Only one UPI address is allowed!",
      "existingPagSmileWallet": "There is already one PagSmile Wallet in the system. Remove the existing wallet PagSmile for creating the new one!",
      "addWallet": "Add wallet",
      "identifier": "Identifier",
      "email": "Email",
      "balance": "Accounts balance",
      "walletUpdating": "Wallet updating",
      "walletDisabling": "Wallet disabling",
      "doYouReallyWantToDisableThisWallet": "Are you sure you want to disable this wallet",
      "walletList": "Wallet list",
      "myWallets": "My wallets",
      "disabledWallets": "Disabled wallets",
      "notWorkingProxy": "Proxy isn't working",
      "couldNotAuthToWalletEmail": "Couldn't authenticate in wallet's email",
      "couldNotAuthToWalletApp": "Couldn't authenticate in wallet's app",
      "parsingDataError": "Error transaction parsing",
      "unknownError": "Unknown error",
      "accountKey": "Account key",
      "enUsername": "Username",
      "password": "Password",
      "enCode": "Code",
      "lastUpdate": "Last update",
      "proxyDoesNotWork": "Proxy does not work",
      "enProxyIP": "Proxy",
      "description": "Description",
      "accountIdentifier": "Account identifier (email)",
      "enApiKey": "Api Key",
      "comment": "Comment",
      "transactionList": "Transaction list",
      "markWalletAsWorking": "Activate wallet",
      "resetData": "Reset cookies",
      "walletPassword": "Wallet password",
      "emailOfEmail": "Email",
      "emailPassword": "Email password",
      "youHaveToUpdateCookies": "Update cookies ",
      "walletCreating": "Wallet creating",
      "wallet": "Wallet",
      "testPayment": "Test payment",
      "active": "Active",
      "disabled": "Disabled",
      "doYouReallyWantToResetThisWalletSettings": "Are you sure you want to discard sms data for this wallet?",
      "dataResetting": "Resetting data ",
      "enterCode": "Enter code",
      "phoneConfirmation": "Phone confirmation",
      "walletIsWorkingNow": "Wallet is working",
      "commentHasBeenSuccessfullyUpdated": "Comment has been successfully updated.",
      "smsHasBeenSuccessfullySent": "Sms has been successfully sent.",
      "smsSendingHasBeenFailed": "Sms sending has been failed. Try again later.",
      "walletDataHasBeenSuccessfullyReset": "Wallet data has been successfully reset.",
      "walletResettingHasBeenFailed": "Wallet resetting has been failed. Try again later.",
      "walletDeleting": "Deleting wallet ",
      "walletType": "Wallet type",
      "selectTheWallet": "Select the wallet",
      "selectAnotherWallet": "Select another wallet",
      "walletIdentifier": "Wallet identifier",
      "walletsLoad": "Wallets' load",
      "selectTypeAndLoadsDirection": "Choose type and direction of the load",
      "loadPreviewSetting": "Load view setting",
      "loadSettings": "Load setting",
      "changeLoadSettings": "Change load setting",
      "amountOfIngoingTransactionsInUSD": "Amount of ingoing transactions in USD",
      "amountOfOutgoingTransactionsInUSD": "Amount of outgoing transactions in USD",
      "amountOfIngoingTransactions": "Amount of ingoing transactions",
      "amountOfOutgoingTransactions": "Amount of outgoing transactions",
      "quantityOfIngoingTransactions": "Number of ingoing transactions",
      "quantityOfOutgoingTransactions": "Number of outgoing transactions",
      "outgoing": "Outgoing",
      "ingoing": "Ingoing",
      "direction": "Direction",
      "loadType": "Load type",
      "walletStatus": "Wallet status",
      "stoppedWallets": "Disabled wallets",
      "activeWallets": "Working wallets",
      "deletedWallets": "Deleted wallets",
      "load": "Load",
      "walletHasBeenSuccessfullyDisabled": "Wallet has been successfully disabled",
      "enterSms": "Enter sms",
      "recoverWallet": "Restore cookies",
      "walletHasBeenSuccessfullyRecovered": "Cookies have been successfully restored",
      "outdatedCookies": "Outdated cookies",
      "loadObject": "Load object",
      "updateComment": "Comment",
      "lastIngoing": "Last money receiving",
      "lastOutgoing": "Last money withdrawal",
      "lastUpdateInfo": "Last update info",
      "lastParsing": "Last parsing",
      "paymentSystems": "Payment systems",
      "addAtLeastOneCard": "Add at least one of the required card",
      "operationType": "Deposit method",
      "country": "Country",
      "username": "Username",
      "vipWallet": "VIP-wallet",
      "pluginStatus": "Visibility",
      "pluginStatusHasBeenSuccessfullyUpdated": "Plugin wallet visibility has been successfully updated",
      "vip": "VIP",
      "standardWallet": "Standard wallet",
      "initialWallet": "Initial wallet",
      "standard": "Standard",
      "initial": "Initial",
      "addAtLeastOneProxy": "Add proxy",
      "walletSettingsHaveBeenSuccessfullyUpdated": "Wallet settings have been successfully updated",
      "addAtLeastOneBankcode": "Enter bank code",
      "token": "Token",
      "depositCards": "Cards for deposit",
      "withdrawalCards": "Cards for withdrawal",
      "emailUsername": "Email",
      "phone": "Phone number",
      "datePlaceholder": "MM/YY",
      "accountDataError": "Account data error",
      "probablyBanned": "Probably banned",
      "linkForPayment": "Link for payment",
      "receiverEmail": "Receiver email",
      "merchantID": "Merchant ID",
      "merchantPassword": "Merchant password",
      "iciciLogin": "icici login",
      "iciciPassword": "icici password",
      "accountName": "Account name",
      "walletOffLimit": "Wallet off limit",
      "transactionSearch": "Transaction search",
      "transactionDateSelection": "Transaction date selection",
      "search": "Search",
      "transactionDate": "Transaction date",
      "parsingHasBeenLaunched": "Parsing has been launched",
      "parsingInProgress": "Parsing in progress...",
      "transactionsFound": "Parsing in progress. Transactions found: {{scraped}}",
      "parsingInQueued": "Parsing in queued",
      "lastTimeWasFound": "Transaction search. Last time was found: {{scraped}}",
      "number": "Number",
      "account": "Account",
      "applicationId": "Application ID",
      "parsingOfAnotherWalletInProgress": "Parsing of another wallet in progress...",
      "yaarpayMerchantId": "Merchant ID",
      "yaarpayApplicationId": "Application ID",
      "depositName": "Deposit Name",
      "yaarpaySecretKey": "Merchant secret key",
      "yaarpayBankCode": "Bank Code",
      "yaarpayIfscCode": "IFSC Code",
      "yaarpayBranchCode": "Branch code",
      "yaarpayAccountNo": "Account No",
      "yaarpayAccountName": "Account Name",
      "payOpPublicKey": "Public key",
      "payOpSecretKey": "Secret key",
      "payOpProjectId": "Project ID",
      "payOpToken": "Token",
      "payKassaMerchantId": "Merchant ID",
      "payKassaSecretKey": "Merchant password",
      "payKassaApiId": "Api ID",
      "payKassaApiSecretKey": "API Password",
      "vietcomBankAccountNumber": "Account number",
      "vietcomBankAccountHolder": "Account holder name",
      "vietcomBankBankName": "Bank name",
      "cashmaalPin": "Pin",
      "cashmaalIpnKey": "IPN key",
      "cashmaalPayoutSecretKey": "Payout Secret KEY",
      "surePayBankCode": "Bank code/Bankcode",
      "surePayDepositIban": "Deposit iban",
      "surePayAccountNumber": "Account number",
      "surePayMerchant": "Merchant ID",
      "surePayApiKey": "Api Key",
      "dusuPayApiKey": "Api key",
      "dusuPayToken": "Token",
      "dusuPaySecretKey": "Secret key",
      "payTecaClientId": "Client ID",
      "payTecaClientSecret": "Client secret",
      "pagsmileSecretKey": "Secret Key",
      "visibilityOfAllWallets": "Visibility of all wallets",
      "enableVisibilityOfAllWallets": "Enable",
      "disableVisibilityOfAllWallets": "Disable",
      "visibilityOfWalletsHaveBeenEnabled": "Visibility of wallets have been enabled",
      "visibilityOfWalletsHaveBeenDisabled": "Visibility of wallets have been disabled",
      "maybankAccountNumber": "Account number",
      "maybankUsername": "Site login",
      "maybankPhrase": "Phrase",
      "accountNumber": "Account number",
      "accountHolder": "Account holder",
      "secretKey": "Secret key",
      "accounts": "Accounts",
      "accountsBalance": "Accounts Balance",
      "accountNo": "Account No",
      "beneficiaryNickname": "Beneficiary nickname",
      "deleteBeneficiariesInIciciAccount": "Deleting beneficiaries in ICICI account",
      "beforeTheTimeExpires": "before the time expires",
      "untilTheLastBeneficiary": "until the last beneficiary",
      "manualPayment": "Manual payment",
      "settlement": "Settlement",
      "settlementReplenishmentAmountHint": "The amount that will be write off from the account balance",
      "settlementReplenishmentRateHint": "Exchange rate 1 c.u.",
      "settlementWriteOffAmountHint": "The amount that will be deducted from the balance of the settlement",
      "settlementWriteOffRateHint": "Cryptocurrency exchange rate",
      "operationsHistory": "Operations history",
      "cu": "c.u.",
      "availableBalance": "Available balance",
      "replenishment": "Replenish",
      "writeOff": "Withdraw",
      "dateTime": "Date and time",
      "operation": "Operation",
      "amountInTransactionCurrency": "Amount in transaction currency",
      "exchangeRate": "Exchange rate",
      "replenishmentFrom": "Replenishment from",
      "writeOffOn": "Withdrawal to the client's external account",
      "bitcoinAddress": "Bitcoin-address",
      "paymentSystem": "Payment system",
      "authError": "Auth Error",
      "localpayAccountName": "Account Name",
      "localpayAccountNumber": "Account Number",
      "localBankCode": "Bank Code",
      "commission": "Commission",
      "addAtLeastOneParser": "At least one parser must be enabled",
      "emailSender": "Email sender",
      "iciciParser": "ICICI Bank site parser",
      "axisParser": "AXIS Bank site parser",
      "nightParser": "Nightly email parser",
      "parser": "Parser",
      "indusIndParser": "Site parser IndusInd",
      "freeChargeParser": "Site parser Freecharge",
      "emailParser": "Email parser",
      "siteLogin": "Site login",
      "sitePassword": "Site password",
      "iciciWalletsConflict1": "There are exist wallets that related to the ICICI account with the login {{login}}, with the following identifiers:",
      "iciciWalletsConflict2": "Proxy parameters in the specified wallets don't match the current wallet and will be changed.",
      "iciciWalletsConflict3": "Are you sure you want to save the wallet?",
      "idbWalletsConflict": "There are exist wallets that related to the IDB account with the login {{login}}, with the following identifiers:",
      "walletsProxyConflict": "Proxy parameters in the specified wallets don't match the current wallet and will be changed.",
      "walletsConflictSaveMessage": "Are you sure you want to save the wallet?",
      "loginId": "Login ID",
      "workingTime": "Working time",
      "payzenApiKey": "Api key",
      "payzenPublicKey": "Public key",
      "paytrustApiKey": "Api key",
      "ePayApiKey": "Api key",
      "perfectMoneyId": "ID",
      "cashmaalWebID": "Web ID",
      "IMPSAccountName": "Account holder name",
      "IMPSAccountNumber": "Account number",
      "IMPSIFSCcode": "IFSC code",
      "help2payMerchantCode": "Merchant Code",
      "help2paySecurityCode": "Security Code",
      "useProxy": "Use proxy",
      "codePhrase": "Code phrase",
      "question": "Question",
      "answer": "Answer",
      "addCodePhrases": "Add 3 code phrases",
      "address": "Address",
      "accountHolderNickname": "Account holder nickname",
      "accountHolderName": "Account holder name",
      "localCryptoAccount": "Account",
      "pendingSmsConfirmation": "Pending SMS confirmation",
      "emailAccount": "Account",
      "transactionStatus": "Transaction status",
      "dateTransactionCreation": "Date of transaction creation",
      "dateTransactionActivation": "Date transaction activation",
      "integrationError": "Integration error",
      "banned": "Banned",
      "upiHbIdentifier": "Number",
      "upiHbUsername": "Site login",
      "upiHbPassword": "Site password",
      "upiHbAccountNumber": "Account number",
      "commissionWithSuffix": "Commission %",
      "addressUpi": "UPI Address",
      "addOneUpiAddress": "Add at least one UPI Address",
      "IMPSHBIFSCcode": "IFSC code",
      "impsHbIdentifier": "Number",
      "deleteData": "Delete inactive cookies",
      "deleteDataHint": "Deleting all inactive wallet cookies",
      "deletingInactiveCookies": "Deleting inactive cookies",
      "doYouReallyWantToRemoveThisWalletCookies": "Cookies will be permanently deleted. Are you sure you want to delete cookies for this wallet?",
      "successfullyDeletedCookies": "Successfully deleted {{count}} cookies",
      "noCookiesToDelete": "There are no cookies to delete",
      "failedToDeleteCookies": "Failed to delete cookies",
      "automaticSmsInput": "Automatic SMS input",
      "port": "Port",
      "apiKey": "API key",
      "dailyLimitOnAmount": "Daily limit on the amount",
      "priority": "Wallet priority",
      "addInfo": "Add. information",
      "appParser": "App parser",
      "siteParser": "Site parser",
      "archivedWallets": "Archived wallets",
      "walletArchiving": "Archiving a wallet",
      "doYouReallyWantToDeleteThisWallet": "Are you sure you want to delete this wallet?\r\nThis action is irreversible, the wallet will be permanently deleted.",
      "doYouReallyWantToArchiveThisWallet": "Are you sure you want to archive your wallet?",
      "walletRecovery": "Wallet recovery",
      "doYouReallyWantToRecoverThisWallet": "Are you sure you want to recovery your wallet?",
      "wallet_type": "Wallet Type",
      "walletHasBeenSuccessfullyDeleted": "Wallet has been successfully deleted",
      "walletHasBeenSuccessfullyRemoved": "Wallet has been successfully removed",
      "walletHasBeenSuccessfullyRestored": "Wallet has been successfully restored",
      "notSpecified": "Not specified",
      "vendorCode": "Wallet provider code",
      "card": "Card",
      "proxiesHint": "Wallets of this type work only with a proxy",
      "laykapaySecretKey": "Merchant secret key",
      "cookie": "Cookie",
      "allowedBalanceExceeded": "Balance exceeded",
      "noCookies": "No cookies",
      "vpaAddress": "Virtual payment address",
      "merchantKey": "Merchant key",
      "hash": "Hash",
      "uzcardApiCodePhrase": "Phrase code",
      "isPersonalType": "Personal type",
      "merchantPrvateKey": "Merchant secret key",
      "nagadPublicKey": "Nagad public key",
      "accountReplenishment": "Replenish",
      "accountWriteOff": "Withdraw",
      "mpesaShortCode": "Short code",
      "mpesaConsumerKey": "Consumer Key",
      "mpesaConsumerSecret": "Consumer secret key",
      "accountType": "Account type",
      "agentAccount": "Agent ",
      "personalAccount": "Personal",
      "merchantAccount": "Merchant",
      "phraseCode": "Phrase code",
      "dataAccessError": "Data access error",
      "PKConnectionError": "Сonnection error with PK",
      "parserError": "Parser error",
      "dailyLimitExceeded": "Daily limit on the amount exceeded",
      "passKey": "Password",
      "mpesaPassKey": "PassKey",
      "provider": "Provider",
      "numberOfCard": "Card number",
      "hdfcParser": "Site parser HDFC",
      "indusinParser": "Site parser IndusInd",
      "mobileApiParser": "PhonePe Mobile API",
      "mnc": "MNC",
      "operatorName": "Network Operator Name",
      "androidId": "Android ID",
      "androidBoard": "Android board",
      "androidHardware": "Android hardware",
      "androidSerial": "Android Serial",
      "androidManufacturer": "Android Manufacturer",
      "androidVersion": "Android API Version",
      "wifiMac": "WiFi MAC address",
      "wifiSsid": "WiFi SSID",
      "wifiFrequency": "WiFi Frequency (MHz)",
      "latitude": "Latitude (degrees)",
      "longitude": "Longitude (degrees)",
      "parsingObject": "Parsing object",
      "webpage": "Webpage",
      "statement": "Statement",
      "smsSource": "Method of receiving SMS",
      "smsBox": "SMS box",
      "manual": "Manual input",
      "visibleWorkingAll": "Visible/Working/All",
      "balanceOfVisibleMax": "Balance of visible/max",
      "turnoverOfVisibleMax": "Turnover of visible/max ",
      "balanceOfAllMax": "Balance of all/max ",
      "turnoverOfAllMax": "Turnover of  all/max",
      "turnoverDailyTurnover": "Max daily turnover",
      "walletsStatisticModal": "Wallet turnover in 7 days",
      "date": "Date",
      "dailyTurnover": "Daily turnover",
      "copyToClipboard": "Copy all",
      "walletStatistic": "Wallet statistics",
      "walletStatisticTurnoverTip": "Maximum daily turnover of wallets for 7 days",
      "tooltipStatistic": "Maximum daily turnover of wallets for 7 days\r",
      "callbackSource": "Receiving callback",
      "directlyFromPS": "Directly from PS ",
      "viaServiceGateway": "Via service gateway",
      "callbackUrl": "Callback URL",
      "priorityRaw": "Priority",
      "highPriority": "High",
      "abovePriority": "Above normal",
      "normalPriority": "Normal",
      "belowPriority": "Below normal",
      "lowPriority": "Low",
      "parserApi": "Mobile API",
      "incorrectQR": "Incorrect QR",
      "dailyTransactionLimit": "Daily transaction limit",
      "dailyTransactionLimitExceeded": "Daily transaction limit exceeded",
      "archivingDate": "Archiving date",
      "storeID": "Store ID",
      "integrationType": "Integration type",
      "monthlyTurnoverLimitExceeded": "Monthly turnover limit exceeded",
      "dailyTurnoverLimitExceeded": "Daily turnover limit exceeded",
      "federalBankParser": "Federal bank site parser",
      "phoneNumber": "Phone number",
      "webhookID": "Webhook ID",
      "displayIdentifier": "Displayed payment details",
      "identifierRequisites": "Payment details"
    },
    "paymentConstructor": {
      "minimumAmount": "Minimum amount",
      "minStockpilingAmount": "Min stockpiling amount",
      "showOnPlugin": "Show on plugin",
      "copyLink": "Copy link",
      "currencies": "Currencies",
      "paymentSystems": "Payment systems",
      "currency": "Currency",
      "appliedPaymentSystems": "Applied payment systems",
      "sortingType": "Sort type",
      "paymentSystemsSorting": "Payment systems sorting",
      "defaultSorting": "Default sorting",
      "constructor": "Constructor",
      "paymentSystem": "Payment system",
      "paymentSystemsCurrencies": "Payment system's currencies",
      "custom": "Custom",
      "byDefault": "Default",
      "resetOrder": "Reset order",
      "orderPrioritet": "Showed first",
      "orderPrioritetTestPS": "Test PS",
      "orderPrioritetRegularPS": "Payment systems ",
      "testPSPrioritet": "Test PS",
      "testPSNewFirst": "Newer first",
      "testPSOldFirst": "Older first",
      "regularPSPrioritet": "Payment systems",
      "regularPSByDefault": "Default ",
      "regularPSPrioritetUniqueFirst": "Unique first",
      "regularPSPrioritetNotUniqueFirst": "Common first",
      "appliedCurrencies": "Applied currencies",
      "PSDataHasBeenUpdated": "Payment system data has been successfully updated",
      "sortingDataHasBeenUpdated": "Sorting data has been successfully updated",
      "currencyDataHasBeenUpdated": "Currency data has been successfully updated",
      "6in1": "6 in 1",
      "logos": "Logos",
      "logo": "Logo",
      "defaultLogo": "Default logo",
      "logosHaveBeenSuccessfullyUpdated": "Logos have been successfully updated",
      "resetAllSettings": "Reset all settings",
      "cancelLastChanges": "Cancel last changes",
      "sortingTemplate": "Sorting template",
      "sortingTemplateHasBeenSuccessfullyUpdated": "Sorting template has been successfully updated",
      "customOrder": "Sorting template",
      "customOrderDisabled": "Disabled",
      "customOrderEnabled": "Enabled",
      "3in1": "3 in 1",
      "minDeposits": "Min deposits",
      "extraMinDeposits": "Extra min deposits",
      "minDepositsHaveBeenSuccessfullyUpdated": "Min deposits have been successfully updated",
      "paymentCurrencies": "Payment currencies",
      "paymentConstructor": "Payment constructor",
      "targetingLogo": "Targeting logo",
      "targetingMinDeposit": "Targeting minimum deposit",
      "defaultMinDeposit": "Default minimum deposit",
      "paymentSystemTitle": "Title",
      "paymentSystemDefaultTitle": "Default title",
      "paymentTitlesHaveBeenSuccessfullyUpdated": "Payment titles have been successfully updated",
      "paymentSystemTitleForCurrencies": "Titles for currencies",
      "checkMinDeposit": "Check minimal deposit",
      "viewType": "View Type",
      "depositsHaveBeenSuccessfullyUpdated": "Deposits have been successfully updated",
      "deposits": "Deposits",
      "targeting": "Targeting",
      "minDeposit": "Min deposit",
      "maxDeposit": "Max deposit",
      "name": "Name",
      "paymentDataHasBeenSuccessfullyUpdated": "Payment data has been successfully updated",
      "display": "Display view in plugin",
      "paymentHints": "Hints",
      "paymentHintList": "Hint list",
      "hintType": "Hint type",
      "payment_hint": "Deposit confirmation",
      "requisites_hint": "Deposit making",
      "addHint": "Add hint",
      "translation": "Translation",
      "hintText": "Hint text",
      "lang": "Lang",
      "translations": "Translations",
      "addAtLeastOneTranslation": "Add at least one translation",
      "limitHint": "Limit hint",
      "hintCreating": "Create hint",
      "hintDeleting": "Delete hint",
      "doYouReallyWantToRemoveThisHint": "Are you sure you want to delete this hint?",
      "hintEditing": "Edit hint",
      "tariffs": "Tariffs",
      "fixedForReceiving": "Fixed for receiving",
      "percentForReceiving": "Percent for receiving",
      "fixedForSending": "Fixed for sending",
      "percentForSending": "Percent for sending",
      "tariffsNotSpecified": "Tariffs for this payment system are not specified",
      "manualPSPrioritet": "Manual PS",
      "2in1": "2 in 1",
      "hintView": "Hint view",
      "depositEnter": "Deposit making",
      "depositConfirm": "Deposit confirmation",
      "fillOneField": "You must fill in at least one field",
      "displayOnPlugin": "Display on plugin",
      "standardPaymentSystem": "Standard payment system",
      "limitForGettingStatusStandard": "Limit for getting \"standard\" status (USD)",
      "statusHasBeenUpdated": "Status has been updated",
      "displayedOnPlugin": "Displayed on plugin",
      "transactionLastDateOnPlugin": "Last activation date of the transaction on the plugin",
      "settings": "Settings",
      "workingTime": "Working time",
      "aroundTheClock": "24 hours a day",
      "timeRange": "Time range",
      "timezone": "Timezone",
      "timeEqual24HoursError": "Set on the \"24 hours a day\" checkbox or set the time not equal to 24 hours",
      "walletUsesSelenium": "Сurrent wallet use selenium automate - enable increase server load",
      "usePossibleTransactions": "Possible transactions",
      "usePossibleTransactionsDescription": "If there is an error when activating a transaction on the plugin a possible transaction will be created automatically.",
      "plugin": "Plugin",
      "currencyCode": "Сurrency code",
      "level": "Level",
      "customTransactionId": "Сustom transaction id",
      "fixedAmount": "Fixed amount",
      "paymentUrlSuccessId": "Payment url success id",
      "paymentUrlFailId": "Payment url fail id",
      "pluginError": "Plugin frame cannot be displayed due to missing env parameter REACT_APP_PAYMENT_URL",
      "pluginNoURL": "To display the plugin, enter parameters and click Apply",
      "limits": "Limits",
      "minWithdrawal": "Min withdrawal",
      "maxWithdrawal": "Max withdrawal",
      "paymentTranslations": "Translations",
      "addPaymentTranslations": "Add translations",
      "paymentMaking": "Payment making",
      "paymentConfirmation": "Payment confirmation",
      "fields": "Fields",
      "formElement": "Form element",
      "form": "Form",
      "hint": "Hint",
      "field": "Field",
      "createTranslation": "Create translation",
      "formType": "Form type",
      "general": "General",
      "paymentMakingForm": "Payment making form",
      "paymentConfirmationForm": "Payment confirmation form",
      "addTariff": "Add tariff",
      "paymentTranslationList": "Translation list",
      "translationView": "Translation view",
      "translationType": "Translation type",
      "translationEditing": "Edit translation",
      "translationDeleting": "Delete translation",
      "doYouReallyWantToRemoveThisTranslation": "Are you sure you want to delete this translation?",
      "tariffPlanSettings": "Tariff plan settings",
      "incomingTransactionCommission": "Incoming transaction commission",
      "outgoingTransactionCommission": "Outgoing transaction commission",
      "amountUpTo": "Amount to",
      "amountFrom": "Amount from",
      "percent": "Percent, %",
      "addCondition": "Add condition",
      "fixAmount": "Fix amount",
      "percentageTransactionAmount": "Percentage of the transaction amount",
      "commissionPercentageIsNotSet": "Commission percentage of the transaction amount is not set",
      "proxies": "Proxy",
      "useProxyNightEmailParser": "Use a proxy night email parser",
      "allowUpdateRequisites": "Allow requisite to be updated",
      "otpForm": "OTP input form",
      "phoneForm": "Phone number input form",
      "notifications": "Notifications",
      "addManualPS": "Add manual PS",
      "creatingAManualPS": "Creating a Manual PS",
      "nameInEnglish": "The name is in English. Example: UPI",
      "codenameInEnglish": "Name in English, small letters, without spaces.Example: upi_ib",
      "codename": "Code name",
      "requisites": "Requisites",
      "requisiteName": "Name of the requisite",
      "requestTransactionId": "Request transaction_id",
      "requestReceipt": "Request a payment receipt",
      "charNumValidation": "Validation for the number of characters",
      "animationHint": "Animation hint",
      "confirmationScreen": "Screenshot for payment confirmation",
      "requisiteNameInEnglish": "Name of requisite in English",
      "deletePaymentSystem": "Deleting a payment system",
      "areYouSureWantToDeleteManualPS": "Are you sure you want to delete this payment system?"
    },
    "rbac": {
      "name": "Name",
      "description": "Description",
      "rights": "Rights",
      "addRole": "Add role",
      "roleDeleting": "Role deleting",
      "roleEditing": "Role editing",
      "roleCreating": "Role creating",
      "rbac": "Administrating",
      "roleList": "Role list",
      "roles": "Roles",
      "users": "Users",
      "identifier": "Identifier",
      "showRights": "Show rights",
      "userList": "User list",
      "login": "Login",
      "createdAt": "Created at",
      "lastUpdate": "Last update",
      "addUser": "Add user",
      "userDeleting": "User deleting",
      "userEditing": "User editing",
      "userCreating": "User creating",
      "showRoles": "Show roles",
      "email": "Email",
      "password": "Password",
      "noRoles": "No roles",
      "test": "test"
    },
    "widgets": {
      "listDataFetchingHasBeenFailed": "List data fetching has been failed.",
      "itemFetchingHasBeenFailed": "Item fetching has been failed.",
      "itemHasBeenSuccessfullyCreated": "Item has been successfully created",
      "itemHasBeenSuccessfullyUpdated": "Item has been successfully updated",
      "itemHasBeenSuccessfullyDeleted": "Item has been successfully deleted",
      "itemCreatingHasBeenFailed": "Item creating has been failed",
      "itemUpdatingHasBeenFailed": "Item updating has been failed",
      "itemDeletingHasBeenFailed": "Item deleted has been failed",
      "doYouReallyWantToDeleteThisItem": "Are you sure you want to delete this item?",
      "listIsEmpty": "List is empty",
      "noRights": "No rights",
      "showThePassword": "Show password",
      "hideThePassword": "Hide password",
      "goTo": "Go to",
      "linesPerPage": "Lines per page",
      "goToPage": "Go to page",
      "displayCells": "Display",
      "dataOnRequestedPageIsNotAvailable": "Sorry, but data on the requested page is not available.",
      "filesAlreadyExist": "These files are already in the table",
      "itemsHaveBeenSuccessfullyDeleted": "Items have been successfully deleted",
      "someOfTheItemsHaveNotBeenDeleted": "Some of the items have not been deleted"
    },
    "common": {
      "day": "Day",
      "week": "Week",
      "month": "Mounth",
      "quarter": "Quarter",
      "year": "Year",
      "update": "Update",
      "currency": "Currency",
      "proxy": "Proxy",
      "proxies": "Proxies",
      "disable": "Disable",
      "confirm": "Confirm",
      "create": "Create",
      "status": "Status",
      "actions": "Actions",
      "next": "Next",
      "back": "Back",
      "reset": "Reset",
      "cancel": "Cancel",
      "success": "Success",
      "error": "Error",
      "type": "Type",
      "delete": "Delete",
      "label": "Label",
      "orderId": "Order ID",
      "labelOrderId": "Label/ Order ID",
      "select": "Select",
      "amount": "Amount",
      "paymentSystem": "Payment system",
      "dateFrom": "Date from",
      "dateTo": "Date to",
      "filterSubmit": "Submit",
      "date": "Date",
      "createdAt": "Creation date",
      "createAndThenCreateAnotherOne": "Create and then create another one",
      "possibleTransactionList": "Possible transaction list",
      "activate": "Activate",
      "copy": "Copy",
      "save": "Save",
      "period": "Period",
      "quantity": "Number",
      "edit": "Edit",
      "add": "Add",
      "noRights": "No rights",
      "upload": "Upload",
      "data": "Data",
      "all": "All",
      "search": "Search",
      "attention": "Attention",
      "gotIt": "Got it",
      "country": "Country",
      "sum": "Sum",
      "approve": "Approve",
      "reject": "Reject",
      "comment": "Comment",
      "yes": "Yes",
      "no": "No",
      "listIsEmpty": "List is empty",
      "filterReturnedNoResults": "The filter returned no results",
      "capsLockIsOn": "Caps-Lock is on",
      "warning": "Warning",
      "close": "Close",
      "absent": "Absent",
      "ID": "ID",
      "api": "API",
      "retry": "Retry",
      "send": "Send",
      "saveFile": "Save file",
      "updatePlugin": "Update plugin",
      "displayIn": "Display in",
      "generateBy": "Generate by",
      "postbackMessage": "Postback message",
      "low": "Low",
      "normal": "Normal",
      "high": "High",
      "archive": "Archive",
      "recover": "Recovery",
      "format": "Format",
      "restore": "Restore",
      "archivedAt": "Archiving date",
      "activatedAt": "Activation date",
      "mark": "Mark",
      "apply": "Apply",
      "chooseLanguage": "Choose language",
      "chooseTranslate": "Choose translation",
      "form": "Form",
      "unsaveChanges": "There are unsaved changes. Close the window and save the translation",
      "closeWindow": "Close the window",
      "translatesHints": "Translates: hints",
      "translatesPackages": "Translates: packages",
      "defaultEng": "English by default",
      "confirmDeleteTranslation": "Are you sure you want to delete this translation?",
      "translationDelete": "Deleting a translation",
      "engIsRequired": "English is required",
      "successUpdate": "Text hints have been successfully updated",
      "errorUpdate": "An error occured while updating text hints",
      "successCreation": "Text hint created successfully",
      "errorCreation": "An error occurred while creating a text hint",
      "belowNormal": "Below normal",
      "aboveNormal": "Above normal",
      "balance": "Balance",
      "completed": "Completed",
      "notCompleted": "Not completed",
      "periodCreation": "Create period",
      "periodUpdating": "Update period"
    },
    "proxies": {
      "index": "Index",
      "walletsID": "Wallets' ID",
      "proxyDeleting": "Deleting the proxy ",
      "port": "Port",
      "username": "Username",
      "password": "Password",
      "proxyUpdating": "Updating the proxy ",
      "proxyCreating": "Creating a proxy ",
      "startCheckingAllProxies": "Run all proxies check ",
      "addProxy": "Add proxy",
      "proxies": "Proxies",
      "proxyList": "Proxy list",
      "notWorking": "Not working",
      "working": "Working",
      "ipPort": "ip:port",
      "noActiveProxies": "No active proxies",
      "doesNotWork": "Doesn't work",
      "check": "Check",
      "loadByList": "Load by list",
      "pasteProxyList": "Insert proxy list",
      "checkList": "Check list",
      "noValidProxies": "Add at least one working proxy.",
      "verifiedProxies": "Verified proxies: {{verified}}",
      "ipHost": "IP | Host",
      "invalidRows": "Invalid rows",
      "proxiesAdding": "Proxies adding",
      "checking": "Checking",
      "existsInSystem": "existsInSystem",
      "addOnlyNew": "Add only new",
      "addNewAndUpdateExisted": "Add new and update existed",
      "proxyConnectionWithPaytm": "Proxy connection with Paytm",
      "download": "Download",
      "upload": "Upload",
      "saveSucceededRelations": "Save available relations",
      "paytmProxiesRelationsUploading": "Downloading the link list of Paytm wallets with a proxy",
      "insertListOfRelationsOfPaytmWithProxies": "Insert the link list of PayTM wallets with a proxy",
      "invalidDataOfPaytmProxiesRelations": "List of lines with wallets / proxies missing in the system",
      "invalidFormatOfPaytmProxiesRelations": "List of unparsed lines in the file",
      "relationsHaveBeenAdded": "Relations have been successfully added",
      "paytmProxiesRowsMustBeRight": "Attention, the string must match the pattern: Wallet_ID Wallet_number IP:Port:Login:Password",
      "ip": "IP",
      "proxiesRelationsUploading": "Downloading a list of wallets linking with a proxy",
      "insertListOfRelationsWithProxies": "Insert the link list wallets with a proxy",
      "proxyConnection": "Proxy connection",
      "stringFormat": "String format - IP:Port:Login:Password:Type:Country_code",
      "undefined": "Undefined",
      "proxyInsertFormat": "Insert the link list wallets with a proxy in the format:\nWallet_ID Wallet_number IP:Port:Login:Password",
      "downloadExample": "Download example file",
      "login": "LOGIN",
      "wantDeleteSelectedProxies": "Are you sure you want to delete the selected proxies?",
      "proxyListCheckFailed": "Proxy list check failed, please try again",
      "dateOfChecking": "Date of checking"
    }
  }
};

export default en;
