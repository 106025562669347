import React from 'react';
import { ISelectData } from '@kassma-team/kassma-toolkit/lib/types/form';

import {
  PreliminaryTransactionStatus,
  TransactionStatus,
  TransactionKeyType,
  TransactionCreationType,
  TransactionDateType,
  TransactionPostbackStatus,
  TransactionKind,
  PostbackStatus,
  PostbackFilter,
} from 'utils/enums';
import { ISelectDataWithNull } from 'interfaces/form';

export const preliminaryTransactionStatusList: ISelectDataWithNull[] = [
  {
    value: null,
    text: `common.all`,
  },
  {
    value: PreliminaryTransactionStatus.NEW,
    text: `transactions.new`,
  },
  {
    value: PreliminaryTransactionStatus.PROCESSED,
    text: `transactions.processed`,
  },
];

export const transactionActivationTypeList: ISelectData[] = [
  {
    value: TransactionKeyType.WITH_KEY,
    text: `transactions.withKey`,
  },
  {
    value: TransactionKeyType.WITHOUT_KEY,
    text: `transactions.withoutKey`,
  },
];

export const transactionDirectionList = (emptyValue = ``): ISelectDataWithNull[] => [
  {
    value: null,
    text: emptyValue,
  },
  {
    value: `outgoing`,
    text: `transactions.outgoing`,
  },
  {
    value: `ingoing`,
    text: `transactions.ingoing`,
  },
];

export const transactionStatusList: ISelectData[] = [
  {
    value: TransactionDateType.ACTIVATION,
    text: `reports.activationData`,
  },
  {
    value: TransactionDateType.CREATION,
    text: `common.createdAt`,
  },
  {
    value: TransactionDateType.STOCKPILING,
    text: `reports.stockpilingEndDate`,
  },
];

export const kindOfTransaction = (emptyValue = ``): ISelectDataWithNull[] => [
  {
    value: null,
    text: emptyValue,
  },
  {
    value: TransactionCreationType.AUTO,
    text: `transactions.auto`,
  },
  {
    value: TransactionCreationType.MANUAL,
    text: `transactions.manual`,
  },
];

export const transactionType = (showInner: boolean, emptyValue = ``): ISelectDataWithNull[] => {
  const types = [
    {
      value: null,
      text: emptyValue,
    },
    {
      value: `fake`,
      text: `transactions.fake`,
    },
    {
      value: `real`,
      text: `transactions.real`,
    },
    {
      value: `forced`,
      text: `transactions.forced`,
    },
  ];

  if (showInner) {
    types.push({
      value: `inner`,
      text: `transactions.inner`,
    });
  }

  return types;
};

export const transactionPostbackStatusList = (emptyValue = ``): ISelectDataWithNull[] => [
  {
    value: null,
    text: emptyValue,
  },
  {
    value: TransactionPostbackStatus.STOCKPILING_NOT_FOUND,
    text: `postbacks.stockpilingNotFound`,
  },
  {
    value: TransactionPostbackStatus.STOCKPILING_IN_PROGRESS,
    text: `postbacks.stockpilingInProgress`,
  },
  {
    value: TransactionPostbackStatus.SENT,
    text: `postbacks.sent`,
  },
  {
    value: TransactionPostbackStatus.ERROR_SENDING,
    text: `postbacks.errorSendingPostback`,
  },
];

export const transactionStatusAtFiltersList = (emptyValue = ``): ISelectDataWithNull[] => [
  {
    value: null,
    text: emptyValue,
  },
  {
    value: TransactionStatus.ACTIVATED,
    text: `transactions.filterActivated`,
  },
  {
    value: TransactionStatus.NOT_ACTIVATED,
    text: `transactions.filterNotActivated`,
  },
  {
    value: TransactionStatus.REFUSED,
    text: `transactions.filterRejected`,
  },
  {
    value: TransactionStatus.PENDING,
    text: `transactions.filterPending`,
  },
  {
    value: TransactionStatus.IN_PROCESSING,
    text: `transactions.filterInProgress`,
  },
  {
    value: TransactionStatus.CRASHED,
    text: `transactions.filterCrashed`,
  },
  {
    value: TransactionStatus.CANCELED,
    text: `transactions.filterCanceled`,
  },
  {
    value: TransactionStatus.ERROR,
    text: `transactions.filterError`,
  },
];

interface ITransactionParams {
  message: string;
  color: string;
}

export const transactionParamsByStatus: Record<number, ITransactionParams> = {
  [TransactionStatus.ACTIVATED]: {
    message: `transactions.activated`,
    color: `badge-success`,
  },
  [TransactionStatus.NOT_ACTIVATED]: {
    message: `transactions.notActivated`,
    color: `badge-danger`,
  },
  [TransactionStatus.REFUSED]: {
    message: `transactions.rejected`,
    color: `badge-danger`,
  },
  [TransactionStatus.PENDING]: {
    message: `transactions.pending`,
    color: `badge-primary`,
  },
  [TransactionStatus.IN_PROCESSING]: {
    message: `transactions.inProgress`,
    color: `badge-primary`,
  },
  [TransactionStatus.CRASHED]: {
    message: `transactions.crashed`,
    color: `badge-danger`,
  },
  [TransactionStatus.CANCELED]: {
    message: `transactions.canceled`,
    color: `badge-danger`,
  },
  [TransactionStatus.ERROR]: {
    message: `transactions.error`,
    color: `badge-danger`,
  },
};

export const transactionParamsByType: Record<string, ITransactionParams> = {
  [TransactionKind.REAL]: {
    message: `transactions.real`,
    color: `badge-success`,
  },
  [TransactionKind.DEBUG]: {
    message: `transactions.fake`,
    color: `badge-warning`,
  },
  [TransactionKind.FORCED]: {
    message: `transactions.forced`,
    color: `badge-primary`,
  },
};

export const postbackStatusToIcon = {
  [PostbackStatus.POSTBACK_NOT_SEND]: <i className="fa fa fa-usd" />,
  [PostbackStatus.POSTBACK_OK]: <i className="fa fa-check-square text-success" />,
  [PostbackStatus.POSTBACK_ERROR]: <i className="fa fa-close text-danger" />,
  [PostbackStatus.POSTBACK_STOCKPILING_NOT_FOUND]: <i className="fa fa-exclamation text-danger" />,
};

export const postbackResponseStatusToIcon = {
  [PostbackFilter.ERROR]: <i className="fa fa-close text-danger" />,
  [PostbackFilter.RECEIVED]: <i className="fa fa-check-square text-success" />,
};
