import React, { useEffect, useMemo } from 'react';
import { reduxForm, getFormValues } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GridType, getSubmitHandler, InputType } from '@kassma-team/kassma-toolkit/lib';

import { ModalType, Permission, WidgetType } from 'utils/enums';
import { hasAccessSelector } from 'selectors/auth';
import transactionListMapping from 'utils/widgets/transactions/transactionListMapping';
import useTransactionsTableTitles from 'hooks/widgets/tableTitles/transactions/useTransactionsTableTitles';
import {
  transactionDirectionList,
  transactionStatusAtFiltersList,
  transactionType,
  transactionPostbackStatusList,
} from 'static/transactions';
import useWalletListByType from 'hooks/widgets/useWalletListByType';
import { parseWalletList } from 'utils/widgets/wallets/parseWalletList';
import { PSWithoutSettlementsSelector } from 'selectors/widgets/paymentSystems';
import withPermissionCheck from 'hocs/withPermissionCheck';
import { selectionOfAllManualPSSelector } from 'selectors/widgets/manualPS';
import { manualPSActionCreators } from 'actions/widgets/paymentSystem';
import { archivedTransactionActionCreators } from 'actions/widgets/transactions/archivedTransactions';
import { IFilterProps } from 'interfaces/widgets/widgets';
import { postbackActionCreators } from 'actions/widgets/postback';

import Filters from 'components/widgets/filters/Filters';
import transactionRouteTabs from 'pages/transactions/transactionRouteTabs';
import TransactionPostbackList from 'components/widgets/transaction/TransactionPostbackList';
import TransactionPostbackInfo from 'components/widgets/transaction/TransactionPostbackInfo';
import Widget from 'components/widgets/Widget';

import './transactions.scss';

const FORM_NAME = `transaction-filters`;

const entityParams = {
  actionCreators: archivedTransactionActionCreators,
  widget: WidgetType.ARCHIVED_TRANSACTIONS,
};

const FilterForm = reduxForm<any, IFilterProps>({
  form: FORM_NAME,
  onSubmit: getSubmitHandler({
    dateFields: [
      {
        name: `date_range`,
        fromName: `date_from`,
        toName: `date_to`,
      },
      {
        name: `activation_date_range`,
        fromName: `activated_date_from`,
        toName: `activated_date_to`,
      },
    ],
    dateFormat: `YYYY-MM-DD HH:mm:ss`,
  }),
})(Filters);

const modals = [
  {
    type: ModalType.SHOW,
    widget: WidgetType.ARCHIVED_TRANSACTIONS,
    component: () =>
      TransactionPostbackList({
        ...entityParams,
        modalWidget: WidgetType.ARCHIVED_TRANSACTIONS,
        shouldUseItem: true,
        widget: WidgetType.POSTBACKS,
        listActionCreators: postbackActionCreators,
      }),
  },
  {
    type: ModalType.INFO,
    widget: WidgetType.ARCHIVED_TRANSACTIONS,
    component: () => TransactionPostbackInfo(entityParams),
  },
  {
    type: ModalType.NOT_ACCEPTED_TRANSACTION_POSTBACKS,
    widget: WidgetType.ARCHIVED_TRANSACTIONS,
    component: () =>
      TransactionPostbackList({
        ...entityParams,
        modalWidget: WidgetType.ARCHIVED_TRANSACTIONS,
        widget: WidgetType.POSTBACKS,
        listActionCreators: postbackActionCreators,
        shouldUseItem: true,
      }),
  },
];

const filterDateFields = [
  {
    from: `date_from`,
    to: `date_to`,
    name: `date_range`,
  },
  {
    from: `activated_date_from`,
    to: `activated_date_to`,
    name: `activation_date_range`,
  },
];

const ArchivedTransactions = () => {
  const transactionInnerAccess = useSelector(hasAccessSelector(Permission.TRANSACTION_INNER));
  const suspectsTransactionViewPermission = useSelector(
    hasAccessSelector(Permission.TRANSACTION_SUSPECTS_TRANSACTIONS_VIEW)
  );

  const dispatch = useDispatch();

  const walletTypes = useSelector(PSWithoutSettlementsSelector);

  const safeWalletTypes = walletTypes?.map((el) => ({
    ...el,
    text: el.name,
    value: el.code,
  }));

  const testPayments = useSelector(selectionOfAllManualPSSelector);

  const safeTestPayments = testPayments?.map((el) => ({
    ...el,
    text: el.name,
    value: el.code,
  }));

  const values: any = useSelector(getFormValues(FORM_NAME)) || {};

  const titles = useTransactionsTableTitles(WidgetType.ARCHIVED_TRANSACTIONS);

  const [t] = useTranslation();

  const title = t(`transactions.archiveTransactionsList`);

  useEffect(() => {
    dispatch(manualPSActionCreators.getList());
  }, []);

  const showWalletFilter = useSelector(hasAccessSelector(Permission.WALLET_LIST));

  const { walletsSelection: walletList, listLoading: walletListLoading } = useWalletListByType(values.wallet_type, {
    showAllWhenTypeIsEmpty: true,
    disableLoading: !showWalletFilter,
  });

  const transactionsViewTypes = useSelector(hasAccessSelector(Permission.DISPLAY_TRANSACTION_TYPE));
  const transactionPrivateInformationView = useSelector(
    hasAccessSelector(Permission.TRANSACTION_PRIVATE_INFORMATION_VIEW)
  );

  const leftFilterFields = useMemo(() => {
    let filters: any[] = [
      {
        name: `primary_id`,
        placeholder: t(`common.ID`),
        numeric: true,
      },
      {
        name: `wallet_type`,
        id: `${FORM_NAME}_wallet_type`,
        data: [{ value: null, text: t(`common.all`) }, ...safeWalletTypes, ...safeTestPayments],
        type: InputType.SELECT,
        placeholder: t(`wallets.walletType`),
      },
      ...[
        showWalletFilter
          ? {
              name: `wallet_id`,
              id: `${FORM_NAME}_wallet_id`,
              data: parseWalletList(walletList, { displayId: true, emptyText: t(`common.all`) }),
              type: InputType.SELECT,
              placeholder: t(`wallets.wallet`),
              busy: walletListLoading,
            }
          : undefined,
      ],
      {
        name: `label`,
        placeholder: t(`common.label`),
      },
      {
        name: `merchant_order_id`,
        placeholder: t(`common.orderId`),
      },
      {
        name: `direction`,
        id: `${FORM_NAME}_direction`,
        data: transactionDirectionList(t(`common.all`)),
        translateKeys: true,
        type: InputType.SELECT,
        placeholder: t(`transactions.direction`),
      },
      {
        name: `transaction_id`,
        placeholder: t(`transactions.transactionId`),
      },
      {
        name: `exchanger_identifier`,
        placeholder: t(`transactions.exchanger`),
      },
      ...[
        transactionsViewTypes
          ? {
              name: `type`,
              id: `${FORM_NAME}_type`,
              data: transactionType(transactionInnerAccess, t(`common.all`)),
              type: InputType.SELECT,
              placeholder: t(`transactions.transactionType`),
              translateKeys: true,
            }
          : undefined,
      ],
      ...[
        transactionPrivateInformationView
          ? {
              name: `status`,
              id: `${FORM_NAME}_status`,
              data: transactionStatusAtFiltersList(t(`common.all`)),
              type: InputType.SELECT,
              placeholder: t(`common.status`),
              translateKeys: true,
            }
          : undefined,
      ],
    ];

    if (suspectsTransactionViewPermission) {
      filters.push({
        name: `is_fake`,
        id: `${FORM_NAME}_is_fake`,
        data: [
          {
            text: `common.all`,
            value: null,
          },
          {
            text: `transactions.original`,
            value: `false`,
          },
          {
            text: `transactions.scam`,
            value: `true`,
          },
        ],
        type: InputType.SELECT,
        placeholder: t(`transactions.originality`),
        translateKeys: true,
      });
    }

    filters = [
      ...filters,
      {
        name: `postback_status`,
        id: `${FORM_NAME}_postback_status`,
        data: transactionPostbackStatusList(t(`common.all`)),
        type: InputType.SELECT,
        placeholder: t(`postbacks.postbackStatus`),
        translateKeys: true,
      },
      {
        name: `date_range`,
        type: InputType.DATE_RANGE,
        placeholder: t(`common.createdAt`),
        timePicker: true,
        showSeconds: true,
      },
      {
        name: `activation_date_range`,
        type: InputType.DATE_RANGE,
        placeholder: t(`common.activatedAt`),
        timePicker: true,
        showSeconds: true,
      },
    ];

    return filters;
  }, [
    suspectsTransactionViewPermission,
    transactionInnerAccess,
    walletTypes,
    walletList,
    walletListLoading,
    testPayments,
    showWalletFilter,
    transactionsViewTypes,
    transactionPrivateInformationView,
  ]);

  return (
    <Widget
      actionCreators={archivedTransactionActionCreators}
      widget={WidgetType.ARCHIVED_TRANSACTIONS}
      filterFormName={FORM_NAME}
      pageTitle="transactions.archiveTransactions"
      headTitle="transactions.archiveTransactions"
      routeTabs={transactionRouteTabs}
      tableTitle={title}
      translatedTitle={false}
      tableHeadTitles={titles}
      listMapping={transactionListMapping(entityParams)}
      filterForm={
        <FilterForm
          leftFields={leftFilterFields}
          formName={FORM_NAME}
          dateFields={filterDateFields}
          gridType={GridType.GRID}
          numFields={[`status`, `country_id`, `postback_status`]}
          submissionControlsOnTheRightSide={false}
        />
      }
      modals={modals}
      withPagination
      statusBar
    />
  );
};

export default withPermissionCheck(ArchivedTransactions, [
  {
    permissions: [
      {
        permission: Permission.ARCHIVE_TRANSACTION_VIEW,
        opposite: true,
      },
    ],
    redirectUrl: `/no-permissions`,
  },
  {
    permissions: [
      {
        permission: Permission.TRANSACTION_ONLY_BY_ID,
        opposite: false,
      },
      {
        permission: Permission.TRANSACTION_LIST,
        opposite: true,
      },
    ],
    redirectUrl: `/transactions-only-by-id`,
  },
]);
