import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import { IHideColumnsPayload } from '@kassma-team/kassma-toolkit';

export class HiddenColumnsApi extends BaseApi {
  getHiddenColumns = (): AxiosPromise => this.makeFetch(``);
  saveHiddenColumns = (data: IHideColumnsPayload): AxiosPromise => this.makeFetch(``, { data, method: `POST` });
}

const hiddenColumnsApi = new HiddenColumnsApi(`hidden-columns`);

export default hiddenColumnsApi;
