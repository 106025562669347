import React, { useState } from 'react';
import map from 'lodash/map';
import size from 'lodash/size';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDesktopSize, widgetModalSelector, Size } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { stockpilingTransactionsActionCreators } from 'actions/widgets/transactions/stockpilingTransactions';
import { stockpilingListActionCreators } from 'actions/widgets/stockpiling';
import useList from 'hooks/widgets/useList';
import { IStockpilingTransactionItem } from 'interfaces/widgets/stockpiling';

import StockpilingTransactionRow from 'components/widgets/stockpiling/StockpilingTransactionRow';
import ModalElement from 'components/modals/ModalElement';

const StockpilingTransactionList = () => {
  const modal = useSelector(widgetModalSelector(WidgetType.STOCKPILING_LIST));

  const [t] = useTranslation();

  const [stockpilingId] = useState(get(modal, `id`));

  const { items, listLoading } = useList<IStockpilingTransactionItem>({
    actionCreators: stockpilingTransactionsActionCreators,
    widget: WidgetType.STOCKPILING_TRANSACTIONS,
    queryParams: stockpilingId,
  });

  const desktopSize = useDesktopSize();

  let content;
  if (!listLoading) {
    if (size(items) === 0) {
      content = <span className="table-content__message">{t(`transactions.transactionListIsEmpty`)}</span>;
    } else {
      content = (
        <div className="table-responsive">
          <table className="table table-vcenter table-padding">
            {desktopSize && (
              <thead>
                <tr>
                  <th className="font-w600">{t(`wallets.walletIdentifier`)}</th>
                  <th className="font-w600">{t(`transactions.transactionNumber`)}</th>
                  <th className="font-w600">{t(`transactions.transactionAmount`)}</th>
                  <th className="font-w600">{t(`common.date`)}</th>
                  <th className="font-w600">{t(`transactions.transactionType`)}</th>
                  <th className="font-w600">{t(`transactions.archivedTransaction`)}</th>
                </tr>
              </thead>
            )}
            <tbody>
              {map(items, (item) => (
                <StockpilingTransactionRow desktopSize={desktopSize} key={item.id} {...item} />
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  }

  return (
    <ModalElement
      actionCreators={stockpilingListActionCreators}
      title="transactions.transactionList"
      size={Size.BIG}
      loading={listLoading}
      content={content}
    />
  );
};

export default StockpilingTransactionList;
