import { put, takeEvery } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import {
  formSubmissionError,
  validateForm,
  WIDGET_RESET_MODAL,
  WIDGET_LIST_SUCCEEDED,
  IWidgetsMeta,
  IAction,
} from '@kassma-team/kassma-toolkit/lib';
import get from 'lodash/get';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import { toastr } from 'react-redux-toastr';
import i18n from 'i18n';

import { CREATE_SETTLEMENT_FORM_NAME, MONEY_TRANSFER_FORM_NAME } from 'utils/constants';
import { accountsBalancesActionCreators, transferMoneySucceeded } from 'actions/widgets/balances/accountsBalances';
import {
  TRANSFER_MONEY_REQUESTED,
  TRANSFER_TO_ANOTHER_ACCOUNT_REQUESTED,
  BALANCE_HISTORY_LIST_REQUESTED,
  BALANCE_MIN_DATE_SUCCEEDED,
} from 'actionTypes';
import refreshSaga from 'sagas/effects/refreshSaga';
import { WidgetType } from 'utils/enums';
import {
  IMoneyTransferFormProps,
  ITransferringMoneyToSettlementBalanceForm,
} from 'interfaces/widgets/balances/balances';
import { getErrorMessage } from 'utils';
import accountBalanceApi from 'api/balances/AccountBalanceApi';
import settlementApi from 'api/balances/SettlementApi';
import balanceHistoryApi from 'api/balances/BalanceHistoryApi';

interface IProps {
  meta: IWidgetsMeta;
  payload: IMoneyTransferFormProps;
}

function* transferMoneySaga({ meta, payload }: IProps) {
  const valid: boolean = yield validateForm({ form: MONEY_TRANSFER_FORM_NAME, meta });
  if (!valid) {
    return;
  }

  yield put(accountsBalancesActionCreators.setAnyData({ submitting: true }));

  yield refreshSaga({
    request: () => accountBalanceApi.transferMoney(payload),
    onSuccess: function* (resp: AxiosResponse) {
      yield put({ type: WIDGET_RESET_MODAL, meta });
      yield toastr.success(i18n.t(`common.success`), i18n.t(`widgets.itemHasBeenSuccessfullyCreated`));

      const writeoff_account = get(resp, `data.params.writeoff_account`);
      const recipient_account = get(resp, `data.params.recipient_account`);
      if (writeoff_account && recipient_account) {
        yield put(
          transferMoneySucceeded({
            writeOfPS: get(writeoff_account, `payment_system`),
            writeOfPSAmount: `${get(writeoff_account, `amount`)}`,
            recipientPS: get(recipient_account, `payment_system`),
            recipientPSAmount: `${get(recipient_account, `amount`)}`,
            currency_code: get(payload, `currency_code`),
          })
        );
      }
    },
    onError: function* (e: AxiosError) {
      const payload: string = yield getErrorMessage(e, { defaultValue: i18n.t(`widgets.itemCreatingHasBeenFailed`) });
      yield toastr.error(i18n.t(`common.error`), payload);
      yield put(accountsBalancesActionCreators.setAnyData({ submitting: false }));
    },
  });
}

interface ITransferToAnotherAccountProps {
  meta: IWidgetsMeta;
  payload: ITransferringMoneyToSettlementBalanceForm;
}

function* transferToAnotherAccountSaga({ meta, payload }: ITransferToAnotherAccountProps) {
  const valid: boolean = yield validateForm({ form: CREATE_SETTLEMENT_FORM_NAME, meta });
  if (!valid) {
    return;
  }

  const data: Omit<ITransferringMoneyToSettlementBalanceForm, `crediting`> = omit(payload, `crediting`);

  yield refreshSaga({
    request: () => settlementApi.createReplenishment(data),
    onSuccess: function* () {
      yield put({ type: WIDGET_RESET_MODAL, meta });
      yield toastr.success(i18n.t(`common.success`), i18n.t(`widgets.itemHasBeenSuccessfullyCreated`));

      yield put(accountsBalancesActionCreators.getList());
    },
    onError: function* (e: AxiosError) {
      const payload: string = yield getErrorMessage(e, { defaultValue: i18n.t(`widgets.itemCreatingHasBeenFailed`) });
      yield formSubmissionError({ payload, meta, form: CREATE_SETTLEMENT_FORM_NAME });
    },
  });
}

interface IAccountBalanceHistoryList {
  payload: { date: string };
  meta: IWidgetsMeta;
}

function* accountBalanceHistoryList({ payload, meta }: IAccountBalanceHistoryList) {
  yield refreshSaga({
    request: () => balanceHistoryApi.loadBalanceHistory(payload),
    onSuccess: function* (resp: AxiosResponse) {
      if (isEmpty(get(resp, `data.balances`))) {
        toastr.error(i18n.t(`common.error`), i18n.t(`balances.noDataAvailableForDate`));
        yield put({ type: WIDGET_LIST_SUCCEEDED, meta, payload: null });
      } else {
        yield put({ type: WIDGET_LIST_SUCCEEDED, meta, payload: resp.data });
      }
    },
    onError: function (err: AxiosError) {
      toastr.error(
        i18n.t(`common.error`),
        getErrorMessage(err, {
          normalize: true,
          defaultValue: i18n.t(`widgets.listDataFetchingHasBeenFailed`),
        })
      );
    },
  });
}

interface IGetAccountBalanceMinDate {
  payload: any;
  meta: IWidgetsMeta;
}

function* getAccountBalanceMinDate({ payload, meta }: IGetAccountBalanceMinDate) {
  const minDate = get(payload, `minDate`);
  const widgetType = get(meta, `widget`);

  if (minDate && widgetType === WidgetType.ACCOUNTS_BALANCES) {
    yield put({ type: BALANCE_MIN_DATE_SUCCEEDED, meta, payload: minDate });
  }
}

const accountBalanceSagas = [
  takeEvery<IAction>(TRANSFER_MONEY_REQUESTED, transferMoneySaga),
  takeEvery<IAction>(TRANSFER_TO_ANOTHER_ACCOUNT_REQUESTED, transferToAnotherAccountSaga),
  takeEvery<IAction>(BALANCE_HISTORY_LIST_REQUESTED, accountBalanceHistoryList),
  takeEvery<IAction>(WIDGET_LIST_SUCCEEDED, getAccountBalanceMinDate),
];

export default accountBalanceSagas;
