import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { widgetSelector } from '@kassma-team/kassma-toolkit';

import useTable from 'hooks/widgets/useTable';
import { settlementWithdrawalActionCreators } from 'actions/widgets/balances/settlement';
import { WidgetType } from 'utils/enums';
import { ISettlementWithdrawalRow, ISettlementWithdrawalWidgetState } from 'interfaces/widgets/settlement';
import { useSettlementWithdrawalTitles } from 'hooks/widgets/tableTitles/settlement/useSettlementWithdrawalTitles';

import TableContentContainer from 'components/containers/TableContentContainer';
import SettlementWithdrawalRow from 'components/widgets/balances/SettlementWithdrawalRow';
import WidgetTable from 'components/widgets/WidgetTable';

const withdrawalListMapping = (item: ISettlementWithdrawalRow, key: number) => (
  <SettlementWithdrawalRow key={item.id || key} {...item} />
);

const SettlementWithdrawal: FC = () => {
  const withdrawalTitles = useSettlementWithdrawalTitles();
  const { items: withdrawalItems }: ISettlementWithdrawalWidgetState = useSelector(
    widgetSelector(WidgetType.SETTLEMENT_WITHDRAWAL)
  );

  useTable({
    widget: WidgetType.SETTLEMENT_WITHDRAWAL,
    actionCreators: settlementWithdrawalActionCreators,
    statusBar: true,
  });

  return (
    <TableContentContainer
      title="reports.listOfWithdrawalRequests"
      onRefresh={settlementWithdrawalActionCreators.getList}
    >
      <WidgetTable
        data={withdrawalItems}
        titles={withdrawalTitles}
        listMapping={withdrawalListMapping}
        widget={WidgetType.SETTLEMENT_WITHDRAWAL}
      />
    </TableContentContainer>
  );
};

export default SettlementWithdrawal;
