import React from 'react';
import { FieldType, InputType } from '@kassma-team/kassma-toolkit';
import get from 'lodash/get';
import size from 'lodash/size';
import moment from 'moment';
import { TFunction } from 'react-i18next';

import { parseWalletList } from 'utils/widgets/wallets/parseWalletList';
import {
  ArchivingStatus,
  PostbackStatus,
  ReportDirection,
  ReportOperationType,
  ReportType,
  TransactionCreationType,
  TransactionDirection,
  TransactionKind,
  TransactionStatus,
  WalletStatus,
  WidgetType,
} from 'utils/enums';
import { reportsFileFormatsList } from 'static/reports';
import { ITransactionsPaymentSystemsItems } from 'interfaces/widgets/paymentSystems';
import { INotRemovedWallet, IWalletSelectItem } from 'interfaces/widgets/wallets/wallet';
import { IGenerateReportsForm } from 'interfaces/widgets/reports';
import { autoReportPeriods } from 'static/autoreports';
import { WalletType } from 'api/WalletTypes';
import { useSelector } from 'react-redux';
import {
  accountBalanceHistoryCurrenciesByPaymentSystem,
  accountBalanceHistoryCurrenciesByPaymentSystemForReports,
} from 'selectors/widgets/balances';
import { paykassmaForReports } from 'selectors/widgets/paymentSystems';

const WalletNumber = ({ item }: Record<string, IWalletSelectItem>) => (
  <span className={item.status === WalletStatus.DISABLED ? `text-danger` : undefined}>{item.text}</span>
);

interface IGetReportTypeFieldsProps {
  archivedTransactionPermission: boolean;
  type: string;
  t: TFunction;
  transactionsPSList: ITransactionsPaymentSystemsItems[];
  testPaymentSystems: Array<{ value: string; text: string } & WalletType>;
  currenciesWithIdSelectionData: { value: string; text: string }[];
  walletsSelection: INotRemovedWallet[] | null;
  directionValue: string;
  fieldList: { value: string; text: string }[];
  withdrawalsPSListSelection: { value?: string; text: string }[];
  currenciesSelectionData: { value: string; text: string }[];
  withdrawalStatusSelectionList?: { value?: string; text: string }[];
  operation_type: string;
  form: string;
  walletType: string;
  values: IGenerateReportsForm;
  // accountBalancesListSelection: { value: string; text: string }[];
  // currencies: { value: string; text: string }[];
  archivingStatus: string;
  shouldPostbackStatusBeFixed?: boolean;
  activationDate: string;
  transactionKindItems: { value: string; text: string }[];
  transactionKind?: number[];
  transactionStatus: number;
  renderAutoreport?: JSX.Element;
  filterWalletId?: string;
  statusData: { value: any; text: string }[];
  transactionPrivateInformationViewPermission: boolean;
}

export const useGetReportTypeFields = ({
  type,
  t,
  transactionsPSList,
  testPaymentSystems,
  currenciesWithIdSelectionData,
  walletsSelection,
  transactionKindItems,
  activationDate,
  fieldList,
  withdrawalsPSListSelection,
  currenciesSelectionData,
  withdrawalStatusSelectionList,
  operation_type,
  form,
  walletType,
  values,
  shouldPostbackStatusBeFixed,
  transactionKind,
  transactionStatus,
  archivedTransactionPermission,
  renderAutoreport,
  archivingStatus,
  filterWalletId,
  statusData,
  transactionPrivateInformationViewPermission,
}: IGetReportTypeFieldsProps) => {
  const currenciesByPSForBalances = useSelector(
    accountBalanceHistoryCurrenciesByPaymentSystemForReports(values?.payment_system)
  );

  const transactionsPSListForBalances = useSelector(paykassmaForReports);

  const originalityOptions = [
    {
      text: `common.all`,
      value: null,
    },
    {
      text: `transactions.original`,
      value: true,
    },
    {
      text: `transactions.scam`,
      value: false,
    },
  ];

  switch (type) {
    case ReportType.SUM_REPORT:
    case ReportType.LIST_REPORT:
    case ReportType.TRANSACTIONS_SUM_DETAIL:
      return [
        {
          name: `filters.wallet_type`,
          id: `${form}_wallet_type`,
          type: InputType.SELECT,
          label: t(`wallets.walletType`),
          placeholder: t(`common.all`),
          data: [
            {
              value: null,
              text: t(`common.all`),
            },
            ...transactionsPSList,
            ...testPaymentSystems,
          ],
        },
        {
          name: `filters.wallet_id`,
          id: `${form}_wallet_identifier`,
          type: InputType.SELECT,
          data: parseWalletList(walletsSelection, {
            displayId: true,
            emptyText: t(`common.all`),
          }),
          label: t(`wallets.wallet`),
          placeholder: t(`common.all`),
          hide:
            !walletType ||
            (type === ReportType.SUM_REPORT &&
              !transactionKind?.includes(TransactionKind.REAL) &&
              (transactionKind?.includes(TransactionKind.DEBUG) || transactionKind?.includes(TransactionKind.FORCED))),
          unused:
            !walletType ||
            (walletType &&
              type === ReportType.SUM_REPORT &&
              !transactionKind?.includes(TransactionKind.REAL) &&
              (transactionKind?.includes(TransactionKind.DEBUG) || transactionKind?.includes(TransactionKind.FORCED))),
        },
        {
          name: `report_currency_code`,
          id: `${form}_currency`,
          type: InputType.SELECT,
          label: t(`common.currency`),
          data: currenciesWithIdSelectionData,
          required: true,
          // TODO: Изучить проблему, почему при других вариантах условий код не работает.
          hide: values?.widget !== WidgetType.AUTOREPORTS ? type !== ReportType.SUM_REPORT : false,
          unused: values?.widget !== WidgetType.AUTOREPORTS ? type !== ReportType.SUM_REPORT : false,
        },
        {
          name: `date_range`,
          fromValue: get(values, `date_range.values.startDate`),
          toValue: get(values, `date_range.values.endDate`),
          type: InputType.DATE_RANGE,
          label: t(`common.period`),
          timePicker: true,
          hideOnDateChanging: false,
          bothDateRequired: true,
          showSeconds: true,
          hide: values?.widget === WidgetType.AUTOREPORTS,
          unused: values?.widget === WidgetType.AUTOREPORTS,
        },
        {
          name: `date_range_of_activation`,
          fromValue: get(values, `date_range_of_activation.values.startDate`),
          toValue: get(values, `date_range_of_activation.values.endDate`),
          type: InputType.DATE_RANGE,
          label: t(`reports.activationPeriod`),
          timePicker: true,
          hideOnDateChanging: false,
          bothDateRequired: true,
          showSeconds: true,
          hide:
            values?.widget === WidgetType.AUTOREPORTS ||
            (type === ReportType.SUM_REPORT && transactionStatus === TransactionStatus.NOT_ACTIVATED),
          unused:
            values?.widget === WidgetType.AUTOREPORTS ||
            (type === ReportType.SUM_REPORT && transactionStatus === TransactionStatus.NOT_ACTIVATED),
        },
        {
          name: `archiving_period`,
          fromValue: get(values, `archiving_period.values.startDate`),
          toValue: get(values, `archiving_period.values.endDate`),
          type: InputType.DATE_RANGE,
          label: t(`reports.archivingPeriod`),
          timePicker: true,
          hideOnDateChanging: false,
          bothDateRequired: true,
          showSeconds: true,
          hide:
            values?.archiving_status === ArchivingStatus.WITHOUT_ARCHIVED ||
            values?.widget === WidgetType.AUTOREPORTS ||
            !(type === ReportType.LIST_REPORT && archivedTransactionPermission),
          unused:
            values?.widget === WidgetType.AUTOREPORTS ||
            !(type === ReportType.LIST_REPORT && archivedTransactionPermission),
        },
        {
          name: `only_archived`,
          label: t(`reports.archivedTransactionsOnly`),
          type: InputType.CHECKBOX,
          hide:
            values?.widget === WidgetType.REPORTS ||
            !(type === ReportType.LIST_REPORT && archivedTransactionPermission),
          unused:
            values?.widget === WidgetType.REPORTS ||
            !(type === ReportType.LIST_REPORT && archivedTransactionPermission),
        },
        {
          name: `archiving_status`,
          label: t(`reports.archivingStatus`),
          type: InputType.SELECT,
          data: [
            {
              value: ArchivingStatus.ALL,
              text: `common.all`,
            },
            {
              value: ArchivingStatus.ONLY_ARCHIVED,
              text: `reports.archivedOnly`,
            },
            {
              value: ArchivingStatus.WITHOUT_ARCHIVED,
              text: `reports.notArchivedOnly`,
            },
          ],
          translateKeys: true,
          placeholder: t(`common.all`),
          hide:
            values?.widget === WidgetType.AUTOREPORTS ||
            !(type === ReportType.LIST_REPORT && archivedTransactionPermission),
          unused:
            values?.widget === WidgetType.AUTOREPORTS ||
            !(type === ReportType.LIST_REPORT && archivedTransactionPermission),
        },
        {
          name: `detail_by_day`,
          label: t(`reports.detailByDay`),
          type: InputType.CHECKBOX,
          hide: values?.widget === WidgetType.AUTOREPORTS || type !== ReportType.SUM_REPORT,
          unused: values?.widget === WidgetType.AUTOREPORTS || type !== ReportType.SUM_REPORT,
        },
        {
          name: `filters.direction`,
          id: `${form}_report_type`,
          data: [
            {
              value: null,
              text: `common.all`,
            },
            {
              value: TransactionDirection.INGOING,
              text: `transactions.ingoing`,
            },
            {
              value: TransactionDirection.OUTGOING,
              text: `transactions.outgoing`,
            },
          ],
          translateKeys: true,
          type: InputType.SELECT,
          label: t(`reports.direction`),
          placeholder: t(`common.all`),
        },
        {
          name: `filters.type`,
          id: `${form}_transaction_type`,
          // в data: value обернуты в toString не просто так. Либа для мультиселекта читает 0 как false и отрабатывает некоректно
          data: transactionKindItems,
          type: InputType.MULTI_SELECT,
          label: t(`transactions.transactionType`),
          translateKeys: true,
          placeholder: t(`common.all`),
        },
        {
          name: `filters.creation_type`,
          id: `${form}_transaction_creation_type`,
          data: [
            {
              value: null,
              text: `common.all`,
            },
            {
              value: TransactionCreationType.AUTO,
              text: `transactions.auto`,
            },
            {
              value: TransactionCreationType.MANUAL,
              text: `transactions.manual`,
            },
          ],
          type: InputType.SELECT,
          label: t(`transactions.kindOfTransaction`),
          translateKeys: true,
          placeholder: t(`common.all`),
        },
        {
          name: `filters.status`,
          id: `${form}_status`,
          data: statusData,
          type: InputType.SELECT,
          label: t(`transactions.status`),
          translateKeys: true,
          placeholder: t(`common.all`),
          disabled: (activationDate && type === ReportType.SUM_REPORT) || !transactionPrivateInformationViewPermission,
        },
        {
          name: `filters.originality`,
          label: t(`transactions.originality`),
          type: InputType.SELECT,
          translateKeys: true,
          data: originalityOptions,
          placeholder: t(`common.all`),
        },
        {
          name: `filters.label`,
          label: t(`common.label`),
          hide: type === ReportType.SUM_REPORT && transactionStatus === TransactionStatus.NOT_ACTIVATED,
          unused: type === ReportType.SUM_REPORT && transactionStatus === TransactionStatus.NOT_ACTIVATED,
        },
        {
          name: `filters.exchanger_identifier`,
          label: t(`reports.counterparty`),
          hide:
            type === ReportType.SUM_REPORT &&
            transactionKind?.length === 1 &&
            transactionKind?.includes(TransactionKind.DEBUG),
          unused:
            type === ReportType.SUM_REPORT &&
            transactionKind?.length === 1 &&
            transactionKind?.includes(TransactionKind.DEBUG),
        },
        {
          name: `fields`,
          data: fieldList,
          fieldType: FieldType.CHECKBOX_GROUP,
          title: t(`reports.fields`),
          hide: !size(fieldList),
          unused: !size(fieldList),
        },
        {
          name: `file_format`,
          data: reportsFileFormatsList,
          type: InputType.SELECT,
          label: t(`reports.reportFormat`),
        },
        {
          name: `utc_0`,
          label: t(`reports.utc`),
          type: InputType.CHECKBOX,
        },
        {
          name: `title`,
          titleText: t(`reports.autogeneratingReport`),
          type: InputType.INPUT_TITLE,
          hide: values?.widget !== WidgetType.AUTOREPORTS,
          unused: values?.widget !== WidgetType.AUTOREPORTS,
        },
        {
          name: `pre_emails`,
          label: t(`wallets.wallet`),
          fieldType: FieldType.FIELD_ARRAY,
          component: renderAutoreport,
          required: true,
          hide: values?.widget !== WidgetType.AUTOREPORTS,
          unused: values?.widget !== WidgetType.AUTOREPORTS,
        },
        {
          name: `pre_start`,
          label: t(`reports.start`),
          type: InputType.DATE_TIME_PICKER,
          fromValue: values?.pre_start,
          timePicker: true,
          hideOnDateChanging: false,
          bothDateRequired: true,
          showSeconds: true,
          required: true,
          hide: values?.widget !== WidgetType.AUTOREPORTS,
          unused: values?.widget !== WidgetType.AUTOREPORTS,
        },
        {
          name: `reporting_period`,
          id: `${form}_reporting_period`,
          type: InputType.SELECT,
          label: t(`reports.reportingPeriod`),
          translateKeys: true,
          required: true,
          data: autoReportPeriods,
          hide: values?.widget !== WidgetType.AUTOREPORTS,
          unused: values?.widget !== WidgetType.AUTOREPORTS,
        },
      ];
    case ReportType.LABEL_HISTORY_REPORT:
      return [
        {
          name: `wallet_type`,
          id: `${form}_wallet_type`,
          type: InputType.SELECT,
          label: t(`wallets.walletType`),
          data: [...transactionsPSList, ...testPaymentSystems],
          required: true,
        },
        {
          name: `label`,
          label: t(`common.label`),
        },
        {
          name: `identifier`,
          id: `${form}_wallet_identifier`,
          type: InputType.SELECT,
          itemComponent: WalletNumber,
          data: parseWalletList(walletsSelection, {
            displayId: true,
            useIdentifierAsValue: true,
            withStatus: true,
            emptyText: t(`common.all`),
          }),
          placeholder: t(`common.all`),
          label: t(`wallets.wallet`),
          hide: !walletType,
          unused: !walletType,
        },
        {
          name: `date_range`,
          fromValue: get(values, `date_range.values.startDate`),
          toValue: get(values, `date_range.values.endDate`),
          minDate: moment().subtract(30, `days`),
          type: InputType.DATE_RANGE,
          required: true,
          label: t(`common.period`),
          timePicker: true,
          hideOnDateChanging: false,
          bothDateRequired: true,
          showSeconds: true,
        },
        {
          name: `file_format`,
          data: reportsFileFormatsList,
          type: InputType.SELECT,
          label: t(`reports.reportFormat`),
        },
      ];
    case ReportType.WITHDRAWAL_REQUEST_REPORT:
      return [
        {
          name: `wallet_type`,
          id: `${form}_wallet_type`,
          type: InputType.SELECT,
          label: t(`wallets.walletType`),
          data: withdrawalsPSListSelection,
          placeholder: t(`common.all`),
        },
        {
          name: `date_range`,
          fromValue: get(values, `date_range.values.startDate`),
          toValue: get(values, `date_range.values.endDate`),
          type: InputType.DATE_RANGE,
          required: true,
          label: t(`common.periodCreation`),
          timePicker: true,
          hideOnDateChanging: false,
          bothDateRequired: true,
          showSeconds: true,
        },
        {
          name: `update_date_range`,
          fromValue: get(values, `update_date_range.values.startDate`),
          toValue: get(values, `update_date_range.values.endDate`),
          type: InputType.DATE_RANGE,
          label: t(`common.periodUpdating`),
          timePicker: true,
          hideOnDateChanging: false,
          bothDateRequired: true,
          showSeconds: true,
        },
        {
          name: `currency_code`,
          id: `${form}_currency`,
          type: InputType.SELECT,
          label: t(`common.currency`),
          data: currenciesSelectionData,
        },
        {
          name: `status`,
          id: `${form}_status`,
          type: InputType.SELECT,
          label: t(`reports.requestStatus`),
          data: withdrawalStatusSelectionList,
        },
        {
          name: `postback_status`,
          id: `${form}_postback_status`,
          type: InputType.SELECT,
          label: t(`reports.postbackStatus`),
          data: [
            {
              text: t(`common.all`),
              value: undefined,
            },
            {
              text: t(`postbacks.sent`),
              value: PostbackStatus.POSTBACK_OK,
            },
            {
              text: t(`postbacks.notSend`),
              value: PostbackStatus.POSTBACK_NOT_SEND,
            },
          ],
          disabled: shouldPostbackStatusBeFixed,
        },
        {
          name: `label`,
          label: t(`common.label`),
        },
        {
          name: `file_format`,
          data: reportsFileFormatsList,
          type: InputType.SELECT,
          label: t(`reports.reportFormat`),
        },
      ];
    case ReportType.COMMISSION_REPORT:
      return [
        {
          name: `date_range`,
          fromValue: get(values, `date_range.values.startDate`),
          toValue: get(values, `date_range.values.endDate`),
          type: InputType.DATE_RANGE,
          required: true,
          label: t(`common.period`),
          timePicker: true,
          hideOnDateChanging: false,
          bothDateRequired: true,
          showSeconds: true,
        },
        {
          name: `file_format`,
          data: reportsFileFormatsList,
          type: InputType.SELECT,
          label: t(`reports.reportFormat`),
        },
      ];
    case ReportType.CLIENT_ACCOUNT_BALANCE_CHANGE:
      return [
        // TODO, WARNING
        // Код закомментирован в рамках задачи. М.б. он еще понадобится.
        // https://jira.fixmost.com/browse/PAY-9645

        // По идеее из-за отсутсвия результата работа хука useList<IAccountBalancesItems>
        // Нет возможности получить данные для accountBalancesListSelection

        // {
        //   name: `wallet_type`,
        //   label: t(`balances.accountType`),
        //   type: InputType.SELECT,
        //   data: accountBalancesListSelection,
        //   required: true,
        // },
        // {
        //   name: `currency_code`,
        //   type: InputType.SELECT,
        //   label: t(`common.currency`),
        //   data: currencies,
        //   required: true,
        //   hide: !walletType,
        //   unused: !walletType,
        // },
        {
          name: `date_range`,
          fromValue: get(values, `date_range.values.startDate`),
          toValue: get(values, `date_range.values.endDate`),
          type: InputType.DATE_RANGE,
          required: true,
          label: t(`common.period`),
          timePicker: true,
          hideOnDateChanging: false,
          bothDateRequired: true,
          showSeconds: true,
        },
        {
          name: `operation_type`,
          type: InputType.SELECT,
          label: t(`reports.operationType`),
          placeholder: t(`common.all`),
          data: [
            {
              value: null,
              text: t(`common.all`),
            },
            {
              value: ReportOperationType.TRANSACTION_ACTIVATION,
              text: t(`reports.transactionActivation`),
            },
            {
              value: ReportOperationType.TRANSACTION_ARCHIVING,
              text: t(`reports.transactionArchiving`),
            },
            {
              value: ReportOperationType.TRANSFER_BETWEEN_ACCOUNTS,
              text: t(`reports.transferBetweenAccounts`),
            },
            {
              value: ReportOperationType.TRANSFER_BETWEEN_ACCOUNT_AND_SETTLEMENT,
              text: t(`reports.transferBetweenAccountAndSettlement`),
            },
          ],
          required: false,
        },
        {
          name: `direction`,
          type: InputType.SELECT,
          label: t(`reports.direction`),
          required: false,
          placeholder: t(`common.all`),
          data: [
            {
              value: null,
              text: t(`common.all`),
            },
            {
              value: ReportDirection.REPLENISHMENT,
              text: t(`reports.replenishment`),
            },
            {
              value: ReportDirection.WRITE_OFF,
              text: t(`reports.writeOff`),
            },
          ],
        },
        {
          name: `file_format`,
          type: InputType.SELECT,
          required: true,
          label: t(`reports.reportFormat`),
          data: reportsFileFormatsList,
        },
      ];
    case ReportType.COMPLETED_TRANSACTION_SUM:
      return [
        {
          name: `filters.wallet_type`,
          id: `${form}_wallet_type`,
          type: InputType.SELECT,
          label: t(`wallets.walletType`),
          placeholder: t(`common.all`),
          data: [
            {
              value: null,
              text: t(`common.all`),
            },
            ...transactionsPSList,
            ...testPaymentSystems,
          ],
        },
        {
          name: `filters.wallet_id`,
          id: `${form}_wallet_identifier`,
          type: InputType.SELECT,
          data: parseWalletList(walletsSelection, {
            displayId: true,
            emptyText: t(`common.all`),
          }),
          label: t(`wallets.wallet`),
          placeholder: t(`common.all`),
          hide:
            !walletType ||
            ((transactionKind?.includes(TransactionKind.FORCED) || transactionKind?.includes(TransactionKind.DEBUG)) &&
              transactionKind?.length === 1) ||
            (transactionKind?.includes(TransactionKind.FORCED) &&
              transactionKind?.includes(TransactionKind.DEBUG) &&
              transactionKind?.length === 2),
          unused:
            !walletType ||
            ((transactionKind?.includes(TransactionKind.FORCED) || transactionKind?.includes(TransactionKind.DEBUG)) &&
              transactionKind?.length === 1) ||
            (transactionKind?.includes(TransactionKind.FORCED) &&
              transactionKind?.includes(TransactionKind.DEBUG) &&
              transactionKind?.length === 2),
        },
        {
          name: `report_currency_code`,
          id: `${form}_report_currency_id`,
          type: InputType.SELECT,
          label: t(`common.currency`),
          data: currenciesSelectionData,
          required: true,
        },
        {
          name: `date_range`,
          fromValue: get(values, `date_range.values.startDate`),
          toValue: get(values, `date_range.values.endDate`),
          type: InputType.DATE_RANGE,
          label: t(`common.period`),
          timePicker: true,
          hideOnDateChanging: false,
          bothDateRequired: true,
          showSeconds: true,
          required: true,
        },
        {
          name: `detail_by_day`,
          label: t(`reports.detailByDay`),
          type: InputType.CHECKBOX,
        },
        {
          name: `filters.direction`,
          id: `${form}_report_type`,
          data: [
            {
              value: null,
              text: `common.all`,
            },
            {
              value: TransactionDirection.INGOING,
              text: `transactions.ingoing`,
            },
            {
              value: TransactionDirection.OUTGOING,
              text: `transactions.outgoing`,
            },
          ],
          translateKeys: true,
          type: InputType.SELECT,
          label: t(`reports.direction`),
          placeholder: t(`common.all`),
        },
        {
          name: `filters.type`,
          id: `${form}_transaction_type`,
          // в data: value обернуты в toString не просто так. Либа для мультиселекта читает 0 как false и отрабатывает некоректно
          data: transactionKindItems,
          type: InputType.MULTI_SELECT,
          label: t(`transactions.transactionType`),
          translateKeys: true,
          placeholder: t(`common.all`),
        },
        {
          name: `filters.creation_type`,
          id: `${form}_transaction_creation_type`,
          data: [
            {
              value: null,
              text: `common.all`,
            },
            {
              value: TransactionCreationType.AUTO,
              text: `transactions.auto`,
            },
            {
              value: TransactionCreationType.MANUAL,
              text: `transactions.manual`,
            },
          ],
          type: InputType.SELECT,
          label: t(`transactions.kindOfTransaction`),
          translateKeys: true,
          placeholder: t(`common.all`),
        },
        {
          name: `filters.originality`,
          label: t(`transactions.originality`),
          type: InputType.SELECT,
          translateKeys: true,
          data: originalityOptions,
          placeholder: t(`common.all`),
        },
        {
          name: `filters.label`,
          label: t(`common.label`),
        },
        {
          name: `filters.exchanger_identifier`,
          label: t(`reports.counterparty`),
          hide: transactionKind?.length === 1 && transactionKind?.includes(TransactionKind.DEBUG),
          unused: transactionKind?.length === 1 && transactionKind?.includes(TransactionKind.DEBUG),
        },
        {
          name: `filters.postback_status`,
          id: `${form}_postback_status`,
          data: [
            {
              value: null,
              text: `common.all`,
            },
            {
              value: PostbackStatus.POSTBACK_OK,
              text: `reports.postbackSent`,
            },
            {
              value: PostbackStatus.POSTBACK_NOT_SEND,
              text: `reports.postbackError`,
            },
          ],
          type: InputType.SELECT,
          translateKeys: true,
          label: t(`reports.postbackStatus`),
          placeholder: t(`common.all`),
        },
        {
          name: `fields`,
          data: fieldList,
          fieldType: FieldType.CHECKBOX_GROUP,
          title: t(`reports.fields`),
          hide: !size(fieldList),
          unused: !size(fieldList),
        },
        {
          name: `file_format`,
          data: reportsFileFormatsList,
          type: InputType.SELECT,
          label: t(`reports.reportFormat`),
        },
        {
          name: `utc_0`,
          label: t(`reports.utc`),
          type: InputType.CHECKBOX,
        },
      ];
    case ReportType.LIST_REPORT_WITH_COMPLETED_STOCKPILING:
      return [
        {
          name: `filters.wallet_type`,
          id: `${form}_wallet_type`,
          type: InputType.SELECT,
          label: t(`wallets.walletType`),
          placeholder: t(`common.all`),
          data: [
            {
              value: null,
              text: t(`common.all`),
            },
            ...transactionsPSList,
            ...testPaymentSystems,
          ],
        },
        {
          name: `filters.wallet_id`,
          id: `${form}_wallet_identifier`,
          type: InputType.SELECT,
          data: parseWalletList(walletsSelection, {
            displayId: true,
            emptyText: t(`common.all`),
          }),
          label: t(`wallets.wallet`),
          placeholder: t(`common.all`),
          hide: !walletType,
          unused: !walletType,
        },
        {
          name: `stockpiling_date`,
          fromValue: get(values, `stockpiling_date.values.startDate`),
          toValue: get(values, `stockpiling_date.values.endDate`),
          type: InputType.DATE_RANGE,
          label: t(`reports.stockpilingEndPeriod`),
          timePicker: true,
          hideOnDateChanging: false,
          bothDateRequired: true,
          showSeconds: true,
          required: !get(values, `archiving_period.values`),
          hide: values?.widget === WidgetType.AUTOREPORTS,
          unused: values?.widget === WidgetType.AUTOREPORTS,
        },
        {
          name: `archiving_period`,
          fromValue: get(values, `archiving_period.values.startDate`),
          toValue: get(values, `archiving_period.values.endDate`),
          type: InputType.DATE_RANGE,
          label: t(`reports.archivingPeriod`),
          timePicker: true,
          hideOnDateChanging: false,
          bothDateRequired: true,
          showSeconds: true,
          required: !get(values, `stockpiling_date.values`),
          hide: values?.widget === WidgetType.AUTOREPORTS || archivingStatus === ArchivingStatus.WITHOUT_ARCHIVED,
          unused: values?.widget === WidgetType.AUTOREPORTS || archivingStatus === ArchivingStatus.WITHOUT_ARCHIVED,
        },
        {
          name: `filters.archiving_status`,
          id: `${form}_status`,
          data: [
            {
              text: `common.all`,
              value: ArchivingStatus.ALL,
            },
            {
              text: `transactions.archivedOnly`,
              value: ArchivingStatus.ONLY_ARCHIVED,
            },
            {
              text: `transactions.notArchivedOnly`,
              value: ArchivingStatus.WITHOUT_ARCHIVED,
            },
          ],
          type: InputType.SELECT,
          label: t(`transactions.archivingStatus`),
          translateKeys: true,
          placeholder: t(`common.all`),
          disabled: activationDate && type === ReportType.SUM_REPORT,
        },
        {
          name: `filters.direction`,
          id: `${form}_report_type`,
          data: [
            {
              value: null,
              text: `common.all`,
            },
            {
              value: TransactionDirection.INGOING,
              text: `transactions.ingoing`,
            },
            {
              value: TransactionDirection.OUTGOING,
              text: `transactions.outgoing`,
            },
          ],
          translateKeys: true,
          type: InputType.SELECT,
          label: t(`reports.direction`),
          placeholder: t(`common.all`),
        },
        {
          name: `filters.type`,
          id: `${form}_transaction_type`,
          // в data: value обернуты в toString не просто так. Либа для мультиселекта читает 0 как false и отрабатывает некоректно
          data: transactionKindItems,
          type: InputType.MULTI_SELECT,
          label: t(`transactions.transactionType`),
          translateKeys: true,
          placeholder: t(`common.all`),
        },
        {
          name: `filters.creation_type`,
          id: `${form}_transaction_creation_type`,
          data: [
            {
              value: null,
              text: `common.all`,
            },
            {
              value: TransactionCreationType.AUTO,
              text: `transactions.auto`,
            },
            {
              value: TransactionCreationType.MANUAL,
              text: `transactions.manual`,
            },
          ],
          type: InputType.SELECT,
          label: t(`transactions.kindOfTransaction`),
          translateKeys: true,
          placeholder: t(`common.all`),
        },
        {
          name: `filters.originality`,
          label: t(`transactions.originality`),
          type: InputType.SELECT,
          translateKeys: true,
          data: originalityOptions,
          placeholder: t(`common.all`),
        },
        {
          name: `filters.label`,
          label: t(`common.label`),
        },
        {
          name: `filters.exchanger_identifier`,
          label: t(`reports.counterparty`),
        },
        {
          name: `filters.postback_status`,
          id: `${form}_postback_status`,
          data: [
            {
              value: PostbackStatus.POSTBACK_OK,
              text: `reports.postbackSent`,
            },
            {
              value: PostbackStatus.POSTBACK_NOT_SEND,
              text: `reports.postbackError`,
            },
          ],
          type: InputType.SELECT,
          label: t(`transactions.postbackStatus`),
          translateKeys: true,
          placeholder: t(`common.all`),
        },
        {
          name: `fields`,
          data: fieldList,
          fieldType: FieldType.CHECKBOX_GROUP,
          title: t(`reports.fields`),
          hide: !size(fieldList),
          unused: !size(fieldList),
        },
        {
          name: `file_format`,
          data: reportsFileFormatsList,
          type: InputType.SELECT,
          label: t(`reports.reportFormat`),
        },
        {
          name: `utc_0`,
          label: t(`reports.utc`),
          type: InputType.CHECKBOX,
        },
      ];
    case ReportType.OPERATIONS_WITH_BALANCES: {
      return [
        {
          name: `payment_system`,
          id: `${form}_wallet_type`,
          type: InputType.SELECT,
          label: t(`wallets.walletType`),
          placeholder: t(`common.all`),
          data: [
            {
              value: null,
              text: t(`common.all`),
            },
            ...transactionsPSList,
            ...testPaymentSystems,
            ...transactionsPSListForBalances,
          ],
          required: true,
        },
        {
          name: `currency_code`,
          id: `${form}_currency`,
          type: InputType.SELECT,
          label: t(`common.currency`),
          placeholder: t(`common.all`),
          // data: [...currenciesSelectionData],
          data: [...currenciesByPSForBalances],
          required: true,
        },
        {
          name: `direction`,
          id: `${form}_report_type`,
          data: [
            {
              value: null,
              text: `common.all`,
            },
            {
              value: TransactionDirection.INGOING,
              text: `transactions.ingoing`,
            },
            {
              value: TransactionDirection.OUTGOING,
              text: `transactions.outgoing`,
            },
          ],
          translateKeys: true,
          type: InputType.SELECT,
          label: t(`reports.direction`),
          placeholder: t(`common.all`),
        },
        {
          name: `transaction_type`,
          id: `${form}_transaction_type`,
          data: [
            {
              value: null,
              text: `common.all`,
            },
            {
              value: 1,
              text: `reports.accountBalance`,
            },
            {
              value: 2,
              text: `reports.externalAccount`,
            },
          ],
          translateKeys: true,
          type: InputType.SELECT,
          label: t(`reports.operationType`),
          placeholder: t(`common.all`),
          hide: ![...transactionsPSList, ...testPaymentSystems, ...transactionsPSListForBalances].find(
            (el) => el.value == values?.payment_system
          )?.is_settlement,
        },
        {
          name: `filters.exchanger_identifier`,
          label: t(`reports.counterpartyWalletType`),
          type: InputType.SELECT,
          data: [
            {
              value: null,
              text: t(`common.all`),
            },
            ...transactionsPSList,
            ...testPaymentSystems,
          ],
          // hide: values?.widget === WidgetType.AUTOREPORTS || values.transaction_type !== 1,
          hide:
            values?.widget === WidgetType.AUTOREPORTS ||
            !values.payment_system ||
            (values.payment_system === `paykassma` && values.transaction_type !== 1),
        },
        {
          name: `external_address`,
          label: t(`reports.externalAccount`),
          type: InputType.TEXT,
          // placeholder: t(`reports.externalAccount`),
          hide: values?.widget === WidgetType.AUTOREPORTS || values.transaction_type !== 2,
        },
        {
          name: `period`,
          fromValue: get(values, `period.values.startDate`),
          toValue: get(values, `period.values.endDate`),
          type: InputType.DATE_RANGE,
          label: t(`common.period`),
          timePicker: true,
          hideOnDateChanging: false,
          bothDateRequired: true,
          showSeconds: true,
          required: true,
          hide: values?.widget === WidgetType.AUTOREPORTS,
        },
        {
          name: `file_format`,
          data: reportsFileFormatsList,
          type: InputType.SELECT,
          label: t(`reports.reportFormat`),
          required: true,
          hide: values?.widget === WidgetType.AUTOREPORTS,
        },
      ];
    }
    default:
      return [];
  }
};
