import map from 'lodash/map';
import { IAction, IWidgetsState } from '@kassma-team/kassma-toolkit/lib';

import { BALANCE_MIN_DATE_SUCCEEDED, TRANSFER_MONEY_SUCCEEDED } from 'actionTypes';

const actionTypes = new Set([TRANSFER_MONEY_SUCCEEDED, BALANCE_MIN_DATE_SUCCEEDED]);

const reducer = (state: IWidgetsState, { type, payload }: IAction) => {
  switch (type) {
    case TRANSFER_MONEY_SUCCEEDED:
      return {
        ...state,
        items: map(state.items, (item) => {
          if (item.payment_system === payload.writeOfPS) {
            return {
              ...item,
              balances: map(item.balances, (balance) =>
                balance.currency_code === payload.currency_code
                  ? { ...balance, amount: payload.writeOfPSAmount }
                  : balance
              ),
            };
          }
          if (item.payment_system === payload.recipientPS) {
            return {
              ...item,
              balances: map(item.balances, (balance) =>
                balance.currency_code === payload.currency_code
                  ? { ...balance, amount: payload.recipientPSAmount }
                  : balance
              ),
            };
          }

          return item;
        }),
      };
    case BALANCE_MIN_DATE_SUCCEEDED:
      return {
        ...state,
        minDate: payload,
      };
    default:
      return state;
  }
};

const accountsBalance = { reducer, actionTypes };

export default accountsBalance;
