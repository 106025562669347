import { useState, useEffect } from 'react';
import { useNotifications, Message } from '../useNotificationContext';

type UseMessages = {
  readedMessages: Array<Message>;
  isBtnShowed: boolean;
  isReadedShowed: boolean;
  getLastTwoReaded: () => Array<Message>;
  getAllReaded: () => Array<Message>;
  checkIsReadedMessages: () => boolean;
  toggleMessages: () => void;
};

// older -> first
const sortByDate = (a: Message, b: Message) => new Date(b.date).getTime() - new Date(a.date).getTime();

export function useMessages(): UseMessages {
  const {
    notificationsState: { readedMessages, unreadedMessages },
    handleSetMessagesOpen,
  } = useNotifications();

  const [isReadedShowed, setIsReadedShowed] = useState(false);
  const [isBtnShowed, setIsBtnShowed] = useState(unreadedMessages.length > 2);

  const getLastTwoReaded = () => unreadedMessages.slice(-2).sort(sortByDate);
  const getAllReaded = () => unreadedMessages.sort(sortByDate);
  const checkIsReadedMessages = () => readedMessages.length > 0;

  const toggleMessages = () => {
    setIsReadedShowed((prev) => !prev);
    setIsBtnShowed((prev) => !prev);
    handleSetMessagesOpen(true);
  };

  return {
    readedMessages,

    isBtnShowed,
    isReadedShowed,

    getLastTwoReaded,
    getAllReaded,
    checkIsReadedMessages,

    toggleMessages,
  };
}
