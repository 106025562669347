import React from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import { useFilterColumns } from '@kassma-team/kassma-toolkit/lib';

import { ChangeLogsColumn } from 'utils/enums';
import { formatDate } from 'utils/formatDate';
import { IChangeLogRowProps } from 'interfaces/widgets/logs';

import './changeLogRow.scss';

const ChangeLogRow = ({
  user,
  created_at,
  object_type,
  object_id,
  action,
  old_value,
  new_value,
  desktopSize,
  hiddenColumns,
}: IChangeLogRowProps) => {
  const [t] = useTranslation();

  const newValueField = map(new_value, (value, key) => (
    <div className="text-break-word" key={key}>
      {value}
    </div>
  ));

  const oldValueField = map(old_value, (value, key) => (
    <div className="text-break-word" key={key}>
      {value}
    </div>
  ));

  const formattedCreatedAt = formatDate(created_at);

  const columns = [
    {
      id: ChangeLogsColumn.USER,
      desktop: <td>{user}</td>,
      mobile: (
        <tr className="first-mobile-row">
          <td>{t(`changeLog.user`)}</td>
          <td>{user}</td>
        </tr>
      ),
    },
    {
      id: ChangeLogsColumn.DATE,
      desktop: <td>{formattedCreatedAt}</td>,
      mobile: (
        <tr>
          <td>{t(`common.date`)}</td>
          <td>{formattedCreatedAt}</td>
        </tr>
      ),
    },
    {
      id: ChangeLogsColumn.OBJECT_TYPE,
      desktop: <td>{object_type}</td>,
      mobile: (
        <tr>
          <td>{t(`changeLog.objectType`)}</td>
          <td>{object_type}</td>
        </tr>
      ),
    },
    {
      id: ChangeLogsColumn.OBJECT_ID,
      desktop: <td>{object_id}</td>,
      mobile: (
        <tr>
          <td>{t(`changeLog.objectId`)}</td>
          <td>{object_id}</td>
        </tr>
      ),
    },
    {
      id: ChangeLogsColumn.ACTION_TYPE,
      desktop: <td>{action}</td>,
      mobile: (
        <tr>
          <td>{t(`changeLog.actionType`)}</td>
          <td>{action}</td>
        </tr>
      ),
    },
    {
      id: ChangeLogsColumn.OLD_VALUE,
      desktop: <td className="change-log__max-width--row">{oldValueField}</td>,
      mobile: (
        <tr>
          <td>{t(`changeLog.oldValue`)}</td>
          <td>{oldValueField}</td>
        </tr>
      ),
    },
    {
      id: ChangeLogsColumn.NEW_VALUE,
      desktop: <td className="change-log__max-width--row">{newValueField}</td>,
      mobile: (
        <tr>
          <td>{t(`changeLog.newValue`)}</td>
          <td>{newValueField}</td>
        </tr>
      ),
    },
  ];

  return useFilterColumns({ columns, desktopSize, hiddenColumns });
};

export default ChangeLogRow;
