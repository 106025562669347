import React, { FC, useEffect } from 'react';

import { usePaymentSystems } from 'pages/constructor/DefaultTranslates/common/hooks/usePaymentSystems';
import { usePaymentSystemsCtx } from 'pages/constructor/DefaultTranslates/PackagesTranslation/ctx';

import PaymentSystemsList from './PaymentSystemsList';
import FormTypesList from './FormTypes';
import { TranslatesScreen } from './TranslatesScreen';

import './PackagesTranslationScreen.scss';

export const PackagesTranslationScreen: FC = () => {
  const { paymentSystems } = usePaymentSystems({ isSorted: true });

  const { currentPaymentSystem, handleSetPaymentSystem } = usePaymentSystemsCtx();

  useEffect(() => {
    // Необходимо спрятать кнопку Refresh. Из лейаута toolkit
    document.getElementById(`refresh_list_btn`)?.setAttribute(`hidden`, `true`);
  }, []);

  useEffect(() => {
    // По умолчанию должна быть выбрана 1 система.
    if (paymentSystems.length > 0 && !currentPaymentSystem) {
      handleSetPaymentSystem(paymentSystems[0]);
    }
  }, [paymentSystems, handleSetPaymentSystem, currentPaymentSystem]);

  return (
    <div className="PackagesTranslationScreen">
      <PaymentSystemsList />
      <FormTypesList />
      <TranslatesScreen />
    </div>
  );
};
