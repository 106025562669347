import { FormType } from 'api/translations/TranslationsApi';

/**
 * getFormTypeTitle
 *
 * Позволяет получить название формы для отображения
 */
export const getFormTypeTitle = (formType: FormType[`type`], formTypes: Array<FormType>): string | undefined => {
  return formTypes.find(({ type }) => type === formType)?.title;
};
