import React from 'react';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Dispatch } from 'redux';

import { createSettlementWriteOff, settlementActionCreators } from 'actions/widgets/balances/settlement';
import { WidgetType } from 'utils/enums';
import { CREATE_SETTLEMENT_FORM_NAME } from 'utils/constants';
import { ICreateSettlementForm } from 'interfaces/widgets/settlement';

import ModalForm from 'components/modals/ModalForm';
import { accountsBalanceHistoryActionCreators } from '../../../actions/widgets/balances/accountsBalanceHistory';

const onSubmit = (values: ICreateSettlementForm, dispatch: Dispatch) => {
  dispatch(createSettlementWriteOff(values));
};

const WriteOff = ({ ...props }) => {
  const [t] = useTranslation();

  const fields = [
    {
      name: `amount`,
      label: t(`common.amount`),
      standardAmount: true,
      required: true,
      description: `wallets.settlementWriteOffAmountHint`,
    },
    {
      name: `bitcoin_address`,
      label: t(`balances.writeOffAddress`),
      onlyLatinDigitsAndSymbols: true,
      maxLength: 90,
      required: true,
    },
  ];

  return (
    <ModalForm
      {...props}
      onSubmit={onSubmit}
      fields={fields}
      formName={CREATE_SETTLEMENT_FORM_NAME}
      submitText="wallets.writeOff"
      widget={WidgetType.ACCOUNT_BALANCE_HISTORY}
      actionCreators={accountsBalanceHistoryActionCreators}
      title="balances.withdrawalToExternalAccount"
    />
  );
};

export default reduxForm({ form: CREATE_SETTLEMENT_FORM_NAME })(WriteOff);
