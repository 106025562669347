import { takeEvery, call, put, select } from 'redux-saga/effects';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import omit from 'lodash/omit';
import size from 'lodash/size';
import trim from 'lodash/trim';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { toastr } from 'react-redux-toastr';
import { AxiosError, AxiosResponse } from 'axios';
import { validateForm, widgetListSelector, IWidgetsMeta, IAction } from '@kassma-team/kassma-toolkit/lib';

import { getErrorMessage } from 'utils';
import refreshSaga from 'sagas/effects/refreshSaga';
import {
  MARK_WALLET_AS_ACTIVE_FAILED,
  MARK_WALLET_AS_ACTIVE_REQUESTED,
  MARK_WALLET_AS_ACTIVE_SUCCEEDED,
  UPDATE_WALLET_COMMENT_REQUESTED,
  UPDATE_WALLET_SETTINGS,
  TRANSACTION_SEARCH,
  TOGGLE_VISIBILITY_OF_ALL_WALLETS_REQUESTED,
  TOGGLE_VISIBILITY_OF_ALL_WALLETS_SUCCEEDED,
  WALLET_SHOW_REQUESTED,
  WALLET_SHOW_SUCCEEDED,
  MANUAL_REQUISITES_REQUESTED,
} from 'actionTypes';
import executeWidgetActionMethod from 'actions/widgets/executeWidgetActionMethod';
import { WidgetType } from 'utils/enums';
import { disabledWalletActionCreators, walletActionCreators } from 'actions/widgets/wallet';
import i18n from 'i18n';
import { TRANSACTION_SEARCH_FORM_NAME, WALLET_COMMENT_FORM_NAME } from 'utils/constants';
import { paytmActionCreators } from 'actions/widgets/payments/paytm';
import {
  ITransactionSearch,
  IUpdateWalletCommentData,
  IWalletsWidgetStateItemsElement,
} from 'interfaces/widgets/wallets/wallet';
import walletApi from 'api/wallet/WalletApi';
import walletsApi from 'api/wallets/WalletsApi';
import paymentSystemsApi from '../../api/walletTypes/WalletTypes';
import { getSearch } from 'connected-react-router';
import queryString from 'query-string';
import api from 'api';
import { setWalletStatistics } from 'actions/widgets/wallets';

interface IMarkWalletAsActiveProps {
  payload: number;
  meta: IWidgetsMeta;
}

export function* markWalletAsActiveSaga({ payload, meta }: IMarkWalletAsActiveProps) {
  yield refreshSaga({
    request: () => walletApi.setWalletActiveRequest(payload),
    onSuccess: function* (resp: AxiosResponse) {
      const status: string = yield call(get, resp, `data.status`);
      if (!status || status === `fail`) {
        throw new Error();
      }
      yield put({ type: MARK_WALLET_AS_ACTIVE_SUCCEEDED, payload, meta });
      yield put(executeWidgetActionMethod(meta.widget, `hideModal`));
      if (meta.widget === WidgetType.DISABLED_WALLETS) {
        yield put(disabledWalletActionCreators.removeSync(payload));
      }
      yield toastr.success(i18n.t(`common.success`), meta.successToastrMessage || i18n.t(`wallets.walletIsWorkingNow`));
    },
    onError: function* (e: AxiosError) {
      yield put({ type: MARK_WALLET_AS_ACTIVE_FAILED, meta });
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

interface IUpdateCommentProps {
  payload: IUpdateWalletCommentData;
  meta: IWidgetsMeta;
}

function* updateCommentSaga({ payload, meta }: IUpdateCommentProps) {
  const valid: boolean = yield validateForm({ form: WALLET_COMMENT_FORM_NAME, meta });
  if (!valid) {
    return;
  }
  const widget = get(meta, `widget`);
  const id = get(payload, `id`);
  if (!isNumber(id) || !widget) {
    return;
  }

  const data: Omit<IUpdateWalletCommentData, `id`> = omit(payload, `id`);
  if (data.comment) {
    //@ts-ignore
    data.comment = data.comment.value;
  }

  yield refreshSaga({
    request: () => walletApi.updateWalletSettings(id, data),
    onSuccess: function* () {
      yield toastr.success(i18n.t(`common.success`), i18n.t(`wallets.commentHasBeenSuccessfullyUpdated`));
      const actionCreators =
        widget === WidgetType.DISABLED_WALLETS ? disabledWalletActionCreators : walletActionCreators;
      //@ts-ignore
      let search = yield select(getSearch);
      search = queryString.parse(search);
      yield put(actionCreators.getList(search));
      yield put(actionCreators.hideModal());
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

interface IUpdateWalletSettingsProps {
  payload: IUpdateWalletCommentData;
  meta: IWidgetsMeta;
}

function* updateWalletSettingsSaga({ payload, meta }: IUpdateWalletSettingsProps) {
  const widget = get(meta, `widget`);
  const id = get(payload, `id`);
  if (!isNumber(id) || !widget) {
    return;
  }
  const data: Omit<IUpdateWalletCommentData, `id`> = omit(payload, `id`);

  yield refreshSaga({
    request: () => walletApi.updateWalletSettings(id, data),
    onSuccess: function* () {
      yield toastr.success(i18n.t(`common.success`), i18n.t(`wallets.walletSettingsHaveBeenSuccessfullyUpdated`), {
        // eslint-disable-next-line
        // @ts-ignore
        preventDuplicates: false,
      });
    },
    onError: function (e: AxiosError) {
      //TODO: КОСТЫЛЬ
      toastr.error(i18n.t(`common.error`), getErrorMessage(e, { normalize: true, onlyValue: true }), {
        // eslint-disable-next-line
        // @ts-ignore
        preventDuplicates: false,
      });
      if (meta.onError) meta.onError();
    },
    onFinally: meta.onFinally,
  });
}

interface ITransactionSearchProps {
  payload: ITransactionSearch;
  meta: IWidgetsMeta;
}

function* transactionSearchSaga({ payload, meta }: ITransactionSearchProps) {
  const valid: boolean = yield validateForm({ form: TRANSACTION_SEARCH_FORM_NAME, meta });
  if (!valid) {
    return;
  }
  const id = get(payload, `id`);
  const data: Omit<ITransactionSearch, `id`> = omit(payload, `id`);

  yield refreshSaga({
    request: () => walletApi.transactionSearch(id, data),
    onSuccess: function* () {
      yield toastr.success(i18n.t(`common.success`), i18n.t(`wallets.parsingHasBeenLaunched`));
      yield put(paytmActionCreators.hideModal());
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

interface IToggleVisibilityOfAllWalletsProps {
  payload: boolean;
  meta: IWidgetsMeta;
}

function* fetchWalletsStatistic() {
  yield refreshSaga({
    request: api.walletsApi.getStatistics,
    onSuccess: function* (resp: AxiosResponse) {
      yield put(setWalletStatistics(resp.data.data));
    },
  });
}

function* toggleVisibilityOfAllWalletsSaga({ payload: active, meta }: IToggleVisibilityOfAllWalletsProps) {
  const wallets: IWalletsWidgetStateItemsElement[] = yield select(widgetListSelector(WidgetType.WALLETS));
  const walletIds = map(wallets, ({ id }) => id);

  yield refreshSaga({
    request: () => walletApi.toggleVisibilityOfAllWallets({ ids: walletIds, active }),
    onSuccess: function* (resp: AxiosResponse) {
      const succeededText = active
        ? `wallets.visibilityOfWalletsHaveBeenEnabled`
        : `wallets.visibilityOfWalletsHaveBeenDisabled`;
      yield toastr.success(i18n.t(`common.success`), i18n.t(succeededText));
      const changedIds = get(resp, `data.ids`);
      if (!isEmpty(changedIds)) {
        yield put({ type: TOGGLE_VISIBILITY_OF_ALL_WALLETS_SUCCEEDED, payload: { changedIds, active }, meta });
      }
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

interface IGetWalletForShowProps {
  payload: Record<`id`, number>;
  meta: IWidgetsMeta;
}

function* getWalletForShow({ payload, meta }: IGetWalletForShowProps) {
  const id = payload.id;
  yield refreshSaga({
    request: () => walletsApi.getWallet(id),
    onSuccess: function* (resp: AxiosResponse) {
      yield put({ type: WALLET_SHOW_SUCCEEDED, payload: resp.data.data, meta });
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

function* getManualPSFields({ payload }: IAction) {
  yield refreshSaga({
    request: () => paymentSystemsApi.getRequisites(payload),
    onSuccess: function* (resp: AxiosResponse) {
      const data = resp.data.data.items;
      yield put(walletActionCreators.setAnyData({ manualFields: data }));
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

const walletSagas = [
  takeEvery<IAction>(MARK_WALLET_AS_ACTIVE_REQUESTED, markWalletAsActiveSaga),
  takeEvery<IAction>(UPDATE_WALLET_COMMENT_REQUESTED, updateCommentSaga),
  takeEvery<IAction>(UPDATE_WALLET_SETTINGS, updateWalletSettingsSaga),
  takeEvery<IAction>(TRANSACTION_SEARCH, transactionSearchSaga),
  takeEvery<IAction>(TOGGLE_VISIBILITY_OF_ALL_WALLETS_REQUESTED, toggleVisibilityOfAllWalletsSaga),
  takeEvery<IAction>(WALLET_SHOW_REQUESTED, getWalletForShow),
  takeEvery<IAction>(MANUAL_REQUISITES_REQUESTED, getManualPSFields),
  takeEvery<IAction>(`WALLET_STATISTIC_REQUESTED`, fetchWalletsStatistic),
];

export default walletSagas;
