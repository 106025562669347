import React, { useEffect, useState } from 'react';

import SettingsBlockWrap from 'components/widgets/settings/SettingsBlockWrap';
import TariffFieldContainer from 'components/widgets/tariffs/tariffsConstructor/TariffFieldContainer';
import { ILocalCommissions } from 'interfaces/widgets/constructor/tariffConstructor';

import './tariffSettings.scss';

interface ITariffSettings {
  data: ILocalCommissions[];
}

const TariffSettings = ({ data }: ITariffSettings): JSX.Element => {
  const [commissionsParsedState, setCommissionsParsedState] = useState<Record<string, ILocalCommissions[]>>({});

  useEffect(() => {
    const parsedData = data?.reduce(
      (result: Record<string, ILocalCommissions[]>, item) => {
        if (item.direction === `ingoing`) {
          result = { ...result, ingoing: [...result?.ingoing, item] };
        } else if (item.direction === `outgoing` && item.type !== `settlement`) {
          result = { ...result, outgoing: [...result?.outgoing, item] };
        } else if (item.type === `settlement`) {
          result = { ...result, settlementType: [...result?.settlementType, item] };
        }

        return result;
      },
      { ingoing: [], outgoing: [], settlementType: [] }
    );

    setCommissionsParsedState(parsedData);
  }, [data]);

  return (
    <div className="tariff_row__form">
      <SettingsBlockWrap title="paymentConstructor.incomingTransactionCommission">
        <TariffFieldContainer fieldsData={commissionsParsedState?.ingoing} direction="ingoing" />
      </SettingsBlockWrap>
      <SettingsBlockWrap title="paymentConstructor.outgoingTransactionCommission">
        <TariffFieldContainer fieldsData={commissionsParsedState?.outgoing} direction="outgoing" />
      </SettingsBlockWrap>

      <SettingsBlockWrap>
        <TariffFieldContainer
          fieldsData={commissionsParsedState?.settlementType}
          direction="outgoing"
          commissionType="settlement"
        />
      </SettingsBlockWrap>
    </div>
  );
};

export default TariffSettings;
