import React, { FC } from 'react';

type Props = {
  onClick: () => void;
  variant: `apply` | `remove` | `edit` | `arrow`;

  className?: string;

  children?: never;
};

export const IconButton: FC<Props> = ({ onClick, variant, className }) => {
  return (
    <button type="button" className={className} onClick={onClick}>
      {variant === `apply` && (
        <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19.7737 3.17189C20.2421 3.64023 20.2421 4.39957 19.7737 4.86791L8.57447 16.0672C8.10612 16.5355 7.34679 16.5355 6.87844 16.0672L0.476259 9.66501C0.00791378 9.19666 0.00791375 8.43732 0.476259 7.96898L2.38429 6.06095C2.85263 5.5926 3.61197 5.5926 4.08032 6.06095L7.51445 9.49508C7.63154 9.61217 7.82137 9.61217 7.93846 9.49508L16.1697 1.26386C16.638 0.795511 17.3974 0.795511 17.8657 1.26386L19.7737 3.17189Z"
            fill="#9CCC65"
          />
        </svg>
      )}
      {variant === `remove` && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.3534 8.62022C11.239 8.50582 11.239 8.32035 11.3534 8.20596L14.7818 4.77751C15.2394 4.31993 15.2394 3.57804 14.7818 3.12046L12.9176 1.25627C12.46 0.798691 11.7182 0.79869 11.2606 1.25627L7.83213 4.68472C7.71774 4.79912 7.53226 4.79912 7.41787 4.68472L3.98942 1.25627C3.53184 0.79869 2.78995 0.798691 2.33237 1.25627L0.468187 3.12046C0.0106044 3.57804 0.0106045 4.31992 0.468187 4.77751L3.89663 8.20596C4.01103 8.32035 4.01103 8.50582 3.89663 8.62022L0.468187 12.0487C0.0106045 12.5062 0.0106044 13.2481 0.468187 13.7057L2.33237 15.5699C2.78995 16.0275 3.53184 16.0275 3.98942 15.5699L7.41787 12.1415C7.53226 12.0271 7.71774 12.0271 7.83213 12.1415L11.2606 15.5699C11.7182 16.0275 12.46 16.0275 12.9176 15.5699L14.7818 13.7057C15.2394 13.2481 15.2394 12.5062 14.7818 12.0487L11.3534 8.62022Z"
            fill="#EF5350"
          />
        </svg>
      )}
      {variant === `edit` && (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.1846 4.44072L14.744 5.88135C14.5971 6.02822 14.3596 6.02822 14.2127 5.88135L10.744 2.4126C10.5971 2.26572 10.5971 2.02822 10.744 1.88135L12.1846 0.440723C12.769 -0.143652 13.719 -0.143652 14.3065 0.440723L16.1846 2.31885C16.7721 2.90322 16.7721 3.85322 16.1846 4.44072ZM9.50649 3.11885L1.30024 11.3251L0.637736 15.122C0.547111 15.6345 0.993986 16.0782 1.50649 15.9907L5.30336 15.3251L13.5096 7.11885C13.6565 6.97197 13.6565 6.73447 13.5096 6.5876L10.0409 3.11885C9.89086 2.97197 9.65336 2.97197 9.50649 3.11885ZM4.50336 10.622C4.33149 10.4501 4.33149 10.1751 4.50336 10.0032L9.31586 5.19072C9.48774 5.01885 9.76274 5.01885 9.93461 5.19072C10.1065 5.3626 10.1065 5.6376 9.93461 5.80947L5.12211 10.622C4.95024 10.7938 4.67524 10.7938 4.50336 10.622ZM3.37524 13.2501H4.87524V14.3845L2.85961 14.7376L1.88774 13.7657L2.24086 11.7501H3.37524V13.2501Z"
            fill="black"
          />
        </svg>
      )}
      {variant === `arrow` && (
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.13086 6.96729L7.06539 1.03275L12.9999 6.96729" stroke="#3D3D3B" />
        </svg>
      )}
    </button>
  );
};
