import React, { FC, ChangeEvent } from 'react';
import { Lang } from 'api/translations/TranslationsApi';
import { SelectLang } from '../SelectLang';
import { IconButton } from '../IconButton';
import './Field.scss';
import { useTranslation } from 'react-i18next';
import { TranslateField } from '../TranslateField';

type Props = {
  lang: string;
  text: string;
  onRemove: () => void;
  onChangeLang: (option: { value: string; text: string } | string) => void;
  langs: Array<Lang>;
  selectedLangs: Array<Lang>;
  onChangeText: (e: ChangeEvent<HTMLInputElement>) => void;
  isRemoveBtnShowed?: boolean;
  isFormSubmitted?: boolean;

  useTranslateWithEffect?: boolean;
  isDisabled?: boolean;

  useInput?: boolean;

  children?: never;
};

export const Field: FC<Props> = (props) => {
  const {
    lang,
    langs,
    text,
    isRemoveBtnShowed = true,
    onRemove,
    onChangeLang,
    onChangeText,
    selectedLangs,
    isFormSubmitted,
    isDisabled,
  } = props;

  const [t] = useTranslation();

  const getTextError = (text: string) => {
    const isEmptyError = !text.length;
    const isMaxSymbolsError = text.length > 300;

    if (isEmptyError && isFormSubmitted) return <p className="Field__message">{t(`errors.requiredField`)}</p>;
    if (isMaxSymbolsError && isFormSubmitted)
      return <p className="Field__message">{t(`errors.maxFieldSize`, { len: 300 })}</p>;
  };

  return (
    <div className="Field">
      <SelectLang
        availableLangs={langs}
        lang={lang}
        error={
          !lang && isFormSubmitted && <p className="Field__message Field__message--lang">{t(`errors.requiredField`)}</p>
        }
        selectedLangs={selectedLangs}
        onChangeLang={onChangeLang}
        isDisabled={isDisabled}
      />

      <TranslateField
        error={getTextError(text)}
        fieldProps={{ type: `text`, onChange: onChangeText, value: text, variant: `standard`, disabled: isDisabled }}
      />

      {isRemoveBtnShowed && !isDisabled && <IconButton variant="remove" onClick={onRemove} />}
    </div>
  );
};
