import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputType, widgetSelector, IRootState, IWidgetsState } from '@kassma-team/kassma-toolkit';
import { Permission, WidgetType, FilterWalletStatus } from 'utils/enums';
import { PSWithoutSettlementsSelector } from 'selectors/widgets/paymentSystems';
import { selectionOfAllManualPSSelector } from 'selectors/widgets/manualPS';
import { walletAdminStatusActionCreators } from 'actions/widgets/walletAdminStatus';
import { hasAccessSelector } from 'selectors/auth';

const useWalletFilterFields = (formName: string, type: string) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const walletTypes = useSelector(PSWithoutSettlementsSelector);
  const safeWalletTypes = walletTypes.map((el) => ({
    ...el,
    text: el.name,
    value: el.code,
  }));
  const testPayments = useSelector(selectionOfAllManualPSSelector);
  const safeTestPayments = testPayments.map((el) => ({
    ...el,
    text: el.name,
    value: el.code,
  }));
  const walletAdminStatusesList: Record<string, string | number>[] =
    useSelector<IRootState, IWidgetsState>(widgetSelector(WidgetType.WALLET_ADMIN_STATUS))?.items || [];

  const stateValues = walletAdminStatusesList.map(({ text, code }) => ({ text, value: code }));

  const canViewEmailAccount = useSelector(hasAccessSelector(Permission.WALLET_NUMBER));

  useEffect(() => {
    dispatch(walletAdminStatusActionCreators.getList(type));
  }, []);

  return useMemo(
    () => [
      type !== FilterWalletStatus.REMOVED && {
        name: `id`,
        placeholder: t(`common.ID`),
      },
      type !== FilterWalletStatus.REMOVED && {
        name: `type`,
        id: `${formName}_wallet_type`,
        data: [{ value: null, text: t(`common.all`) }, ...safeWalletTypes, ...safeTestPayments],
        type: InputType.SELECT,
        placeholder: t(`wallets.walletType`),
      },
      type !== FilterWalletStatus.REMOVED && {
        name: `identifier`,
        placeholder: t(`wallets.identifier`),
      },
      type !== FilterWalletStatus.REMOVED && {
        name: `email_username`,
        placeholder: t(`wallets.email`),
        hasAccess: canViewEmailAccount,
      },
      type !== FilterWalletStatus.REMOVED && {
        name: `username`,
        placeholder: t(`wallets.siteLogin`),
        hasAccess: canViewEmailAccount,
      },
      {
        name: `display_identifier`,
        placeholder: t(`wallets.identifierRequisites`),
      },
      type !== FilterWalletStatus.REMOVED && {
        name: `state`,
        id: `${formName}_state`,
        data: [{ value: null, text: t(`common.all`) }, ...stateValues],
        type: InputType.SELECT,
        placeholder: t(`common.status`),
        translateKeys: true,
      },
    ],
    [walletTypes, testPayments, walletAdminStatusesList, canViewEmailAccount]
  );
};

export default useWalletFilterFields;
