import React, { FC, useContext, createContext } from 'react';
import { useCreationMode } from '../hooks/useCreationMode';

type CreationModeCtx = ReturnType<typeof useCreationMode>;

const creationModeCtx = createContext<CreationModeCtx | undefined>(undefined);

export const CreationModeCtx: FC = ({ children }) => {
  const {
    checkedFields,
    checkedFormType,
    isCreationMode,
    packageName,
    setCheckedFields,
    setCheckedFormType,
    setIsCreationMode,
    setPackageName,
    creationPackage,
    setCreationPackage,
    reset,
  } = useCreationMode();

  const val: CreationModeCtx = {
    creationPackage,
    setCreationPackage,
    checkedFields,
    checkedFormType,
    isCreationMode,
    packageName,
    setCheckedFields,
    setCheckedFormType,
    setIsCreationMode,
    setPackageName,
    reset,
  };

  return <creationModeCtx.Provider value={val}>{children}</creationModeCtx.Provider>;
};

export function useCreationModeCtx(): CreationModeCtx {
  const context = useContext(creationModeCtx);

  if (context === undefined) {
    throw new Error(`Is need to use useCreationMode inside a UseCreationModeCtx component`);
  }

  return context;
}
