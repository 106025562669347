import React, { useEffect } from 'react';
import { reduxForm, getFormValues, change } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { widgetSelector, GridType, getSubmitHandler, InputType, IRootState } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType, Permission, ChangeLogsColumn } from 'utils/enums';
import {
  changeLogsActionCreators,
  changeLogObjectsActionCreators,
  changeLogActionsActionCreators,
  changeLogUsersActionCreators,
} from 'actions/widgets/changeLogs';
import withPermissionCheck from 'hocs/withPermissionCheck';
import { changeLogObjectsSelectionSelector } from 'selectors/widgets/changeLogObject';
import { changeLogActionsSelectionSelector } from 'selectors/widgets/changeLogAction';
import { IChangeLogRowProps } from 'interfaces/widgets/logs';
import { IFilterProps } from 'interfaces/widgets/widgets';

import Filters from 'components/widgets/filters/Filters';
import ChangeLogRow from 'components/widgets/changeLogs/ChangeLogRow';
import Widget from 'components/widgets/Widget';
import { changeLogUsersSelectionSelector } from '../selectors/widgets/changeLogUser';

const FORM_NAME = `change-logs-filters`;

const FilterForm = reduxForm<any, IFilterProps>({
  form: FORM_NAME,
  onSubmit: getSubmitHandler({
    dateFields: [{ name: `date_range`, fromName: `date_from`, toName: `date_to` }],
    dateFormat: `YYYY-MM-DD HH:mm`,
  }),
})(Filters);

const filterDateFields = [
  {
    from: `date_from`,
    to: `date_to`,
    name: `date_range`,
  },
];

const listMapping = (item: IChangeLogRowProps, key: number) => <ChangeLogRow {...item} key={item.uuid} />;

const titles = [
  {
    title: `changeLog.user`,
    id: ChangeLogsColumn.USER,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `common.date`,
    id: ChangeLogsColumn.DATE,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `changeLog.objectType`,
    id: ChangeLogsColumn.OBJECT_TYPE,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `changeLog.objectId`,
    id: ChangeLogsColumn.OBJECT_ID,
    mobile: true,
    desktop: true,
    disabled: true,
  },
  {
    title: `changeLog.actionType`,
    id: ChangeLogsColumn.ACTION_TYPE,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `changeLog.oldValue`,
    id: ChangeLogsColumn.OLD_VALUE,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `changeLog.newValue`,
    id: ChangeLogsColumn.NEW_VALUE,
    desktop: true,
    mobile: true,
    disabled: true,
  },
];

const ChangeLogs = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeLogObjectsActionCreators.getList());
    dispatch(changeLogUsersActionCreators.getList());
  }, []);

  const values = useSelector(getFormValues(FORM_NAME)) || {};

  const changeLogObjects = useSelector(changeLogObjectsSelectionSelector);
  const changeLogActions = useSelector(changeLogActionsSelectionSelector);
  const usersLogins = useSelector(changeLogUsersSelectionSelector);
  const { listLoading: changeLogObjectsLoading } = useSelector<IRootState, Record<`listLoading`, boolean>>(
    widgetSelector(WidgetType.CHANGE_LOG_OBJECTS)
  );
  const { listLoading: changeLogActionsLoading } = useSelector<IRootState, Record<`listLoading`, boolean>>(
    widgetSelector(WidgetType.CHANGE_LOG_ACTIONS)
  );
  const { listLoading: usersLoginsLoading } = useSelector<IRootState, Record<`listLoading`, boolean>>(
    widgetSelector(WidgetType.CHANGE_LOG_USERS)
  );

  const objectType = get(values, `object_type`);

  useEffect(() => {
    dispatch(changeLogActionsActionCreators.getList(objectType));
    dispatch(change(FORM_NAME, `action`, null));
  }, [objectType]);

  const leftFilterFields = [
    {
      name: `username`,
      data: [{ value: null, text: t(`common.all`) }, ...usersLogins],
      type: InputType.SELECT,
      busy: usersLoginsLoading,
      placeholder: t(`changeLog.user`),
    },
    {
      name: `object_type`,
      data: [{ value: null, text: t(`common.all`) }, ...changeLogObjects],
      type: InputType.SELECT,
      busy: changeLogObjectsLoading,
      placeholder: t(`changeLog.objectType`),
      translateKeys: true,
    },
    {
      name: `object_id`,
      placeholder: t(`changeLog.objectId`),
    },
    {
      name: `action`,
      data: [{ value: null, text: t(`common.all`) }, ...changeLogActions],
      type: InputType.SELECT,
      busy: changeLogActionsLoading,
      placeholder: t(`changeLog.actionType`),
    },
  ];

  const rightFilterFields = [
    {
      name: `date_range`,
      type: InputType.DATE_RANGE,
      timePicker: true,
    },
  ];

  return (
    <Widget
      widget={WidgetType.CHANGE_LOGS}
      filterFormName={FORM_NAME}
      actionCreators={changeLogsActionCreators}
      pageTitle="changeLog.changeLogs"
      headTitle="changeLog.changeLogs"
      tableTitle="changeLog.changeLogs"
      tableHeadTitles={titles}
      listMapping={listMapping}
      filterForm={
        <FilterForm
          leftFields={leftFilterFields}
          rightFields={rightFilterFields}
          formName={FORM_NAME}
          dateFields={filterDateFields}
          gridType={GridType.FLEX}
          numFields={[`user`]}
        />
      }
      withPagination
      statusBar
    />
  );
};

export default withPermissionCheck(ChangeLogs, [
  {
    permissions: [
      {
        permission: Permission.VIEW_CHANGE_LOG,
        opposite: true,
      },
    ],
    redirectUrl: `/no-permissions`,
  },
]);
