export const formatCrediting = (creditting: number): number => {
  const isComma = new RegExp(`,`, `g`);

  const parts = isComma.test(creditting.toString())
    ? creditting.toString().split(`,`)
    : creditting.toString().split(`.`);

  if (parts.length > 1) {
    if (parts[1].length > 2) {
      parts[1] = parts[1].substr(0, 2);
    }

    return +parts.join(`.`);
  }

  return creditting;
};
