// Proxies

export const PROXY_CHECK_REQUESTED = `PROXY_CHECK_REQUESTED`;
export const PROXY_CHECK_SUCCEEDED = `PROXY_CHECK_SUCCEEDED`;
export const PROXY_CHECK_FAILED = `PROXY_CHECK_FAILED`;

export const PROXY_INIT_CHECK_ALL_REQUESTED = `PROXY_INIT_CHECK_ALL_REQUESTED`;
export const PROXY_CHECK_ALL_FAILED = `PROXY_CHECK_ALL_FAILED`;
export const PROXY_CHECK_ALL_REQUESTED = `PROXY_CHECK_ALL_REQUESTED`;
export const PROXY_CHECK_ALL_SUCCEEDED = `PROXY_CHECK_ALL_SUCCEEDED`;
export const PROXY_CHECK_ALL_SET_LOADER = `PROXY_CHECK_ALL_SET_LOADER`;

export const INIT_CHECKING_MULTI_ADDING_PROXY_LIST = `INIT_CHECKING_MULTI_ADDING_PROXY_LIST`;
export const CHECK_MULTI_ADDING_PROXY_LIST_REQUESTED = `CHECK_MULTI_ADDING_PROXY_LIST_REQUESTED`;
export const CHECK_MULTI_ADDING_PROXY_LIST_SUCCEEDED = `CHECK_MULTI_ADDING_PROXY_LIST_SUCCEEDED`;
export const INIT_CHECKING_MULTI_ADDING_PROXY_ITEM = `INIT_CHECKING_MULTI_ADDING_PROXY_ITEM`;
export const CHECK_MULTI_ADDING_PROXY_ITEM_REQUESTED = `CHECK_MULTI_ADDING_PROXY_ITEM_REQUESTED`;
export const CHECK_MULTI_ADDING_PROXY_ITEM_SUCCEEDED = `CHECK_MULTI_ADDING_PROXY_ITEM_SUCCEEDED`;
export const SET_INVALID_MULTI_ADDING_PROXY_ROWS = `SET_INVALID_MULTI_ADDING_PROXY_ROWS`;
export const SET_VERIFIED_MULTI_ADDING_PROXIES = `SET_VERIFIED_MULTI_ADDING_PROXIES`;
export const DELETE_MULTI_ADDING_PROXY = `DELETE_MULTI_ADDING_PROXY`;
export const ADD_MULTI_ADDING_PROXIES_REQUESTED = `ADD_MULTI_ADDING_PROXIES_REQUESTED`;
export const ADD_MULTI_ADDING_PROXIES_SUCCEEDED = `ADD_MULTI_ADDING_PROXIES_SUCCEEDED`;
export const RESET_MULTI_ADDING_PROXIES_DATA = `RESET_MULTI_ADDING_PROXIES_DATA`;
export const UPLOAD_PROXIES_REQUESTED = `UPLOAD_PROXIES_REQUESTED`;
export const UPLOAD_PROXIES_SUCCEEDED = `UPLOAD_PROXIES_SUCCEEDED`;
export const RESET_UPLOADING_PROXIES_DATA = `RESET_UPLOADING_PROXIES_DATA`;
export const DOWNLOAD_PROXIES = `DOWNLOAD_PROXIES`;

// Wallets

export const MARK_WALLET_AS_ACTIVE_REQUESTED = `MARK_WALLET_AS_ACTIVE_REQUESTED`;
export const MARK_WALLET_AS_ACTIVE_SUCCEEDED = `MARK_WALLET_AS_ACTIVE_SUCCEEDED`;
export const MARK_WALLET_AS_ACTIVE_FAILED = `MARK_WALLET_AS_ACTIVE_FAILED`;

export const UPDATE_WALLET_COMMENT_REQUESTED = `UPDATE_WALLET_COMMENT_REQUESTED`;
export const UPDATE_WALLET_COMMENT_SUCCEEDED = `UPDATE_WALLET_COMMENT_SUCCEEDED`;

export const UPDATE_WALLET_SETTINGS = `UPDATE_WALLET_SETTINGS`;

export const TOGGLE_WALLET_PLUGIN_STATUS = `TOGGLE_WALLET_PLUGIN_STATUS`;
export const TOGGLE_WALLET_STANDARD_STATUS = `TOGGLE_WALLET_STANDARD_STATUS`;

export const TRANSACTION_SEARCH = `TRANSACTION_SEARCH`;

export const TOGGLE_VISIBILITY_OF_ALL_WALLETS_REQUESTED = `TOGGLE_VISIBILITY_OF_ALL_WALLETS_REQUESTED`;
export const TOGGLE_VISIBILITY_OF_ALL_WALLETS_SUCCEEDED = `TOGGLE_VISIBILITY_OF_ALL_WALLETS_SUCCEEDED`;

export const WALLET_SHOW_REQUESTED = `WALLET_SHOW_REQUESTED`;
export const WALLET_SHOW_SUCCEEDED = `WALLET_SHOW_SUCCEEDED`;

export const MANUAL_REQUISITES_REQUESTED = `MANUAL_REQUISITES_REQUESTED`;

// Transactions

export const DOWNLOAD_TRANSACTION_CSV_LIST_REQUESTED = `DOWNLOAD_TRANSACTION_CSV_LIST_REQUESTED`;
export const DOWNLOAD_TRANSACTION_CSV_LIST_SUCCEEDED = `DOWNLOAD_TRANSACTION_CSV_LIST_SUCCEEDED`;
export const DOWNLOAD_TRANSACTION_CSV_LIST_FAILED = `DOWNLOAD_TRANSACTION_CSV_LIST_FAILED`;

export const ACTIVATE_TRANSACTION_REQUESTED = `ACTIVATE_TRANSACTION_REQUESTED`;
export const ACTIVATE_TRANSACTION_SUCCEEDED = `ACTIVATE_TRANSACTION_SUCCEEDED`;

export const ARCHIVATE_TRANSACTION_REQUESTED = `ARCHIVATE_TRANSACTION_REQUESTED`;
export const ARCHIVATE_TRANSACTION_SUCCEEDED = `ARCHIVATE_TRANSACTION_SUCCEEDED`;

export const APPROVE_TRANSACTION_REQUESTED = `APPROVE_TRANSACTION_REQUESTED`;
export const APPROVE_TRANSACTION_SUCCEEDED = `APPROVE_TRANSACTION_SUCCEEDED`;

export const UPLOAD_TRANSACTIONS = `UPLOAD_TRANSACTIONS`;

export const POSSIBLE_TRANSACTION_APPROVE = `POSSIBLE_TRANSACTION_APPROVE`;

// Preliminary transactions

export const ACTIVATE_PRELIMINARY_TRANSACTION_REQUESTED = `ACTIVATE_PRELIMINARY_TRANSACTION_REQUESTED`;
export const ACTIVATE_PRELIMINARY_TRANSACTION_SUCCEEDED = `ACTIVATE_PRELIMINARY_TRANSACTION_SUCCEEDED`;

// Auth

export const CHANGE_PASSWORD_REQUESTED = `CHANGE_PASSWORD_REQUESTED`;

// Dashboard

export const DASHBOARD_DATA_REQUESTED = `DASHBOARD_DATA_REQUESTED`;
export const DASHBOARD_DATA_SUCCEEDED = `DASHBOARD_DATA_SUCCEEDED`;

export const DASHBOARD_CHART_DATA_REQUESTED = `DASHBOARD_CHART_DATA_REQUESTED`;
export const DASHBOARD_CHART_DATA_SUCCEEDED = `DASHBOARD_CHART_DATA_SUCCEEDED`;

export const SET_DASHBOARD_ASIDE_FILTERS_REQUESTED = `SET_DASHBOARD_ASIDE_FILTERS_REQUESTED`;
export const SET_DASHBOARD_ASIDE_FILTERS_SUCCEEDED = `SET_DASHBOARD_ASIDE_FILTERS_SUCCEEDED`;
export const SET_DASHBOARD_CHART_FILTERS_REQUESTED = `SET_DASHBOARD_CHART_FILTERS_REQUESTED`;
export const SET_DASHBOARD_CHART_FILTERS_SUCCEEDED = `SET_DASHBOARD_CHART_FILTERS_SUCCEEDED`;

export const CHANGE_DASHBOARD_ENTITY_TYPE = `CHANGE_DASHBOARD_ENTITY_TYPE`;

// Wallet paytm

export const WALLET_PAYTM_CODE_CONFIRMATION = `WALLET_PAYTM_CODE_CONFIRMATION`;
export const WALLET_PAYTM_RESETTING_REQUESTED = `WALLET_PAYTM_RESETTING_REQUESTED`;
export const WALLET_PAYTM_RECOVERING_REQUESTED = `WALLET_PAYTM_RECOVERING_REQUESTED`;

// Manual payment systems

export const DISABLE_MANUAL_PS_REQUESTED = `DISABLE_MANUAL_PS_REQUESTED`;
export const DISABLE_MANUAL_PS_SUCCEEDED = `DISABLE_MANUAL_PS_SUCCEEDED`;

// Settings

export const UPDATE_HELP_IMAGES_REQUESTED = `UPDATE_HELP_IMAGES_REQUESTED`;
export const UPDATE_HELP_IMAGES_SUCCEEDED = `UPDATE_HELP_IMAGES_SUCCEEDED`;

export const DELETE_HELP_IMAGES = `DELETE_HELP_IMAGES`;

export const UPDATE_CUSTOM_STYLE_REQUESTED = `UPDATE_CUSTOM_STYLE_REQUESTED`;

export const UPDATE_SETTINGS_REQUESTED = `UPDATE_PLUGIN_SETTINGS_REQUESTED`;
export const UPDATE_PRIVATE_SETTINGS_REQUIRED = `UPDATE_PRIVATE_SETTINGS_REQUIRED`;
export const UPDATE_PASSWORD_REQUESTED = `UPDATE_PASSWORD_REQUESTED`;

export const UPDATE_TIMEZONE = `UPDATE_TIMEZONE`;

export const UPDATE_PLUGIN_COLOR_SCHEME = `UPDATE_PLUGIN_COLOR_SCHEME`;

export const LOAD_SETTINGS_REQUESTED = `LOAD_SETTINGS_REQUESTED`;
export const LOAD_PLUGIN_SETTINGS_REQUESTED = `LOAD_PLUGIN_SETTINGS_REQUESTED`;

export const LOAD_PRIVATE_SETTINGS_REQUESTED = `LOAD_PRIVATe_SETTINGS_REQUESTED`;

export const LOAD_PAYTM_FLAG_REQUESTED = `LOAD_PAYTM_FLAG_REQUESTED`;

export const LOAD_SETTINGS_TIMEZONE = `LOAD_SETTINGS_TIMEZONE`;

export const LOAD_CURRENCIES_WITH_DEFAULT_SORTING_REQUESTED = `LOAD_CURRENCIES_WITH_DEFAULT_SORTING_REQUESTED`;
export const LOAD_PAYMENTS_BY_CURRENCY_REQUESTED = `LOAD_PAYMENTS_BY_CURRENCY_REQUESTED`;

export const SET_PAYMENTS_SORTING_REQUESTED = `SET_PAYMENTS_SORTING_REQUESTED`;
export const SET_PAYMENTS_SORTING_SUCCEEDED = `SET_PAYMENTS_SORTING_SUCCEEDED`;

export const CREATE_PAYMENTS_SORTING_REQUESTED = `CREATE_PAYMENTS_SORTING_REQUESTED`;
export const CREATE_PAYMENTS_SORTING_SUCCEEDED = `CREATE_PAYMENTS_SORTING_SUCCEEDED`;

export const SET_SORTING_CREATE_MODAL_DATA = `SET_SORTING_CREATE_MODAL_DATA`;
export const RESET_SORTING_CREATE_MODAL_DATA = `RESET_SORTING_CREATE_MODAL_DATA`;

export const SET_SORTING_UPDATE_MODAL_DATA = `SET_SORTING_UPDATE_MODAL_DATA`;
export const RESET_SORTING_UPDATE_MODAL_DATA = `RESET_SORTING_UPDATE_MODAL_DATA`;

// Reports

export const GENERATE_REPORT_REQUESTED = `GENERATE_REPORT_REQUESTED`;

// Payment constructors

export const PAYMENT_CURRENCIES_REQUESTED = `PAYMENT_CURRENCIES_REQUESTED`;
export const PAYMENT_CURRENCIES_SUCCEEDED = `PAYMENT_CURRENCIES_SUCCEEDED`;
export const UPDATE_PAYMENT_CURRENCIES_REQUESTED = `UPDATE_PAYMENT_CURRENCIES_REQUESTED`;
export const UPDATE_PAYMENT_CURRENCIES_SUCCEEDED = `UPDATE_PAYMENT_CURRENCIES_SUCCEEDED`;

export const DEPOSITS_REQUESTED = `DEPOSITS_REQUESTED`;
export const DEPOSITS_SUCCEEDED = `DEPOSITS_SUCCEEDED`;
export const UPDATE_DEPOSITS_REQUESTED = `UPDATE_DEPOSITS_REQUESTED`;
export const RESET_DEPOSIT = `RESET_DEPOSIT`;

export const PAYMENT_VIEW_DATA_REQUESTED = `PAYMENT_VIEW_DATA_REQUESTED`;
export const PAYMENT_VIEW_DATA_SUCCEEDED = `PAYMENT_VIEW_DATA_SUCCEEDED`;

export const RESET_PAYMENT_VIEW_DATA = `RESET_PAYMENT_VIEW_DATA`;
export const UPDATE_PAYMENT_VIEW_DATA_REQUESTED = `UPDATE_PAYMENT_VIEW_DATA_REQUESTED`;

export const TOGGLE_SHOW_ON_PLUGIN = `TOGGLE_SHOW_ON_PLUGIN`;
export const TOGGLE_STANDARD_PS = `TOGGLE_STANDARD_PS`;
export const TOGGLE_ENABLING_POSSIBLE_TRANSACTIONS = `TOGGLE_ENABLING_POSSIBLE_TRANSACTIONS`;
export const CHANGE_LIMIT_OF_GETTING_STANDARD_STATUS = `CHANGE_LIMIT_OF_GETTING_STANDARD_STATUS`;

export const UPDATE_PAYMENTS_PLUGIN_PARAMS_REQUESTED = `UPDATE_PAYMENTS_PLUGIN_PARAMS_REQUESTED`;
export const PAYMENT_WORKING_TIME_DATA_REQUESTED = `PAYMENT_WORKING_TIME_DATA_REQUESTED`;
export const PAYMENT_WORKING_TIME_DATA_SUCCEEDED = `PAYMENT_WORKING_TIME_DATA_SUCCEEDED`;
export const RESET_PAYMENT_WORKING_TIME_DATA = `RESET_PAYMENT_WORKING_TIME_DATA`;

export const LOAD_PAYMENT_PROXY_REQUESTED = `LOAD_PAYMENT_PROXY`;
export const GET_REQUISITES = `GET_REQUISITES`;

export const PAYMENT_TRANSLATIONS_DATA_REQUESTED = `PAYMENT_TRANSLATIONS_DATA_REQUESTED`;
export const PAYMENT_TRANSLATIONS_DATA_SUCCEEDED = `PAYMENT_TRANSLATIONS_DATA_SUCCEEDED`;

export const CHANGE_SETTINGS = `CHANGE_SETTINGS`;

// Postbacks

export const RESEND_POSTBACK_REQUESTED = `RESEND_POSTBACK_REQUESTED`;
export const RESEND_WITHDRAWALS_POSTBACK_REQUESTED = `RESEND_WITHDRAWALS_POSTBACK_REQUESTED`;

export const RESET_ATTEMPT_COUNTER = `RESET_ATTEMPT_COUNTER`;
export const MARK_AS_SENT = `MARK_AS_SENT`;

// Stockpiling

export const SEND_POSTBACK_REQUESTED = `SEND_POSTBACK_REQUESTED`;
export const SEND_POSTBACK_SUCCEEDED = `SEND_POSTBACK_SUCCEEDED`;

// Withdrawals
export const CREATE_WITHDRAWAL_REQUESTED = `CREATE_WITHDRAWAL_REQUESTED`;

export const REJECT_WITHDRAWAL_REQUESTED = `REJECT_WITHDRAWAL_REQUESTED`;
export const REJECT_WITHDRAWAL_SUCCEEDED = `REJECT_WITHDRAWAL_SUCCEEDED`;

export const APPROVE_WITHDRAWAL_REQUESTED = `APPROVE_WITHDRAWAL_REQUESTED`;
export const APPROVE_WITHDRAWAL_SUCCEEDED = `APPROVE_WITHDRAWAL_SUCCEEDED`;

export const CONFIRM_APPROVAL_OF_WITHDRAWAL_REQUESTED = `CONFIRM_APPROVAL_OF_WITHDRAWAL_REQUESTED`;
export const CONFIRM_APPROVAL_OF_WITHDRAWAL_SUCCEEDED = `CONFIRM_APPROVAL_OF_WITHDRAWAL_SUCCEEDED`;

export const CONFIRM_REJECTION_OF_WITHDRAWAL_REQUESTED = `CONFIRM_REJECTION_OF_WITHDRAWAL_REQUESTED`;
export const CONFIRM_REJECTION_OF_WITHDRAWAL_SUCCEEDED = `CONFIRM_REJECTION_OF_WITHDRAWAL_SUCCEEDED`;

export const UPLOAD_MANUAL_WITHDRAWALS_REQUESTED = `UPLOAD_MANUAL_WITHDRAWALS_REQUESTED`;
export const DOWNLOAD_MANUAL_WITHDRAWALS_REQUESTED = `DOWNLOAD_MANUAL_WITHDRAWALS_REQUESTED`;

export const UPDATE_WITHDRAWAL_COMMENT = `UPDATE_WITHDRAWAL_COMMENT`;

// Paytm cookies

export const UPLOAD_PAYTM_COOKIES_REQUESTED = `UPLOAD_PAYTM_COOKIES_REQUESTED`;
export const UPLOAD_PAYTM_COOKIES_SUCCEEDED = `UPLOAD_PAYTM_COOKIES_SUCCEEDED`;
export const RESET_PAYTM_COOKIES_UPLOADING = `RESET_PAYTM_COOKIES_UPLOADING`;
export const DELETE_PAYTM_COOKIES_REQUESTED = `DELETE_PAYTM_COOKIES_REQUESTED`;
export const DELETE_PAYTM_COOKIES_FAILED = `DELETE_PAYTM_COOKIES_FAILED`;
export const DELETE_PAYTM_COOKIES_SUCCEEDED = `DELETE_PAYTM_COOKIES_SUCCEEDED`;

export const FETCH_PAYTM_COOKIES = `FETCH_PAYTM_COOKIES`;

// Tariffs

export const TARIFFS_REQUESTED = `TARIFFS_REQUESTED`;
export const TARIFFS_SUCCEEDED = `TARIFFS_SUCCEEDED`;
export const UPDATE_TARIFFS_REQUESTED = `UPDATE_TARIFFS_REQUESTED`;
export const RESET_TARIFFS = `RESET_TARIFFS`;

// Payment hints

export const PAYMENT_HINT_DEFAULT_TRANSLATIONS_REQUESTED = `PAYMENT_HINT_DEFAULT_TRANSLATIONS_REQUESTED`;
export const PAYMENT_HINT_DEFAULT_TRANSLATIONS_SUCCEEDED = `PAYMENT_HINT_DEFAULT_TRANSLATIONS_SUCCEEDED`;
export const RESET_HINT_DEFAULT_TRANSLATIONS = `RESET_HINT_DEFAULT_TRANSLATIONS`;

// Settlement

export const UPDATE_TOTAL_SETTLEMENT_BALANCE = `UPDATE_TOTAL_SETTLEMENT_BALANCE`;

export const CREATE_SETTLEMENT_REPLENISHMENT_REQUESTED = `CREATE_SETTLEMENT_REPLENISHMENT_REQUESTED`;
export const CREATE_SETTLEMENT_WRITE_OFF_REQUESTED = `CREATE_SETTLEMENT_WRITE_OFF_REQUESTED`;
export const CREATE_SETTLEMENT_TRANSFER_REQUESTED = `CREATE_SETTLEMENT_TRANSFER_REQUESTED`;
export const SETTLEMENT_WITHDRAWAL_ACTIONS = `SETTLEMENT_WITHDRAWAL_ACTIONS`;
export const GET_SETTLEMENT_BALANCE = `GET_SETTLEMENT_BALANCE`;

// Settings

export const SET_APP_VERSION_SUCCEEDED = `SET_APP_VERSION_SUCCEEDED`;

//Account balance

export const TRANSFER_MONEY_REQUESTED = `TRANSFER_MONEY_REQUESTED`;
export const TRANSFER_MONEY_SUCCEEDED = `TRANSFER_MONEY_SUCCEEDED`;
export const TRANSFER_TO_ANOTHER_ACCOUNT_REQUESTED = `TRANSFER_TO_ANOTHER_ACCOUNT_REQUESTED`;
export const BALANCE_HISTORY_LIST_REQUESTED = `BALANCE_HISTORY_LIST_REQUESTED`;
export const BALANCE_MIN_DATE_SUCCEEDED = `BALANCE_MIN_DATE_SUCCEEDED`;

export const CREATE_PAYMENT_SYSTEM = `CREATE_PAYMENT_SYSTEM`;

export const UPDATE_WALLET_TYPE_SETTINGS = `UPDATE_WALLET_TYPE_SETTINGS`;

//Commissions

export const LOAD_SETTLEMENT_COMMISSION = `LOAD_SETTLEMENT_COMMISSION`;
