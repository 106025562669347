import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { TextInput } from '@kassma-team/kassma-toolkit';
import { useHintsCtx, usePaymentSystemsCtx } from '../../../ctx';
import { IconButton } from '../../../../common/components';
import translationsApi, { Hint } from 'api/translations/TranslationsApi';

import { useTranslation } from 'react-i18next';

import './CreateFormType.scss';
import Skeleton from 'react-loading-skeleton';
import refreshHelper from 'utils/refreshHelper';

type Props = {
  onRemove: () => void;

  children?: never;
};

export const CreateFormType: FC<Props> = (props) => {
  const { onRemove } = props;
  const [hintName, setHintName] = useState(``);
  const { currentPaymentSystem } = usePaymentSystemsCtx();
  const { handleCreateHint, fetchHints } = useHintsCtx();
  const [isLoading, setIsLoading] = useState(false);
  const [defaultHints, setDefaultHints] = useState<Record<string, Hint>>();

  useEffect(() => {
    if (currentPaymentSystem) {
      const fetcher = async () => {
        setIsLoading(true);

        await refreshHelper({
          request: () =>
            translationsApi.fetchHints({
              wallet_type: currentPaymentSystem?.code,
              is_default: true,
            }),
          onSuccess: ({ data }: any) => {
            if (data.data) {
              //@ts-ignore
              const nData = data.data.reduce((acc, curr) => {
                acc[curr.form_type] = curr;

                return acc;
              }, {} as Record<string, Hint>);

              setDefaultHints(nData);
            }
          },
          onFinally: () => {
            setIsLoading(false);
          },
        });
      };

      fetcher();
    }
  }, []);

  const [errors, setErrors] = useState({ hintName: false });

  const [t] = useTranslation();

  return (
    <div className="CreateFormType">
      <div className="CreateFormType__triangle" />

      {isLoading ? (
        <Skeleton count={2} />
      ) : (
        <>
          <div className="CreateFormType__field">
            <TextInput
              style={{ width: `100%` }}
              value={hintName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.value) {
                  setErrors({ hintName: false });
                }

                setHintName(e.target.value);
              }}
            />
            {errors.hintName && <p className="CreateFormType__error-message">{t(`errors.requiredField`)}</p>}
          </div>

          <div className="CreateFormType__controls">
            <div style={{ width: `21px`, height: `17px` }}>
              {defaultHints && Object.keys(defaultHints).length > 0 && (
                <IconButton
                  variant="apply"
                  onClick={() => {
                    if (!hintName) {
                      setErrors({ hintName: !hintName });

                      return;
                    }

                    if (defaultHints && currentPaymentSystem) {
                      const promises = Object.keys(defaultHints).map((key) => {
                        return handleCreateHint({
                          form_type: key,
                          is_manual: defaultHints[key].is_manual,
                          name: hintName,
                          wallet_type: currentPaymentSystem?.code,
                          translations: defaultHints[key].translations,
                        })().then(async () => {
                          await fetchHints();
                        });
                      });

                      Promise.allSettled(promises).then((result) => {
                        if (result.every((result) => result.status === `fulfilled`)) {
                          onRemove();
                        }
                      });
                    }
                  }}
                />
              )}
            </div>

            <IconButton variant="remove" onClick={onRemove} />
          </div>
        </>
      )}
    </div>
  );
};
