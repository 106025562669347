import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { Dispatch } from 'redux';
import { WidgetType } from 'utils/enums';
import { generateReport, reportsActionCreators } from 'actions/widgets/reports';
import { REPORT_GENERATING_FORM_NAME } from 'utils/constants';
import reportValidation from 'utils/validation/reportValidation';
import { IGenerateReportsForm } from 'interfaces/widgets/reports';

import ModalForm from 'components/modals/ModalForm';
import { useReports } from 'hooks/widgets/report/useReports';

const onSubmit = (values: IGenerateReportsForm, dispatch: Dispatch) => dispatch(generateReport(values));

const ReportGeneratingModal = (props: InjectedFormProps) => {
  const { filteredFields } = useReports(props, WidgetType.REPORTS);

  return (
    <ModalForm
      {...props}
      fields={filteredFields}
      submitText="reports.generateReport"
      onSubmit={onSubmit}
      title="reports.reportGenerating"
      actionCreators={reportsActionCreators}
      widget={WidgetType.REPORTS}
    />
  );
};

export default reduxForm({ form: REPORT_GENERATING_FORM_NAME, onSubmit, validate: reportValidation })(
  ReportGeneratingModal
);
