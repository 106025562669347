import React from 'react';
import { RemovingModal as KassmaRemovingModal, widgetModalSelector } from '@kassma-team/kassma-toolkit/lib';
import { useDispatch, useSelector } from 'react-redux';

import { IRemovingModalProps } from 'interfaces/modal';

const RemovingModal = ({ widget, ...props }: IRemovingModalProps) => {
  const modal = useSelector(widgetModalSelector(widget));

  const dispatch = useDispatch();

  return <KassmaRemovingModal {...props} modal={modal} dispatch={dispatch} />;
};

export default RemovingModal;
