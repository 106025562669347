import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import { IGenerateReportsForm } from 'interfaces/widgets/reports';

export class AutoReportApi extends BaseApi {
  createAutoReports = (data: IGenerateReportsForm) => this.makeFetch(``, { method: `POST`, data });

  loadAutoReportsColumns = (): AxiosPromise => this.makeFetch(``);

  loadAutoReport = (id: number) => this.makeFetch(`{id}`, {}, { id });

  editAutoReports = (data: IGenerateReportsForm, id: number): AxiosPromise =>
    this.makeFetch(`{id}`, { method: `PUT`, data }, { id });

  deleteAutoReports = (id: any): AxiosPromise => this.makeFetch(`{id}`, { method: `DELETE` }, { id });
}

const autoReportApi = new AutoReportApi(`autoreport`);

export default autoReportApi;
