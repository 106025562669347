import React, { useCallback, useEffect, useMemo } from 'react';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import omit from 'lodash/omit';
import { widgetSubmittingSelector, FieldType, GridType, InputType } from '@kassma-team/kassma-toolkit/lib';
import { Dispatch } from 'redux';
import { useUpdateEffect } from 'react-use';

import { hasAccessSelector } from 'selectors/auth';
import { Permission, WidgetType } from 'utils/enums';
import { SYSTEM_SETTINGS } from 'utils/constants';
import { loadTimezone, updateSettings, updateTimezone } from 'actions/widgets/settings';
import { timezonesActionCreators } from 'actions/widgets/timezones';
import useList from 'hooks/widgets/useList';
import useArrayFields from 'hooks/widgets/useArrayFields';
import withPermissionCheck from 'hocs/withPermissionCheck';
import { ISettingsForm } from 'interfaces/widgets/settings';
import { ISystemSettingsForm } from 'interfaces/widgets/settings/systemSettings';
import { ITimezoneItems } from 'interfaces/widgets/timezone';
import { settingsInitSelector, timezoneSelector } from 'selectors/widgets/settings';

import SettingsForm from 'components/widgets/settings/SettingsForm';

import './systemSettingForm.scss';
import i18n from '../../../i18n';

const onSubmit = (values: ISystemSettingsForm, dispatch: Dispatch) => {
  const timezone = values.timezone;
  dispatch(updateTimezone({ timezone }));
  //TODO: Исследовать, почему этот параметр уходит как int
  values.del_possible_trans_after_days = values.del_possible_trans_after_days + ``;
  dispatch(updateSettings(SYSTEM_SETTINGS)(omit(values, [`timezone`])));
};

const SystemSettingsForm = (props: ISettingsForm & InjectedFormProps) => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const systemSettingsAccess = useSelector(hasAccessSelector(Permission.VIEW_SYSTEM_SETTINGS));

  const submitting = useSelector(widgetSubmittingSelector(WidgetType.SETTINGS));

  const currentTimezone = useSelector(timezoneSelector);
  const init = useSelector(settingsInitSelector);

  const urlsFields = useCallback(
    () => [
      {
        placeholder: t(`settings.id`),
        type: InputType.MUI_TEXT,
        name: `id`,
        wrapClassName: `settings__redirect-field--id mr-5`,
        disabled: true,
      },
      {
        placeholder: t(`settings.url`),
        type: InputType.MUI_TEXT,
        name: `url`,
        wrapClassName: `settings__redirect-field--url mr-5`,
        required: true,
        link: true,
        maxLength: 2048,
      },
    ],
    [t]
  );

  const renderSucceededUrls = useArrayFields({
    fields: urlsFields,
    title: `settings.succeededUrl`,
    name: `succeeded_urls`,
  });

  const renderPendingUrl = useArrayFields({
    name: `pending_urls`,
    title: `settings.pendingUrlTitles`,
    fields: () => [
      {
        placeholder: t(`settings.id`),
        type: InputType.MUI_TEXT,
        name: `id`,
        wrapClassName: `settings__redirect-field--id mr-5`,
        disabled: true,
      },
      {
        placeholder: t(`settings.url`),
        type: InputType.MUI_TEXT,
        name: `url`,
        wrapClassName: `settings__redirect-field--url mr-5`,
        required: true,
        link: true,
        maxLength: 2048,
      },
    ],
  });

  const renderFailedUrls = useArrayFields({
    fields: urlsFields,
    title: `settings.failedUrl`,
    name: `failed_urls`,
  });

  const upiPaymentMethodData = [
    {
      text: t(`settings.upi_id`),
      value: `UPI_ID`,
    },
    {
      text: t(`settings.qr`),
      value: `QR`,
      api: true,
    },
    {
      text: t(`settings.link`),
      value: `LINK`,
      api: true,
    },
  ];

  const values = useSelector(getFormValues(SYSTEM_SETTINGS));

  const upiPaymentMethodFields = useCallback(
    (isApi) => {
      const data = isApi ? upiPaymentMethodData.filter((item) => item.api) : upiPaymentMethodData;

      return [
        {
          placeholder: t(`settings.id`),
          type: InputType.MUI_TEXT,
          name: `order`,
          wrapClassName: `settings__paymentMethod--id mr-5`,
          disabled: true,
        },
        {
          placeholder: t(`settings.paymentMethod`),
          type: InputType.MUI_SELECT,
          data,
          name: `code`,
          wrapClassName: `settings__paymentMethod--select`,
          required: true,
        },
      ];
    },
    [t, values]
  );

  const renderUpiP2PFields = useArrayFields({
    fields: () => upiPaymentMethodFields(false),
    title: `settings.upiP2P`,
    name: `upi_p2p_payment_methods`,
    disableCallback: (fields) => fields.length == 3,
  });

  const renderUpiAPIFields = useArrayFields({
    fields: () => upiPaymentMethodFields(true),
    title: `settings.upiAPI`,
    name: `upi_api_payment_methods`,
    disableCallback: (fields) => fields.length == 2,
  });

  //@ts-ignore
  const upiP2PValues = values?.upi_p2p_payment_methods;
  useEffect(() => {
    if (!upiP2PValues || upiP2PValues.length === 0) {
      props.change(`upi_p2p_payment_methods`, [{}]);
    }
  }, [upiP2PValues]);

  //@ts-ignore
  const upiP2PCodes = upiP2PValues ? upiP2PValues.map((item) => item.code).filter((item) => !!item) : [];
  useEffect(() => {
    props.change(
      `upi_p2p_payment_methods`,
      upiP2PCodes.map((code: any, index: number) => ({
        order: index + 1,
        code,
      }))
    );
  }, [upiP2PCodes.length]);

  //@ts-ignore
  const upiAPIValues = values?.upi_api_payment_methods;
  useEffect(() => {
    if (!upiAPIValues || upiAPIValues.length === 0) {
      props.change(`upi_api_payment_methods`, [{}]);
    }
  }, [upiAPIValues]);

  //@ts-ignore
  const upiAPICodes = upiAPIValues ? upiAPIValues.map((item) => item.code).filter((item) => !!item) : [];
  useEffect(() => {
    props.change(
      `upi_api_payment_methods`,
      upiAPICodes.map((code: any, index: number) => ({
        order: index + 1,
        code,
      }))
    );
  }, [upiAPICodes.length]);

  const setTimezoneAccess = useSelector(hasAccessSelector(Permission.TIMEZONE_SET));
  const getTimezoneAccess = useSelector(hasAccessSelector(Permission.TIMEZONE_GET));

  const { items: timezoneList } = useList<ITimezoneItems>({
    widget: WidgetType.TIMEZONES,
    actionCreators: timezonesActionCreators,
    resetOnUnmount: false,
    loadOnlyIfNecessary: true,
  });

  const formattedTimezoneList = useMemo(() => {
    return map(timezoneList, ({ name, value }) => ({
      text: name,
      value,
    }));
  }, [timezoneList]);

  useEffect(() => {
    if (init && getTimezoneAccess) {
      dispatch(loadTimezone());
    }
  }, [init, getTimezoneAccess]);

  useUpdateEffect(() => {
    if (formattedTimezoneList) {
      props.change(`timezone`, currentTimezone);
    }
  }, [currentTimezone, formattedTimezoneList]);

  // const walletFields = [
  //   {
  //     title: t(`wallets.wallets`),
  //     gridType: GridType.GRID,
  //     fields: [
  //       {
  //         name: `use_sms_box`,
  //         type: InputType.MUI_SELECT,
  //         label: t(`settings.smsInputMethodForPayTM`),
  //         data: [
  //           {
  //             value: false,
  //             text: t(`settings.manualInput`),
  //           },
  //           {
  //             value: true,
  //             text: t(`settings.automaticInput`),
  //           },
  //         ],
  //         required: true,
  //       },
  //     ],
  //   },
  // ];

  const timezoneField = useMemo(
    () => [
      {
        title: t(`settings.timeProfile`),
        gridType: GridType.GRID,
        fields: [
          {
            name: `timezone`,
            type: InputType.MUI_SELECT,
            label: t(`settings.timezone`),
            data: formattedTimezoneList,
            required: true,
            description: `settings.timezoneHint`,
          },
        ],
      },
    ],
    [formattedTimezoneList]
  );

  return (
    <SettingsForm
      {...props}
      groupedFields={
        !systemSettingsAccess && setTimezoneAccess
          ? timezoneField
          : [
              {
                title: t(`settings.paymentMethodsDisplay`),
                fields: [
                  {
                    component: renderUpiP2PFields,
                    fieldType: FieldType.FIELD_ARRAY,
                    name: `upi_p2p_payment_methods`,
                  },
                  {
                    component: renderUpiAPIFields,
                    fieldType: FieldType.FIELD_ARRAY,
                    name: `upi_api_payment_methods`,
                  },
                ],
                gridType: GridType.GRID,
              },
              {
                title: t(`settings.plugin`),
                // gridType: GridType.GRID,
                fields: [
                  {
                    name: `display_report_problem`,
                    type: InputType.CHECKBOX,
                    label: t(`settings.reportAProblem`),
                    checkboxLabelClassName: `font-weight-bold`,
                  },
                ],
              },
              {
                title: t(`settings.deposit`),
                fields: [
                  {
                    name: `use_url_from_settings`,
                    type: InputType.CHECKBOX,
                    label: t(`settings.useRedirectURLFromSettings`),
                    checkboxLabelClassName: `font-weight-bold`,
                  },
                ],
                gridType: GridType.GRID,
              },
              {
                fields: [
                  {
                    component: renderSucceededUrls,
                    fieldType: FieldType.FIELD_ARRAY,
                    name: `succeeded_urls`,
                  },
                  {
                    component: renderPendingUrl,
                    fieldType: FieldType.FIELD_ARRAY,
                    name: `pending_urls`,
                  },
                  {
                    component: renderFailedUrls,
                    fieldType: FieldType.FIELD_ARRAY,
                    name: `failed_urls`,
                  },
                  {
                    name: `transaction_reactivation_timer`,
                    type: InputType.MUI_TEXT,
                    required: true,
                    numeric: true,
                    label: t(`settings.reactivationTimer`),
                    description: t(`settings.reactivationTimerHint`),
                  },
                  {
                    name: `transaction_recheck_timer`,
                    type: InputType.MUI_TEXT,
                    minAmount: 10,
                    numeric: true,
                    label: t(`settings.recheckTimer`),
                    description: t(`settings.recheckTimerHint`),
                  },
                  {
                    name: `del_possible_trans_after_days`,
                    type: InputType.MUI_TEXT,
                    label: t(`settings.deletePossibleTransactionAfterDays`),
                    numeric: true,
                    maxAmount: 1000,
                    description: `settings.deletePossibleTransactionAfterDaysDescription`,
                    required: true,
                  },
                ],
                gridType: GridType.GRID,
              },
              {
                title: t(`settings.withdrawalRequestsTitle`),
                fields: [
                  {
                    name: `restrict_withdrawal_requests`,
                    type: InputType.CHECKBOX,
                    label: t(`settings.withdrawalRequestsDescription`),
                    checkboxLabelClassName: `font-weight-bold`,
                  },
                ],
                gridType: GridType.GRID,
              },
              ...(setTimezoneAccess || getTimezoneAccess ? timezoneField : []),
            ]
      }
      submitText="common.update"
      submitting={submitting as boolean}
    />
  );
};

const validate = (values: any) => {
  const errors: Record<any, any> = {};

  const upiP2PCodes =
    values?.upi_p2p_payment_methods?.map((item: any) => item.code).filter((code: string) => !!code) || [];
  if (upiP2PCodes.length > 0 && upiP2PCodes.filter((code: string) => code !== `LINK`).length === 0) {
    errors._error = (errors._error || ``) + `UPI P2P: ` + i18n.t(`errors.upiPaymentDisplayError`) + `\n`;
  }
  if (new Set(upiP2PCodes).size !== upiP2PCodes.length) {
    errors._error = (errors._error || ``) + `UPI P2P: ` + i18n.t(`errors.upiPaymentSamePaymentError`) + `\n`;
  }

  const upiAPICodes =
    values?.upi_api_payment_methods?.map((item: any) => item.code).filter((code: string) => !!code) || [];
  if (upiAPICodes.length > 0 && upiAPICodes.filter((code: string) => code !== `LINK`).length === 0) {
    errors._error = (errors._error || ``) + `UPI API: ` + i18n.t(`errors.upiPaymentDisplayError`) + `\n`;
  }
  if (new Set(upiAPICodes).size !== upiAPICodes.length) {
    errors._error = (errors._error || ``) + `UPI API: ` + i18n.t(`errors.upiPaymentSamePaymentError`) + `\n`;
  }

  return errors;
};

const SystemSettingsReduxForm = reduxForm({
  form: SYSTEM_SETTINGS,
  destroyOnUnmount: false,
  onSubmit,
  validate,
  // shouldError: () => true,
})(SystemSettingsForm as any);

export default withPermissionCheck(SystemSettingsReduxForm, [
  {
    permissions: [
      {
        permission: Permission.VIEW_SYSTEM_SETTINGS,
        opposite: true,
      },
      {
        permission: Permission.TIMEZONE_SET,
        opposite: true,
      },
      {
        permission: Permission.TIMEZONE_GET,
        opposite: true,
      },
    ],
    redirectUrl: `/no-permissions`,
  },
]);
