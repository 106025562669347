import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import { IAction } from '@kassma-team/kassma-toolkit/lib/types/common';

import { WidgetType } from 'utils/enums';
import { disabledWalletActionCreators, walletActionCreators } from 'actions/widgets/wallet';

const actionCreatorByWidget = {
  [WidgetType.WALLETS]: walletActionCreators,
  [WidgetType.DISABLED_WALLETS]: disabledWalletActionCreators,
};

const executeWidgetActionMethod = (widget: string, method: string, ...params: any): IAction => {
  const func = get(actionCreatorByWidget, `[${widget}].${method}`);

  return isFunction(func) ? func(...params) : {};
};

export default executeWidgetActionMethod;
