import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import map from 'lodash/map';
import get from 'lodash/get';
import join from 'lodash/join';
import filter from 'lodash/filter';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@kassma-team/kassma-toolkit/lib';

import { ModalType } from 'utils/enums';
import { settingsActionCreators } from 'actions/widgets/settings';
import { IPaymentCurrencyRow } from 'interfaces/widgets/settings/pluginSettings';

const { showModal } = settingsActionCreators;

const PaymentCurrencyRow = ({ code, payments }: IPaymentCurrencyRow) => {
  const dispatch = useDispatch();

  const [t] = useTranslation();

  const paymentList = useMemo(
    () =>
      join(
        filter(
          map(payments, (data) => {
            const type = get(data, `type`);
            const name = get(data, `name`);

            return type === `test` ? name : type;
          }),
          (type) => type
        ),
        `, `
      ),
    [payments]
  );

  const actions = (
    <Dropdown
      insideContent={(onClose: () => void) => (
        <div>
          <button
            className="mb-20"
            onClick={() => {
              dispatch(showModal({ type: ModalType.UPDATE_PAYMENTS_SORTING, currency: code }));
              onClose();
            }}
          >
            <span className="text-no-wrap">
              <i className="fa fa-pencil mr-2" />
              {t(`common.edit`)}
            </span>
          </button>
          <button
            onClick={() => {
              dispatch(showModal({ type: ModalType.RESET_PAYMENTS_SORTING, currency: code }));
              onClose();
            }}
          >
            <span className="text-no-wrap">
              <i className="fa fa-trash mr-2" />
              {t(`settings.resetOrder`)}
            </span>
          </button>
        </div>
      )}
    >
      <i className="fa fa-ellipsis-v mr-20" />
    </Dropdown>
  );

  return (
    <tr>
      <td>{code}</td>
      <td>{paymentList}</td>
      <td className="text-right">{actions}</td>
    </tr>
  );
};

export default PaymentCurrencyRow;
