import React from 'react';
import { reduxForm } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';
import { getSubmitHandler, InputType } from '@kassma-team/kassma-toolkit/lib';

import { withdrawalsLogsActionCreators } from 'actions/widgets/walletsDebugging/withdrawalsLogs';
import { ModalType, Permission, WidgetType } from 'utils/enums';
import useUserHasAccess from 'hooks/useUserHasAccess';
import walletsDebuggingRouteTabs from 'pages/walletsDebugging/walletsDebuggingRouteTabs';
import { withdrawalsPSSelector } from 'selectors/widgets/paymentSystems';
import { withdrawalPSActionCreators } from 'actions/widgets/paymentSystem';
import useWithdrawalsLogsTableTitles from 'hooks/widgets/tableTitles/useWithdrawalsLogsTableTitles';
import { IWithdrawalsLogRowProps } from 'interfaces/widgets/withdrawals';
import { IFilterProps } from 'interfaces/widgets/widgets';

import Filters from 'components/widgets/filters/Filters';
import WithdrawalsLogRow from 'components/widgets/walletsDebugging/withdrawalsLogs/WithdrawalsLogRow';
import WithdrawalLogMessage from 'components/widgets/walletsDebugging/withdrawalsLogs/WithdrawalLogMessage';
import Widget from 'components/widgets/Widget';

import './logs.scss';
import withPermissionCheck from '../../../hocs/withPermissionCheck';

const FORM_NAME = `withdrawals-logs-filters`;

const FilterForm = reduxForm<any, IFilterProps>({
  form: FORM_NAME,
  onSubmit: getSubmitHandler({
    dateFields: [{ name: `date_range`, fromName: `date_from`, toName: `date_to` }],
  }),
})(Filters);

const listMapping = (item: IWithdrawalsLogRowProps, key: number) => <WithdrawalsLogRow key={key} {...item} />;

const rightFilterFields = [
  {
    name: `date_range`,
    type: InputType.DATE_RANGE,
  },
];

const modals = [
  {
    type: ModalType.LOG_MESSAGE,
    widget: WidgetType.WITHDRAWALS_LOGS,
    component: WithdrawalLogMessage,
  },
];

const filterDateFields = [
  {
    from: `date_from`,
    to: `date_to`,
    name: `date_range`,
  },
];

const WithdrawalsLogs = () => {
  const tableTitles = useWithdrawalsLogsTableTitles();

  useUserHasAccess();

  const dispatch = useDispatch();
  const [t] = useTranslation();

  useMount(() => {
    dispatch(withdrawalPSActionCreators.getList());
  });

  const withdrawalsPaymentSystems = useSelector(withdrawalsPSSelector);

  const safeWithdrawalsPaymentSystems = withdrawalsPaymentSystems.map((el) => ({
    ...el,
    value: el.code,
    text: el.name,
  }));

  const leftFields = [
    {
      name: `withdrawal_id`,
      placeholder: t(`withdrawals.withdrawalId`),
      numeric: true,
    },
    {
      name: `wallet_type`,
      data: [{ value: null, text: t(`common.all`) }, ...safeWithdrawalsPaymentSystems],
      type: InputType.SELECT,
      placeholder: t(`wallets.walletType`),
    },
  ];

  return (
    <Widget
      actionCreators={withdrawalsLogsActionCreators}
      widget={WidgetType.WITHDRAWALS_LOGS}
      filterFormName={FORM_NAME}
      pageTitle="logs.walletsDebugging"
      headTitle="logs.withdrawalsLogs"
      routeTabs={walletsDebuggingRouteTabs}
      tableTitle="logs.withdrawalsLogs"
      tableHeadTitles={tableTitles}
      listMapping={listMapping}
      filterForm={
        <FilterForm
          leftFields={leftFields}
          rightFields={rightFilterFields}
          formName={FORM_NAME}
          dateFields={filterDateFields}
        />
      }
      modals={modals}
      withPagination
      statusBar
    />
  );
};

export default withPermissionCheck(WithdrawalsLogs, [
  {
    permissions: [
      {
        permission: Permission.MANAGE_PAYMENTS_CONSTRUCTOR,
        opposite: true,
      },
    ],
    redirectUrl: `/no-permissions`,
  },
]);
