import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import {
  IPreliminaryTransaction,
  IPreliminaryTransactionFiltersForm,
} from 'interfaces/widgets/transaction/preliminaryTransaction';

interface IData {
  possible_transaction_primary_id: number;
}

export class PretransactionApi extends BaseApi {
  activatePreliminaryTransaction = (id: number, data: IData): AxiosPromise =>
    this.makeFetch(`{id}/activate`, { method: `POST`, data }, { id });

  createPreliminaryTransaction = (data: IPreliminaryTransaction): AxiosPromise =>
    this.makeFetch(`new`, { data, method: `POST` });

  deletePreliminaryTransaction = (id: number): AxiosPromise =>
    this.makeFetch(`delete/{id}`, { method: `DELETE` }, { id });

  loadPreliminaryTransactions = (queryParams: IPreliminaryTransactionFiltersForm): AxiosPromise =>
    this.makeFetch(`list`, { queryParams });
}

const pretransactionApi = new PretransactionApi(`pretransaction`);

export default pretransactionApi;
