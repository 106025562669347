import {
  createWidgetAction,
  widgetActionCreators,
  IWidgetActionCreators,
  IWidgetsAction,
} from '@kassma-team/kassma-toolkit/lib';
import { WidgetType } from 'utils/enums';
import { SEND_POSTBACK_REQUESTED, SEND_POSTBACK_SUCCEEDED } from 'actionTypes';

export const stockpilingListActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.STOCKPILING_LIST,
  labels: { listLabel: `stockpiling_list` },
  withPagination: true,
});

export const sendPostback: IWidgetsAction = createWidgetAction({
  widget: WidgetType.STOCKPILING_LIST,
})(SEND_POSTBACK_REQUESTED);

export const sendPostbackSucceded: IWidgetsAction = createWidgetAction({
  widget: WidgetType.STOCKPILING_LIST,
})(SEND_POSTBACK_SUCCEEDED);
