import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import split from 'lodash/split';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { WalletImg, useFilterColumns, Tooltip } from '@kassma-team/kassma-toolkit/lib';
import { approveTransaction } from 'actions/widgets/transactions/transactions';

import useFullAmount from 'hooks/useFullAmount';
import {
  ModalType,
  Permission,
  TransactionKind,
  TransactionStatus,
  PostbackStatus,
  TransactionsColumn,
  WidgetType,
} from 'utils/enums';
import { walletActionCreators } from 'actions/widgets/wallet';
import { hasAccessSelector } from 'selectors/auth';
import { postbackStatusToIcon, transactionParamsByStatus } from 'static/transactions';
import transactionType from 'utils/widgets/transactions/transactionType';
import { formatDate } from 'utils/formatDate';
import { ITransactionsRowProps } from 'interfaces/widgets/transaction/transaction';
import { paymentSystemLogoSelector } from '../../../selectors/widgets/paymentSystems';
import './TransactionRow.css';
import transactionApi from '../../../api/transactions/TransactionApi';

export const Direction = {
  OUTGOING: `outgoing`,
  INGOING: `ingoing`,
};

const TransactionRow = ({
  id,
  wallet_type,
  wallet_identifier,
  amount,
  currency_code,
  status,
  direction,
  created_at,
  activated_at,
  removed_at,
  desktopSize,
  wallet_id,
  exchanger_identifier,
  transaction_label,
  label,
  merchant_order_id,
  type,
  creation_type,
  test_wallet_type,
  postback_status,
  postback_id,
  postback_message,
  primary_id,
  actionCreators,
  hiddenColumns,
  total_commission,
  is_fake,
  widget,
  wallet_balance,
}: ITransactionsRowProps) => {
  const dispatch = useDispatch();

  const [t] = useTranslation();

  const fullAmount = useFullAmount({ amount, currencyCode: currency_code });

  const commissionAmount = useFullAmount({
    amount: total_commission as number,
    currencyCode: currency_code,
  });

  const walletNumberAccess = useSelector(hasAccessSelector(Permission.WALLET_NUMBER));
  const commissionViewAccess = useSelector(hasAccessSelector(Permission.COMMISSION_VIEW));
  const transactionListAccess = useSelector(hasAccessSelector(Permission.TRANSACTION_LIST));
  const typeDisplayingAccess = useSelector(hasAccessSelector(Permission.DISPLAY_TRANSACTION_TYPE));
  const postbacksAccess = useSelector(hasAccessSelector(Permission.POSTBACKS));
  const createDebugTransactionAccess = useSelector(hasAccessSelector(Permission.CREATE_DEBUG_TRANSACTION));
  const createForcedTransactionAccess = useSelector(hasAccessSelector(Permission.ACTIVATE_TRANSACTION));
  const archivedTransactionAccess = useSelector(hasAccessSelector(Permission.ARCHIVE_TRANSACTION_MANAGEMENT));
  const nonActivatedFakeDelete = useSelector(hasAccessSelector(Permission.NON_ACTIVATED_FAKE_DELETE));
  const showWalletFilter = useSelector(hasAccessSelector(Permission.WALLET_LIST));
  const hasAccessToBalances = useSelector(hasAccessSelector(Permission.WALLET_BALANCE));
  const displayTransactionTypePermission = useSelector(hasAccessSelector(Permission.DISPLAY_TRANSACTION_TYPE));

  const onActivateClick = useCallback(() => {
    dispatch(
      //@ts-ignore @ts-expect-error
      actionCreators.showModal({
        type: ModalType.TRANSACTION_ACTIVATION,
        primary_id,
        creation_type,
        label,
        merchant_order_id,
        transactionType: type,
      })
    );
  }, [primary_id, actionCreators, creation_type]);

  const onApproveClick = () => {
    dispatch(
      //@ts-ignore @ts-expect-error
      actionCreators.showModal({
        type: ModalType.TRANSACTION_APPROVE,
        primary_id,
        id,
      })
    );
  };

  const onArchivateClick = useCallback(() => {
    dispatch(
      //@ts-ignore @ts-expect-error
      actionCreators.showModal({
        type: ModalType.TRANSACTION_ARCHIVATION,
        primary_id,
      })
    );
  }, [primary_id, actionCreators]);

  const onFakeClick = useCallback(() => {
    dispatch(
      approveTransaction({
        transactions: [primary_id || 0],
        is_fake: true,
        widget,
        onError: (e) => {
          console.error(e.message);
        },
      })
    );
  }, [primary_id, actionCreators]);

  const directionColors = {
    'text-danger': direction === Direction.OUTGOING,
    'text-success': direction === Direction.INGOING,
  };

  const [displayCreatedAt] = split(formatDate(created_at));
  const [displayActivatedAt] = split(formatDate(activated_at));
  const [displayRemovedAt] = split(formatDate(removed_at));

  const walletIdentifier = showWalletFilter ? (
    <button
      className="button-link"
      onClick={() =>
        dispatch(
          walletActionCreators.showModal({
            id: wallet_id,
            type: ModalType.SHOW,
          })
        )
      }
    >
      {wallet_identifier}
    </button>
  ) : (
    <div>{wallet_identifier}</div>
  );

  const logo = useSelector(paymentSystemLogoSelector(wallet_type));
  const logoPath = wallet_type === `test` ? get(test_wallet_type, `logo`) : logo;
  const walletTypeImage = (
    <WalletImg title={wallet_type === `test` ? get(test_wallet_type, `name`) : wallet_type} src={logoPath} />
  );

  const transactionSum = <strong>{direction === Direction.INGOING ? `+ ${fullAmount}` : `- ${fullAmount}`}</strong>;

  const labelBadge = (transaction_label || label) && (
    <span className="badge badge-success">{transaction_label || label}</span>
  );
  const orderIdLabel = <span className="badge badge-info">{merchant_order_id}</span>;

  const warningIcon = (
    <Tooltip insideContent={t(`transactions.scam`)}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.45558 0.872955C8.82 -0.290852 7.18335 -0.291019 6.54754 0.872658L0.214181 12.4641C-0.406525 13.6001 0.396088 15 1.66812 15H14.3319C15.6038 15 16.4064 13.6004 15.786 12.4644L9.45558 0.872955Z"
          fill="#FFE600"
        />
        <path
          d="M9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11ZM7.33333 8V4.66667C7.33333 4.29848 7.63181 4 8 4C8.36819 4 8.66667 4.29848 8.66667 4.66667V8C8.66667 8.36819 8.36819 8.66667 8 8.66667C7.63181 8.66667 7.33333 8.36819 7.33333 8Z"
          fill="black"
        />
      </svg>
    </Tooltip>
  );

  const statusField = (
    <div className="multi-badge-cell transaction-status">
      {is_fake ? warningIcon : null}
      <span className={`badge ${get(transactionParamsByStatus, `[${status}].color`)}`}>
        {t(get(transactionParamsByStatus, `[${status}].message`))}
      </span>
    </div>
  );

  const creationTypeField = (
    <div className="multi-badge-cell">
      <span className={`badge ${get(transactionType, `${creation_type}.badge`)}`}>
        {t(get(transactionType, `${creation_type}.text`))}
      </span>
    </div>
  );

  const postbackField = (
    <td>
      {/* eslint-disable-next-line max-len */}
      {postback_message && !(direction === Direction.OUTGOING && postback_status === PostbackStatus.POSTBACK_NOT_SEND) && (
        <button
          onClick={() => {
            if (
              postback_status === PostbackStatus.POSTBACK_NOT_SEND ||
              postback_status === PostbackStatus.POSTBACK_STOCKPILING_NOT_FOUND
            ) {
              return dispatch(
                //@ts-ignore @ts-expect-error

                actionCreators.showModal({
                  type: ModalType.INFO,
                  content: postback_message,
                })
              );
            }

            if (postback_status === PostbackStatus.POSTBACK_ERROR) {
              return dispatch(
                //@ts-ignore @ts-expect-error
                actionCreators.showModal({
                  type: ModalType.NOT_ACCEPTED_TRANSACTION_POSTBACKS,
                  id: postback_id,
                  direction,
                })
              );
            }

            return dispatch(
              //@ts-ignore @ts-expect-error
              actionCreators.showModal({
                type: ModalType.SHOW,
                id: postback_id,
                direction,
              })
            );
          }}
        >
          {postbackStatusToIcon[postback_status as number] || ``}
        </button>
      )}
    </td>
  );

  let transactionKindField;
  switch (type) {
    case TransactionKind.REAL: {
      if (status === TransactionStatus.NOT_ACTIVATED && direction === Direction.OUTGOING) {
        transactionKindField = <span className="badge badge-warning">{t(`transactions.inner`)}</span>;
      } else {
        transactionKindField = <span className="badge badge-success">{t(`transactions.real`)}</span>;
      }
      break;
    }

    case TransactionKind.DEBUG: {
      transactionKindField = <span className="badge badge-warning">{t(`transactions.fake`)}</span>;
      break;
    }

    case TransactionKind.FORCED: {
      transactionKindField = <span className="badge badge-primary">{t(`transactions.forced`)}</span>;
      break;
    }

    case TransactionKind.WITHDRAWAL: {
      transactionKindField = <span className="badge badge-warning">{t(`withdrawals.withdrawal`)}</span>;
      break;
    }
  }

  transactionKindField = <td className="multi-badge-cell">{transactionKindField}</td>;

  const showActivationBtn =
    status === TransactionStatus.NOT_ACTIVATED &&
    createForcedTransactionAccess &&
    direction === Direction.INGOING &&
    !is_fake;

  const hasApprovePermission = useSelector(hasAccessSelector(Permission.SUSPECTS_TRANSACTIONS_APPROVE));
  const hasDeletePermission = useSelector(hasAccessSelector(Permission.ACTIVATED_FAKE_DELETE));
  const hasSetFakePermission = useSelector(hasAccessSelector(Permission.TRANSACTION_SET_FAKE));

  const shouldArchiveButtonBeDisplayed = useMemo(() => {
    if (is_fake && activated_at) return hasDeletePermission;
    if (is_fake && !activated_at) return nonActivatedFakeDelete;

    return archivedTransactionAccess;
  }, [is_fake, hasDeletePermission, archivedTransactionAccess, nonActivatedFakeDelete]);

  const controls = (
    <div className="btn-group">
      {hasSetFakePermission && status === TransactionStatus.ACTIVATED && !is_fake && (
        <button className="btn btn-outline-danger" type="button" onClick={onFakeClick}>
          {t(`transactions.fakeBtn`)}
        </button>
      )}
      {hasApprovePermission && is_fake ? (
        <button type="button" className="btn btn-warning" onClick={onApproveClick}>
          {t(`transactions.approveButton`)}
        </button>
      ) : null}
      {showActivationBtn && (
        <button type="button" className="btn btn-success" onClick={onActivateClick}>
          {t(`transactions.activate`)}
        </button>
      )}
      {shouldArchiveButtonBeDisplayed && (
        <button
          className="btn btn-secondary"
          type="button"
          onClick={onArchivateClick}
          title={t(`transactions.addTransactionToArchive`)}
        >
          <i className="fa fa-archive" />
        </button>
      )}
    </div>
  );

  const columns = [
    {
      id: TransactionsColumn.ID,
      desktop: <td>{primary_id}</td>,
      mobile: (
        <tr>
          <td>{t(`common.ID`)}</td>
          <td>{primary_id}</td>
        </tr>
      ),
      hide: widget === WidgetType.TRANSACTIONS_ONLY_BY_ID,
    },
    {
      id: TransactionsColumn.WALLET_TYPE,
      desktop: <td>{walletTypeImage}</td>,
      mobile: (
        <tr className="first-mobile-row">
          <td>{t(`wallets.walletType`)}</td>
          <td>{walletTypeImage}</td>
        </tr>
      ),
    },
    {
      id: TransactionsColumn.WALLET_IDENTIFIER,
      desktop: <td>{walletIdentifier}</td>,
      mobile: (
        <tr>
          <td>{t(`wallets.walletIdentifier`)}</td>
          <td>{walletIdentifier}</td>
        </tr>
      ),
      hide:
        widget === WidgetType.TRANSACTIONS_ONLY_BY_ID
          ? !walletNumberAccess
          : walletNumberAccess && !transactionListAccess,
    },
    {
      id: TransactionsColumn.TRANSACTION_AMOUNT,
      desktop: <td className={classNames(directionColors, `broken-table-cell`)}>{transactionSum}</td>,
      mobile: (
        <tr>
          <td>{t(`transactions.transactionAmount`)}</td>
          <td className={classNames(directionColors)}>{transactionSum}</td>
        </tr>
      ),
    },
    {
      id: TransactionsColumn.COMISSION_AMOUNT,
      desktop: <td>{commissionAmount}</td>,
      mobile: (
        <tr>
          <td>{t(`transactions.commission`)}</td>
          <td>{commissionAmount}</td>
        </tr>
      ),
      hide: !commissionViewAccess,
    },
    {
      id: TransactionsColumn.BALANCE,
      desktop: <td>{wallet_balance && `${wallet_balance} ${currency_code}`}</td>,
      mobile: (
        <tr>
          <td>{t(`common.balance`)}</td>
          <td>{wallet_balance && `${wallet_balance} ${currency_code}`}</td>
        </tr>
      ),
      hide: !hasAccessToBalances,
    },
    {
      id: TransactionsColumn.TRANSACTION_NUMBER,
      desktop: <td className="broken-table-cell">{id}</td>,
      mobile: (
        <tr>
          <td>{t(`transactions.transactionNumber`)}</td>
          <td>{id}</td>
        </tr>
      ),
    },
    {
      id: TransactionsColumn.EXCHANGER,
      desktop: <td className="broken-table-cell">{exchanger_identifier}</td>,
      mobile: (
        <tr>
          <td>{t(`transactions.exchanger`)}</td>
          <td>{exchanger_identifier}</td>
        </tr>
      ),
    },
    {
      id: TransactionsColumn.DATE,
      desktop: <td>{displayCreatedAt}</td>,
      mobile: (
        <tr>
          <td>{t(`common.date`)}</td>
          <td>{displayCreatedAt}</td>
        </tr>
      ),
    },
    {
      id: TransactionsColumn.LABEL_ORDER_ID,
      desktop: (
        <td className="multi-badge-cell">
          {labelBadge}
          {merchant_order_id && orderIdLabel}
        </td>
      ),
      mobile: (
        <>
          <tr>
            <td>{t(`common.label`)}</td>
            <td>{labelBadge}</td>
          </tr>
          {merchant_order_id && (
            <tr>
              <td>{t(`common.orderId`)}</td>
              <td>{orderIdLabel}</td>
            </tr>
          )}
        </>
      ),
    },
    {
      id: TransactionsColumn.ACTIVATION_DATE,
      desktop: <td>{displayActivatedAt}</td>,
      mobile: (
        <tr>
          <td>{t(`common.activatedAt`)}</td>
          <td>{displayActivatedAt}</td>
        </tr>
      ),
    },
    ...(widget === WidgetType.ARCHIVED_TRANSACTIONS
      ? [
          {
            id: TransactionsColumn.Archived_DATE,
            desktop: <td>{displayRemovedAt}</td>,
            mobile: (
              <tr>
                <td>{t(`common.archivedAt`)}</td>
                <td>{displayRemovedAt}</td>
              </tr>
            ),
          },
        ]
      : []),
    {
      id: TransactionsColumn.STATUS,
      desktop: <td>{statusField}</td>,
      mobile: (
        <tr>
          <td>{t(`common.status`)}</td>
          <td>{statusField}</td>
        </tr>
      ),
    },
    {
      id: TransactionsColumn.POSTBACK_STATUS,
      desktop: postbackField,
      mobile: (
        <tr>
          <td>{t(`transactions.postbackStatus`)}</td>
          {postbackField}
        </tr>
      ),
      hide: !postbacksAccess,
    },
    {
      id: TransactionsColumn.TRANSACTION_KIND,
      desktop: transactionKindField,
      mobile: type !== 0 && (
        <tr>
          <td>{t(`transactions.transactionType`)}</td>
          {transactionKindField}
        </tr>
      ),
      hide: !typeDisplayingAccess,
    },
    {
      id: TransactionsColumn.TRANSACTION_TYPE,
      desktop: <td>{creationTypeField}</td>,
      mobile: (
        <tr>
          <td>{t(`transactions.kindOfTransaction`)}</td>
          <td>{creationTypeField}</td>
        </tr>
      ),
      hide: !displayTransactionTypePermission,
    },
    ...(widget !== WidgetType.ARCHIVED_TRANSACTIONS
      ? [
          {
            id: TransactionsColumn.ACTIONS,
            desktop: <td>{controls}</td>,
            mobile: (
              <tr>
                <td>{t(`common.actions`)}</td>
                <td>{controls}</td>
              </tr>
            ),
          },
        ]
      : []),
  ];

  return useFilterColumns({ columns, desktopSize, hiddenColumns });
};

export default TransactionRow;
