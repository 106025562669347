import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalElement from 'components/modals/ModalElement';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { widgetModalSelector } from '@kassma-team/kassma-toolkit/lib';

import { CREATE_PAYMENTS_SORTING_REQUESTED } from 'actionTypes';
import { settingsActionCreators } from 'actions/widgets/settings';
import { ModalType, WidgetType } from 'utils/enums';
import { ISettingsStateModal } from 'interfaces/widgets/settings';
import { stringify } from 'querystring';

const { showModal } = settingsActionCreators;

const ConfirmDefaultSortingModal = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { submitAction, currency }: ISettingsStateModal | Record<string, string> =
    useSelector(widgetModalSelector(WidgetType.SETTINGS)) || {};

  const showModalOnCloseAction = useMemo(
    () =>
      get(submitAction, `type`) === CREATE_PAYMENTS_SORTING_REQUESTED
        ? { type: ModalType.CREATE_PAYMENTS_SORTING, currency }
        : { type: ModalType.UPDATE_PAYMENTS_SORTING, currency },
    [submitAction, currency]
  );

  const closeHandler = useCallback(() => dispatch(showModal(showModalOnCloseAction)), [showModalOnCloseAction]);
  const submitHandler = useCallback(() => dispatch(submitAction), [submitAction]);

  const footer = (
    <>
      <button className="btn btn-alt-secondary mr-10" onClick={closeHandler}>
        {t(`common.cancel`)}
      </button>
      <button className="btn btn-alt-success" onClick={submitHandler}>
        {t(`common.save`)}
      </button>
    </>
  );

  return (
    <ModalElement
      title="settings.savingDisplayOrder"
      footer={footer}
      content={t(`settings.customAndDefaultOrderIsTheSame`)}
      actionCreators={settingsActionCreators}
      footerClassName="modal__footer--confirmation"
    />
  );
};

export default ConfirmDefaultSortingModal;
