import { createSelector } from 'reselect';
import { IRootState, widgetListSelector } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { ILogsCategoriesItems } from 'interfaces/widgets/logs';

export const logsCategoriesSelector = createSelector<IRootState, ILogsCategoriesItems[], ILogsCategoriesItems[]>(
  widgetListSelector(WidgetType.LOGS_CATEGORIES),
  (list) => list || []
);
