import React, { FC, useContext, createContext } from 'react';
import { usePaymentSystems, UsePaymentSystems } from '../../PaymentTranslation/hooks';

type usePaymentSystemsCtx = UsePaymentSystems;

const ctx = createContext<usePaymentSystemsCtx | undefined>(undefined);

export const PaymentSystemsCtx: FC = ({ children }) => {
  const { paymentSystems, isLoading, handleSetPaymentSystem, currentPaymentSystem } = usePaymentSystems({
    isSorted: true,
  });

  const value: usePaymentSystemsCtx = {
    isLoading,
    paymentSystems,

    handleSetPaymentSystem,
    currentPaymentSystem,
  };

  return <ctx.Provider value={value}>{children}</ctx.Provider>;
};

export function usePaymentSystemsCtx(): usePaymentSystemsCtx {
  const context = useContext(ctx);

  if (context === undefined) {
    throw new Error(`Is need to use usePaymentSystemsCtx inside a HintCtx component`);
  }

  return context;
}
