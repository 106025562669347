import React from 'react';
import {
  ModalForm as KassmaModalForm,
  widgetSubmittingSelector,
  IModalFormProps,
} from '@kassma-team/kassma-toolkit/lib';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';

import FormField from 'components/form/FormField';

const ModalForm = ({ widget, ...props }: IModalFormProps) => {
  const values = useSelector(getFormValues(props.form));
  const submitting = useSelector(widgetSubmittingSelector(widget));

  const dispatch = useDispatch();

  return (
    <KassmaModalForm {...props} values={values} submitting={submitting} dispatch={dispatch} FormField={FormField} />
  );
};

export default ModalForm;
