import React, { useCallback, useState, useMemo } from 'react';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  ModalFormSubmit,
  Checkbox,
  widgetSubmittingSelector,
  InputType,
  IModalFormProps,
  FieldType,
} from '@kassma-team/kassma-toolkit/lib';
import map from 'lodash/map';
import isString from 'lodash/isString';
import split from 'lodash/split';
import first from 'lodash/first';
import toNumber from 'lodash/toNumber';

import { CREATE_PROXY_FORM_NAME } from 'utils/constants';
import proxyFormValidate from 'utils/widgets/proxies/proxyFormValidate';
import { proxyTypeListSelection } from 'static/proxies';
import { ModalType, WidgetType } from 'utils/enums';
import { proxyActionCreators } from 'actions/widgets/proxy';
import { countryCodesSelectionSelector } from 'selectors/widgets/countries';
import useWalletsListForProxy from 'hooks/widgets/proxy/useWalletsListForProxy';

import ModalElement from 'components/modals/ModalElement';
import ModalForm from 'components/modals/ModalForm';

const MODAL_TITLE = `proxies.proxyCreating`;

const ProxyCreatingForm = (props: IModalFormProps) => {
  const [t] = useTranslation();

  const [loadByList, setLoadByList] = useState(false);
  const [proxiesTextareaValue, setProxiesTextareaValues] = useState(``);

  const dispatch = useDispatch();
  const submitting = useSelector(widgetSubmittingSelector(WidgetType.PROXIES)) || false;
  const countryCodes = useSelector(countryCodesSelectionSelector);

  const renderWallets = useWalletsListForProxy(CREATE_PROXY_FORM_NAME);

  const onToggleLoadingByList = useCallback(() => {
    dispatch(proxyActionCreators.resetModalError());
    setLoadByList(!loadByList);
  }, [loadByList]);

  const onProxiesTextareaChange = useCallback((e) => {
    setProxiesTextareaValues(e.target.value);
  }, []);

  const onProxiesCheck = useCallback(() => {
    dispatch(proxyActionCreators.showModal({ type: ModalType.ADDING_MULTIPLE_PROXIES, proxies: proxiesTextareaValue }));
  }, [proxiesTextareaValue]);

  interface IProxyCreatingFormWallet {
    wallet: Record<string, string | number>;
  }

  const onSubmit = (values: Record<string, unknown>) => {
    const walletsWithParser = map(values?.wallets as IProxyCreatingFormWallet[], (walletItem) => {
      const { value, parser_type } = walletItem.wallet || {};

      return {
        id: isString(value) ? toNumber(first(split(value, `-`))) : value,
        parser_type,
      };
    });

    dispatch(
      proxyActionCreators.create(
        {
          ...values,
          wallets: walletsWithParser,
        },
        {
          onSuccess: () => {
            dispatch(proxyActionCreators.getList());
          },
        }
      )
    );
  };

  const fields = useMemo(
    () => [
      {
        name: `ip`,
        label: `IP|HOST`,
        required: true,
      },
      {
        name: `port`,
        label: t(`proxies.port`),
        required: true,
        numeric: true,
      },
      {
        name: `username`,
        label: t(`proxies.username`),
        required: true,
        onlyLatinDigitsAndSymbols: true,
      },
      {
        name: `password`,
        label: t(`proxies.password`),
        required: true,
        password: true,
        onlyLatinDigitsAndSymbols: true,
      },
      {
        name: `type`,
        label: t(`common.type`),
        type: InputType.SELECT,
        data: proxyTypeListSelection,
        required: true,
      },
      {
        name: `country_code`,
        label: t(`common.country`),
        type: `select`,
        data: countryCodes,
        required: true,
      },
      {
        name: `wallets`,
        label: t(`wallets.wallet`),
        fieldType: FieldType.FIELD_ARRAY,
        component: renderWallets,
      },
    ],
    [renderWallets, countryCodes]
  );

  const loadByListCheckbox = (
    <Checkbox
      onChange={onToggleLoadingByList}
      label={t(`proxies.loadByList`)}
      checked={loadByList}
      isModalField
      name="list_loading"
    />
  );

  if (!loadByList) {
    return (
      <ModalForm
        {...props}
        fields={fields}
        onSubmit={onSubmit}
        submitting={submitting}
        submitText="common.create"
        actionCreators={proxyActionCreators}
        widget={WidgetType.PROXIES}
        title={MODAL_TITLE}
        preContent={loadByListCheckbox}
      />
    );
  }

  const content = (
    <>
      {loadByListCheckbox}
      <textarea
        rows={5}
        placeholder={`${t(`proxies.pasteProxyList`)}\n${t(`proxies.stringFormat`)}`}
        onChange={onProxiesTextareaChange}
        value={proxiesTextareaValue}
        className="form-control mb-15"
        id="multiple_proxies_field"
      />
      <ModalFormSubmit
        submitting={submitting}
        disabled={!proxiesTextareaValue}
        onClick={onProxiesCheck}
        id="check_proxies_btn"
      >
        {t(`proxies.checkList`)}
      </ModalFormSubmit>
    </>
  );

  return <ModalElement content={content} actionCreators={proxyActionCreators} title={MODAL_TITLE} />;
};

export default reduxForm({
  form: CREATE_PROXY_FORM_NAME,
  validate: proxyFormValidate,
})(ProxyCreatingForm);
