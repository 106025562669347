import React, { FC, useRef } from 'react';
import { NotificationProvider, useNotifications } from './useNotificationContext';
import { NotificationButton } from './NotificationButton';
import { Messages } from './Messages';
import { useClickOutside } from './useClickOutside';

import './NotificationCenter.scss';

type Props = {
  children?: never;
};

const NotificationCenter: FC<Props> = () => {
  const { isMessagesShowed, setMessagesShowed, handleReadMessages } = useNotifications();
  const messagesRef = useRef<HTMLElement | null>(null);

  useClickOutside(messagesRef, () => {
    setMessagesShowed(false), handleReadMessages();
  });

  const showMessages = () => setMessagesShowed((prev) => !prev);

  return (
    <section ref={messagesRef} data-testid="notification-center" className="NotificationCenter">
      <NotificationButton onClick={showMessages} />
      {isMessagesShowed && <Messages />}
    </section>
  );
};

export const NotificationCenterWrapped: FC = () => (
  <NotificationProvider>
    <NotificationCenter />
  </NotificationProvider>
);
