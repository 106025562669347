import React, { FC } from 'react';

import constructorRouteTabs from 'pages/constructor/constructorRouteTabs';
import usePaymentHintsTableTitles from 'hooks/widgets/tableTitles/constructor/usePaymentHintsTableTitles';

import Widget from 'components/widgets/Widget';

import { PackagesTranslationCtx } from './ctx';
import { PackagesTranslationScreen } from './screens/PackagesTranslationScreen';

export const PackagesTranslation: FC = () => {
  const tableHeadTitles = usePaymentHintsTableTitles();

  return (
    <Widget
      headTitle="paymentConstructor.paymentTranslations"
      routeTabs={constructorRouteTabs}
      pageTitle="paymentConstructor.constructor"
      tableTitle="paymentConstructor.paymentTranslationList"
      actionCreators={[]}
      tableHeadTitles={tableHeadTitles}
      controlPanel={
        <PackagesTranslationCtx>
          <PackagesTranslationScreen />
        </PackagesTranslationCtx>
      }
    />
  );
};
