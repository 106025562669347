import React from 'react';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDesktopSize, widgetModalSelector, Size } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { postbackActionCreators } from 'actions/widgets/postback';
import { stockpilingListActionCreators } from 'actions/widgets/stockpiling';
import { IStockpilingPostbackRowProps } from 'interfaces/widgets/stockpiling';

import StockpilingPostbackRow from 'components/widgets/stockpiling/StockpilingPostbackRow';
import ModalElement from 'components/modals/ModalElement';
import useItem from '../../../hooks/widgets/useItem';

const StockpilingPostbackList = () => {
  const modal: any = useSelector(widgetModalSelector(WidgetType.STOCKPILING_LIST));

  const desktopSize: boolean = useDesktopSize();

  const dispatch = useDispatch();

  const [t] = useTranslation();

  const { item, itemLoading } = useItem<IStockpilingPostbackRowProps>({
    widget: WidgetType.POSTBACKS,
    actionCreators: postbackActionCreators,
    queryParams: {
      stockpiling_id: modal?.id,
      direction: modal?.direction,
    },
  });

  let content;
  if (!itemLoading) {
    if (!item) {
      content = <span className="table-content__message">{t(`postbacks.postbackListIsEmpty`)}</span>;
    } else {
      content = (
        <div className="table-responsive">
          <table className="table table-vcenter table-padding">
            {desktopSize && (
              <thead>
                <tr>
                  <th className="font-w600">ID</th>
                  <th className="font-w600">{t(`postbacks.url`)}</th>
                  <th className="font-w600">{t(`postbacks.statusCode`)}</th>
                  <th className="font-w600">{t(`postbacks.response`)}</th>
                  <th className="font-w600">{t(`common.status`)}</th>
                  <th className="font-w600">{t(`common.createdAt`)}</th>
                  <th className="font-w600">{t(`postbacks.requestBody`)}</th>
                </tr>
              </thead>
            )}
            <tbody>
              <StockpilingPostbackRow key={item.id} {...item} desktopSize={desktopSize} />
            </tbody>
          </table>
        </div>
      );
    }
  }

  return (
    <ModalElement
      actionCreators={stockpilingListActionCreators}
      title="postbacks.postbackList"
      size={Size.BIG}
      loading={itemLoading}
      content={content}
      dispatch={dispatch}
    />
  );
};

export default StockpilingPostbackList;
