import React from 'react';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { setLang, GridType, InputType } from '@kassma-team/kassma-toolkit/lib';
import i18n from 'i18n';
import { Dispatch } from 'redux';

import { PROFILE_SETTINGS } from 'utils/constants';
import { ISettingsForm } from 'interfaces/widgets/settings';
import { IProfileSettingsForm } from 'interfaces/widgets/settings/profileSettings';

import SettingsForm from 'components/widgets/settings/SettingsForm';

const onSubmit = (values: IProfileSettingsForm, dispatch: Dispatch) => {
  const { lang } = values;
  localStorage.setItem(`lang`, lang);
  i18n.changeLanguage(lang);
  dispatch(setLang(lang));
};

const ProfileSettings = (props: ISettingsForm) => {
  const [t] = useTranslation();

  return (
    <SettingsForm
      {...props}
      groupedFields={[
        {
          title: t(`settings.profileSettings`),
          fields: [
            {
              name: `lang`,
              type: InputType.MUI_SELECT,
              label: t(`settings.language`),
              data: [
                {
                  value: `ru`,
                  text: t(`settings.nativeRU`),
                },
                {
                  value: `en`,
                  text: t(`settings.nativeEN`),
                },
              ],
            },
          ],
          gridType: GridType.GRID,
        },
      ]}
      submitText="common.update"
    />
  );
};

export default reduxForm({
  form: PROFILE_SETTINGS,
  onSubmit,
  destroyOnUnmount: false,
})(ProfileSettings as any);
