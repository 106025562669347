import React, { ChangeEvent, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import map from 'lodash/map';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import classNames from 'classnames';
import concat from 'lodash/concat';
import initial from 'lodash/initial';
import last from 'lodash/last';
import { useTranslation } from 'react-i18next';
import he from 'he';
import {
  TableCheckbox,
  Tooltip,
  WalletImg,
  useFilterColumns,
  useIsTouchDevice,
  IWidgetsAction,
} from '@kassma-team/kassma-toolkit/lib';

import useFullAmount from 'hooks/useFullAmount';
import {
  ModalType,
  Permission,
  WalletError,
  WalletStatus,
  WalletType,
  WidgetType,
  WalletColumn,
  WalletState,
  WalletPriority,
} from 'utils/enums';
import { getTimeFromNow } from 'utils';
import { hasAccessSelector } from 'selectors/auth';
import {
  disabledWalletActionCreators,
  walletActionCreators,
  updateWalletSettings,
  toggleWalletPluginStatus,
  toggleWalletStandardStatus,
  removedWalletActionCreators,
} from 'actions/widgets/wallet';
import { IWalletRowProps } from 'interfaces/widgets/wallets/wallet';

import './wallets.scss';
import { paymentSystemLogoSelector } from '../../../selectors/widgets/paymentSystems';
import InvoiceIcon from 'assets/media/icons/invoice.svg';
import { push } from 'connected-react-router';

import BelowPriorityIcon from './assets/below.svg';
import NormalPriorityIcon from './assets/normal.svg';
import LowPriorityIcon from './assets/low.svg';
import AbovePriorityIcon from './assets/above.svg';
import HighPriorityIcon from './assets/high.svg';

interface IControlProps {
  id: number;
  widget: string;
  desktopSize?: boolean;
  type?: string;
  currency?: string;
}

interface ICurrencyCode {
  currency: { code: string };
}

const Control = ({ id, widget, type, currency }: IControlProps) => {
  const dispatch = useDispatch();

  const [t] = useTranslation();
  const location = useLocation();

  const isTouchDevice = useIsTouchDevice();

  const deleteAccess = useSelector(hasAccessSelector(Permission.WALLET_DELETE));
  const hardRemoveAccess = useSelector(hasAccessSelector(Permission.WALLET_HARD_DELETE));
  const updateAccess = useSelector(hasAccessSelector(Permission.WALLET_UPDATE));
  const managePS = useSelector(hasAccessSelector(Permission.MANAGE_PAYMENTS_CONSTRUCTOR));
  const setActiveAccess = useSelector(hasAccessSelector(Permission.WALLET_SET_ACTIVE));

  const removeIcon = widget === WidgetType.DISABLED_WALLETS ? `fa fa-archive` : `fa  fa-power-off`;

  let showModal: IWidgetsAction | null = null;
  switch (widget) {
    case WidgetType.DISABLED_WALLETS: {
      showModal = disabledWalletActionCreators.showModal;
      break;
    }

    case WidgetType.WALLETS: {
      showModal = walletActionCreators.showModal;
      break;
    }

    case WidgetType.REMOVED_WALLETS: {
      showModal = removedWalletActionCreators.showModal;
      break;
    }
  }

  return (
    <div className="btn-group">
      {widget === WidgetType.REMOVED_WALLETS ? (
        <>
          {deleteAccess && (
            <button
              type="button"
              id={`wallet_row_${id}_delete_btn`}
              className={classNames(`btn`, `btn-secondary`, `js-tooltip-enabled`, { 'btn-sm': !isTouchDevice })}
              onClick={() => dispatch(showModal({ id, type: ModalType.RECOVER_CONFIRMATION }))}
              title={t(`common.recover`)}
            >
              <i className="fa fa-undo" />
            </button>
          )}
          {hardRemoveAccess && (
            <button
              type="button"
              id={`wallet_row_${id}_delete_btn`}
              className={classNames(`btn`, `btn-secondary`, `js-tooltip-enabled`, { 'btn-sm': !isTouchDevice })}
              onClick={() => dispatch(showModal({ id, type: ModalType.REMOVE_CONFIRMATION }))}
              title={t(`common.delete`)}
            >
              <i className="fa fa-trash" />
            </button>
          )}
        </>
      ) : (
        <>
          <button
            type="button"
            id={`wallet_row_${id}_show_btn`}
            className={classNames(`btn`, `btn-secondary`, `js-tooltip-enabled`, { 'btn-sm': !isTouchDevice })}
            onClick={() => dispatch(showModal({ id, type: ModalType.SHOW }))}
          >
            <i className="fa fa-eye" />
          </button>
          <Link
            to={`/logs?wallet_id=${id}`}
            id={`wallet_row_${id}_transactions_btn`}
            className={classNames(`btn`, `btn-secondary`, `js-tooltip-enabled`, { 'btn-sm': !isTouchDevice })}
          >
            <i className="fa fa-list" />
          </Link>
          {managePS && location.pathname === `/wallets` && (
            <button
              type="button"
              id={`wallet_row_${id}_delete_btn`}
              className={classNames(`btn`, `btn-secondary`, `js-tooltip-enabled`, { 'btn-sm': !isTouchDevice })}
              onClick={() => {
                dispatch(push(`/view-plugin`));
                localStorage.setItem(
                  `pluginConstructor`,
                  JSON.stringify({
                    w_id: id,
                    wallet_type: type,
                    currency_code: currency,
                    label: `label`,
                  })
                );
                window.location.reload();
              }}
              title={t(`paymentConstructor.showOnPlugin`)}
            >
              <img className={`invoice-button-icon`} src={InvoiceIcon} alt="" />
            </button>
          )}
          {updateAccess && (
            <button
              type="button"
              id={`wallet_row_${id}_edit_btn`}
              className={classNames(`btn`, `btn-secondary`, `js-tooltip-enabled`, { 'btn-sm': !isTouchDevice })}
              onClick={() => dispatch(showModal({ id, type: ModalType.UPDATE }))}
            >
              <i className="fa fa-pencil" />
            </button>
          )}
          {widget === WidgetType.DISABLED_WALLETS && deleteAccess && (
            <button
              type="button"
              id={`wallet_row_${id}_delete_btn`}
              className={classNames(`btn`, `btn-secondary`, `js-tooltip-enabled`, { 'btn-sm': !isTouchDevice })}
              onClick={() => dispatch(showModal({ id, type: ModalType.REMOVE_CONFIRMATION }))}
              title={t(`common.archive`)}
            >
              <i className={removeIcon} />
            </button>
          )}
          {widget === WidgetType.WALLETS && setActiveAccess && (
            <button
              type="button"
              id={`wallet_row_${id}_delete_btn`}
              className={classNames(`btn`, `btn-secondary`, `js-tooltip-enabled`, { 'btn-sm': !isTouchDevice })}
              onClick={() => dispatch(showModal({ id, type: ModalType.REMOVE_CONFIRMATION }))}
              title={t(`common.disable`)}
            >
              <i className={removeIcon} />
            </button>
          )}
        </>
      )}
    </div>
  );
};

const WalletRow = ({
  id,
  hash_id,
  wallet_type,
  identifier,
  balance,
  is_active,
  currency,
  proxy,
  error,
  updated_at,
  desktopSize,
  code,
  cookie_flag,
  parsing_type,
  widget,
  test_wallet_type,
  extra_balances,
  plugin_status,
  priority,
  hiddenColumns,
  username,
  email_username,
  comment,
  sms_code,
  upi_addresses,
  cookie_type,
  states: state = [],
  removed_at,
  display_identifier,
}: IWalletRowProps & ICurrencyCode) => {
  const [pluginStatusDisabled, setPluginStatusDisabled] = useState(false);
  const [standardStatusDisabled, setStandardStatusDisabled] = useState(false);

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const updatePermission = useSelector(hasAccessSelector(Permission.WALLET_SETTINGS));
  const balancePermission = useSelector(hasAccessSelector(Permission.WALLET_BALANCE));

  const checkedAddInfo = isArray(upi_addresses) ? upi_addresses : [];
  const formattedAddInfo = concat(
    map(initial(checkedAddInfo), (info) => info + `,`),
    last(checkedAddInfo)
  );

  const fullAmount = useFullAmount({
    amount: balance,
    currencySymbol: currency,
  });

  const logoPath = useSelector(paymentSystemLogoSelector(wallet_type));

  const logo =
    wallet_type === WalletType.TEST && test_wallet_type && test_wallet_type.logo ? test_wallet_type.logo : logoPath;

  const showPluginStatusCell = widget !== WidgetType.DISABLED_WALLETS && widget !== WidgetType.REMOVED_WALLETS;
  const showPluginStatusControl = !isUndefined(plugin_status);
  const showPluginStatusMobile = showPluginStatusControl && showPluginStatusCell;

  const walletTypeImage = (
    <WalletImg title={wallet_type === WalletType.TEST ? get(test_wallet_type, `name`) : wallet_type} src={logo} />
  );

  let balanceView = isNumber(balance) ? (
    <div className="text-elegance">
      <strong>{fullAmount}</strong>
    </div>
  ) : null;

  function replace(html: string) {
    return html.replace(/<(?!\/?\w+>)/g, `&lt;`);
  }

  // const replacedComment = (comment || ``).replaceAll(`&amp;`, `&`);
  const decodedComment = he.decode(comment || ``);

  const commentView = (
    <>
      <span dangerouslySetInnerHTML={{ __html: replace(decodedComment) }} />
    </>
  );

  if (isArray(extra_balances) && !isEmpty(extra_balances) && balanceView) {
    balanceView = (
      <Tooltip
        insideContent={map(extra_balances, ({ currency, balance }, key) => (
          <div key={key} className="text-no-wrap">
            {currency}: {balance}
          </div>
        ))}
      >
        {balanceView}
      </Tooltip>
    );
  }

  const statusField = (
    <div className="multi-badge-cell state-cell">
      <>
        {state.includes(WalletState.ALLOWED_BALANCE_EXCEEDED) && (
          <Tooltip insideContent={t(`wallets.allowedBalanceExceeded`)}>
            <span className="badge badge-warning text-overflow">{t(`wallets.allowedBalanceExceeded`)}</span>
          </Tooltip>
        )}
        {state.includes(WalletState.AUTHORISATION_ERROR) && (
          <Tooltip insideContent={t(`wallets.authError`)}>
            <span className="badge badge-danger text-overflow">{t(`wallets.authError`)}</span>
          </Tooltip>
        )}
        {state.includes(WalletState.INTEGRATION_ERROR) && (
          <Tooltip insideContent={t(`wallets.integrationError`)}>
            <span className="badge badge-warning text-overflow">{t(`wallets.integrationError`)}</span>
          </Tooltip>
        )}
        {state.includes(WalletState.DATA_ACCESS_ERROR) && (
          <Tooltip insideContent={t(`wallets.dataAccessError`)}>
            <span className="badge badge-danger text-overflow">{t(`wallets.dataAccessError`)}</span>
          </Tooltip>
        )}
        {state.includes(WalletState.PK_CONNECTION_ERROR) && (
          <Tooltip insideContent={t(`wallets.PKConnectionError`)}>
            <span className="badge badge-danger text-overflow">{t(`wallets.PKConnectionError`)}</span>
          </Tooltip>
        )}
        {state.includes(WalletState.PROBABLY_BANNED) && (
          <Tooltip insideContent={t(`wallets.probablyBanned`)}>
            <span className="badge badge-danger text-overflow">{t(`wallets.probablyBanned`)}</span>
          </Tooltip>
        )}
        {state.includes(WalletState.PROXY_NOT_WORKING) && (
          <Tooltip insideContent={t(`wallets.notWorkingProxy`)}>
            <span className="badge badge-danger text-overflow">{t(`wallets.notWorkingProxy`)}</span>
          </Tooltip>
        )}
        {state.includes(WalletState.ERROR_IN_TRANSACTION_PARSING) && (
          <Tooltip insideContent={t(`wallets.parsingDataError`)}>
            <span className="badge badge-danger text-overflow">{t(`wallets.parsingDataError`)}</span>
          </Tooltip>
        )}
        {state.includes(WalletState.PARSER_ERROR) && (
          <Tooltip insideContent={t(`wallets.parserError`)}>
            <span className="badge badge-danger text-overflow">{t(`wallets.parserError`)}</span>
          </Tooltip>
        )}
        {state.includes(WalletState.NO_COOKIES) && wallet_type === `paytm` && (
          <Tooltip insideContent={t(`wallets.noCookies`)}>
            <span className="badge badge-danger text-overflow">{t(`wallets.noCookies`)}</span>
          </Tooltip>
        )}
        {state.includes(WalletState.PENDING_SMS_CONFIRMATION) && (
          <Tooltip insideContent={t(`wallets.pendingSmsConfirmation`)}>
            <span className="badge badge-secondary">{t(`wallets.pendingSmsConfirmation`)}</span>
          </Tooltip>
        )}
        {state.includes(WalletState.UPDATE_COOKIES) && (
          <Tooltip insideContent={t(`wallets.youHaveToUpdateCookies`)}>
            <span className="badge badge-warning text-overflow">{t(`wallets.youHaveToUpdateCookies`)}</span>
          </Tooltip>
        )}
        {state.includes(WalletState.ACCOUNT_DATA_ERROR) && (
          <Tooltip insideContent={t(`wallets.accountDataError`)}>
            <span className="badge badge-danger text-overflow">{t(`wallets.accountDataError`)}</span>
          </Tooltip>
        )}
        {state.includes(WalletState.UNKNOWN_ERROR) && (
          <Tooltip insideContent={t(`wallets.unknownError`)}>
            <span className="badge badge-danger text-overflow">{t(`wallets.unknownError`)}</span>
          </Tooltip>
        )}
        {state.includes(WalletState.DAILY_LIMIT_EXCEEDED) && (
          <Tooltip insideContent={t(`wallets.dailyLimitExceeded`)}>
            <span className="badge badge-warning text-overflow">{t(`wallets.dailyLimitExceeded`)}</span>
          </Tooltip>
        )}
        {state.includes(WalletState.NO_TRANSACTIONS) && (
          <Tooltip insideContent={t(`wallets.dailyLimitExceeded`)}>
            <span className="badge badge-warning text-overflow">{t(`transactions.noTransactions`)}</span>
          </Tooltip>
        )}
        {state.includes(WalletState.DAILY_TRANSACTION_LIMIT_EXCEEDED) && (
          <Tooltip insideContent={t(`wallets.dailyTransactionLimitExceeded`)}>
            <span className="badge badge-warning text-overflow">{t(`wallets.dailyTransactionLimitExceeded`)}</span>
          </Tooltip>
        )}
        {state.includes(WalletState.MONTHLY_TURNOVER_LIMIT_EXCEEDED) && (
          <Tooltip insideContent={t(`wallets.monthlyTurnoverLimitExceeded`)}>
            <span className="badge badge-warning text-overflow">{t(`wallets.monthlyTurnoverLimitExceeded`)}</span>
          </Tooltip>
        )}
        {state.includes(WalletState.DAILY_TURNOVER_LIMIT_EXCEEDED) && (
          <Tooltip insideContent={t(`wallets.dailyTurnoverLimitExceeded`)}>
            <span className="badge badge-warning text-overflow">{t(`wallets.dailyTurnoverLimitExceeded`)}</span>
          </Tooltip>
        )}
      </>
    </div>
  );

  const visibilityCheckbox = (
    <TableCheckbox
      id={`wallet_row_${id}_visibility`}
      checked={plugin_status}
      disabled={pluginStatusDisabled || !updatePermission}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;

        dispatch(toggleWalletPluginStatus(widget)({ id, plugin_status: checked }));
        setPluginStatusDisabled(true);
        dispatch(
          updateWalletSettings(
            {
              plugin_status: checked,
              id,
            },
            {
              onError: () =>
                dispatch(
                  toggleWalletPluginStatus(widget)({
                    id,
                    plugin_status: !checked,
                  })
                ),
              onFinally: () => setPluginStatusDisabled(false),
            }
          )
        );
      }}
    />
  );

  const priorityData: any = useMemo(() => {
    if (priority === WalletPriority.LOW) {
      return {
        label: t(`wallets.lowPriority`),
        icon: LowPriorityIcon,
      };
    } else if (priority === WalletPriority.BELOW_NORMAL) {
      return {
        label: t(`wallets.belowPriority`),
        icon: BelowPriorityIcon,
      };
    } else if (priority === WalletPriority.NORMAL) {
      return {
        label: t(`wallets.normalPriority`),
        icon: NormalPriorityIcon,
      };
    } else if (priority === WalletPriority.ABOVE_NORMAL) {
      return {
        label: t(`wallets.abovePriority`),
        icon: AbovePriorityIcon,
      };
    }

    return {
      label: t(`wallets.highPriority`),
      icon: HighPriorityIcon,
    };
  }, [priority]);

  const priorityField = (
    <div className="wallet_priority">
      <Tooltip insideContent={priorityData.label}>
        <img src={priorityData.icon} />
      </Tooltip>
    </div>
  );

  const canViewEmailAccount = useSelector(hasAccessSelector(Permission.WALLET_NUMBER));

  const columns = [
    {
      id: WalletColumn.ID,
      desktop: (
        <th className="text-center" scope="row">
          {id}
        </th>
      ),
      mobile: (
        <tr className="first-mobile-row">
          <td>ID</td>
          <td>{id}</td>
        </tr>
      ),
    },
    {
      id: WalletColumn.TYPE,
      desktop: <td>{walletTypeImage}</td>,
      mobile: (
        <tr>
          <td>{t(`common.type`)}</td>
          <td>{walletTypeImage}</td>
        </tr>
      ),
    },
    {
      id: WalletColumn.IDENTIFIER,
      desktop: <td className="wallet-row__cell--identifier">{identifier}</td>,
      mobile: (
        <tr>
          <td>{t(`wallets.identifier`)}</td>
          <td>{identifier}</td>
        </tr>
      ),
    },
    {
      id: WalletColumn.PROXY,
      mobile: proxy && (
        <tr>
          <td>{t(`common.proxy`)}</td>
          <td>
            {get(proxy, `ip`)}:{get(proxy, `port`)}
          </td>
        </tr>
      ),
    },
    {
      id: WalletColumn.EMAIL,
      desktop: <td className="text-primary wallet-row__cell--identifier">{email_username}</td>,
      mobile: (
        <tr>
          <td>{t(`wallets.emailAccount`)}</td>
          <td className="text-primary">{email_username}</td>
        </tr>
      ),
      hide: !canViewEmailAccount,
    },
    {
      id: WalletColumn.SITE_LOGIN,
      desktop: <td className="text-primary wallet-row__cell--identifier">{username}</td>,
      mobile: (
        <tr>
          <td>{t(`wallets.siteLogin`)}</td>
          <td className="text-primary">{username}</td>
        </tr>
      ),
      hide: !canViewEmailAccount,
    },
    {
      id: WalletColumn.ADDITIONAL_INFO,
      desktop: (
        <td>
          {map(formattedAddInfo, (info) => (
            <div>{info}</div>
          ))}
          <p>{display_identifier ? he.decode(display_identifier) : display_identifier}</p>
        </td>
      ),
      mobile: (
        <tr>
          <td>{t(`wallets.addInfo`)}</td>
          <td>
            {map(formattedAddInfo, (info) => (
              <div>{info}</div>
            ))}
          </td>
        </tr>
      ),
    },
    {
      id: WalletColumn.COMMENT,
      desktop: <td>{commentView}</td>,
      mobile: (
        <tr>
          <td>{t(`wallets.comment`)}</td>
          <td>{commentView}</td>
        </tr>
      ),
    },
    {
      id: WalletColumn.BALANCE,
      desktop: <td>{balanceView}</td>,
      mobile: (
        <tr>
          <td>{t(`wallets.balance`)}</td>
          <td>{balanceView}</td>
        </tr>
      ),
      hide: !balancePermission,
    },
    {
      id: WalletColumn.EPAY_CODE,
      mobile: wallet_type === WalletType.EPAY && (
        <tr>
          <td>{t(`wallets.enCode`)}</td>
          <td>{code}</td>
        </tr>
      ),
    },
    {
      id: WalletColumn.STATUS,
      desktop: <td>{statusField}</td>,
      mobile: (
        <tr>
          <td>{t(`common.status`)}</td>
          <td>{statusField}</td>
        </tr>
      ),
    },
    {
      id: WalletColumn.PRIORITY,
      desktop: widget !== WidgetType.REMOVED_WALLETS && <td>{priorityField}</td>,
      mobile: widget !== WidgetType.REMOVED_WALLETS && (
        <tr>
          <td>{t(`wallets.priorityRaw`)}</td>
          <td>{priorityField}</td>
        </tr>
      ),
    },
    {
      id: WalletColumn.SHOW_PLUGIN_STATUS,
      desktop: <td>{showPluginStatusControl && visibilityCheckbox}</td>,
      mobile: showPluginStatusMobile && (
        <tr>
          <td>{t(`wallets.pluginStatus`)}</td>
          <td>{visibilityCheckbox}</td>
        </tr>
      ),
      hide: !showPluginStatusCell,
    },
    {
      id: WalletColumn.ARCHIVING_DATE,
      desktop: <td>{removed_at}</td>,
      mobile: showPluginStatusMobile && (
        <tr>
          <td>{t(`wallets.archivingDate`)}</td>
          <td>{removed_at}</td>
        </tr>
      ),
      hide: widget !== WidgetType.REMOVED_WALLETS,
    },
    {
      id: WalletColumn.CONTROL,
      desktop: (
        <td>
          <Control id={id} desktopSize={desktopSize} widget={widget} type={wallet_type} currency={currency} />
        </td>
      ),
      mobile: (
        <tr>
          <td>{t(`common.actions`)}</td>
          <td>
            <Control id={id} desktopSize={desktopSize} widget={widget} type={wallet_type} currency={currency} />
          </td>
        </tr>
      ),
    },
  ];

  return useFilterColumns({ columns, desktopSize, hiddenColumns });
};

export default WalletRow;
