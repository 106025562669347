import React, { FC } from 'react';
import { WalletSummary } from 'api/wallets/WalletsApi';
import './Item.scss';
import { useTranslation } from 'react-i18next';

type Data = Record<WalletSummary[`wallet_type`], Record<WalletSummary[`currency`], WalletSummary>>;

type Props = {
  paymentSystem: string;
  walletSummary: Data;

  children?: never;
};

const getKeys = <T extends Record<string, unknown>>(data: T): ReadonlyArray<keyof T> => Object.keys(data);

export const Item: FC<Props> = ({ paymentSystem, walletSummary }) => {
  const [isOpened, setIsOpened] = React.useState(false);
  const { t } = useTranslation();

  const handleClick = () => setIsOpened((prev) => !prev);

  const reduceByKey = (
    data: Data,
    paymentsSystem: string,
    key: keyof Omit<WalletSummary, `currency` | `wallet_type_name`>
  ) => {
    const currencies = Object.keys(data[paymentsSystem]);

    return currencies.reduce((acc, curr) => {
      return acc + +data[paymentsSystem][curr][key];
    }, 0);
  };

  return (
    <div className="Item">
      <div className="Item__header" onClick={handleClick}>
        <h2 className="Item__title">{paymentSystem}</h2>
        <div className="Item__header-wrapper">
          <p className="Item__counter">
            <span className="Item__counter-worked">{reduceByKey(walletSummary, paymentSystem, `worked`)}</span>
            {` `}
            <span className="Item__counter-total">({reduceByKey(walletSummary, paymentSystem, `all`)})</span>
          </p>
          <i className={`fa fa-xl fa-regular ${isOpened ? `fa-angle-up` : `fa-angle-down`}`} />
        </div>
      </div>

      <section hidden={!isOpened}>
        <div className="Item__info">
          <p className="Item__info-label">{t(`wallets.walletOffLimit`)}:</p>
          <ul>
            {getKeys(walletSummary[paymentSystem]).map((currency) => {
              return (
                <li className="Item__info-item" key={currency}>
                  <span className="Item__info-amount">
                    {walletSummary[paymentSystem][currency].wallet_off_limit.toLocaleString(`en-US`)}
                    {` `}
                    <span className="Item__info-currency">{walletSummary[paymentSystem][currency].currency}</span>
                  </span>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="Item__info">
          <p className="Item__info-label">{t(`wallets.dailyLimitOnAmount`)}:</p>
          <ul>
            {getKeys(walletSummary[paymentSystem]).map((currency) => {
              return (
                <li className="Item__info-item" key={currency}>
                  <span className="Item__info-amount">
                    {walletSummary[paymentSystem][currency].wallet_off_load.toLocaleString(`en-US`)}
                    {` `}
                    <span className="Item__info-currency">{walletSummary[paymentSystem][currency].currency}</span>
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
    </div>
  );
};
