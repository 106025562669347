import {
  createWidgetAction,
  widgetActionCreators,
  IWidgetActionCreators,
  IWidgetsAction,
} from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import {
  MARK_AS_SENT,
  RESEND_POSTBACK_REQUESTED,
  RESEND_WITHDRAWALS_POSTBACK_REQUESTED,
  RESET_ATTEMPT_COUNTER,
} from 'actionTypes';
import { IWidgetLabels } from 'interfaces/widgets/widgets';

const postbackPayloadLabels: IWidgetLabels = { listLabel: `postbacks`, itemLabel: `data` };

export const postbackActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.POSTBACKS,
  labels: postbackPayloadLabels,
  withPagination: true,
});

export const notAcceptedPostbackActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.NOT_ACCEPTED_POSTBACKS,
  labels: postbackPayloadLabels,
  withPagination: true,
});

export const resendPostback: IWidgetsAction = createWidgetAction({
  widget: WidgetType.NOT_ACCEPTED_POSTBACKS,
})(RESEND_POSTBACK_REQUESTED);

export const resendWithdrawalsPostback: IWidgetsAction = createWidgetAction({
  widget: WidgetType.NOT_ACCEPTED_POSTBACKS,
})(RESEND_WITHDRAWALS_POSTBACK_REQUESTED);

export const resetAttemptCounter: IWidgetsAction = createWidgetAction({
  widget: WidgetType.NOT_ACCEPTED_POSTBACKS,
})(RESET_ATTEMPT_COUNTER);

export const markAsSent: IWidgetsAction = createWidgetAction({
  widget: WidgetType.NOT_ACCEPTED_POSTBACKS,
})(MARK_AS_SENT);
