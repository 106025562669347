import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { i18n, widgetModalSelector } from '@kassma-team/kassma-toolkit/lib';

import { approveTransaction, transactionActionCreators } from 'actions/widgets/transactions/transactions';
import { WidgetType } from 'utils/enums';

import ConfirmationModal from 'components/modals/ConfirmationModal';

const TransactionApproveModal = () => {
  const dispatch = useDispatch();

  const value = useSelector(widgetModalSelector(WidgetType.TRANSACTIONS));
  const primaryId = get(value, `primary_id`);
  const id = get(value, `id`);

  const onConfirm = () => {
    dispatch(
      approveTransaction({
        transactions: [primaryId || 0],
        is_fake: false,
        // widget,
        onError: (e) => {
          console.error(e.message);
        },
      })
    );
  };

  return (
    <ConfirmationModal
      title="transactions.approveTransactionTitle"
      content={i18n.t(`transactions.approveTransactionText`, { id })}
      confirmText="transactions.approveButton"
      actionCreators={transactionActionCreators}
      onConfirm={onConfirm}
    />
  );
};

export default TransactionApproveModal;
