import React, { useCallback, useState, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  DropdownSelect,
  InnerTable,
  LATIN_DIGITS_AND_SYMBOLS_REGEX,
  validateMessages,
} from '@kassma-team/kassma-toolkit/lib';

import { proxyTypeListSelection } from 'static/proxies';
import { checkMultiAddingProxyItem, deleteMultiAddingProxy } from 'actions/widgets/proxy';
import validateProxyIp from 'utils/widgets/proxies/validateProxyIp';
import validateProxyPort from 'utils/widgets/proxies/validateProxyPort';
import { ProxyStatus } from 'utils/enums';
import { checkingAddingProxySelector } from 'selectors/widgets/proxies';

import './proxiesMultiAdding.scss';

interface IField {
  children: ReactNode;
  error?: string;
}

interface IError {
  ip_host?: string;
  port?: string;
  username?: string;
  password?: string;
  type?: string;
  country_code?: string;
}

const Field = ({ children, error }: IField) => {
  return (
    <div className={classNames(`adding-proxies__input`, { 'is-invalid': error })}>
      {children}
      {error && <span className="text-error display-block">{error}</span>}
    </div>
  );
};

const proxyStatus = {
  [ProxyStatus.ERROR]: {
    badge: `badge-danger`,
    text: `proxies.notWorking`,
  },
  [ProxyStatus.SUCCESS]: {
    badge: `badge-success`,
    text: `proxies.working`,
  },
  [ProxyStatus.CHECKING]: {
    badge: `badge-warning`,
    text: `proxies.checking`,
  },
};

interface ICountryCode {
  code: string;
}

interface IMultiAddingProxyRow {
  ip_host: string;
  port: string;
  username: string;
  password: string;
  type: string;
  status?: number;
  in_system?: boolean;
  desktopSize: boolean;
  id?: number;
  country_code: string;
  countryCodes: ICountryCode[];
}

const MultiAddingProxyRow = ({
  ip_host,
  port,
  username,
  password,
  type,
  status,
  in_system,
  desktopSize,
  id,
  country_code,
  countryCodes,
}: IMultiAddingProxyRow) => {
  const [editMode, setEditMode] = useState(false);
  const [errors, setErrors] = useState<IError>({});
  const [values, setValues] = useState({
    ip_host,
    port,
    username,
    password,
    type,
    country_code,
  });

  const [t] = useTranslation();

  const dispatch = useDispatch();

  const checkingProxy = useSelector(checkingAddingProxySelector);

  const updateValues = useCallback(
    (name, value) => {
      setValues({
        ...values,
        [name]: value,
      });
    },
    [values]
  );

  const checkProxy = useCallback(() => {
    const { ip_host, port, username, password, type, country_code } = values || {};

    dispatch(
      checkMultiAddingProxyItem({
        ip_host,
        port,
        username,
        password,
        type,
        id,
        country_code,
      })
    );
  }, [values, id]);

  const onEditClick = useCallback(() => {
    setEditMode(true);
  }, []);

  const onDeleteClick = useCallback(() => {
    dispatch(deleteMultiAddingProxy(id));
  }, [id]);

  const onConfirmClick = useCallback(() => {
    const { ip_host, port, username, password } = values || {};
    const errors: IError = {};

    const ipHostError = validateProxyIp(ip_host);
    if (ipHostError) {
      errors.ip_host = ipHostError;
    }

    const portError = validateProxyPort(port);
    if (portError) {
      errors.port = portError;
    }

    if (!trim(username)) {
      errors.username = validateMessages().requiredField;
    }

    if (!trim(password)) {
      errors.password = validateMessages().requiredField;
    } else if (LATIN_DIGITS_AND_SYMBOLS_REGEX.test(password)) {
      errors.password = validateMessages().onlyLatinDigitsAndSymbols;
    }

    if (isEmpty(errors)) {
      checkProxy();
      setEditMode(false);
    } else {
      setErrors(errors);
    }
  }, [values]);

  let ipHostField, portField, usernameField, passwordField, typeField, countryCodeField;

  if (editMode) {
    ipHostField = (
      <Field error={errors.ip_host}>
        <input
          className="form-control"
          value={values.ip_host}
          id={`row_${id}_ip_host_field`}
          onChange={(e) => updateValues(`ip_host`, e.target.value)}
        />
      </Field>
    );
    portField = (
      <Field error={errors.port}>
        <input
          className="form-control"
          value={values.port}
          id={`row_${id}_port_field`}
          onChange={(e) => updateValues(`port`, e.target.value)}
        />
      </Field>
    );
    usernameField = (
      <Field error={errors.username}>
        <input
          className="form-control"
          value={values.username}
          id={`row_${id}_username_field`}
          onChange={(e) => updateValues(`username`, e.target.value)}
        />
      </Field>
    );
    passwordField = (
      <Field error={errors.password}>
        <input
          className="form-control"
          value={values.password}
          id={`row_${id}_password_field`}
          onChange={(e) => updateValues(`password`, e.target.value)}
        />
      </Field>
    );
    typeField = (
      <Field error={errors.type}>
        <DropdownSelect
          value={values.type}
          onChange={(val: Record<string, any>) => updateValues(`type`, val.value)}
          data={proxyTypeListSelection}
          isModalField
          id={`row_${id}_type_field`}
        />
      </Field>
    );
    countryCodeField = (
      <Field error={errors.country_code}>
        <DropdownSelect
          value={values.country_code}
          onChange={(val: Record<string, any>) => updateValues(`country_code`, val.value)}
          data={countryCodes}
          isModalField
          id={`row_${id}_country_code_field`}
        />
      </Field>
    );
  } else {
    ipHostField = <div className="adding-proxies__cell-text-wrap">{values.ip_host}</div>;
    portField = <div className="adding-proxies__cell-text-wrap">{values.port}</div>;
    usernameField = <div className="adding-proxies__cell-text-wrap">{values.username}</div>;
    passwordField = <div className="adding-proxies__cell-text-wrap">{values.password}</div>;
    typeField = <div className="adding-proxies__cell-text-wrap">{values.type}</div>;
    countryCodeField = <div className="adding-proxies__cell-text-wrap">{values.country_code}</div>;
  }

  const controls = (
    <div className="adding-proxies__actions btn-group">
      {editMode ? (
        <button
          className="btn btn-secondary btn-sm"
          onClick={onConfirmClick}
          disabled={checkingProxy}
          id={`row_${id}_confirm_btn`}
        >
          <i className="fa fa-check" />
        </button>
      ) : (
        <>
          <button
            className="btn btn-secondary btn-sm"
            onClick={checkProxy}
            disabled={checkingProxy}
            id={`row_${id}_check_proxy_btn`}
          >
            <i className="si si-reload" />
          </button>
          <button
            className="btn btn-secondary btn-sm"
            onClick={onEditClick}
            id={`row_${id}_edit_btn`}
            disabled={checkingProxy}
          >
            <i className="fa fa-pencil" />
          </button>
        </>
      )}
      <button
        className="btn btn-secondary btn-sm"
        onClick={onDeleteClick}
        disabled={checkingProxy}
        id={`row_${id}_delete_btn`}
      >
        <i className="fa fa-trash" />
      </button>
    </div>
  );

  const statusesField = (
    <div className="multi-badge-cell">
      <span className={classNames(`badge`, get(proxyStatus, `${status}.badge`))} id={`row_${id}_status_badge`}>
        {t(get(proxyStatus, `${status}.text`))}
      </span>
      {in_system && (
        <span className="badge badge-warning" id={`row_${id}_is_exists_in_system_badge`}>
          {t(`proxies.existsInSystem`)}
        </span>
      )}
    </div>
  );

  if (desktopSize) {
    return (
      <tr>
        <td>{ipHostField}</td>
        <td>{portField}</td>
        <td>{usernameField}</td>
        <td>{passwordField}</td>
        <td>{typeField}</td>
        <td>{countryCodeField}</td>
        <td>{statusesField}</td>
        <td>{controls}</td>
      </tr>
    );
  }

  return (
    <InnerTable cellClassName="mobile-inner-table">
      <tr className="first-mobile-row">
        <td>{t(`proxies.ipHost`)}</td>
        <td>{ipHostField}</td>
      </tr>
      <tr>
        <td>{t(`proxies.port`)}</td>
        <td>{portField}</td>
      </tr>
      <tr>
        <td>{t(`proxies.username`)}</td>
        <td>{usernameField}</td>
      </tr>
      <tr>
        <td>{t(`proxies.password`)}</td>
        <td>{passwordField}</td>
      </tr>
      <tr>
        <td>{t(`common.type`)}</td>
        <td>{typeField}</td>
      </tr>
      <tr>
        <td>{t(`common.country`)}</td>
        <td>{countryCodeField}</td>
      </tr>
      <tr>
        <td>{t(`common.status`)}</td>
        <td>{statusesField}</td>
      </tr>
      <tr>
        <td>{t(`common.actions`)}</td>
        <td>{controls}</td>
      </tr>
    </InnerTable>
  );
};

export default MultiAddingProxyRow;
