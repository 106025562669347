import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { widgetSubmittingSelector } from '@kassma-team/kassma-toolkit/lib';
import { Dispatch } from 'redux';

import { CREATE_WITHDRAWAL_FORM_NAME } from 'utils/constants';
import { WidgetType } from 'utils/enums';
import { withdrawalsActionCreators, createWithdrawal } from 'actions/widgets/withdrawals';
import useWizardForm from 'hooks/widgets/useWizardForm';
import { ICreateWithdrawalForm } from 'interfaces/widgets/withdrawals';

import WithdrawalCreationTypeSelection from './WithdrawalCreationTypeSelection';
import WithdrawalCreationData from './WithdrawalCreationData';
import WizardForm from 'components/form/WizardForm';

const WithdrawalCreationForm = () => {
  const { page, setPage } = useWizardForm({ form: CREATE_WITHDRAWAL_FORM_NAME });

  const [t] = useTranslation();

  const submitting = useSelector(widgetSubmittingSelector(WidgetType.WITHDRAWALS));

  const values: Partial<ICreateWithdrawalForm> = useSelector(getFormValues(CREATE_WITHDRAWAL_FORM_NAME)) || {};

  const [tabs] = useState([
    {
      title: t(`common.type`),
      page: 1,
    },
    {
      title: t(`common.data`),
      page: 2,
    },
  ]);

  const { payment_system, test_wallet_type_code } = values;

  return (
    <WizardForm
      actionCreators={withdrawalsActionCreators}
      title="withdrawals.withdrawal"
      activePage={page}
      setPage={setPage}
      tabs={tabs}
      formName={CREATE_WITHDRAWAL_FORM_NAME}
      submitText="common.create"
      disabledNextStep={(payment_system === `test` && !test_wallet_type_code) || !payment_system}
      disabledSubmitBtn={submitting as boolean}
    >
      {page === 1 && <WithdrawalCreationTypeSelection />}
      {page === 2 && (
        <WithdrawalCreationData
          walletType={values?.payment_system}
          onSubmit={(values: Partial<ICreateWithdrawalForm>, dispatch: Dispatch) => dispatch(createWithdrawal(values))}
        />
      )}
    </WizardForm>
  );
};

export default WithdrawalCreationForm;
