import { useEffect, useRef, useState } from 'react';

const useDebounce = (id: string, writeoff_amount: string, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState<{ id: string; writeoff_amount: string }>();
  const timerRef = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    timerRef.current = setTimeout(() => setDebouncedValue({ id, writeoff_amount }), delay);

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [id, writeoff_amount, delay]);

  return debouncedValue;
};

export default useDebounce;
