import { createSelector } from 'reselect';
import { widgetListSelector, widgetSelector } from '@kassma-team/kassma-toolkit/lib';
import { ISelectData } from '@kassma-team/kassma-toolkit/lib/types/form';
import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import some from 'lodash/some';

import { WidgetType } from 'utils/enums';
import { paymentSystemsWithoutSettlementsSelector } from 'selectors/widgets/paymentSystems';
import { selectionOfAllManualPSSelector } from 'selectors/widgets/manualPS';
import { IAccountBalancesItems, IReplenishmentItems } from 'interfaces/widgets/balances/balances';

export const replenishmentPSSelectSelector = createSelector(
  paymentSystemsWithoutSettlementsSelector,
  selectionOfAllManualPSSelector,
  widgetListSelector(WidgetType.REPLENISHMENT),
  (paymentSystems, manualPaymentSystems, replenishmentItems: IReplenishmentItems[]) => {
    const paymentSystemList = map(paymentSystems, ({ text, value }) => ({ text, value }));
    const manualPaymentSystemsList: ISelectData[] = map(manualPaymentSystems, ({ name, code }) => ({
      text: name,
      value: code,
    }));

    const requiredPaymentSystems: string[] = map(replenishmentItems, ({ type }) => type);

    return filter([...paymentSystemList, ...manualPaymentSystemsList], ({ value }) =>
      includes(requiredPaymentSystems, value)
    );
  }
);

export const replenishmentPSCurrencySelectSelector = (paymentSystem: string) =>
  createSelector(widgetListSelector(WidgetType.REPLENISHMENT), (list: IReplenishmentItems[]) => {
    const selectedPaymentSystem = find(list, { type: paymentSystem });
    const currencyList = get(selectedPaymentSystem, `currency_codes`);

    return map(currencyList, (currency) => ({ value: currency, text: currency }));
  });

export const replenishmentPSByCurrencySelectSelector = (selectedPaymentSystem: string, currency_code: string) =>
  createSelector(widgetListSelector(WidgetType.ACCOUNTS_BALANCES), (list: IAccountBalancesItems[]) => {
    const filteredList = filter(
      list,
      ({ payment_system, balances }) => payment_system !== selectedPaymentSystem && some(balances, { currency_code })
    );

    return map(filteredList, ({ payment_system }) => ({ value: payment_system, text: payment_system }));
  });

export const transferSubmittingSelector = createSelector(
  widgetSelector(WidgetType.ACCOUNTS_BALANCES),
  (state: any) => state?.submitting
);

export const accountBalanceHistoryBalanceSelector = createSelector(
  widgetSelector(WidgetType.ACCOUNT_BALANCE_HISTORY),
  (state: any) => state.balance
);

export const accountBalanceHistoryBalanceInfoByIdSelector = (id: string | number) =>
  createSelector(widgetListSelector(WidgetType.ACCOUNTS_BALANCES), (balanceItems?: any) => {
    let balanceInfo = {};
    if (balanceItems) {
      balanceItems.forEach((balanceItem: any) => {
        const { payment_system, balances } = balanceItem;
        if (balances) {
          balances.forEach((balance: any) => {
            if (balance.id.toString() === id.toString()) {
              balanceInfo = {
                ...balance,
                payment_system,
              };
            }
          });
        }
      });
    }

    return balanceInfo;
  });

export const accountBalanceHistoryCurrenciesByPaymentSystem = (paymentSystem: string) =>
  createSelector(widgetListSelector(WidgetType.ACCOUNTS_BALANCES), (list?: any) => {
    if (list) {
      let relevantBalances = list?.filter((item: any) => item.payment_system === paymentSystem);
      if (relevantBalances && relevantBalances[0]) {
        relevantBalances = relevantBalances[0].balances;

        return relevantBalances.map((balance: any) => ({
          value: balance.id,
          text: balance.currency_code,
        }));
      }
    }

    return [];
  });

export const accountBalanceHistoryCurrenciesByPaymentSystemForReports = (paymentSystem: string) =>
  createSelector(widgetListSelector(WidgetType.ACCOUNTS_BALANCES), (list?: any) => {
    if (list) {
      let relevantBalances = list?.filter((item: any) => item.payment_system === paymentSystem);

      if (relevantBalances && relevantBalances[0]) {
        relevantBalances = relevantBalances[0].balances;

        return relevantBalances.map((balance: any) => ({
          value: balance.currency_code,
          text: balance.currency_code,
        }));
      }
    }

    return [];
  });

export const settlementPaymentSystemByIdSelector = (id: string | number) =>
  createSelector(accountBalanceHistoryBalanceInfoByIdSelector(id), (balanceInfo: any) => balanceInfo.is_settlement);
