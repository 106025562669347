import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import { ILocalCommissions } from 'interfaces/widgets/constructor/tariffConstructor';

export interface ITariffCommission {
  id: number;
  data: Partial<ILocalCommissions>;
}

export interface ISettlementCommission {
  id: string;
  amount: string;
}

export class CommissionApi extends BaseApi {
  createTariffCommission = (data: Partial<ILocalCommissions>): AxiosPromise =>
    this.makeFetch(``, { method: `POST`, data });

  deleteTariffCommission = (id: number): AxiosPromise => this.makeFetch(`{id}`, { method: `DELETE` }, { id });

  editTariffCommission = ({ id, data }: ITariffCommission): AxiosPromise =>
    this.makeFetch(`{id}`, { data, method: `PUT` }, { id });

  loadSettlementCommission = ({ id, ...queryParams }: ISettlementCommission): AxiosPromise =>
    this.makeFetch(`settlement_commission/{id}`, { queryParams }, { id });
}

const commissionApi = new CommissionApi(`commission`);

export default commissionApi;
