import React from 'react';
import useMount from 'react-use/lib/useMount';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputType } from '@kassma-team/kassma-toolkit/lib';

import { CREATE_WALLET_FORM_NAME } from 'utils/constants';
import { WalletStatus, WalletType } from 'utils/enums';
import { PSWithoutSettlementsSelector } from 'selectors/widgets/paymentSystems';

import FormField from 'components/form/FormField';
import { ICreateWalletForm } from 'interfaces/widgets/wallets/wallet';

const initialValues = {
  wallet_type: WalletType.PAY_TM,
  status: WalletStatus.OK,
};

const WalletCreationTypeSelection = ({ handleSubmit, initialize, form }: InjectedFormProps) => {
  const values: Partial<ICreateWalletForm> = useSelector(getFormValues(form)) || {};
  const { wallet_type, status, comment, is_standard } = values;

  const paymentSystemList = useSelector(PSWithoutSettlementsSelector);
  const safePaymentSystemList = paymentSystemList.map((el) => ({
    ...el,
    text: el.name,
    value: el.code,
  }));

  const [t] = useTranslation();

  useMount(() => {
    const initialValues: Partial<ICreateWalletForm> = { wallet_type, status, comment, is_active: true };
    if (is_standard) {
      initialValues.is_standard = is_standard;
    }

    initialize(initialValues);
  });

  return (
    <form onSubmit={handleSubmit}>
      <FormField
        name="wallet_type"
        id={`${form}_wallet_type`}
        data={safePaymentSystemList}
        isModalField
        type={InputType.SELECT}
        label={t(`common.type`)}
        required
      />
      <FormField
        name="is_active"
        isModalField
        label={t(`common.status`)}
        type={InputType.SELECT}
        required
        data={[
          {
            value: true,
            text: t(`wallets.active`),
          },
          {
            value: false,
            text: t(`wallets.disabled`),
          },
        ]}
      />
      <FormField
        name="comment"
        isModalField
        label={t(`wallets.comment`)}
        type={InputType.TEXT_EDITOR}
        forcedShowingError
        maxLength={60}
      />
    </form>
  );
};

export default reduxForm({
  form: CREATE_WALLET_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  initialValues,
})(WalletCreationTypeSelection as any);
