import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { GridType } from '@kassma-team/kassma-toolkit/lib';

interface IProps {
  title?: string;
  gridType?: number;
  children?: ReactNode;
}

const SettingsBlockWrap = ({ title, children = null, gridType }: IProps) => {
  const [t] = useTranslation();

  return (
    <div className="block-content">
      {title && <h3 className="content-heading pt-0">{t(title)}</h3>}
      <div
        className={classNames({
          'settings-form-block--grid': gridType === GridType.GRID,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default SettingsBlockWrap;
