import { createSelector } from 'reselect';
import { IRootState, ISelectData, widgetListSelector } from '@kassma-team/kassma-toolkit';
import { WidgetType } from '../../utils/enums';
import map from 'lodash/map';
import { IChangeLogUserItems } from '../../interfaces/widgets/changeLogUser';

export const changeLogUsersSelectionSelector = createSelector<IRootState, IChangeLogUserItems[], ISelectData[]>(
  widgetListSelector(WidgetType.CHANGE_LOG_USERS),
  (items) => map(items, ({ name }) => ({ value: name, text: name }))
);
