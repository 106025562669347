import React, { FC } from 'react';
import constructorRouteTabs from 'pages/constructor/constructorRouteTabs';
import usePaymentHintsTableTitles from 'hooks/widgets/tableTitles/constructor/usePaymentHintsTableTitles';

import Widget from 'components/widgets/Widget';
import { TranslatesScreen } from './screens/TranslatesScreen';
import { TranslatesCtx } from './ctx';
import withPermissionCheck from '../../../../hocs/withPermissionCheck';
import { Permission } from '../../../../utils/enums';

const PaymentTranslations: FC = () => {
  const tableHeadTitles = usePaymentHintsTableTitles();

  return (
    <Widget
      headTitle="paymentConstructor.paymentTranslations"
      routeTabs={constructorRouteTabs}
      pageTitle="paymentConstructor.constructor"
      tableTitle="paymentConstructor.paymentTranslationList"
      actionCreators={[]}
      tableHeadTitles={tableHeadTitles}
      controlPanel={
        <TranslatesCtx>
          <TranslatesScreen />
        </TranslatesCtx>
      }
    />
  );
};

export default withPermissionCheck(PaymentTranslations, [
  {
    permissions: [
      {
        permission: Permission.MANAGE_PAYMENTS_CONSTRUCTOR,
        opposite: true,
      },
    ],
    redirectUrl: `/no-permissions`,
  },
]);
