import React, { FC, ReactNode } from 'react';

import './TranslatesScreen.scss';

type Props = {
  title: string;
  headerBtn?: ReactNode;
};

/**
 * Контейнер для списка переводов.
 */
export const TranslatesScreen: FC<Props> = ({ title, headerBtn, children }) => {
  return (
    <section className="TranslatesScreen">
      <h2 className="TranslatesScreen__title">{title}</h2>
      {headerBtn}

      {children}
    </section>
  );
};
