import React from 'react';

import useUserHasAccess from 'hooks/useUserHasAccess';
import { FilterWalletStatus, ModalType, WidgetType } from 'utils/enums';
import { removedWalletActionCreators } from 'actions/widgets/wallet';
import walletRouteTabs from 'pages/wallets/walletRouteTabs';
import useRemovedWalletTableTitles from 'hooks/widgets/tableTitles/wallets/useRemovedWalletTableTitles';
import { IWalletRowProps } from 'interfaces/widgets/wallets/wallet';

import WalletRow from 'components/widgets/wallets/WalletRow';
import Widget from 'components/widgets/Widget';
import RemoveWalletRecovery from 'components/widgets/wallets/removed/RemoveWalletRecovery';
import RemovedWalletDeleting from 'components/widgets/wallets/removed/RemovedWalletDeleting';
import 'pages/wallets/wallets.scss';
import useWalletFilterFields from 'hooks/widgets/tableTitles/wallets/useWalletFilterFields';
import { reduxForm } from 'redux-form';
import { IFilterProps } from 'interfaces/widgets/widgets';
import { GridType, getSubmitHandler } from '@kassma-team/kassma-toolkit';
import Filters from 'components/widgets/filters/Filters';

const FORM_NAME = `disabled-wallets-filters`;

const FilterForm = reduxForm<Record<string, unknown>, IFilterProps>({
  form: FORM_NAME,
  onSubmit: getSubmitHandler({}),
})(Filters);

const listMapping = (item: IWalletRowProps) => (
  <WalletRow key={item.id} {...item} widget={WidgetType.REMOVED_WALLETS} />
);

const modals = [
  {
    type: ModalType.RECOVER_CONFIRMATION,
    component: RemoveWalletRecovery,
    widget: WidgetType.REMOVED_WALLETS,
  },
  {
    type: ModalType.REMOVE_CONFIRMATION,
    component: RemovedWalletDeleting,
    widget: WidgetType.REMOVED_WALLETS,
  },
];

const RemovedWallets = () => {
  useUserHasAccess();

  const titles = useRemovedWalletTableTitles();
  const leftFilterFields = useWalletFilterFields(FORM_NAME, FilterWalletStatus.REMOVED);

  return (
    <Widget
      widget={WidgetType.REMOVED_WALLETS}
      routeTabs={walletRouteTabs}
      actionCreators={removedWalletActionCreators}
      pageTitle="wallets.wallets"
      tableTitle="wallets.archivedWallets"
      headTitle="wallets.archivedWallets"
      tableHeadTitles={titles}
      filterFormName={FORM_NAME}
      filterForm={
        <FilterForm leftFields={leftFilterFields} formName={FORM_NAME} gridType={GridType.GRID} numFields={[`id`]} />
      }
      listMapping={listMapping}
      modals={modals}
      statusBar
      withPagination
    />
  );
};

export default RemovedWallets;
