import React, { FC } from 'react';
import ConfirmationModal from '../../modals/ConfirmationModal';
import { markAsSent, notAcceptedPostbackActionCreators } from '../../../actions/widgets/postback';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IWidgetModal, widgetModalSelector } from '@kassma-team/kassma-toolkit';
import { WidgetType } from '../../../utils/enums';

const MarkAsSentModal: FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const modal: IWidgetModal = useSelector(widgetModalSelector(WidgetType.NOT_ACCEPTED_POSTBACKS));

  return (
    <ConfirmationModal
      actionCreators={notAcceptedPostbackActionCreators}
      title={t(`postbacks.markPostbackAsSent`)}
      content={t(`postbacks.areYouSureYouWantToMarkPostbackAsSent`)}
      confirmText={t(`common.mark`)}
      onConfirm={() => {
        dispatch(markAsSent({ ids: modal?.id }));
      }}
    />
  );
};

export default MarkAsSentModal;
