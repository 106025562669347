import { BaseApi } from '../common/BaseApi';
import { AxiosPromise } from 'axios';
import { IUpdatePSCurrenciesData } from '../../interfaces/widgets/paymentSystems';

export class WalletTypeToCurrenciesApi extends BaseApi {
  loadPaymentCurrencies = (walletType: string): AxiosPromise =>
    this.makeFetch(``, {
      queryParams: {
        wallet_type: walletType,
      },
    });

  updatePaymentCurrencies = (walletType: string, data: IUpdatePSCurrenciesData): AxiosPromise =>
    this.makeFetch(``, {
      data,
      method: `PUT`,
      queryParams: {
        wallet_type: walletType,
      },
    });

  updateSorting = (data: any): AxiosPromise =>
    this.makeFetch(``, {
      data,
      method: `POST`,
    });
}

const walletTypesToCurrenciesApi = new WalletTypeToCurrenciesApi(`wallet-type-to-currency`);

export default walletTypesToCurrenciesApi;
