import React, { FC } from 'react';
import { FormField as KassmaTeamFormField, widgetListSelector, IFormFieldProps } from '@kassma-team/kassma-toolkit/lib';
import { useSelector } from 'react-redux';
import { Field, FieldArray } from 'redux-form';

import { WidgetType } from 'utils/enums';

const FormField: FC<IFormFieldProps> = (props) => {
  const currencies = useSelector(widgetListSelector(WidgetType.CURRENCIES));

  return (
    <KassmaTeamFormField {...props} currencies={currencies} ReduxFormField={Field} ReduxFormFieldArray={FieldArray} />
  );
};

export default FormField;
