import React, { useCallback } from 'react';
import get from 'lodash/get';
import split from 'lodash/split';
import isNull from 'lodash/isNull';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { WalletImg, useFilterColumns } from '@kassma-team/kassma-toolkit/lib';

import {
  ModalType,
  Permission,
  WithdrawalStatus,
  WithdrawalsColumn,
  WithdrawalKind,
  PostbackFilter,
} from 'utils/enums';
import { withdrawalsActionCreators } from 'actions/widgets/withdrawals';
import withdrawalStatusField from 'utils/widgets/withdrawals/withdrawalStatusField';
import withdrawalTypeField from 'utils/widgets/withdrawals/withdrawalTypeField';
import { hasAccessSelector } from 'selectors/auth';
import withdrawalKindField from 'utils/widgets/withdrawals/withdrawalKindField';
import useFullAmount from 'hooks/useFullAmount';

import { postbackResponseStatusToIcon } from 'static/transactions';
import { IWithdrawalRowProps } from 'interfaces/widgets/withdrawals';
import { paymentSystemLogoSelector } from '../../../selectors/widgets/paymentSystems';

const { showModal } = withdrawalsActionCreators;

const WithdrawalRow = ({
  id,
  type,
  wallet_type,
  wallet_sender,
  wallet_sender_identifier,
  wallet_recipient,
  withdrawal_id,
  postback_id,
  label,
  amount,
  currency_code,
  status,
  desktopSize,
  created_at,
  postback,
  hiddenColumns,
  is_manually,
  is_test,
  postback_status,
  comment,
}: IWithdrawalRowProps) => {
  const [t] = useTranslation();

  const formattedAmount = useFullAmount({ amount, currencyCode: currency_code });

  const dispatch = useDispatch();

  const manageAccess = useSelector(hasAccessSelector(Permission.WITHDRAWALS_MANAGE));
  const canViewPostbacks = useSelector(hasAccessSelector(Permission.POSTBACKS));
  const withdrawalViewAccess = useSelector(hasAccessSelector(Permission.WITHDRAWALS_VIEW));

  const approveWithdrawal = useCallback(() => {
    dispatch(
      showModal({
        type: ModalType.APPROVAL_CONFIRMATION,
        id,
        is_manually,
        walletType: wallet_type,
      })
    );
  }, []);

  const rejectWithdrawal = useCallback(() => {
    dispatch(showModal({ type: ModalType.REJECT_WITHDRAWAL, id, withdrawal_id }));
  }, []);

  const confirmApprovalOfWithdrawal = useCallback(() => {
    dispatch(
      showModal({
        type: ModalType.CONFIRM_APPROVAL_CONFIRMATION,
        id,
        withdrawal_id,
        walletType: wallet_type,
      })
    );
  }, []);

  const confirmRejectionOfWithdrawal = useCallback(() => {
    dispatch(
      showModal({
        type: ModalType.CONFIRM_REJECTION_CONFIRMATION,
        id,
        withdrawal_id,
      })
    );
  }, []);

  const [displayRequestDate] = split(moment(created_at).format(`DD.MM.YYYY HH:mm:ss`));

  const logo = useSelector(paymentSystemLogoSelector(wallet_type));
  const walletTypeField = <WalletImg title={wallet_type} src={logo} />;

  let senderField = wallet_sender;
  if (wallet_sender_identifier) {
    senderField = [wallet_sender, wallet_sender_identifier].join(` | `);
  }

  const labelField = (
    <div className="multi-badge-cell">
      <span className="badge badge-success">{label}</span>
    </div>
  );

  const statusField = (
    <div className="multi-badge-cell">
      <span className={`badge ${get(withdrawalStatusField, `${status}.badge`)}`}>
        {t(get(withdrawalStatusField, `${status}.text`))}
      </span>
    </div>
  );

  const kind = is_test ? WithdrawalKind.DEBUG : WithdrawalKind.CONFIRMED;

  const kindField = (
    <div className="multi-badge-cell">
      <span className={`badge ${get(withdrawalKindField, `${kind}.badge`)}`}>
        {t(get(withdrawalKindField, `${kind}.text`))}
      </span>
    </div>
  );

  const typeField = (
    <div className="multi-badge-cell">
      <span className={`badge ${get(withdrawalTypeField, `${type}.badge`)}`}>
        {t(get(withdrawalTypeField, `${type}.text`))}
      </span>
    </div>
  );

  let postbackField;
  if (isNull(postback)) {
    postbackField = null;
  } else {
    postbackField = (
      <button
        onClick={() => {
          const data: Record<string, any> = {
            type: ModalType.POSTBACK_LIST,
            id: postback_id,
            direction: `outgoing`,
          };
          if (postback_status === PostbackFilter.ERROR) {
            data.type = ModalType.NOT_ACCEPTED_TRANSACTION_POSTBACKS;
          }
          dispatch(showModal(data));
        }}
      >
        {get(postbackResponseStatusToIcon, postback_status, ``)}
      </button>
    );
  }

  const commentView = <span dangerouslySetInnerHTML={{ __html: comment as string }} />;

  const showMainControls = status === WithdrawalStatus.NEW && manageAccess;

  const showCheckingControls = status === WithdrawalStatus.CHECK && manageAccess;

  const showErrorControls = status === WithdrawalStatus.ERROR && manageAccess;

  const controlsField = (
    <div className="btn-group">
      {(withdrawalViewAccess || manageAccess) && (
        <button
          type="button"
          className="btn btn-sm btn-secondary"
          onClick={() => dispatch(showModal({ type: ModalType.SHOW, id }))}
        >
          <i className="fa fa-eye" />
        </button>
      )}
      {(showMainControls || showErrorControls) && (
        <>
          <button type="button" className="btn btn-sm btn-success" onClick={approveWithdrawal}>
            {showErrorControls ? t(`common.retry`) : t(`common.approve`)}
          </button>
          <button type="button" className="btn btn-sm btn-danger" onClick={rejectWithdrawal}>
            {t(`common.reject`)}
          </button>
        </>
      )}
      {showCheckingControls && (
        <>
          <button type="button" className="btn btn-sm btn-secondary" onClick={confirmApprovalOfWithdrawal}>
            <i className="fa fa-check text-success mr-2" />
            {t(`withdrawals.checkApprovedBtn`)}
          </button>
          <button type="button" className="btn btn-sm btn-secondary" onClick={confirmRejectionOfWithdrawal}>
            <i className="fa fa-close text-danger mr-2" />
            {t(`withdrawals.checkRejectedBtn`)}
          </button>
        </>
      )}
    </div>
  );

  const methodField = is_manually ? t(`withdrawals.manualMethod`) : t(`common.api`);

  const columns = [
    {
      id: WithdrawalsColumn.ID,
      desktop: <td>{id}</td>,
      mobile: (
        <tr>
          <td>{t(`common.ID`)}</td>
          <td>{id}</td>
        </tr>
      ),
      hide: !manageAccess,
    },
    {
      id: WithdrawalsColumn.TYPE,
      desktop: <td>{walletTypeField}</td>,
      mobile: (
        <tr>
          <td>{t(`common.type`)}</td>
          <td>{walletTypeField}</td>
        </tr>
      ),
    },
    {
      id: WithdrawalsColumn.METHOD,
      desktop: <td>{methodField}</td>,
      mobile: (
        <tr>
          <td>{t(`withdrawals.withdrawalMethod`)}</td>
          <td>{methodField}</td>
        </tr>
      ),
      hide: !manageAccess,
    },
    {
      id: WithdrawalsColumn.REQUEST_DATE,
      desktop: <td>{displayRequestDate}</td>,
      mobile: (
        <tr>
          <td>{t(`withdrawals.requestDate`)}</td>
          <td>{displayRequestDate}</td>
        </tr>
      ),
    },
    {
      id: WithdrawalsColumn.WITHDRAWAL_ID,
      desktop: <td>{withdrawal_id}</td>,
      mobile: (
        <tr>
          <td>{t(`withdrawals.withdrawalId`)}</td>
          <td>{withdrawal_id}</td>
        </tr>
      ),
    },
    {
      id: WithdrawalsColumn.REQUEST_TYPE,
      desktop: <td>{typeField}</td>,
      mobile: (
        <tr>
          <td>{t(`withdrawals.requestType`)}</td>
          <td>{typeField}</td>
        </tr>
      ),
      hide: !manageAccess,
    },
    {
      id: WithdrawalsColumn.WALLET_SENDER,
      desktop: <td>{senderField}</td>,
      mobile: senderField && (
        <tr>
          <td>{t(`withdrawals.walletSender`)}</td>
          <td>{senderField}</td>
        </tr>
      ),
      hide: !manageAccess,
    },
    {
      id: WithdrawalsColumn.WALLET_RECIPIENT,
      desktop: <td>{wallet_recipient}</td>,
      mobile: (
        <tr>
          <td>{t(`withdrawals.walletRecipient`)}</td>
          <td>{wallet_recipient}</td>
        </tr>
      ),
    },
    {
      id: WithdrawalsColumn.LABEL,
      desktop: <td>{labelField}</td>,
      mobile: (
        <tr>
          <td>{t(`common.label`)}</td>
          <td>{labelField}</td>
        </tr>
      ),
    },
    {
      id: WithdrawalsColumn.AMOUNT,
      desktop: <td>{formattedAmount}</td>,
      mobile: (
        <tr>
          <td>{t(`common.amount`)}</td>
          <td>{formattedAmount}</td>
        </tr>
      ),
    },
    {
      id: WithdrawalsColumn.STATUS,
      desktop: <td>{statusField}</td>,
      mobile: (
        <tr>
          <td>{t(`common.status`)}</td>
          <td>{statusField}</td>
        </tr>
      ),
    },
    {
      id: WithdrawalsColumn.KIND,
      desktop: <td>{kindField}</td>,
      mobile: (
        <tr>
          <td>{t(`withdrawals.kind`)}</td>
          <td>{kindField}</td>
        </tr>
      ),
    },
    {
      id: WithdrawalsColumn.POSTBACK,
      desktop: <td>{postbackField}</td>,
      hide: !canViewPostbacks,
      mobile: (
        <tr>
          <td>{t(`postbacks.postback`)}</td>
          <td>{postbackField}</td>
        </tr>
      ),
    },
    {
      id: WithdrawalsColumn.COMMENT,
      desktop: <td>{commentView}</td>,
      mobile: (
        <tr>
          <td>{t(`common.comment`)}</td>
          <td>{commentView}</td>
        </tr>
      ),
    },
    {
      id: WithdrawalsColumn.ACTIONS,
      desktop: <td>{controlsField}</td>,
      mobile: (
        <tr>
          <td>{t(`common.actions`)}</td>
          <td>{controlsField}</td>
        </tr>
      ),
      hide: !manageAccess && !withdrawalViewAccess,
    },
  ];

  return useFilterColumns({ columns, desktopSize, hiddenColumns });
};

export default WithdrawalRow;
