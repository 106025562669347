export const useAccountBalanceHistoryTitles = () => {
  return [
    {
      title: `wallets.dateTime`,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.amount`,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `wallets.commission`,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `transactions.typeOrAccount`,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.currency`,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `wallets.exchangeRate`,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `balances.extraInfo`,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.actions`,
      desktop: true,
      mobile: true,
      disabled: true,
    },
  ];
};
