import React from 'react';

import { WidgetType } from 'utils/enums';
import { withdrawalsLogsActionCreators } from 'actions/widgets/walletsDebugging/withdrawalsLogs';

import CommonLogMessage from 'components/widgets/walletsDebugging/LogMessage';

const WithdrawalLogMessage = () => {
  return <CommonLogMessage widget={WidgetType.WITHDRAWALS_LOGS} actionCreators={withdrawalsLogsActionCreators} />;
};

export default WithdrawalLogMessage;
