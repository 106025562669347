import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import { IUpdateDepositsPreparedData } from 'interfaces/widgets/constructor/paymentsConstructor';

export class LimitsApi extends BaseApi {
  loadDeposits = (paymentSystem: string): AxiosPromise => this.makeFetch(`{paymentSystem}`, {}, { paymentSystem });

  updateDeposits = (paymentSystem: string, data: IUpdateDepositsPreparedData[]): AxiosPromise =>
    this.makeFetch(`{paymentSystem}`, { method: `POST`, data }, { paymentSystem });
}

const depositApi = new LimitsApi(`wallet-type/limits`);

export default depositApi;
