import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import { WidgetContainer, PageContainer } from '@kassma-team/kassma-toolkit/lib';

import { hasAccessSelector } from 'selectors/auth';
import { appVersionSelector } from 'selectors/widgets/settings';
import { Permission } from 'utils/enums';
import { loadSettings } from 'actions/widgets/settings';

import PluginSettingsForm from 'components/widgets/settings/pluginSettings/AppearanceSettingsForm';
import SettingsBlockWrap from 'components/widgets/settings/SettingsBlockWrap';
import ProfileSettings from 'components/widgets/settings/ProfileSettings';
import SystemSettingsForm from 'components/widgets/settings/SystemSettingsForm';
import PrivateSettings from 'components/widgets/settings/PrivateSettings';
import PluginInstallation from 'components/widgets/settings/PluginInstallation';
import Header from 'components/header/Header';
import ChangePassword from 'components/widgets/settings/ChangePassword';
import TabsBar from 'components/widgets/TabsBar';

import 'pages/settings/settings.scss';
import useSearchParams from '../../hooks/useSearchParams';

const Settings = (): JSX.Element => {
  const [t] = useTranslation();

  const pluginAccess = useSelector(hasAccessSelector(Permission.PLUGIN_SETTINGS));
  const pluginClientAccess = useSelector(hasAccessSelector(Permission.PLUGIN_SETTINGS_CLIENT));
  const systemSettingsAccess = useSelector(hasAccessSelector(Permission.VIEW_SYSTEM_SETTINGS));
  const setTimezoneAccess = useSelector(hasAccessSelector(Permission.TIMEZONE_SET));
  const privateSettingsAccess = useSelector(hasAccessSelector(Permission.PRIVATE_SETTINGS_VIEW));
  const appVersion = useSelector(appVersionSelector);
  const { tab } = useSearchParams();

  const dispatch = useDispatch();

  const tabs = [
    {
      title: t(`settings.profileSettings`),
      component: () => (
        <>
          <ChangePassword />
          <ProfileSettings />
        </>
      ),
    },
    ...(systemSettingsAccess || setTimezoneAccess
      ? [
          {
            title: t(`settings.systemSettings`),
            component: () => <SystemSettingsForm />,
          },
        ]
      : []),
    ...(privateSettingsAccess
      ? [
          {
            title: t(`settings.confidentialSettings`),
            component: () => <PrivateSettings />,
          },
        ]
      : []),
    {
      title: t(`settings.pluginAppearance`),
      hasAccess: pluginAccess || pluginClientAccess,
      component: () => (
        <SettingsBlockWrap title="settings.pluginSettings">
          <PluginSettingsForm />
        </SettingsBlockWrap>
      ),
    },
    {
      title: t(`settings.pluginInstallation`),
      hasAccess: pluginClientAccess,
      component: () => <PluginInstallation />,
    },
  ];

  useEffect(() => {
    if (tab === `1`) dispatch(loadSettings());
  }, [tab]);

  return (
    <PageContainer>
      <Helmet>
        <title>{t(`settings.settings`)}</title>
      </Helmet>
      <Header />
      <WidgetContainer title="settings.settings">
        <TabsBar tabs={tabs} containerClassNames="settings-page" />
        <div className="settings-page-version-block">{appVersion}</div>
      </WidgetContainer>
    </PageContainer>
  );
};

export default Settings;
