import React, { FC } from 'react';
import { MessagesList } from './MessagesList';
import { useMessages } from './useMessages';
import { useNotificationUi } from '../useNotificationUi';
import './Messages.scss';

export const Messages: FC = () => {
  const {
    readedMessages,
    isBtnShowed,
    isReadedShowed,
    getLastTwoReaded,
    getAllReaded,
    checkIsReadedMessages,
    toggleMessages,
  } = useMessages();

  const { title, showAllBtn } = useNotificationUi();

  return (
    <div className="Messages">
      <h5 className="Messages__title">{title}</h5>
      <div className="Messages__line" />
      <MessagesList type="unreaded" messages={isReadedShowed ? getAllReaded() : getLastTwoReaded()} />
      {/* eslint-disable */}
      {/* prettier-ignore Force code style*/}
      {checkIsReadedMessages() && <MessagesList type="readed" messages={readedMessages} />}
      {/* eslint-enable */}
      {isBtnShowed && (
        <button className="Messages__btn" onClick={toggleMessages}>
          {showAllBtn}
        </button>
      )}
    </div>
  );
};
