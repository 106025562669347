import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InputType } from '@kassma-team/kassma-toolkit/lib';

import useArrayFields from 'hooks/widgets/useArrayFields';

const useForGeneratingAutoreportList = () => {
  const [t] = useTranslation();

  const fields = () => [
    {
      // placeholder: `Email`,
      placeholder: t(`wallets.email`),
      name: `autoreport_email`,
      type: InputType.TEXT,
      wrapClassName: `w-90 mr-5`,
      withModalScroll: true,
      email: true,
      required: true,
    },
  ];

  return useArrayFields({
    fields: fields,
    title: t(`reports.recipient`),
    name: `pre_email`,
  });
};

export default useForGeneratingAutoreportList;
