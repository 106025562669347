import { ICreateApiFetchOptions } from 'api/createApiFetch';
import apiFetch from 'api/apiFetch';
import { AxiosPromise } from 'axios';

export class BaseApi {
  private BASE_URL = ``;

  constructor(baseUrl: string) {
    this.BASE_URL = baseUrl;
  }

  private readonly getURL = (path: string, pathOptions?: Record<string, string | number>): string => {
    let url = `${this.BASE_URL}/${path}`;
    if (pathOptions) {
      for (const param in pathOptions) {
        url = url.replace(`{${param}}`, pathOptions[param].toString());
      }
    }

    return url;
  };

  protected readonly makeFetch = <T = unknown>(
    path: string,
    options?: ICreateApiFetchOptions,
    pathOptions?: Record<string, string | number>
  ): AxiosPromise<T> => apiFetch(this.getURL(path, pathOptions), options, path);
}
