import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { IModalFormProps, InputType, widgetModalSelector } from '@kassma-team/kassma-toolkit/lib';
import { Dispatch } from 'redux';

import { WidgetType } from 'utils/enums';
import { updateWithdrawalComment, withdrawalsActionCreators } from 'actions/widgets/withdrawals';
import { WITHDRAWAL_COMMENT_FORM_NAME } from 'utils/constants';

import ModalForm from 'components/modals/ModalForm';

const WithdrawalComment = (props: IModalFormProps) => {
  const modal = useSelector(widgetModalSelector(WidgetType.WITHDRAWALS));
  const comment = get(modal, `comment`);
  const id = get(modal, `id`);

  const [t] = useTranslation();

  const [fields] = useState([
    {
      name: `comment`,
      isModalField: true,
      label: t(`withdrawals.comment`),
      type: InputType.TEXT_EDITOR,
      forcedShowingError: true,
      maxLength: 100,
    },
  ]);

  useEffect(() => {
    if (comment) {
      props.initialize({ comment });
    }
  }, [comment]);

  return (
    <ModalForm
      {...props}
      fields={fields}
      submitText="common.confirm"
      onSubmit={(values: Record<`comment`, Record<string, string>>, dispatch: Dispatch) =>
        dispatch(updateWithdrawalComment(id)({ ...values }))
      }
      actionCreators={withdrawalsActionCreators}
      widget={WidgetType.WITHDRAWALS}
      title="withdrawals.comment"
    />
  );
};

export default reduxForm({
  form: WITHDRAWAL_COMMENT_FORM_NAME,
})(WithdrawalComment);
