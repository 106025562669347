import { takeEvery, put, select } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import get from 'lodash/get';
import { AxiosError, AxiosResponse } from 'axios';
import { apiUrlSelector, createWidgetAction, IAction } from '@kassma-team/kassma-toolkit/lib';

import { getErrorMessage } from 'utils';
import refreshSaga from 'sagas/effects/refreshSaga';
import i18n from 'i18n';
import {
  UPLOAD_PAYTM_COOKIES_REQUESTED,
  UPLOAD_PAYTM_COOKIES_SUCCEEDED,
  DELETE_PAYTM_COOKIES_REQUESTED,
  DELETE_PAYTM_COOKIES_SUCCEEDED,
  DELETE_PAYTM_COOKIES_FAILED,
  FETCH_PAYTM_COOKIES,
} from 'actionTypes';
import { paytmCookiesActionCreators } from 'actions/widgets/walletsDebugging/paytmCookies';
import { paytmActionCreators } from 'actions/widgets/payments/paytm';
import { WidgetType } from 'utils/enums';
import paytmCookiesLogApi from 'api/logs/PaytmCookiesLogApi';
import walletApi from 'api/wallet/WalletApi';
import { tokenSelector } from '../../selectors/auth';

interface IUploadCookiesProps {
  payload: string;
  action: Record<string, unknown>;
}

function* uploadCookies({ payload, ...action }: IUploadCookiesProps) {
  yield refreshSaga({
    request: () => paytmCookiesLogApi.uploadPaytmCookies(payload),
    onSuccess: function* (resp: AxiosResponse) {
      const payload = get(resp, `data.cookie_file_list`);
      yield put({ ...action, type: UPLOAD_PAYTM_COOKIES_SUCCEEDED, payload });

      const invalidUploadedFiles = get(payload, `unloaded_files`, []);
      if (invalidUploadedFiles.length === 0) {
        yield put(paytmCookiesActionCreators.hideModal());
        yield toastr.success(i18n.t(`common.success`), i18n.t(`logs.filesHaveBeenSuccessfullyUploaded`));
      }
    },
    onError: function* (e: AxiosError) {
      yield toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

interface IDeleteCookiesProps {
  payload: number;
}

function* deleteCookies({ payload }: IDeleteCookiesProps) {
  yield refreshSaga({
    request: () => walletApi.deletePaytmWalletData(payload),
    onSuccess: function* (resp: AxiosResponse) {
      const totalDeletedFiles = get(resp, `data.total_deleted_files`);
      yield put(paytmActionCreators.hideModal());
      yield put(
        createWidgetAction({
          widget: WidgetType.PAYTM_COOKIES,
        })(DELETE_PAYTM_COOKIES_SUCCEEDED)()
      );

      if (totalDeletedFiles) {
        yield toastr.success(
          i18n.t(`common.success`),
          i18n.t(`wallets.successfullyDeletedCookies`, { count: totalDeletedFiles })
        );
      } else {
        yield toastr.warning(i18n.t(`common.absent`), i18n.t(`wallets.noCookiesToDelete`));
      }
    },
    onError: function* () {
      yield put(paytmActionCreators.hideModal());
      yield put(
        createWidgetAction({
          widget: WidgetType.PAYTM_COOKIES,
        })(DELETE_PAYTM_COOKIES_FAILED)()
      );
      yield toastr.error(i18n.t(`common.error`), i18n.t(`wallets.failedToDeleteCookies`));
    },
  });
}

function* fetchPaytmCookiesSaga() {
  const token: string = yield select(tokenSelector);
  const apiUrl: string = yield select(apiUrlSelector);
  yield refreshSaga({
    request: () => paytmCookiesLogApi.downloadCookies(token),
    onSuccess: function (resp: AxiosResponse) {
      const {
        config: { url },
      } = resp;
      const fullPath = `${apiUrl}/${url}`;
      window.open(fullPath, `_blank`);
    },
  });
}

const paytmCookiesSagas = [
  takeEvery<IAction>(UPLOAD_PAYTM_COOKIES_REQUESTED, uploadCookies),
  takeEvery<IAction>(DELETE_PAYTM_COOKIES_REQUESTED, deleteCookies),
  takeEvery<IAction>(FETCH_PAYTM_COOKIES, fetchPaytmCookiesSaga),
];

export default paytmCookiesSagas;
