import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import union from 'lodash/union';
import filter from 'lodash/filter';
import omit from 'lodash/omit';
import isObject from 'lodash/isObject';
import size from 'lodash/size';
import isString from 'lodash/isString';
import has from 'lodash/has';
import pickBy from 'lodash/pickBy';
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { helpGifsSelector } from 'selectors/widgets/settings';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputType } from '@kassma-team/kassma-toolkit/lib';
import { Dispatch } from 'redux';

import { deletePluginHelpImages, settingsActionCreators, updatePluginHelpImages } from 'actions/widgets/settings';
import { transactionPSSelector } from 'selectors/widgets/paymentSystems';
import { WidgetType } from 'utils/enums';
import { HELP_GIFS } from 'utils/constants';

import ModalForm from 'components/modals/ModalForm';
import { HelpGifsFormProps } from 'interfaces/widgets/settings/pluginSettings';

interface IFields {
  name: string;
  label: string;
  type: string;
  fileAcceptedTypes: string[];
  maxSize: number;
  deleteFileHandler: () => void;
  dropFileHandler: () => void;
}

const HelpGifsForm = (props: InjectedFormProps) => {
  const [t] = useTranslation();

  const paymentSystemList = useSelector(transactionPSSelector);
  const gifs: any = useSelector(helpGifsSelector);
  const [inited, setInited] = useState(false);
  const [deletedImages, setDeletedImages] = useState<string[]>([]);
  const [fields, setFields] = useState<IFields[]>([]);

  const values = useSelector(getFormValues(props.form));

  useEffect(() => {
    if (gifs) {
      setFields(
        map(paymentSystemList, (field) => {
          const name = field.code;
          const id = gifs[name]?.id;

          return {
            name,
            label: field.name,
            type: InputType.DROPZONE,
            fileAcceptedTypes: [`gif`, `mp4`, `m4v`, `mpeg`, `mpg`],
            maxSize: 1024 * 1024 * 200,
            deleteFileHandler: () => {
              if (id) {
                setDeletedImages(union(deletedImages, [id]));
              }
              props.initialize(omit(values, name));
            },
            dropFileHandler: () => {
              if (id) {
                setDeletedImages(filter(deletedImages, (item) => item !== id));
              }
            },
          };
        })
      );
    }
  }, [deletedImages, values, gifs]);

  useEffect(() => {
    if (!inited && gifs) {
      setInited(true);
      if (gifs) {
        const data = { ...gifs };
        for (const key in data) {
          data[key] = data[key].url;
        }

        props.initialize(data);
      }
    }
  }, [gifs]);

  const onSubmit = (values: HelpGifsFormProps, dispatch: Dispatch) => {
    const data = reduce(
      values,
      (result, value, key) => {
        if (isString(value)) {
          return result;
        }

        return {
          ...result,
          [key]: value,
        };
      },
      []
    );
    if (deletedImages) {
      dispatch(deletePluginHelpImages(deletedImages));
      setDeletedImages([]);
    }
    if (data) {
      dispatch(updatePluginHelpImages(data));
    }
  };

  return (
    <ModalForm
      {...props}
      fields={fields}
      submitText={t(`common.save`)}
      onSubmit={onSubmit}
      actionCreators={settingsActionCreators}
      widget={WidgetType.SETTINGS}
      title="settings.mediaUploading"
    />
  );
};

export default reduxForm({
  form: HELP_GIFS,
})(HelpGifsForm);
