import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import useUnmount from 'react-use/lib/useUnmount';
import { IModalFormProps, InputType, Size } from '@kassma-team/kassma-toolkit/lib';

import { proxyActionCreators, resetProxiesData, uploadProxies } from 'actions/widgets/proxy';
import { WidgetType } from 'utils/enums';
import {
  invalidDataOfProxiesRelationsSelector,
  invalidFormatOfProxiesRelationsSelector,
} from 'selectors/widgets/proxies';

import ModalElement from 'components/modals/ModalElement';
import ModalForm from 'components/modals/ModalForm';

const MODAL_TITLE = `proxies.proxiesRelationsUploading`;

const ProxiesRelations = (props: IModalFormProps) => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const invalidFormat = useSelector(invalidFormatOfProxiesRelationsSelector);
  const invalidData = useSelector(invalidDataOfProxiesRelationsSelector);

  const [fields] = useState([
    {
      name: `proxies`,
      type: InputType.TEXTAREA,
      required: true,
      placeholder: t(`proxies.insertListOfRelationsWithProxies`),
    },
  ]);

  const onSubmit = useCallback((values, dispatch) => {
    dispatch(uploadProxies(values));
  }, []);

  useUnmount(() => {
    dispatch(resetProxiesData());
  });

  if (invalidFormat || invalidData) {
    const content = (
      <>
        {invalidData && (
          <div className="mb-20">
            <div className="mb-10">{t(`proxies.invalidDataOfPaytmProxiesRelations`)}</div>
            <pre id="invalid_data_error" className="border-top pt-5">
              {invalidData}
            </pre>
          </div>
        )}
        {invalidFormat && (
          <div className="mb-10">
            <div className="mb-10">{t(`proxies.invalidFormatOfPaytmProxiesRelations`)}</div>
            <pre id="invalid_format_error" className="border-top pt-5 mb-10">
              {invalidFormat}
            </pre>
            <span className="font-weight-bold">{t(`proxies.paytmProxiesRowsMustBeRight`)}</span>
          </div>
        )}
      </>
    );

    const footer = (
      <div className="flex justify-content-center p-10">
        <button
          id="got_it_btn"
          className="btn btn-alt-primary"
          onClick={() => dispatch(proxyActionCreators.hideModal())}
        >
          {t(`common.gotIt`)}
        </button>
      </div>
    );

    return (
      <ModalElement
        title={MODAL_TITLE}
        actionCreators={proxyActionCreators}
        size={Size.BIG}
        content={content}
        footer={footer}
      />
    );
  }

  return (
    <ModalForm
      {...props}
      fields={fields}
      actionCreators={proxyActionCreators}
      widget={WidgetType.PROXIES}
      title={MODAL_TITLE}
      onSubmit={onSubmit}
      submitText="common.upload"
    />
  );
};

export default reduxForm({
  form: `paytm-proxies-relations`,
})(ProxiesRelations);
