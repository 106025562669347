import { TransactionCreationType } from 'utils/enums';
import { ITransactionType } from 'interfaces/widgets/transaction/transaction';

const transactionType: Record<number, ITransactionType> = {
  [TransactionCreationType.AUTO]: {
    text: `transactions.auto`,
    badge: `badge-primary`,
  },
  [TransactionCreationType.MANUAL]: {
    text: `transactions.manual`,
    badge: `badge-danger`,
  },
};

export default transactionType;
