import React, { ReactNode } from 'react';
import {
  TableContentContainer as KassmaTableContentContainer,
  IFilterSearchParams,
  IAction,
  IExtraHeaderButton,
} from '@kassma-team/kassma-toolkit/lib';
import { useDispatch } from 'react-redux';

import useSearchParams from 'hooks/useSearchParams';
import { Dispatch } from 'redux';

export interface ITableContentContainerProps {
  perPage?: number;
  search?: IFilterSearchParams;
  dispatch?: Dispatch;
  title?: string | ReactNode;
  onRefresh?: (payload?: any) => IAction;
  children?: ReactNode;
  loading?: boolean;
  withFilters?: boolean;
  extraHeaderButtons?: IExtraHeaderButton[];
  extraHeaderContent?: ReactNode;
  tableHeaderContent?: ReactNode;
  translatedTitle?: boolean;
  refreshingIsDisabled?: boolean;
}

const TableContentContainer = (props: ITableContentContainerProps) => {
  const search = useSearchParams();

  const dispatch = useDispatch();

  return <KassmaTableContentContainer {...props} search={search} dispatch={dispatch} />;
};

export default TableContentContainer;
