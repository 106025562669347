import React from 'react';

import { postbackActionCreators } from 'actions/widgets/postback';
import { WidgetType } from 'utils/enums';

import PostbackRequest from 'components/widgets/postback/PostbackRequest';

const PostbackRequestModal = () => {
  return <PostbackRequest actionCreators={postbackActionCreators} widget={WidgetType.POSTBACKS} />;
};

export default PostbackRequestModal;
