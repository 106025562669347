import { toastr } from 'react-redux-toastr';

import { WidgetType } from 'utils/enums';

import api from 'api';

import widgetSagas from 'sagas/common/widgetSagas';
import transactionsApi from 'api/transactions/TransactionsApi';

const listFetching = {
  [WidgetType.WALLETS_STATISTIC]: api.walletsApi.getStatistics,
  [WidgetType.PROXIES]: api.proxy.loadProxyList,
  [WidgetType.WALLETS]: api.walletsApi.loadWalletList,
  [WidgetType.DISABLED_WALLETS]: api.walletsApi.loadDisabledWalletList,
  [WidgetType.REMOVED_WALLETS]: api.walletsApi.loadRemovedWalletList,
  [WidgetType.NOT_REMOVED_WALLETS]: api.walletsApi.loadNotRemovedWalletList,
  [WidgetType.CURRENCIES]: api.currency.loadCurrencyList,
  [WidgetType.PAYMENT_HINT_LANGS]: api.paymentHint.loadLangs,
  // [WidgetType.TRANSACTIONS]: api.transaction.loadTransactionList,
  [WidgetType.TRANSACTIONS]: transactionsApi.fetch(`withoutArchived`),
  // [WidgetType.TRANSACTIONS_ONLY_BY_ID]: api.transaction.loadTransactionList,
  [WidgetType.TRANSACTIONS_ONLY_BY_ID]: transactionsApi.fetch(`withoutArchived`),
  // [WidgetType.ARCHIVED_TRANSACTIONS]: api.archiveTransaction.loadArchivedTransactionList,
  [WidgetType.ARCHIVED_TRANSACTIONS]: transactionsApi.fetch(`onlyArchived`),
  [WidgetType.POSSIBLE_TRANSACTIONS]: api.possibleTransaction.loadTransactionList,
  [WidgetType.PRELIMINARY_TRANSACTIONS]: api.pretransaction.loadPreliminaryTransactions,
  [WidgetType.PRELIMINARY_TRANSACTIONS_ONLY_BY_ID]: api.pretransaction.loadPreliminaryTransactions,
  [WidgetType.STOCKPILING_LIST]: api.stockpiling.loadStockpilingList,
  [WidgetType.POSTBACKS]: api.postback.loadPostbackList,
  [WidgetType.NOT_ACCEPTED_POSTBACKS]: api.postback.loadNotAcceptedPostbackList,
  [WidgetType.WALLETS_LOAD]: api.wallet.loadWalletsLoad,
  [WidgetType.LOGS]: api.log.loadLogList,
  [WidgetType.WITHDRAWALS_LOGS]: api.log.loadWithdrawalsLogList,
  [WidgetType.PERMISSIONS]: api.rbacPermission.loadPermissionList,
  //[WidgetType.MANUAL_PAYMENT_SYSTEMS]: api.testPaymentSystem.loadManualPaymentSystemList,
  // [WidgetType.PAYMENT_SYSTEMS]: api.walletTypes.loadPaymentSystems,
  [WidgetType.PAYMENT_SYSTEMS]: api.pluralWalletTypesApi.list,
  // [WidgetType.PAYMENT_SYSTEMS_FOR_FORCED_TRANSACTIONS]: api.wallet.loadPaymentSystemsForForcedTransactions,
  [WidgetType.PAYMENT_SYSTEMS_FOR_FORCED_TRANSACTIONS]: api.pluralWalletTypesApi.list,
  // [WidgetType.PAYMENT_SYSTEMS_FOR_POSSIBLE_TRANSACTIONS]: api.walletTypes.loadPaymentSystemsForPossibleTransactions,
  [WidgetType.PAYMENT_SYSTEMS_FOR_POSSIBLE_TRANSACTIONS]: api.pluralWalletTypesApi.list,
  [WidgetType.DASHBOARD_PAYMENT_SYSTEMS_INFO]: api.dashboard.loadDashboardPaymentSystemsInfo,
  // [WidgetType.PAYMENTS_CONSTRUCTOR]: api.walletTypes.loadPaymentList,
  [WidgetType.PAYMENTS_CONSTRUCTOR]: api.pluralWalletTypesApi.list,
  [WidgetType.PAYMENT_HINTS_CONSTRUCTOR]: api.paymentHint.loadPaymentHintList,
  [WidgetType.PAYTM_COOKIES]: api.paytmCookiesLog.loadPaytmCookies,
  [WidgetType.COUNTRIES]: api.countries.loadCountryList,
  [WidgetType.REPORTS]: api.report.loadReportList,
  [WidgetType.AUTOREPORTS]: api.autoreport.loadAutoReportsColumns,
  [WidgetType.CHANGE_LOGS]: api.changeLogs.loadChangeLogList,
  [WidgetType.CHANGE_LOG_ACTIONS]: api.changeLogs.loadChangeLogActionList,
  [WidgetType.CHANGE_LOG_OBJECTS]: api.changeLogs.loadChangeLogObjectList,
  [WidgetType.CHANGE_LOG_USERS]: api.changeLogs.loadChangeLogUsers,
  [WidgetType.TIMEZONES]: api.timezones.loadTimezoneList,
  [WidgetType.WITHDRAWALS]: api.withdrawal.loadWithdrawalList,
  [WidgetType.WITHDRAWALS_BANK_CODES]: api.withdrawal.loadBankCodeList,
  [WidgetType.PAYGIGA_BANK_CODES]: api.withdrawal.loadPaygigaBankCodeList,
  [WidgetType.PAYGIGA_AVAILABLE_AMOUNTS]: api.withdrawal.loadPaygigaAvailableAmountList,
  [WidgetType.WITHDRAWALS_BRANCH_CODES]: api.withdrawal.loadBranchCodeList,
  [WidgetType.WITHDRAWALS_PAYOUT_METHODS]: api.withdrawal.loadPayoutMethodList,
  [WidgetType.WITHDRAWALS_PROVIDERS]: api.withdrawal.loadProviderList,
  [WidgetType.WITHDRAWALS_CURRENCY_CODES]: api.walletTypes.loadCurrencyCodeList,
  [WidgetType.WITHDRAWALS_CARDS]: api.withdrawal.loadCardList,
  // [WidgetType.WITHDRAWALS_PAYMENT_SYSTEMS]: api.dashboard.loadWithdrawalsPaymentSystems,
  [WidgetType.WITHDRAWALS_PAYMENT_SYSTEMS]: api.pluralWalletTypesApi.list,
  [WidgetType.WITHDRAWALS_STATUS]: api.withdrawal.loadStatusList,
  [WidgetType.ACCOUNTS_BALANCES]: api.balanceHistory.loadCurrentBalance,
  [WidgetType.STOCKPILING_TRANSACTIONS]: api.transaction.loadStockpilingTransactions,
  [WidgetType.SETTLEMENT_WITHDRAWAL]: api.settlementWithdrawal.loadSettlementWithdrawal,
  [WidgetType.REPLENISHMENT]: api.settlement.loadReplenishment,
  // [WidgetType.TRANSACTIONS_PAYMENT_SYSTEMS]: api.dashboard.loadTransactionPaymentSystems,
  [WidgetType.TRANSACTIONS_PAYMENT_SYSTEMS]: api.pluralWalletTypesApi.list,
  [WidgetType.LOGS_CATEGORIES]: api.log.loadLogsCategoryList,
  [WidgetType.WALLET_ADMIN_STATUS]: api.wallet.loadAdminStatuses,
  [WidgetType.BALANCE_TRANSFER_HISTORY]: api.accountBalance.loadTransferHistory,
  [WidgetType.ACCOUNT_BALANCE_HISTORY]: api.balanceHistory.loadAccountBalanceHistory,
};

const itemFetching = {
  [WidgetType.AUTOREPORTS]: api.autoreport.loadAutoReport,
  [WidgetType.PROXIES]: api.proxy.loadProxyItem,
  [WidgetType.WALLETS]: api.wallet.loadWalletItem,
  [WidgetType.DISABLED_WALLETS]: api.wallet.loadWalletItem,
  [WidgetType.PAYMENT_HINTS_CONSTRUCTOR]: api.paymentHint.loadPaymentHintItem,
  [WidgetType.WITHDRAWALS]: api.withdrawal.loadWithdrawalItem,
  [WidgetType.WALLET_PAYZEN]: api.payment.loadPayzenData,
  [WidgetType.WITHDRAWALS_APPROVAL_DATA]: api.withdrawal.loadApprovalOfWithdrawalData,
  [WidgetType.POSTBACKS]: api.postback.loadPostback,
  [WidgetType.NOT_ACCEPTED_POSTBACKS]: api.postback.loadPostback,
};

const createFetching = {
  [WidgetType.AUTOREPORTS]: api.autoreport.createAutoReports,
  [WidgetType.REMOVED_WALLETS]: api.wallet.restoreWallet,
  [WidgetType.PROXIES]: api.proxy.saveProxy,
  [WidgetType.WALLETS]: api.wallet.createWallet,
  [WidgetType.DISABLED_WALLETS]: api.wallet.createWallet,
  [WidgetType.TRANSACTIONS]: api.transaction.createTransaction,
  [WidgetType.PRELIMINARY_TRANSACTIONS]: api.pretransaction.createPreliminaryTransaction,
  // [WidgetType.MANUAL_PAYMENT_SYSTEMS]: api.testPaymentSystem.createManualPaymentSystem,
  [WidgetType.PAYMENT_HINTS_CONSTRUCTOR]: api.paymentHint.createPaymentHint,
  [WidgetType.PAYTM_COOKIES]: api.paytmCookiesLog.uploadPaytmCookies,
  [WidgetType.WITHDRAWALS]: api.withdrawalV2.createWithdrawal,
  // [WidgetType.MANUAL_PAYMENT_SYSTEMS]: api.testPaymentSystem.createManualPaymentSystem,
};

const updateListItemFetching = {
  [WidgetType.AUTOREPORTS]: api.autoreport.editAutoReports,
  [WidgetType.PROXIES]: api.proxy.updateProxy,
  [WidgetType.WALLETS]: api.wallet.updateWallet,
  [WidgetType.DISABLED_WALLETS]: api.wallet.updateWallet,
  [WidgetType.PAYMENT_HINTS_CONSTRUCTOR]: api.paymentHint.updatePaymentHint,
};

const deleteItemFetching = {
  [WidgetType.AUTOREPORTS]: api.autoreport.deleteAutoReports,
  [WidgetType.WALLETS]: api.wallet.deleteWallet,
  [WidgetType.DISABLED_WALLETS]: api.wallet.deleteDeactivatedWallet,
  [WidgetType.REMOVED_WALLETS]: api.wallet.forcedDeleteWallet,
  [WidgetType.PRELIMINARY_TRANSACTIONS]: api.pretransaction.deletePreliminaryTransaction,
  [WidgetType.PRELIMINARY_TRANSACTIONS_ONLY_BY_ID]: api.pretransaction.deletePreliminaryTransaction,
  [WidgetType.POSSIBLE_TRANSACTIONS]: api.possibleTransaction.deletePossibleTransactions,
  [WidgetType.PAYMENT_HINTS_CONSTRUCTOR]: api.paymentHint.deletePaymentHint,
  [WidgetType.PAYTM_COOKIES]: api.paytmCookiesLog.deletePaytmCookie,
  [WidgetType.PAYMENTS_CONSTRUCTOR]: api.walletType.deletePaymentSystem,
  [WidgetType.ACCOUNT_BALANCE_HISTORY]: api.accountBalance.cancelHistoryTransaction,
};

const multiDeleteFetching = {
  [WidgetType.PROXIES]: api.proxy.deleteProxy,
};

export default widgetSagas({
  listFetching,
  itemFetching,
  createFetching,
  updateListItemFetching,
  deleteItemFetching,
  multiDeleteFetching,
  toastr,
});
