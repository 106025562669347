import { widgetActionCreators, IWidgetActionCreators } from '@kassma-team/kassma-toolkit';
import { WalletStatistic } from 'api/wallets/WalletsApi';
import { WidgetType } from 'utils/enums';

export const walletsSummary: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.WALLETS_SUMMARY,
  labels: { listLabel: `wallets-summary` },
});

export const fetchWalletsStatistic: IWidgetActionCreators = () => ({
  type: `WALLET_STATISTIC_REQUESTED`,
});

export const setWalletStatistics = (data: WalletStatistic) => ({
  type: `WALLET_STATISTIC_SUCCESS`,
  payload: data,
});
