import { useMemo } from 'react';

import useSearchParams from 'hooks/useSearchParams';
import { WalletsLoadColumn, WalletsLoadDirection, WalletsLoadObject, WalletsLoadType } from 'utils/enums';
import { ITableTitleField } from 'interfaces/widgets/widgets';

const useWalletsLoadTableTitles = (): ITableTitleField[] => {
  const search = useSearchParams();

  const { load_object, load_type, direction } = search || {};

  const amountTitle = useMemo(() => {
    if (load_type === WalletsLoadType.TRANSACTIONS_SUM) {
      return direction === WalletsLoadDirection.INGOING
        ? `wallets.amountOfIngoingTransactions`
        : `wallets.amountOfOutgoingTransactions`;
    }
    if (load_type === WalletsLoadType.TRANSACTIONS_COUNT) {
      return direction === WalletsLoadDirection.INGOING
        ? `wallets.quantityOfIngoingTransactions`
        : `wallets.quantityOfOutgoingTransactions`;
    }
  }, [load_type, direction]);

  const amountOfTransactionsInDifferentCurrenciesTitle = useMemo(() => {
    return direction === WalletsLoadDirection.INGOING
      ? `wallets.amountOfIngoingTransactionsInUSD`
      : `wallets.amountOfOutgoingTransactionsInUSD`;
  }, [direction]);

  return [
    {
      title: `ID`,
      id: WalletsLoadColumn.ID,
      hide: load_object === WalletsLoadObject.PAYMENT_SYSTEMS,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.type`,
      id: WalletsLoadColumn.WALLET_TYPE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `wallets.wallet`,
      id: WalletsLoadColumn.WALLET_IDENTIFIER,
      hide: load_object === WalletsLoadObject.PAYMENT_SYSTEMS,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: amountTitle as string,
      id: WalletsLoadColumn.AMOUNT,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: amountOfTransactionsInDifferentCurrenciesTitle,
      id: WalletsLoadColumn.AMOUNT_OF_TRANSACTIONS_IN_DIFFERENT_CURRENCIES,
      desktop: true,
      mobile: true,
      disabled: true,
      hide: load_type !== WalletsLoadType.TRANSACTIONS_SUM,
    },
  ];
};

export default useWalletsLoadTableTitles;
