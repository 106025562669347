import React, { useCallback } from 'react';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { widgetModalSelector } from '@kassma-team/kassma-toolkit/lib';

import { sendPostback, stockpilingListActionCreators } from 'actions/widgets/stockpiling';
import { WidgetType } from 'utils/enums';

import ConfirmationModal from 'components/modals/ConfirmationModal';

const StockpilingSendPostback = () => {
  const dispatch = useDispatch();

  const modal = useSelector(widgetModalSelector(WidgetType.STOCKPILING_LIST));
  const id = get(modal, `id`);
  const onConfirm = useCallback(() => {
    dispatch(sendPostback(id));
  }, [dispatch, id]);

  return (
    <ConfirmationModal
      actionCreators={stockpilingListActionCreators}
      title="stockpilings.actionConfirmation"
      confirmText="common.send"
      content="stockpilings.sureWantSendPostback"
      onConfirm={onConfirm}
    />
  );
};

export default StockpilingSendPostback;
