import React from 'react';

import { WidgetType } from 'utils/enums';
import { transactionActionCreators } from 'actions/widgets/transactions/transactions';

import TransactionActivationModal from 'components/widgets/transaction/transactionActivation/TransactionActivationModal';

const TransactionActivation = () => {
  return <TransactionActivationModal widget={WidgetType.TRANSACTIONS} actionCreators={transactionActionCreators} />;
};

export default TransactionActivation;
