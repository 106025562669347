import React from 'react';

import { WidgetType } from 'utils/enums';

import RemovingModal from 'components/modals/RemovingModal';
import { accountsBalanceHistoryActionCreators } from 'actions/widgets/balances/accountsBalanceHistory';

export const CancelTranslationModal = (): JSX.Element => {
  return (
    <RemovingModal
      widget={WidgetType.ACCOUNT_BALANCE_HISTORY}
      actionCreators={accountsBalanceHistoryActionCreators}
      title="balances.cancelOperationTitle"
      content="balances.cancelModalContent"
      confirmText="OK"
    />
  );
};

export default CancelTranslationModal;
