export const addPoint = (value: string): string => {
  value = value.replace(/,/g, `.`);

  if (value?.startsWith(`0`) && value?.length > 1 && !value?.startsWith(`.`, 1)) {
    value = `0.${value.slice(1)}`;
  }

  return value;
};

export const standardAmountField = (value: string): string => {
  value = addPoint(value);

  if (!/^\d{1,20}(\.{1}\d{0,8})?$/.test(value)) {
    value = value.slice(0, -1);
  }

  return value;
};

export const percentField = (value: string): string => {
  value = addPoint(value);

  if (!/^\d{1,20}(\.{1}\d{0,2})?$/.test(value)) {
    value = value.slice(0, -1);
  }
  const regExp = new RegExp(`^\\d{1,20}(\\.{1}\\d{0,2})?$`);
  const outOfRange = +value > 100;
  const isCorrect = regExp.test(value) && !outOfRange;

  return isCorrect ? value : value.slice(0, -1);
};
