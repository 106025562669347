import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import { IGetNotAcceptedPostbackQueryParams, IGetPostbackQueryParams } from 'interfaces/widgets/postback';

export class PostbackApi extends BaseApi {
  loadPostback = (queryParams: any): AxiosPromise => {
    const id = queryParams.stockpiling_id;
    delete queryParams[`stockpiling_id`];
    delete queryParams[`accepted`];

    return this.makeFetch(`{id}`, { queryParams }, { id });
  };

  loadPostbackList = (queryParams: IGetPostbackQueryParams): AxiosPromise =>
    this.makeFetch(`list`, { queryParams: { ...queryParams, accepted: 1 } });

  loadNotAcceptedPostbackList = (queryParams: IGetNotAcceptedPostbackQueryParams): AxiosPromise =>
    this.makeFetch(`list`, { queryParams: { ...queryParams, accepted: 0 } });

  resendWithdrawalsPostback = (id: number): AxiosPromise => this.makeFetch(`resend/{id}`, { method: `POST` }, { id });

  resetCountFailure = (postback_ids: number[]): AxiosPromise =>
    this.makeFetch(`reset-count-failure`, { method: `POST`, data: { postback_ids } });

  markAsSent = (postback_ids: number[]): AxiosPromise =>
    this.makeFetch(`mark-as-sent`, { method: `POST`, data: { postback_ids } });
}

const postbackApi = new PostbackApi(`postback`);

export default postbackApi;
