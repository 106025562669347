import React from 'react';

import { WidgetType } from 'utils/enums';
import { disabledWalletActionCreators } from 'actions/widgets/wallet';

import WalletComment from 'components/widgets/wallets/WalletComment';

const DisabledWalletComment = () => {
  return <WalletComment widget={WidgetType.DISABLED_WALLETS} actionCreators={disabledWalletActionCreators} />;
};

export default DisabledWalletComment;
