import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { InnerTable } from '@kassma-team/kassma-toolkit/lib';

import { PostbackSendStatus, StatusCode } from 'utils/enums';
import { IStockpilingPostbackRowProps } from 'interfaces/widgets/stockpiling';

const StockpilingPostbackRow = ({
  id,
  url,
  status_code,
  response,
  status,
  created_at,
  request_body,
  desktopSize,
}: IStockpilingPostbackRowProps) => {
  const [showRequestBody, setShowRequestBody] = useState(false);

  const [t] = useTranslation();

  const statusColors = {
    'text-danger': status_code !== StatusCode.OK,
    'text-success': status_code === StatusCode.OK,
  };

  const statusIcon = status === PostbackSendStatus.SEND && <i className="fa fa-check" />;

  return desktopSize ? (
    <>
      <tr>
        <td>{id}</td>
        <td>{url}</td>
        <td>
          <span className={classNames(statusColors)}>{status_code}</span>
        </td>
        <td>{response}</td>
        <td>{statusIcon}</td>
        <td>{created_at}</td>
        <td>
          <button className="btn btn-sm btn-secondary" onClick={() => setShowRequestBody(!showRequestBody)}>
            {t(`postbacks.requestBody`)}
          </button>
        </td>
      </tr>
      {showRequestBody && (
        <tr className="text-break-word">
          <td colSpan={7}>
            <div className="table-padding">{request_body}</div>
          </td>
        </tr>
      )}
    </>
  ) : (
    <InnerTable cellClassName="mobile-inner-table no-border">
      <tr className="first-mobile-row">
        <td>ID</td>
        <td>{id}</td>
      </tr>
      <tr>
        <td>{t(`postbacks.url`)}</td>
        <td>{url}</td>
      </tr>
      <tr>
        <td>{t(`postbacks.statusCode`)}</td>
        <td>
          <span className={classNames(statusColors)}>{status_code}</span>
        </td>
      </tr>
      <tr>
        <td>{t(`postbacks.response`)}</td>
        <td>{response}</td>
      </tr>
      <tr>
        <td>{t(`common.status`)}</td>
        <td>{statusIcon}</td>
      </tr>
      <tr>
        <td>{t(`common.createdAt`)}</td>
        <td>{created_at}</td>
      </tr>
      <tr>
        <td>{t(`postbacks.requestBody`)}</td>
        <td colSpan={2}>
          <button className="btn btn-sm btn-secondary" onClick={() => setShowRequestBody(!showRequestBody)}>
            {t(`postbacks.requestBody`)}
          </button>
        </td>
      </tr>
      <tr className="text-break-word">
        {showRequestBody && (
          <td colSpan={2}>
            <div className="table-padding">{request_body}</div>
          </td>
        )}
      </tr>
    </InnerTable>
  );
};

export default StockpilingPostbackRow;
