import React from 'react';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { widgetSubmittingSelector, GridType, InputType } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { PASSWORD_SETTINGS } from 'utils/constants';
import { updatePassword } from 'actions/widgets/settings';
import { ISettingsForm } from 'interfaces/widgets/settings';
import { IPasswordChangeForm } from 'interfaces/widgets/settings/profileSettings';

import SettingsForm from 'components/widgets/settings/SettingsForm';

const onSubmit = (values: IPasswordChangeForm, dispatch: Dispatch) => {
  dispatch(updatePassword(values));
};

const ChangePassword = (props: ISettingsForm) => {
  const [t] = useTranslation();

  const submitting = useSelector(widgetSubmittingSelector(WidgetType.SETTINGS));

  return (
    <SettingsForm
      {...props}
      groupedFields={[
        {
          title: t(`settings.passwordChanging`),
          fields: [
            {
              name: `old_password`,
              type: InputType.MUI_PASSWORD,
              label: t(`settings.oldPassword`),
              required: true,
            },
            {
              name: `new_password`,
              type: InputType.MUI_PASSWORD,
              label: t(`settings.newPassword`),
              required: true,
            },
          ],
          gridType: GridType.GRID,
        },
      ]}
      submitText="settings.updatePassword"
      submitting={submitting as boolean}
    />
  );
};

export default reduxForm({
  form: PASSWORD_SETTINGS,
  onSubmit,
  destroyOnUnmount: false,
  shouldError: () => true,
})(ChangePassword as any);
