import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBlock } from '@kassma-team/kassma-toolkit/lib';

import { IDuplicatedTransactionModalProps } from 'interfaces/widgets/transaction/transaction';

const DuplicatedTransactionModal = ({
  id,
  amount,
  label,
  merchant_order_id,
  onSave,
  onCancel,
  submitFailed,
  error,
  submitting,
  hasSubmitted,
  className,
}: IDuplicatedTransactionModalProps) => {
  const [t] = useTranslation();

  return (
    <div className={className}>
      <h4>{t(`common.warning`)}</h4>
      <p>{t(`transactions.duplicatedTransactionText1`)}</p>
      <div className="mb-10">
        <span className="d-block">{t(`transactions.transactionNumberWithValue`, { id })}</span>
        <span className="d-block">{t(`transactions.amountWithValue`, { amount })}</span>
        <span className="d-block">{t(`transactions.labelWithValue`, { label })}</span>
        <span className="d-block">{`${t(`transactions.orderId`)}: ${
          merchant_order_id || t(`transactions.notSpecified`)
        }`}</span>
      </div>
      <p>{t(`transactions.duplicatedTransactionText2`)}</p>
      {hasSubmitted && submitFailed && error && <ErrorBlock error={error} />}
      <div className="flex flex-align-center justify-content-end mb-10">
        <button className="btn btn-alt-secondary mr-10" onClick={onCancel} type="button">
          {t(`common.cancel`)}
        </button>
        <button className="btn btn-alt-primary" onClick={onSave} type="button" disabled={submitting}>
          {t(`common.save`)}
        </button>
      </div>
    </div>
  );
};

export default DuplicatedTransactionModal;
