import { takeEvery, put, select } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { IAction } from '@kassma-team/kassma-toolkit';

import refreshSaga from 'sagas/effects/refreshSaga';
import { SEND_POSTBACK_REQUESTED } from 'actionTypes';
import i18n from 'i18n';
import { stockpilingListActionCreators } from 'actions/widgets/stockpiling';
import stockpilingApi from 'api/stockpiling/StockpilingApi';
import { getSearch } from 'connected-react-router';
import queryString from 'query-string';

function* sendPostbackSaga({ payload: stockpiling_id }: Record<string, number>) {
  yield refreshSaga({
    request: () => stockpilingApi.resendPostback(stockpiling_id),
    onSuccess: function* () {
      toastr.success(i18n.t(`common.success`), i18n.t(`postbacks.postbackHasBeenResent`));
      //@ts-ignore
      const search: string = yield select(getSearch);

      const params = queryString.parse(search) || {};

      yield put(stockpilingListActionCreators.getList(params));
      yield put(stockpilingListActionCreators.hideModal());
    },
    onError: function* () {
      toastr.error(i18n.t(`common.error`), i18n.t(`postbacks.postbackResendingHasBeenFailed`));
      yield put(stockpilingListActionCreators.hideModal());
    },
  });
}

const stockpilingSagas = [takeEvery<IAction>(SEND_POSTBACK_REQUESTED, sendPostbackSaga)];

export default stockpilingSagas;
