import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { Package } from 'api/packages/PackagesApi';
import { packagesApi } from 'api/packages';

import {
  FormTypeDropdown,
  Modal,
  EditFormType,
  AddButton,
} from 'pages/constructor/DefaultTranslates/common/components';
import {
  normalizeByKey,
  getCustomTranslatesBy,
  getFormTypeTitle,
  isTranslatesEmpty,
} from 'pages/constructor/DefaultTranslates/common/helpers';
import { FormTypesScreen } from 'pages/constructor/DefaultTranslates/common/screens';
import { isPackageTranslation } from 'pages/constructor/DefaultTranslates/common/types';

import {
  useTranslationsCtx,
  useFormTypeCtx,
  usePaymentSystemsCtx,
  useCreationModeCtx,
  useConfirmModalCtx,
} from 'pages/constructor/DefaultTranslates/PackagesTranslation/ctx';

import { NewPackageForm } from './NewPackageForm';
import axios, { AxiosError } from 'axios';
import { toastr } from 'react-redux-toastr';
import refreshHelper from '../../../../../../utils/refreshHelper';

export const FormTypes: FC = () => {
  const [t] = useTranslation();
  const { translations, handeDeleteTranslation, fetchPackages, handleUpdateTranslation } = useTranslationsCtx();
  const { isConfirmModalOpen, handleToggleModal } = useConfirmModalCtx();
  const { isCreationMode, setIsCreationMode } = useCreationModeCtx();
  const { currentPaymentSystem } = usePaymentSystemsCtx();

  const { handleCurrentFormType, formTypes, currentFormType, isLoading } = useFormTypeCtx();

  const [forDelete, setForDelete] = useState<Array<Package>>([]);

  const [isEditMode, setEditMode] = useState(false);
  const [forEdit, setForEdit] = useState<Array<Package>>([]);

  const dTranslations = getCustomTranslatesBy(translations, `dublication`);
  const uniqNames = getCustomTranslatesBy(translations, `unique`);

  const notUniqFields = normalizeByKey(dTranslations, `name`);

  const handleDelete = () => {
    const promises = forDelete.map((el) => {
      return handeDeleteTranslation(el.uuid)();
    });

    Promise.allSettled(promises).then(async (results) => {
      const fullfilled = results.filter(({ status }) => status === `fulfilled`) as Array<
        PromiseFulfilledResult<unknown | AxiosError>
      >;

      fullfilled.forEach((result) => {
        if (axios.isAxiosError(result.value)) {
          toastr.error(result.value.name, result.value.response?.data?.message);
        } else {
          toastr.success(t(`common.success`), t(`common.success`));
        }
      });

      await fetchPackages();
      handleToggleModal(false)();
    });
  };

  const handleEdit = () => {
    const promises = forEdit.map((el) => {
      // Необходимо убрать лишние поля, чтобы запрос прошле валидацию сервера
      const dataForUpdate: Parameters<typeof handleUpdateTranslation>[`1`] = {
        name: el.name,
        is_manual: el.is_manual,
        packages_translations: el.packages_translations.map((el) => ({
          field_uuid: el.field,
          translations: el.translations,
        })),
      };

      return handleUpdateTranslation(el.uuid, dataForUpdate)();
    });

    Promise.allSettled(promises).then(async (results) => {
      if (results.every((result) => result.status === `fulfilled`)) {
        // toastr.success(t(`common.success`), t(`common.success`));
        handleToggleModal(false)();
        await fetchPackages();
      }
    });
  };

  const [isDefaultPackagesLoading, setDefaultPackagesLoading] = useState(false);

  const [defaultPackages, setDefaultPackages] = useState<Record<string, Package>>();

  useEffect(() => {
    if (currentPaymentSystem?.code) {
      const fetcher = async () => {
        setDefaultPackagesLoading(true);
        await refreshHelper({
          request: () =>
            packagesApi.fetchTranslations({
              wallet_type: currentPaymentSystem?.code,
              is_default: true,
            }),
          onSuccess: ({ data }: any) => {
            if (data.status === `success`) {
              if (data.data) {
                const nData = data.data.reduce((acc: Record<string, Package>, curr: Package) => {
                  acc[curr.form_type] = curr;

                  return acc;
                }, {} as Record<string, Package>);

                setDefaultPackages(nData);
              }
            }
          },
          onFinally: () => {
            setDefaultPackagesLoading(false);
          },
        });
      };

      fetcher();
    }
  }, [currentPaymentSystem?.code]);

  return (
    <FormTypesScreen
      title={t(`common.form`)}
      headerBtn={
        <AddButton
          isLoading={isDefaultPackagesLoading}
          disabled={isTranslatesEmpty(defaultPackages)}
          onClick={() => setIsCreationMode((prev) => !prev)}
        />
      }
    >
      {isCreationMode && <NewPackageForm />}
      {isEditMode && (
        <EditFormType
          title={forEdit[0].name}
          onApply={handleEdit}
          onChange={(e) => setForEdit(forEdit.map((el) => ({ ...el, name: e.target.value })))}
          onRemove={() => setEditMode(false)}
        />
      )}

      <ul className="FormTypes__list">
        {isConfirmModalOpen && <Modal onApply={handleDelete} variant="confirm" onClose={handleToggleModal(false)} />}

        {isLoading ? (
          <Skeleton count={4} />
        ) : (
          uniqNames.map((el) => (
            <li className="Form" key={el.uuid}>
              <FormTypeDropdown
                key={el.uuid}
                formTypes={[
                  {
                    form: { title: getFormTypeTitle(el.form_type, formTypes) || ``, type: el.form_type },
                    entityId: el.uuid,
                    onCheck: (formType) => {
                      handleCurrentFormType(formType);
                    },
                  },
                ]}
                title={el.name}
                checkedForm={currentFormType}
                {...(!el.is_default && !el.is_manual
                  ? {
                      onDelete: () => {
                        handleToggleModal(true)();
                        if (isPackageTranslation(el)) {
                          setForDelete([el]);
                        }
                      },
                      onEdit: () => {
                        setEditMode(true);
                        if (isPackageTranslation(el)) {
                          setForEdit([el]);
                        }
                      },
                    }
                  : {})}
              />
            </li>
          ))
        )}

        {isLoading ? (
          <Skeleton count={4} />
        ) : (
          Object.keys(notUniqFields).length > 0 &&
          Object.keys(notUniqFields).map((key) => {
            return (
              <li key={key}>
                <FormTypeDropdown
                  formTypes={notUniqFields[key].map((pack) => ({
                    form: {
                      title: getFormTypeTitle(pack.form_type, formTypes) || ``,
                      type: pack.form_type,
                    },
                    entityId: pack.uuid,
                    onCheck: (formType) => handleCurrentFormType(formType),
                  }))}
                  title={key}
                  checkedForm={currentFormType}
                  {...(!notUniqFields[key][0].is_default
                    ? {
                        onDelete: () => {
                          handleToggleModal(true)();
                          if (isPackageTranslation(notUniqFields[key][0])) {
                            setForDelete(notUniqFields[key] as Array<Package>);
                          }
                        },
                        onEdit: () => {
                          setEditMode(true);
                          if (isPackageTranslation(notUniqFields[key][0])) {
                            setForEdit(notUniqFields[key] as Array<Package>);
                          }
                        },
                      }
                    : {})}
                />
              </li>
            );
          })
        )}
      </ul>
    </FormTypesScreen>
  );
};
