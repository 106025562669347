import React from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import { ErrorBlock } from '@kassma-team/kassma-toolkit/lib';

interface IProps {
  login?: string;
  wallets?: string[];
  onSave: () => void;
  onCancel: () => void;
  submitFailed?: boolean;
  error?: string;
  submitting?: boolean;
  hasSubmitted?: boolean;
  className?: string;
}

const IdbLoginConflictModal = ({
  login,
  wallets,
  onSave,
  onCancel,
  submitFailed,
  error,
  submitting,
  hasSubmitted,
  className,
}: IProps) => {
  const [t] = useTranslation();

  return (
    <div className={className}>
      <h4>{t(`common.warning`)}</h4>
      <p>{t(`wallets.idbWalletsConflict`, { login })}</p>
      <ul>
        {map(wallets, (wallet, key) => {
          return <li key={key}>{wallet}</li>;
        })}
      </ul>
      <p>{t(`wallets.walletsProxyConflict`)}</p>
      <p>{t(`wallets.walletsConflictSaveMessage`)}</p>
      {hasSubmitted && submitFailed && error && <ErrorBlock error={error} />}
      <div className="flex flex-align-center justify-content-end">
        <button className="btn btn-alt-secondary mr-10" onClick={onCancel} type="button">
          {t(`common.cancel`)}
        </button>
        <button className="btn btn-alt-primary" onClick={onSave} type="button" disabled={submitting}>
          {t(`common.save`)}
        </button>
      </div>
    </div>
  );
};

export default IdbLoginConflictModal;
