import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import { FieldType, InputType, IFormField } from '@kassma-team/kassma-toolkit/lib';

import useWalletProxies from 'hooks/widgets/wallets/useWalletProxies';
import usePayzenData from 'hooks/widgets/wallets/usePayzenData';
import { proxyActionCreators } from 'actions/widgets/proxy';
import { paymentSystemsSelector, supportedWalletCurrenciesSelector } from 'selectors/widgets/paymentSystems';
import { AccountType, ParserType, Permission, WalletFormType, WalletType, WidgetType } from 'utils/enums';
import { walletPriorityData } from 'static/wallets';
import getWalletParserTypes from 'utils/widgets/wallets/getWalletParserTypes';
import useList from 'hooks/widgets/useList';
import useFilterFormFields from 'hooks/widgets/useFilterFormFields';
import useArrayFields from 'hooks/widgets/useArrayFields';
import { hasAccessSelector } from 'selectors/auth';
import { useProxySelector } from 'selectors/widgets/constructor/paymentsConstructor';
import { trim } from 'lodash';

interface IProps {
  type: string;
  walletType: string;
  formName: string;
  change: (name: string, value: any) => void;
  initialValues?: any;
  isManualPS?: boolean;
  manualFields?: any[];
}

interface IValues {
  account_type?: string;
  site_parser?: boolean;
  night_parser?: boolean;
  parser_type?: string;
  country_code?: string;
  parsing_start_at?: string;
}

const parsingPs = [
  WalletType.BKASH,
  WalletType.CLICK_UZS,
  WalletType.NAGAD,
  WalletType.ROCKET,
  WalletType.VIETCOM_BANK,
  WalletType.EASYPAISA,
  WalletType.UPI_HB,
  WalletType.UPI_IB,
  WalletType.UPI_W,
  WalletType.UPI_IDB,
  WalletType.UPI_IN,
  WalletType.IMPS_HB,
  WalletType.IMPS_IB,
  WalletType.IMPS_IDB,
  WalletType.PAY_TM,
];

const useWalletData = ({ type, change, formName, walletType, initialValues, isManualPS, manualFields }: IProps) => {
  const [t] = useTranslation();
  const [fields, setFields] = useState<IFormField[]>([]);
  const [isParsingStartAt, setIsParsingStartAt] = useState(false);
  const paymentSystemsList = useSelector(paymentSystemsSelector);

  const { items: proxyList, listLoading: proxyListLoading } = useList({
    widget: WidgetType.PROXIES,
    actionCreators: proxyActionCreators,
    resetOnUnmount: false,
  });

  const useProxy = useSelector(useProxySelector);

  const values: IValues = useSelector(getFormValues(formName)) || {};

  const { parser_type } = values;

  const supportedCurrenciesSelector = useMemo(() => {
    return supportedWalletCurrenciesSelector(walletType as string);
  }, [walletType]);

  const currencies = useSelector(supportedCurrenciesSelector);

  const renderBankcodes = useArrayFields({
    fields: () => [
      {
        placeholder: t(`wallets.surePayBankCode`),
        name: `bankcode`,
        wrapClassName: `w-50 mr-5`,
      },
      {
        placeholder: t(`wallets.surePayDepositIban`),
        name: `depositIban`,
        wrapClassName: `w-50 mr-5`,
      },
    ],
    title: t(`wallets.surePayBankCode`),
    name: `bank_codes`,
  });
  const renderProxies = useWalletProxies(formName, `proxies`, useProxy);

  const renderUpiAbQuestions = useArrayFields({
    fields: () => [
      {
        placeholder: t(`wallets.question`),
        name: `question`,
        wrapClassName: `wallet-form__upi-ab-question mr-5`,
        required: true,
      },
      {
        placeholder: t(`wallets.answer`),
        name: `answer`,
        wrapClassName: `wallet-form__upi-ab-answer mr-5`,
        required: true,
      },
    ],
    title: t(`wallets.codePhrase`),
    name: `code_phrase`,
    disableCallback: (fields) => fields.length >= 3,
  });

  const { payzenCountries, payzenCurrencies, payzenMethods } = usePayzenData({
    countryCode: values?.country_code as string,
    change,
    walletType,
  });

  const {
    notUseParserICICI,
    notUseParserEmail,
    notUseParserIndusIn,
    notUseParserFreeCharge,
    notUseParserHDFC,
    notUseParserAxis,
    notUseParserSite,
    notUseMobileApi,
  } = useMemo(() => {
    return {
      notUseParserICICI: parser_type !== ParserType.PARSER_ICICI,
      notUseParserEmail: parser_type !== ParserType.PARSER_EMAIL,
      notUseParserAxis: parser_type !== ParserType.PARSER_AXIS,
      notUseParserIndusIn: parser_type !== ParserType.PARSER_INDUSIN,
      notUseParserFreeCharge: parser_type !== ParserType.PARSER_FREE_CHARGE,
      notUseParserHDFC: parser_type !== ParserType.PARSER_HDFC,
      notUseParserSite: parser_type !== ParserType.PARSER_SITE && parser_type !== ParserType.PARSER_APP,
      notUseMobileApi: parser_type !== ParserType.PARSER_PHONE_PE_MOBILE_API,
    };
  }, [parser_type]);

  const walletPrivateInformationView = useSelector(hasAccessSelector(Permission.WALLET_NUMBER));

  const parsingObjectOptions = [
    {
      value: `webpage`,
      text: t(`wallets.webpage`),
    },
    {
      value: `statement`,
      text: t(`wallets.statement`),
    },
  ];

  //@ts-ignore
  const account_type = values?.account_type;

  useEffect(() => {
    if (walletType === WalletType.UPI_L) {
      if (values.integration_type == `upi_l`) {
        if (values.parser_type === null) {
          change(`parser_type`, ParserType.PARSER_FREE_CHARGE);
        }
      }
    }
  }, [walletType, values.integration_type, values.parser_type]);

  useEffect(() => {
    if (walletType === WalletType.PHONE_PE) {
      if (values.parser_type === ParserType.PARSER_INDUSIN) {
        change(`email_username`, ``);
        change(`email_password`, ``);
        change(`sms_source`, ``);
      }

      if (values.parser_type === ParserType.PARSER_HDFC) {
        change(`email_username`, ``);
        change(`email_password`, ``);
        change(`sms_source`, ``);
      }

      if (values.parser_type === ParserType.PARSER_PHONE_PE_MOBILE_API && values.sms_source === `mobile_data`) {
        change(`email_username`, ``);
        change(`email_password`, ``);
      }
    }
  }, [values.parser_type, walletType, values.sms_source]);

  useEffect(() => {
    if (walletType === WalletType.BKASH) {
      if (account_type === AccountType.MERCHANT) {
        change(`parser_type`, ParserType.PARSER_SITE);
      } else if (account_type === AccountType.AGENT || account_type === AccountType.PERSONAL) {
        change(`parser_type`, ParserType.PARSER_EMAIL);
      }
    }
  }, [walletType, account_type]);

  useEffect(() => {
    if (parser_type && account_type && (walletType === WalletType.NAGAD || walletType === WalletType.BKASH)) {
      const possibleData = getWalletParserTypes(walletType, t, account_type) || [];
      const possibleValues = possibleData.map((data) => data.value);
      if (possibleValues.includes(parser_type || ``)) {
        change(`parser_type`, parser_type);
      } else {
        change(`parser_type`, possibleValues[0]);
      }
    }
  }, [walletType, account_type]);

  useEffect(() => {
    if (
      (walletType === WalletType.NAGAD || walletType === WalletType.BKASH) &&
      ((type === WalletFormType.UPDATE && initialValues && parser_type && initialValues.parser_type !== parser_type) ||
        type === WalletFormType.CREATE)
    ) {
      change(`username`, ``);
      change(`password`, ``);
      change(`email_username`, ``);
      change(`email_password`, ``);
    }
  }, [walletType, parser_type, initialValues]);

  useEffect(() => {
    if (walletType === WalletType.NAGAD && account_type === AccountType.MERCHANT) {
      change(`parser_type`, ParserType.PARSER_SITE);
    }
  }, [walletType, account_type]);

  useEffect(() => {
    if (walletType === WalletType.PHONE_PE && type === WalletFormType.CREATE) {
      change(`parser_type`, ParserType.PARSER_PHONE_PE_MOBILE_API);
    }
  }, [walletType, type]);

  const maxDate = new Date();

  useEffect(() => {
    if (isParsingStartAt && !values.parsing_start_at) {
      change(`parsing_start_at`, maxDate);
    } else if (values.parsing_start_at) {
      setIsParsingStartAt(true);
    }
  }, [isParsingStartAt, values.parsing_start_at]);

  useEffect(() => {
    if (type === WalletFormType.CREATE && walletType === WalletType.UPI_L && !values.parser_type) {
      change(`parser_type`, `freecharge`);
    }
  }, [walletType, values.parser_type, type]);

  const isP2P = paymentSystemsList.some((ps) => ps.code === walletType && ps.payment_type === `P2P`);

  const handleUpiLAccountNumber = (values) => {
    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_FREE_CHARGE) {
      return true;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_INDUSIN) {
      return true;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.FEDNET) {
      return true;
    }

    if (values.integration_type === `a_pay`) {
      return false;
    }
  };

  const handleUpiLApiPublicKey = (values) => {
    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_FREE_CHARGE) {
      return false;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_INDUSIN) {
      return false;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.FEDNET) {
      return false;
    }

    if (values.integration_type === `a_pay`) {
      return true;
    }
  };

  const handleUpiLParserType = (values) => {
    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_FREE_CHARGE) {
      return true;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_INDUSIN) {
      return true;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.FEDNET) {
      return true;
    }

    if (values.integration_type === `a_pay`) {
      return false;
    }
  };

  const handleUpiLAddresses = (values) => {
    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_FREE_CHARGE) {
      return true;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_INDUSIN) {
      return true;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.FEDNET) {
      return true;
    }

    if (values.integration_type === `a_pay`) {
      return false;
    }
  };

  const handleUpiLApiPrivateKey = (values) => {
    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_FREE_CHARGE) {
      return false;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_INDUSIN) {
      return false;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.FEDNET) {
      return false;
    }

    if (values.integration_type === `a_pay`) {
      return true;
    }
  };

  const handleUpiLProjectId = (values) => {
    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_FREE_CHARGE) {
      return false;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_INDUSIN) {
      return false;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.FEDNET) {
      return false;
    }

    if (values.integration_type === `a_pay`) {
      return true;
    }
  };

  const handleUpiLEmailPassword = (values) => {
    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_FREE_CHARGE) {
      return true;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_INDUSIN) {
      return false;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.FEDNET) {
      return false;
    }

    if (values.integration_type === `a_pay`) {
      return false;
    }
  };

  const handleUpiLEmailUsername = (values) => {
    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_FREE_CHARGE) {
      return true;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_INDUSIN) {
      return false;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.FEDNET) {
      return false;
    }

    if (values.integration_type === `a_pay`) {
      return false;
    }
  };

  const handleUpiLUsername = (values) => {
    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_FREE_CHARGE) {
      return true;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_INDUSIN) {
      return true;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.FEDNET) {
      return true;
    }

    if (values.integration_type === `a_pay`) {
      return false;
    }
  };

  const handleUpiLCodephrases = (values) => {
    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_FREE_CHARGE) {
      return false;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_INDUSIN) {
      return true;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.FEDNET) {
      return false;
    }

    if (values.integration_type === `a_pay`) {
      return false;
    }
  };

  const handleUpiLPassword = (values) => {
    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_FREE_CHARGE) {
      return false;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.PARSER_INDUSIN) {
      return true;
    }

    if (values.integration_type === `upi_l` && values.parser_type === ParserType.FEDNET) {
      return true;
    }

    if (values.integration_type === `a_pay`) {
      return false;
    }
  };

  const handleUpiLPhoneNumber = (values) => {
    if (values.integration_type === `upi_l` && values.parser_type === ParserType.FEDNET) {
      return true;
    }

    return false;
  };

  const upiLEmailHadler = () => {
    if (values.integration_type !== `upi_l`) {
      return true;
    }

    if (values.sms_source !== `email`) {
      return true;
    }

    if (values.parser_type !== ParserType.PARSER_FREE_CHARGE) {
      return true;
    }
    // values.parser !== ParserType.PARSER_FREE_CHARGE ||
    // values.integration_type !== `upi_l` ||
    // values.sms_source !== `email`,
  };

  const renderUpiAddresses = useArrayFields({
    fields: () => [
      {
        placeholder: t(`wallets.address`),
        name: `address`,
        wrapClassName: `w-90 mr-5`,
        trim: true,
        required: handleUpiLAddresses(values),
      },
    ],
    title: t(`wallets.addressUpi`),
    name: `upi_addresses`,
    disableCallback: (fields) => fields.length >= 10,
  });

  const isParsingPS = parsingPs.includes(walletType);

  // For NAGAD and BKash
  const isFieldEmailHide = () => {
    if (values.sms_source && values.sms_source == `email`) {
      return false;
    }

    if (values.parser_type && values.parser_type == ParserType.PARSER_APP) {
      return false;
    }

    return true;
  };

  // (values.sms_source && values.sms_source !== `email`) || parser_type !== ParserType.PARSER_SITE

  useEffect(() => {
    if (walletType) {
      let newFields;
      switch (walletType) {
        case WalletType.IPAY:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `account_number`,
              label: t(`wallets.accountNumber`),
              required: true,
              maxLength: 15,
              digital: true,
              trim: true,
            },
            {
              name: `username`,
              label: t(`wallets.siteLogin`),
              required: true,
              trim: true,
              maxLength: 32,
            },
            {
              name: `password`,
              label: t(`wallets.sitePassword`),
              password: true,
              onlyLatinDigitsAndSymbols: true,
              required: true,
              trim: true,
              maxLength: 20,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              required: useProxy,
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
            },
            {
              name: `currency_code`,
              label: t(`common.currency`),
              required: true,
              type: InputType.SELECT,
              data: currencies,
            },
          ];
          break;
        case WalletType.EASYPAISA:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `email_username`,
              label: t(`wallets.email`),
              required: true,
              email: true,
              trim: true,
              maxLength: 40,
            },
            {
              name: `email_password`,
              label: t(`wallets.emailPassword`),
              password: true,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              required: useProxy,
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
            },
            {
              name: `currency_code`,
              label: t(`common.currency`),
              required: true,
              type: InputType.SELECT,
              data: currencies,
            },
          ];
          break;
        case WalletType.UPI_L:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.identifier`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
              maxLength: 40,
              onlyLatinDigitsAndSymbols: true,
            },
            {
              name: `integration_type`,
              label: t(`wallets.integrationType`),
              type: InputType.SELECT,
              data: [
                {
                  value: WalletType.UPI_L,
                  text: `UPI L`,
                },
                {
                  value: `a_pay`,
                  text: `A-Pay`,
                },
              ],
              required: true,
            },
            {
              name: `account_number`,
              label: t(`withdrawals.impsAccountNumber`),
              required: handleUpiLAccountNumber(values),
              hide: !handleUpiLAccountNumber(values),
              onlyLatinDigitsAndSymbols: true,
              trim: true,
              maxLength: 40,
            },
            {
              name: `api_public_key`,
              label: t(`wallets.apiKey`),
              required: handleUpiLApiPublicKey(values),
              hide: !handleUpiLApiPublicKey(values),
              trim: true,
            },
            {
              name: `parser_type`,
              label: t(`wallets.parser`),
              required: handleUpiLParserType(values),
              hide: !handleUpiLParserType(values),
              maxLength: 40,
              trim: true,
              onlyLatinDigitsAndSymbols: true,
              type: InputType.SELECT,
              data: getWalletParserTypes(walletType, t),
            },
            {
              name: `api_private_key`,
              label: t(`wallets.secretKey`),
              required: handleUpiLApiPrivateKey(values),
              hide: !handleUpiLApiPrivateKey(values),
              trim: true,
            },
            {
              name: `project_id`,
              label: t(`wallets.payOpProjectId`),
              trim: true,
              required: handleUpiLProjectId(values),
              hide: !handleUpiLProjectId(values),
            },
            {
              name: `webhook_id`,
              label: t(`wallets.webhookID`),
              trim: true,
              digital: true,
              required: values.integration_type === `a_pay`,
              hide: values.integration_type !== `a_pay`,
            },
            {
              name: `phone_number`,
              label: t(`wallets.phoneNumber`),
              trim: true,
              regexMatch: {
                regex: /^91\d{10}$/i,
                message: t(`errors.invalidFormat`),
              },
              required: handleUpiLPhoneNumber(values),
              hide: !handleUpiLPhoneNumber(values),
            },
            {
              name: `username`,
              label: t(`wallets.siteLogin`),
              required: handleUpiLUsername(values),
              hide: !handleUpiLUsername(values),
              maxLength: values.parser_type === ParserType.PARSER_FREE_CHARGE ? 10 : 40,
              onlyLatinDigitsAndSymbols: values.parser_type === ParserType.PARSER_FREE_CHARGE ? true : false,
              trim: true,
            },
            {
              name: `email_username`,
              label: t(`wallets.email`),
              required: true,
              email: true,
              trim: true,
              maxLength: 40,
              // unused: notUseParserFreeCharge,
              hide: upiLEmailHadler(),
            },
            {
              name: `email_password`,
              label: t(`wallets.emailPassword`),
              password: true,
              required: true,
              hide: upiLEmailHadler(),
            },
            {
              name: `sms_source`,
              label: t(`wallets.smsSource`),
              type: InputType.SELECT,
              required: true,
              data: [
                {
                  value: `email`,
                  text: t(`wallets.email`),
                },
                {
                  value: `mobile_data`,
                  // Нет ошибки с t(). На макете для 2 языков Mobile Data.
                  text: `Mobile Data`,
                },
              ],
              hide: values.integration_type != `upi_l` || values.parser_type != ParserType.PARSER_FREE_CHARGE,
            },
            {
              name: `password`,
              label: t(`wallets.sitePassword`),
              required: handleUpiLPassword(values),
              hide: !handleUpiLPassword(values),
              password: true,
              maxLength: 40,
              trim: true,
            },
            {
              name: `codephrases`,
              label: t(`common.codePhrase`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderUpiAbQuestions,
              required: handleUpiLCodephrases(values),
              hide: !handleUpiLCodephrases(values),
              unused: notUseParserIndusIn,
            },
            // {
            //   name: `upi_addresses`,
            //   label: t(`wallets.addressUpi`),
            //   required: handleUpiLAddresses(values),
            //   hide: !handleUpiLAddresses(values),
            //   fieldType: FieldType.FIELD_ARRAY,
            //   component: renderUpiAddresses,
            // },
            {
              name: `currency_code`,
              label: t(`common.currency`),
              required: true,
              type: InputType.SELECT,
              data: currencies,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              required: useProxy,
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
            },
          ];
          break;
        case WalletType.TECHCOMBANK:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
            {
              name: `username`,
              label: t(`wallets.siteLogin`),
              required: true,
              trim: true,
            },
            {
              name: `password`,
              label: t(`wallets.sitePassword`),
              required: true,
              password: true,
              onlyLatinDigitsAndSymbols: true,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
          ];
          break;
        case WalletType.PAYGIGA:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              // name: `merchant_key`,
              name: `merchant_id`,
              label: t(`wallets.merchantID`),
              required: true,
              trim: true,
            },
            {
              // name: `merchant_password`,
              name: `password`,
              label: t(`wallets.merchantPassword`),
              required: true,
              trim: true,
              password: true,
              onlyLatinDigitsAndSymbols: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
          ];
          break;
        case WalletType.PAYZEN:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.identifier`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `api_public_key`,
              label: t(`wallets.payzenApiKey`),
              required: true,
              trim: true,
            },
            {
              name: `public_key`,
              label: t(`wallets.payzenPublicKey`),
              required: true,
              type: InputType.TEXTAREA,
            },
            {
              name: `country_code`,
              label: t(`wallets.country`),
              required: true,
              data: payzenCountries,
              busy: !payzenCountries,
              type: InputType.SELECT,
            },
            {
              // name: `operation_type`,
              name: `payment_method`,
              label: t(`wallets.operationType`),
              required: true,
              type: InputType.SELECT,
              data: payzenMethods,
              disabled: !values.country_code,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: payzenCurrencies,
              required: true,
              disabled: !values.country_code,
            },
          ];
          break;
        case WalletType.SUREPAY:
          newFields = [
            {
              name: `bankcode`,
              label: t(`wallets.surePayBankCode`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderBankcodes,
              required: true,
            },
            {
              name: `identifier`,
              label: t(`wallets.surePayAccountNumber`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `merchant_id`,
              label: t(`wallets.surePayMerchant`),
              required: true,
              trim: true,
            },
            {
              name: `api_public_key`,
              label: t(`wallets.surePayApiKey`),
              required: true,
              trim: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
          ];
          break;
        case WalletType.VIETCOM_BANK:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.vietcomBankAccountNumber`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `account_name`,
              label: t(`wallets.vietcomBankAccountHolder`),
              required: true,
              trim: true,
            },
            {
              name: `bank_name`,
              label: t(`wallets.vietcomBankBankName`),
              required: true,
              trim: true,
            },
            {
              name: `email_username`,
              label: t(`wallets.email`),
              required: true,
              email: true,
              trim: true,
              maxLength: 40,
            },
            {
              name: `email_password`,
              label: t(`wallets.emailPassword`),
              password: true,
              onlyLatinDigitsAndSymbols: true,
              required: true,
              trim: true,
              maxLength: 40,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
          ];
          break;
        case WalletType.PAYTRUST88:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.identifier`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `api_public_key`,
              label: t(`wallets.paytrustApiKey`),
              required: true,
              trim: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
          ];
          break;
        case WalletType.EPAY:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.accountIdentifier`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `api_public_key`,
              label: t(`wallets.ePayApiKey`),
              required: true,
              trim: true,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];
          break;
        case WalletType.IMPS_IB:
          newFields = [
            {
              name: `account_name`,
              label: t(`wallets.IMPSAccountName`),
              required: true,
              trim: true,
            },
            {
              name: `identifier`,
              label: t(`wallets.IMPSAccountNumber`),
              required: true,
              trim: true,
              digital: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `username`,
              label: t(`wallets.siteLogin`),
              required: true,
              trim: true,
              maxLength: 32,
            },
            {
              name: `password`,
              label: t(`wallets.sitePassword`),
              password: true,
              onlyLatinDigitsAndSymbols: true,
              required: true,
              trim: true,
              maxLength: 20,
            },
            {
              name: `ifsc_code`,
              label: t(`wallets.IMPSIFSCcode`),
              required: true,
              trim: true,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];
          break;
        case WalletType.PAY_TM:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.identifier`),
              required: true,
              maxLength: 40,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `password`,
              label: t(`wallets.walletPassword`),
              password: true,
              onlyLatinDigitsAndSymbols: true,
              trim: true,
              required: true,
              maxLength: 40,
            },
            {
              name: `parser_type`,
              label: t(`wallets.parser`),
              type: InputType.SELECT,
              required: true,
              data: getWalletParserTypes(walletType, t),
              translateKeys: true,
            },
            {
              name: `account_number`,
              label: t(`wallets.accountNumber`),
              maxLength: 40,
              trim: true,
              digital: true,
              required: true,
              hide: parser_type != ParserType.MOBILE_API,
            },
            {
              name: `sms_source`,
              label: t(`wallets.smsSource`),
              type: InputType.SELECT,
              required: true,
              data: [
                {
                  value: `email`,
                  text: t(`wallets.email`),
                },
                {
                  value: `sms_box`,
                  text: t(`wallets.smsBox`),
                },
                {
                  value: `manual_input`,
                  text: t(`wallets.manual`),
                },
                {
                  value: `mobile_data`,
                  // Нет ошибки с t(). На макете для 2 языков Mobile Data.
                  text: `Mobile Data`,
                },
              ],
            },
            {
              name: `email_username`,
              label: t(`wallets.email`),
              required: true, //values.sms_source == `email`,
              email: true,
              trim: true,
              maxLength: 40,
              hide: values.sms_source !== `email`,
            },
            {
              name: `email_password`,
              label: t(`wallets.emailPassword`),
              password: true,
              onlyLatinDigitsAndSymbols: true,
              required: true, //values.sms_source == `email`,
              trim: true,
              maxLength: 40,
              hide: values.sms_source !== `email`,
            },
            {
              name: `port`,
              label: t(`wallets.port`),
              standardAmount: true,
              required: true, //values.sms_source == `sms_box`,
              maxLength: 6,
              hide: values.sms_source !== `sms_box`,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `cookie`,
              label: t(`wallets.cookie`),
              jsonFields: [
                {
                  name: `payload`,
                },
                {
                  name: `date`,
                  dateFormat: `YYYY-MM-DD hh:mm:ss`,
                },
                {
                  name: `type`,
                  valueRange: [`work`, `middle`],
                },
              ],
            },
          ];
          break;
        case WalletType.NAGAD_API:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.identifier`),
              required: true,
              trim: true,
              onlyLatinDigitsAndSymbols: true,
              maxLength: 40,
              hide: type !== WalletFormType.CREATE,
            },
            {
              name: `merchant_id`,
              label: t(`wallets.merchantID`),
              maxLength: 15,
              minLength: 15,
              required: true,
              trim: true,
            },
            {
              name: `account_number`,
              label: t(`wallets.accountNumber`),
              maxLength: 11,
              minLength: 11,
              trim: true,
              digital: true,
            },
            {
              name: `merchant_private_key`,
              label: t(`wallets.merchantPrvateKey`),
              required: true,
              trim: true,
            },
            {
              name: `api_public_key`,
              label: t(`wallets.nagadPublicKey`),
              required: true,
              trim: true,
            },
            {
              name: `callback_source`,
              label: t(`wallets.callbackSource`),
              type: InputType.SELECT,
              data: [
                {
                  value: `payment_system`,
                  text: `wallets.directlyFromPS`,
                },
                {
                  value: `callback_service`,
                  text: `wallets.viaServiceGateway`,
                },
              ],
              required: true,
              translateKeys: true,
            },
            {
              name: `callback_url`,
              label: t(`wallets.callbackUrl`),
              hide: values?.callback_source !== `callback_service`,
              unused: values?.callback_source !== `callback_service`,
              minLength: 10,
              maxLength: 255,
              required: true,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];
          break;
        case WalletType.BKASH:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.identifier`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `account_type`,
              label: t(`wallets.accountType`),
              type: InputType.SELECT,
              data: [
                {
                  value: AccountType.AGENT,
                  text: `wallets.agentAccount`,
                },
                {
                  value: AccountType.PERSONAL,
                  text: `wallets.personalAccount`,
                },
                {
                  value: AccountType.MERCHANT,
                  text: `wallets.merchantAccount`,
                },
              ],
              translateKeys: true,
              required: true,
            },
            {
              name: `parser_type`,
              label: t(`wallets.parser`),
              type: InputType.SELECT,
              required: true,
              data: getWalletParserTypes(walletType, t, account_type),
              translateKeys: true,
              disabled: account_type === AccountType.MERCHANT || !account_type,
            },
            {
              name: `username`,
              label: t(`wallets.siteLogin`),
              required: true,
              trim: true,
              digital: true,
              minLength: 11,
              maxLength: 11,
              hide: notUseParserSite,
              unused: notUseParserSite,
            },
            {
              name: `password`,
              label: t(`wallets.sitePassword`),
              required: true,
              trim: true,
              password: true,
              onlyLatinDigitsAndSymbols: true,
              maxLength: 40,
              hide: notUseParserSite,
              unused: notUseParserSite,
            },
            {
              name: `email_username`,
              label: t(`wallets.email`),
              required: true,
              email: true,
              maxLength: 40,
              trim: true,
              hide: isFieldEmailHide(),
              unused: isFieldEmailHide(),
            },
            {
              name: `email_password`,
              label: t(`wallets.emailPassword`),
              password: true,
              onlyLatinDigitsAndSymbols: true,
              required: true,
              trim: true,
              maxLength: 40,
              hide: isFieldEmailHide(),
              unused: isFieldEmailHide(),
            },
            {
              name: `sms_source`,
              label: t(`wallets.smsSource`),
              type: InputType.SELECT,
              required: true,
              data: [
                {
                  value: `email`,
                  text: t(`wallets.email`),
                },
                {
                  value: `mobile_data`,
                  // Нет ошибки с t(). На макете для 2 языков Mobile Data.
                  text: `Mobile Data`,
                },
              ],
              hide: !parser_type || parser_type !== ParserType.PARSER_EMAIL,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
          ];
          break;
        case WalletType.NAGAD:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.identifier`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `account_type`,
              label: t(`wallets.accountType`),
              type: InputType.SELECT,
              data: [
                {
                  value: AccountType.AGENT,
                  text: `wallets.agentAccount`,
                },
                {
                  value: AccountType.PERSONAL,
                  text: `wallets.personalAccount`,
                },
                {
                  value: AccountType.MERCHANT,
                  text: `wallets.merchantAccount`,
                },
              ],
              translateKeys: true,
              required: true,
            },
            {
              name: `parser_type`,
              label: t(`wallets.parser`),
              type: InputType.SELECT,
              required: true,
              data: getWalletParserTypes(walletType, t, account_type),
              translateKeys: true,
              disabled: account_type === AccountType.MERCHANT || !account_type,
            },
            {
              name: `username`,
              label: t(`wallets.siteLogin`),
              required: true,
              trim: true,
              hide: !parser_type || parser_type === ParserType.PARSER_EMAIL,
              unused: !parser_type || parser_type === ParserType.PARSER_EMAIL,
            },
            {
              name: `password`,
              label: t(`wallets.sitePassword`),
              required: true,
              password: true,
              onlyLatinDigitsAndSymbols: true,
              hide: !parser_type || parser_type === ParserType.PARSER_EMAIL,
              unused: !parser_type || parser_type === ParserType.PARSER_EMAIL,
            },
            {
              name: `email_username`,
              label: t(`wallets.email`),
              required: true,
              email: true,
              maxLength: 40,
              trim: true,
              // parser_type === ParserType.PARSER_SITE
              hide: isFieldEmailHide(),
              unused: isFieldEmailHide(),
              // unused: (values.sms_source && values.sms_source !== `email`) || parser_type !== ParserType.PARSER_SITE,
            },
            {
              name: `email_password`,
              label: t(`wallets.emailPassword`),
              password: true,
              onlyLatinDigitsAndSymbols: true,
              required: true,
              trim: true,
              maxLength: 40,
              hide: isFieldEmailHide(),
              unused: isFieldEmailHide(),
            },
            {
              name: `sms_source`,
              label: t(`wallets.smsSource`),
              type: InputType.SELECT,
              required: true,
              data: [
                {
                  value: `email`,
                  text: t(`wallets.email`),
                },
                {
                  value: `mobile_data`,
                  // Нет ошибки с t(). На макете для 2 языков Mobile Data.
                  text: `Mobile Data`,
                },
              ],
              hide: parser_type !== ParserType.PARSER_EMAIL,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
          ];
          break;
        case WalletType.ROCKET:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.identifier`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `email_username`,
              label: t(`wallets.email`),
              required: values.sms_source == `email`,
              email: true,
              maxLength: 40,
              trim: true,
              hidden: values.sms_source !== `email`,
              unused: values.sms_source !== `email`,
            },
            {
              name: `email_password`,
              label: t(`wallets.emailPassword`),
              password: true,
              onlyLatinDigitsAndSymbols: true,
              required: values.sms_source == `email`,
              trim: true,
              maxLength: 40,
              hidden: values.sms_source !== `email`,
              unused: values.sms_source !== `email`,
            },
            {
              name: `sms_source`,
              label: t(`wallets.smsSource`),
              type: InputType.SELECT,
              required: true,
              data: [
                {
                  value: `email`,
                  text: t(`wallets.email`),
                },
                {
                  value: `mobile_data`,
                  // Нет ошибки с t(). На макете для 2 языков Mobile Data.
                  text: `Mobile Data`,
                },
              ],
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
          ];
          break;
        case WalletType.PHONE_PE:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.identifier`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `account_number`,
              label: t(`wallets.accountNumber`),
              required: true,
              maxLength: 40,
              onlyLatinDigitsAndSymbols: true,
              trim: true,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
            {
              name: `parser_type`,
              label: t(`wallets.parser`),
              type: InputType.SELECT,
              required: true,
              data: getWalletParserTypes(walletType, t, account_type),
              translateKeys: true,
            },
            {
              name: `sms_source`,
              label: t(`wallets.smsSource`),
              type: InputType.SELECT,
              required: values.parser_type === ParserType.PARSER_PHONE_PE_MOBILE_API,
              data: [
                {
                  value: `email`,
                  text: t(`wallets.email`),
                },
                {
                  value: `mobile_data`,
                  // Нет ошибки с t(). На макете для 2 языков Mobile Data.
                  text: `Mobile Data`,
                },
              ],
              hide: values.parser_type !== ParserType.PARSER_PHONE_PE_MOBILE_API,
            },
            // Поле для парсера емейл
            {
              name: `email_username`,
              label: t(`wallets.email`),
              email: true,
              maxLength: 40,
              trim: true,
              required: true, //values.parser_type === ParserType.PARSER_EMAIL || values.sms_source === `email`,
              hide: values.parser_type !== ParserType.PARSER_EMAIL && values.sms_source !== `email`,
            },
            {
              name: `email_password`,
              label: t(`wallets.emailPassword`),
              password: true,
              onlyLatinDigitsAndSymbols: true,
              trim: true,
              maxLength: 40,
              required: true, //values.parser_type === ParserType.PARSER_EMAIL || values.sms_source === `email`,
              hide: values.parser_type !== ParserType.PARSER_EMAIL && values.sms_source !== `email`,
            },
            {
              name: `username`,
              label: t(`wallets.siteLogin`),
              required: true,
              maxLength: 30,
              // digital: true,
              trim: true,
              hide: notUseParserHDFC && notUseParserIndusIn && notUseMobileApi,
              unused: notUseParserHDFC && notUseParserIndusIn && notUseMobileApi,
            },
            {
              name: `password`,
              label: t(`wallets.sitePassword`),
              password: true,
              onlyLatinDigitsAndSymbols: true,
              required: true,
              trim: true,
              maxLength: 20,
              hide: notUseParserHDFC && notUseParserIndusIn,
              unused: notUseParserHDFC && notUseParserIndusIn,
            },
            {
              name: `parsing_object`,
              label: t(`wallets.parsingObject`),
              type: InputType.SELECT,
              data: parsingObjectOptions,
              hide: notUseParserHDFC,
              unused: notUseParserHDFC,
            },
            {
              name: `codephrases`,
              label: t(`common.codePhrase`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderUpiAbQuestions,
              required: true,
              hide: notUseParserIndusIn,
            },
            {
              name: `mnc`,
              label: t(`wallets.mnc`),
              digital: true,
              required: true,
              minLength: 2,
              maxLength: 6,
              hide: notUseMobileApi,
              unused: notUseMobileApi,
            },
            {
              name: `network_operator_name`,
              label: t(`wallets.operatorName`),
              regexMatch: {
                regex: /^[A-Z\s\da-z]+$/i,
                message: t(`errors.invalidFormat`),
              },
              onlyLatinDigitsAndSymbolsSpace: true,
              required: true,
              hide: notUseMobileApi,
              unused: notUseMobileApi,
              trim: true,
            },
            {
              name: `android_id`,
              label: t(`wallets.androidId`),
              trim: true,
              onlyLatinDigitsAndSymbolsSpace: true,
              required: true,
              hide: notUseMobileApi,
              unused: notUseMobileApi,
            },
            {
              name: `android_board`,
              label: t(`wallets.androidBoard`),
              regexMatch: {
                regex: /^[A-Za-z0-9]+$/i,
                message: t(`errors.invalidFormat`),
              },
              required: true,
              hide: notUseMobileApi,
              unused: notUseMobileApi,
            },
            {
              name: `android_hardware`,
              label: t(`wallets.androidHardware`),
              trim: true,
              onlyLatinDigitsAndSymbolsSpace: true,
              required: true,
              hide: notUseMobileApi,
              unused: notUseMobileApi,
            },
            {
              name: `android_serial`,
              label: t(`wallets.androidSerial`),
              trim: true,
              onlyLatinDigitsAndSymbolsSpace: true,
              required: true,
              hide: notUseMobileApi,
              unused: notUseMobileApi,
            },
            {
              name: `android_manufacturer`,
              label: t(`wallets.androidManufacturer`),
              required: true,
              hide: notUseMobileApi,
              unused: notUseMobileApi,
              regexMatch: {
                regex: /^[A-Za-z]+$/i,
                message: t(`errors.invalidFormat`),
              },
            },
            {
              name: `android_api_version`,
              label: t(`wallets.androidVersion`),
              regexMatch: {
                regex: /^[A-Za-z\d]+$/,
                message: t(`errors.invalidFormat`),
              },
              trim: true,
              required: true,
              hide: notUseMobileApi,
              unused: notUseMobileApi,
            },
            {
              name: `wifi_bssid`,
              label: t(`wallets.wifiMac`),
              regexMatch: {
                regex: /^([A-Fa-f0-9]{2}:){5}[A-Fa-f0-9]{2}$/i,
                message: t(`errors.invalidFormat`),
              },
              placeholder: `00:00:00:00:00:00`,
              type: InputType.MAC_ADDRESS,
              required: true,
              hide: notUseMobileApi,
              unused: notUseMobileApi,
            },
            {
              name: `wifi_ssid`,
              label: t(`wallets.wifiSsid`),
              maxLength: 32,
              required: true,
              hide: notUseMobileApi,
              unused: notUseMobileApi,
            },
            {
              name: `wifi_frequency`,
              label: t(`wallets.wifiFrequency`),
              required: true,
              regexMatch: {
                regex: /^(0|[1-9]+0*)(\.\d{1,15})?$/,
                message: t(`errors.invalidFormat`),
              },
              hide: notUseMobileApi,
              unused: notUseMobileApi,
            },
            {
              name: `latitude`,
              label: t(`wallets.latitude`),
              required: true,
              regexMatch: {
                regex: /^(?:-?(0|[1-9]+0*)(\.\d{1,15})?)?$/,
                message: t(`errors.invalidFormat`),
              },
              maxAmount: 90,
              minAmount: -90,
              hide: notUseMobileApi,
              unused: notUseMobileApi,
            },
            {
              name: `longitude`,
              label: t(`wallets.longitude`),
              required: true,
              regexMatch: {
                regex: /^(?:-?(0|[1-9]+0*)(\.\d{1,15})?)?$/,
                message: t(`errors.invalidFormat`),
              },
              maxAmount: 180,
              minAmount: -180,
              hide: notUseMobileApi,
              unused: notUseMobileApi,
            },
          ];
          break;
        case WalletType.NGAN_LUONG_EMAIL:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.merchantID`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `email_username`,
              label: t(`wallets.email`),
              required: true,
              email: true,
              trim: true,
            },
            {
              name: `email_password`,
              label: t(`wallets.emailPassword`),
              required: true,
              maxLength: 40,
              trim: true,
              password: true,
              onlyLatinDigitsAndSymbols: true,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];
          break;
        case WalletType.UPI_IB:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.identifier`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            // {
            //   name: `upi_addresses`,
            //   label: t(`wallets.addressUpi`),
            //   fieldType: FieldType.FIELD_ARRAY,
            //   component: renderUpiAddresses,
            //   required: true,
            // },
            {
              name: `account_name`,
              label: t(`wallets.accountHolderName`),
              required: true,
              trim: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
            {
              name: `username`,
              label: t(`wallets.siteLogin`),
              required: true,
              trim: true,
              maxLength: 32,
            },
            {
              name: `password`,
              label: t(`wallets.sitePassword`),
              password: true,
              onlyLatinDigitsAndSymbols: true,
              required: true,
              trim: true,
              maxLength: 20,
            },
          ];
          break;
        case WalletType.PAY_OP:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.identifier`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `api_public_key`,
              label: t(`wallets.apiKey`),
              required: true,
              trim: true,
            },
            {
              name: `api_private_key`,
              label: t(`wallets.payOpSecretKey`),
              required: true,
              trim: true,
            },
            {
              name: `project_id`,
              label: t(`wallets.payOpProjectId`),
              required: true,
              trim: true,
            },
            {
              name: `token`,
              label: t(`wallets.payOpToken`),
              required: true,
              trim: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
          ];
          break;
        case WalletType.UPI_AB:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `account_number`,
              label: t(`wallets.accountNumber`),
              required: true,
              maxLength: 15,
              digital: true,
              trim: true,
            },
            {
              name: `email_username`,
              label: t(`wallets.email`),
              required: true,
              email: true,
              maxLength: 40,
              trim: true,
            },
            {
              name: `email_password`,
              label: t(`wallets.emailPassword`),
              password: true,
              onlyLatinDigitsAndSymbols: true,
              required: true,
              trim: true,
              maxLength: 40,
            },
            {
              name: `email_sender`,
              label: t(`wallets.emailSender`),
              required: true,
              email: true,
              maxLength: 40,
              trim: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            // {
            //   name: `upi_addresses`,
            //   label: t(`wallets.addressUpi`),
            //   fieldType: FieldType.FIELD_ARRAY,
            //   component: renderUpiAddresses,
            //   required: true,
            // },
          ];
          break;
        case WalletType.UPI_HB:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.upiHbIdentifier`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `username`,
              label: t(`wallets.upiHbUsername`),
              required: true,
              trim: true,
              maxLength: 32,
            },
            {
              name: `password`,
              label: t(`wallets.upiHbPassword`),
              required: true,
              trim: true,
              password: true,
              onlyLatinDigitsAndSymbols: true,
              maxLength: 20,
            },
            {
              name: `parsing_object`,
              label: t(`wallets.parsingObject`),
              required: true,
              type: InputType.SELECT,
              data: parsingObjectOptions,
            },
            {
              name: `currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `account_number`,
              label: t(`wallets.upiHbAccountNumber`),
              trim: true,
              required: true,
            },
            // {
            //   name: `upi_addresses`,
            //   label: t(`wallets.addressUpi`),
            //   fieldType: FieldType.FIELD_ARRAY,
            //   component: renderUpiAddresses,
            //   required: true,
            // },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];
          break;
        case WalletType.IMPS_IDB:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.impsHbIdentifier`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `account_name`,
              label: t(`wallets.accountHolderName`),
              required: true,
              trim: true,
            },
            {
              name: `ifsc_code`,
              label: t(`wallets.IMPSHBIFSCcode`),
              required: true,
              trim: true,
            },
            {
              name: `username`,
              label: t(`wallets.siteLogin`),
              required: true,
              maxLength: 32,
              trim: true,
            },
            {
              name: `password`,
              label: t(`wallets.sitePassword`),
              password: true,
              onlyLatinDigitsAndSymbols: true,
              required: true,
              trim: true,
              maxLength: 20,
            },
            {
              name: `currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];
          break;
        case WalletType.IMPS_HB:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.impsHbIdentifier`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `account_name`,
              label: t(`wallets.accountHolderName`),
              required: true,
              trim: true,
            },
            {
              name: `ifsc_code`,
              label: t(`wallets.IMPSHBIFSCcode`),
              required: true,
              trim: true,
            },
            {
              name: `username`,
              label: t(`wallets.siteLogin`),
              required: true,
              maxLength: 32,
              trim: true,
            },
            {
              name: `password`,
              label: t(`wallets.sitePassword`),
              password: true,
              onlyLatinDigitsAndSymbols: true,
              required: true,
              trim: true,
              maxLength: 20,
            },
            {
              name: `parsing_object`,
              label: t(`wallets.parsingObject`),
              required: true,
              type: InputType.SELECT,
              data: parsingObjectOptions,
            },
            {
              name: `currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];
          break;
        case WalletType.UPI_NAAP:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `api_public_key`,
              label: t(`wallets.apiKey`),
              required: true,
              trim: true,
            },
            {
              name: `project_id`,
              label: t(`wallets.payOpProjectId`),
              required: true,
              trim: true,
            },
            {
              name: `api_private_key`,
              label: t(`wallets.secretKey`),
              required: true,
              trim: true,
            },
            {
              name: `webhook_id`,
              label: t(`wallets.webhookID`),
              trim: true,
              digital: true,
              required: true,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];
          break;
        case WalletType.UPI_IDB:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.identifier`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `username`,
              label: t(`wallets.siteLogin`),
              required: true,
              maxLength: 32,
              trim: true,
            },
            {
              name: `password`,
              label: t(`wallets.sitePassword`),
              password: true,
              onlyLatinDigitsAndSymbols: true,
              required: true,
              trim: true,
              maxLength: 20,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            // {
            //   name: `upi_addresses`,
            //   label: t(`wallets.addressUpi`),
            //   fieldType: FieldType.FIELD_ARRAY,
            //   component: renderUpiAddresses,
            //   required: true,
            // },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];
          break;
        case WalletType.IMPS_AB:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `account_number`,
              label: t(`wallets.accountNumber`),
              required: true,
              maxLength: 15,
              digital: true,
              trim: true,
            },
            {
              name: `account_name`,
              label: t(`wallets.IMPSAccountName`),
              required: true,
              trim: true,
            },
            {
              name: `ifsc_code`,
              label: t(`wallets.IMPSIFSCcode`),
              required: true,
              trim: true,
            },
            {
              name: `parser_type`,
              label: t(`wallets.parser`),
              type: InputType.SELECT,
              required: true,
              data: getWalletParserTypes(walletType, t),
              translateKeys: true,
            },
            {
              name: `email_username`,
              label: t(`wallets.email`),
              required: true,
              email: true,
              maxLength: 40,
              trim: true,
              hide: notUseParserEmail,
              unused: notUseParserEmail,
            },
            {
              name: `email_password`,
              label: t(`wallets.emailPassword`),
              password: true,
              onlyLatinDigitsAndSymbols: true,
              required: true,
              trim: true,
              maxLength: 40,
              hide: notUseParserEmail,
              unused: notUseParserEmail,
            },
            {
              name: `username`,
              label: t(`wallets.siteLogin`),
              required: true,
              trim: true,
              hide: notUseParserAxis,
              unused: notUseParserAxis,
            },
            {
              name: `password`,
              label: t(`wallets.sitePassword`),
              required: true,
              password: true,
              onlyLatinDigitsAndSymbols: true,
              trim: true,
              hide: notUseParserAxis,
              unused: notUseParserAxis,
            },
            {
              name: `codephrases`,
              label: t(`common.codePhrase`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderUpiAbQuestions,
              required: true,
              hide: notUseParserAxis,
              unused: notUseParserAxis,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy && notUseParserAxis,
              hide: notUseParserAxis,
              unused: notUseParserAxis,
            },
            {
              name: `currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
          ];
          break;
        case WalletType.LAYKAPAY: {
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.identifier`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `api_public_key`,
              label: t(`wallets.payzenApiKey`),
              required: true,
              trim: true,
            },
            {
              name: `api_private_key`,
              label: t(`wallets.laykapaySecretKey`),
              required: true,
              trim: true,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];
          break;
        }
        case WalletType.UPI_S:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `integration_type`,
              label: t(`wallets.integrationType`),
              type: InputType.SELECT,
              data: [
                {
                  value: `upi_s`,
                  text: `UPI S`,
                },
                {
                  value: `a_pay`,
                  text: `A-Pay`,
                },
              ],
              required: true,
            },
            {
              name: `api_public_key`,
              label: t(`wallets.apiKey`),
              required: values.integration_type,
              hide: !values.integration_type,
              trim: true,
            },
            {
              name: `api_private_key`,
              label: t(`wallets.secretKey`),
              trim: true,
              required: values.integration_type === `a_pay`,
              hide: values.integration_type !== `a_pay`,
            },
            {
              name: `upi_addresses`,
              label: t(`wallets.addressUpi`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderUpiAddresses,
              required: values.integration_type === `upi_s`,
              hide: values.integration_type !== `upi_s`,
            },
            {
              name: `account_name`,
              label: t(`wallets.vietcomBankAccountHolder`),
              required: true,
              trim: true,
              required: values.integration_type === `upi_s`,
              hide: values.integration_type !== `upi_s`,
            },
            {
              name: `merchant_id`,
              label: t(`wallets.merchantID`),
              trim: true,
              required: values.integration_type === `upi_s`,
              hide: values.integration_type !== `upi_s`,
              maxLength: 40,
            },
            {
              name: `project_id`,
              label: t(`wallets.payOpProjectId`),
              trim: true,
              required: values.integration_type === `a_pay`,
              hide: values.integration_type !== `a_pay`,
              // regexMatch: {
              //   regex: /^\d+$/,
              //   message: t(`errors.invalidFormat`),
              // },
            },
            {
              name: `webhook_id`,
              label: t(`wallets.webhookID`),
              trim: true,
              digital: true,
              required: values.integration_type === `a_pay`,
              hide: values.integration_type !== `a_pay`,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];
          break;
        /*
          См. https://jira.fixmost.com/browse/PAY-12492,
          раскомментить как будет понятно как должно работать
        */
        //case WalletType.UPI_SB:
        case WalletType.UPI_SF:
        case WalletType.PHONEPE_S:
        case WalletType.UPI_AP:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `api_public_key`,
              label: t(`wallets.apiKey`),
              required: true,
              trim: true,
            },
            {
              name: `upi_addresses`,
              label: t(`wallets.addressUpi`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderUpiAddresses,
              required: true,
            },
            {
              name: `account_name`,
              label: t(`wallets.vietcomBankAccountHolder`),
              required: true,
              trim: true,
            },
            {
              name: `merchant_id`,
              label: t(`wallets.merchantID`),
              required: true,
              trim: true,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];
          break;
        case WalletType.PAYTM_API:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `merchant_id`,
              label: t(`wallets.merchantID`),
              required: true,
              trim: true,
            },
            {
              // name: `merchant_key`,
              name: `api_public_key`,
              // label: t(`wallets.merchantKey`),
              label: t(`wallets.apiKey`),
              required: true,
              trim: true,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];
          break;
        case WalletType.UZCARD_API:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `merchant_id`,
              label: t(`wallets.merchantID`),
              required: true,
              trim: true,
            },
            {
              name: `token`,
              label: t(`wallets.token`),
              required: true,
              trim: true,
            },
            {
              name: `phrase`,
              label: t(`wallets.uzcardApiCodePhrase`),
              onlyLatinDigitsAndSymbols: true,
              trim: true,
              required: true,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];
          break;
        case WalletType.UPI_PB:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `merchant_id`,
              label: t(`wallets.merchantID`),
              required: true,
              trim: true,
            },
            {
              // name: `merchant_key`,
              // label: t(`wallets.merchantKey`),
              name: `api_public_key`,
              label: t(`wallets.apiKey`),
              required: true,
              trim: true,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];
          break;
        case WalletType.BKASH_API:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `username`,
              label: t(`wallets.siteLogin`),
              required: true,
              trim: true,
            },
            {
              name: `password`,
              label: t(`wallets.sitePassword`),
              required: true,
              password: true,
              onlyLatinDigitsAndSymbols: true,
              trim: true,
            },
            {
              name: `api_public_key`,
              label: t(`wallets.apiKey`),
              required: true,
              trim: true,
            },
            {
              name: `api_private_key`,
              label: t(`wallets.secretKey`),
              required: true,
              trim: true,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];
          break;
        case WalletType.MPESA:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `merchant_id`,
              label: t(`wallets.merchantID`),
              required: true,
              digital: true,
              trim: true,
            },
            {
              name: `api_public_key`,
              label: t(`wallets.apiKey`),
              required: true,
              trim: true,
            },
            {
              name: `api_private_key`,
              label: t(`wallets.secretKey`),
              required: true,
              trim: true,
            },
            {
              name: `password`,
              label: t(`wallets.mpesaPassKey`),
              required: true,
              password: true,
              onlyLatinDigitsAndSymbols: true,
              trim: true,
              maxLength: 64,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];

          break;
        case WalletType.EASYPAISA_API:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `account_number`,
              label: t(`wallets.accountNumber`),
              required: true,
              maxLength: 15,
              onlyLatinDigitsAndSymbols: true,
              trim: true,
            },
            {
              name: `merchant_id`,
              label: t(`wallets.storeID`),
              required: true,
              digital: true,
              trim: true,
            },
            {
              name: `api_public_key`,
              label: t(`wallets.apiKey`),
              required: true,
              trim: true,
            },
            {
              name: `api_private_key`,
              label: t(`wallets.secretKey`),
              required: true,
              trim: true,
            },
            {
              name: `username`,
              label: t(`wallets.siteLogin`),
              required: true,
              trim: true,
              onlyLatinDigitsAndSymbolsStrict: true,
              maxLength: 40,
            },
            {
              name: `password`,
              label: t(`wallets.sitePassword`),
              required: true,
              password: true,
              onlyLatinDigitsAndSymbols: true,
              trim: true,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];

          break;
        case WalletType.UPI_IN:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `account_number`,
              label: t(`wallets.accountNumber`),
              required: true,
              maxLength: 15,
              digital: true,
              trim: true,
            },
            {
              name: `username`,
              label: t(`wallets.siteLogin`),
              required: true,
              trim: true,
              onlyLatinDigitsAndSymbolsStrict: true,
              maxLength: 40,
            },
            {
              name: `password`,
              label: t(`wallets.sitePassword`),
              required: true,
              password: true,
              onlyLatinDigitsAndSymbols: true,
              trim: true,
            },
            {
              name: `codephrases`,
              label: t(`common.codePhrase`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderUpiAbQuestions,
              required: true,
            },
            // {
            //   name: `upi_addresses`,
            //   label: t(`wallets.addressUpi`),
            //   required: true,
            //   fieldType: FieldType.FIELD_ARRAY,
            //   component: renderUpiAddresses,
            // },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];
          break;
        case WalletType.UPI_W:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `username`,
              label: t(`wallets.siteLogin`),
              required: true,
              trim: true,
              digital: true,
              maxLength: 10,
            },
            {
              name: `email_username`,
              label: t(`wallets.email`),
              required: true,
              email: true,
              trim: true,
              maxLength: 40,
            },
            {
              name: `email_password`,
              label: t(`wallets.emailPassword`),
              password: true,
              required: true,
            },
            // {
            //   name: `upi_addresses`,
            //   label: t(`wallets.addressUpi`),
            //   required: true,
            //   fieldType: FieldType.FIELD_ARRAY,
            //   component: renderUpiAddresses,
            // },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
            // {
            //   name: `cookie`,
            //   label: t(`wallets.cookie`),
            //   jsonFields: [
            //     {
            //       name: `payload`,
            //     },
            //     {
            //       name: `date`,
            //       dateFormat: `YYYY-MM-DD hh:mm:ss`,
            //     },
            //     {
            //       name: `type`,
            //       valueRange: [`work`, `middle`],
            //     },
            //   ],
            // },
          ];
          break;
        case WalletType.BKASH_API_CEBALRAI:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `username`,
              label: t(`wallets.siteLogin`),
              required: true,
              trim: true,
            },
            {
              name: `password`,
              label: t(`wallets.sitePassword`),
              required: true,
              password: true,
              onlyLatinDigitsAndSymbols: true,
              trim: true,
            },
            {
              name: `api_public_key`,
              label: t(`wallets.apiKey`),
              required: true,
              trim: true,
            },
            {
              name: `api_private_key`,
              label: t(`wallets.secretKey`),
              required: true,
              trim: true,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];
          break;
        case WalletType.CLICK_UZS: {
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.numberOfCard`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `email_username`,
              label: t(`wallets.email`),
              required: values.sms_source == `email`,
              email: true,
              maxLength: 40,
              trim: true,
              hidden: values.sms_source !== `email`,
            },
            {
              name: `email_password`,
              label: t(`wallets.emailPassword`),
              password: true,
              onlyLatinDigitsAndSymbols: true,
              required: values.sms_source == `email`,
              trim: true,
              maxLength: 40,
              hidden: values.sms_source !== `email`,
              unused: values.sms_source !== `email`,
            },
            {
              name: `sms_source`,
              label: t(`wallets.smsSource`),
              type: InputType.SELECT,
              required: true,
              data: [
                {
                  value: `email`,
                  text: t(`wallets.email`),
                },
                {
                  value: `mobile_data`,
                  // Нет ошибки с t(). На макете для 2 языков Mobile Data.
                  text: `Mobile Data`,
                },
              ],
            },
            {
              name: `username`,
              label: t(`wallets.siteLogin`),
              required: true,
              trim: true,
              digital: true,
              maxLength: 12,
              minLength: 9,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
            // {
            //   name: `cookie`,
            //   label: t(`wallets.cookie`),
            //   required: false,
            //   jsonFields: [
            //     {
            //       name: `payload`,
            //     },
            //     {
            //       name: `date`,
            //       dateFormat: `YYYY-MM-DD hh:mm:ss`,
            //     },
            //     {
            //       name: `type`,
            //       valueRange: [`work`, `middle`],
            //     },
            //   ],
            // },
          ];
          break;
        }
        case WalletType.JAZZCASH:
        case WalletType.WAVE_PAY: {
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              onlyLatinDigitsAndSymbols: true,
              maxLength: 40,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `email_username`,
              label: t(`wallets.email`),
              required: values.sms_source == `email`,
              email: true,
              maxLength: 40,
              trim: true,
              hidden: values.sms_source !== `email`,
              unused: values.sms_source !== `email`,
            },
            {
              name: `email_password`,
              label: t(`wallets.emailPassword`),
              password: true,
              required: values.sms_source == `email`,
              hidden: values.sms_source !== `email`,
              unused: values.sms_source !== `email`,
            },
            {
              name: `sms_source`,
              label: t(`wallets.smsSource`),
              type: InputType.SELECT,
              required: true,
              data: [
                {
                  value: `email`,
                  text: t(`wallets.email`),
                },
                {
                  value: `mobile_data`,
                  // Нет ошибки с t(). На макете для 2 языков Mobile Data.
                  text: `Mobile Data`,
                },
              ],
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];

          break;
        }

        case WalletType.UPI_M: {
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `username`,
              label: t(`wallets.siteLogin`),
              required: true,
              trim: true,
              digital: true,
              maxLength: 10,
              minLength: 10,
            },
            {
              name: `email_username`,
              label: t(`wallets.email`),
              required: values.sms_source == `email`,
              email: true,
              maxLength: 40,
              trim: true,
              hidden: values.sms_source !== `email`,
              unused: values.sms_source !== `email`,
            },
            {
              name: `email_password`,
              label: t(`wallets.emailPassword`),
              password: true,
              onlyLatinDigitsAndSymbols: true,
              required: values.sms_source == `email`,
              trim: true,
              maxLength: 40,
              hidden: values.sms_source !== `email`,
              unused: values.sms_source !== `email`,
            },
            {
              name: `sms_source`,
              label: t(`wallets.smsSource`),
              type: InputType.SELECT,
              required: true,
              data: [
                {
                  value: `email`,
                  text: t(`wallets.email`),
                },
                {
                  value: `mobile_data`,
                  // Нет ошибки с t(). На макете для 2 языков Mobile Data.
                  text: `Mobile Data`,
                },
              ],
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
            // {
            //   name: `upi_addresses`,
            //   label: t(`wallets.addressUpi`),
            //   required: true,
            //   fieldType: FieldType.FIELD_ARRAY,
            //   component: renderUpiAddresses,
            // },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
          ];
          break;
        }

        case WalletType.IPAY_API:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              // В поле будет передаваться merchantWebToken сгенерированный в ЛК Ipay
              name: `token`,
              label: t(`wallets.token`),
              required: true,
            },
            {
              // Секретный ключ продавца.  Необходим для проверки контрольной суммы в колбэках от Ipay.
              name: `api_private_key`,
              label: t(`wallets.secretKey`),
              required: true,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            // {
            //   name: `priority`,
            //   id: `${formName}_priority`,
            //   label: t(`wallets.priority`),
            //   type: InputType.SELECT,
            //   translateKeys: true,
            //   data: walletPriorityData,
            //   required: true,
            // },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              required: useProxy,
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
            },
          ];
          break;
        case WalletType.JAZZCASH_BUSINESS:
          newFields = [
            {
              name: `identifier`,
              label: t(`wallets.number`),
              required: true,
              trim: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
            {
              name: `email_username`,
              label: t(`wallets.email`),
              required: true,
              email: true,
              maxLength: 40,
              trim: true,
            },
            {
              name: `email_password`,
              label: t(`wallets.emailPassword`),
              password: true,
              onlyLatinDigitsAndSymbols: true,
              required: true,
              trim: true,
              maxLength: 40,
            },
            {
              name: `qr_data`,
              label: `QR`,
              fileAcceptedTypes: [`.svg`, `.png`, `.jpeg`, `.ico`, `.jpg`],
              type: InputType.DROPZONE,
              required: true,
              hide: type !== WalletFormType.CREATE,
              unused: type !== WalletFormType.CREATE,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
          ];
          break;
        default:
          newFields = [
            {
              name: `email_username`,
              label: t(`wallets.email`),
              required: true,
              email: true,
              maxLength: 40,
              trim: true,
            },
            {
              name: `email_password`,
              label: t(`wallets.emailPassword`),
              password: true,
              onlyLatinDigitsAndSymbols: true,
              required: true,
              trim: true,
              maxLength: 40,
            },
            {
              name: `proxies`,
              label: t(`common.proxies`),
              fieldType: FieldType.FIELD_ARRAY,
              component: renderProxies,
              busy: proxyListLoading,
              required: useProxy,
            },
            {
              name: `currency_code`,
              id: `${formName}_currency_code`,
              label: t(`common.currency`),
              type: InputType.SELECT,
              data: currencies,
              required: true,
            },
          ];
      }

      if (isManualPS && manualFields) {
        newFields = [
          {
            name: `identifier`,
            label: t(`wallets.number`),
            required: true,
            trim: true,
            hide: type !== WalletFormType.CREATE,
            unused: type !== WalletFormType.CREATE,
          },
          ...manualFields.map((manualField: any) => ({
            name: manualField.code,
            label: manualField.name,
            required: true,
          })),
          {
            name: `proxies`,
            label: t(`common.proxies`),
            fieldType: FieldType.FIELD_ARRAY,
            component: renderProxies,
            busy: proxyListLoading,
            required: false,
          },
          {
            name: `currency_code`,
            id: `${formName}_currency_code`,
            label: t(`common.currency`),
            type: InputType.SELECT,
            data: currencies,
            required: true,
          },
        ];
      }

      const parsingDateTime = !isParsingStartAt
        ? [
            {
              name: `parsing_start_hint`,
              label: t(`wallets.ParsingStartDate`),
              placeholder: t(`wallets.walletCreationDate`),
              onBlur: () => setIsParsingStartAt(false),
              onFocus: () => setIsParsingStartAt(true),
            },
          ]
        : [
            {
              name: `parsing_start_at`,
              label: t(`wallets.ParsingStartDate`),
              type: InputType.DATE_TIME_PICKER,
              fromValue: values?.parsing_start_at,
              timePicker: true,
              hideOnDateChanging: false,
              bothDateRequired: true,
              showSeconds: true,
            },
          ];

      newFields = [
        ...newFields,
        {
          name: `priority`,
          label: t(`wallets.priority`),
          required: true,
          type: InputType.SELECT,
          translateKeys: true,
          data: walletPriorityData,
        },
        {
          name: `vendor_code`,
          label: t(`wallets.vendorCode`),
          maxLength: 190,
          required: true,
          hide: !walletPrivateInformationView,
          unused: !walletPrivateInformationView,
        },
        {
          name: `is_standard`,
          type: InputType.CHECKBOX,
          label: t(`wallets.standardWallet`),
        },
        {
          name: `wallet_off_limit`,
          label: t(`wallets.walletOffLimit`),
          standardAmount: true,
          required: isParsingPS,
        },
        {
          name: `wallet_off_load`,
          label: t(`wallets.dailyLimitOnAmount`),
          standardAmount: true,
          required: !isParsingPS,
        },
        {
          name: `daily_transaction_limit`,
          label: t(`wallets.dailyTransactionLimit`),
          digital: true,
          trim: true,
          standardAmount: true,
        },
        {
          name: `provider`,
          label: t(`wallets.provider`),
          required: true,
          onlyLatinDigitsAndSymbols: true,
          isProvider: true,
          maxLength: 40,
        },
        {
          name: `display_identifier`,
          label: t(`wallets.displayIdentifier`),
          required: false,
          maxLength: 40,
        },
        ...(isP2P ? parsingDateTime : []),
      ];

      if (!isEqual(fields, newFields)) {
        setFields(newFields);
      }
    }
  }, [
    walletType,
    values,
    payzenCountries.length,
    payzenCurrencies.length,
    payzenMethods.length,
    currencies.length,
    proxyList?.length,
    useProxy,
    isManualPS,
    manualFields?.length,
    isParsingStartAt,
    isParsingPS,
  ]);

  return useFilterFormFields({ fields, formName });
};

export default useWalletData;
