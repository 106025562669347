import React from 'react';
import { getFormValues, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GridType, getSubmitHandler, InputType } from '@kassma-team/kassma-toolkit/lib';

import { ModalType, Permission, WalletType, WidgetType } from 'utils/enums';
import { possibleTransactionActionCreators } from 'actions/widgets/transactions/possibleTransactions';
import { preliminaryTransactionStatusList } from 'static/transactions';
import useManualPSList from 'hooks/widgets/manualPS/useManualPSList';
import { paymentSystemsForPossibleTransactionsActionCreators } from 'actions/widgets/paymentSystem';
import usePossibleTransactionsTableTitles from 'hooks/widgets/tableTitles/transactions/usePossibleTransactionsTableTitles';
import useList from 'hooks/widgets/useList';
import {
  IPossibleTransactionRowProps,
  IPossibleTransactionsFilterForm,
} from 'interfaces/widgets/transaction/transaction';
import { IFilterProps } from 'interfaces/widgets/widgets';

import Filters from 'components/widgets/filters/Filters';
import PossibleTransactionRow from 'components/widgets/transaction/possibleTransactions/PossibleTransactionRow';
import transactionRouteTabs from 'pages/transactions/transactionRouteTabs';
import PossibleTransactionItemModal from 'components/widgets/transaction/possibleTransactions/PossibleTransactionItemModal';
import Widget from 'components/widgets/Widget';
import PossibleTransactionApproveModal from 'components/widgets/transaction/possibleTransactions/PossibleTransactionApproveModal';
import withPermissionCheck from '../../hocs/withPermissionCheck';

const FILTER_FORM_NAME = `transactions-management-filters`;

const FilterForm = reduxForm<any, IFilterProps>({
  form: FILTER_FORM_NAME,
  onSubmit: getSubmitHandler({
    dateFields: [{ name: `date_range`, fromName: `date_from`, toName: `date_to` }],
    dateFormat: `YYYY-MM-DD HH:mm`,
  }),
})(Filters);

const rightFilterFields = [
  {
    name: `date_range`,
    type: InputType.DATE_RANGE,
    timePicker: true,
  },
];

const filterDateFields = [
  {
    from: `date_from`,
    to: `date_to`,
    name: `date_range`,
  },
];

const modals = [
  {
    type: ModalType.SHOW,
    widget: WidgetType.POSSIBLE_TRANSACTIONS,
    component: PossibleTransactionItemModal,
  },
  {
    type: ModalType.UPDATE,
    widget: WidgetType.POSSIBLE_TRANSACTIONS,
    component: PossibleTransactionApproveModal,
  },
];

const listMapping = (item: IPossibleTransactionRowProps) => <PossibleTransactionRow key={item.primary_id} {...item} />;

const PossibleTransactions = () => {
  const tableTitles = usePossibleTransactionsTableTitles();
  const [t] = useTranslation();

  const values: IPossibleTransactionsFilterForm = useSelector(getFormValues(FILTER_FORM_NAME)) || {};
  const testPaymentSystems = useManualPSList({
    walletType: values.wallet_type,
    fieldNameOfSelectionOfTestPayments: `test_wallet_type_code`,
    form: FILTER_FORM_NAME,
  });

  const safeTestPayments = testPaymentSystems?.map((el) => ({
    ...el,
    text: el.name,
    value: el.code,
  }));

  let { items: walletTypes } = useList({
    widget: WidgetType.PAYMENT_SYSTEMS_FOR_POSSIBLE_TRANSACTIONS,
    actionCreators: paymentSystemsForPossibleTransactionsActionCreators,
    resetOnUnmount: false,
    loadOnlyIfNecessary: true,
  });

  walletTypes = (walletTypes || []).filter((walletType) => !walletType.is_settlement);

  const safeWalletTypes = walletTypes?.map((el) => ({
    ...el,
    text: el.name,
    value: el.code,
  }));

  const leftFilterFields: Record<string, unknown>[] = [
    {
      name: `primary_id`,
      placeholder: t(`common.ID`),
      numeric: true,
    },
    {
      name: `transaction_id`,
      placeholder: t(`transactions.transactionId`),
    },
    {
      name: `wallet_type`,
      id: `${FILTER_FORM_NAME}_wallet_type`,
      data: [{ value: null, text: t(`common.all`) }, ...(safeWalletTypes || [])],
      type: InputType.SELECT,
      placeholder: t(`wallets.walletType`),
      translateKeys: true,
    },
    {
      name: `status`,
      id: `${FILTER_FORM_NAME}_status`,
      data: preliminaryTransactionStatusList,
      type: InputType.SELECT,
      placeholder: t(`common.status`),
      translateKeys: true,
    },
    {
      name: `label`,
      placeholder: t(`common.label`),
    },
    {
      name: `merchant_order_id`,
      placeholder: t(`common.orderId`),
    },
  ];

  if (values.wallet_type === WalletType.TEST) {
    leftFilterFields.push({
      name: `test_wallet_type_code`,
      id: `${FILTER_FORM_NAME}_test_wallet_type_code`,
      type: InputType.SELECT,
      placeholder: t(`wallets.manualPayment`),
      data: safeTestPayments,
      required: true,
    });
  }

  return (
    <Widget
      actionCreators={possibleTransactionActionCreators}
      widget={WidgetType.POSSIBLE_TRANSACTIONS}
      filterFormName={FILTER_FORM_NAME}
      pageTitle="transactions.transactions"
      tableTitle="transactions.possibleTransactions"
      headTitle="transactions.possibleTransactions"
      routeTabs={transactionRouteTabs}
      tableHeadTitles={tableTitles}
      listMapping={listMapping}
      filterForm={
        <FilterForm
          leftFields={leftFilterFields}
          rightFields={rightFilterFields}
          formName={FILTER_FORM_NAME}
          dateFields={filterDateFields}
          gridType={GridType.GRID}
          numFields={[`status`]}
        />
      }
      withPagination
      statusBar
      modals={modals}
    />
  );
  // return <PossibleTransactionApproveModal />;
};

export default withPermissionCheck(PossibleTransactions, [
  {
    permissions: [
      {
        permission: Permission.TRANSACTION_MANAGEMENT,
        opposite: true,
      },
    ],
    redirectUrl: `/no-permissions`,
  },
]);
