import React, { useEffect, useMemo, useState } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { IWidgetActionCreators, widgetModalSelector, widgetSubmittingSelector } from '@kassma-team/kassma-toolkit/lib';

import { TRANSACTION_ACTIVATION_FORM_NAME } from 'utils/constants';
import useTransactionSubmitHandler from 'hooks/widgets/transactions/useTransactionSubmitHandler';
import { hasAccessSelector } from 'selectors/auth';
import { Permission } from 'utils/enums';

import ConfirmationModal from 'components/widgets/transaction/ConfirmationModal';
import ModalElement from 'components/modals/ModalElement';
import ModalForm from 'components/modals/ModalForm';

const modalTitle = `transactions.transactionActivation`;

interface ITransactionActivationModalProps {
  actionCreators: IWidgetActionCreators;
  widget: string;
}

const TransactionActivationModal = ({
  actionCreators,
  widget,
  initialize,
  ...props
}: ITransactionActivationModalProps & InjectedFormProps) => {
  const [t] = useTranslation();

  const modal: any = useSelector(widgetModalSelector(widget));
  const [primaryId] = useState(get(modal, `primary_id`));
  const [creationType] = useState(get(modal, `creation_type`));
  const [transactionType] = useState(get(modal, `transactionType`));

  const label = modal?.label;
  const merchant_order_id = modal?.merchant_order_id;

  useEffect(() => {
    const initData: any = {};
    if (label) {
      initData.label = label;
    }
    if (merchant_order_id) {
      initData.merchant_order_id = merchant_order_id;
    }
    initialize(initData);
  }, [label, merchant_order_id]);

  const {
    onSubmit,
    onConfirmationInfoCancel,
    onForcedSubmit,
    confirmationInfoType,
    confirmationInfoData,
  } = useTransactionSubmitHandler({
    primaryId,
    form: props.form,
    type: `activation`,
    widget,
    creationType,
    transactionType,
  });

  const submitting = useSelector(widgetSubmittingSelector(widget));
  const transactionNumberViewAccess = useSelector(hasAccessSelector(Permission.TRANSACTION_NUMBER_VIEW));

  const fields = useMemo(
    () => [
      {
        label: t(`transactions.label`),
        name: `label`,
        required: true,
      },
      {
        label: t(`transactions.orderNumber`),
        name: `merchant_order_id`,
      },
      ...[
        !transactionNumberViewAccess
          ? {
              label: t(`transactions.transactionNumber`),
              name: `transaction_id`,
              required: true,
              maxLength: 25,
            }
          : undefined,
      ],
    ],
    [transactionNumberViewAccess]
  );

  if (confirmationInfoType) {
    const content = (
      <ConfirmationModal
        onSave={onForcedSubmit}
        onCancel={onConfirmationInfoCancel}
        infoType={confirmationInfoType}
        infoData={confirmationInfoData}
        submitFailed={props.submitFailed}
        error={props.error}
        submitting={submitting as boolean}
      />
    );

    return <ModalElement content={content} actionCreators={actionCreators} title={modalTitle} />;
  }

  return (
    <ModalForm
      {...props}
      actionCreators={actionCreators}
      title={modalTitle}
      onSubmit={onSubmit}
      widget={widget}
      fields={fields}
      submitText="transactions.activate"
    />
  );
};

export default reduxForm<ITransactionActivationModalProps, any>({
  form: TRANSACTION_ACTIVATION_FORM_NAME,
})(TransactionActivationModal);
