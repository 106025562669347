import React, { useEffect, useMemo } from 'react';
import { reduxForm } from 'redux-form';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { DropdownButtons, GridType, getSubmitHandler, InputType } from '@kassma-team/kassma-toolkit/lib';

import { ModalType, Permission, PostbackFilter, WidgetType, WithdrawalMethod, WithdrawalType } from 'utils/enums';
import { withdrawalsActionCreators, withdrawalStatusActionCreators } from 'actions/widgets/withdrawals';
import { withdrawalPSActionCreators } from 'actions/widgets/paymentSystem';
import { withdrawalsPSSelector } from 'selectors/widgets/paymentSystems';
import useList from 'hooks/widgets/useList';
import { hasAccessSelector } from 'selectors/auth';
import useWithdrawalsTableTitles from 'hooks/widgets/tableTitles/useWithdrawalsTableTitles';
import withPermissionCheck from 'hocs/withPermissionCheck';
import { IFilterProps } from 'interfaces/widgets/widgets';

import WithdrawalRow from 'components/widgets/withdrawals/WithdrawalRow';
import RejectionModal from 'components/widgets/withdrawals/RejectionModal';
import Filters from 'components/widgets/filters/Filters';
import ApprovalModal from 'components/widgets/withdrawals/ApprovalModal';
import WithdrawalCreationForm from 'components/widgets/withdrawals/create/WithdrawalCreationForm';
import ConfirmApprovalModal from 'components/widgets/withdrawals/ConfirmApprovalModal';
import ConfirmRejectionModal from 'components/widgets/withdrawals/ConfirmRejectionModal';
import WithdrawalComment from 'components/widgets/withdrawals/WithdrawalComment';
import WithdrawalItemModal from 'components/widgets/withdrawals/WithdrawalItem';
import ManualWithdrawalsUploadingModal from 'components/widgets/withdrawals/ManualWithdrawalsUploadingModal';
import ManualWithdrawalsDownloadingModal from 'components/widgets/withdrawals/ManualWithdrawalsDownloadingModal';
import ManualWithdrawalsErrorModal from 'components/widgets/withdrawals/ManualWithdrawalsErrorModal';
import Widget from 'components/widgets/Widget';
import TransactionPostbackList from 'components/widgets/transaction/TransactionPostbackList';
import { IWithdrawalRowProps } from 'interfaces/widgets/withdrawals';
import { notAcceptedPostbackActionCreators } from '../actions/widgets/postback';

const { showModal } = withdrawalsActionCreators;

const FORM_NAME = `withdrawals-filters`;

const FilterForm = reduxForm<any, IFilterProps>({
  form: FORM_NAME,
  onSubmit: getSubmitHandler({
    dateFields: [{ name: `date_range`, fromName: `date_from`, toName: `date_to` }],
    dateFormat: `YYYY-MM-DD HH:mm:ss`,
  }),
})(Filters);

const listMapping = (item: IWithdrawalRowProps) => <WithdrawalRow {...item} key={item.id} />;

const modals = [
  {
    type: ModalType.REJECT_WITHDRAWAL,
    widget: WidgetType.WITHDRAWALS,
    component: RejectionModal,
  },
  {
    component: ApprovalModal,
    type: ModalType.APPROVAL_CONFIRMATION,
    widget: WidgetType.WITHDRAWALS,
  },
  {
    component: () =>
      TransactionPostbackList({
        actionCreators: withdrawalsActionCreators,
        widget: WidgetType.WITHDRAWALS,
        shouldUseItem: true,
      }),
    type: ModalType.POSTBACK_LIST,
    widget: WidgetType.WITHDRAWALS,
  },
  {
    component: () =>
      TransactionPostbackList({
        actionCreators: withdrawalsActionCreators,
        listActionCreators: notAcceptedPostbackActionCreators,
        modalWidget: WidgetType.WITHDRAWALS,
        widget: WidgetType.NOT_ACCEPTED_POSTBACKS,
        shouldUseItem: true,
      }),
    type: ModalType.NOT_ACCEPTED_TRANSACTION_POSTBACKS,
    widget: WidgetType.WITHDRAWALS,
  },
  {
    component: ConfirmApprovalModal,
    type: ModalType.CONFIRM_APPROVAL_CONFIRMATION,
    widget: WidgetType.WITHDRAWALS,
  },
  {
    component: ConfirmRejectionModal,
    type: ModalType.CONFIRM_REJECTION_CONFIRMATION,
    widget: WidgetType.WITHDRAWALS,
  },
  {
    component: WithdrawalItemModal,
    type: ModalType.SHOW,
    widget: WidgetType.WITHDRAWALS,
  },
  {
    type: ModalType.CREATE,
    widget: WidgetType.WITHDRAWALS,
    component: WithdrawalCreationForm,
  },
  {
    component: WithdrawalComment,
    type: ModalType.COMMENT,
    widget: WidgetType.WITHDRAWALS,
  },
  {
    component: ManualWithdrawalsUploadingModal,
    type: ModalType.UPLOADING,
    widget: WidgetType.WITHDRAWALS,
  },
  {
    component: ManualWithdrawalsDownloadingModal,
    type: ModalType.DOWNLOADING,
    widget: WidgetType.WITHDRAWALS,
  },
  {
    component: ManualWithdrawalsErrorModal,
    type: ModalType.ERROR,
    widget: WidgetType.WITHDRAWALS,
  },
];

const filterDateFields = [
  {
    from: `date_from`,
    to: `date_to`,
    name: `date_range`,
  },
];

const Withdrawals = () => {
  const titles = useWithdrawalsTableTitles();

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const paymentSystems = useSelector(withdrawalsPSSelector);
  const safePaymentSystems = paymentSystems.map((el) => ({
    ...el,
    value: el.code,
    text: el.name,
  }));
  const managePermission = useSelector(hasAccessSelector(Permission.WITHDRAWALS_MANAGE));
  const createPermission = useSelector(hasAccessSelector(Permission.WITHDRAWALS_CREATE));
  const canViewPostbacks = useSelector(hasAccessSelector(Permission.POSTBACKS));

  const { items: statusList } = useList<Record<string, string>>({
    widget: WidgetType.WITHDRAWALS_STATUS,
    actionCreators: withdrawalStatusActionCreators,
  });

  const statusSelectionList = useMemo(
    () => [
      {
        text: `common.all`,
        value: null,
      },
      ...map(statusList, ({ name, status }) => ({ text: name, value: status })),
    ],
    [statusList]
  );

  useEffect(() => {
    dispatch(withdrawalPSActionCreators.getList());
  }, []);

  const extraTableHeaderContent = (
    <>
      {managePermission && (
        <DropdownButtons
          className="btn-secondary"
          buttons={[
            {
              onClick: () => dispatch(showModal({ type: ModalType.UPLOADING })),
              children: t(`common.upload`),
            },
            {
              onClick: () => dispatch(showModal({ type: ModalType.DOWNLOADING })),
              children: t(`withdrawals.manualDownload`),
            },
          ]}
          children={t(`withdrawals.manualWithdrawals`)}
        />
      )}
    </>
  );

  const leftFilterFields = [
    {
      name: `wallet_type`,
      data: [{ value: null, text: t(`common.all`) }, ...safePaymentSystems],
      type: InputType.SELECT,
      placeholder: t(`wallets.walletType`),
    },
    {
      name: `is_manually`,
      type: InputType.SELECT,
      data: [
        {
          value: WithdrawalMethod.API,
          text: t(`common.api`),
        },
        {
          value: WithdrawalMethod.MANUAL,
          text: t(`withdrawals.manualMethod`),
        },
      ],
      placeholder: t(`withdrawals.withdrawalMethod`),
      hasAccess: managePermission,
    },
    {
      name: `type`,
      data: [
        {
          text: `withdrawals.auto`,
          value: WithdrawalType.AUTO,
        },
        {
          text: `withdrawals.manual`,
          value: WithdrawalType.MANUAL,
        },
      ],
      type: InputType.SELECT,
      placeholder: t(`withdrawals.requestType`),
      translateKeys: true,
      hasAccess: managePermission,
    },
    {
      name: `wallet_sender`,
      placeholder: t(`withdrawals.sender`),
      hasAccess: managePermission,
    },
    {
      name: `wallet_sender_identifier`,
      placeholder: t(`withdrawals.walletSenderIdentifier`),
      hasAccess: managePermission,
    },
    {
      name: `wallet_recipient`,
      placeholder: t(`withdrawals.recipient`),
    },
    {
      name: `label`,
      placeholder: t(`common.label`),
    },
    {
      name: `postback_status`,
      type: InputType.SELECT,
      translateKeys: true,
      hasAccess: canViewPostbacks,
      data: [
        {
          value: PostbackFilter.RECEIVED,
          text: `postbacks.received`,
        },
        {
          value: PostbackFilter.ERROR,
          text: `postbacks.error`,
        },
      ],
      placeholder: t(`postbacks.postback`),
    },
  ];

  const rightFilterFields = [
    {
      name: `withdrawal_id`,
      placeholder: t(`withdrawals.withdrawalId`),
    },
    {
      name: `status`,
      data: statusSelectionList,
      type: InputType.SELECT,
      placeholder: t(`common.status`),
      translateKeys: true,
    },
    {
      name: `date_range`,
      type: InputType.DATE_RANGE,
      timePicker: true,
      showSeconds: true,
    },
  ];

  const extraButtons = createPermission
    ? [
        {
          title: t(`withdrawals.addWithdrawalRequest`),
          onClick: () => withdrawalsActionCreators.showModal({ type: ModalType.CREATE }),
          buttonClassName: `btn-success mr-5`,
        },
      ]
    : [];

  return (
    <Widget
      extraTableHeaderContent={extraTableHeaderContent}
      widget={WidgetType.WITHDRAWALS}
      actionCreators={withdrawalsActionCreators}
      pageTitle="withdrawals.withdrawals"
      headTitle="withdrawals.withdrawals"
      listMapping={listMapping}
      tableHeadTitles={titles}
      tableTitle="withdrawals.withdrawalList"
      modals={modals}
      filterFormName={FORM_NAME}
      filterForm={
        <FilterForm
          leftFields={leftFilterFields}
          rightFields={rightFilterFields}
          formName={FORM_NAME}
          gridType={GridType.GRID}
          dateFields={filterDateFields}
          numFields={[`status`, `type`, `is_manually`, `postback_status`]}
        />
      }
      withPagination
      extraButtons={extraButtons}
    />
  );
};

export default withPermissionCheck(Withdrawals, [
  {
    permissions: [
      {
        permission: Permission.WITHDRAWALS_VIEW,
        opposite: true,
      },
    ],
    redirectUrl: `/no-permissions`,
  },
]);
