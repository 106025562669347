import validateProxyIp from 'utils/widgets/proxies/validateProxyIp';
import validateProxyPort from 'utils/widgets/proxies/validateProxyPort';

interface IProps {
  ip: string;
  port: string;
}

const proxyFormValidate = (values: IProps): Partial<IProps> => {
  const errors: Partial<IProps> = {};
  const { ip, port } = values;

  const ipError = validateProxyIp(ip);
  if (ipError) {
    errors.ip = ipError;
  }

  const portError = validateProxyPort(port);
  if (portError) {
    errors.port = portError;
  }

  return errors;
};

export default proxyFormValidate;
