import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import './AddButton.scss';
import { Preloader, Size } from '@kassma-team/kassma-toolkit';

type Props = {
  onClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  children?: never;
};

export const AddButton: FC<Props> = ({ onClick, isLoading, disabled }) => {
  const [t] = useTranslation();

  return isLoading ? (
    <Preloader center={false} size={Size.LITTLE} />
  ) : (
    <button
      data-test-id="add-hint-button"
      type="button"
      className={cn(`AddButton`, { 'AddButton--disabled': disabled })}
      disabled={disabled}
      onClick={onClick}
    >
      {t(`common.add`)}
    </button>
  );
};
