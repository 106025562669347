import { BarcodeDetector as BarcodeDetectorPolyfill } from 'barcode-detector';

const getBarcode = async (file: File) => {
  return new Promise((res, rej) => {
    const barcodeDetector = new BarcodeDetectorPolyfill();

    try {
      const fr = new FileReader();
      fr.readAsArrayBuffer(file as File);
      fr.onload = () => {
        barcodeDetector
          .detect(new Blob([fr.result as BlobPart]))
          .then((barcodes) => {
            const barcode = barcodes[0];

            if (barcode) {
              res(barcode.rawValue);
            } else {
              rej(undefined);
            }
          })
          .catch((err) => {
            rej(err);
          });
      };
    } catch (err) {
      rej(err);
    }
  });
};

export default getBarcode;
