import { takeEvery, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { AxiosError } from 'axios';
import { IAction } from '@kassma-team/kassma-toolkit/lib';

import { getErrorMessage } from 'utils';
import refreshSaga from 'sagas/effects/refreshSaga';
import i18n from 'i18n';
import { DISABLE_MANUAL_PS_REQUESTED, DISABLE_MANUAL_PS_SUCCEEDED } from 'actionTypes';
import testPaymentSystemApi from 'api/testPaymentSystems/TestPaymentSystemApi';

interface IDisablePaymentSystemProps {
  payload: string;
  action: Record<string, unknown>;
}

function* disablePaymentSystem({ payload, ...action }: IDisablePaymentSystemProps) {
  yield refreshSaga({
    request: () => testPaymentSystemApi.disableManualPaymentSystem(payload),
    onSuccess: function* () {
      yield put({ ...action, type: DISABLE_MANUAL_PS_SUCCEEDED, payload });
      yield toastr.success(i18n.t(`common.success`), i18n.t(`manualPS.PSHasBeenSuccessfullyDisabled`));
    },
    onError: function* (e: AxiosError) {
      yield toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

const manualPSSagas = [takeEvery<IAction>(DISABLE_MANUAL_PS_REQUESTED, disablePaymentSystem)];

export default manualPSSagas;
