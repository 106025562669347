import { createWidgetAction, widgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { SettlementWithdrawalActionType, WidgetType } from 'utils/enums';
import {
  CREATE_SETTLEMENT_REPLENISHMENT_REQUESTED,
  CREATE_SETTLEMENT_WRITE_OFF_REQUESTED,
  CREATE_SETTLEMENT_TRANSFER_REQUESTED,
  UPDATE_TOTAL_SETTLEMENT_BALANCE,
  SETTLEMENT_WITHDRAWAL_ACTIONS,
  GET_SETTLEMENT_BALANCE,
} from 'actionTypes';

const settlementLabels = { listLabel: `data`, itemLabel: `settlement_history` };

export const settlementActionCreators = widgetActionCreators({
  widget: WidgetType.SETTLEMENT,
  labels: settlementLabels,
});

export const settlementWithdrawalActionCreators = widgetActionCreators({
  widget: WidgetType.SETTLEMENT_WITHDRAWAL,
  labels: { listLabel: `withdrawals` },
});

export const createSettlementReplenishment = createWidgetAction({
  widget: WidgetType.SETTLEMENT,
})(CREATE_SETTLEMENT_REPLENISHMENT_REQUESTED);

export const createSettlementWriteOff = createWidgetAction({
  widget: WidgetType.SETTLEMENT,
})(CREATE_SETTLEMENT_WRITE_OFF_REQUESTED);

export const createSettlementTransfer = createWidgetAction({
  widget: WidgetType.SETTLEMENT,
})(CREATE_SETTLEMENT_TRANSFER_REQUESTED);

export const getSettlementBalance = createWidgetAction({
  widget: WidgetType.SETTLEMENT,
})(GET_SETTLEMENT_BALANCE);

export const updateSettlementBalance = createWidgetAction({
  widget: WidgetType.SETTLEMENT,
})(UPDATE_TOTAL_SETTLEMENT_BALANCE);

export const settlementWithdrawalApprove = createWidgetAction({
  widget: WidgetType.SETTLEMENT_WITHDRAWAL,
  type: SettlementWithdrawalActionType.APPROVE,
})(SETTLEMENT_WITHDRAWAL_ACTIONS);

export const settlementWithdrawalReject = createWidgetAction({
  widget: WidgetType.SETTLEMENT_WITHDRAWAL,
  type: SettlementWithdrawalActionType.REJECT,
})(SETTLEMENT_WITHDRAWAL_ACTIONS);
