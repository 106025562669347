import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';

export class PaymentApi extends BaseApi {
  loadPayzenData = (): AxiosPromise => this.makeFetch(`payzen/config`);
}

const paymentApi = new PaymentApi(`payment`);

export default paymentApi;
