import map from 'lodash/map';
import omit from 'lodash/omit';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { WalletType } from 'utils/enums';
import { normalizeTextEditorValue } from 'utils';
import getBarcode from './getBarcode';

interface IProps {
  type: string;
  values: Record<string, unknown>;
  unused: string[];
  walletId?: string;
  isCreating?: boolean;
}

export default async ({ type, values, unused, walletId, isCreating = false }: IProps): Promise<Record<string, any>> => {
  const { wallet_type } = values;

  const formData: Record<string, any> = { ...omit(values, [`wallet_type`, ...unused]) };

  if (wallet_type === WalletType.PAY_TM || wallet_type === WalletType.PHONE_PE) {
    formData.wallet_type = wallet_type;
  }

  if (formData.proxies) {
    formData.proxies = map(formData.proxies, ({ proxy_id }) => proxy_id);
  }

  if (formData.upi_addresses) {
    formData.upi_addresses = map(formData.upi_addresses, ({ address }) => address);
  }

  if (type === `creating`) {
    formData.wallet_type = values.wallet_type;
    formData.comment = normalizeTextEditorValue(get(formData, `comment`));

    if ([WalletType.ZIGOPAY].includes(wallet_type as string)) {
      formData.identifier = formData.email_username;
    }
    if (wallet_type === WalletType.PAY_TM) {
      formData.username = formData.wallet_identifier;
    }

    if (formData.qr_data) {
      const barcode = (await getBarcode(formData.qr_data)) as string;

      formData.qr_data = barcode;
    }
  } else if (type === `updating`) {
    formData.id = walletId;
  }

  if (values.cookie) {
    formData.cookie = JSON.parse(formData.cookie);
  }

  if (values.latitude) {
    formData.latitude = +formData.latitude;
  }

  if (values.latitude) {
    formData.longitude = +formData.longitude;
  }

  if (values.wifi_frequency) {
    formData.wifi_frequency = +formData.wifi_frequency;
  }

  if (!values.daily_transaction_limit) {
    formData.daily_transaction_limit = null;
  } else {
    formData.daily_transaction_limit = +formData.daily_transaction_limit;
  }

  return formData;
};
