import React, { useCallback, useEffect, useState } from 'react';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import useMount from 'react-use/lib/useMount';
import { useTranslation } from 'react-i18next';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import { Dispatch } from 'redux';
import { FieldType, IAction, InputType } from '@kassma-team/kassma-toolkit/lib';

import { Permission, DashboardChartEntityType } from 'utils/enums';
import { DASHBOARD_PAYMENTS_FILTERS_FORM_NAME } from 'utils/constants';
import { manualPSActionCreators, withdrawalPSActionCreators } from 'actions/widgets/paymentSystem';
import { selectionOfAllManualPSSelector } from 'selectors/widgets/manualPS';
import { dashboardEntityTypeSelector } from 'selectors/dashboard';
import { setDashboardChartFilters } from 'actions/dashboard';
import { dashboardFiltersInitData } from 'utils/dashboard/filtersInitData';
import { hasAccessSelector } from 'selectors/auth';
import { withdrawalsPSSelector } from 'selectors/widgets/paymentSystems';
import { transactionPSSelector } from 'selectors/widgets/paymentSystems';
import { IDashboardStateChartFilters } from 'interfaces/dashboard';

import FormField from 'components/form/FormField';
import { WalletType } from 'api/WalletTypes';

const ChartPointsFilterForm = ({ form, handleSubmit, initialize }: InjectedFormProps) => {
  const values = useSelector(getFormValues(form)) || {};
  const [disabled, setDisabled] = useState(false);

  const testPS = useSelector(selectionOfAllManualPSSelector);
  const transactionPS = useSelector(transactionPSSelector);
  const withdrawalPS = useSelector(withdrawalsPSSelector);

  const formatToCheckboxes = (arr: ReadonlyArray<WalletType>) => {
    return arr.map((el) => {
      return {
        ...el,
        value: el.code,
        text: el.name,
      };
    });
  };

  const dashboardAccess = useSelector(hasAccessSelector(Permission.DASHBOARD));
  const transactionListAccess = useSelector(hasAccessSelector(Permission.TRANSACTION_LIST));

  const [t] = useTranslation();

  const dispatch = useDispatch();

  const entityType: number = useSelector(dashboardEntityTypeSelector);

  const getFields = useCallback(() => {
    let filterFields = [
      {
        name: `show_all_active_transaction_data`,
        label: t(`dashboard.totalSum`),
        checkboxAsNumValue: true,
        type: InputType.CHECKBOX,
      },
      {
        name: `stockpiling_status`,
        label: t(`dashboard.withCompletedStockpiling`),
        description: `dashboard.withCompletedStockpilingTip`,
        checkboxAsNumValue: true,
        type: InputType.CHECKBOX,
      },
      {
        name: `payment_systems`,
        title: t(`dashboard.paymentSystems`),
        data:
          entityType === DashboardChartEntityType.TRANSACTION
            ? formatToCheckboxes(transactionPS)
            : formatToCheckboxes(withdrawalPS),
        fieldType: FieldType.CHECKBOX_GROUP,
        grouped: true,
        labelClassName: `dashboard__grouped-checkbox-label`,
        groupWrapClassName: `flex space-between flex-wrap`,
        checkAll: true,
      },
    ];

    if (transactionListAccess) {
      filterFields = [
        {
          name: `show_details`,
          label: t(`dashboard.details`),
          checkboxAsNumValue: true,
          type: InputType.CHECKBOX,
        },
        ...filterFields,
      ];
    }

    return filterFields;
  }, [testPS, transactionPS, entityType, withdrawalPS]);

  useEffect(() => {
    const selectedValues = pickBy(values, (val) => val === 1 || !isEmpty(val));
    setDisabled(isEmpty(selectedValues));
  }, [values]);

  useMount(() => {
    if (dashboardAccess) {
      dispatch(withdrawalPSActionCreators.getList());
      dispatch(manualPSActionCreators.getList());
    }

    initialize({ ...dashboardFiltersInitData });
  });

  useUpdateEffect(() => {
    initialize({ ...dashboardFiltersInitData });
  }, [entityType]);

  return (
    <form onSubmit={handleSubmit}>
      {map(getFields(), (field, key) => {
        const fieldId = get(field, `name`) || key;

        return <FormField key={fieldId} {...field} />;
      })}
      <button className="btn btn-secondary" disabled={disabled}>
        {t(`dashboard.accept`)}
      </button>
    </form>
  );
};

export default reduxForm<IDashboardStateChartFilters, any>({
  form: DASHBOARD_PAYMENTS_FILTERS_FORM_NAME,
  onSubmit: (values: IDashboardStateChartFilters, dispatch: Dispatch): IAction<IDashboardStateChartFilters> =>
    dispatch(setDashboardChartFilters(values)),
})(ChartPointsFilterForm);
