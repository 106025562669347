import React, { FC } from 'react';
import ConfirmationModal from '../../modals/ConfirmationModal';
import { notAcceptedPostbackActionCreators, resetAttemptCounter } from '../../../actions/widgets/postback';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { widgetModalSelector } from '@kassma-team/kassma-toolkit';
import { WidgetType } from '../../../utils/enums';
import { IWidgetModal } from '@kassma-team/kassma-toolkit/lib';

const ResetAttemptCounterModal: FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const modal: IWidgetModal = useSelector(widgetModalSelector(WidgetType.NOT_ACCEPTED_POSTBACKS));

  return (
    <ConfirmationModal
      actionCreators={notAcceptedPostbackActionCreators}
      title={t(`postbacks.clearCountFailure`)}
      content={t(`postbacks.areYouSureYouWantToResetAttemptCounter`)}
      confirmText={t(`common.reset`)}
      confirmClassName="btn-alt-danger"
      onConfirm={() => {
        dispatch(resetAttemptCounter({ ids: modal?.id }));
      }}
    />
  );
};

export default ResetAttemptCounterModal;
