import { useState } from 'react';

import { ProxiesColumn } from 'utils/enums';
import { ITableTitleField } from 'interfaces/widgets/widgets';

const useProxiesTableTitles = (): ITableTitleField[] => {
  const [titles] = useState([
    {
      title: ``,
      id: ProxiesColumn.CHECKBOX,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `proxies.index`,
      id: ProxiesColumn.INDEX,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `UUID`,
      id: ProxiesColumn.UUID,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.country`,
      id: ProxiesColumn.COUNTRY,
      desktop: true,
      mobile: true,
    },
    {
      title: `proxies.ip`,
      id: ProxiesColumn.IP,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `proxies.port`,
      id: ProxiesColumn.PORT,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `proxies.login`,
      id: ProxiesColumn.LOGIN,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `proxies.walletsID`,
      id: ProxiesColumn.WALLETS_ID,
      desktop: true,
      mobile: true,
    },
    {
      title: `common.type`,
      id: ProxiesColumn.TYPE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.status`,
      id: ProxiesColumn.STATUS,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `proxies.dateOfChecking`,
      id: ProxiesColumn.DATE_OF_CHECKING,
      desktop: true,
      mobile: true,
    },
    {
      title: `common.actions`,
      className: `proxy-list__thead--actions`,
      id: ProxiesColumn.ACTIONS,
      desktop: true,
      mobile: true,
      disabled: true,
    },
  ]);

  return titles;
};

export default useProxiesTableTitles;
