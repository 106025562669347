import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import { IArchivedTransactionsFilterForm } from 'interfaces/widgets/transaction/transaction';

export class ArchiveTransactionApi extends BaseApi {
  archivateTransaction = (primaryId: number): AxiosPromise =>
    this.makeFetch(`archive/{primaryId}`, { method: `POST` }, { primaryId });

  loadArchivedTransactionList = (queryParams: IArchivedTransactionsFilterForm): AxiosPromise => {
    if (queryParams.merchant_order_id) {
      //@ts-ignore
      queryParams.merchant_order_id = queryParams.merchant_order_id
        .split(`,`)
        .filter((order) => !!order && !/^\s+$/.test(order))
        .map((order) => order.trim());
    }

    return this.makeFetch(`list`, { queryParams });
  };
}

const archiveTransactionApi = new ArchiveTransactionApi(`archive-transaction`);

export default archiveTransactionApi;
