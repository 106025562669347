import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { widgetModalSelector, FieldType } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import {
  getPaymentCurrencies,
  paymentsConstructorActionCreators,
  updatePaymentCurrencies,
} from 'actions/widgets/constructors/paymentsConstructor';
import {
  appliedPaymentCurrencySelectionSelector,
  availablePaymentCurrencySelectionSelector,
} from 'selectors/widgets/constructor/paymentsConstructor';

import { currencySelectionSelector } from '../../../../selectors/widgets/currency';
import ModalForm from '../../../modals/ModalForm';
import { isEqual } from 'lodash';

const form = `update-payment-currencies`;

const PaymentsConstructorModal = (props: InjectedFormProps) => {
  const availableCurrencies = useSelector(availablePaymentCurrencySelectionSelector);
  const allCurrencies = useSelector(currencySelectionSelector);

  const otherCurrencies = useMemo(() => {
    return allCurrencies
      .filter((currency) => !availableCurrencies.find((c: any) => c.text === currency.text))
      .map(({ text }) => ({
        text,
        value: text,
      }));
  }, [allCurrencies, availableCurrencies]);

  const appliedCurrencies = useSelector(appliedPaymentCurrencySelectionSelector);

  const [t] = useTranslation();

  const modal: any = useSelector(widgetModalSelector(WidgetType.PAYMENTS_CONSTRUCTOR));
  const walletType = modal?.walletType;

  const dispatch = useDispatch();

  const values: any = useSelector(getFormValues(form));

  useEffect(() => {
    dispatch(getPaymentCurrencies(walletType));
  }, []);

  useEffect(() => {
    if (appliedCurrencies) {
      props.initialize({ applied_currencies: appliedCurrencies });
    }
  }, [appliedCurrencies]);

  /**
   * Compares two arrays and returns true if they are not equal in length or contents.
   *
   * @param first - The first array to compare.
   * @param  second - The second array to compare.
   * @return Returns true if the arrays are not equal, false otherwise.
   */
  function compareArrays(first: Array<unknown>, second: Array<unknown>): boolean {
    if (first.length !== second.length) return true;

    return !isEqual(first, second);
  }

  const handleSubmit = () => {
    if (
      props.initialized &&
      values &&
      values.applied_currencies &&
      compareArrays(values.applied_currencies, appliedCurrencies)
    ) {
      const preparedData = availableCurrencies
        .filter((currency) => !otherCurrencies.find((c) => currency.text === c.text))
        .map((currency) => ({
          id: currency.value,
          is_applied: values.applied_currencies.includes(currency.value),
        }));
      const currenciesFromList = values.applied_currencies.filter(
        (curr: string | number) => curr && otherCurrencies.find((c) => c.text === curr.toString())
      );

      dispatch(
        updatePaymentCurrencies(walletType, {
          items: preparedData,
          currencies: currenciesFromList,
        })
      );
    }
  };

  const fields = [
    {
      name: `applied_currencies`,
      title: t(`paymentConstructor.appliedCurrencies`),
      data: [...availableCurrencies, ...otherCurrencies],
      fieldType: FieldType.CHECKBOX_GROUP,
      sort: true,
      filterBySearch: true,
      checkAll: true,
    },
  ];

  return (
    <ModalForm
      {...props}
      fields={fields}
      submitText="common.edit"
      onSubmit={handleSubmit}
      widget={WidgetType.PAYMENTS_CONSTRUCTOR}
      actionCreators={paymentsConstructorActionCreators}
      title="paymentConstructor.paymentSystemsCurrencies"
    />
  );
};

export default reduxForm({ form })(PaymentsConstructorModal);
