import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { WidgetContainer, PageContainer } from '@kassma-team/kassma-toolkit/lib';

import PaymentPluginWidget from 'components/widgets/constructors/paymentPlugin/PaymentPluginWidget';
import constructorRouteTabs from 'pages/constructor/constructorRouteTabs';
import Header from 'components/header/Header';
import RouteTabs from 'components/widgets/RouteTabs';
import useUserHasAccess from '../../hooks/useUserHasAccess';

const PaymentPlugin = (): JSX.Element => {
  useUserHasAccess();
  const [t] = useTranslation();

  return (
    <PageContainer>
      <Helmet>
        <title>{t(`paymentConstructor.plugin`)}</title>
      </Helmet>
      <Header />
      <WidgetContainer title="paymentConstructor.constructor">
        <div className="block">
          <RouteTabs routeTabs={constructorRouteTabs} />
          <div className="row block-content">
            <PaymentPluginWidget />
          </div>
        </div>
      </WidgetContainer>
    </PageContainer>
  );
};

export default PaymentPlugin;
