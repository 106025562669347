import isObject from 'lodash/isObject';
import map from 'lodash/map';
import isString from 'lodash/isString';
import { stopSubmit, touch } from 'redux-form';
import { WIDGET_SET_MODAL_ERROR, WIDGET_SET_SUBMITTING_STATUS } from '@kassma-team/kassma-toolkit/lib';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

const useFormSubmissionError = () => {
  const dispatch = useDispatch();

  return useCallback(({ payload, meta, form }) => {
    if (isObject(payload)) {
      const wrongFields: string[] = map(payload, (value, key) => key);

      dispatch(touch(form, ...wrongFields));
      dispatch(stopSubmit(form, payload));
    } else if (isString(payload)) {
      dispatch(stopSubmit(form, { _error: payload }));
    }

    dispatch({ type: WIDGET_SET_MODAL_ERROR, meta, payload });
    dispatch({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
  }, []);
};

export default useFormSubmissionError;
