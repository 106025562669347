import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';

export class BalanceHistoryApi extends BaseApi {
  loadCurrentBalance = (): AxiosPromise => this.makeFetch(`list`);
  loadBalanceHistory = ({ date }: { date: string }): AxiosPromise => this.makeFetch(`list`, { queryParams: { date } });
  loadAccountBalanceHistory = (queryParams: Record<string, string>): any => {
    const id = queryParams.id;
    for (const key of [`id`, `tab`]) {
      delete queryParams[key];
    }

    if (id) {
      return this.makeFetch(`{id}`, { queryParams }, { id });
    }

    return new Promise(() => ({}));
  };
}

const balanceHistoryApi = new BalanceHistoryApi(`accounts-balance-history`);

export default balanceHistoryApi;
