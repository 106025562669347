import { useList as useKassmaList, widgetSelector } from '@kassma-team/kassma-toolkit/lib';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { IWidgetActionCreators, IWidgetsState } from '@kassma-team/kassma-toolkit/lib/types/widgets';
import { IUseListResult } from '@kassma-team/kassma-toolkit/lib/hooks/widgets/useList';

interface IProps<Items = any[]> {
  actionCreators: IWidgetActionCreators;
  widget?: string;
  queryParams?: Record<string, unknown>;
  resetOnUnmount?: boolean;
  loadOnlyIfNecessary?: boolean;
  disableLoading?: boolean;
  items?: Items | null;
  listLoading?: boolean;
  dispatch?: Dispatch;
}

const useList = <Items = any>({
  actionCreators,
  queryParams,
  resetOnUnmount,
  loadOnlyIfNecessary,
  disableLoading,
  widget,
}: IProps): IUseListResult<Items> => {
  const { items, listLoading }: IWidgetsState = useSelector(widgetSelector(widget));

  const dispatch = useDispatch();

  return useKassmaList({
    actionCreators,
    queryParams,
    resetOnUnmount,
    loadOnlyIfNecessary,
    disableLoading,
    items,
    listLoading,
    dispatch,
  });
};

export default useList;
