import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InjectedFormProps, reduxForm } from 'redux-form';
import isNull from 'lodash/isNull';
import map from 'lodash/map';
import { FieldType, IModalFormProps, InputType } from '@kassma-team/kassma-toolkit/lib';

import { proxyActionCreators } from 'actions/widgets/proxy';
import { WidgetType } from 'utils/enums';
import useUpdateWidgetForm from 'hooks/widgets/useUpdateWidgetForm';
import useWalletsListForProxy from 'hooks/widgets/proxy/useWalletsListForProxy';
import { UPDATE_PROXY_FORM_NAME } from 'utils/constants';
import proxyFormValidate from 'utils/widgets/proxies/proxyFormValidate';
import { proxyTypeListSelection } from 'static/proxies';
import { countryCodesSelectionSelector } from 'selectors/widgets/countries';

import ModalForm from 'components/modals/ModalForm';
import { IInitCheckingProxiesData, IProxyFormDataWithParsedWallet } from 'interfaces/widgets/proxies';
import { useDispatch } from 'react-redux';

const ProxyUpdating = (props: InjectedFormProps & IModalFormProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const countryCodes = useSelector(countryCodesSelectionSelector);

  const renderWallets = useWalletsListForProxy(UPDATE_PROXY_FORM_NAME);

  const getFields = useCallback(
    () => [
      {
        name: `ip`,
        label: `IP|HOST`,
        required: true,
      },
      {
        name: `port`,
        label: t(`proxies.port`),
        required: true,
        numeric: true,
      },
      {
        name: `username`,
        label: t(`proxies.username`),
        required: true,
        onlyLatinDigitsAndSymbols: true,
      },
      {
        name: `password`,
        label: t(`proxies.password`),
        required: true,
        password: true,
        onlyLatinDigitsAndSymbols: true,
      },
      {
        name: `type`,
        label: t(`common.type`),
        type: InputType.SELECT,
        data: proxyTypeListSelection,
        required: true,
      },
      {
        name: `country_code`,
        label: t(`common.country`),
        type: `select`,
        data: countryCodes,
        required: true,
      },
      {
        name: `wallets`,
        label: t(`wallets.wallet`),
        fieldType: FieldType.FIELD_ARRAY,
        component: renderWallets,
      },
    ],
    [t, renderWallets]
  );

  const itemAdapter = useCallback(
    ({ ip, port, username, password, type, country_code, wallets }) => ({
      ip,
      port,
      username,
      password,
      type,
      country_code,
      wallets: map(wallets, (wallet) => ({ wallet: wallet })),
    }),
    []
  );

  const { item, ...formProps } = useUpdateWidgetForm<IInitCheckingProxiesData, IProxyFormDataWithParsedWallet>({
    widget: WidgetType.PROXIES,
    actionCreators: proxyActionCreators,
    initialize: props.initialize,
    prepareValues: (values) => {
      const parsedWallets = map(values?.wallets, (walletItem) => {
        const { id, value } = walletItem.wallet || {};

        return {
          id: value || id,
        };
      });

      return { ...values, wallets: parsedWallets };
    },
    getFields,
    itemAdapter,
    onSuccess: () => {
      dispatch(proxyActionCreators.getList());
    },
  });

  return (
    <ModalForm
      {...props}
      {...formProps}
      actionCreators={proxyActionCreators}
      widget={WidgetType.PROXIES}
      title="proxies.proxyUpdating"
      submitText="common.update"
      loading={isNull(item)}
    />
  );
};

export default reduxForm({
  form: UPDATE_PROXY_FORM_NAME,
  validate: proxyFormValidate,
})(ProxyUpdating);
