import React, { FC, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';

import { Modal, AddButton, EditFormType } from 'pages/constructor/DefaultTranslates/common/components';
import { FormTypesScreen } from 'pages/constructor/DefaultTranslates/common/screens';
import {
  getCustomTranslatesBy,
  normalizeByKey,
  getFormTypeTitle,
  isTranslatesEmpty,
} from 'pages/constructor/DefaultTranslates/common/helpers';
import { isPaymentTranslation } from 'pages/constructor/DefaultTranslates/common/types';
import { useFormTypeCtx, useHintsCtx } from 'pages/constructor/DefaultTranslates/PaymentTranslation/ctx';
import { Hint } from 'api/translations/TranslationsApi';
import { CreateFormType } from './CreateFormType';
import { FormTypeDropdown } from 'pages/constructor/DefaultTranslates/common/components';
import { useConfirmModalCtx } from 'pages/constructor/DefaultTranslates/PaymentTranslation/ctx';

import './FormTypes.scss';
import axios, { AxiosError } from 'axios';

export const FormTypes: FC = () => {
  const { currentFormType, fetchFormTypes, handleCurrentFormType, formTypes, isLoading } = useFormTypeCtx();
  const [t] = useTranslation();

  const { isConfirmModalOpen, handleToggleModal } = useConfirmModalCtx();
  const { handeDeleteHint, handleUpdateHint } = useHintsCtx();

  const { hints, fetchHints, isLoading: hintsLoading } = useHintsCtx();

  const [creationMode, setCreationMode] = useState(false);

  const [isEditMode, setEditMode] = useState(false);
  const [forEdit, setForEdit] = useState<Array<Hint>>([]);

  const [defaultHints, setDefaultHints] = useState<Record<string, Hint>>();
  const [forDelete, setForDelete] = useState<Array<Hint>>([]);

  useEffect(() => {
    if (hints) {
      const defaultHints = hints.filter((hint: Hint) => hint.is_default);
      //@ts-ignore
      const nData = defaultHints.reduce((acc, curr) => {
        acc[curr.form_type] = curr;

        return acc;
      }, {} as Record<string, Hint>);

      setDefaultHints(nData);
    }
  }, [hints]);

  const dTranslations = getCustomTranslatesBy(hints, `dublication`);
  const uniqNames = getCustomTranslatesBy(hints, `unique`);

  const notUniqFields = normalizeByKey(dTranslations, `name`);

  const toggleCreationMode = (flag: boolean) => () => setCreationMode(flag);

  const handleDelete = () => {
    const promises = forDelete.map((el) => {
      return handeDeleteHint(el.uuid)();
    });

    Promise.allSettled(promises).then(async (results) => {
      const fullfilled = results.filter(({ status }) => status === `fulfilled`) as Array<
        PromiseFulfilledResult<unknown | AxiosError>
      >;

      fullfilled.forEach((result) => {
        if (axios.isAxiosError(result.value)) {
          toastr.error(result.value.name, result.value.response?.data?.message);

          return;
        } else {
          toastr.success(t(`common.success`), t(`common.success`));
        }
      });

      handleToggleModal(false)();
      await fetchHints();
      await fetchFormTypes();
    });
  };

  const handleEdit = () => {
    const promises = forEdit.map((el) => {
      return handleUpdateHint(el.uuid, el)();
    });

    Promise.allSettled(promises).then(async (results) => {
      if (results.every((result) => result.status === `fulfilled`)) {
        toastr.success(t(`common.success`), t(`common.successUpdate`));
        handleToggleModal(false)();
        await fetchHints();
      }
    });
  };

  return (
    <FormTypesScreen
      title={t(`common.form`)}
      headerBtn={
        <AddButton
          isLoading={hintsLoading}
          disabled={isTranslatesEmpty(defaultHints)}
          onClick={() => setCreationMode(true)}
        />
      }
    >
      {isLoading ? (
        <Skeleton count={8} />
      ) : (
        <ul className="FormTypes__list">
          {isConfirmModalOpen && <Modal onApply={handleDelete} variant="confirm" onClose={handleToggleModal(false)} />}
          {creationMode && <CreateFormType onRemove={toggleCreationMode(false)} />}
          {isEditMode && (
            <EditFormType
              title={forEdit[0].name}
              onApply={handleEdit}
              onChange={(e) => setForEdit(forEdit.map((el) => ({ ...el, name: e.target.value })))}
              onRemove={() => setEditMode(false)}
            />
          )}
          {uniqNames.map((el) => {
            return (
              <FormTypeDropdown
                key={el.uuid}
                formTypes={[
                  {
                    form: { title: getFormTypeTitle(el.form_type, formTypes) || ``, type: el.form_type },
                    entityId: el.uuid,
                    onCheck: (formType) => handleCurrentFormType(formType),
                  },
                ]}
                checkedForm={currentFormType}
                title={el.name}
                {...(!el.is_default
                  ? {
                      onDelete: () => {
                        handleToggleModal(true)();
                        if (isPaymentTranslation(el)) {
                          setForDelete([el]);
                        }
                      },
                      onEdit: () => {
                        setEditMode(true);
                        if (isPaymentTranslation(el)) {
                          setForEdit([el]);
                        }
                      },
                    }
                  : {})}
              />
            );
          })}

          {Object.keys(notUniqFields).length > 0 &&
            Object.keys(notUniqFields).map((key) => {
              return (
                <FormTypeDropdown
                  key={key}
                  formTypes={notUniqFields[key].map((hint) => ({
                    form: {
                      title: getFormTypeTitle(hint.form_type, formTypes) || ``,
                      type: hint.form_type,
                    },
                    entityId: hint.uuid,
                    onCheck: (formType) => handleCurrentFormType(formType),
                  }))}
                  checkedForm={currentFormType}
                  title={key}
                  {...(!notUniqFields[key][0].is_default
                    ? {
                        onDelete: () => {
                          handleToggleModal(true)();
                          if (isPaymentTranslation(notUniqFields[key][0])) {
                            setForDelete(notUniqFields[key] as Array<Hint>);
                          }
                        },
                        onEdit: () => {
                          setEditMode(true);
                          if (isPaymentTranslation(notUniqFields[key][0])) {
                            setForEdit(notUniqFields[key] as Array<Hint>);
                          }
                        },
                      }
                    : {})}
                />
              );
            })}
        </ul>
      )}
    </FormTypesScreen>
  );
};
