import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import { ITariffsData } from 'interfaces/widgets/constructor/paymentsConstructor';

export class TariffApi extends BaseApi {
  loadTariffs = (paymentSystem: string): AxiosPromise => this.makeFetch(`list/{paymentSystem}`, {}, { paymentSystem });

  updateTariffs = (paymentSystem: string, data: ITariffsData[]): AxiosPromise =>
    this.makeFetch(`{paymentSystem}`, { method: `POST`, data }, { paymentSystem });

  loadTariffsSchemas = (): AxiosPromise => this.makeFetch(``);

  loadTariffCommission = (id: number): AxiosPromise => this.makeFetch(`{id}`, {}, { id });
}

const tariffApi = new TariffApi(`tariff`);

export default tariffApi;
