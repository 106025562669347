import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import isNumber from 'lodash/isNumber';
import get from 'lodash/get';
import find from 'lodash/find';
import { useTranslation } from 'react-i18next';
import { widgetSelector, Size, IWidgetActionCreators, IWidgetsState } from '@kassma-team/kassma-toolkit/lib';

import { copyText } from 'utils';

import ModalElement from 'components/modals/ModalElement';

interface IGetRequestBody {
  modal: Record<string, unknown> | null;
  items: Record<string, unknown>[] | null;
}

const getRequestBody = ({ modal, items }: IGetRequestBody): null | string => {
  const id = get(modal, `id`);
  if (!isNumber(id)) {
    return null;
  }

  const item = find(items, (postback) => postback.id === id);

  return item?.request_body ? (item?.request_body as string) : null;
};

interface IProps {
  widget: string;
  actionCreators: IWidgetActionCreators;
}

const PostbackRequest = ({ widget, actionCreators }: IProps) => {
  const { modal, items }: IWidgetsState = useSelector(widgetSelector(widget));

  const [t] = useTranslation();

  const [request_body] = useState(getRequestBody({ modal, items }));

  const requestBodyRef = useRef(null);

  const content = (
    <div className="table-responsive mb-20">
      <table className="table table-striped table-borderless table-vcenter">
        <tbody>
          <tr>
            <td className="font-w600">{t(`postbacks.requestBody`)}</td>
            <td className="text-muted">
              <pre ref={requestBodyRef}>{request_body}</pre>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="form-group pt-10">
        <button onClick={() => copyText(requestBodyRef)} className="btn btn-primary">
          {t(`common.copy`)}
        </button>
      </div>
    </div>
  );

  return <ModalElement title="postbacks.postback" actionCreators={actionCreators} content={content} size={Size.BIG} />;
};

export default PostbackRequest;
