import React, { createRef, FC, useEffect, useState } from 'react';
import { DropdownSelect, PageContainer, WidgetContainer } from '@kassma-team/kassma-toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ModalType, Permission, WidgetType } from 'utils/enums';
import withPermissionCheck from '../../../hocs/withPermissionCheck';
import { Helmet } from 'react-helmet';
import Header from '../../../components/header/Header';
import { accountsBalanceHistoryActionCreators } from '../../../actions/widgets/balances/accountsBalanceHistory';
import AccountBalanceReplenishment from '../../../components/widgets/balances/AccountBalanceReplenishment';
import WriteOff from '../../../components/widgets/balances/WriteOff';
import ExternalAccountReplenishment from '../../../components/widgets/balances/ExternalAccountReplenishment';
import map from 'lodash/map';
import Modal from '../../../components/modals/Modal';
import { hasAccessSelector } from '../../../selectors/auth';
import TabsBar from '../../../components/widgets/TabsBar';
import SettlementWithdrawal from '../settlement/SettlementWithdrawal';
import AccountsBalanceHistory from '../settlement/AccountsBalanceHistory';
import useSearchParams from '../../../hooks/useSearchParams';
import useTable from '../../../hooks/widgets/useTable';
import {
  accountBalanceHistoryBalanceInfoByIdSelector,
  accountBalanceHistoryCurrenciesByPaymentSystem,
  settlementPaymentSystemByIdSelector,
} from '../../../selectors/widgets/balances';
import useFullAmount from '../../../hooks/useFullAmount';
import { replace, push } from 'connected-react-router';
import { accountsBalancesActionCreators } from '../../../actions/widgets/balances/accountsBalances';
import '../settlement/settlement.scss';
import { paymentSystemLogoSelector } from '../../../selectors/widgets/paymentSystems';
import CancelTranslationModal from 'components/widgets/balances/CancelTranslationModal';

const { showModal } = accountsBalanceHistoryActionCreators;

const modals = [
  {
    widget: WidgetType.ACCOUNT_BALANCE_HISTORY,
    type: ModalType.REPLENISHMENT,
    component: AccountBalanceReplenishment,
  },
  {
    widget: WidgetType.ACCOUNT_BALANCE_HISTORY,
    type: ModalType.WRITE_OFF,
    component: WriteOff,
  },
  {
    widget: WidgetType.ACCOUNT_BALANCE_HISTORY,
    type: ModalType.EXTERNAL_REPLENISHMENT,
    component: ExternalAccountReplenishment,
  },
  {
    widget: WidgetType.ACCOUNT_BALANCE_HISTORY,
    type: ModalType.REMOVE_CONFIRMATION,
    component: CancelTranslationModal,
  },
];

const BalanceCurrenciesSelector = ({ queryParams, payment_system, currency_code }: any) => {
  const dispatch = useDispatch();
  const currencies = useSelector(accountBalanceHistoryCurrenciesByPaymentSystem(payment_system));
  const handleSelect = ({ value: id }: any) => {
    if (queryParams) {
      dispatch(accountsBalancesActionCreators.setAnyData({ balanceId: id }));
      const params = new URLSearchParams({ ...queryParams, id });
      dispatch(push(`/operation-history?${params.toString()}`));
    }
  };

  const currentId = queryParams.id;

  const [btnDisplay, setBtnDisplay] = useState(true);

  const ref: any = createRef();

  const BTN_HEIGHT = 34;

  useEffect(() => {
    if (ref.current) {
      const currHeight = ref.current.clientHeight;
      setBtnDisplay(currHeight <= BTN_HEIGHT);
    }
  }, [ref]);

  return (
    <div className="settlement-balance-currencies">
      {btnDisplay ? (
        <div ref={ref}>
          {currencies.map((currency: any) => {
            const isActiveBtn = currentId.toString() === currency.value.toString();
            const classes = [`btn`, `settlement-currency-btn`];
            if (isActiveBtn) {
              classes.push(`settlement-btn-primary`);
            } else {
              classes.push(`btn-secondary`);
            }

            return (
              <button
                key={currency.value}
                className={classes.join(` `)}
                onClick={() => handleSelect({ value: currency.value })}
              >
                {currency.text}
              </button>
            );
          })}
        </div>
      ) : (
        <DropdownSelect onSelect={handleSelect} data={currencies} value={currency_code} />
      )}
    </div>
  );
};

interface IOperationHistoryHeaderProps {
  isSettlement: boolean;
}

const OperationHistoryHeader = ({ isSettlement }: IOperationHistoryHeaderProps) => {
  const managePermission = useSelector(hasAccessSelector(Permission.MANAGER_BALANCE));

  const settlementCreateWithdrawalPermission = useSelector(hasAccessSelector(Permission.SETTLEMENT_CREATE_WITHDRAWAL));
  const managerBalancePermission = useSelector(hasAccessSelector(Permission.MANAGER_BALANCE));

  const [t] = useTranslation();

  const dispatch = useDispatch();

  const queryParams = useSearchParams();
  const id: any = queryParams?.id || ``;
  const { payment_system, currency_code, amount: balance }: any = useSelector(
    accountBalanceHistoryBalanceInfoByIdSelector(id || ``)
  );

  const logo = useSelector(paymentSystemLogoSelector(payment_system || ``));

  return (
    <div className="settlement-header block">
      <div className="settlement__wrapper">
        <div className="settlement__options">
          <div className="settlement__logo">{payment_system && <img src={logo} />}</div>
          <BalanceCurrenciesSelector
            queryParams={queryParams}
            payment_system={payment_system}
            currency_code={currency_code}
          />
        </div>
        <div
          className="settlement__controls__block"
          style={{
            display: `flex`,
          }}
        >
          <div className="settlement__balance">
            <h4>{t(`balances.availableBalance`)}</h4>
            <h2>
              {useFullAmount({
                amount: balance,
                currencyCode: currency_code,
              })}
            </h2>
          </div>
          <div className="settlement-controls">
            {managePermission && isSettlement && (
              <button
                className="btn settlement-btn-success"
                onClick={() => dispatch(showModal({ type: ModalType.EXTERNAL_REPLENISHMENT }))}
              >
                {t(`wallets.accountReplenishment`)}
              </button>
            )}
            {settlementCreateWithdrawalPermission && isSettlement && (
              <button
                className="btn settlement-btn-primary"
                onClick={() => dispatch(showModal({ type: ModalType.WRITE_OFF }))}
              >
                {t(`wallets.accountWriteOff`)}
              </button>
            )}
            {managerBalancePermission && (
              <button
                className="btn settlement-btn-warning"
                onClick={() =>
                  dispatch(showModal({ type: ModalType.REPLENISHMENT, isSettlement, currency_code, payment_system }))
                }
              >
                {t(`balances.transferMoney`)}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const OperationHistory: FC = () => {
  const [t] = useTranslation();

  useEffect(() => {
    dispatch(accountsBalancesActionCreators.getList());
  }, []);

  const tabs = [
    {
      title: t(`balances.withdrawalRequests`),
      component: SettlementWithdrawal,
    },
    {
      title: t(`wallets.operationsHistory`),
      component: AccountsBalanceHistory,
    },
  ];

  const queryParams = useSearchParams();
  const [id, setId] = useState(queryParams?.id || ``);

  useEffect(() => {
    if (!queryParams?.id && id) {
      const params = new URLSearchParams({ ...queryParams, id: id.toString() || `` });
      dispatch(replace(`/operation-history?${params.toString()}`));
    }
  }, [queryParams, id]);

  useEffect(() => {
    if (queryParams?.id && queryParams?.id !== id) {
      setId(queryParams?.id);
      dispatch(accountsBalancesActionCreators.setAnyData({ balanceId: queryParams?.id }));
      const params = new URLSearchParams({ ...queryParams, id: queryParams?.id.toString() });
      dispatch(replace(`/operation-history?${params.toString()}`));
    }
  }, [queryParams]);

  useEffect(() => {
    return () => {
      dispatch(accountsBalancesActionCreators.setAnyData({ balanceId: undefined }));
    };
  }, []);

  const isSettlement: any = useSelector(settlementPaymentSystemByIdSelector(id.toString() || ``));

  useTable({
    widget: WidgetType.ACCOUNT_BALANCE_HISTORY,
    actionCreators: accountsBalanceHistoryActionCreators,
    queryParams,
    statusBar: false,
  });

  const dispatch = useDispatch();

  return (
    <PageContainer>
      <Helmet>
        <title>{t(`wallets.accountsBalance`)}</title>
      </Helmet>
      <Header />
      <WidgetContainer title="balances.transferHistory">
        <button className="settlement-back-button" onClick={() => dispatch(push(`accounts-balance`))}>
          &larr; {t(`wallets.accountsBalance`)}
        </button>
        <OperationHistoryHeader isSettlement={isSettlement} />
        {isSettlement ? <TabsBar tabs={tabs} containerClassNames="settlement-page" /> : <AccountsBalanceHistory />}
      </WidgetContainer>
      {map(modals, (modal, key) => (
        <Modal key={key} {...modal} />
      ))}
    </PageContainer>
  );
};

export default withPermissionCheck(OperationHistory, [
  {
    permissions: [
      {
        permission: Permission.ACCOUNTS_BALANCE,
        opposite: true,
      },
    ],
    redirectUrl: `/no-permissions`,
  },
]);
