import {
  createWidgetAction,
  widgetActionCreators,
  IWidgetActionCreators,
  IWidgetsAction,
} from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { CREATE_WALLET_FORM_NAME, UPDATE_WALLET_FORM_NAME } from 'utils/constants';
import {
  MARK_WALLET_AS_ACTIVE_REQUESTED,
  TOGGLE_WALLET_PLUGIN_STATUS,
  TOGGLE_WALLET_STANDARD_STATUS,
  UPDATE_WALLET_COMMENT_REQUESTED,
  UPDATE_WALLET_SETTINGS,
  TRANSACTION_SEARCH,
  TOGGLE_VISIBILITY_OF_ALL_WALLETS_REQUESTED,
  UPDATE_WALLET_COMMENT_SUCCEEDED,
  WALLET_SHOW_REQUESTED,
  MANUAL_REQUISITES_REQUESTED,
} from 'actionTypes';
import { IWidgetLabels } from 'interfaces/widgets/widgets';

export const walletPayloadLabels: IWidgetLabels = { itemLabel: `wallet`, listLabel: `wallets` };

export const walletActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.WALLETS,
  labels: walletPayloadLabels,
  listItemUpdatingForm: UPDATE_WALLET_FORM_NAME,
  creatingForm: CREATE_WALLET_FORM_NAME,
  succeededDeletingMessage: `wallets.walletHasBeenSuccessfullyDisabled`,
  withPagination: true,
});

export const walletStatisticsActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.WALLETS_STATISTIC,
  labels: walletPayloadLabels,
  listItemUpdatingForm: UPDATE_WALLET_FORM_NAME,
  creatingForm: CREATE_WALLET_FORM_NAME,
  succeededDeletingMessage: `wallets.walletHasBeenSuccessfullyDisabled`,
});

export const disabledWalletActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.DISABLED_WALLETS,
  labels: walletPayloadLabels,
  listItemUpdatingForm: UPDATE_WALLET_FORM_NAME,
  creatingForm: CREATE_WALLET_FORM_NAME,
  succeededDeletingMessage: `wallets.walletHasBeenSuccessfullyRemoved`,
  withPagination: true,
});

/**
 * @type {WidgetActionCreators}
 */
export const removedWalletActionCreators = widgetActionCreators({
  widget: WidgetType.REMOVED_WALLETS,
  labels: walletPayloadLabels,
  creatingForm: CREATE_WALLET_FORM_NAME,
  succeededCreatingMessage: `wallets.walletHasBeenSuccessfullyRestored`,
  succeededDeletingMessage: `wallets.walletHasBeenSuccessfullyDeleted`,
  withPagination: true,
});

export const notRemovedWalletActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.NOT_REMOVED_WALLETS,
  labels: { listLabel: `wallets` },
});

export const markWalletAsActive = (widget: string): IWidgetsAction => {
  return createWidgetAction({ widget })(MARK_WALLET_AS_ACTIVE_REQUESTED);
};

export const updateWalletComment = (widget: string): IWidgetsAction =>
  createWidgetAction({
    widget,
  })(UPDATE_WALLET_COMMENT_REQUESTED);

export const updateWalletCommentSucceeded = (widget: string): IWidgetsAction =>
  createWidgetAction({
    widget,
  })(UPDATE_WALLET_COMMENT_SUCCEEDED);

export const updateWalletSettings: IWidgetsAction = createWidgetAction({
  widget: WidgetType.WALLETS,
})(UPDATE_WALLET_SETTINGS);

export const toggleWalletPluginStatus = (widget: string): IWidgetsAction =>
  createWidgetAction({
    widget,
  })(TOGGLE_WALLET_PLUGIN_STATUS);

export const toggleWalletStandardStatus = (widget: string): IWidgetsAction =>
  createWidgetAction({
    widget,
  })(TOGGLE_WALLET_STANDARD_STATUS);

export const transactionSearch: IWidgetsAction = createWidgetAction({
  widget: WidgetType.WALLET_PAYTM,
})(TRANSACTION_SEARCH);

export const toggleVisibilityOfAllWallets: IWidgetsAction = createWidgetAction({
  widget: WidgetType.WALLETS,
})(TOGGLE_VISIBILITY_OF_ALL_WALLETS_REQUESTED);

export const showWallet = (widget: string): IWidgetsAction =>
  createWidgetAction({
    widget,
  })(WALLET_SHOW_REQUESTED);

export const getManualFields: IWidgetsAction = createWidgetAction({
  widget: WidgetType.WALLETS,
})(MANUAL_REQUISITES_REQUESTED);
