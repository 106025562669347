import { Reducer } from 'redux';
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';

import {
  AuthStatus,
  DEFAULT_PAGINATION_LIMIT,
  LOGIN_SUCCEEDED,
  LOGOUT_SUCCEEDED,
  AUTH_ERROR,
  AUTH_CHECK_SUCCEEDED,
  REFRESH_TOKEN_FAILED,
  REFRESH_TOKEN_REQUESTED,
  REFRESH_TOKEN_SUCCEEDED,
  SET_LANG,
  SET_TOKEN,
  SET_TABLES_PER_PAGE,
  UPDATE_HIDDEN_COLUMN_SUCCEEDED,
  IAuthState,
} from '@kassma-team/kassma-toolkit/lib';

const defaultPerPage = localStorage.getItem(`perPage`);

/**
 * @type {AuthState}
 */
export const authInitState = {
  user: null,
  token: null,
  status: AuthStatus.CHECKING,
  error: null,
  refreshTokenPromise: null,
  perPage: defaultPerPage ? toNumber(defaultPerPage) : DEFAULT_PAGINATION_LIMIT,
};

// Todo: use reducer from @kassma-toolkit

export const auth: Reducer = (state: IAuthState = authInitState, { type, payload = {} }) => {
  switch (type) {
    case LOGIN_SUCCEEDED:
    case AUTH_CHECK_SUCCEEDED:
      return { ...state, ...payload, status: AuthStatus.LOGGED_IN, error: null };
    case LOGOUT_SUCCEEDED:
      return { ...authInitState, status: AuthStatus.GUEST };
    case AUTH_ERROR:
      return { ...state, ...authInitState, status: AuthStatus.GUEST, error: payload };
    case REFRESH_TOKEN_REQUESTED:
      return { ...state, refreshTokenPromise: payload };
    case REFRESH_TOKEN_FAILED:
      return authInitState;
    case REFRESH_TOKEN_SUCCEEDED:
      return { ...state, token: get(payload, `token`), refreshTokenPromise: null };
    case UPDATE_HIDDEN_COLUMN_SUCCEEDED:
      return {
        ...state,
        user: {
          ...get(state, `user`, {}),
          hidden_columns: {
            ...get(state, `user.hidden_columns`, {}),
            [payload.name]: payload.columns,
          },
        },
      };
    case SET_TOKEN:
      return { ...state, token: payload };
    case SET_LANG:
      return { ...state, lang: payload };
    case SET_TABLES_PER_PAGE:
      return { ...state, perPage: payload };
    default:
      return state;
  }
};
