import { Reducer } from 'redux';
import map from 'lodash/map';
import { IWidgetsState } from '@kassma-team/kassma-toolkit/lib/types/widgets';

import {
  APPROVE_WITHDRAWAL_SUCCEEDED,
  CONFIRM_REJECTION_OF_WITHDRAWAL_SUCCEEDED,
  CONFIRM_APPROVAL_OF_WITHDRAWAL_SUCCEEDED,
  REJECT_WITHDRAWAL_SUCCEEDED,
} from 'actionTypes';
import { WithdrawalStatus } from 'utils/enums';

const actionTypes = new Set([
  REJECT_WITHDRAWAL_SUCCEEDED,
  APPROVE_WITHDRAWAL_SUCCEEDED,
  CONFIRM_REJECTION_OF_WITHDRAWAL_SUCCEEDED,
  CONFIRM_APPROVAL_OF_WITHDRAWAL_SUCCEEDED,
]);

const reducer: Reducer = (state: IWidgetsState, { type, payload }) => {
  switch (type) {
    case REJECT_WITHDRAWAL_SUCCEEDED:
      return {
        ...state,
        items: map(state.items, (item) =>
          item.id === payload ? { ...item, status: WithdrawalStatus.REJECTED } : item
        ),
      };
    case CONFIRM_REJECTION_OF_WITHDRAWAL_SUCCEEDED:
      return {
        ...state,
        items: map(state.items, (item) =>
          item.id === payload ? { ...item, status: WithdrawalStatus.CANCELED } : item
        ),
      };
    case APPROVE_WITHDRAWAL_SUCCEEDED:
    case CONFIRM_APPROVAL_OF_WITHDRAWAL_SUCCEEDED:
      return {
        ...state,
        items: map(state.items, (item) =>
          item.id === payload ? { ...item, status: WithdrawalStatus.IN_PROGRESS } : item
        ),
      };
    default:
      return state;
  }
};

const withdrawals = { actionTypes, reducer };

export default withdrawals;
