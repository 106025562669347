import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import { IDashboardStateAsideFilters, IDashboardStateChartFilters } from 'interfaces/dashboard';

export class DashboardApi extends BaseApi {
  loadDashboardPaymentSystemsInfo = (): AxiosPromise => this.makeFetch(`get-all-payment-system-settings`);

  loadDashboardData = (data: IDashboardStateAsideFilters & Partial<IDashboardStateChartFilters>): AxiosPromise =>
    this.makeFetch(`get-data`, { method: `POST`, data });

  loadDashboardPayments = (data: unknown): AxiosPromise => this.makeFetch(`payment-systems`, { method: `POST`, data });

  loadDashboardTransactionsData = (
    data: IDashboardStateAsideFilters & Partial<IDashboardStateChartFilters>
  ): AxiosPromise => this.makeFetch(`get-transactions-data`, { method: `POST`, data });

  loadDashboardWithdrawalsData = (
    data: IDashboardStateAsideFilters & Partial<IDashboardStateChartFilters>
  ): AxiosPromise => this.makeFetch(`get-withdrawals-data`, { method: `POST`, data });

  // loadTransactionPaymentSystems = (): AxiosPromise => this.makeFetch(`transactions-payment-system-list`);

  // loadWithdrawalsPaymentSystems = (): AxiosPromise => this.makeFetch(`withdrawals-payment-system-list`);
}

const dashboardApi = new DashboardApi(`dashboard`);

export default dashboardApi;
