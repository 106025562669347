import { put, select, takeEvery } from 'redux-saga/effects';
import omit from 'lodash/omit';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import moment from 'moment';

import {
  formSubmissionError,
  validateForm,
  WIDGET_SET_SUBMITTING_STATUS,
  IWidgetsMeta,
  IAction,
  widgetModalSelector,
} from '@kassma-team/kassma-toolkit/lib';
import i18n from 'i18n';
import { AxiosError, AxiosPromise } from 'axios';

import { getErrorMessage } from 'utils';
import refreshSaga from 'sagas/effects/refreshSaga';
import { GENERATE_REPORT_REQUESTED } from 'actionTypes';
import { getAllTransactionKindsByReportType } from 'utils/widgets/reports/getAllTransactionKindsByReportType';
import { dateTimeFormat } from 'utils';
import { ModalType, ReportType, TransactionField, WidgetType } from 'utils/enums';
import { reportsActionCreators } from 'actions/widgets/reports';
import { AUTO_REPORT_GENERATING_FORM_NAME, REPORT_GENERATING_FORM_NAME } from 'utils/constants';
import { IGenerateReportsForm } from 'interfaces/widgets/reports';
import reportApi from 'api/reports/ReportApi';
import autoReportApi from 'api/autoreports/AutoReportApi';
import { autoReportsActionCreators } from 'actions/widgets/autoreports';
import { toastr } from 'react-redux-toastr';

const parseDate = (namespace: string, payload: IGenerateReportsForm) => {
  const startDate = get(payload, `${namespace}.values.startDate`);
  const endDate = get(payload, `${namespace}.values.endDate`);

  return [startDate, endDate];
};

const getTimeFromDate = (date: string) => {
  return moment(date).toDate().getTime();
};

const prepareFilterTypes = (types: number[]): number[] => {
  return types.filter((val) => !isNaN(+val));
};

const validateReportGenerating = (payload: IGenerateReportsForm) => {
  const reportType = payload?.report_type;
  const errors: Record<string, string> = {};

  const [startDate, endDate] = parseDate(`date_range`, payload);

  if (startDate && endDate) {
    if (getTimeFromDate(startDate) >= getTimeFromDate(endDate)) {
      errors.date_range = i18n.t(`errors.invalidDateRange`);
    }
  }

  if (reportType !== ReportType.LABEL_HISTORY_REPORT) {
    const [startDateOfActivation, endDateOfActivation] = parseDate(`date_range_of_activation`, payload);

    if (startDateOfActivation && endDateOfActivation) {
      if (getTimeFromDate(startDateOfActivation) >= getTimeFromDate(endDateOfActivation)) {
        errors.date_range_of_activation = i18n.t(`errors.invalidDateRange`);
      }
    }
  }

  return errors;
};

const gettingEmailsValues = (pre_emails?: { autoreport_email: string }[]) => {
  const data = [];
  if (pre_emails?.length) {
    for (const values of pre_emails) {
      data.push(values.autoreport_email);
    }
  }

  return data;
};

const prepareCompletedSumData = (payload: IGenerateReportsForm, meta: { widget?: string }) => {
  const [startDate, endDate] = parseDate(`date_range`, payload);

  const code = payload.currency_id;

  const data: IGenerateReportsForm = omit(payload, [`date_range`, `report_type`, `currency_id`]);
  if (!data.filters) {
    data.filters = {};
  }

  if (startDate && endDate) {
    data.filters = {
      ...(data.filters || {}),
      stockpiling_date_from: dateTimeFormat(startDate),
      stockpiling_date_to: dateTimeFormat(endDate),
    };
  }

  const fields = data.fields || [];

  if (fields.includes(`total_commission`)) {
    data.fields = [...fields, `deposit_commission`, `withdrawal_commission`];
  }

  data.report_currency_id = code;

  if (data.filters && isNull(data.filters?.originality)) {
    delete data.filters.originality;
  }

  data.filters = omitBy(data.filters, (val) => isNull(val) || isUndefined(val));

  return data;
};

const prepareSumAndListReportData = (payload: IGenerateReportsForm, meta: { widget?: string }) => {
  const [startDate, endDate] = parseDate(`date_range`, payload);
  const [startDateOfActivation, endDateOfActivation] = parseDate(`date_range_of_activation`, payload);
  const [startDateOfArchiving, endDateOfArchiving] = parseDate(`archiving_period`, payload);

  if (typeof payload?.report_currency_id !== `number`) {
    payload.report_currency_id = payload?.currency_id;
  }

  if (payload?.pre_emails) {
    payload.emails = gettingEmailsValues(payload?.pre_emails);
  }

  if (payload?.pre_start) {
    payload.start = dateTimeFormat(payload.pre_start);
  }

  if (payload.filters?.type) {
    payload.filters.type = prepareFilterTypes(payload.filters.type);
  }

  let data: IGenerateReportsForm = omit(payload, [
    `date_range`,
    `date_range_of_activation`,
    `archiving_period`,
    `archiving_status`,
    `only_archived`,
    `pre_start`,
    `pre_emails`,
    `currency_id`,
  ]);
  if (!data.filters) {
    data.filters = {};
  }

  if (startDate && endDate) {
    data.filters = {
      ...(data.filters || {}),
      created_from: dateTimeFormat(startDate),
      created_to: dateTimeFormat(endDate),
    };
  }

  if (startDateOfActivation && endDateOfActivation) {
    data.filters = {
      ...(data.filters || {}),
      activated_from: dateTimeFormat(startDateOfActivation),
      activated_to: dateTimeFormat(endDateOfActivation),
    };
  }

  if (startDateOfArchiving && endDateOfArchiving) {
    data.filters = {
      ...(data.filters || {}),
      archiving_date_from: dateTimeFormat(startDateOfArchiving),
      archiving_date_to: dateTimeFormat(endDateOfArchiving),
    };
  }

  const walletType = get(data, `filters.wallet_type`);
  if (!walletType) {
    data.filters = omit(data.filters, `wallet_id`);
  }

  data.filters = omitBy(data.filters, (val) => isNull(val) || isUndefined(val));

  const dateOfArchiving = startDateOfArchiving && endDateOfArchiving;

  if (payload?.pre_direction) {
    if (payload?.filters?.direction !== undefined) {
      data.filters.direction = payload?.filters?.direction;
    } else data.filters.direction = payload?.pre_direction;
  }

  if (payload?.pre_wallet_id) {
    if (payload?.filters?.wallet_id !== undefined) {
      data.filters.wallet_id = payload?.filters?.wallet_id;
    } else data.filters.wallet_id = payload?.pre_wallet_id;
  }

  if (payload?.pre_status) {
    if (payload?.filters?.status !== undefined) {
      data.filters.status = payload?.filters?.status;
    } else data.filters.status = payload?.pre_status;
  }

  if (payload?.pre_country_id) {
    if (payload?.filters?.country_id !== undefined) {
      data.filters.country_id = payload?.filters?.country_id;
    } else data.filters.country_id = payload?.pre_country_id;
  }

  if (payload?.pre_creation_type) {
    if (payload?.filters?.сreation_type !== undefined) {
      data.filters.сreation_type = payload.filters.сreation_type;
    } else data.filters.сreation_type = payload?.pre_creation_type;
  }

  if (payload?.pre_type) {
    if (payload?.filters?.type !== undefined) {
      data.filters.type = payload.filters.type;
    } else data.filters.type = payload?.pre_type;
  }

  if (payload.archiving_status && WidgetType.REPORTS === meta.widget) {
    data.filters.archiving_status = payload.archiving_status;
  }

  if (payload.only_archived) {
    data.filters.only_archived = payload.only_archived;
  }

  if (dateOfArchiving && payload.only_archived === false) {
    data.filters.only_archived = null;
  }

  if (!dateOfArchiving && !payload.only_archived) {
    data.filters.only_archived = payload.only_archived;
  }

  if (
    data.fields &&
    data.fields?.includes(TransactionField.COMMISSION) &&
    data.report_type &&
    [ReportType.SUM_REPORT, ReportType.TRANSACTIONS_SUM_DETAIL].includes(data.report_type)
  ) {
    data.fields = [...data.fields, `deposit_commission`, `withdrawal_commission`];
  }

  if (data.report_type && [ReportType.SUM_REPORT, ReportType.TRANSACTIONS_SUM_DETAIL].includes(data.report_type)) {
    data = omit(data, [`detail_by_day`, `filters.detail_by_day`]);
  }

  const isReportsWidget = WidgetType.REPORTS === meta.widget;
  const reportType = payload?.report_type;

  if (
    reportType &&
    [ReportType.SUM_REPORT, ReportType.LIST_REPORT, ReportType.TRANSACTIONS_SUM_DETAIL].includes(reportType)
  ) {
    if (isReportsWidget)
      data = omit(data, [`report_type`, `widget, pre_country_id, pre_status, pre_wallet_id, pre_direction, pre_type`]);
    else {
      data = {
        ...data,
        report_type: reportType,
      };
      data = { ...data, currency_id: data.report_currency_id };
      data = omit(data, [
        `widget`,
        `report_currency_id, pre_country_id, pre_status, pre_wallet_id, pre_direction, pre_type`,
      ]);
    }
  }

  return data;
};

const prepareData = (payload: IGenerateReportsForm) => {
  if (payload.filters?.type) {
    payload.filters.type = prepareFilterTypes(payload.filters.type);
  }

  const [startDate, endDate] = parseDate(`date_range`, payload);

  let data: IGenerateReportsForm = omit(payload, [`date_range`, `report_type`]);

  if (payload?.report_type === ReportType.LABEL_HISTORY_REPORT) {
    data.report_type = ReportType.LABEL_HISTORY_REPORT;
  }

  // @ts-expect-error Да пофиг, новый фронт почти вышел.
  if (payload.update_date_range) {
    const [update_date_from, update_date_to] = parseDate(`update_date_range`, payload);
    data.update_date_from = dateTimeFormat(update_date_from);
    data.update_date_to = dateTimeFormat(update_date_to);
    data = omit(data, [`update_date_range`]);
  }

  if (startDate && endDate) {
    data = {
      ...data,
      date_from: dateTimeFormat(startDate),
      date_to: dateTimeFormat(endDate),
    };
  }

  data.filters = omitBy(data.filters, (val) => isNull(val) || isUndefined(val));

  data.flags = data.fields;
  data = omit(data, [`fields`]);

  return data;
};

const prepareListCompletedData = (payload: IGenerateReportsForm) => {
  const [stockpilingDateStart, stockpilingDateEnd] = parseDate(`stockpiling_date`, payload);
  const [archivingDateStart, archivingDateEnd] = parseDate(`archiving_period`, payload);

  let data: IGenerateReportsForm = omit(payload, `stockpiling_date`);

  const filters = payload.filters || {};

  if (stockpilingDateStart) {
    data.filters = {
      ...filters,
      stockpiling_date_from: dateTimeFormat(stockpilingDateStart),
      stockpiling_date_to: dateTimeFormat(stockpilingDateEnd),
    };
  }

  if (archivingDateStart) {
    data.filters = {
      ...filters,
      archiving_date_from: dateTimeFormat(archivingDateStart),
      archiving_date_to: dateTimeFormat(archivingDateEnd),
    };
  }

  data = omit(data, [`stockpiling_date`, `archiving_period`]);

  if (data.filters && isNull(data.filters?.originality)) {
    delete data.filters.originality;
  }

  data.filters = omitBy(data.filters, (val) => isNull(val) || isUndefined(val));

  return data;
};

interface IReportGeneratingProps {
  payload: IGenerateReportsForm;
  meta: IWidgetsMeta;
}

const prepareAppropriateReportRequest = (
  modal: { type: number },
  detail: boolean | undefined,
  isReportsWidget: boolean,
  reportType: string
) => {
  switch (modal?.type) {
    case ModalType.UPDATE:
      return autoReportApi.editAutoReports;
    case ModalType.CREATE:
      if (!isReportsWidget) return autoReportApi.createAutoReports;
      else if (reportType === ReportType.SUM_REPORT && !detail) return reportApi.generateSumReport;
      else if (detail) return reportApi.generateSumWithDetailReport;
      else return reportApi.generateListReport;
    case ModalType.REMOVE_CONFIRMATION:
      return autoReportApi.deleteAutoReports;
    default:
      return reportApi.generateListReport;
  }
};

const prepareBalanceReport = (payload: {
  payment_system: string;
  currency_code: number;
  direction: string;
  filters: {
    exchanger_identifier: string;
  };
  file_format: string;
  transaction_type: string;
  external_address: string;
  period: {
    values: {
      startDate: string;
      endDate: string;
    };
  };
}) => {
  return {
    wallet_type: payload?.payment_system,
    currency_code: payload?.currency_code,
    direction: payload?.direction,
    file_format: payload?.file_format,
    transaction_type: payload?.transaction_type,
    counterparty_wallet_type: payload?.filters?.exchanger_identifier,
    external_address: payload?.external_address,
    date_from: dateTimeFormat(payload?.period?.values?.startDate),
    date_to: dateTimeFormat(payload?.period?.values?.endDate),
  };
};

function* reportGeneratingSaga({ payload, meta }: IReportGeneratingProps) {
  const isReportsWidget = WidgetType.REPORTS === meta.widget;
  const form = isReportsWidget ? REPORT_GENERATING_FORM_NAME : AUTO_REPORT_GENERATING_FORM_NAME;

  const valid: boolean = yield validateForm({ form, meta });
  if (!valid) {
    return;
  }
  const reportType = payload?.report_type;
  const detail = payload?.detail_by_day;

  const errors = validateReportGenerating(payload);
  if (!isEmpty(errors)) {
    yield formSubmissionError({ payload: errors, meta, form });

    return;
  }

  const modal: { type: number; id: number } = yield select(
    widgetModalSelector(isReportsWidget ? WidgetType.REPORTS : WidgetType.AUTOREPORTS)
  );

  let data: IGenerateReportsForm, request: (data: IGenerateReportsForm, id: number) => AxiosPromise;
  switch (reportType) {
    case ReportType.LABEL_HISTORY_REPORT:
      data = prepareData(payload);
      request = reportApi.generateLabelHistoryReport;
      break;
    case ReportType.COMMISSION_REPORT:
      data = prepareData(payload);
      request = reportApi.reportsCommission;
      break;
    case ReportType.WITHDRAWAL_REQUEST_REPORT:
      data = prepareData(payload);
      request = reportApi.generateWithdrawalRequestReport;
      break;
    case ReportType.CLIENT_ACCOUNT_BALANCE_CHANGE:
      data = prepareData(payload);
      request = reportApi.generateClientBalanceChangeReport;
      break;
    case ReportType.SUM_REPORT:
    case ReportType.TRANSACTIONS_SUM_DETAIL:
      data = prepareSumAndListReportData(payload, meta);
      request = prepareAppropriateReportRequest(modal, detail, isReportsWidget, reportType);
      break;
    case ReportType.LIST_REPORT:
      data = prepareSumAndListReportData(payload, meta);
      request = prepareAppropriateReportRequest(modal, detail, isReportsWidget, ReportType.LIST_REPORT);
      break;
    case ReportType.LIST_REPORT_WITH_COMPLETED_STOCKPILING:
      data = prepareListCompletedData(payload);
      request = reportApi.generateCompletedListReport;
      break;
    case ReportType.OPERATIONS_WITH_BALANCES:
      // @ts-expect-error Нет нужды поля для других форм пихать
      data = prepareBalanceReport(payload);
      request = reportApi.generateOperationsWithBalances;
      break;
    case ReportType.COMPLETED_TRANSACTION_SUM: {
      data = prepareCompletedSumData(payload, meta);
      if (payload.detail_by_day) {
        request = reportApi.generateCompletedSumWithDetailReport;
      } else {
        request = reportApi.generateCompletedSumReport;
      }
      break;
    }
    default:
      data = prepareSumAndListReportData(payload, meta);
      request = reportApi.generateReport;
  }

  if (reportType !== ReportType.SUM_REPORT || reportType !== ReportType.TRANSACTIONS_SUM_DETAIL) {
    data = omit(data, `filters.detail_by_day`);
  }

  yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: true, meta });
  yield refreshSaga({
    request: () => request(data, modal?.id),
    onSuccess: function* () {
      yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      if (isReportsWidget) {
        yield toastr.success(i18n.t(`common.success`), i18n.t(`reports.reportSentToQueue`));
        yield put(reportsActionCreators.getList());
        yield put(reportsActionCreators.hideModal());
      } else {
        yield put(autoReportsActionCreators.hideModal());
      }
    },
    onError: function* (e: AxiosError) {
      yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      const payload: string = yield getErrorMessage(e);
      if (payload) {
        yield formSubmissionError({ payload, meta, form });
      } else {
        yield toastr.error(i18n.t(`common.error`), i18n.t(`errors.tryAgainLater`));
      }
    },
  });
}

const reportsSagas = [takeEvery<IAction>(GENERATE_REPORT_REQUESTED, reportGeneratingSaga)];

export default reportsSagas;
