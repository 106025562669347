import React, { ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { initialize } from 'redux-form';
import { Header as KassmaHeader } from '@kassma-team/kassma-toolkit/lib';
import LoadingBar from 'react-redux-loading-bar';

import { Permission } from 'utils/enums';
import { hasAccessSelector } from 'selectors/auth';
import {
  DASHBOARD_ASIDE_FILTERS_FORM_NAME,
  DASHBOARD_PAYMENTS_FILTERS_FORM_NAME,
  DEFAULT_ROUTE,
} from 'utils/constants';
import useNavList from 'hooks/useNavList';
import { setDashboardAsideFilters, setDashboardChartFilters } from 'actions/dashboard';
import { asideFiltersInitData, dashboardFiltersInitData } from 'utils/dashboard/filtersInitData';

import logo from 'assets/media/logo.svg';
import logo_new_year from 'assets/media/logo_new_year.svg';
// import NotificationsTimeline from 'components/header/NotificationsTimeline';
import { NotificationCenter } from './NotificationCenter';

interface IProps {
  logo?: ReactNode;
  list?: Record<string, unknown>[];
  loadingBar?: ReactNode;
  ReactNavLink?: unknown;
  notificationNode?: ReactNode;
}

const renderLogo = () => {
  const currentYear = new Date().getFullYear();

  const from = new Date(`12-20-${currentYear}`).getTime();
  const to = new Date(`01-11-${currentYear + 1}`).getTime();

  const now = new Date().getTime();
  const isNewYearPeriod = now >= from && now <= to;

  const logoSrc = isNewYearPeriod ? logo_new_year : logo;

  return <img src={logoSrc} alt="" />;
};

const Header: React.FC<IProps> = () => {
  const dashboardAccess = useSelector(hasAccessSelector(Permission.DASHBOARD));

  const dispatch = useDispatch();

  const list = useNavList();

  let logo;
  if (dashboardAccess) {
    if (window.location.pathname === DEFAULT_ROUTE) {
      logo = (
        <button
          className="logo-link mr-5"
          onClick={() => {
            dispatch(setDashboardAsideFilters({}));
            dispatch(setDashboardChartFilters({}));
            dispatch(initialize(DASHBOARD_ASIDE_FILTERS_FORM_NAME, { ...asideFiltersInitData }));
            dispatch(initialize(DASHBOARD_PAYMENTS_FILTERS_FORM_NAME, { ...dashboardFiltersInitData }));
          }}
        >
          {renderLogo()}
        </button>
      );
    } else {
      logo = (
        <Link to={DEFAULT_ROUTE} className="font-w700 mr-5 logo-link">
          {renderLogo()}
        </Link>
      );
    }
  } else {
    logo = <span className="font-w700 mr-5">{renderLogo}</span>;
  }

  return (
    <KassmaHeader
      logo={logo}
      list={list}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error В версии пакета v1.71.1 - этот пропс был добавлен. Я не установил причину, почему тс его не видит. Код работает.
      notificationSlot={<NotificationCenter />}
      // @ts-ignore
      loadingBar={<LoadingBar className="progress-line-bar" showFastActions />}
      ReactNavLink={Link}
    />
  );
};

export default Header;
