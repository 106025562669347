import { Reducer } from 'redux';
import { IWidgetsState } from '@kassma-team/kassma-toolkit/lib/types/widgets';

import { PAYMENT_HINT_DEFAULT_TRANSLATIONS_SUCCEEDED, RESET_HINT_DEFAULT_TRANSLATIONS } from 'actionTypes';

const actionTypes = new Set([PAYMENT_HINT_DEFAULT_TRANSLATIONS_SUCCEEDED, RESET_HINT_DEFAULT_TRANSLATIONS]);

const reducer: Reducer = (state: IWidgetsState, { type, payload }) => {
  switch (type) {
    case PAYMENT_HINT_DEFAULT_TRANSLATIONS_SUCCEEDED:
      return {
        ...state,
        defaultTranslations: payload,
      };
    case RESET_HINT_DEFAULT_TRANSLATIONS:
      return {
        ...state,
        defaultTranslations: [],
      };
    default:
      return state;
  }
};

const paymentHints = { actionTypes, reducer };

export default paymentHints;
