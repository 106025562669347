import React, { useRef } from 'react';
import FileCopy from '@material-ui/icons/FileCopy';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, getNormalizedUrl, pluginUrlSelector } from '@kassma-team/kassma-toolkit/lib';

import { copyText } from 'utils';

import SettingsBlockWrap from 'components/widgets/settings/SettingsBlockWrap';

const PluginInstallation = () => {
  const iFrameRef = useRef<any>();
  const pluginUrl: string = useSelector(pluginUrlSelector);

  const [t] = useTranslation();

  return (
    <SettingsBlockWrap title="settings.pluginInstallation">
      <div className="col-lg-12 mb-10">
        <p className="mb-10">{t(`settings.pluginInstallationDescription`)}</p>
        <div className="plagin-code">
          <div className="js-code">
            <code className="js-style" ref={iFrameRef}>
              &lt;iframe id="kassma-plugin-frame" src="{getNormalizedUrl(pluginUrl)}?label=
              <span className="inner-code">label</span>
              &currency_code=
              <span className="inner-code">currency_code</span>
              &lang=
              <span className="inner-code">lang</span>
              &wallet_type=
              <span className="inner-code">wallet_type</span>
              &custom_transaction_id=
              <span className="inner-code">custom_transaction_id</span>
              &fixed_amount=
              <span className="inner-code">fixed_amount</span>
              &payment_url_success_id=
              <span className="inner-code">payment_url_success_id</span>
              &payment_url_fail_id=
              <span className="inner-code">payment_url_fail_id</span>
              &success_url=
              <span className="inner-code">success_url</span>
              &payment_url_pending_id=
              <span className="inner-code">payment_url_pending_id</span>
              &pending_url=
              <span className="inner-code">pending_url</span>
              &fail_url=
              <span className="inner-code">fail_url</span>
              " width="640" height="750" frameBorder="0"&gt;&lt;/iframe&gt;
              <br />
              &lt;script&gt;document.querySelector('#kassma-plugin-frame').src += '&' + new
              Date().getTime();&lt;/script&gt;
            </code>
          </div>
          <div className="form-group">
            <Button endIcon={<FileCopy />} onClick={() => copyText(iFrameRef)}>
              {t(`common.copy`)}
            </Button>
          </div>
        </div>
        <ol>
          <li>
            <code className="inner-code">label</code>&nbsp;- {t(`settings.required`)};
          </li>
          <li>
            <code className="inner-code">currency_code</code>&nbsp;- {t(`settings.required`)}.{` `}
            {t(`settings.severalValue`)};
          </li>
          <li>
            <code className="inner-code">lang</code>
            &nbsp;- {t(`settings.optionalWithDefault`, { param: `en` })};
          </li>
          <li>
            <code className="inner-code">wallet_type</code>&nbsp;- {t(`settings.optional`)};
          </li>
          <li>
            <code className="inner-code">custom_transaction_id</code>&nbsp;- {t(`settings.optional`)};
          </li>
          <li>
            <code className="inner-code">fixed_amount</code>&nbsp;- {t(`settings.optional`)};
          </li>
          <li>
            <code className="inner-code">payment_url_success_id</code>&nbsp;- {t(`settings.optional`)};
          </li>
          <li>
            <code className="inner-code">payment_url_fail_id</code>&nbsp;- {t(`settings.optional`)};
          </li>
          <li>
            <code className="inner-code">success_url</code>&nbsp;- {t(`settings.optional`)}.{` `}
            {t(`settings.useRedirectURLFromSettingsParameterAvailability`)};
          </li>
          <li>
            <code className="inner-code">fail_url</code>&nbsp;- {t(`settings.optional`)}.{` `}
            {t(`settings.useRedirectURLFromSettingsParameterAvailability`)};
          </li>
          <li>
            <code className="inner-code">payment_url_pending_id</code>&nbsp;- {t(`settings.optional`)};
          </li>
          <li>
            <code className="inner-code">pending_url</code>&nbsp;- {t(`settings.optional`)}.{` `}
            {t(`settings.pendingUrlDescription`)}
          </li>
        </ol>
      </div>
    </SettingsBlockWrap>
  );
};

export default PluginInstallation;
