import {
  createWidgetAction,
  widgetActionCreators,
  IWidgetActionCreators,
  IWidgetsAction,
} from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { PASSWORD_SETTINGS } from 'utils/constants';
import {
  CREATE_PAYMENTS_SORTING_REQUESTED,
  CREATE_PAYMENTS_SORTING_SUCCEEDED,
  LOAD_CURRENCIES_WITH_DEFAULT_SORTING_REQUESTED,
  LOAD_PAYMENTS_BY_CURRENCY_REQUESTED,
  LOAD_SETTINGS_REQUESTED,
  RESET_SORTING_CREATE_MODAL_DATA,
  RESET_SORTING_UPDATE_MODAL_DATA,
  SET_PAYMENTS_SORTING_REQUESTED,
  SET_PAYMENTS_SORTING_SUCCEEDED,
  SET_SORTING_CREATE_MODAL_DATA,
  SET_SORTING_UPDATE_MODAL_DATA,
  UPDATE_CUSTOM_STYLE_REQUESTED,
  UPDATE_HELP_IMAGES_REQUESTED,
  UPDATE_SETTINGS_REQUESTED,
  UPDATE_PASSWORD_REQUESTED,
  UPDATE_TIMEZONE,
  UPDATE_PLUGIN_COLOR_SCHEME,
  LOAD_PLUGIN_SETTINGS_REQUESTED,
  LOAD_SETTINGS_TIMEZONE,
  LOAD_PAYTM_FLAG_REQUESTED,
  DELETE_HELP_IMAGES,
  LOAD_PRIVATE_SETTINGS_REQUESTED,
  UPDATE_PRIVATE_SETTINGS_REQUIRED,
} from 'actionTypes';

export const settingsActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.SETTINGS,
});

export const updatePluginHelpImages: IWidgetsAction = createWidgetAction({
  widget: WidgetType.SETTINGS,
})(UPDATE_HELP_IMAGES_REQUESTED);

export const deletePluginHelpImages: IWidgetsAction = createWidgetAction({
  widget: WidgetType.SETTINGS,
})(DELETE_HELP_IMAGES);

export const updateCustomStyle: IWidgetsAction = createWidgetAction({
  widget: WidgetType.SETTINGS,
})(UPDATE_CUSTOM_STYLE_REQUESTED);

export const updateSettings = (form: string): IWidgetsAction =>
  createWidgetAction({
    widget: WidgetType.SETTINGS,
    form,
  })(UPDATE_SETTINGS_REQUESTED);

export const updatePrivateSettings = createWidgetAction({
  widget: WidgetType.SETTINGS,
})(UPDATE_PRIVATE_SETTINGS_REQUIRED);

export const updateTimezone: IWidgetsAction = createWidgetAction({
  widget: WidgetType.SETTINGS,
  withoutPayloadWrap: true,
})(UPDATE_TIMEZONE);

export const loadTimezone: IWidgetsAction = createWidgetAction({
  widget: WidgetType.SETTINGS,
  withoutPayloadWrap: true,
})(LOAD_SETTINGS_TIMEZONE);

export const updatePluginColorScheme: IWidgetsAction = createWidgetAction({
  widget: WidgetType.SETTINGS,
  withoutPayloadWrap: true,
})(UPDATE_PLUGIN_COLOR_SCHEME);

export const loadPluginSettings: IWidgetsAction = createWidgetAction({
  widget: WidgetType.SETTINGS,
})(LOAD_PLUGIN_SETTINGS_REQUESTED);

export const updatePassword: IWidgetsAction = createWidgetAction({
  widget: WidgetType.SETTINGS,
  form: PASSWORD_SETTINGS,
})(UPDATE_PASSWORD_REQUESTED);

export const loadSettings: IWidgetsAction = createWidgetAction({
  widget: WidgetType.SETTINGS,
})(LOAD_SETTINGS_REQUESTED);

export const loadPrivateSettings: IWidgetsAction = createWidgetAction({
  widget: WidgetType.SETTINGS,
})(LOAD_PRIVATE_SETTINGS_REQUESTED);

export const getPaymentOfCurrencies: IWidgetsAction = createWidgetAction({
  widget: WidgetType.SETTINGS,
})(LOAD_CURRENCIES_WITH_DEFAULT_SORTING_REQUESTED);

export const getPaymentsByCurrency: IWidgetsAction = createWidgetAction({
  widget: WidgetType.SETTINGS,
})(LOAD_PAYMENTS_BY_CURRENCY_REQUESTED);

export const setPaymentsSorting: IWidgetsAction = createWidgetAction({
  widget: WidgetType.SETTINGS,
})(SET_PAYMENTS_SORTING_REQUESTED);

export const createPaymentsSorting: IWidgetsAction = createWidgetAction({
  widget: WidgetType.SETTINGS,
})(CREATE_PAYMENTS_SORTING_REQUESTED);

export const resetPaymentsSorting = (currency: string): IWidgetsAction =>
  createWidgetAction({
    widget: WidgetType.SETTINGS,
  })(SET_PAYMENTS_SORTING_REQUESTED)({ isDefault: true, currency });

export const setCreateSortingModalData: IWidgetsAction = createWidgetAction({
  widget: WidgetType.SETTINGS,
})(SET_SORTING_CREATE_MODAL_DATA);

export const resetCreateSortingModalData: IWidgetsAction = createWidgetAction({
  widget: WidgetType.SETTINGS,
})(RESET_SORTING_CREATE_MODAL_DATA);

export const setUpdateSortingModalData: IWidgetsAction = createWidgetAction({
  widget: WidgetType.SETTINGS,
})(SET_SORTING_UPDATE_MODAL_DATA);

export const resetUpdateSortingModalData: IWidgetsAction = createWidgetAction({
  widget: WidgetType.SETTINGS,
})(RESET_SORTING_UPDATE_MODAL_DATA);

export const setPaymentsSortingSucceeded: IWidgetsAction = createWidgetAction({
  widget: WidgetType.SETTINGS,
})(SET_PAYMENTS_SORTING_SUCCEEDED);

export const createPaymentsSortingSucceeded: IWidgetsAction = createWidgetAction({
  widget: WidgetType.SETTINGS,
})(CREATE_PAYMENTS_SORTING_SUCCEEDED);

export const loadPaytmFlag: IWidgetsAction = createWidgetAction({
  widget: WidgetType.SETTINGS,
})(LOAD_PAYTM_FLAG_REQUESTED);
