import React, { FC } from 'react';
import { WalletSummary } from 'api/wallets/WalletsApi';
import { Item } from './Item';

type Data = Record<WalletSummary[`wallet_type_name`], Record<WalletSummary[`currency`], WalletSummary>>;

type Props = {
  data: Data;

  children?: never;
};

const getKeys = <T extends Record<string, unknown>>(data: T): ReadonlyArray<keyof T> => Object.keys(data);

export const Accordion: FC<Props> = ({ data }) => {
  return (
    <div>
      {getKeys<Data>(data).map((paymentSystem, i) => {
        return <Item paymentSystem={paymentSystem} walletSummary={data} key={`${paymentSystem}-${i}`} />;
      })}
    </div>
  );
};
