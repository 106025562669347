import { takeEvery, select, put } from 'redux-saga/effects';
import { getSearch } from 'connected-react-router';
import queryString from 'query-string';
import FileSaver from 'file-saver';
import get from 'lodash/get';
import { toastr } from 'react-redux-toastr';
import { AxiosError, AxiosResponse } from 'axios';
import {
  formSubmissionError,
  validateForm,
  getErrorMessage,
  WIDGET_RESET_MODAL,
  WIDGET_SET_SUBMITTING_STATUS,
  IWidgetsMeta,
  IAction,
} from '@kassma-team/kassma-toolkit/lib';

import {
  ACTIVATE_PRELIMINARY_TRANSACTION_REQUESTED,
  ACTIVATE_PRELIMINARY_TRANSACTION_SUCCEEDED,
  ACTIVATE_TRANSACTION_REQUESTED,
  ACTIVATE_TRANSACTION_SUCCEEDED,
  APPROVE_TRANSACTION_REQUESTED,
  APPROVE_TRANSACTION_SUCCEEDED,
  ARCHIVATE_TRANSACTION_REQUESTED,
  ARCHIVATE_TRANSACTION_SUCCEEDED,
  DOWNLOAD_TRANSACTION_CSV_LIST_REQUESTED,
  POSSIBLE_TRANSACTION_APPROVE,
  UPLOAD_TRANSACTIONS,
} from 'actionTypes';
import refreshSaga from 'sagas/effects/refreshSaga';
import {
  downloadTransactionCSVListFail,
  downloadTransactionCSVListSuccess,
  transactionActionCreators,
} from 'actions/widgets/transactions/transactions';
import { preliminaryTransactionsActionCreators } from 'actions/widgets/transactions/preliminaryTransactions';
import { WidgetType } from 'utils/enums';
import i18n from 'i18n';
import { TRANSACTION_ACTIVATION_FORM_NAME, TRANSACTIONS_UPLOADING_FORM_NAME } from 'utils/constants';

import { IActivateTransactionsForm, IUploadManualWithdrawalForm } from 'interfaces/widgets/transaction/transaction';
import transactionApi from 'api/transactions/TransactionApi';
import pretransactionApi from 'api/transactions/preliminaryTransactions/PretransactionApi';
import archiveTransactionApi from '../../api/transactions/archiveTransactions/ArchiveTransactionApi';
import possibleTransactionApi from '../../api/transactions/possibleTransactions/PossibleTransactionApi';
import { possibleTransactionActionCreators } from '../../actions/widgets/transactions/possibleTransactions';

function* downloadTransactionCSVList() {
  const search: string = yield select(getSearch);
  const searchParams = queryString.parse(search);
  try {
    const resp: AxiosResponse = yield transactionApi.downloadTransactionCSV(searchParams);
    const file: Blob = yield new Blob([get(resp, `data`)], {});
    yield FileSaver.saveAs(file, `transactions.xlsx`);
    yield put(downloadTransactionCSVListSuccess());
  } catch (e: any) {
    const message: string = yield getErrorMessage(e, {
      defaultValue: i18n.t(`transactions.fileDownloadingHasBeenFailed`),
    });
    yield put(transactionActionCreators.setError(message));
    yield put(downloadTransactionCSVListFail());
  }
}

interface IActivatePreliminaryTransactionProps {
  possible_transaction_primary_id: number;
  primary_id: number;
}

function* activatePreliminaryTransactionSaga({
  payload: { possible_transaction_primary_id, primary_id },
}: Record<`payload`, IActivatePreliminaryTransactionProps>) {
  yield refreshSaga({
    request: () => pretransactionApi.activatePreliminaryTransaction(primary_id, { possible_transaction_primary_id }),
    onSuccess: function* () {
      yield toastr.success(
        i18n.t(`common.success`),
        i18n.t(`transactions.preliminaryTransactionHasBeenSuccessfullyActivated`)
      );
      yield put({
        type: ACTIVATE_PRELIMINARY_TRANSACTION_SUCCEEDED,
        payload: primary_id,
        meta: {
          widget: WidgetType.PRELIMINARY_TRANSACTIONS,
        },
      });
      yield put(preliminaryTransactionsActionCreators.hideModal());
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

interface IActivateTransactionProps {
  payload: IActivateTransactionsForm;
  meta: IWidgetsMeta;
}

function* activateTransactionSaga({ payload, meta }: IActivateTransactionProps) {
  const valid: boolean = yield validateForm({ form: TRANSACTION_ACTIVATION_FORM_NAME, meta });
  if (!valid) {
    return;
  }

  yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: true, meta });

  const primaryId = get(meta, `primaryId`);

  yield refreshSaga({
    request: () => transactionApi.activateTransaction(primaryId, payload),
    onSuccess: function* (resp: AxiosResponse) {
      yield put({
        type: ACTIVATE_TRANSACTION_SUCCEEDED,
        payload: {
          primary_id: primaryId,
          label: payload.label,
          merchant_order_id: payload.merchant_order_id,
          postback_message: get(resp, `data.postback_message`),
          postback_status: get(resp, `data.postback_status`),
        },
        meta,
      });
      yield put({ type: WIDGET_RESET_MODAL, meta });
      toastr.success(i18n.t(`common.success`), i18n.t(`transactions.transactionHasBeenSuccessfullyUpdated`));
    },
    onError: function* (e: AxiosError) {
      if (meta.onError) {
        meta.onError(e);
      }
      const errPayload: string = yield getErrorMessage(e);
      yield formSubmissionError({ payload: errPayload, meta, form: TRANSACTION_ACTIVATION_FORM_NAME });
    },
    onFinally: function* () {
      yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
    },
  });
}

function* approveTransactionSaga({ payload }: any) {
  //@ts-ignore
  const queryParams = yield select(getSearch);
  const params = queryString.parse(queryParams);

  yield refreshSaga({
    request: () => transactionApi.set_fake(payload.transactions, payload.is_fake),
    onSuccess: function* () {
      yield put({
        type: APPROVE_TRANSACTION_SUCCEEDED,
        payload: {
          primary_id: payload.primary_id,
        },
      });
      toastr.success(i18n.t(`common.success`), ``);
      yield put(transactionActionCreators.getList(params));
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
    onFinally: function* () {
      yield put(transactionActionCreators.hideModal());
    },
  });
}

interface IArchivateTransactionProps {
  payload: Record<`primaryId`, number>;
  meta: IWidgetsMeta;
}

function* archivateTransactionSaga({ payload, meta }: IArchivateTransactionProps) {
  const primaryId = get(payload, `primaryId`);

  yield refreshSaga({
    request: () => archiveTransactionApi.archivateTransaction(primaryId),
    onSuccess: function* () {
      yield put({
        type: ARCHIVATE_TRANSACTION_SUCCEEDED,
        payload: {
          primary_id: primaryId,
        },
        meta,
      });
      toastr.success(i18n.t(`common.success`), i18n.t(`transactions.transactionHasBeenAddedToArchive`));
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
    onFinally: function* () {
      yield put(transactionActionCreators.hideModal());
    },
  });
}

interface IUploadTransactionsProps {
  meta: IWidgetsMeta;
  payload: IUploadManualWithdrawalForm;
}

function* uploadTransactionsSaga({ meta, payload }: IUploadTransactionsProps) {
  const valid: boolean = yield validateForm({ form: TRANSACTIONS_UPLOADING_FORM_NAME, meta });
  if (!valid) {
    return;
  }

  yield refreshSaga({
    request: () => transactionApi.uploadTransactions(payload),
    onSuccess: function* () {
      yield toastr.success(i18n.t(`common.success`), i18n.t(`transactions.fileInProcessed`));
      yield put(transactionActionCreators.hideModal());
    },
    onError: function* (e: AxiosError) {
      const errPayload: string = yield getErrorMessage(e);
      yield formSubmissionError({ payload: errPayload, meta, form: TRANSACTIONS_UPLOADING_FORM_NAME });
    },
  });
}

function* approvePossibleTransaction({ meta, payload }: IAction) {
  const { id, ...data } = payload;
  yield refreshSaga({
    request: () => possibleTransactionApi.approvePossibleTransaction(id, data),
    onSuccess: function* () {
      yield put(possibleTransactionActionCreators.getList());
      yield put(possibleTransactionActionCreators.hideModal());
      yield toastr.success(i18n.t(`common.success`), i18n.t(`transactions.approveMessageSuccess`));
    },
    onError: function* (e: AxiosError) {
      const errPayload: string = yield getErrorMessage(e);
      yield formSubmissionError({ payload: errPayload, meta, form: TRANSACTIONS_UPLOADING_FORM_NAME });
    },
  });
}

const transactionSagas = [
  takeEvery<IAction>(DOWNLOAD_TRANSACTION_CSV_LIST_REQUESTED, downloadTransactionCSVList),
  takeEvery<IAction>(ACTIVATE_PRELIMINARY_TRANSACTION_REQUESTED, activatePreliminaryTransactionSaga),
  takeEvery<IAction>(ACTIVATE_TRANSACTION_REQUESTED, activateTransactionSaga),
  takeEvery<IAction>(APPROVE_TRANSACTION_REQUESTED, approveTransactionSaga),
  takeEvery<IAction>(ARCHIVATE_TRANSACTION_REQUESTED, archivateTransactionSaga),
  takeEvery<IAction>(UPLOAD_TRANSACTIONS, uploadTransactionsSaga),
  takeEvery<IAction>(POSSIBLE_TRANSACTION_APPROVE, approvePossibleTransaction),
];

export default transactionSagas;
