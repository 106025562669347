import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';

export class WithdrawalV1Api extends BaseApi {
  confirmApprovalOfWithdrawal = (id: number): AxiosPromise =>
    this.makeFetch(`status`, {
      data: {
        withdrawal_id: id,
        status: `PROCESSED`,
      },
      method: `POST`,
    });

  confirmRejectionOfWithdrawal = (id: number): AxiosPromise =>
    this.makeFetch(`status`, {
      data: {
        withdrawal_id: id,
        status: `CANCELED`,
      },
      method: `POST`,
    });
}

const withdrawalV1Api = new WithdrawalV1Api(`v1/withdrawal`);

export default withdrawalV1Api;
