import React, { FC, useContext, createContext } from 'react';
import { useFormTypes, UseFormTypes } from '../../PaymentTranslation/hooks';

type FormTypesCtx = UseFormTypes;

const ctx = createContext<FormTypesCtx | undefined>(undefined);

export const FormTypeCtx: FC = ({ children }) => {
  const { formTypes, isLoading, fetchFormTypes, handleCurrentFormType, currentFormType } = useFormTypes();

  const value: FormTypesCtx = {
    formTypes,
    isLoading,
    fetchFormTypes,
    handleCurrentFormType,
    currentFormType,
  };

  return <ctx.Provider value={value}>{children}</ctx.Provider>;
};

export function useFormTypeCtx(): FormTypesCtx {
  const context = useContext(ctx);

  if (context === undefined) {
    throw new Error(`Is need to use useFormTypesCtx inside a FormTypeCtx component`);
  }

  return context;
}
