import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import map from 'lodash/map';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import find from 'lodash/find';
import useMount from 'react-use/lib/useMount';
import { useTranslation } from 'react-i18next';
import {
  WalletImg,
  widgetModalSelector,
  widgetItemSelector,
  AccordionComponent,
  langSelector,
} from '@kassma-team/kassma-toolkit/lib';

import { PaymentTranslationFieldType, WidgetType } from 'utils/enums';
import { separateFieldData } from 'utils/widgets/paymentHints/paymentHintDataProvider';
import { paymentTranslationFormTextCode } from 'static/paymentHints';
import { paymentHintsActionCreators } from 'actions/widgets/constructors/paymentHintsConstructor';

import ModalElement from 'components/modals/ModalElement';

import './paymentTranslations.scss';
import { paymentSystemLogoSelector } from '../../../../selectors/widgets/paymentSystems';

const PaymentHintViewModal = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const userLang = useSelector(langSelector);
  const item = useSelector(widgetItemSelector(WidgetType.PAYMENT_HINTS_CONSTRUCTOR));
  const modal = useSelector(widgetModalSelector(WidgetType.PAYMENT_HINTS_CONSTRUCTOR));
  const translations = get(item, `translations`);
  const name = get(item, `name`);
  const wallet_type = get(item, `wallet_type`);
  const form_element = get(item, `form_element`);
  const form_type = get(item, `form_type`);
  const logo = useSelector(paymentSystemLogoSelector(wallet_type));
  const [modalId] = useState(get(modal, `id`));

  const translationsHint = translations;
  const translationsField = separateFieldData(translations);

  useMount(() => {
    dispatch(paymentHintsActionCreators.getItem(modalId));
  });

  const content = (
    <>
      <div className="table-responsive">
        <table className="table table-striped table-borderless table-vcenter wallet-item__table">
          <tbody>
            <tr>
              <td className="font-w600">{t(`paymentConstructor.name`)}</td>
              <td>{name}</td>
            </tr>
            <tr>
              <td className="font-w600">{t(`paymentConstructor.paymentSystem`)}</td>
              <td>
                <WalletImg title={wallet_type} src={logo} />
              </td>
            </tr>
            <tr>
              <td className="font-w600">{t(`paymentConstructor.translationType`)}</td>
              <td>{t(`paymentConstructor.${form_element}`)}</td>
            </tr>
            <tr>
              <td className="font-w600">{t(`paymentConstructor.formType`)}</td>
              <td>{t(paymentTranslationFormTextCode[form_type], ``)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mb-10 mt-10">
        <span className="font-weight-bold">{t(`paymentConstructor.translation`)}</span>
      </div>
      <div className="table-responsive">
        {form_element === PaymentTranslationFieldType.FIELD ? (
          map(translationsField, (group, key) => {
            const accordionTitle = get(find(group, { lang: userLang }), `form_text`) || ``;

            return (
              <AccordionComponent key={key} title={accordionTitle}>
                <table className="table table-striped table-borderless table-vcenter wallet-item__table">
                  <tbody>
                    {map(group, ({ lang, form_text, id }) => (
                      <tr key={id}>
                        <td className="font-w600">{lang}</td>
                        <td>{form_text}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </AccordionComponent>
            );
          })
        ) : (
          <table className="table table-striped table-borderless table-vcenter wallet-item__table">
            <tbody>
              {map(translationsHint, ({ lang, form_text, id }) => (
                <tr key={id}>
                  <td className="font-w600">{lang}</td>
                  <td className="translation-text" dangerouslySetInnerHTML={{ __html: form_text }} />
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );

  return (
    <ModalElement
      actionCreators={paymentHintsActionCreators}
      title={`paymentConstructor.translationView`}
      loading={isNull(item)}
      content={content}
    />
  );
};

export default PaymentHintViewModal;
