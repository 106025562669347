import { createSelector } from 'reselect';
import { IRootState } from '@kassma-team/kassma-toolkit/lib/types/common';

import {
  IDashboardState,
  IDashboardStateAsideFilters,
  IDashboardStateChartFilters,
  IDashboardStateTransactions,
  IDashboardStateWithdrawals,
} from 'interfaces/dashboard';

interface IState extends IRootState {
  dashboard: IDashboardState;
}

export const dashboardSelector = (state: IState) => state.dashboard;

export const dashboardChartFilters = createSelector<IState, IDashboardState, IDashboardStateChartFilters>(
  dashboardSelector,
  (dashboard) => dashboard?.chartFilters
);

export const dashboardTransactionsSelector = createSelector<IState, IDashboardState, IDashboardStateTransactions>(
  dashboardSelector,
  (dashboard) => dashboard?.transactions
);

export const dashboardWithdrawalsSelector = createSelector<IState, IDashboardState, IDashboardStateWithdrawals>(
  dashboardSelector,
  (dashboard) => dashboard?.withdrawals
);

export const dashboardAsideFilters = createSelector<IState, IDashboardState, IDashboardStateAsideFilters>(
  dashboardSelector,
  (dashboard) => dashboard?.asideFilters
);

export const dashboardEntityTypeSelector = createSelector<IState, IDashboardState, number>(
  dashboardSelector,
  (dashboard) => dashboard?.entityType
);
