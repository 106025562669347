import { put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import get from 'lodash/get';
import i18n from 'i18n';
import { AxiosError, AxiosResponse } from 'axios';
import { IAction } from '@kassma-team/kassma-toolkit/lib';

import { getErrorMessage } from 'utils';
import refreshSaga from 'sagas/effects/refreshSaga';
import {
  CREATE_PAYMENTS_SORTING_REQUESTED,
  LOAD_CURRENCIES_WITH_DEFAULT_SORTING_REQUESTED,
  LOAD_PAYMENTS_BY_CURRENCY_REQUESTED,
  SET_PAYMENTS_SORTING_REQUESTED,
} from 'actionTypes';
import {
  createPaymentsSortingSucceeded,
  setCreateSortingModalData,
  setPaymentsSortingSucceeded,
  settingsActionCreators,
  setUpdateSortingModalData,
} from 'actions/widgets/settings';

import { ICustomSortingCurrencyListItem } from 'interfaces/widgets/currency';
import settingsApi from 'api/settings/SettingsApi';
import walletTypeToCurrenciesApi from '../../../api/walletTypes/WalletTypeToCurrenciesApi';
import walletTypesToCurrenciesApi from '../../../api/walletTypes/WalletTypeToCurrenciesApi';
import currencyApi from '../../../api/currencies/CurrencyApi';

function* loadCurrenciesWithDefaultSortingSaga() {
  yield refreshSaga({
    request: () => settingsApi.loadPaymentOfCurrencies(),
    onSuccess: function* (resp: AxiosResponse) {
      const { currency_list_with_default_sorting } = get(resp, `data`) || {};
      yield put(setCreateSortingModalData({ currency_list_with_default_sorting }));
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

interface ILoadPaymentsByCurrencyProps {
  payload: string;
}

function* loadPaymentsByCurrencySaga({ payload }: ILoadPaymentsByCurrencyProps) {
  yield refreshSaga({
    request: () => settingsApi.loadPaymentsByCurrency(payload),
    onSuccess: function* (resp: AxiosResponse) {
      const { applied_payment_systems, default_payment_systems } = get(resp, `data`) || {};
      yield put(setUpdateSortingModalData({ applied_payment_systems, default_payment_systems }));
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

interface ISetPaymentsSortingPayload {
  paymentSystems: ICustomSortingCurrencyListItem[];
  isDefault: boolean;
  currency: string;
}

interface ISetPaymentsSortingProps {
  type: string;
  payload: ISetPaymentsSortingPayload;
}

function* setPaymentsSortingSaga({ type, payload }: ISetPaymentsSortingProps) {
  const succeededAction =
    type === CREATE_PAYMENTS_SORTING_REQUESTED ? createPaymentsSortingSucceeded : setPaymentsSortingSucceeded;

  const currency = get(payload, `currency`);

  const systems = payload.paymentSystems;
  let data: any = {
    items: systems.map((system, order) => ({
      //@ts-ignore
      id: system.id,
      order: order + 1,
    })),
  };

  const isDefault = payload.isDefault;
  if (isDefault) {
    data = {
      is_default: true,
      currency,
    };
  }

  const request = isDefault ? currencyApi.setDefaultSorting : walletTypesToCurrenciesApi.updateSorting;

  yield refreshSaga({
    request: () => request(data),
    onSuccess: function* (resp: AxiosResponse) {
      const is_default = get(resp, `data.is_default`);
      yield put(succeededAction({ payments: systems, is_default, currency }));
      yield put(settingsActionCreators.hideModal());
      toastr.success(i18n.t(`common.success`), i18n.t(`settings.orderHasBeenSuccessfullyUpdated`));
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

const paymentCurrenciesSettingsSagas = [
  takeEvery<IAction>(LOAD_CURRENCIES_WITH_DEFAULT_SORTING_REQUESTED, loadCurrenciesWithDefaultSortingSaga),
  takeEvery<IAction>(LOAD_PAYMENTS_BY_CURRENCY_REQUESTED, loadPaymentsByCurrencySaga),
  takeEvery<IAction>(SET_PAYMENTS_SORTING_REQUESTED, setPaymentsSortingSaga),
  takeEvery<IAction>(CREATE_PAYMENTS_SORTING_REQUESTED, setPaymentsSortingSaga),
];

export default paymentCurrenciesSettingsSagas;
