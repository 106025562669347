import React from 'react';

import { walletActionCreators } from 'actions/widgets/wallet';
import { WidgetType } from 'utils/enums';

import WalletModal from 'components/widgets/wallets/WalletModal';

const ActiveWalletModal = () => {
  return <WalletModal actionCreators={walletActionCreators} widget={WidgetType.WALLETS} />;
};

export default ActiveWalletModal;
