import { createSelector } from 'reselect';
import map from 'lodash/map';
import find from 'lodash/find';
import { IRootState, widgetListSelector, ISelectData } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { ICurrenciesItems } from 'interfaces/widgets/currency';

export const currencySelectionSelector = createSelector<IRootState, ICurrenciesItems[], ISelectData[]>(
  widgetListSelector(WidgetType.CURRENCIES),
  (items) => map(items, ({ code }) => ({ value: code, text: code }))
);

export const currencyWithIdSelectionSelector = createSelector<IRootState, ICurrenciesItems[], ISelectData[]>(
  widgetListSelector(WidgetType.CURRENCIES),
  (items) => map(items, ({ id, code }) => ({ value: id, text: code, code }))
);

export const currencySymbolSelector = (code: string) =>
  createSelector<IRootState, ICurrenciesItems[], string | undefined>(
    widgetListSelector(WidgetType.CURRENCIES),
    (items) => find(items, (item) => item.code === code)?.symbol
  );

export const currencyIdFromCodeSelector = (code: string) =>
  createSelector(widgetListSelector(WidgetType.CURRENCIES), (items) => {
    //@ts-ignore
    const arr: any[] = items || [];
    const fountItem = arr.find((item) => item.code === code) || {};

    return fountItem.id;
  });
