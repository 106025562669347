import { takeEvery, put } from 'redux-saga/effects';
import isObject from 'lodash/isObject';
import toNumber from 'lodash/toNumber';
import get from 'lodash/get';
import { toastr } from 'react-redux-toastr';
import { AxiosError } from 'axios';
import { validateForm, IWidgetsMeta, IAction } from '@kassma-team/kassma-toolkit/lib';

import { getErrorMessage } from 'utils';
import refreshSaga from 'sagas/effects/refreshSaga';
import {
  WALLET_PAYTM_CODE_CONFIRMATION,
  WALLET_PAYTM_RECOVERING_REQUESTED,
  WALLET_PAYTM_RESETTING_REQUESTED,
} from 'actionTypes';
import { paytmActionCreators } from 'actions/widgets/payments/paytm';
import { markWalletAsActiveSaga } from 'sagas/widgets/wallet';
import i18n from 'i18n';
import { PAYTM_PHONE_CONFIRM_FORM_NAME } from 'utils/constants';
import { ISetWalletSmsCodeData } from 'interfaces/widgets/paymentSystems';
import walletApi from 'api/wallet/WalletApi';
import cookieApi from 'api/cookie/CookieApi';
import { WalletType } from '../../../utils/enums';

interface IPaytmConfirmNumberProps {
  payload: ISetWalletSmsCodeData;
  meta: IWidgetsMeta;
}

function* paytmConfirmNumberSaga({ payload, meta }: IPaytmConfirmNumberProps) {
  if (!isObject(payload)) return null;

  const valid: boolean = yield validateForm({ form: PAYTM_PHONE_CONFIRM_FORM_NAME, meta });
  if (!valid) {
    return;
  }
  const data: ISetWalletSmsCodeData = {
    ...payload,
    sms_code: toNumber(payload.sms_code),
    wallet_type: WalletType.PAY_TM,
  };
  yield refreshSaga({
    request: () => walletApi.setSmsCode(data),
    onSuccess: function* () {
      yield put(paytmActionCreators.hideModal());
      toastr.success(i18n.t(`common.success`), i18n.t(`wallets.smsHasBeenSuccessfullySent`));
    },
    onError: (e: AxiosError) => {
      const payload = getErrorMessage(e, { normalize: true, defaultValue: i18n.t(`wallets.smsSendingHasBeenFailed`) });
      toastr.error(i18n.t(`common.error`), payload);
    },
  });
}

interface IPaytmResettingProps {
  payload: number;
}

function* paytmResettingSaga({ payload: identifier }: IPaytmResettingProps) {
  if (!identifier) return;
  yield refreshSaga({
    request: () => cookieApi.resetPaytmWallet(identifier),
    onSuccess: function* () {
      yield put(paytmActionCreators.hideModal());
      toastr.success(i18n.t(`common.success`), i18n.t(`wallets.walletDataHasBeenSuccessfullyReset`));
    },
    onError: (e: AxiosError) => {
      const payload = getErrorMessage(e, {
        normalize: true,
        defaultValue: i18n.t(`wallets.walletResettingHasBeenFailed`),
      });
      toastr.error(i18n.t(`common.error`), payload);
    },
  });
}

interface IPaytmRecoveringPayload {
  wallet_identifier: string;
  id: number;
}

interface IPaytmRecoveringProps {
  payload: IPaytmRecoveringPayload;
  meta: IWidgetsMeta;
}

function* paytmRecoveringSaga({ payload, meta }: IPaytmRecoveringProps) {
  const wallet_identifier = get(payload, `wallet_identifier`);
  const walletId = get(payload, `id`);
  if (!wallet_identifier) return;
  let errorHasBeenOccurred = false;
  yield refreshSaga({
    request: () => walletApi.setSmsCode({ wallet_identifier, sms_code: 12345 }),
    onError: (e: AxiosError) => {
      errorHasBeenOccurred = true;
      const payload = getErrorMessage(e, { normalize: true, defaultValue: i18n.t(`wallets.smsSendingHasBeenFailed`) });
      toastr.error(i18n.t(`common.error`), payload);
    },
  });
  if (!errorHasBeenOccurred) {
    yield markWalletAsActiveSaga({
      payload: walletId,
      meta: { ...meta, successToastrMessage: i18n.t(`wallets.walletHasBeenSuccessfullyRecovered`) },
    });
  }
}

const paytmSagas = [
  takeEvery<IAction>(WALLET_PAYTM_CODE_CONFIRMATION, paytmConfirmNumberSaga),
  takeEvery<IAction>(WALLET_PAYTM_RESETTING_REQUESTED, paytmResettingSaga),
  takeEvery<IAction>(WALLET_PAYTM_RECOVERING_REQUESTED, paytmRecoveringSaga),
];

export default paytmSagas;
