import { IWidgetsState } from '@kassma-team/kassma-toolkit/src/types/widgets';

const SET_ROLES_TO_USER = `SET_ROLES_TO_USER`;

type SetRolesToUserAction = {
  type: typeof SET_ROLES_TO_USER;
  payload: {
    id: string;
    roles: Array<{ id: number; name: string; is_global: boolean }>;
  };
  meta: {
    widget: `rbac-users`;
  };
};

const actionTypes = new Set([SET_ROLES_TO_USER]);

const reducer = (state: IWidgetsState, { type, payload }: SetRolesToUserAction) => {
  switch (type) {
    case SET_ROLES_TO_USER:
      return {
        ...state,
        items: state.items?.map((user) =>
          user.id === payload.id ? { ...user, roles: [...(user.roles ? user.roles : []), ...payload.roles] } : user
        ),
      };
    default:
      return state;
  }
};

const rbacUsers = { actionTypes, reducer };

export default rbacUsers;
