import React, { useEffect, useState } from 'react';
import { useFilterColumns, widgetSelector } from '@kassma-team/kassma-toolkit';
import { FilterWalletStatus, ModalType, Permission, WalletStatisticsColumns, WidgetType } from 'utils/enums';
import walletRouteTabs from './walletRouteTabs';
import { walletStatisticsActionCreators } from 'actions/widgets/wallet';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Widget from 'components/widgets/Widget';
import api from 'api';
import { WalletStatistic } from 'api/wallets/WalletsApi';
import WalletStatisticRow from 'components/widgets/wallets/WalletStatisticRow';
import StatisticModal from 'components/widgets/wallets/StatisticModal';
import { fetchWalletsStatistic } from 'actions/widgets/wallets';
import { useTranslation } from 'react-i18next';

const StatisticWallets = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  useEffect(() => {
    dispatch(fetchWalletsStatistic());
  }, []);

  const modals = [
    {
      type: ModalType.WALLETS_STATISTIC,
      component: StatisticModal,
      widget: WidgetType.WALLETS_STATISTIC,
    },
  ];

  const titles = [
    {
      title: t(`wallets.walletType`),
      id: WalletStatisticsColumns.WALLET_TYPE,
      desktop: true,
      // mobile: true,
      // disabled: true,
    },
    {
      title: t(`wallets.visibleWorkingAll`),
      id: WalletStatisticsColumns.VISIBLE_WORKING_ALL,
      desktop: true,
    },
    {
      title: t(`wallets.balanceOfVisibleMax`),
      id: WalletStatisticsColumns.BALANCE_OF_VISIBLE_MAX,
      desktop: true,
    },
    {
      title: t(`wallets.turnoverOfVisibleMax`),
      id: WalletStatisticsColumns.TURNOVER_OF_VISIBLE_MAX,
      desktop: true,
    },
    {
      title: t(`wallets.balanceOfAllMax`),
      id: WalletStatisticsColumns.BALANCE_OF_ALL_MAX,
      desktop: true,
    },
    {
      title: t(`wallets.turnoverOfAllMax`),
      id: WalletStatisticsColumns.TURNOVER_OF_ALL_MAX,
      desktop: true,
    },
    {
      title: t(`wallets.turnoverDailyTurnover`),
      id: WalletStatisticsColumns.TURNOVER_DAILY_TURNOVER,
      desktop: true,
    },
  ];

  const listMapping = (item: WalletStatistic) => {
    return <WalletStatisticRow key={item.wallet_type_name} item={item} />;
  };

  return (
    <Widget
      widget={WidgetType.WALLETS_STATISTIC}
      routeTabs={walletRouteTabs}
      actionCreators={walletStatisticsActionCreators}
      pageTitle="wallets.walletStatistic"
      tableTitle="wallets.walletStatistic"
      headTitle="wallets.walletStatistic"
      modals={modals}
      tableHeadTitles={titles}
      listMapping={listMapping}
    />
  );
};

export default StatisticWallets;
