import React from 'react';
import { IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import InfoModal from 'components/modals/InfoModal';

interface IProps {
  actionCreators: IWidgetActionCreators;
  widget: string;
}

const TransactionPostbackInfo = ({ actionCreators, widget }: IProps) => {
  return <InfoModal actionCreators={actionCreators} widget={widget} title="common.attention" />;
};

export default TransactionPostbackInfo;
