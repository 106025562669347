import { useEffect, useState } from 'react';
import { useUnmount } from 'react-use';
import { useDispatch } from 'react-redux';
import { destroy } from 'redux-form';

interface IProps {
  form: string;
}

const useWizardForm = ({ form }: IProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(destroy(form));
    };
  }, []);

  const [page, setPage] = useState<number>(1);

  return { page, setPage };
};

export default useWizardForm;
