import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { Dispatch } from 'redux';
import { ModalType, WidgetType } from 'utils/enums';
import { autoGenerateReport } from 'actions/widgets/reports';
import { AUTO_REPORT_GENERATING_FORM_NAME } from 'utils/constants';
import { IGenerateReportsForm } from 'interfaces/widgets/reports';
import ModalForm from 'components/modals/ModalForm';
import { autoReportsActionCreators } from 'actions/widgets/autoreports';
import reportValidation from 'utils/validation/reportValidation';
import { useReports } from 'hooks/widgets/report/useReports';
const onSubmit = (values: IGenerateReportsForm, dispatch: Dispatch) => dispatch(autoGenerateReport(values));

const AutoReportProcessing = (props: InjectedFormProps) => {
  const { modal, filteredFields, editedItems } = useReports(props, WidgetType.AUTOREPORTS);

  const loadingState = modal?.type === ModalType.UPDATE ? { loading: editedItems === null } : {};

  return (
    <ModalForm
      {...props}
      fields={filteredFields}
      submitText="reports.generateReport"
      onSubmit={onSubmit}
      title={
        modal?.type === ModalType.CREATE ? `reports.creatingAutogenerationTask` : `reports.updatingAutogenerationTask`
      }
      actionCreators={autoReportsActionCreators}
      widget={WidgetType.AUTOREPORTS}
      {...loadingState}
    />
  );
};

export default reduxForm({ form: AUTO_REPORT_GENERATING_FORM_NAME, onSubmit, validate: reportValidation })(
  AutoReportProcessing
);
