import React, { useCallback, useEffect, useState } from 'react';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import size from 'lodash/size';
import useMount from 'react-use/lib/useMount';
import useUnmount from 'react-use/lib/useUnmount';
import usePrevious from 'react-use/lib/usePrevious';

import {
  widgetModalSelector,
  widgetSelector,
  InputType,
  IModalFormProps,
  ISelectData,
} from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import {
  approvalOfWithdrawalsActionCreators,
  approveWithdrawalRequest,
  withdrawalsActionCreators,
} from 'actions/widgets/withdrawals';
import {
  withdrawalsCardsOfWalletOfApprovalSelector,
  withdrawalsWalletsSelectionOfApprovalSelector,
} from 'selectors/widgets/withdrawals';
import {
  IApproveWithdrawalFrom,
  IWithdrawalApprovalModal,
  IWithdrawalCreateDataFormField,
} from 'interfaces/widgets/withdrawals';

import ModalElement from 'components/modals/ModalElement';
import ModalForm from 'components/modals/ModalForm';

interface ISetSingleSelectValue {
  list: any[];
  fieldName: string;
  changeCallback: (name: string, value: string) => void;
  fulfillCondition: boolean;
  deps?: any[];
}

const useSetSingleSelectValue = ({
  list,
  fieldName,
  changeCallback,
  fulfillCondition = true,
  deps = [],
}: ISetSingleSelectValue) => {
  const prevList = usePrevious(list);

  useEffect(() => {
    if (!isEqual(prevList, list)) {
      const changeValue = fulfillCondition && size(list) === 1;
      const value = changeValue ? get(list, `[0].value`) : undefined;
      changeCallback(fieldName, value);
    }
  }, [list, fulfillCondition, ...deps]);
};

const ApprovalModal = (props: InjectedFormProps & IModalFormProps) => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const [fields, setFields] = useState<IWithdrawalCreateDataFormField[]>([]);

  const modal: IWithdrawalApprovalModal = useSelector(widgetModalSelector(WidgetType.WITHDRAWALS));
  const { id: withdrawalId, walletType, is_manually } = modal || {};

  const values: Partial<IApproveWithdrawalFrom> = useSelector(getFormValues(props.form)) || {};
  const { wallet_sender, isManualFill } = values;

  const wallets: ISelectData[] = useSelector(withdrawalsWalletsSelectionOfApprovalSelector);
  const cards = useSelector(withdrawalsCardsOfWalletOfApprovalSelector(wallet_sender as string));
  const { itemLoading: loading, itemError: error }: Record<string, string> =
    useSelector(widgetSelector(WidgetType.WITHDRAWALS_APPROVAL_DATA)) || {};

  useMount(() => {
    if (is_manually) {
      props.change(`isManualFill`, false);
    }
    dispatch(approvalOfWithdrawalsActionCreators.getItem(withdrawalId));
  });

  useUnmount(() => {
    dispatch(approvalOfWithdrawalsActionCreators.resetItem());
  });

  useSetSingleSelectValue({
    list: wallets,
    fieldName: `wallet_sender`,
    changeCallback: props.change,
    fulfillCondition: !isManualFill,
  });

  useSetSingleSelectValue({
    list: cards,
    fieldName: `card`,
    changeCallback: props.change,
    fulfillCondition: !isManualFill,
    deps: [wallet_sender],
  });

  const onSubmit = useCallback((values) => {
    dispatch(approveWithdrawalRequest(withdrawalId, walletType, values));
  }, []);

  useEffect(() => {
    const showToggle = is_manually && size(wallets) > 0;
    const showWalletsList = size(wallets) > 0 && !isManualFill;
    const showCards = showWalletsList && size(cards) > 0;
    const walletsLabel =
      isManualFill === false
        ? t(`withdrawals.walletNumberForWithdrawalWithAnnotation`)
        : t(`withdrawals.walletNumberForWithdrawal`);

    const newFields = [
      ...(showToggle
        ? [
            {
              name: `isManualFill`,
              type: InputType.CHECKBOX,
              label: t(`withdrawals.manualFill`),
            },
          ]
        : []),
      ...(showWalletsList
        ? [
            {
              name: `wallet_sender`,
              data: wallets,
              type: InputType.SELECT,
              required: true,
              label: walletsLabel,
              disabled: size(wallets) === 1,
            },
          ]
        : [
            {
              name: `wallet_sender`,
              required: true,
              maxLength: 20,
              label: t(`withdrawals.walletNumberForWithdrawal`),
            },
          ]),
      ...(showCards
        ? [
            {
              name: `card`,
              data: cards,
              type: InputType.SELECT,
              required: true,
              label: t(`withdrawals.cardForWithdrawal`),
              disabled: size(cards) === 1,
            },
          ]
        : []),
      {
        name: `comment`,
        isModalField: true,
        label: t(`common.comment`),
        type: InputType.TEXT_EDITOR,
        forcedShowingError: true,
        maxLength: 100,
      },
    ];

    if (!isEqual(fields, newFields)) {
      setFields(newFields);
    }
  }, [wallets, cards, values]);

  let postContent = null;
  if (isManualFill === false && !error) {
    postContent = <div className="font-italic">{t(`withdrawals.turnOnManualFill`)}</div>;
  }

  let preContent = null;
  if (error) {
    if (is_manually) {
      preContent = <div className="text-error mb-10 text-center">{error}</div>;
    } else {
      return (
        <ModalElement
          actionCreators={withdrawalsActionCreators}
          title="withdrawals.withdrawalApproval"
          content={<div className="text-error mb-10 text-center">{error}</div>}
        />
      );
    }
  }

  return (
    <ModalForm
      {...props}
      fields={fields}
      submitText="common.approve"
      formName={props.form}
      onSubmit={onSubmit}
      loading={loading}
      title="withdrawals.withdrawalApproval"
      actionCreators={withdrawalsActionCreators}
      widget={WidgetType.WITHDRAWALS}
      preContent={preContent}
      postContent={postContent}
    />
  );
};

export default reduxForm({
  form: `approve-withdrawal-form`,
})(ApprovalModal);
