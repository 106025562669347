import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import useMount from 'react-use/lib/useMount';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import { widgetModalSelector, widgetItemSelector, IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { IFieldProps } from 'interfaces/form';

interface IProps<IPrepareValues, IPrepareValuesReturn> {
  widget: string;
  getFields?: (data: Record<string, unknown>) => IFieldProps[];
  actionCreators: IWidgetActionCreators;
  initialize: (data: Record<string, unknown>) => void;
  itemAdapter: (item: Record<string, any>) => Record<string, any>;
  setFromList?: boolean;
  prepareValues?: (values: IPrepareValues) => IPrepareValuesReturn;
  itemModalKey?: string;
  onError?: (err?: Error) => void;
  onSuccess?: (resp: any) => void;
}

const useUpdateWidgetForm = <IPrepareValues = Record<string, any>, IPrepareValuesReturn = Record<string, any>>({
  widget,
  getFields,
  actionCreators,
  initialize,
  itemAdapter,
  setFromList = false,
  prepareValues,
  itemModalKey = `id`,
  onError,
  onSuccess,
}: IProps<IPrepareValues, IPrepareValuesReturn>) => {
  const { getItem, update, setItem } = actionCreators;

  const [inited, setInited] = useState(false);

  const item: any = useSelector(widgetItemSelector(widget));
  const modal = useSelector(widgetModalSelector(widget));
  const dispatch = useDispatch();

  const modalKey = get(modal, itemModalKey);

  const [fields, setFields] = useState<IFieldProps[]>([]);

  useMount(() => {
    if (modalKey) {
      if (setFromList) {
        dispatch(setItem(modalKey));
      } else if (getItem) {
        dispatch(getItem(modalKey));
      }
      if (isFunction(getFields)) {
        setFields(getFields(item));
      }
    }
  });

  useUpdateEffect(() => {
    if (isFunction(getFields)) {
      setFields(getFields(item));
    }
  }, [getFields]);

  useUpdateEffect(() => {
    if (item) {
      initialize(itemAdapter(item));
      setTimeout(() => setInited(true), 100);
    }
  }, [item]);

  const preparedValues = prepareValues
    ? (values: IPrepareValues) => ({ ...prepareValues(values) })
    : (values: Record<string, any>) => values;

  const onSubmit = (values: IPrepareValues) =>
    dispatch(
      update(
        {
          id: get(modal, `id`),
          ...preparedValues(values),
        },
        {
          onError,
          onSuccess,
        }
      )
    );

  const submitting = get(modal, `submitting`) || false;

  return { onSubmit, fields, submitting, item, formHasInited: inited };
};

export default useUpdateWidgetForm;
