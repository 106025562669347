import { createSelector } from 'reselect';
import map from 'lodash/map';
import { widgetListSelector, IRootState } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { ILangList } from 'interfaces/widgets/constructor/langList';

export const langListSelector = createSelector<IRootState, string[] | null, ILangList[] | null>(
  widgetListSelector(WidgetType.PAYMENT_HINT_LANGS),
  (list) => map(list, (item) => ({ value: item, text: item }))
);
