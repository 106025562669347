import { useFilterFormFields as useKassmaFilterFormFields, IFormField } from '@kassma-team/kassma-toolkit/lib';
import { useDispatch } from 'react-redux';

// Todo: check this method after updating branch

interface IProps {
  fields: IFormField[];
  formName: string;
}

const useFilterFormFields = ({ fields, formName }: IProps) => {
  const dispatch = useDispatch();

  return useKassmaFilterFormFields({
    fields,
    formName,
    dispatch,
  });
};

export default useFilterFormFields;
