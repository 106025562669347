import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WalletImg, useFilterColumns } from '@kassma-team/kassma-toolkit/lib';

import { withdrawalsLogsActionCreators } from 'actions/widgets/walletsDebugging/withdrawalsLogs';
import { ModalType, WithdrawalsLogsColumn } from 'utils/enums';
import { formatDate } from 'utils/formatDate';
import { IWithdrawalsLogRowProps } from 'interfaces/widgets/withdrawals';
import { paymentSystemLogoSelector } from '../../../../selectors/widgets/paymentSystems';

const WithdrawalsLogRow = ({
  desktopSize,
  message,
  created_at,
  wallet_type,
  hiddenColumns,
}: IWithdrawalsLogRowProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const formattedCreatedAt = formatDate(created_at);

  const messageField = (
    <button
      className="button-link"
      onClick={() =>
        dispatch(
          withdrawalsLogsActionCreators.showModal({
            type: ModalType.LOG_MESSAGE,
            message,
          })
        )
      }
    >
      {message.substr(0, 255)}
    </button>
  );

  const logo = useSelector(paymentSystemLogoSelector(wallet_type));
  const walletTypeField = <WalletImg title={wallet_type} src={logo} />;

  const columns = [
    {
      id: WithdrawalsLogsColumn.WALLET_TYPE,
      desktop: <td>{walletTypeField}</td>,
      mobile: (
        <tr className="first-mobile-row">
          <td>{t(`wallets.walletType`)}</td>
          <td>{walletTypeField}</td>
        </tr>
      ),
    },
    {
      id: WithdrawalsLogsColumn.MESSAGE,
      desktop: <td>{messageField}</td>,
      mobile: (
        <tr>
          <td>{t(`logs.message`)}</td>
          <td>{messageField}</td>
        </tr>
      ),
    },
    {
      id: WithdrawalsLogsColumn.DATE,
      desktop: <td className="logs__cell--date">{formattedCreatedAt}</td>,
      mobile: (
        <tr>
          <td>{t(`common.date`)}</td>
          <td className="logs__cell--date">{formattedCreatedAt}</td>
        </tr>
      ),
    },
  ];

  return useFilterColumns({ columns, desktopSize, hiddenColumns });
};

export default WithdrawalsLogRow;
