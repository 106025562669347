import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  paykassmaForReports,
  paymentCurrenciesSelector,
  PSWithoutSettlementsSelector,
} from 'selectors/widgets/paymentSystems';
import { selectionOfAllManualPSSelector } from 'selectors/widgets/manualPS';
import {
  currencyIdFromCodeSelector,
  currencySelectionSelector,
  currencyWithIdSelectionSelector,
} from 'selectors/widgets/currency';
import { getFormValues } from 'redux-form';
import { useEffect, useMemo, useRef } from 'react';
import { getWalletTypeByReportType } from 'utils/widgets/reports/getWalletTypeByReportType';
import { hasAccessSelector, userPermissionsSelector } from 'selectors/auth';
import { WalletType } from 'api/WalletTypes';
import {
  ArchivingStatus,
  ModalType,
  Permission,
  PostbackStatus,
  ReportFileFormat,
  ReportType,
  TransactionDirection,
  TransactionField,
  TransactionKind,
  TransactionStatus,
  WidgetType,
  WithdrawalStatus,
} from 'utils/enums';
import useWalletListByType from '../useWalletListByType';
import { getListReportFieldList } from 'utils/widgets/reports/getListReportFieldList';
import { getReportTypeSelectionDataByPermissions } from 'utils/widgets/reports/getReportTypeSelectionData';
import { useGetReportTypeFields } from 'utils/widgets/reports/getReportTypeFields';
import {
  InputType,
  widgetListSelector,
  widgetItemSelector,
  widgetModalSelector,
} from '@kassma-team/kassma-toolkit/lib';
import useFilterFormFields from '../useFilterFormFields';
import useList from '../useList';
import { manualPSActionCreators, withdrawalPSActionCreators } from 'actions/widgets/paymentSystem';
import { IWithdrawalStatusListItems } from 'interfaces/widgets/withdrawals';
import { withdrawalStatusActionCreators } from 'actions/widgets/withdrawals';
import useForGeneratingAutoreportList from '../autoreport/useForGeneratingAutoreportList';
import { getAutoReportTypeSelectionDataByPermissions } from 'utils/widgets/autoreports/getAutoReportTypeSelectionDataByPermissions';
import { autoReportsActionCreators } from 'actions/widgets/autoreports';
import moment from 'moment';
import { parseWalletList } from 'utils/widgets/wallets/parseWalletList';
import { getListCompletedReportFieldList } from '../../../utils/widgets/reports/getListCompletedReportFieldList';
import { walletCurrencySelector } from '../../../selectors/widgets/wallets';
import { accountBalanceHistoryList } from 'actions/widgets/balances/accountsBalances';
import { BALANCE_HISTORY_LIST_REQUESTED } from 'actionTypes';
import { IAccountBalancesItems } from 'interfaces/widgets/balances/balances';
import { filterBalanceGroups } from 'pages/balances/accountsBalance/utils';

interface IPreparingDataForFieldsPopulation {
  fields: {
    type?: string;
    сreation_type?: string;
    status?: number;
    wallet_id?: number;
    country_id?: string;
    direction?: string;
  };
  filters: {
    label?: string;
    type?: string;
    сreation_type?: string;
    status?: number;
    wallet_id?: number;
    country_id?: string;
    direction?: string;
  };
  currency_id: number;
  reporting_period: string;
  emails: string[];
  start: string;
  utc: string;
  timezone: boolean;
  report_type: string;
}

const preparingDataForFieldsPopulation = (
  item: IPreparingDataForFieldsPopulation,
  modalType: typeof ModalType[keyof typeof ModalType]
) => {
  if (item && modalType) {
    const { emails, start, timezone, utc, report_type } = item || {};
    const utc_0 = timezone; //utc === `UTC`;
    const mappedEmails = emails ? { pre_emails: emails.map((item: string) => ({ autoreport_email: item })) } : {};
    const parsedDateString = moment(start, `DD.MM.YYYY HH:mm:ss`).toDate();

    return modalType && modalType === ModalType.UPDATE
      ? { ...item, utc_0, report_type, mappedEmails, parsedDateString }
      : {};
  }

  return {};
};

interface IDataForFieldsPopulation {
  reporting_period?: string;
  utc_0?: boolean;
  currency_code?: string;
  file_format?: string;
  report_type?: string;
  filters?: {
    only_archived?: boolean;
    detail_by_day: boolean;
    label: string;
    exchanger_identifier: string;
    direction: string;
    archived?: string;
    wallet_type?: string;
    originality?: boolean;
    wallet_id?: string;
  };
  fields?: {
    label: string;
    exchanger_identifier: string;
    direction: string;
    archived?: string;
    only_archived?: boolean;
    wallet_type?: string;
    originality?: boolean;
  };
  mappedEmails?: { email: string }[];
  reportingPeriod?: { reporting_period: string }[];
  parsedDateString?: Date;
}

export const useReports = (props: any, widgetType: string) => {
  const postbackViewPermission = useSelector(hasAccessSelector(Permission.POSTBACKS));
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const renderAutoreport = useForGeneratingAutoreportList();
  const modal: { type: number } = useSelector(widgetModalSelector(widgetType));
  const isModalTypeUpdate = modal?.type === ModalType.UPDATE && widgetType === WidgetType.AUTOREPORTS;

  const form = props?.form;
  const transactionsPSList = useSelector(PSWithoutSettlementsSelector);

  const safeTransactionsPSList = transactionsPSList?.map((el) => ({
    ...el,
    text: el.name,
    value: el.code,
  }));

  const testPaymentSystems = useSelector(selectionOfAllManualPSSelector);

  const safeTestPaymentSystems = testPaymentSystems?.map((el) => ({
    ...el,
    text: el.name,
    value: el.code,
  }));

  // const countries = useSelector(countriesSelectionSelector);
  const currencyWithIdList = useSelector(currencyWithIdSelectionSelector);
  const currencyList = useSelector(currencySelectionSelector);

  const values: any = useSelector(getFormValues(form));

  const editedItems: IPreparingDataForFieldsPopulation = useSelector(widgetItemSelector(WidgetType.AUTOREPORTS));

  const dataForFieldsPopulation: IDataForFieldsPopulation = useMemo(
    () => preparingDataForFieldsPopulation(editedItems, modal?.type),
    [editedItems]
  );
  const {
    utc_0 = false,
    currency_code,
    file_format,
    report_type,
    filters,
    fields: editedFields,
    mappedEmails,
    parsedDateString,
    reporting_period,
  } = dataForFieldsPopulation;

  const { getItem } = autoReportsActionCreators;
  const modalID: { id?: number } = useSelector(widgetModalSelector(WidgetType.AUTOREPORTS));

  let reportType = values?.report_type;

  if (report_type) {
    reportType = report_type;
    delete dataForFieldsPopulation?.report_type;
  }

  const operation_type = values?.operation_type;

  const walletType = useMemo(() => {
    return getWalletTypeByReportType(values, reportType);
  }, [values]);

  const directionValue = values?.filters?.direction;

  const currenciesSelector = useMemo(() => paymentCurrenciesSelector(walletType), [walletType]);
  const walletCurrencies = useSelector(currenciesSelector);

  const permissions = useSelector(userPermissionsSelector) || [];

  const commissionViewPermission = useSelector(hasAccessSelector(Permission.COMMISSION_VIEW));
  const internalPermission = useSelector(hasAccessSelector(Permission.TRANSACTION_INNER));
  const archivedTransactionPermission = useSelector(hasAccessSelector(Permission.ARCHIVE_TRANSACTION_VIEW));
  const vendorCodePermission = useSelector(hasAccessSelector(Permission.WALLET_PROVIDER_CODE));

  const { walletsSelection } = useWalletListByType(walletType);

  useEffect(() => {
    dispatch(accountBalanceHistoryList({}));
  }, []);

  const balancesGroupedByPS: IAccountBalancesItems[] = useSelector(widgetListSelector(WidgetType.ACCOUNTS_BALANCES));
  const filteredBalancesGroupedByPS = filterBalanceGroups(balancesGroupedByPS, true);

  const currenciesWithIdSelectionData = useMemo(
    () =>
      (walletType
        ? walletCurrencies.map((currencyListItem) =>
            currencyWithIdList.find((currencyIdItem) => currencyListItem.text === currencyIdItem.text)
          )
        : currencyWithIdList
      ).map((item) => ({ ...item, value: item.code })),
    [walletType, walletCurrencies, currencyWithIdList]
  );

  const filterWalletId = values?.filters?.wallet_id;
  const walletCurrency = useSelector(walletCurrencySelector(filterWalletId?.toString() || ``));
  const currencyId = useSelector(currencyIdFromCodeSelector(walletCurrency));
  useEffect(() => {
    if (reportType === ReportType.COMPLETED_TRANSACTION_SUM && walletCurrency) {
      props.change(`report_currency_id`, walletCurrency.code);
    }
  }, [reportType, walletCurrency]);

  const report_currency_id = values?.report_currency_id;
  const reportCurrencyCode = useSelector(currencyIdFromCodeSelector(report_currency_id));
  useEffect(() => {
    if (reportType === ReportType.COMPLETED_TRANSACTION_SUM && reportCurrencyCode) {
      props.change(`currency_id`, reportCurrencyCode);
    }
  }, [reportType, reportCurrencyCode]);

  const currenciesSelectionData = useMemo(() => {
    if (reportType === ReportType.COMPLETED_TRANSACTION_SUM && walletType && filterWalletId) {
      return [
        {
          text: walletCurrency.code,
          value: walletCurrency.code,
        },
      ];
    }

    if (reportType === ReportType.COMPLETED_TRANSACTION_SUM && !walletType) {
      return [...currencyList];
    }

    if (walletType) {
      return [
        {
          value: undefined,
          text: t(`common.all`),
        },
        ...walletCurrencies,
      ];
    } else {
      return [
        {
          value: undefined,
          text: t(`common.all`),
        },
        ...currencyList,
      ];
    }

    // if (reportType === ReportType.COMPLETED_TRANSACTION_SUM && walletType && filterWalletId) {
    //   return [
    //     {
    //       text: walletCurrency,
    //       value: walletCurrency,
    //     },
    //   ];
    // } else {
    //   return walletType
    //     ? [
    //         {
    //           value: undefined,
    //           text: t(`common.all`),
    //         },
    //         ...walletCurrencies,
    //       ]
    //     : [
    //         {
    //           value: undefined,
    //           text: t(`common.all`),
    //         },
    //         ...currencyList,
    //       ];
    // }
  }, [walletType, walletCurrencies, currencyList, reportType, filterWalletId, walletCurrency]);

  const listReportFieldList = useMemo(
    () => getListReportFieldList(t, commissionViewPermission, vendorCodePermission, archivedTransactionPermission),
    [t, commissionViewPermission, archivedTransactionPermission]
  );

  const completedListReportFieldList = useMemo(() => getListCompletedReportFieldList(t, vendorCodePermission), [
    t,
    vendorCodePermission,
  ]);

  const checkedCompletedListReportFields = useMemo(
    () =>
      completedListReportFieldList
        .filter(({ value }) => value !== TransactionField.ARCHIVING_DATES)
        .map(({ value }) => value),
    [completedListReportFieldList]
  );

  const checkedListReportFields = useMemo(() => listReportFieldList.map(({ value }) => value), [listReportFieldList]);

  const sumReportFieldList = useMemo(
    () =>
      commissionViewPermission
        ? [
            {
              value: TransactionField.COMMISSION,
              text: t(`reports.commission`),
            },
          ]
        : [],
    [t]
  );

  const checkedSumReportFields = useMemo(() => sumReportFieldList.map(({ value }) => value), [sumReportFieldList]);

  const fieldList = useMemo(() => {
    switch (reportType) {
      case ReportType.SUM_REPORT:
      case ReportType.COMPLETED_TRANSACTION_SUM:
      case ReportType.TRANSACTIONS_SUM_DETAIL:
        return sumReportFieldList;
      case ReportType.LIST_REPORT_WITH_COMPLETED_STOCKPILING:
        return completedListReportFieldList;
      default:
        return listReportFieldList;
    }
  }, [reportType]);

  /**
   * Because of the implicit conversion of the array into object, it is necessary to reconvert the object into array.
   * The required data for our checkboxes are coming either in filters field or in fields
   * */
  const filteredFiltersList = isModalTypeUpdate && Array.isArray(filters) && filters;
  let filteredFieldList = isModalTypeUpdate && Array.isArray(editedItems?.fields) && editedItems?.fields;
  if (!filteredFieldList && isModalTypeUpdate && !filteredFiltersList)
    filteredFieldList = Object.values(editedItems?.fields || {});
  //@ts-ignore
  if (!filteredFieldList && isModalTypeUpdate && filteredFiltersList) filteredFieldList = filteredFiltersList;
  if (isModalTypeUpdate && editedItems?.report_type !== values?.report_type) filteredFieldList = false;

  const { items: withdrawalsPSList } = useList<WalletType>({
    widget: WidgetType.WITHDRAWALS_PAYMENT_SYSTEMS,
    actionCreators: withdrawalPSActionCreators,
  });

  const safeWithdrawalsPSListSelection = withdrawalsPSList?.map((el) => ({
    ...el,
    value: el.code,
    text: el.name,
  }));

  const withdrawalsPSListSelection: { value: string | undefined; text: string }[] = useMemo(
    () => [
      {
        value: undefined,
        text: t(`common.all`),
      },
      ...(safeWithdrawalsPSListSelection || []).map(({ value }: Record<`value`, string>) => ({ value, text: value })),
    ],
    [safeWithdrawalsPSListSelection]
  );

  const { items: statusList } = useList<IWithdrawalStatusListItems>({
    widget: WidgetType.WITHDRAWALS_STATUS,
    actionCreators: withdrawalStatusActionCreators,
  });

  type WrongItems = Array<IWithdrawalStatusListItems> | Record<string, IWithdrawalStatusListItems> | null;

  const getItems = (statusList: WrongItems): Array<IWithdrawalStatusListItems> => {
    const isNeedToSave = (statusList: WrongItems) => typeof statusList === `object` && statusList !== null;
    const getSaveItems = (statusList: Record<string, IWithdrawalStatusListItems>) =>
      Object.keys(statusList).map((key) => statusList[key]);

    if (!statusList) return [];

    if (isNeedToSave(statusList)) {
      return getSaveItems(statusList as Record<string, IWithdrawalStatusListItems>);
    }

    return statusList as Array<IWithdrawalStatusListItems>;
  };

  const withdrawalStatusSelectionList: any[] = useMemo(
    () => [
      {
        text: t(`common.all`),
        value: undefined,
      },
      ...getItems(statusList).map(({ name, status }) => ({ text: name, value: status })),
    ],
    [statusList]
  );

  const reportTypeSelectionData = useMemo(
    () =>
      widgetType === WidgetType.AUTOREPORTS
        ? getAutoReportTypeSelectionDataByPermissions(permissions)
        : getReportTypeSelectionDataByPermissions(permissions, postbackViewPermission),
    [permissions, values?.widget]
  );

  const reportMapping = {
    [Permission.TRANSACTION_REPORTS]: ReportType.SUM_REPORT,
    [Permission.LABEL_HISTORY_REPORT]: ReportType.LABEL_HISTORY_REPORT,
    [Permission.CLIENT_BALANCE_HISTORY_REPORT]: ReportType.CLIENT_ACCOUNT_BALANCE_CHANGE,
    [Permission.WITHDRAWAL_REQUEST_REPORT]: ReportType.WITHDRAWAL_REQUEST_REPORT,
  };

  useEffect(() => {
    dispatch(manualPSActionCreators.getList());
    let type = ReportType.COMMISSION_REPORT;
    for (const permission in reportMapping) {
      if (permissions.includes(permission)) {
        type = reportMapping[permission];
        break;
      }
    }
    props.change(`report_type`, type);
  }, []);

  useEffect(() => {
    if (modalID?.id && getItem) {
      dispatch(getItem(modalID?.id));
    }
  }, []);

  /**
   * Storing the first not undefined value on multiple updates and unmounts that is received from back.
   * Deleting it so that it does not affect the result of manual input
   * */
  const idRef = useRef(currency_code);

  if (currency_code) {
    idRef.current = currency_code;
    delete dataForFieldsPopulation?.currency_code;
  }
  const walletRef = useRef(filters?.wallet_type);
  if (filters?.wallet_type || editedFields?.wallet_type) {
    walletRef.current = filters?.wallet_type || editedFields?.wallet_type;
    delete dataForFieldsPopulation?.filters?.wallet_type;
    delete dataForFieldsPopulation?.fields?.wallet_type;
  }

  /**
   * Finding the particular currency id from the list that relates to the current Wallet
   * */
  const currencyID = currencyWithIdList.find((walletCurrencyItem) => walletCurrencyItem?.code === idRef.current);

  const filteredCurrencyId = currenciesWithIdSelectionData.find(
    (currenciesWithIdSelection) => currenciesWithIdSelection?.value === currencyID?.code
  );

  /**
   * Evaluating the currency id from the back to undefined for resetting the Currency field for the proper manual inputs
   * */
  if (!filteredCurrencyId) {
    idRef.current = undefined;
  }

  const walletsSelectionData = parseWalletList(walletsSelection, {
    displayId: true,
    emptyText: t(`common.all`),
  });

  const wallet_id = useMemo(
    () => (isModalTypeUpdate ? editedItems?.fields?.wallet_id || editedItems?.filters?.wallet_id : undefined),
    [editedItems?.fields?.wallet_id, editedItems?.filters?.wallet_id]
  );

  const walletID = useMemo(
    () => (isModalTypeUpdate ? walletsSelectionData?.find((elem) => elem?.value === wallet_id) : undefined),
    [walletsSelectionData?.length, wallet_id]
  );

  const direction = useMemo(
    () => (isModalTypeUpdate ? editedItems?.fields?.direction || editedItems?.filters?.direction : undefined),
    [editedItems?.fields?.direction, editedItems?.filters?.direction]
  );

  const country_id = useMemo(
    () => (isModalTypeUpdate ? editedItems?.fields?.country_id || editedItems?.filters?.country_id : undefined),
    [editedItems?.fields?.country_id, editedItems?.filters?.country_id]
  );

  const exchanger_identifier = useMemo(
    () => (isModalTypeUpdate ? editedFields?.exchanger_identifier || filters?.exchanger_identifier : undefined),
    [editedFields?.exchanger_identifier, filters?.exchanger_identifier]
  );

  const originality = useMemo(
    () =>
      isModalTypeUpdate
        ? editedFields?.originality === undefined
          ? filters?.originality === undefined
            ? undefined
            : filters?.originality
          : editedFields?.originality
        : undefined,
    [editedFields?.originality, filters?.originality]
  );

  const status = useMemo(
    () =>
      !isModalTypeUpdate
        ? undefined
        : editedItems?.fields?.status === TransactionStatus.NOT_ACTIVATED ||
          editedItems?.filters?.status === TransactionStatus.NOT_ACTIVATED
        ? TransactionStatus.NOT_ACTIVATED
        : editedItems?.fields?.status || editedItems?.filters?.status
        ? TransactionStatus.ACTIVATED
        : null,
    [editedItems?.fields?.status, editedItems?.filters?.status]
  );

  const сreation_type = useMemo(
    () => (isModalTypeUpdate ? editedItems?.fields?.сreation_type || editedItems?.filters?.сreation_type : undefined),
    [editedItems?.fields?.сreation_type, editedItems?.filters?.сreation_type, modal?.type]
  );

  const label = isModalTypeUpdate ? editedFields?.label || editedItems?.filters?.label : undefined;
  let type = isModalTypeUpdate ? editedItems?.fields?.type || editedItems?.filters?.type : undefined;
  if (type?.length === 5) type = undefined;

  /**
   * It is required to assign to the only_archived field a boolean value
   * */
  let onlyArchived: boolean | string | undefined = filters?.archived || editedFields?.archived;
  onlyArchived = onlyArchived === `onlyArchived`;

  useEffect(() => {
    if (reportType) {
      switch (reportType) {
        case ReportType.SUM_REPORT:
          props.initialize({
            report_type: ReportType.SUM_REPORT,
            filters: {
              detail_by_day: filters ? filters.detail_by_day : false,
              wallet_type: walletType || walletRef.current,
              wallet_id: walletID?.value,
              direction,
              exchanger_identifier,
              label,
              сreation_type,
              originality,
              status,
              type,
            },
            pre_originality: originality,
            pre_type: type,
            pre_creation_type: сreation_type,
            pre_country_id: country_id,
            pre_status: status,
            pre_wallet_id: wallet_id,
            pre_direction: direction,
            report_currency_id: filteredCurrencyId && currencyID?.text,
            currency_id: currencyID?.code,
            report_currency_code: currencyID?.code,
            fields: filteredFieldList || checkedSumReportFields,
            file_format: file_format || ReportFileFormat.XLSX,
            utc_0,
            ...mappedEmails,
            reporting_period,
            pre_start: values?.pre_start || parsedDateString,
            widget: widgetType,
          });
          break;
        case ReportType.LIST_REPORT:
          props.initialize({
            report_type: ReportType.LIST_REPORT,
            filters: {
              wallet_type: walletType || walletRef.current,
              wallet_id: walletID?.value,
              direction: direction,
              сreation_type,
              originality,
              type,
              status,
              label,
              exchanger_identifier: editedFields?.exchanger_identifier || filters?.exchanger_identifier,
            },
            pre_originality: originality,
            pre_type: type,
            pre_creation_type: сreation_type,
            pre_country_id: country_id,
            pre_status: status,
            pre_wallet_id: wallet_id,
            pre_direction: direction,
            report_currency_id: filteredCurrencyId && currencyID?.text,
            archiving_status: ArchivingStatus.ALL,
            only_archived: onlyArchived,
            currency_id: currencyID?.code,
            report_currency_code: currencyID?.code,
            fields: filteredFieldList || checkedListReportFields,
            file_format: file_format || ReportFileFormat.XLSX,
            utc_0,
            ...mappedEmails,
            reporting_period,
            pre_start: values?.pre_start || parsedDateString,
            widget: widgetType,
          });
          break;
        case ReportType.TRANSACTIONS_SUM_DETAIL:
          props.initialize({
            report_type: ReportType.TRANSACTIONS_SUM_DETAIL,
            filters: {
              wallet_type: walletType || walletRef.current,
              detail_by_day: filters ? filters.detail_by_day : false,
              wallet_id: walletID?.value,
              direction,
              exchanger_identifier: editedFields?.exchanger_identifier || filters?.exchanger_identifier,
              label,
              originality,
              сreation_type,
              status,
              type,
            },
            pre_originality: originality,
            pre_type: type,
            pre_creation_type: сreation_type,
            pre_country_id: country_id,
            pre_status: status,
            pre_wallet_id: wallet_id,
            pre_direction: direction,
            report_currency_id: filteredCurrencyId && currencyID?.text,
            currency_id: currencyID?.code,
            report_currency_code: currencyID?.code,
            fields: filteredFieldList || checkedSumReportFields,
            file_format: file_format || ReportFileFormat.XLSX,
            utc_0,
            ...mappedEmails,
            reporting_period,
            pre_start: values?.pre_start || parsedDateString,
            widget: widgetType,
          });
          break;
        case ReportType.CLIENT_ACCOUNT_BALANCE_CHANGE:
          props.initialize({
            report_type: ReportType.CLIENT_ACCOUNT_BALANCE_CHANGE,
            file_format: ReportFileFormat.XLSX,
          });
          break;
        case ReportType.COMPLETED_TRANSACTION_SUM:
          props.initialize({
            report_type: ReportType.COMPLETED_TRANSACTION_SUM,
            fields: filteredFieldList || checkedSumReportFields,
            file_format: file_format || ReportFileFormat.XLSX,
            utc_0,
          });
          break;
        case ReportType.LIST_REPORT_WITH_COMPLETED_STOCKPILING:
          props.initialize({
            report_type: ReportType.LIST_REPORT_WITH_COMPLETED_STOCKPILING,
            filters: {
              archiving_status: ArchivingStatus.WITHOUT_ARCHIVED,
            },
            utc_0,
            file_format: ReportFileFormat.XLSX,
            fields: checkedCompletedListReportFields,
          });
          break;
        default:
          props.initialize({
            report_type: reportType,
            file_format: ReportFileFormat.XLSX,
          });
      }
    }
  }, [
    reportType,
    dataForFieldsPopulation,
    report_type,
    filteredCurrencyId?.text,
    filters?.wallet_type,
    walletID?.value,
  ]);

  useEffect(() => {
    props.change(`report_currency_id`, undefined);
    props.change(`filters.wallet_id`, undefined);
  }, [walletType]);

  const withdrawalStatus = values?.status;

  const shouldPostbackStatusBeFixed = useMemo(() => {
    return (
      reportType &&
      (withdrawalStatus === WithdrawalStatus.NEW ||
        withdrawalStatus === WithdrawalStatus.IN_PROGRESS ||
        withdrawalStatus === WithdrawalStatus.CHECK ||
        withdrawalStatus === WithdrawalStatus.REJECTED ||
        withdrawalStatus === WithdrawalStatus.CANCELED ||
        withdrawalStatus === WithdrawalStatus.ADD_BENEFICIARE ||
        withdrawalStatus === WithdrawalStatus.ERROR)
    );
  }, [reportType, withdrawalStatus]);

  useEffect(() => {
    if (shouldPostbackStatusBeFixed) {
      props.change(`postback_status`, PostbackStatus.POSTBACK_NOT_SEND);
    }
  }, [shouldPostbackStatusBeFixed]);

  const activationDate = values?.date_range_of_activation;
  const isActivationDate = !!activationDate;

  useEffect(() => {
    if (activationDate && reportType === ReportType.SUM_REPORT) {
      props.change(`filters.status`, TransactionStatus.ACTIVATED);
    }
  }, [isActivationDate, reportType]);

  const unFilteredtransactionKindItems = [
    {
      value: TransactionKind.REAL.toString(),
      text: `transactions.real`,
    },
    !(
      (reportType === ReportType.SUM_REPORT || reportType === ReportType.TRANSACTIONS_SUM_DETAIL) &&
      directionValue === TransactionDirection.OUTGOING
    ) && {
      value: TransactionKind.FORCED.toString(),
      text: `transactions.forced`,
    },
    directionValue !== TransactionDirection.INGOING &&
      reportType === ReportType.LIST_REPORT &&
      internalPermission && {
        value: TransactionKind.INNER.toString(),
        text: `transactions.inner`,
      },
    !(
      (reportType === ReportType.SUM_REPORT || reportType === ReportType.TRANSACTIONS_SUM_DETAIL) &&
      directionValue === TransactionDirection.OUTGOING
    ) && {
      value: TransactionKind.DEBUG.toString(),
      text: `transactions.fake`,
    },
  ];

  const transactionKindItems: any = useMemo(() => {
    return unFilteredtransactionKindItems.filter((elem) => elem);
  }, [internalPermission, reportType, directionValue]);

  const transactionKind = useMemo(() => {
    return (values?.filters?.type || []).map((val: string) => +val);
  }, [values]);

  const transactionStatus = values && values.filters && values.filters.status;
  const archivingStatus = values && values.filters && values.filters.archiving_status;

  const transactionPrivateInformationViewPermission = useSelector(
    hasAccessSelector(Permission.TRANSACTION_PRIVATE_INFORMATION_VIEW)
  );
  const statusData = useMemo(() => {
    const data = [
      {
        value: null,
        text: `common.all`,
      },
      {
        value: TransactionStatus.ACTIVATED,
        text: `reports.activated`,
      },
    ];
    if (transactionPrivateInformationViewPermission) {
      data.push({
        value: TransactionStatus.NOT_ACTIVATED,
        text: `reports.notActivated`,
      });
    }

    return data;
  }, [transactionPrivateInformationViewPermission]);

  useEffect(() => {
    if (
      !transactionPrivateInformationViewPermission &&
      [ReportType.SUM_REPORT, ReportType.LIST_REPORT].includes(reportType)
    ) {
      props.change(`filters.status`, TransactionStatus.ACTIVATED);
    }
  }, [transactionPrivateInformationViewPermission, reportType]);

  useEffect(() => {
    props.change(`filters.type`, undefined);
  }, [transactionKindItems.length]);

  const reportTypeFields = useGetReportTypeFields({
    type: reportType,
    t,
    transactionsPSList: safeTransactionsPSList,
    testPaymentSystems: safeTestPaymentSystems,
    currenciesSelectionData,
    walletsSelection,
    directionValue,
    // @ts-ignore
    fieldList,
    withdrawalsPSListSelection,
    currenciesWithIdSelectionData,
    withdrawalStatusSelectionList,
    operation_type,
    form,
    walletType,
    values,
    activationDate,
    transactionKindItems,
    transactionKind,
    transactionStatus,
    archivingStatus,
    shouldPostbackStatusBeFixed,
    archivedTransactionPermission,
    renderAutoreport,
    filterWalletId,
    statusData,
    transactionPrivateInformationViewPermission,
  });

  const fields = useMemo(() => {
    return [
      {
        name: `report_type`,
        id: `${form}_report_type`,
        type: InputType.SELECT,
        label: t(`reports.kindOfReport`),
        translateKeys: true,
        required: true,
        data: reportTypeSelectionData,
      },
      ...reportTypeFields,
    ];
  }, [reportType, values, reportTypeFields]);

  const filteredFields = useFilterFormFields({ fields, formName: form });

  return {
    modal,
    filteredFields,
    checkedSumReportFields,
    checkedListReportFields,
    reportType,
    widgetType,
    editedItems,
  };
};
