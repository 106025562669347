import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDesktopSize, widgetModalSelector, Size, IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { postbackActionCreators } from 'actions/widgets/postback';

import StockpilingPostbackRow from 'components/widgets/stockpiling/StockpilingPostbackRow';
import ModalElement from 'components/modals/ModalElement';
import useTransactionPostbackList from '../../../hooks/widgets/transactions/useTransactionPostbackList';

interface IProps {
  actionCreators: IWidgetActionCreators;
  widget: string;
  //TODO: КОСТЫЛЬ, УБРАТЬ
  modalWidget?: string;
  listActionCreators?: IWidgetActionCreators;
  shouldUseItem?: boolean;
}

const TransactionPostbackList = ({
  actionCreators,
  widget,
  modalWidget,
  shouldUseItem = false,
  listActionCreators = postbackActionCreators,
}: IProps) => {
  const modal = useSelector(widgetModalSelector(modalWidget || widget));

  const desktopSize = useDesktopSize();

  const [t] = useTranslation();

  const info = useTransactionPostbackList({
    shouldUseItem,
    modal,
    widget,
    listActionCreators,
  });

  const { listLoading } = info;
  let { items } = info;

  if (typeof items === `object`) {
    items = [items];
  }

  let content;
  if (!listLoading) {
    if (!items || !items[0]) {
      content = <span className="table-content__message">{t(`postbacks.postbackListIsEmpty`)}</span>;
    } else {
      content = (
        <div className="table-responsive">
          <table className="table table-vcenter table-padding">
            {desktopSize && (
              <thead>
                <tr>
                  <th className="font-w600">ID</th>
                  <th className="font-w600">{t(`postbacks.url`)}</th>
                  <th className="font-w600">{t(`postbacks.statusCode`)}</th>
                  <th className="font-w600">{t(`postbacks.response`)}</th>
                  <th className="font-w600">{t(`common.status`)}</th>
                  <th className="font-w600">{t(`common.createdAt`)}</th>
                  <th className="font-w600">{t(`postbacks.requestBody`)}</th>
                </tr>
              </thead>
            )}
            <tbody>
              {items.map((item: any) => (
                <StockpilingPostbackRow key={item.id} {...item} desktopSize={desktopSize} />
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  }

  return (
    <ModalElement
      title="postbacks.postbackList"
      actionCreators={actionCreators}
      content={content}
      loading={listLoading}
      size={Size.BIG}
    />
  );
};

export default TransactionPostbackList;
