import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useMount from 'react-use/lib/useMount';
import queryString from 'query-string';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { Button, ErrorBlock, InputType, pluginUrlSelector, configSelector } from '@kassma-team/kassma-toolkit/lib';

import { PAYMENT_PLUGIN_WIDGET } from 'utils/constants';
import { currencySelectionSelector } from 'selectors/widgets/currency';
import { paymentSystemsWithoutSettlementsSelector } from 'selectors/widgets/paymentSystems';
import useFormSubmissionHandler from 'hooks/widgets/useFormSubmissionHandler';

import SettingsBlockWrap from 'components/widgets/settings/SettingsBlockWrap';
import FormField from 'components/form/FormField';
import useValidateForm from 'hooks/useValidateForm';

import './paymentPlugin.scss';
import { widgetListSelector } from '@kassma-team/kassma-toolkit';
import { Permission, WalletType, WidgetType } from '../../../../utils/enums';
import { walletActionCreators } from '../../../../actions/widgets/wallet';
import { hasAccessSelector } from '../../../../selectors/auth';
import { IWalletsWidgetStateItemsElement } from '../../../../interfaces/widgets/wallets/wallet';
import useList from '../../../../hooks/widgets/useList';

interface IConfigs {
  testPluginUrl: string;
}

function copyToClipboard(text: string) {
  const split = text.replace(/[&?]timestamp=\d+/, ``).split(/[?&]/);
  const [base, ...params] = split;
  const normalized = params
    .map((param) => param.replace(/^[^=]+$/g, ``))
    .filter((elem) => elem)
    .join(`&`);

  const textArea = document.createElement(`textarea`);
  textArea.value = `${base}/?${normalized}`;
  document.body.appendChild(textArea);

  textArea.select();
  document.execCommand(`copy`);

  document.body.removeChild(textArea);
}
import { loadSettings } from 'actions/widgets/settings';

const getUrl = (pluginUrl: string) => {
  const pluginData = localStorage.getItem(`pluginConstructor`);
  const parsedPluginData = JSON.parse(pluginData as string);
  const urlCanBeConstructed =
    !isEmpty(parsedPluginData) && (get(parsedPluginData, `currency_code`) || get(parsedPluginData, `label`));

  if (urlCanBeConstructed) {
    const queryParam = { ...parsedPluginData, timestamp: new Date().getTime() };

    return `${pluginUrl}?${queryString.stringify(queryParam, { arrayFormat: `comma` })}`;
  } else {
    return null;
  }
};

const pluginLangs = [
  `en`,
  `vi`,
  `ms`,
  `id`,
  `uz`,
  `es`,
  `pt`,
  `tr`,
  `ar`,
  `fa`,
  `ur`,
  `bn`,
  `ne`,
  `ru`,
  `hi`,
  `my`,
  `si`,
  `sw`, // Суахили
  `pa`, // Панджаби
];
const selectionOfPluginLangs = map(pluginLangs, (value) => ({ value, text: value }));

const PaymentPluginWidget = ({ initialize, form }: InjectedFormProps) => {
  const [t] = useTranslation();
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [copiedUrl, setCopiedUrl] = useState(``);
  const closeRequestFormIpayApiGetted = useRef(false);

  const dispatch = useDispatch();
  const pluginUrl: string = useSelector(pluginUrlSelector);
  const configs: IConfigs = useSelector(configSelector);
  const currencies = useSelector(currencySelectionSelector);
  const paymentSystemList = useSelector(paymentSystemsWithoutSettlementsSelector);

  const dynamicUrl = configs.testPluginUrl || pluginUrl;

  useEffect(() => {
    dispatch(loadSettings());
  }, []);

  const walletsList = useSelector(widgetListSelector(WidgetType.WALLETS)) as IWalletsWidgetStateItemsElement[];

  const walletListAccess = useSelector(hasAccessSelector(Permission.WALLET_LIST));
  const values = useSelector(getFormValues(form)) as { wallet_type: string; code: string };

  if (walletListAccess) {
    useList({
      widget: WidgetType.WALLETS,
      actionCreators: walletActionCreators,
    });
  }

  const walletsIDList = walletsList
    ?.filter((wallet) => {
      return wallet.code === values?.wallet_type && wallet.is_active && !wallet.removed;
    })
    .map((wallet) => ({ text: `${wallet.id} | ${wallet.identifier} `, value: wallet.id }));

  const safePaymentSystemList = paymentSystemList.map((el) => ({
    ...el,
    value: el.code,
    text: el.name,
  }));

  const [url, setUrl] = useState(getUrl(dynamicUrl));

  const initForm = useCallback(() => {
    const pluginData = localStorage.getItem(`pluginConstructor`);
    const parsedPluginData = JSON.parse(pluginData as string);
    initialize(parsedPluginData);
  }, []);

  useMount(() => {
    initForm();
  });

  const validate = useValidateForm(form);

  const onSubmit = useCallback(
    (values) => {
      const valid = validate();

      if (valid) {
        if (!walletsIDList?.length) values.w_id = undefined;

        localStorage.setItem(`pluginConstructor`, JSON.stringify(values));
        setUrl(getUrl(dynamicUrl));
      }
    },
    [values?.wallet_type, walletsIDList]
  );

  const handleSubmit = useFormSubmissionHandler({ form, onSubmit });

  const onUpdate = useCallback(() => {
    setUrl(getUrl(dynamicUrl));
    initForm();
  }, []);

  const fields = useMemo(
    () => [
      {
        name: `label`,
        type: InputType.MUI_TEXT,
        label: `label`,
      },
      {
        name: `currency_code`,
        type: InputType.MUI_MULTI_SELECT,
        label: `currency_code`,
        data: currencies,
      },
      {
        name: `lang`,
        type: InputType.MUI_SELECT,
        label: `lang`,
        data: selectionOfPluginLangs,
      },
      {
        name: `wallet_type`,
        type: InputType.MUI_SELECT,
        label: `wallet_type`,
        data: [{ value: null, text: t(`common.all`) }, ...safePaymentSystemList],
      },
      ...(walletListAccess && values?.wallet_type
        ? [
            {
              name: `w_id`,
              type: InputType.MUI_SELECT,
              label: `w_id`,
              data: [{ value: null, text: t(`common.all`) }, ...(walletsIDList || [])],
            },
          ]
        : []),
      {
        name: `level`,
        type: InputType.MUI_TEXT,
        label: `level`,
      },
      {
        name: `custom_transaction_id`,
        type: InputType.MUI_TEXT,
        label: `custom_transaction_id`,
      },
      {
        name: `fixed_amount`,
        type: InputType.MUI_TEXT,
        label: `fixed_amount`,
      },
      {
        name: `payment_url_success_id`,
        type: InputType.MUI_TEXT,
        label: `payment_url_success_id`,
      },
      {
        name: `payment_url_fail_id`,
        type: InputType.MUI_TEXT,
        label: `payment_url_fail_id`,
      },
      {
        name: `payment_url_pending_id`,
        type: InputType.MUI_TEXT,
        label: `payment_url_pending_id`,
      },
      {
        name: `success_url`,
        type: InputType.MUI_TEXT,
        label: `success_url`,
      },
      {
        name: `fail_url`,
        type: InputType.MUI_TEXT,
        label: `fail_url`,
      },
      {
        name: `pending_url`,
        type: InputType.MUI_TEXT,
        label: `pending_url`,
      },
    ],
    [currencies, paymentSystemList, values?.wallet_type, walletsIDList]
  );

  useEffect(() => {
    if (isIframeLoaded && url) setCopiedUrl(url || ``);
  }, [isIframeLoaded, url]);

  const iframe = useMemo(() => {
    if (dynamicUrl) {
      if (url) {
        return (
          <iframe
            src={url}
            width="100%"
            height="1200"
            sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation allow-popups-to-escape-sandbox allow-popups"
            frameBorder="0"
            onLoad={() => setIsIframeLoaded(true)}
          />
        );
      } else {
        return t(`paymentConstructor.pluginNoURL`);
      }
    } else {
      return <ErrorBlock error={t(`paymentConstructor.pluginError`)} />;
    }
  }, [dynamicUrl, url]);

  useEffect(() => {
    const eventChecker = (e) => {
      if (e.data === `IpayApiCloseRequest`) {
        if (values.wallet_type === WalletType.IPAY_API) {
          if (!closeRequestFormIpayApiGetted.current) {
            closeRequestFormIpayApiGetted.current = true;
            location.href = `${location.href}`;
          }

          return;
        }

        location.href = `${location.href}`;
      }
    };

    window.addEventListener(`message`, eventChecker);

    return () => {
      window.removeEventListener(`message`, eventChecker);
    };
  }, []);

  return (
    <div className="plugin-widget">
      <div className="plugin-widget__form">
        <form onSubmit={handleSubmit}>
          <SettingsBlockWrap title={t(`paymentConstructor.plugin`)}>
            {map(fields, (field, fieldKey) => (
              <FormField {...field} key={fieldKey} />
            ))}
            <div className="plugin-widget__form-controls-container">
              <div className="plugin-widget__form-controls">
                <Button onClick={() => setCopiedUrl(url || ``)} type="submit">
                  {t(`dashboard.accept`)}
                </Button>
                <Button onClick={onUpdate}>{t(`common.updatePlugin`)}</Button>
              </div>
              {isIframeLoaded && (
                <div className="plugin-widget__form-controls__copy-button">
                  <Button onClick={() => copyToClipboard(copiedUrl)}>{t(`paymentConstructor.copyLink`)}</Button>
                </div>
              )}
            </div>
          </SettingsBlockWrap>
        </form>
      </div>
      <div className="plugin-widget__iframe-wrap">{iframe}</div>
    </div>
  );
};

export default reduxForm({
  form: PAYMENT_PLUGIN_WIDGET,
  destroyOnUnmount: false,
  shouldError: () => true,
})(PaymentPluginWidget);
