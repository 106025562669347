import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import keys from 'lodash/keys';
import without from 'lodash/without';
import find from 'lodash/find';
import includes from 'lodash/includes';
import { useSelector } from 'react-redux';
import { ResponsiveContainer, BarChart, XAxis, YAxis, Tooltip, CartesianGrid, Bar, Legend } from 'recharts';

import { selectionOfAllManualPSSelector } from 'selectors/widgets/manualPS';
import yAxisTickFormatter from 'utils/dashboard/yAxisTickFormatter';
import { walletColor } from './constants';

import CustomTooltip from './CustomTooltip';
import { IChartProps } from 'interfaces/dashboard';

const BarChartWrapper = ({ chartData, isSum }: IChartProps) => {
  const [bars, setBars] = useState<string[]>([]);
  const [hiddenBars, setHiddenBars] = useState<string[]>([]);

  useEffect(() => {
    setBars(without(keys(get(chartData, `[0]`, {})), `date`));
  }, [chartData]);

  const testPayments = useSelector(selectionOfAllManualPSSelector);

  const selectBar = (event: Record<string, any>) => {
    if (includes(hiddenBars, event.value)) {
      return setHiddenBars(without(hiddenBars, event.value));
    }
    setHiddenBars([...hiddenBars, event.value]);
  };

  if (!chartData) return null;

  return (
    <ResponsiveContainer minWidth={296} minHeight={168} width="100%" height="100%" aspect={1.895}>
      <BarChart data={chartData} margin={{ top: 5, right: 30, left: 0, bottom: 40 }}>
        <XAxis dataKey="date" type="category" angle={-45} tickMargin={30} minTickGap={1} />
        <YAxis padding={{ top: 10 }} tickFormatter={yAxisTickFormatter} />
        <Tooltip
          isAnimationActive={false}
          content={<CustomTooltip testPayments={testPayments} isSum={isSum as boolean} />}
          wrapperStyle={{ zIndex: 2, overflow: `hidden` }}
          allowEscapeViewBox={{ x: true, y: false }}
        />
        <Legend
          onClick={selectBar}
          verticalAlign="top"
          height={48}
          wrapperStyle={{ overflowY: `scroll` }}
          iconType="rect"
          formatter={(value) => {
            const isHidden = includes(hiddenBars, value);
            const testPaymentName = get(find(testPayments, { value: value }), `text`);

            return (
              <span style={{ textDecoration: isHidden ? `line-through` : `none` }}>{testPaymentName || value}</span>
            );
          }}
        />
        <CartesianGrid stroke="#f5f5f5" />
        {bars.map((bar, index) => {
          return (
            <Bar
              type="monotone"
              stackId="a"
              dataKey={(record) => (includes(hiddenBars, bar) ? null : get(record, `${bar}.value`))}
              key={bar}
              name={bar}
              fill={walletColor[index]}
              isAnimationActive={false}
            />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

BarChartWrapper.defaultProps = {
  isSum: false,
};

export default BarChartWrapper;
