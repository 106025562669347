import { useDispatch, useSelector } from 'react-redux';
import { usernameSelector } from '@kassma-team/kassma-toolkit/lib';

import { userPermissionsSelector } from 'selectors/auth';

import listData from 'components/header/listData';

const useNavList = () => {
  const permissions = useSelector(userPermissionsSelector);
  const dispatch = useDispatch();
  const userName: string = useSelector(usernameSelector);

  return listData({ permissions, dispatch, userName });
};

export default useNavList;
