import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import {
  IActivateTransactionsForm,
  ITransactionsFilterForm,
  IUploadManualWithdrawalForm,
} from 'interfaces/widgets/transaction/transaction';
import { ParsedQuery } from 'query-string';

export class TransactionApi extends BaseApi {
  activateTransaction = (primaryId: number, data: IActivateTransactionsForm): AxiosPromise =>
    this.makeFetch(`force-activate/{primaryId}`, { data, method: `POST` }, { primaryId });

  createTransaction = (data: any): AxiosPromise => this.makeFetch(`create-manual`, { data, method: `POST` });

  downloadTransactionCSV = (queryParams: ParsedQuery): AxiosPromise =>
    this.makeFetch(`list-to-xls`, { queryParams, responseType: `blob` });

  loadTransactionList = (queryParams: ITransactionsFilterForm): AxiosPromise => {
    if (queryParams.merchant_order_id) {
      //@ts-ignore
      queryParams.merchant_order_id = queryParams.merchant_order_id
        .split(`,`)
        .filter((order) => !!order && !/^\s+$/.test(order))
        .map((order) => order.trim());
    }

    return this.makeFetch(`list`, {
      queryParams,
    });
  };

  uploadTransactions = (data: IUploadManualWithdrawalForm): AxiosPromise =>
    this.makeFetch(`icici-file`, { data, method: `POST`, formData: true });

  loadStockpilingTransactions = (stockpiling_id: number): AxiosPromise =>
    this.makeFetch(`list-by-stockpiling`, { queryParams: { stockpiling_id } });

  set_fake = (transactions: number[], is_fake: boolean): AxiosPromise =>
    this.makeFetch(`set_fake`, { data: { is_fake, transactions }, method: `PUT` });
}

const transactionApi = new TransactionApi(`transaction`);

export default transactionApi;
