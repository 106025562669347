import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { hasAccessSelector } from 'selectors/auth';
import { ModalType, Permission } from 'utils/enums';
import { walletActionCreators } from 'actions/widgets/wallet';

interface IWalletsExtraButtonsReturns {
  title: string;
  onClick: () => void;
  buttonClassName: string;
  id: string;
}

const useWalletsExtraButtons = (): IWalletsExtraButtonsReturns[] => {
  const walletCreatingAccess = useSelector(hasAccessSelector(Permission.WALLET_CREATE));

  const [t] = useTranslation();

  const extraButtons = [];
  if (walletCreatingAccess) {
    extraButtons.push({
      title: t(`wallets.addWallet`),
      onClick: () => walletActionCreators.showModal({ type: ModalType.CREATE }),
      buttonClassName: `btn-success`,
      id: `add-new-wallet`,
    });
  }

  return extraButtons;
};

export default useWalletsExtraButtons;
