import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import join from 'lodash/join';
import { widgetModalSelector } from '@kassma-team/kassma-toolkit/lib';

import { withdrawalsActionCreators } from 'actions/widgets/withdrawals';
import { WidgetType } from 'utils/enums';
import withdrawalLogTypeField from 'utils/widgets/withdrawals/WithdrawalLogTypeField';

import ModalElement from 'components/modals/ModalElement';
import { IWithdrawalApprovalModal } from 'interfaces/widgets/withdrawals';

const ManualWithdrawalsErrorModal = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const values: IWithdrawalApprovalModal = useSelector(widgetModalSelector(WidgetType.WITHDRAWALS));
  const logs = values?.logs;

  const content = (
    <div>
      {map(logs, (sheets, type) => (
        <div className="pb-20">
          <div className="pb-10">{t(withdrawalLogTypeField[type]?.text)}:</div>
          {map(sheets, (data, sheet) => (
            <span className="d-block text-danger">{t(`withdrawals.logField`, { sheet, data: join(data, `,`) })}</span>
          ))}
        </div>
      ))}
    </div>
  );

  const footer = (
    <div className="form-group pt-10 row justify-content-center">
      <button className="btn btn-alt-primary" onClick={() => dispatch(withdrawalsActionCreators.hideModal())}>
        {t(`common.gotIt`)}
      </button>
    </div>
  );

  return (
    <ModalElement
      title={`withdrawals.unprocessedWithdrawalRequests`}
      actionCreators={withdrawalsActionCreators}
      content={content}
      footer={footer}
    />
  );
};

export default ManualWithdrawalsErrorModal;
