import React, { useState, useMemo, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { WalletImg } from '@kassma-team/kassma-toolkit';

import { ITariffsSchemas, ITariffsWalletTypes } from 'interfaces/widgets/constructor/tariffConstructor';
import { ITariffContext, TariffContext } from 'pages/constructor/TariffsConstructor';

import './tariffButtons.scss';
import { useSelector } from 'react-redux';
import { paymentSystemsWithoutSettlementsSelector } from '../../../../selectors/widgets/paymentSystems';
import { IPaymentSystemsItem } from '../../../../interfaces/widgets/paymentSystems';

interface IProps {
  onClickCallback: (value: string) => void;
  schemasList: ITariffsSchemas[];
}

const PaymentSystemColumn = ({ onClickCallback, schemasList }: IProps): JSX.Element => {
  const [activeId, setActiveId] = useState(``);
  const [t] = useTranslation();

  const { paymentSystemsList } = useContext<ITariffContext>(TariffContext);

  useEffect(() => {
    onClickCallback(activeId);
  }, []);

  const paymentSystems = useMemo(
    () =>
      paymentSystemsList?.reduce<ITariffsWalletTypes[]>((result, wallet: ITariffsWalletTypes) => {
        const isExists = schemasList?.find((item) => item.wallet_type.toString() === wallet.code.toString());

        if (isExists) {
          return [...result, wallet];
        }

        return result;
      }, []),
    [schemasList, paymentSystemsList]
  );

  useEffect(() => {
    //@ts-ignore
    setActiveId(paymentSystems?.[0]?.wallet_type);
  }, [paymentSystems]);

  const handleClick = (value: string) => {
    setActiveId(value);

    onClickCallback(value);
  };

  const activeClass = (activeId: string, id: string) =>
    activeId === id ? `tariff_buttons_row__payment_button-active` : ``;

  const psList = useSelector(paymentSystemsWithoutSettlementsSelector);

  const getLogo = useCallback(
    (walletType) => {
      return (psList.find((paymentSystem: IPaymentSystemsItem) => paymentSystem.code === walletType) || { logo: `` })
        .logo;
    },
    [psList]
  );

  const renderContent = useMemo(
    () =>
      paymentSystems?.length ? (
        paymentSystems?.map((item, key: number) => {
          return (
            <button
              key={key}
              className={`tariff_buttons_row__payment_button ${activeClass(activeId || ``, item.code)}`}
              onClick={() => handleClick(item.code)}
            >
              <WalletImg title={item.code} src={getLogo(item.code)} className="tariff_buttons_row__img" />
            </button>
          );
        })
      ) : (
        <div className="d-flex justify-content-center">{t(`widgets.listIsEmpty`)}</div>
      ),
    [paymentSystems, activeId, handleClick, t, psList, getLogo]
  );

  return (
    <div className="tariff_row__payment_system tariff_row__column">
      <div className="tariff_buttons_row">{renderContent}</div>
    </div>
  );
};

export default PaymentSystemColumn;
