import React, { ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMount from 'react-use/lib/useMount';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import isNull from 'lodash/isNull';
import { WalletImg, widgetModalSelector, widgetItemSelector } from '@kassma-team/kassma-toolkit/lib';
import { hasAccessSelector } from 'selectors/auth';
import { Permission } from 'utils/enums';
import withdrawalStatusField from 'utils/widgets/withdrawals/withdrawalStatusField';
import withdrawalTypeField from 'utils/widgets/withdrawals/withdrawalTypeField';
import { ModalType, WalletType, WidgetType, WithdrawalKind, WithdrawalStatus } from 'utils/enums';
import { withdrawalsActionCreators } from 'actions/widgets/withdrawals';
import withdrawalKindField from 'utils/widgets/withdrawals/withdrawalKindField';
import { formatDate } from 'utils/formatDate';
import { IWithdrawalItemInfo } from 'interfaces/widgets/withdrawals';

import ModalElement from 'components/modals/ModalElement';
import { paymentSystemLogoSelector } from '../../../selectors/widgets/paymentSystems';

const finalStatuses = [WithdrawalStatus.PROCESSED, WithdrawalStatus.CANCELED, WithdrawalStatus.REJECTED];

interface IRowProps {
  label: string;
  textClassName?: string;
  children: ReactNode;
}

const Row = ({ children, textClassName = `text-muted`, label }: IRowProps) => {
  const [t] = useTranslation();

  return (
    <tr>
      <td className="font-w600">{t(label)}</td>
      <td className={textClassName}>{children}</td>
    </tr>
  );
};

const WithdrawalItem = ({
  id,
  wallet_type,
  wallet_sender,
  wallet_recipient,
  wallet_recipient_name,
  currency_code,
  amount,
  status,
  comment,
  created_at,
  updated_at,
  withdrawal_id,
  type,
  payments_method,
  provider_id,
  bank_code,
  branch_code,
  email,
  is_manually,
  account_name,
  account_email,
  account_number,
  bank_code_in_payments_system,
  payments_provider,
  is_test,
}: IWithdrawalItemInfo) => {
  const [t] = useTranslation();
  const hasManageAccess = useSelector(hasAccessSelector(Permission.WITHDRAWALS_MANAGE));

  const dispatch = useDispatch();

  const formattedCreatedAt = formatDate(created_at);
  const formattedUpdatedAt = formatDate(updated_at);

  const onUpdateCommentClick = useCallback(() => {
    dispatch(withdrawalsActionCreators.showModal({ type: ModalType.COMMENT, comment, id }));
  }, [dispatch, id, comment]);

  let recipientNameField = null;
  if (wallet_recipient_name) {
    let i18nKey;
    switch (wallet_type) {
      case WalletType.DUSUPAY:
        i18nKey = `withdrawals.walletRecipientAccount`;
        break;
      default:
        i18nKey = `withdrawals.user`;
    }
    recipientNameField = <Row label={i18nKey}>{wallet_recipient_name}</Row>;
  }

  const methodField = is_manually ? t(`withdrawals.manualMethod`) : t(`common.api`);

  const kind = is_test ? WithdrawalKind.DEBUG : WithdrawalKind.CONFIRMED;

  const logo = useSelector(paymentSystemLogoSelector(wallet_type));

  return (
    <div className="table-responsive">
      <table className="table table-striped table-borderless table-vcenter">
        <tbody>
          <Row label="common.type">
            <WalletImg title={wallet_type} src={logo} />
          </Row>
          <Row label="withdrawals.requestDate">{formattedCreatedAt}</Row>
          <Row label="withdrawals.withdrawalId">{withdrawal_id}</Row>
          <Row label="withdrawals.requestType">
            <div className="multi-badge-cell">
              <span className={`badge ${get(withdrawalTypeField, `${type}.badge`)}`}>
                {t(get(withdrawalTypeField, `${type}.text`))}
              </span>
            </div>
          </Row>
          <Row label="withdrawals.withdrawalMethod">{methodField}</Row>
          <Row label="common.status">
            <div className="multi-badge-cell">
              <span className={`badge ${get(withdrawalStatusField, `${status}.badge`)}`}>
                {t(get(withdrawalStatusField, `${status}.text`))}
              </span>
            </div>
          </Row>
          <Row label="withdrawals.kind">
            <div className="multi-badge-cell">
              <span className={`badge ${get(withdrawalKindField, `${kind}.badge`)}`}>
                {t(get(withdrawalKindField, `${kind}.text`))}
              </span>
            </div>
          </Row>

          {is_manually && account_name && <Row label="withdrawals.accountName">{account_name}</Row>}
          {is_manually && account_number && <Row label="withdrawals.accountNumber">{account_number}</Row>}
          {is_manually && account_email && <Row label="withdrawals.accountEmail">{account_email}</Row>}

          {wallet_sender && <Row label="withdrawals.walletSender">{wallet_sender}</Row>}
          {wallet_recipient && <Row label="withdrawals.walletRecipient">{wallet_recipient}</Row>}

          {payments_method && <Row label="withdrawals.payoutMethod">{payments_method}</Row>}
          {payments_provider && <Row label="withdrawals.paymentsProvider">{payments_provider}</Row>}
          {provider_id && <Row label="withdrawals.providerId">{provider_id}</Row>}
          {bank_code && <Row label="withdrawals.bankCode">{bank_code}</Row>}
          {branch_code && <Row label="withdrawals.branchCode">{branch_code}</Row>}
          {bank_code_in_payments_system && (
            <Row label="withdrawals.bankCodeInPaymentsSystem">{bank_code_in_payments_system}</Row>
          )}
          {recipientNameField}
          {email && <Row label="withdrawals.email">{email}</Row>}

          <Row label="common.amount">{amount}</Row>
          <Row label="common.currency">{currency_code}</Row>
          <Row label="withdrawals.updatedAt">{formattedUpdatedAt}</Row>

          {comment && (
            <Row label="common.comment">
              <span className="text-break-word" dangerouslySetInnerHTML={{ __html: comment }} />
            </Row>
          )}
        </tbody>
      </table>
      {!finalStatuses.includes(status) && hasManageAccess && (
        <div className="modal__control-button">
          <button className="btn btn-alt-warning" onClick={onUpdateCommentClick}>
            {t(`withdrawals.changeComment`)}
          </button>
        </div>
      )}
    </div>
  );
};

const WithdrawalItemModal = () => {
  const dispatch = useDispatch();

  const item: IWithdrawalItemInfo = useSelector(widgetItemSelector(WidgetType.WITHDRAWALS));
  const modal = useSelector(widgetModalSelector(WidgetType.WITHDRAWALS));
  const [modalId] = useState(get(modal, `id`));

  useMount(() => {
    dispatch(withdrawalsActionCreators.getItem(modalId));
  });

  const content = isObject(item) ? <WithdrawalItem {...item} /> : null;

  return (
    <ModalElement
      title="withdrawals.withdrawalInfo"
      content={content}
      actionCreators={withdrawalsActionCreators}
      loading={isNull(item)}
    />
  );
};

export default WithdrawalItemModal;
