import { useCallback, useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, stopSubmit } from 'redux-form';
import { sleep } from '@kassma-team/kassma-toolkit/lib';

import { TransactionCreationType, ModalType, TransactionConfirmationType, TransactionKind } from 'utils/enums';
import { activateTransaction, transactionActionCreators } from 'actions/widgets/transactions/transactions';
import { IActivateTransaction } from 'interfaces/widgets/transaction/transaction';

interface IProps {
  transactionType: number;
  type: string;
  form: string;
  primaryId?: number;
  widget?: string;
  creationType?: string;
}

interface TransactionSubmitHandlerReturns {
  confirmationInfoType: number | null;
  confirmationInfoData: Record<string, unknown> | null;
  onSubmit: () => void;
  onForcedSubmit: () => void;
  onConfirmationInfoCancel: () => void;
  onSubmitWithReopeningModal: () => void;
}

const useTransactionSubmitHandler = ({
  transactionType,
  type,
  form,
  primaryId,
  widget,
  creationType,
}: IProps): TransactionSubmitHandlerReturns => {
  const [confirmationInfoType, setConfirmationInfoType] = useState<number | null>(null);
  const [confirmationInfoData, setConfirmationInfoData] = useState<Record<string, unknown> | null>(null);

  const values = useSelector(getFormValues(form)) || {};
  const dispatch = useDispatch();

  const requestErrorHandler = useCallback((err) => {
    const status = get(err, `response.status`);
    const duplicatedData = get(err, `response.data.data`);

    if (status === 409 && !isEmpty(duplicatedData)) {
      setConfirmationInfoType(TransactionConfirmationType.DUPLICATED_TRANSACTION);
      setConfirmationInfoData(duplicatedData);
    }
  }, []);

  const onCreateSubmit = useCallback(
    ({ values, meta = {}, reopenModalAfterSubmit = false }) => {
      if (includes([TransactionKind.FORCED, TransactionKind.REAL], transactionType)) {
        meta.onError = requestErrorHandler;
      }

      if (reopenModalAfterSubmit) {
        const currency_code = get(values, `currency_code`);
        const wallet_type = get(values, `wallet_type`);

        meta.onSuccess = async () => {
          await sleep(500);
          const data = {
            currency_code,
            type: transactionType,
            wallet_type,
          };
          dispatch(transactionActionCreators.showModal({ type: ModalType.CREATE, data, transactionType }));
        };
      }

      dispatch(transactionActionCreators.create(values, meta));
    },
    [requestErrorHandler]
  );

  const onActivationSubmit = useCallback(
    (values) => {
      const params: IActivateTransaction = { primaryId, payload: values, widget };

      if (
        creationType === TransactionCreationType.MANUAL &&
        includes([TransactionKind.FORCED, TransactionKind.REAL], transactionType)
      ) {
        params.onError = requestErrorHandler;
      }

      dispatch(activateTransaction(params));
    },
    [widget, primaryId, creationType, transactionType, requestErrorHandler]
  );

  const submit = useCallback(
    (values) => {
      if (type === `creating`) {
        onCreateSubmit({ values });
      } else if (type === `activation`) {
        onActivationSubmit(values);
      }
    },
    [onCreateSubmit, onActivationSubmit]
  );

  const onSubmit = useCallback(() => {
    submit(values);
  }, [values, submit]);

  const onSubmitWithReopeningModal = useCallback(() => {
    if (type === `creating`) {
      onCreateSubmit({ values, reopenModalAfterSubmit: true });
    }
  }, [onCreateSubmit, values]);

  const onForcedSubmit = useCallback(() => {
    dispatch(stopSubmit(form, {}));
    submit({ ...values, is_remove_possible_transaction: true });
  }, [submit, values]);

  const onConfirmationInfoCancel = useCallback(() => {
    setConfirmationInfoType(null);
    setConfirmationInfoData(null);
  }, []);

  return {
    confirmationInfoType,
    confirmationInfoData,
    onSubmit,
    onForcedSubmit,
    onConfirmationInfoCancel,
    onSubmitWithReopeningModal,
  };
};

export default useTransactionSubmitHandler;
