import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IWidgetsAction, useFilterColumns, useIsTouchDevice } from '@kassma-team/kassma-toolkit/lib';

import { AutoReportColumn, ModalType, Permission } from 'utils/enums';
import { useDispatch, useSelector } from 'react-redux';
import { hasAccessSelector } from 'selectors/auth';
import { autoReportsActionCreators } from 'actions/widgets/autoreports';
import SpoilerWithCount from '../../SpoilerWithCount';

const AutoReportsRow = ({
  id,
  report_type,
  emails,
  start,
  reporting_period,
  author,
  desktopSize,
  hiddenColumns,
  ...props
}: any) => {
  const [t] = useTranslation();

  interface IControlProps {
    id: number;
  }

  const Control = ({ id }: IControlProps) => {
    const dispatch = useDispatch();
    const [t] = useTranslation();

    const isTouchDevice = useIsTouchDevice();
    const removeAccess = useSelector(hasAccessSelector(Permission.WALLET_DELETE));
    const updateAccess = useSelector(hasAccessSelector(Permission.WALLET_UPDATE));
    const removeIcon = `fa fa-trash`;
    const showModal: IWidgetsAction = autoReportsActionCreators.showModal;

    return (
      <div className="btn-group">
        {updateAccess && (
          <button
            type="button"
            id={`wallet_row_${id}_edit_btn`}
            className={classNames(`btn`, `btn-secondary`, `js-tooltip-enabled`, { 'btn-sm': !isTouchDevice })}
            onClick={() => {
              dispatch(showModal({ id, type: ModalType.UPDATE }));
            }}
          >
            <i className="fa fa-pencil" />
          </button>
        )}
        {removeAccess && (
          <button
            type="button"
            id={`wallet_row_${id}_delete_btn`}
            className={classNames(`btn`, `btn-secondary`, `js-tooltip-enabled`, { 'btn-sm': !isTouchDevice })}
            onClick={() => dispatch(showModal({ id, type: ModalType.REMOVE_CONFIRMATION }))}
            title={t(`common.disable`)}
          >
            <i className={removeIcon} />
          </button>
        )}
      </div>
    );
  };

  const columns = [
    {
      id: AutoReportColumn.AUTO_REPORT_FILE,
      desktop: <td>{id}</td>,
      mobile: (
        <tr className="first-mobile-row">
          <td>{t(`reports.file`)}</td>
          <td>{id}</td>
        </tr>
      ),
    },
    {
      id: AutoReportColumn.AUTO_REPORT_TYPE,
      desktop: <td>{t(`reports.${report_type}`)}</td>,
      mobile: (
        <tr>
          <td>{t(`common.date`)}</td>
          <td>{t(`reports.${report_type}`)}</td>
        </tr>
      ),
    },
    {
      id: AutoReportColumn.AUTO_REPORT_EMAILS,
      desktop: (
        <td>
          <SpoilerWithCount elements={emails} />
        </td>
      ),
      mobile: (
        <tr>
          <td>{t(`common.date`)}</td>
          <td>
            <SpoilerWithCount elements={emails} />
          </td>
        </tr>
      ),
    },
    {
      id: AutoReportColumn.AUTO_REPORT_START,
      desktop: <td>{start}</td>,
      mobile: (
        <tr>
          <td>{t(`common.date`)}</td>
          <td>{start}</td>
        </tr>
      ),
    },
    {
      id: AutoReportColumn.AUTO_REPORT_REPORTING_PERIOD,
      desktop: <td>{t(`common.${reporting_period}`)}</td>,
      mobile: (
        <tr>
          <td>{t(`common.date`)}</td>
          <td>{t(`common.${reporting_period}`)}</td>
        </tr>
      ),
    },
    {
      id: AutoReportColumn.AUTO_REPORT_AUTHOR,
      desktop: <td>{author}</td>,
      mobile: (
        <tr>
          <td>{t(`common.date`)}</td>
          <td>{author}</td>
        </tr>
      ),
    },
    {
      id: AutoReportColumn.AUTO_REPORT_DATE,
      desktop: (
        <td>
          <Control id={id} />
        </td>
      ),
      mobile: (
        <tr>
          <td>{t(`common.date`)}</td>
          <td>
            <Control id={id} />
          </td>
        </tr>
      ),
    },
  ];

  return useFilterColumns({ columns, desktopSize, hiddenColumns });
};

export default AutoReportsRow;
