import React, { useState, useEffect } from 'react';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import map from 'lodash/map';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ErrorBlock, widgetSelector, InputType, IRootState } from '@kassma-team/kassma-toolkit/lib';

import {
  paygigaAvailableAmountsActionCreators,
  paygigaBankCodesActionCreators,
  withdrawalsBankCodesActionCreators,
  withdrawalsBranchCodesActionCreators,
  withdrawalsCurrencyCodesActionCreators,
  withdrawalsPayoutMethodsActionCreators,
  withdrawalsProvidersActionCreators,
} from 'actions/widgets/withdrawals';
import {
  paygigaAvailableAmountSelector,
  paygigaBankCodesSelector,
  paygigaCurrencyCodeByIdSelector,
  withdrawalBankCodesSelector,
  withdrawalBranchCodesSelector,
  withdrawalCurrencyCodesSelector,
  withdrawalPayoutMethodsSelector,
  withdrawalProvidersSelector,
} from 'selectors/widgets/withdrawals';
import validateWithdrawals from 'utils/widgets/withdrawals/validate';
import { CREATE_WITHDRAWAL_FORM_NAME } from 'utils/constants';
import {
  WithdrawalsBankSelectionType,
  ImpsTransferType,
  WalletType,
  WidgetType,
  WithdrawalPayoutMethod,
} from 'utils/enums';
import FormField from 'components/form/FormField';
import { ICreateWithdrawalForm, IWithdrawalCreateDataFormField } from 'interfaces/widgets/withdrawals';

interface IProps {
  walletType: string;
}

const WithdrawalCreationData = ({
  walletType,
  handleSubmit,
  error,
  submitFailed,
  change,
  form,
}: InjectedFormProps & IProps) => {
  const dispatch = useDispatch();
  const [fields, setFields] = useState<IWithdrawalCreateDataFormField[]>([]);

  const [t] = useTranslation();

  const values: Partial<ICreateWithdrawalForm> = useSelector(getFormValues(form)) || {};

  const { payments_details, bankCodeType, bank_details, currency_code, transfer_type, amount } = values;
  const bankCode = get(bank_details, `bank_code`);
  const paymentsMethod = get(payments_details, `payments_method`);

  const bankCodes = useSelector(withdrawalBankCodesSelector);
  const paygigaBankCodes = useSelector(paygigaBankCodesSelector);
  const paygigaAvailableAmount = useSelector(paygigaAvailableAmountSelector);
  const paygigaCurrencyCode = useSelector(paygigaCurrencyCodeByIdSelector(amount as number));
  const branchCodes = useSelector(withdrawalBranchCodesSelector);
  const currencyList = useSelector(withdrawalCurrencyCodesSelector);
  const payoutMethods = useSelector(withdrawalPayoutMethodsSelector);
  const providerList = useSelector(withdrawalProvidersSelector);
  const { listLoading: bankCodesLoading } = useSelector<IRootState, Record<`listLoading`, boolean>>(
    widgetSelector(WidgetType.WITHDRAWALS_BANK_CODES)
  );
  const { listLoading: paygigaBankCodesLoading } = useSelector<IRootState, Record<`listLoading`, boolean>>(
    widgetSelector(WidgetType.PAYGIGA_BANK_CODES)
  );
  const { listLoading: paygigaAvailableAmountLoading } = useSelector<IRootState, Record<`listLoading`, boolean>>(
    widgetSelector(WidgetType.PAYGIGA_AVAILABLE_AMOUNTS)
  );
  const { listLoading: branchCodesLoading } = useSelector<IRootState, Record<`listLoading`, boolean>>(
    widgetSelector(WidgetType.WITHDRAWALS_BRANCH_CODES)
  );
  const { listLoading: currencyCodesLoading } = useSelector<IRootState, Record<`listLoading`, boolean>>(
    widgetSelector(WidgetType.WITHDRAWALS_CURRENCY_CODES)
  );
  const { listLoading: providersLoading } = useSelector<IRootState, Record<`listLoading`, boolean>>(
    widgetSelector(WidgetType.WITHDRAWALS_PROVIDERS)
  );
  const { listLoading: payoutMethodsLoading } = useSelector<IRootState, Record<`listLoading`, boolean>>(
    widgetSelector(WidgetType.WITHDRAWALS_PAYOUT_METHODS)
  );

  useEffect(() => {
    if (walletType === WalletType.PAYGIGA) {
      dispatch(paygigaBankCodesActionCreators.getList());
    } else {
      dispatch(withdrawalsCurrencyCodesActionCreators.getList(walletType));
    }
  }, [walletType]);

  useEffect(() => {
    if (walletType === WalletType.NAGAD || walletType === WalletType.BKASH || walletType === WalletType.ROCKET) {
      change(`account_number`, `01`);
    }
  }, [walletType]);

  useEffect(() => {
    if (walletType === WalletType.NAGAD || walletType === WalletType.BKASH) {
      if (values.account_number && values.account_number?.length >= 11) {
        change(`account_number`, values.account_number?.slice(0, 11));
      }
    }

    if (walletType === WalletType.ROCKET) {
      if (values.account_number && values.account_number?.length >= 12) {
        change(`account_number`, values.account_number?.slice(0, 12));
      }
    }
  }, [values.account_number, walletType]);

  useEffect(() => {
    if (paygigaCurrencyCode) {
      change(`currency_code`, paygigaCurrencyCode);
    }
  }, [paygigaCurrencyCode]);

  useEffect(() => {
    if (walletType === WalletType.PAYGIGA) {
      change(`amount`, undefined);
    }

    const loadAvailableAmounts = walletType === WalletType.PAYGIGA && bankCode;
    if (loadAvailableAmounts) {
      dispatch(paygigaAvailableAmountsActionCreators.getList(bankCode));
    }
  }, [bankCode, walletType]);

  useEffect(() => {
    if (walletType === WalletType.IMPS_IB) {
      change(`bank_details.bank_code`, undefined);
    }
  }, [transfer_type]);

  useEffect(() => {
    let newFields: IWithdrawalCreateDataFormField[];

    switch (walletType) {
      case WalletType.IMPS_IB:
        const showIfscField = transfer_type === ImpsTransferType.OTHER_BANK_CLIENT;
        newFields = [
          {
            name: `withdrawal_id`,
            label: t(`withdrawals.withdrawalId`),
            maxLength: 36,
            trim: true,
          },
          {
            name: `label`,
            label: t(`common.label`),
            required: true,
            trim: true,
          },
          {
            name: `account_number`,
            label: t(`withdrawals.impsAccountNumber`),
            required: true,
            trim: true,
            digital: true,
          },
          {
            name: `amount`,
            label: t(`common.amount`),
            required: true,
            numeric: true,
          },
          {
            name: `currency_code`,
            id: `${form}_currency_code`,
            label: t(`common.currency`),
            type: InputType.SELECT,
            data: currencyList,
            busy: currencyCodesLoading,
            required: true,
          },
          {
            name: `account_name`,
            label: t(`withdrawals.impsReceiverName`),
            required: true,
            trim: true,
            maxLength: 191,
          },
          {
            name: `account_email`,
            label: t(`withdrawals.email`),
            email: true,
            trim: true,
            maxLength: 40,
          },
          {
            name: `bank_details.bank_code`,
            label: t(`withdrawals.impsIfsc`),
            required: true,
            trim: true,
          },
          {
            name: `comment`,
            label: t(`withdrawals.comment`),
            type: InputType.TEXT_EDITOR,
            required: false,
            maxLength: 30,
          },
        ];
        break;
      case WalletType.YAAR_PAY:
        newFields = [
          {
            name: `withdrawal_id`,
            label: t(`withdrawals.withdrawalId`),
            maxLength: 36,
            trim: true,
          },
          {
            name: `label`,
            label: t(`common.label`),
            required: true,
            trim: true,
          },
          {
            name: `amount`,
            label: t(`common.amount`),
            required: true,
            numeric: true,
          },
          {
            name: `account_number`,
            label: t(`wallets.yaarpayAccountNo`),
            required: true,
            trim: true,
            maxLength: 40,
          },
          {
            name: `account_name`,
            label: t(`wallets.yaarpayAccountName`),
            required: true,
            trim: true,
            maxLength: 40,
          },
          {
            name: `bank_details.bank_code_in_payments_system`,
            label: t(`wallets.yaarpayBankCode`),
            required: true,
            trim: true,
            maxLength: 10,
          },
          {
            name: `bank_details.bank_code`,
            label: t(`wallets.yaarpayIfscCode`),
            required: true,
            trim: true,
            maxLength: 32,
          },
          {
            name: `currency_code`,
            id: `${form}_currency_code`,
            label: t(`common.currency`),
            type: InputType.SELECT,
            data: currencyList,
            busy: currencyCodesLoading,
            required: true,
          },
          {
            name: `comment`,
            label: t(`withdrawals.comment`),
            type: InputType.TEXT_EDITOR,
            required: false,
          },
        ];
        break;
      case WalletType.PAYGIGA:
        newFields = [
          {
            name: `withdrawal_id`,
            label: t(`withdrawals.withdrawalId`),
            maxLength: 36,
            trim: true,
          },
          {
            name: `label`,
            label: t(`common.label`),
            required: true,
            trim: true,
          },
          {
            name: `account_number`,
            label: t(`withdrawals.walletRecipientID`),
            required: true,
            trim: true,
          },
          {
            name: `account_name`,
            label: t(`withdrawals.walletRecipientName`),
            required: true,
            trim: true,
          },
          {
            name: `iban`,
            label: t(`withdrawals.paygigaIBAN`),
            required: true,
            trim: true,
          },
          {
            name: `bank_details.bank_code`,
            label: t(`withdrawals.paygigaBankSelection`),
            type: InputType.SELECT,
            data: paygigaBankCodes,
            busy: paygigaBankCodesLoading,
            required: true,
          },
          ...(bankCode
            ? [
                {
                  name: `amount`,
                  label: t(`common.amount`),
                  type: InputType.SELECT,
                  data: paygigaAvailableAmount,
                  busy: paygigaAvailableAmountLoading,
                  required: true,
                },
              ]
            : []),
          {
            name: `currency_code`,
            id: `${form}_currency_code`,
            label: t(`common.currency`),
            disabled: true,
            required: true,
          },
          {
            name: `comment`,
            label: t(`withdrawals.comment`),
            type: InputType.TEXT_EDITOR,
            required: false,
          },
        ];
        break;
      case WalletType.UPI_IB:
        newFields = [
          {
            name: `withdrawal_id`,
            label: t(`withdrawals.withdrawalId`),
            maxLength: 36,
            trim: true,
          },
          {
            name: `label`,
            label: t(`common.label`),
            required: true,
            trim: true,
          },
          {
            name: `amount`,
            label: t(`common.amount`),
            required: true,
            numeric: true,
          },
          {
            name: `currency_code`,
            id: `${form}_currency_code`,
            label: t(`common.currency`),
            type: InputType.SELECT,
            data: currencyList,
            busy: currencyCodesLoading,
            required: true,
          },
          {
            name: `account_number`,
            label: t(`withdrawals.walletRecipient`),
            trim: true,
            maxLength: 40,
            digital: true,
            required: true,
          },
          {
            name: `account_name`,
            label: t(`withdrawals.accountName`),
            trim: true,
            maxLength: 40,
          },
          {
            name: `account_email`,
            label: t(`withdrawals.email`),
            email: true,
            trim: true,
          },
          {
            name: `payments_details.payments_method`,
            label: t(`withdrawals.paymentsMethod`),
            trim: true,
          },
          {
            name: `payments_details.payments_provider`,
            label: t(`withdrawals.paymentsProvider`),
            trim: true,
          },
          {
            name: `bank_details.bank_code`,
            label: t(`withdrawals.bankCode`),
            trim: true,
          },
          {
            name: `bank_details.branch_code`,
            label: t(`withdrawals.branchCode`),
            trim: true,
          },
          {
            name: `bank_details.bank_code_in_payments_system`,
            label: t(`withdrawals.bankCodeInPaymentsSystem`),
            trim: true,
          },
          {
            name: `comment`,
            label: t(`withdrawals.comment`),
            type: InputType.TEXT_EDITOR,
            required: false,
          },
        ];
        break;
      case WalletType.PAY_TM:
        newFields = [
          {
            name: `withdrawal_id`,
            label: t(`withdrawals.withdrawalId`),
            maxLength: 36,
            trim: true,
          },
          {
            name: `label`,
            label: t(`common.label`),
            required: true,
            trim: true,
          },
          {
            name: `amount`,
            label: t(`common.amount`),
            required: true,
            numeric: true,
          },
          {
            name: `currency_code`,
            id: `${form}_currency_code`,
            label: t(`common.currency`),
            type: InputType.SELECT,
            data: currencyList,
            busy: currencyCodesLoading,
            required: true,
          },
          {
            name: `account_number`,
            label: t(`withdrawals.walletRecipient`),
            type: InputType.PHONE_NUMBER,
            required: true,
            disableDropdown: true,
            countryButtonHidden: true,
            disableCountryCode: true,
            initialValue: `91`,
          },
          {
            name: `account_name`,
            label: t(`withdrawals.accountName`),
            trim: true,
            maxLength: 40,
          },
          {
            name: `account_email`,
            label: t(`withdrawals.email`),
            email: true,
            trim: true,
          },
          {
            name: `payments_details.payments_method`,
            label: t(`withdrawals.paymentsMethod`),
            trim: true,
          },
          {
            name: `payments_details.payments_provider`,
            label: t(`withdrawals.paymentsProvider`),
            trim: true,
          },
          {
            name: `bank_details.bank_code`,
            label: t(`withdrawals.bankCode`),
            trim: true,
          },
          {
            name: `bank_details.branch_code`,
            label: t(`withdrawals.branchCode`),
            trim: true,
          },
          {
            name: `bank_details.bank_code_in_payments_system`,
            label: t(`withdrawals.bankCodeInPaymentsSystem`),
            trim: true,
          },
          {
            name: `comment`,
            label: t(`withdrawals.comment`),
            type: InputType.TEXT_EDITOR,
            required: false,
          },
        ];
        break;
      case WalletType.BKASH:
        newFields = [
          {
            name: `withdrawal_id`,
            label: t(`withdrawals.withdrawalId`),
            maxLength: 36,
            trim: true,
          },
          {
            name: `label`,
            label: t(`common.label`),
            required: true,
            trim: true,
          },
          {
            name: `amount`,
            label: t(`common.amount`),
            required: true,
            amount: true,
          },
          {
            name: `currency_code`,
            id: `${form}_currency_code`,
            label: t(`common.currency`),
            type: InputType.SELECT,
            data: currencyList,
            busy: currencyCodesLoading,
            required: true,
          },
          {
            name: `account_number`,
            label: t(`withdrawals.walletRecipient`),
            trim: true,
            maxLength: 11,
            required: true,
            regexMatch: {
              regex: /^01\d{9}$/,
              message: t(`errors.invalidFormat`),
            },
            digital: true,
            placeholder: `01xxxxxxxxx`,
          },
          {
            name: `account_name`,
            label: t(`withdrawals.accountName`),
            trim: true,
            maxLength: 40,
          },
          {
            name: `account_email`,
            label: t(`withdrawals.email`),
            email: true,
            trim: true,
            required: true,
          },
          {
            name: `payments_details.payments_method`,
            label: t(`withdrawals.paymentsMethod`),
            trim: true,
          },
          {
            name: `payments_details.payments_provider`,
            label: t(`withdrawals.paymentsProvider`),
            trim: true,
          },
          {
            name: `bank_details.bank_code`,
            label: t(`withdrawals.bankCode`),
            trim: true,
          },
          {
            name: `bank_details.branch_code`,
            label: t(`withdrawals.branchCode`),
            trim: true,
          },
          {
            name: `bank_details.bank_code_in_payments_system`,
            label: t(`withdrawals.bankCodeInPaymentsSystem`),
            trim: true,
          },
          {
            name: `comment`,
            label: t(`withdrawals.comment`),
            type: InputType.TEXT_EDITOR,
            required: false,
          },
        ];
        break;
      case WalletType.NAGAD:
        newFields = [
          {
            name: `withdrawal_id`,
            label: t(`withdrawals.withdrawalId`),
            maxLength: 36,
            trim: true,
          },
          {
            name: `label`,
            label: t(`common.label`),
            required: true,
            trim: true,
          },
          {
            name: `amount`,
            label: t(`common.amount`),
            required: true,
            amount: true,
          },
          {
            name: `currency_code`,
            id: `${form}_currency_code`,
            label: t(`common.currency`),
            type: InputType.SELECT,
            data: currencyList,
            busy: currencyCodesLoading,
            required: true,
          },
          {
            name: `account_number`,
            label: t(`withdrawals.walletRecipient`),
            trim: true,
            maxLength: 11,
            required: true,
            regexMatch: {
              regex: /^01\d{9}$/,
              message: t(`errors.invalidFormat`),
            },
            digital: true,
            placeholder: `01xxxxxxxxx`,
          },
          {
            name: `account_name`,
            label: t(`withdrawals.accountName`),
            trim: true,
            maxLength: 40,
          },
          {
            name: `account_email`,
            label: t(`withdrawals.email`),
            email: true,
            trim: true,
          },
          {
            name: `payments_details.payments_method`,
            label: t(`withdrawals.paymentsMethod`),
            trim: true,
          },
          {
            name: `payments_details.payments_provider`,
            label: t(`withdrawals.paymentsProvider`),
            trim: true,
          },
          {
            name: `bank_details.bank_code`,
            label: t(`withdrawals.bankCode`),
            trim: true,
          },
          {
            name: `bank_details.branch_code`,
            label: t(`withdrawals.branchCode`),
            trim: true,
          },
          {
            name: `bank_details.bank_code_in_payments_system`,
            label: t(`withdrawals.bankCodeInPaymentsSystem`),
            trim: true,
          },
          {
            name: `comment`,
            label: t(`withdrawals.comment`),
            type: InputType.TEXT_EDITOR,
            required: false,
          },
        ];
        break;
      case WalletType.ROCKET:
        newFields = [
          {
            name: `withdrawal_id`,
            label: t(`withdrawals.withdrawalId`),
            maxLength: 36,
            trim: true,
          },
          {
            name: `label`,
            label: t(`common.label`),
            required: true,
            trim: true,
          },
          {
            name: `amount`,
            label: t(`common.amount`),
            required: true,
            amount: true,
          },
          {
            name: `currency_code`,
            id: `${form}_currency_code`,
            label: t(`common.currency`),
            type: InputType.SELECT,
            data: currencyList,
            busy: currencyCodesLoading,
            required: true,
          },
          {
            name: `account_number`,
            label: t(`withdrawals.walletRecipient`),
            trim: true,
            maxLength: 40,
            required: true,
            regexMatch: {
              regex: /^01\d{9,10}$/,
              message: t(`errors.invalidFormat`),
            },
            digital: true,
            placeholder: `01xxxxxxxxxx`,
          },
          {
            name: `account_name`,
            label: t(`withdrawals.accountName`),
            trim: true,
            maxLength: 40,
          },
          {
            name: `account_email`,
            label: t(`withdrawals.email`),
            email: true,
            trim: true,
          },
          {
            name: `payments_details.payments_method`,
            label: t(`withdrawals.paymentsMethod`),
            trim: true,
          },
          {
            name: `payments_details.payments_provider`,
            label: t(`withdrawals.paymentsProvider`),
            trim: true,
          },
          {
            name: `bank_details.bank_code`,
            label: t(`withdrawals.bankCode`),
            trim: true,
          },
          {
            name: `bank_details.branch_code`,
            label: t(`withdrawals.branchCode`),
            trim: true,
          },
          {
            name: `bank_details.bank_code_in_payments_system`,
            label: t(`withdrawals.bankCodeInPaymentsSystem`),
            trim: true,
          },
          {
            name: `comment`,
            label: t(`withdrawals.comment`),
            type: InputType.TEXT_EDITOR,
            required: false,
          },
        ];
        break;
      default:
        newFields = [
          {
            name: `withdrawal_id`,
            label: t(`withdrawals.withdrawalId`),
            maxLength: 36,
            trim: true,
          },
          {
            name: `label`,
            label: t(`common.label`),
            required: true,
            trim: true,
          },
          {
            name: `amount`,
            label: t(`common.amount`),
            required: true,
            amount: true,
          },
          {
            name: `currency_code`,
            id: `${form}_currency_code`,
            label: t(`common.currency`),
            type: InputType.SELECT,
            data: currencyList,
            busy: currencyCodesLoading,
            required: true,
          },
          {
            name: `account_number`,
            label: t(`withdrawals.walletRecipient`),
            trim: true,
            maxLength: 40,
            required: true,
          },
          {
            name: `account_name`,
            label: t(`withdrawals.accountName`),
            trim: true,
            maxLength: 40,
          },
          {
            name: `account_email`,
            label: t(`withdrawals.email`),
            email: true,
            trim: true,
          },
          {
            name: `payments_details.payments_method`,
            label: t(`withdrawals.paymentsMethod`),
            trim: true,
          },
          {
            name: `payments_details.payments_provider`,
            label: t(`withdrawals.paymentsProvider`),
            trim: true,
          },
          {
            name: `bank_details.bank_code`,
            label: t(`withdrawals.bankCode`),
            trim: true,
          },
          {
            name: `bank_details.branch_code`,
            label: t(`withdrawals.branchCode`),
            trim: true,
          },
          {
            name: `bank_details.bank_code_in_payments_system`,
            label: t(`withdrawals.bankCodeInPaymentsSystem`),
            trim: true,
          },
          {
            name: `comment`,
            label: t(`withdrawals.comment`),
            type: InputType.TEXT_EDITOR,
            required: false,
          },
        ];
    }

    if (!isEqual(fields, newFields)) {
      setFields(newFields);
    }
  }, [
    values,
    currencyList,
    bankCodes,
    branchCodes,
    providerList,
    payoutMethods,
    paygigaBankCodes,
    paygigaAvailableAmount,
    paygigaCurrencyCode,
  ]);

  return (
    <form onSubmit={handleSubmit}>
      {map(fields, (field, key) => {
        const fieldId = get(field, `name`) || key;

        return field ? <FormField key={fieldId} isModalField {...field} /> : null;
      })}
      {submitFailed && error && <ErrorBlock error={error} />}
    </form>
  );
};

export default reduxForm<any, any, any>({
  form: CREATE_WITHDRAWAL_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateWithdrawals,
})(WithdrawalCreationData);
