import { toastr } from 'react-redux-toastr';
import { call, select, put } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import { ToastrEmitter } from 'react-redux-toastr';
import get from 'lodash/get';
import { AxiosError } from 'axios';
import { REFRESH_TOKEN_FAILED } from '@kassma-team/kassma-toolkit/lib/actionTypes';
import {
  IRefreshSagaParams,
  LOGOUT_REQUESTED,
  refreshTokenRequest,
  SET_TOKEN,
  userSelector,
} from '@kassma-team/kassma-toolkit';
import { REFRESH_TOKEN_SUCCEEDED } from '@kassma-team/kassma-toolkit/lib';
import { refreshPromiseSelector } from '@kassma-team/kassma-toolkit/lib/selectors/auth';

import i18n from 'i18n';
import { getErrorMessage } from 'utils';
import api from 'api';
import { tokenSelector } from '../../selectors/auth';

export const TOKEN_REFRESHING_FAILED_MESSAGE = `Token refreshing has been failed`;

function* resetUserSaga(e: any) {
  const message = e.message;
  const status = get(e, `response.status`);
  if (message === TOKEN_REFRESHING_FAILED_MESSAGE || [401, 500].includes(status)) {
    yield put({ type: REFRESH_TOKEN_FAILED });
    yield put(push(`/login`));
    yield localStorage.removeItem(`token`);
    yield localStorage.removeItem(`refresh_token`);
    yield localStorage.removeItem(`from_sa`);
  }
}

export interface IGetRefreshSagaParams {
  toastr: ToastrEmitter;
}

// Todo: get rid of @ts-ignore
export const getRefreshSaga = ({ toastr }: IGetRefreshSagaParams) =>
  function* refreshSaga({
    request,
    onError,
    onSuccess,
    onFinally,
    redirectWhenNoPermissions = false,
    callErrorWhenNoPermissions = false,
    showToastrWhenNoPermissions = true,
  }: IRefreshSagaParams) {
    if (!onError) {
      onError = (err: any) => {
        toastr.error(i18n.t(`common.error`), getErrorMessage(err as AxiosError) as string);
      };
    }

    const refresh_token = localStorage.getItem(`refresh_token`) || ``;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let refreshPromise = yield select(refreshPromiseSelector);
    if (!refreshPromise) {
      let resp;
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resp = yield call(request);
        if (resp?.status !== 401 && onSuccess) {
          yield call(onSuccess, resp);
        }
      } catch (e) {
        const status = get(e, `response.status`);
        if (status === 403) {
          if (redirectWhenNoPermissions) {
            yield put(replace(`/no-permissions`));
          } else if (showToastrWhenNoPermissions) {
            toastr.error(i18n.t(`common.error`), i18n.t(`common.noRights`));
          }

          if (callErrorWhenNoPermissions) {
            yield call(onError, e);
          }
        } else if (status !== 401 && onError) {
          yield call(onError, e);
        }
      } finally {
        if (onFinally) {
          yield call(onFinally);
        }
      }
      if (resp && resp.status === 401 && !!refresh_token) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        refreshPromise = yield select(refreshPromiseSelector);
        if (!refreshPromise) {
          try {
            const refreshToken = localStorage.getItem(`from_sa`)
              ? api.auth.refreshExchangeToken
              : api.auth.refreshToken;
            const promise = refreshToken({ refresh_token });
            yield put(refreshTokenRequest(promise));
            const { data, status } = yield promise;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line no-inner-declarations
            function* setToken(token: string, refresh_token: string, tokenData: any) {
              yield put({ type: REFRESH_TOKEN_SUCCEEDED, payload: tokenData });
              yield localStorage.setItem(`refresh_token`, refresh_token);
              yield localStorage.setItem(`token`, token);
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const resp = yield call(request);
              yield put({ type: SET_TOKEN, payload: token });
              if (onSuccess) {
                yield call(onSuccess, resp);
              }
            }

            if (status === 401) {
              const token: string = yield select(tokenSelector);
              const currToken: string = localStorage.getItem(`token`) || ``;
              const currRefreshToken: string = localStorage.getItem(`refresh_token`) || ``;
              if (token !== currToken) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const user = yield select(userSelector);
                yield setToken(token, currRefreshToken, {
                  token,
                  refresh_token: currRefreshToken,
                  status: `ok`,
                  user,
                });
              }
              throw new Error(TOKEN_REFRESHING_FAILED_MESSAGE);
            } else if (status === 500) {
              throw new Error(TOKEN_REFRESHING_FAILED_MESSAGE);
            } else {
              yield setToken(data.data.token, data.data.refresh_token, data.data);
            }
          } catch (e) {
            if (onError) {
              yield call(onError, e);
            }
            yield resetUserSaga(e);
          } finally {
            if (onFinally) {
              yield call(onFinally);
            }
          }
        }
      } else if (resp && resp.status === 401) {
        const e = new Error(TOKEN_REFRESHING_FAILED_MESSAGE);
        if (onError) {
          yield call(onError, e);
        }
        yield resetUserSaga(e);
      }
    }

    if (refreshPromise) {
      try {
        yield refreshPromise;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const resp = yield call(request);
        if (onSuccess) {
          yield call(onSuccess, resp);
        }
      } catch (e) {
        if (onError) {
          yield call(onError, e);
        }
        yield resetUserSaga(e);
      } finally {
        if (onFinally) {
          yield call(onFinally);
        }
      }
    }
  };

const refreshSaga: any = getRefreshSaga({
  toastr,
});

export default refreshSaga;
