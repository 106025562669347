import React, { useEffect, useMemo, useState } from 'react';
import useMount from 'react-use/lib/useMount';
import useUnmount from 'react-use/lib/useUnmount';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, getFormValues, InjectedFormProps } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { widgetModalSelector } from '@kassma-team/kassma-toolkit/lib';

import { CREATE_PAYMENT_SORTING_FORM_NAME } from 'utils/constants';
import { WidgetType } from 'utils/enums';
import {
  createPaymentsSorting,
  getPaymentOfCurrencies,
  resetCreateSortingModalData,
  settingsActionCreators,
} from 'actions/widgets/settings';
import { currencyListForSortingSelector, defaultPaymentsByCurrencySelector } from 'selectors/widgets/settings';
import usePaymentsSortingList from 'hooks/widgets/usePaymentsSortingList';
import { IAppliedPaymentSystems, ISettingsStateModal } from 'interfaces/widgets/settings';

import ModalElement from 'components/modals/ModalElement';
import FormField from 'components/form/FormField';
import DragNDropOrder from 'components/widgets/settings/pluginSettings/paymentSorting/DragNDropOrder';
import { IPaymentSortedList } from 'interfaces/widgets/widgets';

const CreateSortingModal = (props: InjectedFormProps) => {
  const [sorting, setSorting] = useState<IPaymentSortedList[]>([]);

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { currency: initialCurrency }: ISettingsStateModal | Record<string, unknown> =
    useSelector(widgetModalSelector(WidgetType.SETTINGS)) || {};
  const { currency }: Record<string, any> = useSelector(getFormValues(CREATE_PAYMENT_SORTING_FORM_NAME)) || {};
  const currencyList = useSelector(currencyListForSortingSelector);
  const defaultPayments = useSelector(defaultPaymentsByCurrencySelector(currency));

  useMount(() => {
    dispatch(getPaymentOfCurrencies());

    if (initialCurrency) {
      props.change(`currency`, initialCurrency);
    }
  });

  useUnmount(() => dispatch(resetCreateSortingModalData()));

  const { getSortingList, sortingListsAreEqual } = usePaymentsSortingList();

  const defaultSorting: IPaymentSortedList[] = useMemo(
    () => getSortingList(defaultPayments as IAppliedPaymentSystems[]),
    [defaultPayments]
  );

  useEffect(() => setSorting(defaultSorting), [defaultSorting]);

  const content = (
    <form>
      <FormField name="currency" label={t(`common.currency`)} type="select" required data={currencyList} />
      {currency ? (
        <>
          <DragNDropOrder
            sorting={sorting}
            defaultSorting={defaultSorting}
            currency={currency}
            setSorting={setSorting}
            submitAction={createPaymentsSorting}
            sortingListsAreEqual={sortingListsAreEqual}
          />
        </>
      ) : (
        t(`settings.selectCurrencyToDisplayPS`)
      )}
    </form>
  );

  return <ModalElement actionCreators={settingsActionCreators} content={content} title="settings.addingDisplayOrder" />;
};

export default reduxForm({
  form: CREATE_PAYMENT_SORTING_FORM_NAME,
})(CreateSortingModal);
