import { takeEvery, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import toNumber from 'lodash/toNumber';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import each from 'lodash/each';
import { validateForm, WIDGET_SET_SUBMITTING_STATUS, IWidgetsMeta, IAction } from '@kassma-team/kassma-toolkit/lib';
import { AxiosError, AxiosResponse } from 'axios';

import { getErrorMessage } from 'utils';
import i18n from 'i18n';

import refreshSaga from 'sagas/effects/refreshSaga';
import {
  DEPOSITS_REQUESTED,
  DEPOSITS_SUCCEEDED,
  PAYMENT_CURRENCIES_REQUESTED,
  PAYMENT_CURRENCIES_SUCCEEDED,
  UPDATE_DEPOSITS_REQUESTED,
  UPDATE_PAYMENT_CURRENCIES_REQUESTED,
  UPDATE_PAYMENT_CURRENCIES_SUCCEEDED,
  PAYMENT_VIEW_DATA_SUCCEEDED,
  PAYMENT_VIEW_DATA_REQUESTED,
  UPDATE_PAYMENT_VIEW_DATA_REQUESTED,
  UPDATE_PAYMENTS_PLUGIN_PARAMS_REQUESTED,
  PAYMENT_WORKING_TIME_DATA_SUCCEEDED,
  PAYMENT_WORKING_TIME_DATA_REQUESTED,
  PAYMENT_TRANSLATIONS_DATA_REQUESTED,
  LOAD_PAYMENT_PROXY_REQUESTED,
  GET_REQUISITES,
  CREATE_PAYMENT_SYSTEM,
  UPDATE_WALLET_TYPE_SETTINGS,
} from 'actionTypes';
import {
  changeSettings,
  getPaymentCurrencies,
  getPaymentTranslationsDataSucceeded,
  paymentsConstructorActionCreators,
} from 'actions/widgets/constructors/paymentsConstructor';
import { PAYMENT_SETTINGS_FORM_NAME, PAYMENT_VIEW_FORM_NAME } from 'utils/constants';

import { IUpdatePSCurrenciesData } from 'interfaces/widgets/paymentSystems';
import {
  ILimitsFormData,
  IUpdateDepositsPreparedData,
  IUpdateViewData,
} from 'interfaces/widgets/constructor/paymentsConstructor';

import limitsApi from 'api/constructors/LimitsApi';
import workingTimeApi from 'api/constructors/WorkingTimeApi';
import proxySettingsApi from 'api/constructors/ProxySettingsApi';
import { availableTranslationsApi } from 'api/constructors/AvailableTranslationsApi';
import walletTypes from 'api/walletTypes/WalletTypes';
import walletTypeApi from '../../../api/walletType/WalletTypeApi';
import walletTypesToCurrenciesApi from '../../../api/walletTypes/WalletTypeToCurrenciesApi';
import { paymentSystemsActionCreators } from '../../../actions/widgets/paymentSystem';
import paymentSystemsApi from 'api/walletTypes/WalletTypes';

interface ILoadPaymentCurrenciesProps {
  payload: string;
  meta: IWidgetsMeta;
}

function* getRequisitesSaga({ meta }: { meta: IWidgetsMeta }) {
  yield refreshSaga({
    request: () => walletTypeApi.getRequisites(meta.walletType),
    onSuccess: function* (resp: AxiosResponse) {
      const { data } = resp;
      yield put(paymentsConstructorActionCreators.setAnyData({ ...data.data }));
    },
  });
}

function* loadPaymentCurrenciesSaga({ payload: walletType, meta }: ILoadPaymentCurrenciesProps) {
  yield refreshSaga({
    request: () => walletTypesToCurrenciesApi.loadPaymentCurrencies(walletType),
    onSuccess: function* (resp: AxiosResponse) {
      yield put({
        type: PAYMENT_CURRENCIES_SUCCEEDED,
        meta,
        payload: {
          currencies: resp.data.data.items,
        },
      });
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

interface IUpdatePaymentCurrencies {
  payload: IUpdatePSCurrenciesData;
  meta: IWidgetsMeta;
}

function* updatePaymentCurrenciesSaga({ payload: data, meta }: IUpdatePaymentCurrencies) {
  const { walletType } = meta;
  yield put({ meta, type: WIDGET_SET_SUBMITTING_STATUS, payload: true });

  yield refreshSaga({
    request: () => walletTypesToCurrenciesApi.updatePaymentCurrencies(walletType, data),
    onSuccess: function* (resp: AxiosResponse) {
      yield put(paymentsConstructorActionCreators.getList());
      yield put(getPaymentCurrencies(walletType));
      yield put({ type: UPDATE_PAYMENT_CURRENCIES_SUCCEEDED, meta, payload: get(resp, `data.applied_currencies`) });
      yield put({ meta, type: WIDGET_SET_SUBMITTING_STATUS, payload: false });
      yield put(paymentsConstructorActionCreators.hideModal());
      toastr.success(i18n.t(`common.success`), i18n.t(`paymentConstructor.paymentDataHasBeenSuccessfullyUpdated`));
    },
    onError: function* (e: AxiosError) {
      yield put({ meta, type: WIDGET_SET_SUBMITTING_STATUS, payload: false });
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

interface ILoadViewDataSagaProps {
  payload: string;
  meta: IWidgetsMeta;
}

function* loadViewDataSaga({ payload, meta }: ILoadViewDataSagaProps) {
  yield refreshSaga({
    request: () => walletTypes.loadPaymentViewData(payload),
    onSuccess: function* (resp: AxiosResponse) {
      yield put({ meta, type: PAYMENT_VIEW_DATA_SUCCEEDED, payload: resp.data });
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

interface IUpdateViewDataProps {
  payload: IUpdateViewData;
  meta: IWidgetsMeta;
}

function* updateViewDataSaga({ payload, meta }: IUpdateViewDataProps) {
  const valid: boolean = yield validateForm({ form: PAYMENT_VIEW_FORM_NAME, meta });
  if (!valid) {
    return;
  }

  yield put({ meta, type: WIDGET_SET_SUBMITTING_STATUS, payload: true });

  const walletType = get(meta, `walletType`);

  interface IParsedData {
    targets?: Record<string, string | number>;
    deleted?: Record<string, number>;
  }

  const parsedData: IParsedData = {};

  if (payload.targets) {
    parsedData.targets = reduce(
      payload.targets,
      (result, target, index) => {
        if (!target) {
          return result;
        }

        const params: Record<string, string | number | boolean> = {};

        if (isObject(target.logo)) {
          params[`targets[${index}][logo]`] = target.logo;
        }

        if (target.id) {
          params[`targets[${index}][id]`] = target.id;
        }

        if (target.title) {
          params[`targets[${index}][title]`] = target.title;
        }

        if (target.currencies) {
          each(target.currencies, (currency, currencyIndex) => {
            params[`targets[${index}][currencies][${currencyIndex}]`] = currency;
          });
        }

        if (target.hint_requisites) {
          params[`targets[${index}][hint_requisites]`] = target.hint_requisites;
        }

        if (target.hint_payment) {
          params[`targets[${index}][hint_payment]`] = target.hint_payment;
        }

        if (target.field_requisites) {
          params[`targets[${index}][field_requisites]`] = target.field_requisites;
        }

        if (target.field_payment) {
          params[`targets[${index}][field_payment]`] = target.field_payment;
        }

        params[`targets[${index}][show_limits]`] = target.show_limits;

        return {
          ...result,
          ...params,
        };
      },
      {}
    );
  }

  if (!isEmpty(payload.deleted)) {
    parsedData.deleted = reduce(
      payload.deleted,
      (result, id, index) => {
        return {
          ...result,
          [`deleted[${index}]`]: id,
        };
      },
      {}
    );
  } else {
    delete payload.deleted;
  }

  const data = {
    ...(parsedData.targets || {}),
    ...(parsedData.deleted || {}),
    is_default_enabled: payload.is_default_enabled ? `1` : `0`,
    show_limits: payload.show_limits,
  };

  yield refreshSaga({
    request: () => walletTypes.updatePaymentViewData(walletType, data),
    onSuccess: function* () {
      toastr.success(i18n.t(`common.success`), i18n.t(`paymentConstructor.paymentDataHasBeenSuccessfullyUpdated`));
      yield put(paymentsConstructorActionCreators.hideModal());
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
    onFinally: function* () {
      yield put({ meta, type: WIDGET_SET_SUBMITTING_STATUS, payload: false });
    },
  });
}

interface ILoadDepositsProps {
  payload: string;
  meta: IWidgetsMeta;
}

function* loadDepositsSaga({ payload: walletType, meta }: ILoadDepositsProps) {
  yield refreshSaga({
    request: () => limitsApi.loadDeposits(walletType),
    onSuccess: function* (resp: AxiosResponse) {
      yield put({ type: DEPOSITS_SUCCEEDED, meta, payload: resp.data.data });
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

interface IUpdateDepositsSagaProps {
  meta: IWidgetsMeta;
  payload: ILimitsFormData[];
}

function* updateDepositsSaga({ payload, meta }: IUpdateDepositsSagaProps) {
  yield put({ meta, type: WIDGET_SET_SUBMITTING_STATUS, payload: true });
  const valid: boolean = yield validateForm({ form: `update-payment-deposits`, meta });
  if (!valid) {
    return;
  }

  const preparedData = reduce(
    payload,
    (
      result: IUpdateDepositsPreparedData[],
      { maxDeposit, minDeposit, minWithdrawal, maxWithdrawal, minStockpiling },
      key
    ) => {
      return [
        ...result,
        {
          currency: key,
          limits: {
            deposit: {
              min: minDeposit || minDeposit === 0 ? toNumber(minDeposit) : null,
              max: maxDeposit || maxDeposit === 0 ? toNumber(maxDeposit) : null,
            },
            withdrawal: {
              min: minWithdrawal || minWithdrawal === 0 ? toNumber(minWithdrawal) : null,
              max: maxWithdrawal || maxWithdrawal === 0 ? toNumber(maxWithdrawal) : null,
            },
            stockpiling: {
              min: minStockpiling === 0 ? 0 : !minStockpiling ? null : toNumber(minStockpiling),
            },
          },
        },
      ];
    },
    []
  );

  yield refreshSaga({
    request: () => limitsApi.updateDeposits(meta.walletType, preparedData),
    onSuccess: function* () {
      yield put({ meta, type: WIDGET_SET_SUBMITTING_STATUS, payload: false });
      toastr.success(i18n.t(`common.success`), i18n.t(`paymentConstructor.depositsHaveBeenSuccessfullyUpdated`));
      yield put(paymentsConstructorActionCreators.hideModal());
    },
    onError: function* (e: AxiosError) {
      yield put({ meta, type: WIDGET_SET_SUBMITTING_STATUS, payload: false });
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

interface IUpdatePaymentsPluginParamsProps {
  payload: Record<string, string | boolean>;
  meta: IWidgetsMeta;
}

function* updatePaymentsPluginParamsSaga({ payload, meta }: IUpdatePaymentsPluginParamsProps) {
  payload.wallet_type = meta.walletType;

  yield refreshSaga({
    request: () => walletTypeApi.updateWalletTypesSettings(payload),
    onSuccess: function (resp: AxiosResponse) {
      const type = get(resp, `data.type`);
      if (type === `selenium` && payload.show_on_plugin === true) {
        toastr.warning(i18n.t(`common.attention`), i18n.t(`paymentConstructor.walletUsesSelenium`));
      } else {
        toastr.success(i18n.t(`common.success`), i18n.t(`paymentConstructor.statusHasBeenUpdated`));
      }
      if (meta.onSuccess) meta.onSuccess();
    },
    onError: function (e: AxiosError) {
      // eslint-disable-next-line
      // @ts-ignore
      toastr.error(i18n.t(`common.error`), getErrorMessage(e), { preventDuplicates: false });
      if (meta.onError) meta.onError();
    },
    onFinally: meta.onFinally,
  });
}

interface ITimeData {
  all_time: boolean;
  begin: string;
  end: string;
  timezone: string;
}

interface IUpdatePaymentSettingsProps {
  payload: ITimeData;
  meta: IWidgetsMeta;
}

interface ILoadWorkingTimeDataProps {
  payload: string;
  meta: IWidgetsMeta;
}

function* loadWorkingTimeDataSaga({ payload, meta }: ILoadWorkingTimeDataProps) {
  yield refreshSaga({
    request: () => workingTimeApi.loadPaymentWorkingTimeData(payload),
    onSuccess: function* (resp: AxiosResponse) {
      yield put({ meta, type: PAYMENT_WORKING_TIME_DATA_SUCCEEDED, payload: resp.data });
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

interface ILoadHintSelectionDataSagas {
  payload: string;
  meta: IWidgetsMeta;
}

function* loadHintSelectionDataSaga({ payload, meta }: ILoadHintSelectionDataSagas) {
  yield refreshSaga({
    request: () => availableTranslationsApi.getList(payload),
    onSuccess: function* (resp: AxiosResponse) {
      const data = {
        hints: resp?.data?.hints,
        fields: resp?.data?.fields,
      };
      yield put(getPaymentTranslationsDataSucceeded(data));
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

function* loadProxyDataSaga({ meta }: IAction<void>) {
  const { walletType } = meta;
  yield refreshSaga({
    request: () => proxySettingsApi.loadProxyData(walletType),
    onSuccess: function* (resp: AxiosResponse) {
      const data = {
        use_proxy: resp?.data?.use_proxy,
        use_proxy_night_parser: resp?.data?.use_proxy_night_parser,
      };
      yield put(paymentsConstructorActionCreators.setAnyData(data));
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

function* createPaymentSystem({ payload }: IAction) {
  const finalValues = payload;
  const forms = [`requisite`, `payment`];
  let formIndex = 0;
  for (const form of forms) {
    finalValues[form].forEach((value: any) => {
      finalValues[`hints[${formIndex}][lang]`] = value.lang;
      finalValues[`hints[${formIndex}][text]`] = value.form_text;
      finalValues[`hints[${formIndex}][form_type]`] = form;
      formIndex++;
    });
    delete finalValues[form];
  }
  finalValues[`limits[deposit][min]`] = +finalValues.limits.deposit.min;
  finalValues[`limits[deposit][max]`] = +finalValues.limits.deposit.max;
  delete finalValues.limits;
  finalValues.requisites.forEach((value: any, index: any) => {
    finalValues[`requisites[${index}][code]`] = value.code;
  });
  delete finalValues.requisites;
  finalValues.show_transaction_id = 1;
  finalValues.show_payment_bill = finalValues.show_payment_bill ? 1 : 0;
  yield refreshSaga({
    request: () => walletTypeApi.createNewPaymentSystem(finalValues),
    onSuccess: function* () {
      toastr.success(i18n.t(`common.success`), i18n.t(`paymentConstructor.statusHasBeenUpdated`));
      yield put(paymentSystemsActionCreators.getList());
      yield put(paymentsConstructorActionCreators.hideModal());
    },
    onError: function (e: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(e, { normalize: true }));
    },
  });
}

function* updateWalletTypeSettingsSaga({ payload: data, meta }: IAction) {
  const valid: boolean = yield validateForm({ form: PAYMENT_SETTINGS_FORM_NAME, meta });

  if (!valid) {
    return;
  }

  const { walletType } = meta;
  yield put({ meta, type: WIDGET_SET_SUBMITTING_STATUS, payload: true });

  yield refreshSaga({
    //@ts-ignore
    request: () =>
      walletTypeApi.updateWalletTypesSettings({
        wallet_type: walletType,
        ...data,
      }),
    onSuccess: function* () {
      yield put({ meta, type: WIDGET_SET_SUBMITTING_STATUS, payload: false });
      yield put(
        paymentsConstructorActionCreators.setAnyData({
          allowed_update_requisites: data.allowed_update_requisites,
        })
      );
      yield put(
        changeSettings({
          walletType,
          settings: data,
        })
      );
      yield put(paymentSystemsActionCreators.getList());
      toastr.success(i18n.t(`common.success`), i18n.t(`paymentConstructor.statusHasBeenUpdated`));
      yield put(paymentsConstructorActionCreators.hideModal());
    },
    onError: function* (e: AxiosError) {
      yield put({ meta, type: WIDGET_SET_SUBMITTING_STATUS, payload: false });
      toastr.error(i18n.t(`common.error`), getErrorMessage(e));
    },
  });
}

const paymentsConstructorSagas = [
  takeEvery<IAction>(PAYMENT_CURRENCIES_REQUESTED, loadPaymentCurrenciesSaga),
  takeEvery<IAction>(UPDATE_PAYMENT_CURRENCIES_REQUESTED, updatePaymentCurrenciesSaga),
  takeEvery<IAction>(DEPOSITS_REQUESTED, loadDepositsSaga),
  takeEvery<IAction>(UPDATE_DEPOSITS_REQUESTED, updateDepositsSaga),
  takeEvery<IAction>(PAYMENT_VIEW_DATA_REQUESTED, loadViewDataSaga),
  takeEvery<IAction>(UPDATE_PAYMENT_VIEW_DATA_REQUESTED, updateViewDataSaga),
  takeEvery<IAction>(UPDATE_PAYMENTS_PLUGIN_PARAMS_REQUESTED, updatePaymentsPluginParamsSaga),
  takeEvery<IAction>(PAYMENT_WORKING_TIME_DATA_REQUESTED, loadWorkingTimeDataSaga),
  takeEvery<IAction>(PAYMENT_TRANSLATIONS_DATA_REQUESTED, loadHintSelectionDataSaga),
  takeEvery<IAction>(LOAD_PAYMENT_PROXY_REQUESTED, loadProxyDataSaga),
  takeEvery<IAction>(GET_REQUISITES, getRequisitesSaga),
  takeEvery<IAction>(CREATE_PAYMENT_SYSTEM, createPaymentSystem),
  takeEvery<IAction>(UPDATE_WALLET_TYPE_SETTINGS, updateWalletTypeSettingsSaga),
];

export default paymentsConstructorSagas;
