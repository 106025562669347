import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';

export class PaytmCookiesLogApi extends BaseApi {
  loadPaytmCookies = (): AxiosPromise => this.makeFetch(`list`);

  uploadPaytmCookies = (data: string): AxiosPromise =>
    this.makeFetch(`upload`, { method: `POST`, data, formData: true });

  deletePaytmCookie = (file_name: string): AxiosPromise =>
    this.makeFetch(`delete`, { queryParams: { file_name }, method: `DELETE` });

  downloadCookies = (token: string): AxiosPromise =>
    this.makeFetch(`download-all-zip?token=${token}`, { method: `GET` });
}

const paytmCookiesLogApi = new PaytmCookiesLogApi(`log/paytm_cookies`);

export default paytmCookiesLogApi;
