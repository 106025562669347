export const DATETIME_FORMAT = `YYYY-MM-DD hh:mm:ss`;

export const DEFAULT_ROUTE = `/`;

export const DOLAR_DOLAR_HOST = `http://www.dolardolar.com/`;

export const PAYMENT_HINT_DEFAULT = 1;

// Form names

export const UPDATE_WALLET_FORM_NAME = `update-wallet`;
export const CREATE_WALLET_FORM_NAME = `create-wallet`;

export const CREATE_WITHDRAWAL_FORM_NAME = `create-withdrawal`;

export const UPDATE_PROXY_FORM_NAME = `update-proxy`;
export const CREATE_PROXY_FORM_NAME = `create-proxy`;

export const UPDATE_ROLE_FORM_NAME = `update-role`;
export const CREATE_ROLE_FORM_NAME = `create-role`;

export const UPDATE_USER_FORM_NAME = `update-user`;
export const CREATE_USER_FORM_NAME = `create-user`;

export const UPDATE_PAYMENT_SYSTEM_FORM_NAME = `update-payment-system`;
export const CREATE_PAYMENT_SYSTEM_FORM_NAME = `create-payment-system`;

export const UPDATE_PAYMENT_HINT_FORM_NAME = `update-payment-hint`;
export const CREATE_PAYMENT_HINT_FORM_NAME = `create-payment-hint`;

export const CREATE_PRELIMINARY_TRANSACTION_FORM_NAME = `create-preliminary-transaction`;

export const CREATE_TRANSACTION_FORM_NAME = `create-fake-transaction`;

export const PROFILE_SETTINGS = `profile-settings`;
export const PASSWORD_SETTINGS = `password-settings`;
export const SYSTEM_SETTINGS = `system-settings`;
export const PRIVATE_SETTINGS = `private-settings`;
export const PAYMENT_PLUGIN_WIDGET = `payment-plugin-widget`;
export const APPEARANCE_SETTINGS = `appearance-settings`;
export const HELP_GIFS = `help-gifs`;

export const DASHBOARD_ASIDE_FILTERS_FORM_NAME = `dashboard-aside-filters`;
export const DASHBOARD_PAYMENTS_FILTERS_FORM_NAME = `dashboard-payments-filters`;

export const PAYMENT_VIEW_FORM_NAME = `payment-view`;
export const PAYMENT_SETTINGS_FORM_NAME = `payment-settings`;

export const PAYTM_PHONE_CONFIRM_FORM_NAME = `paytm-phone-confirm`;

export const REPORT_GENERATING_FORM_NAME = `report-generating`;

export const AUTO_REPORT_GENERATING_FORM_NAME = `auto-report-generating`;

export const TRANSACTION_SEARCH_FORM_NAME = `wallet-transaction-search`;

export const UPLOAD_PROXIES_FORM_NAME = `paytm-proxies-uploading`;

export const WALLET_COMMENT_FORM_NAME = `wallet-comment`;

export const TRANSACTION_ACTIVATION_FORM_NAME = `transaction-activation`;

export const TARIFFS_FORM_NAME = `tariffs`;

export const WITHDRAWAL_COMMENT_FORM_NAME = `withdrawal-comment`;

export const CREATE_SETTLEMENT_FORM_NAME = `create-settlement`;

export const MANUAL_WITHDRAWALS_UPLOADING_FORM_NAME = `upload-manual-withdrawals`;
export const MANUAL_WITHDRAWALS_DOWNLOADING_FORM_NAME = `download-manual-withdrawals`;

export const TRANSACTIONS_UPLOADING_FORM_NAME = `upload-transactions`;

export const CREATE_PAYMENT_SORTING_FORM_NAME = `create-payment-sorting`;

export const MONEY_TRANSFER_FORM_NAME = `money-transfer`;

export const CREATE_MANUAL_PS_FORM = `create-manual-ps-form`;
export const CREATE_MANUAL_PS_HINT_FORM = `create-manual-ps-hint-form`;

export const POSSIBLE_TRANSACTION_APPROVE_FORM = `possible-transaction-approve-form`;
