import axios from 'axios';
import { useState, useEffect } from 'react';
import api from 'api/translations/TranslationsApi';
import { WalletType } from 'api/translations/TranslationsApi';
import refreshHelper from '../../../../../utils/refreshHelper';

export type UsePaymentSystems = {
  isLoading: boolean;
  paymentSystems: Array<WalletType>;

  /* Выбранная платежная система */
  currentPaymentSystem?: WalletType;
  /* Хэндлер для выбора платежной системы */
  handleSetPaymentSystem: (paymentSystem: WalletType) => void;
};

// TODO Вместо флага передавать функцию сравнения.

/**
 * usePaymentSystems
 *
 * Хранит список платежных систем
 * Хранит значение выбранной платежной системы
 */
export function usePaymentSystems({ isSorted }: { isSorted?: boolean }): UsePaymentSystems {
  const [isLoading, setIsLoading] = useState(false);
  const [paymentSystems, setPaymentSystems] = useState<Array<WalletType>>([]);

  const [currentPaymentSystem, setCurrentPaymentSystem] = useState<WalletType | undefined>(undefined);
  const handleSetPaymentSystem = (paymentSystem: WalletType) => setCurrentPaymentSystem(paymentSystem);

  const fetchPaymentSystems = async () => {
    setIsLoading(true);
    await refreshHelper({
      request: api.fetchPaymentSystems,
      onSuccess: ({ data }: any) => {
        setPaymentSystems((data.data || []).filter((ps: WalletType) => !ps.is_settlement));
      },
      onFinally: () => {
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    fetchPaymentSystems();
  }, []);

  return {
    isLoading,
    paymentSystems,

    currentPaymentSystem,
    handleSetPaymentSystem,
    // paymentSystems: isSorted ? paymentSystems.sort((a, b) => a.name.localeCompare(b.name)) : paymentSystems,
  };
}
