const postbackRouteTabs = () => [
  {
    title: `postbacks.postbacks`,
    path: `/postbacks`,
    hasAccess: true,
  },
  {
    title: `postbacks.notAcceptedPostbacks`,
    path: `/not-accepted-postbacks`,
    hasAccess: true,
  },
];

export default postbackRouteTabs;
