import { useCallback } from 'react';
import { getFormError, getFormSyncErrors, isValid } from 'redux-form';
import { useSelector } from 'react-redux';
import { IWidgetsMeta } from '@kassma-team/kassma-toolkit';

import useFormSubmissionError from 'hooks/useFormSubmissionError';

const useValidateForm = (form: string, meta?: IWidgetsMeta) => {
  const valid = useSelector(isValid(form));
  const syncErrors = useSelector(getFormSyncErrors(form)) || {};
  const formError = useSelector(getFormError(form));
  const stopSubmit = useFormSubmissionError();

  return useCallback(() => {
    if (!valid) {
      const errors: Record<string, unknown> = { ...syncErrors };
      if (formError) {
        errors._error = formError;
      }
      stopSubmit({ payload: errors, meta, form });
    }

    return valid;
  }, [valid, syncErrors, formError]);
};

export default useValidateForm;
