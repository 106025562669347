import { Permission, ReportType } from 'utils/enums';

export const getAutoReportTypeSelectionDataByPermissions = (permissions: string[]) => {
  const hasPermission = (permission: string) => permissions.includes(permission);

  return [
    ...(hasPermission(Permission.TRANSACTION_REPORTS)
      ? [
          {
            value: ReportType.SUM_REPORT,
            text: `reports.sumOfTransactions`,
          },
          {
            value: ReportType.LIST_REPORT,
            text: `transactions.transactionList`,
          },
          {
            value: ReportType.TRANSACTIONS_SUM_DETAIL,
            text: `transactions.sumOfTransactionsDetailedByDay`,
          },
        ]
      : []),
  ];
};
