export const WalletColumn = {
  ID: `id`,
  TYPE: `type`,
  IDENTIFIER: `identifier`,
  PROXY: `proxy`,
  EMAIL: `email`,
  SITE_LOGIN: `siteLogin`,
  ADDITIONAL_INFO: `addInfo`,
  BALANCE: `balance`,
  EPAY_CODE: `epayCode`,
  UPDATED_AT: `updatedAt`,
  SHOW_PLUGIN_STATUS: `showPluginStatus`,
  STATUS: `status`,
  PRIORITY: `priority`,
  CONTROL: `control`,
  COMMENT: `comment`,
  ARCHIVING_DATE: `archivingDate`,
};

export const WalletStatisticsColumns = {
  WALLET_TYPE: `walletType`,
  /** Видимые/Рабочие/Все*/
  VISIBLE_WORKING_ALL: `visibleWorkingAll`,
  /** Текущий/макс.  баланс видимых */
  BALANCE_OF_VISIBLE_MAX: `balanceOfVisibleMax`,
  /** Текущий/макс. оборот видимых */
  TURNOVER_OF_VISIBLE_MAX: `turnoverOfVisibleMax`,
  /** Текущий/макс. баланс всех */
  BALANCE_OF_ALL_MAX: `balanceOfAllMax`,
  /** Текущий/макс. оборот всех */
  TURNOVER_OF_ALL_MAX: `turnoverOfAllMax`,
  /** Макс. дневной оборот */
  TURNOVER_DAILY_TURNOVER: `turnoverDailyTurnover`,
};

export const PaymentSystemColumn = {
  TITLE: `title`,
  NAME: `name`,
  STATUS: `status`,
  ACTIONS: `actions`,
};

export const PaymentsConstructorColumn = {
  NOTIFICATION: `notification`,
  PAYMENT_SYSTEM: `paymentSystem`,
  CURRENCIES: `currencies`,
  ACTIONS: `actions`,
  DISPLAY_ON_PLUGIN: `displayOnPlugin`,
  STANDARD_PS: `standardPS`,
  LIMIT_FOR_GETTING_STANDARD_STATUS: `limitForGettingStatusStandard`,
  USE_POSSIBLE_TRANSACTIONS: `usePossibleTransactions`,
};

export const PaymentHintsColumn = {
  NAME: `name`,
  TYPE: `type`,
  FORM: `form`,
  FORM_ELEMENT: `formElement`,
  ACTIONS: `actions`,
};

export const LogsColumn = {
  WALLET_TYPE: `walletType`,
  IDENTIFIER: `identifier`,
  ERROR_TYPE: `errorType`,
  MESSAGE: `message`,
  DATE: `date`,
  CATEGORY: `category`,
};

export const WithdrawalsLogsColumn = {
  WALLET_TYPE: `walletType`,
  MESSAGE: `message`,
  DATE: `date`,
};

export const PaytmCookiesColumn = {
  FILE: `file`,
  ACTIONS: `actions`,
};

export const ProxiesColumn = {
  CHECKBOX: `checkbox`,
  INDEX: `index`,
  IP: `ip`,
  PORT: `port`,
  LOGIN: `login`,
  WALLETS_ID: `walletsId`,
  TYPE: `type`,
  STATUS: `status`,
  ACTIONS: `actions`,
  COUNTRY: `country`,
  DATE_OF_CHECKING: `date_of_checking`,
  UUID: `uuid`,
};

export const PreliminaryTransactionsColumn = {
  PRELIMINARY_TRANSACTION_NUMBER: `preliminaryTransactionNumber`,
  LABEL: `label`,
  AMOUNT: `amount`,
  CREATED_AT: `createdAt`,
  STATUS: `status`,
  ACTIONS: `actions`,
  ACTIVATION: `activation`,
};

export const PossibleTransactionsColumn = {
  ID: `id`,
  TYPE: `type`,
  TRANSACTION_NUMBER: `transactionNumber`,
  AMOUNT: `amount`,
  LABEL: `label`,
  CREATED_AT: `createdAt`,
  ACTIONS: `actions`,
  STATUS: `status`,
  EMPTY: `empty`,
};

export const TransactionsColumn = {
  ID: `id`,
  BALANCE: `balance`,
  WALLET_TYPE: `walletType`,
  WALLET_IDENTIFIER: `walletIdentifier`,
  TRANSACTION_AMOUNT: `transactionAmount`,
  TRANSACTION_NUMBER: `transactionNumber`,
  COMISSION_AMOUNT: `comissionAmount`,
  EXCHANGER: `exchanger`,
  DATE: `date`,
  ACTIVATION_DATE: `activation_date`,
  Archived_DATE: `archived_date`,
  LABEL_ORDER_ID: `labelOrderId`,
  COUNTRY: `country`,
  STATUS: `status`,
  POSTBACK_STATUS: `postbackStatus`,
  TRANSACTION_KIND: `kindOfTransaction`,
  TRANSACTION_TYPE: `transactionType`,
  ACTIONS: `actions`,
};

export const WithdrawalsColumn = {
  ID: `id`,
  TYPE: `type`,
  REQUEST_DATE: `requestDate`,
  WITHDRAWAL_ID: `withdrawalId`,
  REQUEST_TYPE: `requestType`,
  WALLET_SENDER: `walletSender`,
  WALLET_RECIPIENT: `walletRecipient`,
  LABEL: `label`,
  AMOUNT: `amount`,
  STATUS: `status`,
  POSTBACK: `postback`,
  COMMENT: `comment`,
  ACTIONS: `actions`,
  METHOD: `method`,
  KIND: `kind`,
};

export const StockpilingListColumn = {
  ID: `id`,
  LABEL: `label`,
  PAYMENT_SYSTEM: `paymentSystem`,
  MIN_DEPOSIT: `minDeposit`,
  STOCKPILINGS_AMOUNT: `stockpilingsAmount`,
  STOCKPILING_TYPE: `stockpilingType`,
  STATUS: `status`,
  ACTIONS: `actions`,
};

export const PostbacksColumn = {
  ID: `id`,
  URL: `url`,
  STATUS_CODE: `statusCode`,
  RESPONSE: `response`,
  LABEL: `label`,
  MERCHANT_ORDER_ID: `merchantOrderID`,
  WALLET_TYPE: `walletType`,
  STATUS: `status`,
  TYPE: `type`,
  CREATED_AT: `createdAt`,
  ACTIONS: `actions`,
};

export const NotAcceptedPostbacksColumn = {
  CHECKBOX: `checkbox`,
  ID: `id`,
  URL: `url`,
  STATUS_CODE: `statusCode`,
  RESPONSE: `response`,
  LABEL: `label`,
  WALLET_TYPE: `walletType`,
  STATUS: `status`,
  TYPE: `type`,
  CREATED_AT: `createdAt`,
  ACTIONS: `actions`,
  COUNT_FAILURE: `count_failure`,
};

export const RolesColumn = {
  NAME: `name`,
  DESCRIPTION: `description`,
  RIGHTS: `rights`,
  ACTIONS: `actions`,
};

export const UsersColumn = {
  LOGIN: `login`,
  CREATED_AT: `createdAt`,
  LAST_UPDATE: `lastUpdate`,
  ROLES: `roles`,
  ACTIONS: `actions`,
};

export const ReportColumn = {
  STATUS: `status`,
  FILE: `file`,
  DATE: `date`,
  PROCESSING_DATE: `processingDate`,
  ACTIONS: `actions`,
};

export const AutoReportColumn = {
  AUTO_REPORT_FILE: `auto-report-file`,
  AUTO_REPORT_TYPE: `auto-report-type`,
  AUTO_REPORT_DATE: `auto-report-date`,
  AUTO_REPORT_EMAILS: `auto-report-emails`,
  AUTO_REPORT_START: `auto-report-start`,
  AUTO_REPORT_AUTHOR: `auto-report-author`,
  AUTO_REPORT_ID: `auto-report-id`,
  AUTO_REPORT: `auto-report`,
  AUTO_REPORT_RECIPIENT: `auto-report-start`,
  AUTO_REPORT_REPORTING_PERIOD: `auto-report-reporting-period`,
  AUTO_REPORT_ACTIONS: `auto-report-recipient`,
};

export const ChangeLogsColumn = {
  USER: `user`,
  DATE: `date`,
  OBJECT_TYPE: `objectType`,
  OBJECT_ID: `objectId`,
  ACTION_TYPE: `actionType`,
  OLD_VALUE: `oldValue`,
  NEW_VALUE: `newValue`,
};

export const WalletsLoadColumn = {
  ID: `id`,
  WALLET_TYPE: `walletType`,
  WALLET_IDENTIFIER: `walletIdentifier`,
  AMOUNT_OF_TRANSACTIONS_IN_DIFFERENT_CURRENCIES: `amountOfTransactionsInDifferentCurrencies`,
  AMOUNT: `amount`,
  LOAD_TYPE: `loadType`,
};
