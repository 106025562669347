import React from 'react';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { WidgetType } from 'utils/enums';
import { createSettlementTransfer, settlementActionCreators } from 'actions/widgets/balances/settlement';
import {
  paymentSystemsWithoutSettlementsSelector,
  supportedWalletCurrenciesSelector,
} from 'selectors/widgets/paymentSystems';
import { ITransferringMoneyToSettlementBalanceForm } from 'interfaces/widgets/balances/balances';

import Replenishment from 'components/widgets/balances/Replenishment';
import { accountsBalanceHistoryActionCreators } from '../../../actions/widgets/balances/accountsBalanceHistory';

const onSubmit = (values: ITransferringMoneyToSettlementBalanceForm, dispatch: Dispatch) => {
  dispatch(createSettlementTransfer(values));
};

const AccountBalanceReplenishment = () => {
  const paymentSystems = useSelector(paymentSystemsWithoutSettlementsSelector);

  const safePaymentSystems = paymentSystems?.map((el) => ({
    ...el,
    value: el.code,
    text: el.name,
  }));

  return (
    <Replenishment
      paymentSystems={safePaymentSystems}
      currencySelectorByPSType={supportedWalletCurrenciesSelector}
      onSubmit={onSubmit}
      widget={WidgetType.ACCOUNT_BALANCE_HISTORY}
      actionCreators={accountsBalanceHistoryActionCreators}
      title="balances.transferMoneyToAnotherAccountBalance"
      submitText="wallets.replenishment"
    />
  );
};

export default AccountBalanceReplenishment;
