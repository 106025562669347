import { CustomTranslate } from 'pages/constructor/DefaultTranslates/common/types';

/**
 * isTranslatesEmpty
 *
 * Проверяет, есть ли переводы в коллекции
 * Используется на нормализованных переводах
 */
export const isTranslatesEmpty = (customTranslates?: Record<string, CustomTranslate>): boolean => {
  if (!customTranslates) return true;
  if (!Object.keys(customTranslates).length) return true;

  return false;
};
