import React, { useCallback } from 'react';
import map from 'lodash/map';
import every from 'lodash/every';
import { useTranslation } from 'react-i18next';

import { WalletType } from 'utils/enums';
import { IAppliedPaymentSystems } from 'interfaces/widgets/settings';
import { IPaymentSortedList } from 'interfaces/widgets/widgets';
import { useSelector } from 'react-redux';
import { paymentSystemsSelector } from '../../selectors/widgets/paymentSystems';

const usePaymentsSortingList = () => {
  const [t] = useTranslation();

  const paymentSystems = useSelector(paymentSystemsSelector);

  const getLogo = useCallback(
    (walletType: string) => {
      return (paymentSystems.find((paymentSystem) => paymentSystem.value === walletType) || { logo: `` }).logo;
    },
    [paymentSystems]
  );

  const getSortingList = useCallback(
    (list: IAppliedPaymentSystems[]) =>
      map(list, ({ type, name, logo, id, test_wallet_type_id }) => {
        const paymentData: IPaymentSortedList =
          type === `test`
            ? { type, name, test_wallet_type_id, logo, id }
            : { type, name: type, id, logo: getLogo(type) };

        if (type === WalletType.NGAN_LUONG) {
          paymentData.extraContent = <span className="block text-danger mb-0">({t(`paymentConstructor.6in1`)})</span>;
        }

        return paymentData;
      }),
    []
  );

  const sortingListsAreEqual = useCallback<(first: Record<string, any>[], second: Record<string, any>[]) => boolean>(
    (firstList, secondList) =>
      every(firstList, (payment, index) =>
        payment.type === `test` ? payment.name === secondList[index].name : payment.type === secondList[index].type
      ),
    []
  );

  return { getSortingList, sortingListsAreEqual };
};

export default usePaymentsSortingList;
