import React from 'react';
import { IWidgetModal, widgetModalSelector } from '@kassma-team/kassma-toolkit';
import { postbackActionCreators, resendWithdrawalsPostback } from 'actions/widgets/postback';
import { WidgetType } from 'utils/enums';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationModal from '../../modals/ConfirmationModal';
const PostBackResentModal = () => {
  const dispatch = useDispatch();
  const modal: IWidgetModal = useSelector(widgetModalSelector(WidgetType.POSTBACKS));

  return (
    <ConfirmationModal
      actionCreators={postbackActionCreators}
      title="postbacks.resendPostback"
      content="postbacks.sureToResendPostback"
      confirmText="postbacks.resend"
      onConfirm={() => {
        dispatch(resendWithdrawalsPostback({ id: modal.id }));
        dispatch(postbackActionCreators.hideModal());
      }}
    />
  );
};

export default PostBackResentModal;
