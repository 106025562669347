import map from 'lodash/map';
import omit from 'lodash/omit';
import get from 'lodash/get';
import reduce from 'lodash/reduce';

import { IDefaultTranslations } from 'selectors/widgets/paymentHints';
import { PaymentTranslationFieldType } from 'utils/enums';

interface IProps {
  translationsHint: IDefaultTranslations[];
  translationsField: Record<string, IDefaultTranslations[]>;
  name: string;
  wallet_type: string;
  form_element: string;
  form_type: string;
}

export default (values: IProps) => ({
  ...omit(values, [`translationsHint`, `translationsField`]),
  translations:
    values?.form_element === PaymentTranslationFieldType.FIELD
      ? reduce<Record<string, IDefaultTranslations[]>, IDefaultTranslations[]>(
          values?.translationsField,
          (acc, item, key) => [
            ...acc,
            ...map(item, (translation) => ({
              ...translation,
              form_id: item[0].form_id,
              field_type: translation?.field_type || key,
            })),
          ],
          []
        )
      : map(values?.translationsHint, (translation) => ({
          ...translation,
          form_id: values?.translationsHint[0].form_id,
          form_text: get(translation, `form_text.html`),
        })),
});

export const separateFieldData = (translationList: IDefaultTranslations[]) =>
  reduce(
    translationList,
    (acc: Record<string, IDefaultTranslations[]>, translation) => {
      const property = translation.field_type;
      acc[property] = acc[property] || [];
      acc[property].push(translation);

      return acc;
    },
    {}
  );
