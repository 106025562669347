import React, { FC, ReactNode } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import './TranslateField.scss';

type Props = {
  fieldProps: TextFieldProps;
  error: ReactNode;
};

export const TranslateField: FC<Props> = ({ error, fieldProps }) => {
  return (
    <div className="TranslateField">
      <TextField className="TranslateField" {...fieldProps} />
      {error && error}
    </div>
  );
};
