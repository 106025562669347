import { WithdrawalKind } from 'utils/enums';

const withdrawalKindField = {
  [WithdrawalKind.CONFIRMED]: {
    text: `withdrawals.confirmedKind`,
    badge: `badge-success`,
    filterText: `withdrawals.confirmedKind`,
  },
  [WithdrawalKind.DEBUG]: {
    text: `withdrawals.debugKind`,
    badge: `badge-warning`,
    filterText: `withdrawals.debugKind`,
  },
};

export default withdrawalKindField;
