import React from 'react';
import { reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { IModalFormProps, InputType, widgetModalSelector } from '@kassma-team/kassma-toolkit/lib';

import { rejectWithdrawalRequest, withdrawalsActionCreators } from 'actions/widgets/withdrawals';
import { WidgetType } from 'utils/enums';

import ModalForm from 'components/modals/ModalForm';

const RejectionModal = (props: IModalFormProps) => {
  const dispatch = useDispatch();
  const modal = useSelector(widgetModalSelector(WidgetType.WITHDRAWALS));

  const [t] = useTranslation();

  return (
    <ModalForm
      {...props}
      fields={[
        {
          name: `comment`,
          label: t(`common.comment`),
          type: InputType.TEXT_EDITOR,
          required: true,
          maxLength: 100,
        },
      ]}
      submitText="common.reject"
      onSubmit={(values: Record<`comment`, Record<string, string>>) => {
        dispatch(
          rejectWithdrawalRequest(values, {
            id: get(modal, `id`),
            withdrawal_id: get(modal, `withdrawal_id`),
          })
        );
      }}
      title="withdrawals.withdrawalRejection"
      actionCreators={withdrawalsActionCreators}
      widget={WidgetType.WITHDRAWALS}
    />
  );
};

export default reduxForm({
  form: `reject-withdrawal-form`,
})(RejectionModal);
