import { Reducer } from 'redux';
import { IWidgetsState } from '@kassma-team/kassma-toolkit/lib/types/widgets';

import {
  UPLOAD_PAYTM_COOKIES_SUCCEEDED,
  RESET_PAYTM_COOKIES_UPLOADING,
  DELETE_PAYTM_COOKIES_REQUESTED,
  DELETE_PAYTM_COOKIES_SUCCEEDED,
  DELETE_PAYTM_COOKIES_FAILED,
} from 'actionTypes';

const actionTypes = new Set([
  UPLOAD_PAYTM_COOKIES_SUCCEEDED,
  RESET_PAYTM_COOKIES_UPLOADING,
  DELETE_PAYTM_COOKIES_REQUESTED,
  DELETE_PAYTM_COOKIES_SUCCEEDED,
  DELETE_PAYTM_COOKIES_FAILED,
]);

const reducer: Reducer = (state: IWidgetsState, { type, payload }) => {
  switch (type) {
    case UPLOAD_PAYTM_COOKIES_SUCCEEDED:
      return {
        ...state,
        items: [...(payload?.uploaded_files || []), ...(state.items || [])],
        invalidUploadedFiles: [...(payload?.unloaded_files || [])],
      };
    case RESET_PAYTM_COOKIES_UPLOADING:
      return {
        ...state,
        invalidUploadedFiles: [],
      };
    case DELETE_PAYTM_COOKIES_REQUESTED:
      return {
        ...state,
        submitting: true,
      };
    case DELETE_PAYTM_COOKIES_SUCCEEDED:
    case DELETE_PAYTM_COOKIES_FAILED:
      return {
        ...state,
        submitting: false,
      };
    default:
      return state;
  }
};

const paytmCookies = { actionTypes, reducer };

export default paytmCookies;
