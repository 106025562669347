import React from 'react';
import { TabsBar as KassmaTabsBar } from '@kassma-team/kassma-toolkit/lib';
import { useDispatch, useSelector } from 'react-redux';
import { getLocation, RouterLocation, RouterRootState } from 'connected-react-router';
import { Dispatch } from 'redux';

import useSearchParams from 'hooks/useSearchParams';

interface IProps {
  containerClassNames?: string;
  tabs: any[];
  search?: Record<string, string | string[] | null>;
  location?: RouterLocation<unknown>;
  dispatch?: Dispatch;
}

const TabsBar = (props: IProps) => {
  const dispatch = useDispatch();
  const location = useSelector(getLocation as (state: RouterRootState) => RouterLocation<unknown>);
  const search = useSearchParams();

  return <KassmaTabsBar {...props} search={search} location={location} dispatch={dispatch} />;
};

export default TabsBar;
