import React from 'react';
import { useTranslation } from 'react-i18next';
import useFullAmount from '../../../hooks/useFullAmount';
import { InnerTable, WalletImg, useIsTouchDevice } from '@kassma-team/kassma-toolkit';
import { formatDate } from '../../../utils/formatDate';
import classNames from 'classnames';
import { Direction } from '../transaction/TransactionRow';
import { useSelector } from 'react-redux';
import { paymentSystemLogoSelector } from '../../../selectors/widgets/paymentSystems';
import { accountBalanceHistoryBalanceInfoByIdSelector } from '../../../selectors/widgets/balances';
import useSearchParams from '../../../hooks/useSearchParams';
import { useDispatch } from 'react-redux';
import { hasAccessSelector } from 'selectors/auth';
import { ModalType, Permission } from 'utils/enums';
import { accountsBalanceHistoryActionCreators } from 'actions/widgets/balances/accountsBalanceHistory';

export type IBalanceHistory = {
  id: string;
  amount: number;
  created_at: string;
  currency_code: string;
  directing: string;
  is_settlement: number;
  desktopSize: boolean;
  total_commission: number;
  wallet_type?: string;
  ex_rate?: any;
  external_address?: any;
  operation_type?: number;
  cancellations_role?: number;
};

type ActionProps = {
  id: IBalanceHistory[`id`];
  operation_type: IBalanceHistory[`operation_type`];
  cancellations_role: IBalanceHistory[`cancellations_role`];
};

type AddInfoBadgeProps = {
  cancellations_role: IBalanceHistory[`cancellations_role`];
};

const Actions = ({ id, operation_type, cancellations_role }: ActionProps) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const managerBalanceAccess = useSelector(hasAccessSelector(Permission.MANAGER_BALANCE));

  const isTouchDevice = useIsTouchDevice();

  const { showModal } = accountsBalanceHistoryActionCreators;

  if (operation_type === 2 || cancellations_role != null) return null;

  return (
    <div className="btn-group">
      {managerBalanceAccess && (
        <button
          type="button"
          className={classNames(`btn`, `btn-warning`, `js-tooltip-enabled`, { 'btn-sm': !isTouchDevice })}
          onClick={() => dispatch(showModal({ id, type: ModalType.REMOVE_CONFIRMATION }))}
          title={t(`balances.cancelBtn`)}
        >
          {t(`balances.cancelBtn`)}
        </button>
      )}
    </div>
  );
};

const AdditionalInfoBadge = ({ cancellations_role }: AddInfoBadgeProps) => {
  const [t] = useTranslation();
  switch (cancellations_role) {
    case 0: {
      return (
        <div className="multi-badge-cell">
          <span className={`badge badge-danger`}>{t(`balances.canceledLabel`)}</span>
        </div>
      );
    }
    case 1: {
      return (
        <div className="multi-badge-cell">
          <span className={`badge badge-success`}>{t(`balances.compensatingLabel`)}</span>
        </div>
      );
    }
    case null:
    default: {
      return null;
    }
  }
};

export const BalanceHistoryRow = ({
  id: transactionId,
  amount,
  created_at,
  currency_code,
  directing,
  ex_rate,
  external_address,
  is_settlement,
  desktopSize,
  wallet_type,
  total_commission,
  operation_type,
  cancellations_role,
}: IBalanceHistory) => {
  const [t] = useTranslation();

  const queryParams = useSearchParams();
  const id: any = queryParams?.id || ``;
  const { currency_code: currency }: any = useSelector(accountBalanceHistoryBalanceInfoByIdSelector(id || ``));

  const formattedAmount = useFullAmount({
    amount,
    currencyCode: currency,
  });

  const directionColors = {
    'text-danger': directing === Direction.OUTGOING,
    'text-success': directing === Direction.INGOING,
  };

  const transactionSum = (
    <strong>{directing === Direction.INGOING ? `+ ${formattedAmount}` : `- ${formattedAmount}`}</strong>
  );

  const exchangeRate = useFullAmount({
    amount: ex_rate,
    currencyCode: currency_code,
    showSymbol: false,
    precision: 4,
    needToTrim: false,
  });

  const formattedCommission = useFullAmount({
    amount: total_commission,
    currencyCode: currency,
  });

  const logo = useSelector(paymentSystemLogoSelector(wallet_type || ``));
  const walletTypeImage = wallet_type ? (
    <WalletImg title={wallet_type || external_address} src={logo} />
  ) : (
    external_address
  );

  const formattedCreatedAt = formatDate(created_at);

  return desktopSize ? (
    <tr>
      <td>{formattedCreatedAt}</td>
      <td className={classNames(directionColors, `broken-table-cell`)}>{transactionSum}</td>
      <td>{formattedCommission}</td>
      <td>{walletTypeImage}</td>
      <td>{currency_code}</td>
      <td>{exchangeRate}</td>
      <td>
        <AdditionalInfoBadge cancellations_role={cancellations_role} />
      </td>
      <td>
        <Actions id={transactionId} operation_type={operation_type} cancellations_role={cancellations_role} />
      </td>
    </tr>
  ) : (
    <InnerTable cellClassName="mobile-inner-table">
      <tr className="first-mobile-row">
        <td>{t(`wallets.dateTime`)}</td>
        <td>{formattedCreatedAt}</td>
      </tr>
      <tr>
        <td>{t(`common.amount`)}</td>
        <td className={classNames(directionColors)}>{transactionSum}</td>
      </tr>
      <tr>
        <td>{t(`transactions.commission`)}</td>
        <td>{formattedCommission}</td>
      </tr>
      <tr>
        <td>{t(`transactions.typeOrAccount`)}</td>
        <td>{walletTypeImage}</td>
      </tr>
      <tr>
        <td>{t(`common.currency`)}</td>
        <td>{currency_code}</td>
      </tr>
      <tr>
        <td>{t(`wallets.exchangeRate`)}</td>
        <td>{exchangeRate}</td>
      </tr>
      <tr>
        <td>{t(`balances.extraInfo`)}</td>
        <td>
          <AdditionalInfoBadge cancellations_role={cancellations_role} />
        </td>
      </tr>
      <tr>
        <td>{t(`common.actions`)}</td>
        <td>
          <Actions id={transactionId} operation_type={operation_type} cancellations_role={cancellations_role} />
        </td>
      </tr>
    </InnerTable>
  );
};
