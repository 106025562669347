function isInArray<V, A extends any[]>(value: V, array: A) {
  return array.indexOf(value) > -1;
}

export default function omit<O>(obj: O, removeItems: (keyof O)[]): O {
  return {
    ...Object.keys(obj)
      .filter((item) => !isInArray<string, (keyof O)[]>(item, removeItems))
      .reduce((newObj, item) => {
        return {
          ...newObj,
          [item]: obj[item as keyof O],
        };
      }, {} as O),
  };
}
