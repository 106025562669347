import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { widgetModalSelector } from '@kassma-team/kassma-toolkit/lib';

import { archivateTransaction, transactionActionCreators } from 'actions/widgets/transactions/transactions';
import { WidgetType } from 'utils/enums';

import ConfirmationModal from 'components/modals/ConfirmationModal';

const TransactionArchivationModal = () => {
  const dispatch = useDispatch();

  const value = useSelector(widgetModalSelector(WidgetType.TRANSACTIONS));
  const primaryId = get(value, `primary_id`);

  const onConfirm = () => {
    dispatch(archivateTransaction({ primaryId }));
  };

  return (
    <ConfirmationModal
      title="transactions.archivateTransaction"
      actionCreators={transactionActionCreators}
      confirmText="common.yes"
      content="transactions.addToArchive"
      onConfirm={onConfirm}
    />
  );
};

export default TransactionArchivationModal;
