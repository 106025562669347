import size from 'lodash/size';
import i18n from 'i18n';

import { WalletType } from 'utils/enums';
import cardValidation from 'utils/validation/cardValidation';

interface IBankDetails {
  bank_code?: string;
}

interface IValidateWithdrawals {
  wallet_recipient?: string;
  account_number?: string;
  amount?: string;
  bank_details?: IBankDetails;
}

interface IValues {
  amount: number;
  account_number: string;
  payment_system: string;
  bank_details?: IBankDetails;
}

const validateWithdrawals = (values: IValues): IValidateWithdrawals => {
  const errors: IValidateWithdrawals = {};

  const { amount, account_number, payment_system, bank_details } = values || {};

  if (payment_system === WalletType.IMPS_IB) {
    const bank_code = bank_details?.bank_code;
    if (bank_code && !/^[A-Z]{4}0[A-Z0-9]{6}$/g.test(bank_code)) {
      errors.bank_details = {
        bank_code: i18n.t(`errors.invalidIfscCode`),
      };
    }
  }

  if (payment_system === WalletType.PAY_TM) {
    if (amount % 10 !== 0) {
      errors.amount = i18n.t(`errors.mustBeMultiple`);
    }
    if (size(account_number) !== 12) {
      errors.account_number = i18n.t(`errors.phoneNumberLength`);
    }
  }

  return errors;
};

export default validateWithdrawals;
