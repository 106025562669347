import axios, { AxiosError } from 'axios';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { packagesApi } from 'api/packages';
import { CreateBody, UpdateBody } from 'api/packages/PackagesApi';
import { Package } from 'api/packages/PackagesApi';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
import refreshHelper from '../../../../../utils/refreshHelper';
import api from '../../../../../api/translations/TranslationsApi';
import { usePaymentSystemsCtx } from '../../common/ctx';
import { Error, Response } from 'api/translations/types';

export type UseTranslations = {
  isLoading: boolean;
  translations: Array<Package>;
  setTranslations: Dispatch<SetStateAction<Array<Package>>>;

  handleUpdateTranslation: (id: string, body: UpdateBody) => () => Promise<void>;
  handeDeleteTranslation: (id: string) => () => Promise<AxiosError<any> | undefined>;
  handleCreateField: (body: CreateBody, withToastr?: boolean) => () => Promise<void>;
  fetchPackages: () => Promise<void>;
};

export function useTranslations(): UseTranslations {
  const [isLoading, setIsLoading] = useState(false);
  const [isTranslationUpdating, setIsTranslationUpdating] = useState(false);
  const [isTranslationsDeleting, setIsTranslationsDeleting] = useState(false);
  const [isFieldCreating, setIsFieldCreating] = useState(false);
  const [translations, setTranslations] = useState<Array<Package>>([]);
  const { currentPaymentSystem } = usePaymentSystemsCtx();

  const [t] = useTranslation();

  const fetchPackages = async (queryParams: Record<string, string> = {}) => {
    setIsLoading(true);
    await refreshHelper({
      request: () =>
        packagesApi.fetchTranslations({
          wallet_type: currentPaymentSystem?.code || ``,
          ...queryParams,
        }),
      onSuccess: ({ data }: any) => {
        setTranslations(data.data);
      },
      onFinally: () => {
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    if (currentPaymentSystem) {
      fetchPackages();
    }
  }, [currentPaymentSystem]);

  const handleUpdateTranslation = (id: string, body: UpdateBody) => async () => {
    setIsTranslationUpdating(true);

    await refreshHelper({
      request: () => packagesApi.updateTranslation(id, body),
      onSuccess: ({ data }: any) => {
        if (data.status === `success`) {
          toastr.success(t(`common.success`), t(`common.successUpdate`));
        }

        if (data.status === `failed` || data.status === `error`) {
          toastr.error(t(`common.error`), t(`common.errorUpdate`));
        }
      },
      onFinally: () => {
        setIsTranslationUpdating(false);
      },
    });
  };

  const handeDeleteTranslation = (id: string) => async () => {
    setIsTranslationsDeleting(true);

    await refreshHelper({
      request: () => packagesApi.deleteTranslation(id),
      onSuccess: ({ data }: any) => {
        if (data.status === `success`) {
          toastr.success(t(`common.success`), t(`common.successUpdate`));
        }
      },
      onFinally: () => {
        setIsTranslationsDeleting(false);
      },
    });
  };

  const handleCreateField = (body: CreateBody, withToastr = true) => async () => {
    setIsFieldCreating(true);

    await refreshHelper({
      request: () => packagesApi.createField(body),
      onSuccess: ({ data }: any) => {
        if (data.status === `success`) {
          if (withToastr) {
            toastr.success(t(`common.success`), t(`common.successCreation`));
          }
        }

        if (data.error_message) {
          if (data.errors && data.errors.length) {
            data.errors.forEach((err: Error) => {
              toastr.error(t(`common.error`), err.message);
            });

            return;
          }

          toastr.error(t(`common.error`), data.error_message);
        }
      },
      onFinally: () => {
        setIsFieldCreating(false);
      },
    });
  };

  return {
    isLoading,
    translations,
    setTranslations,
    handleUpdateTranslation,
    //@ts-ignore
    handeDeleteTranslation,
    handleCreateField,
    fetchPackages,
  };
}
