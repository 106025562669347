import { useArrayFields as useKassmaArrayFields } from '@kassma-team/kassma-toolkit/lib';

import FormField from 'components/form/FormField';

interface IProps {
  fields: (field: any) => Record<string, any>;
  title: string;
  wrapClassName?: string;
  disabled?: boolean;
  name: string;
  disableCallback?: (fields: any) => boolean;
  hint?: string;
  showHint?: boolean;
}

const useArrayFields = ({ fields, title, wrapClassName, disabled, name, disableCallback, hint, showHint }: IProps) => {
  return useKassmaArrayFields({
    fields,
    title,
    wrapClassName,
    disabled,
    name,
    disableCallback,
    FormField,
    hint,
    showHint,
  });
};

export default useArrayFields;
