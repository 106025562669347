import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import {
  Preloader,
  useFilterColumns,
  useIsTouchDevice,
  Size,
  widgetSelectedItemsSelector,
} from '@kassma-team/kassma-toolkit/lib';

import { ModalType, Permission, ProxyStatus, ProxiesColumn, WidgetType } from 'utils/enums';
import { hasAccessSelector } from 'selectors/auth';
import { checkProxyStatusRequest, proxyActionCreators } from 'actions/widgets/proxy';
import { walletActionCreators } from 'actions/widgets/wallet';
import { IProxyRowProps } from 'interfaces/widgets/proxies';

const { showModal, selectItem, unselectItem } = proxyActionCreators;

interface IControlProps {
  uuid: string;
  status: number;
  checkClicked: boolean;
  onCheck: () => void;
}

const Control = ({ status, onCheck, uuid, checkClicked }: IControlProps) => {
  const dispatch = useDispatch();

  const [t] = useTranslation();

  const isTouchDevice = useIsTouchDevice();

  const checkProxyAccess = useSelector(hasAccessSelector(Permission.PROXY_CHECK));
  const deleteProxyAccess = useSelector(hasAccessSelector(Permission.PROXY_DELETE));
  const updateProxyAccess = useSelector(hasAccessSelector(Permission.PROXY_UPDATE));

  const checkIconClassName = classNames({
    'si si-like': checkClicked && status === ProxyStatus.SUCCESS,
    'fa fa-sun-o fa-spin': status === ProxyStatus.CHECKING,
    'si si-dislike': checkClicked && status === ProxyStatus.ERROR,
    'si si-reload': !checkClicked,
  });

  return (
    <div className="btn-group">
      {checkProxyAccess && (
        <button
          type="button"
          className={classNames(`btn`, `js-tooltip-enabled`, {
            'btn-success': checkClicked && status === ProxyStatus.SUCCESS,
            'btn-danger': checkClicked && status === ProxyStatus.ERROR,
            'btn-secondary': !checkClicked || status === ProxyStatus.CHECKING,
            'btn-sm': !isTouchDevice,
          })}
          title={t(`proxies.check`)}
          onClick={onCheck}
        >
          <i className={checkIconClassName} />
        </button>
      )}
      {updateProxyAccess && (
        <button
          type="button"
          className={classNames(`btn`, `btn-secondary`, `js-tooltip-enabled`, {
            'btn-sm': !isTouchDevice,
          })}
          onClick={() => dispatch(showModal({ type: ModalType.UPDATE, id: uuid }))}
        >
          <i className="fa fa-pencil" />
        </button>
      )}
      {deleteProxyAccess && (
        <button
          type="button"
          className={classNames(`btn`, `btn-secondary`, `js-tooltip-enabled`, {
            'btn-sm': !isTouchDevice,
          })}
          onClick={() => dispatch(showModal({ type: ModalType.REMOVE_CONFIRMATION, list: [uuid] }))}
        >
          <i className="fa fa-trash" />
        </button>
      )}
    </div>
  );
};

const ProxyRow = ({
  id,
  ip,
  port,
  status,
  desktopSize,
  type,
  username,
  wallets,
  hiddenColumns,
  country_code,
  last_checked_at,
  uuid,
  is_checking,
}: IProxyRowProps) => {
  const dispatch = useDispatch();

  const [t] = useTranslation();

  const selectedItems: any = useSelector(widgetSelectedItemsSelector(WidgetType.PROXIES));

  const [checkClicked, setCheckClicked] = useState(false);

  const checkChangeHandler = () => {
    setCheckClicked(true);
    dispatch(checkProxyStatusRequest(uuid));
  };

  const walletIdsField = wallets.map((wallet: Record<`id`, string> | any) => (
    <button
      className="button-link button-link--separated"
      onClick={() =>
        dispatch(walletActionCreators.showModal({ id: wallet?.id ? wallet.id : wallet, type: ModalType.SHOW }))
      }
      key={wallet?.id ? wallet.id : wallet}
    >
      {wallet?.id ? wallet.id : wallet}
    </button>
  ));

  const statusContent = {
    [ProxyStatus.ERROR]: <span className="badge badge-danger text-overflow">{t(`proxies.notWorking`)}</span>,
    [ProxyStatus.SUCCESS]: <span className="badge badge-success text-overflow">{t(`proxies.working`)}</span>,
    [ProxyStatus.CHECKING]: <Preloader size={Size.MINI} />,
  };

  const checkboxField = (
    <input
      checked={selectedItems.has(uuid)}
      type="checkbox"
      onChange={({ target: { checked } }) => {
        if (checked) {
          dispatch(selectItem(uuid));
        } else {
          dispatch(unselectItem(uuid));
        }
      }}
    />
  );

  const columns = [
    {
      id: ProxiesColumn.CHECKBOX,
      desktop: <td>{checkboxField}</td>,
      mobile: (
        <tr className="first-mobile-row">
          <td>{checkboxField}</td>
        </tr>
      ),
    },
    {
      id: ProxiesColumn.INDEX,
      desktop: <td>{id}</td>,
      mobile: (
        <tr>
          <td>{t(`proxies.index`)}</td>
          <td>{id}</td>
        </tr>
      ),
    },
    {
      id: ProxiesColumn.UUID,
      desktop: <td>{uuid}</td>,
      mobile: (
        <tr>
          <td>UUID</td>
          <td>{uuid}</td>
        </tr>
      ),
    },
    {
      id: ProxiesColumn.COUNTRY,
      desktop: <td className="text-no-wrap">{country_code || t(`proxies.undefined`)}</td>,
      mobile: (
        <tr>
          <td>{t(`common.country`)}</td>
          <td>{country_code || t(`proxies.undefined`)}</td>
        </tr>
      ),
    },
    {
      id: ProxiesColumn.IP,
      desktop: <td>{ip}</td>,
      mobile: (
        <tr>
          <td>IP</td>
          <td>{ip}</td>
        </tr>
      ),
    },
    {
      id: ProxiesColumn.PORT,
      desktop: <td>{port}</td>,
      mobile: (
        <tr>
          <td>PORT</td>
          <td>{port}</td>
        </tr>
      ),
    },
    {
      id: ProxiesColumn.LOGIN,
      desktop: <td>{username}</td>,
      mobile: (
        <tr>
          <td>{t(`proxies.login`)}</td>
          <td>{username}</td>
        </tr>
      ),
    },
    {
      id: ProxiesColumn.WALLETS_ID,
      desktop: <td>{walletIdsField}</td>,
      mobile: !isEmpty(wallets) && (
        <tr>
          <td>{t(`proxies.walletsID`)}</td>
          <td>{walletIdsField}</td>
        </tr>
      ),
    },
    {
      id: ProxiesColumn.TYPE,
      desktop: <td>{type}</td>,
      mobile: (
        <tr>
          <td>{t(`common.type`)}</td>
          <td>{type}</td>
        </tr>
      ),
    },
    {
      id: ProxiesColumn.STATUS,
      desktop: <td className="multi-badge-cell">{statusContent[status]}</td>,
      mobile: !checkClicked && status === ProxyStatus.ERROR && (
        <tr>
          <td>{t(`common.status`)}</td>
          <td className="multi-badge-cell">{statusContent[status]}</td>
        </tr>
      ),
    },
    {
      id: ProxiesColumn.DATE_OF_CHECKING,
      desktop: <td className="multi-badge-cell">{last_checked_at}</td>,
      mobile: (
        <tr>
          <td>{t(`proxies.dateOfChecking`)}</td>
          <td className="multi-badge-cell">{last_checked_at}</td>
        </tr>
      ),
    },
    {
      id: ProxiesColumn.ACTIONS,
      desktop: (
        <td>
          <Control uuid={uuid} status={status} onCheck={checkChangeHandler} checkClicked={checkClicked} />
        </td>
      ),
      mobile: (
        <tr>
          <td>{t(`common.actions`)}</td>
          <td>
            <Control uuid={uuid} status={status} onCheck={checkChangeHandler} checkClicked={checkClicked} />
          </td>
        </tr>
      ),
    },
  ];

  return useFilterColumns({ columns, desktopSize, hiddenColumns });
};

export default ProxyRow;
