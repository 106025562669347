import { useUserHasAccess as useKassmaTeamUserHasAccess } from '@kassma-team/kassma-toolkit/lib';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import { userPermissionsSelector } from 'selectors/auth';
import useRouteName from 'hooks/useRouteName';
import { IListDataProps } from 'interfaces/widgets/header';

import listData from 'components/header/listData';

interface IProps {
  navListData: (props: IListDataProps) => Record<string, unknown>[];
  userPermissions: string[];
  dispatch: Dispatch;
  routeName?: string;
}

const useUserHasAccess = (props?: IProps) => {
  const userPermissions = useSelector(userPermissionsSelector);

  const dispatch = useDispatch();

  const routeName = useRouteName();

  useKassmaTeamUserHasAccess({
    navListData: listData,
    userPermissions,
    dispatch,
    routeName,
  });
};

export default useUserHasAccess;
