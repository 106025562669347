import { useEffect, useState } from 'react';
import get from 'lodash/get';

import { WidgetType } from 'utils/enums';
import { notRemovedWalletActionCreators, walletActionCreators } from 'actions/widgets/wallet';
import useList from 'hooks/widgets/useList';
import { INotRemovedWallet } from 'interfaces/widgets/wallets/wallet';

const useWalletListByType = (
  walletType: string,
  { showAllWhenTypeIsEmpty = false, wallet = false, disableLoading = false } = {}
) => {
  const [walletsSelection, setWalletsSelection] = useState<INotRemovedWallet[] | null>([]);

  const queryParams: any = {};

  if (wallet) {
    queryParams.withoutQuery = true;
  }

  const { items: walletList, listLoading } = useList<INotRemovedWallet>({
    widget: wallet ? WidgetType.WALLETS : WidgetType.NOT_REMOVED_WALLETS,
    actionCreators: wallet ? walletActionCreators : notRemovedWalletActionCreators,
    queryParams,
    disableLoading,
  });

  useEffect(() => {
    if (walletType) {
      setWalletsSelection(
        walletList?.filter(({ wallet_type, test_wallet_type }) => {
          if (wallet_type !== `test`) {
            return wallet_type === walletType;
          }
          const machineName = get(test_wallet_type, `code`);

          return machineName === walletType;
        }) || []
      );
    } else {
      setWalletsSelection(showAllWhenTypeIsEmpty ? walletList : []);
    }
  }, [walletType, walletList, showAllWhenTypeIsEmpty]);

  return { walletsSelection, listLoading };
};

export default useWalletListByType;
