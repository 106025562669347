import deepEqual from 'deep-equal';

import { CustomTranslate } from 'pages/constructor/DefaultTranslates/common/types';

type Args = {
  initial: CustomTranslate;
  current: CustomTranslate;
};

/**
 * Позволяет узнать, внес ли пользователь изменения в кастомные переводы.
 */
export const isTranslateWasModified = ({ initial, current }: Args): boolean => !deepEqual(initial, current);
