import React from 'react';

import { WidgetType } from 'utils/enums';
import { proxyActionCreators } from 'actions/widgets/proxy';

import RemovingModal from 'components/modals/RemovingModal';

const ProxyRemoving = (): JSX.Element => {
  return (
    <RemovingModal
      removeMultipleItems
      widget={WidgetType.PROXIES}
      actionCreators={proxyActionCreators}
      title="proxies.proxyDeleting"
      content="proxies.wantDeleteSelectedProxies"
    />
  );
};

export default ProxyRemoving;
