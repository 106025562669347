import React, { useEffect, useMemo } from 'react';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IModalFormProps, InputType, useIsCrypt, widgetListSelector } from '@kassma-team/kassma-toolkit/lib';
import omit from 'lodash/omit';
import { Dispatch } from 'redux';

import { replenishmentActionCreators } from 'actions/widgets/balances/replenishment';
import { accountsBalancesActionCreators, transferMoney } from 'actions/widgets/balances/accountsBalances';
import useList from 'hooks/widgets/useList';
import {
  replenishmentPSByCurrencySelectSelector,
  replenishmentPSCurrencySelectSelector,
  replenishmentPSSelectSelector,
  transferSubmittingSelector,
} from 'selectors/widgets/balances';
import { MONEY_TRANSFER_FORM_NAME } from 'utils/constants';
import { WidgetType } from 'utils/enums';
import { IMoneyTransferFormProps } from 'interfaces/widgets/balances/balances';

import ModalForm from 'components/modals/ModalForm';
import { put } from 'redux-saga/effects';

const onSubmit = (values: IMoneyTransferFormProps, dispatch: Dispatch) => {
  const payload = omit(values, [`replenishment_amount`, `replenishment_currency_code`]);
  dispatch(transferMoney(payload));
};

const MoneyTransfer = ({ change, ...props }: InjectedFormProps & IModalFormProps) => {
  const [t] = useTranslation();

  const form = props?.form;
  const values: IMoneyTransferFormProps = useSelector(getFormValues(form));
  const { writeoff_payment_system, currency_code, writeoff_amount, commission_percent } = values || {};

  const paymentSystems = useSelector(replenishmentPSSelectSelector);
  const currenciesBySelectedPS = useSelector(replenishmentPSCurrencySelectSelector(writeoff_payment_system as string));
  const replenishmentPSBySelectedCurrency = useSelector(
    replenishmentPSByCurrencySelectSelector(writeoff_payment_system as string, currency_code as string)
  );
  const currencyList = useSelector(widgetListSelector(WidgetType.CURRENCIES));

  const isCrypt = useIsCrypt({ currencyCode: currency_code, currencies: currencyList });

  useEffect(() => {
    change(`currency_code`, undefined);
  }, [writeoff_payment_system]);

  useEffect(() => {
    change(`writeoff_amount`, undefined);
    change(`commission_percent`, undefined);
    change(`recipient_payment_system`, undefined);
    change(`replenishment_currency_code`, currency_code);
  }, [currency_code]);

  useEffect(() => {
    if (writeoff_amount && commission_percent) {
      const replenishmentAmount = +writeoff_amount - (+writeoff_amount * +commission_percent) / 100;
      const fixedReplenishmentAmount = replenishmentAmount.toFixed(10);
      const roundedReplenishmentAmount = isCrypt
        ? fixedReplenishmentAmount.slice(0, -2)
        : fixedReplenishmentAmount.slice(0, -8);
      change(`replenishment_amount`, +roundedReplenishmentAmount);
    } else {
      change(`replenishment_amount`, undefined);
    }
  }, [writeoff_amount, commission_percent]);

  useList({
    widget: WidgetType.REPLENISHMENT,
    actionCreators: replenishmentActionCreators,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(accountsBalancesActionCreators.setAnyData({ submitting: false }));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(accountsBalancesActionCreators.setAnyData({ submitting: false }));
    };
  }, []);

  const submitting = useSelector(transferSubmittingSelector);

  const writeOffFields = useMemo(
    () => [
      {
        name: `writeoff_payment_system`,
        label: t(`balances.accountType`),
        type: InputType.SELECT,
        data: paymentSystems,
        required: true,
      },
      {
        name: `currency_code`,
        label: t(`common.currency`),
        type: InputType.SELECT,
        required: true,
        data: currenciesBySelectedPS,
        disabled: !writeoff_payment_system,
      },
      {
        name: `writeoff_amount`,
        label: t(`common.amount`),
        required: true,
        standardAmount: true,
        currency: currency_code,
        disabled: !currency_code,
      },
      {
        name: `commission_percent`,
        label: t(`wallets.commissionWithSuffix`),
        percent: true,
        required: true,
        disabled: !currency_code,
      },
    ],
    [paymentSystems, currenciesBySelectedPS, writeoff_payment_system, currency_code]
  );

  const recipientFields = useMemo(
    () => [
      {
        name: `recipient_payment_system`,
        label: t(`balances.accountType`),
        type: InputType.SELECT,
        data: replenishmentPSBySelectedCurrency,
        required: true,
        disabled: !currency_code,
      },
      {
        name: `replenishment_currency_code`,
        label: t(`common.currency`),
        disabled: true,
      },
      {
        name: `replenishment_amount`,
        label: t(`common.amount`),
        standardAmount: true,
        currency: currency_code,
        disabled: true,
      },
    ],
    [replenishmentPSBySelectedCurrency, currency_code]
  );

  const fields = useMemo(
    () => [
      {
        title: `balances.writeOffAccount`,
        type: `group`,
        fields: writeOffFields,
      },
      {
        title: `balances.replenishmentAccount`,
        type: `group`,
        fields: recipientFields,
      },
    ],
    [writeOffFields, recipientFields]
  );

  return (
    <ModalForm
      {...props}
      onSubmit={onSubmit}
      fields={fields}
      submitText="balances.transferMoney"
      widget={WidgetType.ACCOUNTS_BALANCES}
      actionCreators={accountsBalancesActionCreators}
      title="balances.transferMoneyToAnotherAccountBalance"
      submtting={submitting}
    />
  );
};

export default reduxForm({ form: MONEY_TRANSFER_FORM_NAME })(MoneyTransfer);
