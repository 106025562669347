import React, { useCallback, useState } from 'react';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { IModalFormProps, InputType } from '@kassma-team/kassma-toolkit/lib';

import { MANUAL_WITHDRAWALS_UPLOADING_FORM_NAME } from 'utils/constants';
import { WidgetType } from 'utils/enums';
import { uploadManualWithdrawalsRequest, withdrawalsActionCreators } from 'actions/widgets/withdrawals';

import ModalForm from 'components/modals/ModalForm';

const ManualWithdrawalsUploadingModal = (props: IModalFormProps) => {
  const [t] = useTranslation();

  const [fields] = useState([
    {
      type: InputType.DROPZONE,
      label: t(`withdrawals.xlsxFile`),
      name: `report`,
      fileAcceptedTypes: [`xlsx`],
      maxSize: 1024 * 1024 * 200,
      required: true,
    },
  ]);

  const onSubmit = useCallback((values, dispatch) => {
    dispatch(uploadManualWithdrawalsRequest(values));
  }, []);

  return (
    <ModalForm
      {...props}
      widget={WidgetType.WITHDRAWALS}
      actionCreators={withdrawalsActionCreators}
      fields={fields}
      onSubmit={onSubmit}
      submitText="common.upload"
      title="withdrawals.manualWithdrawalsUploading"
    />
  );
};

export default reduxForm({
  form: MANUAL_WITHDRAWALS_UPLOADING_FORM_NAME,
})(ManualWithdrawalsUploadingModal);
