import { useState } from 'react';

import { PaymentsConstructorColumn } from 'utils/enums';
import { ITableTitleField } from 'interfaces/widgets/widgets';

const usePaymentsConstructorTableTitles = (): ITableTitleField[] => {
  const [titles] = useState([
    {
      title: `paymentConstructor.paymentSystem`,
      id: PaymentsConstructorColumn.PAYMENT_SYSTEM,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `paymentConstructor.currencies`,
      id: PaymentsConstructorColumn.CURRENCIES,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `paymentConstructor.usePossibleTransactions`,
      id: PaymentsConstructorColumn.USE_POSSIBLE_TRANSACTIONS,
      desktop: true,
      mobile: true,
      disabled: true,
      description: `paymentConstructor.usePossibleTransactionsDescription`,
    },
    {
      title: `paymentConstructor.displayOnPlugin`,
      id: PaymentsConstructorColumn.DISPLAY_ON_PLUGIN,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `paymentConstructor.standardPaymentSystem`,
      id: PaymentsConstructorColumn.STANDARD_PS,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `paymentConstructor.notifications`,
      id: PaymentsConstructorColumn.NOTIFICATION,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `paymentConstructor.limitForGettingStatusStandard`,
      id: PaymentsConstructorColumn.LIMIT_FOR_GETTING_STANDARD_STATUS,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.actions`,
      id: PaymentsConstructorColumn.ACTIONS,
      desktop: true,
      mobile: true,
      disabled: true,
    },
  ]);

  return titles;
};

export default usePaymentsConstructorTableTitles;
