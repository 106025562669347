type AmountValues = {
  creditting: number;
  commission_rate: number;
  ex_rate: number;
};

type CreditingValues = {
  amount: number;
  commission_rate: number;
  ex_rate: number;
};

type GetAmount = (values: AmountValues) => number;
const getAmount: GetAmount = ({ commission_rate, creditting, ex_rate }) =>
  (100 * creditting * ex_rate) / (100 - commission_rate);

const getAmountForSettlement: GetAmount = ({ creditting, commission_rate, ex_rate }) =>
  (100 * creditting) / (ex_rate * (100 - commission_rate));

type GetCrediting = (values: CreditingValues) => number;

const getCrediting: GetCrediting = ({ amount, commission_rate, ex_rate }) =>
  (amount - (amount * commission_rate) / 100) / ex_rate;

const getCreditingForSettlement: GetCrediting = ({ amount, commission_rate, ex_rate }) =>
  (amount - (amount * commission_rate) / 100) * ex_rate;

export const getCalcAmount = (isSettlement: boolean): GetAmount => {
  return isSettlement ? getAmountForSettlement : getAmount;
};

export const getCalcCrediting = (isSettlement: boolean): GetCrediting => {
  return isSettlement ? getCreditingForSettlement : getCrediting;
};
