import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { InputType, IWidgetActionCreators, widgetModalSelector } from '@kassma-team/kassma-toolkit/lib';
import he from 'he';

import { updateWalletComment } from 'actions/widgets/wallet';
import { WALLET_COMMENT_FORM_NAME } from 'utils/constants';

import ModalForm from 'components/modals/ModalForm';
import { Dispatch } from 'redux';

interface IProps {
  widget: string;
  actionCreators: IWidgetActionCreators;
}

function replace(html: string) {
  return html.replace(/<(?!\/?\w+>)/g, `&lt;`);
}

const WalletComment = ({ widget, actionCreators, ...props }: IProps & InjectedFormProps) => {
  const modal = useSelector(widgetModalSelector(widget));

  const comment = get(modal, `comment`);

  const id = get(modal, `id`);

  const [t] = useTranslation();

  const fields = [
    {
      name: `comment`,
      isModalField: true,
      label: t(`wallets.comment`),
      type: InputType.TEXT_EDITOR,
      forcedShowingError: true,
      maxLength: 60,
      value: comment,
    },
  ];

  useEffect(() => {
    if (comment) {
      props.initialize({ comment: he.encode(comment) });
    }
  }, [comment]);

  return (
    <ModalForm
      {...props}
      fields={fields}
      submitText="common.confirm"
      onSubmit={(values: Record<string, string>, dispatch: Dispatch) => {
        dispatch(updateWalletComment(widget)({ ...values, id }));
      }}
      widget={widget}
      title="wallets.comment"
      actionCreators={actionCreators}
    />
  );
};

export default reduxForm<IProps, any>({
  form: WALLET_COMMENT_FORM_NAME,
})(WalletComment);
