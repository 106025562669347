import React, { Fragment, useState } from 'react';
import map from 'lodash/map';

import { ModalType, WidgetType } from 'utils/enums';

import PaytmPhoneConfirm from 'components/widgets/wallets/paytm/PaytmPhoneConfirm';
import PaytmDataResetting from 'components/widgets/wallets/paytm/PaytmDataResetting';
import ProxyCreatingForm from 'components/widgets/proxy/ProxyCreatingForm';
import ActiveWalletModal from 'components/widgets/wallets/active/ActiveWalletModal';
import ActiveWalletComment from 'components/widgets/wallets/active/ActiveWalletComment';
import ProxiesMultiAdding from 'components/widgets/proxy/multiAdding/ProxiesMultiAdding';
import WalletCreationForm from 'components/widgets/wallets/create/WalletCreationForm';
import PaymentHintViewModal from 'components/widgets/constructors/paymentHints/PaymentHintViewModal';
import Modal from 'components/modals/Modal';
import { WalletSummaryModal } from 'components/widgets/wallets/WalletSummaryModal';

const GeneralModals = (): JSX.Element => {
  const [modalList] = useState([
    // Wallets
    {
      type: ModalType.SMS_CONFIRM,
      component: PaytmPhoneConfirm,
      widget: WidgetType.WALLET_PAYTM,
    },
    {
      type: ModalType.RESET_WALLET_DATA,
      widget: WidgetType.WALLET_PAYTM,
      component: PaytmDataResetting,
    },
    {
      type: ModalType.COMMENT,
      widget: WidgetType.WALLETS,
      component: ActiveWalletComment,
    },
    {
      type: ModalType.SHOW,
      widget: WidgetType.WALLETS,
      component: ActiveWalletModal,
    },
    {
      type: ModalType.CREATE,
      component: WalletCreationForm,
      widget: WidgetType.WALLETS,
    },
    {
      type: ModalType.WALLETS_SUMMARY,
      component: WalletSummaryModal,
      widget: WidgetType.WALLETS_SUMMARY,
    },

    // Proxies
    {
      type: ModalType.CREATE,
      widget: WidgetType.PROXIES,
      component: ProxyCreatingForm,
    },
    {
      type: ModalType.ADDING_MULTIPLE_PROXIES,
      widget: WidgetType.PROXIES,
      component: ProxiesMultiAdding,
    },

    {
      type: ModalType.SHOW,
      widget: WidgetType.PAYMENT_HINTS_CONSTRUCTOR,
      component: PaymentHintViewModal,
    },
  ]);

  const modals = map(modalList, (modal, key) => <Modal key={key} {...modal} />);

  return <Fragment>{modals}</Fragment>;
};

export default GeneralModals;
