import { all } from 'redux-saga/effects';

import widgetSagas from 'sagas/widgets';
import authSagas from 'sagas/auth';
import proxySagas from 'sagas/widgets/proxy';
import walletSagas from 'sagas/widgets/wallet';
import dashboardSagas from 'sagas/dashboard';
import transactionSagas from 'sagas/widgets/transactions';
import paytmSagas from 'sagas/widgets/wallets/paytm';
import paytmCookiesSagas from 'sagas/widgets/paytmCookies';
import manualPSSagas from 'sagas/widgets/manualPS';
import settingsSagas from 'sagas/widgets/settings/settings';
import paymentCurrenciesSettingsSagas from 'sagas/widgets/settings/paymentCurrenciesSettings';
import paymentsConstructorSagas from 'sagas/widgets/constructors/paymentsContructor';
import postbackSagas from 'sagas/widgets/postbacks';
import stockpilingSagas from 'sagas/widgets/stockpiling';
import reportsSagas from 'sagas/widgets/reports';
import withdrawalsSagas from 'sagas/widgets/withdrawals';
import tariffsSagas from 'sagas/widgets/tariffs';
import paymentHintSagas from 'sagas/widgets/paymentHints';
import filtersSagas from 'sagas/filters';
import settlementSagas from 'sagas/widgets/balances/settlement';
import accountBalanceSagas from 'sagas/widgets/balances/accountBalances';

export default function* rootSaga() {
  yield all([
    ...widgetSagas,
    ...authSagas,
    ...proxySagas,
    ...walletSagas,
    ...dashboardSagas,
    ...transactionSagas,
    ...paytmSagas,
    ...paytmCookiesSagas,
    ...manualPSSagas,
    ...settingsSagas,
    ...paymentCurrenciesSettingsSagas,
    ...paymentsConstructorSagas,
    ...postbackSagas,
    ...stockpilingSagas,
    ...reportsSagas,
    ...withdrawalsSagas,
    ...filtersSagas,
    ...tariffsSagas,
    ...paymentHintSagas,
    ...settlementSagas,
    ...accountBalanceSagas,
  ]);
}
