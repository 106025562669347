import React from 'react';

import { WidgetType } from 'utils/enums';
import { transactionsOnlyByIdActionCreators } from 'actions/widgets/transactions/transactions';

import TransactionActivationModal from 'components/widgets/transaction/transactionActivation/TransactionActivationModal';

const TransactionActivationOnlyById = () => {
  return (
    <TransactionActivationModal
      widget={WidgetType.TRANSACTIONS_ONLY_BY_ID}
      actionCreators={transactionsOnlyByIdActionCreators}
    />
  );
};

export default TransactionActivationOnlyById;
