import React from 'react';
import RemovingModal from 'components/modals/RemovingModal';
import { paymentsConstructorActionCreators } from 'actions/widgets/constructors/paymentsConstructor';
import { useTranslation } from 'react-i18next';
import { WidgetType } from 'utils/enums';

const DeleteManualPS = () => {
  const [t] = useTranslation();

  return (
    <RemovingModal
      widget={WidgetType.PAYMENTS_CONSTRUCTOR}
      actionCreators={paymentsConstructorActionCreators}
      content={t(`paymentConstructor.areYouSureWantToDeleteManualPS`)}
      title={t(`paymentConstructor.deletePaymentSystem`)}
    />
  );
};

export default DeleteManualPS;
