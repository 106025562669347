import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import split from 'lodash/split';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import { useTranslation } from 'react-i18next';
import { WalletImg, useFilterColumns, useIsTouchDevice } from '@kassma-team/kassma-toolkit/lib';

import { ModalType, Permission, PreliminaryTransactionStatus, PossibleTransactionsColumn } from 'utils/enums';
import { formatDate } from 'utils/formatDate';
import { possibleTransactionActionCreators } from 'actions/widgets/transactions/possibleTransactions';
import { hasAccessSelector } from 'selectors/auth';
import useFullAmount from 'hooks/useFullAmount';
import { IPossibleTransactionRowProps } from 'interfaces/widgets/transaction/transaction';
import { paymentSystemLogoSelector } from '../../../../selectors/widgets/paymentSystems';

const { showModal: showManagementModal, remove } = possibleTransactionActionCreators;

const PossibleTransactionRow = ({
  id,
  primary_id,
  wallet_type,
  created_at,
  desktopSize,
  status,
  test_wallet_type,
  label,
  amount,
  currency_symbol,
  hiddenColumns,
  merchant_order_id,
  is_manual,
  screenshot,
}: IPossibleTransactionRowProps) => {
  const dispatch = useDispatch();

  const [t] = useTranslation();

  const isTouchDevice = useIsTouchDevice();

  const fullAmount = useFullAmount({ amount, currencyCode: currency_symbol });

  const removePossibleTransactionAccess = useSelector(hasAccessSelector(Permission.REMOVE_POSSIBLE_TRANSACTION));

  const testWalletLogo = get(test_wallet_type, `logo`);

  const statusField =
    status === PreliminaryTransactionStatus.NEW ? (
      <span className="badge badge-primary">{t(`transactions.new`)}</span>
    ) : (
      <span className="badge badge-success">{t(`transactions.processed`)}</span>
    );

  const [displayCreatedAt] = split(formatDate(created_at));

  const logo = useSelector(paymentSystemLogoSelector(wallet_type));
  const walletTypeImage = (
    <WalletImg
      title={wallet_type === `test` ? get(test_wallet_type, `name`) : wallet_type}
      src={logo || testWalletLogo}
    />
  );

  const successLabel = label && <span className="badge badge-success">{label}</span>;

  const orderIdLabel = merchant_order_id && <span className="badge badge-info">{merchant_order_id}</span>;

  const controls = (
    <div className="btn-group">
      {is_manual === 1 && status === 0 && (
        <button
          type="button"
          className={classNames(`btn`, `btn-success`, `js-tooltip-enabled`, {
            'btn-sm': !isTouchDevice,
          })}
          onClick={() =>
            dispatch(
              showManagementModal({ type: ModalType.UPDATE, id: primary_id, wallet_type, amount, currency_symbol })
            )
          }
        >
          {t(`common.approve`)}
        </button>
      )}
      {is_manual === 1 && screenshot && (
        <button
          type="button"
          className={classNames(`btn`, `btn-secondary`, `js-tooltip-enabled`, {
            'btn-sm': !isTouchDevice,
          })}
          onClick={() => dispatch(showManagementModal({ type: ModalType.SHOW, id: primary_id }))}
        >
          <i className="fa fa-eye" />
        </button>
      )}
      {status === PreliminaryTransactionStatus.NEW && removePossibleTransactionAccess && (
        <button
          type="button"
          className={classNames(`btn`, `btn-secondary`, `js-tooltip-enabled`, { 'btn-sm': !isTouchDevice })}
          onClick={() => dispatch(remove(primary_id))}
        >
          <i className="fa fa-trash" />
        </button>
      )}
    </div>
  );

  const columns = [
    {
      id: PossibleTransactionsColumn.ID,
      desktop: <td>{primary_id}</td>,
      mobile: (
        <tr>
          <td>{t(`common.ID`)}</td>
          <td>{primary_id}</td>
        </tr>
      ),
    },
    {
      id: PossibleTransactionsColumn.TYPE,
      desktop: <td>{walletTypeImage}</td>,
      mobile: (
        <tr className="first-mobile-row">
          <td>{t(`common.type`)}</td>
          <td>{walletTypeImage}</td>
        </tr>
      ),
    },
    {
      id: PossibleTransactionsColumn.TRANSACTION_NUMBER,
      desktop: <td>{id}</td>,
      mobile: (
        <tr>
          <td>{t(`transactions.transactionNumber`)}</td>
          <td>{id}</td>
        </tr>
      ),
    },
    {
      id: PossibleTransactionsColumn.AMOUNT,
      desktop: <td>{isNumber(amount) && fullAmount}</td>,
      mobile: isNumber(amount) && (
        <tr>
          <td>{t(`common.amount`)}</td>
          <td>{fullAmount}</td>
        </tr>
      ),
    },
    {
      id: PossibleTransactionsColumn.LABEL,
      desktop: (
        <td className="multi-badge-cell">
          {successLabel}
          {orderIdLabel}
        </td>
      ),
      mobile: (
        <>
          {successLabel && (
            <tr>
              <td>{t(`common.label`)}</td>
              <td>{successLabel}</td>
            </tr>
          )}
          {orderIdLabel && (
            <tr>
              <td>{t(`common.orderId`)}</td>
              <td>{orderIdLabel}</td>
            </tr>
          )}
        </>
      ),
    },
    {
      id: PossibleTransactionsColumn.CREATED_AT,
      desktop: <td>{displayCreatedAt}</td>,
      mobile: (
        <tr>
          <td>{t(`common.date`)}</td>
          <td>{displayCreatedAt}</td>
        </tr>
      ),
    },
    {
      id: PossibleTransactionsColumn.ACTIONS,
      desktop: <td>{controls}</td>,
      mobile: (
        <tr>
          <td>{t(`common.actions`)}</td>
          <td>{controls}</td>
        </tr>
      ),
    },
    {
      id: PossibleTransactionsColumn.STATUS,
      desktop: <td className="multi-badge-cell">{statusField}</td>,
      mobile: (
        <tr>
          <td>{t(`common.status`)}</td>
          <td className="multi-badge-cell">{statusField}</td>
        </tr>
      ),
    },
  ];

  return useFilterColumns({ columns, desktopSize, hiddenColumns });
};

export default PossibleTransactionRow;
