import React, { FC, useContext, createContext, useState } from 'react';

type SaveModalCtx = {
  isSaveModalOpen: boolean;
  handleToggleModal: (flag: boolean) => () => void;
};

const saveModalCtx = createContext<SaveModalCtx | undefined>(undefined);

export const SaveModalCtx: FC = ({ children }) => {
  const [isSaveModalOpen, setIsModalOpen] = useState(false);

  const handleToggleModal = (flag: boolean) => () => setIsModalOpen(flag);

  const val: SaveModalCtx = {
    isSaveModalOpen,
    handleToggleModal,
  };

  return <saveModalCtx.Provider value={val}>{children}</saveModalCtx.Provider>;
};

export function useSaveModalCtx(): SaveModalCtx {
  const context = useContext(saveModalCtx);

  if (context === undefined) {
    throw new Error(`Is need to use useSaveModalContext inside a SaveModalContext component`);
  }

  return context;
}
