import { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DecoratedComponentClass } from 'redux-form';
import { withPermissionCheck as withKassmaPermissionCheck } from '@kassma-team/kassma-toolkit/lib';

import { userPermissionsSelector } from 'selectors/auth';

interface IPermission {
  permission: string;
  opposite: boolean;
}

interface IRedirectData {
  redirectUrl: string;
  permissions: IPermission[];
}

const withPermissionCheck: any = (
  Component: FunctionComponent | DecoratedComponentClass<any, any>,
  redirectData: IRedirectData[]
) => (props: any) => {
  const dispatch = useDispatch();
  const permissionsList = useSelector(userPermissionsSelector);

  return withKassmaPermissionCheck(permissionsList, dispatch)(Component, redirectData)(props);
};

export default withPermissionCheck;
