import React, { FC, useContext, createContext, useState } from 'react';

type ConfirmModalCtx = {
  isConfirmModalOpen: boolean;
  handleToggleModal: (flag: boolean) => () => void;
};

const confirmModalCtx = createContext<ConfirmModalCtx | undefined>(undefined);

export const ConfirmModalCtx: FC = ({ children }) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const handleToggleModal = (flag: boolean) => () => setIsConfirmModalOpen(flag);

  const val: ConfirmModalCtx = {
    isConfirmModalOpen,
    handleToggleModal,
  };

  return <confirmModalCtx.Provider value={val}>{children}</confirmModalCtx.Provider>;
};

export function useConfirmModalCtx(): ConfirmModalCtx {
  const context = useContext(confirmModalCtx);

  if (context === undefined) {
    throw new Error(`Is need to use useConfirmModalContext inside a ConfirmModalContext component`);
  }

  return context;
}
