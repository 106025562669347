import React, { ReactNode, useState } from 'react';
import isNull from 'lodash/isNull';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import useMount from 'react-use/lib/useMount';
import { useTranslation } from 'react-i18next';
import { Preloader, widgetModalSelector, widgetListItemSelector, Size } from '@kassma-team/kassma-toolkit/lib';

import { Permission, PreliminaryTransactionStatus, WidgetType } from 'utils/enums';
import { possibleTransactionActionCreators } from 'actions/widgets/transactions/possibleTransactions';
import { hasAccessSelector } from 'selectors/auth';
import possibleTransactionApi from 'api/transactions/possibleTransactions/PossibleTransactionApi';

import './possibleTransactionItem.scss';

interface IViewRowProps {
  label: string;
  children: ReactNode;
}

const ViewRow = ({ label, children }: IViewRowProps) => (
  <div className="row mb-15">
    <span className="col-4 font-weight-bold">{label}</span>
    <span className="col-8">{children}</span>
  </div>
);

const { setItem, remove, hideModal } = possibleTransactionActionCreators;

const PossibleTransactionItem = ({ id }: Partial<Record<`id`, number>>) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [imageError, setImageError] = useState(false);

  const [t] = useTranslation();

  const modal = useSelector(widgetModalSelector(WidgetType.POSSIBLE_TRANSACTIONS));
  const itemId = id || get(modal, `id`);

  const removePossibleTransactionAccess = useSelector(hasAccessSelector(Permission.REMOVE_POSSIBLE_TRANSACTION));
  const item = useSelector(
    widgetListItemSelector({ path: `primary_id`, value: itemId })(WidgetType.POSSIBLE_TRANSACTIONS)
  );
  const status = get(item, `status`);

  const dispatch = useDispatch();

  useMount(() => {
    if (itemId) {
      dispatch(setItem(itemId));

      possibleTransactionApi
        .loadTransactionImage(itemId)
        .then((resp) => {
          const urlCreator = window.URL || window.webkitURL;
          setImageSrc(urlCreator.createObjectURL(resp.data));
        })
        .catch(() => setImageError(true));
    }
  });

  let image;
  if (imageError) {
    image = <span className="text-error">{t(`transactions.screenDownloadingHasBeenFailed`)}</span>;
  } else if (isNull(imageSrc)) {
    image = <Preloader size={Size.MINI} />;
  } else {
    image = <img src={imageSrc} alt="" className="w-100 possible-transaction__image" />;
  }

  return (
    <div>
      <ViewRow label={t(`transactions.screen`)}>{image}</ViewRow>
      {removePossibleTransactionAccess && status === PreliminaryTransactionStatus.NEW && (
        <button
          className="btn btn-danger full-width"
          onClick={() => {
            dispatch(remove(itemId, { onSuccess: () => dispatch(hideModal()) }));
          }}
        >
          {t(`common.delete`)}
        </button>
      )}
    </div>
  );
};

export default PossibleTransactionItem;
