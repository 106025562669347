import React, { ReactElement, useEffect } from 'react';
import { getFormValues, reduxForm, InjectedFormProps } from 'redux-form';
import map from 'lodash/map';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import includes from 'lodash/includes';
import { ErrorBlock, i18n } from '@kassma-team/kassma-toolkit/lib';

import { CREATE_WALLET_FORM_NAME, DOLAR_DOLAR_HOST } from 'utils/constants';
import { WalletFormType, WalletPriority, WalletType } from 'utils/enums';
import validateWallets from 'utils/widgets/wallets/validate';
import useWalletData from 'hooks/widgets/wallets/useWalletData';
import { IValidateWalletsProps } from 'interfaces/widgets/wallets/validate';
import { loadPaytmFlag } from 'actions/widgets/settings';
import { loadProxyData } from 'actions/widgets/constructors/paymentsConstructor';

import ConfirmationModal from 'components/widgets/wallets/ConfirmationModal';
import FormField from 'components/form/FormField';
import { isManualPSSelector } from '../../../../selectors/widgets/paymentSystems';
import { getManualFields } from '../../../../actions/widgets/wallet';
import { manualFieldsSelector } from '../../../../selectors/widgets/wallets';
import getBarcode from 'utils/widgets/wallets/getBarcode';

interface IWalletCreationData {
  walletTypeValue: string;
  onForcedSubmit: () => void;
  onConfirmationInfoCancel: () => void;
  confirmationInfoType: number | null;
  confirmationInfoData?: any;
  submitting: boolean;
  useProxy?: boolean;
  useProxyNightEmailParser?: boolean;
}

const WalletCreationData = ({
  handleSubmit,
  walletTypeValue,
  error,
  submitFailed,
  change,
  confirmationInfoType,
  confirmationInfoData,
  onForcedSubmit,
  onConfirmationInfoCancel,
  submitting,
  initialValues,
  form,
}: InjectedFormProps<IValidateWalletsProps> & IWalletCreationData): ReactElement => {
  const values = useSelector(getFormValues(form)) || {};
  const isManualPS = useSelector(isManualPSSelector(walletTypeValue));
  const manualFields = useSelector(manualFieldsSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isManualPS) {
      dispatch(getManualFields(walletTypeValue));
    }
  }, [isManualPS]);

  const fields = useWalletData({
    type: WalletFormType.CREATE,
    formName: form,
    change,
    walletType: walletTypeValue,
    initialValues,
    manualFields,
    isManualPS,
  });

  // useEffect(() => {
  //   if (walletTypeValue === WalletType.PAY_TM) {
  //     dispatch(loadPaytmFlag());
  //   }
  // }, [walletTypeValue]);

  useEffect(() => {
    dispatch(loadProxyData(walletTypeValue));
    if (change) {
      change(`priority`, WalletPriority.NORMAL);
      if (walletTypeValue === WalletType.DOLAR_DOLAR) {
        change(`payment_link`, DOLAR_DOLAR_HOST);
      }
      if (walletTypeValue === WalletType.UPI_IB) {
        change(`site_parser`, true);
      }
      if (
        walletTypeValue === WalletType.PHONE_PE ||
        walletTypeValue === WalletType.UPI_HB ||
        walletTypeValue === WalletType.IMPS_HB
      ) {
        change(`parsing_object`, `webpage`);
      }
    }
  }, []);

  if (confirmationInfoType) {
    return (
      <ConfirmationModal
        infoType={confirmationInfoType}
        infoData={confirmationInfoData}
        onCancel={onConfirmationInfoCancel}
        onSave={onForcedSubmit}
        submitFailed={submitFailed}
        error={error}
        submitting={submitting}
      />
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      {map(fields, (field, key) => {
        const fieldId = get(field, `name`) || key;

        return <FormField key={fieldId} isModalField {...field} />;
      })}
      {submitFailed && error && <ErrorBlock error={error} />}
    </form>
  );
};

export default reduxForm<IValidateWalletsProps, IWalletCreationData, any>({
  form: CREATE_WALLET_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateWallets,
  asyncValidate: (values, disp, props) => {
    return new Promise((res, rej) => {
      const wallet_type = values.wallet_type;
      if (wallet_type === WalletType.JAZZCASH_BUSINESS && values.qr_data) {
        getBarcode(values.qr_data)
          .catch((err) => {
            rej({ qr_data: i18n.t(`wallets.incorrectQR`) });
          })
          .then((barcode) => {
            if (!barcode || !barcode.startsWith(`0002`)) {
              rej({ qr_data: i18n.t(`wallets.incorrectQR`) });
            } else {
              res({});
            }
          });
      } else {
        res({});
      }
    });
  },
})(WalletCreationData as any);
