import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';

export class MinimumDepositApi extends BaseApi {
  loadMinDeposits = (walletType: string): AxiosPromise =>
    this.makeFetch(`{walletType}/get-minimum-deposit`, {}, { walletType });

  updateMinDeposits = (walletType: string, data: Record<any, unknown>): AxiosPromise =>
    // todo unused api
    this.makeFetch(`{walletType}`, { data, method: `POST` }, { walletType });
}

const minimumDepositApi = new MinimumDepositApi(`minimum-deposit`);

export default minimumDepositApi;
