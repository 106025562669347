import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import { useMount } from 'react-use';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { IModalFormProps, widgetModalSelector } from '@kassma-team/kassma-toolkit/lib';
import { Dispatch } from 'redux';

import { WalletType, WidgetType } from 'utils/enums';
import { paytmActionCreators, paytmWalletConfirmation } from 'actions/widgets/payments/paytm';
import { PAYTM_PHONE_CONFIRM_FORM_NAME } from 'utils/constants';

import ModalForm from 'components/modals/ModalForm';

const getSmsValues = (values: Record<string, unknown> | unknown) => {
  const identifier = get(values, `identifier`);
  const wallet_type = get(values, `wallet_type`);

  if (wallet_type === WalletType.PAY_TM) {
    return { identifier, wallet_type };
  }

  return { wallet_type, identifier };
};

const PaytmPhoneConfirm = (props: IModalFormProps) => {
  const modal = useSelector(widgetModalSelector(WidgetType.WALLET_PAYTM));

  const [t] = useTranslation();

  const [fields] = useState([
    {
      name: `sms_code`,
      label: t(`wallets.enterCode`),
      required: true,
      numeric: true,
    },
  ]);

  useMount(() => {
    props.initialize(getSmsValues(modal));
  });

  return (
    <ModalForm
      {...props}
      fields={fields}
      submitText="common.confirm"
      title="wallets.phoneConfirmation"
      actionCreators={paytmActionCreators}
      widget={WidgetType.WALLET_PAYTM}
    />
  );
};

export default reduxForm<any, any, any>({
  form: PAYTM_PHONE_CONFIRM_FORM_NAME,
  onSubmit: (values: Record<string, unknown>, dispatch: Dispatch) => dispatch(paytmWalletConfirmation(values)),
})(PaytmPhoneConfirm);
