import React, { ReactNode } from 'react';
import { IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import TransactionRow from 'components/widgets/transaction/TransactionRow';
import { ITransactionsRowProps } from 'interfaces/widgets/transaction/transaction';

interface IProps {
  actionCreators: IWidgetActionCreators;
  widget: string;
}

const transactionListMapping = ({ actionCreators, widget }: IProps) => (item: ITransactionsRowProps): ReactNode => (
  <TransactionRow {...item} actionCreators={actionCreators} widget={widget} key={item.primary_id} />
);

export default transactionListMapping;
