import React from 'react';
import { useTranslation } from 'react-i18next';
import { InnerTable } from '@kassma-team/kassma-toolkit/lib';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';

import { hasAccessSelector } from 'selectors/auth';
import { settlementWithdrawalApprove, settlementWithdrawalReject } from 'actions/widgets/balances/settlement';
import useFullAmount from 'hooks/useFullAmount';
import { Permission, SettlementWithdrawalStatusType } from 'utils/enums';
import { formatDate } from 'utils/formatDate';
import { ISettlementWithdrawalRow } from 'interfaces/widgets/settlement';

const settlementWithdrawalStatusType = {
  [SettlementWithdrawalStatusType.NEW]: {
    name: `balances.statusNew`,
    badge: `badge-primary`,
  },
  [SettlementWithdrawalStatusType.SUCCESS]: {
    name: `balances.statusSuccess`,
    badge: `badge-success`,
  },
  [SettlementWithdrawalStatusType.REJECTED]: {
    name: `balances.statusRejected`,
    badge: `badge-warning`,
  },
};

const SettlementWithdrawalRow = ({
  desktopSize,
  id,
  status,
  amount,
  bitcoin_address,
  created_at,
}: ISettlementWithdrawalRow) => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const formattedAmount = useFullAmount({ amount, precision: 2, needToTrim: false });

  const managePermission = useSelector(hasAccessSelector(Permission.MANAGER_BALANCE));

  const statusField = (
    <div className="multi-badge-cell">
      <span className={`badge ${get(settlementWithdrawalStatusType, `${status}.badge`)}`}>
        {t(get(settlementWithdrawalStatusType, `${status}.name`))}
      </span>
    </div>
  );

  const controls = managePermission && status === SettlementWithdrawalStatusType.NEW && (
    <div className="btn-group">
      <button
        type="button"
        className="btn btn-sm btn-success"
        onClick={() => {
          dispatch(settlementWithdrawalApprove(id));
        }}
      >
        {t(`common.approve`)}
      </button>
      <button
        type="button"
        className="btn btn-sm btn-danger"
        onClick={() => {
          dispatch(settlementWithdrawalReject(id));
        }}
      >
        {t(`common.reject`)}
      </button>
    </div>
  );

  const formattedCreatedAt = formatDate(created_at);

  return desktopSize ? (
    <tr>
      <td>{formattedCreatedAt}</td>
      <td>{bitcoin_address}</td>
      <td>
        {formattedAmount} {t(`wallets.cu`)}
      </td>
      <td>{statusField}</td>
      <td>{controls}</td>
    </tr>
  ) : (
    <InnerTable cellClassName="mobile-inner-table">
      <tr className="first-mobile-row">
        <td>{t(`common.date`)}</td>
        <td>{formattedCreatedAt}</td>
      </tr>
      <tr>
        <td>{t(`balances.writeOffAddress`)}</td>
        <td>{bitcoin_address}</td>
      </tr>
      <tr>
        <td>{t(`common.amount`)}</td>
        <td>
          {formattedAmount} {t(`wallets.cu`)}
        </td>
      </tr>
      <tr>
        <td>{t(`common.status`)}</td>
        <td>{statusField}</td>
      </tr>
      <tr>
        <td>{t(`common.actions`)}</td>
        <td>{controls}</td>
      </tr>
    </InnerTable>
  );
};

export default SettlementWithdrawalRow;
