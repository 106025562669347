import React, { useEffect, useState } from 'react';

import { WidgetType } from 'utils/enums';
import { removedWalletActionCreators } from 'actions/widgets/wallet';
import ConfirmationModal from '../../../modals/ConfirmationModal';
import { useDispatch, useSelector } from 'react-redux';
import { InputType, IWidgetModal, widgetModalSelector } from '@kassma-team/kassma-toolkit';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { CREATE_WALLET_FORM_NAME } from 'utils/constants';
import { paymentSystemsSelector } from 'selectors/widgets/paymentSystems';
import ModalForm from '../../../modals/ModalForm';
import moment from 'moment/moment';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';

const RemoveWalletRecovery = ({ change, ...props }: InjectedFormProps) => {
  const [t] = useTranslation();
  const [isParsingStartAt, setIsParsingStartAt] = useState(false);
  const dispatch = useDispatch();
  const modal: IWidgetModal = useSelector(widgetModalSelector(WidgetType.REMOVED_WALLETS));
  const values = useSelector(getFormValues(CREATE_WALLET_FORM_NAME)) as {
    parsing_start_at: Moment | string;
    parsing_start_time: string;
  };
  const paymentSystemsList = useSelector(paymentSystemsSelector);
  const isP2P = paymentSystemsList.some((ps) => ps.code === modal?.walletType && ps.payment_type === `P2P`);
  const maxDate = new Date();

  useEffect(() => {
    if (!isParsingStartAt) {
      change(`parsing_start_hint`, t(`wallets.walletRecoveryDate`));
    } else if (isParsingStartAt) {
      change(`parsing_start_at`, maxDate);
      change(`parsing_start_time`, `00:00:00`);
    }
  }, [isParsingStartAt]);

  if (!paymentSystemsList.length) return <></>;

  if (!isP2P && modal) {
    return (
      <ConfirmationModal
        actionCreators={removedWalletActionCreators}
        title="wallets.walletRecovery"
        content="wallets.doYouReallyWantToRecoverThisWallet"
        confirmText="common.restore"
        onConfirm={() => {
          dispatch(removedWalletActionCreators.create({ id: modal?.id }));
        }}
      />
    );
  }

  let parsingStartAt: string | undefined = undefined;
  if (values?.parsing_start_at) {
    parsingStartAt = moment(values?.parsing_start_at).format(`YYYY-MM-DD HH:mm:ss`);
  }

  const fields = !isParsingStartAt
    ? [
        {
          name: `parsing_start_hint`,
          label: t(`wallets.ParsingStartDate`),
          onBlur: () => setIsParsingStartAt(false),
          onFocus: () => setIsParsingStartAt(true),
        },
      ]
    : [
        {
          name: `parsing_start_at`,
          label: t(`wallets.ParsingStartDate`),
          type: InputType.DATE_TIME_PICKER,
          fromValue: values?.parsing_start_at,
          timePicker: true,
          hideOnDateChanging: false,
          bothDateRequired: true,
          showSeconds: true,
        },
      ];

  const Hint = <div className="hint-recover-wallet">{t(`wallets.sureToRecoverWallet`)}</div>;

  if (isP2P) {
    return (
      <ModalForm
        onSubmit={() => {
          dispatch(removedWalletActionCreators.create({ id: modal?.id, parsing_start_at: parsingStartAt }));
          removedWalletActionCreators.hideModal();
        }}
        title="wallets.walletRecovery"
        actionCreators={removedWalletActionCreators}
        submitText="common.restore"
        fields={fields}
        preContent={Hint}
        {...props}
      />
    );
  }

  return <></>;
};

export default reduxForm<{ parsing_start_time: string; parsing_start_at: string }, any, any>({
  form: CREATE_WALLET_FORM_NAME,
})(RemoveWalletRecovery);
