import React, { FC } from 'react';
import {
  FormTypeCtx,
  useFormTypeCtx,
  LangsuagesCtx,
  useLanguagesCtx,
  PaymentSystemsCtx,
  usePaymentSystemsCtx,
} from '../../common/ctx';

import { ConfirmModalCtx, useConfirmModalCtx } from './useConfirmModal';
import { ModifiedPackageTranslationsCtx, useModifiedPackageTranslationsCtx } from './useModifiedPaymentTranslations';
import { TranslationsCtx, useTranslationsCtx } from './useTranslationsCtx';
import { CreationModeCtx, useCreationModeCtx } from './useCreationModeCtx';
import { UseOpenedFormTypeCtx, useUseOpenedFormTypeCtx } from './useOpenedFormType';

export { FormTypeCtx, LangsuagesCtx, PaymentSystemsCtx };

export { ConfirmModalCtx, ModifiedPackageTranslationsCtx, TranslationsCtx, CreationModeCtx, UseOpenedFormTypeCtx };

export {
  useFormTypeCtx,
  useLanguagesCtx,
  usePaymentSystemsCtx,
  useConfirmModalCtx,
  useModifiedPackageTranslationsCtx,
  useTranslationsCtx,
  useCreationModeCtx,
  useUseOpenedFormTypeCtx,
};

export const PackagesTranslationCtx: FC = ({ children }) => {
  return (
    <ConfirmModalCtx>
      <ModifiedPackageTranslationsCtx>
        <PaymentSystemsCtx>
          <FormTypeCtx>
            <TranslationsCtx>
              <CreationModeCtx>
                <UseOpenedFormTypeCtx>
                  <LangsuagesCtx>{children}</LangsuagesCtx>
                </UseOpenedFormTypeCtx>
              </CreationModeCtx>
            </TranslationsCtx>
          </FormTypeCtx>
        </PaymentSystemsCtx>
      </ModifiedPackageTranslationsCtx>
    </ConfirmModalCtx>
  );
};
