import { WithdrawalStatus } from 'utils/enums';

const withdrawalStatusField = {
  [WithdrawalStatus.NEW]: {
    text: `withdrawals.new`,
    badge: `badge-primary`,
    filterText: `withdrawals.filterNew`,
  },
  [WithdrawalStatus.IN_PROGRESS]: {
    text: `withdrawals.inProgress`,
    badge: `badge-secondary`,
    filterText: `withdrawals.filterInProgress`,
  },
  [WithdrawalStatus.CANCELED]: {
    text: `withdrawals.canceled`,
    badge: `badge-danger`,
    filterText: `withdrawals.filterCanceled`,
  },
  [WithdrawalStatus.PROCESSED]: {
    text: `withdrawals.processed`,
    badge: `badge-success`,
    filterText: `withdrawals.filterProcessed`,
  },
  [WithdrawalStatus.REJECTED]: {
    text: `withdrawals.rejected`,
    badge: `badge-warning`,
    filterText: `withdrawals.filterRejected`,
  },
  [WithdrawalStatus.CHECK]: {
    text: `withdrawals.check`,
    badge: `badge-warning`,
    filterText: `withdrawals.check`,
  },
  [WithdrawalStatus.ADD_BENEFICIARE]: {
    text: `withdrawals.addBeneficiare`,
    badge: `badge-secondary`,
  },
  [WithdrawalStatus.ERROR]: {
    text: `withdrawals.error`,
    badge: `badge-danger`,
    filterText: `withdrawals.error`,
  },
};

export default withdrawalStatusField;
