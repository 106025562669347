import React, { useState } from 'react';
import get from 'lodash/get';
import moment from 'moment';
import useMount from 'react-use/lib/useMount';
import { useDispatch, useSelector } from 'react-redux';

import { DashboardChartCategory, Permission } from 'utils/enums';
import { fetchDashboardChartEntityData } from 'actions/dashboard';
import { hasAccessSelector } from 'selectors/auth';
import {
  IDashboardStateWithdrawals,
  IDashboardStateWithdrawalsCount,
  IDashboardStateWithdrawalsSum,
} from 'interfaces/dashboard';
import Chart from './chart/Chart';

import TabList from 'components/dashboard/TabList';

const categories = [
  { id: DashboardChartCategory.SUM, title: `dashboard.sum` },
  { id: DashboardChartCategory.COUNT, title: `dashboard.count` },
];

const DashboardChartTab = ({ chartData }: Record<`chartData`, IDashboardStateWithdrawals>) => {
  const dashboardAccess = useSelector(hasAccessSelector(Permission.DASHBOARD));

  const [activeChartCategoryTab, setActiveChartCategoryTab] = useState<number>(DashboardChartCategory.SUM);

  const dispatch = useDispatch();

  useMount(() => {
    if (dashboardAccess) {
      dispatch(fetchDashboardChartEntityData());
    }
  });

  const prepareChartData = (chartData: IDashboardStateWithdrawalsSum[] | IDashboardStateWithdrawalsCount[]) => {
    return chartData.map((item) => ({ ...item, date: moment(item.date).format(`DD.MM`) }));
  };

  const chartDataSum = prepareChartData(get(chartData, `sum`, []));
  const chartDataCount = prepareChartData(get(chartData, `count`, []));

  const changeChartEntityTab = (number: number) => {
    setActiveChartCategoryTab(number);
  };

  const showSum = activeChartCategoryTab === DashboardChartCategory.SUM;
  const showCount = activeChartCategoryTab === DashboardChartCategory.COUNT;

  return (
    <>
      <TabList
        onChange={changeChartEntityTab}
        list={categories}
        activeTab={activeChartCategoryTab}
        className="dashboard-chart-tabs"
      />
      <div className="dashboard-chart-tab tab-content" id="nav-tabContent">
        <div className="dashboard-tab-wrapper">
          {showSum && <Chart chartData={chartDataSum} isSum />}
          {showCount && <Chart chartData={chartDataCount} />}
        </div>
      </div>
    </>
  );
};

export default DashboardChartTab;
