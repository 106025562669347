import React, { useState } from 'react';

import { ChartType } from 'utils/enums';
import LineChart from './LineChart';
import BarChart from './BarChart';
import { IChartProps } from 'interfaces/dashboard';

import TabList from 'components/dashboard/TabList';

import './chart.scss';

const chartCategories = [
  { id: ChartType.LINE, title: `Line` },
  { id: ChartType.BAR, title: `BarChart` },
];

const Chart = ({ chartData, isSum = false }: IChartProps) => {
  const [activeChartTab, setActiveChartTab] = useState(ChartType.LINE);

  const changeTab = (number: number) => {
    setActiveChartTab(number);
  };

  const showLine = activeChartTab === ChartType.LINE;
  const showBar = activeChartTab === ChartType.BAR;

  return (
    <div>
      <TabList onChange={changeTab} list={chartCategories} activeTab={activeChartTab} className="chart-types-tabs" />
      <div className="tab-content" id="nav-tabContent">
        <div className="dashboard-tab-wrapper">
          {showLine && <LineChart chartData={chartData} isSum={isSum} />}
          {showBar && <BarChart chartData={chartData} isSum={isSum} />}
        </div>
      </div>
    </div>
  );
};

export default Chart;
