import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';

export type PaymentType = `P2P` | `API` | `MANUAL`;

export type WalletType = {
  id: string;
  code: string;
  name: string;
  created_at: string;
  updated_at: string;
  payment_type: PaymentType;
  applied_currencies: ReadonlyArray<string>;
  supported_currencies: ReadonlyArray<string>;
  supported_wallet_currencies: ReadonlyArray<string>;
  logo: string;
  type_settings: {
    is_check_amount: boolean;
    is_control: boolean;
    use_possible_transactions: boolean;
    is_standard: boolean;
    is_settlement: boolean;
    standard_threshold: number;
    plugin_visibility: boolean;
    is_enabled: boolean;
    enable_notifications: boolean;
    show_transaction_id: boolean;
    valid_trans_id_from: number;
    valid_trans_id_to: number;
    show_payment_bill: boolean;
  };
};

export type Limit = {
  wallet_type: string;
  currency: string;
  min: number;
  max: number;
};

// У нас есть Апи WalletTypes, которое фетчит с wallet-type
// Сие фетчит именно с wallet-types
export class PluralWalletTypesApi extends BaseApi {
  limits = (
    direction: `outgoing` | `ingoing`
  ): AxiosPromise<{
    data: ReadonlyArray<Limit>;
  }> => this.makeFetch(`limits`, { queryParams: { direction } });

  list = (): AxiosPromise<ReadonlyArray<WalletType>> => this.makeFetch(``);
}

const pluralWalletTypesApi = new PluralWalletTypesApi(`wallet-types`);

export default pluralWalletTypesApi;
