import React from 'react';
import map from 'lodash/map';
import classNames from 'classnames';

import FullAmount from 'components/base/FullAmount';

interface IDashboardSiderItem {
  value: number;
  description: string;
  currencyCode?: string;
  showCurrencyCode?: boolean;
  usdValue?: number;
  wrapClassName?: string;
  isUppercase?: boolean;
}

export const DashboardSiderItem = ({
  value,
  description,
  currencyCode,
  showCurrencyCode = false,
  usdValue,
  wrapClassName,
  isUppercase = true,
}: IDashboardSiderItem) => {
  const textClassNames = [`font-size-sm font-w500 text-muted`];
  if (isUppercase) {
    textClassNames.push(`text-uppercase`);
  }

  return (
    <div className={classNames(`dashboard-sider-item`, wrapClassName)}>
      <div className="font-size-h3 font-w600 dashboard-number">
        {showCurrencyCode ? <FullAmount amount={value} currencyCode={currencyCode} /> : parseFloat(value?.toString())}
        {!!usdValue && currencyCode !== `USD` && (
          <span className="display-block text-gray">
            (<FullAmount amount={usdValue} currencyCode="USD" />)
          </span>
        )}
      </div>
      <div className={textClassNames.join(` `)}>{description}</div>
    </div>
  );
};

interface IDashboardSiderSection {
  title: string;
  logoSrc: string;
  items: IDashboardSiderItem[];
  bordered?: boolean;
}

const DashboardSiderSection = ({ title, logoSrc, items, bordered = true }: IDashboardSiderSection) => {
  return (
    <div className={classNames(`row pt-30 pb-30 align-items-center`, { 'border-b': bordered })}>
      <div className="col-12 mb-20">
        <span className="font-weight-bold text-uppercase">{title}</span>
      </div>
      <div className="col-8">
        {map(items, (item, key) => (
          <DashboardSiderItem {...item} key={key} />
        ))}
      </div>
      <div className="col-4 text-right">
        <img src={logoSrc} alt="" />
      </div>
    </div>
  );
};

interface IDashboardSiderInfo {
  data: IDashboardSiderSection[];
  periodText: string;
}

const DashboardSiderInfo = ({ data, periodText }: IDashboardSiderInfo) => {
  return (
    <>
      <div className="mb-10">
        <span className="font-weight-bold">{periodText}</span>
      </div>
      <div className="block dashboard-tile dashboard-tile_indent-top">
        <div className="block-content block-content-full pt-0 pb-0">
          {map(data, (item, key) => (
            <DashboardSiderSection {...item} key={key} />
          ))}
        </div>
      </div>
    </>
  );
};

export default DashboardSiderInfo;
