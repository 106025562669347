import useFullAmount from 'hooks/useFullAmount';

interface IProps {
  amount: string | number;
  currencySymbol?: string;
  currencyCode?: string;
}

const FullAmount = ({ amount, currencySymbol, currencyCode }: IProps) => {
  return useFullAmount({
    amount,
    currencySymbol,
    currencyCode,
  });
};

export default FullAmount;
