import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMount from 'react-use/lib/useMount';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Helmet from 'react-helmet';
import { Preloader, WidgetContainer, PageContainer, langSelector, Size } from '@kassma-team/kassma-toolkit/lib';

import { fetchDashboardData } from 'actions/dashboard';
import { dashboardAsideFilters, dashboardSelector } from 'selectors/dashboard';
import { ModalType, Permission, WidgetType } from 'utils/enums';
import { hasAccessSelector } from 'selectors/auth';
import { paymentSystemsActionCreators } from 'actions/widgets/paymentSystem';
import { walletsSummary as walletsSummaryActions } from 'actions/widgets/wallets';
import withPermissionCheck from 'hocs/withPermissionCheck';
import { IDashboardState } from 'interfaces/dashboard';

import AsideFilterForm from 'components/dashboard/AsideFilterForm';
import DashboardChartWithFilter from './DashboardChartWithFilter';
import DashboardSiderInfo from 'components/dashboard/DashboardSiderInfo';
import PaymentSystemsList from 'components/widgets/constructors/payments/PaymentSystemsList';
import Header from 'components/header/Header';
import Modal from 'components/modals/Modal';
import { renderSmallTitle } from './utils/renderSmallTitle';

import 'pages/dashboard/style.scss';

import BillIcon from 'assets/media/icons/bill.svg';
import CommissionIcon from 'assets/media/icons/withdraw.svg';
import ReportIcon from 'assets/media/icons/report.svg';
import api from 'api';

const Dashboard = () => {
  const dispatch = useDispatch();
  const dashboard: IDashboardState = useSelector(dashboardSelector);
  const withCompletedStockpiling = !!dashboard.chartFilters.stockpiling_status;
  const [walletsSummaryLoading, setWalletsSummaryLoading] = useState<boolean>(false);
  const [walletsSummary, setWalletsSummary] = useState<
    | undefined
    | {
        total: number;
        worked: number;
      }
  >(undefined);

  const [periodText, setPeriodText] = useState<string>(``);

  const { date_from = moment().subtract(6, `d`), date_to = moment() } = useSelector(dashboardAsideFilters) || {};

  const lang: string = useSelector(langSelector);

  useEffect(() => {
    const fetcher = async () => {
      try {
        setWalletsSummaryLoading(true);
        const { data } = await api.walletsApi.getSummary();

        // Общее количество кошельков для всех ПС
        const total = data.data.reduce((acc, item) => acc + item.all, 0);
        const worked = data.data.reduce((acc, item) => acc + item.worked, 0);

        setWalletsSummary({
          total,
          worked,
        });
      } catch (e) {
        console.log(e);
      } finally {
        setWalletsSummaryLoading(false);
      }
    };

    fetcher();
  }, []);

  useEffect(() => {
    if (date_from && date_to) {
      if (moment(date_from).isSame(date_to, `day`)) {
        setPeriodText(moment(date_from).locale(lang).format(`DD.MM.YY`));
      } else {
        setPeriodText(
          t(`dashboard.periodFromTo`, {
            from: moment(date_from).locale(lang).format(`DD.MM.YY`),
            to: moment(date_to).locale(lang).format(`DD.MM.YY`),
          })
        );
      }
    } else {
      setPeriodText(``);
    }
  }, [date_from, date_to]);

  const [t] = useTranslation();

  const dashboardAccess = useSelector(hasAccessSelector(Permission.DASHBOARD));
  const walletListAccess = useSelector(hasAccessSelector(Permission.WALLET_LIST));
  const proxyListAccess = useSelector(hasAccessSelector(Permission.PROXY_LIST));
  const commissionViewAccess = useSelector(hasAccessSelector(Permission.COMMISSION_VIEW));
  const paymentsConstructorAccess = useSelector(hasAccessSelector(Permission.MANAGE_PAYMENTS_CONSTRUCTOR));

  useMount(() => {
    if (dashboardAccess) {
      dispatch(fetchDashboardData());
    }
  });

  return (
    <>
      <PageContainer>
        <Helmet>
          <title>{t(`dashboard.dashboard`)}</title>
        </Helmet>
        <Header />
        <WidgetContainer>
          {!dashboard.loadedAllData ? <Preloader className="margin-center" size={Size.BIG} /> : null}
          <div className={!dashboard.loadedAllData ? `dashboard-hidden` : ``}>
            {!dashboardAccess ? null : (
              <div className="row js-appear-enabled animated fadeIn row-deck">
                {walletListAccess &&
                  renderSmallTitle({
                    number: walletsSummaryLoading ? undefined : walletsSummary?.worked,
                    title: `${t(`dashboard.workingWallets`)}`,
                    src: `wallet-verified`,
                    color: `earth`,
                    currencyCode: dashboard.currency.code,
                    totalWallets: walletsSummaryLoading ? undefined : walletsSummary?.total,
                    onClick: () => {
                      dispatch(walletsSummaryActions.showModal({ type: ModalType.WALLETS_SUMMARY }));
                    },
                  })}
                {proxyListAccess &&
                  renderSmallTitle({
                    number: dashboard.active_proxies_count,
                    title: t(`dashboard.workingProxies`),
                    src: `proxy`,
                    color: `corporate`,
                    currencyCode: dashboard.currency.code,
                  })}
                {dashboardAccess &&
                  renderSmallTitle({
                    number: dashboard.payment_systems_count,
                    title: t(`dashboard.paymentSystems`),
                    src: `payment-system`,
                    currencyCode: dashboard.currency.code,
                    color: `primary`,
                    link: paymentsConstructorAccess ? `/payments-constructor` : undefined,
                    onClick: !paymentsConstructorAccess
                      ? () => dispatch(paymentSystemsActionCreators.showModal({ type: ModalType.PAYMENT_SYSTEMS_LIST }))
                      : undefined,
                  })}
              </div>
            )}
            <div className="row align-items-stretch js-appear-enabled animated fadeIn">
              <div className="col-lg-8">
                <DashboardChartWithFilter />
              </div>
              <div className="col-lg-4">
                <div className="block">
                  <AsideFilterForm />
                </div>
                <DashboardSiderInfo
                  periodText={periodText}
                  data={[
                    {
                      title: t(`dashboard.sumOfActivatedTransactions`),
                      logoSrc: BillIcon,
                      items: [
                        {
                          value: get(dashboard, `sum_of_activated_transactions.${dashboard.currency.code}`),
                          usdValue: get(dashboard, `sum_of_activated_transactions.USD`),
                          description: t(`dashboard.ingoingTransactions`),
                          currencyCode: dashboard.currency.code,
                          showCurrencyCode: true,
                          wrapClassName: `text-success`,
                        },
                        ...(withCompletedStockpiling
                          ? [
                              {
                                value: get(dashboard, `sum_of_completed_transactions.${dashboard.currency.code}`),
                                usdValue: get(dashboard, `sum_of_completed_transactions.USD`),
                                description: t(`dashboard.withCompletedStockpiling`),
                                currencyCode: dashboard.currency.code,
                                showCurrencyCode: true,
                                isUppercase: false,
                                // wrapClassName: `text-success`,
                              },
                            ]
                          : []),
                        {
                          value: get(dashboard, `sum_of_withdrawals.${dashboard.currency.code}`),
                          usdValue: get(dashboard, `sum_of_withdrawals.USD`),
                          description: t(`dashboard.outgoingTransactions`),
                          currencyCode: dashboard.currency.code,
                          showCurrencyCode: true,
                          wrapClassName: `text-error`,
                        },
                      ],
                    },
                    ...(commissionViewAccess
                      ? [
                          {
                            title: t(`dashboard.sumOfCommission`),
                            logoSrc: CommissionIcon,
                            items: [
                              {
                                value: get(dashboard, `sum_of_transactions_commission.${dashboard.currency.code}`),
                                usdValue: get(dashboard, `sum_of_transactions_commission.USD`),
                                description: t(`dashboard.ingoingTransactions`),
                                currencyCode: dashboard.currency.code,
                                showCurrencyCode: true,
                                wrapClassName: `text-elegance`,
                              },
                              ...(withCompletedStockpiling
                                ? [
                                    {
                                      value: get(
                                        dashboard,
                                        `sum_of_completed_transactions_commission.${dashboard.currency.code}`
                                      ),
                                      usdValue: get(dashboard, `sum_of_completed_transactions_commission.USD`),
                                      description: t(`dashboard.withCompletedStockpiling`),
                                      currencyCode: dashboard.currency.code,
                                      showCurrencyCode: true,
                                      isUppercase: false,
                                      // wrapClassName: `text-elegance`,
                                    },
                                  ]
                                : []),
                              {
                                value: get(dashboard, `sum_of_withdrawals_commission.${dashboard.currency.code}`),
                                usdValue: get(dashboard, `sum_of_withdrawals_commission.USD`),
                                description: t(`dashboard.outgoingTransactions`),
                                currencyCode: dashboard.currency.code,
                                showCurrencyCode: true,
                                wrapClassName: `text-elegance`,
                              },
                              {
                                value: get(dashboard, `sum_of_settlement_commission.${dashboard.currency.code}`),
                                usdValue: get(dashboard, `sum_of_settlement_commission.USD`),
                                description: t(`wallets.settlement`),
                                currencyCode: dashboard.currency.code,
                                showCurrencyCode: true,
                                wrapClassName: `text-elegance`,
                              },
                            ],
                          },
                        ]
                      : []),
                    {
                      title: t(`dashboard.quantityOfActivatedTransactions`),
                      logoSrc: ReportIcon,
                      items: [
                        {
                          value: get(dashboard, `activated_transactions_count`),
                          description: t(`dashboard.ingoingTransactions`),
                          showCurrencyCode: false,
                          wrapClassName: `text-primary`,
                        },
                        ...(withCompletedStockpiling
                          ? [
                              {
                                value: get(dashboard, `completed_transactions_count`) || 0,
                                description: t(`dashboard.withCompletedStockpiling`),
                                currencyCode: dashboard.currency.code,
                                showCurrencyCode: false,
                                isUppercase: false,
                                // wrapClassName: `text-primary`,
                              },
                            ]
                          : []),
                        {
                          value: get(dashboard, `activated_withdrawals_count`),
                          description: t(`dashboard.outgoingTransactions`),
                          showCurrencyCode: false,
                          wrapClassName: `text-primary`,
                        },
                      ],
                    },
                  ]}
                />
                <DashboardSiderInfo
                  periodText={t(`dashboard.forToday`)}
                  data={[
                    {
                      title: t(`dashboard.sumOfActivatedTransactions`),
                      logoSrc: BillIcon,
                      items: [
                        {
                          value: get(dashboard, `sum_of_activated_transactions_today.${dashboard.currency.code}`),
                          usdValue: get(dashboard, `sum_of_activated_transactions_today.USD`),
                          description: t(`dashboard.ingoingTransactions`),
                          currencyCode: dashboard.currency.code,
                          showCurrencyCode: true,
                          wrapClassName: `text-success`,
                        },
                        ...(withCompletedStockpiling
                          ? [
                              {
                                value: get(dashboard, `sum_of_completed_transactions_today.${dashboard.currency.code}`),
                                usdValue: get(dashboard, `sum_of_completed_transactions_today.USD`),
                                description: t(`dashboard.withCompletedStockpiling`),
                                currencyCode: dashboard.currency.code,
                                showCurrencyCode: true,
                                isUppercase: false,
                                // wrapClassName: `text-success`,
                              },
                            ]
                          : []),
                        {
                          value: get(dashboard, `sum_of_withdrawals_for_today.${dashboard.currency.code}`),
                          usdValue: get(dashboard, `sum_of_withdrawals_for_today.USD`),
                          description: t(`dashboard.outgoingTransactions`),
                          currencyCode: dashboard.currency.code,
                          showCurrencyCode: true,
                          wrapClassName: `text-error`,
                        },
                      ],
                    },
                    ...(commissionViewAccess
                      ? [
                          {
                            title: t(`dashboard.sumOfCommission`),
                            logoSrc: CommissionIcon,
                            items: [
                              {
                                value: get(
                                  dashboard,
                                  `sum_of_transactions_commission_today.${dashboard.currency.code}`
                                ),
                                usdValue: get(dashboard, `sum_of_transactions_commission_today.USD`),
                                description: t(`dashboard.ingoingTransactions`),
                                currencyCode: dashboard.currency.code,
                                showCurrencyCode: true,
                                wrapClassName: `text-elegance`,
                              },
                              ...(withCompletedStockpiling
                                ? [
                                    {
                                      value: get(
                                        dashboard,
                                        `sum_of_completed_transactions_commission_today.${dashboard.currency.code}`
                                      ),
                                      usdValue: get(dashboard, `sum_of_completed_transactions_commission_today.USD`),
                                      description: t(`dashboard.withCompletedStockpiling`),
                                      currencyCode: dashboard.currency.code,
                                      showCurrencyCode: true,
                                      isUppercase: false,
                                      // wrapClassName: `text-elegance`,
                                    },
                                  ]
                                : []),
                              {
                                value: get(dashboard, `sum_of_withdrawals_commission_today.${dashboard.currency.code}`),
                                usdValue: get(dashboard, `sum_of_withdrawals_commission_today.USD`),
                                description: t(`dashboard.outgoingTransactions`),
                                currencyCode: dashboard.currency.code,
                                showCurrencyCode: true,
                                wrapClassName: `text-elegance`,
                              },
                              {
                                value: get(dashboard, `sum_of_settlement_commission_today.${dashboard.currency.code}`),
                                usdValue: get(dashboard, `sum_of_settlement_commission_today.USD`),
                                description: t(`wallets.settlement`),
                                currencyCode: dashboard.currency.code,
                                showCurrencyCode: true,
                                wrapClassName: `text-elegance`,
                              },
                            ],
                          },
                        ]
                      : []),
                    {
                      title: t(`dashboard.quantityOfActivatedTransactions`),
                      logoSrc: ReportIcon,
                      items: [
                        {
                          value: get(dashboard, `activated_transactions_count_today`),
                          description: t(`dashboard.ingoingTransactions`),
                          showCurrencyCode: false,
                          wrapClassName: `text-primary`,
                        },
                        ...(withCompletedStockpiling
                          ? [
                              {
                                value: get(dashboard, `completed_transactions_count_today`) || 0,
                                description: t(`dashboard.withCompletedStockpiling`),
                                currencyCode: dashboard.currency.code,
                                showCurrencyCode: false,
                                isUppercase: false,
                                // wrapClassName: `text-primary`,
                              },
                            ]
                          : []),
                        {
                          value: get(dashboard, `activated_withdrawals_count_today`),
                          description: t(`dashboard.outgoingTransactions`),
                          showCurrencyCode: false,
                          wrapClassName: `text-primary`,
                        },
                      ],
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </WidgetContainer>
      </PageContainer>
      <Modal widget={WidgetType.PAYMENT_SYSTEMS} type={ModalType.PAYMENT_SYSTEMS_LIST} component={PaymentSystemsList} />
    </>
  );
};

export default withPermissionCheck(Dashboard, [
  {
    permissions: [
      {
        permission: Permission.DASHBOARD,
        opposite: true,
      },
    ],
    redirectUrl: `/settings`,
  },
]);
