import { Reducer } from 'redux';
import get from 'lodash/get';

import {
  DASHBOARD_DATA_SUCCEEDED,
  DASHBOARD_CHART_DATA_SUCCEEDED,
  SET_DASHBOARD_ASIDE_FILTERS_SUCCEEDED,
  SET_DASHBOARD_CHART_FILTERS_SUCCEEDED,
  CHANGE_DASHBOARD_ENTITY_TYPE,
} from 'actionTypes';
import { DashboardChartEntityType } from 'utils/enums';

export const dashboardInitState = {
  data: {},
  currency: { symbol: `$`, code: `USD` },
  active_wallets_count: 0,
  total_wallets_count: 0,
  total_wallets_balance: { USD: 0 },
  active_proxies_count: 0,
  total_proxies_count: 0,
  sum_of_activated_transactions: { USD: 0 },
  sum_of_activated_transactions_today: { USD: 0 },
  activated_transactions_count: 0,
  sum_of_withdrawals_for_today: { USD: 0 },
  activated_transactions_count_today: 0,
  loadedAllData: false,
  chartFilters: {},
  asideFilters: {},
  entityType: DashboardChartEntityType.TRANSACTION,
};

const chartEntityToStoreKey = {
  [DashboardChartEntityType.WITHDRAWAL]: `withdrawals`,
  [DashboardChartEntityType.TRANSACTION]: `transactions`,
};

const dashboard: Reducer = (state = dashboardInitState, { type, payload = {}, meta }) => {
  const metaChartEntityType = get(meta, `entityType`);
  const dashboardChartStoreKey = get(chartEntityToStoreKey, metaChartEntityType);

  switch (type) {
    case DASHBOARD_DATA_SUCCEEDED:
      return { ...state, ...payload, loadedAllData: true };
    case DASHBOARD_CHART_DATA_SUCCEEDED:
      const chartData = get(payload, `data`);

      return { ...state, [dashboardChartStoreKey]: chartData };
    case SET_DASHBOARD_CHART_FILTERS_SUCCEEDED:
      return { ...state, chartFilters: payload };
    case SET_DASHBOARD_ASIDE_FILTERS_SUCCEEDED:
      return { ...state, asideFilters: payload };
    case CHANGE_DASHBOARD_ENTITY_TYPE:
      return { ...state, entityType: payload };
    default:
      return state;
  }
};

export default dashboard;
