import React, { useEffect } from 'react';
import isNull from 'lodash/isNull';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import { getFormSyncErrors, InjectedFormProps, reduxForm, stopSubmit } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  i18n,
  IWidgetActionCreators,
  widgetItemSelector,
  widgetSubmittingSelector,
} from '@kassma-team/kassma-toolkit/lib';
import he from 'he';
import useUpdateWidgetForm from 'hooks/widgets/useUpdateWidgetForm';
import { WalletType, WalletFormType, WalletPriority, ParserType } from 'utils/enums';
import { UPDATE_WALLET_FORM_NAME } from 'utils/constants';
import validateWallets from 'utils/widgets/wallets/validate';
import useWalletSubmitHandler from 'hooks/widgets/wallets/useWalletSubmitHandler';
import useWalletData from 'hooks/widgets/wallets/useWalletData';
import { IValidateWalletsProps } from 'interfaces/widgets/wallets/validate';
import { loadProxyData } from 'actions/widgets/constructors/paymentsConstructor';
import ConfirmationModal from 'components/widgets/wallets/ConfirmationModal';
import ModalElement from 'components/modals/ModalElement';
import ModalForm from 'components/modals/ModalForm';
import { isManualPSSelector, paymentSystemsSelector } from '../../../selectors/widgets/paymentSystems';
import { manualFieldsSelector } from '../../../selectors/widgets/wallets';
import { getManualFields } from '../../../actions/widgets/wallet';

const modalTitle = `wallets.walletUpdating`;

const formName = UPDATE_WALLET_FORM_NAME;

interface IProps {
  widget: string;
  actionCreators: IWidgetActionCreators;
  useProxy?: boolean;
  useProxyNightEmailParser?: boolean;
}

const WalletUpdatingForm = ({
  widget,
  actionCreators,
  change,
  initialValues,
  ...props
}: InjectedFormProps & IProps) => {
  const { useProxy, useProxyNightEmailParser } = props;
  const paymentSystemsList = useSelector(paymentSystemsSelector);

  useEffect(() => {
    change(`useProxy`, useProxy);
  }, [useProxy]);

  useEffect(() => {
    change(`useProxyNightEmailParser`, useProxyNightEmailParser);
  }, [useProxyNightEmailParser]);

  const walletItem: any = useSelector(widgetItemSelector(widget));

  const dispatch = useDispatch();

  const submitting = useSelector(widgetSubmittingSelector(widget));

  const wallet_type = walletItem ? walletItem.type : undefined;
  const isManualPS = useSelector(isManualPSSelector(wallet_type));
  const manualFields = useSelector(manualFieldsSelector);

  useEffect(() => {
    if (isManualPS && wallet_type) {
      dispatch(getManualFields(wallet_type));
    }
  }, [isManualPS, wallet_type]);

  const { item, ...formProps } = useUpdateWidgetForm({
    widget,
    actionCreators,
    initialize: props.initialize,
    itemAdapter: ({
      proxies,
      password,
      email_username,
      email_password,
      type,
      currency,
      phrase,
      username,
      api_public_key,
      merchant_id,
      account_name,
      ifsc_code,
      token,
      public_key,
      project_id,
      bank_name,
      pin,
      web_id,
      ipn_key,
      bankcode,
      api_private_key,
      country_code,
      payment_method,
      wallet_off_limit,
      is_personal_type,
      identifier,
      email_sender,
      start_night_parser,
      end_night_parser,
      night_parser,
      site_parser,
      parser_type,
      upi_addresses,
      wallet_off_load,
      account_number,
      priority,
      vendor_code,
      nagad_public_key,
      port,
      cookie,
      account_type,
      wallet_settings,
      codephrases,
      provider,
      sms_source,
      is_standard,
      parsing_start_at,
      daily_transaction_limit,
      integration_type,
      webhook_id,
      display_identifier,
    }) => {
      const initial: Record<string, any> = {
        wallet_type: type, // This setting for correct validation
        priority: !isUndefined(priority) ? priority : WalletPriority.NORMAL,
        vendor_code: vendor_code ? he.decode(vendor_code) : vendor_code,
        is_standard,
      };

      if (!isEmpty(proxies)) {
        proxies = map(proxies, (proxy) => ({ proxy_id: get(proxy, `uuid`) }));
      }

      if (!isEmpty(upi_addresses)) {
        upi_addresses = map(upi_addresses, (address) => ({ address }));
        wallet_settings.upi_addresses = map(wallet_settings.upi_addresses, (address) => ({ address }));
      }

      const time_range = {
        fromTime: start_night_parser || `00:00`,
        toTime: end_night_parser || `23:59`,
      };

      initial.wallet_off_limit = wallet_off_limit;
      initial.wallet_off_load = wallet_off_load;
      initial.provider = provider;
      initial.daily_transaction_limit = daily_transaction_limit;
      initial.display_identifier = display_identifier ? he.decode(display_identifier) : display_identifier;

      const decodedEmailPassword = email_password ? he.decode(email_password) : email_password;
      const decodedAccountNumber = account_number ? he.decode(account_number) : account_number;
      const decodedUsername = username ? he.decode(username) : username;
      const decodedPassword = password ? he.decode(password) : password;
      const decodedApiPrivateKey = api_private_key ? he.decode(api_private_key) : wallet_settings.api_private_key;
      const decodedPhrase = phrase ? he.decode(phrase) : phrase;
      const decodedEmailUsername = email_username ? he.decode(email_username) : email_username;
      const decodedApiPublicKey = api_public_key ? he.decode(api_public_key) : api_public_key;
      const decodedVendorCode = vendor_code ? he.decode(vendor_code) : vendor_code;
      const decodedMerchantId = merchant_id ? he.decode(merchant_id) : merchant_id;
      const decodedPublicKey = public_key ? he.decode(public_key) : wallet_settings.api_public_key;
      const decodedAccountName = account_name ? he.decode(account_name) : account_name;
      const decodedBankName = bank_name ? he.decode(bank_name) : bank_name;
      const decodedPort = port ? he.decode(port.toString()) : port;
      const decodedIfscCode = ifsc_code ? he.decode(ifsc_code) : ifsc_code;
      const decodedToken = token ? he.decode(token) : token;
      const decodedProjectId = project_id ? he.decode(String(project_id)) : project_id;
      const decodedBankCode = bankcode ? JSON.parse(he.decode(JSON.stringify(bankcode))) : bankcode;
      const decodedIdentifier = identifier ? he.decode(identifier) : identifier;

      const isP2P = paymentSystemsList.some((ps) => ps.code === walletType && ps.payment_type === `P2P`);

      switch (type) {
        case WalletType.IPAY:
          initial.account_number = decodedAccountNumber;
          initial.username = decodedUsername;
          initial.password = decodedPassword;
          initial.proxies = proxies;
          initial.vendor_code = decodedVendorCode;
          initial.currency_code = get(currency, `code`);
          initial.priority = priority;
          break;
        case WalletType.NAGAD_API:
          initial.merchant_id = decodedMerchantId;
          initial.account_number = decodedAccountNumber;
          initial.merchant_private_key = wallet_settings?.merchant_private_key;
          initial.nagad_public_key = nagad_public_key;
          initial.api_public_key = decodedApiPublicKey;
          initial.currency_code = get(currency, `code`);
          initial.proxies = proxies;
          initial.priority = priority;
          initial.vendor_code = decodedVendorCode;
          initial.callback_source = wallet_settings?.callback_source;
          initial.callback_url = wallet_settings?.callback_url;
          break;
        case WalletType.NAGAD:
          initial.proxies = proxies;
          initial.currency_code = get(currency, `code`);
          initial.email_username = decodedEmailUsername;
          initial.email_password = decodedEmailPassword;
          initial.account_type = account_type;
          initial.sms_source = sms_source || wallet_settings?.sms_source;
          initial.password = decodedPassword;
          initial.username = decodedUsername;
          initial.parser_type = parser_type;
          break;
        case WalletType.BKASH:
          initial.proxies = proxies;
          initial.currency_code = get(currency, `code`);
          initial.account_type = account_type;
          initial.parser_type = parser_type;
          initial.email_username = decodedEmailUsername;
          initial.email_password = decodedEmailPassword;
          initial.sms_source = sms_source || wallet_settings?.sms_source;
          initial.username = decodedUsername;
          initial.password = decodedPassword;
          initial.is_personal_type = is_personal_type;
          break;
        case WalletType.ROCKET:
          initial.proxies = proxies;
          initial.currency_code = get(currency, `code`);
          initial.email_username = decodedEmailUsername;
          initial.email_password = decodedEmailPassword;
          initial.sms_source = sms_source || wallet_settings?.sms_source;
          break;
        case WalletType.UPI_L:
          initial.account_number = decodedAccountNumber;
          initial.api_public_key = api_public_key || wallet_settings?.api_public_key;
          initial.parser_type = parser_type || wallet_settings?.parser_type;
          initial.upi_addresses = upi_addresses;
          initial.api_private_key = api_private_key || wallet_settings?.api_private_key;
          initial.project_id = project_id || wallet_settings?.project_id;
          initial.email_password = decodedEmailPassword;
          initial.email_username = decodedEmailUsername;
          initial.username = decodedUsername;
          initial.codephrases = codephrases || wallet_settings?.codephrases;
          initial.password = password || wallet_settings?.password;
          initial.webhook_id = webhook_id || wallet_settings?.webhook_id;
          initial.sms_source = sms_source || wallet_settings?.sms_source;
          initial.currency_code = get(currency, `code`);
          initial.proxies = proxies;
          initial.priority = priority;
          initial.vendor_code = decodedVendorCode;
          initial.integration_type = integration_type || wallet_settings?.integration_type;

          initial.phone_number = wallet_settings?.phone_number;

          if (cookie) {
            initial.cookie = JSON.stringify(cookie);
          }
          break;
        case WalletType.PHONE_PE:
          initial.proxies = proxies;
          initial.currency_code = get(currency, `code`);

          if (wallet_settings?.parser_type === ParserType.PARSER_EMAIL) {
            initial.email_username = decodedEmailUsername;
            initial.email_password = decodedEmailPassword;
          }

          if (wallet_settings?.parser_type === ParserType.PARSER_HDFC) {
            initial.username = decodedUsername;
            initial.password = decodedPassword;
            initial.parsing_object = wallet_settings?.parsing_object;
          }

          if (wallet_settings?.parser_type === ParserType.PARSER_INDUSIN) {
            initial.username = decodedUsername;
            initial.password = decodedPassword;

            initial.codephrases = codephrases || JSON.parse(wallet_settings?.codephrases);
          }

          if (wallet_settings?.parser_type === ParserType.PARSER_PHONE_PE_MOBILE_API) {
            initial.username = username;
            initial.email_username = email_username;
            initial.email_password = email_password;
            initial.sms_source = sms_source;

            const {
              mnc,
              network_operator_name,
              android_id,
              android_board,
              android_hardware,
              android_serial,
              android_manufacturer,
              android_api_version,
              wifi_bssid,
              wifi_ssid,
              wifi_frequency,
              latitude,
              longitude,
            } = wallet_settings;

            initial.mnc = mnc;
            initial.network_operator_name = network_operator_name;
            initial.android_id = android_id;
            initial.android_board = android_board;
            initial.android_hardware = android_hardware;
            initial.android_serial = android_serial;
            initial.android_manufacturer = android_manufacturer;
            initial.android_api_version = android_api_version;
            initial.wifi_bssid = wifi_bssid;
            initial.wifi_ssid = wifi_ssid;

            initial.wifi_frequency = wifi_frequency;
            initial.latitude = latitude;
            initial.longitude = longitude;
          }

          if (wallet_settings?.parser_type === ParserType.PARSER_HDFC) {
            delete initial.wifi_frequency;
            delete initial.latitude;
            delete initial.longitude;
          }

          initial.account_number = account_number;
          initial.vendor_code = vendor_code;
          initial.parser_type = wallet_settings?.parser_type;
          break;
        case WalletType.PAYGIGA:
          initial.proxies = proxies;
          initial.currency_code = get(currency, `code`);
          // initial.merchant_key = merchant_key;
          initial.merchant_id = decodedMerchantId || wallet_settings?.merchant_id;
          // initial.merchant_password = merchant_password;
          initial.password = decodedPassword;
          break;
        case WalletType.PAYZEN:
          initial.proxies = proxies;
          initial.currency_code = get(currency, `code`);
          initial.country_code = country_code;
          // initial.operation_type = operation_type;
          initial.payment_method = payment_method;
          initial.api_public_key = decodedApiPublicKey;
          initial.public_key = decodedPublicKey;
          break;
        case WalletType.VIETCOM_BANK:
          initial.proxies = proxies;
          initial.currency_code = get(currency, `code`);
          initial.email_username = decodedEmailUsername;
          initial.email_password = decodedEmailPassword;
          initial.account_name = decodedAccountName;
          initial.bank_name = decodedBankName;
          break;
        case WalletType.PAY_TM:
          initial.proxies = proxies;
          initial.currency_code = get(currency, `code`);
          initial.port = decodedPort;
          initial.sms_source = sms_source;
          initial.email_username = decodedEmailUsername;
          initial.email_password = decodedEmailPassword;
          initial.account_number = wallet_settings?.account_number ?? account_number;
          initial.parser_type = wallet_settings?.parser_type;
          if (password) {
            initial.password = decodedPassword;
          }
          if (cookie) {
            initial.cookie = JSON.stringify(cookie);
          }
          break;
        case WalletType.EPAY:
          initial.currency_code = get(currency, `code`);
          initial.api_public_key = decodedApiPublicKey;
          initial.proxies = proxies;
          break;
        case WalletType.IMPS_IB:
          initial.account_name = decodedAccountName;
          initial.proxies = proxies;
          initial.currency_code = get(currency, `code`);
          initial.username = decodedUsername;
          initial.password = decodedPassword;
          initial.ifsc_code = decodedIfscCode;
          break;
        case WalletType.UPI_IB:
          initial.proxies = proxies;
          initial.currency_code = get(currency, `code`);
          initial.username = decodedUsername;
          initial.password = decodedPassword;
          initial.account_name = decodedAccountName;
          initial.email_password = decodedEmailPassword;
          initial.email_username = decodedEmailUsername;
          initial.email_sender = email_sender;
          initial.site_parser = !!site_parser;
          initial.night_parser = night_parser;
          initial.time_range = time_range;
          initial.upi_addresses = upi_addresses;
          break;
        case WalletType.NGAN_LUONG_EMAIL:
          initial.currency_code = get(currency, `code`);
          initial.email_username = decodedEmailUsername;
          initial.email_password = decodedEmailPassword;
          initial.proxies = proxies;
          break;
        case WalletType.PAY_OP:
          initial.proxies = proxies;
          initial.currency_code = get(currency, `code`);
          initial.api_public_key = decodedApiPublicKey;
          initial.api_private_key = decodedApiPrivateKey;
          initial.project_id = decodedProjectId;
          initial.token = decodedToken;
          break;
        case WalletType.PAYTRUST88:
          initial.proxies = proxies;
          initial.currency_code = get(currency, `code`);
          initial.api_public_key = decodedApiPublicKey;
          break;
        case WalletType.SUREPAY:
          initial.proxies = proxies;
          initial.currency_code = get(currency, `code`);
          initial.token = decodedToken;
          initial.api_public_key = decodedApiPublicKey;
          initial.bankcode = decodedBankCode;
          initial.merchant_id = decodedMerchantId;
          break;
        case WalletType.TECHCOMBANK:
          initial.username = decodedUsername;
          initial.password = decodedPassword;
          initial.currency_code = get(currency, `code`);
          initial.proxies = proxies;
          break;
        case WalletType.UPI_AB:
          initial.account_number = decodedAccountNumber;
          initial.currency_code = get(currency, `code`);
          initial.proxies = proxies;
          initial.upi_addresses = upi_addresses;
          initial.email_username = decodedEmailUsername;
          initial.email_password = decodedEmailPassword;
          initial.email_sender = wallet_settings?.email_sender;
          break;
        case WalletType.UPI_HB:
          initial.username = decodedUsername;
          initial.password = decodedPassword;
          initial.proxies = proxies;
          initial.parsing_object = wallet_settings?.parsing_object;
          initial.currency_code = get(currency, `code`);
          initial.upi_addresses = upi_addresses;
          initial.account_number = decodedAccountNumber;
          break;
        case WalletType.IMPS_HB:
          initial.identifier = decodedIdentifier;
          initial.account_name = decodedAccountName;
          initial.ifsc_code = decodedIfscCode;
          initial.username = decodedUsername;
          initial.password = decodedPassword;
          initial.parsing_object = wallet_settings?.parsing_object;
          initial.currency_code = get(currency, `code`);
          initial.proxies = proxies;
          break;
        case WalletType.IMPS_IDB:
          initial.account_name = decodedAccountName;
          initial.ifsc_code = decodedIfscCode;
          initial.username = decodedUsername;
          initial.password = decodedPassword;
          initial.currency_code = get(currency, `code`);
          initial.proxies = proxies;
          break;
        case WalletType.UPI_NAAP:
          initial.api_public_key = decodedApiPublicKey;
          initial.api_private_key = decodedApiPrivateKey;
          initial.webhook_id = webhook_id || wallet_settings?.webhook_id;
          initial.project_id = project_id || wallet_settings?.project_id;
          initial.currency_code = get(currency, `code`);
          initial.proxies = proxies;
          break;
        case WalletType.IMPS_AB:
          initial.account_name = decodedAccountName;
          initial.account_number = decodedAccountNumber;
          initial.ifsc_code = decodedIfscCode;
          initial.username = decodedUsername;
          initial.password = decodedPassword;
          initial.proxies = proxies;
          initial.codephrases = codephrases;
          initial.currency_code = get(currency, `code`);
          initial.parser_type = parser_type;
          initial.email_username = decodedEmailUsername;
          initial.email_password = decodedEmailPassword;
          break;
        case WalletType.UPI_IDB:
          initial.username = decodedUsername;
          initial.password = decodedPassword;
          initial.currency_code = get(currency, `code`);
          initial.upi_addresses = upi_addresses;
          initial.proxies = proxies;
          break;
        case WalletType.LAYKAPAY:
          initial.api_public_key = decodedApiPublicKey;
          initial.api_private_key = decodedApiPrivateKey;
          initial.currency_code = get(currency, `code`);
          initial.proxies = proxies;
          initial.priority = priority;
          break;
        /*
          См. https://jira.fixmost.com/browse/PAY-12492,
          раскомментить как будет понятно как должно работать
        */
        //case WalletType.UPI_SB:
        case WalletType.UPI_S:
          initial.api_public_key = wallet_settings.api_public_key;
          initial.upi_addresses = wallet_settings.upi_addresses;
          initial.account_name = wallet_settings.account_name;
          initial.merchant_id = wallet_settings.merchant_id;
          initial.api_private_key = wallet_settings.api_private_key;
          initial.project_id = decodedProjectId;
          initial.integration_type = wallet_settings.integration_type;
          initial.currency_code = get(currency, `code`);
          initial.proxies = proxies;
          initial.priority = priority;
          initial.password = password || wallet_settings?.password;
          initial.webhook_id = webhook_id || wallet_settings?.webhook_id;
          break;
        case WalletType.UPI_SF:
        case WalletType.PHONEPE_S:
        case WalletType.UPI_AP:
          initial.api_public_key = decodedApiPublicKey;
          initial.upi_addresses = upi_addresses;
          initial.account_name = decodedAccountName;
          initial.merchant_id = decodedMerchantId;
          initial.currency_code = get(currency, `code`);
          initial.proxies = proxies;
          initial.priority = priority;
          break;
        case WalletType.UZCARD_API:
          initial.merchant_id = decodedMerchantId;
          initial.token = decodedToken;
          initial.phrase = decodedPhrase;
          initial.currency_code = currency?.code;
          initial.proxies = proxies;
          initial.priority = priority;
          initial.vendor_code = decodedVendorCode;
          break;
        case WalletType.PAYTM_API:
          initial.merchant_id = decodedMerchantId;
          // initial.merchant_key = merchant_key;
          initial.api_public_key = decodedApiPublicKey;
          initial.currency_code = get(currency, `code`);
          initial.proxies = proxies;
          initial.priority = priority;
          initial.vendor_code = decodedVendorCode;
          break;
        case WalletType.UPI_PB:
          initial.merchant_id = decodedMerchantId;
          // initial.merchant_key = merchant_key;
          initial.api_public_key = decodedApiPublicKey;
          initial.currency_code = currency.code;
          initial.proxies = proxies;
          initial.priority = priority;
          initial.vendor_code = decodedVendorCode;
          break;
        case WalletType.MPESA:
          initial.merchant_id = decodedMerchantId;
          initial.api_public_key = decodedApiPublicKey;
          initial.api_private_key = decodedApiPrivateKey;
          initial.currency_code = currency.code;
          initial.password = decodedPassword;
          initial.proxies = proxies;
          initial.priority = priority;
          initial.vendor_code = decodedVendorCode;
          break;
        case WalletType.EASYPAISA_API:
          initial.merchant_id = decodedMerchantId;
          initial.api_public_key = decodedApiPublicKey;
          initial.api_private_key = decodedApiPrivateKey;
          initial.account_number = account_number;
          initial.currency_code = currency.code;
          initial.username = decodedUsername;
          initial.password = decodedPassword;
          initial.proxies = proxies;
          initial.priority = priority;
          initial.vendor_code = decodedVendorCode;
          break;
        case WalletType.BKASH_API:
          initial.merchant_id = decodedMerchantId;
          initial.api_public_key = decodedApiPublicKey;
          initial.currency_code = get(currency, `code`);
          initial.proxies = proxies;
          initial.priority = priority;
          initial.vendor_code = decodedVendorCode;
          initial.api_private_key = decodedApiPrivateKey;
          initial.username = decodedUsername;
          initial.password = decodedPassword;
          break;
        case WalletType.UPI_IN:
          initial.account_number = decodedAccountNumber;
          initial.password = decodedPassword;
          initial.username = decodedUsername;
          initial.codephrases = codephrases;
          initial.upi_addresses = upi_addresses;
          initial.currency_code = currency.code;
          initial.proxies = proxies;
          initial.priority = priority;
          initial.vendor_code = decodedVendorCode;
          initial.wallet_off_limit = wallet_off_limit;
          initial.wallet_off_load = wallet_off_load;
          break;
        case WalletType.BKASH_API_CEBALRAI:
          initial.password = decodedPassword;
          initial.username = decodedUsername;
          initial.api_public_key = decodedApiPublicKey;
          initial.api_private_key = decodedApiPrivateKey;
          initial.currency_code = currency.code;
          initial.proxies = proxies;
          initial.priority = priority;
          initial.vendor_code = decodedVendorCode;
          initial.wallet_off_limit = wallet_off_limit;
          initial.wallet_off_load = wallet_off_load;
          break;
        case WalletType.UPI_W:
          initial.username = decodedUsername;
          initial.email_username = decodedEmailUsername;
          initial.email_password = decodedEmailPassword;
          initial.upi_addresses = upi_addresses;
          initial.currency_code = get(currency, `code`);
          initial.proxies = proxies;
          initial.priority = priority;
          initial.vendor_code = decodedVendorCode;
          if (cookie) {
            initial.cookie = JSON.stringify(cookie);
          }
          break;
        case WalletType.EASYPAISA:
          initial.email_username = decodedEmailUsername;
          initial.email_password = decodedEmailPassword;
          initial.currency_code = get(currency, `code`);
          initial.proxies = proxies;
          initial.priority = priority;
          initial.vendor_code = decodedVendorCode;
          break;
        case WalletType.CLICK_UZS:
          initial.username = decodedUsername;
          initial.identifier = decodedIdentifier;
          initial.email_username = decodedEmailUsername;
          initial.email_password = decodedEmailPassword;
          initial.sms_source = sms_source || wallet_settings?.sms_source;
          initial.priority = priority;
          initial.vendor_code = decodedVendorCode;
          initial.proxies = proxies;
          initial.currency_code = get(currency, `code`);

          if (cookie) {
            initial.cookie = JSON.stringify(cookie);
          }

          break;
        case WalletType.UPI_M:
          initial.username = username;
          initial.identifier = identifier;
          initial.email_username = email_username;
          initial.email_password = email_password;
          initial.sms_source = sms_source || wallet_settings?.sms_source;
          initial.username = username;
          initial.priority = priority;
          initial.vendor_code = vendor_code;
          initial.proxies = proxies;
          initial.currency_code = get(currency, `code`);
          initial.upi_addresses = upi_addresses;
          if (cookie) {
            initial.cookie = JSON.stringify(cookie);
          }
          break;

        case WalletType.JAZZCASH_BUSINESS:
        case WalletType.JAZZCASH:
        case WalletType.WAVE_PAY:
          initial.identifier = identifier;
          initial.email_username = email_username;
          initial.email_password = email_password;
          initial.sms_source = sms_source || wallet_settings?.sms_source;
          initial.currency_code = get(currency, `code`);
          initial.priority = priority;
          initial.proxies = proxies;
          initial.vendor_code = vendor_code;
          break;

        case WalletType.IPAY_API:
          initial.token = wallet_settings.token;
          initial.api_private_key = wallet_settings.api_private_key;
          initial.currency_code = get(currency, `code`);
          initial.proxies = proxies;
          break;
        default:
          initial.currency_code = currency.code;
          initial.priority = priority;
          initial.vendor_code = decodedVendorCode;
          if (wallet_settings) {
            for (const key in wallet_settings) {
              if (key !== `account_login`) {
                initial[key] = wallet_settings[key];
              }
            }
          }
          break;
      }

      if (isP2P && parsing_start_at) {
        initial.parsing_start_at = new Date(parsing_start_at);
      } else if (isP2P && !parsing_start_at) {
        initial.parsing_start_hint = i18n.t(`wallets.walletCreationDate`);
      }

      return initial;
    },
  });
  const walletType = get(item, `type`);

  useEffect(() => {
    if (walletType) {
      dispatch(loadProxyData(walletType));
    }
  }, [walletType]);

  const fields = useWalletData({
    type: WalletFormType.UPDATE,
    formName,
    change,
    walletType,
    initialValues: initialValues || walletItem,
    isManualPS,
    manualFields,
  });

  const errors = useSelector(getFormSyncErrors(props.form));

  useEffect(() => {
    //@ts-ignore
    if (Object.keys(errors).length > 0 && !!errors?.cookie) {
      dispatch(stopSubmit(props.form, errors));
    }
  }, [errors]);

  const {
    onConfirmationInfoCancel,
    onSubmit,
    onForcedSubmit,
    confirmationInfoType,
    confirmationInfoData,
  } = useWalletSubmitHandler({
    form: props.form,
    actionCreators,
    widget,
    type: `updating`,
    errors: {},
  });

  // useEffect(() => {
  //   const resetWindowHandler = () => dispatch({ type: `WIDGET_RESET_MODAL`, meta: { widget: `wallets` } });

  //   window.addEventListener(`blur`, resetWindowHandler);

  //   return () => window.removeEventListener(`blur`, resetWindowHandler);
  // }, []);

  if (confirmationInfoType) {
    const content = (
      <ConfirmationModal
        infoType={confirmationInfoType}
        infoData={confirmationInfoData}
        onCancel={onConfirmationInfoCancel}
        onSave={onForcedSubmit}
        submitFailed={props.submitFailed}
        error={props.error}
        form={props.form}
        submitting={submitting as boolean}
        className="pb-10"
      />
    );

    return <ModalElement title={modalTitle} actionCreators={actionCreators} content={content} />;
  }

  return (
    <ModalForm
      {...props}
      {...formProps}
      onSubmit={onSubmit}
      fields={fields}
      submitText="common.update"
      formName={UPDATE_WALLET_FORM_NAME}
      title={modalTitle}
      actionCreators={actionCreators}
      widget={widget}
      loading={isNull(item)}
    />
  );
};

export default reduxForm<IValidateWalletsProps, any, any>({
  form: UPDATE_WALLET_FORM_NAME,
  validate: validateWallets,
})(WalletUpdatingForm);
