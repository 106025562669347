import React, { useMemo } from 'react';
import { getFormValues, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { getSubmitHandler, InputType } from '@kassma-team/kassma-toolkit/lib';
import { useSelector } from 'react-redux';

import { changeLogsCategoriesActionCreators, logActionCreators } from 'actions/widgets/walletsDebugging/logs';
import { ModalType, Permission, WidgetType } from 'utils/enums';
import useUserHasAccess from 'hooks/useUserHasAccess';
import walletsDebuggingRouteTabs from 'pages/walletsDebugging/walletsDebuggingRouteTabs';
import useLogsTableTitles from 'hooks/widgets/tableTitles/useLogsTableTitles';
import { parseWalletList } from 'utils/widgets/wallets/parseWalletList';
import { paymentSystemsWithoutSettlementsSelector } from 'selectors/widgets/paymentSystems';
import useList from 'hooks/widgets/useList';
import { logsCategoriesSelector } from 'selectors/widgets/logs';
import useWalletListByType from 'hooks/widgets/useWalletListByType';
import { IFilterProps } from 'interfaces/widgets/widgets';

import Filters from 'components/widgets/filters/Filters';
import LogRow from 'components/widgets/walletsDebugging/log/LogRow';
import LogMessage from 'components/widgets/walletsDebugging/log/LogMessage';
import Widget from 'components/widgets/Widget';

import './logs.scss';
import { ILogRowProps, ILogsQueryParams } from 'interfaces/widgets/logs';
import { hasAccessSelector } from '../../../selectors/auth';

const FORM_NAME = `log-filters`;

const FilterForm = reduxForm<any, IFilterProps>({
  form: FORM_NAME,
  onSubmit: getSubmitHandler({
    dateFields: [{ name: `date_range`, fromName: `date_from`, toName: `date_to` }],
  }),
})(Filters);

const listMapping = (item: ILogRowProps, key: number) => <LogRow key={key} {...item} />;

const modals = [
  {
    type: ModalType.LOG_MESSAGE,
    widget: WidgetType.LOGS,
    component: LogMessage,
  },
];

const filterDateFields = [
  {
    from: `date_from`,
    to: `date_to`,
    name: `date_range`,
  },
];

const Logs = () => {
  const tableTitles = useLogsTableTitles();

  useUserHasAccess();

  const [t] = useTranslation();

  const paymentSystemList = useSelector(paymentSystemsWithoutSettlementsSelector);

  const safePaymentSystemList = paymentSystemList?.map((el) => ({
    ...el,
    value: el.code,
    text: el.name,
  }));

  useList({
    widget: WidgetType.LOGS_CATEGORIES,
    actionCreators: changeLogsCategoriesActionCreators,
    resetOnUnmount: false,
    loadOnlyIfNecessary: true,
  });

  const categoryList = useSelector(logsCategoriesSelector);
  const values: ILogsQueryParams = useSelector(getFormValues(FORM_NAME)) || {};

  const showWalletFilter = useSelector(hasAccessSelector(Permission.WALLET_LIST));

  const { walletsSelection: walletList, listLoading: walletListLoading } = useWalletListByType(
    values?.wallet_type as string,
    {
      showAllWhenTypeIsEmpty: true,
      disableLoading: !showWalletFilter,
    }
  );

  const leftFilterFields = useMemo(
    () => [
      {
        name: `wallet_type`,
        type: `select`,
        data: [{ value: null, text: t(`common.all`) }, ...safePaymentSystemList],
        placeholder: t(`wallets.walletType`),
      },
      ...[
        showWalletFilter
          ? {
              name: `wallet_id`,
              type: InputType.SELECT,
              data: parseWalletList(walletList, {
                emptyText: t(`common.all`),
              }),
              placeholder: t(`wallets.wallet`),
              busy: walletListLoading,
            }
          : undefined,
      ],
      {
        name: `category`,
        type: `select`,
        data: [{ value: null, text: t(`common.all`) }, ...categoryList],
        placeholder: t(`logs.category`),
      },
      {
        name: `date_range`,
        type: InputType.DATE_RANGE,
      },
    ],
    [paymentSystemList, walletList, walletListLoading]
  );

  return (
    <Widget
      actionCreators={logActionCreators}
      widget={WidgetType.LOGS}
      filterFormName={FORM_NAME}
      pageTitle="logs.walletsDebugging"
      headTitle="logs.logs"
      routeTabs={walletsDebuggingRouteTabs}
      tableTitle="logs.logList"
      tableHeadTitles={tableTitles}
      listMapping={listMapping}
      filterForm={<FilterForm leftFields={leftFilterFields} formName={FORM_NAME} dateFields={filterDateFields} />}
      modals={modals}
      withPagination
      statusBar
    />
  );
};

export default Logs;
