import React from 'react';
import { IModalProps, ModalElement as KassmaModalElement } from '@kassma-team/kassma-toolkit/lib';
import { useDispatch } from 'react-redux';

const ModalElement = (props: Omit<IModalProps, `modal`>) => {
  const dispatch = useDispatch();

  return <KassmaModalElement {...props} dispatch={dispatch} />;
};

export default ModalElement;
