import { AxiosPromise } from 'axios';
import { BaseApi } from 'api/common/BaseApi';

export class CookieApi extends BaseApi {
  resetPaytmWallet = (id: number): AxiosPromise => this.makeFetch(`{id}`, { method: `PUT` }, { id });
}

const cookieApi = new CookieApi(`cookie`);

export default cookieApi;
