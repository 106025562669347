import React, { FC, useContext, createContext } from 'react';
import { UseTranslations, useTranslations } from '../hooks/useTranslations';

type TranslationsCtx = UseTranslations;

const translationsCtx = createContext<TranslationsCtx | undefined>(undefined);

export const TranslationsCtx: FC = ({ children }) => {
  const {
    translations,
    setTranslations,
    handleUpdateTranslation,
    isLoading,
    handeDeleteTranslation,
    handleCreateField,
    fetchPackages,
  } = useTranslations();

  const val: TranslationsCtx = {
    setTranslations,
    translations,
    handleUpdateTranslation,
    isLoading,
    handeDeleteTranslation,
    handleCreateField,
    fetchPackages,
  };

  return <translationsCtx.Provider value={val}>{children}</translationsCtx.Provider>;
};

export function useTranslationsCtx(): TranslationsCtx {
  const context = useContext(translationsCtx);

  if (context === undefined) {
    throw new Error(`Is need to use useTranslationsCtx inside a TranslationsCtx component`);
  }

  return context;
}
