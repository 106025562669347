import React, { ReactNode } from 'react';
import { TableModalForm as KassmaTableModalForm, IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import FormField from 'components/form/FormField';

interface IProps<IRows = Record<string, any>> {
  actionCreators: IWidgetActionCreators;
  title: string;
  initialize: any;
  rows: IRows;
  onSubmit?: (values: any) => void;
  headTitles: string[];
  submitText: string;
  disabled?: boolean;
  submitting: boolean;
  size?: number;
  loading?: boolean;
  footerClassName?: string;
  dispatch?: Dispatch;
  FormField?: ReactNode;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const TableModalForm = (props: IProps) => {
  const dispatch = useDispatch();

  return <KassmaTableModalForm {...props} dispatch={dispatch} FormField={FormField} />;
};

export default TableModalForm;
