import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import includes from 'lodash/includes';
import get from 'lodash/get';
import { InputType } from '@kassma-team/kassma-toolkit/lib';

import useArrayFields from 'hooks/widgets/useArrayFields';
import useAvailableWallets from 'hooks/widgets/proxy/useAvailableWallets';
import useList from 'hooks/widgets/useList';
import { WalletStatus, WidgetType } from 'utils/enums';
import { notRemovedWalletActionCreators } from 'actions/widgets/wallet';
import { INotRemovedWallet } from 'interfaces/widgets/wallets/wallet';

const DISABLED_WALLETS = [WalletStatus.ERROR, WalletStatus.DISABLED, WalletStatus.PROBABLY_BANNED, WalletStatus.BANNED];

interface IItemComponentProps {
  item: {
    text: string;
    status: number;
  };
}

const useWalletsListForProxy = (formName: string) => {
  const [t] = useTranslation();

  const { items: wallets, listLoading } = useList<INotRemovedWallet>({
    widget: WidgetType.NOT_REMOVED_WALLETS,
    actionCreators: notRemovedWalletActionCreators,
    loadOnlyIfNecessary: true,
    resetOnUnmount: false,
  });

  const walletsList = useAvailableWallets({
    wallets,
    formName: formName,
    listName: `wallets`,
  });

  const fields = useCallback(
    () => [
      {
        placeholder: t(`wallets.wallet`),
        name: `wallet`,
        type: InputType.SELECT,
        data: walletsList,
        wrapClassName: `w-90 mr-5`,
        withModalScroll: true,
        required: true,
        busy: listLoading,
        itemComponent: ({ item: { text, status } }: IItemComponentProps) => {
          if (includes(DISABLED_WALLETS, status)) {
            return <span className="text-danger">{text}</span>;
          }

          return text;
        },
        valueComponent: ({ item }: Record<`item`, INotRemovedWallet>) => {
          let text = get(item, `text`);

          if (!text) {
            text = item.id;

            if (item.identifier) {
              text = `${text} | ${item.identifier}`;
            }
          }

          return text;
        },
        handleChange: (input: Record<string, any>) => (item: INotRemovedWallet) => {
          input.onChange(item);
        },
      },
    ],
    [walletsList, listLoading]
  );

  return useArrayFields({
    fields: fields,
    title: t(`wallets.wallet`),
    name: `wallets`,
  });
};

export default useWalletsListForProxy;
