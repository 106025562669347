import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import { ILogsQueryParams } from 'interfaces/widgets/logs';
import { IWithdrawalsLogListQuery } from 'interfaces/widgets/withdrawals';

export class LogApi extends BaseApi {
  loadLogList = (queryParams: ILogsQueryParams): AxiosPromise => this.makeFetch(`list`, { queryParams });

  loadLogsCategoryList = (): AxiosPromise => this.makeFetch(`categories`);

  loadWithdrawalsLogList = (queryParams: IWithdrawalsLogListQuery): AxiosPromise =>
    this.makeFetch(`withdrawals`, { queryParams });
}

const logApi = new LogApi(`log`);

export default logApi;
