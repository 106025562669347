import { AutoReportColumn } from '../utils/enums';

export const autoReportPeriods = [
  {
    value: `day`,
    text: `common.day`,
  },
  {
    value: `week`,
    text: `common.week`,
  },
  {
    value: `month`,
    text: `common.month`,
  },
  {
    value: `quarter`,
    text: `common.quarter`,
  },
  {
    value: `year`,
    text: `common.year`,
  },
];

export const autoReportsTableTitles = [
  {
    title: `common.ID`,
    id: AutoReportColumn.AUTO_REPORT_ID,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `reports.report`,
    id: AutoReportColumn.AUTO_REPORT_DATE,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `reports.recipient`,
    id: AutoReportColumn.AUTO_REPORT_RECIPIENT,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `reports.start`,
    id: AutoReportColumn.AUTO_REPORT_START,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `reports.reportingPeriod`,
    id: AutoReportColumn.AUTO_REPORT_DATE,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `reports.author`,
    id: AutoReportColumn.AUTO_REPORT_AUTHOR,
    desktop: true,
    mobile: true,
    disabled: true,
  },
  {
    title: `reports.actions`,
    id: AutoReportColumn.AUTO_REPORT_ACTIONS,
    desktop: true,
    mobile: true,
    disabled: true,
  },
];
