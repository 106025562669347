const ru = {
  "translation": {
    "auth": {
      "login": "Логин",
      "password": "Пароль",
      "signIn": "Войти",
      "logout": "Выйти",
      "configError": "Ошибка заполения config",
      "noRequiredParameters": "Нет обязательных параметров"
    },
    "notification": {
      "center": "Центр уведомлений",
      "unreaded": "Непрочитанные",
      "readed": "Прочитанные",
      "showAll": "Показать все",
      "hide": "Скрыть",
      "hasNotMessages": "У вас нет непрочитанных сообщений"
    },
    "quantities": {
      "million": "млн",
      "billion": "млрд",
      "trillion": "трлн",
      "quadrillion": "квдрлн",
      "quintillion": "квнтлн"
    },
    "withdrawals": {
      "withdrawals": "Выводы",
      "withdrawalList": "Список выводов",
      "new": "Новая",
      "inProgress": "Обработка",
      "canceled": "Отмененная",
      "processed": "Завершенная",
      "rejected": "Отклоненная",
      "addBeneficiare": "Добавление бенефициара",
      "withdrawalHasBeenSuccessfullyRejected": "Вывод был успешно отклонен",
      "withdrawalHasBeenSuccessfullyApproved": "Вывод был успешно подтвержден",
      "withdrawalRejection": "Отклонение заявки на вывод",
      "withdrawalApproval": "Подтверждение заявки на вывод",
      "sendersWalletNumber": "Номер кошелька отправителя",
      "receiversWalletNumber": "Номер кошелька получателя",
      "withdrawal": "Вывод",
      "filterNew": "Новые",
      "filterInProgress": "В обработке",
      "filterCanceled": "Отмененные",
      "filterProcessed": "Завершенные",
      "filterRejected": "Отклоненные",
      "withdrawalId": "ID Вывода",
      "receipts": "Поступления",
      "areYouSureYouWantToApproveThisWithdrawal": "Вы действительно хотите подтвердить данный вывод?",
      "recipient": "Получатель",
      "sender": "Отправитель",
      "receipt": "Поступление",
      "method": "Метод",
      "bankCode": "Код банка",
      "branchCode": "Код отделения",
      "manualWithdrawalRequest": "Ручная заявка на вывод",
      "user": "Пользователь",
      "auto": "Авто",
      "manual": "Ручная",
      "type": "Заявка",
      "withdrawalRecipientBank": "Банк получателя",
      "bankAccountRecipient": "Название аккаунта получателя в банке",
      "walletRecipientNumber": "Номер аккаунта получателя в банке",
      "card": "Карта",
      "wallet_sender": "Кошелек отправителя",
      "walletSender": "Кошелек отправителя",
      "walletSenderIdentifier": "Идентификатор кошелька отправителя",
      "cardNumber": "Номер карты получателя",
      "label": "Лейбл пользователя",
      "walletRecipient": "Кошелек получателя",
      "accountName": "Имя получателя",
      "comment": "Комментарий",
      "processing": "Обработка",
      "approved": "Завершенная",
      "payoutMethod": "Метод оплаты",
      "providerId": "Провайдер",
      "walletRecipientAccount": "Аккаунт получателя",
      "branchCode2": "Подразделение",
      "email": "Email получателя",
      "walletRecipientName": "Имя получателя",
      "walletRecipientCard": "Карта получателя",
      "filterProcessing": "В обработке",
      "filterApproved": "Завершенные",
      "areYouSureYouWantToRejectThisWithdrawal": "Вы действительно хотите отклонить данный вывод?",
      "checkApprovedBtn": "Принят",
      "checkRejectedBtn": "Отклонен",
      "doYouConfirmThatTheWithdrawalHasBeenMade": "Подтверждаете, что вывод был произведен?",
      "doYouConfirmThatTheWithdrawalHasNotBeenMade": "Подтверждаете, что вывод НЕ был произведен?",
      "check": "Проверить",
      "walletNumberForWithdrawal": "Номер кошелька для вывода",
      "cardForWithdrawal": "Карта для вывода",
      "requestDate": "Дата заявки",
      "requestType": "Тип заявки",
      "withdrawalInfo": "Информация о выводе",
      "updatedAt": "Дата последнего обновления",
      "changeComment": "Изменить комментарий",
      "commentHasBeenSuccessfullyUpdated": "Комментарий был успешно обновлен.",
      "impsReceiverName": "Имя получателя",
      "impsTransferType": "Тип перевода",
      "impsIfsc": "IFSC получателя",
      "impsAccountNumber": "Номер счета",
      "impsIciciBankClient": "Клиент ICICI банка",
      "impsOtherBankClient": "Клиент другого банка",
      "partitial": "Частичная оплата",
      "partitialAmount": "Сумма частичной оплаты",
      "withdrawalMethod": "Способ вывода",
      "manualMethod": "Вручную из ЛК",
      "accountNumber": "Номер счета",
      "accountEmail": "Email получателя",
      "manualWithdrawals": "Ручные выплаты",
      "manualWithdrawalsUploading": "Загрузка заявок для ручных выплат",
      "manualWithdrawalsDownloading": "Выгрузка заявок для ручных выплат",
      "manualDownload": "Выгрузить",
      "xlsxFile": "XLSX-файл",
      "withdrawalsHaveBeenSuccessfullyUploaded": "Выводы были успешно загружены",
      "pagsmileBeneficiaryName": "Имя",
      "pagsmileBankSelectionType": "Тип выбора банка",
      "pagsmileBanksCodeList": "Выбрать из списка",
      "pagsmileBanksCodeText": "Написать свой",
      "pagsmileBankSelection": "Выберите банк",
      "pagsmileWriteBankCode": "Введите код банка ХХХ",
      "pagsmileBranch": "Код отделения банка",
      "pagsmileActionType": "Тип банковского счета получателя",
      "pagsmileAccountNumber": "Номер банковского счета получателя",
      "pagsmileDocumentType": "Тип документа получателя",
      "pagsmileDocumentID": "Идентификатор документа получателя",
      "pagsmileAccountDigit": "Цифра счета",
      "walletRecipientID": "ID получателя",
      "paygigaIBAN": "IBAN",
      "paygigaBankSelection": "Выбор банка",
      "paymentsMethod": "Cпособ оплаты",
      "paymentsProvider": "Поставщик платежей",
      "bankCodeInPaymentsSystem": "Код банка в платежной системе",
      "error": "Ошибка вывода",
      "unprocessedWithdrawalRequests": "Необработанные заявки на вывод",
      "incorrectWalletSender": "Пустой Wallet sender у принятой заявки",
      "incorrectStatus": "Некорректный статус",
      "logField": "Дата {{sheet}} строка {{data}}",
      "turnOnManualFill": "*Если необходимого кошелька нет в списке, выберите вариант \"Ввод вручную\" и заполните номер кошелька вручную.",
      "manualFill": "Ввод вручную",
      "walletNumberForWithdrawalWithAnnotation": "Номер кошелька для вывода*",
      "addWithdrawalRequest": "Добавить заявку на вывод",
      "downloadExampleFile": "Скачать пример",
      "tooltip": "Чтобы принять заявку, укажите номер кошелька для вывода в столбце Wallet sender и значение “Yes” в столбце Status.\r\nЧтобы отклонить заявку, укажите значение “No” в стобце Status.",
      "statusMessage": "Статусное сообщение",
      "kind": "Вид заявки",
      "confirmedKind": "Подтвержденная",
      "debugKind": "Отладочная"
    },
    "balances": {
      "transferMoney": "Перевести",
      "transferMoneyBetweenAccounts": "Перевод между счетами",
      "writeOffAccount": "Счет списания",
      "replenishmentAccount": "Счет зачисления",
      "accountType": "Тип счета",
      "toAnotherAccountBalance": "На баланс другого счета",
      "toSettlementBalance": "На баланс сеттлемента",
      "transferMoneyToAnotherAccountBalance": "Перевод на баланс другого счета",
      "transferMoneyToTheSettlementBalance": "Перевод на баланс сеттлемента",
      "toCrediting": "К зачислению",
      "replenishmentFromExternalAccount": "Пополнение с внешнего счета клиента",
      "withdrawalToExternalAccount": "Вывод на внешний счет клиента",
      "transferMoneyToClientAccountBalance": "Перевод на баланс клиентского счета",
      "replenishmentSuccessful": "Пополнение успешно выполнено.",
      "replenishmentError": "Ошибка пополнения",
      "transferingSuccessful": "Перевод успешно выполнен",
      "transferingError": "Ошибка перевода",
      "transferOnBalance": "Перевод на счет",
      "unknownOperationType": "Неизвестный тип операции",
      "writeOffAddress": "Адрес вывода",
      "withdrawals": "Выводы",
      "statusNew": "Новый",
      "statusSuccess": "Успешный",
      "statusRejected": "Отклоненный",
      "currentBalance": "Текущий баланс",
      "show": "Показать",
      "showBalanceOn": "Показать баланс на:",
      "balanceOn": "Баланс на",
      "noDataAvailableForDate": "На выбранную дату данных нет",
      "accounts": "Счета",
      "transferHistory": "История переводов",
      "replenishmentAccountType": "Тип счета зачисления",
      "writeOffAccountType": "Тип счета списания",
      "writeOffAmountInTransactionCurrency": "Сумма списания в валюте операции",
      "replenishmentAmountInTransactionCurrency": "Сумма зачисления в валюте операции",
      "operationHistory": "История операций",
      "accountBalance": "Баланс счета",
      "externalAccount": "Внешний счет",
      "operationType": "Тип операции",
      "availableBalance": "Доступный баланс",
      "withdrawalRequests": "Заявки на вывод",
      "hideZeroBalances": "Скрыть нулевые балансы",
      "zeroBalancesPlaceholderTitle": "Балансы всех счетов нулевые",
      "zeroBalancesPlaceholderDescription": "Измените дату либо включите отображение нулевых балансов.",
      "cancelBtn": "Отменить",
      "canceledLabel": "Отменённая",
      "compensatingLabel": "Компенсирующая",
      "cancelOperationTitle": "Отмена операции",
      "cancelModalContent": "Вы действительно хотите отменить операцию?",
      "extraInfo": "Доп. инфа"
    },
    "reports": {
      "reports": "Отчеты",
      "archivingStatus": "Статус архивации транзакции",
      "notArchivedOnly": "Только не архивные",
      "archivedOnly": "Только архивные",
      "oneRecipientEmail": "Должен быть указан минимум один email получателя",
      "transactions_list": "Список транзакций",
      "transactions_sum-detail": "Сумма транзакций с детализацией по дням",
      "transactions_sum": "Сумма транзакций",
      "listOfTasksForAutoGeneration": "Список задач на автогенерацию отчетов",
      "creatingAutogenerationTask": "Создание задачи на автогенерацию",
      "updatingAutogenerationTask": "Редактирование задачи на автогенерацию",
      "deletingAutogenerationTask": "Удаление задачи на автогенерацию",
      "doYouDeleteAutogenerationTask": "Вы действительно хотите удалить данную задачу на автогенерацию?",
      "createTask": "Создать задачу",
      "reportingPeriod": "Отчетный период",
      "start": "Начало",
      "autogeneratingReport": "Автогенерация отчета",
      "reportTasks": "Задачи",
      "report": "Отчет",
      "recipient": "Получатель",
      "author": "Автор",
      "actions": "Действия",
      "utc": "Часовой пояс UTC+00:00",
      "reportGenerating": "Генерация отчета",
      "generateReport": "Сформировать отчет",
      "calcAmounts": "Считать суммы",
      "fields": "Поля",
      "reportList": "Список отчетов",
      "file": "Файл",
      "reportInProgress": "Формирование отчёта может занять до 5 минут. Пока можете покинуть страницу. Сгенерированный отчёт будет доступен на этой странице до 23:59 следующего дня.",
      "kindOfReport": "Вид отчета",
      "sumOfTransactions": "Сумма транзакций",
      "counterparty": "Контрагент",
      "reportType": "Тип отчета",
      "activated": "Активированные",
      "notActivated": "Неактивированные",
      "canceled": "Отмененные",
      "approved": "Выплаченные",
      "activationPeriod": "Период активации",
      "activationData": "Дата активации",
      "archivingPeriod": "Период архивации",
      "archivedTransactionsOnly": "Только архивные транзакции",
      "archivingDate": "Дата архивации",
      "orderId": "Номер заказа",
      "commissionAmount": "Сумма комиссий",
      "detailByDay": "Детализация по дням",
      "listOfWithdrawalRequests": "Список заявок на вывод",
      "postbackStatus": "Статус постбека",
      "requestStatus": "Статус заявки",
      "operationWithSettlementBalance": "Операции с балансом сеттлемента",
      "commission": "Комиссия",
      "operationType": "Тип операции",
      "transactionActivation": "Активация транзакции",
      "transactionArchiving": "Архивация транзакции",
      "transferBetweenAccounts": "Перевод между счетами",
      "transferBetweenAccountAndSettlement": "Перевод между счетом и сеттлемент",
      "direction": "Направление",
      "replenishment": "Пополнение",
      "writeOff": "Списание",
      "reportFormat": "Формат отчета",
      "postbackDate": "Дата отправки постбека",
      "stockpilingId": "ID накопления",
      "selectBy": "Отбирать по",
      "transactionListWithCompletedStockpiling": "Список транзакций, по которым завершено накопление",
      "stockpilingEndDate": "Дата завершения накопления",
      "stockpilingEndPeriod": "Период завершения накопления",
      "postbackSent": "Отправлен",
      "postbackError": "Ошибка отправки постбека",
      "transactionSumWithCompletedStockpiling": "Сумма транзакций, по которым завершено накопление",
      "operationWithBalances": "Операции с балансами",
      "counterpartyWalletType": "Тип кошелька контрагента ",
      "externalAccount": "Внешний счет ",
      "accountBalance": "Баланс счета",
      "dateCompleted": "Дата выполнения",
      "statusNew": "Новый",
      "statusInProgress": "В работе",
      "statusCompleted": "Готов",
      "statusError": "Ошибка",
      "reportSentToQueue": "Отчет отправлен в очередь на обработку"
    },
    "testPS": {
      "psEditing": "Редактирование платежной системы",
      "psCreating": "Создание платежной системы",
      "addPS": "Добавить платежную систему",
      "paymentSystems": "Платежные системы",
      "psList": "Список платежных систем",
      "name": "Название",
      "description": "Описание",
      "logo": "Логотип",
      "screen": "Скриншот",
      "minDeposit": "Минимальный депозит",
      "requisites": "Реквизиты",
      "machineName": "Кодовое название",
      "transactionType": "Тип транзакции",
      "isActive": "Отображать на плагине",
      "requisite": "Реквизит",
      "active": "Активный",
      "notActive": "Неактивный",
      "addAtLeastOneRequisite": "Добавьте хотя бы один реквизит",
      "testPS": "Тестовые платежные системы",
      "PSHasBeenSuccessfullyDisabled": "Платежная система успешно отключена",
      "inOrderToActivatePSCreateWalletForIt": "Для активации платежной вам необходимо создать для нее кошелек.",
      "psDeleting": "Удаление платежной системы",
      "doYouReallyWantToDisableThisTestPS": "Вы действительно хотите удалить данную платежную систему?",
      "keyFieldDescription": "Если платёж можно идентифицировать с помощью уникального номера, необходимо выбрать тип \"С ключом\". Если подтвердить платёж можно только с помощью скриншота – \"Без ключа\".",
      "requisiteName": "Наименование реквизита",
      "walletData": "Данные кошелька",
      "paymentSystemHint": "Подсказка для платежной системы",
      "manualPS": "Ручные платёжные системы",
      "codeName": "Кодовое название",
      "manualPSList": "Список ручных платежных систем"
    },
    "manualPS": {
      "psEditing": "Редактирование платежной системы",
      "psCreating": "Создание платежной системы",
      "addPS": "Добавить платежную систему",
      "paymentSystems": "Платежные системы",
      "psList": "Список платежных систем",
      "name": "Название",
      "description": "Описание",
      "logo": "Логотип",
      "screen": "Скриншот",
      "minDeposit": "Минимальный депозит",
      "requisites": "Реквизиты",
      "machineName": "Кодовое название",
      "transactionType": "Тип транзакции",
      "isActive": "Отображать на плагине",
      "requisite": "Реквизит",
      "active": "Активный",
      "notActive": "Неактивный",
      "addAtLeastOneRequisite": "Добавьте хотя бы один реквизит",
      "testPS": "Тестовые платежные системы",
      "PSHasBeenSuccessfullyDisabled": "Платежная система успешно отключена",
      "inOrderToActivatePSCreateWalletForIt": "Для активации платежной вам необходимо создать для нее кошелек.",
      "psDeleting": "Удаление платежной системы",
      "doYouReallyWantToDisableThisTestPS": "Вы действительно хотите удалить данную платежную систему?",
      "keyFieldDescription": "Если платёж можно идентифицировать с помощью уникального номера, необходимо выбрать тип \"С ключом\". Если подтвердить платёж можно только с помощью скриншота – \"Без ключа\".",
      "requisiteName": "Наименование реквизита",
      "walletData": "Данные кошелька",
      "paymentSystemHint": "Подсказка для платежной системы",
      "manualPS": "Ручные платёжные системы",
      "codeName": "Кодовое название",
      "manualPSList": "Список ручных платежных систем",
      "codeNameFormat": "Название на английском с постфиксом _ml, доступны маленькие буквы, без пробелов. Пример: upi_ib_ml"
    },
    "changeLog": {
      "changeLogs": "Журнал изменений",
      "user": "Пользователь",
      "objectType": "Тип объекта",
      "objectId": "ID объекта",
      "actionType": "Вид действия",
      "oldValue": "Старое значение",
      "newValue": "Новое значение"
    },
    "errors": {
      "requiredField": "Обязательное поле",
      "invalidEmail": "Неверный формат почты",
      "onlyLatinDigitsAndSymbols": "Разрешены только латинские буквы, цифры и символы",
      "minFieldSize": "Минимальное допустимое кол-во символов: {{len}}",
      "maxFieldSize": "Максимальное допустимое кол-во символов: {{len}}",
      "maxFileSize": "Файл должен быть меньше {{size}} {{postfix}}",
      "fileFormatNotAllowed": "Недопустимый формат файла. Файл должен быть следующих форматов: {{formats}}",
      "invalidDateRange": "Неверный диапазон. Поле даты \"по\" должна быть больше \"с\"",
      "dateRangeIsRequired": "Необходимо заполнить диапазон дат: [с-по]",
      "invalidIpFormat": "Поле должно принимать вид или IP-адреса: от 0.0.0.0 до 255.255.255.255, или web-адреса  хоста.",
      "invalidPortFormat": "Port должен состоять только из цифр и его значение не должно превышать 65535",
      "invalidAmount": "Неверный формат суммы",
      "cannotActivatePaymentBecauseOfMinDeposit": "Для активации следующих платежных систем, задайте минимальные депозиты больше 0: {{payments}}",
      "minAmount": "Минимальное допустимое значение: {{amount}}",
      "maxAmount": "Максимальное допустимое значение: {{amount}}",
      "minAmountShouldBeGreater": "Значение должно быть больше {{amount}}",
      "setValueGreaterThan0ForThisPayment": "Данная платежная система активна. Вы не можете выставить минимальный депозит 0 для нее",
      "setValueRange": "Значение должно быть между 0 и 9999999999.9999999999",
      "invalidCardFormat": "Неверный формат номера карты. Номер карты должен состоять из 16 или 18 символов",
      "invalidPhoneFormat": "Номер телефона должен состоять из {{len}} цифр и начинаться с \"{{prefix}}\"",
      "invalidDate": "Ошибка данных аккаунта",
      "passwordMustNotContainSpaces": "Пароль не должен содержать пробелы",
      "invalidUrl": "Неверный формат ссылки",
      "maxDepositMustBeGreaterThanMin": "Максимальный депозит должен быть больше чем минимальный",
      "mustBeDifferentValues": "Нельзя задать одинаковое время для включения и отключения",
      "fileNameDontMatchPattern": "Наименование файла( -ов) не соответствует требуемому шаблону {{pattern}}",
      "maxWithdrawalMustBeGreaterThanMin": "Максимальный вывод должен быть больше чем минимальный",
      "mustBeMultiple": "Значение должно быть кратным 10",
      "phoneNumberLength": "Значение без кода страны должно состоять из 10 цифр",
      "startWithSA": "Значение не может начинаться с \"sa_\"",
      "fillAtLeastOnePeriod": "Укажите хотя бы один период",
      "invalidIfscCode": "IFSC получателя не соответствует заданному формату",
      "filesAlreadyInTable": "Эти файлы уже находятся в таблице:",
      "bothDateRequired": "Необходимо указать обе даты",
      "commissionPercentageIsNotSet": "Процент комиссии от суммы транзакции не задан",
      "onlyOnePeriodMustBeDefined": "Одновременно может быть указан только один из периодов",
      "invalidCookieString": "Формат ввода: {\"date\":\"ГГГГ-ММ-ДД ЧЧ:ММ:СС\", \"type\":\"work/middle\", \"payload\": {json-строка}}.",
      "isRequiredAtSymbol": "Символ @ обязателен",
      "invalidEnglishName": "Неверое название платежной системы",
      "invalidEnglishCode": "Неверое кодовое название платежной системы",
      "providerError": "Необходимо поставить @ перед никнеймом",
      "invalidFormat": "Неверный формат",
      "upiPaymentDisplayError": "Оплата только по ссылке недоступна. Выберите еще один способ оплаты.",
      "upiPaymentSamePaymentError": "Дублируются значения. Выберите разные способы оплаты.",
      "manualPSFormatError": "Допустимые символы: a-z, 0-9, _. Обязателен постфикс \"_ml\". Пример: upi_ib_ml",
      "tryAgainLater": "Произошла ошибка, попробуйте позже"
    },
    "settings": {
      "passwordChanging": "Изменение пароля",
      "passwordChangingDescription": "Изменение пароля для входа - это простой способ обеспечить безопасность вашего аккаунта",
      "oldPassword": "Старый пароль",
      "newPassword": "Новый пароль",
      "pluginInstallation": "Установка плагина",
      "pluginInstallationDescription": "Это код вашего виджета. Скопируйте и вставьте его в код тех страниц, на которых вы хотите принимать платежи. Необходимо самостоятельно заполнить параметры",
      "pluginSettings": "Настройки плагина",
      "light": "Светлая",
      "dark": "Темная",
      "whiteGreen": "Бело-зеленая",
      "minDepositSettings": "Настройки минимальных депозитов",
      "mediaSettings": "Загрузка подсказок платёжной системы",
      "dashboardCurrency": "Валюта в дашборде",
      "theme": "Тема",
      "project": "Проект",
      "postbackUrl": "Постбек URL депозита",
      "postbackAccessKey": "Ключ доступа на постбек",
      "privatePostbackAccessKey": "Приватный ключ доступа на постбек",
      "companyName": "Название компании",
      "paymentUrlOnSuccess": "URL перенаправления при успешной оплате",
      "paymentUrlOnError": "URL перенаправления при сбое во время оплаты",
      "mediaUploading": "Загрузка подсказок платёжной системы",
      "minDeposit": "Минимальный депозит",
      "settings": "Настройки",
      "passwordHasBeenSuccessfullyUpdated": "Пароль успешно обновлен",
      "passwordUpdatingHasBeenFailed": "Пароль не был обновлен",
      "mediaFilesHaveBeenSuccessfullyUploaded": "Медиафайлы были успешно сохранены",
      "useConstructorInPlugin": "Использовать конструктор",
      "profileSettings": "Настройки профиля",
      "language": "Язык",
      "ru": "Русский",
      "en": "Английский",
      "setMinDeposit": "Настроить минимальный депозит",
      "displayFirstWindow": "Показывать \"первое\" окно",
      "timezone": "Часовой пояс",
      "custom": "Пользовательская",
      "primaryButtonColor": "Основной цвет кнопок",
      "secondaryButtonColor": "Второстенный цвет кнопок",
      "textColor": "Цвет текста",
      "backgroundColor": "Цвет фона",
      "inputBackgroundColor": "Цвет фона текстового поля",
      "inputTextColor": "Цвет текста текстового поля",
      "inputBorderColor": "Цвет рамок текстового поля",
      "displayOnPlugin": "Отображать на плагине",
      "customTheme": "Своя",
      "file": "Файл",
      "download": "Скачать",
      "stylesHaveBeenUpdated": "Стили для плагина были успешно обновлены",
      "stylesUpdatingHasBeenFailed": "При обновлении стилей возникла ошибка",
      "paykassmaSettings": "Настройки paykassma",
      "required": "Обязательный параметр",
      "optional": "Опциональный параметр",
      "optionalWithDefault": "Опциональный параметр, по умолчанию {{param}}",
      "withdrawalPostbackSuccess": "Успешный URL для постбека вывода",
      "withdrawalPostbackFailure": "URL для постбека вывода при ошибке",
      "manualWithdrawalPostback": "Постбек URL ручного вывода",
      "deleteWithdrawalsAfterDays": "Количество дней простоя для удаления выводов",
      "standardStatusSettings": "Настройки статуса пользователя",
      "generalAmountForGettingStandardStatus": "Общая сумма пополнений для получения статуса \"Стандартный\" (USD)",
      "paymentSystems": "Платежные системы",
      "vip": "VIP",
      "availableForInvoice": "Доступность для ввода",
      "standard": "Стандартный",
      "standardPaymentSystem": "Стандартная платёжная система",
      "limitForGettingStatusStandard": "Порог получения статуса \"Стандартный\" (USD)",
      "parserSettings": "Настройки парсера",
      "severalValue": "Возможно указать несколько значений через запятую",
      "succeededUrl": "URL перенаправления при успешной оплате",
      "failedUrl": "URL перенаправления при сбое во время оплаты",
      "id": "ID",
      "url": "Ссылка",
      "timezoneHint": "Часовой пояс задает метку времени транзакций в системе. Рекомендуется указывать часовой пояс страны, в которой совершаются транзакции",
      "manualWithdrawalPostbackHint": "URL, на который будет отправлен постбек о выводе денежных средств со счета пользователя",
      "deleteWithdrawalsAfterDaysHint": "Количество дней, по истечению которых необработанный вывод будет удален из Системы",
      "postbackUrlHint": "URL, на который будет отправлен постбек о зачислении денежных средств на счет пользователя",
      "postbackAccessKeyHint": "Открытый ключ доступа, используемый для шифрования данных постбека",
      "privatePostbackAccessKeyHint": "Секретный ключ доступа, используемый для расшифрования данных постбека",
      "reactivationTimer": "Время ожидания между попытками активации транзакции (в секундах)",
      "columnUpdated": "Отображение полей таблицы изменено",
      "columnUpdatingFailed": "Отображение полей таблицы не было изменено",
      "nativeRU": "Русский",
      "nativeEN": "English",
      "postback": "Постбек",
      "postbackWithdrawal": "Вывод постбек",
      "deposit": "Оплата",
      "profile": "Профиль",
      "systemSettings": "Системные настройки",
      "pluginAppearance": "Внешний вид плагина",
      "timeProfile": "Часовой профиль",
      "deletePossibleTransactionAfterDays": "Количество дней простоя для удаления возможной транзакции",
      "deletePossibleTransactionAfterDaysDescription": "Количество дней, по истечению которых необработанная возможная транзакция будет удалена из Системы",
      "paymentSystemsDisplayOrder": "Порядок отображения платежных систем",
      "displayOrder": "Порядок отображения",
      "resetOrder": "Сбросить порядок",
      "addingDisplayOrder": "Добавление порядка отображения",
      "selectCurrencyToDisplayPS": "Для отображения списка платежных систем выберите валюту",
      "resetDisplayOrder": "Сброс порядка отображения",
      "sureResetDisplayOrder": "Вы действительно хотите сбросить порядок отображения платежных систем?",
      "editingDisplayOrder": "Редактирование порядка отображения",
      "orderHasBeenSuccessfullyUpdated": "Порядок отображения успешно обновлен",
      "fileMissing": "Файл отсутствует",
      "customAndDefaultOrderIsTheSame": "Пользовательский порядок отображения совпадает с порядком отображения по умолчанию. Вы уверены, что хотите использовать порядок отображения по умолчанию?",
      "savingDisplayOrder": "Сохранение порядка отображения",
      "updatePassword": "Обновить пароль",
      "versionFetchingHasBeenFailed": "При загрузке версии произошла ошибка.",
      "reactivationTimerHint": "Время, которое должно пройти перед повторной попыткой активировать транзакцию",
      "recheckTimer": "Период повторной проверки наличия транзакции (в секундах)",
      "recheckTimerHint": "Период повторной проверки наличия транзакции за время ожидания между попытками активации транзакции",
      "files": "Файлы",
      "notification": "Уведомления",
      "numberOfDaysToKeepNotifications": "Количество дней хранения уведомлений в окне \"Центр уведомлений\"",
      "numberOfDaysToKeepNotificationsHint": "Количество дней, по истечению которых уведомление не будет отображаться в окне \"Центр уведомлений\"",
      "useRedirectURLFromSettingsParameterAvailability": "Параметр доступен, если в настройках выключен переключатель \"Использовать URL перенаправления из настроек\"",
      "useRedirectURLFromSettings": "Использовать URL перенаправления из настроек",
      "smsInputMethodForPayTM": "Способ ввода SMS для PayTM",
      "manualInput": "Ручной ввод",
      "automaticInput": "Автоматический ввод",
      "depositPostback": "Депозит постбек",
      "useUpiQrCode": "Отображать QR-код на плагине для UPI",
      "checkingForTransactions": "Проверка на наличие транзакций",
      "noTransactionsPeriod": "Период отсутствия транзакций ",
      "disableWalletVisibility": "Выключения видимости кошелька ",
      "numberOfWalletImpressions": "Количество показов кошелька",
      "wrongTransactionAmount": "Период должен быть больше 5 и меньше 600",
      "wrongImpressionsAmount": " Количество показов должно быть больше 5",
      "noTransactionsPeriodDescription": "Период отсутствия транзакций при котором проверка считается неуспешной (в минутах)",
      "link": "Ссылка",
      "qr": "QR",
      "upi_id": "UPI ID",
      "upiP2P": "UPI P2P",
      "upiAPI": "UPI API",
      "paymentMethod": "Способ оплаты",
      "paymentMethodsDisplay": "Отображение способов оплаты на плагине в UPI типах кошелька",
      "confidentialSettings": "Конфиденциальные настройки",
      "plugin": "Плагин",
      "reportAProblem": "Отображение функционала \"Сообщить о проблеме\"",
      "withdrawalRequestsTitle": "Заявки на вывод",
      "withdrawalRequestsDescription": "Ограничить прием заявок на вывод при недостаточном балансе",
      "pendingUrlTitles": "URL перенаправления при ожидании подтверждения оплаты",
      "pendingUrlDescription": "Параметр доступен, если в настройках выключен переключатель \"Использовать URL перенаправления из настроек\"",
      "isCheckAmount": "Проверка суммы при активации"
    },
    "stockpilings": {
      "stockpilings": "Накопления",
      "stockpilingList": "Список накоплений",
      "stockpilingsAmount": "Сумма накоплений",
      "createdAt": "Дата формирования",
      "sendPostback": "Отправить постбэк",
      "actionConfirmation": "Подтверждение действия",
      "sureWantSendPostback": "Вы действительно хотите отправить постбек?",
      "real": "Реальное",
      "debug": "Отладочное",
      "type": "Тип накопления"
    },
    "postbacks": {
      "resend": "Переслать",
      "sureToResendPostback": "Вы действительно хотите переслать постбек?",
      "postbackHasBeenSent": "Постбек отправлен",
      "postbackList": "Список постбеков",
      "sentPostbacks": "Отправленные постбеки",
      "url": "Ссылка",
      "statusCode": "Статус код",
      "response": "Ответ",
      "requestBody": "Тело запроса",
      "postbackListIsEmpty": "Список постбеков пуст",
      "postback": "Постбек",
      "postbacks": "Постбеки",
      "sentPostbackList": "Список отправленных постбеков",
      "notAcceptedPostbacks": "Недошедшие постбеки",
      "notAcceptedPostbackList": "Список недошедших постбеков",
      "resendPostback": "Переслать постбек",
      "postbackHasBeenResent": "Постбек был успешно переслан",
      "postbackResendingHasBeenFailed": "Не удалось переслать постбек",
      "postbackStatus": "Статус постбека",
      "sent": "Отправлен",
      "stockpilingInProgress": "Идет накопление",
      "stockpilingNotFound": "Накопление не найдено",
      "notSend": "Не отправлен",
      "received": "Получен",
      "error": "Ошибка",
      "errorSendingPostback": "Ошибка отправки постбека",
      "countFailure": "Количество попыток отправки",
      "clearCountFailure": "Сбросить счетчик попыток",
      "markAsSent": "Отметить как отправленный",
      "areYouSureYouWantToResetAttemptCounter": "Вы действительно хотите сбросить счетчик попыток отправки постбека?",
      "markPostbackAsSent": "Отметить постбек как отправленный",
      "areYouSureYouWantToMarkPostbackAsSent": "Вы действительно хотите отметить постбек как успешно отправленный?"
    },
    "transactions": {
      "outgoing": "Исходящие",
      "ingoing": "Входящие",
      "direction": "Направление",
      "transactionId": "Номер транзакции",
      "exchanger": "Контрагент",
      "fake": "Отладочная",
      "real": "Подтвержденная",
      "forced": "Принудительная",
      "selectTheWallet": "Выберите кошелек",
      "transactionGeneration": "Генерация транзакции",
      "downloadData": "Скачать данные",
      "transactionList": "Список транзакций",
      "sumOfTransactionsDetailedByDay": "Сумма транзакций с детализацией по дням",
      "walletTransactions": "Транзакции кошелька",
      "generateTransaction": "Сгенерировать транзакцию",
      "transactions": "Транзакции",
      "possibleTransactions": "Возможные транзакции",
      "preliminaryTransactions": "Предтранзакции",
      "kindOfTransaction": "Тип транзакции",
      "playerId": "Лейбл",
      "youHaveNotPermissionsToCreateForcedTransaction": "У вас нет прав на создание принудительной транзакции",
      "youHaveNotPermissionsToCreateFakeTransaction": "У вас нет прав на создание отладочной транзакции",
      "generate": "Сгенерировать",
      "transactionAmount": "Сумма транзакции",
      "transactionNumber": "Номер транзакции",
      "activated": "Активирована",
      "notActivated": "Неактивирована",
      "rejected": "Отклонена",
      "pending": "В ожидании",
      "inProgress": "В обработке",
      "crashed": "Сбой",
      "canceled": "Отменена",
      "whereFrom": "Откуда",
      "whereTo": "Куда",
      "transactionsById": "Транзакции по ID",
      "transaction": "Транзакция",
      "new": "Новый",
      "processed": "Обработанный",
      "screenDownloadingHasBeenFailed": "Скриншот для этой транзакции не был загружен",
      "screen": "Скрин",
      "preliminaryTransactionCreation": "Создание предтранзакции",
      "preliminaryTransactionDeleting": "Удаление предтранзакции",
      "createTransaction": "Создать транзакцию",
      "preliminaryTransactionHasBeenSuccessfullyActivated": "Предтранзакция успешно активирована",
      "fileDownloadingHasBeenFailed": "Ошибка загрузки файла",
      "preliminaryTransactionsById": "Предтранзакции по ID",
      "withKey": "С ключем",
      "withoutKey": "Без ключа",
      "possibleTransactionList": "Список возможных транзакций",
      "error": "Ошибка",
      "filterActivated": "Активированные",
      "filterNotActivated": "Нективированные",
      "filterRejected": "Отклоненные",
      "filterPending": "В ожидании",
      "filterInProgress": "В обработке",
      "filterCrashed": "Дали сбой",
      "filterCanceled": "Отмененные",
      "filterError": "С ошибкой",
      "undefined": "Не определена",
      "preliminaryTransactionsList": "Список предтранзакций",
      "preliminaryTransactionNumber": "Номер предтранзакции",
      "preliminaryTransactionId": "ID предтранзакции",
      "createPreliminaryTransaction": "Создать предтранзакцию",
      "payments": "Оплаты",
      "postbackStatus": "Статус постбека",
      "transactionListIsEmpty": "Список транзакций пуст",
      "inner": "Внутренняя",
      "activate": "Активировать",
      "transactionActivation": "Активация транзакций",
      "empty": "Пустое",
      "withLabel": "С лейблом",
      "labelAvailability": "Наличие лейбла",
      "label": "Лейбл",
      "orderNumber": "Номер заказа",
      "transactionHasBeenSuccessfullyUpdated": "Транзакция успешно активирована",
      "labelHistoryReport": "История отображения данных счета лейблу",
      "fakeGeneration": "Генерация отладочной транзакции",
      "realGeneration": "Генерация подтвержденной транзакции",
      "forcedGeneration": "Генерация принудительной транзакции",
      "walletNumber": "Номер кошелька",
      "transactionType": "Вид транзакции",
      "auto": "Авто",
      "manual": "Ручная",
      "commission": "Комиссия",
      "realHint": "Выберите данный тип транзакции, если денежные средства поступили на счет, но транзакции по какой-то причине нет в системе.",
      "forcedHint": "Выберите данный тип транзакции, если денежные средства не поступили на счет, но вы вынуждены создавать транзакцию в системе.",
      "fakeHint": "Выберите данный тип транзакции, если необходимо провести тестирование или демо-показ.",
      "duplicatedTransactionText1": "В списке возможных транзакций найдена транзакция:",
      "transactionNumberWithValue": "Номер транзакции: {{id}}",
      "amountWithValue": "Сумма: {{amount}}",
      "labelWithValue": "Лейбл: {{label}}",
      "duplicatedTransactionText2": "При сохранении возможная транзакция будет удалена. Вы действительно хотите создать транзакцию?",
      "archiveTransactions": "Архивные транзакции",
      "archivateTransaction": "Архивация транзакций",
      "addToArchive": "Вы уверены, что хотите отправить транзакцию в архив?",
      "addTransactionToArchive": "Добавить транзакцию в архив",
      "transactionHasBeenAddedToArchive": "Транзакция добавлена в архив!",
      "archiveTransactionsList": "Список архивных транзакций",
      "notSpecified": "Не указан",
      "orderId": "Номер заказа",
      "bulkTransactionLoading": "Массовая загрузка транзакций",
      "iciciTransactionFile": "Файл с транзакциями ICICI",
      "iciciTransactionFileHint": "Выберите данный пункт меню для загрузки файла выгруженного из личного кабинета банка ICICI",
      "loadIciciTransactionFile": "Загрузка файла с транзакциями ICICI",
      "fileInProcessed": "Файл загружен и находится в обработке",
      "psvFile": "PSV-файл",
      "archivedTransaction": "Архивная транзакция",
      "internal": "Внутренние",
      "status": "Статус транзакции",
      "clientAccountBalanceChange": "Изменение баланса клиентского счета",
      "transfer": "Перевод",
      "replenishment": "Пополнение",
      "withdrawal": "Вывод",
      "statusSuspect": "Подозрительная",
      "approveButton": "Одобрить",
      "approveMessageSuccess": "Успешно одобрено",
      "typeOrAccount": "Тип/Вн. счет",
      "possibleTransactionApproval": "Подтверждение возможной транзакции",
      "archivingStatus": "Статус архивации транзакции",
      "archivedOnly": "Только архивные",
      "notArchivedOnly": "Только не архивные",
      "outsideLimit": "Введенная сумма превышает максимальный депозит для ПС",
      "noTransactions": "Нет транзакций",
      "originality": "Оригинальность",
      "original": "Нормальная",
      "scam": "Scam",
      "fakeBtn": "Фейк",
      "findedPossible": "Найдена возможная транзакция с номером",
      "choosePossible": "Выберите позицию из списка и нажмите \"Далее\"",
      "dontUseLastData": "Не использовать данные из возможной транзакции",
      "approveTransactionTitle": "Одобрить транзакцию",
      "approveTransactionText": "Вы действительно хотите одобрить транзакцию с номером {{id}}?"
    },
    "dashboard": {
      "sumOfActivatedTransactions": "Сумма активированных транзакций",
      "quantityOfActivatedTransactions": "Количество активированных транзакций",
      "workingWallets": "Рабочих кошельков",
      "totalBalance": "Общий баланс",
      "workingProxies": "рабочих прокси",
      "withdrawalsAmountForToday": "Сумма выводов за сегодня",
      "dashboardDataFetchingHasBeenFailed": "Данные для дашборда не были получены",
      "filters": "Фильтры",
      "totalSum": "Все транзакции",
      "accept": "Применить",
      "paymentSystems": "Платежные системы",
      "testPS": "Тестовые платежные системы",
      "details": "Детализация",
      "sumOfActivatedTransactionsForToday": "Сумма активированных транзакций за сегодня",
      "quantityOfActivatedTransactionsForToday": "Количество активированных транзакций за сегодня",
      "dashboard": "Статистика",
      "periodFromTo": "За период с {{from}} по {{to}}",
      "sum": "Сумма",
      "count": "Количество",
      "manualPS": "Ручные платёжные системы",
      "ingoingTransactions": "Входящие транзакции",
      "outgoingTransactions": "Исходящие транзакции",
      "sumOfCommission": "Сумма комиссии",
      "forToday": "За сегодня",
      "withCompletedStockpiling": "С завершенным накоплением",
      "withCompletedStockpilingTip": "Если этот параметр включен:\n\n1. На графике будут отображены только активированные транзакции с завершенным накоплением\n2. В информационных блоках будет отображена информация по транзакциям с завершенным накоплением\nЕсли этот параметр выключен, на графике и в информационных блоках будет отображена информация по активированным транзакциям, независимо от статуса накопления."
    },
    "logs": {
      "errorType": "Тип ошибки",
      "message": "Сообщение",
      "logList": "Список логов",
      "walletLogs": "Логи кошелька",
      "walletsLogs": "Логи кошельков",
      "logs": "Логи",
      "messageIsEmpty": "Нет сообщения",
      "file": "Файл",
      "paytmCookies": "Куки Paytm",
      "walletsDebugging": "Отладка кошельков",
      "filesUploading": "Загрузка файлов",
      "uploadFiles": "Загрузить файлы",
      "uploadFile": "Загрузить файл",
      "files": "Файлы",
      "filesHaveBeenSuccessfullyUploaded": "Файлы были успешно загружены",
      "downloadAll": "Скачать все",
      "withdrawalsLogs": "Логи выводов",
      "category": "Категория",
      "walletType": "Тип кошелька",
      "сategory": "Категория"
    },
    "wallets": {
      "wallets": "Кошельки",
      "walletRecoveryDate": "Дата восстановления кошелька",
      "sureToRecoverWallet": "Вы действительно хотите восстановить кошелек? Если кошелек P2P, укажите дату начала парсинга транзакций.",
      "walletCreationDate": "Дата создания кошелька",
      "parsingStartTime": "Время начала парсинга",
      "ParsingStartDate": "Дата начала парсинга",
      "onlyOneUPIAddress": "Можно вводить только один UPI адрес!",
      "existingPagSmileWallet": "Кошелек PagSmile уже существует в системе. Удалите существующий кошелек PagSmile, чтобы завести новый!",
      "addWallet": "Добавить кошелек",
      "identifier": "Номер",
      "email": "Email",
      "balance": "Баланс счетов",
      "walletUpdating": "Редактирование кошелька",
      "walletDisabling": "Отключение кошелька",
      "doYouReallyWantToDisableThisWallet": "Вы действительно хотите отключить данный кошелек?",
      "walletList": "Список кошельков",
      "myWallets": "Рабочие кошельки",
      "disabledWallets": "Отключенные кошельки",
      "notWorkingProxy": "Нерабочий прокси",
      "couldNotAuthToWalletEmail": "Не смогли авторизоваться в почте кошелька",
      "couldNotAuthToWalletApp": "Не смогли авторизоваться в приложении кошелька",
      "parsingDataError": "Ошибка парсинга транзакций",
      "unknownError": "Неизвестная ошибка",
      "accountKey": "Ключ аккаунта",
      "enUsername": "Username",
      "password": "Пароль",
      "enCode": "Code",
      "lastUpdate": "Обновление",
      "proxyDoesNotWork": "Прокси не работает",
      "enProxyIP": "Прокси",
      "description": "Описание",
      "accountIdentifier": "Идентификатор счёта (email)",
      "enApiKey": "Api Key",
      "comment": "Комментарий",
      "transactionList": "Список транзакций",
      "markWalletAsWorking": "Активировать кошелёк",
      "resetData": "Cбросить куки",
      "walletPassword": "Пароль кошелька",
      "emailOfEmail": "Почта",
      "emailPassword": "Пароль email",
      "youHaveToUpdateCookies": "Обновите куки",
      "walletCreating": "Создание кошелька",
      "wallet": "Кошелек",
      "testPayment": "Тестовая платежка",
      "active": "Рабочий",
      "disabled": "Отключенный",
      "doYouReallyWantToResetThisWalletSettings": "Вы действительно хотите сбросить смс данные для кошелька?",
      "dataResetting": "Сброс данных",
      "enterCode": "Введите код",
      "phoneConfirmation": "Подтверждение телефона",
      "walletIsWorkingNow": "Кошелек стал рабочим",
      "commentHasBeenSuccessfullyUpdated": "Комментарий был успешно обновлен.",
      "smsHasBeenSuccessfullySent": "Отправка sms прошла успешно.",
      "smsSendingHasBeenFailed": "Ошибка отправки sms. Попробуйте позже.",
      "walletDataHasBeenSuccessfullyReset": "Данные кошелька были успешно сброшены.",
      "walletResettingHasBeenFailed": "Ошибка сброса данных. Попробуйте позже.",
      "walletDeleting": "Удаление кошелька",
      "walletType": "Тип кошелька",
      "selectTheWallet": "Выберите кошелек",
      "selectAnotherWallet": "Выберите другой кошелек",
      "walletIdentifier": "Номер кошелька",
      "walletsLoad": "Нагрузка кошельков",
      "selectTypeAndLoadsDirection": "Выберите тип и направление нагрузки",
      "loadPreviewSetting": "Настройка просмотра нагрузки",
      "loadSettings": "Настройка нагрузки",
      "changeLoadSettings": "Изменить настройки нагрузки",
      "amountOfIngoingTransactionsInUSD": "Cумма входящих транзакций в USD",
      "amountOfOutgoingTransactionsInUSD": "Сумма исходящих транзакций в USD",
      "amountOfIngoingTransactions": "Cумма входящих транзакций",
      "amountOfOutgoingTransactions": "Сумма исходящих транзакций",
      "quantityOfIngoingTransactions": "Кол-во входящих транзакций",
      "quantityOfOutgoingTransactions": "Кол-во исходящих транзакций",
      "outgoing": "Уход",
      "ingoing": "Приход",
      "direction": "Направление",
      "loadType": "Вид нагрузки",
      "walletStatus": "Статус кошелька",
      "stoppedWallets": "Отключенные кошельки",
      "activeWallets": "Рабочие кошельки",
      "deletedWallets": "Удаленные кошельки",
      "load": "Нагрузка",
      "walletHasBeenSuccessfullyDisabled": "Кошелек был успешно отключен",
      "enterSms": "Ввести sms",
      "recoverWallet": "Восстановить куки",
      "walletHasBeenSuccessfullyRecovered": "Куки были успешно восстановлены",
      "outdatedCookies": "Куки устарели",
      "loadObject": "Объект нагрузки",
      "updateComment": "Оставить комментарий",
      "lastIngoing": "Последний приход средств",
      "lastOutgoing": "Последний уход средств",
      "lastUpdateInfo": "Информация последнего обновления",
      "lastParsing": "Последний парсинг",
      "paymentSystems": "Платежные системы",
      "addAtLeastOneCard": "Добавьте хотя бы одну карту",
      "operationType": "Способ зачисления",
      "country": "Страна",
      "username": "Логин",
      "vipWallet": "VIP-кошелек",
      "pluginStatus": "Видимость",
      "pluginStatusHasBeenSuccessfullyUpdated": "Видимость кошелька в плагине успешно обновлена",
      "vip": "VIP",
      "standardWallet": "Стандартный кошелек",
      "initialWallet": "Начальный кошелек",
      "standard": "Стандартный",
      "initial": "Начальный",
      "addAtLeastOneProxy": "Добавьте прокси",
      "walletSettingsHaveBeenSuccessfullyUpdated": "Настройки кошелька были успешно обновлены",
      "addAtLeastOneBankcode": "Укажите bank code",
      "token": "Токен",
      "depositCards": "Карты для пополнения",
      "withdrawalCards": "Карты для вывода",
      "emailUsername": "Электронная почта",
      "phone": "Телефон",
      "datePlaceholder": "MM/ГГ",
      "accountDataError": "Ошибка данных аккаунта",
      "probablyBanned": "Возможно забанен",
      "linkForPayment": "Ссылка для оплаты",
      "receiverEmail": "Эл. почта получателя",
      "merchantID": "ID мерчанта",
      "merchantPassword": "Пароль мерчанта",
      "iciciLogin": "icici логин",
      "iciciPassword": "icici пароль",
      "accountName": "Имя аккаунта",
      "walletOffLimit": "Отключать при балансе",
      "transactionSearch": "Поиск транзакций",
      "transactionDateSelection": "Выбор даты транзакций",
      "search": "Поиск",
      "transactionDate": "Дата транзакций",
      "parsingHasBeenLaunched": "Парсинг запущен",
      "parsingInProgress": "Идет парсинг...",
      "transactionsFound": "Идет парсинг. Найдено транзакций: {{scraped}}",
      "parsingInQueued": "Добавлен в очередь",
      "lastTimeWasFound": "Поиск транзакций. В последний раз было найдено: {{scraped}}",
      "number": "Номер",
      "account": "Учетная запись",
      "applicationId": "ID приложения",
      "parsingOfAnotherWalletInProgress": "Идет парсинг другого кошелька...",
      "yaarpayMerchantId": "ID мерчанта",
      "yaarpayApplicationId": "ID приложения",
      "depositName": "Название депозита",
      "yaarpaySecretKey": "Секретный ключ",
      "yaarpayBankCode": "Код банка",
      "yaarpayIfscCode": "IFSC код",
      "yaarpayBranchCode": "Код отделения",
      "yaarpayAccountNo": "Номер аккаунта",
      "yaarpayAccountName": "Название аккаунта",
      "payOpPublicKey": "Открытый ключ",
      "payOpSecretKey": "Секретный ключ",
      "payOpProjectId": "ID проекта",
      "payOpToken": "Токен",
      "payKassaMerchantId": "ID мерчанта",
      "payKassaSecretKey": "Пароль мерчанта",
      "payKassaApiId": "API ID",
      "payKassaApiSecretKey": "Пароль API",
      "vietcomBankAccountNumber": "Номер счета",
      "vietcomBankAccountHolder": "Имя владельца счета",
      "vietcomBankBankName": "Название банка",
      "cashmaalPin": "PIN-код",
      "cashmaalIpnKey": "IPN ключ",
      "cashmaalPayoutSecretKey": "Секретный ключ выплат",
      "surePayBankCode": "Банковский код",
      "surePayDepositIban": "Депозит IBAN",
      "surePayAccountNumber": "Номер счета",
      "surePayMerchant": "ID мерчанта",
      "surePayApiKey": "Ключ API",
      "dusuPayApiKey": "Ключ API",
      "dusuPayToken": "Токен",
      "dusuPaySecretKey": "Секретный ключ",
      "payTecaClientId": "ID клиента",
      "payTecaClientSecret": "Код клиента",
      "pagsmileSecretKey": "Секретный ключ",
      "visibilityOfAllWallets": "Видимость всех кошельков",
      "enableVisibilityOfAllWallets": "Включить",
      "disableVisibilityOfAllWallets": "Отключить",
      "visibilityOfWalletsHaveBeenEnabled": "Видимость кошельков была включена",
      "visibilityOfWalletsHaveBeenDisabled": "Видимость кошельков была выключена",
      "maybankAccountNumber": "Номер счета",
      "maybankUsername": "Логин на сайте",
      "maybankPhrase": "Кодовое слово",
      "accountNumber": "Номер счета",
      "accountHolder": "Владелец счета",
      "secretKey": "Секретный ключ",
      "accounts": "Счета",
      "accountsBalance": "Баланс счетов",
      "accountNo": "Номер аккаунта",
      "beneficiaryNickname": "Ник бенефициара",
      "deleteBeneficiariesInIciciAccount": "Удаление бенефициаров в аккаунте ICICI",
      "beforeTheTimeExpires": "до истечения времени",
      "untilTheLastBeneficiary": "до последнего бенефициара",
      "manualPayment": "Ручная платежка",
      "settlement": "Сеттлемент",
      "settlementReplenishmentAmountHint": "Сумма, которая будет списана с баланса счета",
      "settlementReplenishmentRateHint": "Курс обмена 1 y.e.",
      "settlementWriteOffAmountHint": "Сумма, которая будет списана с баланса сеттлемент",
      "settlementWriteOffRateHint": "Курс обмена криптовалюты",
      "operationsHistory": "История операций",
      "cu": "у.е.",
      "availableBalance": "Доступный баланс",
      "replenishment": "Пополнить",
      "writeOff": "Вывести",
      "dateTime": "Дата и время",
      "operation": "Операции",
      "amountInTransactionCurrency": "Сумма в валюте операции",
      "exchangeRate": "Курс",
      "replenishmentFrom": "Пополнение со счета",
      "writeOffOn": "Вывод на внешний счет клиента",
      "bitcoinAddress": "Bitcoin-адрес",
      "paymentSystem": "Платёжная система",
      "authError": "Ошибка авторизации",
      "localpayAccountName": "Имя получателя",
      "localpayAccountNumber": "Номер счета получателя",
      "localBankCode": "Код банка",
      "commission": "Комиссия",
      "addAtLeastOneParser": "Должен быть включен хотя бы один парсер",
      "emailSender": "Email отправителя",
      "iciciParser": "Парсер сайта банка ICICI",
      "axisParser": "Парсер сайта банка Axis",
      "nightParser": "Ночной парсер email",
      "parser": "Парсер",
      "indusIndParser": "Парсер сайта IndusInd",
      "freeChargeParser": "Парсер сайта Freecharge",
      "emailParser": "Парсер email",
      "siteLogin": "Логин на сайте",
      "sitePassword": "Пароль на сайте",
      "iciciWalletsConflict1": "Найдены кошельки, которые относятся к аккаунту ICICI c логином {{login}}, со следующими номерами:",
      "iciciWalletsConflict2": "Параметры прокси в указанных кошельках не совпадают с текущим кошельком и будут изменены.",
      "iciciWalletsConflict3": "Вы действительно хотите сохранить данный кошелек?",
      "idbWalletsConflict": "Найдены кошельки, которые относятся к аккаунту IDB c логином {{login}}, со следующими номерами:",
      "walletsProxyConflict": "Параметры прокси в указанных кошельках не совпадают с текущим кошельком и будут изменены.",
      "walletsConflictSaveMessage": "Вы действительно хотите сохранить данный кошелек?",
      "loginId": "Логин ID",
      "workingTime": "Рабочее время",
      "payzenApiKey": "Ключ API",
      "payzenPublicKey": "Открытый ключ",
      "paytrustApiKey": "Ключ API",
      "ePayApiKey": "Ключ API",
      "perfectMoneyId": "ID",
      "cashmaalWebID": "Web ID",
      "IMPSAccountName": "Имя владельца счета",
      "IMPSAccountNumber": "Номер счета",
      "IMPSIFSCcode": "IFSC код",
      "help2payMerchantCode": "Код мерчанта",
      "help2paySecurityCode": "Секретный код",
      "useProxy": "Использовать прокси",
      "codePhrase": "Кодовые фразы",
      "question": "Вопрос",
      "answer": "Ответ",
      "addCodePhrases": "Добавьте 3 кодовые фразы",
      "address": "Адрес",
      "accountHolderNickname": "Ник владельца счета",
      "accountHolderName": "Имя владельца счета",
      "localCryptoAccount": "Аккаунт",
      "pendingSmsConfirmation": "Ожидает СМС подтверждение",
      "emailAccount": "Аккаунт",
      "transactionStatus": "Статус транзакций",
      "dateTransactionCreation": "Дата создания транзакции",
      "dateTransactionActivation": "Дата активации транзакции",
      "integrationError": "Ошибка интеграции",
      "banned": "Забанен",
      "upiHbIdentifier": "Номер",
      "upiHbUsername": "Логин на сайте",
      "upiHbPassword": "Пароль на сайте",
      "upiHbAccountNumber": "Номер счета",
      "commissionWithSuffix": "Комиссия %",
      "addressUpi": "Адрес UPI",
      "addOneUpiAddress": "Добавьте хотя бы один адрес UPI",
      "IMPSHBIFSCcode": "IFSC код",
      "impsHbIdentifier": "Номер",
      "deleteData": "Удалить неактивные куки",
      "deleteDataHint": "Удаление всех неактивных куки кошелька",
      "deletingInactiveCookies": "Удаление неактивных куки",
      "doYouReallyWantToRemoveThisWalletCookies": "Куки будут удалены без возможности восстановления. Вы действительно хотите удалить куки для данного кошелька?",
      "successfullyDeletedCookies": "Успешно удалено {{count}} куки",
      "noCookiesToDelete": "Куки для удаления отсутствуют",
      "failedToDeleteCookies": "Не удалось удалить куки",
      "automaticSmsInput": "Автоматический ввод SMS",
      "port": "Порт",
      "apiKey": "Ключ API",
      "dailyLimitOnAmount": "Суточный лимит по сумме",
      "priority": "Приоритет кошелька",
      "addInfo": "Доп. сведения",
      "appParser": "Парсер приложения",
      "siteParser": "Парсер сайта",
      "archivedWallets": "Архивные кошельки",
      "walletArchiving": "Архивирование кошелька",
      "doYouReallyWantToDeleteThisWallet": "Вы действительно хотите удалить кошелек?\r\nДанное действие необратимо, кошелек будет удален навсегда.",
      "doYouReallyWantToArchiveThisWallet": "Вы действительно хотите архивировать кошелек?",
      "walletRecovery": "Восстановление кошелька",
      "doYouReallyWantToRecoverThisWallet": "Вы действительно хотите восстановить кошелек?",
      "wallet_type": "Тип кошелька",
      "walletHasBeenSuccessfullyDeleted": "Кошелек успешно удален",
      "walletHasBeenSuccessfullyRemoved": "Кошелек успешко архивирован",
      "walletHasBeenSuccessfullyRestored": "Кошелек успешно восстановлен",
      "notSpecified": "Не указан",
      "vendorCode": "Код поставщика",
      "card": "Карта",
      "proxiesHint": "Кошельки данного типа работают только при наличии прокси",
      "laykapaySecretKey": "Секретный ключ",
      "cookie": "Куки",
      "allowedBalanceExceeded": "Баланс превышен",
      "noCookies": "Куки нет",
      "vpaAddress": "Виртуальный платежный адрес",
      "merchantKey": "Ключ мерчанта",
      "hash": "Хэш",
      "uzcardApiCodePhrase": "Кодовое слово",
      "isPersonalType": "Персональный тип",
      "merchantPrvateKey": "Секретный ключ мерчанта",
      "nagadPublicKey": "Открытый ключ Nagad",
      "accountReplenishment": "Ввод",
      "accountWriteOff": "Вывод",
      "mpesaShortCode": "Короткий код",
      "mpesaConsumerKey": "Потребительский ключ",
      "mpesaConsumerSecret": "Потребительский\nсекретный ключ",
      "accountType": "Тип аккаунта ",
      "agentAccount": "Агент ",
      "personalAccount": "Персональный ",
      "merchantAccount": "Мерчант",
      "phraseCode": "Кодовое слово",
      "dataAccessError": "Ошибка доступа к данным",
      "PKConnectionError": "Ошибка соединения с PK",
      "parserError": "Ошибка парсера",
      "dailyLimitExceeded": "Суточный лимит по сумме превышен",
      "passKey": "Ключ доступа",
      "mpesaPassKey": "Ключ доступа",
      "provider": "Провайдер",
      "numberOfCard": "Номер карты",
      "hdfcParser": "Парсер сайта HDFC",
      "indusinParser": "Парсер сайта IndusInd",
      "mobileApiParser": "PhonePe Mobile API",
      "mnc": "MNC",
      "operatorName": "Название оператора сети",
      "androidId": "Android ID",
      "androidBoard": "Android board",
      "androidHardware": "Аппаратное обеспечение",
      "androidSerial": "Серийный номер устройства",
      "androidManufacturer": "Производитель устройства",
      "androidVersion": "Версия Android API",
      "wifiMac": "MAC-адрес WiFi",
      "wifiSsid": "WiFi SSID",
      "wifiFrequency": "Частота WiFi (MHz)",
      "latitude": "Широта (в градусах)",
      "longitude": "Долгота (в градусах)",
      "parsingObject": "Объект парсинга",
      "webpage": "Веб-страница",
      "statement": "Выписка",
      "smsSource": "Способ получения СМС",
      "smsBox": "СМС бокс",
      "manual": "Ручной ввод",
      "visibleWorkingAll": "Видимые/Рабочие/Все",
      "balanceOfVisibleMax": "Текущий/макс.  баланс видимых",
      "turnoverOfVisibleMax": "Текущий/макс. оборот видимых",
      "balanceOfAllMax": "Текущий/макс. баланс всех",
      "turnoverOfAllMax": "Текущий/макс. оборот всех",
      "turnoverDailyTurnover": "Макс. дневной оборот",
      "walletsStatisticModal": "Оборот по кошелькам за 7 дней",
      "date": "Дата",
      "dailyTurnover": "Дневной оборот",
      "copyToClipboard": "Скопировать все",
      "walletStatistic": "Статистика кошельков",
      "walletStatisticTurnoverTip": "Максимальный дневной оборот за 7 дней по всем кошелькам",
      "tooltipStatistic": "Максимальный дневной оборот за 7 дней по всем кошелькам",
      "callbackSource": "Получение callback",
      "directlyFromPS": "Напрямую от ПС",
      "viaServiceGateway": "Через сервис-шлюз",
      "callbackUrl": "Callback URL",
      "priorityRaw": "Приоритет",
      "highPriority": "Высокий",
      "abovePriority": "Повышенный",
      "normalPriority": "Обычный",
      "belowPriority": "Пониженный",
      "lowPriority": "Низкий",
      "parserApi": "Mobile API",
      "incorrectQR": "Некорректный QR",
      "dailyTransactionLimit": "Суточный лимит по количеству транзакций",
      "dailyTransactionLimitExceeded": "Суточный лимит по транзакциям превышен",
      "archivingDate": "Дата архивации",
      "storeID": "ID магазина   ",
      "integrationType": "Тип Интеграции",
      "monthlyTurnoverLimitExceeded": "Месячный лимит по сумме превышен",
      "dailyTurnoverLimitExceeded": "Суточный лимит по сумме превышен",
      "federalBankParser": "Парсер сайта Federal bank",
      "phoneNumber": "Номер телефона",
      "webhookID": "Webhook ID",
      "displayIdentifier": "Отображаемый реквизит платежа",
      "identifierRequisites": "Реквизит платежа"
    },
    "paymentConstructor": {
      "minimumAmount": "Минимальная сумма",
      "minStockpilingAmount": "Минимальная сумма накопления",
      "showOnPlugin": "Показать на плагине",
      "copyLink": "Скопировать ссылку",
      "currencies": "Валюты",
      "paymentSystems": "Платежные системы",
      "currency": "Валюта",
      "appliedPaymentSystems": "Привязанные платежные системы",
      "sortingType": "Тип сортировки",
      "paymentSystemsSorting": "Сортировка платежных систем",
      "defaultSorting": "Сортировка по умолчанию",
      "constructor": "Конструктор",
      "paymentSystem": "Платежная система",
      "paymentSystemsCurrencies": "Валюты платежной системы",
      "custom": "Пользовательская",
      "byDefault": "По умолчанию",
      "resetOrder": "Сбросить порядок",
      "orderPrioritet": "Первыми выводятся",
      "orderPrioritetTestPS": "Тестовые ПС",
      "orderPrioritetRegularPS": "Обычные ПС",
      "testPSPrioritet": "Тестовые ПС",
      "testPSNewFirst": "Сначала новые",
      "testPSOldFirst": "Сначала старые",
      "regularPSPrioritet": "Обычные ПС",
      "regularPSByDefault": "По умолчанию",
      "regularPSPrioritetUniqueFirst": "Сначала уникальные",
      "regularPSPrioritetNotUniqueFirst": "Сначала не уникальные",
      "appliedCurrencies": "Привязанные валюты",
      "PSDataHasBeenUpdated": "Данные о платежках были успешно обновлены",
      "sortingDataHasBeenUpdated": "Данные о сортировке успешно обновлены",
      "currencyDataHasBeenUpdated": "Данные о валютах были успешно обновлены",
      "6in1": "6 в 1",
      "logos": "Логотипы",
      "logo": "Логотип",
      "defaultLogo": "Логотип по умолчанию",
      "logosHaveBeenSuccessfullyUpdated": "Логотипы были успешно обновлены",
      "resetAllSettings": "Сбросить все настройки",
      "cancelLastChanges": "Отменить последние изменения",
      "sortingTemplate": "Шаблон сортировки",
      "sortingTemplateHasBeenSuccessfullyUpdated": "Шаблон сортировки был успешно обновлен",
      "customOrder": "Шаблон сортировки",
      "customOrderDisabled": "Не использовать",
      "customOrderEnabled": "Использовать",
      "3in1": "3 в 1",
      "minDeposits": "Минимальные депозиты",
      "extraMinDeposits": "Дополнительные минимальные депозиты",
      "minDepositsHaveBeenSuccessfullyUpdated": "Минимальные депозиты были успешно обновлены",
      "paymentCurrencies": "Валюты",
      "paymentConstructor": "Конструктор платежных систем",
      "targetingLogo": "Таргетированный логотип",
      "targetingMinDeposit": "Таргетированный минимальный депозит",
      "defaultMinDeposit": "Минимальный депозит по умолчанию",
      "paymentSystemTitle": "Название",
      "paymentSystemDefaultTitle": "По умолчанию",
      "paymentTitlesHaveBeenSuccessfullyUpdated": "Названия платежных систем были успешно обновлены",
      "paymentSystemTitleForCurrencies": "Названия для валют",
      "checkMinDeposit": "Проверьте минимальный депозит",
      "viewType": "Вид отображения",
      "depositsHaveBeenSuccessfullyUpdated": "Депозиты были успешно обновлены",
      "deposits": "Депозиты",
      "targeting": "Таргетинг",
      "minDeposit": "Минимальный депозит",
      "maxDeposit": "Максимальный депозит",
      "name": "Название",
      "paymentDataHasBeenSuccessfullyUpdated": "Данные о платежке были успешно обновлены",
      "display": "Отображать вид на плагине",
      "paymentHints": "Подсказки",
      "paymentHintList": "Список подсказок",
      "hintType": "Тип подсказки",
      "payment_hint": "Подтверждение депозита",
      "requisites_hint": "Внесение депозита",
      "addHint": "Добавить подсказку",
      "translation": "Перевод",
      "hintText": "Текст подсказки",
      "lang": "Язык",
      "translations": "Переводы",
      "addAtLeastOneTranslation": "Добавьте хотя бы один перевод",
      "limitHint": "Подсказка по лимитам",
      "hintCreating": "Добавление подсказки",
      "hintDeleting": "Удаление подсказки",
      "doYouReallyWantToRemoveThisHint": "Вы действительно хотите удалить данную подсказку?",
      "hintEditing": "Редактирование подсказки",
      "tariffs": "Тарифы",
      "fixedForReceiving": "Фикс за прием",
      "percentForReceiving": "Процент за прием, %",
      "fixedForSending": "Фикс за отправку",
      "percentForSending": "Процент за отправку, %",
      "tariffsNotSpecified": "Тарифы для данной платежной системы не заданы",
      "manualPSPrioritet": "Ручные ПС",
      "2in1": "2 в 1",
      "hintView": "Просмотр подсказки",
      "depositEnter": "Внесение депозита",
      "depositConfirm": "Подтверждение депозита",
      "fillOneField": "Необходимо заполнить хотя бы одно поле",
      "displayOnPlugin": "Отображать на плагине",
      "standardPaymentSystem": "Стандартная платёжная система",
      "limitForGettingStatusStandard": "Порог получения статуса \"Стандартный\" (USD)",
      "statusHasBeenUpdated": "Статус был обновлен",
      "displayedOnPlugin": "Отображается на плагине",
      "transactionLastDateOnPlugin": "Дата последней активации транзакции на плагине",
      "settings": "Настройки",
      "workingTime": "Режим работы",
      "aroundTheClock": "Круглосуточно",
      "timeRange": "Временной диапазон",
      "timezone": "Часовой пояс",
      "timeEqual24HoursError": "Включите чекбокс \"Круглосуточно\" или установите время, не равное 24 часам",
      "walletUsesSelenium": "Данный кошелёк использует автоматизацию через selenium - его включение увеличит нагрузку на систему",
      "usePossibleTransactions": "Возможные транзакции",
      "usePossibleTransactionsDescription": "Если возникнет ошибка при активации транзакции на плагине, автоматически будет создана возможная транзакция.",
      "plugin": "Плагин",
      "currencyCode": "Код валюты",
      "level": "Уровень",
      "customTransactionId": "Пользовательский id транзакции",
      "fixedAmount": "Фиксированное количество",
      "paymentUrlSuccessId": "id успешного URL-адреса платежа",
      "paymentUrlFailId": "id ошибки URL платежа",
      "pluginError": "Плагин не может быть отображен, по причине отстуствия env переменной REACT_APP_PAYMENT_URL",
      "pluginNoURL": "Для отображения плагина, введите данные и нажмите Применить",
      "limits": "Лимиты",
      "minWithdrawal": "Минимальный вывод",
      "maxWithdrawal": "Максимальный вывод",
      "paymentTranslations": "Переводы",
      "addPaymentTranslations": "Добавить переводы",
      "paymentMaking": "Совершение оплаты",
      "paymentConfirmation": "Подтверждение оплаты",
      "fields": "Поля",
      "formElement": "Элемент формы",
      "form": "Форма",
      "hint": "Подсказка",
      "field": "Поля",
      "createTranslation": "Добавление перевода",
      "formType": "Тип формы",
      "general": "Общие",
      "paymentMakingForm": "Форма совершения оплаты",
      "paymentConfirmationForm": "Форма подтверждения оплаты",
      "addTariff": "Добавить тариф",
      "paymentTranslationList": "Список переводов",
      "translationView": "Просмотр перевода",
      "translationType": "Тип перевода",
      "translationEditing": "Редактирование перевода",
      "translationDeleting": "Удаление перевода",
      "doYouReallyWantToRemoveThisTranslation": "Вы действительно хотите удалить данный перевод?",
      "tariffPlanSettings": "Настройки тарифного плана",
      "incomingTransactionCommission": "Комиссия за входящую транзакцию",
      "outgoingTransactionCommission": "Комиссия за исходящую транзакцию",
      "amountUpTo": "Сумма до",
      "amountFrom": "Сумма от",
      "percent": "Процент, %",
      "addCondition": "Добавить условие",
      "fixAmount": "Сумма фикса",
      "percentageTransactionAmount": "Процент от суммы транзакции",
      "commissionPercentageIsNotSet": "Процент комиссии от суммы транзакции не задан",
      "proxies": "Прокси",
      "useProxyNightEmailParser": "Использовать прокси ночного парсера email",
      "allowUpdateRequisites": "Разрешить обновление реквизитов",
      "otpForm": "Форма ввода OTP",
      "phoneForm": "Форма ввода номера",
      "notifications": "Уведомления",
      "addManualPS": "Добавить ручную ПС",
      "creatingAManualPS": "Создание ручной ПС",
      "nameInEnglish": "Название на английском. Пример: UPI",
      "codenameInEnglish": "Название на английском, маленькие буквы, без пробелов Пример: upi_ib",
      "codename": "Кодовое название",
      "requisites": "Реквизиты",
      "requisiteName": "Наименование реквизита ",
      "requestTransactionId": "Запрашивать transaction_id",
      "requestReceipt": "Запрашивать чек оплаты",
      "charNumValidation": "Валидация на количество символов",
      "animationHint": "Анимационная подсказка",
      "confirmationScreen": "Скриншот для подтверждения оплаты",
      "requisiteNameInEnglish": "Наименование реквизита на английском",
      "deletePaymentSystem": "Удаление платежной системы",
      "areYouSureWantToDeleteManualPS": "Вы действительно хотите удалить данную платежную систему?"
    },
    "rbac": {
      "name": "Название",
      "description": "Описание",
      "rights": "Права",
      "addRole": "Добавить роль",
      "roleDeleting": "Удаление роли",
      "roleEditing": "Редактирование роли",
      "roleCreating": "Создание роли",
      "rbac": "Администрирование",
      "roleList": "Список ролей",
      "roles": "Роли",
      "users": "Пользователи",
      "identifier": "Идентификатор",
      "showRights": "Показать права",
      "userList": "Список пользователей",
      "login": "Логин",
      "createdAt": "Создан",
      "lastUpdate": "Последнее редактирование",
      "addUser": "Добавить пользователя",
      "userDeleting": "Удаление пользователя",
      "userEditing": "Редактирование пользователя",
      "userCreating": "Создание пользователя",
      "showRoles": "Показать роли",
      "email": "Почта",
      "password": "Пароль",
      "noRoles": "Нет ролей",
      "test": "тест2"
    },
    "widgets": {
      "listDataFetchingHasBeenFailed": "При загрузке списка произошла ошибка.",
      "itemFetchingHasBeenFailed": "При загрузке данных произошла ошибка.",
      "itemHasBeenSuccessfullyCreated": "Запись была успешно создана",
      "itemHasBeenSuccessfullyUpdated": "Запись была успешно обновлена",
      "itemHasBeenSuccessfullyDeleted": "Запись была успешно удалена",
      "itemCreatingHasBeenFailed": "При создании записи произошла ошибка.",
      "itemUpdatingHasBeenFailed": "При обновлении записи произошла ошибка.",
      "itemDeletingHasBeenFailed": "При удалении записи произошла ошибка.",
      "doYouReallyWantToDeleteThisItem": "Вы действительно хотите удалить запись?",
      "listIsEmpty": "Список пуст",
      "noRights": "Нет прав",
      "showThePassword": "Показать пароль",
      "hideThePassword": "Скрыть пароль",
      "goTo": "Перейти",
      "linesPerPage": "Строк на странице",
      "goToPage": "Перейти на страницу",
      "displayCells": "Показать",
      "dataOnRequestedPageIsNotAvailable": "К сожалению, записи на данной странице не существует.",
      "filesAlreadyExist": "Эти файлы уже имеются в таблице",
      "itemsHaveBeenSuccessfullyDeleted": "Записи были успешно удалены",
      "someOfTheItemsHaveNotBeenDeleted": "Часть записей не была удалена"
    },
    "common": {
      "day": "День",
      "week": "Неделя",
      "month": "Месяц",
      "quarter": "Квартал",
      "year": "Год",
      "update": "Обновить",
      "currency": "Валюта",
      "proxy": "Прокси",
      "proxies": "Прокси",
      "disable": "Отключить",
      "confirm": "Подтвердить",
      "create": "Создать",
      "status": "Статус",
      "actions": "Действия",
      "next": "Далее",
      "back": "Назад",
      "reset": "Сбросить",
      "cancel": "Отмена",
      "success": "Успех",
      "error": "Ошибка",
      "type": "Тип",
      "delete": "Удалить",
      "label": "Лейбл",
      "orderId": "Номер заказа",
      "labelOrderId": "Лейбл/ Номер заказа",
      "select": "Выбрать",
      "amount": "Сумма",
      "paymentSystem": "Платёжная система",
      "dateFrom": "С",
      "dateTo": "По",
      "filterSubmit": "Поиск",
      "date": "Дата",
      "createdAt": "Дата создания",
      "createAndThenCreateAnotherOne": "Создать и создать еще одну",
      "possibleTransactionList": "Список возможных транзакций",
      "activate": "Активировать",
      "copy": "Скопировать",
      "save": "Сохранить",
      "period": "Период",
      "quantity": "Количество",
      "edit": "Редактировать",
      "add": "Добавить",
      "noRights": "Нет прав",
      "upload": "Загрузить",
      "data": "Данные",
      "all": "Все",
      "search": "Поиск",
      "attention": "Внимание",
      "gotIt": "Понятно",
      "country": "Страна",
      "sum": "Сумма",
      "approve": "Принять",
      "reject": "Отклонить",
      "comment": "Комментарий",
      "yes": "Да",
      "no": "Нет",
      "listIsEmpty": "Список пуст",
      "filterReturnedNoResults": "По фильтру ничего не найдено",
      "capsLockIsOn": "Включен Caps lock",
      "warning": "Предупреждение",
      "close": "Завершить",
      "absent": "Отсутствует",
      "ID": "ID",
      "api": "API",
      "retry": "Повторить",
      "send": "Отправить",
      "saveFile": "Сохранить файл",
      "updatePlugin": "Обновить Плагин",
      "displayIn": "Отображать в",
      "generateBy": "Формировать по",
      "postbackMessage": "Сообщение постбека",
      "low": "Низкий",
      "normal": "Обычный",
      "high": "Высокий",
      "archive": "Архивировать",
      "recover": "Восстановить",
      "format": "Формат",
      "restore": "Восстановить",
      "archivedAt": "Дата архивации",
      "activatedAt": "Дата активации",
      "mark": "Отметить",
      "apply": "Применить",
      "chooseLanguage": "Необходимо выбрать язык",
      "chooseTranslate": "Необходимо указать перевод",
      "form": "Форма",
      "unsaveChanges": "Есть не сохраненные изменения. Закройте окно и сохраните перевод",
      "closeWindow": "Закрыть окно",
      "translatesHints": "Переводы: подсказки",
      "translatesPackages": "Переводы: поля",
      "defaultEng": "Английский по умолчанию",
      "confirmDeleteTranslation": "Вы действительно хотите удалить данный перевод?",
      "translationDelete": "Удаление перевода",
      "engIsRequired": "Английский язык обязателен",
      "successUpdate": "Текстовые подсказки успешно обновлены",
      "errorUpdate": "При обновлении текстовых подсказок возникла ошибка",
      "successCreation": "Текстовая подсказка успешно создана",
      "errorCreation": "При создании текстовой подсказки произошла ошибка",
      "belowNormal": "Пониженный",
      "aboveNormal": "Повышенный",
      "balance": "Баланс",
      "completed": "Завершено",
      "notCompleted": "Не завершено",
      "periodCreation": "Период создания",
      "periodUpdating": "Период обновления"
    },
    "proxies": {
      "index": "Индекс",
      "walletsID": "ID кошельков",
      "proxyDeleting": "Удаление прокси",
      "port": "Порт",
      "username": "Логин",
      "password": "Пароль",
      "proxyUpdating": "Редактирование прокси",
      "proxyCreating": "Создание прокси",
      "startCheckingAllProxies": "Запустить проверку прокси",
      "addProxy": "Добавить прокси",
      "proxies": "Прокси",
      "proxyList": "Список прокси",
      "notWorking": "Не работает",
      "working": "Работает",
      "ipPort": "ip:port",
      "noActiveProxies": "Нет активных прокси",
      "doesNotWork": "Не работает",
      "check": "Проверить",
      "loadByList": "Загрузить списком",
      "pasteProxyList": "Вставьте список прокси",
      "checkList": "Проверить список",
      "noValidProxies": "Добавьте хотя бы один рабочий прокси.",
      "verifiedProxies": "Проверено прокси: {{verified}}",
      "ipHost": "IP | Хост",
      "invalidRows": "Список неразобранных строк",
      "proxiesAdding": "Добавление прокси",
      "checking": "Идет проверка",
      "existsInSystem": "Существует в системе",
      "addOnlyNew": "Добавить только новые",
      "addNewAndUpdateExisted": "Добавить новые и обновить существующие",
      "proxyConnectionWithPaytm": "Связь прокси с Paytm",
      "download": "Выгрузить",
      "upload": "Загрузить",
      "saveSucceededRelations": "Сохранять рабочие связи",
      "paytmProxiesRelationsUploading": "Загрузка списка связи кошельков Paytm c прокси",
      "insertListOfRelationsOfPaytmWithProxies": "Вставьте список связи кошельков PayTM c прокси",
      "invalidDataOfPaytmProxiesRelations": "Список строк с отсутствующими в системе кошельками/прокси",
      "invalidFormatOfPaytmProxiesRelations": "Список неразобранных строк файла",
      "relationsHaveBeenAdded": "Связи успешно добавлены",
      "paytmProxiesRowsMustBeRight": "Внимание, строки должны соответствовать шаблону: ID_кошелька Номер_кошелька IP:Порт:Логин:Пароль",
      "ip": "IP",
      "proxiesRelationsUploading": "Загрузка списка связи кошельков c прокси",
      "insertListOfRelationsWithProxies": "Вставьте список связи кошельков c прокси ",
      "proxyConnection": "Связь прокси",
      "stringFormat": "Формат строки - IP:Порт:Логин:Пароль:Тип:Код_страны",
      "undefined": "Не определена",
      "proxyInsertFormat": "Вставьте список связи кошельков с прокси в формате:\nID_кошелька Номер_кошелька IP:Порт:Логин:Пароль",
      "downloadExample": "Скачать пример",
      "login": "ЛОГИН",
      "wantDeleteSelectedProxies": "Вы действительно хотите удалить выбранные прокси?",
      "proxyListCheckFailed": "Список прокси не прошел проверку, попробуйте заново",
      "dateOfChecking": "Дата проверки"
    }
  }
};

export default ru;
