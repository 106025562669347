import { AxiosPromise } from 'axios';
import { ILoginFormData } from '@kassma-team/kassma-toolkit';

import { IExchangeTokenData, IRefreshTokenData } from 'interfaces/auth';
import { BaseApi } from 'api/common/BaseApi';

export class AuthApi extends BaseApi {
  exchange = (data: IExchangeTokenData): AxiosPromise =>
    this.makeFetch(`exchange`, { method: `POST`, data, withToken: false });

  refreshToken = (data: IRefreshTokenData): AxiosPromise => this.makeFetch(`refresh`, { method: `POST`, data });

  refreshExchangeToken = (data: IRefreshTokenData): AxiosPromise =>
    this.makeFetch(`refresh-exchange`, { method: `POST`, data });

  logout = (refresh_token: string): AxiosPromise =>
    this.makeFetch(`logout`, { method: `POST`, data: { refresh_token } });

  login = (data: ILoginFormData): AxiosPromise =>
    this.makeFetch(`login`, { data, withToken: false, method: `POST`, needRefresh: false });
}

const authApi = new AuthApi(`auth`);

export default authApi;
