import React, { ReactNode } from 'react';
import map from 'lodash/map';
import toString from 'lodash/toString';
import { useTranslation } from 'react-i18next';

export interface ISelectData {
  value: string | number;
  text: string;
  component?: ReactNode;
}

export interface IRadioButtonsProps {
  onChange: (val: string | number) => void;
  name: string;
  isLabelFullWidth?: boolean;

  selected?: string | number;
  data?: ISelectData[];
}

const RadioButtons = ({ data, selected, onChange, name, isLabelFullWidth }: IRadioButtonsProps) => {
  const [t] = useTranslation();

  return (
    <>
      {map(data, ({ value, text, component }) => {
        const id = `${name}_${value}_input`;

        return (
          <div className="custom-control custom-radio mb-5" key={value} id={`${name}_${value}_radio_wrap`}>
            <input
              type="radio"
              className="custom-control-input"
              value={value}
              name={name}
              id={id}
              checked={toString(selected) === toString(value)}
              onChange={(e) => onChange(e.target.value)}
            />
            <label
              className={`custom-control-label`}
              style={isLabelFullWidth ? { width: `100%` } : {}}
              id={`${name}_${value}_input_label`}
              htmlFor={id}
            >
              {component ? component : t(text)}
            </label>
          </div>
        );
      })}
    </>
  );
};

export default RadioButtons;
