import { CustomTranslate } from 'pages/constructor/DefaultTranslates/common/types';

/**
 * getDuplicatesNames
 *
 * Возвращает массив имен,
 * которые встречаются более 2 раз в массиве переводов
 *
 */
const getDuplicatesNames = (translations: Array<CustomTranslate>): Array<string> => {
  return translations.map((el) => el.name).filter((el, i, namesArr) => namesArr.indexOf(el) !== i);
};

/**
 * getCustomTranslatesBy
 */
export const getCustomTranslatesBy = (translations: Array<CustomTranslate>, by: `unique` | `dublication`) => {
  const dubplicateNames = getDuplicatesNames(translations);

  const packagesWithDublicates = translations.filter((el) => dubplicateNames.includes(el.name));

  const uniqNames = translations.filter((ps) => !packagesWithDublicates.find((el) => el.name === ps.name));

  return by === `unique` ? uniqNames : packagesWithDublicates;
};
