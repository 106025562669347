import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import { ISelectData } from '@kassma-team/kassma-toolkit';

import useFullAmount from 'hooks/useFullAmount';

import './customTooltip.scss';

interface ISumValueProps {
  amount: string | number;
  currencyCode?: string;
}

interface IProps {
  active?: boolean;
  payload?: any[];
  label?: string;
  isSum: boolean;
  testPayments: ISelectData[];
}

const SumValue = ({ amount, currencyCode = `` }: ISumValueProps) => {
  const fullAmount = useFullAmount({
    amount,
    currencyCode,
    showSymbol: false,
  });

  return <span>{fullAmount}</span>;
};

const CustomTooltip = ({ active, payload = [], label, testPayments, isSum = false }: IProps) => {
  if (isEmpty(payload)) {
    return null;
  }

  if (active) {
    const showTwoColumns = payload.length > 7;
    const sortedPayload = payload.sort(function (a, b) {
      if (a.value === b.value) return 0;

      return b.value - a.value;
    });

    return (
      <div className="custom-tooltip-wrapper">
        <p>{label}</p>
        <ul className={classnames(`custom-tooltip-list`, { 'two-columns': showTwoColumns })}>
          {map(sortedPayload, ({ color, name, value, payload }) => {
            const currencies = get(payload, `${name}.currencies`);
            const hasCurrencies = !isEmpty(currencies);
            const testPaymentName = get(find(testPayments, { value: name }), `text`);

            return (
              <li key={`${name}`}>
                <ul className="custom-tooltip-list-item">
                  <li key={`${name}`} style={{ color }}>
                    <span>{testPaymentName || name}</span>
                    <span> : </span>
                    {isSum ? <SumValue amount={value} /> : <span>{value}</span>}
                  </li>
                  {hasCurrencies &&
                    map(currencies, (currency, key) => (
                      <li key={`${name}${key}${currency}`} className="item-currency" style={{ color }}>
                        <span>{key}</span>
                        <span> : </span>
                        <SumValue amount={currency} currencyCode={key} />
                      </li>
                    ))}
                </ul>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
