import React from 'react';

import { WidgetType } from 'utils/enums';
import { walletStatisticsActionCreators } from 'actions/widgets/wallet';

import { useDispatch, useSelector } from 'react-redux';
import { IWidgetModal, ModalElement, widgetListSelector, widgetModalSelector } from '@kassma-team/kassma-toolkit';
import { WalletStatistic } from 'api/wallets/WalletsApi';
import { formatDateWithoutTime } from 'utils/formatDate';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../../utils';

function copyToClipboard(text: string) {
  const textArea = document.createElement(`textarea`);

  textArea.value = text;
  document.body.appendChild(textArea);

  textArea.select();
  document.execCommand(`copy`);

  document.body.removeChild(textArea);
}

function getMaxValue(data: WalletStatistic[`all_daily_turnover`]): number | null {
  if (data.length === 0) {
    return null;
  }

  const maxValue = data.reduce((max, obj) => (obj.value > max ? obj.value : max), data[0].value);

  return maxValue;
}

const StatisticModal = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const modal: IWidgetModal = useSelector(widgetModalSelector(WidgetType.WALLETS_STATISTIC));
  const items: Array<WalletStatistic> = useSelector(widgetListSelector(WidgetType.WALLETS_STATISTIC));
  const founded = items.find((el) => el.wallet_type_name === modal?.walletTypeName);

  const maxValue = founded ? getMaxValue(founded?.all_daily_turnover) : null;

  return (
    <ModalElement
      title="wallets.walletsStatisticModal"
      actionCreators={walletStatisticsActionCreators}
      dispatch={dispatch}
      content={
        <div>
          <table className="table table-striped table-borderless table-vcenter wallet-item__table">
            <thead>
              <tr>
                <td>{t(`wallets.date`)}</td>
                <td>{t(`wallets.dailyTurnover`)}</td>
              </tr>
            </thead>
            <tbody>
              {founded?.all_daily_turnover?.map(({ date, value }) => {
                return (
                  <tr key={date}>
                    <td>{formatDateWithoutTime(date)}</td>
                    {` `}
                    <td>
                      {maxValue && maxValue === value ? <span className="font-size-xs">max {` `}</span> : null}
                      <span className="font-w600">{formatNumber(value)}</span> {founded?.currency}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="d-flex justify-content-center mt-20">
            <button
              className="btn btn-secondary"
              onClick={() =>
                copyToClipboard(
                  founded?.all_daily_turnover
                    ?.map(({ date, value }) => `${date} ${value} ${founded?.currency}`)
                    ?.join(`\n`) || ``
                )
              }
            >
              {t(`wallets.copyToClipboard`)}
            </button>
          </div>
        </div>
      }
    />
  );
};

export default StatisticModal;
