import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import {
  IApproveWithdrawalFrom,
  IDownloadWithdrawalForm,
  IWithdrawalListFilterForm,
} from 'interfaces/widgets/withdrawals';

interface IBankCodeData {
  walletType: string;
  currency_code: string;
}

interface IBranchCodeData {
  currency: number;
  bank_code: number;
}

interface IMethodData {
  currency_code: string;
}

interface IProviderData {
  paymentsMethod: string;
  currency_code: string;
}

export class WithdrawalApi extends BaseApi {
  approveWithdrawal = (id: number, data: IApproveWithdrawalFrom): AxiosPromise =>
    this.makeFetch(`approve/{id}`, { data, method: `POST` }, { id });

  downloadManualWithdrawals = (data: IDownloadWithdrawalForm): AxiosPromise =>
    this.makeFetch(`report`, { data, method: `POST`, responseType: `blob` });

  loadApprovalOfWithdrawalData = (id: number): AxiosPromise => this.makeFetch(`wallet-sender/{id}`, {}, { id });

  loadBankCodeList = ({ walletType, currency_code }: IBankCodeData): AxiosPromise =>
    this.makeFetch(`bank-codes/get`, { queryParams: { type: walletType, currency: currency_code } });

  loadBranchCodeList = ({ currency, bank_code }: IBranchCodeData): AxiosPromise =>
    this.makeFetch(`branch-codes/get`, { queryParams: { bank_code, currency } });

  loadCardList = (withdrawalId: number): AxiosPromise =>
    this.makeFetch(`get-cards/{withdrawalId}`, {}, { withdrawalId });

  loadPaygigaAvailableAmountList = (bankCode: string): AxiosPromise =>
    this.makeFetch(`paygiga/get-available-amounts/{bankCode}`, {}, { bankCode });

  loadPaygigaBankCodeList = (): AxiosPromise => this.makeFetch(`paygiga/get-bank-list`);

  loadPayoutMethodList = ({ currency_code }: IMethodData): AxiosPromise =>
    this.makeFetch(`methods/get`, { queryParams: { currency: currency_code } });

  loadProviderList = ({ paymentsMethod, currency_code }: IProviderData): AxiosPromise =>
    this.makeFetch(`provider-id/get`, { queryParams: { type: paymentsMethod, currency: currency_code } });

  loadStatusList = (): AxiosPromise => this.makeFetch(`status`);

  loadWithdrawalItem = (id: number): AxiosPromise => this.makeFetch(`{id}`, {}, { id });

  loadWithdrawalList = (queryParams: IWithdrawalListFilterForm): AxiosPromise =>
    this.makeFetch(`list`, { queryParams });

  rejectWithdrawal = (id: number, data: Record<`comment`, string | undefined>): AxiosPromise =>
    this.makeFetch(
      `reject/{id}`,
      {
        data: {
          ...data,
        },
        method: `POST`,
      },
      { id }
    );

  updateWithdrawalComment = (id: number, data: Record<`comment`, string>): AxiosPromise =>
    this.makeFetch(`set-comment/{id}`, { data, method: `POST` }, { id });

  uploadManualWithdrawals = (data: Record<`report`, string>): AxiosPromise =>
    this.makeFetch(`import-report`, { data, method: `POST`, formData: true });
}

const withdrawalApi = new WithdrawalApi(`withdrawal`);

export default withdrawalApi;
