import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { DashboardChartEntityType, Permission } from 'utils/enums';
import {
  dashboardEntityTypeSelector,
  dashboardTransactionsSelector,
  dashboardWithdrawalsSelector,
} from 'selectors/dashboard';
import { changeDashboardEntityType } from 'actions/dashboard';

import TabList from 'components/dashboard/TabList';
import ChartPointsFilterForm from 'components/dashboard/ChartPointsFilterForm';
import ChartTab from './DashboardChartTab';
import { hasAccessSelector } from '../../selectors/auth';

const chartEntities = (hasWithdrawalViewAccess: boolean) => [
  { id: DashboardChartEntityType.TRANSACTION, title: `transactions.transactions` },
  ...(hasWithdrawalViewAccess ? [{ id: DashboardChartEntityType.WITHDRAWAL, title: `withdrawals.withdrawals` }] : []),
];

const DashboardChartWithFilter = (): JSX.Element => {
  const [t] = useTranslation();

  const entityType = useSelector(dashboardEntityTypeSelector);

  const dashboardTransactionsData = useSelector(dashboardTransactionsSelector);
  const dashboardWithdrawalsData = useSelector(dashboardWithdrawalsSelector);

  const showTransactions = entityType === DashboardChartEntityType.TRANSACTION;
  const showWithdrawals = entityType === DashboardChartEntityType.WITHDRAWAL;
  const withdrawalsViewPermission = useSelector(hasAccessSelector(Permission.WITHDRAWALS_VIEW));

  const dispatch = useDispatch();

  return (
    <>
      <TabList
        list={chartEntities(withdrawalsViewPermission)}
        activeTab={entityType}
        onChange={(type) => dispatch(changeDashboardEntityType(type))}
      />
      <div className="tab-content" id="nav-tabContent">
        <div className="block dashboard-tile">
          <div className="block-content block-content-full">
            <div className="dashboard-tab-wrapper">
              {showTransactions && <ChartTab chartData={dashboardTransactionsData} />}
              {showWithdrawals && <ChartTab chartData={dashboardWithdrawalsData} />}
            </div>
          </div>
        </div>
      </div>
      <div className="tab-content" id="nav-tabContent">
        <div className="block p-10 dashboard__chart-filters">
          <h2>{t(`dashboard.filters`)}</h2>
          <ChartPointsFilterForm />
        </div>
      </div>
    </>
  );
};

export default DashboardChartWithFilter;
