import { Reducer } from 'redux';
import map from 'lodash/map';
import { IWidgetsState } from '@kassma-team/kassma-toolkit/lib/types/widgets';

import { DISABLE_MANUAL_PS_SUCCEEDED } from 'actionTypes';

const actionTypes = new Set([DISABLE_MANUAL_PS_SUCCEEDED]);

const reducer: Reducer = (state: IWidgetsState, { type, payload }) => {
  switch (type) {
    case DISABLE_MANUAL_PS_SUCCEEDED:
      return {
        ...state,
        items: map(state.items, (item) => (payload === item.code ? { ...item, status: 0 } : item)),
      };
    default:
      return state;
  }
};

const manualPS = { actionTypes, reducer };

export default manualPS;
