import { TransactionField } from 'utils/enums';
import { TFunction } from 'react-i18next';

export const getListReportFieldList = (
  t: TFunction<`translation`>,
  commissionViewPermission: boolean,
  vendorCodePermission: boolean,
  archivedTransactionPermission: boolean
) => {
  return [
    {
      value: TransactionField.WALLET_IDENTIFIER,
      text: t(`wallets.walletIdentifier`),
    },
    {
      value: TransactionField.TRANSACTION_NUMBER,
      text: t(`transactions.transactionId`),
    },
    ...(vendorCodePermission
      ? [
          {
            value: TransactionField.VENDOR_CODE,
            text: t(`wallets.vendorCode`),
          },
        ]
      : []),
    {
      value: TransactionField.MERCHANT_ORDER_ID,
      text: t(`reports.orderId`),
    },
    {
      value: TransactionField.EXCHANGER_IDENTIFIER,
      text: t(`reports.counterparty`),
    },
    ...(commissionViewPermission
      ? [
          {
            value: TransactionField.COMMISSION,
            text: t(`reports.commission`),
          },
        ]
      : []),
    {
      value: TransactionField.DATE,
      text: t(`common.date`),
    },
    {
      value: TransactionField.ACTIVATION_DATE,
      text: t(`reports.activationData`),
    },
    {
      value: TransactionField.LABEL,
      text: t(`common.label`),
    },
    {
      value: TransactionField.STATUS,
      text: t(`common.status`),
    },
    {
      value: TransactionField.TYPE,
      text: t(`transactions.transactionType`),
    },
    {
      value: TransactionField.KIND_OF_TRANSACTION,
      text: t(`transactions.kindOfTransaction`),
    },
    ...(archivedTransactionPermission
      ? [
          {
            value: TransactionField.ARCHIVING_DATES,
            text: t(`reports.archivingDate`),
          },
        ]
      : []),
    {
      value: TransactionField.ORIGINALITY,
      text: t(`transactions.originality`),
    },
  ];
};
