import { AxiosPromise } from 'axios';

import { IChangePasswordData } from 'interfaces/auth';
import { BaseApi } from 'api/common/BaseApi';

export class UserApi extends BaseApi {
  changePassword = (data: IChangePasswordData): AxiosPromise =>
    this.makeFetch(`change-password`, { data, method: `POST` });

  checkAuth = (): AxiosPromise => this.makeFetch(`me`);
}

const userApi = new UserApi(`user`);

export default userApi;
