import { createWidgetAction, IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { LOAD_SETTLEMENT_COMMISSION, RESET_TARIFFS, TARIFFS_REQUESTED, UPDATE_TARIFFS_REQUESTED } from 'actionTypes';
import { ITariffsData } from 'interfaces/widgets/constructor/paymentsConstructor';
import { widgetActionCreators } from '@kassma-team/kassma-toolkit';
import { WidgetType } from '../../utils/enums';

export const updateTariffsRequest = (widget: string) => (
  walletType: string,
  data: ITariffsData[]
): IWidgetActionCreators =>
  createWidgetAction({
    widget,
    walletType,
  })(UPDATE_TARIFFS_REQUESTED)(data);

export const getTariffs = (widget: string): IWidgetActionCreators =>
  createWidgetAction({
    widget,
  })(TARIFFS_REQUESTED);

export const resetTariffs = (widget: string): IWidgetActionCreators =>
  createWidgetAction({
    widget,
  })(RESET_TARIFFS);

export const tariffsMeta = widgetActionCreators({
  widget: WidgetType.MANUAL_PAYMENT_SYSTEMS,
});

export const loadSettlementCommission: IWidgetActionCreators = createWidgetAction(tariffsMeta)(
  LOAD_SETTLEMENT_COMMISSION
);
