import { RefObject } from 'react';
import { AxiosError } from 'axios';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import size from 'lodash/size';
import trim from 'lodash/trim';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import 'moment/locale/ru';
import store from 'store';
import endsWith from 'lodash/endsWith';
import { langSelector } from '@kassma-team/kassma-toolkit/lib';
import each from 'lodash/each';
import isObject from 'lodash/isObject';

import { DATETIME_FORMAT } from './constants';
import i18n from 'i18n';
import { IComment } from 'interfaces/widgets/paymentSystems';
import { IError } from '../interfaces/common';

export const getTimeFromNow = (dt: string) => {
  const lang = langSelector(store.getState());

  return moment(dt, DATETIME_FORMAT).utc(true).locale(lang).fromNow();
};

export const normalizeErrorMessage = (err: Record<string, unknown> | string, onlyValue = false): string => {
  if (isObject(err)) {
    let message = ``;

    each(err, (value, key) => {
      if (onlyValue) {
        message += value;
      } else {
        message += `${key}: ${value}`;
      }
      message += `\n`;
    });

    return message;
  }

  return err;
};

export const getErrorMessage = (
  err?: Error | AxiosError,
  {
    normalize = false,
    defaultValue = ``,
    onlyValue = false,
  }: {
    normalize?: boolean;
    defaultValue?: string;
    onlyValue?: boolean;
  } = {}
): any => {
  const message = get(err, `response.data.message`);

  if (!message) {
    return defaultValue || get(err, `message`, ``);
  }

  if (typeof message === `object`) {
    if (message.withdrawals_sum_amount) {
      return normalizeErrorMessage(message, true);
    }
  }

  return normalize ? normalizeErrorMessage(message, onlyValue) : message;
};

export const formatErrors = (errors: IError[]): Record<string, unknown> => {
  const obj: Record<string, unknown> = {};
  errors.forEach((err) => {
    const { target, message } = err;
    obj[target] = message;
  });

  return obj;
};

export const getNormalizedUrl = (url: string): string => (endsWith(url, `/`) ? url : `${url}/`);

export const dateTimeFormat = (date: string): string => moment(date).format(`YYYY-MM-DD HH:mm:ss`);

export const dateFormat = (date: string): string => moment(date).format(`YYYY-MM-DD`);

export const copyText = (ref: RefObject<HTMLElement>) => {
  if (ref && ref.current) {
    const selection = (window as any).getSelection();
    const range = document.createRange();
    range.selectNodeContents(ref.current);
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand(`copy`);
  }
};

export const splitNumBySpaces = (num: number | string, { splitFloatNumbers = false } = {}): string | null => {
  if (!isString(num) && !isNumber(num)) {
    return null;
  }

  const parts = num.toString().split(`.`);
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ` `);
  if (splitFloatNumbers && parts[1]) {
    parts[1] = parts[1].replace(/\B(?=(\d{3})+(?!\d))/g, ` `);
  }

  return parts.join(`.`);
};

export const getFilledDateRange = (startDate: string, endDate: string) => {
  if (!startDate || !endDate) {
    return undefined;
  }

  return { values: { startDate: new Date(startDate), endDate: new Date(endDate) } };
};

export const displayingDateInputFormat = (date: string, time = ``) => {
  if (!date) {
    return ``;
  }

  if (time) {
    return `${moment(date).format(`DD-MM-YYYY`)} ${time}`;
  }

  return moment(date).format(`DD-MM-YYYY`);
};

export const hasIntersection = (arr1: any[], arr2: any[]): boolean => !isEmpty(intersection(arr1, arr2));

export const normalizeTextEditorValue = (textEditorValue: IComment): string | undefined => {
  if (textEditorValue) {
    const html = get(textEditorValue, `html`);
    const val = get(textEditorValue, `value`);

    if (size(trim(val)) > 0 && html) {
      return html;
    } else {
      return ``;
    }
  }
};

interface IImitateLinkClick {
  fileName?: string;
  download?: boolean;
  openInNewTab?: boolean;
}

export const imitateLinkClick = (
  url: string,
  { fileName, download = false, openInNewTab = false }: IImitateLinkClick
) => {
  const a = document.createElement(`a`);
  a.href = url;
  if (download && fileName) {
    a.download = fileName;
  }
  if (openInNewTab) {
    a.target = `_blank`;
  }
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
  }, 0);
};

export const downloadFile = (data: string, fileName: string, type: string) => {
  const file = new Blob([data], { type: type });
  // eslint-disable-next-line
  // @ts-ignore
  if (window.navigator.msSaveOrOpenBlob) {
    // IE10+
    // eslint-disable-next-line
    // @ts-ignore
    window.navigator.msSaveOrOpenBlob(file, fileName);
  } else {
    const url = URL.createObjectURL(file);
    imitateLinkClick(url, { fileName, download: true });
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 0);
  }
};

export const nFormatter = (num: number, digits = 1): string => {
  const si = [
    { value: 1, symbol: `` },
    { value: 1e6, symbol: i18n.t(`quantities.million`) },
    { value: 1e9, symbol: i18n.t(`quantities.billion`) },
    { value: 1e12, symbol: i18n.t(`quantities.trillion`) },
    { value: 1e15, symbol: i18n.t(`quantities.quadrillion`) },
    { value: 1e18, symbol: i18n.t(`quantities.quintillion`) },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }

  const spaceBetweenDigitsAndSymbol = i18n.language === `ru` ? ` ` : ``;

  return `${(num / si[i].value).toFixed(digits).replace(rx, `$1`)}${spaceBetweenDigitsAndSymbol}${si[i].symbol}`;
};

export const range = (start: number, end: number) => [
  // todo unused function
  ...(function* f(start, end): any {
    yield start;

    // если начальное и конечное значение совпадают
    if (start === end) return;

    // рекурсивно вызываем функцию-генератор
    yield* f(start + 1, end);
  })(start, end),
];

export const pressEsc = (e: KeyboardEvent) => e.keyCode === 27;

export const formatNumber = (amount: number): string =>
  Intl.NumberFormat(`en`, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(amount);
