import { useState } from 'react';
import { useSelector } from 'react-redux';

import { hasAccessSelector } from 'selectors/auth';
import { Permission, WalletColumn } from 'utils/enums';
import { ITableTitleField } from 'interfaces/widgets/widgets';

const useDisabledWalletTableTitles = (): ITableTitleField[] => {
  const balancePermission = useSelector(hasAccessSelector(Permission.WALLET_BALANCE));

  const [titles] = useState([
    {
      title: `ID`,
      id: WalletColumn.ID,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.type`,
      id: WalletColumn.TYPE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `wallets.identifier`,
      id: WalletColumn.IDENTIFIER,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.proxy`,
      id: WalletColumn.PROXY,
      mobile: true,
    },
    {
      title: `wallets.email`,
      id: WalletColumn.EMAIL,
      desktop: true,
      mobile: true,
    },
    {
      title: `wallets.siteLogin`,
      id: WalletColumn.SITE_LOGIN,
      desktop: true,
      mobile: true,
    },
    {
      title: `wallets.addInfo`,
      id: WalletColumn.ADDITIONAL_INFO,
      desktop: true,
      mobile: true,
    },
    {
      title: `common.comment`,
      id: WalletColumn.COMMENT,
      desktop: true,
      mobile: true,
    },
    {
      title: `wallets.balance`,
      id: WalletColumn.BALANCE,
      desktop: true,
      mobile: true,
      hide: !balancePermission,
    },
    {
      title: `wallets.enCode`,
      id: WalletColumn.EPAY_CODE,
      mobile: true,
    },
    {
      title: `common.type`,
      id: WalletColumn.UPDATED_AT,
      mobile: true,
    },
    {
      title: `wallets.pluginStatus`,
      id: WalletColumn.SHOW_PLUGIN_STATUS,
      mobile: true,
    },
    {
      title: `common.status`,
      id: WalletColumn.STATUS,
      desktop: true,
      mobile: true,
    },
    {
      title: `wallets.priorityRaw`,
      id: WalletColumn.PRIORITY,
      desktop: true,
      mobile: true,
    },
    {
      title: `common.actions`,
      id: WalletColumn.CONTROL,
      desktop: true,
      mobile: true,
      disabled: true,
    },
  ]);

  return titles;
};

export default useDisabledWalletTableTitles;
