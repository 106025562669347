import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ITariffCurrencies, ITariffsSchemas } from 'interfaces/widgets/constructor/tariffConstructor';

import './tariffButtons.scss';

interface IProps {
  data?: ITariffCurrencies[];
  onClickCallback: (value: number) => void;
  schemas: ITariffsSchemas[];
}

const CurrencyColumn = ({ data, onClickCallback, schemas }: IProps): JSX.Element => {
  const [activeId, setActiveId] = useState(schemas?.[0]?.id || 0);
  const [t] = useTranslation();

  useEffect(() => {
    setActiveId(schemas?.[0]?.id || 0);
    onClickCallback(schemas?.[0]?.id as number);
  }, [data, onClickCallback]);

  const handleClick = useCallback(
    (value) => {
      setActiveId(value);
      onClickCallback(value as number);
    },
    [schemas, onClickCallback]
  );

  const activeClass = (activeId: number | undefined, id: number) =>
    activeId === id ? `tariff_buttons_row__currency_button-active` : ``;

  const renderButtons = useMemo(
    () =>
      schemas?.map((item, key: number) => (
        <button
          key={key}
          className={`tariff_buttons_row__currency_button ${activeClass(activeId, item.id)}`}
          onClick={() => handleClick(item.id)}
        >
          <div key={key}>{item.currency}</div>
        </button>
      )),
    [data, activeId, handleClick]
  );

  return (
    <div className="tariff_row__currency tariff_row__column">
      <div className="tariff_buttons_row">
        {data ? renderButtons : <div className="d-flex justify-content-center">{t(`widgets.listIsEmpty`)}</div>}
      </div>
    </div>
  );
};

export default CurrencyColumn;
