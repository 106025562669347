import React, { ReactNode } from 'react';
import { ConfirmationModal as KassmaConfirmationModal, IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

interface IProps {
  actionCreators: IWidgetActionCreators;
  title: string;
  content: string;
  confirmText: string;
  onConfirm: () => void;
  confirmClassName?: string;
  size?: number;
  footer?: ReactNode;
  loading?: boolean;
  footerClassName?: string;
  dispatch?: Dispatch;
  submitting?: boolean;
}

const ConfirmationModal = (props: IProps) => {
  const dispatch = useDispatch();

  return <KassmaConfirmationModal {...props} dispatch={dispatch} />;
};

export default ConfirmationModal;
