import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import isObject from 'lodash/isObject';
import { widgetSubmittingSelector, InputType } from '@kassma-team/kassma-toolkit/lib';

import { updateCustomStyle } from 'actions/widgets/settings';
import { APPEARANCE_SETTINGS } from 'utils/constants';
import { WidgetType } from 'utils/enums';
import { IAppearanceSettingsForm } from 'interfaces/widgets/settings/pluginSettings';

import NewFormSubmit from 'components/form/formSubmit/NewFormSubmit';
import FormField from 'components/form/FormField';

const CustomThemeUploading: React.FC<Record<`changeHandler`, (field: string, values: any) => void>> = () => {
  const [t] = useTranslation();

  const [disabled] = useState(false);

  const { custom_style, custom_css }: Partial<IAppearanceSettingsForm> =
    useSelector(getFormValues(APPEARANCE_SETTINGS)) || {};
  const submitting = useSelector(widgetSubmittingSelector(WidgetType.SETTINGS));

  const dispatch = useDispatch();

  return (
    <div className="mb-10 setting__plugin--file-download">
      <FormField
        name="custom_style"
        label={t(`settings.file`)}
        fileAcceptedTypes={[`css`]}
        maxSize={100 * 1024}
        type={InputType.FILE}
        showDeleteButton={false}
        defaultFileName={custom_css}
      />
      <div className="flex align-items-center">
        {isObject(custom_style) && (
          <NewFormSubmit
            submitting={submitting as boolean}
            type="button"
            onClick={() => dispatch(updateCustomStyle(custom_style))}
            disabled={disabled}
          >
            {t(`common.saveFile`)}
          </NewFormSubmit>
        )}
      </div>
    </div>
  );
};

export default CustomThemeUploading;
