import isNull from 'lodash/isNull';
import map from 'lodash/map';
import toString from 'lodash/toString';
import concat from 'lodash/concat';

import { ISelectDataWithNull } from 'interfaces/form';
import { ITestWalletType } from 'interfaces/widgets/wallets/wallet';

interface IList {
  id: number;
  type: string;
  identifier: string;
  status: number;
  currency: string;
  test_wallet_type?: ITestWalletType;
}

export const parseWalletList = (
  list: any[] | null,
  { displayId = false, useIdentifierAsValue = false, showNullValue = true, emptyText = ``, withStatus = false } = {}
): ISelectDataWithNull[] | null => {
  if (isNull(list)) {
    return null;
  }

  const nullValue: ISelectDataWithNull[] = [{ value: null, text: emptyText }];

  return concat(
    showNullValue ? nullValue : [],
    map(list, ({ id, identifier, status }) => ({
      value: useIdentifierAsValue ? identifier : toString(id),
      text: displayId ? `${id} | ${identifier}` : identifier,
      ...(withStatus ? { status } : {}),
    }))
  );
};
