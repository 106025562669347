import React, { useEffect } from 'react';
import SettingsForm from './SettingsForm';
import { GridType, InputType, widgetSubmittingSelector } from '@kassma-team/kassma-toolkit';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { hasAccessSelector } from '../../../selectors/auth';
import { Permission, WidgetType } from '../../../utils/enums';
import { Dispatch } from 'redux';
import { loadPrivateSettings, updatePrivateSettings } from 'actions/widgets/settings';
import { PRIVATE_SETTINGS } from '../../../utils/constants';

const onSubmit = (values: any, dispatch: Dispatch) => {
  dispatch(updatePrivateSettings(values));
};
const PrivateSettings = (props: InjectedFormProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const pluginAccess = useSelector(hasAccessSelector(Permission.PLUGIN_SETTINGS));
  const pluginClientAccess = useSelector(hasAccessSelector(Permission.PLUGIN_SETTINGS_CLIENT));

  const submitting = useSelector(widgetSubmittingSelector(WidgetType.SETTINGS));

  useEffect(() => {
    dispatch(loadPrivateSettings());
  }, []);

  return (
    <SettingsForm
      submitDisabled={false}
      {...props}
      groupedFields={[
        {
          title: t(`settings.postback`),
          fields: [
            ...(!pluginAccess && pluginClientAccess
              ? []
              : [
                  {
                    name: `postback_access_key`,
                    type: InputType.MUI_TEXT,
                    label: t(`settings.postbackAccessKey`),
                    description: `settings.postbackAccessKeyHint`,
                    required: true,
                  },
                  {
                    name: `postback_private_access_key`,
                    type: InputType.MUI_TEXT,
                    label: t(`settings.privatePostbackAccessKey`),
                    description: `settings.privatePostbackAccessKeyHint`,
                    required: true,
                  },
                ]),
          ],
          gridType: GridType.GRID,
        },
        {
          title: t(`settings.depositPostback`),
          fields: [
            {
              name: `postback_url`,
              type: InputType.MUI_TEXT,
              label: t(`settings.postbackUrl`),
              description: t(`settings.postbackUrlHint`),
              required: true,
            },
          ],
          gridType: GridType.GRID,
        },
        {
          title: t(`settings.postbackWithdrawal`),
          fields: [
            {
              name: `withdrawal_postback_url`,
              type: InputType.MUI_TEXT,
              label: t(`settings.manualWithdrawalPostback`),
              link: true,
              description: `settings.manualWithdrawalPostbackHint`,
              required: true,
            },
            {
              name: `delete_withdrawals_after_days`,
              type: InputType.MUI_TEXT,
              label: t(`settings.deleteWithdrawalsAfterDays`),
              numeric: true,
              maxAmount: 1000,
              description: `settings.deleteWithdrawalsAfterDaysHint`,
              required: true,
            },
          ],
          gridType: GridType.GRID,
        },
      ]}
      submitText="common.update"
      submitting={submitting as boolean}
    />
  );
};

const PrivateSettingsReduxForm = reduxForm({
  form: PRIVATE_SETTINGS,
  destroyOnUnmount: false,
  onSubmit,
  shouldError: () => true,
})(PrivateSettings as any);

export default PrivateSettingsReduxForm;
