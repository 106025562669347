import React, { useEffect, useMemo } from 'react';
import { reduxForm, getFormValues } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DropdownButtons, Tooltip, GridType, getSubmitHandler, InputType } from '@kassma-team/kassma-toolkit/lib';

import { ModalType, Permission, WidgetType, TransactionKind } from 'utils/enums';
import { transactionActionCreators } from 'actions/widgets/transactions/transactions';
import { hasAccessSelector } from 'selectors/auth';
import transactionListMapping from 'utils/widgets/transactions/transactionListMapping';
import useTransactionsTableTitles from 'hooks/widgets/tableTitles/transactions/useTransactionsTableTitles';
import {
  transactionDirectionList,
  transactionStatusAtFiltersList,
  transactionType,
  kindOfTransaction,
  transactionPostbackStatusList,
} from 'static/transactions';
import useWalletListByType from 'hooks/widgets/useWalletListByType';
import { parseWalletList } from 'utils/widgets/wallets/parseWalletList';
import { PSWithoutSettlementsSelector } from 'selectors/widgets/paymentSystems';
import { selectionOfAllManualPSSelector } from 'selectors/widgets/manualPS';
import { manualPSActionCreators } from 'actions/widgets/paymentSystem';
import withPermissionCheck from 'hocs/withPermissionCheck';
import { ITransactionsFilterForm } from 'interfaces/widgets/transaction/transaction';
import { IFilterProps } from 'interfaces/widgets/widgets';
import transactionRouteTabs from 'pages/transactions/transactionRouteTabs';
import { notAcceptedPostbackActionCreators } from 'actions/widgets/postback';

import Filters from 'components/widgets/filters/Filters';
import TransactionCreation from 'components/widgets/transaction/TransactionCreating';
import TransactionPostbackList from 'components/widgets/transaction/TransactionPostbackList';
import TransactionPostbackInfo from 'components/widgets/transaction/TransactionPostbackInfo';
import TransactionActivation from 'components/widgets/transaction/transactionActivation/TransactionActivation';
import TransactionArchivationModal from 'components/widgets/transaction/archivedTransactions/TransactionArchivationModal';
import Widget from 'components/widgets/Widget';
import TransactionsUploadingModal from 'components/widgets/transaction/TransactionsUploadingModal';

import './transactions.scss';
import TransactionApproveModal from 'components/widgets/transaction/TransactionApproveModal';

const { showModal } = transactionActionCreators;

const entityParams = {
  actionCreators: transactionActionCreators,
  widget: WidgetType.TRANSACTIONS,
};

const FORM_NAME = `transaction-filters`;

const FilterForm = reduxForm<any, IFilterProps>({
  form: FORM_NAME,
  // @ts-expect-error
  useSearchParamsAsDefaultParams: true,
  onSubmit: getSubmitHandler({
    dateFields: [
      {
        name: `date_range`,
        fromName: `date_from`,
        toName: `date_to`,
      },
      {
        name: `activation_date_range`,
        fromName: `activated_date_from`,
        toName: `activated_date_to`,
      },
    ],
    dateFormat: `YYYY-MM-DD HH:mm:ss`,
  }),
})(Filters);

const modals = [
  {
    type: ModalType.CREATE,
    widget: WidgetType.TRANSACTIONS,
    component: TransactionCreation,
  },
  {
    type: ModalType.SHOW,
    widget: WidgetType.TRANSACTIONS,
    component: () =>
      TransactionPostbackList({
        ...entityParams,
        shouldUseItem: true,
      }),
  },
  {
    type: ModalType.INFO,
    widget: WidgetType.TRANSACTIONS,
    component: () => TransactionPostbackInfo(entityParams),
  },
  {
    type: ModalType.TRANSACTION_ACTIVATION,
    widget: WidgetType.TRANSACTIONS,
    component: TransactionActivation,
  },
  {
    type: ModalType.TRANSACTION_ARCHIVATION,
    widget: WidgetType.TRANSACTIONS,
    component: TransactionArchivationModal,
  },
  {
    type: ModalType.UPLOADING,
    widget: WidgetType.TRANSACTIONS,
    component: TransactionsUploadingModal,
  },
  {
    type: ModalType.TRANSACTION_APPROVE,
    widget: WidgetType.TRANSACTIONS,
    component: TransactionApproveModal,
  },
  {
    type: ModalType.NOT_ACCEPTED_TRANSACTION_POSTBACKS,
    widget: WidgetType.NOT_ACCEPTED_POSTBACKS,
    component: () =>
      TransactionPostbackList({
        ...entityParams,
        widget: WidgetType.NOT_ACCEPTED_POSTBACKS,
        modalWidget: WidgetType.TRANSACTIONS,
        listActionCreators: notAcceptedPostbackActionCreators,
        shouldUseItem: true,
      }),
  },
];

const filterDateFields = [
  {
    from: `date_from`,
    to: `date_to`,
    name: `date_range`,
  },
  {
    from: `activated_date_from`,
    to: `activated_date_to`,
    name: `activation_date_range`,
  },
];

const Transactions = () => {
  const createForcedTransactionAccess = useSelector(hasAccessSelector(Permission.ACTIVATE_TRANSACTION));
  const createDebugTransactionAccess = useSelector(hasAccessSelector(Permission.CREATE_DEBUG_TRANSACTION));
  const transactionInnerAccess = useSelector(hasAccessSelector(Permission.TRANSACTION_INNER));
  const transactionLoadAccess = useSelector(hasAccessSelector(Permission.LOAD_TRANSACTIONS));
  const suspectsTransactionViewPermission = useSelector(
    hasAccessSelector(Permission.TRANSACTION_SUSPECTS_TRANSACTIONS_VIEW)
  );
  const displayTransactionTypePermission = useSelector(hasAccessSelector(Permission.DISPLAY_TRANSACTION_TYPE));

  const dispatch = useDispatch();

  const walletTypes = useSelector(PSWithoutSettlementsSelector);
  const testPayments = useSelector(selectionOfAllManualPSSelector);

  const safeTestPayments = testPayments.map((el) => ({
    ...el,
    text: el.name,
    value: el.code,
  }));

  const safePaymentSystems = walletTypes.map((el) => ({
    ...el,
    text: el.name,
    value: el.code,
  }));

  const values: ITransactionsFilterForm = useSelector(getFormValues(FORM_NAME)) || {};

  const showWalletFilter = useSelector(hasAccessSelector(Permission.WALLET_LIST));

  const { walletsSelection: walletList, listLoading: walletListLoading } = useWalletListByType(
    values?.wallet_type as string,
    {
      showAllWhenTypeIsEmpty: true,
      wallet: true,
      disableLoading: !showWalletFilter,
    }
  );

  const titles = useTransactionsTableTitles(WidgetType.TRANSACTIONS);

  const [t] = useTranslation();

  const title = t(`transactions.transactionList`);

  useEffect(() => {
    dispatch(manualPSActionCreators.getList());
  }, []);

  const transactionsViewTypes = useSelector(hasAccessSelector(Permission.DISPLAY_TRANSACTION_TYPE));
  const postbackViewAccess = useSelector(hasAccessSelector(Permission.POSTBACKS));
  const transactionPrivateInformationView = useSelector(
    hasAccessSelector(Permission.TRANSACTION_PRIVATE_INFORMATION_VIEW)
  );

  const leftFilterFields = useMemo(() => {
    let filters: any[] = [
      {
        name: `primary_id`,
        placeholder: t(`common.ID`),
        numeric: true,
      },
      {
        name: `wallet_type`,
        id: `${FORM_NAME}_wallet_type`,
        data: [{ value: null, text: t(`common.all`) }, ...safePaymentSystems, ...safeTestPayments],
        type: InputType.SELECT,
        placeholder: t(`wallets.walletType`),
      },
      ...[
        showWalletFilter
          ? {
              name: `wallet_id`,
              id: `${FORM_NAME}_wallet_id`,
              data: parseWalletList(walletList, { displayId: true, emptyText: t(`common.all`) }),
              type: InputType.SELECT,
              placeholder: t(`wallets.wallet`),
              busy: walletListLoading,
            }
          : undefined,
      ],
      !values.has_label || values.has_label === `true`
        ? {
            name: `label`,
            placeholder: t(`common.label`),
          }
        : undefined,
      transactionPrivateInformationView && !values.label
        ? {
            name: `has_label`,
            type: InputType.SELECT,
            id: `${FORM_NAME}_has_label`,
            placeholder: t(`transactions.labelAvailability`),
            data: [
              { value: null, text: t(`common.all`) },
              { value: `true`, text: t(`transactions.withLabel`) },
              { value: `false`, text: t(`transactions.empty`) },
            ],
          }
        : undefined,
      {
        name: `merchant_order_id`,
        placeholder: t(`common.orderId`),
      },
      {
        name: `direction`,
        id: `${FORM_NAME}_direction`,
        data: transactionDirectionList(t(`common.all`)),
        translateKeys: true,
        type: InputType.SELECT,
        placeholder: t(`transactions.direction`),
      },
      {
        name: `transaction_id`,
        placeholder: t(`transactions.transactionId`),
      },
      {
        name: `exchanger_identifier`,
        placeholder: t(`transactions.exchanger`),
      },
      ...[
        transactionsViewTypes
          ? {
              name: `type`,
              id: `${FORM_NAME}_type`,
              data: transactionType(transactionInnerAccess, t(`common.all`)),
              type: InputType.SELECT,
              placeholder: t(`transactions.transactionType`),
              translateKeys: true,
            }
          : undefined,
      ],
      {
        name: `creation_type`,
        id: `${FORM_NAME}_creation_type`,
        data: kindOfTransaction(t(`common.all`)),
        type: InputType.SELECT,
        placeholder: t(`transactions.kindOfTransaction`),
        translateKeys: true,
        hidden: !displayTransactionTypePermission,
      },
      ...[
        transactionPrivateInformationView
          ? {
              name: `status`,
              id: `${FORM_NAME}_status`,
              data: transactionStatusAtFiltersList(t(`common.all`)),
              type: InputType.SELECT,
              placeholder: t(`common.status`),
              translateKeys: true,
            }
          : undefined,
      ],
    ];

    if (suspectsTransactionViewPermission) {
      filters.push({
        name: `is_fake`,
        id: `${FORM_NAME}_is_fake`,
        data: [
          {
            text: `common.all`,
            value: null,
          },
          {
            text: `transactions.original`,
            value: `false`,
          },
          {
            text: `transactions.scam`,
            value: `true`,
          },
        ],
        type: InputType.SELECT,
        placeholder: t(`transactions.originality`),
        translateKeys: true,
      });
    }

    filters = [
      ...filters,
      ...[
        postbackViewAccess
          ? {
              name: `postback_status`,
              id: `${FORM_NAME}_postback_status`,
              data: transactionPostbackStatusList(t(`common.all`)),
              type: InputType.SELECT,
              placeholder: t(`postbacks.postbackStatus`),
              translateKeys: true,
            }
          : undefined,
      ],
      {
        name: `date_range`,
        type: InputType.DATE_RANGE,
        placeholder: t(`common.createdAt`),
        timePicker: true,
        showSeconds: true,
      },
      {
        name: `activation_date_range`,
        type: InputType.DATE_RANGE,
        placeholder: t(`common.activatedAt`),
        timePicker: true,
        showSeconds: true,
      },
    ];

    return filters;
  }, [
    suspectsTransactionViewPermission,
    transactionInnerAccess,
    walletTypes,
    walletList,
    walletListLoading,
    testPayments,
    showWalletFilter,
    transactionsViewTypes,
    transactionPrivateInformationView,
    values.label,
    values.has_label,
  ]);

  const generateTransactionDropdown = createForcedTransactionAccess ? (
    <div className="generate-transaction-wrapper">
      <DropdownButtons
        id="generate_transaction_btn"
        buttons={[
          ...(createForcedTransactionAccess
            ? [
                {
                  children: t(`transactions.real`),
                  onClick: () =>
                    dispatch(
                      showModal({
                        type: ModalType.CREATE,
                        transactionType: TransactionKind.REAL,
                      })
                    ),
                  id: `generate_real_transaction_btn`,
                },
              ]
            : []),
          ...(createDebugTransactionAccess
            ? [
                {
                  children: t(`transactions.fake`),
                  onClick: () =>
                    dispatch(
                      showModal({
                        type: ModalType.CREATE,
                        transactionType: TransactionKind.DEBUG,
                      })
                    ),
                  id: `generate_fake_transaction_btn`,
                },
              ]
            : []),
        ]}
        className="btn-secondary"
      >
        {t(`transactions.generateTransaction`)}
      </DropdownButtons>
      <Tooltip
        style={{
          width: 330,
          textAlign: `left`,
        }}
        insideContent={
          <div className="generate-transaction-hint">
            <div>
              <b>{t(`transactions.real`)}</b>. {t(`transactions.realHint`)}
            </div>
            <div>
              <b>{t(`transactions.fake`)}</b>. {t(`transactions.fakeHint`)}
            </div>
          </div>
        }
      >
        <i className="fa fa-question-circle-o mx-10" />
      </Tooltip>
    </div>
  ) : null;

  const loadTransactionDropdown = transactionLoadAccess ? (
    <div className="upload-transaction-wrapper">
      <DropdownButtons
        id="upload_transaction_dropdown_btn"
        buttons={[
          {
            children: t(`transactions.iciciTransactionFile`),
            onClick: () =>
              dispatch(
                showModal({
                  type: ModalType.UPLOADING,
                })
              ),
            id: `upload_transaction_btn`,
          },
        ]}
        className="btn-secondary"
      >
        {t(`transactions.bulkTransactionLoading`)}
      </DropdownButtons>
      <Tooltip
        style={{
          width: 330,
          textAlign: `left`,
        }}
        insideContent={
          <div className="upload-transaction-hint">
            <div>
              <b>{t(`transactions.iciciTransactionFile`)}</b>. {t(`transactions.iciciTransactionFileHint`)}
            </div>
          </div>
        }
      >
        <i className="fa fa-question-circle-o mx-10" />
      </Tooltip>
    </div>
  ) : null;

  const extraTableHeaderContent = (
    <>
      {generateTransactionDropdown}
      {loadTransactionDropdown}
    </>
  );

  return (
    <Widget
      actionCreators={transactionActionCreators}
      widget={WidgetType.TRANSACTIONS}
      filterFormName={FORM_NAME}
      pageTitle="transactions.transactions"
      headTitle="transactions.transactions"
      routeTabs={transactionRouteTabs}
      tableTitle={title}
      translatedTitle={false}
      tableHeadTitles={titles}
      listMapping={transactionListMapping(entityParams)}
      filterForm={
        <FilterForm
          leftFields={leftFilterFields}
          formName={FORM_NAME}
          dateFields={filterDateFields}
          gridType={GridType.GRID}
          numFields={[`status`, `country_id`, `postback_status`]}
          submissionControlsOnTheRightSide={false}
        />
      }
      extraTableHeaderContent={extraTableHeaderContent}
      modals={modals}
      withPagination
      statusBar
    />
  );
};

export default withPermissionCheck(Transactions, [
  {
    permissions: [
      {
        permission: Permission.TRANSACTION_ONLY_BY_ID,
        opposite: false,
      },
      {
        permission: Permission.TRANSACTION_LIST,
        opposite: true,
      },
    ],
    redirectUrl: `/transactions-only-by-id`,
  },
  {
    permissions: [
      {
        permission: Permission.TRANSACTION_ONLY_BY_ID,
        opposite: true,
      },
      {
        permission: Permission.TRANSACTION_LIST,
        opposite: true,
      },
    ],
    redirectUrl: `/no-permissions`,
  },
]);
