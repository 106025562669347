import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';

export class WalletTypeImageApi extends BaseApi {
  getImages = (): AxiosPromise => this.makeFetch(``);
  createImage = (data: any): AxiosPromise => this.makeFetch(``, { data, method: `POST`, formData: true });
  deleteImage = (id: string): AxiosPromise => this.makeFetch(`{id}`, { method: `DELETE` }, { id });
}

const walletTypeImageApi = new WalletTypeImageApi(`wallet-type/images`);

export default walletTypeImageApi;
