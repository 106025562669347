import React, { useEffect } from 'react';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputType } from '@kassma-team/kassma-toolkit/lib';

import { CREATE_WITHDRAWAL_FORM_NAME } from 'utils/constants';

import { withdrawalsPSSelector } from 'selectors/widgets/paymentSystems';

import FormField from 'components/form/FormField';

const WalletCreationTypeSelection = ({ handleSubmit, reset, change, form }: InjectedFormProps) => {
  const { payment_system }: Partial<Record<`payment_system`, string>> = useSelector(getFormValues(form)) || {};
  const withdrawalsPaymentSystems = useSelector(withdrawalsPSSelector);

  const safePaymentSystems = withdrawalsPaymentSystems.map((el) => ({
    ...el,
    text: el.name,
    value: el.code,
  }));

  const [t] = useTranslation();

  useEffect(() => {
    reset();
    change(`payment_system`, payment_system);
  }, [payment_system]);

  return (
    <form onSubmit={handleSubmit}>
      <FormField
        name="payment_system"
        id={`${form}_payment_system`}
        data={safePaymentSystems}
        isModalField
        type={InputType.SELECT}
        label={t(`common.paymentSystem`)}
        required
      />
    </form>
  );
};

export default reduxForm({
  form: CREATE_WITHDRAWAL_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(WalletCreationTypeSelection);
