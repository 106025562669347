import { FieldsState } from './types';

type Step =
  | `cleared credit field`
  | `cleared amount field`
  | `cleared comission field`
  | `amount field didnt touched`
  | `crediting field didnt touched`
  | `amount field touched, but empty`
  | `crediting field touched, but empty`
  | `amount field in focus`
  | `crediting field in focus`
  | `comission field in focus`
  | `exRate field in focus`;

type Data = {
  writeoff_amount: any;
  crediting: any;
  commission_percent: any;
  ex_rate: any;
};

export const getCases = (
  fieldsState: FieldsState,
  { writeoff_amount, crediting, commission_percent, ex_rate }: Data
) => (step: Step, debug?: boolean): boolean => {
  switch (step) {
    case `cleared credit field`: {
      const result = fieldsState.crediting.inFocus && !crediting;

      if (result) {
        debug && console.log(`cleared credit field`);
      }

      return result;
    }
    case `cleared amount field`: {
      const result = fieldsState.amount.inFocus && !writeoff_amount;

      if (result) {
        debug && console.log(`cleared amount field`);
      }

      return result;
    }

    case `cleared comission field`: {
      const result = fieldsState.commission.inFocus && !commission_percent;

      if (result) {
        debug && console.log(`cleared comission field`);
      }

      return result;
    }

    case `amount field didnt touched`: {
      const result = !fieldsState.amount.touched;

      if (result) {
        debug && console.log(`amount field didnt touched`);
      }

      return result;
    }
    case `crediting field didnt touched`: {
      const result = !fieldsState.crediting.touched;

      if (result) {
        debug && console.log(`crediting field didnt touched`);
      }

      return result;
    }
    case `amount field touched, but empty`: {
      const result = fieldsState.amount.touched && !writeoff_amount;

      if (result) {
        debug && console.log(`amount field touched, but empty`);
      }

      return result;
    }
    case `crediting field touched, but empty`: {
      const result = fieldsState.crediting.touched && !crediting;

      if (result) {
        debug && console.log(`crediting field touched, but empty`);
      }

      return result;
    }
    case `amount field in focus`: {
      const result = fieldsState.amount.inFocus;

      if (result) {
        debug && console.log(`amount field in focus`);
      }

      return result;
    }
    case `crediting field in focus`: {
      const result = fieldsState.crediting.inFocus;

      if (result) {
        debug && console.log(`crediting field in focus`);
      }

      return result;
    }
    case `comission field in focus`: {
      const result = fieldsState.commission.inFocus;

      if (result) {
        debug && console.log(`comission field in focus`);
      }

      return result;
    }
    case `exRate field in focus`: {
      const result = fieldsState.exRate.inFocus;

      if (result) {
        debug && console.log(`exRate field in focus`);
      }

      return result;
    }
  }
};
