import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import { ISelectData } from '@kassma-team/kassma-toolkit/lib/types/form';

import {
  payzenCountriesSelector,
  payzenCurrenciesSelector,
  payzenMethodsSelector,
} from 'selectors/widgets/payments/payzen';
import { WalletType } from 'utils/enums';
import { payzenPaymentActionCreator } from 'actions/widgets/payments/payzen';

interface IProps {
  countryCode: string;
  walletType: string;
  change: (name: string, value: any) => void;
}

interface IPayzenDataReturns {
  payzenCountries: ISelectData[];
  payzenCurrencies: ISelectData[];
  payzenMethods: ISelectData[];
}

const usePayzenData = ({ countryCode, change, walletType }: IProps): IPayzenDataReturns => {
  const payzenCountries = useSelector(payzenCountriesSelector);
  const payzenCurrencies = useSelector(payzenCurrenciesSelector(countryCode));
  const payzenMethods = useSelector(payzenMethodsSelector(countryCode));

  const [paramsHaveLoaded, setParamsHaveLoaded] = useState(false);

  const [prevCurrencyCode, setPrevCurrencyCode] = useState(``);

  const dispatch = useDispatch();

  useUpdateEffect(() => {
    if (prevCurrencyCode !== `` && walletType === WalletType.PAYZEN) {
      change(`operation_type`, null);
      change(`currency_code`, null);
    }

    if (countryCode) {
      setPrevCurrencyCode(countryCode);
    }
  }, [countryCode]);

  useEffect(() => {
    if (walletType === WalletType.PAYZEN && !paramsHaveLoaded) {
      dispatch(payzenPaymentActionCreator.getItem());
      setParamsHaveLoaded(true);
    }
  }, [walletType]);

  return {
    payzenCountries,
    payzenCurrencies,
    payzenMethods,
  };
};

export default usePayzenData;
