import { put, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import i18n from 'i18n';
import omit from 'lodash/omit';
import get from 'lodash/get';
import { AxiosError, AxiosResponse } from 'axios';
import { formSubmissionError, validateForm, IWidgetsMeta, IAction } from '@kassma-team/kassma-toolkit/lib';

import { getErrorMessage } from 'utils';
import refreshSaga from 'sagas/effects/refreshSaga';
import { CREATE_SETTLEMENT_FORM_NAME } from 'utils/constants';

import {
  CREATE_SETTLEMENT_REPLENISHMENT_REQUESTED,
  CREATE_SETTLEMENT_TRANSFER_REQUESTED,
  CREATE_SETTLEMENT_WRITE_OFF_REQUESTED,
  GET_SETTLEMENT_BALANCE,
  SETTLEMENT_WITHDRAWAL_ACTIONS,
} from 'actionTypes';
import {
  settlementActionCreators,
  settlementWithdrawalActionCreators,
  updateSettlementBalance,
} from 'actions/widgets/balances/settlement';
import { SettlementWithdrawalActionType } from 'utils/enums';
import { ICreateSettlementForm } from 'interfaces/widgets/settlement';
import { ITransferringMoneyToSettlementBalanceForm } from 'interfaces/widgets/balances/balances';
import settlementApi from 'api/balances/SettlementApi';
import settlementWithdrawalApi from 'api/balances/SettlementWithdrawalApi';
import { accountsBalanceHistoryActionCreators } from '../../../actions/widgets/balances/accountsBalanceHistory';
import accountBalanceApi from '../../../api/balances/AccountBalanceApi';

const { hideModal, getList } = settlementActionCreators;
const { getList: withdrawalGetList } = settlementWithdrawalActionCreators;

interface ICreateSettlementReplenishmentProps {
  meta: IWidgetsMeta;
  payload: ICreateSettlementForm;
}

// Todo: start from here
function* createSettlementReplenishmentSaga({ payload, meta }: ICreateSettlementReplenishmentProps) {
  const valid: boolean = yield validateForm({ form: CREATE_SETTLEMENT_FORM_NAME, meta });
  if (!valid) {
    return;
  }

  yield refreshSaga({
    request: () => settlementApi.createExternalReplenishment(payload),
    onSuccess: function* () {
      yield put(accountsBalanceHistoryActionCreators.hideModal());
      yield toastr.success(i18n.t(`common.success`), i18n.t(`balances.replenishmentSuccessful`));

      yield put(withdrawalGetList());
    },
    onError: function* (e: AxiosError) {
      const payload: string = yield getErrorMessage(e, { defaultValue: i18n.t(`balances.replenishmentError`) });
      yield formSubmissionError({ payload, meta, form: CREATE_SETTLEMENT_FORM_NAME });
    },
  });
}

interface ICreateSettlementWriteOffProps {
  payload: ICreateSettlementForm;
  meta: IWidgetsMeta;
}

function* createSettlementWriteOffSaga({ payload, meta }: ICreateSettlementWriteOffProps) {
  const valid: boolean = yield validateForm({ form: CREATE_SETTLEMENT_FORM_NAME, meta });
  if (!valid) {
    return;
  }

  yield refreshSaga({
    request: () => settlementWithdrawalApi.createWriteOff(payload),
    onSuccess: function* () {
      yield put(accountsBalanceHistoryActionCreators.hideModal());
      yield toastr.success(i18n.t(`common.success`), i18n.t(`widgets.itemHasBeenSuccessfullyCreated`));

      yield put(withdrawalGetList());
    },
    onError: function* (e: AxiosError) {
      const payload: string = yield getErrorMessage(e, { defaultValue: i18n.t(`widgets.itemCreatingHasBeenFailed`) });
      yield formSubmissionError({ payload, meta, form: CREATE_SETTLEMENT_FORM_NAME });
    },
  });
}

interface ICreateSettlementTransferProps {
  payload: ITransferringMoneyToSettlementBalanceForm;
  meta: IWidgetsMeta;
}

function* createSettlementTransferSaga({ payload, meta }: ICreateSettlementTransferProps) {
  const valid: boolean = yield validateForm({ form: CREATE_SETTLEMENT_FORM_NAME, meta });
  if (!valid) {
    return;
  }

  yield refreshSaga({
    request: () => accountBalanceApi.transferMoney(omit(payload, `crediting`)),
    onSuccess: function* () {
      yield put(accountsBalanceHistoryActionCreators.hideModal());
      yield toastr.success(i18n.t(`common.success`), i18n.t(`balances.transferingSuccessful`));

      yield put(withdrawalGetList());
    },
    onError: function* (e: AxiosError) {
      const payload: string = yield getErrorMessage(e, { defaultValue: i18n.t(`balances.transferingError`) });
      yield formSubmissionError({ payload, meta, form: CREATE_SETTLEMENT_FORM_NAME });
    },
  });
}

interface ISettlementWithdrawalProps {
  payload: number;
  meta: IWidgetsMeta;
}

function* settlementWithdrawalSaga({ payload, meta }: ISettlementWithdrawalProps) {
  const type = get(meta, `type`);
  let request;
  switch (type) {
    case SettlementWithdrawalActionType.APPROVE:
      request = () => settlementWithdrawalApi.settlementWithdrawalApprove(payload);
      break;
    case SettlementWithdrawalActionType.REJECT:
      request = () => settlementWithdrawalApi.settlementWithdrawalReject(payload);
      break;
    default:
      throw new Error(`unknown settlement withdrawal action type`);
  }

  yield refreshSaga({
    request,
    onSuccess: function* () {
      yield toastr.success(i18n.t(`common.success`), i18n.t(`widgets.itemHasBeenSuccessfullyUpdated`));

      yield put(withdrawalGetList());
    },
    onError: function* (e: AxiosError) {
      const payload: string = yield getErrorMessage(e, { defaultValue: i18n.t(`widgets.itemUpdatingHasBeenFailed`) });
      yield toastr.error(i18n.t(`common.error`), payload);
    },
  });
}

interface ISettlementBalanceResponse {
  balance?: number;
}

function* updateAvailableBalanceSaga() {
  yield refreshSaga({
    request: () => settlementApi.loadSettlementBalance(),
    onSuccess: function* (resp: AxiosResponse<ISettlementBalanceResponse>) {
      yield put(updateSettlementBalance(resp.data.balance));
    },
    onError: function* (e: AxiosError) {
      const payload: string = yield getErrorMessage(e, { defaultValue: i18n.t(`widgets.itemUpdatingHasBeenFailed`) });
      yield toastr.error(i18n.t(`common.error`), payload);
    },
  });
}

const settlementSagas = [
  takeEvery<IAction>(CREATE_SETTLEMENT_REPLENISHMENT_REQUESTED, createSettlementReplenishmentSaga),
  takeEvery<IAction>(CREATE_SETTLEMENT_WRITE_OFF_REQUESTED, createSettlementWriteOffSaga),
  takeEvery<IAction>(CREATE_SETTLEMENT_TRANSFER_REQUESTED, createSettlementTransferSaga),
  takeEvery<IAction>(SETTLEMENT_WITHDRAWAL_ACTIONS, settlementWithdrawalSaga),
  takeEvery<IAction>(GET_SETTLEMENT_BALANCE, updateAvailableBalanceSaga),
];

export default settlementSagas;
