import React, { FC } from 'react';
import map from 'lodash/map';
import { Switch, Route } from 'react-router-dom';

import userIsAuthenticated from 'routes/userIsAuthenticated';
import userIsAnonymous from 'routes/userIsAnonymous';

import Dashboard from 'pages/dashboard/Dashboard';
import Proxies from 'pages/proxies/Proxies';
import Login from 'pages/Login';
import Wallets from 'pages/wallets/Wallets';
import Transactions from 'pages/transactions/Transactions';
import StockpilingList from 'pages/StockpilingList';
import WalletsLoad from 'pages/WalletsLoad';
import Postbacks from 'pages/postbacks/Postbacks';
import Logs from 'pages/walletsDebugging/logs/Logs';
import Settings from 'pages/settings/Settings';
import TransactionsOnlyById from 'pages/transactions/TransactionsOnlyById';
import PossibleTransactions from 'pages/transactions/PossibleTransactions';
import DisabledWallets from 'pages/wallets/DisabledWallets';
import NotAcceptedPostbacks from 'pages/postbacks/NotAcceptedPostbacks';
import PaymentsConstructor from 'pages/constructor/PaymentsConstructor';
import PaymentTranslations from 'pages/constructor/DefaultTranslates/PaymentTranslation';
import PackagesTranslations from 'pages/constructor/DefaultTranslates/PackagesTranslation';
import Reports from 'pages/reports/Reports';
import AutoReports from 'pages/reports/Autoreports';
import Withdrawals from 'pages/Withdrawals';
import WithdrawalsLogs from 'pages/walletsDebugging/logs/WithdrawalsLogs';
import ChangeLogs from 'pages/ChangeLogs';
import AccountsBalance from 'pages/balances/accountsBalance/AccountsBalance';
import ArchivedTransactions from 'pages/transactions/ArchivedTransactions';
import PaymentPlugin from 'pages/constructor/PaymentPlugin';
import NoPermissionsPage from 'pages/errorPages/NoPermissionsPage';
import NotFoundPage from 'pages/errorPages/NotFoundPage';
import RemovedWallets from 'pages/wallets/RemovedWallets';
import TariffsConstructor from 'pages/constructor/TariffsConstructor';
import OperationHistory from 'pages/balances/accountsBalance/OperationHistory';
import StatisticWallets from 'pages/wallets/StatisticWallets';

interface IRoute {
  path: string;
  component: (props: unknown) => JSX.Element | null;
  exact?: boolean;
}

let appRoutes: IRoute[] = [
  {
    path: `/`,
    component: Dashboard,
    exact: true,
  },
  {
    path: `/wallets`,
    component: Wallets,
  },
  {
    path: `/statistic-wallets`,
    component: StatisticWallets,
  },
  {
    path: `/wallets-load`,
    component: WalletsLoad,
  },
  {
    path: `/proxies`,
    component: Proxies,
  },
  {
    path: `/transactions`,
    component: Transactions,
  },
  {
    path: `/withdrawals`,
    component: Withdrawals,
  },
  {
    path: `/transactions-only-by-id`,
    component: TransactionsOnlyById,
  },
  {
    path: `/possible-transactions`,
    component: PossibleTransactions,
  },
  {
    path: `/archive-transactions`,
    component: ArchivedTransactions,
  },
  {
    path: `/settings`,
    component: Settings,
  },
  {
    path: `/stockpiling`,
    component: StockpilingList,
  },
  {
    path: `/postbacks`,
    component: Postbacks,
  },
  {
    path: `/logs`,
    component: Logs,
  },
  {
    path: `/withdrawals-logs`,
    component: WithdrawalsLogs,
  },
  {
    path: `/disabled-wallets`,
    component: DisabledWallets,
  },
  {
    path: `/removed-wallets`,
    component: RemovedWallets,
  },
  {
    path: `/not-accepted-postbacks`,
    component: NotAcceptedPostbacks,
  },
  {
    path: `/payments-constructor`,
    component: PaymentsConstructor,
  },
  {
    path: `/tariffs-constructor`,
    component: TariffsConstructor,
  },
  {
    path: `/payment-translations`,
    component: PaymentTranslations,
  },
  {
    path: `/packages-translations`,
    component: PackagesTranslations,
  },
  {
    path: `/view-plugin`,
    component: PaymentPlugin,
  },
  {
    path: `/reports`,
    component: Reports,
  },
  {
    path: `/autoreports`,
    component: AutoReports,
  },
  {
    path: `/change-logs`,
    component: ChangeLogs,
  },
  {
    path: `/accounts-balance`,
    component: AccountsBalance,
  },
  {
    path: `/operation-history`,
    component: OperationHistory,
  },
];

appRoutes = map(appRoutes, (item) => ({ ...item, component: userIsAuthenticated(item.component) as any }));

const routes = [
  ...appRoutes,
  {
    path: `/login`,
    component: userIsAnonymous(Login as any),
  },
  {
    path: `/no-permissions`,
    component: NoPermissionsPage,
  },
  {
    path: `*`,
    component: NotFoundPage,
  },
];

const Root: FC = () => (
  <Switch>
    {map(routes, (route, key) => (
      <Route key={key} {...route} />
    ))}
  </Switch>
);

export default Root;
