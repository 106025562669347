import React, { Key } from 'react';
import { useSelector } from 'react-redux';
import { widgetModalSelector, Modal as KassmaModal, IWidgetModal } from '@kassma-team/kassma-toolkit/lib';

export interface IModalProps {
  component: any; // todo try to change type
  widget?: string;
  type: number;
  modal?: IWidgetModal;
  key?: Key | null;
}

const Modal = ({ widget, ...props }: IModalProps) => {
  const modal = useSelector(widgetModalSelector(widget));

  return <KassmaModal {...props} modal={modal} />;
};

export default Modal;
