import { Dispatch } from 'redux';

import { InjectedFormProps, SubmissionError, getFormSyncErrors, reduxForm, updateSyncErrors } from 'redux-form';
import { WidgetType } from 'utils/enums';
import ModalForm from 'components/modals/ModalForm';
import { POSSIBLE_TRANSACTION_APPROVE_FORM } from 'utils/constants';
import React, { useEffect, useState } from 'react';
import {
  approvePossibleTransaction,
  possibleTransactionActionCreators,
} from 'actions/widgets/transactions/possibleTransactions';
import { InputType, ModalElement, widgetSelector } from '@kassma-team/kassma-toolkit';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { widgetModalSelector } from '@kassma-team/kassma-toolkit/lib';
import { supportedWalletCurrenciesSelector } from 'selectors/widgets/paymentSystems';
import api from 'api';
import { INotRemovedWallet } from 'interfaces/widgets/wallets/wallet';
import { filter, get, map } from 'lodash';

const onSubmit = (values: any, dispatch: Dispatch) => {
  dispatch(approvePossibleTransaction(values));
};

const PossibleTransactionApproveModal = (props: InjectedFormProps) => {
  const modal: any = useSelector(widgetModalSelector(WidgetType.POSSIBLE_TRANSACTIONS));
  const [t] = useTranslation();
  const { id, amount, wallet_type, currency_symbol } = modal || {};
  const currencies = useSelector(supportedWalletCurrenciesSelector(wallet_type as string));
  const widget = useSelector(widgetSelector(WidgetType.POSSIBLE_TRANSACTIONS));

  const [isWalletsLoading, setIsWalletsLoading] = useState(false);
  const [wallets, setWallets] = useState([]);

  useEffect(() => {
    const fetcher = async () => {
      try {
        setIsWalletsLoading(true);
        const { data } = await api.wallet.loadList({ type: wallet_type });

        if (data.status === `ok`) {
          setWallets(data.wallets);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsWalletsLoading(false);
      }
    };

    fetcher();
  }, []);

  const formatModalErrors = (errors: Record<string, string>): string => {
    let string = ``;
    Object.keys(errors).map((key) => {
      string += `${key}: ${errors[key].map((el) => el)}\n`;
    });

    return string;
  };

  useEffect(() => {
    props.change(`amount`, amount);
  }, [amount]);

  useEffect(() => {
    props.change(`id`, id);
  }, [id]);

  useEffect(() => {
    props.change(`currency`, currency_symbol);
  }, [currency_symbol]);

  const fields = [
    {
      name: `amount`,
      label: t(`common.amount`),
      digital: true,
      required: true,
    },
    {
      name: `currency`,
      type: InputType.SELECT,
      label: t(`common.currency`),
      required: true,
      data: currencies,
    },
    {
      name: `wallet_id`,
      type: InputType.SELECT,
      label: t(`transactions.walletNumber`),
      data: wallets.map((el) => ({ text: el.identifier, value: el.id, ...el })),
      busy: isWalletsLoading,
      itemComponent: ({ item }) => {
        if (!item.is_active) {
          return <span className="text-danger">{item.text}</span>;
        }

        return item.text;
      },
    },
  ];

  return (
    <ModalForm
      {...props}
      fields={fields}
      submitText="common.approve"
      onSubmit={onSubmit}
      title="transactions.possibleTransactionApproval"
      actionCreators={possibleTransactionActionCreators}
      widget={WidgetType.POSSIBLE_TRANSACTIONS}
      error={widget.modalError && formatModalErrors(widget.modalError)}
      forcedShowingError={true}
    />
  );
};

export default reduxForm({
  form: POSSIBLE_TRANSACTION_APPROVE_FORM,
  onSubmit,
  forceUnregisterOnUnmount: true,
})(PossibleTransactionApproveModal);
