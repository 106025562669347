import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WalletImg, useFilterColumns, Dropdown } from '@kassma-team/kassma-toolkit/lib';
import DefaultIcon from 'assets/media/icons/defaultIcon.svg';

import './postbackRow.scss';

import { notAcceptedPostbackActionCreators, resendWithdrawalsPostback } from 'actions/widgets/postback';
import {
  ModalType,
  PostbackSendStatus,
  PostbackType,
  StatusCode,
  WidgetType,
  PostbacksColumn,
  NotAcceptedPostbacksColumn,
} from 'utils/enums';
import { formatDate } from 'utils/formatDate';
import { IPostbackRowProps } from 'interfaces/widgets/postback';
import { widgetSelectedItemsSelector } from '@kassma-team/kassma-toolkit';
import { ReactComponent as Details } from 'assets/media/icons/details.svg';
import { Direction } from '../transaction/TransactionRow';
import { paymentSystemLogoSelector } from 'selectors/widgets/paymentSystems';
import { hasAccessSelector } from 'selectors/auth';
import { Permission } from 'utils/enums/commonEnums';

const { selectItem, unselectItem } = notAcceptedPostbackActionCreators;

const postbackParamsByType = {
  [PostbackType.RECEIPT]: {
    name: `withdrawals.receipt`,
    badge: `badge-primary`,
  },
  [PostbackType.WITHDRAWAL]: {
    name: `withdrawals.withdrawal`,
    badge: `badge-warning`,
  },
};

const PostbackRow = ({
  id,
  desktopSize,
  url,
  label,
  wallet_type,
  test_wallet_type,
  status_code,
  response,
  status,
  created_at,
  actionCreators,
  widget,
  stockpiling_id,
  direction,
  hiddenColumns,
  count_failure,
  merchant_order_id,
}: IPostbackRowProps) => {
  const dispatch = useDispatch();

  const selectedItems: any = useSelector(widgetSelectedItemsSelector(WidgetType.NOT_ACCEPTED_POSTBACKS));

  const [t] = useTranslation();
  const logo = useSelector(paymentSystemLogoSelector(wallet_type));
  const walletTypeImage = <WalletImg title={logo ? wallet_type : `Default Icon`} src={logo || DefaultIcon} />;

  const statusCode = (
    <span
      className={classNames({
        'text-danger': status_code !== StatusCode.OK,
        'text-success': status_code === StatusCode.OK,
      })}
    >
      {status_code}
    </span>
  );

  const postbackType = direction === Direction.INGOING ? PostbackType.RECEIPT : PostbackType.WITHDRAWAL;

  const postbackTypeField = (
    <div className="multi-badge-cell">
      <span className={`badge ${postbackParamsByType[postbackType]?.badge}`}>
        {t(postbackParamsByType[postbackType]?.name)}
      </span>
    </div>
  );

  const statusIcon = status === PostbackSendStatus.SEND && <i className="fa fa-check" />;

  const hasSendingPermission = useSelector(hasAccessSelector(Permission.POSTBACK_SENDING));

  const postbackBtns = (
    <div>
      <Dropdown
        className="counterparty-btn__item--right"
        insideContent={(onClose: () => void) => {
          return (
            <>
              <button
                className="dropdown__list-button postback-btn__item"
                onClick={() => {
                  dispatch(actionCreators.showModal({ type: ModalType.SHOW, id }));
                  onClose();
                }}
              >
                {t(`postbacks.requestBody`)}
              </button>
              {widget === WidgetType.POSTBACKS && hasSendingPermission && id && (
                <button
                  className="dropdown__list-button postback-btn__item"
                  onClick={() => {
                    dispatch(actionCreators.showModal({ type: ModalType.UPDATE, id }));
                    onClose();
                  }}
                >
                  {t(`postbacks.resendPostback`)}
                </button>
              )}
              {widget === WidgetType.NOT_ACCEPTED_POSTBACKS && hasSendingPermission && id && (
                <button
                  className="dropdown__list-button postback-btn__item"
                  onClick={() => {
                    dispatch(resendWithdrawalsPostback({ id }));
                    onClose();
                  }}
                >
                  {t(`postbacks.resendPostback`)}
                </button>
              )}
              {widget === WidgetType.NOT_ACCEPTED_POSTBACKS && hasSendingPermission && count_failure !== 0 && (
                <button
                  className="dropdown__list-button postback-btn__item"
                  onClick={() => {
                    dispatch(
                      notAcceptedPostbackActionCreators.showModal({ type: ModalType.APPROVAL_CONFIRMATION, id: [id] })
                    );
                    onClose();
                  }}
                >
                  {t(`postbacks.clearCountFailure`)}
                </button>
              )}
              {widget === WidgetType.NOT_ACCEPTED_POSTBACKS && hasSendingPermission && (
                <button
                  className="dropdown__list-button postback-btn__item"
                  onClick={() => {
                    dispatch(notAcceptedPostbackActionCreators.showModal({ type: ModalType.UPDATE, id: [id] }));
                    onClose();
                  }}
                >
                  {t(`postbacks.markAsSent`)}
                </button>
              )}
            </>
          );
        }}
      >
        <button type="button" className="postback-btn__item postback-btn__item--right">
          <Details />
        </button>
      </Dropdown>
    </div>
  );

  const formattedCreatedAt = formatDate(created_at);

  const checkboxField = widget === WidgetType.NOT_ACCEPTED_POSTBACKS && (
    <input
      checked={selectedItems.has(id)}
      type="checkbox"
      onChange={({ target: { checked } }) => {
        if (checked) {
          dispatch(selectItem(id));
        } else {
          dispatch(unselectItem(id));
        }
      }}
    />
  );

  const orderIdLabel = <span className="badge badge-info">{merchant_order_id}</span>;
  const labelBadge = label && <span className="badge badge-success">{label}</span>;

  const columns = [
    ...(widget === WidgetType.NOT_ACCEPTED_POSTBACKS
      ? [
          {
            id: NotAcceptedPostbacksColumn.CHECKBOX,
            desktop: <td>{checkboxField}</td>,
            mobile: (
              <tr className="first-mobile-row">
                <td>{checkboxField}</td>
              </tr>
            ),
          },
        ]
      : []),
    {
      id: PostbacksColumn.ID,
      desktop: (
        <th className="text-center" scope="row">
          {id}
        </th>
      ),
      mobile: (
        <tr className="first-mobile-row">
          <td>ID</td>
          <td>{id}</td>
        </tr>
      ),
    },
    {
      id: PostbacksColumn.URL,
      desktop: <td>{url}</td>,
      mobile: (
        <tr>
          <td>{t(`postbacks.url`)}</td>
          <td>{url}</td>
        </tr>
      ),
    },
    {
      id: PostbacksColumn.STATUS_CODE,
      desktop: <td>{statusCode}</td>,
      mobile: (
        <tr>
          <td>{t(`postbacks.statusCode`)}</td>
          <td>{statusCode}</td>
        </tr>
      ),
    },
    ...(widget === WidgetType.NOT_ACCEPTED_POSTBACKS
      ? [
          {
            id: NotAcceptedPostbacksColumn.COUNT_FAILURE,
            desktop: <td>{count_failure}</td>,
            mobile: (
              <tr>
                <td>{t(`postbacks.countFailure`)}</td>
                <td>{count_failure}</td>
              </tr>
            ),
          },
        ]
      : []),
    {
      id: PostbacksColumn.RESPONSE,
      desktop: <td>{response}</td>,
      mobile: (
        <tr>
          <td>{t(`postbacks.response`)}</td>
          <td className="text-break-word">{response}</td>
        </tr>
      ),
    },
    {
      id: PostbacksColumn.MERCHANT_ORDER_ID,
      desktop: (
        <td className="multi-badge-cell">
          {labelBadge}
          {merchant_order_id && orderIdLabel}
        </td>
      ),
      mobile: (
        <>
          <tr>
            <td>{t(`common.label`)}</td>
            <td>{labelBadge}</td>
          </tr>

          {merchant_order_id && (
            <tr>
              <td>{t(`common.labelOrderId`)}</td>
              <td>{merchant_order_id && orderIdLabel}</td>
            </tr>
          )}
        </>
      ),
    },
    {
      id: PostbacksColumn.WALLET_TYPE,
      desktop: <td>{walletTypeImage}</td>,
      mobile: (
        <tr>
          <td>{t(`wallets.walletType`)}</td>
          <td>{walletTypeImage}</td>
        </tr>
      ),
    },
    {
      id: PostbacksColumn.STATUS,
      desktop: <td>{statusIcon}</td>,
      mobile: (
        <tr>
          <td>{t(`common.status`)}</td>
          <td>{statusIcon}</td>
        </tr>
      ),
    },
    {
      id: PostbacksColumn.TYPE,
      desktop: <td>{postbackTypeField}</td>,
      mobile: (
        <tr>
          <td>{t(`common.type`)}</td>
          <td>{postbackTypeField}</td>
        </tr>
      ),
    },
    {
      id: PostbacksColumn.CREATED_AT,
      desktop: <td>{formattedCreatedAt}</td>,
      mobile: (
        <tr>
          <td>{t(`common.createdAt`)}</td>
          <td>{formattedCreatedAt}</td>
        </tr>
      ),
    },
    {
      id: PostbacksColumn.ACTIONS,
      desktop: <td>{postbackBtns}</td>,
      mobile: (
        <tr>
          <td>{t(`common.actions`)}</td>
          <td>{postbackBtns}</td>
        </tr>
      ),
    },
  ];

  return useFilterColumns({ columns, desktopSize, hiddenColumns });
};

export default PostbackRow;
