import { Reducer } from 'redux';
import { IWidgetsState } from '@kassma-team/kassma-toolkit/lib/types/widgets';

import { UPDATE_TOTAL_SETTLEMENT_BALANCE } from 'actionTypes';

const actionTypes = new Set([UPDATE_TOTAL_SETTLEMENT_BALANCE]);

//TODO: Убрать
const defaultState: Partial<IWidgetsState> = {
  page: 1,
};

const reducer: Reducer = (state: IWidgetsState, { type, payload }) => {
  switch (type) {
    case UPDATE_TOTAL_SETTLEMENT_BALANCE:
      return {
        ...defaultState,
        ...state,
        available_balance: payload,
      };
    default:
      return state;
  }
};

const settelements = { reducer, actionTypes };

export default settelements;
