import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import { ICreateWithdrawalForm } from 'interfaces/widgets/withdrawals';

export class WithdrawalV2Api extends BaseApi {
  createWithdrawal = (data: ICreateWithdrawalForm): AxiosPromise => this.makeFetch(`create`, { data, method: `POST` });
}

const withdrawalV2Api = new WithdrawalV2Api(`v2/withdrawal`);

export default withdrawalV2Api;
