import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getFormValues, WrappedFieldArrayProps } from 'redux-form';
import classNames from 'classnames';
import { InputType } from '@kassma-team/kassma-toolkit';
import { useSelector } from 'react-redux';
import map from 'lodash/map';
import get from 'lodash/get';

import { ILangList } from 'interfaces/widgets/constructor/langList';

import FormField from 'components/form/FormField';

interface IProps {
  title?: string;
  disabled?: boolean;
  wrapClassName?: string;
  name?: string;
  showAddButton?: boolean;
  showDeleteButtons?: boolean;
  form: string;
  getFilterLangs: (props: string[]) => ILangList[];
}

export type ITranslationsArrayFields = (props: IProps, fieldArrayProps: WrappedFieldArrayProps) => ReactNode;

const TranslationsArrayFields: ITranslationsArrayFields = (
  {
    title,
    wrapClassName,
    disabled,
    name = `translates`,
    showAddButton = true,
    showDeleteButtons = true,
    form,
    getFilterLangs,
  },
  { fields }
) => {
  const [t] = useTranslation();

  const formValues = useSelector(getFormValues(form));
  const selectedList = useMemo(() => map(get(formValues, fields.name), (value) => value.lang), [formValues]);

  const inputs = useMemo(
    () => [
      {
        placeholder: t(`paymentConstructor.lang`),
        name: `lang`,
        required: true,
        wrapClassName: `w-25 mr-5`,
        type: InputType.SELECT,
        data: getFilterLangs(selectedList),
      },
      {
        placeholder: t(`paymentConstructor.hintText`),
        name: `form_text`,
        required: true,
        maxLength: 300,
        wrapClassName: `w-75 mr-5`,
      },
    ],
    [selectedList]
  );

  return (
    <div className="mb-15">
      <div className="flex space-between align-items-center mb-10">
        {title && <span className="font-weight-bold">{t(title)}</span>}
        {showAddButton && (
          <button
            className="btn btn-alt-success"
            type="button"
            disabled={disabled}
            id={`${name}_add_btn`}
            onClick={() => fields.push({})}
          >
            {t(`common.add`)}
          </button>
        )}
      </div>
      {fields.map((field, index) => {
        return (
          <div key={index} className={classNames(wrapClassName, `flex space-between mb-10 align-items-start`)}>
            {inputs.map((input, key) => {
              return <FormField {...input} arrayField key={key} formGroup={false} name={`${field}.${input.name}`} />;
            })}
            {showDeleteButtons && (
              <button
                className="ml-10"
                type="button"
                id={`${name}_${index}_remove_btn`}
                disabled={fields.length <= 1}
                onClick={() => {
                  fields.remove(index);
                }}
              >
                <i
                  className={classNames(`fa fa-close text-danger font-size-xl mt-7`, { disabled: fields.length <= 1 })}
                />
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TranslationsArrayFields;
