import { TransactionField } from 'utils/enums';
import { TFunction } from 'react-i18next';

export const getListCompletedReportFieldList = (t: TFunction<`translation`>, vendorCodePermission: boolean) => {
  return [
    {
      value: TransactionField.WALLET_IDENTIFIER,
      text: t(`wallets.walletIdentifier`),
    },
    {
      value: TransactionField.TRANSACTION_NUMBER,
      text: t(`transactions.transactionId`),
    },
    ...(vendorCodePermission
      ? [
          {
            value: TransactionField.VENDOR_CODE,
            text: t(`wallets.vendorCode`),
          },
        ]
      : []),
    {
      value: TransactionField.MERCHANT_ORDER_ID,
      text: t(`reports.orderId`),
    },
    {
      value: TransactionField.EXCHANGER_IDENTIFIER,
      text: t(`reports.counterparty`),
    },
    {
      value: TransactionField.COMMISSION,
      text: t(`reports.commission`),
    },
    {
      value: TransactionField.STOCKPILING_END_DATE,
      text: t(`reports.stockpilingEndDate`),
    },
    {
      value: TransactionField.ARCHIVING_DATES,
      text: t(`reports.archivingDate`),
    },
    {
      value: TransactionField.LABEL,
      text: t(`common.label`),
    },
    {
      value: TransactionField.TYPE,
      text: t(`transactions.transactionType`),
    },
    {
      value: TransactionField.KIND_OF_TRANSACTION,
      text: t(`transactions.kindOfTransaction`),
    },
    {
      value: TransactionField.POSTBACK_STATUS,
      text: t(`transactions.postbackStatus`),
    },
    {
      value: TransactionField.STOCKPILING_ID,
      text: t(`reports.stockpilingId`),
    },
  ];
};
