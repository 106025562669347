import React from 'react';
import { Filters as KassmaFilters, sleep } from '@kassma-team/kassma-toolkit/lib';
import { useSelector } from 'react-redux';
import { getFormValues, Field } from 'redux-form';

import useSearchParams from 'hooks/useSearchParams';
import { IFilterProps } from 'interfaces/widgets/widgets';

import FormField from 'components/form/FormField';

const Filters = (props: IFilterProps) => {
  const values = useSelector(getFormValues(props.formName));
  const searchParams = useSearchParams();

  // const isNeedToPassOnReset = (searchParams: ReturnType<typeof useSearchParams>) => {
  //   return `archiving_status` in searchParams;
  // };

  const getOnReset = (searchParams: ReturnType<typeof useSearchParams>) => async () => {
    // props.initialize({ archiving_status: searchParams.archiving_status });
    await sleep(100);
    // @ts-expect-error
    props.submit();
  };

  return (
    <KassmaFilters
      {...props}
      FormField={FormField}
      values={values}
      searchParams={searchParams}
      ReduxFormField={Field}
    />
  );
};

export default Filters;
