import React from 'react';
import { useTranslation } from 'react-i18next';
import { InnerTable } from '@kassma-team/kassma-toolkit/lib';

import useFullAmount from 'hooks/useFullAmount';
import { transactionParamsByType } from 'static/transactions';
import { IStockpilingTransactionRowProps } from 'interfaces/widgets/stockpiling';
import { formatDate } from 'utils/formatDate';

const StockpilingTransactionRow = ({
  desktopSize,
  wallet_identifier,
  amount,
  symbol,
  created_at,
  id,
  type,
  is_archived,
}: IStockpilingTransactionRowProps) => {
  const [t] = useTranslation();

  const fullAmount = useFullAmount({
    amount: amount,
    currencySymbol: symbol,
  });

  const typeField = (
    <span className={`badge ${transactionParamsByType[type as string].color}`}>
      {t(transactionParamsByType[type as string].message)}
    </span>
  );

  const formattedCreatedAt = formatDate(created_at);

  if (desktopSize) {
    return (
      <tr>
        <td>{wallet_identifier}</td>
        <td>{id}</td>
        <td>{fullAmount}</td>
        <td>{formattedCreatedAt}</td>
        <td>{typeField}</td>
        <td className="text-center">{is_archived && <i className="fa fa-check" />}</td>
      </tr>
    );
  }

  return (
    <InnerTable cellClassName="mobile-inner-table">
      <tr className="first-mobile-row">
        <td>{t(`wallets.walletIdentifier`)}</td>
        <td>{wallet_identifier}</td>
      </tr>
      <tr>
        <td>{t(`transactions.transactionNumber`)}</td>
        <td>{id}</td>
      </tr>
      <tr>
        <td>{t(`transactions.transactionAmount`)}</td>
        <td>{fullAmount}</td>
      </tr>
      <tr>
        <td>{t(`common.date`)}</td>
        <td>{formattedCreatedAt}</td>
      </tr>
      <tr>
        <td>{t(`transactions.transactionType`)}</td>
        <td>{typeField}</td>
      </tr>
      {is_archived && (
        <tr>
          <td>{t(`transactions.archivedTransaction`)}</td>
          <td>
            <i className="fa fa-check" />
          </td>
        </tr>
      )}
    </InnerTable>
  );
};

export default StockpilingTransactionRow;
