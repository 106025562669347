import { useState, useEffect } from 'react';
import api, { Hint, UpdateParams, CreateParams } from 'api/translations/TranslationsApi';
import { toastr } from 'react-redux-toastr';
import { useModifiedHintsCtx } from '../ctx';
import { useTranslation } from 'react-i18next';
import { useFormWasSubmittedCtx, usePaymentSystemsCtx } from '../ctx';
import refreshHelper from 'utils/refreshHelper';

export type UseHints = {
  isLoading: boolean;
  hints: Array<Hint>;
  handeDeleteHint: (id: string) => () => Promise<void>;
  handleUpdateHint: (id: string, body: UpdateParams) => () => Promise<void>;
  handleCreateHint: (body: CreateParams) => () => Promise<void>;
  fetchHints: () => Promise<void>;

  isHintUpdating: boolean;
  isHintDeleting: boolean;
  isHintCreating: boolean;
};

/**
 * useHints
 *
 * Хранит список подсказок-переводов
 *
 * Позволяет удалить подсказку с сервера
 *
 * Позволяет обновить подсказку на сервере
 *
 * Позволяет создать подсказку на сервере
 */
export function useHints(): UseHints {
  const [isLoading, setIsLoading] = useState(false);
  const [isHintDeleting, setIsHintDeleting] = useState(false);
  const [isHintUpdating, setIsHintUpdating] = useState(false);
  const [isHintCreating, setIsHintCreating] = useState(false);
  const [hints, setHints] = useState<UseHints[`hints`]>([]);
  const [t] = useTranslation();
  const { setModifiedHints } = useModifiedHintsCtx();
  const { setFormSubmitted } = useFormWasSubmittedCtx();
  const { currentPaymentSystem } = usePaymentSystemsCtx();

  const fetchHints = async (queryParams: Record<string, string> = {}) => {
    setIsLoading(true);
    await refreshHelper({
      request: () =>
        api.fetchHints({
          wallet_type: currentPaymentSystem?.code || ``,
          ...queryParams,
        }),
      onSuccess: ({ data }: any) => {
        setHints(data.data);
      },
      onFinally: () => {
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    if (currentPaymentSystem) {
      fetchHints();
    }
  }, [currentPaymentSystem]);

  const handeDeleteHint = (id: string) => async () => {
    setIsHintDeleting(true);

    await refreshHelper({
      request: () => api.deleteHint(id),
      onFinally: () => {
        setIsHintDeleting(false);
      },
    });
  };

  const handleUpdateHint = (id: string, body: UpdateParams) => async () => {
    setIsHintUpdating(true);
    const trimmed: UpdateParams = {
      name: body.name,
      is_manual: body.is_manual,
      // Убираем клиентский uuid (Поле clientId)
      translations: body.translations.map(({ language, text }) => {
        return {
          language,
          text,
        };
      }),
    };

    await refreshHelper({
      request: () => api.updateHint(id, trimmed),
      onSuccess: ({ data }: any) => {
        if (data.status === `success`) {
          setModifiedHints([]);
          toastr.success(t(`common.success`), t(`common.successUpdate`));
          setFormSubmitted(false);
        }

        if (data.status === `failed` || data.status === `error`) {
          toastr.error(t(`common.error`), t(`common.errorUpdate`));
        }
      },
      onFinally: () => {
        setIsHintUpdating(false);
      },
    });
  };

  const handleCreateHint = (body: CreateParams) => async () => {
    setIsHintCreating(true);
    await refreshHelper({
      request: () => api.createHint(body),
      onSuccess: async ({ data }: any) => {
        if (data.status === `success`) {
          await fetchHints();
          toastr.success(t(`common.success`), t(`common.successUpdate`));
          setModifiedHints([]);
        }

        if (data.error_message) {
          if (data.errors && data.errors.length) {
            data.errors.forEach((err: Error) => {
              toastr.error(t(`common.error`), err.message);
            });

            return;
          }

          toastr.error(t(`common.error`), data.error_message);
        }

        // if (data.status === `error` || data.status === `failed`) {
        //   toastr.error(data.status, data.error_message);
        // }
      },
      onFinally: () => {
        setIsHintCreating(false);
      },
    });
  };

  return {
    isLoading,
    hints,
    fetchHints,

    handeDeleteHint,
    handleUpdateHint,
    handleCreateHint,

    isHintUpdating,
    isHintDeleting,
    isHintCreating,
  };
}
