import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import { getSubmitHandler, GridType, InputType, widgetSelector } from '@kassma-team/kassma-toolkit';
import { WidgetType } from 'utils/enums';
import { useAccountBalanceHistoryTitles } from 'hooks/widgets/tableTitles/useAccountBalanceHistoryTitles';
import useSearchParams from 'hooks/useSearchParams';
import TableContentContainer from 'components/containers/TableContentContainer';
import Filters from 'components/widgets/filters/Filters';
import WidgetTable from 'components/widgets/WidgetTable';
import { accountsBalanceHistoryActionCreators } from 'actions/widgets/balances/accountsBalanceHistory';
import { BalanceHistoryRow } from 'components/widgets/balances/BalanceHistoryRow';
import { settlementPaymentSystemByIdSelector } from 'selectors/widgets/balances';
import { transactionDirectionList } from 'static/transactions';

const FORM_NAME = `settlements-filters`;

const FilterForm = reduxForm<any, any>({
  form: FORM_NAME,
  onSubmit: getSubmitHandler({
    dateFields: [{ name: `date_range`, fromName: `date_from`, toName: `date_to` }],
    dateFormat: `YYYY-MM-DD HH:mm:ss`,
  }),
})(Filters as any);

const historyListMapping = (item: any, key: number) => <BalanceHistoryRow key={item.id || key} {...item} />;

const AccountsBalanceHistory: FC = () => {
  const [t] = useTranslation();

  const historyTitles = useAccountBalanceHistoryTitles();

  const { items: historyItems }: any = useSelector(widgetSelector(WidgetType.ACCOUNT_BALANCE_HISTORY));

  const queryParams = useSearchParams();
  const id: any = queryParams?.id || ``;
  const isSettlement: any = useSelector(settlementPaymentSystemByIdSelector(id || ``));

  const leftFilterFields = [
    {
      name: `direction`,
      id: `${FORM_NAME}_direction`,
      data: transactionDirectionList(t(`common.all`)),
      translateKeys: true,
      type: InputType.SELECT,
      placeholder: t(`transactions.direction`),
    },
    ...[
      isSettlement
        ? {
            name: `operation_type`,
            type: InputType.SELECT,
            data: [
              {
                value: `1`,
                text: `balances.accountBalance`,
              },
              {
                value: `2`,
                text: `balances.externalAccount`,
              },
            ],
            translateKeys: true,
            placeholder: t(`balances.operationType`),
          }
        : undefined,
    ],
    {
      name: `date_range`,
      type: InputType.DATE_RANGE,
    },
  ];

  const filterDateFields = [
    {
      from: `date_from`,
      to: `date_to`,
      name: `date_range`,
    },
  ];

  return (
    <TableContentContainer title="wallets.operationsHistory" onRefresh={accountsBalanceHistoryActionCreators.getList}>
      <FilterForm
        submitText={`common.apply`}
        leftFields={leftFilterFields}
        formName={FORM_NAME}
        gridType={GridType.FLEX}
        dateFields={filterDateFields}
      />
      <WidgetTable
        data={historyItems}
        titles={historyTitles}
        listMapping={historyListMapping}
        widget={WidgetType.ACCOUNT_BALANCE_HISTORY}
      />
    </TableContentContainer>
  );
};

export default AccountsBalanceHistory;
