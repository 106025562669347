import {
  createWidgetAction,
  widgetActionCreators,
  IWidgetActionCreators,
  IWidgetsAction,
} from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import {
  ACTIVATE_TRANSACTION_REQUESTED,
  APPROVE_TRANSACTION_REQUESTED,
  ARCHIVATE_TRANSACTION_REQUESTED,
  DOWNLOAD_TRANSACTION_CSV_LIST_FAILED,
  DOWNLOAD_TRANSACTION_CSV_LIST_REQUESTED,
  DOWNLOAD_TRANSACTION_CSV_LIST_SUCCEEDED,
  UPLOAD_TRANSACTIONS,
} from 'actionTypes';
import { CREATE_TRANSACTION_FORM_NAME } from 'utils/constants';
import { IWidgetLabels } from 'interfaces/widgets/widgets';
import { IActivateTransaction } from 'interfaces/widgets/transaction/transaction';

const transactionPayloadLabels: IWidgetLabels = {
  listLabel: `transactions`,
  itemLabel: `transaction`,
};

export const transactionActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.TRANSACTIONS,
  labels: transactionPayloadLabels,
  creatingForm: CREATE_TRANSACTION_FORM_NAME,
  uniqKey: `primary_id`,
  withPagination: true,
});

export const downloadTransactionCSVListRequest: IWidgetActionCreators = createWidgetAction({
  widget: WidgetType.TRANSACTIONS,
})(DOWNLOAD_TRANSACTION_CSV_LIST_REQUESTED);

export const downloadTransactionCSVListSuccess: IWidgetActionCreators = createWidgetAction({
  widget: WidgetType.TRANSACTIONS,
})(DOWNLOAD_TRANSACTION_CSV_LIST_SUCCEEDED);

export const downloadTransactionCSVListFail: IWidgetActionCreators = createWidgetAction({
  widget: WidgetType.TRANSACTIONS,
})(DOWNLOAD_TRANSACTION_CSV_LIST_FAILED);

export const uploadTransactionsRequest: IWidgetActionCreators = createWidgetAction({
  widget: WidgetType.TRANSACTIONS,
})(UPLOAD_TRANSACTIONS);

export const transactionsOnlyByIdActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.TRANSACTIONS_ONLY_BY_ID,
  labels: transactionPayloadLabels,
  withPagination: true,
});

export const activateTransaction = ({ primaryId, payload, widget, onError }: IActivateTransaction): IWidgetsAction =>
  createWidgetAction({
    widget,
    primaryId,
    onError,
  })(ACTIVATE_TRANSACTION_REQUESTED)(payload);

export const approveTransaction = ({
  transactions,
  is_fake,
  widget,
  onError,
}: {
  transactions: number[];
  is_fake: boolean;
  widget?: string;
  onError?: (err: any) => void;
}) => ({
  type: APPROVE_TRANSACTION_REQUESTED,
  payload: {
    transactions,
    is_fake,
    widget,
    onError,
  },
});

export const archivateTransaction: IWidgetActionCreators = createWidgetAction({
  widget: WidgetType.TRANSACTIONS,
})(ARCHIVATE_TRANSACTION_REQUESTED);
