import { createWidgetAction, widgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import {
  TRANSFER_MONEY_REQUESTED,
  TRANSFER_MONEY_SUCCEEDED,
  TRANSFER_TO_ANOTHER_ACCOUNT_REQUESTED,
  BALANCE_HISTORY_LIST_REQUESTED,
} from 'actionTypes';

export const accountsBalancesActionCreators = widgetActionCreators({
  widget: WidgetType.ACCOUNTS_BALANCES,
  labels: { listLabel: `balances` },
});

export const transferMoney = createWidgetAction({
  widget: WidgetType.ACCOUNTS_BALANCES,
})(TRANSFER_MONEY_REQUESTED);

export const transferMoneySucceeded = createWidgetAction({
  widget: WidgetType.ACCOUNTS_BALANCES,
})(TRANSFER_MONEY_SUCCEEDED);

export const transferToAnotherAccount = createWidgetAction({
  widget: WidgetType.ACCOUNTS_BALANCES,
})(TRANSFER_TO_ANOTHER_ACCOUNT_REQUESTED);

export const accountBalanceHistoryList = createWidgetAction({
  widget: WidgetType.ACCOUNTS_BALANCES,
  labels: { listLabel: `balances` },
  statusBar: true,
})(BALANCE_HISTORY_LIST_REQUESTED);
