import some from 'lodash/some';
import has from 'lodash/has';

import useSearchParams from 'hooks/useSearchParams';

interface IParticularFiltersReturns {
  loadOnlyFilters: string[];
  refreshingIsDisabled: boolean;
}

const useParticularFilters = (requiredFilters: string[]): IParticularFiltersReturns => {
  const search = useSearchParams();

  return {
    loadOnlyFilters: requiredFilters,
    refreshingIsDisabled: !some(requiredFilters, (field) => has(search, field)),
  };
};

export default useParticularFilters;
