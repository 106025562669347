import useList from '../useList';
import { IPostbackItem } from '../../../interfaces/widgets/postback';
import { postbackActionCreators } from '../../../actions/widgets/postback';
import { WidgetType } from '../../../utils/enums';
import get from 'lodash/get';
import useItem from '../useItem';

const useTransactionPostbackList = ({ shouldUseItem, ...rest }: any): any => {
  const modal = rest.modal;
  const config = {
    widget: rest.listActionCreators !== postbackActionCreators ? rest.widget : WidgetType.POSTBACKS,
    actionCreators: rest.listActionCreators,
    queryParams: {
      stockpiling_id: get(modal, `id`),
      withdrawal_id: get(modal, `withdrawal_id`),
      not_accepted: get(modal, `not_accepted`),
      direction: get(modal, `direction`),
    },
  };
  if (shouldUseItem) {
    const { item, itemLoading } = useItem<IPostbackItem>(config);

    return {
      items: item,
      listLoading: itemLoading,
    };
  } else {
    return useList<IPostbackItem>(config);
  }
};

export default useTransactionPostbackList;
