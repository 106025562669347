import { createSelector } from 'reselect';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { widgetListSelector, IRootState, ISelectData } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { IManualPaymentSystemsItems } from 'interfaces/widgets/paymentSystems';

export const selectionOfManualPSSelector = createSelector<IRootState, IManualPaymentSystemsItems[], ISelectData[]>(
  widgetListSelector(WidgetType.MANUAL_PAYMENT_SYSTEMS),
  (items) => {
    const filtered = filter(items, ({ status }) => status === 1);

    return map(filtered, ({ name, code }) => ({ value: code, text: name }));
  }
);

export const selectionOfAllManualPSSelector = createSelector<IRootState, IManualPaymentSystemsItems[], ISelectData[]>(
  widgetListSelector(WidgetType.MANUAL_PAYMENT_SYSTEMS),
  (items) => map(items, ({ name, code }) => ({ value: code, text: name }))
);
