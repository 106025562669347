import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';

export class TimezonesApi extends BaseApi {
  loadTimezoneList = (): AxiosPromise => this.makeFetch(`list`);
}

const timezonesApi = new TimezonesApi(`timezones`);

export default timezonesApi;
