import React from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { ErrorBlock } from '@kassma-team/kassma-toolkit/lib';

import { ISettingsForm } from 'interfaces/widgets/settings';

import NewFormSubmit from 'components/form/formSubmit/NewFormSubmit';
import SettingsBlockWrap from 'components/widgets/settings/SettingsBlockWrap';
import FormField from 'components/form/FormField';

const SettingsForm = ({
  submitText,
  submitting,
  handleSubmit,
  submitDisabled,
  groupedFields,
  error,
}: ISettingsForm) => {
  const [t] = useTranslation();

  return (
    <div className="p-20">
      <form onSubmit={handleSubmit}>
        {map(groupedFields, ({ title, fields, gridType }, groupKey) => (
          <SettingsBlockWrap title={title as string} key={groupKey} gridType={gridType}>
            {map(fields, (field, fieldKey) => {
              const fieldId = get(field, `name`) || fieldKey;

              return <FormField {...field} key={fieldId} />;
            })}
          </SettingsBlockWrap>
        ))}
        {!!error && <ErrorBlock error={error} />}
        <NewFormSubmit submitting={submitting} disabled={submitDisabled}>
          {t(submitText)}
        </NewFormSubmit>
      </form>
    </div>
  );
};

export default SettingsForm;
