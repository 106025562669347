import { useSelector } from 'react-redux';
import { useFullAmount as kassmaTeamFullAmount, widgetListSelector, ICurrency } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';

interface IProps {
  amount: string | number;
  precision?: number;
  currencySymbol?: string;
  currencyCode?: string;
  showSymbol?: boolean;
  currencies?: ICurrency;
  needToTrim?: boolean;
}

const useFullAmount = ({
  amount,
  currencySymbol,
  currencyCode,
  showSymbol = true,
  needToTrim = true,
  precision,
}: IProps): any => {
  // todo change type in toolkit
  const currencies: ICurrency = useSelector(widgetListSelector(WidgetType.CURRENCIES));

  return kassmaTeamFullAmount(currencies)({
    amount,
    currencySymbol,
    currencyCode,
    showSymbol,
    precision,
    currencies,
    needToTrim,
  });
};

export default useFullAmount;
