import React, { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import join from 'lodash/join';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { toastr } from 'react-redux-toastr';
import {
  TableChangeableInput,
  TableCheckbox,
  WalletImg,
  useFilterColumns,
  validateAmount,
  amountField,
  Dropdown,
} from '@kassma-team/kassma-toolkit/lib';

import { ModalType, WalletType, PaymentsConstructorColumn } from 'utils/enums';
import {
  changeLimitOfGettingStandardStatus,
  paymentsConstructorActionCreators,
  toggleEnablingPossibleTransactions,
  toggleShowOnPlugin,
  toggleStandardPS,
  updatePaymentsPlugin,
} from 'actions/widgets/constructors/paymentsConstructor';
import { isP2PPaymentSystem, paymentLabelSelector, paymentSystemLogoSelector } from 'selectors/widgets/paymentSystems';
import { IPaymentsConstructorRowProps } from 'interfaces/widgets/constructor/paymentsConstructor';
import { ReactComponent as Details } from 'assets/media/icons/details.svg';

import './paymentsConstructorRow.scss';
import { rest } from 'lodash';
import { IsManualPS } from 'utils/isManualPS';

const { showModal } = paymentsConstructorActionCreators;

const PaymentsConstructorRow = ({
  code,
  supported_currencies,
  applied_currencies,
  payment_type,
  // @ts-expect-error
  desktopSize,
  // @ts-expect-error
  hiddenColumns,
  type_settings: {
    is_settlement,
    is_check_amount,
    plugin_visibility,
    is_standard,
    standard_threshold,
    use_possible_transactions,
    enable_notifications,
  },
}: IPaymentsConstructorRowProps) => {
  const [checkboxDisabled, setCheckboxDisabled] = useState(false);
  const [localNotifications, setLocalNotifications] = useState(enable_notifications);

  const isP2P = useSelector(isP2PPaymentSystem(code));
  const isManual = IsManualPS(payment_type);

  const dispatch = useDispatch();

  const [t] = useTranslation();

  const isViewModalDisabled = code === WalletType.NGAN_LUONG;
  const paymentName = useSelector(paymentLabelSelector(code));

  const logo = useSelector(paymentSystemLogoSelector(code));
  const paymentSystemField = <WalletImg title={paymentName || code} src={logo || `#`} />;

  const currenciesField = join(applied_currencies, `, `);

  const actions = (
    <>
      <button
        onClick={() => dispatch(showModal({ type: ModalType.PAYMENT_CURRENCIES, walletType: code }))}
        className="btn btn-primary"
      >
        {t(`paymentConstructor.currencies`)}
      </button>
      <button
        onClick={() => dispatch(showModal({ type: ModalType.DEPOSITS, walletType: code }))}
        className="btn btn-alt-info"
      >
        {t(`paymentConstructor.limits`)}
      </button>
      <button
        onClick={() => dispatch(showModal({ type: ModalType.VIEW_TYPE, walletType: code }))}
        className="btn btn-alt-info"
        disabled={isViewModalDisabled}
      >
        {t(`paymentConstructor.viewType`)}
      </button>
      <button
        onClick={() => dispatch(showModal({ type: ModalType.SETTINGS, walletType: code }))}
        className="btn btn-alt-info"
      >
        {t(`paymentConstructor.settings`)}
      </button>
      {/* {isManual && (
        <Dropdown
          className="counterparty-btn__item--right"
          insideContent={(onClose: () => void) => {
            return (
              <button
                className="dropdown__list-button postback-btn__item"
                onClick={() => {
                  dispatch(showModal({ type: ModalType.REMOVE_CONFIRMATION, id: payment_type }));
                  onClose();
                }}
              >
                {t(`common.delete`)}
              </button>
            );
          }}
        >
          <button type="button" className="postback-btn__item postback-btn__item--right">
            <Details />
          </button>
        </Dropdown>
      )} */}
    </>
  );

  const showOnPluginCheckbox = (
    <TableCheckbox
      id={`${code}_show_on_plugin`}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;

        dispatch(toggleShowOnPlugin({ walletType: code, showOnPlugin: checked }));
        setCheckboxDisabled(true);
        dispatch(
          updatePaymentsPlugin(code)(
            {
              plugin_visibility: checked,
              // notifications: localNotifications,
              ...(typeof is_check_amount === `boolean` ? { is_check_amount } : {}),
            },
            {
              onError: () => dispatch(toggleShowOnPlugin({ walletType: code, showOnPlugin: !checked })),
              onFinally: () => setCheckboxDisabled(false),
            }
          )
        );
      }}
      checked={plugin_visibility}
      disabled={checkboxDisabled}
    />
  );

  const possibleTransactionCheckbox = !isManual && isP2P && code !== WalletType.DOLAR_DOLAR && (
    <TableCheckbox
      id={`${code}_possible_transaction`}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;

        dispatch(toggleEnablingPossibleTransactions({ walletType: code, enablePossibleTransactions: checked }));
        setCheckboxDisabled(true);
        dispatch(
          updatePaymentsPlugin(code)(
            {
              use_possible_transactions: checked,
              // notifications: localNotifications,
              ...(typeof is_check_amount === `boolean` ? { is_check_amount } : {}),
            },
            {
              onError: () =>
                dispatch(
                  toggleEnablingPossibleTransactions({
                    walletType: code,
                    enablePossibleTransactions: !checked,
                  })
                ),
              onFinally: () => setCheckboxDisabled(false),
            }
          )
        );
      }}
      checked={use_possible_transactions}
      disabled={checkboxDisabled}
    />
  );

  const standardPSCheckbox = (
    <TableCheckbox
      id={`${code}_standard`}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;

        dispatch(toggleStandardPS({ walletType: code, isStandardPS: checked }));
        setCheckboxDisabled(true);
        dispatch(
          updatePaymentsPlugin(code)(
            {
              is_standard: checked,
              // notifications: localNotifications,
              ...(typeof is_check_amount === `boolean` ? { is_check_amount } : {}),
            },
            {
              onError: () => dispatch(toggleStandardPS({ walletType: code, isStandardPS: !checked })),
              onFinally: () => setCheckboxDisabled(false),
            }
          )
        );
      }}
      checked={is_standard}
      disabled={checkboxDisabled}
    />
  );

  const notificationPSCheckbox = (
    <TableCheckbox
      id={`${code}-notification`}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;

        setCheckboxDisabled(true);
        dispatch(
          updatePaymentsPlugin(code)(
            {
              notifications: checked,
              ...(typeof is_check_amount === `boolean` ? { is_check_amount } : {}),
            },
            {
              onSuccess: () => setLocalNotifications(checked),
              onError: () => {
                setLocalNotifications(!checked);
                toastr.error(i18n.t(`common.error`), i18n.t(`common.error`));
              },
              onFinally: () => setCheckboxDisabled(false),
            }
          )
        );
      }}
      checked={localNotifications}
      disabled={checkboxDisabled}
    />
  );

  interface ITableChangeableInputChangeProps {
    setEditMode: (value: boolean) => void;
    setError: (value: string | undefined) => void;
  }

  const limitForGettingStandardStatusField = (
    <TableChangeableInput
      value={standard_threshold}
      normalize={amountField}
      onChange={({ setEditMode, setError }: ITableChangeableInputChangeProps) => {
        return (value: string | undefined) => {
          const error = validateAmount(value);
          setError(error);

          if (!value) {
            value = `0`;
          }

          if (!error) {
            setCheckboxDisabled(true);
            dispatch(
              updatePaymentsPlugin(code)(
                {
                  ...(typeof is_check_amount === `boolean` ? { is_check_amount } : {}),
                  standard_threshold: value,
                  // notifications: localNotifications,
                },
                {
                  onSuccess: () => {
                    dispatch(
                      changeLimitOfGettingStandardStatus({
                        walletType: code,
                        limitOfGettingStandardStatus: value,
                      })
                    );
                    setEditMode(false);
                  },
                  onFinally: () => setCheckboxDisabled(false),
                }
              )
            );
          }
        };
      }}
      rowId={code}
      disabled={checkboxDisabled}
    />
  );

  const columns = [
    {
      id: PaymentsConstructorColumn.PAYMENT_SYSTEM,
      desktop: <td>{paymentSystemField}</td>,
      mobile: (
        <tr className="first-mobile-row">
          <td>{t(`paymentConstructor.paymentSystem`)}</td>
          <td>{paymentSystemField}</td>
        </tr>
      ),
    },
    {
      id: PaymentsConstructorColumn.CURRENCIES,
      desktop: <td>{currenciesField}</td>,
      mobile: (
        <tr>
          <td>{t(`paymentConstructor.currencies`)}</td>
          <td>{currenciesField}</td>
        </tr>
      ),
    },
    {
      id: PaymentsConstructorColumn.USE_POSSIBLE_TRANSACTIONS,
      desktop: <td>{possibleTransactionCheckbox}</td>,
      mobile: (
        <tr>
          <td>{t(`paymentConstructor.usePossibleTransactions`)}</td>
          <td>{possibleTransactionCheckbox}</td>
        </tr>
      ),
    },
    {
      id: PaymentsConstructorColumn.DISPLAY_ON_PLUGIN,
      desktop: <td>{showOnPluginCheckbox}</td>,
      mobile: (
        <tr>
          <td>{t(`paymentConstructor.displayOnPlugin`)}</td>
          <td>{showOnPluginCheckbox}</td>
        </tr>
      ),
    },
    {
      id: PaymentsConstructorColumn.STANDARD_PS,
      desktop: <td>{standardPSCheckbox}</td>,
      mobile: (
        <tr>
          <td>{t(`paymentConstructor.standardPaymentSystem`)}</td>
          <td>{standardPSCheckbox}</td>
        </tr>
      ),
    },
    {
      id: PaymentsConstructorColumn.NOTIFICATION,
      desktop: <td>{notificationPSCheckbox}</td>,
      mobile: (
        <tr className="first-mobile-row">
          <td>{t(`paymentConstructor.notifications`)}</td>
          <td>{notificationPSCheckbox}</td>
        </tr>
      ),
    },
    {
      id: PaymentsConstructorColumn.LIMIT_FOR_GETTING_STANDARD_STATUS,
      desktop: <td>{limitForGettingStandardStatusField}</td>,
      mobile: (
        <tr>
          <td>{t(`paymentConstructor.limitForGettingStatusStandard`)}</td>
          <td>{limitForGettingStandardStatusField}</td>
        </tr>
      ),
    },
    {
      id: PaymentsConstructorColumn.ACTIONS,
      desktop: <td className="table-controls payments-constructor__action-cell">{actions}</td>,
      mobile: (
        <tr>
          <td>{t(`common.actions`)}</td>
          <td className="table-controls payments-constructor__action-cell--mobile">{actions}</td>
        </tr>
      ),
    },
  ];

  if (is_settlement) {
    return null;
  }

  return useFilterColumns({ columns, desktopSize, hiddenColumns });
};

export default PaymentsConstructorRow;
