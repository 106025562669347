import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';

import { WalletType } from 'api/translations/TranslationsApi';
import { PaymentSystemsScreen } from 'pages/constructor/DefaultTranslates/common/screens/PaymentSystemsScreen';
import { usePaymentSystemsCtx } from 'pages/constructor/DefaultTranslates/PackagesTranslation/ctx';

import './PaymentSystemList.scss';

export const PaymentSystemList: FC = () => {
  const {
    paymentSystems,
    isLoading: isLoadingPaymentSystems,
    currentPaymentSystem,
    handleSetPaymentSystem,
  } = usePaymentSystemsCtx();

  const [t] = useTranslation();

  const handleCheckSystem = (ps: WalletType) => () => {
    handleSetPaymentSystem(ps);
  };

  /**
   * isChecked
   *
   * Определяает состояние checked для ПС в списке.
   */
  const isChecked = (firstId: string, secondId?: string) => firstId === secondId;

  return (
    <PaymentSystemsScreen title={t(`common.paymentSystem`)}>
      <ul className="PaymentSystemsList__list">
        {isLoadingPaymentSystems ? (
          <Skeleton count={40} />
        ) : (
          paymentSystems.map((ps) => {
            return (
              <li
                key={ps.code}
                className={cn(`PaymentSystemsList__item`, {
                  'PaymentSystemsList__item--checked': isChecked(ps.code, currentPaymentSystem?.code),
                })}
                onClick={handleCheckSystem(ps)}
              >
                {ps.name}
              </li>
            );
          })
        )}
      </ul>
    </PaymentSystemsScreen>
  );
};
