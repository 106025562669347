import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import replace from 'lodash/replace';
import moment from 'moment';
import isArray from 'lodash/isArray';
import { validateMessages } from '@kassma-team/kassma-toolkit/lib';
import i18n from 'i18n';

import { ParserType, WalletType } from 'utils/enums';
import cardValidation from 'utils/validation/cardValidation';
import {
  IValidateWalletsProps,
  IValidationErrors,
  ILocalErrors,
  IWithdrawalLocalErrors,
  IBankcodeLocalErrors,
} from 'interfaces/widgets/wallets/validate';
import getBarcode from './getBarcode';

const parseDate = (date: string) => {
  const oldParseMethod = moment.parseTwoDigitYear;

  moment.parseTwoDigitYear = function (year) {
    return parseInt(year, 10) + 2000;
  };

  const momentDate = date && moment(date, `MM/YY`);

  moment.parseTwoDigitYear = oldParseMethod;

  return momentDate;
};

const paymentsWithUpiAddress = new Set([
  // WalletType.UPI_IB,
  // WalletType.UPI_AB,
  // WalletType.UPI_HB,
  // WalletType.UPI_IDB,
  // WalletType.UPI_L,
  // WalletType.UPI_IN,
  // WalletType.UPI_W,
]);

const validateWallets = (values: IValidateWalletsProps, props: any): IValidationErrors => {
  const errors: IValidationErrors = {};

  const { wallet_type, bankcode, proxies, email_parser_proxy, codephrases, upi_addresses, parser_type } = values || {};

  if (wallet_type === WalletType.SUREPAY) {
    if (isEmpty(bankcode)) {
      errors._error = i18n.t(`wallets.addAtLeastOneBankcode`);
    } else {
      const bankcodeErrors = bankcode?.reduce<(IBankcodeLocalErrors | undefined)[]>((result, data) => {
        const localErrors: Record<string, (string | undefined)[]> = {};
        const { bankcode, depositIban } = data;
        const trimmedBankcode = replace(bankcode, / /g, ``);
        const trimmedDepositIban = replace(depositIban, / /g, ``);

        if (!trimmedBankcode) {
          localErrors.bankcode = validateMessages().requiredField;
        }

        if (!trimmedDepositIban) {
          localErrors.depositIban = validateMessages().requiredField;
        }

        if (isEmpty(localErrors)) {
          return [...result, undefined];
        }

        return [...result, localErrors];
      }, []);

      if (!isEmpty(bankcodeErrors)) {
        errors.bankcode = bankcodeErrors;
      }
    }
  }

  const is_use_proxy = props.useProxy || values.useProxy;
  const is_use_email_proxy = props.useProxyNightEmailParser || values.useProxyNightEmailParser;

  const emptyProxyList = is_use_proxy && isEmpty(proxies);

  const emptyEmailProxyList = is_use_email_proxy && isEmpty(email_parser_proxy);

  let siteCondition = emptyProxyList;
  let emailCondition = emptyEmailProxyList;

  if (wallet_type === WalletType.IMPS_AB || wallet_type === WalletType.UPI_AB) {
    siteCondition = parser_type === ParserType.PARSER_AXIS && emptyProxyList;
    emailCondition = parser_type === ParserType.PARSER_EMAIL && emptyEmailProxyList;
  }

  if (siteCondition || emailCondition) {
    errors._error = i18n.t(`wallets.addAtLeastOneProxy`);
  }

  if (
    ([WalletType.UPI_AB, WalletType.IMPS_AB, WalletType.PHONE_PE].includes(wallet_type || ``) &&
      parser_type === ParserType.PARSER_AXIS) ||
    parser_type === ParserType.PARSER_INDUSIN ||
    wallet_type === WalletType.UPI_IN
  ) {
    if (!isArray(codephrases) || size(codephrases) !== 3) {
      errors._error = i18n.t(`wallets.addCodePhrases`);
    }
  }

  // if (
  //   values?.wallet_type === WalletType.UPI_L &&
  //   values?.integration_type === `upi_l` &&
  //   (!isArray(upi_addresses) || size(upi_addresses) === 0)
  // ) {
  //   errors._error = i18n.t(`wallets.addOneUpiAddress`);
  // }

  if (paymentsWithUpiAddress.has(wallet_type as string) && (!isArray(upi_addresses) || size(upi_addresses) === 0)) {
    errors._error = i18n.t(`wallets.addOneUpiAddress`);
  }

  const isUPI = [WalletType.UPI_AP, WalletType.UPI_S].includes(values?.wallet_type || ``);
  if (values?.upi_addresses && values?.upi_addresses.length > 1 && isUPI) {
    errors._error = i18n.t(`wallets.onlyOneUPIAddress`);
  }

  return errors;
};

export default validateWallets;
