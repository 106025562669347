import { useTranslation } from 'react-i18next';
import { InputType, IRootState, widgetListSelector } from '@kassma-team/kassma-toolkit/lib';
import React, { useCallback } from 'react';

import useAvailableProxiesCallback from 'hooks/widgets/callbacks/useGettingAvailableProxies';
import useArrayFields from 'hooks/widgets/useArrayFields';
import { ProxyStatus, WidgetType } from 'utils/enums';
import { IProxyWalletSelect } from 'interfaces/widgets/proxies';
import { IWidgetsState } from '@kassma-team/kassma-toolkit/lib/types/widgets';
import { useSelector } from 'react-redux';
import { widgetItemSelector, widgetSelector } from '@kassma-team/kassma-toolkit';

const proxyFieldName = `proxy_id`;

function getWalletsAmount<T>(uuid: T, items: { uuid: T; wallets: T[] }[] | null): number | undefined {
  const walletElement = items?.find((elem: { uuid: T }) => elem.uuid === uuid);

  return walletElement?.wallets.length;
}

const useWalletProxies = (formName: string, listName: string, showHint: boolean) => {
  const [t] = useTranslation();
  const items: IRootState = useSelector(widgetListSelector(listName));
  const getAvailableProxies = useAvailableProxiesCallback({
    formName,
    proxyFieldName,
    listName,
  });

  const proxyComponent = ({ item: { text, status, value } }: Record<`item`, IProxyWalletSelect>) => {
    if (status === ProxyStatus.ERROR) {
      return <span className="text-danger">{text}</span>;
    }

    const walletsAmount = getWalletsAmount(value, items);

    return (
      <>
        {text} <div className="wallets-amount">( {walletsAmount} )</div>
      </>
    );
  };

  const hint = t(`wallets.proxiesHint`);

  const fields = useCallback(
    (field) => [
      {
        placeholder: t(`common.proxy`),
        name: proxyFieldName,
        type: InputType.SELECT,
        data: getAvailableProxies(field.proxy_id),
        wrapClassName: `w-90 mr-5`,
        withModalScroll: true,
        required: true,
        itemComponent: proxyComponent,
        valueComponent: proxyComponent,
      },
    ],
    [getAvailableProxies]
  );

  return useArrayFields({
    fields,
    disableCallback: (fields) => fields.length >= 5,
    title: t(`common.proxies`),
    name: `proxies`,
    showHint,
    hint,
  });
};

export default useWalletProxies;
