import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';

export class CountriesApi extends BaseApi {
  loadCountryList = (): AxiosPromise => this.makeFetch(`list`);
}

const countriesApi = new CountriesApi(`countries`);

export default countriesApi;
