import axios from 'axios';
import { useState, useEffect } from 'react';
import api, { FormType } from 'api/translations/TranslationsApi';
import refreshHelper from '../../../../../utils/refreshHelper';

export type CheckedFormType = { entityId: string } & FormType;

export type UseFormTypes = {
  isLoading: boolean;
  formTypes: Array<FormType>;
  fetchFormTypes: () => Promise<void>;

  currentFormType?: CheckedFormType;
  handleCurrentFormType: (paymentSystem: CheckedFormType | undefined) => void;
};

/**
 * useFormTypes
 *
 * Хранит доступные типы форм. (Форма совершения оплаты, форма подтверждения оплаты и т.д.)
 *
 * Управляет выбором типа формы.
 *
 * {
 *  type: "requisite",
 *  title: "Форма совершения оплаты",
 *  entityId: "uuid" - где именно щелкнули.
 * }
 */
export function useFormTypes(): UseFormTypes {
  const [isLoading, setIsLoading] = useState(false);
  const [formTypes, setFormTypes] = useState<UseFormTypes[`formTypes`]>([]);

  const [currentFormType, setCurrentFormType] = useState<CheckedFormType | undefined>(undefined);

  const handleCurrentFormType = (currentFormType: CheckedFormType | undefined) => setCurrentFormType(currentFormType);
  const fetchFormTypes = async () => {
    setIsLoading(true);

    await refreshHelper({
      request: api.fetchFormTypes,
      onSuccess: ({ data }: any) => {
        setFormTypes(data.data);
      },
      onFinally: () => {
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    fetchFormTypes();
  }, []);

  return {
    isLoading,
    formTypes,
    fetchFormTypes,

    currentFormType,
    handleCurrentFormType,
  };
}
