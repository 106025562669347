import { InjectedFormProps, reduxForm, WrappedFieldArrayProps, FieldArray, getFormValues } from 'redux-form';
import { CREATE_MANUAL_PS_HINT_FORM } from 'utils/constants';
import { AccordionComponent, InputType } from '@kassma-team/kassma-toolkit';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TranslationsArrayFields from '../../paymentHints/TranslationsArrayFields';
import { langListSelector } from 'selectors/widgets/constructor/paymentHintsConstructor';
import useList from 'hooks/widgets/useList';
import { WidgetType } from 'utils/enums';
import { hintLangsActionCreators } from 'actions/widgets/hintLangs';
import FormField from 'components/form/FormField';

type IProps = {
  title: string;
  name: string;
  form: string;
};

const HintsAccordion = ({ title, name, form }: IProps) => {
  const [t] = useTranslation();

  const langs = useSelector(langListSelector) || [];

  const getFilterLangs = useCallback((translations) => langs.filter((item) => !translations.includes(item.value)), [
    langs,
  ]);

  const renderArray = useCallback(
    (fieldArrayProps: WrappedFieldArrayProps) => {
      return TranslationsArrayFields(
        {
          getFilterLangs,
          name: `translates`,
          form,
        },
        fieldArrayProps
      );
    },
    [getFilterLangs]
  );

  return (
    <AccordionComponent title={t(title)}>
      {/*@ts-ignore*/}
      <FieldArray name={name} required={true} component={renderArray} />
    </AccordionComponent>
  );
};

const CreationPSHintsData = ({ handleSubmit, form, change }: InjectedFormProps) => {
  const [t] = useTranslation();
  const values: Partial<any> = useSelector(getFormValues(form)) || {};

  const paymentHints = values.payment;
  const requisitesHints = values.requisite;

  useEffect(() => {
    if (!paymentHints) {
      change(`payment`, [{ form_text: ``, lang: `` }]);
    }
  }, [paymentHints]);

  useEffect(() => {
    if (!requisitesHints) {
      change(`requisite`, [{ form_text: ``, lang: `` }]);
    }
  }, [requisitesHints]);

  const fields = [
    {
      name: `animation_hint`,
      id: `${form}_animation_hint`,
      type: InputType.DROPZONE,
      label: t(`paymentConstructor.animationHint`),
      required: true,
      fileAcceptedTypes: [`.gif`],
      maxSize: 1024 * 1024 * 50,
      showDeleteButton: true,
    },
    {
      name: `screenshot`,
      id: `${form}_screenshot_for_payment_confirmation`,
      type: InputType.DROPZONE,
      label: t(`paymentConstructor.confirmationScreen`),
      required: true,
      fileAcceptedTypes: [`.svg`, `.png`, `.jpeg`, `.jpg`, `.ico`],
      maxSize: 1024 * 1024 * 10,
      showDeleteButton: true,
    },
  ];

  useList({
    widget: WidgetType.PAYMENT_HINT_LANGS,
    actionCreators: hintLangsActionCreators,
    resetOnUnmount: false,
    loadOnlyIfNecessary: true,
  });

  return (
    <form onSubmit={handleSubmit}>
      <HintsAccordion
        key={`paymentConstructor.paymentMaking`}
        title={`paymentConstructor.paymentMaking`}
        name={`requisite`}
        form={CREATE_MANUAL_PS_HINT_FORM}
      />
      <HintsAccordion
        key={`paymentConstructor.paymentConfirmation`}
        title={`paymentConstructor.paymentConfirmation`}
        name={`payment`}
        form={CREATE_MANUAL_PS_HINT_FORM}
      />
      {fields.map((field) => (
        <FormField key={field.name} isModalField={true} {...field} />
      ))}
    </form>
  );
};

export default reduxForm({
  destroyOnUnmount: false,
  form: CREATE_MANUAL_PS_HINT_FORM,
})(CreationPSHintsData);
