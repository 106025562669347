import { createSelector } from 'reselect';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import includes from 'lodash/includes';
import map from 'lodash/map';
import { widgetItemSelector, IRootState, ISelectData } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { IPayzenWalletItem } from 'interfaces/widgets/payzen';

export const payzenMethodsSelector = (country: string) =>
  createSelector<IRootState, IPayzenWalletItem, ISelectData[]>(widgetItemSelector(WidgetType.WALLET_PAYZEN), (item) => {
    const supportedMethods: string[] = get(item, `supported_countries.${country}.methods`);
    const allMethods = item?.supported_methods;
    const filteredMethods = pickBy(allMethods, (val, key) => includes(supportedMethods, key));

    return map(filteredMethods, (text, value) => ({ text, value }));
  });

export const payzenCurrenciesSelector = (country: string) =>
  createSelector<IRootState, IPayzenWalletItem, ISelectData[]>(widgetItemSelector(WidgetType.WALLET_PAYZEN), (item) => {
    const currencies = get(item, `supported_countries.${country}.currencies`);

    return map(currencies, (currency) => ({ value: currency, text: currency }));
  });

export const payzenCountriesSelector = createSelector<IRootState, IPayzenWalletItem, ISelectData[]>(
  widgetItemSelector(WidgetType.WALLET_PAYZEN),
  (item) => map(item?.supported_countries, (data, key) => ({ value: key, text: data?.name }))
);
