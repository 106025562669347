import { ParserType, WalletPriority } from 'utils/enums';

export const walletParserTypes = [
  {
    value: ParserType.PARSER_ICICI,
    text: `wallets.iciciParser`,
  },
  {
    value: ParserType.PARSER_EMAIL,
    text: `wallets.emailParser`,
  },
];

export const walletPriorityData = [
  {
    text: `common.low`,
    value: WalletPriority.LOW,
  },
  {
    text: `common.belowNormal`,
    value: WalletPriority.BELOW_NORMAL,
  },
  {
    text: `common.normal`,
    value: WalletPriority.NORMAL,
  },
  {
    text: `common.aboveNormal`,
    value: WalletPriority.ABOVE_NORMAL,
  },
  {
    text: `common.high`,
    value: WalletPriority.HIGH,
  },
];
