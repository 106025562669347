import {
  createWidgetAction,
  widgetActionCreators,
  IWidgetActionCreators,
  IWidgetsAction,
} from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { CREATE_WITHDRAWAL_FORM_NAME } from 'utils/constants';
import {
  APPROVE_WITHDRAWAL_REQUESTED,
  CONFIRM_REJECTION_OF_WITHDRAWAL_REQUESTED,
  CONFIRM_APPROVAL_OF_WITHDRAWAL_REQUESTED,
  REJECT_WITHDRAWAL_REQUESTED,
  UPDATE_WITHDRAWAL_COMMENT,
  UPLOAD_MANUAL_WITHDRAWALS_REQUESTED,
  DOWNLOAD_MANUAL_WITHDRAWALS_REQUESTED,
  CREATE_WITHDRAWAL_REQUESTED,
} from 'actionTypes';

export const withdrawalsActionCreators = widgetActionCreators({
  widget: WidgetType.WITHDRAWALS,
  labels: { itemLabel: `withdrawal`, listLabel: `withdrawals` },
  creatingForm: CREATE_WITHDRAWAL_FORM_NAME,
  statusBar: true,
  withPagination: true,
});

export const approvalOfWithdrawalsActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.WITHDRAWALS_APPROVAL_DATA,
  labels: { itemLabel: `data` },
});

export const rejectWithdrawalRequest: IWidgetsAction = createWidgetAction({
  widget: WidgetType.WITHDRAWALS,
})(REJECT_WITHDRAWAL_REQUESTED);

export const approveWithdrawalRequest = (id: number, walletType: string, payload = {}): IWidgetsAction =>
  createWidgetAction({
    widget: WidgetType.WITHDRAWALS,
    id,
    walletType,
  })(APPROVE_WITHDRAWAL_REQUESTED)(payload);

interface IConfirmApprovalOfWithdrawal {
  id: number;
  withdrawal_id: number;
}

export const confirmApprovalOfWithdrawalRequest = ({
  id,
  withdrawal_id,
}: IConfirmApprovalOfWithdrawal): IWidgetsAction =>
  createWidgetAction({
    widget: WidgetType.WITHDRAWALS,
    id,
    withdrawal_id,
  })(CONFIRM_APPROVAL_OF_WITHDRAWAL_REQUESTED)();

type IConfirmRejectionOfWithdrawal = IConfirmApprovalOfWithdrawal;

export const confirmRejectionOfWithdrawalRequest = ({
  id,
  withdrawal_id,
}: IConfirmRejectionOfWithdrawal): IWidgetsAction =>
  createWidgetAction({
    widget: WidgetType.WITHDRAWALS,
    id,
    withdrawal_id,
  })(CONFIRM_REJECTION_OF_WITHDRAWAL_REQUESTED)();

export const withdrawalsBankCodesActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.WITHDRAWALS_BANK_CODES,
  labels: { listLabel: `bank_codes` },
});

export const paygigaBankCodesActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.PAYGIGA_BANK_CODES,
  labels: { listLabel: `banks` },
});

export const paygigaAvailableAmountsActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.PAYGIGA_AVAILABLE_AMOUNTS,
  labels: { listLabel: `amounts_data` },
});

export const withdrawalsCurrencyCodesActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.WITHDRAWALS_CURRENCY_CODES,
  labels: { listLabel: `currencies` },
});

export const withdrawalsBranchCodesActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.WITHDRAWALS_BRANCH_CODES,
  labels: { listLabel: `branch_codes` },
});

export const withdrawalsPayoutMethodsActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.WITHDRAWALS_PAYOUT_METHODS,
  labels: { listLabel: `payout_methods` },
});

export const withdrawalsProvidersActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.WITHDRAWALS_PROVIDERS,
  labels: { listLabel: `providers` },
});

export const withdrawalsCardsActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.WITHDRAWALS_CARDS,
  labels: { listLabel: `cards` },
});

export const updateWithdrawalComment = (id: number): IWidgetsAction =>
  createWidgetAction({
    widget: WidgetType.WITHDRAWALS,
    id,
  })(UPDATE_WITHDRAWAL_COMMENT);

export const createWithdrawal: IWidgetsAction = createWidgetAction({
  widget: WidgetType.WITHDRAWALS,
})(CREATE_WITHDRAWAL_REQUESTED);

export const uploadManualWithdrawalsRequest: IWidgetsAction = createWidgetAction({
  widget: WidgetType.WITHDRAWALS,
})(UPLOAD_MANUAL_WITHDRAWALS_REQUESTED);

export const downloadManualWithdrawalsRequest: IWidgetsAction = createWidgetAction({
  widget: WidgetType.WITHDRAWALS,
})(DOWNLOAD_MANUAL_WITHDRAWALS_REQUESTED);

export const withdrawalStatusActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.WITHDRAWALS_STATUS,
  labels: { listLabel: `statuses` },
});
