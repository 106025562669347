import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import { IPossibleTransactionsFilterForm } from 'interfaces/widgets/transaction/transaction';

export class PossibleTransactionApi extends BaseApi {
  deletePossibleTransactions = (primary_id: number): AxiosPromise =>
    this.makeFetch(`remove/{primary_id}`, { method: `DELETE` }, { primary_id });

  changeStatus = (primary_id: number): AxiosPromise =>
    this.makeFetch(`status_change/${primary_id}`, { method: `POST` });

  loadTransactionImage = (id: number): AxiosPromise =>
    this.makeFetch(`get-image/{id}`, { responseType: `blob` }, { id });

  loadTransactionList = (queryParams: IPossibleTransactionsFilterForm): AxiosPromise =>
    this.makeFetch(`list`, { queryParams });

  approvePossibleTransaction = (primary_id: number, data: any): AxiosPromise =>
    this.makeFetch(`approve/{primary_id}`, { method: `POST`, data }, { primary_id });
}

const possibleTransactionApi = new PossibleTransactionApi(`possible-transaction`);

export default possibleTransactionApi;
