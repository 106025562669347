import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import TariffInput from 'components/widgets/tariffs/tariffsConstructor/TariffInput';
import TariffFieldControlButtons from 'components/widgets/tariffs/tariffsConstructor/TariffFieldControlButtons';
import { ILocalCommissions } from 'interfaces/widgets/constructor/tariffConstructor';

interface IProps {
  fieldsState: ILocalCommissions[];
  fieldsData: ILocalCommissions[];
  setFieldsState: (data: ILocalCommissions[]) => void;
  direction: string;
  onRemoveCallback?: (direction: string, id: number) => void;
  onCreateCallback: (type: string, value: any) => void;
  setCurrentField: (index: number | null) => void;
  error?: Record<string, any>;
  activeField: number | null;
  setErrorState: (error: Record<string, any>) => void;
  commissionType?: `fix` | `percent` | `settlement`;
  isLoading: boolean;
}

const TariffInputContainer = ({
  fieldsState,
  direction,
  onRemoveCallback,
  onCreateCallback,
  setCurrentField,
  error,
  activeField,
  setFieldsState,
  setErrorState,
  commissionType,
  fieldsData,
  isLoading,
}: IProps): JSX.Element => {
  const [t] = useTranslation();
  const [controlButtonsVisible, setControlButtonsVisible] = useState(false);

  const fixData = fieldsState?.find((item) => item?.type === `fix`);
  const percentData = fieldsState?.filter((item) => item?.type === `percent` || item?.type === `settlement`) || [];

  useEffect(() => {
    if (fixData?.id !== activeField && controlButtonsVisible) {
      setControlButtonsVisible(false);
    }
  }, [fieldsData, activeField, fixData]);

  const commissionItemTemplate = useCallback(
    (direction, type, id, value = ``) => ({
      id: id,
      commission: value,
      direction: direction,
      type: type,
      min_amount: ``,
      max_amount: ``,
      local: true,
    }),
    []
  );

  const onResetFixField = useCallback(() => {
    setErrorState({});
    setFieldsState(fieldsData);
    setControlButtonsVisible(false);
  }, [fieldsData]);

  const onChange = useCallback(
    (value, type, id, name = `commission`) => {
      if (!controlButtonsVisible && type === `fix`) {
        setCurrentField(id);
        setControlButtonsVisible(true);
      }

      const currentField = fieldsState?.find((field) => field?.id === id) || { id, type, local: true };
      const otherFieldsStateItems = fieldsState?.filter((item) => item?.id !== id) || [];
      const newFieldsState = [...otherFieldsStateItems, { ...currentField, [name]: value }]?.sort(
        (a, b) => a?.id - b?.id
      );

      setFieldsState(newFieldsState as ILocalCommissions[]);
    },
    [fieldsState, setFieldsState, controlButtonsVisible]
  );

  const addField = useCallback(() => {
    const copyFieldData = [...fieldsState];
    copyFieldData?.sort((a: ILocalCommissions, b: ILocalCommissions) => a?.id - b?.id);
    const id = copyFieldData?.[copyFieldData?.length - 1]?.id + 1 || 2;

    copyFieldData?.push(
      commissionItemTemplate(direction, commissionType === `settlement` ? commissionType : `percent`, id)
    );

    if (!activeField) {
      setCurrentField(id);
    }
    setFieldsState(copyFieldData);
  }, [setFieldsState, fieldsState, direction]);

  const renderPercentField = useMemo(
    () =>
      percentData?.map((fieldData, index) => {
        const id = fieldData?.id;
        const type = fieldData?.type;

        return (
          <div className="tariff_field_container__array_field_item" key={id}>
            <div className="tariff_field_container__inputs_block">
              <div className="tariff_field_container__inputs">
                <TariffInput
                  disabled={id !== activeField}
                  name="min_amount"
                  label={t(`paymentConstructor.amountFrom`)}
                  value={fieldData?.min_amount}
                  key={`${direction}-minAmount-${index}`}
                  onChange={(value) => onChange(value, type, id, `min_amount`)}
                  error={error?.[id]?.min_amount?.[0]}
                />
                <TariffInput
                  disabled={id !== activeField}
                  name="max_amount"
                  label={t(`paymentConstructor.amountUpTo`)}
                  value={fieldData?.max_amount}
                  key={`${direction}-max_amount-${index}`}
                  onChange={(value) => onChange(value, type, id, `max_amount`)}
                  error={error?.[id]?.max_amount?.[0]}
                />
                <TariffInput
                  disabled={id !== activeField}
                  name="commission"
                  label={t(`paymentConstructor.percent`)}
                  value={fieldData?.commission}
                  percent={true}
                  key={`${direction}-commission-${index}`}
                  onChange={(value) => {
                    onChange(value, type, id, `commission`);
                  }}
                  error={error?.[id]?.commission?.[0]}
                />
              </div>
              {error?.[id] && <div className="tariff_field_container__error">{error?.[id]?.interval?.[0]}</div>}
            </div>
            {!fieldsState && (
              <p className="tariff_field_container__empty_text">
                {t(`paymentConstructor.commissionPercentageIsNotSet`)}
              </p>
            )}
            <div className="tariff_field_container__controls">
              <TariffFieldControlButtons
                onRemoveCallback={onRemoveCallback}
                fieldsState={fieldData}
                onCreateCallback={onCreateCallback}
                setCurrentField={setCurrentField}
                activeField={activeField}
                isLoading={isLoading}
              />
            </div>
          </div>
        );
      }),
    [percentData, activeField, direction, error]
  );

  const commissionPercentageText =
    commissionType === `settlement` ? `` : `paymentConstructor.commissionPercentageIsNotSet`;

  return (
    <>
      <div className="tariff_field_container__fields_fix_item">
        {commissionType !== `settlement` && (
          <div className="tariff_field_container__fields_fix_item_block">
            <TariffInput
              value={fixData?.commission}
              name="commission"
              label={t(`paymentConstructor.fixAmount`)}
              onChange={(value) => onChange(value, `fix`, fixData?.id || 1)}
              error={error?.[(fixData?.id || 1) as number]?.commission?.[0]}
              disabled={(activeField && activeField !== fixData?.id) as boolean}
            />
            {error?.[(fixData?.id || 1) as number] && (
              <div className="tariff_field_container__error">
                {error?.[(fixData?.id || 1) as number]?.interval?.[0]}
              </div>
            )}
          </div>
        )}
        <div className="tariff_field_container__controls">
          {controlButtonsVisible && (
            <TariffFieldControlButtons
              fieldsState={fixData as ILocalCommissions}
              onCreateCallback={onCreateCallback}
              onRemoveCallback={onRemoveCallback}
              onResetFixCallback={onResetFixField}
              setCurrentField={setCurrentField}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
      <div className="tariff_field_container__fields">
        <div className="tariff_field_container__fields_label">
          {commissionType === `settlement`
            ? t(`wallets.settlement`)
            : t(`paymentConstructor.percentageTransactionAmount`)}
        </div>
        <div className="tariff_field_container__element">
          <div className="tariff_field_container__array_field">
            {percentData.length ? renderPercentField : t(commissionPercentageText)}
          </div>
          <button className="btn btn-success tariff_field_container__button" onClick={() => addField()}>
            {t(`paymentConstructor.addCondition`)}
          </button>
        </div>
      </div>
    </>
  );
};

const MemoTariffInputContainer = React.memo(TariffInputContainer);

export default MemoTariffInputContainer;
