import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import { IChangeLogQueryParams } from 'interfaces/widgets/changeLogObject';

export class ChangeLogsApi extends BaseApi {
  loadChangeLogActionList = (object_type: string): AxiosPromise =>
    this.makeFetch(`action-types`, { queryParams: { object_type } });

  loadChangeLogList = (queryParams: IChangeLogQueryParams): AxiosPromise => this.makeFetch(``, { queryParams });

  loadChangeLogObjectList = (): AxiosPromise => this.makeFetch(`object-types`);

  loadChangeLogUsers = (): AxiosPromise => this.makeFetch(`users`);
}

const changeLogsApi = new ChangeLogsApi(`change-logs`);

export default changeLogsApi;
