import React, { FC, useContext, createContext } from 'react';
import { useOpenedFormType } from '../hooks/useOpenedFormType';

type UseOpenedFormTypeCtx = ReturnType<typeof useOpenedFormType>;

const useOpenedFormTypeCtx = createContext<UseOpenedFormTypeCtx | undefined>(undefined);

export const UseOpenedFormTypeCtx: FC = ({ children }) => {
  const { openedFormType, setOpenedFormType } = useOpenedFormType();

  const val: UseOpenedFormTypeCtx = {
    openedFormType,
    setOpenedFormType,
  };

  return <useOpenedFormTypeCtx.Provider value={val}>{children}</useOpenedFormTypeCtx.Provider>;
};

export function useUseOpenedFormTypeCtx(): UseOpenedFormTypeCtx {
  const context = useContext(useOpenedFormTypeCtx);

  if (context === undefined) {
    throw new Error(`Is need to use useOpenedFormTypeCtx inside a UseOpenedFormTypeCtx component`);
  }

  return context;
}
