import { createSelector } from 'reselect';
import find from 'lodash/find';
import map from 'lodash/map';
import get from 'lodash/get';
import { widgetSelector, widgetFieldSelector, IRootState, ISelectData } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import {
  IAppliedPaymentSystems,
  ICurrencyListWithCustomSorting,
  ICurrencyListWithDefaultSorting,
  ISettingsState,
} from 'interfaces/widgets/settings';

export const helpGifsSelector = createSelector(widgetFieldSelector(WidgetType.SETTINGS, `gif_guide`), (gifs) => gifs);

export const currencyListWithCustomSortingSelector = createSelector<
  IRootState,
  ISettingsState,
  ICurrencyListWithCustomSorting[]
>(widgetSelector(WidgetType.SETTINGS), (state) => state?.currency_list_with_custom_sorting);

export const timezoneSelector = createSelector<IRootState, ISettingsState, string>(
  widgetSelector(WidgetType.SETTINGS),
  (state) => state?.timezone || ``
);

export const settingsInitSelector = createSelector<IRootState, ISettingsState, boolean>(
  widgetSelector(WidgetType.SETTINGS),
  (state) => state?.init || false
);

export const appliedPaymentsSelector = createSelector<IRootState, ISettingsState, IAppliedPaymentSystems[]>(
  widgetSelector(WidgetType.SETTINGS),
  (state) => state?.applied_payment_systems || []
);

export const defaultPaymentsSelector = createSelector<IRootState, ISettingsState, IAppliedPaymentSystems[]>(
  widgetSelector(WidgetType.SETTINGS),
  (state) => state?.default_payment_systems || []
);

export const currencyListWithDefaultSortingSelector = createSelector<
  IRootState,
  ISettingsState,
  ICurrencyListWithDefaultSorting[]
>(widgetSelector(WidgetType.SETTINGS), (state) => state?.currency_list_with_default_sorting || []);

export const currencyListForSortingSelector = createSelector<
  ICurrencyListWithDefaultSorting[],
  ICurrencyListWithDefaultSorting[],
  ISelectData[]
>(currencyListWithDefaultSortingSelector, (list) => map(list, ({ code }) => ({ value: code, text: code })));

export const defaultPaymentsByCurrencySelector = (currency: string) =>
  createSelector<
    ICurrencyListWithDefaultSorting[],
    ICurrencyListWithDefaultSorting[],
    IAppliedPaymentSystems[] | undefined
  >(currencyListWithDefaultSortingSelector, (list) => find(list, ({ code }) => currency === code)?.payments);

export const appVersionSelector = createSelector(widgetSelector(WidgetType.SETTINGS), (settings) =>
  get(settings, `appVersion`)
);

export const paytmFlagSelector = createSelector(
  widgetSelector(WidgetType.SETTINGS),
  (state: any) => state && state?.use_sms_box
);
