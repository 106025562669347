import React from 'react';

import { ILocalCommissions } from 'interfaces/widgets/constructor/tariffConstructor';

export interface ITariffFieldControlButtons {
  onRemoveCallback?: (direction: string, id: number) => void;
  onCreateCallback: (type: string, value: any) => void;
  onResetFixCallback?: () => void;
  fieldsState: ILocalCommissions;
  setCurrentField?: (index: number | null) => void;
  activeField?: number | null;
  isLoading: boolean;
}

const TariffFieldControlButtons = ({
  onRemoveCallback,
  onResetFixCallback,
  onCreateCallback,
  fieldsState,
  activeField,
  setCurrentField,
  isLoading,
}: ITariffFieldControlButtons): JSX.Element => {
  const onResetFix = () => (fieldsState?.type === `fix` && onResetFixCallback ? onResetFixCallback() : null);

  let buttons = (
    <>
      <button
        className="tariff_array_field__button"
        type="button"
        id={`${fieldsState?.id}_edit_btn`}
        onClick={() => {
          if (!activeField) {
            setCurrentField && setCurrentField(fieldsState?.id);
          }
        }}
        disabled={isLoading}
      >
        <i className="fa fa-pencil font-size-xl" />
      </button>
      <button
        className="tariff_array_field__button"
        type="button"
        id={`${fieldsState?.id}_remove_btn`}
        onClick={() => {
          if (!activeField) {
            setCurrentField && setCurrentField(null);
            onRemoveCallback && onRemoveCallback(fieldsState?.direction, fieldsState?.id);
          }
        }}
        disabled={isLoading}
      >
        <i className="fa fa-trash font-size-xl" />
      </button>
    </>
  );

  if (fieldsState?.id === activeField || fieldsState?.type === `fix`) {
    buttons = (
      <>
        <button
          className="tariff_array_field__button"
          type="button"
          id={`${fieldsState?.id}_create_btn`}
          onClick={() => {
            onCreateCallback(fieldsState?.type, fieldsState);
          }}
          disabled={isLoading}
        >
          <i className="fa fa-check text-success font-size-xl" />
        </button>
        <button
          className="tariff_array_field__button"
          type="button"
          id={`${fieldsState?.id}_cancel_btn`}
          onClick={() => {
            setCurrentField && setCurrentField(null);
            onResetFix();
          }}
          disabled={isLoading}
        >
          <i className="fa fa-close text-danger font-size-xl" />
        </button>
      </>
    );
  }

  return buttons;
};

export default TariffFieldControlButtons;
