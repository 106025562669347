import { IWidgetActionCreators, widgetActionCreators, IWidgetsMeta } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import {
  WALLET_PAYTM_CODE_CONFIRMATION,
  WALLET_PAYTM_RECOVERING_REQUESTED,
  WALLET_PAYTM_RESETTING_REQUESTED,
} from 'actionTypes';

export const paytmActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.WALLET_PAYTM,
});

interface IPaytmWalletConfirmationReturns {
  type: string;
  payload: Record<string, unknown>;
}

export const paytmWalletConfirmation = (payload: Record<string, unknown>): IPaytmWalletConfirmationReturns => ({
  type: WALLET_PAYTM_CODE_CONFIRMATION,
  payload,
});

interface IResetPaytmWalletReturns {
  type: string;
  payload: number;
}

export const resetPaytmWallet = (payload: number): IResetPaytmWalletReturns => ({
  type: WALLET_PAYTM_RESETTING_REQUESTED,
  payload,
});

/**
 * @param {{ wallet_identifier: string, id: number }} payload
 * @returns {{payload: { wallet_identifier: string, id: number }, type: string}}
 */
interface IRecoverPaytmWalletPayload {
  wallet_identifier: string;
  id: number;
}

interface IRecoverPaytmWalletReturns {
  payload: IRecoverPaytmWalletPayload;
  type: string;
  meta: IWidgetsMeta;
}

export const recoverPaytmWallet = (payload: IRecoverPaytmWalletPayload): IRecoverPaytmWalletReturns => ({
  type: WALLET_PAYTM_RECOVERING_REQUESTED,
  payload,
  meta: {
    widget: WidgetType.DISABLED_WALLETS,
  },
});
