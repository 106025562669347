import React, { useState, useEffect, useCallback, useContext } from 'react';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@kassma-team/kassma-toolkit/lib';

import commissionApi from 'api/commission/CommissionApi';
import { TariffContext } from 'pages/constructor/TariffsConstructor';
import { ILocalCommissions } from 'interfaces/widgets/constructor/tariffConstructor';

import TariffInputContainer from 'components/widgets/tariffs/tariffsConstructor/TariffInputContainer';

import './tariffFieldContainer.scss';
import refreshHelper from 'utils/refreshHelper';
import { AxiosResponse } from 'axios';

interface ITariffArrayField {
  fieldsData: ILocalCommissions[];
  direction: string;
  commissionType?: `fix` | `percent` | `settlement`;
}

interface IPostPayload {
  data: Partial<ILocalCommissions>;
  id: number;
}

const TariffFieldContainer = ({ fieldsData, direction, commissionType }: ITariffArrayField): JSX.Element => {
  const [t] = useTranslation();
  const [activeField, setActiveField] = useState<number | null>(null);
  const [errorState, setErrorState] = useState<Record<string, any> | undefined>();
  const [fieldsState, setFieldsState] = useState<ILocalCommissions[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { commissionId, getCommissionsList } = useContext(TariffContext);

  const deleteRequest = (id: number) =>
    refreshHelper({
      request: () => commissionApi.deleteTariffCommission(id),
      onSuccess: (resp: AxiosResponse) => {
        if (resp?.data?.status === `ok`) {
          toastr.success(t(`common.success`), t(`common.success`));
        }
      },
      onFinally: () => {
        getCommissionsList(commissionId as number);
        setIsLoading(false);
      },
    });

  const putRequest = (currentData: Partial<ILocalCommissions>) =>
    refreshHelper({
      request: () => commissionApi.createTariffCommission(currentData),
      onSuccess: (resp: AxiosResponse) => {
        if (resp?.data?.status === `ok`) {
          getCommissionsList(commissionId as number);
          toastr.success(t(`common.success`), t(`widgets.itemHasBeenSuccessfullyCreated`));
        }
      },
      onError: (e: any) => {
        setErrorState({ [activeField as number]: getErrorMessage(e) });
      },
      onFinally: () => {
        setIsLoading(false);
      },
    });

  const postRequest = (payload: IPostPayload) =>
    refreshHelper({
      request: () => commissionApi.editTariffCommission(payload),
      onSuccess: (resp: AxiosResponse) => {
        if (resp?.data?.status === `ok`) {
          getCommissionsList(commissionId as number);
          toastr.success(t(`common.success`), t(`widgets.itemHasBeenSuccessfullyCreated`));
        }
      },
      onError: (e: any) => {
        setErrorState({ [activeField as number]: getErrorMessage(e) });
      },
      onFinally: () => {
        setIsLoading(false);
      },
    });

  useEffect(() => {
    setFieldsState(fieldsData?.sort((a: ILocalCommissions, b: ILocalCommissions) => a?.id - b?.id));
    setActiveField(null);
    setErrorState(undefined);
  }, [fieldsData]);

  useEffect(() => {
    setErrorState(undefined);
  }, [activeField]);

  const setCurrentField = useCallback(
    (id) => {
      const fieldDataItem = fieldsData?.find(({ id }) => id === activeField);
      const filteredState = fieldsState?.filter((item) => item?.id !== activeField);
      const currentData = fieldDataItem ? [...filteredState, fieldDataItem] : filteredState;
      const sortedFields = currentData?.sort((a: ILocalCommissions, b: ILocalCommissions) => a?.id - b?.id);

      setFieldsState(sortedFields);
      setActiveField(id);
    },
    [fieldsData, fieldsState]
  );

  const createCommission = useCallback(
    (type, value) => {
      let currentData: Partial<ILocalCommissions> = {};
      const local = fieldsState?.find((item) => item?.id === value?.id)?.local || false;

      switch (type) {
        case `fix`:
          currentData = {
            tariff_id: commissionId,
            direction: direction,
            type: type,
            commission: value?.commission,
            min_amount: `0`,
            max_amount: ``,
          };
          break;
        case `percent`:
          currentData = {
            tariff_id: commissionId,
            direction: direction,
            type: type,
            commission: Number(value?.commission)?.toFixed(2).toString(),
            min_amount: value?.min_amount,
            max_amount: value?.max_amount,
          };
          break;
        case `settlement`:
          currentData = {
            tariff_id: commissionId,
            direction: direction,
            type: type,
            commission: Number(value?.commission)?.toFixed(2).toString(),
            min_amount: value?.min_amount,
            max_amount: value?.max_amount,
          };
          break;
      }

      const payload: IPostPayload = {
        id: value?.id as number,
        data: currentData,
      };

      if (local) {
        setIsLoading(true);
        putRequest(currentData);
      } else {
        setIsLoading(true);
        postRequest(payload);
      }
    },
    [commissionId, direction, activeField, fieldsState, getCommissionsList]
  );

  const deleteCommission = useCallback(
    (direction, id) => {
      const currentField = fieldsState?.find((item: ILocalCommissions) => item.id === id);

      if (currentField?.local) {
        const newFieldState = fieldsState
          ?.filter((item: ILocalCommissions) => item.id !== id)
          ?.sort((a: ILocalCommissions, b: ILocalCommissions) => a?.id - b?.id);

        setFieldsState(newFieldState);
      } else {
        setIsLoading(true);
        deleteRequest(id);
      }
    },
    [fieldsState, getCommissionsList, activeField, commissionId]
  );

  return (
    <div className="tariff_field_container">
      <TariffInputContainer
        fieldsData={fieldsData}
        activeField={activeField}
        direction={direction}
        onCreateCallback={createCommission}
        onRemoveCallback={deleteCommission}
        setCurrentField={setCurrentField}
        error={errorState}
        fieldsState={fieldsState}
        setErrorState={setErrorState}
        setFieldsState={setFieldsState}
        commissionType={commissionType}
        isLoading={isLoading}
      />
    </div>
  );
};

export default TariffFieldContainer;
