import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import { ICreateSettlementForm } from 'interfaces/widgets/settlement';

export class SettlementWithdrawalApi extends BaseApi {
  settlementWithdrawalApprove = (id: number): AxiosPromise =>
    this.makeFetch(`approve/{id}`, { method: `POST` }, { id });

  loadSettlementWithdrawal = (): AxiosPromise => this.makeFetch(`list`);

  settlementWithdrawalReject = (id: number): AxiosPromise => this.makeFetch(`reject/{id}`, { method: `POST` }, { id });

  createWriteOff = (data: ICreateSettlementForm): AxiosPromise => this.makeFetch(`create`, { data, method: `POST` });
}

const settlementWithdrawalApi = new SettlementWithdrawalApi(`settlement/withdrawal`);

export default settlementWithdrawalApi;
