import { ReportFileFormat, ReportStatus } from 'utils/enums';

export const reportsFileFormatsList = [
  {
    value: ReportFileFormat.XLSX,
    text: `xlsx`,
  },
  {
    value: ReportFileFormat.CSV,
    text: `csv`,
  },
];

interface IStatusParams {
  message: string;
  color: string;
}

export const reportParamsByStatus: Record<number, IStatusParams> = {
  [ReportStatus.NEW]: {
    message: `reports.statusNew`,
    color: `badge-info`,
  },
  [ReportStatus.IN_PROGRESS]: {
    message: `reports.statusInProgress`,
    color: `badge-warning`,
  },
  [ReportStatus.SUCCESS]: {
    message: `reports.statusCompleted`,
    color: `badge-success`,
  },
  [ReportStatus.FAIL]: {
    message: `reports.statusError`,
    color: `badge-danger`,
  },
};
