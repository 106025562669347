import { createSelector } from 'reselect';
import map from 'lodash/map';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import { widgetListSelector, IRootState, ISelectData } from '@kassma-team/kassma-toolkit/lib';

import { PaymentSystemType, WidgetType } from 'utils/enums';
import {
  IManualPaymentSystemsItem,
  IPaymentSystemsForForcedTransactionsItems,
  IPaymentSystemsForForcedTransactionsList,
  IPaymentSystemsItem,
  IPaymentTypeCheckboxesItem,
  ITransactionsPaymentSystemsItems,
  IWithdrawalsPaymentsSystemsItem,
} from 'interfaces/widgets/paymentSystems';
import { ICurrenciesItems } from 'interfaces/widgets/currency';
import { IsManualPS } from 'utils/isManualPS';

export const paymentSystemsForForcedTransactionsSelector = createSelector<
  IRootState,
  IPaymentSystemsForForcedTransactionsItems[],
  IPaymentSystemsForForcedTransactionsList[]
  // @ts-expect-error Тип не корректно инферится
>(widgetListSelector(WidgetType.PAYMENT_SYSTEMS_FOR_FORCED_TRANSACTIONS), (items) => {
  return map(items, ({ name, code, plugin_visibility }) => ({
    value: code,
    text: name,
    plugin_visibility,
  }));
});

export const testPaymentSystemByCodeSelector = (codeValue: string) =>
  createSelector<IRootState, IManualPaymentSystemsItem[], IManualPaymentSystemsItem | undefined>(
    widgetListSelector(WidgetType.MANUAL_PAYMENT_SYSTEMS),
    (items) => find(items, ({ code }) => code === codeValue)
  );

export const transactionTypeOfSelectedPaymentSystemsSelector = (codeValue: string) =>
  createSelector<IManualPaymentSystemsItem | undefined, IManualPaymentSystemsItem | undefined, number | undefined>(
    testPaymentSystemByCodeSelector(codeValue),
    (paymentSystem) => paymentSystem?.transaction_type
  );

export const currencyCodeOfSelectedTestPaymentSystemSelector = (codeValue: string) =>
  createSelector<IRootState, IManualPaymentSystemsItem | undefined, ICurrenciesItems[], string | null | undefined>(
    testPaymentSystemByCodeSelector(codeValue),
    widgetListSelector(WidgetType.CURRENCIES),
    (paymentSystem, currencies) => {
      const currencyId = paymentSystem?.currency_id;
      if (currencyId && !isEmpty(currencies)) {
        const currency = find(currencies, ({ id }) => id === currencyId);

        return get(currency, `code`);
      }

      return null;
    }
  );

export const paymentSystemsSelector = createSelector<IRootState, IPaymentSystemsItem[], IPaymentSystemsItem[]>(
  widgetListSelector(WidgetType.PAYMENT_SYSTEMS),
  (list) => list || []
);

export const paymentSystemsWithoutSettlementsSelector = createSelector<
  IRootState,
  IPaymentSystemsItem[],
  IPaymentSystemsItem[]
>(paymentSystemsSelector, (list) => (list || []).filter((item) => !item.is_settlement));

export const paymentSystemLogoSelector = (walletType: string) =>
  createSelector(
    paymentSystemsSelector,
    (list: IPaymentSystemsItem[]) =>
      (list.find((item) => item.value === walletType || item.code === walletType) || { logo: `` }).logo
  );

export const transactionPSSelector = createSelector<
  IRootState,
  ITransactionsPaymentSystemsItems[],
  ITransactionsPaymentSystemsItems[]
>(widgetListSelector(WidgetType.TRANSACTIONS_PAYMENT_SYSTEMS), (list) => list || []);

export const PSWithoutSettlementsSelector = createSelector<
  IRootState,
  ITransactionsPaymentSystemsItems[],
  ITransactionsPaymentSystemsItems[]
>(transactionPSSelector, (list) => (list || []).filter((item) => !item.is_settlement));

export const paykassmaForReports = createSelector<
  IRootState,
  ITransactionsPaymentSystemsItems[],
  ITransactionsPaymentSystemsItems[]
>(transactionPSSelector, (list) =>
  (list || [])
    .filter((item) => item.code === `paykassma`)
    .map((el) => ({
      ...el,
      value: el.code,
      text: el.name,
    }))
);

export const isManualPSSelector = (walletType: string) =>
  createSelector(transactionPSSelector, (list) => {
    const finded = list.find((item) => item.code === walletType) || {};

    // @ts-expect-error Тип не корректно инферится.
    return IsManualPS(finded.payment_type);
    // return Boolean(list.find((el) => IsManualPS(el.payment_type)));
  });

export const withdrawalsPSSelector = createSelector<
  IRootState,
  IWithdrawalsPaymentsSystemsItem[],
  IWithdrawalsPaymentsSystemsItem[]
>(widgetListSelector(WidgetType.WITHDRAWALS_PAYMENT_SYSTEMS), (list) =>
  (list || [])
    .filter((ps) => !ps.is_settlement)
    .map((el) => ({
      ...el,
      value: el.code,
      text: el.name,
    }))
);

export const paymentTypeCheckboxesSelector = createSelector<
  IRootState,
  IPaymentSystemsItem[],
  IPaymentTypeCheckboxesItem[]
>(paymentSystemsSelector, (items: IPaymentSystemsItem[]): IPaymentTypeCheckboxesItem[] => {
  return map(items, ({ value, text }) => ({ label: text, name: value }));
});

export const transactionPSCheckboxesSelector = createSelector<
  IRootState,
  ITransactionsPaymentSystemsItems[],
  IPaymentTypeCheckboxesItem[]
>(transactionPSSelector, (items: ITransactionsPaymentSystemsItems[]): IPaymentTypeCheckboxesItem[] =>
  map(items, ({ value, text }) => ({ label: text, name: value }))
);

export const paymentSystemByCodeSelector = (paymentCode: string) =>
  createSelector<IRootState, IPaymentSystemsItem[], IPaymentSystemsItem | undefined>(
    paymentSystemsSelector,
    (items) => {
      return find(items, ({ code }) => code === paymentCode);
    }
  );

export const paymentLabelSelector = (paymentCode: string) =>
  createSelector<IRootState, IPaymentSystemsItem | undefined, string | undefined>(
    paymentSystemByCodeSelector(paymentCode),
    (payment) => payment?.text
  );

export const supportedPaymentSystemsByCurrencySelector = (currency: string) =>
  createSelector<IRootState, IPaymentSystemsItem[], ISelectData[]>(paymentSystemsSelector, (list) => {
    return list
      .filter((payment) => payment.supported_currencies.includes(currency))
      .map((item) => ({ value: item.code, text: item.name }));
  });

export const paymentCurrenciesSelector = (paymentCode: string) =>
  createSelector<
    IRootState,
    IPaymentSystemsItem | undefined,
    IManualPaymentSystemsItem[],
    ICurrenciesItems,
    ISelectData[]
  >(
    paymentSystemByCodeSelector(paymentCode),
    widgetListSelector(WidgetType.MANUAL_PAYMENT_SYSTEMS),
    widgetListSelector(WidgetType.CURRENCIES),
    (payment, testPaymentSystems, currencies) => {
      if (payment) {
        return map(get(payment, `supported_currencies`), (item) => ({ value: item, text: item }));
      } else {
        const testPS = find(testPaymentSystems, (testPS) => get(testPS, `code`) === paymentCode);
        if (testPS) {
          const currencyId = get(testPS, `currency_id`);
          const currency = find(currencies, (currency) => get(currency, `id`) === currencyId);
          const currencyCode = get(currency, `code`);

          if (currencyCode) {
            return [{ value: currencyCode, text: currencyCode }];
          }
        }
      }

      return [];
    }
  );

export const supportedWalletCurrenciesSelector = (paymentCode: string) =>
  createSelector<IRootState, IPaymentSystemsItem | undefined, ISelectData[], ISelectData[]>(
    paymentSystemByCodeSelector(paymentCode),
    paymentCurrenciesSelector(paymentCode),
    (payment, currenciesSelector) => {
      if (payment) {
        const supportedWalletCurrencies = get(payment, `supported_wallet_currencies`, []);

        if (supportedWalletCurrencies.length > 0) {
          return map(supportedWalletCurrencies, (item) => ({ value: item, text: item }));
        }
      }

      return currenciesSelector;
    }
  );

export const paymentItemSelector = (paymentCode: string) =>
  createSelector<IRootState, IPaymentSystemsItem[], IPaymentSystemsItem | undefined>(paymentSystemsSelector, (items) =>
    find(items, ({ code }) => code === paymentCode)
  );

export const filteredPaymentSystemsSelector = (requiredPaymentSystems: string[]) =>
  createSelector<IRootState, IPaymentSystemsItem[], IManualPaymentSystemsItem[], ISelectData[]>(
    paymentSystemsSelector,
    widgetListSelector(WidgetType.MANUAL_PAYMENT_SYSTEMS),
    (paymentSystems, testPaymentSystems) => {
      const paymentSystemList = map(paymentSystems, ({ text, value }) => ({ text, value }));
      const testPaymentSystemList = map(testPaymentSystems, ({ name, code }) => ({ text: name, value: code }));

      return filter([...paymentSystemList, ...testPaymentSystemList], ({ value }) =>
        includes(requiredPaymentSystems, value)
      );
    }
  );

export const paymentSystemTypeSelector = (paymentSystemCode: string) =>
  createSelector<IRootState, IPaymentSystemsItem[], string | undefined>(paymentSystemsSelector, (list) => {
    const paymentSystem = find(list, { value: paymentSystemCode });

    return paymentSystem?.payment_type;
  });

export const isP2PPaymentSystem = (paymentSystemCode: string) =>
  createSelector<IRootState, IPaymentSystemsItem[], boolean>(paymentSystemsSelector, (list) => {
    const paymentSystem = find(list, { value: paymentSystemCode });
    const safePaymentSystem = find(list, { code: paymentSystemCode });

    if (paymentSystem) {
      return paymentSystem?.payment_type === PaymentSystemType.P2P;
    }

    if (safePaymentSystem) {
      return safePaymentSystem?.payment_type === PaymentSystemType.P2P;
    }

    return false;
  });
