import get from 'lodash/get';
import { createSelector } from 'reselect';
import { widgetSelector, IRootState } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { IAddingProxies, IProxiesWidget } from 'interfaces/widgets/proxies';

export const isCheckingAllProxiesSelector = createSelector<IRootState, IProxiesWidget, boolean>(
  widgetSelector(WidgetType.PROXIES),
  (state) => state?.checkingAll || false
);

export const verifiedAddingProxiesSelector = createSelector<IRootState, IProxiesWidget, number>(
  widgetSelector(WidgetType.PROXIES),
  (state) => state?.verifiedAddingProxies || 0
);

export const verifyingAddingProxiesSelector = createSelector<IRootState, IProxiesWidget, boolean>(
  widgetSelector(WidgetType.PROXIES),
  (state) => state?.verifyingAddingProxies || false
);

export const invalidAddingProxiesSelector = createSelector<IRootState, IProxiesWidget, string | null>(
  widgetSelector(WidgetType.PROXIES),
  (state) => state.invalidAddingProxies
);

export const addingProxiesSelector = createSelector<IRootState, IProxiesWidget, IAddingProxies[] | null>(
  widgetSelector(WidgetType.PROXIES),
  (state) =>
    (state.addingProxies || []).map((item) => {
      if (`uuid` in item) {
        delete item.uuid;
      }

      return item;
    })
);

export const checkingAddingProxySelector = createSelector<IRootState, IProxiesWidget, boolean>(
  widgetSelector(WidgetType.PROXIES),
  (state) => get(state, `checkingAddingProxy`, false)
);

export const invalidFormatOfProxiesRelationsSelector = createSelector<IRootState, IProxiesWidget, string | undefined>(
  widgetSelector(WidgetType.PROXIES),
  (state) => state?.invalidFormatOfProxiesRelations
);

export const invalidDataOfProxiesRelationsSelector = createSelector<IRootState, IProxiesWidget, string | undefined>(
  widgetSelector(WidgetType.PROXIES),
  (state) => state?.invalidDataOfProxiesRelations
);
