import React, { FC } from 'react';
import cn from 'classnames';
import { EditorState } from 'draft-js';

import { PackageTranslation } from 'api/packages/PackagesApi';

import './CreationTranslateDropdown.scss';

type HandleChangeLang = (
  packageTranslationUuid: string,
  language: string,
  clientId?: string
) => (option: { value: string; text: string } | string) => void;

type HandleChangeText = (
  packageTranslationUuid: string,
  language: string,
  clientId?: string
) => (text: EditorState) => void;

type Props = {
  /**
   * Пакет с переводами
   */
  packageTranslation: PackageTranslation;

  /**
   * Коллбэк, добавляющий новую пару язык-перевод в пакете с переводами
   */
  handleAddTranslate: (packageTranslationUuid: string) => void;

  /**
   * Коллбэк, изменяющий язык в пакете с переводами
   */
  handleChangeLang: HandleChangeLang;

  /**
   * Коллбэк, изменяющий перевод в пакете с переводами
   */
  handleChangeText: HandleChangeText;

  /**
   * Коллбэк, удаляющий пару язык-перевод в переданном пакете
   */
  handleRemove: (packageTranslationUuid: string, clientId?: string) => () => void;

  /**
   * Идентификатор, в каком именно пакете с переводами нужно сделать изменения
   */
  fieldId: string;
  prevStateForEffect?: EditorState;

  children?: never;
};

export const CreationTranslateDropdown: FC<Props> = (props) => {
  const { packageTranslation } = props;

  return (
    <div className="CreationTranslateDropdown">
      <div className={cn(`CreationTranslateDropdown__header`)}>
        <h4 className="CreationTranslateDropdown__header_name">{packageTranslation.name}</h4>
      </div>
    </div>
  );
};
