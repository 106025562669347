export const WalletType = {
  PAG_SMILE: `pag_smile`,
  PAY_TM: `paytm`,
  EPAY: `epay`,
  CERTUS_FINANCE: `certus_fin`,
  IMPS_IB: `imps_ib`,
  UPI_IB: `upi_ib`,
  BKASH: `bkash`,
  DUSUPAY: `dusupay`,
  ZIGOPAY: `zigopay`,
  NGAN_LUONG: `nganluong`,
  NGAN_LUONG_EMAIL: `nganluong_email`,
  PAY_OP: `payop`,
  NAGAD: `nagad`,
  NAGAD_API: `nagad_api`,
  ROCKET: `rocket`,
  PHONE_PE: `phone_pe`,
  VIETCOM_BANK: `vietcombank`,
  PAYTRUST88: `paytrust88`,
  PAYME_UZ: `payme_uz`,
  SUREPAY: `surepay`,
  PAYZEN: `payzen`,
  DOLAR_DOLAR: `dolardolar`,
  TECHCOMBANK: `techcombank`,
  PAYGIGA: `paygiga`,
  YAAR_PAY: `yaarpay`,
  YAAR_PAY_OB: `yaarpay_ob`,
  YAAR_PAY_P2A: `yaarpay_p2a`,
  LOKALPAY: `lokalpay`,
  GIFTY_CODE: `giftycode`,
  UPI_AB: `upi_ab`,
  UPI_HB: `upi_hb`,
  IMPS_HB: `imps_hb`,
  UPI_NAAP: `upi_naap`,
  IMPS_AB: `imps_ab`,
  IMPS_IDB: `imps_idb`,
  UPI_IDB: `upi_idb`,
  TEST: `test`,
  LAYKAPAY: `layka_pay`,
  UPI_AP: `upi_ap`,
  UPI_L: `upi_l`,
  UZCARD_API: `uzcard_api`,
  PAYTM_API: `paytm_api`,
  UPI_PB: `upi_pb`,
  UPI_S: `upi_s`,
  BKASH_API: `bkash_api`,
  MPESA: `mpesa`,
  EASYPAISA: `easypaisa`,
  IPAY: `ipay`,
  /*
    См. https://jira.fixmost.com/browse/PAY-12492,
    раскомментить как будет понятно как должно работать
  */
  //UPI_SB: `upi_sb`,
  UPI_SF: `upi_sf`,
  UPI_IN: `upi_in`,
  UPI_W: `upi_w`,
  BKASH_API_CEBALRAI: `bkash_api_cebalrai`,
  CLICK_UZS: `click_uzs`,
  WAVE_PAY: `wavepay`,
  UPI_M: `upi_m`,
  PAYKASSMA: `paykassma`,
  PHONEPE_S: `phonepe_s`,
  JAZZCASH: `jazzcash`,
  IPAY_API: `ipay_api`,
  JAZZCASH_BUSINESS: `jazzcash_business`,
  EASYPAISA_API: `easypaisa_api`,
};

export const WalletStatus = {
  ERROR: 0,
  OK: 1,
  DISABLED: 2,
  PROBABLY_BANNED: 4,
  PROXY_FREE_BROKEN: 5,
  BANNED: 7,
  ALLOWED_BALANCE_EXCEEDED: 8,
};

export const WalletError = {
  PROXY_CONNECT: `ProxyConnectionError`,
  EMAIL_AUTH: `EmailAuthError`,
  ACCOUNT_AUTH: `AccountAuthError`,
  TRANSACTION_PARSING: `TransactionParsingError`,
  COOKIE_EXPIRED: `CookieExpiredError`,
  API_AUTH: `ApiAuthError`,
  API_INTEGRATION_ERROR: `APIIntegrationError`,
  ACCOUNT_DATA_ERROR: `AccountDataError`,
};

export const ProxyStatus = {
  ERROR: 0,
  SUCCESS: 1,
  CHECKING: 3,
};

export const TransactionStatus = {
  NOT_ACTIVATED: 0,
  ACTIVATED: 1,
  PENDING: 3,
  IN_PROCESSING: 4,
  CRASHED: 5,
  CANCELED: 6,
  REFUSED: 7,
  ERROR: 8,
};

export const TransactionPostbackStatus = {
  SENT: 1,
  ERROR_SENDING: 2,
  STOCKPILING_NOT_FOUND: 3,
  STOCKPILING_IN_PROGRESS: 0,
};

export const StatusCode = {
  OK: 200,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_ACCEPTABLE: 406,
  CONFLICT: 409,
  UNPROCESSABLE_ENTITY: 422,
};

export const PluginColorScheme = {
  LIGHT: `light`,
  DARK: `dark`,
  WHITE_GREEN: `white-green`,
  CUSTOM: `custom`,
};

export const PostbackSendStatus = {
  NO_SEND: 0,
  SEND: 1,
};

/**
 * Do not forget to add modal type to closeOnClickAwayTypes in components/wrappers/modalWrap/ModalWrap if it necessary
 */
export const ModalType = {
  CREATE: 0,
  UPDATE: 1,
  SHOW: 2,
  ERROR: 3,
  WALLETS_LOAD_SETTINGS: 4,
  PERMISSION_LIST: 5,
  ROLE_LIST: 6,
  SMS_CONFIRM: 7,
  DEPOSITS: 8,
  LOG_MESSAGE: 9,
  REMOVE_CONFIRMATION: 10,
  POSSIBLE_TRANSACTION_LIST: 11,
  COMMENT: 12,
  SETTINGS_MEDIA_UPLOADING: 13,
  RESET_WALLET_DATA: 14,
  CREATE_PAYMENTS_SORTING: 15,
  PAYMENT_CURRENCIES: 16,
  PAYMENTS_DEFAULT_SORTING: 17,
  PAYMENT_LOGOS: 18,
  INFO: 19,
  PAYMENT_TITLE: 20,
  REJECT_WITHDRAWAL: 21,
  APPROVE_WITHDRAWAL: 22,
  APPROVAL_CONFIRMATION: 23,
  CONFIRM_APPROVAL_CONFIRMATION: 24,
  CONFIRM_REJECTION_CONFIRMATION: 25,
  STOCKPILINGS_TRANSACTION_LIST: 26,
  STOCKPILINGS_POSTBACK: 27,
  VIEW_TYPE: 28,
  TRANSACTION_SEARCH: 29,
  ADDING_MULTIPLE_PROXIES: 30,
  PROXY_RELATIONS: 31,
  TRANSACTION_ACTIVATION: 32,
  TARIFFS: 33,
  REPLENISHMENT: 34,
  WRITE_OFF: 35,
  UPLOADING: 36,
  DOWNLOADING: 37,
  PAYMENT_SYSTEMS_LIST: 38,
  SETTINGS: 39,
  STOCKPILINGS_SEND_POSTBACK: 40,
  TRANSACTION_ARCHIVATION: 41,
  UPDATE_PAYMENTS_SORTING: 42,
  RESET_PAYMENTS_SORTING: 43,
  CONFIRM_DEFAULT_SORTING: 44,
  REMOVE_WALLET_DATA: 45,
  MONEY_TRANSFER: 46,
  EXTERNAL_REPLENISHMENT: 47,
  ARCHIVE_CONFIRMATION: 48,
  RECOVER_CONFIRMATION: 49,
  POSTBACK_LIST: 50,
  NOT_ACCEPTED_TRANSACTION_POSTBACKS: 51,
  WALLETS_SUMMARY: 52,
  WALLETS_STATISTIC: 53,
  TRANSACTION_APPROVE: 54,
};

export const WidgetType = {
  WALLETS_SUMMARY: `wallets-summary`,
  WALLETS_STATISTIC: `wallets-statistic`,
  PROXIES: `proxies`,
  WALLETS: `wallets`,
  BALANCE_TRANSFER_HISTORY: `balance-transfer-history`,
  DISABLED_WALLETS: `disabled-wallets`,
  REMOVED_WALLETS: `removed-wallets`,
  NOT_REMOVED_WALLETS: `not-removed-wallets`,
  CURRENCIES: `currencies`,
  TRANSACTIONS: `transactions`,
  STOCKPILING_TRANSACTIONS: `stockpiling-transactions`,
  POSSIBLE_TRANSACTIONS: `possible-transactions`,
  PRELIMINARY_TRANSACTIONS: `preliminary-transactions`,
  TRANSACTIONS_ONLY_BY_ID: `transactions-only-by-id`,
  PRELIMINARY_TRANSACTIONS_ONLY_BY_ID: `preliminary-transactions-only-by-id`,
  ARCHIVED_TRANSACTIONS: `archive-transactions`,
  STOCKPILING_LIST: `stockpiling-list`,
  POSTBACKS: `postbacks`,
  NOT_ACCEPTED_POSTBACKS: `not-accepted-postbacks`,
  WALLETS_LOAD: `wallets-load`,
  LOGS: `logs`,
  LOGS_CATEGORIES: `logs-categories`,
  WITHDRAWALS_LOGS: `withdrawals-logs`,
  PERMISSIONS: `permissions`,
  PAYTM_PARSING_TYPES: `paytm-parsing-types`,
  WALLET_PAYTM: `wallet-paytm`,
  WALLET_PAYZEN: `wallet-payzen`,
  MANUAL_PAYMENT_SYSTEMS: `manual-payment-systems`,
  PAYTM_COOKIES: `paytm-cookies`,
  COUNTRIES: `countries`,
  REPORTS: `reports`,
  AUTOREPORTS: `autoreports`,
  TIMEZONES: `timezones`,
  SETTINGS: `settings`,
  WITHDRAWALS: `withdrawals`,
  WALLET_PLUGIN_STATUS: `wallet-plugin-status`,
  STANDARD_STATUS_SETTINGS: `standard-status-settings`,
  CHANGE_LOGS: `change-logs`,
  CHANGE_LOG_OBJECTS: `change-log-objects`,
  CHANGE_LOG_ACTIONS: `change-log-actions`,
  CHANGE_LOG_USERS: `change-log-users`,
  ACCOUNTS_BALANCES: `acounts-balances`,
  SETTLEMENT: `settlement`,
  SETTLEMENT_WITHDRAWAL: `settlement-withdrawal`,
  REPLENISHMENT: `replenishment`,
  WALLET_ADMIN_STATUS: `wallet-admin-status`,

  // Payment systems
  PAYMENT_SYSTEMS: `payment-systems`,
  PAYMENT_SYSTEMS_FOR_FORCED_TRANSACTIONS: `payment-systems-for-forced-transactions`,
  PAYMENT_SYSTEMS_FOR_POSSIBLE_TRANSACTIONS: `payment-systems-for-possible-transactions`,
  WITHDRAWALS_PAYMENT_SYSTEMS: `withdrawals-payment-systems`,
  TRANSACTIONS_PAYMENT_SYSTEMS: `transactions-payment-systems`,

  PAYMENTS_CONSTRUCTOR: `payments-constructor`,
  PAYMENT_HINTS_CONSTRUCTOR: `payment-hints-constructor`,
  PAYMENT_HINT_LANGS: `payment-hint-langs`,

  DASHBOARD_PAYMENT_SYSTEMS_INFO: `dashboard-payment-systems-info`,

  // Withdrawals
  WITHDRAWALS_BANK_CODES: `withdrawals-bank-codes`,
  PAYGIGA_BANK_CODES: `paygiga-bank-codes`,
  PAYGIGA_AVAILABLE_AMOUNTS: `paygiga-available-amounts`,
  WITHDRAWALS_BRANCH_CODES: `withdrawals-branch-codes`,
  WITHDRAWALS_CURRENCY_CODES: `withdrawals-currency-codes`,
  WITHDRAWALS_PROVIDERS: `withdrawals-providers`,
  WITHDRAWALS_PAYOUT_METHODS: `withdrawal-payout-methods`,
  WITHDRAWALS_CARDS: `withdrawals-cards`,
  WITHDRAWALS_APPROVAL_DATA: `withdrawals-approval-data`,
  WITHDRAWALS_STATUS: `withdrawals-status`,

  // Dashboard
  DASHBOARD_PAYMENTS: `dashboard-payments`,
  DASHBOARD_TRANSACTIONS: `dashboard-transactions`,
  DASHBOARD_WITHDRAWALS: `dashboard-withdrawals`,

  ACCOUNT_BALANCE_HISTORY: `account-balance-history`,
};

export const WalletsLoadType = {
  TRANSACTIONS_COUNT: `transactions_count`,
  TRANSACTIONS_SUM: `transactions_sum`,
};

export const WalletsLoadDirection = {
  OUTGOING: `outgoing`,
  INGOING: `ingoing`,
};

export const TransactionDirection = {
  OUTGOING: `outgoing`,
  INGOING: `ingoing`,
};

export const WalletsLoadObject = {
  WALLETS: `wallets`,
  PAYMENT_SYSTEMS: `payment_systems`,
};

export const ArchivingStatus = {
  ALL: `all`,
  ONLY_ARCHIVED: `onlyArchived`,
  WITHOUT_ARCHIVED: `withoutArchived`,
};

export const Permission = {
  MANAGE_AUTO_GENERATING_REPORTS: `admin.reports.autoreports.manage`,
  PAYMENT_SETTINGS: `admin.payment.settings`,
  PLUGIN_SETTINGS: `admin.settings.payment-plugin`,
  WALLET_VISIBILITY: `admin.wallet.visibility`,
  WALLET_CREATE: `admin.wallet.create`,
  WALLET_UPDATE: `admin.wallet.update`,
  WALLET_DELETE: `admin.wallet.delete`,
  WALLET_HARD_DELETE: `admin.wallet.hard-delete`,
  WALLET_SET_ACTIVE: `admin.wallet.set-active`,
  PROXY_CREATE: `admin.proxy.create`,
  PROXY_UPDATE: `admin.proxy.update`,
  PROXY_DELETE: `admin.proxy.delete`,
  PROXY_CHECK: `admin.proxy.check`,
  RBAC: `admin.rbac.all`,
  TRANSACTION_LIST: `admin.transaction.list`,
  TRANSACTION_ONLY_BY_ID: `admin.transaction.list-only-with-transaction-id`,
  TRANSACTION_MANAGEMENT: `admin.transaction.possible-transaction-manager`,
  PRELIMINARY_TRANSACTION: `admin.pretransaction.list`,
  PRELIMINARY_TRANSACTION_ONLY_BY_ID: `admin.pretransaction.list-only-with-pretransaction-id`,
  PRELIMINARY_TRANSACTION_MANAGEMENT: `admin.pretransaction.pretransaction-manager`,
  ARCHIVE_TRANSACTION_MANAGEMENT: `admin.transaction.archive-transaction-manager`,
  ARCHIVE_TRANSACTION_VIEW: `admin.transaction.archive-transactions-view`,
  DASHBOARD: `admin.dashboard.all`,
  CREATE_DEBUG_TRANSACTION: `admin.transaction.debug-transaction.create`,
  WALLET_LIST: `admin.wallet.list`,
  PROXY_LIST: `admin.proxy.list`,
  LOGS: `admin.logs.list`,
  WALLETS_LOAD: `admin.wallets-load`,
  WALLETS_DISABLED: `admin.disabled-wallets.list`,
  WALLETS_REMOVED: `archived-wallets.view`,
  TRANSACTION_CLIENT: `admin.transaction.client`,
  PLUGIN_SETTINGS_CLIENT: `admin.settings.payment-plugin.client`,
  MANUAL_PS_MANAGEMENT: `admin.test_wallet_type.manager`,
  CREATE_FORCED_TRANSACTION: `admin.transaction.forced-transaction.create`,
  ACTIVATE_TRANSACTION: `transaction.activate`,
  REMOVE_POSSIBLE_TRANSACTION: `admin.transaction.possible_transaction.delete`,
  MANAGE_PAYMENTS_CONSTRUCTOR: `admin.payment-systems.manage`,
  PAYTM_COOKIES_VIEW: `admin.paytm_cookies.view`,
  PAYTM_COOKIES_DELETE: `admin.paytm_cookies.delete`,
  PAYTM_COOKIES_UPLOAD: `admin.paytm_cookies.upload`,
  WALLET_BALANCE: `admin.wallet.balance-view`,
  WALLET_NUMBER: `admin.wallet.private-information-view`,
  MEDIA_SETTINGS: `admin.media.settings`,
  MANUAL_PS_LIST: `admin.test_payment_systems.list`,
  DISPLAY_TRANSACTION_TYPE: `admin.transaction.view-types`,
  TRANSACTION_REPORTS: `admin.report.transactions`,
  LABEL_HISTORY_REPORT: `admin.report.label-history`,
  TIMEZONE_SET: `admin.settings.timezone.update`,
  TIMEZONE_GET: `admin.settings.timezone.view`,
  POSTBACKS: `admin.postbacks.view`,
  POSTBACK_SEND: `admin.postback.sending`,
  WITHDRAWALS_VIEW: `admin.withdrawals.view`,
  WITHDRAWALS_MANAGE: `admin.withdrawals.manage`,
  WITHDRAWALS_CREATE: `admin.withdrawals.create`,
  TRANSACTION_INNER: `admin.transaction.inner`,
  STOCKPILING_LIST: `admin.stockpiling.list`,
  VIEW_CHANGE_LOG: `admin.change-log.view`,
  ACCOUNTS_BALANCE: `admin.account-balances.view`,
  VIEW_SETTLEMENT: `admin.account-balances.settlements.view`,
  SETTLEMENT_CREATE_WITHDRAWAL: `admin.account-balances.settlement-withdrawal.create`,
  MANAGER_BALANCE: `admin.account-balances.manage`,
  COMMISSION_REPORT: `admin.report.commission`,
  COMMISSION_VIEW: `admin.transaction.commission-view`,
  LOAD_TRANSACTIONS: `admin.transactions.load`,
  DELETE_PAYTM_COOKIES: `admin.paytm_cookies.delete`,
  WITHDRAWAL_REQUEST_REPORT: `admin.report.withdrawal-list`,
  CLIENT_BALANCE_HISTORY_REPORT: `admin.reports.client-balance-history`,
  VIEW_SYSTEM_SETTINGS: `admin.settings.system-settings.view`,
  WALLET_PROVIDER_CODE: `admin.reports.wallet-provider-code-view`,
  TRANSACTION_PRIVATE_INFORMATION_VIEW: `admin.transaction.private-information.view`,

  TRANSACTION_SUSPECTS_TRANSACTIONS_VIEW: `admin.transaction.suspects_transactions_view`,
  SUSPECTS_TRANSACTIONS_APPROVE: `admin.transaction.suspects-transactions-approve`,
  SUSPECTS_TRANSACTIONS_DELETE: `admin.transaction.suspects-transactions.delete`,

  ACTIVATED_FAKE_DELETE: `activated-fake.delete`,
  NON_ACTIVATED_FAKE_DELETE: `nonactivated-fake.delete`,

  TRANSACTION_SET_FAKE: `admin.transaction.set-fake`,
  PRIVATE_SETTINGS_VIEW: `private.settings.view`,
  TRANSACTION_NUMBER_VIEW: `admin.transaction.number.view`,
  POSTBACK_SENDING: `admin.postback.sending`,
  OPERATION_WITH_BALANCES: `admin.report.settlement-list`,
  WALLET_COMMENT: `wallet.comment`,
  WALLET_SETTINGS: `wallet.settings`,
};

export const PreliminaryTransactionStatus = {
  NEW: 0,
  PROCESSED: 1,
};

export const LoadingWalletStatus = {
  STOPPED: 0,
  ACTIVE: 1,
  DELETED: 2,
};

export const TransactionKeyType = {
  WITHOUT_KEY: 0,
  WITH_KEY: 1,
};

export const TransactionKind = {
  REAL: 0,
  DEBUG: 1,
  FORCED: 2,
  WITHDRAWAL: 3,
  INNER: 4,
};

export const TransactionField = {
  ARCHIVING_DATES: `archiving_date`,
  WALLET_IDENTIFIER: `wallet_identifier`,
  TRANSACTION_AMOUNT: `transaction_amount`,
  TRANSACTION_NUMBER: `transaction_number`,
  ORDER_ID: `order_id`,
  MERCHANT_ORDER_ID: `merchant_order_id`,
  EXCHANGER_IDENTIFIER: `exchanger_identifier`,
  COMMISSION: `total_commission`,
  DATE: `date`,
  ACTIVATION_DATE: `activated_date`,
  STOCKPILING_END_DATE: `stockpiling_date`,
  LABEL: `label`,
  STATUS: `status`,
  TYPE: `type`,
  KIND_OF_TRANSACTION: `creation_type`,
  COUNTRY: `country`,
  VENDOR_CODE: `vendor_code`,
  POSTBACK_STATUS: `postback_status`,
  STOCKPILING_ID: `stockpiling_id`,
  ORIGINALITY: `originality`,
};

export const PostbackStatus = {
  POSTBACK_NOT_SEND: 0,
  POSTBACK_OK: 1,
  POSTBACK_ERROR: 2,
  POSTBACK_STOCKPILING_NOT_FOUND: 3,
};

export const WithdrawalStatus = {
  NEW: 0,
  PROCESSED: 1,
  CANCELED: 2,
  IN_PROGRESS: 3,
  CHECK: 4,
  REJECTED: 5,
  ADD_BENEFICIARE: 6,
  ERROR: 8,
};

export const WithdrawalKind = {
  DEBUG: 0,
  CONFIRMED: 1,
};

export const PostbackType = {
  WITHDRAWAL: `withdrawal`,
  RECEIPT: `receipt`,
};

export const WithdrawalPayoutMethod = {
  MOBILE_MONEY: `MOBILE_MONEY`,
  BANK: `BANK`,
};

export const WithdrawalType = {
  AUTO: 0,
  MANUAL: 1,
};

export const ChartType = {
  LINE: 0,
  BAR: 1,
};

export const DashboardChartEntityType = {
  TRANSACTION: 0,
  WITHDRAWAL: 1,
};

export const DashboardChartCategory = {
  SUM: 0,
  COUNT: 1,
};

export const PaytmScrapingStatus = {
  SCRAPING: `scraping`,
  QUEUED: `queued`,
  SCRAPING_ANOTHER_WALLET: `scraping_another`,
};

export const TransactionDateType = {
  ACTIVATION: `activation_date`,
  CREATION: `creation_date`,
  STOCKPILING: `completion_date`,
};

export const PaymentTranslationFormType = {
  REQUISITES: `requisites_hint`,
  REQUISITE: `requisite`,
  PAYMENT: `payment`,
};

export const PaymentTranslationFieldType = {
  HINT: `hint`,
  FIELD: `field`,
};

export const PaymentSystemType = {
  P2P: `P2P`,
  API: `API`,
  MANUAL: `MANUAL`,
};

export const DropdownPosition = {
  LEFT: `left`,
  RIGHT: `right`,
};

export const TransactionCreationType = {
  AUTO: `auto`,
  MANUAL: `manual`,
};

export const ImpsTransferType = {
  OTHER_BANK_CLIENT: 0,
  ICICI_BANK_CLIENT: 1,
};

export const ReportType = {
  LABEL_HISTORY_REPORT: `label_statistics`,
  SUM_REPORT: `transactions_sum`,
  LIST_REPORT: `transactions_list`,
  TRANSACTIONS_SUM_DETAIL: `transactions_sum-detail`,
  COMMISSION_REPORT: `reports.commission`,
  WITHDRAWAL_REQUEST_REPORT: `withdrawal_request`,
  CLIENT_ACCOUNT_BALANCE_CHANGE: `balance_history`,
  COMPLETED_TRANSACTION_SUM: `completed_transaction_sum`,
  LIST_REPORT_WITH_COMPLETED_STOCKPILING: `list_report_with_completed_stockpiling`,
  OPERATIONS_WITH_BALANCES: `operations_with_balances`,
};

export const WithdrawalsBankSelectionType = {
  LIST: `list`,
  TEXT: `text`,
};

export const WithdrawalMethod = {
  API: 0,
  MANUAL: 1,
};

export const ParserType = {
  PARSER_ICICI: `icici`,
  FEDNET: `fednet`,
  PARSER_INDUSIN: `indusind`,
  PARSER_FREE_CHARGE: `freecharge`,
  PARSER_HDFC: `hdfc`,
  PARSER_EMAIL: `email`,
  PARSER_AXIS: `icici`,
  PARSER_SITE: `site`,
  PARSER_APP: `app`,
  PARSER_PHONE_PE_MOBILE_API: `mobile_api`,
  MOBILE_API: `mobile_api`,
};

export const WalletConfirmationInfoType = {
  ICICI_LOGIN_CONFLICT: 1,
  IDB_LOGIN_CONFLICT: 2,
};

export const WithdrawalLogType = {
  INCORRECT_WALLET_SENDER: `empty_wallet_sender`,
  INCORRECT_STATUS: `incorrect_status`,
};

export const TransactionConfirmationType = {
  DUPLICATED_TRANSACTION: 1,
};

export const WalletFormType = {
  CREATE: `create`,
  UPDATE: `update`,
};

export const WalletPriority = {
  LOW: 0,
  BELOW_NORMAL: 25,
  NORMAL: 50,
  ABOVE_NORMAL: 75,
  HIGH: 100,
};

export const SettlementWithdrawalStatusType = {
  NEW: 0,
  SUCCESS: 1,
  REJECTED: 2,
};

export const SettlementWithdrawalActionType = {
  APPROVE: `approve`,
  REJECT: `reject`,
};

export const ReportOperationType = {
  TRANSACTION_ACTIVATION: `transaction_activation`,
  TRANSACTION_ARCHIVING: `transaction_archiving`,
  TRANSFER_BETWEEN_ACCOUNTS: `transfer_between_accounts`,
  TRANSFER_BETWEEN_ACCOUNT_AND_SETTLEMENT: `transfer_between_account_and_settlement`,
};

export const ReportDirection = {
  REPLENISHMENT: `replenishment`,
  WRITE_OFF: `write_off`,
};

export const ReportFileFormat = {
  XLSX: `xlsx`,
  CSV: `csv`,
};

export const PostbackFilter = {
  RECEIVED: 1,
  ERROR: 0,
};

export const SettlementOperation = {
  OUTGOING: -1,
  INGOING: +1,
  REPLENISHMENT: +2,
  WRITE_OFF: -2,
};

export const SettlementOperationGroup = {
  REPLENISHMENT: `replenishment`,
  TRANSFER: `transfer`,
  WITHDRAWAL: `withdrawal`,
};

export const FilterWalletStatus = {
  WORKED: `worked`,
  DISABLED: `disabled`,
  REMOVED: `removed`,
};

export const AccountType = {
  AGENT: `agent`,
  PERSONAL: `personal`,
  MERCHANT: `merchant`,
};

export const reportType = {
  day: `day`,
  week: `week`,
  month: `month`,
  quarter: `quarter`,
  year: `year`,
};

export const WalletState = {
  ALLOWED_BALANCE_EXCEEDED: `allowed_balance_exceeded`,
  AUTHORISATION_ERROR: `authorisation_error`,
  INTEGRATION_ERROR: `integration_error`,
  PROBABLY_BANNED: `probably_banned`,
  PROXY_NOT_WORKING: `proxy_isnt_working`,
  ERROR_IN_TRANSACTION_PARSING: `error_in_transaction_parsing`,
  PARSER_ERROR: `parser_error`,
  NO_COOKIES: `no_cookies`,
  PENDING_SMS_CONFIRMATION: `pending_sms_confirmation`,
  UPDATE_COOKIES: `update_cookies`,
  ACCOUNT_DATA_ERROR: `account_data_error`,
  UNKNOWN_ERROR: `unknown_error`,
  DAILY_LIMIT_EXCEEDED: `daily_limit_exceeded`,
  DATA_ACCESS_ERROR: `data_access_error`,
  PK_CONNECTION_ERROR: `pk_connection_error`,
  NO_TRANSACTIONS: `no_transactions`,
  DAILY_TRANSACTION_LIMIT_EXCEEDED: `daily_transaction_limit_exceeded`,
  MONTHLY_TURNOVER_LIMIT_EXCEEDED: `monthly_turnover_limit_exceeded`,
  DAILY_TURNOVER_LIMIT_EXCEEDED: `daily_turnover_limit_exceeded`,
};

export const ReportStatus = {
  NEW: 2,
  IN_PROGRESS: 0,
  SUCCESS: 1,
  FAIL: 3,
};
