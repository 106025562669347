import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import { IManualPSData, IManualPSUpdateData } from 'interfaces/widgets/paymentSystems';

export class TestPaymentSystemApi extends BaseApi {
  createManualPaymentSystem = (data: IManualPSData): AxiosPromise =>
    this.makeFetch(`create`, { method: `POST`, data, formData: true });

  deleteManualPaymentSystem = (machineName: string): AxiosPromise =>
    this.makeFetch(`{machineName}/delete`, { method: `DELETE` }, { machineName });

  disableManualPaymentSystem = (machineName: string): AxiosPromise =>
    this.makeFetch(`{machineName}/disable`, { method: `POST` }, { machineName });

  loadManualPaymentSystemItem = (id: number): AxiosPromise => this.makeFetch(`{id}/show`, {}, { id });

  loadManualPaymentSystemList = (): AxiosPromise => this.makeFetch(`list`);

  updateManualPaymentSystem = (id: number, data: IManualPSUpdateData): AxiosPromise =>
    this.makeFetch(`{id}/update`, { data, method: `POST`, formData: true }, { id });
}

const testPaymentSystemApi = new TestPaymentSystemApi(`test-wallet-types`);

export default testPaymentSystemApi;
