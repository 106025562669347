import { AxiosMiddleware, ICreateApiFetchOptions } from 'api/createApiFetch';
import { v4 as uuidv4 } from 'uuid';

export const newResponseOptionsMiddleware: AxiosMiddleware = (options: ICreateApiFetchOptions) => {
  options.headers = {
    ...options.headers,
    'Request-Version': `1.000.0`,
    'Request-Id': uuidv4(),
  };

  return options;
};
