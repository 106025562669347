import { BaseApi } from 'api/common/BaseApi';
import { AxiosPromise } from 'axios';
import { ParsedQuery } from 'query-string';

export type WalletSummary = {
  all: number;
  currency: string;
  wallet_off_limit: number;
  wallet_off_load: number;
  wallet_type: string;
  wallet_type_name: string;
  worked: number;
};

export type WalletStatistic = {
  // Тип ПС
  wallet_type: string;
  // Наименование ПС
  wallet_type_name: string;
  // Валюта ПС
  currency: string;
  // Общее количество рабочих кошельков с включенной видимостью
  visible_counter: number;
  // Общее количество рабочих кошельков
  worked_counter: number;
  // Общее количество кошельков
  all_counter: number;
  // Текущий оборот видимых - сумма фактически совершенных транзакций за сегодняшний день (с 00:00 UTC) по видимым кошелькам
  visible_daily_turnover: number;
  // Суммарное значение параметра "Отключать при балансе"
  visible_wallet_off_limit: number;
  // Суммарное значение параметра "Баланс"
  visible_balance: number;
  // Суммарное значение параметра "Суточный лимит по сумме"
  visible_wallet_off_load: number;
  // Суммарное значение параметра "Баланс"
  not_removed_balance: number;
  // 	Суммарное значение параметра "Отключать при балансе"
  not_removed_wallet_off_limit: number;
  // Текущий оборот  - сумма фактически совершенных транзакций за сегодняшний день (с 00:00 UTC) по  кошелькам  (removed=NULL ) этой ПС
  not_removed_daily_turnover: number;
  // Суммарное значение параметра "Суточный лимит по сумме"
  not_removed_wallet_off_load: number;
  // Массив значений по дневному обороту за 7 дней
  all_daily_turnover: Array<{
    // Дата, за которую был сформирован оборот по кошелькам
    date: string;
    // Дневной оборот  - сумма фактически совершенных транзакций за день (с 00:00 UTC) по  кошелькам  этой ПС
    value: number;
  }>;
};

// {
//   "currency": "USD",
//   "wallet_type": "bkash",
//   "wallet_type_name": "Bkash",
//   "visible_daily_turnover": 0,
//   "not_removed_daily_turnover ": 0,
//   "all_daily_turnover": [
//       {
//           "date": "2023-11-16",
//           "value": 0
//       },
//       {
//           "date": "2023-11-15",
//           "value": 0
//       },
//       {
//           "date": "2023-11-14",
//           "value": 0
//       },
//       {
//           "date": "2023-11-13",
//           "value": 0
//       },
//       {
//           "date": "2023-11-12",
//           "value": 0
//       },
//       {
//           "date": "2023-11-11",
//           "value": 0
//       },
//       {
//           "date": "2023-11-10",
//           "value": 0
//       }
//   ],
//   "visible_counter": 0,
//   "worked_counter": 3,
//   "all_counter": 5,
//   "visible_wallet_off_limit": 0,
//   "visible_balance": 0,
//   "visible_wallet_off_load": 0,
//   "not_removed_balance": 3705.17,
//   "not_removed_wallet_off_limit": 0,
//   "not_removed_wallet_off_load": 0
// }

class WalletsApi extends BaseApi {
  getWallet = (id: number) => this.makeFetch(`{id}`, {}, { id });

  loadWalletList = (queryParams: Record<string, unknown>): AxiosPromise => {
    const { withoutQuery, ...rest } = queryParams || {};
    let params = { ...rest };
    if (!withoutQuery) {
      params = {
        is_active: 1,
        removed: 0,
        ...queryParams,
      };
    }

    return this.makeFetch(``, {
      queryParams: params,
    });
  };

  loadRemovedWalletList = (queryParams: ParsedQuery): AxiosPromise =>
    this.makeFetch(``, {
      queryParams: {
        removed: 1,
        ...queryParams,
      },
    });

  loadDisabledWalletList = (queryParams: Record<string, unknown>): AxiosPromise =>
    this.makeFetch(``, {
      queryParams: {
        is_active: 0,
        removed: 0,
        ...queryParams,
      },
    });

  loadNotRemovedWalletList = (): AxiosPromise => this.makeFetch(``, { queryParams: { removed: 0 } });

  getSummary = () =>
    this.makeFetch<{
      data: ReadonlyArray<WalletSummary>;
    }>(`summary`);

  getStatistics = () => this.makeFetch<ReadonlyArray<WalletStatistic>>(`statistic`);
}

export default new WalletsApi(`wallets`);
