import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useWindowSize from 'react-use/lib/useWindowSize';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-dnd';
import ceil from 'lodash/ceil';
import size from 'lodash/size';
import map from 'lodash/map';
import isFunction from 'lodash/isFunction';
import { FormSubmissionButton } from '@kassma-team/kassma-toolkit/lib';
import { Dispatch } from 'redux';

import { settingsActionCreators } from 'actions/widgets/settings';
import { ModalType } from 'utils/enums';
import { IDragNDropOrderProps } from 'interfaces/widgets/settings/pluginSettings';

import './drugnDropOrder.scss';

const { showModal } = settingsActionCreators;

const SORTING_ITEM_HEIGHT = 110;

interface IOnSubmitProps extends Omit<IDragNDropOrderProps, `setSorting`> {
  dispatch: Dispatch;
}

const onSubmit = ({
  dispatch,
  submitAction,
  currency,
  sorting,
  defaultSorting,
  sortingListsAreEqual,
}: IOnSubmitProps) => {
  const paymentSystems = map(sorting, ({ type, name, id, test_wallet_type_id }) =>
    type === `test` ? { type, name, test_wallet_type_id, id } : { type, id }
  );
  if (isFunction(sortingListsAreEqual) && sortingListsAreEqual(sorting, defaultSorting)) {
    dispatch(
      showModal({
        type: ModalType.CONFIRM_DEFAULT_SORTING,
        submitAction: submitAction({ currency, isDefault: true, paymentSystems }),
        currency: currency,
      })
    );
  } else {
    dispatch(submitAction({ currency, paymentSystems }));
  }
};

const DragNDropOrder = ({
  sorting,
  setSorting,
  defaultSorting,
  currency,
  submitAction,
  sortingListsAreEqual,
}: IDragNDropOrderProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { width } = useWindowSize();
  const columnCount = useMemo(() => (width > 500 ? 3 : 1), [width]);

  const onChange = (sourceId: string, sourceIndex: number, targetIndex: number) => {
    setSorting(swap(sorting, sourceIndex, targetIndex));
  };

  return (
    <div>
      <div className="payment-constructor__header-section">
        <span className="font-weight-bold mr-5">{t(`settings.displayOrder`)}</span>
      </div>
      <GridContextProvider onChange={onChange}>
        <GridDropZone
          id="items"
          className="payment-grid__container"
          style={{
            height: `${ceil(size(sorting) / columnCount) * SORTING_ITEM_HEIGHT}px`,
          }}
          boxesPerRow={columnCount}
          rowHeight={SORTING_ITEM_HEIGHT}
        >
          {map(sorting, (payment) => (
            <GridItem key={payment.name} className="payment-item">
              <div className="payment-item__content">
                <img draggable={false} src={payment.logo} alt="" className="payment-item__logo" />
                <span className="payment-item__text">{payment.name}</span>
                {payment.extraContent}
              </div>
            </GridItem>
          ))}
        </GridDropZone>
      </GridContextProvider>
      <div className="d-flex mt-20">
        <button className="btn btn-secondary mr-10" type="button" onClick={() => setSorting(defaultSorting)}>
          {t(`paymentConstructor.resetOrder`)}
        </button>
        <FormSubmissionButton
          className="btn btn-primary"
          type="button"
          onClick={() =>
            onSubmit({
              dispatch,
              submitAction,
              currency,
              sorting,
              defaultSorting,
              sortingListsAreEqual,
            })
          }
        >
          {t(`common.save`)}
        </FormSubmissionButton>
      </div>
    </div>
  );
};

export default DragNDropOrder;
