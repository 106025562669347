import React from 'react';
import { useSelector } from 'react-redux';

import { walletActionCreators } from 'actions/widgets/wallet';
import { WidgetType } from 'utils/enums';
import { useProxyNightParserSelector, useProxySelector } from 'selectors/widgets/constructor/paymentsConstructor';

import WalletUpdatingForm from 'components/widgets/wallets/WalletUpdatingForm';

const WalletUpdating = () => {
  //TODO: КОСТЫЛЬ, используется для валидации кошельков
  const useProxy = useSelector(useProxySelector);
  const useProxyNightEmailParser = useSelector(useProxyNightParserSelector);

  return (
    <WalletUpdatingForm
      useProxy={useProxy}
      useProxyNightEmailParser={useProxyNightEmailParser}
      actionCreators={walletActionCreators}
      widget={WidgetType.WALLETS}
    />
  );
};

export default WalletUpdating;
