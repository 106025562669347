import { takeEvery, put, select, call } from 'redux-saga/effects';
import { change, initialize } from 'redux-form';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { toastr } from 'react-redux-toastr';
import { AxiosError, AxiosResponse } from 'axios';
import {
  formSubmissionError,
  validateForm,
  langSelector,
  WIDGET_SET_SUBMITTING_STATUS,
  IWidgetsMeta,
  IAction,
} from '@kassma-team/kassma-toolkit/lib';
import i18n from 'i18n';

import settingsApi from 'api/settings/SettingsApi';
import userApi from 'api/auth/UserApi';

import { getErrorMessage } from 'utils';
import {
  LOAD_SETTINGS_REQUESTED,
  UPDATE_CUSTOM_STYLE_REQUESTED,
  UPDATE_HELP_IMAGES_REQUESTED,
  UPDATE_HELP_IMAGES_SUCCEEDED,
  UPDATE_SETTINGS_REQUESTED,
  UPDATE_PASSWORD_REQUESTED,
  UPDATE_TIMEZONE,
  UPDATE_PLUGIN_COLOR_SCHEME,
  LOAD_PLUGIN_SETTINGS_REQUESTED,
  LOAD_SETTINGS_TIMEZONE,
  LOAD_PAYTM_FLAG_REQUESTED,
  DELETE_HELP_IMAGES,
  LOAD_PRIVATE_SETTINGS_REQUESTED,
  UPDATE_PRIVATE_SETTINGS_REQUIRED,
} from 'actionTypes';
import { setAppVersion } from 'actions/widgets/settings/settings';
import { loadPluginSettings, settingsActionCreators } from 'actions/widgets/settings';
import { APPEARANCE_SETTINGS, PRIVATE_SETTINGS, PROFILE_SETTINGS, SYSTEM_SETTINGS } from 'utils/constants';
import { IPluginColorScheme } from 'interfaces/widgets/settings/pluginSettings';
import { ITimezoneUpdate, IUpdatePrivateSettingsForm, IUpdateSettingsForm } from 'interfaces/widgets/settings';
import { IPasswordChangeForm } from 'interfaces/widgets/settings/profileSettings';
import refreshSaga from 'sagas/effects/refreshSaga';
import walletTypeImageApi from '../../../api/walletType/WalletTypeImage';

interface IUpdateHelpImagesProps {
  payload: Record<string, File>;
  meta: IWidgetsMeta;
}

function* updateHelpImagesSaga({ payload, meta }: IUpdateHelpImagesProps) {
  yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: true, meta });
  yield refreshSaga({
    request: () => {
      return Promise.all(
        Object.keys(payload).map((key) => {
          return walletTypeImageApi.createImage({
            [`wallet_type`]: key,
            [`file`]: payload[key],
          });
        })
      );
    },
    onSuccess: function* (resp: AxiosResponse) {
      const payload = get(resp, `data.gif_guide`);
      yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      yield put(settingsActionCreators.hideModal());
      yield put({ type: UPDATE_HELP_IMAGES_SUCCEEDED, payload, meta });
      yield put(loadPluginSettings());
      toastr.success(i18n.t(`common.success`), i18n.t(`settings.mediaFilesHaveBeenSuccessfullyUploaded`));
    },
    onError: function* (e: AxiosError) {
      const payload = getErrorMessage(e, {
        normalize: true,
        defaultValue: i18n.t(`widgets.itemUpdatingHasBeenFailed`),
      });
      yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      toastr.error(i18n.t(`common.error`), payload);
    },
  });
}

function* deleteHelpImagesSaga({ payload, meta }: any) {
  yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: true, meta });
  yield refreshSaga({
    request: () => {
      return Promise.all(
        payload.map((id: string) => {
          return walletTypeImageApi.deleteImage(id);
        })
      );
    },
    onSuccess: function* (resp: AxiosResponse) {
      const payload = get(resp, `data.gif_guide`);
      yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      yield put(settingsActionCreators.hideModal());
      yield put({ type: UPDATE_HELP_IMAGES_SUCCEEDED, payload, meta });
      yield put(loadPluginSettings());
      toastr.success(i18n.t(`common.success`), i18n.t(`settings.mediaFilesHaveBeenSuccessfullyUploaded`));
    },
    onError: function* (e: AxiosError) {
      const payload = getErrorMessage(e, {
        normalize: true,
        defaultValue: i18n.t(`widgets.itemUpdatingHasBeenFailed`),
      });
      yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      toastr.error(i18n.t(`common.error`), payload);
    },
  });
}

interface IUpdateCustomThemeProps {
  payload: Record<string, any>;
  meta: IWidgetsMeta;
}

function* updateCustomThemeSaga({ payload: file, meta }: IUpdateCustomThemeProps) {
  yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: true, meta });

  yield refreshSaga({
    request: () => settingsApi.updateCustomStyle({ file }),
    onSuccess: function* () {
      yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      yield put(settingsActionCreators.hideModal());
      toastr.success(i18n.t(`common.success`), i18n.t(`settings.stylesHaveBeenUpdated`));
    },
    onError: function* (e: AxiosError) {
      const payload = getErrorMessage(e, {
        normalize: true,
        defaultValue: i18n.t(`settings.stylesUpdatingHasBeenFailed`),
      });
      yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      toastr.error(i18n.t(`common.error`), payload);
    },
  });
}

interface IUpdateSettingsProps {
  payload: IUpdateSettingsForm;
  meta: IWidgetsMeta;
}

function* updateSettingsSaga({ payload, meta }: IUpdateSettingsProps) {
  const form = get(meta, `form`);

  const valid: boolean = yield validateForm({ form, meta });
  if (!valid) {
    return;
  }

  yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: true, meta });

  if (form === SYSTEM_SETTINGS && !get(payload, `transaction_recheck_timer`)) {
    payload.transaction_recheck_timer = null;
  }

  yield refreshSaga({
    request: () => settingsApi.saveSettings(payload),
    onSuccess: function* (resp: AxiosResponse) {
      yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });

      if (form === SYSTEM_SETTINGS) {
        yield put(change(SYSTEM_SETTINGS, `failed_urls`, get(resp, `data.params.failed_urls`)));
        yield put(change(SYSTEM_SETTINGS, `succeeded_urls`, get(resp, `data.params.succeeded_urls`)));
        yield put(change(SYSTEM_SETTINGS, `pending_urls`, get(resp, `data.params.pending_urls`)));
      }

      toastr.success(i18n.t(`common.success`), i18n.t(`widgets.itemHasBeenSuccessfullyUpdated`));
    },
    onError: function* (e: AxiosError) {
      const payload = getErrorMessage(e, {
        defaultValue: i18n.t(`widgets.itemUpdatingHasBeenFailed`),
      });
      yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      yield formSubmissionError({ payload, meta, form });
    },
  });
}

interface IUpdatePrivateSettingsProps {
  payload: IUpdatePrivateSettingsForm;
  meta: IWidgetsMeta;
}

function* updatePrivateSettingsSaga({ payload, meta }: IUpdatePrivateSettingsProps) {
  const valid: boolean = yield validateForm({ form: PRIVATE_SETTINGS, meta });
  if (!valid) {
    return;
  }

  yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: true, meta });

  yield refreshSaga({
    request: () => settingsApi.savePrivateSettings(payload),
    onSuccess: function* () {
      yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      toastr.success(i18n.t(`common.success`), i18n.t(`widgets.itemHasBeenSuccessfullyUpdated`));
    },
    onError: function* (e: AxiosError) {
      const payload = getErrorMessage(e, {
        defaultValue: i18n.t(`widgets.itemUpdatingHasBeenFailed`),
      });
      yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      yield formSubmissionError({ payload, meta, form: PRIVATE_SETTINGS });
    },
  });
}

interface IUpdatePasswordProps {
  payload: IPasswordChangeForm;
  meta: IWidgetsMeta;
}

function* updatePasswordSaga({ payload, meta }: IUpdatePasswordProps) {
  const form = get(meta, `form`);
  const valid: boolean = yield validateForm({ form, meta });
  if (!valid) {
    return;
  }

  yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: true, meta });

  yield refreshSaga({
    request: () => userApi.changePassword(payload),
    onSuccess: function* () {
      yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });

      toastr.success(i18n.t(`common.success`), i18n.t(`widgets.itemHasBeenSuccessfullyUpdated`));
    },
    onError: function* (e: AxiosError) {
      const payload = getErrorMessage(e, {
        defaultValue: i18n.t(`widgets.itemUpdatingHasBeenFailed`),
      });
      yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
      yield formSubmissionError({ payload, meta, form });
    },
  });
}

function* initAppVersion() {
  yield refreshSaga({
    request: () => settingsApi.loadAppVersion(),
    onSuccess: function* (resp: AxiosResponse) {
      yield put(setAppVersion(resp.data.version));
    },
    onError: () => toastr.error(i18n.t(`common.error`), i18n.t(`settings.versionFetchingHasBeenFailed`)),
  });
}

function* loadSettingsSaga({ meta }: Record<`meta`, IWidgetsMeta>) {
  yield initAppVersion();
  yield put(settingsActionCreators.setAnyData({ init: false }));
  yield refreshSaga({
    request: settingsApi.loadSettings,
    onSuccess: function* (resp: AxiosResponse) {
      const data = get(resp, `data.params`);
      if (!isEmpty(data)) {
        const {
          succeeded_urls,
          pending_urls,
          failed_urls,
          transaction_reactivation_timer,
          transaction_recheck_timer,
          del_possible_trans_after_days,
          use_url_from_settings,
          upi_p2p_payment_methods,
          upi_api_payment_methods,
          use_sms_box,
          use_upi_qrcode,
          display_report_problem,
          restrict_withdrawal_requests,
        } = data;
        const lang: string = yield select(langSelector);

        const profileForm = {
          lang,
        };

        const systemSettingsForm = {
          succeeded_urls,
          pending_urls,
          failed_urls,
          display_report_problem,
          restrict_withdrawal_requests,
          transaction_reactivation_timer,
          transaction_recheck_timer,
          del_possible_trans_after_days,
          use_url_from_settings,
          use_sms_box,
          use_upi_qrcode,
          upi_p2p_payment_methods:
            upi_p2p_payment_methods?.map((item: any, index: number) => ({
              order: index + 1,
              ...item,
            })) || [],
          upi_api_payment_methods:
            upi_api_payment_methods?.map((item: any, index: number) => ({
              order: index + 1,
              ...item,
            })) || [],
        };

        yield put(initialize(PROFILE_SETTINGS, profileForm));
        yield put(initialize(SYSTEM_SETTINGS, systemSettingsForm));
        yield put(settingsActionCreators.setAnyData({ init: true }));
      }
    },
  });
}

function* loadPrivateSettingsSaga({ meta }: Record<`meta`, IWidgetsMeta>) {
  yield initAppVersion();
  yield put(settingsActionCreators.setAnyData({ init: false }));
  yield refreshSaga({
    request: settingsApi.loadPrivateSettings,
    onSuccess: function* (resp: AxiosResponse) {
      const data = get(resp, `data.data`);
      if (!isEmpty(data)) {
        const {
          postback_url,
          postback_access_key,
          postback_private_access_key,
          withdrawal_postback_url,
          delete_withdrawals_after_days,
        } = data;

        const systemSettingsForm = {
          postback_url,
          postback_access_key,
          postback_private_access_key,
          withdrawal_postback_url,
          delete_withdrawals_after_days,
        };

        yield put(initialize(PRIVATE_SETTINGS, systemSettingsForm));
        yield put(settingsActionCreators.setAnyData({ init: true }));
      }
    },
  });
}

interface IUpdateTimezoneProps extends ITimezoneUpdate {
  meta: IWidgetsMeta;
}

function* updateTimezoneSaga({ timezone }: IUpdateTimezoneProps) {
  yield refreshSaga({
    request: () => settingsApi.saveTimezone({ timezone }),
    onSuccess: function* () {
      yield put(settingsActionCreators.setAnyData({ timezone }));
    },
    onError: () => toastr.error(i18n.t(`common.error`), i18n.t(`settings.itemUpdatingHasBeenFailed`)),
  });
}

function* loadTimezoneSaga() {
  yield refreshSaga({
    request: () => settingsApi.loadTimezone(),
    onSuccess: function* (resp: AxiosResponse) {
      const timezone = resp.data.params.timezone;
      yield put(settingsActionCreators.setAnyData({ timezone }));
      yield put(change(SYSTEM_SETTINGS, `timezone`, timezone));
    },
    onError: () => toastr.error(i18n.t(`common.error`), i18n.t(`settings.itemFetchingHasBeenFailed`)),
  });
}

interface IUpdatePluginColorSchemeProps extends IPluginColorScheme {
  meta: IWidgetsMeta;
}

function* updatePluginColorSaga({ payment_plugin_color_scheme }: IUpdatePluginColorSchemeProps) {
  yield refreshSaga({
    request: () => settingsApi.loadPluginSettings({ payment_plugin_color_scheme }),
    onSuccess: function* (resp: AxiosResponse) {
      const data = resp.data;
      yield put(settingsActionCreators.setAnyData(data));
      toastr.success(i18n.t(`common.success`), i18n.t(`widgets.itemHasBeenSuccessfullyUpdated`));
    },
    onError: (e: AxiosError) => {
      const payload = getErrorMessage(e, {
        defaultValue: i18n.t(`widgets.itemUpdatingHasBeenFailed`),
      });
      toastr.error(i18n.t(`common.error`), payload);
    },
  });
}

function* loadPluginSettingsSaga() {
  yield refreshSaga({
    request: () => settingsApi.getPluginSettings(),
    onSuccess: function* (resp: AxiosResponse) {
      const data = resp.data;
      const { params } = data;
      //@ts-ignore
      const gifResp = yield call(walletTypeImageApi.getImages);
      const gifGuides = gifResp.data.data;
      const allParams = {
        ...params,
        gif_guide: gifGuides,
      };
      yield put(initialize(APPEARANCE_SETTINGS, allParams));
      yield put(settingsActionCreators.setAnyData({ ...allParams }));
    },
    onError: () => toastr.error(i18n.t(`common.error`), i18n.t(`settings.itemFetchingHasBeenFailed`)),
  });
}

function* loadPaytmFlagSaga() {
  yield refreshSaga({
    request: () => settingsApi.loadPaytmFlag(),
    onSuccess: function* (resp: AxiosResponse) {
      const data = resp.data;
      const { params } = data;
      yield put(settingsActionCreators.setAnyData({ ...params }));
    },
    onError: () => toastr.error(i18n.t(`common.error`), i18n.t(`settings.itemFetchingHasBeenFailed`)),
  });
}

const settingsSagas = [
  takeEvery<IAction>(UPDATE_HELP_IMAGES_REQUESTED, updateHelpImagesSaga),
  takeEvery<IAction>(DELETE_HELP_IMAGES, deleteHelpImagesSaga),
  takeEvery<IAction>(UPDATE_CUSTOM_STYLE_REQUESTED, updateCustomThemeSaga),
  takeEvery<IAction>(UPDATE_SETTINGS_REQUESTED, updateSettingsSaga),
  takeEvery<IAction>(UPDATE_PRIVATE_SETTINGS_REQUIRED, updatePrivateSettingsSaga),
  takeEvery<IAction>(UPDATE_PASSWORD_REQUESTED, updatePasswordSaga),
  takeEvery<IAction>(LOAD_SETTINGS_REQUESTED, loadSettingsSaga),
  takeEvery<IAction>(LOAD_PLUGIN_SETTINGS_REQUESTED, loadPluginSettingsSaga),
  takeEvery<IAction>(UPDATE_TIMEZONE, updateTimezoneSaga),
  takeEvery<IAction>(UPDATE_PLUGIN_COLOR_SCHEME, updatePluginColorSaga),
  takeEvery<IAction>(LOAD_SETTINGS_TIMEZONE, loadTimezoneSaga),
  takeEvery<IAction>(LOAD_PRIVATE_SETTINGS_REQUESTED, loadPrivateSettingsSaga),
  takeEvery<IAction>(LOAD_PAYTM_FLAG_REQUESTED, loadPaytmFlagSaga),
];

export default settingsSagas;
