import { Permission, ReportType } from 'utils/enums';

export const getReportTypeSelectionDataByPermissions = (permissions: string[], postbackViewPermission?: boolean) => {
  const hasPermission = (permission: string) => permissions.includes(permission);

  return [
    ...(hasPermission(Permission.TRANSACTION_REPORTS)
      ? [
          {
            value: ReportType.SUM_REPORT,
            text: `reports.sumOfTransactions`,
          },
          {
            value: ReportType.LIST_REPORT,
            text: `transactions.transactionList`,
          },
          ...(postbackViewPermission
            ? [
                {
                  value: ReportType.COMPLETED_TRANSACTION_SUM,
                  text: `reports.transactionSumWithCompletedStockpiling`,
                },
                {
                  value: ReportType.LIST_REPORT_WITH_COMPLETED_STOCKPILING,
                  text: `reports.transactionListWithCompletedStockpiling`,
                },
              ]
            : []),
        ]
      : []),
    ...(hasPermission(Permission.WITHDRAWAL_REQUEST_REPORT)
      ? [
          {
            value: ReportType.WITHDRAWAL_REQUEST_REPORT,
            text: `reports.listOfWithdrawalRequests`,
          },
        ]
      : []),
    ...(hasPermission(Permission.LABEL_HISTORY_REPORT)
      ? [
          {
            value: ReportType.LABEL_HISTORY_REPORT,
            text: `transactions.labelHistoryReport`,
          },
        ]
      : []),
    ...(hasPermission(Permission.COMMISSION_REPORT)
      ? [
          {
            value: ReportType.COMMISSION_REPORT,
            text: `reports.commissionAmount`,
          },
        ]
      : []),
    ...(hasPermission(Permission.OPERATION_WITH_BALANCES)
      ? [
          {
            value: ReportType.OPERATIONS_WITH_BALANCES,
            text: `reports.operationWithBalances`,
          },
        ]
      : []),

    // TODO, WARNING
    // Код закомментирован в рамках задачи. Т.к. он еще понадобится.
    // https://jira.fixmost.com/browse/PAY-9645

    // ...(hasPermission(Permission.CLIENT_BALANCE_HISTORY_REPORT)
    //   ? [
    //       {
    //         value: ReportType.CLIENT_ACCOUNT_BALANCE_CHANGE,
    //         text: `transactions.clientAccountBalanceChange`,
    //       },
    //     ]
    //   : []),
  ];
};
