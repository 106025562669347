import { takeEvery, call, put, select } from 'redux-saga/effects';
import get from 'lodash/get';
import split from 'lodash/split';
import each from 'lodash/each';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import size from 'lodash/size';
import toNumber from 'lodash/toNumber';
import reduce from 'lodash/reduce';
import { toastr } from 'react-redux-toastr';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { AxiosError, AxiosResponse } from 'axios';
import {
  validateForm,
  sleep,
  WIDGET_RESET_MODAL,
  WIDGET_SET_SUBMITTING_STATUS,
  WIDGET_SET_MODAL_ERROR,
  IWidgetsMeta,
  IAction,
} from '@kassma-team/kassma-toolkit/lib';

import { getErrorMessage } from 'utils';
import refreshSaga from 'sagas/effects/refreshSaga';
import {
  PROXY_CHECK_ALL_FAILED,
  PROXY_INIT_CHECK_ALL_REQUESTED,
  PROXY_CHECK_ALL_SET_LOADER,
  PROXY_CHECK_ALL_SUCCEEDED,
  PROXY_CHECK_FAILED,
  PROXY_CHECK_REQUESTED,
  PROXY_CHECK_SUCCEEDED,
  PROXY_CHECK_ALL_REQUESTED,
  INIT_CHECKING_MULTI_ADDING_PROXY_LIST,
  CHECK_MULTI_ADDING_PROXY_LIST_REQUESTED,
  CHECK_MULTI_ADDING_PROXY_LIST_SUCCEEDED,
  SET_VERIFIED_MULTI_ADDING_PROXIES,
  INIT_CHECKING_MULTI_ADDING_PROXY_ITEM,
  CHECK_MULTI_ADDING_PROXY_ITEM_SUCCEEDED,
  CHECK_MULTI_ADDING_PROXY_ITEM_REQUESTED,
  ADD_MULTI_ADDING_PROXIES_REQUESTED,
  ADD_MULTI_ADDING_PROXIES_SUCCEEDED,
  UPLOAD_PROXIES_REQUESTED,
  UPLOAD_PROXIES_SUCCEEDED,
  DOWNLOAD_PROXIES,
} from 'actionTypes';
import {
  addingProxiesSelector,
  checkingAddingProxySelector,
  isCheckingAllProxiesSelector,
  verifyingAddingProxiesSelector,
} from 'selectors/widgets/proxies';
import i18n from 'i18n';
import validateProxyIp from 'utils/widgets/proxies/validateProxyIp';
import validateProxyPort from 'utils/widgets/proxies/validateProxyPort';
import { proxyTypes } from 'static/proxies';
import { proxyActionCreators, setInvalidMultiAddingProxyRows } from 'actions/widgets/proxy';
import { UPLOAD_PROXIES_FORM_NAME } from 'utils/constants';
import { countryCodesSelector } from 'selectors/widgets/countries';
import proxyApi from 'api/proxy/ProxyApi';

import {
  IAddingProxies,
  IInitCheckingProxiesData,
  IProxiesMultiAddingData,
  IProxiesRelations,
} from 'interfaces/widgets/proxies';

interface ICheckProxyProps {
  payload: number;
  meta: IWidgetsMeta;
}

function* checkProxySaga({ payload, meta }: ICheckProxyProps) {
  yield refreshSaga({
    request: () => proxyApi.checkProxy(payload),
    onSuccess: function* (resp: AxiosResponse) {
      const status: string = yield call(get, resp, `data.status`);
      if (!status || status === `fail`) {
        throw new Error();
      }
      yield put({ type: PROXY_CHECK_SUCCEEDED, payload, meta });
    },
    onError: function* (err: AxiosError) {
      yield put({ type: PROXY_CHECK_FAILED, payload, meta });
      toastr.error(i18n.t(`common.error`), getErrorMessage(err));
    },
  });
}

function* initCheckAllProxiesSaga({ meta }: Record<`meta`, IWidgetsMeta>) {
  const isCheckingAll: boolean = yield select(isCheckingAllProxiesSelector);
  if (isCheckingAll) {
    return;
  }
  yield put({ type: PROXY_CHECK_ALL_SET_LOADER, meta });
  yield refreshSaga({
    request: () => proxyApi.initCheckAll(),
    onSuccess: function* () {
      yield put({ type: PROXY_CHECK_ALL_REQUESTED, meta });
    },
    onError: function* (err: AxiosError) {
      yield put({ type: PROXY_CHECK_ALL_FAILED, meta });
      toastr.error(i18n.t(`common.error`), getErrorMessage(err));
    },
  });
}

function* checkAllProxiesSaga({ meta }: Record<`meta`, IWidgetsMeta>) {
  yield refreshSaga({
    request: proxyApi.getChecked,
    onSuccess: function* (resp: AxiosResponse) {
      const status: string = yield call(get, resp, `data.status`);
      const payload: string = yield call(get, resp, `data.${meta.labels.listLabel}`);
      const isChecked: boolean = yield call(get, resp, `data.checked`);

      if (!status || !isChecked || status === `fail` || !payload) {
        yield put({ type: PROXY_CHECK_ALL_SUCCEEDED, meta, payload });
        yield sleep(10 * 1000);
        yield put({ type: PROXY_CHECK_ALL_REQUESTED, meta });
      } else {
        yield put({ type: PROXY_CHECK_ALL_SUCCEEDED, meta, payload });
      }
    },
    onError: function* (err: AxiosError) {
      yield put({ type: PROXY_CHECK_ALL_FAILED, meta });
      toastr.error(i18n.t(`proxies.proxyListCheckFailed`), getErrorMessage(err));
    },
  });
}

const proxyAvailableDividers = [`\t`, `;`, `,`, `:`];

const parseProxies = (text: string, countryCodes: string[]) => {
  const rows = split(text, `\n`);

  const proxies: IInitCheckingProxiesData[] = [];
  let failedProxies = ``;

  each(rows, (row) => {
    if (!row) {
      return;
    }

    const onError = () => (failedProxies += `${row}\n`);

    const divider = find(proxyAvailableDividers, (divider) => row.indexOf(divider) > -1);
    if (!divider) {
      onError();

      return;
    }
    const [ip_host, port, username, password, type, country_code] = split(row, divider);

    const ipError = validateProxyIp(ip_host);
    const portError = validateProxyPort(port);
    const invalidType = !includes(proxyTypes, type);
    const invalidCountryCode = !includes(countryCodes, country_code);
    const error = ipError || portError || !username || !password || invalidType || invalidCountryCode;
    if (error) {
      onError();

      return;
    }

    proxies.push({ ip_host, port, username, password, type, country_code });
  });

  return {
    proxies,
    failedProxies,
  };
};

interface IInitCheckingMultiAddingProxiesProps {
  meta: IWidgetsMeta;
  payload: string;
}

function* initCheckingMultiAddingProxiesSaga({ meta, payload }: IInitCheckingMultiAddingProxiesProps) {
  const countryCodes: string[] = yield select(countryCodesSelector);
  const { proxies, failedProxies } = parseProxies(payload, countryCodes);

  if (isEmpty(proxies)) {
    yield put({ type: WIDGET_SET_MODAL_ERROR, meta, payload: i18n.t(`proxies.noValidProxies`) });

    return;
  }

  if (failedProxies) {
    yield put(setInvalidMultiAddingProxyRows(failedProxies));
  }

  yield refreshSaga({
    request: () => proxyApi.initCheckingMultiAddingProxies(proxies),
    onSuccess: function* () {
      yield put({ type: CHECK_MULTI_ADDING_PROXY_LIST_REQUESTED, meta });
    },
    onError: function* (err: AxiosError) {
      yield put({ type: WIDGET_SET_MODAL_ERROR, meta, payload: getErrorMessage(err) });
    },
  });
}

function* checkMultiAddingProxiesSaga({ meta }: Record<`meta`, IWidgetsMeta>) {
  yield refreshSaga({
    request: proxyApi.checkAllMultiAddingProxyStatuses,
    onSuccess: function* (resp: AxiosResponse) {
      const status = get(resp, `data.status`);
      const verified = get(resp, `data.verified`);
      const proxies = get(resp, `data.proxies`);

      if (status && isArray(proxies)) {
        const normalizedProxies = map(proxies, (proxy, index) => ({ ...proxy, uuid: index + 1 }));
        yield put({ type: CHECK_MULTI_ADDING_PROXY_LIST_SUCCEEDED, meta, payload: normalizedProxies });
      } else {
        if (isNumber(verified)) {
          yield put({ type: SET_VERIFIED_MULTI_ADDING_PROXIES, meta, payload: verified });
        }

        yield sleep(5 * 1000);

        const verifyingProxies: boolean = yield select(verifyingAddingProxiesSelector);
        if (verifyingProxies) {
          yield put({ type: CHECK_MULTI_ADDING_PROXY_LIST_REQUESTED, meta });
        }
      }
    },
    onError: function* (err: AxiosError) {
      yield put({ type: WIDGET_SET_MODAL_ERROR, meta, payload: getErrorMessage(err) });
    },
  });
}

interface IinitCheckingMultiAddingProxyItemProps {
  payload: IAddingProxies;
  meta: IWidgetsMeta;
}

function* initCheckingMultiAddingProxyItemSaga({ payload, meta }: IinitCheckingMultiAddingProxyItemProps) {
  yield refreshSaga({
    request: () => proxyApi.initCheckingMultiAddingProxy(payload),
    onSuccess: function* () {
      yield put({ type: CHECK_MULTI_ADDING_PROXY_ITEM_REQUESTED, meta, payload: payload?.uuid });
    },
    onError: function* (err: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(err));
      yield put({ type: CHECK_MULTI_ADDING_PROXY_ITEM_SUCCEEDED, payload: {}, meta });
    },
  });
}

interface ICheckMultiAddingProxyItemPayload {
  id: number;
  meta: IWidgetsMeta;
}

function* checkMultiAddingProxyItemSaga({ payload: uuid, meta }: Record<string, ICheckMultiAddingProxyItemPayload>) {
  yield refreshSaga({
    request: proxyApi.checkMultiAddingProxyStatus,
    onSuccess: function* (resp: AxiosResponse) {
      const status = get(resp, `data.status`);
      const proxyData = get(resp, `data.proxy`);

      if (status && proxyData) {
        proxyData.uuid = uuid;
        yield put({ type: CHECK_MULTI_ADDING_PROXY_ITEM_SUCCEEDED, meta, payload: proxyData });
      } else {
        yield sleep(5 * 1000);

        const checkingProxy: boolean = yield select(checkingAddingProxySelector);
        if (checkingProxy) {
          yield put({ type: CHECK_MULTI_ADDING_PROXY_ITEM_REQUESTED, meta, payload: uuid });
        }
      }
    },
    onError: function (err: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(err));
    },
  });
}

interface IAddMultiAddingProxiesProps {
  payload: IProxiesMultiAddingData;
  meta: IWidgetsMeta;
}

function* addMultiAddingProxiesSaga({ meta, payload }: IAddMultiAddingProxiesProps) {
  yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: true, meta });

  const proxies: IAddingProxies[] = yield select(addingProxiesSelector);

  payload = {
    ...payload,
    proxies,
  };

  yield refreshSaga({
    request: () => proxyApi.addProxies(payload),
    onSuccess: function* (resp: AxiosResponse) {
      const payload = get(resp, `data.${meta.labels.listLabel}`);

      // yield put({ type: ADD_MULTI_ADDING_PROXIES_SUCCEEDED, meta, payload });
      yield put(proxyActionCreators.getList());
      yield put({ type: WIDGET_RESET_MODAL, meta });
    },
    onError: function (err: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(err));
    },
    onFinally: function* () {
      yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
    },
  });
}

interface IUploadProxiesProps {
  meta: IWidgetsMeta;
  payload: string;
}

function* uploadProxiesSaga({ meta, payload }: IUploadProxiesProps) {
  const valid: boolean = yield validateForm({ form: UPLOAD_PROXIES_FORM_NAME, meta });
  if (!valid) {
    return;
  }

  let invalidFormatOfProxiesRelations = ``;
  const rows = split(get(payload, `proxies`), `\n`);
  const relations: IProxiesRelations[] = [];

  each(rows, (row) => {
    if (!row) {
      return;
    }

    const onError = () => (invalidFormatOfProxiesRelations += `${row}\n`);

    const splittedRow = split(row, /\t| /g);
    const [wallet_id, wallet_number, ...proxies] = splittedRow;

    if (size(splittedRow) < 3 || !wallet_id || !isNumber(toNumber(wallet_id)) || !wallet_number || !proxies) {
      onError();

      return;
    }

    let invalidProxyWasFound = false;
    const availableProxies = map(proxies, (proxy) => {
      if (invalidProxyWasFound) {
        return undefined;
      }

      const [ip_host, port, login, password] = split(proxy, `:`);

      const ipError = validateProxyIp(ip_host);
      const portError = validateProxyPort(port);
      const error = ipError || portError || !login || !password;
      if (error) {
        invalidProxyWasFound = true;

        return undefined;
      }

      return { ip_host, port, login, password };
    });

    if (invalidProxyWasFound) {
      onError();

      return;
    }

    relations.push({
      wallet_id: toNumber(wallet_id),
      wallet_number,
      proxies: availableProxies,
    });
  });

  if (isEmpty(relations)) {
    yield put({ type: UPLOAD_PROXIES_SUCCEEDED, meta, payload: { invalidFormatOfProxiesRelations } });

    return;
  }

  const requestPayload = {
    save_succeeded: !invalidFormatOfProxiesRelations,
    relations,
  };

  yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: true, meta });

  yield refreshSaga({
    request: () => proxyApi.uploadProxies(requestPayload),
    onSuccess: function* (resp: AxiosResponse) {
      const invalidDataOfProxiesRelations = get(resp, `data.errors`);

      interface IRespPayload {
        invalidFormatOfProxiesRelations?: string;
        invalidDataOfProxiesRelations?: string;
      }

      const respPayload: IRespPayload = {};

      if (invalidFormatOfProxiesRelations) {
        respPayload.invalidFormatOfProxiesRelations = invalidFormatOfProxiesRelations;
      }

      if (!isEmpty(invalidDataOfProxiesRelations)) {
        respPayload.invalidDataOfProxiesRelations = reduce(
          invalidDataOfProxiesRelations,
          (result, relation) => {
            const { wallet_id, wallet_number, proxies } = relation || {};

            const proxiesText = reduce(
              proxies,
              (result, proxy) => {
                const { ip_host, port, login, password } = proxy || {};

                return `${result}\t${ip_host}:${port}:${login}:${password}`;
              },
              ``
            );

            return `${result}\n${wallet_id}\t${wallet_number}\t${proxiesText}`;
          },
          ``
        );
      }

      yield put({ type: UPLOAD_PROXIES_SUCCEEDED, meta, payload: respPayload });

      if (!invalidFormatOfProxiesRelations && isEmpty(invalidDataOfProxiesRelations)) {
        yield put({ type: WIDGET_RESET_MODAL, meta });
        toastr.success(i18n.t(`common.success`), i18n.t(`proxies.relationsHaveBeenAdded`));
      }
    },
    onError: function* (err: AxiosError) {
      yield put({ type: WIDGET_RESET_MODAL, meta });
      toastr.error(i18n.t(`common.error`), getErrorMessage(err));
    },
    onFinally: function* () {
      yield put({ type: WIDGET_SET_SUBMITTING_STATUS, payload: false, meta });
    },
  });
}

function* downloadProxiesSaga() {
  yield refreshSaga({
    request: proxyApi.downloadProxies,
    onSuccess: (resp: AxiosResponse) => {
      const file = get(resp, `data`);
      if (file) {
        fileDownload(file, `wallet_proxy_${moment().format(`YYYY_MM_DD_hh_mm_ss`)}.txt`);
      }
    },
    onError: function (err: AxiosError) {
      toastr.error(i18n.t(`common.error`), getErrorMessage(err));
    },
  });
}

const proxySagas = [
  takeEvery<IAction>(PROXY_CHECK_REQUESTED, checkProxySaga),
  takeEvery<IAction>(PROXY_INIT_CHECK_ALL_REQUESTED, initCheckAllProxiesSaga),
  takeEvery<IAction>(PROXY_CHECK_ALL_REQUESTED, checkAllProxiesSaga),
  takeEvery<IAction>(INIT_CHECKING_MULTI_ADDING_PROXY_LIST, initCheckingMultiAddingProxiesSaga),
  takeEvery<IAction>(CHECK_MULTI_ADDING_PROXY_LIST_REQUESTED, checkMultiAddingProxiesSaga),
  takeEvery<IAction>(INIT_CHECKING_MULTI_ADDING_PROXY_ITEM, initCheckingMultiAddingProxyItemSaga),
  takeEvery<IAction>(CHECK_MULTI_ADDING_PROXY_ITEM_REQUESTED, checkMultiAddingProxyItemSaga),
  takeEvery<IAction>(ADD_MULTI_ADDING_PROXIES_REQUESTED, addMultiAddingProxiesSaga),
  takeEvery<IAction>(UPLOAD_PROXIES_REQUESTED, uploadProxiesSaga),
  takeEvery<IAction>(DOWNLOAD_PROXIES, downloadProxiesSaga),
];

export default proxySagas;
