import { widgetActionCreators, IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';

export const archivedTransactionActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.ARCHIVED_TRANSACTIONS,
  labels: {
    itemLabel: `transaction`,
    listLabel: `transactions`,
  },
  uniqKey: `primary_id`,
  withPagination: true,
});
