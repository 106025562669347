import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Field } from 'api/packages/PackagesApi';
import { FormType } from 'api/translations/TranslationsApi';
import { Package } from 'api/packages/PackagesApi';
import { usePaymentSystemsCtx } from '../ctx';

type UseCreationMode = {
  isCreationMode: boolean;
  setIsCreationMode: Dispatch<SetStateAction<boolean>>;

  packageName: string;
  setPackageName: Dispatch<SetStateAction<string>>;

  checkedFields: Array<Field>;
  setCheckedFields: Dispatch<SetStateAction<Array<Field>>>;

  checkedFormType: FormType | undefined;
  setCheckedFormType: Dispatch<SetStateAction<FormType | undefined>>;

  creationPackage: Package | null;
  setCreationPackage: Dispatch<SetStateAction<Package>>;

  reset: () => void;
};

/**
 * Хук покрывает кейс добавления нового перевода.
 * Содержит следующие части данных:
 * 1 - Определяет, вкл-выкл для режима создания.
 * 2 - Имя пакета переводов, который нужно создать.
 * 3 - Поля переводов, которые нужно включить в пакет.
 * 4 - Тип формы перевода.
 * 5 - Пакет переводов, над которым и происходят изменения. Именно он является "Результатом работы" хука.
 */
export function useCreationMode(): UseCreationMode {
  const { currentPaymentSystem } = usePaymentSystemsCtx();

  const [isCreationMode, setIsCreationMode] = useState(false);
  const [packageName, setPackageName] = useState(``);
  const [checkedFields, setCheckedFields] = useState<Array<Field>>([]);
  const [checkedFormType, setCheckedFormType] = useState<FormType | undefined>(undefined);

  const reset = () => {
    setPackageName(``);
    setCheckedFields([]);
    setCheckedFormType(undefined);
  };

  const [creationPackage, setCreationPackage] = useState<Package>({
    name: packageName,
    // @ts-expect-error
    form_type: checkedFormType?.type,
    uuid: `client-id`,
    wallet_type: currentPaymentSystem?.wallet_type || ``,
    packages_translations: [],

    updated_at: ``,
    created_at: ``,
    is_default: true,
  });

  useEffect(() => {
    if (packageName && checkedFormType && currentPaymentSystem?.wallet_type) {
      setCreationPackage({
        ...creationPackage,
        name: packageName,
        form_type: checkedFormType.type,
        wallet_type: currentPaymentSystem?.wallet_type,
        packages_translations: checkedFields.map((el) => ({
          code: el.code,
          name: el.name,
          field_uuid: el.uuid,
          translations: [],
        })),
      });
    }
  }, [packageName, checkedFormType, currentPaymentSystem?.wallet_type, checkedFields]);

  return {
    isCreationMode,
    setIsCreationMode,

    packageName,
    setPackageName,

    checkedFields,
    setCheckedFields,

    checkedFormType,
    setCheckedFormType,

    creationPackage,
    setCreationPackage,

    reset,
  };
}
