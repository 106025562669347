import { BaseApi } from 'api/common/BaseApi';
import { AxiosPromise } from 'axios';
import apiFetch from '../apiFetch';
import { ICustomSortingCurrencyListItem } from 'interfaces/widgets/currency';
import { IPluginColorScheme } from '../../interfaces/widgets/settings/pluginSettings';
import { ITimezoneUpdate, IUpdatePrivateSettingsForm, IUpdateSettingsForm } from '../../interfaces/widgets/settings';
import { IPasswordChangeForm } from '../../interfaces/widgets/settings/profileSettings';

interface IData {
  payment_systems: ICustomSortingCurrencyListItem[];
  is_default: boolean;
}

export class SettingsApi extends BaseApi {
  loadPaymentsByCurrency = (currency_code: string): AxiosPromise =>
    this.makeFetch(`payments-for-currency`, { queryParams: { currency_code } });

  loadPaymentOfCurrencies = (): AxiosPromise => this.makeFetch(`default-payment-of-currencies`);

  setPaymentsSorting = (currency_code: string, data: IData): AxiosPromise =>
    this.makeFetch(`update-for-currency`, { data, method: `POST`, queryParams: { currency_code } });

  loadPaytmFlag = (): AxiosPromise => this.makeFetch(`public-params`);

  getPluginSettings = (): AxiosPromise => this.makeFetch(`plugin`);

  loadPluginSettings = (data: IPluginColorScheme): AxiosPromise => this.makeFetch(`plugin`, { data, method: `POST` });

  loadTimezone = (): AxiosPromise => this.makeFetch(`timezone`);

  saveTimezone = (data: ITimezoneUpdate): AxiosPromise => this.makeFetch(`timezone`, { data, method: `POST` });

  loadAppVersion = (): AxiosPromise => this.makeFetch(`version`);

  loadSettings = (): AxiosPromise => this.makeFetch(`params`);

  loadPrivateSettings = (): AxiosPromise => this.makeFetch(`private-params`);

  savePrivateSettings = (data: IUpdatePrivateSettingsForm): AxiosPromise =>
    this.makeFetch(`private-params`, { data, method: `POST` });

  saveSettings = (data: IUpdateSettingsForm): AxiosPromise => this.makeFetch(`params`, { data, method: `POST` });

  updateCustomStyle = (data: Record<string, unknown>): AxiosPromise =>
    this.makeFetch(`set-custom-css`, { data, method: `POST`, formData: true });
}

const settingsApi = new SettingsApi(`settings`);

export default settingsApi;
