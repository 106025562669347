import get from 'lodash/get';

import { ReportType } from 'utils/enums';
import { IGenerateReportsForm } from 'interfaces/widgets/reports';

export const getWalletTypeByReportType = (values: IGenerateReportsForm, reportType: string) => {
  switch (reportType) {
    case ReportType.SUM_REPORT:
    case ReportType.LIST_REPORT:
    case ReportType.TRANSACTIONS_SUM_DETAIL:
    case ReportType.COMPLETED_TRANSACTION_SUM:
    case ReportType.LIST_REPORT_WITH_COMPLETED_STOCKPILING:
      return get(values, `filters.wallet_type`);
    case ReportType.LABEL_HISTORY_REPORT:
      return get(values, `payment_system`);
    case ReportType.CLIENT_ACCOUNT_BALANCE_CHANGE:
    case ReportType.WITHDRAWAL_REQUEST_REPORT:
      return get(values, `wallet_type`);
    default:
      return undefined;
  }
};
