import React from 'react';
import { WidgetType } from 'utils/enums';
import RemovingModal from 'components/modals/RemovingModal';
import { autoReportsActionCreators } from 'actions/widgets/autoreports';

const AutoReportRemoving = () => {
  return (
    <RemovingModal
      widget={WidgetType.AUTOREPORTS}
      actionCreators={autoReportsActionCreators}
      title="reports.deletingAutogenerationTask"
      content="reports.doYouDeleteAutogenerationTask"
      confirmText="common.delete"
    />
  );
};

export default AutoReportRemoving;
