import React from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import useMount from 'react-use/lib/useMount';
import { getSubmitHandler, InputType } from '@kassma-team/kassma-toolkit/lib';
import { ModalType, Permission, TransactionDirection, WidgetType } from 'utils/enums';
import { notAcceptedPostbackActionCreators } from 'actions/widgets/postback';
import postbackRouteTabs from 'pages/postbacks/postbackRouteTabs';
import { selectionOfAllManualPSSelector } from 'selectors/widgets/manualPS';
import { paymentSystemsWithoutSettlementsSelector } from 'selectors/widgets/paymentSystems';
import { manualPSActionCreators } from 'actions/widgets/paymentSystem';
import useNotAcceptedPostbackTableTitles from 'hooks/widgets/tableTitles/useNotAcceptedPostbackTableTitles';
import { IPostbackRowProps } from 'interfaces/widgets/postback';

import Filters from 'components/widgets/filters/Filters';
import PostbackRow from 'components/widgets/postback/PostbackRow';
import NotAcceptedPostbackModal from 'components/widgets/postback/NotAcceptedPostbackModal';
import Widget from 'components/widgets/Widget';
import { IFilterProps } from 'interfaces/widgets/widgets';
import { widgetSelectedItemsSelector } from '@kassma-team/kassma-toolkit';
import ResetAttemptCounterModal from '../../components/widgets/postback/ResetAttemptCounterModal';
import MarkAsSentModal from '../../components/widgets/postback/MarkAsSentModal';
import withPermissionCheck from '../../hocs/withPermissionCheck';

const FORM_NAME = `not-accepted-postbacks-form`;

const { showModal } = notAcceptedPostbackActionCreators;

const FilterForm = reduxForm<any, IFilterProps>({
  form: FORM_NAME,
  onSubmit: getSubmitHandler({
    dateFields: [{ name: `date_range`, fromName: `date_from`, toName: `date_to` }],
  }),
})(Filters);

const filterDateFields = [
  {
    from: `date_from`,
    to: `date_to`,
    name: `date_range`,
  },
];

const rightFilterFields = [
  {
    name: `date_range`,
    type: InputType.DATE_RANGE,
  },
];

const modals = [
  {
    type: ModalType.APPROVAL_CONFIRMATION,
    widget: WidgetType.NOT_ACCEPTED_POSTBACKS,
    component: ResetAttemptCounterModal,
  },
  {
    type: ModalType.UPDATE,
    widget: WidgetType.NOT_ACCEPTED_POSTBACKS,
    component: MarkAsSentModal,
  },
  {
    type: ModalType.SHOW,
    widget: WidgetType.NOT_ACCEPTED_POSTBACKS,
    component: NotAcceptedPostbackModal,
  },
];

const NotAcceptedPostbacks = () => {
  const titles = useNotAcceptedPostbackTableTitles();
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const selectedItems: any = useSelector(widgetSelectedItemsSelector(WidgetType.NOT_ACCEPTED_POSTBACKS));

  const extraButtons =
    selectedItems && selectedItems.size
      ? [
          {
            title: t(`postbacks.clearCountFailure`),
            onClick: () =>
              showModal({
                type: ModalType.APPROVAL_CONFIRMATION,
                id: [...selectedItems],
              }),
            buttonClassName: `btn-secondary`,
            id: `clear-count-failure`,
          },
          {
            title: t(`postbacks.markAsSent`),
            onClick: () => showModal({ type: ModalType.UPDATE, id: [...selectedItems] }),
            buttonClassName: `btn-secondary`,
            id: `mark-as-sent`,
          },
        ]
      : [];

  const paymentSystemList = useSelector(paymentSystemsWithoutSettlementsSelector);

  const safePaymentSystemList = paymentSystemList?.map((el) => ({
    ...el,
    text: el.name,
    value: el.code,
  }));

  const testPaymentSystems = useSelector(selectionOfAllManualPSSelector);

  const safeTestPaymentSystems = testPaymentSystems?.map((el) => ({
    ...el,
    text: el.name,
    value: el.code,
  }));

  const leftFilterFields = [
    {
      name: `wallet_type`,
      id: `${FORM_NAME}_wallet_type`,
      data: [{ value: null, text: t(`common.all`) }, ...safePaymentSystemList, ...safeTestPaymentSystems],
      type: InputType.SELECT,
      placeholder: t(`wallets.walletType`),
    },
    {
      name: `label`,
      placeholder: t(`common.label`),
    },
    {
      name: `merchant_order_id`,
      placeholder: t(`common.orderId`),
    },
    {
      name: `direction`,
      id: `${FORM_NAME}_direction`,
      data: [
        {
          value: TransactionDirection.INGOING,
          text: `transactions.ingoing`,
        },
        {
          value: TransactionDirection.OUTGOING,
          text: `transactions.outgoing`,
        },
      ],
      translateKeys: true,
      type: InputType.SELECT,
      placeholder: t(`reports.direction`),
    },
  ];

  useMount(() => {
    dispatch(manualPSActionCreators.getList());
  });

  return (
    <Widget
      widget={WidgetType.NOT_ACCEPTED_POSTBACKS}
      actionCreators={notAcceptedPostbackActionCreators}
      pageTitle="postbacks.postbacks"
      headTitle="postbacks.notAcceptedPostbacks"
      routeTabs={postbackRouteTabs}
      tableTitle="postbacks.notAcceptedPostbackList"
      tableHeadTitles={titles}
      extraButtons={extraButtons}
      listMapping={(item: IPostbackRowProps) => (
        <PostbackRow
          {...item}
          actionCreators={notAcceptedPostbackActionCreators}
          key={item.id}
          widget={WidgetType.NOT_ACCEPTED_POSTBACKS}
        />
      )}
      filterForm={
        <FilterForm
          formName={FORM_NAME}
          leftFields={leftFilterFields}
          rightFields={rightFilterFields}
          dateFields={filterDateFields}
        />
      }
      modals={modals}
      withPagination
      statusBar
      filterFormName={FORM_NAME}
    />
  );
};

export default withPermissionCheck(NotAcceptedPostbacks, [
  {
    permissions: [
      {
        permission: Permission.POSTBACKS,
        opposite: true,
      },
    ],
    redirectUrl: `/no-permissions`,
  },
]);
