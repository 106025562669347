import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import { WalletImg } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { paymentSystemsActionCreators, dashboardPaymentSystemsInfoActionCreators } from 'actions/widgets/paymentSystem';

import ModalElement from 'components/modals/ModalElement';
import { paymentSystemLogoSelector } from 'selectors/widgets/paymentSystems';
import useList from 'hooks/widgets/useList';

const headTitles = [
  `paymentConstructor.paymentSystems`,
  `paymentConstructor.displayedOnPlugin`,
  `paymentConstructor.transactionLastDateOnPlugin`,
];

const PaymentSystemRow = ({ wallet_type, plugin_status, date }: any) => {
  const logo = useSelector(paymentSystemLogoSelector(wallet_type));

  return (
    <tr className="p-5 border-top">
      <td className="p-10 text-center">
        <WalletImg title={wallet_type} src={logo} />
      </td>
      <td className="p-10 text-center">
        {plugin_status ? <i className="fa fa-check" /> : <i className="si si-close" />}
      </td>
      <td className="p-10 text-center">{date}</td>
    </tr>
  );
};

const PaymentSystemsList = () => {
  const [t] = useTranslation();

  const { items: paymentSystems, listLoading: paymentSystemsLoading } = useList({
    actionCreators: dashboardPaymentSystemsInfoActionCreators,
    widget: WidgetType.DASHBOARD_PAYMENT_SYSTEMS_INFO,
  });

  const content = (
    <form>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              {map(headTitles, (title, key) => (
                <th className="p-10 text-center" key={key}>
                  {t(title)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {map(paymentSystems || [], (item, key) => (
              <PaymentSystemRow {...item} key={key} />
            ))}
          </tbody>
        </table>
      </div>
    </form>
  );

  return (
    <ModalElement
      actionCreators={paymentSystemsActionCreators}
      title={`dashboard.paymentSystems`}
      content={content}
      loading={paymentSystemsLoading}
    />
  );
};

export default PaymentSystemsList;
