import React, { useEffect, useMemo, useState } from 'react';
import { getFormValues, InjectedFormProps, reduxForm, stopSubmit, SubmissionError, submit } from 'redux-form';
import { useSelector } from 'react-redux';
import useMount from 'react-use/lib/useMount';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import filter from 'lodash/filter';
import map from 'lodash/map';
import find from 'lodash/find';
import useDeepCompareEffect from 'react-use/lib/useDeepCompareEffect';
import {
  ErrorBlock,
  widgetModalSelector,
  widgetSubmittingSelector,
  widgetSelector,
  InputType,
} from '@kassma-team/kassma-toolkit/lib';
import { transactionActionCreators } from 'actions/widgets/transactions/transactions';
import { ModalType, Permission, TransactionKind, WidgetType } from 'utils/enums';
import { CREATE_TRANSACTION_FORM_NAME } from 'utils/constants';
import {
  paymentCurrenciesSelector,
  paymentSystemsForForcedTransactionsSelector,
} from 'selectors/widgets/paymentSystems';
import { hasAccessSelector } from 'selectors/auth';
import { paymentSystemsForForcedTransactionsActionCreators } from 'actions/widgets/paymentSystem';
import useTransactionSubmitHandler from 'hooks/widgets/transactions/useTransactionSubmitHandler';
import useList from 'hooks/widgets/useList';
import FormField from 'components/form/FormField';
import ModalElement from 'components/modals/ModalElement';
import { INotRemovedWallet } from 'interfaces/widgets/wallets/wallet';
import api from 'api';
import { Limit } from 'api/WalletTypes';
import possibleTransactionApi from 'api/transactions/possibleTransactions/PossibleTransactionApi';
import RadioButtons from 'components/form/RadioButtons';
import './TransactionCreating.css';
import { useDispatch } from 'react-redux';
import transactionApi from 'api/transactions/TransactionApi';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import { walletActionCreators } from 'actions/widgets/wallet';

type NormalizedLimits = Record<Limit[`wallet_type`], Record<Limit[`currency`], Limit>>;

const modalTitle = {
  [TransactionKind.REAL]: `transactions.realGeneration`,
  [TransactionKind.DEBUG]: `transactions.fakeGeneration`,
  [TransactionKind.FORCED]: `transactions.forcedGeneration`,
};

const getWalletsForCurrentPS = (wallets: INotRemovedWallet[], currentPaymentSystem: string) => {
  return map(
    filter(
      wallets,
      (wallet) =>
        wallet.wallet_type === currentPaymentSystem || get(wallet, `test_wallet_type.code`) === currentPaymentSystem
    ),
    (item) => ({
      value: item.id,
      text: item.identifier,
      status: item.status,
      currency: item.currency,
      is_active: item.is_active,
      removed: item.removed,
    })
  );
};

export type Transaction = {
  primary_id: number;
  id: string;
  amount: number;
  currency_symbol: string;
  wallet_type: string;
  status: number;
  created_at: string;
  label: string;
  merchant_order_id: string;
  screenshot: null;
  is_manual: number;
};

const TransactionCreating = ({ initialize, form, change, error, handleSubmit }: InjectedFormProps) => {
  const [t] = useTranslation();
  const values: Record<string, string | number> = useSelector(getFormValues(form)) || {};
  const [limits, setLimits] = useState<NormalizedLimits>({});
  const dispatch = useDispatch();
  type Step =
    // If we have possible transactions, we need to choose one
    | `chooseTransaction`
    // If user choose an transaction
    | `transactionWasChoosen`
    // If user choose to not use possible transactions
    | `transactionWasNotChoosen`
    // If we don't have transactions, we need to create one
    | `thereIsNoTransactions`;

  const [possibleTransactions, setPossibleTransactions] = useState<Array<Transaction>>([]);
  const [isTransactionsLoaded, setIsTransactionsLoaded] = useState(false);
  const [checkedPrimaryId, setCheckedPrimaryId] = useState<string>(`none`);

  useEffect(() => {
    if (values.step === `chooseTransaction`) {
      // change(`transaction`, `none`);
      setCheckedPrimaryId(`none`);
    }
  }, [values.step]);

  //@ts-ignore
  const sub = async (values, dispatch, props) => {
    if (values.step === `transactionWasChoosen`) {
      const forDelete = possibleTransactions.filter((el) => el.primary_id !== +checkedPrimaryId);

      const requests = [...forDelete.map((el) => possibleTransactionApi.deletePossibleTransactions(el.primary_id))];

      Promise.allSettled(requests).finally(async () => {
        try {
          await possibleTransactionApi.changeStatus(+values.primary_id);
        } catch (e) {
          if (axios.isAxiosError(e)) {
            console.log(`change error`, e);
          }
        }
        try {
          const { data } = await transactionApi.createTransaction({
            activate: true,
            amount: values.amount,
            currency_code: values.currency_code,
            label: values.label,
            transaction_id: values.transaction_id,
            type: values.type,
            wallet_id: values.wallet_id,
            wallet_type: values.wallet_type,
            merchant_order_id: values.merchant_order_id,
          });

          if (data.status === `ok`) {
            toastr.success(t(`common.success`), t(`transactions.transactionHasBeenSuccessfullyUpdated`));
            dispatch(transactionActionCreators.hideModal());

            if (values.isNeedToReopen) {
              setTimeout(() => {
                const currency_code = get(values, `currency_code`);
                const wallet_type = get(values, `wallet_type`);
                const data = {
                  currency_code,
                  type: transactionType,
                  wallet_type,
                };

                dispatch(transactionActionCreators.showModal({ type: ModalType.CREATE, data, transactionType }));
              }, 500);
            }
          }
        } catch (e) {
          if (axios.isAxiosError(e)) {
            dispatch(stopSubmit(form, { _error: e.response?.data.message }));
            throw new SubmissionError({ _error: e.response?.data.message });
          }
        }
      });
    }

    if (values.step === `transactionWasNotChoosen`) {
      const requests = [
        ...possibleTransactions.map((el) => possibleTransactionApi.deletePossibleTransactions(el.primary_id)),
      ];

      Promise.allSettled(requests).finally(async () => {
        try {
          const { data } = await transactionApi.createTransaction({
            activate: values.activate,
            amount: values.amount,
            currency_code: values.currency_code,
            exchange_identifier: values.exchange_identifier,
            label: values.label,
            transaction_id: values.transaction_id,
            type: values.type,
            wallet_id: values.wallet_id,
            wallet_type: values.wallet_type,
            merchant_order_id: values.merchant_order_id,
          });

          if (data.status === `ok`) {
            toastr.success(t(`common.success`), t(`transactions.transactionHasBeenSuccessfullyUpdated`));
            dispatch(transactionActionCreators.hideModal());

            if (values.isNeedToReopen) {
              setTimeout(() => {
                const currency_code = get(values, `currency_code`);
                const wallet_type = get(values, `wallet_type`);
                const data = {
                  currency_code,
                  type: transactionType,
                  wallet_type,
                };

                dispatch(transactionActionCreators.showModal({ type: ModalType.CREATE, data, transactionType }));
              }, 500);
            }
          }
        } catch (e) {
          if (axios.isAxiosError(e)) {
            dispatch(stopSubmit(form, { _error: e.response?.data.message }));
            throw new SubmissionError({ _error: e.response?.data.message });
          }
        }
      });
    }
  };

  const fetchPossibleTransactions = async (params: {
    transaction_id: string | number;
    wallet_type: string | number;
    status: 0;
  }) => {
    // @ts-expect-error
    const { data } = await possibleTransactionApi.loadTransactionList({ ...params });
    try {
      if (data.status === `ok`) {
        setPossibleTransactions(data.transactions.data);
        setIsTransactionsLoaded(true);
      } else {
        setIsTransactionsLoaded(false);
      }
    } catch (e) {
      console.log(e);
      setIsTransactionsLoaded(false);
    }
  };

  useEffect(() => {
    if (isTransactionsLoaded) {
      if (possibleTransactions.length) {
        // setStep(`chooseTransaction`);
        change(`step`, `chooseTransaction`);
      } else {
        // setStep(`thereIsNoTransactions`);
        change(`step`, `thereIsNoTransactions`);
      }
    }
  }, [isTransactionsLoaded]);

  useEffect(() => {
    const f = async () => {
      const { data } = await api.pluralWalletTypesApi.limits(`ingoing`);

      const normalized = (data: ReadonlyArray<Limit>) =>
        data.reduce((acc, item) => {
          acc[item.wallet_type] = {
            ...acc[item.wallet_type],
            [item.currency]: item,
          };

          return acc;
        }, {} as NormalizedLimits);

      setLimits(normalized(data.data));
    };

    f();
  }, []);

  const submitting = useSelector(widgetSubmittingSelector(WidgetType.TRANSACTIONS));

  const createForcedTransactionAccess = useSelector(hasAccessSelector(Permission.ACTIVATE_TRANSACTION));
  const createFakeTransactionAccess = useSelector(hasAccessSelector(Permission.CREATE_DEBUG_TRANSACTION));

  const walletTypes = useSelector(paymentSystemsForForcedTransactionsSelector);

  interface INotRemovedWallets {
    items: INotRemovedWallet[];
    listLoading: boolean;
  }

  // const { items: wallets, listLoading: walletsListLoading } = useList({
  //   actionCreators: walletActionCreators,
  //   widget: WidgetType.WALLETS,
  // });

  const [wallets, setWallets] = useState([]);
  const [walletsListLoading, setIsWalletsLoading] = useState([]);

  const modal = useSelector(widgetModalSelector(WidgetType.TRANSACTIONS));
  const transactionType = get(modal, `transactionType`);
  const isActive = get(values, `activate`);

  useEffect(() => {
    const fetcher = async () => {
      try {
        setIsWalletsLoading(true);
        const { data } = await api.walletsApi.loadWalletList({ type: values.wallet_type, withoutQuery: true });

        if (data.status === `success`) {
          setWallets(data.data);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsWalletsLoading(false);
      }
    };

    fetcher();
  }, [values.wallet_type]);

  const walletsForCurrentPaymentSystem = getWalletsForCurrentPS(wallets || [], values.wallet_type as string);

  const sortedWallets = useMemo(() => {
    const getSortingOrder = (wallet: any) => {
      if (wallet.is_active && !wallet.removed) {
        return 1;
      } else if (!wallet.is_active && !wallet.removed) {
        return 2;
      } else {
        return 3;
      }
    };

    return walletsForCurrentPaymentSystem.sort((a, b) => getSortingOrder(a) - getSortingOrder(b));
  }, [walletsForCurrentPaymentSystem]);
  const currencies = useSelector(paymentCurrenciesSelector(values.wallet_type as string));
  const disabledCurrencyField =
    transactionType === TransactionKind.REAL || (transactionType !== TransactionKind.REAL && !values.wallet_type);

  useMount(() => {
    const initialData = get(modal, `data`) || {};
    initialize({ type: transactionType, ...initialData, activate: false });
  });

  const { listLoading: walletTypesLoading } = useList({
    widget: WidgetType.PAYMENT_SYSTEMS_FOR_FORCED_TRANSACTIONS,
    actionCreators: paymentSystemsForForcedTransactionsActionCreators,
    disableLoading: !createForcedTransactionAccess,
  });

  const { wallet_id, wallet_type, amount } = values;

  const showErrorAmountMessage = (
    limits: NormalizedLimits,
    wallet_type: string,
    amount: string | number,
    currency: string | number
  ) => {
    if (!currency) return null;
    if (!wallet_type) return null;
    if (!amount) return null;
    if (!limits[wallet_type]) return null;
    if (!limits[wallet_type][currency]) return null;

    const maxValue = +limits[wallet_type][currency].max;
    const amountValue = +amount;

    return amountValue > maxValue;
  };

  useDeepCompareEffect(() => {
    let currencyCode = null;
    if (wallet_id) {
      const wallet = find(walletsForCurrentPaymentSystem, ({ value }) => value === wallet_id);
      if (wallet && wallet.currency) {
        currencyCode = wallet.currency;
      }
    }

    change(`currency_code`, currencyCode);
  }, [wallet_id, walletsForCurrentPaymentSystem]);

  const { onSubmit, onSubmitWithReopeningModal } = useTransactionSubmitHandler({
    transactionType,
    type: `creating`,
    form,
  });

  const creatingPermissionDenied =
    (!createForcedTransactionAccess && transactionType === TransactionKind.FORCED) ||
    (!createFakeTransactionAccess && transactionType === TransactionKind.DEBUG);

  if (creatingPermissionDenied) {
    if (transactionType === TransactionKind.FORCED) {
      error = t(`transactions.youHaveNotPermissionsToCreateForcedTransaction`);
    } else if (transactionType === TransactionKind.DEBUG) {
      error = t(`transactions.youHaveNotPermissionsToCreateFakeTransaction`);
    }
  }

  const content = (
    <form id="create-transaction" onSubmit={handleSubmit(sub)}>
      {transactionType === TransactionKind.DEBUG && (
        <>
          <FormField
            name="wallet_type"
            id={`${form}_wallet_type`}
            label={t(`common.paymentSystem`)}
            data={walletTypes}
            type={InputType.SELECT}
            busy={walletTypesLoading}
            onChange={() => {
              change(`wallet_id`, null);
              change(`currency_code`, null);
            }}
            itemComponent={({
              item: { text, plugin_visibility },
            }: Record<`item`, Record<string, string | boolean>>) => {
              if (!plugin_visibility) {
                return <span className="text-danger">{text}</span>;
              }

              return text;
            }}
            required
            translateKeys
            isModalField
          />
          {(transactionType === TransactionKind.REAL || transactionType === TransactionKind.FORCED) && (
            <FormField
              name="transaction_id"
              label={t(`transactions.transactionId`)}
              onlyLatinDigitsAndSymbols
              required={transactionType !== TransactionKind.FORCED}
              isModalField
            />
          )}
          {transactionType === TransactionKind.REAL && (
            <FormField
              name="wallet_id"
              id={`${form}_wallet_id`}
              label={t(`transactions.walletNumber`)}
              data={sortedWallets}
              type={InputType.SELECT}
              busy={walletsListLoading}
              required
              itemComponent={({
                item: { text, is_active, removed },
              }: Record<`item`, Record<string, string | number>>) => {
                if (!is_active && !removed) {
                  return <span className="text-danger">{text}</span>;
                } else if (removed) {
                  return <span style={{ color: `blue` }}>{text}</span>;
                }

                return text;
              }}
              translateKeys
              isModalField
            />
          )}
          <div>
            <FormField
              name="amount"
              className={`${
                showErrorAmountMessage(limits, wallet_type as string, amount, values?.currency_code) && `error`
              }`}
              label={t(`common.amount`)}
              amount
              required
              isModalField
            />
            {/* wallet_type is always string in runtime */}
            {showErrorAmountMessage(limits, wallet_type as string, amount, values?.currency_code) && (
              <div className="max-amount-error-message">{t(`transactions.outsideLimit`)}</div>
            )}
          </div>
          <FormField
            name="currency_code"
            id={`${form}_currency_code`}
            label={t(`common.currency`)}
            type={InputType.SELECT}
            data={currencies}
            disabled={disabledCurrencyField}
            required
            isModalField
          />
          {(transactionType === TransactionKind.REAL || transactionType === TransactionKind.FORCED) && (
            <FormField name="exchange_identifier" label={t(`transactions.exchanger`)} isModalField maxLength={40} />
          )}
          <FormField
            name="activate"
            label={t(`transactions.activate`)}
            type={InputType.CHECKBOX}
            required
            isModalField
          />
          {isActive && (
            <>
              <FormField name="label" label={t(`transactions.playerId`)} required isModalField />
              <FormField
                name="merchant_order_id"
                label={t(`transactions.orderNumber`)}
                onlyLatinDigitsAndSymbols
                isModalField
              />
            </>
          )}
        </>
      )}
      {(transactionType == TransactionKind.FORCED || transactionType === TransactionKind.REAL) && (
        <>
          {values.step === undefined && (
            <div>
              <FormField
                name="wallet_type"
                id={`${form}_wallet_type`}
                label={t(`common.paymentSystem`)}
                data={walletTypes}
                type={InputType.SELECT}
                busy={walletTypesLoading}
                onChange={() => {
                  change(`wallet_id`, null);
                  change(`currency_code`, null);
                }}
                itemComponent={({
                  item: { text, plugin_visibility },
                }: Record<`item`, Record<string, string | boolean>>) => {
                  if (!plugin_visibility) {
                    return <span className="text-danger">{text}</span>;
                  }

                  return text;
                }}
                required
                translateKeys
                isModalField
              />

              <FormField
                label={t(`transactions.transactionId`)}
                onlyLatinDigitsAndSymbols
                name="transaction_id"
                isModalField
              />
            </div>
          )}
          {values.step === `chooseTransaction` && (
            <div>
              <div className="notice">
                <h1>{t(`transactions.findedPossible`)}:</h1>
                <p>
                  {values.transaction_id} - {values.wallet_type}
                </p>
                <span>{t(`transactions.choosePossible`)}</span>
              </div>
              <div className="trans-titles">
                <span>{t(`common.sum`)}</span>
                <span>{t(`common.label`)}</span>
                <span>{t(`common.orderId`)}</span>
              </div>
              <RadioButtons
                selected={checkedPrimaryId}
                data={[
                  ...possibleTransactions.map((el) => {
                    return {
                      value: el.primary_id,
                      text: `${el.amount} ${el.label} ${el.merchant_order_id}`,
                      component: (
                        <div className="trans-row">
                          <span>
                            {el.amount} {el.currency_symbol}
                          </span>
                          <span>{el.label}</span>
                          <span>{el.merchant_order_id}</span>
                        </div>
                      ),
                    };
                  }),
                  {
                    value: `none`,
                    text: t(`transactions.dontUseLastData`),
                  },
                ]}
                isLabelFullWidth={true}
                onChange={(value: string | number) => {
                  const finded = possibleTransactions.find((el) => el.primary_id === value);
                  setCheckedPrimaryId(value as string);

                  if (finded) {
                    change(`transaction`, finded.id);
                  }
                }}
                name={`transaction`}
              />
              <div></div>
            </div>
          )}
          {values.step === `transactionWasChoosen` && (
            <div>
              <FormField
                name="wallet_type"
                id={`${form}_wallet_type`}
                label={t(`common.paymentSystem`)}
                data={walletTypes}
                type={InputType.SELECT}
                busy={walletTypesLoading}
                disabled={true}
                onChange={() => {
                  change(`wallet_id`, null);
                  change(`currency_code`, null);
                }}
                itemComponent={({ item: { text, is_active } }: Record<`item`, Record<string, string | boolean>>) => {
                  if (!is_active) {
                    return <span className="text-danger">{text}</span>;
                  }

                  return text;
                }}
                required
                translateKeys
                isModalField
              />
              <FormField
                label={t(`transactions.transactionId`)}
                onlyLatinDigitsAndSymbols
                name="transaction_id"
                disabled={true}
                isModalField
              />
              <div>
                <FormField
                  name="amount"
                  className={`
              ${showErrorAmountMessage(limits, wallet_type as string, amount, values?.currency_code) && `error`}
              `}
                  label={t(`common.amount`)}
                  amount
                  disabled={true}
                  required
                  isModalField
                />
                {showErrorAmountMessage(limits, wallet_type as string, amount, values?.currency_code) && (
                  <div className="max-amount-error-message">{t(`transactions.outsideLimit`)}</div>
                )}
              </div>
              <FormField
                name="currency_code"
                id={`${form}_currency_code`}
                label={t(`common.currency`)}
                type={InputType.SELECT}
                data={currencies}
                disabled={true}
                required
                isModalField
              />
              <FormField name="label" label={t(`transactions.playerId`)} required disabled={true} isModalField />
              <FormField
                name="merchant_order_id"
                label={t(`transactions.orderNumber`)}
                onlyLatinDigitsAndSymbols
                isModalField
                disabled={true}
              />
              {transactionType === TransactionKind.REAL && (
                <FormField
                  name="wallet_id"
                  id={`${form}_wallet_id`}
                  label={t(`transactions.walletNumber`)}
                  data={sortedWallets}
                  type={InputType.SELECT}
                  busy={walletsListLoading}
                  required
                  itemComponent={({
                    item: { text, is_active, removed },
                  }: Record<`item`, Record<string, string | number>>) => {
                    if (!is_active && !removed) {
                      return <span className="text-danger">{text}</span>;
                    } else if (removed) {
                      return <span style={{ color: `blue` }}>{text}</span>;
                    }

                    return text;
                  }}
                  translateKeys
                  isModalField
                />
              )}
              {(transactionType === TransactionKind.REAL || transactionType === TransactionKind.FORCED) && (
                <FormField name="exchange_identifier" label={t(`transactions.exchanger`)} isModalField maxLength={40} />
              )}
            </div>
          )}
          {values.step === `transactionWasNotChoosen` && (
            <div>
              <FormField
                name="wallet_type"
                id={`${form}_wallet_type`}
                label={t(`common.paymentSystem`)}
                data={walletTypes}
                type={InputType.SELECT}
                busy={walletTypesLoading}
                disabled={true}
                onChange={() => {
                  change(`wallet_id`, null);
                  change(`currency_code`, null);
                }}
                itemComponent={({ item: { text, is_active } }: Record<`item`, Record<string, string | boolean>>) => {
                  if (!is_active) {
                    return <span className="text-danger">{text}</span>;
                  }

                  return text;
                }}
                required
                translateKeys
                isModalField
              />
              <FormField
                disabled={true}
                label={t(`transactions.transactionId`)}
                onlyLatinDigitsAndSymbols
                required={transactionType !== TransactionKind.FORCED}
                name="transaction_id"
                isModalField
              />
              {transactionType === TransactionKind.REAL && (
                <FormField
                  name="wallet_id"
                  id={`${form}_wallet_id`}
                  label={t(`transactions.walletNumber`)}
                  data={sortedWallets}
                  type={InputType.SELECT}
                  busy={walletsListLoading}
                  required={true}
                  itemComponent={({
                    item: { text, is_active, removed },
                  }: Record<`item`, Record<string, string | number>>) => {
                    if (!is_active && !removed) {
                      return <span className="text-danger">{text}</span>;
                    } else if (removed) {
                      return <span style={{ color: `blue` }}>{text}</span>;
                    }

                    return text;
                  }}
                  translateKeys
                  isModalField
                />
              )}
              <div>
                <FormField
                  name="amount"
                  className={`
              ${showErrorAmountMessage(limits, wallet_type as string, amount, values?.currency_code) && `error`}
              `}
                  label={t(`common.amount`)}
                  amount
                  required
                  isModalField
                />
                {showErrorAmountMessage(limits, wallet_type as string, amount, values?.currency_code) && (
                  <div className="max-amount-error-message">{t(`transactions.outsideLimit`)}</div>
                )}
              </div>
              <FormField
                name="currency_code"
                id={`${form}_currency_code`}
                label={t(`common.currency`)}
                type={InputType.SELECT}
                data={currencies}
                // disabled={disabledCurrencyField}
                required
                isModalField
              />
              {(transactionType === TransactionKind.REAL || transactionType === TransactionKind.FORCED) && (
                <FormField name="exchange_identifier" label={t(`transactions.exchanger`)} isModalField maxLength={40} />
              )}
              <FormField
                name="activate"
                label={t(`transactions.activate`)}
                type={InputType.CHECKBOX}
                required
                isModalField
              />
              {isActive && (
                <>
                  <FormField name="label" label={t(`transactions.playerId`)} required isModalField />
                  <FormField
                    name="merchant_order_id"
                    label={t(`transactions.orderNumber`)}
                    onlyLatinDigitsAndSymbols
                    isModalField
                  />
                </>
              )}
            </div>
          )}
          {values.step === `thereIsNoTransactions` && (
            <div>
              <FormField
                name="wallet_type"
                id={`${form}_wallet_type`}
                label={t(`common.paymentSystem`)}
                data={walletTypes}
                type={InputType.SELECT}
                busy={walletTypesLoading}
                onChange={() => {
                  change(`wallet_id`, null);
                  change(`currency_code`, null);
                }}
                itemComponent={({ item: { text, is_active } }: Record<`item`, Record<string, string | boolean>>) => {
                  if (!is_active) {
                    return <span className="text-danger">{text}</span>;
                  }

                  return text;
                }}
                required
                translateKeys
                isModalField
              />

              <FormField
                label={t(`transactions.transactionId`)}
                onlyLatinDigitsAndSymbols
                // required={transactionType !== TransactionKind.FORCED}
                name="transaction_id"
                isModalField
              />
              {transactionType === TransactionKind.REAL && (
                <FormField
                  name="wallet_id"
                  id={`${form}_wallet_id`}
                  label={t(`transactions.walletNumber`)}
                  data={sortedWallets}
                  type={InputType.SELECT}
                  busy={walletsListLoading}
                  required
                  itemComponent={({
                    item: { text, is_active, removed },
                  }: Record<`item`, Record<string, string | number>>) => {
                    if (!is_active && !removed) {
                      return <span className="text-danger">{text}</span>;
                    } else if (removed) {
                      return <span style={{ color: `blue` }}>{text}</span>;
                    }

                    return text;
                  }}
                  translateKeys
                  isModalField
                />
              )}
              <div>
                <FormField
                  name="amount"
                  className={`
                 ${showErrorAmountMessage(limits, wallet_type as string, amount, values?.currency_code) && `error`}
                `}
                  label={t(`common.amount`)}
                  amount
                  required
                  isModalField
                />
                {showErrorAmountMessage(limits, wallet_type as string, amount, values?.currency_code) && (
                  <div className="max-amount-error-message">{t(`transactions.outsideLimit`)}</div>
                )}
              </div>

              <FormField
                name="currency_code"
                id={`${form}_currency_code`}
                label={t(`common.currency`)}
                type={InputType.SELECT}
                data={currencies}
                // disabled={disabledCurrencyField}
                required
                isModalField
              />
              <FormField name="exchange_identifier" label={t(`transactions.exchanger`)} isModalField maxLength={40} />
              <FormField
                name="activate"
                label={t(`transactions.activate`)}
                type={InputType.CHECKBOX}
                required
                isModalField
              />
              {isActive && (
                <>
                  <FormField name="label" label={t(`transactions.playerId`)} required isModalField />
                  <FormField
                    name="merchant_order_id"
                    label={t(`transactions.orderNumber`)}
                    onlyLatinDigitsAndSymbols
                    isModalField
                  />
                </>
              )}
            </div>
          )}
        </>
      )}
    </form>
  );

  const footer = (
    <>
      {/* {submitFailed && error && <ErrorBlock error={error} />} */}
      {error && <ErrorBlock error={error} />}
      <div className="modal__control-button">
        {transactionType === TransactionKind.DEBUG && (
          <button
            className="btn btn-alt-success"
            onClick={() => {
              if (!submitting) {
                onSubmit();
              }
            }}
            disabled={
              Boolean(submitting) ||
              (showErrorAmountMessage(limits, wallet_type as string, amount, values?.currency_code) as true | undefined)
            }
            id="create_transaction_btn"
          >
            {t(`common.create`)}
          </button>
        )}
        {(transactionType === TransactionKind.FORCED || transactionType === TransactionKind.REAL) && (
          <>
            {values.step === undefined && (
              <button
                type="button"
                onClick={async () => {
                  const { transaction_id, wallet_type } = values;

                  fetchPossibleTransactions({ transaction_id, wallet_type, status: 0 });
                }}
                className="btn btn-alt-success"
                disabled={!values.transaction_id || !values.wallet_type}
              >
                {t(`common.next`)}
              </button>
            )}
            {values.step === `chooseTransaction` && (
              <>
                <button
                  className="btn btn-alt-info"
                  onClick={() => {
                    setIsTransactionsLoaded(false);
                    // setStep(`setWallet`);
                    change(`step`, undefined);
                  }}
                >
                  {t(`common.back`)}
                </button>
                <button
                  type="button"
                  className="btn btn-alt-success"
                  onClick={() => {
                    // if (values.transaction === `none`) {
                    if (checkedPrimaryId === `none`) {
                      // setStep(`transactionWasNotChoosen`);
                      change(`step`, `transactionWasNotChoosen`);
                    } else {
                      // const checkedTransaction = possibleTransactions.find((el) => el.id == values.transaction);
                      const checkedTransaction = possibleTransactions.find((el) => el.primary_id == +checkedPrimaryId);
                      if (checkedTransaction) {
                        change(`amount`, checkedTransaction.amount);
                        change(`currency_code`, checkedTransaction.currency_symbol);
                        change(`label`, checkedTransaction.label);
                        change(`merchant_order_id`, checkedTransaction.merchant_order_id);
                        change(`primary_id`, checkedTransaction.primary_id);
                      }

                      // setStep(`transactionWasChoosen`);
                      change(`step`, `transactionWasChoosen`);
                    }
                  }}
                >
                  {t(`common.next`)}
                </button>
              </>
            )}
            {values.step === `transactionWasChoosen` && (
              <>
                <button
                  className="btn btn-alt-info"
                  onClick={() => {
                    // setStep(`chooseTransaction`);
                    change(`step`, `chooseTransaction`);
                    setCheckedPrimaryId(`none`);
                  }}
                >
                  {t(`common.back`)}
                </button>
                {transactionType === TransactionKind.FORCED && (
                  <button
                    className="btn btn-alt-primary"
                    type="submit"
                    onClick={() => {
                      change(`isNeedToReopen`, true);
                    }}
                    form="create-transaction"
                    id="create_another_transaction_btn"
                    disabled={
                      Boolean(submitting) ||
                      (showErrorAmountMessage(limits, wallet_type as string, amount, values?.currency_code) as
                        | true
                        | undefined)
                    }
                  >
                    {t(`common.createAndThenCreateAnotherOne`)}
                  </button>
                )}
                <button
                  className="btn btn-alt-success"
                  form="create-transaction"
                  disabled={
                    Boolean(submitting) ||
                    (showErrorAmountMessage(limits, wallet_type as string, amount, values?.currency_code) as
                      | true
                      | undefined)
                  }
                  id="create_transaction_btn"
                >
                  {t(`common.create`)}
                </button>
              </>
            )}
            {values.step === `transactionWasNotChoosen` && (
              <>
                <button
                  className="btn btn-alt-info"
                  onClick={() => {
                    // setStep(`chooseTransaction`);
                    change(`step`, `chooseTransaction`);
                  }}
                >
                  {t(`common.back`)}
                </button>
                {transactionType === TransactionKind.FORCED && (
                  <button
                    className="btn btn-alt-primary"
                    type="submit"
                    form="create-transaction"
                    onClick={() => {
                      change(`isNeedToReopen`, true);
                    }}
                    id="create_another_transaction_btn"
                    disabled={
                      Boolean(submitting) ||
                      (showErrorAmountMessage(limits, wallet_type as string, amount, values?.currency_code) as
                        | true
                        | undefined)
                    }
                  >
                    {t(`common.createAndThenCreateAnotherOne`)}
                  </button>
                )}
                <button
                  className="btn btn-alt-success"
                  type="submit"
                  form="create-transaction"
                  disabled={
                    Boolean(submitting) ||
                    (showErrorAmountMessage(limits, wallet_type as string, amount, values?.currency_code) as
                      | true
                      | undefined)
                  }
                  id="create_transaction_btn"
                >
                  {t(`common.create`)}
                </button>
              </>
            )}
            {values.step === `thereIsNoTransactions` && (
              <>
                <button
                  className="btn btn-alt-info"
                  onClick={() => {
                    // setStep(`setWallet`);
                    change(`step`, undefined);
                    setIsTransactionsLoaded(false);
                  }}
                >
                  {t(`common.back`)}
                </button>
                {transactionType === TransactionKind.FORCED && (
                  <button
                    className="btn btn-alt-primary"
                    onClick={onSubmitWithReopeningModal}
                    id="create_another_transaction_btn"
                    disabled={
                      Boolean(submitting) ||
                      (showErrorAmountMessage(limits, wallet_type as string, amount, values?.currency_code) as
                        | true
                        | undefined)
                    }
                  >
                    {t(`common.createAndThenCreateAnotherOne`)}
                  </button>
                )}
                <button
                  className="btn btn-alt-success"
                  onClick={() => {
                    dispatch(transactionActionCreators.create(values));
                  }}
                  disabled={
                    Boolean(submitting) ||
                    (showErrorAmountMessage(limits, wallet_type as string, amount, values?.currency_code) as
                      | true
                      | undefined)
                  }
                  id="create_transaction_btn"
                >
                  {t(`common.create`)}
                </button>
              </>
            )}
          </>
        )}
      </div>
    </>
  );

  return (
    <ModalElement
      title={modalTitle[transactionType]}
      actionCreators={transactionActionCreators}
      content={content}
      footer={footer}
    />
  );
};

export default reduxForm({
  form: CREATE_TRANSACTION_FORM_NAME,
  forceUnregisterOnUnmount: true,
})(TransactionCreating);
