import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';

export class WorkingTimeApi extends BaseApi {
  loadPaymentWorkingTimeData = (walletType: string): AxiosPromise => this.makeFetch(`{walletType}`, {}, { walletType });
}

const workingTimeApi = new WorkingTimeApi(`wallet-type/worktime`);

export default workingTimeApi;
