import authApi, { AuthApi } from 'api/auth/AuthApi';
import userApi, { UserApi } from 'api/auth/UserApi';
import balanceHistoryApi, { BalanceHistoryApi } from 'api/balances/BalanceHistoryApi';
import settlementApi, { SettlementApi } from 'api/balances/SettlementApi';
import walletApi, { WalletApi } from 'api/wallet/WalletApi';
import cookieApi, { CookieApi } from 'api/cookie/CookieApi';
import settlementWithdrawalApi, { SettlementWithdrawalApi } from 'api/balances/SettlementWithdrawalApi';
import changeLogsApi, { ChangeLogsApi } from 'api/changeLogs/ChangeLogsApi';
import paymentHintApi, { PaymentHintApi } from 'api/constructors/PaymentHintApi';
import limitsApi, { LimitsApi } from 'api/constructors/LimitsApi';
import proxySettingsApi, { ProxySettingsApi } from 'api/constructors/ProxySettingsApi';
import minimumDepositApi, { MinimumDepositApi } from './constructors/MinimumDepositApi';
import tariffApi, { TariffApi } from 'api/constructors/TariffApi';
import workingTimeApi, { WorkingTimeApi } from 'api/constructors/WorkingTimeApi';
import currencyApi, { CurrencyApi } from 'api/currencies/CurrencyApi';
import commissionApi, { CommissionApi } from 'api/commission/CommissionApi';
import dashboardApi, { DashboardApi } from 'api/dashboard/DashboardApi';
import logApi, { LogApi } from 'api/logs/LogApi';
import paytmCookiesLogApi, { PaytmCookiesLogApi } from 'api/logs/PaytmCookiesLogApi';
import postbackApi, { PostbackApi } from 'api/postback/PostbackApi';
import proxyApi, { ProxyApi } from 'api/proxy/ProxyApi';
import rbacPermissionApi, { RbacPermissionApi } from 'api/rbac/permissions/RbacPermissionApi';
import reportApi, { ReportApi } from 'api/reports/ReportApi';
import autoReportApi, { AutoReportApi } from './autoreports/AutoReportApi';
import settingsApi, { SettingsApi } from 'api/settings/SettingsApi';
import stockpilingApi, { StockpilingApi } from 'api/stockpiling/StockpilingApi';
import testPaymentSystemApi, { TestPaymentSystemApi } from 'api/testPaymentSystems/TestPaymentSystemApi';
import transactionApi, { TransactionApi } from 'api/transactions/TransactionApi';
import archiveTransactionApi, {
  ArchiveTransactionApi,
} from 'api/transactions/archiveTransactions/ArchiveTransactionApi';
import possibleTransactionApi, {
  PossibleTransactionApi,
} from 'api/transactions/possibleTransactions/PossibleTransactionApi';
import pretransactionApi, { PretransactionApi } from 'api/transactions/preliminaryTransactions/PretransactionApi';
import withdrawalApi, { WithdrawalApi } from 'api/withdrawals/WithdrawalApi';
import withdrawalV1Api, { WithdrawalV1Api } from 'api/withdrawals/WithdrawalV1Api';
import withdrawalV2Api, { WithdrawalV2Api } from 'api/withdrawals/WithdrawalV2Api';
import paymentApi, { PaymentApi } from 'api/payment/PaymentApi';
import walletTypeImageApi, { WalletTypeImageApi } from 'api/walletType/WalletTypeImage';
import timezonesApi, { TimezonesApi } from 'api/timezones/TimezonesApi';
import walletTypeApi, { WalletTypeApi } from 'api/walletType/WalletTypeApi';
import walletTypesApi, { WalletTypesApi } from './walletTypes/WalletTypes';
import countriesApi, { CountriesApi } from 'api/countries/CountriesApi';
import accountBalanceApi, { AccountBalanceApi } from 'api/balances/AccountBalanceApi';
import walletTypesToCurrenciesApi, { WalletTypeToCurrenciesApi } from './walletTypes/WalletTypeToCurrenciesApi';
import pluralWalletTypesApi from './WalletTypes';
import walletsApi from './wallets/WalletsApi';

export class Api {
  auth: AuthApi = authApi;
  user: UserApi = userApi;
  accountBalance: AccountBalanceApi = accountBalanceApi;
  balanceHistory: BalanceHistoryApi = balanceHistoryApi;
  settlement: SettlementApi = settlementApi;
  wallet: WalletApi = walletApi;
  cookie: CookieApi = cookieApi;
  settlementWithdrawal: SettlementWithdrawalApi = settlementWithdrawalApi;
  changeLogs: ChangeLogsApi = changeLogsApi;
  paymentHint: PaymentHintApi = paymentHintApi;
  limits: LimitsApi = limitsApi;
  proxySettings: ProxySettingsApi = proxySettingsApi;
  minimumDeposit: MinimumDepositApi = minimumDepositApi;
  tariff: TariffApi = tariffApi;
  workingTime: WorkingTimeApi = workingTimeApi;
  currency: CurrencyApi = currencyApi;
  commission: CommissionApi = commissionApi;
  dashboard: DashboardApi = dashboardApi;
  log: LogApi = logApi;
  paytmCookiesLog: PaytmCookiesLogApi = paytmCookiesLogApi;
  postback: PostbackApi = postbackApi;
  proxy: ProxyApi = proxyApi;
  rbacPermission: RbacPermissionApi = rbacPermissionApi;
  report: ReportApi = reportApi;
  autoreport: AutoReportApi = autoReportApi;
  settings: SettingsApi = settingsApi;
  stockpiling: StockpilingApi = stockpilingApi;
  testPaymentSystem: TestPaymentSystemApi = testPaymentSystemApi;
  transaction: TransactionApi = transactionApi;
  archiveTransaction: ArchiveTransactionApi = archiveTransactionApi;
  possibleTransaction: PossibleTransactionApi = possibleTransactionApi;
  pretransaction: PretransactionApi = pretransactionApi;
  withdrawal: WithdrawalApi = withdrawalApi;
  withdrawalV1: WithdrawalV1Api = withdrawalV1Api;
  withdrawalV2: WithdrawalV2Api = withdrawalV2Api;
  payment: PaymentApi = paymentApi;
  walletTypeImageApi: WalletTypeImageApi = walletTypeImageApi;
  timezones: TimezonesApi = timezonesApi;
  walletType: WalletTypeApi = walletTypeApi;
  walletTypes: WalletTypesApi = walletTypesApi;
  countries: CountriesApi = countriesApi;
  walletTypesToCurrenciesApi: WalletTypeToCurrenciesApi = walletTypesToCurrenciesApi;
  pluralWalletTypesApi = pluralWalletTypesApi;
  walletsApi = walletsApi;
}

const api = new Api();

export default api;
