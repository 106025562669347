export const newResponseSupportList: Record<string, Record<string, boolean>> = {
  [`translation`]: {
    [``]: true,
    [`package`]: true,
    [`language`]: true,
    [`package/{id}`]: true,
  },
  [`auth`]: {
    [`refresh`]: false,
    [`logout`]: false,
    [`login`]: true,
  },
  [`user`]: {
    [`me`]: false,
    [`change-password`]: false,
    [`hidden_column`]: false,
  },
  [`account-balance`]: {
    [`transfer-history`]: false,
    [`transfer`]: false,
    [`list`]: false,
  },
  [`accounts-balance-history`]: {
    [`list`]: true,
  },
  [`settlement`]: {
    [`external-replenishment`]: false,
    [`replenishment`]: false,
    [`replenish-from-balance`]: false,
    [`list`]: false,
    [`balance`]: false,
    [`withdrawal/approve/{id}`]: false,
    [`withdrawal/list`]: false,
    [`withdrawal/reject/{id}`]: false,
    [`withdrawal/create`]: false,
  },
  [`change-logs`]: {
    [``]: false,
    [`action-types`]: false,
    [`object-types`]: false,
  },
  [`hint`]: {
    [`create`]: false,
    [`delete/{id}`]: false,
    [`langs`]: false,
    [`default/{paymentSystem}`]: false,
    [`get/{paymentSystem}`]: false,
    [`show/{id}`]: false,
    [`list`]: false,
    [`update/{id}`]: false,
  },
  [`payment`]: {
    [`payzen/config`]: false,
  },
  [`wallet-types`]: {
    [``]: true,
  },
  [`wallet-type`]: {
    [`get-all-logo/{walletType}`]: false,
    [`logo/{walletType}`]: false,
    [`get-targets/{paymentSystem}`]: false,
    [`targets/{paymentSystem}`]: false,
    [`currencies-for/{walletType}`]: false,
    [`list-with-currencies`]: false,
    [`{walletType}/get-names`]: false,
    [`update-currencies/{walletType}`]: false,
    [`update-settings/{walletType}`]: false,
    [`name/{walletType}`]: false,
    [`proxy-setting/{walletType}`]: false,
    [`limits/{paymentSystem}`]: false,
    [`worktime/{walletType}`]: false,
    [`list`]: false,
    [`list/for-possible-transactions`]: false,
    [`get-support-currency/{walletType}`]: false,
    [`settings-constructor/{ps}`]: false,
  },
  [`minimum-deposit`]: {
    [`{walletType}/get-minimum-deposit`]: false,
    [`{walletType}`]: false,
  },
  [`tariff`]: {
    [``]: false,
    [`{paymentSystem}`]: false,
    [`list/{paymentSystem}`]: false,
    [`{id}`]: false,
  },
  [`currency`]: {
    [`list`]: false,
  },
  [`commission`]: {
    [``]: false,
    [`{id}`]: false,
  },
  [`dashboard`]: {
    [`get-all-payment-system-settings`]: false,
    [`get-data`]: false,
    [`payment-systems`]: false,
    [`get-transactions-data`]: false,
    [`get-withdrawals-data`]: false,
  },
  [`log`]: {
    [`list`]: false,
    [`categories`]: false,
    [`withdrawals`]: false,
    [`paytm_cookies/list`]: false,
    [`paytm_cookies/upload`]: false,
    [`paytm_cookies/delete`]: false,
  },
  [`postback`]: {
    [`list`]: false,
    [`resend/{id}`]: false,
  },
  [`proxy`]: {
    [`multi-adding`]: false,
    [`get-checking-all-statuses`]: false,
    [`get-checking-status`]: false,
    [`check/{id}`]: false,
    [`wallets-relations`]: false,
    [`getchecked`]: false,
    [`initcheckall`]: false,
    [`init-checking-all`]: false,
    [`init-checking`]: false,
    [`{id}`]: false,
    [`list`]: false,
    [`create`]: false,
    [`update/{id}`]: false,
  },
  [`rbac`]: {
    [`permission/list`]: false,
    [`role/add`]: false,
    [`role/delete/{id}`]: false,
    [`role/with-permissions/{id}`]: false,
    [`role/list-with-permissions-users`]: false,
    [`role/update/{id}`]: false,
    [`user/add`]: false,
    [`user/delete/{id}`]: false,
    [`user/with-roles/{id}`]: false,
    [`user/list-with-roles`]: false,
    [`user/list-with-login`]: false,
    [`user/update/{id}`]: false,
  },
  [`reports`]: {
    [`balance-history`]: false,
    [`label-statistics`]: false,
    [`transactions/list`]: false,
    [`transactions`]: false,
    [`settlement-operations`]: false,
    [`transactions/sum`]: false,
    [`transactions/sum-detail`]: false,
    [`withdrawals`]: false,
    [``]: false,
    [`commission`]: false,
  },
  [`settings`]: {
    [`payments-for-currency`]: false,
    [`default-payment-of-currencies`]: false,
    [`update-for-currency`]: false,
    [`public-params`]: false,
    [`plugin`]: false,
    [`timezone`]: false,
    [`change-password`]: false,
    [`version`]: false,
    [`params`]: false,
    [`set-custom-css`]: false,
  },
  [`stockpiling`]: {
    [`list`]: false,
    [`{id}/force-send`]: false,
  },
  [`test-wallet-types`]: {
    [`create`]: false,
    [`{machineName}/delete`]: false,
    [`{machineName}/disable`]: false,
    [`{id}/show`]: false,
    [`list`]: false,
    [`{id}/update`]: false,
  },
  [`transaction`]: {
    [`force-activate/{primaryId}`]: false,
    [`create-manual`]: false,
    [`list-to-xls`]: false,
    [`list`]: false,
    [`icici-file`]: false,
    [`list-by-stockpiling`]: false,
    [`approve`]: true,
  },
  [`archive-transaction`]: {
    [`archive/{primaryId}`]: false,
    [`list`]: false,
  },
  [`possible-transaction`]: {
    [`remove/{primary_id}`]: false,
    [`get-image/{id}`]: false,
    [`list`]: false,
  },
  [`pretransaction`]: {
    [`{id}/activate`]: false,
    [`new`]: false,
    [`delete/{id}`]: false,
    [`list`]: false,
  },
  [`wallet`]: {
    [`balances`]: false,
    [`create`]: false,
    [`remove/{id}`]: false,
    [`paytm-with-modified-parsing-type/{id}`]: false,
    [`delete/{id}`]: false,
    [`admin-status`]: false,
    [`list-disabled-wallets`]: false,
    [`list-not-removed-wallets`]: false,
    [`{id}`]: false,
    [`list-removed-wallets`]: false,
    [`show/{id}`]: false,
    [`list`]: false,
    [`load`]: false,
    [`set-default-sms-cookie-flag`]: false,
    [`restore/{id}`]: false,
    [`set-sms-code`]: false,
    [`set-active/{id}`]: false,
    [`updated-plugin-status`]: false,
    [`scrape/{id}`]: false,
    [`update/{id}`]: false,
    [`settings/{id}`]: false,
    [`delete-archive-paytm-cookies/{id}`]: false,
    [`types-for-forced-transactions`]: false,
  },
  [`cookie`]: {
    [`{id}`]: false,
  },
  [`withdrawal`]: {
    [`approve/{id}`]: false,
    [`report`]: false,
    [`wallet-sender/{id}`]: false,
    [`bank-codes/get`]: false,
    [`branch-codes/get`]: false,
    [`get-cards/{withdrawalId}`]: false,
    [`paygiga/get-available-amounts/{bankCode}`]: false,
    [`paygiga/get-bank-list`]: false,
    [`methods/get`]: false,
    [`provider-id/get`]: false,
    [`status`]: false,
    [`{id}`]: false,
    [`list`]: false,
    [`reject/{id}`]: false,
    [`set-comment/{id}`]: false,
    [`import-report`]: false,
  },
  [`v1`]: {
    [`withdrawal/status`]: false,
  },
  [`v2`]: {
    [`withdrawal/create`]: false,
  },
  [`wallet-type/images`]: {
    [``]: false,
  },
  [`timezones`]: {
    [`list`]: false,
  },
  [`wallet_type`]: {
    [``]: false,
    [`settings-constructor/{ps}`]: true,
  },
  [`countries`]: {
    [`list`]: false,
  },
};

export const isPathSupportNewResponse = (path: string): boolean => {
  const parts = path.split(`/`);

  const part = parts[0];
  const route = parts.slice(1).join(`/`) || ``;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!newResponseSupportList[part]) {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return newResponseSupportList[part][route];
};
