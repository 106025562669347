import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { CREATE_MANUAL_PS_FORM } from 'utils/constants';
import { useSelector } from 'react-redux';
import { FieldType, InputType } from '@kassma-team/kassma-toolkit';
import FormField from 'components/form/FormField';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useArrayFields from 'hooks/widgets/useArrayFields';
import { currencySelectionSelector } from 'selectors/widgets/currency';
import i18n from 'i18n';

const validate = (values: any) => {
  const errors: any = {};

  const { limits, requisites, validation_transaction_id_from, validation_transaction_id_to } = values;

  if (requisites && requisites[0]) {
    const firstRequisite = requisites[0].code;
    if (!firstRequisite) {
      errors.requisites = [{ code: i18n.t(`errors.requiredField`) }];
    }
  }

  if (limits && limits.deposit.min && limits.deposit.max && +limits.deposit.min >= +limits.deposit.max) {
    errors.limits = {
      deposit: {
        max: i18n.t(`errors.maxDepositMustBeGreaterThanMin`),
      },
    };
  }

  if (
    validation_transaction_id_from &&
    validation_transaction_id_to &&
    +validation_transaction_id_to < +validation_transaction_id_from
  ) {
    errors.validation_transaction_id_to = i18n.t(`errors.minAmount`, { amount: +validation_transaction_id_from });
  }

  return errors;
};

const CreationPSData = ({ handleSubmit, form, change }: InjectedFormProps) => {
  const values: Partial<any> = useSelector(getFormValues(form)) || {};
  const [t] = useTranslation();

  const currencies: any[] = useSelector(currencySelectionSelector);

  const requisites = values.requisites;
  const showTransactionId = values.show_transaction_id;

  useEffect(() => {
    if (!showTransactionId) {
      change(`show_transaction_id`, true);
    }
  }, [showTransactionId]);

  useEffect(() => {
    if (!requisites || requisites.length === 0) {
      change(`requisites`, [{ code: `` }]);
    }
  }, [requisites]);

  const renderRequisites = useArrayFields({
    fields: () => [
      {
        placeholder: t(`paymentConstructor.requisiteName`),
        name: `code`,
        wrapClassName: `w-90 mr-5`,
        required: true,
        englishName: true,
        onlyLatinDigitsAndSymbolsSpace: true,
      },
    ],
    title: t(`paymentConstructor.requisites`),
    showHint: true,
    hint: t(`paymentConstructor.requisiteNameInEnglish`),
    name: `requisites`,
  });

  const requestTransactionId = values.show_transaction_id;

  const fields = useMemo(() => {
    let items: any[] = [
      {
        name: `logo`,
        id: `${form}_image`,
        type: InputType.DROPZONE,
        label: t(`paymentConstructor.logo`),
        required: true,
        fileAcceptedTypes: [`.svg`, `.png`, `.jpeg`, `.jpg`, `.ico`],
        maxSize: 1024 * 1024 * 10,
        showDeleteButton: true,
      },
      {
        name: `name`,
        id: `${form}_name`,
        type: InputType.TEXT,
        label: t(`paymentConstructor.paymentSystemTitle`),
        description: `paymentConstructor.nameInEnglish`,
        required: true,
        englishName: true,
        onlyLatinDigitsAndSymbolsSpace: true,
      },
      {
        name: `code`,
        id: `${form}_codename`,
        type: InputType.TEXT,
        label: t(`paymentConstructor.codename`),
        description: `manualPS.codeNameFormat`,
        regexMatch: {
          regex: /^[a-z\d_]*[a-z\d]_ml$/i,
          message: t(`errors.manualPSFormatError`),
        },
        required: true,
        englishCode: true,
        onlyLatinDigitsAndSymbolsLower: true,
      },
      {
        name: `currency`,
        label: t(`common.currency`),
        required: true,
        type: InputType.SELECT,
        data: currencies,
      },
      {
        name: `limits.deposit.min`,
        id: `${form}_min_deposit`,
        sModalField: true,
        type: InputType.TEXT,
        label: t(`paymentConstructor.minDeposit`),
        required: true,
        amount: true,
      },
      {
        name: `limits.deposit.max`,
        id: `${form}_max_deposit`,
        sModalField: true,
        type: InputType.TEXT,
        label: t(`paymentConstructor.maxDeposit`),
        required: true,
        amount: true,
      },
      {
        name: `requisites`,
        label: t(`paymentConstructor.requisites`),
        required: true,
        fieldType: FieldType.FIELD_ARRAY,
        component: renderRequisites,
      },
      {
        name: `show_transaction_id`,
        label: t(`paymentConstructor.requestTransactionId`),
        type: InputType.CHECKBOX,
        disabled: true,
      },
      {
        name: `show_payment_bill`,
        label: t(`paymentConstructor.requestReceipt`),
        type: InputType.CHECKBOX,
      },
    ];
    if (requestTransactionId) {
      const lastField = items[items.length - 1];
      items = [...items.slice(0, items.length - 1)];
      items.push({
        title: t(`paymentConstructor.charNumValidation`),
        fields: [
          {
            name: `validation_transaction_id_from`,
            label: t(`common.dateFrom`),
            amount: true,
            required: true,
          },
          {
            name: `validation_transaction_id_to`,
            label: t(`common.dateTo`),
            amount: true,
            required: true,
          },
        ],
      });
      items.push(lastField);
    }

    return items;
  }, [requestTransactionId, currencies]);

  return (
    <form onSubmit={handleSubmit}>
      {fields.map((field) => {
        const innerFields = field.fields;
        if (!innerFields) {
          return <FormField key={field.name} isModalField={true} {...field} />;
        } else {
          return (
            <>
              <b>{field.title}</b>
              {innerFields.map((innerField: any) => (
                <FormField key={innerField.name} isModalField={true} {...innerField} />
              ))}
            </>
          );
        }
      })}
    </form>
  );
};

export default reduxForm({
  form: CREATE_MANUAL_PS_FORM,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  validate,
})(CreationPSData);
