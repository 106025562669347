import { CustomTranslate } from 'pages/constructor/DefaultTranslates/common/types';

type NormalizedData = Record<string, Array<CustomTranslate>>;
type Key = `name`;

/**
 * normalizeByKey
 *
 * Группирует данные по ключу
 *
 * Собирает кастомные подсказки с одинаковым именем в 1 коллекцию
 */
export const normalizeByKey = (arr: Array<CustomTranslate>, key: Key): NormalizedData => {
  const normalized = arr.reduce((acc, curr) => {
    if (!acc[curr[key]]) {
      acc[curr[key]] = [];
    }

    acc[curr[key]].push(curr);

    return acc;
  }, {} as NormalizedData);

  return normalized;
};
