import React from 'react';

import { possibleTransactionActionCreators } from 'actions/widgets/transactions/possibleTransactions';

import PossibleTransactionItem from 'components/widgets/transaction/possibleTransactions/possibleTransactionItem/PossibleTransactionItem';
import ModalElement from 'components/modals/ModalElement';

const PossibleTransactionItemModal = () => {
  return (
    <ModalElement
      content={<PossibleTransactionItem />}
      actionCreators={possibleTransactionActionCreators}
      title="transactions.transaction"
    />
  );
};

export default PossibleTransactionItemModal;
