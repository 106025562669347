import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import { getSubmitHandler, InputType } from '@kassma-team/kassma-toolkit/lib';

import { ModalType, WidgetType, Permission } from 'utils/enums';
import { stockpilingListActionCreators } from 'actions/widgets/stockpiling';
import { PSWithoutSettlementsSelector } from 'selectors/widgets/paymentSystems';
import useStorckpilingTableTitles from 'hooks/widgets/tableTitles/useStorckpilingTableTitles';
import { selectionOfAllManualPSSelector } from 'selectors/widgets/manualPS';
import withPermissionCheck from 'hocs/withPermissionCheck';

import StockpilingRow from 'components/widgets/stockpiling/StockpilingRow';
import Filters from 'components/widgets/filters/Filters';
import StockpilingPostbackList from 'components/widgets/stockpiling/StockpilingPostbackList';
import StockpilingTransactionList from 'components/widgets/stockpiling/StockpilingTransactionList';
import StockpilingSendPostback from 'components/widgets/stockpiling/StockpilingSendPostback';
import Widget from 'components/widgets/Widget';
import { IStockpilingRowProps } from 'interfaces/widgets/stockpiling';
import { IFilterProps } from 'interfaces/widgets/widgets';

const FORM_NAME = `stockpiling-list-form`;

const FilterForm = reduxForm<any, IFilterProps>({
  form: FORM_NAME,
  onSubmit: getSubmitHandler({
    dateFields: [{ name: `date_range`, fromName: `date_from`, toName: `date_to` }],
  }),
})(Filters);

const filterDateFields = [
  {
    from: `date_from`,
    to: `date_to`,
    name: `date_range`,
  },
];

const rightFilterFields = [
  {
    name: `date_range`,
    type: InputType.DATE_RANGE,
  },
];

const modals = [
  {
    type: ModalType.STOCKPILINGS_POSTBACK,
    widget: WidgetType.STOCKPILING_LIST,
    component: StockpilingPostbackList,
  },
  {
    type: ModalType.STOCKPILINGS_SEND_POSTBACK,
    widget: WidgetType.STOCKPILING_LIST,
    component: StockpilingSendPostback,
  },
  {
    type: ModalType.STOCKPILINGS_TRANSACTION_LIST,
    widget: WidgetType.STOCKPILING_LIST,
    component: StockpilingTransactionList,
  },
];

const listMapping = (item: IStockpilingRowProps) => <StockpilingRow key={item.id} {...item} />;

const StockpilingList = () => {
  const titles = useStorckpilingTableTitles();

  const [t] = useTranslation();

  const paymentSystemList = useSelector(PSWithoutSettlementsSelector);

  const safePaymentSystemList = paymentSystemList?.map((el) => ({
    ...el,
    text: el.name,
    value: el.code,
  }));

  const testPS = useSelector(selectionOfAllManualPSSelector);

  const safeTestPS = testPS?.map((el) => ({
    ...el,
    text: el.name,
    value: el.code,
  }));

  const leftFilterFields = [
    {
      name: `label`,
      placeholder: t(`common.label`),
    },
    {
      name: `payment_system`,
      id: `${FORM_NAME}_payment_system`,
      data: [...safePaymentSystemList, ...safeTestPS],
      type: InputType.SELECT,
      placeholder: t(`wallets.walletType`),
    },
    {
      name: `is_real`,
      id: `stockpiling_type`,
      data: [
        {
          text: t(`common.all`),
          value: `all`,
        },
        {
          text: t(`stockpilings.real`),
          value: `1`,
        },
        {
          text: t(`stockpilings.debug`),
          value: `0`,
        },
      ],
      type: InputType.SELECT,
      placeholder: t(`stockpilings.type`),
    },
    {
      name: `status`,
      id: `${FORM_NAME}_status`,
      data: [
        {
          value: null,
          text: t(`common.all`),
        },
        {
          value: `0`,
          text: t(`common.notCompleted`),
        },
        {
          value: `1`,
          text: t(`common.completed`),
        },
      ],
      type: InputType.SELECT,
      placeholder: t(`common.status`),
    },
  ];

  return (
    <Widget
      widget={WidgetType.STOCKPILING_LIST}
      actionCreators={stockpilingListActionCreators}
      filterFormName={FORM_NAME}
      pageTitle="stockpilings.stockpilings"
      headTitle="stockpilings.stockpilings"
      tableTitle="stockpilings.stockpilingList"
      tableHeadTitles={titles}
      listMapping={listMapping}
      filterForm={
        <FilterForm
          formName={FORM_NAME}
          rightFields={rightFilterFields}
          leftFields={leftFilterFields}
          dateFields={filterDateFields}
        />
      }
      modals={modals}
      withPagination
      statusBar
    />
  );
};

export default withPermissionCheck(StockpilingList, [
  {
    permissions: [
      {
        permission: Permission.STOCKPILING_LIST,
        opposite: true,
      },
    ],
    redirectUrl: `/no-permissions`,
  },
]);
