import { widgetActionCreators, IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';

export const changeLogsActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.CHANGE_LOGS,
  labels: { listLabel: `change_logs` },
  withPagination: true,
});

export const changeLogObjectsActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.CHANGE_LOG_OBJECTS,
  labels: { listLabel: `data` },
});

export const changeLogActionsActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.CHANGE_LOG_ACTIONS,
  labels: { listLabel: `data` },
});

export const changeLogUsersActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.CHANGE_LOG_USERS,
  labels: { listLabel: `data` },
});
