import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import isNumber from 'lodash/isNumber';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isNull from 'lodash/isNull';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classNames from 'classnames';
import useMount from 'react-use/lib/useMount';
import {
  Tooltip,
  WalletImg,
  widgetModalSelector,
  widgetItemSelector,
  IWidgetActionCreators,
} from '@kassma-team/kassma-toolkit/lib';

import {
  ModalType,
  PaytmScrapingStatus,
  Permission,
  ProxyStatus,
  WalletStatus,
  WalletType,
  WidgetType,
} from 'utils/enums';
import {
  disabledWalletActionCreators,
  markWalletAsActive,
  showWallet,
  walletActionCreators,
} from 'actions/widgets/wallet';
import { loadPaytmFlag } from 'actions/widgets/settings';
import { hasAccessSelector } from 'selectors/auth';
import { paytmActionCreators } from 'actions/widgets/payments/paytm';
import useFullAmount from 'hooks/useFullAmount';
import executeWidgetActionMethod from 'actions/widgets/executeWidgetActionMethod';
import { paymentLabelSelector, paymentSystemLogoSelector } from 'selectors/widgets/paymentSystems';
import { IWalletItemProps } from 'interfaces/widgets/wallets/wallet';
import { paytmFlagSelector } from 'selectors/widgets/settings';

import ModalElement from 'components/modals/ModalElement';

interface IWalletRowProps {
  children: ReactNode;
  textClassName: string;
  label: string;
}

const WalletRow = ({ children, textClassName = `text-muted`, label }: IWalletRowProps) => (
  <tr>
    <td className="font-w600">{label}</td>
    <td className={textClassName}>{children}</td>
  </tr>
);

const WalletItem = ({
  id,
  wallet_type: type,
  identifier,
  balance,
  proxies,
  currency,
  widget,
  test_wallet_type,
  comment,
  actionCreators,
  states: state,
  sms_code,
  extra_balances,
  activity_log,
  scraping,
  scraped,
  created_at,
  cookie_type,
  is_active,
  removed_at,
  sms_source,
  daily_transaction_limit,
  parser_type,
}: Partial<IWalletItemProps>) => {
  const dispatch = useDispatch();

  const [t] = useTranslation();

  const transactionAccess = useSelector(hasAccessSelector(Permission.TRANSACTION_LIST));
  const setActiveAccess = useSelector(hasAccessSelector(Permission.WALLET_SET_ACTIVE));
  const walletBalanceAccess = useSelector(hasAccessSelector(Permission.WALLET_BALANCE));
  const commentPermission = useSelector(hasAccessSelector(Permission.WALLET_COMMENT));
  const paymentLabel = useSelector(paymentLabelSelector(type as string));

  const renderProxyTooltip = useCallback(({ type, ip, port, country_code }) => {
    return (
      <Tooltip
        insideContent={
          <div>
            <div>
              {t(`proxies.ip`)}: {ip}
            </div>
            <div>
              {t(`proxies.port`)}: {port}
            </div>
            <div>
              {t(`common.type`)}: {type}
            </div>
            <div>
              {t(`common.country`)}: {country_code || t(`proxies.undefined`)}
            </div>
          </div>
        }
      >
        <i className="fa fa-eye mr-2 ml-2" />
      </Tooltip>
    );
  }, []);

  const renderProxy = useCallback(
    ({ status, ip, port, type, country_code }) => (
      <>
        <span className={classNames({ 'mr-2': status === ProxyStatus.ERROR })}>
          {ip}:{port}
        </span>
        {renderProxyTooltip({ type, ip, port, country_code })}
        {status === ProxyStatus.ERROR && <span className="badge badge-danger">{t(`wallets.proxyDoesNotWork`)}</span>}
      </>
    ),
    [renderProxyTooltip]
  );

  const fullAmount = useFullAmount({ amount: balance as number, currencySymbol: currency });

  const updated_at = get(activity_log, `data.updated_at`);

  const lastUpdate = updated_at ? moment(updated_at).format(`DD.MM.YYYY HH:mm:ss`) : undefined;
  let lastUpdateTooltip;
  const { last_ingoing, last_outgoing, last_parsing } = activity_log || {};
  const data = [];

  if (last_ingoing) {
    data.push({
      key: `wallets.lastIngoing`,
      value: last_ingoing,
    });
  }
  if (last_outgoing) {
    data.push({
      key: `wallets.lastOutgoing`,
      value: last_outgoing,
    });
  }
  if (last_parsing) {
    data.push({
      key: `wallets.lastParsing`,
      value: last_parsing,
    });
  }

  if (data.length && lastUpdate) {
    lastUpdateTooltip = (
      <Tooltip
        insideContent={map(data, ({ key, value }, index) =>
          key ? (
            <div key={key}>
              {t(key)}: {value} /
            </div>
          ) : (
            <div key={index}>{value} /</div>
          )
        )}
      >
        {lastUpdate}
      </Tooltip>
    );
  }

  const paytmFlag = useSelector(paytmFlagSelector);

  const logo = useSelector(paymentSystemLogoSelector(type as string));
  const fields = (
    <>
      <WalletRow label="ID" textClassName="text-muted">
        {id}
      </WalletRow>
      <WalletRow label={t(`wallets.identifier`)} textClassName="text-muted">
        {identifier}
      </WalletRow>
      <WalletRow label={t(`common.type`)} textClassName="text-muted">
        {WalletType.TEST === type ? (
          <WalletImg title={get(test_wallet_type, `name`)} src={get(test_wallet_type, `logo`)} />
        ) : (
          <WalletImg title={paymentLabel} src={logo} />
        )}
      </WalletRow>
      {isNumber(balance) && walletBalanceAccess ? (
        <WalletRow label={t(`wallets.balance`)} textClassName="text-elegance">
          {fullAmount}
        </WalletRow>
      ) : null}
      {isNumber(daily_transaction_limit) && (
        <WalletRow label={t(`wallets.dailyTransactionLimit`)} textClassName="text-muted">
          {daily_transaction_limit}
        </WalletRow>
      )}
      <WalletRow label={t(`wallets.lastUpdate`)} textClassName="text-success">
        {lastUpdateTooltip || lastUpdate}
      </WalletRow>
      {!isEmpty(proxies) && (
        <WalletRow label={t(`wallets.enProxyIP`)} textClassName="text-muted">
          {map(proxies, ({ type, port, ip, status, country_code }) => {
            return renderProxy({ type, port, ip, status, country_code });
          })}
        </WalletRow>
      )}
    </>
  );

  const walletsWithSms = [WalletType.PAY_TM];

  const isNeedSms =
    type &&
    walletsWithSms.includes(type) &&
    (parser_type === `mobile_api` || state?.includes(`pending_sms_confirmation`)) &&
    sms_source === `manual_input`;

  return (
    <div className="table-responsive" style={{ overflow: `visible` }}>
      <table className="table table-striped table-borderless table-vcenter wallet-item__table">
        <tbody>{fields}</tbody>
      </table>
      <div className="modal__control-button">
        {commentPermission && (
          <button
            className="btn btn-alt-warning"
            onClick={() => {
              dispatch(walletActionCreators.hideModal());
              dispatch(actionCreators.showModal({ type: ModalType.COMMENT, comment, id }));
            }}
          >
            {t(`wallets.updateComment`)}
          </button>
        )}
        {transactionAccess && (
          <Link
            to={`/transactions?wallet_id=${id}`}
            onClick={() => dispatch(executeWidgetActionMethod(widget as string, `hideModal`))}
            className="btn btn-alt-primary"
          >
            {<span>{t(`wallets.transactionList`)}</span>}
          </Link>
        )}
        {setActiveAccess && is_active === false && (
          <button className="btn btn-alt-success" onClick={() => dispatch(markWalletAsActive(widget as string)(id))}>
            {t(`wallets.markWalletAsWorking`)}
          </button>
        )}
        {isNeedSms ? (
          <button
            className="btn btn-primary"
            onClick={() => {
              dispatch(walletActionCreators.hideModal());
              dispatch(
                paytmActionCreators.showModal({
                  type: ModalType.SMS_CONFIRM,
                  identifier,
                  wallet_type: type,
                })
              );
            }}
          >
            {t(`wallets.enterSms`)}
          </button>
        ) : null}
        {!is_active && cookie_type && (
          <button
            className="btn btn-alt-danger"
            onClick={() => {
              dispatch(disabledWalletActionCreators.hideModal());
              dispatch(paytmActionCreators.showModal({ type: ModalType.RESET_WALLET_DATA, identifier }));
            }}
          >
            {t(`wallets.resetData`)}
          </button>
        )}
      </div>
    </div>
  );
};

interface IWalletModalProps {
  widget: string;
  actionCreators: IWidgetActionCreators;
}

const WalletModal = ({ actionCreators, widget }: IWalletModalProps) => {
  const dispatch = useDispatch();

  const item: any = useSelector(widgetItemSelector(widget));
  const modal = useSelector(widgetModalSelector(widget));
  const [modalId] = useState(get(modal, `id`));

  // const walletType = item && item?.type;

  // useEffect(() => {
  //   if (walletType === WalletType.PAY_TM) {
  //     dispatch(loadPaytmFlag());
  //   }
  // }, [walletType]);

  useMount(() => {
    dispatch(showWallet(widget)({ id: modalId }));
  });

  const content = isObject(item) ? <WalletItem {...item} actionCreators={actionCreators} widget={widget} /> : null;

  return (
    <ModalElement title="wallets.wallet" content={content} actionCreators={actionCreators} loading={isNull(item)} />
  );
};

export default WalletModal;
