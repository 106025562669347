import { ISelectData } from '@kassma-team/kassma-toolkit/lib/types/form';

import { PaymentTranslationFieldType, PaymentTranslationFormType } from 'utils/enums';

export const paymentTranslationFormTextCode = {
  [PaymentTranslationFormType.PAYMENT]: `paymentConstructor.paymentConfirmation`,
  [PaymentTranslationFormType.REQUISITES]: `paymentConstructor.paymentMaking`,
};

export const paymentTranslationFieldTextCode = {
  [PaymentTranslationFieldType.HINT]: `paymentConstructor.hint`,
  [PaymentTranslationFieldType.FIELD]: `paymentConstructor.field`,
};

export const paymentTranslationFormList = [
  {
    value: PaymentTranslationFormType.REQUISITES,
    text: paymentTranslationFormTextCode[PaymentTranslationFormType.REQUISITES],
  },
  {
    value: PaymentTranslationFormType.PAYMENT,
    text: paymentTranslationFormTextCode[PaymentTranslationFormType.PAYMENT],
  },
];

export const paymentTranslationFieldList: ISelectData[] = [
  {
    value: PaymentTranslationFieldType.HINT,
    text: paymentTranslationFieldTextCode[PaymentTranslationFieldType.HINT],
  },
  {
    value: PaymentTranslationFieldType.FIELD,
    text: paymentTranslationFieldTextCode[PaymentTranslationFieldType.FIELD],
  },
];

export const payoutMethods: ISelectData[] = [
  {
    value: PaymentTranslationFormType.REQUISITES,
    text: `paymentConstructor.${PaymentTranslationFormType.REQUISITES}`,
  },
  {
    value: PaymentTranslationFormType.PAYMENT,
    text: `paymentConstructor.${PaymentTranslationFormType.PAYMENT}`,
  },
];
