import { Permission } from 'utils/enums';
import includes from 'lodash/includes';

const transactionRouteTabs = (permissions: string[]) => {
  return [
    {
      path: `/transactions`,
      title: `transactions.transactions`,
      hasAccess: includes(permissions, Permission.TRANSACTION_LIST),
    },
    {
      title: `transactions.transactions`,
      path: `/transactions-only-by-id`,
      hasAccess:
        includes(permissions, Permission.TRANSACTION_ONLY_BY_ID) && !includes(permissions, Permission.TRANSACTION_LIST),
    },
    {
      title: `transactions.possibleTransactions`,
      path: `/possible-transactions`,
      hasAccess: includes(permissions, Permission.TRANSACTION_MANAGEMENT),
    },
    {
      title: `transactions.archiveTransactions`,
      path: `/archive-transactions`,
      hasAccess: includes(permissions, Permission.ARCHIVE_TRANSACTION_VIEW),
    },
  ];
};

export default transactionRouteTabs;
