import { AxiosPromise } from 'axios';
import { ParsedQuery } from 'query-string';

import { BaseApi } from 'api/common/BaseApi';
import {
  ICreateWalletForm,
  ILoadWalletForm,
  ITransactionSearch,
  IUpdateWalletCommentData,
} from 'interfaces/widgets/wallets/wallet';
import { ISetWalletSmsCodeData } from 'interfaces/widgets/paymentSystems';
import { IProxy } from 'interfaces/widgets/proxies';

interface IData {
  ids: number[];
  active: boolean;
}

export class WalletApi extends BaseApi {
  forcedDeleteWallet = (id: number): AxiosPromise => this.makeFetch(`${id}`, { method: `DELETE` });
  createWallet = (data: ICreateWalletForm): AxiosPromise => this.makeFetch(`create`, { data, method: `POST` });

  deleteDeactivatedWallet = (id: number): AxiosPromise => this.makeFetch(`remove/{id}`, { method: `DELETE` }, { id });

  deleteNotifyOfPaytmWalletStatus = (id: number): AxiosPromise =>
    this.makeFetch(`paytm-with-modified-parsing-type/{id}`, { method: `DELETE` }, { id });

  deleteWallet = (hash_id: string): AxiosPromise => this.makeFetch(`disable/{hash_id}`, { method: `PUT` }, { hash_id });

  loadAdminStatuses = (type: string): AxiosPromise => this.makeFetch(`admin-status`, { queryParams: { type } });

  loadDisabledWalletList = (queryParams: Record<string, unknown>): AxiosPromise =>
    this.makeFetch(`list`, {
      queryParams: {
        is_active: false,
        removed: false,
        ...queryParams,
      },
    });

  loadNotRemovedWalletList = (): AxiosPromise => this.makeFetch(`list`, { queryParams: { removed: false } });

  loadWalletItem = (id: number): AxiosPromise => this.makeFetch(`{id}`, {}, { id });

  loadRemovedWalletList = (queryParams: ParsedQuery): AxiosPromise =>
    this.makeFetch(`list`, {
      queryParams: {
        removed: true,
        ...queryParams,
      },
    });

  loadWalletItemForShow = (id: number): AxiosPromise => this.makeFetch(`{id}`, {}, { id });

  loadList = (params) =>
    this.makeFetch(`list`, {
      queryParams: params,
    });

  loadWalletList = (queryParams: Record<string, unknown>): AxiosPromise => {
    const { withoutQuery } = queryParams || {};
    let params = {};
    if (!withoutQuery) {
      params = {
        is_active: true,
        removed: false,
        ...queryParams,
      };
    }

    return this.makeFetch(`list`, {
      queryParams: params,
    });
  };

  loadWalletsLoad = (queryParams: ILoadWalletForm): AxiosPromise => this.makeFetch(`load`, { queryParams });

  resetPaytmWallet = (wallet_identifier: number): AxiosPromise =>
    this.makeFetch(`set-default-sms-cookie-flag`, { method: `POST`, data: { wallet_identifier } });

  restoreWallet = ({ id, parsing_start_at }: { id: number; parsing_start_at: string }): AxiosPromise =>
    this.makeFetch(`restore/{id}`, { method: `POST`, data: { parsing_start_at } }, { id });

  setSmsCode = (data: ISetWalletSmsCodeData): AxiosPromise => this.makeFetch(`set-sms-code`, { method: `POST`, data });

  setWalletActiveRequest = (id: number): AxiosPromise => this.makeFetch(`set-active/{id}`, { method: `POST` }, { id });

  toggleVisibilityOfAllWallets = (data: IData): AxiosPromise =>
    this.makeFetch(`updated-plugin-status`, { method: `POST`, data });

  transactionSearch = (id: number, data: Omit<ITransactionSearch, `id`>): AxiosPromise =>
    this.makeFetch(`scrape/{id}`, { data, method: `POST` }, { id });

  updateWallet = (id: number, data: IProxy): AxiosPromise =>
    this.makeFetch(`update/{id}`, { data, method: `POST` }, { id });

  updateWalletSettings = (id: number, data: Omit<IUpdateWalletCommentData, `id`>): AxiosPromise =>
    this.makeFetch(`settings/{id}`, { data, method: `POST` }, { id });

  loadWalletsBalances = (): AxiosPromise => this.makeFetch(`balances`);

  deletePaytmWalletData = (id: number): AxiosPromise =>
    this.makeFetch(`delete-archive-paytm-cookies/{id}`, { method: `POST` }, { id });

  // loadPaymentSystemsForForcedTransactions = (): AxiosPromise => this.makeFetch(`types-for-forced-transactions`);
}

const walletApi = new WalletApi(`wallet`);

export default walletApi;
