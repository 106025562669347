import React from 'react';

import { WidgetType } from 'utils/enums';
import { walletActionCreators } from 'actions/widgets/wallet';

import RemovingModal from 'components/modals/RemovingModal';

const ActiveWalletRemoving = () => {
  return (
    <RemovingModal
      widget={WidgetType.WALLETS}
      actionCreators={walletActionCreators}
      title="wallets.walletDisabling"
      content="wallets.doYouReallyWantToDisableThisWallet"
      confirmText="common.disable"
    />
  );
};

export default ActiveWalletRemoving;
