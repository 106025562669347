import React from 'react';

import { WidgetType } from 'utils/enums';
import { walletActionCreators } from 'actions/widgets/wallet';

import WalletComment from 'components/widgets/wallets/WalletComment';

const ActiveWalletComment = () => {
  return <WalletComment widget={WidgetType.WALLETS} actionCreators={walletActionCreators} />;
};

export default ActiveWalletComment;
