import { Reducer } from 'redux';
import map from 'lodash/map';
import get from 'lodash/get';
import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';
import { IWidgetsState } from '@kassma-team/kassma-toolkit/lib/types/widgets';

import {
  ADD_MULTI_ADDING_PROXIES_SUCCEEDED,
  CHECK_MULTI_ADDING_PROXY_ITEM_SUCCEEDED,
  CHECK_MULTI_ADDING_PROXY_LIST_SUCCEEDED,
  DELETE_MULTI_ADDING_PROXY,
  INIT_CHECKING_MULTI_ADDING_PROXY_ITEM,
  INIT_CHECKING_MULTI_ADDING_PROXY_LIST,
  PROXY_CHECK_ALL_FAILED,
  PROXY_CHECK_ALL_SET_LOADER,
  PROXY_CHECK_ALL_SUCCEEDED,
  PROXY_CHECK_FAILED,
  PROXY_CHECK_REQUESTED,
  PROXY_CHECK_SUCCEEDED,
  RESET_MULTI_ADDING_PROXIES_DATA,
  RESET_UPLOADING_PROXIES_DATA,
  SET_INVALID_MULTI_ADDING_PROXY_ROWS,
  SET_VERIFIED_MULTI_ADDING_PROXIES,
  UPLOAD_PROXIES_REQUESTED,
  UPLOAD_PROXIES_SUCCEEDED,
} from 'actionTypes';
import { ProxyStatus } from 'utils/enums';
import { IProxiesWidget } from 'interfaces/widgets/proxies';

const actionTypes = new Set([
  PROXY_CHECK_SUCCEEDED,
  PROXY_CHECK_FAILED,
  PROXY_CHECK_REQUESTED,
  PROXY_CHECK_ALL_SET_LOADER,
  PROXY_CHECK_ALL_FAILED,
  PROXY_CHECK_ALL_SUCCEEDED,
  INIT_CHECKING_MULTI_ADDING_PROXY_LIST,
  CHECK_MULTI_ADDING_PROXY_LIST_SUCCEEDED,
  SET_INVALID_MULTI_ADDING_PROXY_ROWS,
  SET_VERIFIED_MULTI_ADDING_PROXIES,
  CHECK_MULTI_ADDING_PROXY_ITEM_SUCCEEDED,
  INIT_CHECKING_MULTI_ADDING_PROXY_ITEM,
  DELETE_MULTI_ADDING_PROXY,
  ADD_MULTI_ADDING_PROXIES_SUCCEEDED,
  RESET_MULTI_ADDING_PROXIES_DATA,
  UPLOAD_PROXIES_REQUESTED,
  UPLOAD_PROXIES_SUCCEEDED,
  RESET_UPLOADING_PROXIES_DATA,
]);

interface IStatus {
  state: IWidgetsState;
  status: number;
  uuid: string;
}

const setStatus = ({ state, status, uuid }: IStatus) => ({
  ...state,
  items: map(state.items, (item) => (item.uuid === uuid ? { ...item, status } : item)),
});

const reducer: Reducer = (state: IProxiesWidget, { type, payload }) => {
  switch (type) {
    case PROXY_CHECK_REQUESTED:
      return setStatus({ state, uuid: payload, status: ProxyStatus.CHECKING });
    case PROXY_CHECK_SUCCEEDED:
      return setStatus({ state, uuid: payload, status: ProxyStatus.SUCCESS });
    case PROXY_CHECK_FAILED:
      return setStatus({ state, uuid: payload, status: ProxyStatus.ERROR });
    case PROXY_CHECK_ALL_SET_LOADER:
      return { ...state, checkingAll: true };
    case PROXY_CHECK_ALL_FAILED:
      return { ...state, checkingAll: false };
    case PROXY_CHECK_ALL_SUCCEEDED:
      return { ...state, items: payload, checkingAll: false };
    case INIT_CHECKING_MULTI_ADDING_PROXY_LIST:
      return {
        ...state,
        verifiedAddingProxies: 0,
        addingProxies: null,
        verifyingAddingProxies: true,
        invalidAddingProxies: null,
        checkingAddingProxy: false,
      };
    case CHECK_MULTI_ADDING_PROXY_LIST_SUCCEEDED:
      return { ...state, addingProxies: payload, verifyingAddingProxies: false };
    case SET_INVALID_MULTI_ADDING_PROXY_ROWS:
      return { ...state, invalidAddingProxies: payload };
    case SET_VERIFIED_MULTI_ADDING_PROXIES:
      return { ...state, verifiedAddingProxies: payload };
    case INIT_CHECKING_MULTI_ADDING_PROXY_ITEM:
      return {
        ...state,
        addingProxies: map(state.addingProxies, (proxy) => {
          if (get(proxy, `uuid`) === get(payload, `uuid`)) {
            return { ...proxy, status: ProxyStatus.CHECKING };
          }

          return proxy;
        }),
        checkingAddingProxy: true,
      };
    case CHECK_MULTI_ADDING_PROXY_ITEM_SUCCEEDED:
      return {
        ...state,
        addingProxies: map(state.addingProxies, (proxy) => {
          if (get(proxy, `uuid`) === get(payload, `uuid`)) {
            return { ...proxy, ...payload };
          }

          return proxy;
        }),
        checkingAddingProxy: false,
      };
    case DELETE_MULTI_ADDING_PROXY:
      return {
        ...state,
        addingProxies: filter(state.addingProxies, (proxy) => get(proxy, `uuid`) !== payload),
      };
    case ADD_MULTI_ADDING_PROXIES_SUCCEEDED:
      return {
        ...state,
        items: uniqBy([...(payload || []), ...(state.items || [])], `uuid`),
      };
    case RESET_MULTI_ADDING_PROXIES_DATA:
      return {
        ...state,
        addingProxies: null,
        checkingAddingProxy: false,
        verifiedAddingProxies: 0,
        invalidAddingProxies: null,
        verifyingAddingProxies: false,
      };
    case UPLOAD_PROXIES_REQUESTED:
      return {
        ...state,
        invalidFormatOfProxiesRelations: null,
        invalidDataOfProxiesRelations: null,
      };
    case UPLOAD_PROXIES_SUCCEEDED:
      return {
        ...state,
        ...(payload || {}),
      };
    case RESET_UPLOADING_PROXIES_DATA:
      return {
        ...state,
        invalidFormatOfProxiesRelations: null,
        invalidDataOfProxiesRelations: null,
      };
    default:
      return state;
  }
};

const proxy = { actionTypes, reducer };

export default proxy;
