import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import {
  IAddingProxies,
  IInitCheckingProxiesData,
  IProxiesMultiAddingData,
  IProxyFormData,
  IUploadProxies,
} from 'interfaces/widgets/proxies';

export class ProxyApi extends BaseApi {
  addProxies = (data: IProxiesMultiAddingData): AxiosPromise =>
    this.makeFetch(`multi-adding`, { data, method: `POST` });

  checkAllMultiAddingProxyStatuses = (): AxiosPromise => this.makeFetch(`get-checking-all-statuses`);

  checkMultiAddingProxyStatus = (): AxiosPromise => this.makeFetch(`get-checking-status`);

  checkProxy = (id: number): AxiosPromise => this.makeFetch(`check/{id}`, { method: `POST` }, { id });

  deleteProxy = (proxies: string[]): AxiosPromise => this.makeFetch(``, { method: `DELETE`, data: { proxies } });

  downloadProxies = (): AxiosPromise => this.makeFetch(`wallets-relations`);

  uploadProxies = (data: IUploadProxies): AxiosPromise => this.makeFetch(`wallets-relations`, { data, method: `POST` });

  getChecked = (): AxiosPromise => this.makeFetch(`getchecked`);

  initCheckAll = (): AxiosPromise => this.makeFetch(`initcheckall`);

  initCheckingMultiAddingProxies = (data: IInitCheckingProxiesData[]): AxiosPromise =>
    this.makeFetch(`init-checking-all`, { data, method: `POST` });

  initCheckingMultiAddingProxy = (data: IAddingProxies): AxiosPromise =>
    this.makeFetch(`init-checking`, { data, method: `POST` });

  loadProxyItem = (uuid: string): AxiosPromise => this.makeFetch(`{uuid}`, {}, { uuid });

  loadProxyList = (): AxiosPromise => this.makeFetch(`list`);

  saveProxy = (data: IProxyFormData): AxiosPromise => this.makeFetch(`create`, { data, method: `POST` });

  updateProxy = (uuid: string, data: IProxyFormData): AxiosPromise =>
    this.makeFetch(`update/{uuid}`, { data, method: `POST` }, { uuid });
}

const proxyApi = new ProxyApi(`proxy`);

export default proxyApi;
