import React from 'react';
import { getSubmitHandler, GridType } from '@kassma-team/kassma-toolkit/lib';
import { reduxForm } from 'redux-form';

import useUserHasAccess from 'hooks/useUserHasAccess';
import { FilterWalletStatus, ModalType, WidgetType } from 'utils/enums';
import { disabledWalletActionCreators } from 'actions/widgets/wallet';
import walletRouteTabs from 'pages/wallets/walletRouteTabs';
import useWalletsExtraButtons from 'hooks/widgets/wallets/useWalletsExtraButtons';
import useDisabledWalletTableTitles from 'hooks/widgets/tableTitles/wallets/useDisabledWalletTableTitles';
import { IWalletRowProps } from 'interfaces/widgets/wallets/wallet';
import useWalletFilterFields from 'hooks/widgets/tableTitles/wallets/useWalletFilterFields';
import { IFilterProps } from 'interfaces/widgets/widgets';

import WalletRow from 'components/widgets/wallets/WalletRow';
import DisabledWalletRemoving from 'components/widgets/wallets/disabled/DisabledWalletRemoving';
import DisabledWalletComment from 'components/widgets/wallets/disabled/DisabledWalletComment';
import DisabledWalletUpdating from 'components/widgets/wallets/disabled/DisabledWalletUpdating';
import DisabledWalletModal from 'components/widgets/wallets/disabled/DisabledWalletModal';
import Widget from 'components/widgets/Widget';
import Filters from 'components/widgets/filters/Filters';

import 'pages/wallets/wallets.scss';

const FORM_NAME = `disabled-wallets-filters`;

const FilterForm = reduxForm<Record<string, unknown>, IFilterProps>({
  form: FORM_NAME,
  onSubmit: getSubmitHandler({}),
})(Filters);

const listMapping = (item: IWalletRowProps) => (
  <WalletRow key={item.id} {...item} widget={WidgetType.DISABLED_WALLETS} />
);

const modals = [
  {
    type: ModalType.UPDATE,
    component: DisabledWalletUpdating,
    widget: WidgetType.DISABLED_WALLETS,
  },
  {
    type: ModalType.REMOVE_CONFIRMATION,
    component: DisabledWalletRemoving,
    widget: WidgetType.DISABLED_WALLETS,
  },
  {
    type: ModalType.SHOW,
    component: DisabledWalletModal,
    widget: WidgetType.DISABLED_WALLETS,
  },
  {
    type: ModalType.COMMENT,
    widget: WidgetType.DISABLED_WALLETS,
    component: DisabledWalletComment,
  },
];

const DisabledWallets = () => {
  useUserHasAccess();

  const extraButtons = useWalletsExtraButtons();

  const titles = useDisabledWalletTableTitles();

  const leftFilterFields = useWalletFilterFields(FORM_NAME, FilterWalletStatus.DISABLED);

  return (
    <Widget
      widget={WidgetType.DISABLED_WALLETS}
      routeTabs={walletRouteTabs}
      actionCreators={disabledWalletActionCreators}
      pageTitle="wallets.wallets"
      tableTitle="wallets.disabledWallets"
      headTitle="wallets.disabledWallets"
      tableHeadTitles={titles}
      listMapping={listMapping}
      modals={modals}
      filterFormName={FORM_NAME}
      filterForm={
        <FilterForm leftFields={leftFilterFields} formName={FORM_NAME} gridType={GridType.GRID} numFields={[`id`]} />
      }
      statusBar
      extraButtons={extraButtons}
      withPagination
    />
  );
};

export default DisabledWallets;
