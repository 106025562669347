import React from 'react';

import { WidgetType } from 'utils/enums';
import { logActionCreators } from 'actions/widgets/walletsDebugging/logs';

import CommonLogMessage from 'components/widgets/walletsDebugging/LogMessage';

const LogMessage = () => {
  return <CommonLogMessage widget={WidgetType.LOGS} actionCreators={logActionCreators} />;
};

export default LogMessage;
