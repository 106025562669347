import React, { useEffect, createContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { PageContainer, WidgetContainer, getErrorMessage } from '@kassma-team/kassma-toolkit/lib';
import { toastr } from 'react-redux-toastr';

import constructorRouteTabs from 'pages/constructor/constructorRouteTabs';
import {
  ICommissions,
  ITariffCurrencies,
  ITariffsSchemas,
  ITariffsWalletTypes,
} from 'interfaces/widgets/constructor/tariffConstructor';

import walletTypeApi from 'api/walletType/WalletTypeApi';
import tariffApi from 'api/constructors/TariffApi';
import currencyApi from 'api/currencies/CurrencyApi';

import Header from 'components/header/Header';
import RouteTabs from 'components/widgets/RouteTabs';
import PaymentSystemColumn from 'components/widgets/tariffs/tariffsConstructor/PaymentSystemColumn';
import CurrencyColumn from 'components/widgets/tariffs/tariffsConstructor/CurrencyColumn';
import TariffSettings from 'components/widgets/tariffs/tariffsConstructor/TariffSettings';

import './tariffConstructor.scss';
import refreshHelper from '../../utils/refreshHelper';
import api from 'api';

export interface ITariffContext {
  paymentSystemsList?: ITariffsWalletTypes[];
  currenciesList?: ITariffCurrencies[];
  commissionId?: number;
  getCommissionsList: (id: number) => void;
}

export const TariffContext = createContext<ITariffContext>({
  getCommissionsList: () => {
    return;
  },
});

const Tariffs = (): JSX.Element => {
  const [t] = useTranslation();
  const [psListState, setPsListState] = useState<ITariffsWalletTypes[]>([]);
  const [schemasState, setSchemasState] = useState<ITariffsSchemas[]>([]);
  const [currenciesListState, setCurrenciesListState] = useState<ITariffCurrencies[]>([]);
  const [paymentSystemId, setPaymentSystemId] = useState<string>(``);
  const [currenciesState, setCurrenciesState] = useState<ITariffCurrencies[]>([]);
  const [idsSchemasByWalletType, setIdsSchemasByWalletType] = useState<ITariffsSchemas[]>([]);
  const [commissionIdState, setCommissionIdState] = useState<number | undefined>();
  const [commissionsDataState, setCommissionsDataState] = useState<ICommissions[]>([]);

  const getPaymentSystemsList = () =>
    refreshHelper({
      // request: walletTypeApi.loadWalletTypes,
      request: api.pluralWalletTypesApi.list,
      onSuccess: (resp: any) => {
        if (resp?.data?.code === 20000) {
          setPsListState(resp?.data?.data);
        }
      },
    });

  const getTariffsSchemas = () =>
    refreshHelper({
      request: tariffApi.loadTariffsSchemas,
      onSuccess: (resp: any) => {
        if (resp?.data?.status === `ok`) {
          setSchemasState(resp?.data?.items);
          setPaymentSystemId(resp?.data?.items[0].wallet_type);
        }
      },
    });

  const getCurrencyList = () =>
    refreshHelper({
      request: currencyApi.loadCurrencyList,
      onSuccess: (resp: any) => {
        if (resp?.data?.status === `ok`) {
          setCurrenciesListState(resp?.data?.currencies);
        }
      },
    });

  const getCommissionsList = (commissionIdState: number) =>
    refreshHelper({
      request: () => tariffApi.loadTariffCommission(commissionIdState),
      onSuccess: (resp: any) => {
        if (resp?.data?.status === `ok`) {
          setCommissionsDataState(resp?.data?.item?.commissions);
        }
      },
    });

  useEffect(() => {
    getPaymentSystemsList();
    getTariffsSchemas();
    getCurrencyList();
  }, []);

  useEffect(() => {
    if (commissionIdState) {
      getCommissionsList(commissionIdState);
    }
  }, [commissionIdState]);

  useEffect(() => {
    const targetIdsSchemas = schemasState?.filter((item) => item?.wallet_type?.toString() === paymentSystemId);
    const targetIds = targetIdsSchemas?.map(({ currency }) => currency);
    const targetCurrencies = currenciesListState?.filter(({ code }) => targetIds?.includes(code));

    targetIdsSchemas && setIdsSchemasByWalletType(targetIdsSchemas);
    targetCurrencies && setCurrenciesState(targetCurrencies);
  }, [paymentSystemId, schemasState, currenciesListState]);

  return (
    <PageContainer>
      <Helmet>
        <title>{t(`paymentConstructor.tariffs`)}</title>
      </Helmet>
      <Header />
      <WidgetContainer title="paymentConstructor.constructor">
        <div className="block">
          <RouteTabs routeTabs={constructorRouteTabs} />
          <div className="block-header block-header-default">
            <h3 className="table-content__title">{t(`paymentConstructor.tariffs`)}</h3>
          </div>
          <div className="row block-content w-100">
            <TariffContext.Provider
              value={{
                paymentSystemsList: psListState,
                currenciesList: currenciesListState,
                commissionId: commissionIdState,
                getCommissionsList: getCommissionsList,
              }}
            >
              <div className="tariff_row">
                <div className="tariff_row__payment_system_block">
                  <p className="tariff_row__column_title">{t(`paymentConstructor.paymentSystem`)}</p>
                  <PaymentSystemColumn schemasList={schemasState} onClickCallback={setPaymentSystemId} />
                </div>
                <div className="tariff_row__currency_block">
                  <p className="tariff_row__column_title">{t(`paymentConstructor.currency`)}</p>
                  <CurrencyColumn
                    onClickCallback={setCommissionIdState}
                    data={currenciesState}
                    schemas={idsSchemasByWalletType}
                  />
                </div>
                <div className="tariff_row__form_block">
                  <p className="tariff_row__column_title">{t(`paymentConstructor.tariffPlanSettings`)}</p>
                  <TariffSettings data={commissionsDataState} />
                </div>
              </div>
            </TariffContext.Provider>
          </div>
        </div>
      </WidgetContainer>
    </PageContainer>
  );
};

export default Tariffs;
