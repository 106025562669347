import React from 'react';
import map from 'lodash/map';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ITabListProps } from 'interfaces/dashboard';

const TabList = ({ list, onChange, activeTab, className }: ITabListProps) => {
  const [t] = useTranslation();

  return (
    <nav>
      <div className={classnames(className, `nav nav-tabs`)}>
        {map(list, ({ id, title }) => (
          <button
            className={classnames(`nav-item nav-link`, { active: activeTab === id })}
            onClick={() => onChange(id)}
            key={id}
          >
            {t(title)}
          </button>
        ))}
      </div>
    </nav>
  );
};

export default TabList;
