import { toastr } from 'react-redux-toastr';
import { ToastrEmitter } from 'react-redux-toastr';
import get from 'lodash/get';
import { AxiosError } from 'axios';
import { IRefreshSagaParams } from '@kassma-team/kassma-toolkit';

import i18n from 'i18n';
import { getErrorMessage } from 'utils';
import api from 'api';
import { TOKEN_REFRESHING_FAILED_MESSAGE } from '../sagas/effects/refreshSaga';
import { call } from 'redux-saga/effects';

let refreshPromise: any = null;

function resetUser(e: any) {
  const message = e.message;
  const status = get(e, `response.status`);
  if (message === TOKEN_REFRESHING_FAILED_MESSAGE || [401, 500].includes(status)) {
    localStorage.removeItem(`token`);
    localStorage.removeItem(`refresh_token`);
    window.location.href = `/login`;
  }
}

export interface IGetRefreshHelperParams {
  refreshToken: any;
  toastr: ToastrEmitter;
}
export const getRefreshHelper = ({ refreshToken, toastr }: IGetRefreshHelperParams) =>
  async function refreshHelper({ request, onError, onSuccess, onFinally }: IRefreshSagaParams) {
    if (!onError) {
      onError = (err: any) => {
        toastr.error(i18n.t(`common.error`), getErrorMessage(err as AxiosError) as string);
      };
    }

    const refresh_token = localStorage.getItem(`refresh_token`) || ``;

    let resp;
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resp = await request();
      if (resp?.data?.code && resp?.data?.code !== 50003 && onSuccess) {
        await onSuccess(resp);
      } else if (!resp?.data?.code && resp?.status !== 401 && onSuccess) {
        await onSuccess(resp);
      }
    } catch (e) {
      const status = get(e, `response.status`);
      if ((status !== 401 || resp?.data?.code !== 50003) && onError) {
        await onError(e);
      }
    } finally {
      if (onFinally) {
        await onFinally();
      }
    }
    if (resp && (resp?.status === 401 || resp?.data?.code === 50003) && !!refresh_token) {
      if (!refreshPromise) {
        try {
          const promise = refreshToken({ refresh_token });
          refreshPromise = promise;
          const { data, status } = await promise;
          refreshPromise = null;
          // @ts-ignore
          // eslint-disable-next-line no-inner-declarations
          async function setToken(token: string, refresh_token: string) {
            localStorage.setItem(`refresh_token`, refresh_token);
            localStorage.setItem(`token`, token);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const resp = await request();
            if (onSuccess) {
              await onSuccess(resp);
            }
          }

          if (status !== 401 || data?.code !== 50003) {
            await setToken(data.data.token, refresh_token);
            // throw new Error(TOKEN_REFRESHING_FAILED_MESSAGE);
          } else if (status === 500) {
            throw new Error(TOKEN_REFRESHING_FAILED_MESSAGE);
          } else {
            await setToken(data.data.token, data.data.refresh_token);
          }
        } catch (e) {
          if (onError) {
            await onError(e);
          }
          resetUser(e);
        } finally {
          if (onFinally) {
            await onFinally();
          }
        }
      } else {
        try {
          await refreshPromise;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const resp = await request();
          refreshPromise = null;
          if (onSuccess) {
            await onSuccess(resp);
          }
        } catch (e) {
          if (onError) {
            await onError(e);
          }
          await resetUser(e);
        } finally {
          if (onFinally) {
            await call(onFinally);
          }
        }
      }
    } else if (resp && (resp?.status === 401 || resp?.data?.code === 50003)) {
      const e = new Error(TOKEN_REFRESHING_FAILED_MESSAGE);
      if (onError) {
        await onError(e);
      }
      resetUser(e);
    }
  };

const refreshHelper: any = getRefreshHelper({
  refreshToken: api.auth.refreshToken,
  toastr,
});

export default refreshHelper;
