export const walletColor = [
  `#3182bd`,
  `#001f3f`,
  `#7FDBFF`,
  `#39CCCC`,
  `#3D9970`,
  `#2ECC40`,
  `#01FF70`,
  `#FFDC00`,
  `#FF851B`,
  `#FF4136`,
  `#85144b`,
  `#F012BE`,
  `#B10DC9`,
  `#111111`,
  `#0074D9`,
  `#AAAAAA`,
  `#91a0ff`,
  `#c787ff`,
  `#a56192`,
  `#ffe597`,
  `#ffac97`,
  `#97ffce`,
  `#0029ff`,
  `#8dd3c7`,
  `#bebada`,
  `#fb8072`,
  `#66c2a5`,
  `#3182bd`,
  `#001f3f`,
  `#0074D9`,
  `#7FDBFF`,
  `#39CCCC`,
  `#3D9970`,
  `#2ECC40`,
  `#01FF70`,
  `#FFDC00`,
  `#FF851B`,
  `#FF4136`,
  `#85144b`,
  `#F012BE`,
  `#B10DC9`,
  `#111111`,
  `#AAAAAA`,
  `#91a0ff`,
  `#c787ff`,
  `#a56192`,
  `#ffe597`,
  `#ffac97`,
  `#97ffce`,
  `#0029ff`,
  `#8dd3c7`,
  `#bebada`,
  `#fb8072`,
  `#66c2a5`,
];
