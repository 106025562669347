import { AxiosPromise } from 'axios';

import { BaseApi } from 'api/common/BaseApi';
import { IStockpilingFiltersFrom } from 'interfaces/widgets/stockpiling';
import { omit } from 'lodash';

export class StockpilingApi extends BaseApi {
  loadStockpilingList = (queryParams: IStockpilingFiltersFrom): AxiosPromise =>
    this.makeFetch(
      `list`,
      queryParams.is_real === `all` ? { queryParams: omit(queryParams, `is_real`) } : { queryParams }
    );

  resendPostback = (id: number): AxiosPromise => this.makeFetch(`{id}/force-send`, { method: `POST` }, { id });
}

const stockpilingApi = new StockpilingApi(`stockpiling`);

export default stockpilingApi;
