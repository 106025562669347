import { Reducer } from 'redux';
import map from 'lodash/map';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { IWidgetsState } from '@kassma-team/kassma-toolkit/lib/types/widgets';

import {
  MARK_WALLET_AS_ACTIVE_SUCCEEDED,
  TOGGLE_VISIBILITY_OF_ALL_WALLETS_SUCCEEDED,
  TOGGLE_WALLET_PLUGIN_STATUS,
  TOGGLE_WALLET_STANDARD_STATUS,
  UPDATE_WALLET_COMMENT_SUCCEEDED,
  WALLET_SHOW_REQUESTED,
  WALLET_SHOW_SUCCEEDED,
} from 'actionTypes';
import { WalletStatus } from 'utils/enums';

const actionTypes = new Set([
  MARK_WALLET_AS_ACTIVE_SUCCEEDED,
  TOGGLE_WALLET_PLUGIN_STATUS,
  TOGGLE_WALLET_STANDARD_STATUS,
  TOGGLE_VISIBILITY_OF_ALL_WALLETS_SUCCEEDED,
  UPDATE_WALLET_COMMENT_SUCCEEDED,
  WALLET_SHOW_REQUESTED,
  WALLET_SHOW_SUCCEEDED,
]);

const reducer: Reducer = (state: IWidgetsState, { type, payload }) => {
  switch (type) {
    case MARK_WALLET_AS_ACTIVE_SUCCEEDED:
      return {
        ...state,
        items: map(state.items, (item) => (item.id === payload ? { ...item, status: WalletStatus.OK } : item)),
      };
    case TOGGLE_WALLET_PLUGIN_STATUS:
      return {
        ...state,
        items: map(state.items, (item) => {
          return item.id === payload.id ? { ...item, plugin_status: payload.plugin_status } : item;
        }),
      };
    case UPDATE_WALLET_COMMENT_SUCCEEDED:
      return {
        ...state,
        items: map(state.items, (item) => {
          return item.id === payload.id ? { ...item, comment: get(payload, `comment`) } : item;
        }),
      };
    case TOGGLE_WALLET_STANDARD_STATUS:
      return {
        ...state,
        items: map(state.items, (item) => {
          return item.id === payload.id ? { ...item, is_standard: payload.is_standard } : item;
        }),
      };
    case TOGGLE_VISIBILITY_OF_ALL_WALLETS_SUCCEEDED:
      return {
        ...state,
        items: map(state.items, (item) => {
          if (includes(payload.changedIds, item.id)) {
            return {
              ...item,
              plugin_status: payload.active,
            };
          }

          return item;
        }),
      };
    case WALLET_SHOW_REQUESTED:
      return {
        ...state,
        item: null,
        itemLoading: true,
      };
    case WALLET_SHOW_SUCCEEDED:
      return {
        ...state,
        item: payload,
        itemLoading: false,
      };
    default:
      return state;
  }
};

const wallet = { actionTypes, reducer };

export default wallet;
