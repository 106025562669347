import { useCallback, useState } from 'react';
import { getFormValues, stopSubmit } from 'redux-form';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import getFormData from 'utils/widgets/wallets/getFormData';
import { useDispatch, useSelector } from 'react-redux';
import { formUnusedSelector, widgetModalSelector, IWidgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { ModalType, ParserType, WalletConfirmationInfoType, WalletStatus, WalletType } from 'utils/enums';
import { disabledWalletActionCreators, walletActionCreators } from 'actions/widgets/wallet';
import { paytmActionCreators } from 'actions/widgets/payments/paytm';
import { IConfirmationInfoType } from 'interfaces/widgets/wallets/wallet';
import useSearchParams from '../../useSearchParams';
import omit from 'utils/omit';
import moment from 'moment';
import he from 'he';

interface IWalletSubmitHandlerProps {
  form: string;
  type: string;
  widget: string;
  actionCreators: IWidgetActionCreators;
  errors?: any;
}

interface IData {
  wallets_with_the_same_login: string[];
  status: string;
}

interface IResponse {
  data: IData;
}

interface IError {
  response?: IResponse;
}

const useWalletSubmitHandler = ({ form, type, widget, actionCreators, errors }: IWalletSubmitHandlerProps) => {
  const [confirmationInfoType, setConfirmationInfoType] = useState<number | null>(null);
  const [confirmationInfoData, setConfirmationInfoData] = useState<IConfirmationInfoType | null>(null);

  const values = useSelector(getFormValues(form)) || {};

  const unused: string[] = useSelector(formUnusedSelector(form)) || [];

  const dispatch = useDispatch();

  const modal = useSelector(widgetModalSelector(widget));

  const queryParams = useSearchParams();

  const requestErrorHandler = useCallback(
    (values) => (err: IError) => {
      const status = get(err, `response.status`);

      const walletsWithTheSameLogin = err?.response?.data?.wallets_with_the_same_login;
      const wallet_type = values.wallet_type === WalletType.UPI_IB;

      if (status === 409 && !isEmpty(walletsWithTheSameLogin) && values.username && wallet_type) {
        setConfirmationInfoType(WalletConfirmationInfoType.ICICI_LOGIN_CONFLICT);
        setConfirmationInfoData({
          wallets: walletsWithTheSameLogin,
          login: values.username,
        });
      }

      if (status === 409 && !isEmpty(walletsWithTheSameLogin) && values.username && !wallet_type) {
        setConfirmationInfoType(WalletConfirmationInfoType.IDB_LOGIN_CONFLICT);
        setConfirmationInfoData({
          wallets: walletsWithTheSameLogin,
          login: values.username,
        });
      }
    },
    []
  );

  const convertedIntoNumber = (value: string) => {
    const pattern = /^-?\d+$/;
    if (pattern.test(value)) {
      return parseInt(value);
    } else return parseFloat(value);
  };

  const onCreateSubmit = useCallback(
    async (values) => {
      let formData: any = await getFormData({ type: `creating`, values, unused, isCreating: true });

      if (formData?.display_identifier) {
        formData.display_identifier = he.encode(formData.display_identifier);
      }

      if (formData.wallet_type === `bkash` || formData.wallet_type === `nagad`) {
        if (!formData.is_personal_type) formData.is_personal_type = false;
      }

      if (formData.wallet_type === WalletType.UPI_L) {
        if (formData.integration_type === `a_pay`) {
          formData = omit(formData, [`parser_type`]);
        }
      }

      if (formData.wallet_type === WalletType.UPI_IB) {
        formData = omit(formData, [`site_parser`]);
      }

      if (formData?.sms_source) {
        if (formData?.sms_source === `manual_input`) {
          formData = omit(formData, [`email_username`, `email_password`, `port`]);
        }
        if (formData?.sms_source === `sms_box`) {
          formData = omit(formData, [`email_username`, `email_password`]);
        }
        if (formData?.sms_source === `email`) {
          formData = omit(formData, [`port`]);
        }

        // formData = omit(formData, [`sms_source`]);
      }

      // if (formData.wallet_type === `upi_s` && formData.project_id) {
      //   formData.project_id = convertedIntoNumber(formData.project_id);
      // }

      if (formData.wallet_type === `phone_pe` && formData.parser_type === `mobile_api`) {
        const { longitude, latitude, wifi_frequency } = formData;
        formData.longitude = convertedIntoNumber(longitude);
        formData.latitude = convertedIntoNumber(latitude);
        formData.wifi_frequency = convertedIntoNumber(wifi_frequency);
      } else {
        formData = omit<typeof formData>(formData, [`longitude`, `latitude`, `wifi_frequency`]);
      }

      const createHandler =
        values.status === WalletStatus.OK ? walletActionCreators.create : disabledWalletActionCreators.create;

      const parsingDateTime = formData.parsing_start_at;
      if (parsingDateTime) {
        formData.parsing_start_at = moment(parsingDateTime).format(`YYYY-MM-DD HH:mm:ss`);

        formData = omit(formData, [`parsing_start_time`, `parsing_start_hint`]);
      } else {
        formData = omit(formData, [`parsing_start_hint`, `parsing_start_time`]);
      }

      dispatch(
        createHandler(formData, {
          onSuccess: (data: Record<string, unknown>) => {
            const walletType = get(data, `wallet_type`);
            const identifier = get(data, `wallet_identifier`);
            dispatch(walletActionCreators.getList(queryParams));
            dispatch(walletActionCreators.hideModal());
            if (walletType === WalletType.PAY_TM && identifier) {
              dispatch(paytmActionCreators.showModal({ type: ModalType.SMS_CONFIRM, identifier }));
            }
          },
          onError: requestErrorHandler(values),
        })
      );
    },
    [requestErrorHandler, unused]
  );

  const onUpdateSubmit = useCallback(
    async (values) => {
      if (errors && Object.keys(errors).length === 0) {
        const walletId = get(modal, `id`);
        let formData = await getFormData({
          type: `updating`,
          values,
          unused: [`useProxy`, `useProxyNightEmailParser`, ...unused],
          walletId,
        });

        formData = omit(formData, [`time_range`, `site_parser`]);

        if (formData.integration_type === `a_pay` && formData.project_id) {
          formData = omit(formData, [`upi_addresses`]);
        }

        // Если есть это поле - это или Paytm или PhonePe
        // Поля возвращаются из getFormData

        const getData = (formData) => {
          if (formData.wallet_type) {
            if (formData.parser_type === ParserType.PARSER_INDUSIN) {
              formData = omit(formData, [
                `longitude`,
                `latitude`,
                `wifi_frequency`,
                `email_username`,
                `email_password`,
              ]);
            }

            if (formData?.parser_type === ParserType.PARSER_HDFC) {
              formData = omit(formData, [
                `longitude`,
                `latitude`,
                `wifi_frequency`,
                `email_username`,
                `email_password`,
              ]);
            }

            if (formData.parser_type === ParserType.PARSER_EMAIL) {
              formData = omit(formData, [`port`, `latitude`, `longitude`, `wifi_frequency`]);
            }

            if (formData.wallet_type === WalletType.PAY_TM && formData?.account_number == null) {
              formData = omit(formData, [`account_number`]);
            }

            if (
              formData.wallet_type === WalletType.PHONE_PE &&
              formData?.parser_type != ParserType.PARSER_PHONE_PE_MOBILE_API
            ) {
              formData = omit(formData, [`sms_source`]);
            }

            if (formData?.sms_source === `manual_input`) {
              formData = omit(formData, [`email_username`, `email_password`, `port`]);

              return formData;
            }

            if (formData?.sms_source === `sms_box`) {
              formData = omit(formData, [`email_username`, `email_password`]);

              return formData;
            }

            if (formData?.sms_source === `mobile_data`) {
              formData = omit(formData, [`email_username`, `email_password`]);

              return formData;
            }

            return formData;
          }

          return formData;
        };

        formData = getData(formData);

        const removeNullValues = (obj: Record<string, any>): Record<string, any> => {
          return Object.keys(obj).reduce((newObj, key) => {
            if (obj[key] !== null) {
              newObj[key] = obj[key];
            }

            return newObj;
          }, {});
        };

        if (formData.webhook_id === ``) {
          formData.webhook_id = null;
        }

        formData = removeNullValues(formData);

        const parsingDateTime = formData.parsing_start_at;
        if (parsingDateTime) {
          formData.parsing_start_at = moment(parsingDateTime).format(`YYYY-MM-DD HH:mm:ss`);

          formData = omit(formData, [`parsing_start_time`, `parsing_start_hint`]);
        } else {
          formData = omit(formData, [`parsing_start_hint`, `parsing_start_time`]);
        }

        dispatch(
          actionCreators.update(formData, {
            onError: requestErrorHandler(values),
            onSuccess: () => {
              dispatch(walletActionCreators.getList(queryParams));
            },
          })
        );
      }
    },
    [modal, unused]
  );

  const onSubmit = useCallback<(values: any) => void>( // todo change type
    (values) => {
      if (type === `creating`) {
        onCreateSubmit(values);
      } else {
        onUpdateSubmit(values);
      }
    },
    [onCreateSubmit, onUpdateSubmit]
  );

  const onForcedSubmit = useCallback<() => void>(() => {
    dispatch(stopSubmit(form, {}));
    onSubmit({ ...values, save_forced: true });
  }, [onSubmit, values]);

  const onConfirmationInfoCancel = useCallback<() => void>(() => {
    setConfirmationInfoType(null);
    setConfirmationInfoData(null);
  }, []);

  return {
    confirmationInfoType,
    confirmationInfoData,
    onForcedSubmit,
    onConfirmationInfoCancel,
    onSubmit,
  };
};

export default useWalletSubmitHandler;
