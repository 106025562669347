import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Permission, WithdrawalsColumn } from 'utils/enums';
import { hasAccessSelector } from 'selectors/auth';
import { ITableTitleField } from 'interfaces/widgets/widgets';

const useWithdrawalsTableTitles = (): ITableTitleField[] => {
  const managePermission = useSelector(hasAccessSelector(Permission.WITHDRAWALS_MANAGE));
  const canViewPostbacks = useSelector(hasAccessSelector(Permission.POSTBACKS));
  const withdrawalViewAccess = useSelector(hasAccessSelector(Permission.WITHDRAWALS_VIEW));

  const [titles] = useState([
    {
      title: `common.ID`,
      id: WithdrawalsColumn.ID,
      desktop: true,
      mobile: true,
      hide: !managePermission,
    },
    {
      title: `common.type`,
      id: WithdrawalsColumn.TYPE,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `withdrawals.withdrawalMethod`,
      id: WithdrawalsColumn.METHOD,
      desktop: true,
      mobile: true,
      hide: !managePermission,
    },
    {
      title: `withdrawals.requestDate`,
      id: WithdrawalsColumn.REQUEST_DATE,
      desktop: true,
      mobile: true,
    },
    {
      title: `withdrawals.withdrawalId`,
      id: WithdrawalsColumn.WITHDRAWAL_ID,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `withdrawals.requestType`,
      id: WithdrawalsColumn.REQUEST_TYPE,
      desktop: true,
      mobile: true,
      hide: !managePermission,
    },
    {
      title: `withdrawals.walletSender`,
      id: WithdrawalsColumn.WALLET_SENDER,
      desktop: true,
      mobile: true,
      disabled: true,
      hide: !managePermission,
    },
    {
      title: `withdrawals.walletRecipient`,
      id: WithdrawalsColumn.WALLET_RECIPIENT,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.label`,
      id: WithdrawalsColumn.LABEL,
      desktop: true,
      mobile: true,
    },
    {
      title: `common.amount`,
      id: WithdrawalsColumn.AMOUNT,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `common.status`,
      id: WithdrawalsColumn.STATUS,
      desktop: true,
      mobile: true,
      disabled: true,
    },
    {
      title: `withdrawals.kind`,
      id: WithdrawalsColumn.KIND,
      desktop: true,
      mobile: true,
    },
    {
      title: `postbacks.postback`,
      id: WithdrawalsColumn.POSTBACK,
      desktop: true,
      mobile: true,
      hide: !canViewPostbacks,
    },
    {
      title: `common.comment`,
      id: WithdrawalsColumn.COMMENT,
      desktop: true,
      mobile: true,
    },
    {
      title: `common.actions`,
      id: WithdrawalsColumn.ACTIONS,
      desktop: true,
      mobile: true,
      disabled: true,
      hide: !managePermission && !withdrawalViewAccess,
    },
  ]);

  return titles;
};

export default useWithdrawalsTableTitles;
