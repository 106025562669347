import React, { createContext, useReducer, FC, useContext, useState, Dispatch, SetStateAction } from 'react';
import format from 'date-fns/format';
import { notificationsApi } from 'api/notifications';
import { useNotificationWS } from './useNotificationWS';

// Сообщение, приходящее с сервера
export type WSMessage = {
  event: `showMessage`;
  data: {
    id: number;
    title: string;
    text: string;
  };
  requestId: string;
};

// Формат сообщения с сервера, как оно храниться в сторе
export type Message = {
  id: number;
  title: string;
  text: string;
  date: string;
};

export type State = {
  readedMessages: Array<Message>;
  unreadedMessages: Array<Message>;
  isMessagesOpen: boolean;
};

type ReadMessagesAction = {
  type: `READ_MESSAGES`;
  payload: Array<Message>;
};

type AddUnreadMessageAction = {
  type: `ADD_UNREAD_MESSAGE`;
  payload: Message;
};

type SetMessagesOpen = {
  type: `SET_MESSAGES_OPEN`;
  payload: boolean;
};

const initialState: State = {
  readedMessages: [],
  unreadedMessages: [],
  isMessagesOpen: false,
};

const reducer = (state: State, action: AddUnreadMessageAction | ReadMessagesAction | SetMessagesOpen): State => {
  switch (action.type) {
    case `ADD_UNREAD_MESSAGE`:
      return { ...state, unreadedMessages: [...state.unreadedMessages, action.payload] };

    case `READ_MESSAGES`:
      return {
        ...state,
        readedMessages: [
          ...state.unreadedMessages.filter(({ id }) => action.payload.find((message) => id === message.id)),
          ...state.readedMessages,
        ],
        unreadedMessages: [
          ...state.unreadedMessages.filter(({ id }) => !action.payload.find((message) => id === message.id)),
        ],
      };

    case `SET_MESSAGES_OPEN`:
      return { ...state, isMessagesOpen: action.payload };
  }
};

type UseNotifications = {
  notificationsState: State;
  isMessagesShowed: boolean;
  setMessagesShowed: Dispatch<SetStateAction<boolean>>;
  handleAddUnreadMessage: (message: Message) => void;
  handleReadMessages: () => void;
  handleSetMessagesOpen: (flag: boolean) => void;
};

export const NotificationContext = createContext<UseNotifications | undefined>(undefined);

export const NotificationProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isMessagesShowed, setMessagesShowed] = useState(false);

  // TODO: Commented for further considerations
  // const handleMessageWS = (e: MessageEvent<WSMessage>) => {
  //   const data: WSMessage = JSON.parse((e.data as unknown) as string);
  //
  //   if (data.event === `showMessage`) {
  //     const message = data.data;
  //
  //     handleAddUnreadMessage({
  //       ...message,
  //       date: format(new Date(), `dd.MM.y kk:mm`),
  //     });
  //   }
  // };
  //
  // useNotificationWS({
  //   onMessageCb: handleMessageWS,
  // });

  const handleReadMessages = async () => {
    setMessagesShowed(false);
    handleSetMessagesOpen(false);

    try {
      // Пользователь нажал на кнопку "Прочитать все"
      if (state.isMessagesOpen) {
        if (state.unreadedMessages.length > 0) {
          dispatch({ type: `READ_MESSAGES`, payload: state.unreadedMessages });
          await notificationsApi.readMessages(state.unreadedMessages.map(({ id }) => id));
        }
      } else {
        if (state.unreadedMessages.length > 0) {
          const lastTwo = state.unreadedMessages.slice(-2);

          dispatch({ type: `READ_MESSAGES`, payload: lastTwo });
          await notificationsApi.readMessages(lastTwo.map(({ id }) => id));
        }
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const handleAddUnreadMessage = (message: Message) => {
    dispatch({ type: `ADD_UNREAD_MESSAGE`, payload: message });
  };

  const handleSetMessagesOpen = (flag: boolean) => dispatch({ type: `SET_MESSAGES_OPEN`, payload: flag });

  return (
    <NotificationContext.Provider
      value={{
        notificationsState: state,
        isMessagesShowed,
        setMessagesShowed,
        handleAddUnreadMessage,
        handleReadMessages,
        handleSetMessagesOpen,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = (): UseNotifications => {
  const ctx = useContext(NotificationContext);

  if (ctx === undefined) throw new Error(`UseNotifications must be invoke inside a NotificationProvider`);

  return ctx;
};
