import { useState, useEffect } from 'react';
import { getLocation, RouterLocation, RouterRootState } from 'connected-react-router';
import { useSelector } from 'react-redux';

const useRouteName = (): string | undefined => {
  const [route, setRoute] = useState<string>();

  const location: RouterLocation<unknown> = useSelector(
    getLocation as (state: RouterRootState) => RouterLocation<unknown>
  );

  useEffect(() => {
    setRoute(location?.pathname);
  }, [location]);

  return route;
};

export default useRouteName;
