import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import toNumber from 'lodash/toNumber';
import each from 'lodash/each';
import { widgetModalSelector, widgetSubmittingSelector, validateMessages, Size } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import {
  getPaymentCurrencies,
  getPaymentDeposits,
  paymentsConstructorActionCreators,
  resetDeposit,
  updateDepositsRequest,
} from 'actions/widgets/constructors/paymentsConstructor';
import {
  appliedDepositsSelector,
  appliedPaymentCurrencyItemSelectionSelector,
} from 'selectors/widgets/constructor/paymentsConstructor';
import useFormSubmissionHandler from 'hooks/widgets/useFormSubmissionHandler';

import TableModalForm from 'components/modals/TableModalForm';
import { ILimitsFormData, IUpdateDepositsInitialParsedData } from 'interfaces/widgets/constructor/paymentsConstructor';

const headTitles = [
  `common.currency`,
  `paymentConstructor.minDeposit`,
  `paymentConstructor.maxDeposit`,
  `paymentConstructor.minWithdrawal`,
  `paymentConstructor.maxWithdrawal`,
  `paymentConstructor.minStockpilingAmount`,
];

const DepositsModal = (props: InjectedFormProps) => {
  const [rows, setRows] = useState<IUpdateDepositsInitialParsedData[]>([]);

  const dispatch = useDispatch();

  const submitting: boolean = useSelector(widgetSubmittingSelector(WidgetType.PAYMENTS_CONSTRUCTOR)) || false;
  const modal: Record<string, any> = useSelector(widgetModalSelector(WidgetType.PAYMENTS_CONSTRUCTOR));
  const walletType = modal?.walletType;
  const appliedDeposits = useSelector(appliedDepositsSelector);
  const appliedCurrencies = useSelector(appliedPaymentCurrencyItemSelectionSelector);

  useEffect(() => {
    dispatch(getPaymentCurrencies(walletType));
    dispatch(getPaymentDeposits(walletType));

    return () => {
      dispatch(resetDeposit());
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(appliedDeposits) && !isEmpty(appliedCurrencies)) {
      setRows(
        appliedDeposits?.reduce<IUpdateDepositsInitialParsedData[]>((result, { currency, defaults }) => {
          const depositMinDefault = defaults?.deposit?.min;
          const depositMaxDefault = defaults?.deposit?.max;
          const withdrawalMinDefault = defaults?.withdrawal?.min;
          const withdrawalMaxDefault = defaults?.withdrawal?.max;
          const stockpilingAmountMinDefault = defaults?.stockpiling?.min;

          //@ts-ignore
          if (!appliedCurrencies.map((item) => item.currency)?.includes(currency)) {
            return [...result];
          }

          return [
            ...result,
            {
              fields: [
                {
                  fieldType: `static`,
                  value: currency,
                },
                {
                  minAmount: depositMinDefault ? toNumber(depositMinDefault) : undefined,
                  amount: true,
                  name: `${currency}.minDeposit`,
                  arrayField: true,
                },
                {
                  maxAmount: depositMaxDefault ? toNumber(depositMaxDefault) : undefined,
                  amount: true,
                  name: `${currency}.maxDeposit`,
                  arrayField: true,
                },
                {
                  minAmount: withdrawalMinDefault ? toNumber(withdrawalMinDefault) : undefined,
                  amount: true,
                  name: `${currency}.minWithdrawal`,
                  arrayField: true,
                },
                {
                  maxAmount: withdrawalMaxDefault ? toNumber(withdrawalMaxDefault) : undefined,
                  amount: true,
                  name: `${currency}.maxWithdrawal`,
                  arrayField: true,
                },
                {
                  minAmount: stockpilingAmountMinDefault ? toNumber(stockpilingAmountMinDefault) : undefined,
                  format: (value: string | number) =>
                    `${value}`.replace(/[a-zа-я\s|/\\'"`,=:;~^№#!&?%$@ёЁ_<>)(}{\[\]*+-]/gi, ``),
                  name: `${currency}.minStockpiling`,
                  arrayField: true,
                },
              ],
            },
          ];
        }, [])
      );
      props.initialize(
        appliedDeposits?.reduce((result, { currency, limits }) => {
          const minDeposit = limits?.deposit?.min;
          const maxDeposit = limits?.deposit?.max;
          const minWithdrawal = limits?.withdrawal?.min;
          const maxWithdrawal = limits?.withdrawal?.max;
          const minStockpiling = limits?.stockpiling?.min;

          //@ts-ignore
          if (!appliedCurrencies.map((item) => item.currency).includes(currency)) {
            return { ...result };
          }

          return {
            ...result,
            [currency]: {
              minDeposit,
              maxDeposit,
              minWithdrawal,
              maxWithdrawal,
              minStockpiling,
            },
          };
        }, {})
      );
    }
  }, [appliedDeposits, appliedCurrencies]);

  const onSubmit = useCallback(
    (values) => {
      dispatch(updateDepositsRequest(walletType, values));
    },
    [walletType]
  );

  const handleSubmit = useFormSubmissionHandler({
    form: props.form,
    onSubmit,
  });

  return (
    <TableModalForm
      {...props}
      rows={rows}
      submitText="common.edit"
      submitting={submitting}
      handleSubmit={handleSubmit}
      headTitles={headTitles}
      title="paymentConstructor.limits"
      actionCreators={paymentsConstructorActionCreators}
      size={Size.MIDDLE}
    />
  );
};

export default reduxForm({
  form: `update-payment-deposits`,
  validate: (values: Record<string, ILimitsFormData>) => {
    const errors: Record<string, any> = {};

    each(values, (deposit, currency) => {
      let minDeposit = deposit?.minDeposit;
      let maxDeposit = deposit?.maxDeposit;
      let minWithdrawal = deposit?.minWithdrawal;
      let maxWithdrawal = deposit?.maxWithdrawal;
      let minStockpiling = deposit?.minStockpiling;

      minDeposit = toNumber(minDeposit);
      maxDeposit = toNumber(maxDeposit);
      minWithdrawal = toNumber(minWithdrawal);
      maxWithdrawal = toNumber(maxWithdrawal);
      minStockpiling = toNumber(minStockpiling);

      const fields = {
        minDeposit: minDeposit,
        maxDeposit: maxDeposit,
        minWithdrawal: minWithdrawal,
        maxWithdrawal: maxWithdrawal,
        minStockpiling: minStockpiling,
      };

      const getRequiredParam = (key: string, getKey = false): number | string | null => {
        switch (key) {
          case `minDeposit`:
            return getKey ? `maxDeposit` : maxDeposit;
          case `maxDeposit`:
            return getKey ? `minDeposit` : minDeposit;
          case `minWithdrawal`:
            return getKey ? `maxWithdrawal` : maxWithdrawal;
          case `maxWithdrawal`:
            return getKey ? `minWithdrawal` : minWithdrawal;
          case `minStockpiling`:
            return getKey ? `minStockpiling` : minStockpiling;
          default:
            return null;
        }
      };

      if (maxDeposit <= minDeposit && maxDeposit) {
        errors[currency] = { maxDeposit: validateMessages().maxDepositMustBeGreaterThanMin };
      }

      if (maxWithdrawal <= minWithdrawal && maxWithdrawal) {
        errors[currency] = { maxWithdrawal: validateMessages().maxWithdrawalMustBeGreaterThanMin };
      }

      each(fields, (field, key) => {
        if (!!field && !getRequiredParam(key)) {
          errors[currency] = {
            ...errors[currency],
            [getRequiredParam(key, true) as string]: validateMessages().requiredField,
          };
        }
      });
    });

    return errors;
  },
})(DepositsModal);
