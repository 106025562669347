import { useState } from 'react';

export function useOpenedFormType() {
  const [openedFormType, setOpenedFormType] = useState<Array<string>>([]);

  return {
    openedFormType,
    setOpenedFormType,
  };
}
