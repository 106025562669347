import { Action } from 'redux';

import {
  DASHBOARD_DATA_REQUESTED,
  DASHBOARD_CHART_DATA_REQUESTED,
  SET_DASHBOARD_ASIDE_FILTERS_REQUESTED,
  SET_DASHBOARD_CHART_FILTERS_REQUESTED,
  CHANGE_DASHBOARD_ENTITY_TYPE,
} from 'actionTypes';
import { IDashboardStateAsideFilters, IDashboardStateChartFilters } from 'interfaces/dashboard';

interface IAction<T = any> {
  type: string;
  payload: T;
}

export const fetchDashboardData = (): Action<string> => ({ type: DASHBOARD_DATA_REQUESTED });

export const fetchDashboardChartEntityData = (): Action<string> => ({ type: DASHBOARD_CHART_DATA_REQUESTED });

export const setDashboardChartFilters = (
  payload: IDashboardStateChartFilters
): IAction<IDashboardStateChartFilters> => ({
  type: SET_DASHBOARD_CHART_FILTERS_REQUESTED,
  payload,
});

export const setDashboardAsideFilters = (
  payload: IDashboardStateAsideFilters
): IAction<IDashboardStateAsideFilters> => ({
  type: SET_DASHBOARD_ASIDE_FILTERS_REQUESTED,
  payload,
});

export const changeDashboardEntityType = (payload: number): IAction<number> => ({
  type: CHANGE_DASHBOARD_ENTITY_TYPE,
  payload,
});
