import React, { useCallback, useEffect, useState } from 'react';
import useUnmount from 'react-use/lib/useUnmount';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import size from 'lodash/size';
import isArray from 'lodash/isArray';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import { WidgetContainer, PageContainer, widgetSelector, IRootState } from '@kassma-team/kassma-toolkit/lib';

import { ModalType, WalletsLoadDirection, WalletsLoadType, WidgetType } from 'utils/enums';
import useUserHasAccess from 'hooks/useUserHasAccess';
import { walletsLoadActionCreators } from 'actions/widgets/walletsLoad';
import { walletActionCreators } from 'actions/widgets/wallet';
import useWalletsLoadTableTitles from 'hooks/widgets/tableTitles/useWalletsLoadTableTitles';
import useSearchParams from 'hooks/useSearchParams';
import { IWalletsLoadRowProps } from 'interfaces/widgets/wallets/wallet';

import WalletsLoadRow from 'components/widgets/walletsLoad/WalletsLoadRow';
import WalletsLoadSettings from 'components/widgets/walletsLoad/WalletsLoadSettings';
import Header from 'components/header/Header';
import WidgetTable from 'components/widgets/WidgetTable';
import Modal from 'components/modals/Modal';
import TableContentContainer from 'components/containers/TableContentContainer';

interface IHeaderButtonsProps {
  children: string | React.ReactNode;
  onClick: () => void;
  className?: string;
}

const { showModal, getList, resetList } = walletsLoadActionCreators;

const useExtraHeaderButtons = () => {
  const search = useSearchParams();

  const [t] = useTranslation();

  const { direction, load_type } = search;
  const withSettings = direction && load_type;

  const headerButtons: IHeaderButtonsProps[] = [
    {
      children: t(withSettings ? `wallets.changeLoadSettings` : `wallets.loadSettings`),
      onClick: () => showModal({ type: ModalType.WALLETS_LOAD_SETTINGS }),
    },
  ];
  if (withSettings) {
    headerButtons.push({
      children: <i className="fa fa-times text-danger icon-margin-left" />,
      className: ``,
      onClick: () => push(`/wallets-load`),
    });
  }

  return headerButtons;
};

const getTitleOfData = (load_type: string, direction: string) => {
  if (load_type === WalletsLoadType.TRANSACTIONS_SUM) {
    if (direction === WalletsLoadDirection.INGOING) {
      return `wallets.amountOfIngoingTransactionsInUSD`;
    }

    return `wallets.amountOfOutgoingTransactionsInUSD`;
  } else {
    if (direction === WalletsLoadDirection.INGOING) {
      return `wallets.quantityOfIngoingTransactions`;
    }

    return `wallets.quantityOfOutgoingTransactions`;
  }
};

const dateFormat = (date?: string) => moment(date).format(`DD.MM.YYYY`);

const WalletsLoad = () => {
  useUserHasAccess();
  const dispatch = useDispatch();
  const { items, error } = useSelector<IRootState, any>(widgetSelector(WidgetType.WALLETS_LOAD));

  const [t] = useTranslation();

  const search: Record<string, string | null | string[]> = useSearchParams();
  const extraHeaderButtons = useExtraHeaderButtons();

  const getTitle = useCallback(() => {
    if (!search) {
      return t(`wallets.walletList`);
    }

    const { date_from, date_to, load_type, direction } = search;

    if (load_type && direction && date_from && date_to) {
      return (
        <span>
          {t(`wallets.dateTransactionActivation`)}:{` `}
          <b>
            {dateFormat(date_from as string)} - {dateFormat(date_to as string)}
          </b>
        </span>
      );
    }

    return t(`wallets.walletList`);
  }, [search]);

  const [title, setTitle] = useState(getTitle());

  useEffect(() => {
    const { direction, load_type } = search;
    if (direction && load_type) {
      dispatch(getList(search, { statusBar: true }));
    }
  }, []);

  useUpdateEffect(() => {
    const { direction, load_type } = search;
    if (direction && load_type) {
      dispatch(getList(search, { statusBar: true }));
    } else {
      dispatch(resetList());
    }

    setTitle(getTitle());
  }, [search]);

  const titles = useWalletsLoadTableTitles();

  useUnmount(() => {
    dispatch(resetList());
  });

  const listMapping = (item: IWalletsLoadRowProps, key: number) => (
    <WalletsLoadRow
      key={item.id || key}
      {...item}
      onWalletClick={() => walletActionCreators.showModal({ type: ModalType.SHOW, id: item.id })}
      dataTitle={search ? getTitleOfData(search.load_type as string, search.direction as string) : null}
    />
  );

  const table =
    isArray(items) && size(items) > 0 ? (
      <WidgetTable
        widget={WidgetType.WALLETS_LOAD}
        data={items}
        titles={titles}
        listMapping={listMapping}
        error={error}
      />
    ) : (
      <span className="table-content__message">{t(`wallets.selectTypeAndLoadsDirection`)}</span>
    );

  return (
    <>
      <PageContainer>
        <Helmet>
          <title>{t(`wallets.walletsLoad`)}</title>
        </Helmet>
        <Header />
        <WidgetContainer title="wallets.walletsLoad">
          <TableContentContainer
            title={title}
            extraHeaderButtons={extraHeaderButtons}
            withFilters
            translatedTitle={false}
          >
            {table}
          </TableContentContainer>
        </WidgetContainer>
      </PageContainer>
      <Modal widget={WidgetType.WALLETS_LOAD} type={ModalType.WALLETS_LOAD_SETTINGS} component={WalletsLoadSettings} />
    </>
  );
};

export default WalletsLoad;
