import React, { FC } from 'react';
import { Message } from '../../useNotificationContext';
import { useNotificationUi } from '../../useNotificationUi';
import './MessagesList.scss';

type Props = {
  type: `readed` | `unreaded`;
  messages: Array<Message>;

  children?: never;
};

export const MessagesList: FC<Props> = ({ type = `readed`, messages }) => {
  const { readedTitle, unreadedTitle, emptyMessages } = useNotificationUi();

  const hasMessages = () => messages.length > 0;

  const renderMessages = () =>
    messages.map(({ title, date, text, id }) => (
      <div className="message" key={id}>
        <p className="message__title">{title}</p>
        <p className="message__body">{text}</p>
        <p className="message__date">{date}</p>
      </div>
    ));

  const showEmptyMessage = () => <p className="MessagesList__empty">{emptyMessages}</p>;

  return (
    <div className="MessagesList">
      {hasMessages() && <p className="MessagesList__title">{type === `readed` ? readedTitle : unreadedTitle}</p>}
      <div className="MessagesList__body">{hasMessages() ? renderMessages() : showEmptyMessage()}</div>
    </div>
  );
};
