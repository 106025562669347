import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { widgetModalSelector } from '@kassma-team/kassma-toolkit/lib';

import { resetPaymentsSorting, settingsActionCreators } from 'actions/widgets/settings';
import { WidgetType } from 'utils/enums';

import ConfirmationModal from 'components/modals/ConfirmationModal';
import { ISettingsStateModal } from 'interfaces/widgets/settings';

const ResetSortingModal = () => {
  const dispatch = useDispatch();

  const { currency }: ISettingsStateModal | Record<string, unknown> =
    useSelector(widgetModalSelector(WidgetType.SETTINGS)) || {};

  return (
    <ConfirmationModal
      actionCreators={settingsActionCreators}
      title="settings.resetDisplayOrder"
      confirmText="common.reset"
      onConfirm={() => dispatch(resetPaymentsSorting(currency as string))}
      content="settings.sureResetDisplayOrder"
    />
  );
};

export default ResetSortingModal;
