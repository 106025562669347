import { createWidgetAction, IWidgetActionCreators, widgetActionCreators } from '@kassma-team/kassma-toolkit/lib';

import { WidgetType } from 'utils/enums';
import { CREATE_PAYMENT_SYSTEM_FORM_NAME, UPDATE_PAYMENT_SYSTEM_FORM_NAME } from 'utils/constants';
import { DISABLE_MANUAL_PS_REQUESTED } from 'actionTypes';

export const manualPSActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.MANUAL_PAYMENT_SYSTEMS,
  labels: { listLabel: `test_wallet_types`, itemLabel: `test_wallet_type` },
  creatingForm: CREATE_PAYMENT_SYSTEM_FORM_NAME,
  listItemUpdatingForm: UPDATE_PAYMENT_SYSTEM_FORM_NAME,
  uniqKey: `code`,
  withPagination: false,
});

export const disableManualPaymentSystem: IWidgetActionCreators = createWidgetAction({
  widget: WidgetType.MANUAL_PAYMENT_SYSTEMS,
})(DISABLE_MANUAL_PS_REQUESTED);

export const paymentSystemsActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.PAYMENT_SYSTEMS,
  labels: { listLabel: `payment-systems` },
  withPagination: false,
});

export const transactionPSActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.TRANSACTIONS_PAYMENT_SYSTEMS,
  labels: { listLabel: `payment_systems` },
  withPagination: false,
});

export const withdrawalPSActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.WITHDRAWALS_PAYMENT_SYSTEMS,
  labels: { listLabel: `payment_systems` },
  withPagination: false,
});

export const paymentSystemsForPossibleTransactionsActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.PAYMENT_SYSTEMS_FOR_POSSIBLE_TRANSACTIONS,
  labels: { listLabel: `payment-systems` },
  withPagination: false,
});

export const paymentSystemsForForcedTransactionsActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.PAYMENT_SYSTEMS_FOR_FORCED_TRANSACTIONS,
  labels: { listLabel: `types` },
  withPagination: false,
});

export const dashboardPaymentSystemsInfoActionCreators: IWidgetActionCreators = widgetActionCreators({
  widget: WidgetType.DASHBOARD_PAYMENT_SYSTEMS_INFO,
  labels: { listLabel: `payment_systems` },
});
