import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { formReducer, configReducer, getWidgetReducer } from '@kassma-team/kassma-toolkit/lib';

import proxy from 'reducers/widgets/proxy';
import wallet from 'reducers/widgets/wallet';
import transaction from 'reducers/widgets/transaction';
import manualPS from 'reducers/widgets/manualPS';
import settings from 'reducers/widgets/settings';
import paymentsConstructor from 'reducers/widgets/constructors/paymentsConstructor';
import withdrawals from 'reducers/widgets/withdrawals';
import paytmCookies from 'reducers/widgets/paytmCookies';
import tariffs from 'reducers/widgets/tariffs';
import paymentHints from 'reducers/widgets/paymentHints';
import settelements from 'reducers/widgets/settelement';
import accountsBalance from 'reducers/widgets/accountsBalance';
import rbacUsers from 'reducers/widgets/rbac-users';

import history from '../rootHistory';
import dashboard from './dashboard';
import { auth } from 'reducers/auth';

const appReducer = combineReducers({
  router: connectRouter(history),
  form: formReducer,
  loadingBar: loadingBarReducer,
  dashboard,
  widgets: getWidgetReducer([
    proxy,
    wallet,
    transaction,
    manualPS,
    paymentsConstructor,
    settings,
    withdrawals,
    paytmCookies,
    tariffs,
    paymentHints,
    settelements,
    accountsBalance,
    rbacUsers,
  ]),
  auth,
  config: configReducer,
  toastr: toastrReducer,
});

export default appReducer;
