import { createSelector } from 'reselect';
import map from 'lodash/map';
import includes from 'lodash/includes';
import { IAuthState, IRootState, userSelector as user } from '@kassma-team/kassma-toolkit/lib';
import { authSelector } from '@kassma-team/kassma-toolkit/lib/selectors/auth';

interface ITableHiddenColumns {
  [table: string]: string[];
}

export interface IAuthUser {
  id: number;
  name: string;
  roles: string[];
  hidden_columns: ITableHiddenColumns;
}

export const tokenSelector = createSelector<IRootState, IAuthState, string | null>(authSelector, ({ token }) => token);

export const userPermissionsSelector = createSelector<IAuthUser | null, IAuthUser, string[]>(
  user,
  (user) => user.roles
);

export const hasAccessSelector = (role: string) =>
  createSelector<IAuthUser, string[], boolean>(userPermissionsSelector, (roles) => roles.includes(role));
