import { Permission } from 'utils/enums';
import includes from 'lodash/includes';

const reportRouteTabs = (permissions: string[]) => [
  {
    path: `/reports`,
    title: `reports.reportList`,
    hasAccess: includes(permissions, Permission.WALLET_LIST),
  },
  {
    path: `/autoreports`,
    title: `reports.reportTasks`,
    hasAccess: includes(permissions, Permission.WALLET_LIST),
  },
];

export default reportRouteTabs;
