import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalType, Permission, WidgetType } from 'utils/enums';
import { reportsActionCreators } from 'actions/widgets/reports';
import useReportsTableTitles from 'hooks/widgets/tableTitles/useReportsTableTitles';
import { IReportRowProps } from 'interfaces/widgets/reports';

import ReportGeneratingModal from 'components/widgets/reports/ReportGenerating';
import ReportRow from 'components/widgets/reports/ReportRow';
import ReportWaitingInfo from 'components/widgets/reports/ReportWaitingInfo';
import Widget from 'components/widgets/Widget';
import reportRouteTabs from './reportRoutTabs';
import { useDispatch, useSelector } from 'react-redux';
import { userPermissionsSelector } from '../../selectors/auth';
import { replace } from 'connected-react-router';

const { showModal } = reportsActionCreators;

const listMapping = (item: IReportRowProps, key: number) => <ReportRow {...item} key={key} />;

const modals = [
  {
    type: ModalType.CREATE,
    widget: WidgetType.REPORTS,
    component: ReportGeneratingModal,
  },
  {
    type: ModalType.INFO,
    widget: WidgetType.REPORTS,
    component: ReportWaitingInfo,
  },
];

const ReportsPermissionsList = [
  Permission.TRANSACTION_REPORTS,
  Permission.LABEL_HISTORY_REPORT,
  Permission.COMMISSION_REPORT,
  Permission.WITHDRAWAL_REQUEST_REPORT,
  Permission.CLIENT_BALANCE_HISTORY_REPORT,
];

const Reports = () => {
  const tableHeadTitles = useReportsTableTitles();
  const dispatch = useDispatch();
  const permissionsList = useSelector(userPermissionsSelector);
  const [t] = useTranslation();

  const hasPermission = ReportsPermissionsList.some((permission: string) => permissionsList.includes(permission));

  useEffect(() => {
    if (!hasPermission) dispatch(replace(`/no-permissions`));
  }, [hasPermission]);

  const [extraButtons] = useState([
    {
      title: t(`reports.generateReport`),
      onClick: () => showModal({ type: ModalType.CREATE }),
    },
  ]);

  return (
    <Widget
      widget={WidgetType.REPORTS}
      routeTabs={reportRouteTabs}
      actionCreators={reportsActionCreators}
      pageTitle="reports.reports"
      headTitle="reports.reports"
      tableTitle="reports.reportList"
      tableHeadTitles={tableHeadTitles}
      listMapping={listMapping}
      extraButtons={extraButtons}
      statusBar
      modals={modals}
    />
  );
};

export default Reports;
