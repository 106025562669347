import React, { ReactNode } from 'react';
import { Button, Preloader, Size } from '@kassma-team/kassma-toolkit/lib';

interface IProps {
  children: ReactNode;
  submitting?: boolean;
  disabled?: boolean;
  type?: string;
  onClick?: () => void;
}

const NewFormSubmit = ({ children, submitting = false, disabled = false, ...props }: IProps): JSX.Element => (
  <div className="flex form-submit-btn">
    <Button disabled={submitting || disabled} {...props}>
      {children}
    </Button>
    {submitting && <Preloader center={false} className="margin-mini-preloader" size={Size.LITTLE} />}
  </div>
);

export default NewFormSubmit;
