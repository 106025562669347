import React, { useCallback, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { WalletConfirmationInfoType } from 'utils/enums';
import { IConfirmationInfoType } from 'interfaces/widgets/wallets/wallet';

import IciciLoginConflictModal from 'components/widgets/wallets/confirmationModals/IciciLoginConflictModal';
import IdbLoginConflictModal from 'components/widgets/wallets/confirmationModals/IdbLoginConflictModal';

interface IProps {
  onSave: () => void;
  onCancel: () => void;
  infoType?: number;
  infoData?: IConfirmationInfoType | null;
  submitFailed?: boolean;
  error?: string;
  className?: string;
  submitting?: boolean;
  form?: string;
}

const ConfirmationModal = ({ infoType, infoData, onSave, ...props }: IProps) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const onConfirm = useCallback(() => {
    onSave();
    setHasSubmitted(true);
  }, []);

  if (infoType === WalletConfirmationInfoType.ICICI_LOGIN_CONFLICT && !isEmpty(infoData)) {
    return <IciciLoginConflictModal {...infoData} {...props} onSave={onConfirm} hasSubmitted={hasSubmitted} />;
  }

  if (infoType === WalletConfirmationInfoType.IDB_LOGIN_CONFLICT && !isEmpty(infoData)) {
    return <IdbLoginConflictModal {...infoData} {...props} onSave={onConfirm} hasSubmitted={hasSubmitted} />;
  }

  return null;
};

export default ConfirmationModal;
