import { widgetActionCreators } from '@kassma-team/kassma-toolkit';

import { WidgetType } from 'utils/enums';
import { IWidgetLabels } from 'interfaces/widgets/widgets';
import { accountsBalancesActionCreators } from './accountsBalances';
import { put } from 'redux-saga/effects';

const transferHistoryLabels: IWidgetLabels = { listLabel: `transactions`, itemLabel: `data` };

export const accountsBalanceHistoryActionCreators = widgetActionCreators({
  widget: WidgetType.ACCOUNT_BALANCE_HISTORY,
  labels: transferHistoryLabels,
  onSuccess: function* (data: any) {
    const balance = data.balance;
    if (balance) {
      yield put(accountsBalanceHistoryActionCreators.setAnyData({ balance }));
    }
  },
});
